import { useAuth0 } from "@auth0/auth0-react";
import {
  Box,
  Button,
  Flex,
  Text,
  VStack,
  useMediaQuery,
} from "@chakra-ui/react";
import axios from "axios";
import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState,
} from "react";
import { useStore } from "../../../store";
import GlobalBodyPage from "../../GlobalContainer/BodyPage";
import DropDownButton from "../../InputFields/dropDownButton";
import NormalInputButton from "../../InputFields/normalInputButton";
import TableComponent from "../../TableComponent";
import TooltipContent from "../../TooltipContent";
import { CloseIcon } from "../../CustomIcons";

const ExpenseDebtPage = forwardRef((props, ref) => {
  const { getAccessTokenSilently } = useAuth0();

  useImperativeHandle(ref, () => ({
    submitHandler,
    checkMandatoryFields,
  }));

  const checkMandatoryFields = () => {
    return true;
  };

  const axiosInstance = axios.create();
  async function getToken() {
    // Replace this with your Auth0 token retrieval logic
    const token = await getAccessTokenSilently({
      audience:
        "https://os2lsiwff43wfpb5ggyawt6qri0mgcro.lambda-url.us-east-2.on.aws/",
      scope: "write:optimization",
    });
    return token;
  }

  // Add a request interceptor
  axiosInstance.interceptors.request.use(
    async (config) => {
      const token = await getToken();
      config.headers.Authorization = `Bearer ${token}`;
      return config;
    },
    (error) => {
      // Do something with request error
      return Promise.reject(error);
    }
  );
  const { user, logout } = useAuth0();

  const userInfo = useStore((store) => store.userInfo);
  const userDebtInfo = useStore((store) => store.userDebtInfo);
  const setUserDebtInfo = useStore((store) => store.setUserDebtInfo);
  const updateUserDebtInfo = useStore((store) => store.updateUserDebtInfo);
  const deleteUserDebtInfo = useStore((store) => store.deleteUserDebtInfo);

  const {
    FirstName: firstName,
    LastName: lastName,
    DateOfBirth: currentAge,
    spouseFirstName,
    spouseLastName,
    spouseDateOfBirth: spouseAge,
  } = userInfo;

  const [hasDebt, setHasDebt] = useState("No");
  const [debtName, setDebtName] = useState("");
  const [amountBorrowed, setAmountBorrowed] = useState(0);
  const [debtInterest, setDebtInterest] = useState(0);
  const [monthlyPayment, setMonthlyPayment] = useState(0);
  const [debtNumber, setDebtNumber] = useState("");
  const [debtArray, setDebtArray] = useState([]);

  const [onCancel, setOnCancel] = useState(false);

  const [editing, setEditing] = useState(false);
  const [editingIndex, setEditingIndex] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const [isLargerThan768] = useMediaQuery("(min-width: 500px)");
  const isMobile = !isLargerThan768;

  const setUserPageLockInfo = useStore((store) => store.setUserPageLockInfo);
  const userPageLockInfo = useStore((store) => store.userPageLockInfo);

  const userEditAssetInfo = useStore((store) => store.userEditAssetInfo);
  const setUserEditAssetInfo = useStore((store) => store.setUserEditAssetInfo);

  useEffect(() => {
    if (userEditAssetInfo?.editAsset?.Debt_Name) {
      setDebtName(userEditAssetInfo?.editAsset?.Debt_Name);
      setAmountBorrowed(userEditAssetInfo?.editAsset?.Amount_Borrowed);
      setDebtNumber(userEditAssetInfo?.editAsset.Debt_Number);
      setDebtInterest(userEditAssetInfo?.editAsset?.Debt_Interest);
      setMonthlyPayment(userEditAssetInfo?.editAsset?.Debt_Monthly_Payments);
      setHasDebt("Yes");
      setEditing(true);
      setEditingIndex(userEditAssetInfo?.editIndex);
    }
  }, [userEditAssetInfo]);

  useEffect(() => {
    if (userDebtInfo?.length > 0) {
      setDebtArray(userDebtInfo);
    }
  }, [userDebtInfo]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [userDebtInfo]);

  const moneyToNumber = (value) => {
    let numValue = value.replace(/[$,\s]/g, "");
    numValue = Number(numValue);
    return numValue;
  };

  const submitHandler = () => {
    setUserEditAssetInfo({});
    return true;
  };

  const [errors, setErrors] = useState({});

  const checkMandatoryAddUpdateFields = () => {
    let newErrors = {};

    // Debt conditions
    if (!debtName) {
      newErrors.debtName = "Debt name is required";
    }
    if (!monthlyPayment) {
      newErrors.monthlyPayment = "Monthly payment is required";
    }
    if (!amountBorrowed) {
      newErrors.amountBorrowed = "Amount borrowed is required";
    }
    if (debtInterest > 30) {
      newErrors.debtInterest = "Debt interest must be less than 30%";
    }
    if (
      amountBorrowed > 0 &&
      monthlyPayment < (amountBorrowed * (debtInterest / 100)) / 12
    ) {
      newErrors.monthlyPayment =
        "Monthly payment is less than the effective monthly interest.";
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0; // Return true if no errors
  };

  const renderAddUpdate = (arr) => {
    if (
      !debtName ||
      !monthlyPayment ||
      !amountBorrowed ||
      debtInterest > 30 ||
      (amountBorrowed > 0 &&
        monthlyPayment < (amountBorrowed * (debtInterest / 100)) / 12)
    ) {
      return (
        <Flex justifyContent={"flex-start"} marginTop={"20px"}>
          <Button
            bg="#27AE60"
            w="172px"
            h="37px"
            align="flex-start"
            onClick={checkMandatoryAddUpdateFields}
            // disabled={!isFieldsValid}
          >
            <Text
              color={"#FFFFFF"}
              fontFamily={"Nunito"}
              fontSize={"14px"}
              fontStyle={"normal"}
              fontWeight={"700"}
              lineHeight={"normal"}
            >
              {editing ? "Update" : "Add"}
            </Text>
          </Button>
        </Flex>
      );
    } else {
      return (
        <Flex justifyContent={"flex-start"} marginTop={"20px"}>
          <Button
            bg="#27AE60"
            w="172px"
            h="37px"
            align="flex-start"
            onClick={(e) => addDebtItem(e, arr)}
            // disabled={!isFieldsValid}
          >
            <Text
              color={"#FFFFFF"}
              fontFamily={"Nunito"}
              fontSize={"14px"}
              fontStyle={"normal"}
              fontWeight={"700"}
              lineHeight={"normal"}
            >
              {editing ? "Update" : "Add"}
            </Text>
          </Button>
        </Flex>
      );
    }
  };

  const onSubmitUpdate = (e, newArray) => {
    e.preventDefault();
    // console.log("Editing", debtName, amountBorrowed, monthlyPayment, debtInterest, hasDebt, debtNumber, debtArray)
    const debtURL = (action) =>
      `${
        process.env.REACT_APP_NODE_API_GATEWAY
      }/CompassLambdaApi/DEBT/${action}?DebtID=${user.sub.replace(
        "|",
        "%7c"
      )}&Debt_Name=${debtName}&Amount_Borrowed=${amountBorrowed}&Debt_Interest=${
        debtInterest ?? 0
      }&Debt_Monthly_Payments=${monthlyPayment}&Do_You_Debt=${hasDebt}&Debt_Number=${debtNumber}`;
    axiosInstance
      .get(debtURL("update"))
      .then((res) => {
        console.log(res);
        const newData = {
          Debt_Name: debtName,
          Amount_Borrowed: amountBorrowed,
          Debt_Monthly_Payments: monthlyPayment,
          Debt_Interest: debtInterest ?? 0,
          Do_You_Debt: hasDebt,
          Debt_Number: debtNumber,
        };
        // console.log(newData)
        updateUserDebtInfo(debtNumber, newData);
        // console.log(userDebtInfo)
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const onSubmitAdd = (e) => {
    e.preventDefault();
    // console.log("Adding", debtName, amountBorrowed, monthlyPayment, debtInterest, hasDebt, debtArray[debtArray.length -1])
    const debtURL = (action) =>
      `${
        process.env.REACT_APP_NODE_API_GATEWAY
      }/CompassLambdaApi/DEBT/${action}?DebtID=${user.sub.replace(
        "|",
        "%7c"
      )}&Debt_Name=${debtName}&Amount_Borrowed=${amountBorrowed}&Debt_Interest=${
        debtInterest ?? 0
      }&Debt_Monthly_Payments=${monthlyPayment}&Do_You_Debt=${hasDebt}`;
    axiosInstance
      .get(debtURL("add"))
      .then((res) => {
        console.log(res);
        const newData = [
          {
            Debt_Name: debtName,
            Amount_Borrowed: amountBorrowed,
            Debt_Monthly_Payments: monthlyPayment,
            Debt_Interest: debtInterest ?? 0,
            Do_You_Debt: hasDebt,
            Debt_Number: res.data.lastInsertedId,
          },
        ];
        setUserDebtInfo(newData);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const editField = (i) => {
    setDebtName(debtArray[i]?.Debt_Name);
    setAmountBorrowed(debtArray[i]?.Amount_Borrowed);
    setDebtNumber(debtArray[i].Debt_Number);
    setDebtInterest(debtArray[i]?.Debt_Interest);
    setMonthlyPayment(debtArray[i]?.Debt_Monthly_Payments);
    setHasDebt("Yes");
    setEditing(true);
    setEditingIndex(i);
  };

  const onDeleteRow = (i, array) => {
    setEditingIndex(i);
    setShowDeleteModal(true);
  };

  const DeleteIncomeModal = () => {
    const removeDebtModal = () => {
      const deleteDebtUrl = (action) =>
        `${process.env.REACT_APP_NODE_API_GATEWAY}/CompassLambdaApi/DEBT/${action}?Debt_Number=${debtArray[editingIndex].Debt_Number}`;
      axiosInstance.get(deleteDebtUrl("delete")).then((res) => {
        // console.log('Delete res', res)
        deleteUserDebtInfo(debtArray[editingIndex].Debt_Number);
        removeField(editingIndex);
        setEditingIndex(null);
        setEditing(false);
        setDebtName("");
        setAmountBorrowed(0);
        setDebtInterest(0);
        setMonthlyPayment(0);
      });
    };

    if (!showDeleteModal) {
      return null;
    }

    const [yesClicked, setYesClicked] = useState(false);
    const [noClicked, setNoClicked] = useState(false);
    return (
      <div
        className="delete-income-modal"
        style={{
          display: "flex",
          alignItems: "center",
          width: "100%",
          maxWidth: "700px",
          justifyContent: "center",
          top: "50%",
          left: "50%",
          transform: isMobile
            ? "translate(-50%, -50%)"
            : "translate(-50%, -50%)",
        }}
        id="delete-income-modal"
      >
        <Box
          display={"flex"}
          width={"100%"}
          padding={"10px 10px 0px 0px"}
          marginLeft={"5px"}
          alignItems={"flex-end"}
          justifyContent={"flex-end"}
          onClick={() => setShowDeleteModal(false)}
          cursor={"pointer"}
        >
          <CloseIcon
            onClick={() => setShowDeleteModal(false)}
            svgheight={"20px"}
            svgwidth={"20px"}
          />
        </Box>
        <Box
          display={"flex"}
          padding={"0px 25px 25px 25px"}
          flexDirection={"column"}
          alignItems={"center"}
          gap={"10px"}
          alignSelf={"stretch"}
          width={"100%"}
        >
          <Text
            color={"#0093D0"}
            fontFamily={"Nunito"}
            fontSize={"24px"}
            fontStyle={"normal"}
            fontWeight={"600"}
            lineHeight={"normal"}
            textDecor={"underline"}
          >
            Are you sure you want to remove this debt?
          </Text>
          <div className="content"></div>
          <div className="actions">
            <button
              className={`decision-button yes-button ${
                yesClicked ? "yes-clicked" : ""
              }`}
              onClick={() => {
                setYesClicked(true);
                setNoClicked(false);
                removeDebtModal();
              }}
            >
              Yes
            </button>
            <button
              className={`decision-button no-button ${
                noClicked ? "yes-clicked" : ""
              }`}
              onClick={() => {
                {
                  setNoClicked(true);
                  setYesClicked(false);
                  setShowDeleteModal(false);
                }
              }}
            >
              No
            </button>
          </div>
        </Box>
      </div>
    );
  };

  const addDebtItem = (e) => {
    if (editing) {
      setDebtArray((prevArray) => {
        // Create a copy of the original array
        let newArray = [...prevArray];

        // Replace the object at editingIndex with the new object
        newArray[editingIndex] = {
          Debt_Name: debtName,
          Amount_Borrowed: amountBorrowed,
          Debt_Interest: debtInterest,
          Debt_Monthly_Payments: monthlyPayment,
          Debt_Number: debtNumber,
        };

        onSubmitUpdate(e, newArray);

        return newArray;
      });
      setEditing(false);
      setEditingIndex(null);
    } else {
      setDebtArray((prevArray) => {
        let newArray = [
          ...prevArray,
          {
            Debt_Name: debtName,
            Amount_Borrowed: amountBorrowed,
            Debt_Interest: debtInterest,
            Debt_Monthly_Payments: monthlyPayment,
          },
        ];
        onSubmitAdd(e);
        return newArray;
      });
    }
    setDebtName("");
    setAmountBorrowed("");
    setHasDebt("No");
    setDebtInterest("");
    setMonthlyPayment("");
  };

  const removeField = (i) => {
    debtArray.splice(i, 1);
    setDebtArray((prevArray) => [...prevArray]);
    setShowDeleteModal(false);
  };

  const columns = [
    { header: "Name", field: "name" },
    { header: "Value", field: "value" },
  ];

  const data = debtArray.map((item) => ({
    name: item.Debt_Name,
    value: item.Amount_Borrowed,
  }));

  const handleOnCancel = () => {
    setHasDebt("No");
  };
  return (
    <Flex
      display="flex"
      flexWrap="wrap" // Enable wrapping
      justifyContent="flex-start"
      alignItems="start"
      width={"100%"}
    >
      <DeleteIncomeModal />
      {!isMobile && (
        <VStack alignItems={"flex-start"} width={"100%"} mt={-2}>
          <GlobalBodyPage
            Heading={"Debt"}
            mainframeContent={
              <>
                <Box
                  display={"flex"}
                  width={"100%"}
                  flexDirection={"row"}
                  alignItems={"flex-end"}
                  justifyContent={"space-between"}
                  gap={"10px"}
                  flexWrap={"wrap"}
                >
                  {data.length > 0 && hasDebt != "Yes" && (
                    <TableComponent
                      columns={columns}
                      data={data}
                      editField={editField}
                      onDeleteRow={onDeleteRow}
                      rawData={debtArray}
                    />
                  )}
                  {hasDebt != "Yes" && data.length < 1 && (
                    <DropDownButton
                      Heading={
                        "Do you have any debt you would like to include in the analysis (excluding mortgages)?"
                      }
                      placeholder={"Select"}
                      dropDownItems={[
                        { key: "Yes", value: "Yes" },
                        { key: "No", value: "No" },
                      ]}
                      value={hasDebt}
                      onChange={(key) => {
                        setHasDebt(key);
                      }}
                      customWidth={"100%"}
                      MenuCustomWidth={"45%"}
                      error={errors.hasDebt}
                      tooltipContent={TooltipContent.debtYesNo}
                    />
                  )}
                  {data.length >= 1 && hasDebt != "Yes" && (
                    <Flex justifyContent={"flex-start"} marginTop={"20px"}>
                      <Button
                        bg="#27AE60"
                        w="172px"
                        h="37px"
                        align="flex-start"
                        onClick={() => {
                          setHasDebt("Yes");
                        }}
                      >
                        <Text
                          color={"#FFFFFF"}
                          fontFamily={"Nunito"}
                          fontSize={"14px"}
                          fontStyle={"normal"}
                          fontWeight={"700"}
                          lineHeight={"normal"}
                        >
                          Add
                        </Text>
                      </Button>
                    </Flex>
                  )}
                  {hasDebt === "Yes" && (
                    <HasDebt
                      debtName={debtName}
                      setDebtName={setDebtName}
                      amountBorrowed={amountBorrowed}
                      setAmountBorrowed={setAmountBorrowed}
                      monthlyPayment={monthlyPayment}
                      setMonthlyPayment={setMonthlyPayment}
                      debtInterest={debtInterest}
                      setDebtInterest={setDebtInterest}
                      onCancel={handleOnCancel}
                      renderAddUpdate={renderAddUpdate}
                      debtArray={debtArray}
                      moneyToNumber={moneyToNumber}
                      errors={errors}
                      TooltipContent={TooltipContent}
                    />
                  )}
                </Box>
              </>
            }
            videoName={"Debt Page"}
            video={"DEBT.mp4"}
          />
        </VStack>
      )}

      {isMobile && (
        <VStack spacing={4} alignItems={"flex-start"} width={"100%"}>
          <GlobalBodyPage
            Heading={"Debt"}
            mainframeContent={
              <>
                <Box
                  display={"flex"}
                  width={"100%"}
                  flexDirection={"row"}
                  alignItems={"flex-end"}
                  justifyContent={"space-between"}
                  gap={"10px"}
                  flexWrap={"wrap"}
                >
                  {data.length > 0 && hasDebt != "Yes" && (
                    <TableComponent
                      columns={columns}
                      data={data}
                      editField={editField}
                      onDeleteRow={onDeleteRow}
                      rawData={debtArray}
                    />
                  )}
                  {hasDebt != "Yes" && data.length < 1 && (
                    <DropDownButton
                      Heading={
                        "Do you have any debt you would like to include in the analysis (excluding mortgages)?"
                      }
                      placeholder={"Select"}
                      dropDownItems={[
                        { key: "Yes", value: "Yes" },
                        { key: "No", value: "No" },
                      ]}
                      value={hasDebt}
                      onChange={(key) => {
                        setHasDebt(key);
                      }}
                      customWidth={"100%"}
                      MenuCustomWidth={"100%"}
                      error={errors.hasDebt}
                      tooltipContent={TooltipContent.debtYesNo}
                    />
                  )}
                  {data.length >= 1 && hasDebt != "Yes" && (
                    <Flex justifyContent={"flex-start"} marginTop={"20px"}>
                      <Button
                        bg="#27AE60"
                        w="172px"
                        h="37px"
                        align="flex-start"
                        onClick={() => {
                          setHasDebt("Yes");
                        }}
                      >
                        <Text
                          color={"#FFFFFF"}
                          fontFamily={"Nunito"}
                          fontSize={"14px"}
                          fontStyle={"normal"}
                          fontWeight={"700"}
                          lineHeight={"normal"}
                        >
                          Add
                        </Text>
                      </Button>
                    </Flex>
                  )}
                  {hasDebt === "Yes" && !onCancel && (
                    <HasDebt
                      debtName={debtName}
                      setDebtName={setDebtName}
                      amountBorrowed={amountBorrowed}
                      setAmountBorrowed={setAmountBorrowed}
                      monthlyPayment={monthlyPayment}
                      setMonthlyPayment={setMonthlyPayment}
                      debtInterest={debtInterest}
                      setDebtInterest={setDebtInterest}
                      onCancel={handleOnCancel}
                      renderAddUpdate={renderAddUpdate}
                      debtArray={debtArray}
                      moneyToNumber={moneyToNumber}
                      errors={errors}
                      TooltipContent={TooltipContent}
                    />
                  )}
                </Box>
              </>
            }
            videoName={"Debt Page"}
            video={"DEBT.mp4"}
          />
        </VStack>
      )}
    </Flex>
  );
});

function HasDebt({
  debtName,
  setDebtName,
  amountBorrowed,
  setAmountBorrowed,
  monthlyPayment,
  setMonthlyPayment,
  debtInterest,
  setDebtInterest,
  onCancel,
  renderAddUpdate,
  debtArray,
  moneyToNumber,
  errors,
  TooltipContent,
}) {
  const [isLargerThan768] = useMediaQuery("(min-width: 500px)");
  const isMobile = !isLargerThan768;

  return (
    <>
      {isMobile && (
        <>
          <Box
            display={"flex"}
            justifyContent={"flex-end"}
            alignItems={"center"}
            alignSelf={"stretch"}
            w={"100%"}
          >
            <Button
              bg="#FFE5E5"
              w="73px"
              h="31px"
              align="flex-end"
              onClick={onCancel}
            >
              <Text
                color={"#E71D36"}
                fontFamily={"Nunito"}
                fontSize={"14px"}
                fontStyle={"normal"}
                fontWeight={"700"}
                lineHeight={"normal"}
              >
                Cancel
              </Text>
            </Button>
          </Box>
          <NormalInputButton
            Heading={"Name/Title"}
            value={debtName}
            onChange={(e) => {
              setDebtName(e.target.value);
            }}
            customWidth={"100%"}
            InputWidth={"100%"}
            error={errors.debtName}
            tooltipContent={TooltipContent.debtName}
          />
          <NormalInputButton
            Heading={"Debt Amount"}
            value={amountBorrowed}
            onChange={(e) => {
              setAmountBorrowed(moneyToNumber(e.target.value));
            }}
            placeholder={"$"}
            useThousandSeparator={true}
            customWidth={"100%"}
            InputWidth={"100%"}
            error={errors.amountBorrowed}
            tooltipContent={TooltipContent.debtBalance}
          />
          <NormalInputButton
            Heading={"Minimum Monthly Payment"}
            value={monthlyPayment}
            onChange={(e) => {
              setMonthlyPayment(moneyToNumber(e.target.value));
            }}
            placeholder={"$"}
            useThousandSeparator={true}
            customWidth={"100%"}
            InputWidth={"100%"}
            error={errors.monthlyPayment}
            tooltipContent={TooltipContent.debtMinimumPayment}
          />
          <NormalInputButton
            Heading={"Interest Rate"}
            value={debtInterest}
            onChange={(e) => {
              const noSymbol = e.target.value.replace("%", "");
              const growthNum = parseFloat(noSymbol);
              setDebtInterest(
                !isNaN(growthNum) ? parseFloat(growthNum.toFixed(2)) : ""
              );
            }}
            customWidth={"100%"}
            InputWidth={"100%"}
            placeholder={"%"}
            usePercentage={true}
            error={errors.debtInterest}
            tooltipContent={TooltipContent.debtInterest}
          />
          {renderAddUpdate(debtArray)}
        </>
      )}
      {!isMobile && (
        <>
          <Box
            display={"flex"}
            justifyContent={"flex-end"}
            alignItems={"center"}
            alignSelf={"stretch"}
            w={"100%"}
          >
            <Button
              bg="#FFE5E5"
              w="73px"
              h="31px"
              align="flex-end"
              onClick={onCancel}
            >
              <Text
                color={"#E71D36"}
                fontFamily={"Nunito"}
                fontSize={"14px"}
                fontStyle={"normal"}
                fontWeight={"700"}
                lineHeight={"normal"}
              >
                Cancel
              </Text>
            </Button>
          </Box>
          <NormalInputButton
            Heading={"Name/Title"}
            value={debtName}
            onChange={(e) => {
              setDebtName(e.target.value);
            }}
            customWidth={"100%"}
            InputWidth={"100%"}
            error={errors.debtName}
            tooltipContent={TooltipContent.debtName}
          />
          <NormalInputButton
            Heading={"Debt Amount"}
            value={amountBorrowed}
            onChange={(e) => {
              setAmountBorrowed(moneyToNumber(e.target.value));
            }}
            placeholder={"$"}
            useThousandSeparator={true}
            error={errors.amountBorrowed}
            tooltipContent={TooltipContent.debtBalance}
          />
          <NormalInputButton
            Heading={"Minimum Monthly Payment"}
            value={monthlyPayment}
            onChange={(e) => {
              setMonthlyPayment(moneyToNumber(e.target.value));
            }}
            placeholder={"$"}
            useThousandSeparator={true}
            error={errors.monthlyPayment}
            tooltipContent={TooltipContent.debtMinimumPayment}
          />
          <NormalInputButton
            Heading={"Interest Rate"}
            value={debtInterest}
            onChange={(e) => {
              const noSymbol = e.target.value.replace("%", "");
              const growthNum = parseFloat(noSymbol);
              setDebtInterest(
                !isNaN(growthNum) ? parseFloat(growthNum.toFixed(2)) : ""
              );
            }}
            customWidth={"100%"}
            placeholder={"%"}
            usePercentage={true}
            error={errors.debtInterest}
            tooltipContent={TooltipContent.debtInterest}
          />
          {renderAddUpdate(debtArray)}
        </>
      )}
    </>
  );
}

export default ExpenseDebtPage;
