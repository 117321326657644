import { useAuth0 } from "@auth0/auth0-react";
import {
  Box,
  Button,
  HStack,
  Icon,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Tab,
  TabList,
  Tabs,
  Text,
  VStack,
  useCallbackRef,
  useMediaQuery,
} from "@chakra-ui/react";
import axios from "axios";
import { Workbook } from "exceljs";
import { saveAs } from "file-saver";
import React, {
  useCallback,
  useContext,
  useEffect,
  useLayoutEffect,
  useState,
} from "react";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import CanvasJSReact from "../../canvasjs.react";
import Modal from "../../components/Modal";
import { ReactComponent as AnalysisArchiveLogo } from "../../img/AnalysisArchiveLogo.svg";
import CSVButton from "../../img/Dowload CSV Button.png";
import nextYearIcon from "../../img/Next Year Blue Button.png";
import pencilIcon from "../../img/pencilIcon.svg";
import { useStore } from "../../store";
import {
  AnalysisArchiveIcon,
  CSVDownloadIcon,
  ManageDashboardPenIcon,
  NextYearIcon,
  SaveAnalysisIcon,
} from "../CustomIcons";
import ErrorModal from "../ErrorModal";
import DropDownButton from "../InputFields/dropDownButton";
import SaveAnalysisModal from "../Save Analysis Modal";
import { SpouseContext } from "../SpouseContext";
import { StripeContext } from "../StripeContext";
import StripeUpgradeModal from "../StripeUpgradeModal";
import VideoModal from "../VideoModal/VideoModal";
import DashboardLargeCard from "./DashboardLargeCard";
import DashboardSmallCard from "./DashboardSmallCard";
import "./EndOfLifePageSpouse.css";
import LineGraph from "./LineGraph";
import { set } from "lodash";

var { CanvasJS } = CanvasJSReact;

function EndOfLifePageSpouse(props) {
  const { getAccessTokenSilently } = useAuth0();

  const axiosInstance = axios.create();
  async function getToken() {
    // Replace this with your Auth0 token retrieval logic
    const token = await getAccessTokenSilently({
      audience:
        "https://os2lsiwff43wfpb5ggyawt6qri0mgcro.lambda-url.us-east-2.on.aws/",
      scope: "write:optimization",
    });
    return token;
  }

  // Add a request interceptor
  axiosInstance.interceptors.request.use(
    async (config) => {
      const token = await getToken();
      config.headers.Authorization = `Bearer ${token}`;
      return config;
    },
    (error) => {
      // Do something with request error
      return Promise.reject(error);
    }
  );

  const {
    passedOptiList,
    passedSpouseOptiList,
    spouse1_ROT,
    spouse2_ROT,
    optiNoise,
  } = props;
  // const [sheet, setSheet] = useState(false);
  const [optiList, setOptiList] = useState(passedOptiList);
  const [spouse1ROT, setSpouse1ROT] = useState(spouse1_ROT);
  const [assetsList, setAssetsList] = useState(passedOptiList);
  const [spouseOptiList, setSpouseOptiList] = useState(passedSpouseOptiList);
  const [spouse2ROT, setSpouse2ROT] = useState(spouse2_ROT);
  const userInfo = useStore((store) => store.userInfo);
  const userExpensesInfo = useStore((store) => store.userExpensesInfo);
  const userPopupNotification = useStore(
    (store) => store.userPopupNotification
  );
  const userArchiveInfo = useStore((store) => store.userArchiveInfo);
  const setUserArchiveInfo = useStore((store) => store.setUserArchiveInfo);
  const [spousePrefix, setSpousePrefix] = useState("sps1");
  const [analysisName, setAnalysisName] = useState("");

  const [confirmSaved, setConfirmSaved] = useState("Confirm");

  const { spouseStatus, setSpouseStatus } = useContext(SpouseContext);
  const { stripePlan, setStripePlan } = useContext(StripeContext);

  const { user } = useAuth0();

  const [year, setYear] = useState(new Date().getFullYear());
  const [nextYear, setNextYear] = useState(year + 1);
  const [listPlace, setListPlace] = useState(0);
  const [spousePage, setSpousePage] = useState("joint-portfolio");
  const [ageStart, setAgeStart] = useState(false);
  const [ageEnd, setAgeEnd] = useState(true);
  // const [otherAssets, setOtherAssets] = useState(false);
  const [maxValue, setMaxValue] = useState(false);
  const [isDragDisabled, setIsDragDisabled] = useState(true);
  const [movingCards, setMovingCards] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [showStripeModal, setShowStripeModal] = useState(false);
  const userOptiData = useStore((store) => store.userOptiData);
  const [errorModal, setErrorModal] = useState(false);
  const [saveAnalysisModal, setSaveAnalysisModal] = useState(false);

  const [imageError, setImageError] = useState(false);
  const [spouseImageError, setSpouseImageError] = useState(false);

  const [showVideo, setShowVideo] = useState(false);

  function closeErrorModal() {
    setErrorModal(false);
    setErrorModalShown(true);
  }

  const calculateDaysLeft = (trialEndTimestamp) => {
    const currentDate = new Date();
    const trialEndDate = new Date(trialEndTimestamp * 1000); // Convert to milliseconds
    const differenceInTime = trialEndDate.getTime() - currentDate.getTime();
    const differenceInDays = Math.ceil(differenceInTime / (1000 * 3600 * 24));
    return differenceInDays;
  };

  function analysisNameFunc(name) {
    // setAnalysisName(name);
  }

  const [profilePicUrl, setProfilePicUrl] = useState("");
  const [profilePicUrlSpouse, setProfilePicUrlSpouse] = useState("");

  useEffect(() => {
    // Configure AWS SDK with environment variables
    AWS.config.update({
      accessKeyId: process.env.REACT_APP_PICTURE_ID,
      secretAccessKey: process.env.REACT_APP_PROFILE_SECRET,
      region: "us-east-2",
    });
    const s3 = new AWS.S3();
    const params = {
      Bucket: "optiml-user-pictures",
      Key: "profile-pics/" + user?.sub, // Unique key per user
      Expires: 3600, // Valid for 1 hour
    };

    const spouseParams = {
      Bucket: "optiml-user-pictures",
      Key: "profile-pics/" + user?.sub + "spouse", // Unique key per user
      Expires: 3600, // Valid for 1 hour
    };

    s3.getSignedUrl("getObject", params, (err, url) => {
      if (err) {
        console.error("Error generating pre-signed URL:", err);
      } else {
        setProfilePicUrl(url);
      }
    });

    s3.getSignedUrl("getObject", spouseParams, (err, url) => {
      if (err) {
        console.error("Error generating pre-signed URL:", err);
      } else {
        setProfilePicUrlSpouse(url);
      }
    });
  }, [profilePicUrl, profilePicUrlSpouse]);

  const [isVideoModalOpen, setIsVideoModalOpen] = useState(true);

  const closeVideoModal = () => {
    setIsVideoModalOpen(false);
    setErrorModalShown(false);
  };

  const dashboardLayout = useStore((store) => store.dashboardLayout);
  const setDashboardLayout = useStore((store) => store.setDashboardLayout);

  const setShowNewOptimization = useStore(
    (store) => store.setShowNewOptimization
  );
  setShowNewOptimization(false);

  const currentYear = new Date();

  function getAge(birthday, currentDate) {
    // Convert the birthday string into a Date object
    const birthDate = new Date(birthday);

    // Calculate the age in years
    let age = currentDate?.getFullYear() - birthDate?.getFullYear();

    // Decrement the age if the current month and day are before the birth month and day
    if (
      currentDate?.getMonth() < birthDate?.getMonth() ||
      (currentDate?.getMonth() === birthDate?.getMonth() &&
        currentDate?.getDate() < birthDate?.getDate())
    ) {
      age -= 1;
    }

    return age;
  }

  const currencyFormat = (money) => {
    var intTotal = Math.round(Math.round(money * 100) / 100);
    if (intTotal > -2 && intTotal < 0.25) {
      intTotal = 0;
    }
    // Math.round(intTotal);
    var currencyCad = new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD",
      minimumFractionDigits: 0,
    }).format(intTotal);
    return currencyCad;
  };

  const percentageWithdrawals = (total, withdraw) => {
    const percent = (withdraw / total) * 100;
    let x = Math.round(percent);
    if (x < 1 && x > 0) {
      x = 1;
    }
    return x;
  };

  const setPortfolioGraphData = (list) => {
    if (!list) {
      return []; // Return an empty array or some default value
    }
    const dataPoint = [];
    const finalAge = optiList?.[`${spousePrefix}_registeredFunds`]?.length;
    const updatedDate = new Date();
    for (let i = 0; i < finalAge; i++) {
      updatedDate?.setFullYear(currentYear?.getFullYear() + i);
      const testAge = getAge(
        optiList?.[`${spousePrefix}_birthday`],
        updatedDate
      );
      dataPoint?.push({
        x: updatedDate?.getFullYear(),
        y: Math.floor(list?.[i]),
        label: `${testAge?.toString()}   ${updatedDate
          ?.getFullYear()
          .toString()}`,
      });
      if (list[i] > -2 && list[i] < 0.25) {
        list[i] = 0;
      }
    }
    return dataPoint;
  };

  CanvasJS.addColorSet("oltreColoursSet1", [
    "#B3E5FC",
    "#4DD0E1",
    "#03A9F4",
    "#0288D1",
    "#01579B",
    "#303F9F",
    "#1A237E",
    "#525252",
  ]);

  const yearHeaders = (birthday) => {
    const Headers = [];
    // Headers[0] = '';
    const age = getAge(birthday, currentYear);
    for (let i = 0; i < assetsList?.expenses?.length; i++) {
      Headers[i] = (age + i).toString();
    }
    return Headers;
  };

  const mergeArrays = (array1, array2) => {
    const mergedArray = [];
    const maxLength = Math.max(array1?.length, array2?.length);
    for (let i = 0; i < maxLength; i++) {
      if (array1[i] !== undefined) mergedArray.push(array1[i]);
      if (array2[i] !== undefined) mergedArray.push(array2[i]);
    }
    return mergedArray;
  };

  const currencyFormatList = (money) => {
    const moneyArr = [];
    for (let i = 0; i < money?.length; i++) {
      var intTotal = Math.round(Math.round(money[i] * 100) / 100);
      if (intTotal > -2 && intTotal < 0.25) {
        intTotal = 0;
      }
      var currencyCad = new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "USD",
        minimumFractionDigits: 0,
      }).format(intTotal);
      moneyArr[i] = currencyCad;
    }
    return moneyArr;
  };

  const currencyFormatListShift = (money, growth) => {
    const moneyArr = [];

    // Create a new array without the first element
    const shiftedMoney = money?.slice(1);

    // Add the growth value to the last element and append to the array
    // const lastValue = shiftedMoney[shiftedMoney.length - 1];
    // shiftedMoney.push(lastValue + growth);

    for (let i = 0; i < shiftedMoney?.length; i++) {
      var intTotal = Math.round(Math.round(shiftedMoney[i] * 100) / 100);
      if (intTotal > -2 && intTotal < 0.25) {
        intTotal = 0;
      }
      var currencyCad = new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "USD",
        minimumFractionDigits: 0,
      }).format(intTotal);
      moneyArr[i] = currencyCad;
    }

    return moneyArr;
  };

  function safeAdd(...values) {
    return values.reduce((sum, val) => sum + (Number(val) || 0), 0);
  }

  const optiListToCSV = (optiList) => {
    var data = [
      ["Optiml"],
      ["Date of Analysis", formatDate(optiList?.planRunDate)],
      [
        "Spouse 1",
        optiList?.[`${spousePrefix}_name`] +
          " " +
          optiList?.[`${spousePrefix}_lastName`],
        optiList?.[`${spousePrefix}_birthday`]?.toString(),
      ]?.concat(yearHeaders(optiList?.[`${spousePrefix}_birthday`])),
      [""],
      ["Strategy", passedOptiList?.optimizationType],
      ["CPI", userExpensesInfo?.expenseCPI + "%"],
      [
        "End of Analysis Portfolio Balance",
        currencyFormat(optiList?.optimizedEOLAmount),
      ],
      [
        "End of Analysis Asset Balance",
        currencyFormat(
          optiList?.PrimaryResidence?.Value?.[
            optiList?.PrimaryResidence?.Value?.length - 1
          ] +
            optiList?.businessYearlyValue?.[
              optiList?.businessYearlyValue?.length - 1
            ] +
            optiList?.RecreationPropertyYearlyValue?.[
              optiList?.RecreationPropertyYearlyValue?.length - 1
            ] +
            optiList?.IncomePropertyYearlyValue?.[
              optiList?.IncomePropertyYearlyValue?.length - 1
            ] +
            optiList?.LandYearlyValue?.[optiList?.LandYearlyValue?.length - 1] +
            optiList?.otherAssetsYearlyValue?.[
              optiList?.otherAssetsYearlyValue?.length - 1
            ] +
            optiList?.lifeInsuranceYearlyValue?.[
              optiList?.lifeInsuranceYearlyValue?.length - 1
            ]
        ),
      ],
      [
        "Before Tax End of Analysis Total",
        currencyFormat(
          optiList?.optimizedEOLAmount +
            optiList?.PrimaryResidence?.Value?.[
              optiList?.PrimaryResidence?.Value?.length - 1
            ] +
            optiList?.businessYearlyValue?.[
              optiList?.businessYearlyValue?.length - 1
            ] +
            optiList?.RecreationPropertyYearlyValue?.[
              optiList?.RecreationPropertyYearlyValue?.length - 1
            ] +
            optiList?.LandYearlyValue?.[optiList?.LandYearlyValue?.length - 1] +
            optiList?.IncomePropertyYearlyValue?.[
              optiList?.IncomePropertyYearlyValue?.length - 1
            ] +
            optiList?.otherAssetsYearlyValue?.[
              optiList?.otherAssetsYearlyValue?.length - 1
            ] +
            optiList?.lifeInsuranceYearlyValue?.[
              optiList?.lifeInsuranceYearlyValue?.length - 1
            ]
        ),
      ],
      [
        "After Tax End of Analysis Balance",
        currencyFormat(
          optiList?.optimizedEOLAmountAfterTax +
            optiList?.PrimaryResidence?.Value?.[
              optiList?.PrimaryResidence?.Value?.length - 1
            ] +
            optiList?.businessYearlyValue?.[
              optiList?.businessYearlyValue?.length - 1
            ] +
            optiList?.RecreationPropertyYearlyValue?.[
              optiList?.RecreationPropertyYearlyValue?.length - 1
            ] +
            optiList?.LandYearlyValue?.[optiList?.LandYearlyValue?.length - 1] +
            optiList?.IncomePropertyYearlyValue?.[
              optiList?.IncomePropertyYearlyValue?.length - 1
            ] +
            optiList?.otherAssetsYearlyValue?.[
              optiList?.otherAssetsYearlyValue?.length - 1
            ] +
            optiList?.lifeInsuranceYearlyValue?.[
              optiList?.lifeInsuranceYearlyValue?.length - 1
            ]
        ),
      ],
      [""],

      ["Income"],
      ["Salary"].concat(
        currencyFormatList(optiList?.[`${spousePrefix}_salary`])
      ),
      ["CPP"].concat(currencyFormatList(optiList?.[`${spousePrefix}_cpp`])),
      ["OAS"].concat(currencyFormatList(optiList?.[`${spousePrefix}_oas`])),
      ["Pension"].concat(
        currencyFormatList(optiList?.[`${spousePrefix}_pension`]?.Benefits)
      ),
      ["Rental Income"].concat(
        currencyFormatList(optiList?.IncomePropertyYearlyIncome)
      ),
      ["Business Dividends"].concat(
        currencyFormatList(optiList?.businessYearlyDividends)
      ),
      ["Other Income"].concat(
        currencyFormatList(optiList?.[`${spousePrefix}_otherIncomeTotal`])
      ),
      [""],

      ["Investments"],
      ["Registered"],
      ["TFSA"].concat(
        currencyFormatList(optiList?.[`${spousePrefix}_tfsaFunds`])
      ),
      ["Deposits"].concat(
        currencyFormatList(optiList?.[`${spousePrefix}_tfsaContributions`])
      ),
      ["Withdrawals"].concat(
        currencyFormatList(optiList?.[`${spousePrefix}_tfsaWithdrawals`])
      ),
      ["Growth"].concat(
        currencyFormatList(optiList?.[`${spousePrefix}_tfsaGrowth`])
      ),
      ["Closing Balance"].concat(
        currencyFormatListShift(
          [...optiList?.[`${spousePrefix}_tfsaFunds`]],
          optiList?.[`${spousePrefix}_tfsaGrowth`][
            optiList?.[`${spousePrefix}_tfsaGrowth`]?.length - 1
          ]
        )
      ),
      [""],
      ["FHSA"].concat(
        currencyFormatList(optiList?.[`${spousePrefix}_fhsaFunds`])
      ),
      ["Deposits"].concat(
        currencyFormatList(optiList?.[`${spousePrefix}_fhsaContributions`])
      ),
      ["Withdrawals"].concat(
        currencyFormatList(optiList?.[`${spousePrefix}_fhsaWithdrawals`])
      ),
      ["Growth"].concat(
        currencyFormatList(optiList?.[`${spousePrefix}_fhsaGrowth`])
      ),
      ["Closing Balance"].concat(
        currencyFormatListShift(
          [...optiList?.[`${spousePrefix}_fhsaFunds`]],
          optiList?.[`${spousePrefix}_fhsaGrowth`][
            optiList?.[`${spousePrefix}_fhsaGrowth`]?.length - 1
          ]
        )
      ),
      [""],
      ["RRSP"].concat(
        currencyFormatList(optiList?.[`${spousePrefix}_registeredFunds`])
      ),
      ["Deposits"].concat(
        currencyFormatList(
          optiList?.[`${spousePrefix}_registeredContributions`]
        )
      ),
      ["Employer Contributions"].concat(
        currencyFormatList(
          optiList?.[`${spousePrefix}_registeredContributionsMatched`]
        )
      ),
      ["Salary Contributions"].concat(
        currencyFormatList(
          optiList?.[`${spousePrefix}_registeredContributionsMatched`]
        )
      ),
      ["Withdrawals"].concat(
        currencyFormatList(optiList?.[`${spousePrefix}_registeredWithdrawals`])
      ),
      ["Growth"].concat(
        currencyFormatList(optiList?.[`${spousePrefix}_registeredGrowth`])
      ),
      ["Closing Balance"].concat(
        currencyFormatListShift(
          [...optiList?.[`${spousePrefix}_registeredFunds`]],
          optiList?.[`${spousePrefix}_registeredGrowth`][
            optiList?.[`${spousePrefix}_registeredGrowth`]?.length - 1
          ]
        )
      ),
      [""],
      ["LIRA"].concat(
        currencyFormatList(optiList?.[`${spousePrefix}_liraFunds`])
      ),
      ["Withdrawals"].concat(
        currencyFormatList(optiList?.[`${spousePrefix}_liraWithdrawals`])
      ),
      ["Growth"].concat(
        currencyFormatList(optiList?.[`${spousePrefix}_liraGrowth`])
      ),
      ["Closing Balance"].concat(
        currencyFormatListShift(
          [...optiList?.[`${spousePrefix}_liraFunds`]],
          optiList?.[`${spousePrefix}_liraGrowth`][
            optiList?.[`${spousePrefix}_liraGrowth`]?.length - 1
          ]
        )
      ),
      [""],
      ["Defined Contribution Pension"].concat(
        currencyFormatList(optiList?.[`${spousePrefix}_pension`]?.Value)
      ),
      ["Self Contributions"].concat(
        currencyFormatList(
          optiList?.[`${spousePrefix}_pension`]?.SelfContributions
        )
      ),
      ["Employer Contributions"].concat(
        currencyFormatList(
          optiList?.[`${spousePrefix}_pension`]?.EmployerContributions
        )
      ),
      ["Growth"].concat(
        currencyFormatList(optiList?.[`${spousePrefix}_pension`]?.Growth)
      ),
      ["Closing Balance"],
      [""],
      ["Non-Registered"].concat(
        currencyFormatList(optiList?.[`${spousePrefix}_nonregisteredFunds`])
      ),
      ["Adjusted Cost Base"].concat(
        currencyFormatList(optiList?.[`${spousePrefix}_acb`])
      ),
      ["Deposits"].concat(
        currencyFormatList(
          optiList?.[`${spousePrefix}_nonRegisteredContributions`]
        )
      ),
      ["Dividends"].concat(
        currencyFormatList(optiList?.[`${spousePrefix}_dividends`])
      ),
      ["Withdrawals"].concat(
        currencyFormatList(
          optiList?.[`${spousePrefix}_nonregisteredWithdrawals`]
        )
      ),
      ["Growth"].concat(
        currencyFormatList(optiList?.[`${spousePrefix}_nonregisteredGrowth`])
      ),
      ["Closing Balance"].concat(
        currencyFormatListShift(
          [...optiList?.[`${spousePrefix}_nonregisteredFunds`]],
          optiList?.[`${spousePrefix}_nonregisteredGrowth`][
            optiList?.[`${spousePrefix}_nonregisteredGrowth`]?.length - 1
          ]
        )
      ),
      ["Fixed Income"].concat(
        currencyFormatList(optiList?.[`${spousePrefix}_fixedIncomeTotal`])
      ),
      ["Withdrawals"].concat(
        currencyFormatList(optiList?.[`${spousePrefix}_fixedIncomeWithdrawals`])
      ),
      ["Growth"].concat(
        currencyFormatList(optiList?.[`${spousePrefix}_fixedIncomeGrowth`])
      ),
      [""],
      ["Total Investment Balance"].concat(
        currencyFormatList(
          optiList?.[`${spousePrefix}_tfsaFunds`]?.map(
            (e, i) =>
              e +
              optiList?.[`${spousePrefix}_registeredFunds`]?.[i] +
              optiList?.[`${spousePrefix}_fixedIncomeTotal`]?.[i] +
              optiList?.[`${spousePrefix}_fhsaFunds`]?.[i] +
              optiList?.[`${spousePrefix}_nonregisteredFunds`]?.[i] +
              optiList?.[`${spousePrefix}_liraFunds`]?.[i] +
              optiList?.[`${spousePrefix}_pension`]?.Value?.[i]
          )
        )
      ),
      [""],

      ["Assets"],
      ["Real Estate"],
      ["Primary Residence"].concat(
        currencyFormatList(optiList?.PrimaryResidence?.Value)
      ),
      ["  Growth"].concat(
        currencyFormatList(optiList?.PrimaryResidence?.Growth)
      ),
      ["  Closing Balance"].concat(
        currencyFormatListShift(
          [...optiList?.PrimaryResidence?.Value],
          optiList?.PrimaryResidence?.Growth[
            optiList?.PrimaryResidence?.Growth?.length - 1
          ]
        )
      ),
      ["Recreational Property"].concat(
        currencyFormatList(optiList?.RecreationPropertyYearlyValue)
      ),
      ["  Growth"].concat(
        currencyFormatList(optiList?.RecreationPropertyYearlyGrowth)
      ),
      ["  Closing Balance"].concat(
        currencyFormatListShift(
          [...optiList?.RecreationPropertyYearlyValue],
          optiList?.RecreationPropertyYearlyGrowth[
            optiList?.RecreationPropertyYearlyGrowth?.length - 1
          ]
        )
      ),
      ["Land"].concat(currencyFormatList(optiList?.LandYearlyValue)),
      ["  Growth"].concat(currencyFormatList(optiList?.LandYearlyGrowth)),
      ["  Closing Balance"].concat(
        currencyFormatListShift(
          [...optiList?.LandYearlyValue],
          optiList?.LandYearlyGrowth[optiList?.LandYearlyGrowth?.length - 1]
        )
      ),
      ["Income Property(s)"].concat(
        currencyFormatList(optiList?.IncomePropertyYearlyValue)
      ),
      ["  Growth"].concat(
        currencyFormatList(optiList?.IncomePropertyYearlyGrowth)
      ),
      ["  Closing Balance"].concat(
        currencyFormatListShift(
          [...optiList?.IncomePropertyYearlyValue],
          optiList?.IncomePropertyYearlyGrowth[
            optiList?.IncomePropertyYearlyGrowth?.length - 1
          ]
        )
      ),
      [""],
      ["Life Insurance"].concat(
        currencyFormatList(optiList?.lifeInsuranceYearlyValue)
      ),
      [""],
      ["Business"].concat(currencyFormatList(optiList?.businessYearlyValue)),
      ["Growth"].concat(currencyFormatList(optiList?.businessYearlyGrowth)),
      ["Business Closing Value"].concat(
        currencyFormatListShift([...optiList?.businessYearlyValue])
      ),
      ["CCPC Investments Fund"].concat(
        currencyFormatList(optiList?.ccpcYearlyBalance)
      ),
      ["Growth"].concat(
        currencyFormatList(optiList?.ccpcYearlyInvestmentGrowth)
      ),
      ["Re-Invested Funds From Operations"].concat(
        currencyFormatList(optiList?.ccpcYearlyOperationFunds)
      ),
      ["Issued Capital Dividends"].concat(
        currencyFormatList(optiList?.ccpcYearlyCapitalDividendsIssued)
      ),
      ["Issued Eligible Dividends"].concat(
        currencyFormatList(optiList?.ccpcYearlyEligibleDividendsIssued)
      ),
      ["Issued Non-Eligible Dividends"].concat(
        currencyFormatList(optiList?.ccpcYearlyNonEligibleDividendsIssued)
      ),
      ["Earned Eligible Dividends"].concat(
        currencyFormatList(optiList?.ccpcYearlyEligibleDividendsEarned)
      ),
      ["Earned Non-Eligible Dividends"].concat(
        currencyFormatList(optiList?.ccpcYearlyNonEligibleDividendsEarned)
      ),
      ["Earned Other Dividends"].concat(
        currencyFormatList(optiList?.ccpcYearlyOtherDividendsEarned)
      ),
      ["Eligible Dividend Refund"].concat(
        currencyFormatList(optiList?.ccpcYearlyEligibleDividendRefund)
      ),
      ["Non-Eligible Dividend Refund"].concat(
        currencyFormatList(optiList?.ccpcYearlyNonEligibleDividendRefund)
      ),
      ["CCPC Taxes Paid within Holding Company"].concat(
        currencyFormatList(optiList?.ccpcYearlyTaxesPaid)
      ),
      ["CCPC Investment Fund ACB"].concat(
        currencyFormatList(optiList?.ccpcYearlyACB)
      ),
      ["NERDTOH Tracking Accounts"].concat(
        currencyFormatList(optiList?.ccpcYearlyNERDTOH)
      ),
      ["ERDTOH Tracking Accounts"].concat(
        currencyFormatList(optiList?.ccpcYearlyERDTOH)
      ),
      ["CDA Tracking Accounts"].concat(
        currencyFormatList(optiList?.ccpcYearlyCDA)
      ),
      ["GRIP Tracking Accounts"].concat(
        currencyFormatList(optiList?.ccpcYearlyGRIP)
      ),
      ["CCPC Investment Fund Loans Repaid"].concat(
        currencyFormatList(optiList?.ccpcYearlyShareholderLoanRepayments)
      ),
      ["CCPC Investment Loan Balance"].concat(
        currencyFormatList(optiList?.ccpcYearlyShareholderLoanBalance)
      ),
      [""],
      ["Personal Assets"],
      ["Value"].concat(currencyFormatList(optiList?.otherAssetsYearlyValue)),
      ["Growth"].concat(currencyFormatList(optiList?.otherAssetsYearlyGrowth)),
      ["Closing Balance"].concat(
        currencyFormatListShift(
          [...optiList?.otherAssetsYearlyValue],
          optiList?.otherAssetsYearlyGrowth?.[
            optiList?.otherAssetsYearlyGrowth?.length - 1
          ]
        )
      ),
      [""],
      ["Expenses"],
      ["Mortgage(s)"].concat(
        currencyFormatList(
          optiList?.PrimaryResidence?.Payments?.map(
            (e, i) =>
              e +
              optiList?.RecreationPropertyYearlyPayments?.[i] +
              optiList?.LandYearlyPayments?.[i] +
              optiList?.IncomePropertyYearlyPayments?.[i]
          )
        )
      ),
      ["Life Insurance Premiums"].concat(
        currencyFormatList(optiList?.lifeInsuranceYearlyPayments)
      ),
      ["Annual Debt Payments"].concat(
        currencyFormatList(optiList?.totalDebtPayments)
      ),
      ["Living Expenses"].concat(currencyFormatList(optiList?.expenses)),
      ["Extra Spending"].concat(currencyFormatList(optiList?.extraSpending)),
      ["Total Expenses"].concat(
        currencyFormatList(
          optiList?.expenses?.map(
            (e, i) =>
              e +
              optiList?.PrimaryResidence?.Payments?.[i] +
              optiList?.RecreationPropertyYearlyPayments?.[i] +
              optiList?.LandYearlyPayments?.[i] +
              optiList?.IncomePropertyYearlyPayments?.[i] +
              optiList?.totalDebtPayments?.[i] +
              optiList?.extraSpending?.[i] +
              optiList?.lifeInsuranceYearlyPayments?.[i]
          )
        )
      ),
      [""],
      ["Total Debt"].concat(
        currencyFormatList(
          optiList?.PrimaryResidence?.Balance?.map(
            (e, i) =>
              e +
              optiList?.RecreationPropertyYearlyBalance?.[i] +
              optiList?.LandYearlyBalance?.[i] +
              optiList?.IncomePropertyYearlyBalance?.[i] +
              optiList?.totalDebtProrated?.[i]
          )
        )
      ),
      [""],
      ["Taxes"],
      ["Provincial Taxes"].concat(
        currencyFormatList(optiList?.[`${spousePrefix}_provincialTaxes`])
      ),
      ["Federal Taxes"].concat(
        currencyFormatList(optiList?.[`${spousePrefix}_federalTaxes`])
      ),
      ["EI Premiums"].concat(
        currencyFormatList(optiList?.[`${spousePrefix}_eiPremiums`])
      ),
      ["CPP Contributions"].concat(
        currencyFormatList(optiList?.[`${spousePrefix}_cppContributions`])
      ),
      ["Deductions"].concat(
        currencyFormatList(optiList?.[`${spousePrefix}_salaryDeductions`])
      ),
      ["Total Taxes"].concat(
        currencyFormatList(
          optiList?.[`${spousePrefix}_incomeTaxes`]?.map(
            (e, i) =>
              e +
              optiList?.[`${spousePrefix}_eiPremiums`]?.[i] +
              optiList?.[`${spousePrefix}_cppContributions`]?.[i]
          )
        )
      ),
      [""],
    ];
    return data;
  };

  const optiListToCSVSpouse = (optiList) => {
    var data = [
      ["Optiml"],
      ["Date of Analysis", formatDate(optiList?.planRunDate)],
      [
        "Spouse 1",
        optiList?.[`${spousePrefix}_name`] +
          " " +
          optiList?.[`${spousePrefix}_lastName`],
        optiList?.[`${spousePrefix}_birthday`]?.toString(),
      ]?.concat(yearHeaders(optiList?.[`${spousePrefix}_birthday`])),
      [
        "Spouse 2",
        spouseOptiList?.sps2_name + " " + spouseOptiList?.sps2_lastName,
        spouseOptiList?.sps2_birthday?.toString(),
      ]?.concat(yearHeaders(spouseOptiList?.sps2_birthday)),
      ["Strategy", passedOptiList?.optimizationType],
      ["CPI", userExpensesInfo?.expenseCPI + "%"],
      [
        "End of Analysis Portfolio Balance",
        currencyFormat(optiList?.optimizedEOLAmount),
      ],
      [
        "End of Analysis Asset Balance",
        currencyFormat(
          optiList?.PrimaryResidence?.Value?.[
            optiList?.PrimaryResidence?.Value?.length - 1
          ] +
            optiList?.RecreationPropertyYearlyValue?.[
              optiList?.RecreationPropertyYearlyValue?.length - 1
            ] +
            optiList?.LandYearlyValue?.[optiList?.LandYearlyValue?.length - 1] +
            optiList?.businessYearlyValue?.[
              optiList?.businessYearlyValue?.length - 1
            ] +
            optiList?.IncomePropertyYearlyValue?.[
              optiList?.IncomePropertyYearlyValue?.length - 1
            ] +
            optiList?.otherAssetsYearlyValue?.[
              optiList?.otherAssetsYearlyValue?.length - 1
            ] +
            optiList?.lifeInsuranceYearlyValue?.[
              optiList?.lifeInsuranceYearlyValue?.length - 1
            ]
        ),
      ],
      [
        "Before Tax End of Analysis Total",
        currencyFormat(
          optiList?.optimizedEOLAmount +
            optiList?.PrimaryResidence?.Value?.[
              optiList?.PrimaryResidence?.Value?.length - 1
            ] +
            optiList?.RecreationPropertyYearlyValue?.[
              optiList?.RecreationPropertyYearlyValue?.length - 1
            ] +
            optiList?.LandYearlyValue?.[optiList?.LandYearlyValue?.length - 1] +
            optiList?.businessYearlyValue?.[
              optiList?.businessYearlyValue?.length - 1
            ] +
            optiList?.IncomePropertyYearlyValue?.[
              optiList?.IncomePropertyYearlyValue?.length - 1
            ] +
            optiList?.otherAssetsYearlyValue?.[
              optiList?.otherAssetsYearlyValue?.length - 1
            ] +
            optiList?.lifeInsuranceYearlyValue?.[
              optiList?.lifeInsuranceYearlyValue?.length - 1
            ]
        ),
      ],
      [
        "After Tax End of Analysis Balance",
        currencyFormat(
          optiList?.optimizedEOLAmountAfterTax +
            optiList?.PrimaryResidence?.Value?.[
              optiList?.PrimaryResidence?.Value?.length - 1
            ] +
            optiList?.RecreationPropertyYearlyValue?.[
              optiList?.RecreationPropertyYearlyValue?.length - 1
            ] +
            optiList?.LandYearlyValue?.[optiList?.LandYearlyValue?.length - 1] +
            optiList?.businessYearlyValue?.[
              optiList?.businessYearlyValue?.length - 1
            ] +
            optiList?.IncomePropertyYearlyValue?.[
              optiList?.IncomePropertyYearlyValue?.length - 1
            ] +
            optiList?.otherAssetsYearlyValue?.[
              optiList?.otherAssetsYearlyValue?.length - 1
            ] +
            optiList?.lifeInsuranceYearlyValue?.[
              optiList?.lifeInsuranceYearlyValue?.length - 1
            ]
        ),
      ],
      [""],

      [optiList?.[`${spousePrefix}_name`] + " " + "Income"],
      ["Salary"].concat(
        currencyFormatList(optiList?.[`${spousePrefix}_salary`])
      ),
      ["CPP"].concat(currencyFormatList(optiList?.[`${spousePrefix}_cpp`])),
      ["OAS"].concat(currencyFormatList(optiList?.[`${spousePrefix}_oas`])),
      ["Pension"].concat(
        currencyFormatList(optiList?.[`${spousePrefix}_pension`]?.Benefits)
      ),
      ["Rental Income"].concat(
        currencyFormatList(optiList?.IncomePropertyYearlyIncome)
      ),
      ["Business Dividends"].concat(
        currencyFormatList(optiList?.businessYearlyDividends)
      ),
      ["Other Income"].concat(
        currencyFormatList(optiList?.[`${spousePrefix}_otherIncomeTotal`])
      ),
      [""],
      [spouseOptiList?.sps2_name + " " + "Income"],
      ["Salary"].concat(currencyFormatList(spouseOptiList?.sps2_salary)),
      ["CPP"].concat(currencyFormatList(spouseOptiList?.sps2_cpp)),
      ["OAS"].concat(currencyFormatList(spouseOptiList?.sps2_oas)),
      ["Pension"].concat(
        currencyFormatList(spouseOptiList?.sps2_pension?.Benefits)
      ),
      ["Other"].concat(
        currencyFormatList(spouseOptiList?.sps2_otherIncomeTotal)
      ),
      [""],
      ["Combined Income"].concat(
        currencyFormatList(
          optiList?.[`${spousePrefix}_salary`]?.map(
            (e, i) =>
              e +
              optiList?.[`${spousePrefix}_cpp`]?.[i] +
              optiList?.[`${spousePrefix}_oas`]?.[i] +
              optiList?.[`${spousePrefix}_pension`]?.Benefits?.[i] +
              optiList?.[`${spousePrefix}_otherIncomeTotal`]?.[i] +
              spouseOptiList?.sps2_salary?.[i] +
              spouseOptiList?.sps2_cpp?.[i] +
              spouseOptiList?.sps2_oas?.[i] +
              spouseOptiList?.sps2_pension?.Benefits?.[i] +
              spouseOptiList?.sps2_otherIncomeTotal?.[i] +
              optiList?.IncomePropertyYearlyIncome[i] +
              optiList?.businessYearlyDividends[i]
          )
        )
      ),
      [""],

      [optiList?.[`${spousePrefix}_name`] + " " + "Investments"],
      ["Registered"],
      ["TFSA"].concat(
        currencyFormatList(optiList?.[`${spousePrefix}_tfsaFunds`])
      ),
      ["Deposits"].concat(
        currencyFormatList(optiList?.[`${spousePrefix}_tfsaContributions`])
      ),
      ["Withdrawals"].concat(
        currencyFormatList(optiList?.[`${spousePrefix}_tfsaWithdrawals`])
      ),
      ["Growth"].concat(
        currencyFormatList(optiList?.[`${spousePrefix}_tfsaGrowth`])
      ),
      ["Closing Balance"].concat(
        currencyFormatListShift(
          [...optiList?.[`${spousePrefix}_tfsaFunds`]],
          optiList?.[`${spousePrefix}_tfsaGrowth`][
            optiList?.[`${spousePrefix}_tfsaGrowth`]?.length - 1
          ]
        )
      ),
      [""],
      ["RRSP"].concat(
        currencyFormatList(optiList?.[`${spousePrefix}_registeredFunds`])
      ),
      ["Deposits"].concat(
        currencyFormatList(
          optiList?.[`${spousePrefix}_registeredContributions`]
        )
      ),
      ["Employer Contributions"].concat(
        currencyFormatList(
          optiList?.[`${spousePrefix}_registeredContributionsMatched`]
        )
      ),
      ["Salary Contributions"].concat(
        currencyFormatList(
          optiList?.[`${spousePrefix}_registeredContributionsMatched`]
        )
      ),
      ["Withdrawals"].concat(
        currencyFormatList(optiList?.[`${spousePrefix}_registeredWithdrawals`])
      ),
      ["Growth"].concat(
        currencyFormatList(optiList?.[`${spousePrefix}_registeredGrowth`])
      ),
      ["Closing Balance"].concat(
        currencyFormatListShift(
          [...optiList?.[`${spousePrefix}_registeredFunds`]],
          optiList?.[`${spousePrefix}_registeredGrowth`][
            optiList?.[`${spousePrefix}_registeredGrowth`]?.length - 1
          ]
        )
      ),
      [""],
      ["LIRA"].concat(
        currencyFormatList(optiList?.[`${spousePrefix}_liraFunds`])
      ),
      ["Withdrawals"].concat(
        currencyFormatList(optiList?.[`${spousePrefix}_liraWithdrawals`])
      ),
      ["Growth"].concat(
        currencyFormatList(optiList?.[`${spousePrefix}_liraGrowth`])
      ),
      ["Closing Balance"].concat(
        currencyFormatListShift(
          [...optiList?.[`${spousePrefix}_liraFunds`]],
          optiList?.[`${spousePrefix}_liraGrowth`][
            optiList?.[`${spousePrefix}_liraGrowth`]?.length - 1
          ]
        )
      ),
      [""],
      ["Defined Contribution Pension"].concat(
        currencyFormatList(optiList?.[`${spousePrefix}_pension`]?.Value)
      ),
      ["Self Contributions"].concat(
        currencyFormatList(
          optiList?.[`${spousePrefix}_pension`]?.SelfContributions
        )
      ),
      ["Employer Contributions"].concat(
        currencyFormatList(
          optiList?.[`${spousePrefix}_pension`]?.EmployerContributions
        )
      ),
      ["Growth"].concat(
        currencyFormatList(optiList?.[`${spousePrefix}_pension`]?.Growth)
      ),
      ["Closing Balance"],
      [""],

      ["Non-Registered"].concat(
        currencyFormatList(optiList?.[`${spousePrefix}_nonregisteredFunds`])
      ),
      ["Adjusted Cost Base"].concat(
        currencyFormatList(optiList?.[`${spousePrefix}_acb`])
      ),
      ["Deposits"].concat(
        currencyFormatList(
          optiList?.[`${spousePrefix}_nonRegisteredContributions`]
        )
      ),
      ["Dividends"].concat(
        currencyFormatList(optiList?.[`${spousePrefix}_dividends`])
      ),
      ["Withdrawals"].concat(
        currencyFormatList(
          optiList?.[`${spousePrefix}_nonregisteredWithdrawals`]
        )
      ),
      ["Growth"].concat(
        currencyFormatList(optiList?.[`${spousePrefix}_nonregisteredGrowth`])
      ),
      ["Closing Balance"].concat(
        currencyFormatListShift(
          [...optiList?.[`${spousePrefix}_nonregisteredFunds`]],
          optiList?.[`${spousePrefix}_nonregisteredGrowth`][
            optiList?.[`${spousePrefix}_nonregisteredGrowth`]?.length - 1
          ]
        )
      ),
      ["Fixed Income"].concat(
        currencyFormatList(optiList?.[`${spousePrefix}_fixedIncomeTotal`])
      ),
      ["Withdrawals"].concat(
        currencyFormatList(optiList?.[`${spousePrefix}_fixedIncomeWithdrawals`])
      ),
      ["Growth"].concat(
        currencyFormatList(optiList?.[`${spousePrefix}_fixedIncomeGrowth`])
      ),
      [""],
      ["Total Investment Balance"].concat(
        currencyFormatList(
          optiList?.[`${spousePrefix}_tfsaFunds`]?.map(
            (e, i) =>
              e +
              optiList?.[`${spousePrefix}_registeredFunds`]?.[i] +
              optiList?.[`${spousePrefix}_fixedIncomeTotal`]?.[i] +
              optiList?.[`${spousePrefix}_nonregisteredFunds`]?.[i] +
              optiList?.[`${spousePrefix}_liraFunds`]?.[i] +
              optiList?.[`${spousePrefix}_pension`]?.Value?.[i]
          )
        )
      ),
      [""],
      [spouseOptiList?.sps2_name + " " + "Investments"],
      ["Registered"],
      ["TFSA"].concat(currencyFormatList(spouseOptiList?.sps2_tfsaFunds)),
      ["Deposits"].concat(
        currencyFormatList(spouseOptiList?.sps2_tfsaContributions)
      ),
      ["Withdrawals"].concat(
        currencyFormatList(spouseOptiList?.sps2_tfsaWithdrawals)
      ),
      ["Growth"].concat(currencyFormatList(spouseOptiList?.sps2_tfsaGrowth)),
      ["Closing Balance"].concat(
        currencyFormatListShift(
          [...spouseOptiList?.sps2_tfsaFunds],
          spouseOptiList?.sps2_tfsaGrowth[
            spouseOptiList?.sps2_tfsaGrowth?.length - 1
          ]
        )
      ),
      [""],
      ["RRSP"].concat(currencyFormatList(spouseOptiList?.sps2_registeredFunds)),
      ["Deposits"].concat(
        currencyFormatList(spouseOptiList?.sps2_registeredContributions)
      ),
      ["Employer Contributions"].concat(
        currencyFormatList(spouseOptiList?.sps2_registeredContributionsMatched)
      ),
      ["Salary Contributions"].concat(
        currencyFormatList(spouseOptiList?.sps2_registeredContributionsMatched)
      ),
      ["Withdrawals"].concat(
        currencyFormatList(spouseOptiList?.sps2_registeredWithdrawals)
      ),
      ["Growth"].concat(
        currencyFormatList(spouseOptiList?.sps2_registeredGrowth)
      ),
      ["Closing Balance"].concat(
        currencyFormatListShift(
          [...spouseOptiList?.sps2_registeredFunds],
          spouseOptiList?.sps2_registeredGrowth[
            spouseOptiList?.sps2_registeredGrowth?.length - 1
          ]
        )
      ),
      [""],
      ["LIRA"].concat(currencyFormatList(spouseOptiList?.sps2_liraFunds)),
      ["Withdrawals"].concat(
        currencyFormatList(spouseOptiList?.sps2_liraWithdrawals)
      ),
      ["Growth"].concat(currencyFormatList(spouseOptiList?.sps2_liraGrowth)),
      ["Closing Balance"].concat(
        currencyFormatListShift(
          [...spouseOptiList?.sps2_liraFunds],
          spouseOptiList?.sps2_liraGrowth[
            spouseOptiList?.sps2_liraGrowth?.length - 1
          ]
        )
      ),
      [""],
      ["Defined Contribution Pension"].concat(
        currencyFormatList(spouseOptiList?.sps2_pension?.Value)
      ),
      ["Self Contributions"].concat(
        currencyFormatList(spouseOptiList?.sps2_pension?.SelfContributions)
      ),
      ["Employer Contributions"].concat(
        currencyFormatList(spouseOptiList?.sps2_pension?.EmployerContributions)
      ),
      ["Growth"].concat(
        currencyFormatList(spouseOptiList?.sps2_pension?.Growth)
      ),
      ["Closing Balance"],
      [""],

      ["Non-Registered"].concat(
        currencyFormatList(spouseOptiList?.sps2_nonregisteredFunds)
      ),
      ["Adjusted Cost Base"].concat(
        currencyFormatList(spouseOptiList?.sps2_acb)
      ),
      ["Deposits"].concat(
        currencyFormatList(spouseOptiList?.sps2_nonRegisteredContributions)
      ),
      ["Dividends"].concat(currencyFormatList(spouseOptiList?.sps2_dividends)),
      ["Withdrawals"].concat(
        currencyFormatList(spouseOptiList?.sps2_nonregisteredWithdrawals)
      ),
      ["Growth"].concat(
        currencyFormatList(spouseOptiList?.sps2_nonregisteredGrowth)
      ),
      ["Closing Balance"].concat(
        currencyFormatListShift(
          [...spouseOptiList?.sps2_nonregisteredFunds],
          spouseOptiList?.sps2_nonregisteredGrowth?.[
            spouseOptiList?.sps2_nonregisteredGrowth?.length - 1
          ]
        )
      ),
      ["Fixed Income"].concat(
        currencyFormatList(spouseOptiList?.sps2_fixedIncomeTotal)
      ),
      ["Withdrawals"].concat(
        currencyFormatList(spouseOptiList?.sps2_fixedIncomeWithdrawals)
      ),
      ["Growth"].concat(
        currencyFormatList(spouseOptiList?.sps2_fixedIncomeGrowth)
      ),
      [""],
      ["Total Investment Balance"].concat(
        currencyFormatList(
          spouseOptiList?.sps2_registeredFunds?.map(
            (e, i) =>
              e +
              spouseOptiList?.sps2_nonregisteredFunds?.[i] +
              spouseOptiList?.sps2_fixedIncomeTotal?.[i] +
              spouseOptiList?.sps2_tfsaFunds?.[i] +
              spouseOptiList?.sps2_liraFunds?.[i] +
              spouseOptiList?.sps2_pension?.Value?.[i]
          )
        )
      ),
      [""],
      ["Combined Investment Balance"].concat(
        currencyFormatList(
          optiList?.[`${spousePrefix}_registeredFunds`]?.map(
            (e, i) =>
              e +
              optiList?.[`${spousePrefix}_nonregisteredFunds`]?.[i] +
              optiList?.[`${spousePrefix}_fixedIncomeTotal`]?.[i] +
              optiList?.[`${spousePrefix}_tfsaFunds`]?.[i] +
              optiList?.[`${spousePrefix}_liraFunds`]?.[i] +
              optiList?.[`${spousePrefix}_pension`]?.Value?.[i] +
              spouseOptiList?.sps2_nonregisteredFunds?.[i] +
              spouseOptiList?.sps2_tfsaFunds?.[i] +
              spouseOptiList?.sps2_fixedIncomeTotal?.[i] +
              spouseOptiList?.sps2_registeredFunds?.[i] +
              spouseOptiList?.sps2_liraFunds?.[i] +
              spouseOptiList?.sps2_pension?.Value?.[i]
          )
        )
      ),
      [""],

      ["Assets"],
      ["Real Estate"],
      ["Primary Residence"].concat(
        currencyFormatList(optiList?.PrimaryResidence?.Value)
      ),
      ["  Growth"].concat(
        currencyFormatList(optiList?.PrimaryResidence?.Growth)
      ),
      ["  Closing Balance"].concat(
        currencyFormatListShift(
          [...optiList?.PrimaryResidence?.Value],
          optiList?.PrimaryResidence?.Growth[
            optiList?.PrimaryResidence?.Growth?.length - 1
          ]
        )
      ),
      ["Recreational Property"].concat(
        currencyFormatList(optiList?.RecreationPropertyYearlyValue)
      ),
      ["  Growth"].concat(
        currencyFormatList(optiList?.RecreationPropertyYearlyGrowth)
      ),
      ["  Closing Balance"].concat(
        currencyFormatListShift(
          [...optiList?.RecreationPropertyYearlyValue],
          optiList?.RecreationPropertyYearlyGrowth[
            optiList?.RecreationPropertyYearlyGrowth?.length - 1
          ]
        )
      ),
      ["Land"].concat(currencyFormatList(optiList?.LandYearlyValue)),
      ["  Growth"].concat(currencyFormatList(optiList?.LandYearlyGrowth)),
      ["  Closing Balance"].concat(
        currencyFormatListShift(
          [...optiList?.LandYearlyValue],
          optiList?.LandYearlyGrowth[optiList?.LandYearlyGrowth?.length - 1]
        )
      ),
      ["Income Property(s)"].concat(
        currencyFormatList(optiList?.IncomePropertyYearlyValue)
      ),
      ["  Growth"].concat(
        currencyFormatList(optiList?.IncomePropertyYearlyGrowth)
      ),
      ["  Closing Balance"].concat(
        currencyFormatListShift(
          [...optiList?.IncomePropertyYearlyValue],
          optiList?.IncomePropertyYearlyGrowth[
            optiList?.IncomePropertyYearlyGrowth?.length - 1
          ]
        )
      ),
      [""],
      ["Life Insurance"].concat(
        currencyFormatList(optiList?.lifeInsuranceYearlyValue)
      ),
      [""],
      ["Business"].concat(currencyFormatList(optiList?.businessYearlyValue)),
      ["Growth"].concat(currencyFormatList(optiList?.businessYearlyGrowth)),
      ["Business Closing Value"].concat(
        currencyFormatListShift([...optiList?.businessYearlyValue])
      ),
      ["CCPC Investments Fund"].concat(
        currencyFormatList(optiList?.ccpcYearlyBalance)
      ),
      ["Growth"].concat(
        currencyFormatList(optiList?.ccpcYearlyInvestmentGrowth)
      ),
      ["Re-Invested Funds From Operations"].concat(
        currencyFormatList(optiList?.ccpcYearlyOperationFunds)
      ),
      ["Issued Capital Dividends"].concat(
        currencyFormatList(optiList?.ccpcYearlyCapitalDividendsIssued)
      ),
      ["Issued Eligible Dividends"].concat(
        currencyFormatList(optiList?.ccpcYearlyEligibleDividendsIssued)
      ),
      ["Issued Non-Eligible Dividends"].concat(
        currencyFormatList(optiList?.ccpcYearlyNonEligibleDividendsIssued)
      ),
      ["Earned Eligible Dividends"].concat(
        currencyFormatList(optiList?.ccpcYearlyEligibleDividendsEarned)
      ),
      ["Earned Non-Eligible Dividends"].concat(
        currencyFormatList(optiList?.ccpcYearlyNonEligibleDividendsEarned)
      ),
      ["Earned Other Dividends"].concat(
        currencyFormatList(optiList?.ccpcYearlyOtherDividendsEarned)
      ),
      ["Eligible Dividend Refund"].concat(
        currencyFormatList(optiList?.ccpcYearlyEligibleDividendRefund)
      ),
      ["Non-Eligible Dividend Refund"].concat(
        currencyFormatList(optiList?.ccpcYearlyNonEligibleDividendRefund)
      ),
      ["CCPC Taxes Paid within Holding Company"].concat(
        currencyFormatList(optiList?.ccpcYearlyTaxesPaid)
      ),
      ["CCPC Investment Fund ACB"].concat(
        currencyFormatList(optiList?.ccpcYearlyACB)
      ),
      ["NERDTOH Tracking Accounts"].concat(
        currencyFormatList(optiList?.ccpcYearlyNERDTOH)
      ),
      ["ERDTOH Tracking Accounts"].concat(
        currencyFormatList(optiList?.ccpcYearlyERDTOH)
      ),
      ["CDA Tracking Accounts"].concat(
        currencyFormatList(optiList?.ccpcYearlyCDA)
      ),
      ["GRIP Tracking Accounts"].concat(
        currencyFormatList(optiList?.ccpcYearlyGRIP)
      ),
      ["CCPC Investment Fund Loans Repaid"].concat(
        currencyFormatList(optiList?.ccpcYearlyShareholderLoanRepayments)
      ),
      ["CCPC Investment Loan Balance"].concat(
        currencyFormatList(optiList?.ccpcYearlyShareholderLoanBalance)
      ),
      [""],
      ["Personal Assets"],
      ["Value"].concat(currencyFormatList(optiList?.otherAssetsYearlyValue)),
      ["Growth"].concat(currencyFormatList(optiList?.otherAssetsYearlyGrowth)),
      ["Closing Balance"].concat(
        currencyFormatListShift(
          [...optiList?.otherAssetsYearlyValue],
          optiList?.otherAssetsYearlyGrowth?.[
            optiList?.otherAssetsYearlyGrowth?.length - 1
          ]
        )
      ),
      [""],
      ["Expenses"],
      ["Mortgage(s)"].concat(
        currencyFormatList(
          optiList?.PrimaryResidence?.Payments?.map(
            (e, i) =>
              e +
              optiList?.RecreationPropertyYearlyPayments?.[i] +
              optiList?.LandYearlyPayments?.[i] +
              optiList?.IncomePropertyYearlyPayments?.[i]
          )
        )
      ),
      ["Life Insurance Premiums"].concat(
        currencyFormatList(optiList?.lifeInsuranceYearlyPayments)
      ),
      ["Annual Debt Payments"].concat(
        currencyFormatList(optiList?.totalDebtPayments)
      ),
      ["Living Expenses"].concat(currencyFormatList(optiList?.expenses)),
      ["Extra Spending"].concat(currencyFormatList(optiList?.extraSpending)),
      ["Total Expenses"].concat(
        currencyFormatList(
          optiList?.expenses?.map(
            (e, i) =>
              e +
              optiList?.PrimaryResidence?.Payments?.[i] +
              optiList?.RecreationPropertyYearlyPayments?.[i] +
              optiList?.LandYearlyPayments?.[i] +
              optiList?.IncomePropertyYearlyPayments?.[i] +
              optiList?.totalDebtPayments?.[i] +
              optiList?.extraSpending?.[i] +
              optiList?.lifeInsuranceYearlyPayments?.[i]
          )
        )
      ),
      [""],
      ["Total Debt"].concat(
        currencyFormatList(
          optiList?.PrimaryResidence?.Balance?.map(
            (e, i) =>
              e +
              optiList?.RecreationPropertyYearlyBalance?.[i] +
              optiList?.LandYearlyBalance?.[i] +
              optiList?.IncomePropertyYearlyBalance?.[i] +
              optiList?.totalDebtProrated?.[i]
          )
        )
      ),
      [""],
      [optiList?.[`${spousePrefix}_name`] + " " + "Taxes"],
      ["Provincial Taxes"].concat(
        currencyFormatList(optiList?.[`${spousePrefix}_provincialTaxes`])
      ),
      ["Federal Taxes"].concat(
        currencyFormatList(optiList?.[`${spousePrefix}_federalTaxes`])
      ),
      ["EI Premiums"].concat(
        currencyFormatList(optiList?.[`${spousePrefix}_eiPremiums`])
      ),
      ["CPP Contributions"].concat(
        currencyFormatList(optiList?.[`${spousePrefix}_cppContributions`])
      ),
      ["Deductions"].concat(
        currencyFormatList(optiList?.[`${spousePrefix}_salaryDeductions`])
      ),
      ["Total Taxes"].concat(
        currencyFormatList(
          optiList?.[`${spousePrefix}_incomeTaxes`]?.map(
            (e, i) =>
              e +
              optiList?.[`${spousePrefix}_eiPremiums`]?.[i] +
              optiList?.[`${spousePrefix}_cppContributions`]?.[i]
          )
        )
      ),
      [""],
      [spouseOptiList?.sps2_name + " " + "Taxes"],
      ["Provincial Taxes"].concat(
        currencyFormatList(spouseOptiList?.sps2_provincialTaxes)
      ),
      ["Federal Taxes"].concat(
        currencyFormatList(spouseOptiList?.sps2_federalTaxes)
      ),
      ["EI Premiums"].concat(
        currencyFormatList(spouseOptiList?.sps2_eiPremiums)
      ),
      ["CPP Contributions"].concat(
        currencyFormatList(spouseOptiList?.sps2_cppContributions)
      ),
      ["Deductions"].concat(
        currencyFormatList(spouseOptiList?.sps2_salaryDeductions)
      ),
      ["Total Taxes"].concat(
        currencyFormatList(
          spouseOptiList?.sps2_incomeTaxes?.map(
            (e, i) =>
              e +
              spouseOptiList?.sps2_eiPremiums?.[i] +
              spouseOptiList?.sps2_cppContributions?.[i]
          )
        )
      ),
      [""],
      ["Combined Total Taxes"].concat(
        currencyFormatList(
          optiList?.[`${spousePrefix}_incomeTaxes`]?.map(
            (e, i) =>
              e +
              spouseOptiList?.sps2_incomeTaxes?.[i] +
              optiList?.[`${spousePrefix}_cppContributions`]?.[i] +
              spouseOptiList?.sps2_cppContributions?.[i] +
              optiList?.[`${spousePrefix}_eiPremiums`]?.[i] +
              spouseOptiList?.sps2_eiPremiums?.[i]
          )
        )
      ),
    ];
    return data;
  };

  const optiListToCSVSpouse1 = (optiList) => {
    var data = [
      ["Optiml"],
      ["Date of Analysis", formatDate(optiList?.planRunDate)],
      [
        "Spouse 1",
        optiList?.[`${spousePrefix}_name`] +
          " " +
          optiList?.[`${spousePrefix}_lastName`],
        optiList?.[`${spousePrefix}_birthday`]?.toString(),
      ]?.concat(yearHeaders(optiList?.[`${spousePrefix}_birthday`])),
      ["Strategy", passedOptiList?.optimizationType],
      ["CPI", userExpensesInfo?.expenseCPI + "%"],
      [
        "End of Analysis Portfolio Balance",
        currencyFormat(optiList?.optimizedEOLAmount),
      ],
      [
        "End of Analysis Asset Balance",
        currencyFormat(
          optiList?.PrimaryResidence?.Value?.[
            optiList?.PrimaryResidence?.Value?.length - 1
          ] +
            optiList?.RecreationPropertyYearlyValue?.[
              optiList?.RecreationPropertyYearlyValue?.length - 1
            ] +
            optiList?.LandYearlyValue?.[optiList?.LandYearlyValue?.length - 1] +
            optiList?.businessYearlyValue?.[
              optiList?.businessYearlyValue?.length - 1
            ] +
            optiList?.IncomePropertyYearlyValue?.[
              optiList?.IncomePropertyYearlyValue?.length - 1
            ] +
            optiList?.otherAssetsYearlyValue?.[
              optiList?.otherAssetsYearlyValue?.length - 1
            ] +
            optiList?.lifeInsuranceYearlyValue?.[
              optiList?.lifeInsuranceYearlyValue?.length - 1
            ]
        ),
      ],
      [
        "Before Tax End of Analysis Total",
        currencyFormat(
          optiList?.optimizedEOLAmount +
            optiList?.PrimaryResidence?.Value?.[
              optiList?.PrimaryResidence?.Value?.length - 1
            ] +
            optiList?.RecreationPropertyYearlyValue?.[
              optiList?.RecreationPropertyYearlyValue?.length - 1
            ] +
            optiList?.LandYearlyValue?.[optiList?.LandYearlyValue?.length - 1] +
            optiList?.businessYearlyValue?.[
              optiList?.businessYearlyValue?.length - 1
            ] +
            optiList?.IncomePropertyYearlyValue?.[
              optiList?.IncomePropertyYearlyValue?.length - 1
            ] +
            optiList?.otherAssetsYearlyValue?.[
              optiList?.otherAssetsYearlyValue?.length - 1
            ] +
            optiList?.lifeInsuranceYearlyValue?.[
              optiList?.lifeInsuranceYearlyValue?.length - 1
            ]
        ),
      ],
      [
        "After Tax End of Analysis Balance",
        currencyFormat(
          optiList?.optimizedEOLAmountAfterTax +
            optiList?.PrimaryResidence?.Value?.[
              optiList?.PrimaryResidence?.Value?.length - 1
            ] +
            optiList?.RecreationPropertyYearlyValue?.[
              optiList?.RecreationPropertyYearlyValue?.length - 1
            ] +
            optiList?.LandYearlyValue?.[optiList?.LandYearlyValue?.length - 1] +
            optiList?.businessYearlyValue?.[
              optiList?.businessYearlyValue?.length - 1
            ] +
            optiList?.IncomePropertyYearlyValue?.[
              optiList?.IncomePropertyYearlyValue?.length - 1
            ] +
            optiList?.otherAssetsYearlyValue?.[
              optiList?.otherAssetsYearlyValue?.length - 1
            ] +
            optiList?.lifeInsuranceYearlyValue?.[
              optiList?.lifeInsuranceYearlyValue?.length - 1
            ]
        ),
      ],
      [""],
      ["Income"],
      ["Salary"].concat(
        currencyFormatList(optiList?.[`${spousePrefix}_salary`])
      ),
      ["CPP"].concat(currencyFormatList(optiList?.[`${spousePrefix}_cpp`])),
      ["OAS"].concat(currencyFormatList(optiList?.[`${spousePrefix}_oas`])),
      ["Pension"].concat(
        currencyFormatList(optiList?.[`${spousePrefix}_pension`]?.Benefits)
      ),
      ["Rental Income"].concat(
        currencyFormatList(optiList?.IncomePropertyYearlyIncome)
      ),
      ["Business Dividends"].concat(
        currencyFormatList(optiList?.businessYearlyDividends)
      ),
      ["Other Income"].concat(
        currencyFormatList(optiList?.[`${spousePrefix}_otherIncomeTotal`])
      ),
      [""],
      ["Investments"],
      ["Registered"],
      ["TFSA"].concat(
        currencyFormatList(optiList?.[`${spousePrefix}_tfsaFunds`])
      ),
      ["Deposits"].concat(
        currencyFormatList(optiList?.[`${spousePrefix}_tfsaContributions`])
      ),
      ["Withdrawals"].concat(
        currencyFormatList(optiList?.[`${spousePrefix}_tfsaWithdrawals`])
      ),
      ["Growth"].concat(
        currencyFormatList(optiList?.[`${spousePrefix}_tfsaGrowth`])
      ),
      ["Closing Balance"].concat(
        currencyFormatListShift(
          [...optiList?.[`${spousePrefix}_tfsaFunds`]],
          optiList?.[`${spousePrefix}_tfsaGrowth`][
            optiList?.[`${spousePrefix}_tfsaGrowth`]?.length - 1
          ]
        )
      ),
      [""],
      ["RRSP"].concat(
        currencyFormatList(optiList?.[`${spousePrefix}_registeredFunds`])
      ),
      ["Deposits"].concat(
        currencyFormatList(
          optiList?.[`${spousePrefix}_registeredContributions`]
        )
      ),
      ["Employer Contributions"].concat(
        currencyFormatList(
          optiList?.[`${spousePrefix}_registeredContributionsMatched`]
        )
      ),
      ["Salary Contributions"].concat(
        currencyFormatList(
          optiList?.[`${spousePrefix}_registeredContributionsMatched`]
        )
      ),
      ["Withdrawals"].concat(
        currencyFormatList(optiList?.[`${spousePrefix}_registeredWithdrawals`])
      ),
      ["Growth"].concat(
        currencyFormatList(optiList?.[`${spousePrefix}_registeredGrowth`])
      ),
      ["Closing Balance"].concat(
        currencyFormatListShift(
          [...optiList?.[`${spousePrefix}_registeredFunds`]],
          optiList?.[`${spousePrefix}_registeredGrowth`][
            optiList?.[`${spousePrefix}_registeredGrowth`]?.length - 1
          ]
        )
      ),
      [""],
      ["LIRA"].concat(
        currencyFormatList(optiList?.[`${spousePrefix}_liraFunds`])
      ),
      ["Withdrawals"].concat(
        currencyFormatList(optiList?.[`${spousePrefix}_liraWithdrawals`])
      ),
      ["Growth"].concat(
        currencyFormatList(optiList?.[`${spousePrefix}_liraGrowth`])
      ),
      ["Closing Balance"].concat(
        currencyFormatListShift(
          [...optiList?.[`${spousePrefix}_liraFunds`]],
          optiList?.[`${spousePrefix}_liraGrowth`][
            optiList?.[`${spousePrefix}_liraGrowth`]?.length - 1
          ]
        )
      ),
      [""],
      ["Defined Contribution Pension"].concat(
        currencyFormatList(optiList?.[`${spousePrefix}_pension`]?.Value)
      ),
      ["Self Contributions"].concat(
        currencyFormatList(
          optiList?.[`${spousePrefix}_pension`]?.SelfContributions
        )
      ),
      ["Employer Contributions"].concat(
        currencyFormatList(
          optiList?.[`${spousePrefix}_pension`]?.EmployerContributions
        )
      ),
      ["Growth"].concat(
        currencyFormatList(optiList?.[`${spousePrefix}_pension`]?.Growth)
      ),
      ["Closing Balance"],
      [""],
      ["Non-Registered"].concat(
        currencyFormatList(optiList?.[`${spousePrefix}_nonregisteredFunds`])
      ),
      ["Adjusted Cost Base"].concat(
        currencyFormatList(optiList?.[`${spousePrefix}_acb`])
      ),
      ["Deposits"].concat(
        currencyFormatList(
          optiList?.[`${spousePrefix}_nonRegisteredContributions`]
        )
      ),
      ["Dividends"].concat(
        currencyFormatList(optiList?.[`${spousePrefix}_dividends`])
      ),
      ["Withdrawals"].concat(
        currencyFormatList(
          optiList?.[`${spousePrefix}_nonregisteredWithdrawals`]
        )
      ),
      ["Growth"].concat(
        currencyFormatList(optiList?.[`${spousePrefix}_nonregisteredGrowth`])
      ),
      ["Closing Balance"].concat(
        currencyFormatListShift(
          [...optiList?.[`${spousePrefix}_nonregisteredFunds`]],
          optiList?.[`${spousePrefix}_nonregisteredGrowth`][
            optiList?.[`${spousePrefix}_nonregisteredGrowth`]?.length - 1
          ]
        )
      ),
      ["Fixed Income"].concat(
        currencyFormatList(optiList?.[`${spousePrefix}_fixedIncomeTotal`])
      ),
      ["Withdrawals"].concat(
        currencyFormatList(optiList?.[`${spousePrefix}_fixedIncomeWithdrawals`])
      ),
      ["Growth"].concat(
        currencyFormatList(optiList?.[`${spousePrefix}_fixedIncomeGrowth`])
      ),
      [""],
      ["Total Investment Balance"].concat(
        currencyFormatList(
          optiList?.[`${spousePrefix}_tfsaFunds`]?.map(
            (e, i) =>
              e +
              optiList?.[`${spousePrefix}_registeredFunds`]?.[i] +
              optiList?.[`${spousePrefix}_fixedIncomeTotal`]?.[i] +
              optiList?.[`${spousePrefix}_nonregisteredFunds`]?.[i] +
              optiList?.[`${spousePrefix}_liraFunds`]?.[i] +
              optiList?.[`${spousePrefix}_pension`]?.Value?.[i]
          )
        )
      ),
      [""],
      ["Assets"],
      ["Real Estate"],
      ["Primary Residence"].concat(
        currencyFormatList(optiList?.PrimaryResidence?.Value)
      ),
      ["  Growth"].concat(
        currencyFormatList(optiList?.PrimaryResidence?.Growth)
      ),
      ["  Closing Balance"].concat(
        currencyFormatListShift(
          [...optiList?.PrimaryResidence?.Value],
          optiList?.PrimaryResidence?.Growth[
            optiList?.PrimaryResidence?.Growth?.length - 1
          ]
        )
      ),
      ["Recreational Property"].concat(
        currencyFormatList(optiList?.RecreationPropertyYearlyValue)
      ),
      ["  Growth"].concat(
        currencyFormatList(optiList?.RecreationPropertyYearlyGrowth)
      ),
      ["  Closing Balance"].concat(
        currencyFormatListShift(
          [...optiList?.RecreationPropertyYearlyValue],
          optiList?.RecreationPropertyYearlyGrowth[
            optiList?.RecreationPropertyYearlyGrowth?.length - 1
          ]
        )
      ),
      ["Land"].concat(currencyFormatList(optiList?.LandYearlyValue)),
      ["  Growth"].concat(currencyFormatList(optiList?.LandYearlyGrowth)),
      ["  Closing Balance"].concat(
        currencyFormatListShift(
          [...optiList?.LandYearlyValue],
          optiList?.LandYearlyGrowth[optiList?.LandYearlyGrowth?.length - 1]
        )
      ),
      ["Income Property(s)"].concat(
        currencyFormatList(optiList?.IncomePropertyYearlyValue)
      ),
      ["  Growth"].concat(
        currencyFormatList(optiList?.IncomePropertyYearlyGrowth)
      ),
      ["  Closing Balance"].concat(
        currencyFormatListShift(
          [...optiList?.IncomePropertyYearlyValue],
          optiList?.IncomePropertyYearlyGrowth[
            optiList?.IncomePropertyYearlyGrowth?.length - 1
          ]
        )
      ),
      [""],
      ["Life Insurance"].concat(
        currencyFormatList(optiList?.lifeInsuranceYearlyValue)
      ),
      [""],
      ["Business"].concat(currencyFormatList(optiList?.businessYearlyValue)),
      ["Growth"].concat(currencyFormatList(optiList?.businessYearlyGrowth)),
      ["Business Closing Value"].concat(
        currencyFormatListShift([...optiList?.businessYearlyValue])
      ),
      ["CCPC Investments Fund"].concat(
        currencyFormatList(optiList?.ccpcYearlyBalance)
      ),
      ["Growth"].concat(
        currencyFormatList(optiList?.ccpcYearlyInvestmentGrowth)
      ),
      ["Re-Invested Funds From Operations"].concat(
        currencyFormatList(optiList?.ccpcYearlyOperationFunds)
      ),
      ["Issued Capital Dividends"].concat(
        currencyFormatList(optiList?.ccpcYearlyCapitalDividendsIssued)
      ),
      ["Issued Eligible Dividends"].concat(
        currencyFormatList(optiList?.ccpcYearlyEligibleDividendsIssued)
      ),
      ["Issued Non-Eligible Dividends"].concat(
        currencyFormatList(optiList?.ccpcYearlyNonEligibleDividendsIssued)
      ),
      ["Earned Eligible Dividends"].concat(
        currencyFormatList(optiList?.ccpcYearlyEligibleDividendsEarned)
      ),
      ["Earned Non-Eligible Dividends"].concat(
        currencyFormatList(optiList?.ccpcYearlyNonEligibleDividendsEarned)
      ),
      ["Earned Other Dividends"].concat(
        currencyFormatList(optiList?.ccpcYearlyOtherDividendsEarned)
      ),
      ["Eligible Dividend Refund"].concat(
        currencyFormatList(optiList?.ccpcYearlyEligibleDividendRefund)
      ),
      ["Non-Eligible Dividend Refund"].concat(
        currencyFormatList(optiList?.ccpcYearlyNonEligibleDividendRefund)
      ),
      ["CCPC Taxes Paid within Holding Company"].concat(
        currencyFormatList(optiList?.ccpcYearlyTaxesPaid)
      ),
      ["CCPC Investment Fund ACB"].concat(
        currencyFormatList(optiList?.ccpcYearlyACB)
      ),
      ["NERDTOH Tracking Accounts"].concat(
        currencyFormatList(optiList?.ccpcYearlyNERDTOH)
      ),
      ["ERDTOH Tracking Accounts"].concat(
        currencyFormatList(optiList?.ccpcYearlyERDTOH)
      ),
      ["CDA Tracking Accounts"].concat(
        currencyFormatList(optiList?.ccpcYearlyCDA)
      ),
      ["GRIP Tracking Accounts"].concat(
        currencyFormatList(optiList?.ccpcYearlyGRIP)
      ),
      ["CCPC Investment Fund Loans Repaid"].concat(
        currencyFormatList(optiList?.ccpcYearlyShareholderLoanRepayments)
      ),
      ["CCPC Investment Loan Balance"].concat(
        currencyFormatList(optiList?.ccpcYearlyShareholderLoanBalance)
      ),
      [""],
      ["Personal Assets"],
      ["Value"].concat(currencyFormatList(optiList?.otherAssetsYearlyValue)),
      ["Growth"].concat(currencyFormatList(optiList?.otherAssetsYearlyGrowth)),
      ["Closing Balance"].concat(
        currencyFormatListShift(
          [...optiList?.otherAssetsYearlyValue],
          optiList?.otherAssetsYearlyGrowth?.[
            optiList?.otherAssetsYearlyGrowth?.length - 1
          ]
        )
      ),
      [""],
      ["Expenses"],
      ["Mortgage(s)"].concat(
        currencyFormatList(
          optiList?.PrimaryResidence?.Payments?.map(
            (e, i) =>
              e +
              optiList?.RecreationPropertyYearlyPayments?.[i] +
              optiList?.LandYearlyPayments?.[i] +
              optiList?.IncomePropertyYearlyPayments?.[i]
          )
        )
      ),
      ["Life Insurance Premiums"].concat(
        currencyFormatList(optiList?.lifeInsuranceYearlyPayments)
      ),
      ["Annual Debt Payments"].concat(
        currencyFormatList(optiList?.totalDebtPayments)
      ),
      ["Living Expenses"].concat(currencyFormatList(optiList?.expenses)),
      ["Extra Spending"].concat(currencyFormatList(optiList?.extraSpending)),
      ["Total Expenses"].concat(
        currencyFormatList(
          optiList?.expenses?.map(
            (e, i) =>
              e +
              optiList?.PrimaryResidence?.Payments?.[i] +
              optiList?.RecreationPropertyYearlyPayments?.[i] +
              optiList?.LandYearlyPayments?.[i] +
              optiList?.IncomePropertyYearlyPayments?.[i] +
              optiList?.totalDebtPayments?.[i] +
              optiList?.extraSpending?.[i] +
              optiList?.lifeInsuranceYearlyPayments?.[i]
          )
        )
      ),
      [""],
      ["Total Debt"].concat(
        currencyFormatList(
          optiList?.PrimaryResidence?.Balance?.map(
            (e, i) =>
              e +
              optiList?.RecreationPropertyYearlyBalance?.[i] +
              optiList?.LandYearlyBalance?.[i] +
              optiList?.IncomePropertyYearlyBalance?.[i] +
              optiList?.totalDebtProrated?.[i]
          )
        )
      ),
      [""],
      ["Taxes"],
      ["Provincial Taxes"].concat(
        currencyFormatList(optiList?.[`${spousePrefix}_provincialTaxes`])
      ),
      ["Federal Taxes"].concat(
        currencyFormatList(optiList?.[`${spousePrefix}_federalTaxes`])
      ),
      ["EI Premiums"].concat(
        currencyFormatList(optiList?.[`${spousePrefix}_eiPremiums`])
      ),
      ["CPP Contributions"].concat(
        currencyFormatList(optiList?.[`${spousePrefix}_cppContributions`])
      ),
      ["Deductions"].concat(
        currencyFormatList(optiList?.[`${spousePrefix}_salaryDeductions`])
      ),
      ["Total Taxes"].concat(
        currencyFormatList(
          optiList?.[`${spousePrefix}_incomeTaxes`]?.map(
            (e, i) =>
              e +
              optiList?.[`${spousePrefix}_eiPremiums`]?.[i] +
              optiList?.[`${spousePrefix}_cppContributions`]?.[i]
          )
        )
      ),
      [""],
      ["Net Worth"],
    ];
    return data;
  };

  const optiListToCSVSpouse2 = (optiList) => {
    var data = [
      ["Optiml"],
      ["Date of Analysis", formatDate(optiList?.planRunDate)],
      [
        "Spouse 2",
        spouseOptiList?.sps2_name + " " + spouseOptiList?.sps2_lastName,
        spouseOptiList?.sps2_birthday?.toString(),
      ]?.concat(yearHeaders(spouseOptiList?.sps2_birthday)),
      ["Strategy", passedOptiList?.optimizationType],
      ["CPI", userExpensesInfo?.expenseCPI + "%"],
      [
        "End of Analysis Portfolio Balance",
        currencyFormat(optiList?.optimizedEOLAmount),
      ],
      [
        "End of Analysis Asset Balance",
        currencyFormat(
          optiList?.PrimaryResidence?.Value?.[
            optiList?.PrimaryResidence?.Value?.length - 1
          ] +
            optiList?.RecreationPropertyYearlyValue?.[
              optiList?.RecreationPropertyYearlyValue?.length - 1
            ] +
            optiList?.LandYearlyValue?.[optiList?.LandYearlyValue?.length - 1] +
            optiList?.businessYearlyValue?.[
              optiList?.businessYearlyValue?.length - 1
            ] +
            optiList?.IncomePropertyYearlyValue?.[
              optiList?.IncomePropertyYearlyValue?.length - 1
            ] +
            optiList?.otherAssetsYearlyValue?.[
              optiList?.otherAssetsYearlyValue?.length - 1
            ] +
            optiList?.lifeInsuranceYearlyValue?.[
              optiList?.lifeInsuranceYearlyValue?.length - 1
            ]
        ),
      ],
      [
        "Before Tax End of Analysis Total",
        currencyFormat(
          optiList?.optimizedEOLAmount +
            optiList?.PrimaryResidence?.Value?.[
              optiList?.PrimaryResidence?.Value?.length - 1
            ] +
            optiList?.RecreationPropertyYearlyValue?.[
              optiList?.RecreationPropertyYearlyValue?.length - 1
            ] +
            optiList?.LandYearlyValue?.[optiList?.LandYearlyValue?.length - 1] +
            optiList?.businessYearlyValue?.[
              optiList?.businessYearlyValue?.length - 1
            ] +
            optiList?.IncomePropertyYearlyValue?.[
              optiList?.IncomePropertyYearlyValue?.length - 1
            ] +
            optiList?.otherAssetsYearlyValue?.[
              optiList?.otherAssetsYearlyValue?.length - 1
            ] +
            optiList?.lifeInsuranceYearlyValue?.[
              optiList?.lifeInsuranceYearlyValue?.length - 1
            ]
        ),
      ],
      [
        "After Tax End of Analysis Balance",
        currencyFormat(
          optiList?.optimizedEOLAmountAfterTax +
            optiList?.PrimaryResidence?.Value?.[
              optiList?.PrimaryResidence?.Value?.length - 1
            ] +
            optiList?.RecreationPropertyYearlyValue?.[
              optiList?.RecreationPropertyYearlyValue?.length - 1
            ] +
            optiList?.LandYearlyValue?.[optiList?.LandYearlyValue?.length - 1] +
            optiList?.businessYearlyValue?.[
              optiList?.businessYearlyValue?.length - 1
            ] +
            optiList?.IncomePropertyYearlyValue?.[
              optiList?.IncomePropertyYearlyValue?.length - 1
            ] +
            optiList?.otherAssetsYearlyValue?.[
              optiList?.otherAssetsYearlyValue?.length - 1
            ] +
            optiList?.lifeInsuranceYearlyValue?.[
              optiList?.lifeInsuranceYearlyValue?.length - 1
            ]
        ),
      ],
      [""],

      ["Income"],
      ["Salary"].concat(currencyFormatList(spouseOptiList?.sps2_salary)),
      ["CPP"].concat(currencyFormatList(spouseOptiList?.sps2_cpp)),
      ["OAS"].concat(currencyFormatList(spouseOptiList?.sps2_oas)),
      ["Pension"].concat(
        currencyFormatList(spouseOptiList?.sps2_pension?.Benefits)
      ),
      ["Other"].concat(
        currencyFormatList(spouseOptiList?.sps2_otherIncomeTotal)
      ),
      [""],

      ["Investments"],
      ["Registered"],
      ["TFSA"].concat(currencyFormatList(spouseOptiList?.sps2_tfsaFunds)),
      ["Deposits"].concat(
        currencyFormatList(spouseOptiList?.sps2_tfsaContributions)
      ),
      ["Withdrawals"].concat(
        currencyFormatList(spouseOptiList?.sps2_tfsaWithdrawals)
      ),
      ["Growth"].concat(currencyFormatList(spouseOptiList?.sps2_tfsaGrowth)),
      ["Closing Balance"].concat(
        currencyFormatListShift(
          [...spouseOptiList?.sps2_tfsaFunds],
          spouseOptiList?.sps2_tfsaGrowth[
            spouseOptiList?.sps2_tfsaGrowth?.length - 1
          ]
        )
      ),
      [""],
      ["RRSP"].concat(currencyFormatList(spouseOptiList?.sps2_registeredFunds)),
      ["Deposits"].concat(
        currencyFormatList(spouseOptiList?.sps2_registeredContributions)
      ),
      ["Employer Contributions"].concat(
        currencyFormatList(spouseOptiList?.sps2_registeredContributionsMatched)
      ),
      ["Salary Contributions"].concat(
        currencyFormatList(spouseOptiList?.sps2_registeredContributionsMatched)
      ),
      ["Withdrawals"].concat(
        currencyFormatList(spouseOptiList?.sps2_registeredWithdrawals)
      ),
      ["Growth"].concat(
        currencyFormatList(spouseOptiList?.sps2_registeredGrowth)
      ),
      ["Closing Balance"].concat(
        currencyFormatListShift(
          [...spouseOptiList?.sps2_registeredFunds],
          spouseOptiList?.sps2_registeredGrowth[
            spouseOptiList?.sps2_registeredGrowth?.length - 1
          ]
        )
      ),
      [""],
      ["LIRA"].concat(currencyFormatList(spouseOptiList?.sps2_liraFunds)),
      ["Withdrawals"].concat(
        currencyFormatList(spouseOptiList?.sps2_liraWithdrawals)
      ),
      ["Growth"].concat(currencyFormatList(spouseOptiList?.sps2_liraGrowth)),
      ["Closing Balance"].concat(
        currencyFormatListShift(
          [...spouseOptiList?.sps2_liraFunds],
          spouseOptiList?.sps2_liraGrowth[
            spouseOptiList?.sps2_liraGrowth?.length - 1
          ]
        )
      ),
      [""],
      ["Defined Contribution Pension"].concat(
        currencyFormatList(spouseOptiList?.sps2_pension?.Value)
      ),
      ["Self Contributions"].concat(
        currencyFormatList(spouseOptiList?.sps2_pension?.SelfContributions)
      ),
      ["Employer Contributions"].concat(
        currencyFormatList(spouseOptiList?.sps2_pension?.EmployerContributions)
      ),
      ["Growth"].concat(
        currencyFormatList(spouseOptiList?.sps2_pension?.Growth)
      ),
      ["Closing Balance"],
      [""],
      ["Non-Registered"].concat(
        currencyFormatList(spouseOptiList?.sps2_nonregisteredFunds)
      ),
      ["Adjusted Cost Base"].concat(
        currencyFormatList(spouseOptiList?.sps2_acb)
      ),
      ["Deposits"].concat(
        currencyFormatList(spouseOptiList?.sps2_nonRegisteredContributions)
      ),
      ["Dividends"].concat(currencyFormatList(spouseOptiList?.sps2_dividends)),
      ["Withdrawals"].concat(
        currencyFormatList(spouseOptiList?.sps2_nonregisteredWithdrawals)
      ),
      ["Growth"].concat(
        currencyFormatList(spouseOptiList?.sps2_nonregisteredGrowth)
      ),
      ["Closing Balance"].concat(
        currencyFormatListShift(
          [...spouseOptiList?.sps2_nonregisteredFunds],
          spouseOptiList?.sps2_nonregisteredGrowth?.[
            spouseOptiList?.sps2_nonregisteredGrowth?.length - 1
          ]
        )
      ),
      ["Fixed Income"].concat(
        currencyFormatList(spouseOptiList?.sps2_fixedIncomeTotal)
      ),
      ["Withdrawals"].concat(
        currencyFormatList(spouseOptiList?.sps2_fixedIncomeWithdrawals)
      ),
      ["Growth"].concat(
        currencyFormatList(spouseOptiList?.sps2_fixedIncomeGrowth)
      ),
      [""],
      ["Total Investment Balance"].concat(
        currencyFormatList(
          spouseOptiList?.sps2_registeredFunds?.map(
            (e, i) =>
              e +
              spouseOptiList?.sps2_nonregisteredFunds?.[i] +
              spouseOptiList?.sps2_fixedIncomeTotal?.[i] +
              spouseOptiList?.sps2_tfsaFunds?.[i] +
              spouseOptiList?.sps2_liraFunds?.[i] +
              spouseOptiList?.sps2_pension?.Value?.[i]
          )
        )
      ),
      [""],

      ["Assets"],
      ["Real Estate"],
      ["Primary Residence"].concat(
        currencyFormatList(optiList?.PrimaryResidence?.Value)
      ),
      ["  Growth"].concat(
        currencyFormatList(optiList?.PrimaryResidence?.Growth)
      ),
      ["  Closing Balance"].concat(
        currencyFormatListShift(
          [...optiList?.PrimaryResidence?.Value],
          optiList?.PrimaryResidence?.Growth[
            optiList?.PrimaryResidence?.Growth?.length - 1
          ]
        )
      ),
      ["Recreational Property"].concat(
        currencyFormatList(optiList?.RecreationPropertyYearlyValue)
      ),
      ["  Growth"].concat(
        currencyFormatList(optiList?.RecreationPropertyYearlyGrowth)
      ),
      ["  Closing Balance"].concat(
        currencyFormatListShift(
          [...optiList?.RecreationPropertyYearlyValue],
          optiList?.RecreationPropertyYearlyGrowth[
            optiList?.RecreationPropertyYearlyGrowth?.length - 1
          ]
        )
      ),
      ["Land"].concat(currencyFormatList(optiList?.LandYearlyValue)),
      ["  Growth"].concat(currencyFormatList(optiList?.LandYearlyGrowth)),
      ["  Closing Balance"].concat(
        currencyFormatListShift(
          [...optiList?.LandYearlyValue],
          optiList?.LandYearlyGrowth[optiList?.LandYearlyGrowth?.length - 1]
        )
      ),
      ["Income Property(s)"].concat(
        currencyFormatList(optiList?.IncomePropertyYearlyValue)
      ),
      ["  Growth"].concat(
        currencyFormatList(optiList?.IncomePropertyYearlyGrowth)
      ),
      ["  Closing Balance"].concat(
        currencyFormatListShift(
          [...optiList?.IncomePropertyYearlyValue],
          optiList?.IncomePropertyYearlyGrowth[
            optiList?.IncomePropertyYearlyGrowth?.length - 1
          ]
        )
      ),
      [""],
      ["Life Insurance"].concat(
        currencyFormatList(optiList?.lifeInsuranceYearlyValue)
      ),
      [""],
      ["Business"].concat(currencyFormatList(optiList?.businessYearlyValue)),
      ["Growth"].concat(currencyFormatList(optiList?.businessYearlyGrowth)),
      ["Business Closing Value"].concat(
        currencyFormatListShift([...optiList?.businessYearlyValue])
      ),
      ["CCPC Investments Fund"].concat(
        currencyFormatList(optiList?.ccpcYearlyBalance)
      ),
      ["Growth"].concat(
        currencyFormatList(optiList?.ccpcYearlyInvestmentGrowth)
      ),
      ["Re-Invested Funds From Operations"].concat(
        currencyFormatList(optiList?.ccpcYearlyOperationFunds)
      ),
      ["Issued Capital Dividends"].concat(
        currencyFormatList(optiList?.ccpcYearlyCapitalDividendsIssued)
      ),
      ["Issued Eligible Dividends"].concat(
        currencyFormatList(optiList?.ccpcYearlyEligibleDividendsIssued)
      ),
      ["Issued Non-Eligible Dividends"].concat(
        currencyFormatList(optiList?.ccpcYearlyNonEligibleDividendsIssued)
      ),
      ["Earned Eligible Dividends"].concat(
        currencyFormatList(optiList?.ccpcYearlyEligibleDividendsEarned)
      ),
      ["Earned Non-Eligible Dividends"].concat(
        currencyFormatList(optiList?.ccpcYearlyNonEligibleDividendsEarned)
      ),
      ["Earned Other Dividends"].concat(
        currencyFormatList(optiList?.ccpcYearlyOtherDividendsEarned)
      ),
      ["Eligible Dividend Refund"].concat(
        currencyFormatList(optiList?.ccpcYearlyEligibleDividendRefund)
      ),
      ["Non-Eligible Dividend Refund"].concat(
        currencyFormatList(optiList?.ccpcYearlyNonEligibleDividendRefund)
      ),
      ["CCPC Taxes Paid within Holding Company"].concat(
        currencyFormatList(optiList?.ccpcYearlyTaxesPaid)
      ),
      ["CCPC Investment Fund ACB"].concat(
        currencyFormatList(optiList?.ccpcYearlyACB)
      ),
      ["NERDTOH Tracking Accounts"].concat(
        currencyFormatList(optiList?.ccpcYearlyNERDTOH)
      ),
      ["ERDTOH Tracking Accounts"].concat(
        currencyFormatList(optiList?.ccpcYearlyERDTOH)
      ),
      ["CDA Tracking Accounts"].concat(
        currencyFormatList(optiList?.ccpcYearlyCDA)
      ),
      ["GRIP Tracking Accounts"].concat(
        currencyFormatList(optiList?.ccpcYearlyGRIP)
      ),
      ["CCPC Investment Fund Loans Repaid"].concat(
        currencyFormatList(optiList?.ccpcYearlyShareholderLoanRepayments)
      ),
      ["CCPC Investment Loan Balance"].concat(
        currencyFormatList(optiList?.ccpcYearlyShareholderLoanBalance)
      ),
      [""],
      ["Personal Assets"],
      ["Value"].concat(currencyFormatList(optiList?.otherAssetsYearlyValue)),
      ["Growth"].concat(currencyFormatList(optiList?.otherAssetsYearlyGrowth)),
      ["Closing Balance"].concat(
        currencyFormatListShift(
          [...optiList?.otherAssetsYearlyValue],
          optiList?.otherAssetsYearlyGrowth?.[
            optiList?.otherAssetsYearlyGrowth?.length - 1
          ]
        )
      ),
      [""],
      ["Expenses"],
      ["Mortgage(s)"].concat(
        currencyFormatList(
          optiList?.PrimaryResidence?.Payments?.map(
            (e, i) =>
              e +
              optiList?.RecreationPropertyYearlyPayments?.[i] +
              optiList?.LandYearlyPayments?.[i] +
              optiList?.IncomePropertyYearlyPayments?.[i]
          )
        )
      ),
      ["Life Insurance Premiums"].concat(
        currencyFormatList(optiList?.lifeInsuranceYearlyPayments)
      ),
      ["Annual Debt Payments"].concat(
        currencyFormatList(optiList?.totalDebtPayments)
      ),
      ["Living Expenses"].concat(currencyFormatList(optiList?.expenses)),
      ["Extra Spending"].concat(currencyFormatList(optiList?.extraSpending)),
      ["Total Expenses"].concat(
        currencyFormatList(
          optiList?.expenses?.map(
            (e, i) =>
              e +
              optiList?.PrimaryResidence?.Payments?.[i] +
              optiList?.RecreationPropertyYearlyPayments?.[i] +
              optiList?.LandYearlyPayments?.[i] +
              optiList?.IncomePropertyYearlyPayments?.[i] +
              optiList?.totalDebtPayments?.[i] +
              optiList?.extraSpending?.[i] +
              optiList?.lifeInsuranceYearlyPayments?.[i]
          )
        )
      ),
      [""],
      ["Total Debt"].concat(
        currencyFormatList(
          optiList?.PrimaryResidence?.Balance?.map(
            (e, i) =>
              e +
              optiList?.RecreationPropertyYearlyBalance?.[i] +
              optiList?.LandYearlyBalance?.[i] +
              optiList?.IncomePropertyYearlyBalance?.[i] +
              optiList?.totalDebtProrated?.[i]
          )
        )
      ),
      [""],
      ["Taxes"],
      ["Provincial Taxes"].concat(
        currencyFormatList(spouseOptiList?.sps2_provincialTaxes)
      ),
      ["Federal Taxes"].concat(
        currencyFormatList(spouseOptiList?.sps2_federalTaxes)
      ),
      ["EI Premiums"].concat(
        currencyFormatList(spouseOptiList?.sps2_eiPremiums)
      ),
      ["CPP Contributions"].concat(
        currencyFormatList(spouseOptiList?.sps2_cppContributions)
      ),
      ["Deductions"].concat(
        currencyFormatList(spouseOptiList?.sps2_salaryDeductions)
      ),
      ["Total Taxes"].concat(
        currencyFormatList(
          spouseOptiList?.sps2_incomeTaxes?.map(
            (e, i) =>
              e +
              spouseOptiList?.sps2_eiPremiums?.[i] +
              spouseOptiList?.sps2_cppContributions?.[i]
          )
        )
      ),
      [""],
      ["Net Worth"],
    ];
    return data;
  };

  const optiListToCSVROT = (optiList, spouseOptiList) => {
    if (optiList?.expenses?.length) {
      var data = [
        ["Rule Of Thumb"],
        ["Date of Analysis", formatDate(optiList?.planRunDate)],
        [
          "Spouse 1",
          optiList?.[`${spousePrefix}_name`] +
            " " +
            optiList?.[`${spousePrefix}_lastName`],
          optiList?.[`${spousePrefix}_birthday`]?.toString(),
        ]?.concat(yearHeaders(optiList?.[`${spousePrefix}_birthday`])),
        [
          "Spouse 2",
          spouseOptiList?.sps2_name + " " + spouseOptiList?.sps2_lastName,
          spouseOptiList?.sps2_birthday?.toString(),
        ]?.concat(yearHeaders(spouseOptiList?.sps2_birthday)),
        ["Strategy", passedOptiList?.optimizationType],
        ["CPI", userExpensesInfo?.expenseCPI + "%"],
        [
          "End of Analysis Portfolio Balance",
          currencyFormat(optiList?.optimizedEOLAmount),
        ],
        [
          "End of Analysis Asset Balance",
          currencyFormat(
            optiList?.PrimaryResidence?.Value?.[
              optiList?.PrimaryResidence?.Value?.length - 1
            ] +
              optiList?.RecreationPropertyYearlyValue?.[
                optiList?.RecreationPropertyYearlyValue?.length - 1
              ] +
              optiList?.LandYearlyValue?.[
                optiList?.LandYearlyValue?.length - 1
              ] +
              optiList?.businessYearlyValue?.[
                optiList?.businessYearlyValue?.length - 1
              ] +
              optiList?.IncomePropertyYearlyValue?.[
                optiList?.IncomePropertyYearlyValue?.length - 1
              ] +
              optiList?.otherAssetsYearlyValue?.[
                optiList?.otherAssetsYearlyValue?.length - 1
              ] +
              optiList?.lifeInsuranceYearlyValue?.[
                optiList?.lifeInsuranceYearlyValue?.length - 1
              ]
          ),
        ],
        [
          "Before Tax End of Analysis Total",
          currencyFormat(
            optiList?.optimizedEOLAmount +
              optiList?.PrimaryResidence?.Value?.[
                optiList?.PrimaryResidence?.Value?.length - 1
              ] +
              optiList?.RecreationPropertyYearlyValue?.[
                optiList?.RecreationPropertyYearlyValue?.length - 1
              ] +
              optiList?.LandYearlyValue?.[
                optiList?.LandYearlyValue?.length - 1
              ] +
              optiList?.businessYearlyValue?.[
                optiList?.businessYearlyValue?.length - 1
              ] +
              optiList?.IncomePropertyYearlyValue?.[
                optiList?.IncomePropertyYearlyValue?.length - 1
              ] +
              optiList?.otherAssetsYearlyValue?.[
                optiList?.otherAssetsYearlyValue?.length - 1
              ] +
              optiList?.lifeInsuranceYearlyValue?.[
                optiList?.lifeInsuranceYearlyValue?.length - 1
              ]
          ),
        ],
        [
          "After Tax End of Analysis Balance",
          currencyFormat(
            optiList?.optimizedEOLAmountAfterTax +
              optiList?.PrimaryResidence?.Value?.[
                optiList?.PrimaryResidence?.Value?.length - 1
              ] +
              optiList?.RecreationPropertyYearlyValue?.[
                optiList?.RecreationPropertyYearlyValue?.length - 1
              ] +
              optiList?.LandYearlyValue?.[
                optiList?.LandYearlyValue?.length - 1
              ] +
              optiList?.businessYearlyValue?.[
                optiList?.businessYearlyValue?.length - 1
              ] +
              optiList?.IncomePropertyYearlyValue?.[
                optiList?.IncomePropertyYearlyValue?.length - 1
              ] +
              optiList?.otherAssetsYearlyValue?.[
                optiList?.otherAssetsYearlyValue?.length - 1
              ] +
              optiList?.lifeInsuranceYearlyValue?.[
                optiList?.lifeInsuranceYearlyValue?.length - 1
              ]
          ),
        ],
        [""],

        [optiList?.[`${spousePrefix}_name`] + " " + "Income"],
        ["Salary"].concat(
          currencyFormatList(optiList?.[`${spousePrefix}_salary`])
        ),
        ["CPP"].concat(currencyFormatList(optiList?.[`${spousePrefix}_cpp`])),
        ["OAS"].concat(currencyFormatList(optiList?.[`${spousePrefix}_oas`])),
        ["Pension"].concat(
          currencyFormatList(optiList?.[`${spousePrefix}_pension`]?.Benefits)
        ),
        ["Rental Income"].concat(
          currencyFormatList(optiList?.IncomePropertyYearlyIncome)
        ),
        ["Business Dividends"].concat(
          currencyFormatList(optiList?.businessYearlyDividends)
        ),
        ["Other Income"].concat(
          currencyFormatList(optiList?.[`${spousePrefix}_otherIncomeTotal`])
        ),
        [""],
        [spouseOptiList?.sps2_name + " " + "Income"],
        ["Salary"].concat(currencyFormatList(spouseOptiList?.sps2_salary)),
        ["CPP"].concat(currencyFormatList(spouseOptiList?.sps2_cpp)),
        ["OAS"].concat(currencyFormatList(spouseOptiList?.sps2_oas)),
        ["Pension"].concat(
          currencyFormatList(spouseOptiList?.sps2_pension?.Benefits)
        ),
        ["Other"].concat(
          currencyFormatList(spouseOptiList?.sps2_otherIncomeTotal)
        ),
        [""],
        ["Combined Income"].concat(
          currencyFormatList(
            optiList?.[`${spousePrefix}_salary`]?.map(
              (e, i) =>
                e +
                optiList?.[`${spousePrefix}_cpp`]?.[i] +
                optiList?.[`${spousePrefix}_oas`]?.[i] +
                optiList?.[`${spousePrefix}_pension`]?.Benefits?.[i] +
                optiList?.[`${spousePrefix}_otherIncomeTotal`]?.[i] +
                spouseOptiList?.sps2_salary?.[i] +
                spouseOptiList?.sps2_cpp?.[i] +
                spouseOptiList?.sps2_oas?.[i] +
                spouseOptiList?.sps2_pension?.Benefits?.[i] +
                spouseOptiList?.sps2_otherIncomeTotal?.[i] +
                optiList?.IncomePropertyYearlyIncome[i] +
                optiList?.businessYearlyDividends[i]
            )
          )
        ),
        [""],

        [optiList?.[`${spousePrefix}_name`] + " " + "Investments"],
        ["Registered"],
        ["TFSA"].concat(
          currencyFormatList(optiList?.[`${spousePrefix}_tfsaFunds`])
        ),
        ["Deposits"].concat(
          currencyFormatList(optiList?.[`${spousePrefix}_tfsaContributions`])
        ),
        ["Withdrawals"].concat(
          currencyFormatList(optiList?.[`${spousePrefix}_tfsaWithdrawals`])
        ),
        ["Growth"].concat(
          currencyFormatList(optiList?.[`${spousePrefix}_tfsaGrowth`])
        ),
        ["Closing Balance"].concat(
          currencyFormatListShift(
            [...optiList?.[`${spousePrefix}_tfsaFunds`]],
            optiList?.[`${spousePrefix}_tfsaGrowth`][
              optiList?.[`${spousePrefix}_tfsaGrowth`]?.length - 1
            ]
          )
        ),
        [""],
        ["RRSP"].concat(
          currencyFormatList(optiList?.[`${spousePrefix}_registeredFunds`])
        ),
        ["Deposits"].concat(
          currencyFormatList(
            optiList?.[`${spousePrefix}_registeredContributions`]
          )
        ),
        ["Employer Contributions"].concat(
          currencyFormatList(
            optiList?.[`${spousePrefix}_registeredContributionsMatched`]
          )
        ),
        ["Salary Contributions"].concat(
          currencyFormatList(
            optiList?.[`${spousePrefix}_registeredContributionsMatched`]
          )
        ),
        ["Withdrawals"].concat(
          currencyFormatList(
            optiList?.[`${spousePrefix}_registeredWithdrawals`]
          )
        ),
        ["Growth"].concat(
          currencyFormatList(optiList?.[`${spousePrefix}_registeredGrowth`])
        ),
        ["Closing Balance"].concat(
          currencyFormatListShift(
            [...optiList?.[`${spousePrefix}_registeredFunds`]],
            optiList?.[`${spousePrefix}_registeredGrowth`][
              optiList?.[`${spousePrefix}_registeredGrowth`]?.length - 1
            ]
          )
        ),
        [""],
        ["LIRA"].concat(
          currencyFormatList(optiList?.[`${spousePrefix}_liraFunds`])
        ),
        ["Withdrawals"].concat(
          currencyFormatList(optiList?.[`${spousePrefix}_liraWithdrawals`])
        ),
        ["Growth"].concat(
          currencyFormatList(optiList?.[`${spousePrefix}_liraGrowth`])
        ),
        ["Closing Balance"].concat(
          currencyFormatListShift(
            [...optiList?.[`${spousePrefix}_liraFunds`]],
            optiList?.[`${spousePrefix}_liraGrowth`][
              optiList?.[`${spousePrefix}_liraGrowth`]?.length - 1
            ]
          )
        ),
        [""],
        ["Defined Contribution Pension"].concat(
          currencyFormatList(optiList?.[`${spousePrefix}_pension`]?.Value)
        ),
        ["Self Contributions"].concat(
          currencyFormatList(
            optiList?.[`${spousePrefix}_pension`]?.SelfContributions
          )
        ),
        ["Employer Contributions"].concat(
          currencyFormatList(
            optiList?.[`${spousePrefix}_pension`]?.EmployerContributions
          )
        ),
        ["Growth"].concat(
          currencyFormatList(optiList?.[`${spousePrefix}_pension`]?.Growth)
        ),
        ["Closing Balance"],
        [""],
        // ['FHSA'],
        // ['Deposits'],
        // ['Withdrawals'],
        // ['Growth'],
        // ['Closing Balance'],
        // [''],
        ["Non-Registered"].concat(
          currencyFormatList(optiList?.[`${spousePrefix}_nonregisteredFunds`])
        ),
        ["Adjusted Cost Base"].concat(
          currencyFormatList(optiList?.[`${spousePrefix}_acb`])
        ),
        ["Deposits"].concat(
          currencyFormatList(
            optiList?.[`${spousePrefix}_nonRegisteredContributions`]
          )
        ),
        ["Dividends"].concat(
          currencyFormatList(optiList?.[`${spousePrefix}_dividends`])
        ),
        ["Withdrawals"].concat(
          currencyFormatList(
            optiList?.[`${spousePrefix}_nonregisteredWithdrawals`]
          )
        ),
        ["Growth"].concat(
          currencyFormatList(optiList?.[`${spousePrefix}_nonregisteredGrowth`])
        ),
        ["Closing Balance"].concat(
          currencyFormatListShift(
            [...optiList?.[`${spousePrefix}_nonregisteredFunds`]],
            optiList?.[`${spousePrefix}_nonregisteredGrowth`][
              optiList?.[`${spousePrefix}_nonregisteredGrowth`]?.length - 1
            ]
          )
        ),
        ["Fixed Income"].concat(
          currencyFormatList(optiList?.[`${spousePrefix}_fixedIncomeTotal`])
        ),
        ["Withdrawals"].concat(
          currencyFormatList(
            optiList?.[`${spousePrefix}_fixedIncomeWithdrawals`]
          )
        ),
        ["Growth"].concat(
          currencyFormatList(optiList?.[`${spousePrefix}_fixedIncomeGrowth`])
        ),
        [""],
        ["Total Investment Balance"].concat(
          currencyFormatList(
            optiList?.[`${spousePrefix}_tfsaFunds`]?.map(
              (e, i) =>
                e +
                optiList?.[`${spousePrefix}_registeredFunds`]?.[i] +
                optiList?.[`${spousePrefix}_fixedIncomeTotal`]?.[i] +
                optiList?.[`${spousePrefix}_nonregisteredFunds`]?.[i] +
                optiList?.[`${spousePrefix}_liraFunds`]?.[i] +
                optiList?.[`${spousePrefix}_pension`]?.Value?.[i]
            )
          )
        ),
        [""],
        [spouseOptiList?.sps2_name + " " + "Investments"],
        ["Registered"],
        ["TFSA"].concat(currencyFormatList(spouseOptiList?.sps2_tfsaFunds)),
        ["Deposits"].concat(
          currencyFormatList(spouseOptiList?.sps2_tfsaContributions)
        ),
        ["Withdrawals"].concat(
          currencyFormatList(spouseOptiList?.sps2_tfsaWithdrawals)
        ),
        ["Growth"].concat(currencyFormatList(spouseOptiList?.sps2_tfsaGrowth)),
        ["Closing Balance"].concat(
          currencyFormatListShift(
            [...spouseOptiList?.sps2_tfsaFunds],
            spouseOptiList?.sps2_tfsaGrowth[
              spouseOptiList?.sps2_tfsaGrowth?.length - 1
            ]
          )
        ),
        [""],
        ["RRSP"].concat(
          currencyFormatList(spouseOptiList?.sps2_registeredFunds)
        ),
        ["Deposits"].concat(
          currencyFormatList(spouseOptiList?.sps2_registeredContributions)
        ),
        ["Employer Contributions"].concat(
          currencyFormatList(
            spouseOptiList?.sps2_registeredContributionsMatched
          )
        ),
        ["Salary Contributions"].concat(
          currencyFormatList(
            spouseOptiList?.sps2_registeredContributionsMatched
          )
        ),
        ["Withdrawals"].concat(
          currencyFormatList(spouseOptiList?.sps2_registeredWithdrawals)
        ),
        ["Growth"].concat(
          currencyFormatList(spouseOptiList?.sps2_registeredGrowth)
        ),
        ["Closing Balance"].concat(
          currencyFormatListShift(
            [...spouseOptiList?.sps2_registeredFunds],
            spouseOptiList?.sps2_registeredGrowth[
              spouseOptiList?.sps2_registeredGrowth?.length - 1
            ]
          )
        ),
        [""],
        ["LIRA"].concat(currencyFormatList(spouseOptiList?.sps2_liraFunds)),
        ["Withdrawals"].concat(
          currencyFormatList(spouseOptiList?.sps2_liraWithdrawals)
        ),
        ["Growth"].concat(currencyFormatList(spouseOptiList?.sps2_liraGrowth)),
        ["Closing Balance"].concat(
          currencyFormatListShift(
            [...spouseOptiList?.sps2_liraFunds],
            spouseOptiList?.sps2_liraGrowth[
              spouseOptiList?.sps2_liraGrowth?.length - 1
            ]
          )
        ),
        [""],
        ["Defined Contribution Pension"].concat(
          currencyFormatList(spouseOptiList?.sps2_pension?.Value)
        ),
        ["Self Contributions"].concat(
          currencyFormatList(spouseOptiList?.sps2_pension?.SelfContributions)
        ),
        ["Employer Contributions"].concat(
          currencyFormatList(
            spouseOptiList?.sps2_pension?.EmployerContributions
          )
        ),
        ["Growth"].concat(
          currencyFormatList(spouseOptiList?.sps2_pension?.Growth)
        ),
        ["Closing Balance"],
        [""],
        // // ['FHSA'],
        // // ['Deposits'],
        // // ['Withdrawals'],
        // // ['Growth'],
        // // ['Closing Balance'],
        // // [''],
        ["Non-Registered"].concat(
          currencyFormatList(spouseOptiList?.sps2_nonregisteredFunds)
        ),
        ["Adjusted Cost Base"].concat(
          currencyFormatList(spouseOptiList?.sps2_acb)
        ),
        ["Deposits"].concat(
          currencyFormatList(spouseOptiList?.sps2_nonRegisteredContributions)
        ),
        ["Dividends"].concat(
          currencyFormatList(spouseOptiList?.sps2_dividends)
        ),
        ["Withdrawals"].concat(
          currencyFormatList(spouseOptiList?.sps2_nonregisteredWithdrawals)
        ),
        ["Growth"].concat(
          currencyFormatList(spouseOptiList?.sps2_nonregisteredGrowth)
        ),
        ["Closing Balance"].concat(
          currencyFormatListShift(
            [...spouseOptiList?.sps2_nonregisteredFunds],
            spouseOptiList?.sps2_nonregisteredGrowth?.[
              spouseOptiList?.sps2_nonregisteredGrowth?.length - 1
            ]
          )
        ),
        ["Fixed Income"].concat(
          currencyFormatList(spouseOptiList?.sps2_fixedIncomeTotal)
        ),
        ["Withdrawals"].concat(
          currencyFormatList(spouseOptiList?.sps2_fixedIncomeWithdrawals)
        ),
        ["Growth"].concat(
          currencyFormatList(spouseOptiList?.sps2_fixedIncomeGrowth)
        ),
        [""],
        ["Total Investment Balance"].concat(
          currencyFormatList(
            spouseOptiList?.sps2_registeredFunds?.map(
              (e, i) =>
                e +
                spouseOptiList?.sps2_nonregisteredFunds?.[i] +
                spouseOptiList?.sps2_fixedIncomeTotal?.[i] +
                spouseOptiList?.sps2_tfsaFunds?.[i] +
                spouseOptiList?.sps2_liraFunds?.[i] +
                spouseOptiList?.sps2_pension?.Value?.[i]
            )
          )
        ),
        [""],
        ["Combined Investment Balance"].concat(
          currencyFormatList(
            optiList?.[`${spousePrefix}_registeredFunds`]?.map(
              (e, i) =>
                e +
                optiList?.[`${spousePrefix}_nonregisteredFunds`]?.[i] +
                optiList?.[`${spousePrefix}_tfsaFunds`]?.[i] +
                optiList?.[`${spousePrefix}_fixedIncomeTotal`]?.[i] +
                optiList?.[`${spousePrefix}_liraFunds`]?.[i] +
                optiList?.[`${spousePrefix}_pension`]?.Value?.[i] +
                spouseOptiList?.sps2_nonregisteredFunds?.[i] +
                spouseOptiList?.sps2_fixedIncomeTotal?.[i] +
                spouseOptiList?.sps2_tfsaFunds?.[i] +
                spouseOptiList?.sps2_registeredFunds?.[i] +
                spouseOptiList?.sps2_liraFunds?.[i] +
                spouseOptiList?.sps2_pension?.Value?.[i]
            )
          )
        ),
        [""],

        ["Assets"],
        ["Real Estate"],
        ["Primary Residence"].concat(
          currencyFormatList(optiList?.PrimaryResidence?.Value)
        ),
        ["  Growth"].concat(
          currencyFormatList(optiList?.PrimaryResidence?.Growth)
        ),
        ["  Closing Balance"].concat(
          currencyFormatListShift(
            [...optiList?.PrimaryResidence?.Value],
            optiList?.PrimaryResidence?.Growth[
              optiList?.PrimaryResidence?.Growth?.length - 1
            ]
          )
        ),
        ["Recreational Property"].concat(
          currencyFormatList(optiList?.RecreationPropertyYearlyValue)
        ),
        ["  Growth"].concat(
          currencyFormatList(optiList?.RecreationPropertyYearlyGrowth)
        ),
        ["  Closing Balance"].concat(
          currencyFormatListShift(
            [...optiList?.RecreationPropertyYearlyValue],
            optiList?.RecreationPropertyYearlyGrowth[
              optiList?.RecreationPropertyYearlyGrowth?.length - 1
            ]
          )
        ),
        ["Land"].concat(currencyFormatList(optiList?.LandYearlyValue)),
        ["  Growth"].concat(currencyFormatList(optiList?.LandYearlyGrowth)),
        ["  Closing Balance"].concat(
          currencyFormatListShift(
            [...optiList?.LandYearlyValue],
            optiList?.LandYearlyGrowth[optiList?.LandYearlyGrowth?.length - 1]
          )
        ),
        ["Income Property(s)"].concat(
          currencyFormatList(optiList?.IncomePropertyYearlyValue)
        ),
        ["  Growth"].concat(
          currencyFormatList(optiList?.IncomePropertyYearlyGrowth)
        ),
        ["  Closing Balance"].concat(
          currencyFormatListShift(
            [...optiList?.IncomePropertyYearlyValue],
            optiList?.IncomePropertyYearlyGrowth[
              optiList?.IncomePropertyYearlyGrowth?.length - 1
            ]
          )
        ),
        [""],
        ["Life Insurance"].concat(
          currencyFormatList(optiList?.lifeInsuranceYearlyValue)
        ),
        [""],
        ["Business"].concat(currencyFormatList(optiList?.businessYearlyValue)),
        ["Growth"].concat(currencyFormatList(optiList?.businessYearlyGrowth)),
        ["Business Closing Value"].concat(
          currencyFormatListShift([...optiList?.businessYearlyValue])
        ),
        ["CCPC Investments Fund"].concat(
          currencyFormatList(optiList?.ccpcYearlyBalance)
        ),
        ["Growth"].concat(
          currencyFormatList(optiList?.ccpcYearlyInvestmentGrowth)
        ),
        ["Re-Invested Funds From Operations"].concat(
          currencyFormatList(optiList?.ccpcYearlyOperationFunds)
        ),
        ["Issued Capital Dividends"].concat(
          currencyFormatList(optiList?.ccpcYearlyCapitalDividendsIssued)
        ),
        ["Issued Eligible Dividends"].concat(
          currencyFormatList(optiList?.ccpcYearlyEligibleDividendsIssued)
        ),
        ["Issued Non-Eligible Dividends"].concat(
          currencyFormatList(optiList?.ccpcYearlyNonEligibleDividendsIssued)
        ),
        ["Earned Eligible Dividends"].concat(
          currencyFormatList(optiList?.ccpcYearlyEligibleDividendsEarned)
        ),
        ["Earned Non-Eligible Dividends"].concat(
          currencyFormatList(optiList?.ccpcYearlyNonEligibleDividendsEarned)
        ),
        ["Earned Other Dividends"].concat(
          currencyFormatList(optiList?.ccpcYearlyOtherDividendsEarned)
        ),
        ["Eligible Dividend Refund"].concat(
          currencyFormatList(optiList?.ccpcYearlyEligibleDividendRefund)
        ),
        ["Non-Eligible Dividend Refund"].concat(
          currencyFormatList(optiList?.ccpcYearlyNonEligibleDividendRefund)
        ),
        ["CCPC Taxes Paid within Holding Company"].concat(
          currencyFormatList(optiList?.ccpcYearlyTaxesPaid)
        ),
        ["CCPC Investment Fund ACB"].concat(
          currencyFormatList(optiList?.ccpcYearlyACB)
        ),
        ["NERDTOH Tracking Accounts"].concat(
          currencyFormatList(optiList?.ccpcYearlyNERDTOH)
        ),
        ["ERDTOH Tracking Accounts"].concat(
          currencyFormatList(optiList?.ccpcYearlyERDTOH)
        ),
        ["CDA Tracking Accounts"].concat(
          currencyFormatList(optiList?.ccpcYearlyCDA)
        ),
        ["GRIP Tracking Accounts"].concat(
          currencyFormatList(optiList?.ccpcYearlyGRIP)
        ),
        ["CCPC Investment Fund Loans Repaid"].concat(
          currencyFormatList(optiList?.ccpcYearlyShareholderLoanRepayments)
        ),
        ["CCPC Investment Loan Balance"].concat(
          currencyFormatList(optiList?.ccpcYearlyShareholderLoanBalance)
        ),
        [""],
        ["Personal Assets"],
        ["Value"].concat(currencyFormatList(optiList?.otherAssetsYearlyValue)),
        ["Growth"].concat(
          currencyFormatList(optiList?.otherAssetsYearlyGrowth)
        ),
        ["Closing Balance"].concat(
          currencyFormatListShift(
            [...optiList?.otherAssetsYearlyValue],
            optiList?.otherAssetsYearlyGrowth?.[
              optiList?.otherAssetsYearlyGrowth?.length - 1
            ]
          )
        ),
        [""],
        ["Expenses"],
        ["Mortgage(s)"].concat(
          currencyFormatList(
            optiList?.PrimaryResidence?.Payments?.map(
              (e, i) =>
                e +
                optiList?.RecreationPropertyYearlyPayments?.[i] +
                optiList?.LandYearlyPayments?.[i] +
                optiList?.IncomePropertyYearlyPayments?.[i]
            )
          )
        ),
        ["Life Insurance Premiums"].concat(
          currencyFormatList(optiList?.lifeInsuranceYearlyPayments)
        ),
        ["Annual Debt Payments"].concat(
          currencyFormatList(optiList?.totalDebtPayments)
        ),
        ["Living Expenses"].concat(currencyFormatList(optiList?.expenses)),
        ["Extra Spending"].concat(currencyFormatList(optiList?.extraSpending)),
        ["Total Expenses"].concat(
          currencyFormatList(
            optiList?.expenses?.map(
              (e, i) =>
                e +
                optiList?.PrimaryResidence?.Payments?.[i] +
                optiList?.RecreationPropertyYearlyPayments?.[i] +
                optiList?.LandYearlyPayments?.[i] +
                optiList?.IncomePropertyYearlyPayments?.[i] +
                optiList?.totalDebtPayments?.[i] +
                optiList?.extraSpending?.[i] +
                optiList?.lifeInsuranceYearlyPayments?.[i]
            )
          )
        ),
        [""],
        ["Total Debt"].concat(
          currencyFormatList(
            optiList?.PrimaryResidence?.Balance?.map(
              (e, i) =>
                e +
                optiList?.RecreationPropertyYearlyBalance?.[i] +
                optiList?.LandYearlyBalance?.[i] +
                optiList?.IncomePropertyYearlyBalance?.[i] +
                optiList?.totalDebtProrated?.[i]
            )
          )
        ),
        [""],
        [optiList?.[`${spousePrefix}_name`] + " " + "Taxes"],
        ["Provincial Taxes"].concat(
          currencyFormatList(optiList?.[`${spousePrefix}_provincialTaxes`])
        ),
        ["Federal Taxes"].concat(
          currencyFormatList(optiList?.[`${spousePrefix}_federalTaxes`])
        ),
        ["EI Premiums"].concat(
          currencyFormatList(optiList?.[`${spousePrefix}_eiPremiums`])
        ),
        ["CPP Contributions"].concat(
          currencyFormatList(optiList?.[`${spousePrefix}_cppContributions`])
        ),
        ["Deductions"].concat(
          currencyFormatList(optiList?.[`${spousePrefix}_salaryDeductions`])
        ),
        ["Total Taxes"].concat(
          currencyFormatList(
            optiList?.[`${spousePrefix}_incomeTaxes`]?.map(
              (e, i) =>
                e +
                optiList?.[`${spousePrefix}_eiPremiums`]?.[i] +
                optiList?.[`${spousePrefix}_cppContributions`]?.[i]
            )
          )
        ),
        [""],
        [spouseOptiList?.sps2_name + " " + "Taxes"],
        ["Provincial Taxes"].concat(
          currencyFormatList(spouseOptiList?.sps2_provincialTaxes)
        ),
        ["Federal Taxes"].concat(
          currencyFormatList(spouseOptiList?.sps2_federalTaxes)
        ),
        ["EI Premiums"].concat(
          currencyFormatList(spouseOptiList?.sps2_eiPremiums)
        ),
        ["CPP Contributions"].concat(
          currencyFormatList(spouseOptiList?.sps2_cppContributions)
        ),
        ["Deductions"].concat(
          currencyFormatList(spouseOptiList?.sps2_salaryDeductions)
        ),
        ["Total Taxes"].concat(
          currencyFormatList(
            spouseOptiList?.sps2_incomeTaxes?.map(
              (e, i) =>
                e +
                spouseOptiList?.sps2_eiPremiums?.[i] +
                spouseOptiList?.sps2_cppContributions?.[i]
            )
          )
        ),
        [""],
        ["Combined Total Taxes"].concat(
          currencyFormatList(
            optiList?.[`${spousePrefix}_incomeTaxes`]?.map(
              (e, i) =>
                e +
                spouseOptiList?.sps2_incomeTaxes?.[i] +
                optiList?.[`${spousePrefix}_cppContributions`]?.[i] +
                spouseOptiList?.sps2_cppContributions?.[i] +
                optiList?.[`${spousePrefix}_eiPremiums`]?.[i] +
                spouseOptiList?.sps2_eiPremiums?.[i]
            )
          )
        ),
      ];
    } else {
      var data = [];
    }
    return data;
  };

  const optiListToCSVROTSingle = (optiList) => {
    if (optiList?.expenses?.length) {
      var data = [
        ["Rule of Thumb"],
        ["Date of Analysis", formatDate(optiList?.planRunDate)],
        [
          "Spouse 1",
          optiList?.[`${spousePrefix}_name`] +
            " " +
            optiList?.[`${spousePrefix}_lastName`],
          optiList?.[`${spousePrefix}_birthday`]?.toString(),
        ]?.concat(yearHeaders(optiList?.[`${spousePrefix}_birthday`])),
        ["Strategy", passedOptiList?.optimizationType],
        ["CPI", userExpensesInfo?.expenseCPI + "%"],
        [
          "End of Analysis Portfolio Balance",
          currencyFormat(optiList?.optimizedEOLAmount),
        ],
        [
          "End of Analysis Asset Balance",
          currencyFormat(
            optiList?.PrimaryResidence?.Value?.[
              optiList?.PrimaryResidence?.Value?.length - 1
            ] +
              optiList?.RecreationPropertyYearlyValue?.[
                optiList?.RecreationPropertyYearlyValue?.length - 1
              ] +
              optiList?.LandYearlyValue?.[
                optiList?.LandYearlyValue?.length - 1
              ] +
              optiList?.businessYearlyValue?.[
                optiList?.businessYearlyValue?.length - 1
              ] +
              optiList?.IncomePropertyYearlyValue?.[
                optiList?.IncomePropertyYearlyValue?.length - 1
              ] +
              optiList?.otherAssetsYearlyValue?.[
                optiList?.otherAssetsYearlyValue?.length - 1
              ] +
              optiList?.lifeInsuranceYearlyValue?.[
                optiList?.lifeInsuranceYearlyValue?.length - 1
              ]
          ),
        ],
        [
          "Before Tax End of Analysis Total",
          currencyFormat(
            optiList?.optimizedEOLAmount +
              optiList?.PrimaryResidence?.Value?.[
                optiList?.PrimaryResidence?.Value?.length - 1
              ] +
              optiList?.RecreationPropertyYearlyValue?.[
                optiList?.RecreationPropertyYearlyValue?.length - 1
              ] +
              optiList?.LandYearlyValue?.[
                optiList?.LandYearlyValue?.length - 1
              ] +
              optiList?.businessYearlyValue?.[
                optiList?.businessYearlyValue?.length - 1
              ] +
              optiList?.IncomePropertyYearlyValue?.[
                optiList?.IncomePropertyYearlyValue?.length - 1
              ] +
              optiList?.otherAssetsYearlyValue?.[
                optiList?.otherAssetsYearlyValue?.length - 1
              ] +
              optiList?.lifeInsuranceYearlyValue?.[
                optiList?.lifeInsuranceYearlyValue?.length - 1
              ]
          ),
        ],
        [
          "After Tax End of Analysis Balance",
          currencyFormat(
            optiList?.optimizedEOLAmountAfterTax +
              optiList?.PrimaryResidence?.Value?.[
                optiList?.PrimaryResidence?.Value?.length - 1
              ] +
              optiList?.RecreationPropertyYearlyValue?.[
                optiList?.RecreationPropertyYearlyValue?.length - 1
              ] +
              optiList?.LandYearlyValue?.[
                optiList?.LandYearlyValue?.length - 1
              ] +
              optiList?.businessYearlyValue?.[
                optiList?.businessYearlyValue?.length - 1
              ] +
              optiList?.IncomePropertyYearlyValue?.[
                optiList?.IncomePropertyYearlyValue?.length - 1
              ] +
              optiList?.otherAssetsYearlyValue?.[
                optiList?.otherAssetsYearlyValue?.length - 1
              ] +
              optiList?.lifeInsuranceYearlyValue?.[
                optiList?.lifeInsuranceYearlyValue?.length - 1
              ]
          ),
        ],
        [""],
        ["Income"],
        ["Salary"].concat(
          currencyFormatList(optiList?.[`${spousePrefix}_salary`])
        ),
        ["CPP"].concat(currencyFormatList(optiList?.[`${spousePrefix}_cpp`])),
        ["OAS"].concat(currencyFormatList(optiList?.[`${spousePrefix}_oas`])),
        ["Pension"].concat(
          currencyFormatList(optiList?.[`${spousePrefix}_pension`]?.Benefits)
        ),
        ["Rental Income"].concat(
          currencyFormatList(optiList?.IncomePropertyYearlyIncome)
        ),
        ["Business Dividends"].concat(
          currencyFormatList(optiList?.businessYearlyDividends)
        ),
        ["Other Income"].concat(
          currencyFormatList(optiList?.[`${spousePrefix}_otherIncomeTotal`])
        ),
        [""],
        ["Investments"],
        ["Registered"],
        ["TFSA"].concat(
          currencyFormatList(optiList?.[`${spousePrefix}_tfsaFunds`])
        ),
        ["Deposits"].concat(
          currencyFormatList(optiList?.[`${spousePrefix}_tfsaContributions`])
        ),
        ["Withdrawals"].concat(
          currencyFormatList(optiList?.[`${spousePrefix}_tfsaWithdrawals`])
        ),
        ["Growth"].concat(
          currencyFormatList(optiList?.[`${spousePrefix}_tfsaGrowth`])
        ),
        ["Closing Balance"].concat(
          currencyFormatListShift(
            [...optiList?.[`${spousePrefix}_tfsaFunds`]],
            optiList?.[`${spousePrefix}_tfsaGrowth`][
              optiList?.[`${spousePrefix}_tfsaGrowth`]?.length - 1
            ]
          )
        ),
        [""],
        ["RRSP"].concat(
          currencyFormatList(optiList?.[`${spousePrefix}_registeredFunds`])
        ),
        ["Deposits"].concat(
          currencyFormatList(
            optiList?.[`${spousePrefix}_registeredContributions`]
          )
        ),
        ["Employer Contributions"].concat(
          currencyFormatList(
            optiList?.[`${spousePrefix}_registeredContributionsMatched`]
          )
        ),
        ["Salary Contributions"].concat(
          currencyFormatList(
            optiList?.[`${spousePrefix}_registeredContributionsMatched`]
          )
        ),
        ["Withdrawals"].concat(
          currencyFormatList(
            optiList?.[`${spousePrefix}_registeredWithdrawals`]
          )
        ),
        ["Growth"].concat(
          currencyFormatList(optiList?.[`${spousePrefix}_registeredGrowth`])
        ),
        ["Closing Balance"].concat(
          currencyFormatListShift(
            [...optiList?.[`${spousePrefix}_registeredFunds`]],
            optiList?.[`${spousePrefix}_registeredGrowth`][
              optiList?.[`${spousePrefix}_registeredGrowth`]?.length - 1
            ]
          )
        ),
        [""],
        ["LIRA"].concat(
          currencyFormatList(optiList?.[`${spousePrefix}_liraFunds`])
        ),
        ["Withdrawals"].concat(
          currencyFormatList(optiList?.[`${spousePrefix}_liraWithdrawals`])
        ),
        ["Growth"].concat(
          currencyFormatList(optiList?.[`${spousePrefix}_liraGrowth`])
        ),
        ["Closing Balance"].concat(
          currencyFormatListShift(
            [...optiList?.[`${spousePrefix}_liraFunds`]],
            optiList?.[`${spousePrefix}_liraGrowth`][
              optiList?.[`${spousePrefix}_liraGrowth`]?.length - 1
            ]
          )
        ),
        [""],
        ["Defined Contribution Pension"].concat(
          currencyFormatList(optiList?.[`${spousePrefix}_pension`]?.Value)
        ),
        ["Self Contributions"].concat(
          currencyFormatList(
            optiList?.[`${spousePrefix}_pension`]?.SelfContributions
          )
        ),
        ["Employer Contributions"].concat(
          currencyFormatList(
            optiList?.[`${spousePrefix}_pension`]?.EmployerContributions
          )
        ),
        ["Growth"].concat(
          currencyFormatList(optiList?.[`${spousePrefix}_pension`]?.Growth)
        ),
        ["Closing Balance"],
        [""],
        ["Non-Registered"].concat(
          currencyFormatList(optiList?.[`${spousePrefix}_nonregisteredFunds`])
        ),
        ["Adjusted Cost Base"].concat(
          currencyFormatList(optiList?.[`${spousePrefix}_acb`])
        ),
        ["Deposits"].concat(
          currencyFormatList(
            optiList?.[`${spousePrefix}_nonRegisteredContributions`]
          )
        ),
        ["Dividends"].concat(
          currencyFormatList(optiList?.[`${spousePrefix}_dividends`])
        ),
        ["Withdrawals"].concat(
          currencyFormatList(
            optiList?.[`${spousePrefix}_nonregisteredWithdrawals`]
          )
        ),
        ["Growth"].concat(
          currencyFormatList(optiList?.[`${spousePrefix}_nonregisteredGrowth`])
        ),
        ["Closing Balance"].concat(
          currencyFormatListShift(
            [...optiList?.[`${spousePrefix}_nonregisteredFunds`]],
            optiList?.[`${spousePrefix}_nonregisteredGrowth`][
              optiList?.[`${spousePrefix}_nonregisteredGrowth`]?.length - 1
            ]
          )
        ),
        ["Fixed Income"].concat(
          currencyFormatList(optiList?.[`${spousePrefix}_fixedIncomeTotal`])
        ),
        ["Withdrawals"].concat(
          currencyFormatList(
            optiList?.[`${spousePrefix}_fixedIncomeWithdrawals`]
          )
        ),
        ["Growth"].concat(
          currencyFormatList(optiList?.[`${spousePrefix}_fixedIncomeGrowth`])
        ),
        [""],
        ["Total Investment Balance"].concat(
          currencyFormatList(
            optiList?.[`${spousePrefix}_tfsaFunds`]?.map(
              (e, i) =>
                e +
                optiList?.[`${spousePrefix}_registeredFunds`]?.[i] +
                optiList?.[`${spousePrefix}_fixedIncomeTotal`]?.[i] +
                optiList?.[`${spousePrefix}_nonregisteredFunds`]?.[i] +
                optiList?.[`${spousePrefix}_liraFunds`]?.[i] +
                optiList?.[`${spousePrefix}_pension`]?.Value?.[i]
            )
          )
        ),
        [""],
        ["Assets"],
        ["Real Estate"],
        ["Primary Residence"].concat(
          currencyFormatList(optiList?.PrimaryResidence?.Value)
        ),
        ["  Growth"].concat(
          currencyFormatList(optiList?.PrimaryResidence?.Growth)
        ),
        ["  Closing Balance"].concat(
          currencyFormatListShift(
            [...optiList?.PrimaryResidence?.Value],
            optiList?.PrimaryResidence?.Growth[
              optiList?.PrimaryResidence?.Growth?.length - 1
            ]
          )
        ),
        ["Recreational Property"].concat(
          currencyFormatList(optiList?.RecreationPropertyYearlyValue)
        ),
        ["  Growth"].concat(
          currencyFormatList(optiList?.RecreationPropertyYearlyGrowth)
        ),
        ["  Closing Balance"].concat(
          currencyFormatListShift(
            [...optiList?.RecreationPropertyYearlyValue],
            optiList?.RecreationPropertyYearlyGrowth[
              optiList?.RecreationPropertyYearlyGrowth?.length - 1
            ]
          )
        ),
        ["Land"].concat(currencyFormatList(optiList?.LandYearlyValue)),
        ["  Growth"].concat(currencyFormatList(optiList?.LandYearlyGrowth)),
        ["  Closing Balance"].concat(
          currencyFormatListShift(
            [...optiList?.LandYearlyValue],
            optiList?.LandYearlyGrowth[optiList?.LandYearlyGrowth?.length - 1]
          )
        ),
        ["Income Property(s)"].concat(
          currencyFormatList(optiList?.IncomePropertyYearlyValue)
        ),
        ["  Growth"].concat(
          currencyFormatList(optiList?.IncomePropertyYearlyGrowth)
        ),
        ["  Closing Balance"].concat(
          currencyFormatListShift(
            [...optiList?.IncomePropertyYearlyValue],
            optiList?.IncomePropertyYearlyGrowth[
              optiList?.IncomePropertyYearlyGrowth?.length - 1
            ]
          )
        ),
        [""],
        ["Life Insurance"].concat(
          currencyFormatList(optiList?.lifeInsuranceYearlyValue)
        ),
        [""],
        ["Business"].concat(currencyFormatList(optiList?.businessYearlyValue)),
        ["Growth"].concat(currencyFormatList(optiList?.businessYearlyGrowth)),
        ["Business Closing Value"].concat(
          currencyFormatListShift([...optiList?.businessYearlyValue])
        ),
        ["CCPC Investments Fund"].concat(
          currencyFormatList(optiList?.ccpcYearlyBalance)
        ),
        ["Growth"].concat(
          currencyFormatList(optiList?.ccpcYearlyInvestmentGrowth)
        ),
        ["Re-Invested Funds From Operations"].concat(
          currencyFormatList(optiList?.ccpcYearlyOperationFunds)
        ),
        ["Issued Capital Dividends"].concat(
          currencyFormatList(optiList?.ccpcYearlyCapitalDividendsIssued)
        ),
        ["Issued Eligible Dividends"].concat(
          currencyFormatList(optiList?.ccpcYearlyEligibleDividendsIssued)
        ),
        ["Issued Non-Eligible Dividends"].concat(
          currencyFormatList(optiList?.ccpcYearlyNonEligibleDividendsIssued)
        ),
        ["Earned Eligible Dividends"].concat(
          currencyFormatList(optiList?.ccpcYearlyEligibleDividendsEarned)
        ),
        ["Earned Non-Eligible Dividends"].concat(
          currencyFormatList(optiList?.ccpcYearlyNonEligibleDividendsEarned)
        ),
        ["Earned Other Dividends"].concat(
          currencyFormatList(optiList?.ccpcYearlyOtherDividendsEarned)
        ),
        ["Eligible Dividend Refund"].concat(
          currencyFormatList(optiList?.ccpcYearlyEligibleDividendRefund)
        ),
        ["Non-Eligible Dividend Refund"].concat(
          currencyFormatList(optiList?.ccpcYearlyNonEligibleDividendRefund)
        ),
        ["CCPC Taxes Paid within Holding Company"].concat(
          currencyFormatList(optiList?.ccpcYearlyTaxesPaid)
        ),
        ["CCPC Investment Fund ACB"].concat(
          currencyFormatList(optiList?.ccpcYearlyACB)
        ),
        ["NERDTOH Tracking Accounts"].concat(
          currencyFormatList(optiList?.ccpcYearlyNERDTOH)
        ),
        ["ERDTOH Tracking Accounts"].concat(
          currencyFormatList(optiList?.ccpcYearlyERDTOH)
        ),
        ["CDA Tracking Accounts"].concat(
          currencyFormatList(optiList?.ccpcYearlyCDA)
        ),
        ["GRIP Tracking Accounts"].concat(
          currencyFormatList(optiList?.ccpcYearlyGRIP)
        ),
        ["CCPC Investment Fund Loans Repaid"].concat(
          currencyFormatList(optiList?.ccpcYearlyShareholderLoanRepayments)
        ),
        ["CCPC Investment Loan Balance"].concat(
          currencyFormatList(optiList?.ccpcYearlyShareholderLoanBalance)
        ),
        [""],
        ["Personal Assets"],
        ["Value"].concat(currencyFormatList(optiList?.otherAssetsYearlyValue)),
        ["Growth"].concat(
          currencyFormatList(optiList?.otherAssetsYearlyGrowth)
        ),
        ["Closing Balance"].concat(
          currencyFormatListShift(
            [...optiList?.otherAssetsYearlyValue],
            optiList?.otherAssetsYearlyGrowth?.[
              optiList?.otherAssetsYearlyGrowth?.length - 1
            ]
          )
        ),
        [""],
        ["Expenses"],
        ["Mortgage(s)"].concat(
          currencyFormatList(
            optiList?.PrimaryResidence?.Payments?.map(
              (e, i) =>
                e +
                optiList?.RecreationPropertyYearlyPayments?.[i] +
                optiList?.LandYearlyPayments?.[i] +
                optiList?.IncomePropertyYearlyPayments?.[i]
            )
          )
        ),
        ["Life Insurance Premiums"].concat(
          currencyFormatList(optiList?.lifeInsuranceYearlyPayments)
        ),
        ["Annual Debt Payments"].concat(
          currencyFormatList(optiList?.totalDebtPayments)
        ),
        ["Living Expenses"].concat(currencyFormatList(optiList?.expenses)),
        ["Extra Spending"].concat(currencyFormatList(optiList?.extraSpending)),
        ["Total Expenses"].concat(
          currencyFormatList(
            optiList?.expenses?.map(
              (e, i) =>
                e +
                optiList?.PrimaryResidence?.Payments?.[i] +
                optiList?.RecreationPropertyYearlyPayments?.[i] +
                optiList?.LandYearlyPayments?.[i] +
                optiList?.IncomePropertyYearlyPayments?.[i] +
                optiList?.totalDebtPayments?.[i] +
                optiList?.extraSpending?.[i] +
                optiList?.lifeInsuranceYearlyPayments?.[i]
            )
          )
        ),
        [""],
        ["Total Debt"].concat(
          currencyFormatList(
            optiList?.PrimaryResidence?.Balance?.map(
              (e, i) =>
                e +
                optiList?.RecreationPropertyYearlyBalance?.[i] +
                optiList?.LandYearlyBalance?.[i] +
                optiList?.IncomePropertyYearlyBalance?.[i] +
                optiList?.totalDebtProrated?.[i]
            )
          )
        ),
        [""],
        ["Taxes"],
        ["Provincial Taxes"].concat(
          currencyFormatList(optiList?.[`${spousePrefix}_provincialTaxes`])
        ),
        ["Federal Taxes"].concat(
          currencyFormatList(optiList?.[`${spousePrefix}_federalTaxes`])
        ),
        ["EI Premiums"].concat(
          currencyFormatList(optiList?.[`${spousePrefix}_eiPremiums`])
        ),
        ["CPP Contributions"].concat(
          currencyFormatList(optiList?.[`${spousePrefix}_cppContributions`])
        ),
        ["Deductions"].concat(
          currencyFormatList(optiList?.[`${spousePrefix}_salaryDeductions`])
        ),
        ["Total Taxes"].concat(
          currencyFormatList(
            optiList?.[`${spousePrefix}_incomeTaxes`]?.map(
              (e, i) =>
                e +
                optiList?.[`${spousePrefix}_eiPremiums`]?.[i] +
                optiList?.[`${spousePrefix}_cppContributions`]?.[i]
            )
          )
        ),
        [""],
        ["Net Worth"],
      ];
    } else {
      var data = [];
    }
    return data;
  };

  const optiListToCSVOptimlVsROT = (
    optiList,
    spouseOptiList,
    spouse1ROT,
    spouse2ROT
  ) => {
    var data = [
      ["Optml vs. Rule Of Thumb"],
      ["Date of Analysis", formatDate(optiList?.planRunDate)],
      [
        "Spouse 1",
        optiList?.[`${spousePrefix}_name`] +
          " " +
          optiList?.[`${spousePrefix}_lastName`],
        optiList?.[`${spousePrefix}_birthday`]?.toString(),
      ]?.concat(yearHeaders(optiList?.[`${spousePrefix}_birthday`])),
      [
        "Spouse 2",
        spouseOptiList?.sps2_name + " " + spouseOptiList?.sps2_lastName,
        spouseOptiList?.sps2_birthday?.toString(),
      ]?.concat(yearHeaders(spouseOptiList?.sps2_birthday)),
      ["Strategy", passedOptiList?.optimizationType],
      ["CPI", userExpensesInfo?.expenseCPI + "%"],
      [
        "End of Analysis Portfolio Balance",
        currencyFormat(optiList?.optimizedEOLAmount),
      ],
      [
        "End of Analysis Asset Balance",
        currencyFormat(
          optiList?.PrimaryResidence?.Value?.[
            optiList?.PrimaryResidence?.Value?.length - 1
          ] +
            optiList?.RecreationPropertyYearlyValue?.[
              optiList?.RecreationPropertyYearlyValue?.length - 1
            ] +
            optiList?.LandYearlyValue?.[optiList?.LandYearlyValue?.length - 1] +
            optiList?.businessYearlyValue?.[
              optiList?.businessYearlyValue?.length - 1
            ] +
            optiList?.IncomePropertyYearlyValue?.[
              optiList?.IncomePropertyYearlyValue?.length - 1
            ] +
            optiList?.otherAssetsYearlyValue?.[
              optiList?.otherAssetsYearlyValue?.length - 1
            ] +
            optiList?.lifeInsuranceYearlyValue?.[
              optiList?.lifeInsuranceYearlyValue?.length - 1
            ]
        ),
      ],
      [
        "Before Tax End of Analysis Total",
        currencyFormat(
          optiList?.optimizedEOLAmount +
            optiList?.PrimaryResidence?.Value?.[
              optiList?.PrimaryResidence?.Value?.length - 1
            ] +
            optiList?.RecreationPropertyYearlyValue?.[
              optiList?.RecreationPropertyYearlyValue?.length - 1
            ] +
            optiList?.LandYearlyValue?.[optiList?.LandYearlyValue?.length - 1] +
            optiList?.businessYearlyValue?.[
              optiList?.businessYearlyValue?.length - 1
            ] +
            optiList?.IncomePropertyYearlyValue?.[
              optiList?.IncomePropertyYearlyValue?.length - 1
            ] +
            optiList?.otherAssetsYearlyValue?.[
              optiList?.otherAssetsYearlyValue?.length - 1
            ] +
            optiList?.lifeInsuranceYearlyValue?.[
              optiList?.lifeInsuranceYearlyValue?.length - 1
            ]
        ),
      ],
      [
        "After Tax End of Analysis Balance",
        currencyFormat(
          optiList?.optimizedEOLAmountAfterTax +
            optiList?.PrimaryResidence?.Value?.[
              optiList?.PrimaryResidence?.Value?.length - 1
            ] +
            optiList?.RecreationPropertyYearlyValue?.[
              optiList?.RecreationPropertyYearlyValue?.length - 1
            ] +
            optiList?.LandYearlyValue?.[optiList?.LandYearlyValue?.length - 1] +
            optiList?.businessYearlyValue?.[
              optiList?.businessYearlyValue?.length - 1
            ] +
            optiList?.IncomePropertyYearlyValue?.[
              optiList?.IncomePropertyYearlyValue?.length - 1
            ] +
            optiList?.otherAssetsYearlyValue?.[
              optiList?.otherAssetsYearlyValue?.length - 1
            ] +
            optiList?.lifeInsuranceYearlyValue?.[
              optiList?.lifeInsuranceYearlyValue?.length - 1
            ]
        ),
      ],
      [""],

      [optiList?.[`${spousePrefix}_name`] + " " + "Income"],
      ["Salary"].concat(
        currencyFormatList(optiList?.[`${spousePrefix}_salary`])
      ),
      ["CPP"].concat(currencyFormatList(optiList?.[`${spousePrefix}_cpp`])),
      ["OAS"].concat(currencyFormatList(optiList?.[`${spousePrefix}_oas`])),
      ["Pension"].concat(
        currencyFormatList(optiList?.[`${spousePrefix}_pension`]?.Benefits)
      ),
      ["Rental Income"].concat(
        currencyFormatList(optiList?.IncomePropertyYearlyIncome)
      ),
      ["Business Dividends"].concat(
        currencyFormatList(optiList?.businessYearlyDividends)
      ),
      ["Other Income"].concat(
        currencyFormatList(optiList?.[`${spousePrefix}_otherIncomeTotal`])
      ),
      [""],
      [spouseOptiList?.sps2_name + " " + "Income"],
      ["Salary"].concat(currencyFormatList(spouseOptiList?.sps2_salary)),
      ["CPP"].concat(currencyFormatList(spouseOptiList?.sps2_cpp)),
      ["OAS"].concat(currencyFormatList(spouseOptiList?.sps2_oas)),
      ["Pension"].concat(
        currencyFormatList(spouseOptiList?.sps2_pension?.Benefits)
      ),
      ["Other"].concat(
        currencyFormatList(spouseOptiList?.sps2_otherIncomeTotal)
      ),
      [""],
      ["Combined Income"].concat(
        currencyFormatList(
          optiList?.[`${spousePrefix}_salary`]?.map(
            (e, i) =>
              e +
              optiList?.[`${spousePrefix}_cpp`]?.[i] +
              optiList?.[`${spousePrefix}_oas`]?.[i] +
              optiList?.[`${spousePrefix}_pension`]?.Benefits?.[i] +
              optiList?.[`${spousePrefix}_otherIncomeTotal`]?.[i] +
              spouseOptiList?.sps2_salary?.[i] +
              spouseOptiList?.sps2_cpp?.[i] +
              spouseOptiList?.sps2_oas?.[i] +
              spouseOptiList?.sps2_pension?.Benefits?.[i] +
              spouseOptiList?.sps2_otherIncomeTotal?.[i] +
              optiList?.IncomePropertyYearlyIncome[i] +
              optiList?.businessYearlyDividends[i]
          )
        )
      ),
      [""],

      [optiList?.[`${spousePrefix}_name`] + " " + "Investments"],
      ["Registered"],
      ["TFSA"].concat(
        currencyFormatList(optiList?.[`${spousePrefix}_tfsaFunds`])
      ),
      ["Deposits"].concat(
        currencyFormatList(optiList?.[`${spousePrefix}_tfsaContributions`])
      ),
      ["Withdrawals"].concat(
        currencyFormatList(optiList?.[`${spousePrefix}_tfsaWithdrawals`])
      ),
      ["Growth"].concat(
        currencyFormatList(optiList?.[`${spousePrefix}_tfsaGrowth`])
      ),
      ["Closing Balance"].concat(
        currencyFormatListShift(
          [...optiList?.[`${spousePrefix}_tfsaFunds`]],
          optiList?.[`${spousePrefix}_tfsaGrowth`][
            optiList?.[`${spousePrefix}_tfsaGrowth`]?.length - 1
          ]
        )
      ),
      [""],
      ["RRSP"].concat(
        currencyFormatList(optiList?.[`${spousePrefix}_registeredFunds`])
      ),
      ["Deposits"].concat(
        currencyFormatList(
          optiList?.[`${spousePrefix}_registeredContributions`]
        )
      ),
      ["Employer Contributions"].concat(
        currencyFormatList(
          optiList?.[`${spousePrefix}_registeredContributionsMatched`]
        )
      ),
      ["Salary Contributions"].concat(
        currencyFormatList(
          optiList?.[`${spousePrefix}_registeredContributionsMatched`]
        )
      ),
      ["Withdrawals"].concat(
        currencyFormatList(optiList?.[`${spousePrefix}_registeredWithdrawals`])
      ),
      ["Growth"].concat(
        currencyFormatList(optiList?.[`${spousePrefix}_registeredGrowth`])
      ),
      ["Closing Balance"].concat(
        currencyFormatListShift(
          [...optiList?.[`${spousePrefix}_registeredFunds`]],
          optiList?.[`${spousePrefix}_registeredGrowth`][
            optiList?.[`${spousePrefix}_registeredGrowth`]?.length - 1
          ]
        )
      ),
      [""],
      ["LIRA"].concat(
        currencyFormatList(optiList?.[`${spousePrefix}_liraFunds`])
      ),
      ["Withdrawals"].concat(
        currencyFormatList(optiList?.[`${spousePrefix}_liraWithdrawals`])
      ),
      ["Growth"].concat(
        currencyFormatList(optiList?.[`${spousePrefix}_liraGrowth`])
      ),
      ["Closing Balance"].concat(
        currencyFormatListShift(
          [...optiList?.[`${spousePrefix}_liraFunds`]],
          optiList?.[`${spousePrefix}_liraGrowth`][
            optiList?.[`${spousePrefix}_liraGrowth`]?.length - 1
          ]
        )
      ),
      [""],
      ["Defined Contribution Pension"].concat(
        currencyFormatList(optiList?.[`${spousePrefix}_pension`]?.Value)
      ),
      ["Self Contributions"].concat(
        currencyFormatList(
          optiList?.[`${spousePrefix}_pension`]?.SelfContributions
        )
      ),
      ["Employer Contributions"].concat(
        currencyFormatList(
          optiList?.[`${spousePrefix}_pension`]?.EmployerContributions
        )
      ),
      ["Growth"].concat(
        currencyFormatList(optiList?.[`${spousePrefix}_pension`]?.Growth)
      ),
      ["Closing Balance"],
      [""],
      // ['FHSA'],
      // ['Deposits'],
      // ['Withdrawals'],
      // ['Growth'],
      // ['Closing Balance'],
      // [''],
      ["Non-Registered"].concat(
        currencyFormatList(optiList?.[`${spousePrefix}_nonregisteredFunds`])
      ),
      ["Adjusted Cost Base"].concat(
        currencyFormatList(optiList?.[`${spousePrefix}_acb`])
      ),
      ["Deposits"].concat(
        currencyFormatList(
          optiList?.[`${spousePrefix}_nonRegisteredContributions`]
        )
      ),
      ["Dividends"].concat(
        currencyFormatList(optiList?.[`${spousePrefix}_dividends`])
      ),
      ["Withdrawals"].concat(
        currencyFormatList(
          optiList?.[`${spousePrefix}_nonregisteredWithdrawals`]
        )
      ),
      ["Growth"].concat(
        currencyFormatList(optiList?.[`${spousePrefix}_nonregisteredGrowth`])
      ),
      ["Closing Balance"].concat(
        currencyFormatListShift(
          [...optiList?.[`${spousePrefix}_nonregisteredFunds`]],
          optiList?.[`${spousePrefix}_nonregisteredGrowth`][
            optiList?.[`${spousePrefix}_nonregisteredGrowth`]?.length - 1
          ]
        )
      ),
      ["Fixed Income"].concat(
        currencyFormatList(optiList?.[`${spousePrefix}_fixedIncomeTotal`])
      ),
      ["Withdrawals"].concat(
        currencyFormatList(optiList?.[`${spousePrefix}_fixedIncomeWithdrawals`])
      ),
      ["Growth"].concat(
        currencyFormatList(optiList?.[`${spousePrefix}_fixedIncomeGrowth`])
      ),
      [""],
      ["Total Investment Balance"].concat(
        currencyFormatList(
          optiList?.[`${spousePrefix}_tfsaFunds`]?.map(
            (e, i) =>
              e +
              optiList?.[`${spousePrefix}_registeredFunds`]?.[i] +
              optiList?.[`${spousePrefix}_fixedIncomeTotal`]?.[i] +
              optiList?.[`${spousePrefix}_nonregisteredFunds`]?.[i] +
              optiList?.[`${spousePrefix}_liraFunds`]?.[i] +
              optiList?.[`${spousePrefix}_pension`]?.Value?.[i]
          )
        )
      ),
      [""],
      [spouseOptiList?.sps2_name + " " + "Investments"],
      ["Registered"],
      ["TFSA"].concat(currencyFormatList(spouseOptiList?.sps2_tfsaFunds)),
      ["Deposits"].concat(
        currencyFormatList(spouseOptiList?.sps2_tfsaContributions)
      ),
      ["Withdrawals"].concat(
        currencyFormatList(spouseOptiList?.sps2_tfsaWithdrawals)
      ),
      ["Growth"].concat(currencyFormatList(spouseOptiList?.sps2_tfsaGrowth)),
      ["Closing Balance"].concat(
        currencyFormatListShift(
          [...spouseOptiList?.sps2_tfsaFunds],
          spouseOptiList?.sps2_tfsaGrowth[
            spouseOptiList?.sps2_tfsaGrowth?.length - 1
          ]
        )
      ),
      [""],
      ["RRSP"].concat(currencyFormatList(spouseOptiList?.sps2_registeredFunds)),
      ["Deposits"].concat(
        currencyFormatList(spouseOptiList?.sps2_registeredContributions)
      ),
      ["Employer Contributions"].concat(
        currencyFormatList(spouseOptiList?.sps2_registeredContributionsMatched)
      ),
      ["Salary Contributions"].concat(
        currencyFormatList(spouseOptiList?.sps2_registeredContributionsMatched)
      ),
      ["Withdrawals"].concat(
        currencyFormatList(spouseOptiList?.sps2_registeredWithdrawals)
      ),
      ["Growth"].concat(
        currencyFormatList(spouseOptiList?.sps2_registeredGrowth)
      ),
      ["Closing Balance"].concat(
        currencyFormatListShift(
          [...spouseOptiList?.sps2_registeredFunds],
          spouseOptiList?.sps2_registeredGrowth[
            spouseOptiList?.sps2_registeredGrowth?.length - 1
          ]
        )
      ),
      [""],
      ["LIRA"].concat(currencyFormatList(spouseOptiList?.sps2_liraFunds)),
      ["Withdrawals"].concat(
        currencyFormatList(spouseOptiList?.sps2_liraWithdrawals)
      ),
      ["Growth"].concat(currencyFormatList(spouseOptiList?.sps2_liraGrowth)),
      ["Closing Balance"].concat(
        currencyFormatListShift(
          [...spouseOptiList?.sps2_liraFunds],
          spouseOptiList?.sps2_liraGrowth[
            spouseOptiList?.sps2_liraGrowth?.length - 1
          ]
        )
      ),
      [""],
      ["Defined Contribution Pension"].concat(
        currencyFormatList(spouseOptiList?.sps2_pension?.Value)
      ),
      ["Self Contributions"].concat(
        currencyFormatList(spouseOptiList?.sps2_pension?.SelfContributions)
      ),
      ["Employer Contributions"].concat(
        currencyFormatList(spouseOptiList?.sps2_pension?.EmployerContributions)
      ),
      ["Growth"].concat(
        currencyFormatList(spouseOptiList?.sps2_pension?.Growth)
      ),
      ["Closing Balance"],
      [""],
      // // ['FHSA'],
      // // ['Deposits'],
      // // ['Withdrawals'],
      // // ['Growth'],
      // // ['Closing Balance'],
      // // [''],
      ["Non-Registered"].concat(
        currencyFormatList(spouseOptiList?.sps2_nonregisteredFunds)
      ),
      ["Adjusted Cost Base"].concat(
        currencyFormatList(spouseOptiList?.sps2_acb)
      ),
      ["Deposits"].concat(
        currencyFormatList(spouseOptiList?.sps2_nonRegisteredContributions)
      ),
      ["Dividends"].concat(currencyFormatList(spouseOptiList?.sps2_dividends)),
      ["Withdrawals"].concat(
        currencyFormatList(spouseOptiList?.sps2_nonregisteredWithdrawals)
      ),
      ["Growth"].concat(
        currencyFormatList(spouseOptiList?.sps2_nonregisteredGrowth)
      ),
      ["Closing Balance"].concat(
        currencyFormatListShift(
          [...spouseOptiList?.sps2_nonregisteredFunds],
          spouseOptiList?.sps2_nonregisteredGrowth?.[
            spouseOptiList?.sps2_nonregisteredGrowth?.length - 1
          ]
        )
      ),
      ["Fixed Income"].concat(
        currencyFormatList(spouseOptiList?.sps2_fixedIncomeTotal)
      ),
      ["Withdrawals"].concat(
        currencyFormatList(spouseOptiList?.sps2_fixedIncomeWithdrawals)
      ),
      ["Growth"].concat(
        currencyFormatList(spouseOptiList?.sps2_fixedIncomeGrowth)
      ),
      [""],
      ["Total Investment Balance"].concat(
        currencyFormatList(
          spouseOptiList?.sps2_registeredFunds?.map(
            (e, i) =>
              e +
              spouseOptiList?.sps2_nonregisteredFunds?.[i] +
              spouseOptiList?.sps2_fixedIncomeTotal?.[i] +
              spouseOptiList?.sps2_tfsaFunds?.[i] +
              spouseOptiList?.sps2_liraFunds?.[i] +
              spouseOptiList?.sps2_pension?.Value?.[i]
          )
        )
      ),
      [""],
      ["Combined Investment Balance"].concat(
        currencyFormatList(
          optiList?.[`${spousePrefix}_registeredFunds`]?.map(
            (e, i) =>
              e +
              optiList?.[`${spousePrefix}_nonregisteredFunds`]?.[i] +
              optiList?.[`${spousePrefix}_tfsaFunds`]?.[i] +
              optiList?.[`${spousePrefix}_fixedIncomeTotal`]?.[i] +
              optiList?.[`${spousePrefix}_liraFunds`]?.[i] +
              optiList?.[`${spousePrefix}_pension`]?.Value?.[i] +
              spouseOptiList?.sps2_nonregisteredFunds?.[i] +
              spouseOptiList?.sps2_tfsaFunds?.[i] +
              spouseOptiList?.sps2_fixedIncomeTotal?.[i] +
              spouseOptiList?.sps2_registeredFunds?.[i] +
              spouseOptiList?.sps2_liraFunds?.[i] +
              spouseOptiList?.sps2_pension?.Value?.[i]
          )
        )
      ),
      [""],

      ["Assets"],
      ["Real Estate"],
      ["Primary Residence"].concat(
        currencyFormatList(optiList?.PrimaryResidence?.Value)
      ),
      ["  Growth"].concat(
        currencyFormatList(optiList?.PrimaryResidence?.Growth)
      ),
      ["  Closing Balance"].concat(
        currencyFormatListShift(
          [...optiList?.PrimaryResidence?.Value],
          optiList?.PrimaryResidence?.Growth[
            optiList?.PrimaryResidence?.Growth?.length - 1
          ]
        )
      ),
      ["Recreational Property"].concat(
        currencyFormatList(optiList?.RecreationPropertyYearlyValue)
      ),
      ["  Growth"].concat(
        currencyFormatList(optiList?.RecreationPropertyYearlyGrowth)
      ),
      ["  Closing Balance"].concat(
        currencyFormatListShift(
          [...optiList?.RecreationPropertyYearlyValue],
          optiList?.RecreationPropertyYearlyGrowth[
            optiList?.RecreationPropertyYearlyGrowth?.length - 1
          ]
        )
      ),
      ["Land"].concat(currencyFormatList(optiList?.LandYearlyValue)),
      ["  Growth"].concat(currencyFormatList(optiList?.LandYearlyGrowth)),
      ["  Closing Balance"].concat(
        currencyFormatListShift(
          [...optiList?.LandYearlyValue],
          optiList?.LandYearlyGrowth[optiList?.LandYearlyGrowth?.length - 1]
        )
      ),
      ["Income Property(s)"].concat(
        currencyFormatList(optiList?.IncomePropertyYearlyValue)
      ),
      ["  Growth"].concat(
        currencyFormatList(optiList?.IncomePropertyYearlyGrowth)
      ),
      ["  Closing Balance"].concat(
        currencyFormatListShift(
          [...optiList?.IncomePropertyYearlyValue],
          optiList?.IncomePropertyYearlyGrowth[
            optiList?.IncomePropertyYearlyGrowth?.length - 1
          ]
        )
      ),
      [""],
      ["Life Insurance"].concat(
        currencyFormatList(optiList?.lifeInsuranceYearlyValue)
      ),
      [""],
      ["Business"].concat(currencyFormatList(optiList?.businessYearlyValue)),
      ["Growth"].concat(currencyFormatList(optiList?.businessYearlyGrowth)),
      ["Business Closing Value"].concat(
        currencyFormatListShift([...optiList?.businessYearlyValue])
      ),
      ["CCPC Investments Fund"].concat(
        currencyFormatList(optiList?.ccpcYearlyBalance)
      ),
      ["Growth"].concat(
        currencyFormatList(optiList?.ccpcYearlyInvestmentGrowth)
      ),
      ["Re-Invested Funds From Operations"].concat(
        currencyFormatList(optiList?.ccpcYearlyOperationFunds)
      ),
      ["Issued Capital Dividends"].concat(
        currencyFormatList(optiList?.ccpcYearlyCapitalDividendsIssued)
      ),
      ["Issued Eligible Dividends"].concat(
        currencyFormatList(optiList?.ccpcYearlyEligibleDividendsIssued)
      ),
      ["Issued Non-Eligible Dividends"].concat(
        currencyFormatList(optiList?.ccpcYearlyNonEligibleDividendsIssued)
      ),
      ["Earned Eligible Dividends"].concat(
        currencyFormatList(optiList?.ccpcYearlyEligibleDividendsEarned)
      ),
      ["Earned Non-Eligible Dividends"].concat(
        currencyFormatList(optiList?.ccpcYearlyNonEligibleDividendsEarned)
      ),
      ["Earned Other Dividends"].concat(
        currencyFormatList(optiList?.ccpcYearlyOtherDividendsEarned)
      ),
      ["Eligible Dividend Refund"].concat(
        currencyFormatList(optiList?.ccpcYearlyEligibleDividendRefund)
      ),
      ["Non-Eligible Dividend Refund"].concat(
        currencyFormatList(optiList?.ccpcYearlyNonEligibleDividendRefund)
      ),
      ["CCPC Taxes Paid within Holding Company"].concat(
        currencyFormatList(optiList?.ccpcYearlyTaxesPaid)
      ),
      ["CCPC Investment Fund ACB"].concat(
        currencyFormatList(optiList?.ccpcYearlyACB)
      ),
      ["NERDTOH Tracking Accounts"].concat(
        currencyFormatList(optiList?.ccpcYearlyNERDTOH)
      ),
      ["ERDTOH Tracking Accounts"].concat(
        currencyFormatList(optiList?.ccpcYearlyERDTOH)
      ),
      ["CDA Tracking Accounts"].concat(
        currencyFormatList(optiList?.ccpcYearlyCDA)
      ),
      ["GRIP  racking Accounts"].concat(
        currencyFormatList(optiList?.ccpcYearlyGRIP)
      ),
      ["CCPC Investment Fund Loans Repaid"].concat(
        currencyFormatList(optiList?.ccpcYearlyShareholderLoanRepayments)
      ),
      ["CCPC Investment Loan Balance"].concat(
        currencyFormatList(optiList?.ccpcYearlyShareholderLoanBalance)
      ),
      [""],
      ["Personal Assets"],
      ["Value"].concat(currencyFormatList(optiList?.otherAssetsYearlyValue)),
      ["Growth"].concat(currencyFormatList(optiList?.otherAssetsYearlyGrowth)),
      ["Closing Balance"].concat(
        currencyFormatListShift(
          [...optiList?.otherAssetsYearlyValue],
          optiList?.otherAssetsYearlyGrowth?.[
            optiList?.otherAssetsYearlyGrowth?.length - 1
          ]
        )
      ),
      [""],
      ["Expenses"],
      ["Mortgage(s)"].concat(
        currencyFormatList(
          optiList?.PrimaryResidence?.Payments?.map(
            (e, i) =>
              e +
              optiList?.RecreationPropertyYearlyPayments?.[i] +
              optiList?.LandYearlyPayments?.[i] +
              optiList?.IncomePropertyYearlyPayments?.[i]
          )
        )
      ),
      ["Life Insurance Premiums"].concat(
        currencyFormatList(optiList?.lifeInsuranceYearlyPayments)
      ),
      ["Annual Debt Payments"].concat(
        currencyFormatList(optiList?.totalDebtPayments)
      ),
      ["Living Expenses"].concat(currencyFormatList(optiList?.expenses)),
      ["Extra Spending"].concat(currencyFormatList(optiList?.extraSpending)),
      ["Total Expenses"].concat(
        currencyFormatList(
          optiList?.expenses?.map(
            (e, i) =>
              e +
              optiList?.PrimaryResidence?.Payments?.[i] +
              optiList?.RecreationPropertyYearlyPayments?.[i] +
              optiList?.LandYearlyPayments?.[i] +
              optiList?.IncomePropertyYearlyPayments?.[i] +
              optiList?.totalDebtPayments?.[i] +
              optiList?.extraSpending?.[i] +
              optiList?.lifeInsuranceYearlyPayments?.[i]
          )
        )
      ),
      [""],
      ["Total Debt"].concat(
        currencyFormatList(
          optiList?.PrimaryResidence?.Balance?.map(
            (e, i) =>
              e +
              optiList?.RecreationPropertyYearlyBalance?.[i] +
              optiList?.LandYearlyBalance?.[i] +
              optiList?.IncomePropertyYearlyBalance?.[i] +
              optiList?.totalDebtProrated?.[i]
          )
        )
      ),
      [""],
      [optiList?.[`${spousePrefix}_name`] + " " + "Taxes"],
      ["Provincial Taxes"].concat(
        currencyFormatList(optiList?.[`${spousePrefix}_provincialTaxes`])
      ),
      ["Federal Taxes"].concat(
        currencyFormatList(optiList?.[`${spousePrefix}_federalTaxes`])
      ),
      ["EI Premiums"].concat(
        currencyFormatList(optiList?.[`${spousePrefix}_eiPremiums`])
      ),
      ["CPP Contributions"].concat(
        currencyFormatList(optiList?.[`${spousePrefix}_cppContributions`])
      ),
      ["Deductions"].concat(
        currencyFormatList(optiList?.[`${spousePrefix}_salaryDeductions`])
      ),
      ["Total Taxes"].concat(
        currencyFormatList(
          optiList?.[`${spousePrefix}_incomeTaxes`]?.map(
            (e, i) =>
              e +
              optiList?.[`${spousePrefix}_eiPremiums`]?.[i] +
              optiList?.[`${spousePrefix}_cppContributions`]?.[i]
          )
        )
      ),
      [""],
      [spouseOptiList?.sps2_name + " " + "Taxes"],
      ["Provincial Taxes"].concat(
        currencyFormatList(spouseOptiList?.sps2_provincialTaxes)
      ),
      ["Federal Taxes"].concat(
        currencyFormatList(spouseOptiList?.sps2_federalTaxes)
      ),
      ["EI Premiums"].concat(
        currencyFormatList(spouseOptiList?.sps2_eiPremiums)
      ),
      ["CPP Contributions"].concat(
        currencyFormatList(spouseOptiList?.sps2_cppContributions)
      ),
      ["Deductions"].concat(
        currencyFormatList(spouseOptiList?.sps2_salaryDeductions)
      ),
      ["Total Taxes"].concat(
        currencyFormatList(
          spouseOptiList?.sps2_incomeTaxes?.map(
            (e, i) =>
              e +
              spouseOptiList?.sps2_eiPremiums?.[i] +
              spouseOptiList?.sps2_cppContributions?.[i]
          )
        )
      ),
      [""],
      ["Combined Total Taxes"].concat(
        currencyFormatList(
          optiList?.[`${spousePrefix}_incomeTaxes`]?.map(
            (e, i) =>
              e +
              spouseOptiList?.sps2_incomeTaxes?.[i] +
              optiList?.[`${spousePrefix}_cppContributions`]?.[i] +
              spouseOptiList?.sps2_cppContributions?.[i] +
              optiList?.[`${spousePrefix}_eiPremiums`]?.[i] +
              spouseOptiList?.sps2_eiPremiums?.[i]
          )
        )
      ),
    ];
    return data;
  };

  const optiListToCSVEndOfAnalysis = (optiList, spouse1ROT) => {
    var data = [
      ["Optiml"],
      ["Date of Analysis", formatDate(optiList?.planRunDate)],
      ["Strategy", passedOptiList?.optimizationType],
      ["CPI", userExpensesInfo?.expenseCPI + "%"],
      [
        "End of Analysis Portfolio Balance",
        currencyFormat(optiList?.optimizedEOLAmount),
      ],
      [
        "End of Analysis Asset Balance",
        currencyFormat(
          optiList?.PrimaryResidence?.Value?.[
            optiList?.PrimaryResidence?.Value?.length - 1
          ] +
            optiList?.RecreationPropertyYearlyValue?.[
              optiList?.RecreationPropertyYearlyValue?.length - 1
            ] +
            optiList?.LandYearlyValue?.[optiList?.LandYearlyValue?.length - 1] +
            optiList?.businessYearlyValue?.[
              optiList?.businessYearlyValue?.length - 1
            ] +
            optiList?.IncomePropertyYearlyValue?.[
              optiList?.IncomePropertyYearlyValue?.length - 1
            ] +
            optiList?.otherAssetsYearlyValue?.[
              optiList?.otherAssetsYearlyValue?.length - 1
            ] +
            optiList?.lifeInsuranceYearlyValue?.[
              optiList?.lifeInsuranceYearlyValue?.length - 1
            ]
        ),
      ],
      [
        "Before Tax End of Analysis Total",
        currencyFormat(
          optiList?.optimizedEOLAmount +
            optiList?.PrimaryResidence?.Value?.[
              optiList?.PrimaryResidence?.Value?.length - 1
            ] +
            optiList?.RecreationPropertyYearlyValue?.[
              optiList?.RecreationPropertyYearlyValue?.length - 1
            ] +
            optiList?.LandYearlyValue?.[optiList?.LandYearlyValue?.length - 1] +
            optiList?.businessYearlyValue?.[
              optiList?.businessYearlyValue?.length - 1
            ] +
            optiList?.IncomePropertyYearlyValue?.[
              optiList?.IncomePropertyYearlyValue?.length - 1
            ] +
            optiList?.otherAssetsYearlyValue?.[
              optiList?.otherAssetsYearlyValue?.length - 1
            ] +
            optiList?.lifeInsuranceYearlyValue?.[
              optiList?.lifeInsuranceYearlyValue?.length - 1
            ]
        ),
      ],
      [
        "After Tax End of Analysis Balance",
        currencyFormat(
          optiList?.optimizedEOLAmountAfterTax +
            optiList?.PrimaryResidence?.Value?.[
              optiList?.PrimaryResidence?.Value?.length - 1
            ] +
            optiList?.RecreationPropertyYearlyValue?.[
              optiList?.RecreationPropertyYearlyValue?.length - 1
            ] +
            optiList?.LandYearlyValue?.[optiList?.LandYearlyValue?.length - 1] +
            optiList?.IncomePropertyYearlyValue?.[
              optiList?.IncomePropertyYearlyValue?.length - 1
            ] +
            optiList?.businessYearlyValue?.[
              optiList?.businessYearlyValue?.length - 1
            ] +
            optiList?.otherAssetsYearlyValue?.[
              optiList?.otherAssetsYearlyValue?.length - 1
            ] +
            optiList?.lifeInsuranceYearlyValue?.[
              optiList?.lifeInsuranceYearlyValue?.length - 1
            ]
        ),
      ],
      [""],

      ["Rule of Thumb"],
      ["Date of Analysis", formatDate(optiList?.planRunDate)],
      ["Strategy", passedOptiList?.optimizationType],
      ["CPI", userExpensesInfo?.expenseCPI + "%"],
      [
        "End of Analysis Portfolio Balance",
        currencyFormat(spouse1ROT?.optimizedEOLAmount),
      ],
      [
        "End of Analysis Asset Balance",
        currencyFormat(
          spouse1ROT?.PrimaryResidence?.Value?.[
            spouse1ROT?.PrimaryResidence?.Value?.length - 1
          ] +
            spouse1ROT?.RecreationPropertyYearlyValue?.[
              spouse1ROT?.RecreationPropertyYearlyValue?.length - 1
            ] +
            spouse1ROT?.LandYearlyValue?.[
              spouse1ROT?.LandYearlyValue?.length - 1
            ] +
            spouse1ROT?.businessYearlyValue?.[
              spouse1ROT?.businessYearlyValue?.length - 1
            ] +
            spouse1ROT?.IncomePropertyYearlyValue?.[
              spouse1ROT?.IncomePropertyYearlyValue?.length - 1
            ] +
            spouse1ROT?.otherAssetsYearlyValue?.[
              spouse1ROT?.otherAssetsYearlyValue?.length - 1
            ] +
            spouse1ROT?.lifeInsuranceYearlyValue?.[
              spouse1ROT?.lifeInsuranceYearlyValue?.length - 1
            ]
        ),
      ],
      [
        "Before Tax End of Analysis Total",
        currencyFormat(
          spouse1ROT?.optimizedEOLAmount +
            spouse1ROT?.PrimaryResidence?.Value?.[
              spouse1ROT?.PrimaryResidence?.Value?.length - 1
            ] +
            spouse1ROT?.RecreationPropertyYearlyValue?.[
              spouse1ROT?.RecreationPropertyYearlyValue?.length - 1
            ] +
            spouse1ROT?.LandYearlyValue?.[
              spouse1ROT?.LandYearlyValue?.length - 1
            ] +
            spouse1ROT?.businessYearlyValue?.[
              spouse1ROT?.businessYearlyValue?.length - 1
            ] +
            spouse1ROT?.IncomePropertyYearlyValue?.[
              spouse1ROT?.IncomePropertyYearlyValue?.length - 1
            ] +
            spouse1ROT?.otherAssetsYearlyValue?.[
              spouse1ROT?.otherAssetsYearlyValue?.length - 1
            ] +
            spouse1ROT?.lifeInsuranceYearlyValue?.[
              spouse1ROT?.lifeInsuranceYearlyValue?.length - 1
            ]
        ),
      ],
      [
        "After Tax End of Analysis Balance",
        currencyFormat(
          spouse1ROT?.optimizedEOLAmountAfterTax +
            spouse1ROT?.PrimaryResidence?.Value?.[
              spouse1ROT?.PrimaryResidence?.Value?.length - 1
            ] +
            spouse1ROT?.RecreationPropertyYearlyValue?.[
              spouse1ROT?.RecreationPropertyYearlyValue?.length - 1
            ] +
            spouse1ROT?.LandYearlyValue?.[
              spouse1ROT?.LandYearlyValue?.length - 1
            ] +
            spouse1ROT?.businessYearlyValue?.[
              spouse1ROT?.businessYearlyValue?.length - 1
            ] +
            spouse1ROT?.IncomePropertyYearlyValue?.[
              spouse1ROT?.IncomePropertyYearlyValue?.length - 1
            ] +
            spouse1ROT?.otherAssetsYearlyValue?.[
              spouse1ROT?.otherAssetsYearlyValue?.length - 1
            ] +
            spouse1ROT?.lifeInsuranceYearlyValue?.[
              spouse1ROT?.lifeInsuranceYearlyValue?.length - 1
            ]
        ),
      ],
      [""],
    ];
    return data;
  };

  function colourSyleSheet(sheet) {
    if (sheet.length <= 1) {
      return;
    }
    const lightBlueFill = {
      type: "pattern",
      pattern: "solid",
      fgColor: { argb: "FFADD8E6" }, // Light Blue ARGB code
    };

    for (let rowNum = 12; rowNum <= sheet.rowCount; rowNum += 2) {
      const row = sheet.getRow(rowNum);
      row.eachCell((cell) => {
        cell.style.fill = lightBlueFill;
      });
    }
  }

  function colourSyleSheetComp(sheet) {
    if (sheet.length <= 1) {
      return;
    }
    const lightBlueFill = {
      type: "pattern",
      pattern: "solid",
      fgColor: { argb: "FFADD8E6" }, // Light Blue ARGB code
    };

    const DarkBlueFill = {
      type: "pattern",
      pattern: "solid",
      fgColor: { argb: "FF0F9CD6" }, // Light Blue ARGB code
    };

    const row = sheet.getRow(1);
    row.eachCell((cell) => {
      cell.style.fill = DarkBlueFill;
    });

    const row2 = sheet.getRow(10);
    row2.eachCell((cell) => {
      cell.style.fill = DarkBlueFill;
    });

    for (let rowNum = 2; rowNum <= 8; rowNum += 2) {
      const row = sheet.getRow(rowNum);
      row.eachCell((cell) => {
        cell.style.fill = lightBlueFill;
      });
    }
    for (let rowNum = 11; rowNum <= 17; rowNum += 2) {
      const row = sheet.getRow(rowNum);
      row.eachCell((cell) => {
        cell.style.fill = lightBlueFill;
      });
    }
  }

  function exportToExcel(optiList) {
    setOptiList(passedOptiList);
    setSpouseOptiList(passedSpouseOptiList);
    const workbook = new Workbook();
    const worksheet = spouseStatus ? workbook.addWorksheet("Combined") : [];
    const worksheet2 = workbook.addWorksheet(
      `${optiList?.[`${spousePrefix}_name`]} ${
        optiList?.[`${spousePrefix}_lastName`]
      }`
    );
    const worksheet3 = spouseStatus
      ? workbook.addWorksheet(
          `${spouseOptiList?.sps2_name} ${spouseOptiList?.sps2_lastName}`
        )
      : [];
    const worksheet4 =
      optiList?.optimizationType === "Max Value" &&
      spouse1ROT?.expenses?.length > 1
        ? workbook.addWorksheet("Rule of Thumb")
        : [];
    // const worksheet5 = workbook.addWorksheet('Optiml Vs Rule of Thumb');
    const worksheet6 =
      optiList?.optimizationType === "Max Value" &&
      spouse1ROT?.expenses?.length > 1
        ? workbook.addWorksheet("End of Analysis Comparison")
        : [];
    const data = spouseStatus
      ? optiListToCSVSpouse(optiList)
      : optiListToCSV(optiList);
    const data1 = optiListToCSVSpouse1(optiList);
    const data2 = spouseStatus ? optiListToCSVSpouse2(optiList) : [];
    const data3 = spouseStatus
      ? optiListToCSVROT(spouse1ROT, spouseStatus ? spouse2ROT : [])
      : optiListToCSVROTSingle(spouse1ROT);
    // const data4 = optiListToCSVOptimlVsROT(optiList, (spouseStatus ? spouseOptiList: ['']), spouse1ROT, (spouseStatus ? spouse2ROT: ['']))
    const data5 = optiListToCSVEndOfAnalysis(optiList, spouse1ROT);
    if (spouseStatus) {
      data.forEach((row) => {
        worksheet.addRow(row);
      });
    }
    data1.forEach((row) => {
      worksheet2?.addRow(row);
    });
    if (spouseStatus && worksheet3) {
      data2.forEach((row) => {
        worksheet3.addRow(row);
      });
    }
    if (
      optiList?.optimizationType === "Max Value" &&
      spouse1ROT?.expenses?.length > 1
    ) {
      data3.forEach((row) => {
        worksheet4?.addRow(row);
      });
    }
    // data4.forEach(row => {
    //   worksheet5?.addRow(row);
    //   });
    if (
      optiList?.optimizationType === "Max Value" &&
      spouse1ROT?.expenses?.length > 1
    ) {
      data5.forEach((row) => {
        worksheet6?.addRow(row);
      });
    }
    if (worksheet._name === "Combined") {
      const cellsToRightAlign = spouseStatus
        ? [
            "A12",
            "A13",
            "A14",
            "A15",
            "A16",
            "A17",
            "A20",
            "A21",
            "A22",
            "A23",
            "A24",
            "A31",
            "A32",
            "A33",
            "A34",
            "A37",
            "A38",
            "A39",
            "A40",
            "A41",
            "A42",
            "A45",
            "A46",
            "A47",
            "A50",
            "A51",
            "A52",
            "A53",
            "A56",
            "A57",
            "A58",
            "A59",
            "A60",
            "A61",
            "A68",
            "A69",
            "A70",
            "A71",
            "A74",
            "A75",
            "A76",
            "A77",
            "A78",
            "A79",
            "A82",
            "A83",
            "A84",
            "A87",
            "A88",
            "A89",
            "A90",
            "A93",
            "A94",
            "A95",
            "A96",
            "A97",
            "A98",
            "A107",
            "A108",
            "A110",
            "A111",
            "A113",
            "A114",
            "A119",
            "A120",
            "A121",
            "A124",
            "A125",
            "A126",
            "A127",
            "A128",
            "A134",
            "A135",
            "A136",
            "A137",
            "A138",
            "A142",
            "A143",
            "A144",
            "A145",
            "A146",
          ]
        : [
            "A11",
            "A12",
            "A13",
            "A14",
            "A15",
            "A16",
            "A17",
            "A20",
            "A21",
            "A22",
            "A23",
            "A24",
            "A27",
            "A28",
            "A29",
            "A30",
            "A31",
            "A32",
            "A33",
            "A35",
            "A36",
            "A37",
            "A40",
            "A41",
            "A42",
            "A43",
            "A46",
            "A47",
            "A48",
            "A49",
            "A50",
            "A51",
            "A57",
            "A58",
            "A59",
            "A61",
            "A62",
            "A64",
            "A65",
            "A70",
            "A71",
            "A72",
            "A75",
            "A76",
            "A77",
            "A78",
            "A79",
          ];
      cellsToRightAlign.forEach((cellAddress) => {
        const cell = worksheet?.getCell(cellAddress);
        cell.alignment = { horizontal: "right" };
      });
      const boldCells = spouseStatus
        ? [
            "A1",
            "A2",
            "A4",
            "A5",
            "A6",
            "A7",
            "A8",
            "A9",
            "A11",
            "A19",
            "A26",
            "A28",
            "A29",
            "A30",
            "A36",
            "A44",
            "A49",
            "A55",
            "A63",
            "A65",
            "A66",
            "A67",
            "A73",
            "A81",
            "A86",
            "A92",
            "A100",
            "A102",
            "A104",
            "A105",
            "A106",
            "A109",
            "A112",
            "A116",
            "A118",
            "A123",
            "A129",
            "A131",
            "A133",
            "A139",
            "A141",
            "A147",
            "A149",
          ]
        : [
            "A1",
            "A3",
            "A4",
            "A5",
            "A6",
            "A7",
            "A8",
            "A10",
            "A18",
            "A19",
            "A26",
            "A34",
            "A39",
            "A45",
            "A53",
            "A55",
            "A56",
            "A60",
            "A63",
            "A67",
            "A69",
            "A74",
            "A80",
            "A82",
            "A84",
            "A90",
          ];
      boldCells.forEach((cell) => {
        const cellObject = worksheet?.getCell(cell);
        cellObject.font = { bold: true };
      });

      function autoColumnWidth(arr) {
        const widths = arr?.map((row) =>
          Array.isArray(row)
            ? row?.map((val) => (val || "").toString().length)
            : [0]
        );
        return widths[0]?.map(
          (_, colIndex) => Math.max(...widths?.map((row) => row[colIndex]), 5) // Ensure a minimum width
        );
      }

      for (let rowIndex = 11; rowIndex <= worksheet.rowCount; rowIndex++) {
        const row = worksheet.getRow(rowIndex);
        for (let colIndex = row?.cellCount; colIndex >= 2; colIndex--) {
          // Starting from the last cell in the row
          const cell = row?.getCell(colIndex);
          const targetCell = row?.getCell(colIndex + 2);
          targetCell.value = cell.value; // Move the value two columns to the right
          cell.value = null; // Clear the original cell value
        }
      }

      const columnWidths = autoColumnWidth(data);
      columnWidths.forEach((width, index) => {
        worksheet.getColumn(index + 1).width = width.wch;
      });
    }
    if (
      worksheet2._name ===
      `${optiList?.[`${spousePrefix}_name`]} ${
        optiList?.[`${spousePrefix}_lastName`]
      }`
    ) {
      const cellsToRightAlign = [
        "A12",
        "A13",
        "A14",
        "A15",
        "A16",
        "A17",
        "A20",
        "A22",
        "A23",
        "A24",
        "A25",
        "A28",
        "A29",
        "A30",
        "A31",
        "A32",
        "A33",
        "A36",
        "A37",
        "A38",
        "A41",
        "A42",
        "A43",
        "A44",
        "A47",
        "A48",
        "A49",
        "A50",
        "A51",
        "A52",
        "A58",
        "A59",
        "A60",
        "A62",
        "A63",
        "A65",
        "A66",
        "A71",
        "A72",
        "A73",
        "A76",
        "A77",
        "A78",
        "A79",
        "A80",
        "A86",
        "A87",
        "A88",
        "A89",
        "A90",
      ];
      cellsToRightAlign.forEach((cellAddress) => {
        const cell = worksheet2?.getCell(cellAddress);
        cell.alignment = { horizontal: "right" };
      });
      const boldCells = [
        "A1",
        "A2",
        "A3",
        "A4",
        "A5",
        "A6",
        "A7",
        "A8",
        "A9",
        "A11",
        "A19",
        "A20",
        "A21",
        "A27",
        "A35",
        "A40",
        "A46",
        "A54",
        "A56",
        "A57",
        "A68",
        "A70",
        "A75",
        "A81",
        "A83",
        "A85",
        "A91",
        "A93",
      ];
      boldCells.forEach((cell) => {
        const cellObject = worksheet2?.getCell(cell);
        cellObject.font = { bold: true };
      });

      function autoColumnWidth(arr) {
        const widths = arr?.map((row) =>
          Array.isArray(row)
            ? row?.map((val) => (val || "").toString().length)
            : [0]
        );
        return widths[0]?.map(
          (_, colIndex) => Math.max(...widths?.map((row) => row[colIndex]), 5) // Ensure a minimum width
        );
      }

      for (let rowIndex = 11; rowIndex <= worksheet2.rowCount; rowIndex++) {
        const row = worksheet2.getRow(rowIndex);
        for (let colIndex = row?.cellCount; colIndex >= 2; colIndex--) {
          // Starting from the last cell in the row
          const cell = row?.getCell(colIndex);
          const targetCell = row?.getCell(colIndex + 2);
          targetCell.value = cell.value; // Move the value two columns to the right
          cell.value = null; // Clear the original cell value
        }
      }

      const columnWidths = autoColumnWidth(data);
      columnWidths.forEach((width, index) => {
        worksheet2.getColumn(index + 1).width = width.wch;
      });
    }
    if (
      worksheet3._name ===
      `${spouseOptiList?.sps2_name} ${spouseOptiList?.sps2_lastName}`
    ) {
      const cellsToRightAlign = [
        "A12",
        "A13",
        "A14",
        "A15",
        "A16",
        "A17",
        "A20",
        "A22",
        "A23",
        "A24",
        "A25",
        "A28",
        "A29",
        "A30",
        "A31",
        "A32",
        "A33",
        "A36",
        "A37",
        "A38",
        "A41",
        "A42",
        "A43",
        "A44",
        "A47",
        "A48",
        "A49",
        "A50",
        "A51",
        "A52",
        "A58",
        "A59",
        "A60",
        "A62",
        "A63",
        "A65",
        "A66",
        "A71",
        "A72",
        "A73",
        "A76",
        "A77",
        "A78",
        "A79",
        "A80",
        "A86",
        "A87",
        "A88",
        "A89",
        "A90",
      ];
      cellsToRightAlign.forEach((cellAddress) => {
        const cell = worksheet3?.getCell(cellAddress);
        cell.alignment = { horizontal: "right" };
      });
      const boldCells = [
        "A1",
        "A2",
        "A3",
        "A4",
        "A5",
        "A6",
        "A7",
        "A8",
        "A9",
        "A11",
        "A19",
        "A20",
        "A21",
        "A27",
        "A35",
        "A40",
        "A46",
        "A54",
        "A56",
        "A57",
        "A68",
        "A70",
        "A75",
        "A81",
        "A83",
        "A85",
        "A91",
        "A93",
      ];
      boldCells.forEach((cell) => {
        const cellObject = worksheet3?.getCell(cell);
        cellObject.font = { bold: true };
      });

      function autoColumnWidth(arr) {
        const widths = arr?.map((row) =>
          Array.isArray(row)
            ? row?.map((val) => (val || "").toString().length)
            : [0]
        );
        return widths[0]?.map(
          (_, colIndex) => Math.max(...widths?.map((row) => row[colIndex]), 5) // Ensure a minimum width
        );
      }

      for (let rowIndex = 11; rowIndex <= worksheet3.rowCount; rowIndex++) {
        const row = worksheet3.getRow(rowIndex);
        for (let colIndex = row?.cellCount; colIndex >= 2; colIndex--) {
          // Starting from the last cell in the row
          const cell = row?.getCell(colIndex);
          const targetCell = row?.getCell(colIndex + 2);
          targetCell.value = cell.value; // Move the value two columns to the right
          cell.value = null; // Clear the original cell value
        }
      }

      const columnWidths = autoColumnWidth(data);
      columnWidths.forEach((width, index) => {
        worksheet3.getColumn(index + 1).width = width.wch;
      });
    }
    if (worksheet4._name === "Rule of Thumb") {
      const cellsToRightAlign = [
        "A12",
        "A13",
        "A14",
        "A15",
        "A16",
        "A17",
        "A20",
        "A22",
        "A23",
        "A24",
        "A25",
        "A28",
        "A29",
        "A30",
        "A31",
        "A32",
        "A33",
        "A36",
        "A37",
        "A38",
        "A41",
        "A42",
        "A43",
        "A44",
        "A47",
        "A48",
        "A49",
        "A50",
        "A51",
        "A52",
        "A58",
        "A59",
        "A60",
        "A62",
        "A63",
        "A65",
        "A66",
        "A71",
        "A72",
        "A73",
        "A76",
        "A77",
        "A78",
        "A79",
        "A80",
        "A86",
        "A87",
        "A88",
        "A89",
        "A90",
      ];
      cellsToRightAlign.forEach((cellAddress) => {
        const cell = worksheet4?.getCell(cellAddress);
        cell.alignment = { horizontal: "right" };
      });
      const boldCells = [
        "A1",
        "A2",
        "A3",
        "A4",
        "A5",
        "A6",
        "A7",
        "A8",
        "A9",
        "A11",
        "A19",
        "A20",
        "A21",
        "A27",
        "A35",
        "A40",
        "A46",
        "A54",
        "A56",
        "A57",
        "A68",
        "A70",
        "A75",
        "A81",
        "A83",
        "A85",
        "A91",
        "A93",
      ];
      boldCells.forEach((cell) => {
        const cellObject = worksheet4?.getCell(cell);
        cellObject.font = { bold: true };
      });

      function autoColumnWidth(arr) {
        const widths = arr?.map((row) =>
          Array.isArray(row)
            ? row?.map((val) => (val || "").toString().length)
            : [0]
        );
        return widths[0]?.map(
          (_, colIndex) => Math.max(...widths?.map((row) => row[colIndex]), 5) // Ensure a minimum width
        );
      }
      for (let rowIndex = 11; rowIndex <= worksheet4.rowCount; rowIndex++) {
        const row = worksheet4.getRow(rowIndex);
        for (let colIndex = row?.cellCount; colIndex >= 2; colIndex--) {
          // Starting from the last cell in the row
          const cell = row?.getCell(colIndex);
          const targetCell = row?.getCell(colIndex + 2);
          targetCell.value = cell.value; // Move the value two columns to the right
          cell.value = null; // Clear the original cell value
        }
      }

      const columnWidths = autoColumnWidth(data);
      columnWidths.forEach((width, index) => {
        worksheet4.getColumn(index + 1).width = width.wch;
      });
    }
    colourSyleSheet(worksheet);
    colourSyleSheet(worksheet2);
    colourSyleSheet(worksheet3);
    colourSyleSheet(worksheet4);
    colourSyleSheetComp(worksheet6);
    workbook.xlsx.writeBuffer().then((buffer) => {
      const blob = new Blob([buffer], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      spouseStatus
        ? saveAs(
            blob,
            `Optimized_Report_${optiList?.[`${spousePrefix}_name`]}_${
              spouseOptiList?.sps2_name
            }.xlsx`
          )
        : saveAs(
            blob,
            `Optimized_Report_${optiList?.[`${spousePrefix}_name`]}.xlsx`
          );
    });
  }

  // useEffect(() => {
  //   setTimeout(() => {
  //     if (optiList?.IncludeAssets === true) {
  //       setOtherAssets(true);
  //     }
  //   }, 100);
  // }, [listPlace]);

  useEffect(() => {
    if (optiNoise != null) {
      optiNoise.play();
    }
    if (passedOptiList?.optimizationType === "Max Value") {
      setMaxValue(true);
    }
    if (passedOptiList?.optimizationType == "Error Value") {
      setErrorModal(true);
      setShowVideo(sessionStorage.getItem("alternatePlanViewed"));
    }
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 100);
  }, []);

  const averageTaxRate = (total, tax) => {
    // Check if total is zero, null, or undefined
    if (total === 0 || total === null || total === undefined) {
      return 0; // or return null, depending on how you want to handle this case
    }

    const percent = (tax / total) * 100;
    const x = Math.round(percent);
    return x;
  };

  const sumArray = (arr) => {
    var sum = arr?.reduce((a, b) => a + b, 0);
    return sum;
  };

  const yearToDate = (year) => {
    const date = new Date();
    date?.setFullYear(year);
    // console?.log(date);
    return date;
  };

  const withdrawCppAge = (cpp, spouseBday) => {
    let age = 70; // Default age set to 70

    for (let i = 0; i < cpp?.length; i++) {
      if (cpp?.[i] > 0) {
        age = i;
        break;
      }
    }

    return Math.min(age + spouseBday, 70);
  };

  function formatDate(dateString) {
    const date = new Date(dateString);
    const options = { month: "long", day: "numeric", year: "numeric" };
    const formatted = date?.toLocaleDateString("en-US", options);

    const day = date?.getDate();
    let suffix = "th";
    if (day === 1 || day === 21 || day === 31) {
      suffix = "st";
    } else if (day === 2 || day === 22) {
      suffix = "nd";
    } else if (day === 3 || day === 23) {
      suffix = "rd";
    }

    const dayWithSuffix = day + suffix;

    return formatted.replace(day.toString(), dayWithSuffix);
  }

  const setRunDate = () => {
    if (passedOptiList?.planRunDate !== null) {
      const dateOfPlan = new Date(passedOptiList?.planRunDate);
      // console?.log(dateOfPlan.toLocaleDateString());
      return formatDate(passedOptiList?.planRunDate);
    } else {
      const today = new Date();
      return formatDate(today);
    }
  };

  const onDragSmallCardEnd = (result) => {
    if (!result?.destination) {
      return;
    }
    const layout = {
      ...dashboardLayout,
      smallCards: [...dashboardLayout.smallCards], // Create a new array reference
    };
    const fromIndex = layout.smallCards.indexOf(result.draggableId);
    layout.smallCards.splice(fromIndex, 1);
    layout.smallCards.splice(result.destination.index, 0, result.draggableId);
    setDashboardLayout(layout);
  };

  const onDragLargeCardEnd = (result) => {
    if (!result?.destination) {
      return;
    }
    const layout = {
      ...dashboardLayout,
      largeCards: [...dashboardLayout.largeCards], // Create a new array reference
    };
    const fromIndex = layout.largeCards.indexOf(result.draggableId);
    layout.largeCards.splice(fromIndex, 1);
    layout.largeCards.splice(result.destination.index, 0, result.draggableId);
    setDashboardLayout(layout);
  };

  const onDragOverallLayoutEnd = (section, direction) => {
    const layout = {
      ...dashboardLayout,
      overallLayout: [...dashboardLayout.overallLayout], // Create a new array reference
    };
    const fromIndex = layout.overallLayout.indexOf(section);
    if (
      (direction === "up" && fromIndex !== 0) ||
      (direction === "down" && fromIndex !== 2)
    ) {
      const element = layout.overallLayout[fromIndex];
      layout.overallLayout.splice(fromIndex, 1);
      layout.overallLayout.splice(
        direction === "up" ? fromIndex - 1 : fromIndex + 1,
        0,
        element
      );
      setDashboardLayout(layout);
      setMovingCards(!movingCards);
    }
  };

  const getDraggableStyle = (isDragging) => ({
    opacity: isDragging ? 0.5 : 1,
  });

  const getDraggableStyleCards = (isDragging) => ({
    opacity: isDragging ? 0.5 : 1,
  });

  // eslint-disable-next-line react/no-unstable-nested-components
  function SmallCardLayout(props) {
    return (
      <>
        {isSmallScreen && (
          <Box
            display={"flex"}
            justifyContent={"space-between"}
            alignItems={"flex-start"}
            alignContent={"flex-start"}
            rowGap={"10px"}
            alignSelf={"stretch"}
            flexWrap={"wrap"}
          >
            <Box>
              {/* <div className="small-box-edit">Edit</div> */}
              <Box
                display={"flex"}
                justifyContent={"space-between"}
                alignItems={"flex-start"}
                alignContent={"flex-start"}
                rowGap={"10px"}
                alignSelf={"stretch"}
                flexWrap={"wrap"}
              >
                {dashboardLayout.smallCards

                  ?.filter(
                    (card) =>
                      card !== "averageTaxRate" &&
                      card !== "OSFA" &&
                      card !== "optimlValue" &&
                      card != "netWorth"
                  ) // Replace "omittedCardId" with the actual ID or condition
                  .map((card, index) => (
                    <Box
                      display={"flex"}
                      w={"49%"}
                      h={"51px"}
                      padding={"5px 10px"}
                      alignItems={"center"}
                      borderRadius={"5px"}
                      border={"1px solid #00A2D4"}
                      bg={"#FFF"}
                    >
                      <DashboardSmallCard
                        card={card}
                        optiList={optiList}
                        assetsList={assetsList}
                        spouseOptiList={spouseOptiList}
                        currentYear={currentYear}
                        getAge={getAge}
                        withdrawCppAge={withdrawCppAge}
                        currencyFormat={currencyFormat}
                        averageTaxRate={averageTaxRate}
                        sumArray={sumArray}
                        spousePrefix={spousePrefix}
                        spousePage={spousePage}
                      />
                    </Box>
                  ))}
              </Box>
            </Box>
          </Box>
        )}

        {!isSmallScreen && (
          <Box
            flexDirection={"row"}
            w={"100%"}
            justifyContent={"space-between"}
            flexWrap={"wrap"}
          >
            <Box>
              {/* <div className="small-box-edit">Edit</div> */}

              <div className="dashboard-small-box-container">
                {dashboardLayout.smallCards
                  ?.filter(
                    (card) =>
                      card !== "OSFA" &&
                      card !== "optimlValue" &&
                      card != "netWorth"
                  ) // Replace "omittedCardId" with the actual ID or condition
                  .map((card, index) => (
                    <Box
                      display="flex"
                      justifyContent={
                        card === "averageTaxRate" ? "center" : "flex-start"
                      }
                      alignSelf="stretch"
                      flexWrap="wrap"
                      bg={"#FFF"}
                      padding={"15px 20px"}
                      alignItems={"center"}
                      borderRadius={"5px"}
                      border={"1px solid #00A2D4"}
                      w={isLargerThan1200 && "48%"}
                      minW={card === "averageTaxRate" ? "120px" : "200px"}
                      minH={"100px"}
                    >
                      <DashboardSmallCard
                        card={card}
                        optiList={optiList}
                        assetsList={assetsList}
                        spouseOptiList={spouseOptiList}
                        currentYear={currentYear}
                        getAge={getAge}
                        withdrawCppAge={withdrawCppAge}
                        currencyFormat={currencyFormat}
                        averageTaxRate={averageTaxRate}
                        sumArray={sumArray}
                        spousePrefix={spousePrefix}
                        spousePage={spousePage}
                      />
                    </Box>
                  ))}
              </div>
            </Box>
          </Box>
        )}
      </>
    );
  }

  // eslint-disable-next-line react/no-unstable-nested-components
  function LargeCardLayout(props) {
    const years = [];
    const endYear = assetsList?.expenses?.length
      ? assetsList?.expenses?.length + new Date().getFullYear()
      : new Date().getFullYear();
    let currentLargeYear = new Date().getFullYear();

    for (let i = currentLargeYear; i <= endYear - 1; i++) {
      years.push(i);
    }

    const [isClicked, setIsClicked] = useState(false);

    function findFirstZeroYear(array) {
      // Check if the first value is 0
      const currentYear = new Date().getFullYear();
      if (!array) {
        return 0;
      }
      if (array[0] === 0) {
        return null;
      }

      // Iterate through the array to find the first 0
      for (let i = 0; i < array.length; i++) {
        if (array[i] === 0) {
          return currentYear + i;
        }
      }

      // If no zero is found, return null
      return null;
    }

    const spouse1RetireYear = findFirstZeroYear(
      optiList?.[`${spousePrefix}_salary`]
    );
    const spouse2RetireYear = findFirstZeroYear(spouseOptiList?.sps2_salary);

    const retirementEvents = {
      [spouse1RetireYear]: `${optiList?.[`${spousePrefix}_name`]}'s retirement`,
      [spouse2RetireYear]: spouseStatus
        ? `${spouseOptiList?.sps2_name}'s retirement`
        : "",
      // Add more events as needed
    };
    const handleMenuItemClick = useCallbackRef(
      (index, yr) => async (e) => {
        e.preventDefault();
        const updateState = async () => {
          setListPlace(index);
          setYear(yr);
          setAgeEnd(true);
          setAgeStart(true);
          setNextYear(yr + 1);
        };
        await updateState();
      },
      [setListPlace, setYear, setAgeEnd, setAgeStart, setNextYear]
    );

    return (
      <>
        {isSmallScreen && (
          <div className="activity-summary-container">
            <div>
              <Box
                className="activity-summary-header"
                display={"flex"}
                padding={"10px 10px"}
                justifyContent={"space-between"}
                alignItems={"center"}
                alignSelf={"stretch"}
                borderRadius={"5px"}
                border={"1px solid #00A2D4"}
                bg={"#FFF"}
                h={"auto"}
              >
                <Box
                  display={"flex"}
                  alignItems={"flex-start"}
                  gap={"10px"}
                  flexDirection={"column"}
                >
                  <Box
                    className="activity-summary-profile-pic-container"
                    gap={"3px"}
                  >
                    {/* Profile picture to be added here when feature exists */}
                    {/* {user?.picture === null && ( */}
                    {imageError && (
                      <div
                        className="activity-summary-profile-pic-initials"
                        style={{ width: "25px", height: "25px" }}
                      >
                        <Text
                          color={"#FFF"}
                          fontFamily={"Nunito"}
                          fontSize={"12px"}
                          fontStyle={"normal"}
                          fontWeight={"800"}
                          lineHeight={"normal"}
                        >
                          {optiList?.[`${spousePrefix}_name`]?.charAt(0)}

                          {optiList?.[`${spousePrefix}_lastName`]?.charAt(0)}
                        </Text>
                      </div>
                    )}
                    {!imageError && profilePicUrl && (
                      <div
                        className="activity-summary-profile-pic"
                        style={{ width: "25px", height: "25px" }}
                      >
                        <img
                          src={profilePicUrl}
                          alt="Profile"
                          style={{
                            width: "100%",
                            height: "100%",
                            objectFit: "cover",
                            objectPosition: "top center",
                            justifyContent: "center",
                          }}
                          onError={() => setImageError(true)}
                        />
                      </div>
                    )}
                    <div>
                      <Text
                        color={"#00A2D4"}
                        textAlign={"center"}
                        fontFamily={"Nunito"}
                        fontSize={"12px"}
                        fontStyle={"normal"}
                        fontWeight={"700"}
                        lineHeight={"130%"}
                      >
                        {getAge(
                          optiList?.[`${spousePrefix}_birthday`],
                          yearToDate(year)
                        )}
                      </Text>
                    </div>
                  </Box>
                  {spouseOptiList?.sps2_name && (
                    <Box
                      className="activity-summary-profile-pic-container"
                      gap={"3px"}
                    >
                      {spouseImageError && (
                        <div
                          className="activity-summary-profile-pic-spouse"
                          style={{ width: "25px", height: "25px" }}
                        >
                          <Text
                            color={"#00A2D4"}
                            fontFamily={"Nunito"}
                            fontSize={"12px"}
                            fontStyle={"normal"}
                            fontWeight={"800"}
                            lineHeight={"normal"}
                          >
                            {spouseOptiList?.sps2_name?.charAt(0)}
                            {spouseOptiList?.sps2_lastName?.charAt(0)}
                          </Text>
                        </div>
                      )}

                      {!spouseImageError && profilePicUrlSpouse && (
                        <div
                          className="activity-summary-profile-pic-spouse"
                          style={{ width: "25px", height: "25px" }}
                        >
                          <img
                            src={profilePicUrlSpouse}
                            alt="Profile"
                            style={{
                              width: "100%",
                              height: "100%",
                              objectFit: "cover",
                              objectPosition: "top center",
                              justifyContent: "center",
                            }}
                            onError={() => setSpouseImageError(true)}
                          />
                        </div>
                      )}
                      <div>
                        <div>
                          <Text
                            color={"#00A2D4"}
                            textAlign={"center"}
                            fontFamily={"Nunito"}
                            fontSize={"12px"}
                            fontStyle={"normal"}
                            fontWeight={"700"}
                            lineHeight={"130%"}
                          >
                            {getAge(
                              spouseOptiList?.sps2_birthday,
                              yearToDate(year)
                            )}
                          </Text>
                        </div>
                      </div>
                    </Box>
                  )}
                </Box>
                <VStack align={"center"} ml={"10px"}>
                  <Box
                    display={"flex"}
                    flexDirection={"column"}
                    alignItems={"center"}
                    alignContent={"flex-end"}
                    position={"relative"}
                    alignSelf={"stretch"}
                  >
                    <Text
                      color={"#004069"}
                      textAlign={"center"}
                      fontFamily={"Nunito"}
                      fontSize={"12px"}
                      fontStyle={"normal"}
                      fontWeight={"700"}
                      lineHeight={"130%"}
                    >
                      Activity Summary for {year}
                    </Text>
                    {/* NEED TO ADD WHEN LOGIC SET UP */}
                    {/* <Text
                          color={"#004069"}
                          textAlign={"center"}
                          fontFamily={"Nunito"}
                          fontSize={"10px"}
                          fontStyle={"normal"}
                          fontWeight={"600"}
                          lineHeight={"normal"}
                          letterSpacing={"0.14px"}
                        >
                          Display
                        </Text>

                        <Tabs variant="unstyled">
                          <TabList>
                            <Tab
                              w={"70px"}
                              h={"18px"}
                              borderRadius={"50px 0px 0px 50px"}
                              border={"1px solid #00A2D4"}
                              _selected={{ color: "white", bg: "#00A2D4" }}
                              // onClick={() => setSheet(false)}
                              fontSize={"10px"}
                              alignItems={"center"}
                              lineHeight={"normal"}
                            >
                              Graphical
                            </Tab>
                            <Tab
                              w={"70px"}
                              h={"18px"}
                              border={"1px solid #00A2D4"}
                              borderRadius={"0px 50px 50px 0px"}
                              _selected={{ color: "white", bg: "#00A2D4" }}
                              // onClick={() => setSheet(true)}
                              fontSize={"10px"}
                              alignItems={"center"}
                              lineHeight={"normal"}
                              style={{ whiteSpace: "nowrap" }}
                            >
                              Cash Flow
                            </Tab>
                          </TabList>
                        </Tabs> */}
                  </Box>
                </VStack>
                <Box
                  display={"flex"}
                  flexDirection={"row"}
                  justifyContent={"flex-end"}
                  gap={"25px"}
                >
                  {(!stripePlan?.trial_end ||
                    calculateDaysLeft(stripePlan?.trial_end) < 1) && (
                    <Menu>
                      <MenuButton>
                        <HStack justifyContent={"end"} gap={"10px"}>
                          <Text
                            color={"#00A2D4"}
                            textAlign={"right"}
                            fontFamily={"Nunito"}
                            fontSize={"12px"}
                            fontStyle={"normal"}
                            fontWeight={"700"}
                            lineHeight={"130%"}
                          >
                            Year
                          </Text>
                          {ageEnd && (
                            <Icon
                              as={NextYearIcon}
                              svgwidth={"15px"}
                              svgheight={"15px"}
                              style={{
                                transform: isClicked
                                  ? "rotate(90deg)"
                                  : "rotate(0deg)",
                                transition: "transform 0.3s ease-in-out",
                              }}
                              onClick={() => setIsClicked(!isClicked)}
                            />
                          )}
                        </HStack>
                      </MenuButton>
                      <MenuList
                        bg={"#00466D"}
                        w={"150px"}
                        h={"300px"}
                        flexShrink={0}
                        overflowY={"auto"}
                        borderRadius={"5px"}
                      >
                        {years.map((yr, index) => (
                          <MenuItem
                            bg={"#00466D"}
                            key={index}
                            onClick={(e) => {
                              e.preventDefault();
                              setListPlace(index);
                              setYear(yr);
                              setAgeEnd(true);
                              setAgeStart(true);
                              setNextYear(yr + 1);
                            }}
                            _hover={{
                              bg: "#B6B3B3",
                              borderRadius: "5px",
                            }}
                          >
                            <Text
                              color={"#FFF"}
                              fontFamily={"Nunito"}
                              fontSize={"15px"}
                              fontStyle={"normal"}
                              fontWeight={"700"}
                              lineHeight={"normal"}
                            >
                              {yr}
                              {retirementEvents[yr] && (
                                <Text as={"span"} fontSize={"12px"}>
                                  {" "}
                                  - {retirementEvents[yr]}
                                </Text>
                              )}
                            </Text>
                          </MenuItem>
                        ))}
                      </MenuList>
                    </Menu>
                  )}
                  {calculateDaysLeft(stripePlan?.trial_end) >= 1 && (
                    <Menu>
                      <MenuButton>
                        <HStack justifyContent={"end"} gap={"10px"}>
                          <Text
                            color={"#00A2D4"}
                            textAlign={"right"}
                            fontFamily={"Nunito"}
                            fontSize={"12px"}
                            fontStyle={"normal"}
                            fontWeight={"700"}
                            lineHeight={"130%"}
                          >
                            Year
                          </Text>
                          {ageEnd && (
                            <Icon
                              as={NextYearIcon}
                              svgwidth={"15px"}
                              svgheight={"15px"}
                              style={{
                                transform: isClicked
                                  ? "rotate(90deg)"
                                  : "rotate(0deg)",
                                transition: "transform 0.3s ease-in-out",
                              }}
                              onClick={() => setIsClicked(!isClicked)}
                            />
                          )}
                        </HStack>
                      </MenuButton>
                      <MenuList
                        bg={"#00466D"}
                        w={"150px"}
                        h={"300px"}
                        flexShrink={0}
                        overflowY={"auto"}
                        borderRadius={"5px"}
                      >
                        {years.map((yr, index) => (
                          <MenuItem
                            bg={"#00466D"}
                            key={index}
                            onClick={() => UpgradeStripeModal()}
                            _hover={{
                              bg: "#B6B3B3",
                              borderRadius: "5px",
                            }}
                          >
                            <Text
                              color={"#FFF"}
                              fontFamily={"Nunito"}
                              fontSize={"15px"}
                              fontStyle={"normal"}
                              fontWeight={"700"}
                              lineHeight={"normal"}
                            >
                              {yr}
                              {retirementEvents[yr] && (
                                <Text as={"span"} fontSize={"12px"}>
                                  {" "}
                                  - {retirementEvents[yr]}
                                </Text>
                              )}
                            </Text>
                          </MenuItem>
                        ))}
                      </MenuList>
                    </Menu>
                  )}
                </Box>
              </Box>
              {userPopupNotification?.DashboardVideo === 1 &&
                (passedOptiList?.optimizationType !== "Error Value" ? (
                  <VideoModal
                    isOpen={isVideoModalOpen}
                    closeModal={closeVideoModal}
                    videoName={"Dashboard.mp4"}
                    title="Dashboard Page"
                  />
                ) : (
                  showVideo && (
                    <VideoModal
                      isOpen={isVideoModalOpen}
                      closeModal={closeVideoModal}
                      videoName={"Dashboard.mp4"}
                      title="Dashboard Page"
                    />
                  )
                ))}
              <Box
                display={"flex"}
                width={"100%"}
                flexDirection={"row"}
                paddingTop={"10px"}
                paddingBottom={"10px"}
                alignItems={"Flex-start"}
                rowGap={"10px"}
                flexWrap={"wrap"}
              >
                {dashboardLayout.largeCards?.map((card, index) => (
                  <Box
                    display={"flex"}
                    padding={"20px 15px"}
                    flexDirection={"column"}
                    alignItems={"center"}
                    gap={"25px"}
                    alignSelf={"stretch"}
                    borderRadius={"10px"}
                    border={"1px solid #00A2D4"}
                    bg={"#FFF"}
                    width={"100%"}
                  >
                    <DashboardLargeCard
                      card={card}
                      optiList={optiList}
                      assetsList={assetsList}
                      spousePage={spousePage}
                      spouseOptiList={spouseOptiList}
                      combinedIncome={safeAdd(
                        optiList?.[`${spousePrefix}_salary`]?.[listPlace],
                        spouseOptiList?.sps2_salary?.[listPlace]
                      )}
                      combinedCPP={safeAdd(
                        optiList?.[`${spousePrefix}_cpp`]?.[listPlace],
                        spouseOptiList?.sps2_cpp?.[listPlace]
                      )}
                      combinedOAS={safeAdd(
                        optiList?.[`${spousePrefix}_oas`]?.[listPlace],
                        spouseOptiList?.sps2_oas?.[listPlace]
                      )}
                      combinedPension={safeAdd(
                        optiList?.[`${spousePrefix}_pension`]?.Benefits?.[
                          listPlace
                        ],
                        spouseOptiList?.sps2_pension?.Benefits?.[listPlace]
                      )}
                      combinedDividends={safeAdd(
                        optiList?.[`${spousePrefix}_dividends`]?.[listPlace],
                        spouseOptiList?.sps2_dividends?.[listPlace]
                      )}
                      combinedOtherIncome={safeAdd(
                        optiList?.[`${spousePrefix}_otherIncomeTotal`]?.[
                          listPlace
                        ],
                        spouseOptiList?.sps2_otherIncomeTotal?.[listPlace]
                      )}
                      combinedAssetSaleIncome={safeAdd(
                        assetsList?.PrimaryResidence?.Sale?.[listPlace],
                        assetsList?.RecreationPropertyYearlySale?.[listPlace],
                        assetsList?.LandYearlySale?.[listPlace],
                        assetsList?.IncomePropertyYearlySale?.[listPlace]
                      )}
                      combinedIncomePropertyIncome={
                        assetsList?.IncomePropertyYearlyIncome?.[listPlace]
                      }
                      combinedBusinessIncome={
                        assetsList?.businessYearlyDividends?.[listPlace]
                      }
                      combinedInheritanceIncome={
                        assetsList?.inheritanceYearlyIncome?.[listPlace]
                      }
                      combinedTotalIncome={safeAdd(
                        optiList?.[`${spousePrefix}_salary`]?.[listPlace],
                        optiList?.[`${spousePrefix}_pension`]?.Benefits?.[
                          listPlace
                        ],
                        optiList?.[`${spousePrefix}_cpp`]?.[listPlace],
                        optiList?.[`${spousePrefix}_oas`]?.[listPlace],
                        optiList?.[`${spousePrefix}_otherIncomeTotal`]?.[
                          listPlace
                        ],

                        spouseOptiList?.sps2_salary?.[listPlace],
                        spouseOptiList?.sps2_pension?.Benefits?.[listPlace],
                        spouseOptiList?.sps2_cpp?.[listPlace],
                        spouseOptiList?.sps2_oas?.[listPlace],
                        spouseOptiList?.sps2_otherIncomeTotal?.[listPlace]
                      )}
                      combinedTFSADeposits={safeAdd(
                        optiList?.[`${spousePrefix}_tfsaContributions`]?.[
                          listPlace
                        ],
                        spouseOptiList?.sps2_tfsaContributions?.[listPlace]
                      )}
                      combinedFHSADeposits={safeAdd(
                        optiList?.[`${spousePrefix}_fhsaContributions`]?.[
                          listPlace
                        ],
                        spouseOptiList?.sps2_fhsaContributions?.[listPlace]
                      )}
                      combinedRRSPDeposits={safeAdd(
                        optiList?.[`${spousePrefix}_registeredContributions`]?.[
                          listPlace
                        ],
                        spouseOptiList?.sps2_registeredContributions?.[
                          listPlace
                        ]
                      )}
                      combinedNonRegDeposits={safeAdd(
                        optiList?.[
                          `${spousePrefix}_nonRegisteredContributions`
                        ]?.[listPlace],
                        spouseOptiList?.sps2_nonRegisteredContributions?.[
                          listPlace
                        ]
                      )}
                      combinedRRSPContributions={safeAdd(
                        optiList?.[
                          `${spousePrefix}_registeredContributionsMatched`
                        ]?.[listPlace],
                        spouseOptiList?.sps2_registeredContributionsMatched?.[
                          listPlace
                        ]
                      )}
                      combinedPensionContributions={safeAdd(
                        optiList?.[`${spousePrefix}_pension`]
                          ?.EmployerContributions?.[listPlace],
                        spouseOptiList?.sps2_pension?.EmployerContributions?.[
                          listPlace
                        ]
                      )}
                      combinedTFSAWithdrawals={safeAdd(
                        optiList?.[`${spousePrefix}_tfsaWithdrawals`]?.[
                          listPlace
                        ],
                        spouseOptiList?.sps2_tfsaWithdrawals?.[listPlace]
                      )}
                      combinedFHSAWithdrawals={safeAdd(
                        optiList?.[`${spousePrefix}_fhsaWithdrawals`]?.[
                          listPlace
                        ],
                        spouseOptiList?.sps2_fhsaWithdrawals?.[listPlace]
                      )}
                      combinedRRSPWithdrawals={safeAdd(
                        optiList?.[`${spousePrefix}_registeredWithdrawals`]?.[
                          listPlace
                        ],
                        spouseOptiList?.sps2_registeredWithdrawals?.[listPlace]
                      )}
                      combinedLIRAWithdrawals={safeAdd(
                        optiList?.[`${spousePrefix}_liraWithdrawals`]?.[
                          listPlace
                        ],
                        spouseOptiList?.sps2_liraWithdrawals?.[listPlace]
                      )}
                      combinedNonRegWithdrawals={safeAdd(
                        optiList?.[
                          `${spousePrefix}_nonregisteredWithdrawals`
                        ]?.[listPlace],
                        spouseOptiList?.sps2_nonregisteredWithdrawals?.[
                          listPlace
                        ]
                      )}
                      combinedCashWithdrawals={safeAdd(
                        optiList?.[`${spousePrefix}_cashWithdrawals`]?.[
                          listPlace
                        ],
                        spouseOptiList?.sps2_cashWithdrawals?.[listPlace]
                      )}
                      combinedFixedWithdrawals={safeAdd(
                        optiList?.[`${spousePrefix}_fixedIncomeWithdrawals`]?.[
                          listPlace
                        ],
                        spouseOptiList?.sps2_fixedIncomeWithdrawals?.[listPlace]
                      )}
                      combinedBusinessDividendWithdrawals={safeAdd(
                        assetsList?.ccpcYearlyCapitalDividendsIssued?.[
                          listPlace
                        ],
                        assetsList?.ccpcYearlyEligibleDividendsIssued?.[
                          listPlace
                        ],
                        assetsList?.ccpcYearlyNonEligibleDividendsIssued?.[
                          listPlace
                        ],
                        assetsList?.ccpcYearlyShareholderLoanRepayments?.[
                          listPlace
                        ]
                      )}
                      combinedTFSAPortfolioBalance={safeAdd(
                        optiList?.[`${spousePrefix}_tfsaFunds`]?.[listPlace],
                        spouseOptiList?.sps2_tfsaFunds?.[listPlace]
                      )}
                      combinedFHSAPortfolioBalance={safeAdd(
                        optiList?.[`${spousePrefix}_fhsaFunds`]?.[listPlace],
                        spouseOptiList?.sps2_fhsaFunds?.[listPlace]
                      )}
                      combinedRRSPPortfolioBalance={safeAdd(
                        optiList?.[`${spousePrefix}_registeredFunds`]?.[
                          listPlace
                        ],
                        spouseOptiList?.sps2_registeredFunds?.[listPlace]
                      )}
                      combinedLIRAPortfolioBalance={safeAdd(
                        optiList?.[`${spousePrefix}_liraFunds`]?.[listPlace],
                        spouseOptiList?.sps2_liraFunds?.[listPlace]
                      )}
                      combinedNonRegPortfolioBalance={safeAdd(
                        optiList?.[`${spousePrefix}_nonregisteredFunds`]?.[
                          listPlace
                        ],
                        spouseOptiList?.sps2_nonregisteredFunds?.[listPlace]
                      )}
                      combinedCashBalance={safeAdd(
                        optiList?.[`${spousePrefix}_cashFunds`]?.[listPlace],
                        spouseOptiList?.sps2_cashFunds?.[listPlace]
                      )}
                      combinedFixedIncomeBalance={safeAdd(
                        optiList?.[`${spousePrefix}_fixedIncomeTotal`]?.[
                          listPlace
                        ],
                        spouseOptiList?.sps2_fixedIncomeTotal?.[listPlace]
                      )}
                      combinedPrimaryResMortgage={
                        assetsList?.PrimaryResidence?.Balance?.[listPlace]
                      }
                      combinedRecreationMortgage={
                        assetsList?.RecreationPropertyYearlyBalance?.[listPlace]
                      }
                      combinedLandMortgage={
                        assetsList?.LandYearlyBalance?.[listPlace]
                      }
                      combinedIncomePropertyMortgage={
                        assetsList?.IncomePropertyYearlyBalance?.[listPlace]
                      }
                      combinedLoans={assetsList?.totalDebtProrated?.[listPlace]}
                      combinedAssetPortfolioBalance={safeAdd(
                        assetsList?.PrimaryResidence?.Value?.[listPlace],
                        assetsList?.IncomePropertyYearlyValue?.[listPlace],
                        assetsList?.RecreationPropertyYearlyValue?.[listPlace],
                        assetsList?.LandYearlyValue?.[listPlace]
                      )}
                      combinedPersonAssetsBalance={
                        assetsList?.otherAssetsYearlyValue?.[listPlace]
                      }
                      combinedLifeInsuranceBalances={safeAdd(
                        assetsList?.lifeInsuranceYearlyValue?.[listPlace]
                      )}
                      combinedBusinessValue={
                        assetsList?.businessYearlyValue?.[listPlace]
                      }
                      combinedBusinessInvestmentsValue={
                        assetsList?.ccpcYearlyBalance?.[listPlace]
                      }
                      combinedPensionValue={safeAdd(
                        optiList?.[`${spousePrefix}_pension`]?.Value?.[
                          listPlace
                        ],
                        spouseOptiList?.sps2_pension?.Value?.[listPlace]
                      )}
                      provincialTax={safeAdd(
                        optiList?.[`${spousePrefix}_provincialTaxes`]?.[
                          listPlace
                        ],
                        spouseOptiList?.sps2_provincialTaxes?.[listPlace]
                      )}
                      federalTax={safeAdd(
                        optiList?.[`${spousePrefix}_federalTaxes`]?.[listPlace],
                        spouseOptiList?.sps2_federalTaxes?.[listPlace]
                      )}
                      cppContributions={safeAdd(
                        optiList?.[`${spousePrefix}_cppContributions`]?.[
                          listPlace
                        ],
                        spouseOptiList?.sps2_cppContributions?.[listPlace]
                      )}
                      eiPremium={safeAdd(
                        optiList?.[`${spousePrefix}_eiPremiums`]?.[listPlace],
                        spouseOptiList?.sps2_eiPremiums?.[listPlace]
                      )}
                      combinedDeductions={safeAdd(
                        optiList?.[`${spousePrefix}_salaryDeductions`]?.[
                          listPlace
                        ],
                        spouseOptiList?.sps2_salaryDeductions?.[listPlace]
                      )}
                      taxesTotal={safeAdd(
                        optiList?.[`${spousePrefix}_incomeTaxes`]?.[listPlace],
                        spouseOptiList?.sps2_incomeTaxes?.[listPlace],
                        optiList?.[`${spousePrefix}_cppContributions`]?.[
                          listPlace
                        ],
                        optiList?.[`${spousePrefix}_eiPremiums`]?.[listPlace],
                        spouseOptiList?.sps2_cppContributions?.[listPlace],
                        spouseOptiList?.sps2_eiPremiums?.[listPlace]
                      )}
                      averageTaxRate={averageTaxRate(
                        safeAdd(
                          optiList?.[`${spousePrefix}_taxableIncome`]?.[
                            listPlace
                          ],
                          spouseOptiList?.sps2_taxableIncome?.[listPlace]
                        ),

                        safeAdd(
                          optiList?.[`${spousePrefix}_incomeTaxes`]?.[
                            listPlace
                          ],
                          spouseOptiList?.sps2_incomeTaxes?.[listPlace],
                          optiList?.[`${spousePrefix}_cppContributions`]?.[
                            listPlace
                          ],
                          spouseOptiList?.sps2_cppContributions?.[listPlace],
                          optiList?.[`${spousePrefix}_eiPremiums`]?.[listPlace],
                          spouseOptiList?.sps2_eiPremiums?.[listPlace]
                        )
                      )}
                      totalIncome={safeAdd(
                        optiList?.[`${spousePrefix}_salary`]?.[listPlace],
                        optiList?.[`${spousePrefix}_pension`]?.Benefits?.[
                          listPlace
                        ],
                        optiList?.[`${spousePrefix}_cpp`]?.[listPlace],
                        optiList?.[`${spousePrefix}_oas`]?.[listPlace],
                        optiList?.[`${spousePrefix}_otherIncomeTotal`]?.[
                          listPlace
                        ],

                        assetsList?.PrimaryResidence?.Sale?.[listPlace],
                        assetsList?.RecreationPropertyYearlySale?.[listPlace],
                        assetsList?.LandYearlySale?.[listPlace],
                        assetsList?.IncomePropertyYearlySale?.[listPlace],
                        assetsList?.IncomePropertyYearlyIncome?.[listPlace],
                        assetsList?.businessYearlyDividends?.[listPlace],
                        assetsList?.inheritanceYearlyIncome?.[listPlace],

                        spouseOptiList?.sps2_salary?.[listPlace],
                        spouseOptiList?.sps2_pension?.Benefits?.[listPlace],
                        spouseOptiList?.sps2_cpp?.[listPlace],
                        spouseOptiList?.sps2_oas?.[listPlace],
                        spouseOptiList?.sps2_otherIncomeTotal?.[listPlace]
                      )}
                      totalWithdrawals={safeAdd(
                        optiList?.[`${spousePrefix}_tfsaWithdrawals`]?.[
                          listPlace
                        ],
                        optiList?.[`${spousePrefix}_fhsaWithdrawals`]?.[
                          listPlace
                        ],
                        optiList?.[`${spousePrefix}_registeredWithdrawals`]?.[
                          listPlace
                        ],
                        optiList?.[`${spousePrefix}_liraWithdrawals`]?.[
                          listPlace
                        ],
                        optiList?.[
                          `${spousePrefix}_nonregisteredWithdrawals`
                        ]?.[listPlace],
                        optiList?.[`${spousePrefix}_cashWithdrawals`]?.[
                          listPlace
                        ],
                        optiList?.[`${spousePrefix}_fixedIncomeWithdrawals`]?.[
                          listPlace
                        ],

                        spouseOptiList?.sps2_tfsaWithdrawals?.[listPlace],
                        spouseOptiList?.sps2_fhsaWithdrawals?.[listPlace],
                        spouseOptiList?.sps2_registeredWithdrawals?.[listPlace],
                        spouseOptiList?.sps2_liraWithdrawals?.[listPlace],
                        spouseOptiList?.sps2_nonregisteredWithdrawals?.[
                          listPlace
                        ],
                        spouseOptiList?.sps2_cashWithdrawals?.[listPlace],
                        spouseOptiList?.sps2_fixedIncomeWithdrawals?.[
                          listPlace
                        ],

                        assetsList?.ccpcYearlyCapitalDividendsIssued?.[
                          listPlace
                        ],
                        assetsList?.ccpcYearlyEligibleDividendsIssued?.[
                          listPlace
                        ],
                        assetsList?.ccpcYearlyNonEligibleDividendsIssued?.[
                          listPlace
                        ]
                      )}
                      totalTaxes={safeAdd(
                        optiList?.[`${spousePrefix}_incomeTaxes`]?.[listPlace],
                        optiList?.[`${spousePrefix}_cppContributions`]?.[
                          listPlace
                        ],
                        optiList?.[`${spousePrefix}_eiPremiums`]?.[listPlace],

                        spouseOptiList?.sps2_incomeTaxes?.[listPlace],
                        spouseOptiList?.sps2_cppContributions?.[listPlace],
                        spouseOptiList?.sps2_eiPremiums?.[listPlace]
                      )}
                      totalDeposits={safeAdd(
                        optiList?.[`${spousePrefix}_registeredContributions`]?.[
                          listPlace
                        ],
                        optiList?.[
                          `${spousePrefix}_registeredContributionsMatched`
                        ]?.[listPlace],
                        optiList?.[
                          `${spousePrefix}_nonRegisteredContributions`
                        ]?.[listPlace],
                        optiList?.[`${spousePrefix}_tfsaContributions`]?.[
                          listPlace
                        ],
                        optiList?.[`${spousePrefix}_fhsaContributions`]?.[
                          listPlace
                        ],
                        optiList?.[`${spousePrefix}_pension`]
                          ?.EmployerContributions?.[listPlace],

                        spouseOptiList?.sps2_registeredContributions?.[
                          listPlace
                        ],
                        spouseOptiList?.sps2_registeredContributionsMatched?.[
                          listPlace
                        ],
                        spouseOptiList?.sps2_nonRegisteredContributions?.[
                          listPlace
                        ],
                        spouseOptiList?.sps2_tfsaContributions?.[listPlace],
                        spouseOptiList?.sps2_fhsaContributions?.[listPlace],
                        spouseOptiList?.sps2_pension?.EmployerContributions?.[
                          listPlace
                        ]
                      )}
                      totalExpenses={safeAdd(
                        assetsList?.expenses?.[listPlace],
                        assetsList?.totalDebtPayments?.[listPlace],
                        assetsList?.PrimaryResidence?.Payments?.[listPlace],
                        assetsList?.RecreationPropertyYearlyPayments?.[
                          listPlace
                        ],
                        assetsList?.LandYearlyPayments?.[listPlace],
                        assetsList?.IncomePropertyYearlyPayments?.[listPlace],
                        assetsList?.lifeInsuranceYearlyPayments?.[listPlace],
                        assetsList?.PrimaryResidence?.DownPayment?.[listPlace],
                        -1 *
                          assetsList?.PrimaryResidence?.RentDecrease?.[
                            listPlace
                          ]
                      )}
                      thisYearDeposits={safeAdd(
                        optiList?.[
                          `${spousePrefix}_registeredInitialContributions`
                        ]?.[listPlace],
                        optiList?.[
                          `${spousePrefix}_nonRegisteredInitialContributions`
                        ]?.[listPlace],
                        optiList?.[
                          `${spousePrefix}_tfsaInitialContributions`
                        ]?.[listPlace],
                        optiList?.[
                          `${spousePrefix}_fhsaInitialContributions`
                        ]?.[listPlace],
                        optiList?.sps2_registeredInitialContributions?.[
                          listPlace
                        ],
                        optiList?.sps2_nonRegisteredInitialContributions?.[
                          listPlace
                        ],
                        optiList?.sps2_tfsaInitialContributions?.[listPlace],
                        optiList?.sps2_fhsaInitialContributions?.[listPlace],
                        optiList?.[
                          `${spousePrefix}_registeredContributionsMatched`
                        ]?.EmployerContributions?.[listPlace],
                        optiList?.sps2_registeredContributionsMatched
                          ?.EmployerContributions?.[listPlace],
                        optiList?.[`${spousePrefix}_pension`]
                          ?.EmployerContributions?.[listPlace],
                        optiList?.sps2_pension?.EmployerContributions?.[
                          listPlace
                        ]
                      )}
                      extraSpending={assetsList?.extraSpending?.[listPlace]}
                      totalGoals={assetsList?.goalsYearlyExpenses?.[listPlace]}
                      extraSpendingSpouse={safeAdd(
                        optiList?.[`${spousePrefix}_netWithdrawal`]?.[
                          listPlace
                        ],
                        spouseOptiList?.sps2_netWithdrawal?.[listPlace],
                        assetsList?.totalDebtPayments?.[listPlace] / 2,
                        assetsList?.PrimaryResidence?.Payments?.[listPlace] / 2,
                        assetsList?.RecreationPropertyYearlyPayments?.[
                          listPlace
                        ] / 2,
                        assetsList?.LandYearlyPayments?.[listPlace] / 2,
                        assetsList?.IncomePropertyYearlyPayments?.[listPlace] /
                          2,
                        assetsList?.lifeInsuranceYearlyPayments?.[listPlace] /
                          2,
                        assetsList?.PrimaryResidence?.DownPayment?.[listPlace] /
                          2
                      )}
                    />
                  </Box>
                ))}
              </Box>

              {/* {!isDragDisabled && (
                      <div className="move-small-card-down-container">
                        <img
                          id="incrementYear"
                          className="next-year-button"
                          src={nextYearIcon}
                          onClick={() => onDragOverallLayoutEnd(card, "down")}
                        />
                      </div>
                    )}
                   */}
            </div>
          </div>
        )}
        {!isSmallScreen && (
          <>
            <Box
              display={"flex"}
              padding={"15px 20px"}
              marginTop={"20px"}
              justifyContent={"space-between"}
              alignSelf={"stretch"}
              borderRadius={"5px"}
              border={"1px solid #00A2D4"}
              bg={"#FFF"}
              h={"auto"}
            >
              {/* {!isDragDisabled && (
                      <div className="move-card-up-container">
                        <img
                          id="incrementYear"
                          className="next-year-button"
                          src={nextYearIcon}
                          onClick={() => onDragOverallLayoutEnd(card, "up")}
                        />
                      </div>
                    )} */}

              <Box
                display={"flex"}
                alignItems={isLargerThan1200 ? "flex-start" : "center"}
                justifyContent={isLargerThan1200 && "center"}
                gap={"25px"}
                w={isLargerThan1200 ? "20%" : "277px"}
                flexDirection={isLargerThan1200 ? "column" : "row"}
              >
                <Box
                  className="activity-summary-profile-pic-container"
                  gap={"10px"}
                  alignItems={"start"}
                >
                  {/* Profile picture to be added here when feature exists */}
                  {/* {user?.picture === null && ( */}
                  {imageError && (
                    <div className="activity-summary-profile-pic-initials">
                      <Text
                        color={"#FFF"}
                        fontFamily={"Nunito"}
                        fontSize={"22px"}
                        fontStyle={"normal"}
                        fontWeight={"800"}
                        lineHeight={"normal"}
                        textAlign={"center"}
                      >
                        {optiList?.[`${spousePrefix}_name`]?.charAt(0)}

                        {optiList?.[`${spousePrefix}_lastName`]?.charAt(0)}
                      </Text>
                    </div>
                  )}
                  {!imageError && profilePicUrl && (
                    <div className="activity-summary-profile-pic">
                      <img
                        src={profilePicUrl}
                        alt="Profile"
                        style={{
                          width: "100%",
                          height: "100%",
                          objectFit: "cover",
                          objectPosition: "top center",
                          justifyContent: "center",
                        }}
                        onError={() => setImageError(true)}
                      />
                    </div>
                  )}

                  <Text
                    color={"#00A2D4"}
                    textAlign={"center"}
                    align={"center"}
                    fontFamily={"Nunito"}
                    fontSize={"28px"}
                    fontStyle={"normal"}
                    fontWeight={"700"}
                    lineHeight={"175%"}
                  >
                    {getAge(
                      optiList?.[`${spousePrefix}_birthday`],
                      yearToDate(year)
                    )}
                  </Text>
                </Box>
                {spouseOptiList?.sps2_name && (
                  <Box
                    className="activity-summary-profile-pic-container"
                    gap={"10px"}
                    display={"flex"}
                  >
                    {spouseImageError && (
                      <div className="activity-summary-profile-pic-spouse">
                        <Text
                          color={"#00A2D4"}
                          fontFamily={"Nunito"}
                          fontSize={"22px"}
                          fontStyle={"normal"}
                          fontWeight={"800"}
                          lineHeight={"normal"}
                        >
                          {spouseOptiList?.sps2_name?.charAt(0)}
                          {spouseOptiList?.sps2_lastName?.charAt(0)}
                        </Text>
                      </div>
                    )}
                    {!spouseImageError && profilePicUrlSpouse && (
                      <div className="activity-summary-profile-pic-spouse">
                        <img
                          src={profilePicUrlSpouse}
                          alt="Profile"
                          style={{
                            width: "100%",
                            height: "100%",
                            objectFit: "cover",
                            objectPosition: "top center",
                            justifyContent: "center",
                          }}
                          onError={() => setSpouseImageError(true)}
                        />
                      </div>
                    )}
                    <div>
                      <div>
                        <Text
                          color={"#00A2D4"}
                          textAlign={"center"}
                          fontFamily={"Nunito"}
                          fontSize={"28px"}
                          fontStyle={"normal"}
                          fontWeight={"700"}
                          lineHeight={"130%"}
                        >
                          {getAge(
                            spouseOptiList?.sps2_birthday,
                            yearToDate(year)
                          )}
                        </Text>
                      </div>
                    </div>
                  </Box>
                )}
              </Box>

              <VStack justifyContent={"center"}>
                <Box
                  display={"flex"}
                  flexDirection={"column"}
                  alignItems={"center"}
                  alignContent={"flex-end"}
                  position={"relative"}
                  gap={"5px"}
                >
                  <Text
                    color={"#004069"}
                    textAlign={"center"}
                    fontFamily={"Nunito"}
                    fontSize={isLargerThan1200 ? "28px" : "36px"}
                    fontStyle={"normal"}
                    fontWeight={"700"}
                    lineHeight={"130%"}
                  >
                    Activity Summary for {year}
                  </Text>
                  {/* NEED TO ADD WHEN LOGIC ADDED */}
                  {/* <Box
                          display={"flex"}
                          gap={"2px"}
                          flexDirection={"column"}
                        >
                          <Text
                            color={"#004069"}
                            textAlign={"center"}
                            fontFamily={"Nunito"}
                            fontSize={"14px"}
                            fontStyle={"normal"}
                            fontWeight={"600"}
                            lineHeight={"normal"}
                            letterSpacing={"0.14px"}
                          >
                            Display
                          </Text>
                          <Tabs variant="unstyled">
                            <TabList>
                              <Tab
                                w={"110px"}
                                borderRadius={"50px 0px 0px 50px"}
                                border={"1px solid #00A2D4"}
                                _selected={{ color: "white", bg: "#00A2D4" }}
                                // onClick={() => setSheet(false)}
                              >
                                Graphical
                              </Tab>
                              <Tab
                                w={"110px"}
                                border={"1px solid #00A2D4"}
                                borderRadius={"0px 50px 50px 0px"}
                                _selected={{ color: "white", bg: "#00A2D4" }}
                                // onClick={() => setSheet(true)}
                              >
                                Cash Flow
                              </Tab>
                            </TabList>
                          </Tabs>
                        </Box> */}
                </Box>
              </VStack>
              <Box
                display={"flex"}
                flexDirection={"row"}
                justifyContent={"flex-end"}
                gap={"25px"}
                w={isLargerThan1200 ? "20%" : "277px"}
              >
                {(!stripePlan?.trial_end ||
                  calculateDaysLeft(stripePlan?.trial_end) < 1) && (
                  <Menu>
                    <MenuButton>
                      <HStack
                        justifyContent={isLargerThan1200 ? "center" : "end"}
                        gap={"10px"}
                        alignContent={"center"}
                        alignItems={"center"}
                      >
                        <Text
                          color={"#00A2D4"}
                          textAlign={"right"}
                          fontFamily={"Nunito"}
                          fontSize={"20px"}
                          fontStyle={"normal"}
                          fontWeight={"700"}
                          lineHeight={"130%"}
                        >
                          Year
                        </Text>
                        {ageEnd && (
                          <Icon
                            as={NextYearIcon}
                            svgheight={"15px"}
                            svgwidth={"15px"}
                            transform={"rotate(180deg)"}
                          />
                        )}
                      </HStack>
                    </MenuButton>
                    <MenuList
                      bg={"#00466D"}
                      w={"150px"}
                      h={"300px"}
                      flexShrink={0}
                      overflowY={"auto"}
                      borderRadius={"5px"}
                      paddingLeft={"5px"}
                    >
                      {years.map((yr, index) => (
                        <MenuItem
                          bg={"#00466D"}
                          key={index}
                          onClick={handleMenuItemClick(index, yr)}
                          _hover={{ bg: "#B6B3B3", borderRadius: "5px" }}
                        >
                          <Text
                            color={"#FFF"}
                            fontFamily={"Nunito"}
                            fontSize={"15px"}
                            fontStyle={"normal"}
                            fontWeight={"700"}
                            lineHeight={"normal"}
                          >
                            {yr}
                            {retirementEvents[yr] && (
                              <Text as={"span"} fontSize={"12px"}>
                                {" "}
                                - {retirementEvents[yr]}
                              </Text>
                            )}
                          </Text>
                        </MenuItem>
                      ))}
                    </MenuList>
                  </Menu>
                )}
                {calculateDaysLeft(stripePlan?.trial_end) >= 1 && (
                  <Menu>
                    <MenuButton>
                      <HStack
                        justifyContent={isLargerThan1200 ? "center" : "end"}
                        gap={"10px"}
                        alignContent={"center"}
                        alignItems={"center"}
                      >
                        <Text
                          color={"#00A2D4"}
                          textAlign={"right"}
                          fontFamily={"Nunito"}
                          fontSize={"20px"}
                          fontStyle={"normal"}
                          fontWeight={"700"}
                          lineHeight={"130%"}
                        >
                          Year
                        </Text>
                        {ageEnd && (
                          <Icon
                            as={NextYearIcon}
                            svgheight={"15px"}
                            svgwidth={"15px"}
                            transform={"rotate(180deg)"}
                          />
                        )}
                      </HStack>
                    </MenuButton>
                    <MenuList
                      bg={"#00466D"}
                      w={"150px"}
                      h={"300px"}
                      flexShrink={0}
                      overflowY={"auto"}
                      borderRadius={"5px"}
                      paddingLeft={"5px"}
                    >
                      {years.map((yr, index) => (
                        <MenuItem
                          bg={"#00466D"}
                          key={index}
                          onClick={() => UpgradeStripeModal()}
                          _hover={{ bg: "#B6B3B3", borderRadius: "5px" }}
                        >
                          <Text
                            color={"#FFF"}
                            fontFamily={"Nunito"}
                            fontSize={"15px"}
                            fontStyle={"normal"}
                            fontWeight={"700"}
                            lineHeight={"normal"}
                          >
                            {yr}
                            {retirementEvents[yr] && (
                              <Text as={"span"} fontSize={"12px"}>
                                {" "}
                                - {retirementEvents[yr]}
                              </Text>
                            )}
                          </Text>
                        </MenuItem>
                      ))}
                    </MenuList>
                  </Menu>
                )}
              </Box>
            </Box>

            <Box
              display={"flex"}
              width={"100%"}
              flexDirection={"row"}
              padding={"20px 0px"}
              alignSelf={"stretch"}
              justifyContent={"space-between"}
              rowGap={"20px"}
              flexWrap={"wrap"}
              alignItems={"stretch"}
            >
              {dashboardLayout.largeCards?.map((card) => (
                <DashboardLargeCard
                  card={card}
                  optiList={optiList}
                  assetsList={assetsList}
                  spousePage={spousePage}
                  spouseOptiList={spouseOptiList}
                  combinedIncome={safeAdd(
                    optiList?.[`${spousePrefix}_salary`]?.[listPlace],
                    spouseOptiList?.sps2_salary?.[listPlace]
                  )}
                  combinedCPP={safeAdd(
                    optiList?.[`${spousePrefix}_cpp`]?.[listPlace],
                    spouseOptiList?.sps2_cpp?.[listPlace]
                  )}
                  combinedOAS={safeAdd(
                    optiList?.[`${spousePrefix}_oas`]?.[listPlace],
                    spouseOptiList?.sps2_oas?.[listPlace]
                  )}
                  combinedPension={safeAdd(
                    optiList?.[`${spousePrefix}_pension`]?.Benefits?.[
                      listPlace
                    ],
                    spouseOptiList?.sps2_pension?.Benefits?.[listPlace]
                  )}
                  combinedDividends={safeAdd(
                    optiList?.[`${spousePrefix}_dividends`]?.[listPlace],
                    spouseOptiList?.sps2_dividends?.[listPlace]
                  )}
                  combinedOtherIncome={safeAdd(
                    optiList?.[`${spousePrefix}_otherIncomeTotal`]?.[listPlace],
                    spouseOptiList?.sps2_otherIncomeTotal?.[listPlace]
                  )}
                  combinedAssetSaleIncome={safeAdd(
                    assetsList?.PrimaryResidence?.Sale?.[listPlace],
                    assetsList?.RecreationPropertyYearlySale?.[listPlace],
                    assetsList?.LandYearlySale?.[listPlace],
                    assetsList?.IncomePropertyYearlySale?.[listPlace]
                  )}
                  combinedIncomePropertyIncome={
                    assetsList?.IncomePropertyYearlyIncome?.[listPlace]
                  }
                  combinedBusinessIncome={
                    assetsList?.businessYearlyDividends?.[listPlace]
                  }
                  combinedInheritanceIncome={
                    assetsList?.inheritanceYearlyIncome?.[listPlace]
                  }
                  combinedTotalIncome={safeAdd(
                    optiList?.[`${spousePrefix}_salary`]?.[listPlace],
                    optiList?.[`${spousePrefix}_pension`]?.Benefits?.[
                      listPlace
                    ],
                    optiList?.[`${spousePrefix}_cpp`]?.[listPlace],
                    optiList?.[`${spousePrefix}_oas`]?.[listPlace],
                    optiList?.[`${spousePrefix}_otherIncomeTotal`]?.[listPlace],

                    spouseOptiList?.sps2_salary?.[listPlace],
                    spouseOptiList?.sps2_pension?.Benefits?.[listPlace],
                    spouseOptiList?.sps2_cpp?.[listPlace],
                    spouseOptiList?.sps2_oas?.[listPlace],
                    spouseOptiList?.sps2_otherIncomeTotal?.[listPlace]
                  )}
                  combinedTFSADeposits={safeAdd(
                    optiList?.[`${spousePrefix}_tfsaContributions`]?.[
                      listPlace
                    ],
                    spouseOptiList?.sps2_tfsaContributions?.[listPlace]
                  )}
                  combinedFHSADeposits={safeAdd(
                    optiList?.[`${spousePrefix}_fhsaContributions`]?.[
                      listPlace
                    ],
                    spouseOptiList?.sps2_fhsaContributions?.[listPlace]
                  )}
                  combinedRRSPDeposits={safeAdd(
                    optiList?.[`${spousePrefix}_registeredContributions`]?.[
                      listPlace
                    ],
                    spouseOptiList?.sps2_registeredContributions?.[listPlace]
                  )}
                  combinedNonRegDeposits={safeAdd(
                    optiList?.[`${spousePrefix}_nonRegisteredContributions`]?.[
                      listPlace
                    ],
                    spouseOptiList?.sps2_nonRegisteredContributions?.[listPlace]
                  )}
                  combinedRRSPContributions={safeAdd(
                    optiList?.[
                      `${spousePrefix}_registeredContributionsMatched`
                    ]?.[listPlace],
                    spouseOptiList?.sps2_registeredContributionsMatched?.[
                      listPlace
                    ]
                  )}
                  combinedPensionContributions={safeAdd(
                    optiList?.[`${spousePrefix}_pension`]
                      ?.EmployerContributions?.[listPlace],
                    spouseOptiList?.sps2_pension?.EmployerContributions?.[
                      listPlace
                    ]
                  )}
                  combinedTFSAWithdrawals={safeAdd(
                    optiList?.[`${spousePrefix}_tfsaWithdrawals`]?.[listPlace],
                    spouseOptiList?.sps2_tfsaWithdrawals?.[listPlace]
                  )}
                  combinedFHSAWithdrawals={safeAdd(
                    optiList?.[`${spousePrefix}_fhsaWithdrawals`]?.[listPlace],
                    spouseOptiList?.sps2_fhsaWithdrawals?.[listPlace]
                  )}
                  combinedRRSPWithdrawals={safeAdd(
                    optiList?.[`${spousePrefix}_registeredWithdrawals`]?.[
                      listPlace
                    ],
                    spouseOptiList?.sps2_registeredWithdrawals?.[listPlace]
                  )}
                  combinedLIRAWithdrawals={safeAdd(
                    optiList?.[`${spousePrefix}_liraWithdrawals`]?.[listPlace],
                    spouseOptiList?.sps2_liraWithdrawals?.[listPlace]
                  )}
                  combinedNonRegWithdrawals={safeAdd(
                    optiList?.[`${spousePrefix}_nonregisteredWithdrawals`]?.[
                      listPlace
                    ],
                    spouseOptiList?.sps2_nonregisteredWithdrawals?.[listPlace]
                  )}
                  combinedCashWithdrawals={safeAdd(
                    optiList?.[`${spousePrefix}_cashWithdrawals`]?.[listPlace],
                    spouseOptiList?.sps2_cashWithdrawals?.[listPlace]
                  )}
                  combinedFixedWithdrawals={safeAdd(
                    optiList?.[`${spousePrefix}_fixedIncomeWithdrawals`]?.[
                      listPlace
                    ],
                    spouseOptiList?.sps2_fixedIncomeWithdrawals?.[listPlace]
                  )}
                  combinedBusinessDividendWithdrawals={safeAdd(
                    assetsList?.ccpcYearlyCapitalDividendsIssued?.[listPlace],
                    assetsList?.ccpcYearlyEligibleDividendsIssued?.[listPlace],
                    assetsList?.ccpcYearlyNonEligibleDividendsIssued?.[
                      listPlace
                    ],
                    assetsList?.ccpcYearlyShareholderLoanRepayments?.[listPlace]
                  )}
                  combinedTFSAPortfolioBalance={safeAdd(
                    optiList?.[`${spousePrefix}_tfsaFunds`]?.[listPlace],
                    spouseOptiList?.sps2_tfsaFunds?.[listPlace]
                  )}
                  combinedFHSAPortfolioBalance={safeAdd(
                    optiList?.[`${spousePrefix}_fhsaFunds`]?.[listPlace],
                    spouseOptiList?.sps2_fhsaFunds?.[listPlace]
                  )}
                  combinedRRSPPortfolioBalance={safeAdd(
                    optiList?.[`${spousePrefix}_registeredFunds`]?.[listPlace],
                    spouseOptiList?.sps2_registeredFunds?.[listPlace]
                  )}
                  combinedLIRAPortfolioBalance={safeAdd(
                    optiList?.[`${spousePrefix}_liraFunds`]?.[listPlace],
                    spouseOptiList?.sps2_liraFunds?.[listPlace]
                  )}
                  combinedNonRegPortfolioBalance={safeAdd(
                    optiList?.[`${spousePrefix}_nonregisteredFunds`]?.[
                      listPlace
                    ],
                    spouseOptiList?.sps2_nonregisteredFunds?.[listPlace]
                  )}
                  combinedCashBalance={safeAdd(
                    optiList?.[`${spousePrefix}_cashFunds`]?.[listPlace],
                    spouseOptiList?.sps2_cashFunds?.[listPlace]
                  )}
                  combinedFixedIncomeBalance={safeAdd(
                    optiList?.[`${spousePrefix}_fixedIncomeTotal`]?.[listPlace],
                    spouseOptiList?.sps2_fixedIncomeTotal?.[listPlace]
                  )}
                  combinedPrimaryResMortgage={
                    assetsList?.PrimaryResidence?.Balance?.[listPlace]
                  }
                  combinedRecreationMortgage={
                    assetsList?.RecreationPropertyYearlyBalance?.[listPlace]
                  }
                  combinedLandMortgage={
                    assetsList?.LandYearlyBalance?.[listPlace]
                  }
                  combinedIncomePropertyMortgage={
                    assetsList?.IncomePropertyYearlyBalance?.[listPlace]
                  }
                  combinedLoans={assetsList?.totalDebtProrated?.[listPlace]}
                  combinedAssetPortfolioBalance={safeAdd(
                    assetsList?.PrimaryResidence?.Value?.[listPlace],
                    assetsList?.IncomePropertyYearlyValue?.[listPlace],
                    assetsList?.RecreationPropertyYearlyValue?.[listPlace],
                    assetsList?.LandYearlyValue?.[listPlace]
                  )}
                  combinedPersonAssetsBalance={
                    assetsList?.otherAssetsYearlyValue?.[listPlace]
                  }
                  combinedLifeInsuranceBalances={safeAdd(
                    assetsList?.lifeInsuranceYearlyValue?.[listPlace]
                  )}
                  combinedBusinessValue={
                    assetsList?.businessYearlyValue?.[listPlace]
                  }
                  combinedBusinessInvestmentsValue={
                    assetsList?.ccpcYearlyBalance?.[listPlace]
                  }
                  combinedPensionValue={safeAdd(
                    optiList?.[`${spousePrefix}_pension`]?.Value?.[listPlace],
                    spouseOptiList?.sps2_pension?.Value?.[listPlace]
                  )}
                  provincialTax={safeAdd(
                    optiList?.[`${spousePrefix}_provincialTaxes`]?.[listPlace],
                    spouseOptiList?.sps2_provincialTaxes?.[listPlace]
                  )}
                  federalTax={safeAdd(
                    optiList?.[`${spousePrefix}_federalTaxes`]?.[listPlace],
                    spouseOptiList?.sps2_federalTaxes?.[listPlace]
                  )}
                  cppContributions={safeAdd(
                    optiList?.[`${spousePrefix}_cppContributions`]?.[listPlace],
                    spouseOptiList?.sps2_cppContributions?.[listPlace]
                  )}
                  eiPremium={safeAdd(
                    optiList?.[`${spousePrefix}_eiPremiums`]?.[listPlace],
                    spouseOptiList?.sps2_eiPremiums?.[listPlace]
                  )}
                  combinedDeductions={safeAdd(
                    optiList?.[`${spousePrefix}_salaryDeductions`]?.[listPlace],
                    spouseOptiList?.sps2_salaryDeductions?.[listPlace]
                  )}
                  taxesTotal={safeAdd(
                    optiList?.[`${spousePrefix}_incomeTaxes`]?.[listPlace],
                    spouseOptiList?.sps2_incomeTaxes?.[listPlace],
                    optiList?.[`${spousePrefix}_cppContributions`]?.[listPlace],
                    optiList?.[`${spousePrefix}_eiPremiums`]?.[listPlace],
                    spouseOptiList?.sps2_cppContributions?.[listPlace],
                    spouseOptiList?.sps2_eiPremiums?.[listPlace]
                  )}
                  averageTaxRate={averageTaxRate(
                    safeAdd(
                      optiList?.[`${spousePrefix}_taxableIncome`]?.[listPlace],
                      spouseOptiList?.sps2_taxableIncome?.[listPlace]
                    ),

                    safeAdd(
                      optiList?.[`${spousePrefix}_incomeTaxes`]?.[listPlace],
                      spouseOptiList?.sps2_incomeTaxes?.[listPlace],
                      optiList?.[`${spousePrefix}_cppContributions`]?.[
                        listPlace
                      ],
                      spouseOptiList?.sps2_cppContributions?.[listPlace],
                      optiList?.[`${spousePrefix}_eiPremiums`]?.[listPlace],
                      spouseOptiList?.sps2_eiPremiums?.[listPlace]
                    )
                  )}
                  totalIncome={safeAdd(
                    optiList?.[`${spousePrefix}_salary`]?.[listPlace],
                    optiList?.[`${spousePrefix}_pension`]?.Benefits?.[
                      listPlace
                    ],
                    optiList?.[`${spousePrefix}_cpp`]?.[listPlace],
                    optiList?.[`${spousePrefix}_oas`]?.[listPlace],
                    optiList?.[`${spousePrefix}_otherIncomeTotal`]?.[listPlace],

                    assetsList?.PrimaryResidence?.Sale?.[listPlace],
                    assetsList?.RecreationPropertyYearlySale?.[listPlace],
                    assetsList?.LandYearlySale?.[listPlace],
                    assetsList?.IncomePropertyYearlySale?.[listPlace],
                    assetsList?.IncomePropertyYearlyIncome?.[listPlace],
                    assetsList?.businessYearlyDividends?.[listPlace],
                    assetsList?.inheritanceYearlyIncome?.[listPlace],

                    spouseOptiList?.sps2_salary?.[listPlace],
                    spouseOptiList?.sps2_pension?.Benefits?.[listPlace],
                    spouseOptiList?.sps2_cpp?.[listPlace],
                    spouseOptiList?.sps2_oas?.[listPlace],
                    spouseOptiList?.sps2_otherIncomeTotal?.[listPlace]
                  )}
                  totalWithdrawals={safeAdd(
                    optiList?.[`${spousePrefix}_tfsaWithdrawals`]?.[listPlace],
                    optiList?.[`${spousePrefix}_fhsaWithdrawals`]?.[listPlace],
                    optiList?.[`${spousePrefix}_registeredWithdrawals`]?.[
                      listPlace
                    ],
                    optiList?.[`${spousePrefix}_liraWithdrawals`]?.[listPlace],
                    optiList?.[`${spousePrefix}_nonregisteredWithdrawals`]?.[
                      listPlace
                    ],
                    optiList?.[`${spousePrefix}_cashWithdrawals`]?.[listPlace],
                    optiList?.[`${spousePrefix}_fixedIncomeWithdrawals`]?.[
                      listPlace
                    ],

                    spouseOptiList?.sps2_tfsaWithdrawals?.[listPlace],
                    spouseOptiList?.sps2_fhsaWithdrawals?.[listPlace],
                    spouseOptiList?.sps2_registeredWithdrawals?.[listPlace],
                    spouseOptiList?.sps2_liraWithdrawals?.[listPlace],
                    spouseOptiList?.sps2_nonregisteredWithdrawals?.[listPlace],
                    spouseOptiList?.sps2_cashWithdrawals?.[listPlace],
                    spouseOptiList?.sps2_fixedIncomeWithdrawals?.[listPlace],

                    assetsList?.ccpcYearlyCapitalDividendsIssued?.[listPlace],
                    assetsList?.ccpcYearlyEligibleDividendsIssued?.[listPlace],
                    assetsList?.ccpcYearlyNonEligibleDividendsIssued?.[
                      listPlace
                    ]
                  )}
                  totalTaxes={safeAdd(
                    optiList?.[`${spousePrefix}_incomeTaxes`]?.[listPlace],
                    optiList?.[`${spousePrefix}_cppContributions`]?.[listPlace],
                    optiList?.[`${spousePrefix}_eiPremiums`]?.[listPlace],

                    spouseOptiList?.sps2_incomeTaxes?.[listPlace],
                    spouseOptiList?.sps2_cppContributions?.[listPlace],
                    spouseOptiList?.sps2_eiPremiums?.[listPlace]
                  )}
                  totalDeposits={safeAdd(
                    optiList?.[`${spousePrefix}_registeredContributions`]?.[
                      listPlace
                    ],
                    optiList?.[
                      `${spousePrefix}_registeredContributionsMatched`
                    ]?.[listPlace],
                    optiList?.[`${spousePrefix}_nonRegisteredContributions`]?.[
                      listPlace
                    ],
                    optiList?.[`${spousePrefix}_tfsaContributions`]?.[
                      listPlace
                    ],
                    optiList?.[`${spousePrefix}_fhsaContributions`]?.[
                      listPlace
                    ],
                    optiList?.[`${spousePrefix}_pension`]
                      ?.EmployerContributions?.[listPlace],

                    spouseOptiList?.sps2_registeredContributions?.[listPlace],
                    spouseOptiList?.sps2_registeredContributionsMatched?.[
                      listPlace
                    ],
                    spouseOptiList?.sps2_nonRegisteredContributions?.[
                      listPlace
                    ],
                    spouseOptiList?.sps2_tfsaContributions?.[listPlace],
                    spouseOptiList?.sps2_fhsaContributions?.[listPlace],
                    spouseOptiList?.sps2_pension?.EmployerContributions?.[
                      listPlace
                    ]
                  )}
                  totalExpenses={safeAdd(
                    assetsList?.expenses?.[listPlace],
                    assetsList?.totalDebtPayments?.[listPlace],
                    assetsList?.PrimaryResidence?.Payments?.[listPlace],
                    assetsList?.RecreationPropertyYearlyPayments?.[listPlace],
                    assetsList?.LandYearlyPayments?.[listPlace],
                    assetsList?.IncomePropertyYearlyPayments?.[listPlace],
                    assetsList?.lifeInsuranceYearlyPayments?.[listPlace],
                    assetsList?.PrimaryResidence?.DownPayment?.[listPlace],
                    -1 * assetsList?.PrimaryResidence?.RentDecrease?.[listPlace]
                  )}
                  thisYearDeposits={safeAdd(
                    optiList?.[
                      `${spousePrefix}_registeredInitialContributions`
                    ]?.[listPlace],
                    optiList?.[
                      `${spousePrefix}_nonRegisteredInitialContributions`
                    ]?.[listPlace],
                    optiList?.[`${spousePrefix}_tfsaInitialContributions`]?.[
                      listPlace
                    ],
                    optiList?.[`${spousePrefix}_fhsaInitialContributions`]?.[
                      listPlace
                    ],
                    optiList?.sps2_registeredInitialContributions?.[listPlace],
                    optiList?.sps2_nonRegisteredInitialContributions?.[
                      listPlace
                    ],
                    optiList?.sps2_tfsaInitialContributions?.[listPlace],
                    optiList?.sps2_fhsaInitialContributions?.[listPlace],
                    optiList?.[`${spousePrefix}_registeredContributionsMatched`]
                      ?.EmployerContributions?.[listPlace],
                    optiList?.sps2_registeredContributionsMatched
                      ?.EmployerContributions?.[listPlace],
                    optiList?.[`${spousePrefix}_pension`]
                      ?.EmployerContributions?.[listPlace],
                    optiList?.sps2_pension?.EmployerContributions?.[listPlace]
                  )}
                  extraSpending={assetsList?.extraSpending?.[listPlace]}
                  totalGoals={assetsList?.goalsYearlyExpenses?.[listPlace]}
                  extraSpendingSpouse={safeAdd(
                    optiList?.[`${spousePrefix}_netWithdrawal`]?.[listPlace],
                    spouseOptiList?.sps2_netWithdrawal?.[listPlace],
                    assetsList?.totalDebtPayments?.[listPlace] / 2,
                    assetsList?.PrimaryResidence?.Payments?.[listPlace] / 2,
                    assetsList?.RecreationPropertyYearlyPayments?.[listPlace] /
                      2,
                    assetsList?.LandYearlyPayments?.[listPlace] / 2,
                    assetsList?.IncomePropertyYearlyPayments?.[listPlace] / 2,
                    assetsList?.lifeInsuranceYearlyPayments?.[listPlace] / 2,
                    assetsList?.PrimaryResidence?.DownPayment?.[listPlace] / 2
                  )}
                />
              ))}
            </Box>
          </>
        )}
      </>
    );
  }

  // eslint-disable-next-line react/no-unstable-nested-components
  function GraphLayout(props) {
    const { card, index } = props;
    return (
      <div>
        {/* {!isDragDisabled && (
              <div className="move-small-card-up-container">
                <img
                  id="incrementYear"
                  className="next-year-button"
                  src={nextYearIcon}
                  onClick={() => onDragOverallLayoutEnd(card, "up")}
                />
              </div>
            )} */}
        {spousePage === "joint-portfolio" &&
          (!stripePlan?.trial_end ||
            calculateDaysLeft(stripePlan?.trial_end) < 1) && (
            <div className="dashboard-graph-container">
              <LineGraph
                title="Overview"
                currentAge={getAge(
                  optiList?.[`${spousePrefix}_birthday`],
                  yearToDate(year)
                )}
                spouseAge={getAge(
                  spouseOptiList?.sps2_birthday,
                  yearToDate(year)
                )}
                tfsaData={setPortfolioGraphData(
                  optiList?.[`${spousePrefix}_tfsaFunds`]?.map((e, i) =>
                    safeAdd(e, spouseOptiList?.sps2_tfsaFunds?.[i])
                  )
                )}
                fhsaData={setPortfolioGraphData(
                  optiList?.[`${spousePrefix}_fhsaFunds`]?.map((e, i) =>
                    safeAdd(e, spouseOptiList?.sps2_fhsaFunds?.[i])
                  )
                )}
                rrspData={setPortfolioGraphData(
                  optiList?.[`${spousePrefix}_registeredFunds`]?.map((e, i) =>
                    safeAdd(e, spouseOptiList?.sps2_registeredFunds?.[i])
                  )
                )}
                pensionData={setPortfolioGraphData(
                  optiList?.[`${spousePrefix}_pension`]?.Value?.map((e, i) =>
                    safeAdd(e, spouseOptiList?.sps2_pension?.Value?.[i])
                  )
                )}
                liraData={setPortfolioGraphData(
                  optiList?.[`${spousePrefix}_liraFunds`]?.map((e, i) =>
                    safeAdd(e, spouseOptiList?.sps2_liraFunds?.[i])
                  )
                )}
                nonRegData={setPortfolioGraphData(
                  optiList?.[`${spousePrefix}_nonregisteredFunds`]?.map(
                    (e, i) =>
                      safeAdd(e, spouseOptiList?.sps2_nonregisteredFunds?.[i])
                  )
                )}
                cashData={setPortfolioGraphData(
                  optiList?.[`${spousePrefix}_cashFunds`]?.map((e, i) =>
                    safeAdd(e, spouseOptiList?.sps2_cashFunds?.[i])
                  )
                )}
                fixedIncomeData={setPortfolioGraphData(
                  optiList?.[`${spousePrefix}_fixedIncomeTotal`]?.map((e, i) =>
                    safeAdd(e, spouseOptiList?.sps2_fixedIncomeTotal?.[i])
                  )
                )}
                realEstateData={setPortfolioGraphData(
                  optiList?.PrimaryResidence?.Value?.map((e, i) =>
                    safeAdd(
                      e,
                      optiList?.RecreationPropertyYearlyValue?.[i],
                      optiList?.LandYearlyValue?.[i],
                      optiList?.IncomePropertyYearlyValue?.[i]
                    )
                  )
                )}
                lifeInsuranceData={setPortfolioGraphData(
                  optiList?.lifeInsuranceYearlyValue
                )}
                businessData={setPortfolioGraphData(
                  optiList?.businessYearlyValue
                )}
                businessInvestmentData={setPortfolioGraphData(
                  optiList?.ccpcYearlyBalance
                )}
                otherAssetsData={setPortfolioGraphData(
                  optiList?.otherAssetsYearlyValue
                )}
                totalPortfolioData={setPortfolioGraphData(
                  optiList?.[`${spousePrefix}_nonregisteredFunds`]?.map(
                    (e, i) =>
                      safeAdd(
                        e,
                        optiList?.[`${spousePrefix}_registeredFunds`]?.[i],
                        spouseOptiList?.sps2_registeredFunds?.[i],
                        spouseOptiList?.sps2_nonregisteredFunds?.[i],
                        optiList?.[`${spousePrefix}_tfsaFunds`]?.[i],
                        spouseOptiList?.sps2_tfsaFunds?.[i],
                        optiList?.[`${spousePrefix}_fhsaFunds`]?.[i],
                        spouseOptiList?.sps2_fhsaFunds?.[i],
                        optiList?.[`${spousePrefix}_liraFunds`]?.[i],
                        spouseOptiList?.sps2_liraFunds?.[i],
                        optiList?.[`${spousePrefix}_pension`]?.Value?.[i],
                        spouseOptiList?.sps2_pension?.Value?.[i],
                        optiList?.PrimaryResidence?.Value?.[i],
                        optiList?.businessYearlyValue?.[i],
                        optiList?.ccpcYearlyBalance?.[i],
                        optiList?.RecreationPropertyYearlyValue?.[i],
                        optiList?.LandYearlyValue?.[i],
                        optiList?.IncomePropertyYearlyValue?.[i],
                        optiList?.lifeInsuranceYearlyValue?.[i],
                        optiList?.otherAssetsYearlyValue?.[i],
                        optiList?.[`${spousePrefix}_cashFunds`]?.[i],
                        spouseOptiList?.sps2_cashFunds?.[i],
                        optiList?.[`${spousePrefix}_fixedIncomeTotal`]?.[i],
                        spouseOptiList?.sps2_fixedIncomeTotal?.[i]
                      )
                  )
                )}
                graphClicked={"Overview"}
              />
            </div>
          )}
        {spousePage === "joint-portfolio" &&
          calculateDaysLeft(stripePlan?.trial_end) >= 1 && (
            <div className="dashboard-graph-container free-trial-blur">
              <LineGraph
                title="Overview"
                currentAge={getAge(
                  optiList?.[`${spousePrefix}_birthday`],
                  yearToDate(year)
                )}
                spouseAge={getAge(
                  spouseOptiList?.sps2_birthday,
                  yearToDate(year)
                )}
                tfsaData={setPortfolioGraphData(
                  optiList?.[`${spousePrefix}_tfsaFunds`]?.map((e, i) =>
                    safeAdd(e, spouseOptiList?.sps2_tfsaFunds?.[i])
                  )
                )}
                fhsaData={setPortfolioGraphData(
                  optiList?.[`${spousePrefix}_fhsaFunds`]?.map((e, i) =>
                    safeAdd(e, spouseOptiList?.sps2_fhsaFunds?.[i])
                  )
                )}
                rrspData={setPortfolioGraphData(
                  optiList?.[`${spousePrefix}_registeredFunds`]?.map((e, i) =>
                    safeAdd(e, spouseOptiList?.sps2_registeredFunds?.[i])
                  )
                )}
                pensionData={setPortfolioGraphData(
                  optiList?.[`${spousePrefix}_pension`]?.Value?.map((e, i) =>
                    safeAdd(e, spouseOptiList?.sps2_pension?.Value?.[i])
                  )
                )}
                liraData={setPortfolioGraphData(
                  optiList?.[`${spousePrefix}_liraFunds`]?.map((e, i) =>
                    safeAdd(e, spouseOptiList?.sps2_liraFunds?.[i])
                  )
                )}
                nonRegData={setPortfolioGraphData(
                  optiList?.[`${spousePrefix}_nonregisteredFunds`]?.map(
                    (e, i) =>
                      safeAdd(e, spouseOptiList?.sps2_nonregisteredFunds?.[i])
                  )
                )}
                cashData={setPortfolioGraphData(
                  optiList?.[`${spousePrefix}_cashFunds`]?.map((e, i) =>
                    safeAdd(e, spouseOptiList?.sps2_cashFunds?.[i])
                  )
                )}
                fixedIncomeData={setPortfolioGraphData(
                  optiList?.[`${spousePrefix}_fixedIncomeTotal`]?.map((e, i) =>
                    safeAdd(e, spouseOptiList?.sps2_fixedIncomeTotal?.[i])
                  )
                )}
                realEstateData={setPortfolioGraphData(
                  optiList?.PrimaryResidence?.Value?.map((e, i) =>
                    safeAdd(
                      e,
                      optiList?.RecreationPropertyYearlyValue?.[i],
                      optiList?.LandYearlyValue?.[i],
                      optiList?.IncomePropertyYearlyValue?.[i]
                    )
                  )
                )}
                lifeInsuranceData={setPortfolioGraphData(
                  optiList?.lifeInsuranceYearlyValue
                )}
                businessData={setPortfolioGraphData(
                  optiList?.businessYearlyValue
                )}
                businessInvestmentData={setPortfolioGraphData(
                  optiList?.ccpcYearlyBalance
                )}
                otherAssetsData={setPortfolioGraphData(
                  optiList?.otherAssetsYearlyValue
                )}
                totalPortfolioData={setPortfolioGraphData(
                  optiList?.[`${spousePrefix}_nonregisteredFunds`]?.map(
                    (e, i) =>
                      safeAdd(
                        e,
                        optiList?.[`${spousePrefix}_registeredFunds`]?.[i],
                        spouseOptiList?.sps2_registeredFunds?.[i],
                        spouseOptiList?.sps2_nonregisteredFunds?.[i],
                        optiList?.[`${spousePrefix}_tfsaFunds`]?.[i],
                        spouseOptiList?.sps2_tfsaFunds?.[i],
                        optiList?.[`${spousePrefix}_fhsaFunds`]?.[i],
                        spouseOptiList?.sps2_fhsaFunds?.[i],
                        optiList?.[`${spousePrefix}_liraFunds`]?.[i],
                        spouseOptiList?.sps2_liraFunds?.[i],
                        optiList?.[`${spousePrefix}_pension`]?.Value?.[i],
                        spouseOptiList?.sps2_pension?.Value?.[i],
                        optiList?.PrimaryResidence?.Value?.[i],
                        optiList?.businessYearlyValue?.[i],
                        optiList?.ccpcYearlyBalance?.[i],
                        optiList?.RecreationPropertyYearlyValue?.[i],
                        optiList?.LandYearlyValue?.[i],
                        optiList?.IncomePropertyYearlyValue?.[i],
                        optiList?.lifeInsuranceYearlyValue?.[i],
                        optiList?.otherAssetsYearlyValue?.[i],
                        optiList?.[`${spousePrefix}_cashFunds`]?.[i],
                        spouseOptiList?.sps2_cashFunds?.[i],
                        optiList?.[`${spousePrefix}_fixedIncomeTotal`]?.[i],
                        spouseOptiList?.sps2_fixedIncomeTotal?.[i]
                      )
                  )
                )}
                graphClicked={"Overview"}
              />
            </div>
          )}
        {spousePage != "joint-portfolio" &&
          (!stripePlan?.trial_end ||
            calculateDaysLeft(stripePlan?.trial_end) < 1) && (
            <div className="dashboard-graph-container">
              <LineGraph
                title="Overview"
                currentAge={getAge(
                  optiList?.[`${spousePrefix}_birthday`],
                  yearToDate(year)
                )}
                spouseAge={getAge(
                  spouseOptiList?.sps2_birthday,
                  yearToDate(year)
                )}
                tfsaData={setPortfolioGraphData(
                  optiList?.[`${spousePrefix}_tfsaFunds`]?.map((e, i) =>
                    safeAdd(e, spouseOptiList?.sps2_tfsaFunds?.[i])
                  )
                )}
                fhsaData={setPortfolioGraphData(
                  optiList?.[`${spousePrefix}_fhsaFunds`]?.map((e, i) =>
                    safeAdd(e, spouseOptiList?.sps2_fhsaFunds?.[i])
                  )
                )}
                liraData={setPortfolioGraphData(
                  optiList?.[`${spousePrefix}_liraFunds`]?.map((e, i) =>
                    safeAdd(e, spouseOptiList?.sps2_liraFunds?.[i])
                  )
                )}
                rrspData={setPortfolioGraphData(
                  optiList?.[`${spousePrefix}_registeredFunds`]?.map((e, i) =>
                    safeAdd(e, spouseOptiList?.sps2_registeredFunds?.[i])
                  )
                )}
                pensionData={setPortfolioGraphData(
                  optiList?.[`${spousePrefix}_pension`]?.Value?.map((e, i) =>
                    safeAdd(e, spouseOptiList?.sps2_pension?.Value?.[i])
                  )
                )}
                nonRegData={setPortfolioGraphData(
                  optiList?.[`${spousePrefix}_nonregisteredFunds`]?.map(
                    (e, i) =>
                      safeAdd(e, spouseOptiList?.sps2_nonregisteredFunds?.[i])
                  )
                )}
                cashData={setPortfolioGraphData(
                  optiList?.[`${spousePrefix}_cashFunds`]?.map((e, i) =>
                    safeAdd(e, spouseOptiList?.sps2_cashFunds?.[i])
                  )
                )}
                fixedIncomeData={setPortfolioGraphData(
                  optiList?.[`${spousePrefix}_fixedIncomeTotal`]?.map((e, i) =>
                    safeAdd(e, spouseOptiList?.sps2_fixedIncomeTotal?.[i])
                  )
                )}
                realEstateData={setPortfolioGraphData(
                  assetsList?.PrimaryResidence?.Value?.map((e, i) =>
                    safeAdd(
                      e / 2,
                      assetsList?.IncomePropertyYearlyValue?.[i] / 2,
                      assetsList?.RecreationPropertyYearlyValue?.[i] / 2,
                      assetsList?.LandYearlyValue?.[i] / 2
                    )
                  )
                )}
                lifeInsuranceData={setPortfolioGraphData(
                  assetsList?.lifeInsuranceYearlyValue?.map((e) => e / 2)
                )}
                businessData={setPortfolioGraphData(
                  assetsList?.businessYearlyValue?.map((e) => e / 2)
                )}
                businessInvestmentData={setPortfolioGraphData(
                  assetsList?.ccpcYearlyBalance?.map((e) => e / 2)
                )}
                otherAssetsData={setPortfolioGraphData(
                  assetsList?.otherAssetsYearlyValue?.map((e) => e / 2)
                )}
                totalPortfolioData={setPortfolioGraphData(
                  optiList?.[`${spousePrefix}_nonregisteredFunds`]?.map(
                    (e, i) =>
                      safeAdd(
                        e,
                        optiList?.[`${spousePrefix}_registeredFunds`]?.[i],
                        spouseOptiList?.sps2_registeredFunds?.[i],
                        spouseOptiList?.sps2_nonregisteredFunds?.[i],
                        optiList?.[`${spousePrefix}_tfsaFunds`]?.[i],
                        spouseOptiList?.sps2_tfsaFunds?.[i],
                        optiList?.[`${spousePrefix}_fhsaFunds`]?.[i],
                        spouseOptiList?.sps2_fhsaFunds?.[i],
                        optiList?.[`${spousePrefix}_liraFunds`]?.[i],
                        spouseOptiList?.sps2_liraFunds?.[i],
                        optiList?.[`${spousePrefix}_pension`]?.Value?.[i],
                        spouseOptiList?.sps2_pension?.Value?.[i],
                        optiList?.[`${spousePrefix}_cashFunds`]?.[i],
                        spouseOptiList?.sps2_cashFunds?.[i],
                        optiList?.[`${spousePrefix}_fixedIncomeTotal`]?.[i],
                        spouseOptiList?.sps2_fixedIncomeTotal?.[i],
                        assetsList?.PrimaryResidence?.Value?.[i] / 2,
                        assetsList?.businessYearlyValue?.[i] / 2,
                        assetsList?.ccpcYearlyBalance?.[i] / 2,
                        assetsList?.RecreationPropertyYearlyValue?.[i] / 2,
                        assetsList?.LandYearlyValue?.[i] / 2,
                        assetsList?.IncomePropertyYearlyValue?.[i] / 2,
                        assetsList?.lifeInsuranceYearlyValue?.[i] / 2,
                        assetsList?.otherAssetsYearlyValue?.[i] / 2
                      )
                  )
                )}
                graphClicked={"Overview"}
              />
            </div>
          )}
        {spousePage != "joint-portfolio" &&
          calculateDaysLeft(stripePlan?.trial_end) >= 1 && (
            <div className="dashboard-graph-container free-trial-blur">
              <LineGraph
                title="Overview"
                currentAge={getAge(
                  optiList?.[`${spousePrefix}_birthday`],
                  yearToDate(year)
                )}
                spouseAge={getAge(
                  spouseOptiList?.sps2_birthday,
                  yearToDate(year)
                )}
                tfsaData={setPortfolioGraphData(
                  optiList?.[`${spousePrefix}_tfsaFunds`]?.map((e, i) =>
                    safeAdd(e, spouseOptiList?.sps2_tfsaFunds?.[i])
                  )
                )}
                fhsaData={setPortfolioGraphData(
                  optiList?.[`${spousePrefix}_fhsaFunds`]?.map((e, i) =>
                    safeAdd(e, spouseOptiList?.sps2_fhsaFunds?.[i])
                  )
                )}
                liraData={setPortfolioGraphData(
                  optiList?.[`${spousePrefix}_liraFunds`]?.map((e, i) =>
                    safeAdd(e, spouseOptiList?.sps2_liraFunds?.[i])
                  )
                )}
                rrspData={setPortfolioGraphData(
                  optiList?.[`${spousePrefix}_registeredFunds`]?.map((e, i) =>
                    safeAdd(e, spouseOptiList?.sps2_registeredFunds?.[i])
                  )
                )}
                pensionData={setPortfolioGraphData(
                  optiList?.[`${spousePrefix}_pension`]?.Value?.map((e, i) =>
                    safeAdd(e, spouseOptiList?.sps2_pension?.Value?.[i])
                  )
                )}
                nonRegData={setPortfolioGraphData(
                  optiList?.[`${spousePrefix}_nonregisteredFunds`]?.map(
                    (e, i) =>
                      safeAdd(e, spouseOptiList?.sps2_nonregisteredFunds?.[i])
                  )
                )}
                cashData={setPortfolioGraphData(
                  optiList?.[`${spousePrefix}_cashFunds`]?.map((e, i) =>
                    safeAdd(e, spouseOptiList?.sps2_cashFunds?.[i])
                  )
                )}
                fixedIncomeData={setPortfolioGraphData(
                  optiList?.[`${spousePrefix}_fixedIncomeTotal`]?.map((e, i) =>
                    safeAdd(e, spouseOptiList?.sps2_fixedIncomeTotal?.[i])
                  )
                )}
                realEstateData={setPortfolioGraphData(
                  assetsList?.PrimaryResidence?.Value?.map((e, i) =>
                    safeAdd(
                      e / 2,
                      assetsList?.IncomePropertyYearlyValue?.[i] / 2,
                      assetsList?.RecreationPropertyYearlyValue?.[i] / 2,
                      assetsList?.LandYearlyValue?.[i] / 2
                    )
                  )
                )}
                lifeInsuranceData={setPortfolioGraphData(
                  assetsList?.lifeInsuranceYearlyValue?.map((e) => e / 2)
                )}
                businessData={setPortfolioGraphData(
                  assetsList?.businessYearlyValue?.map((e) => e / 2)
                )}
                businessInvestmentData={setPortfolioGraphData(
                  assetsList?.ccpcYearlyBalance?.map((e) => e / 2)
                )}
                otherAssetsData={setPortfolioGraphData(
                  assetsList?.otherAssetsYearlyValue?.map((e) => e / 2)
                )}
                totalPortfolioData={setPortfolioGraphData(
                  optiList?.[`${spousePrefix}_nonregisteredFunds`]?.map(
                    (e, i) =>
                      safeAdd(
                        e,
                        optiList?.[`${spousePrefix}_registeredFunds`]?.[i],
                        spouseOptiList?.sps2_registeredFunds?.[i],
                        spouseOptiList?.sps2_nonregisteredFunds?.[i],
                        optiList?.[`${spousePrefix}_tfsaFunds`]?.[i],
                        spouseOptiList?.sps2_tfsaFunds?.[i],
                        optiList?.[`${spousePrefix}_fhsaFunds`]?.[i],
                        spouseOptiList?.sps2_fhsaFunds?.[i],
                        optiList?.[`${spousePrefix}_liraFunds`]?.[i],
                        spouseOptiList?.sps2_liraFunds?.[i],
                        optiList?.[`${spousePrefix}_pension`]?.Value?.[i],
                        spouseOptiList?.sps2_pension?.Value?.[i],
                        optiList?.[`${spousePrefix}_cashFunds`]?.[i],
                        spouseOptiList?.sps2_cashFunds?.[i],
                        optiList?.[`${spousePrefix}_fixedIncomeTotal`]?.[i],
                        spouseOptiList?.sps2_fixedIncomeTotal?.[i],
                        assetsList?.PrimaryResidence?.Value?.[i] / 2,
                        assetsList?.businessYearlyValue?.[i] / 2,
                        assetsList?.ccpcYearlyBalance?.[i] / 2,
                        assetsList?.RecreationPropertyYearlyValue?.[i] / 2,
                        assetsList?.LandYearlyValue?.[i] / 2,
                        assetsList?.IncomePropertyYearlyValue?.[i] / 2,
                        assetsList?.lifeInsuranceYearlyValue?.[i] / 2,
                        assetsList?.otherAssetsYearlyValue?.[i] / 2
                      )
                  )
                )}
                graphClicked={"Overview"}
              />
            </div>
          )}
        {/* {!isDragDisabled && (
              <div className="move-small-card-down-container">
                <img
                  id="incrementYear"
                  className="next-year-button"
                  src={nextYearIcon}
                  onClick={() => onDragOverallLayoutEnd(card, "down")}
                />
              </div>
            )} */}
      </div>
    );
  }

  // eslint-disable-next-line react/no-unstable-nested-components

  // function SmallCard(props) {
  //   const { card, index } = props;
  //   return <>{<SmallCardLayout card={card} index={index} />}</>;
  // }
  function DashboardLayout(props) {
    const { card, index } = props;
    return (
      <>
        {<SmallCardLayout />}
        {<LargeCardLayout />}
        {<GraphLayout card={card} index={index} />}
      </>
    );
  }

  function remainingSaves() {
    if (
      stripePlan?.items?.data?.[0]?.price?.lookup_key === "PREMIUM" ||
      stripePlan?.items?.data?.[0]?.price?.lookup_key === "PREMIUM_M"
    ) {
      return 20 - userArchiveInfo.length;
    }
    if (
      stripePlan?.items?.data?.[0]?.price?.lookup_key === "PRO" ||
      stripePlan?.items?.data?.[0]?.price?.lookup_key === "PRO_M"
    ) {
      return 10 - userArchiveInfo.length;
    } else {
      return 10;
    }
  }

  function openSaveAnalysis() {
    setSaveAnalysisModal(true);
  }

  function closeSaveAnalysisModal() {
    setSaveAnalysisModal(false);
  }

  function UpgradeStripeModal() {
    setShowStripeModal(!showStripeModal);
  }

  const [showSpouseProfile, setShowSpouseProfile] = useState(false);
  function spouseDataChange(change) {
    if (change === "joint-portfolio" && spouseStatus) {
      setSpousePrefix("sps1");
      setOptiList(passedOptiList);
      setAssetsList(passedOptiList);
      setSpouseOptiList(passedSpouseOptiList);
      setShowSpouseProfile(false);
    } else if (change === "spouse-one-portfolio") {
      setSpousePrefix("sps1");
      setOptiList(passedOptiList);
      setAssetsList(passedOptiList);
      setSpouseOptiList("");
    } else if (change === "spouse-two-portfolio") {
      setSpousePrefix("sps2");
      setOptiList(passedSpouseOptiList);
      setAssetsList(passedOptiList);
      setSpouseOptiList("");
      setShowSpouseProfile(true);
    }
  }
  const [isLargerThan768] = useMediaQuery("(min-width: 1870px)");
  const [isLargerThan1200] = useMediaQuery(
    "(min-width: 500px) and (max-width: 1250px)"
  );

  // const renderModals = () => {
  //   <>
  //    {!errorModalShown && (

  //       )}
  //   </>
  // }
  const [isLargerThan500] = useMediaQuery("(min-width: 500px)");
  const isSmallScreen = !isLargerThan500;

  let count = 0;

  const [errorModalShown, setErrorModalShown] = useState(false); // State to track if error modal has been shown

  return (
    <Box
      display={"flex"}
      flexDirection={"column"}
      alignItems={"center"}
      width={"100%"}
      bg={"#F3FAFF"}
    >
      {isSmallScreen && (
        <Box
          display={"flex"}
          flexDirection={"column"}
          alignItems={"flex-start"}
          alignSelf={"stretch"}
          gap={"10px"}
        >
          <ErrorModal
            setErrorModalShown={setErrorModalShown}
            isOpen={errorModal}
            closeModal={closeErrorModal}
          />
          <Modal show={showModal} onClose={() => setShowModal(false)} />
          <StripeUpgradeModal
            isOpen={showStripeModal}
            closeModal={UpgradeStripeModal}
          />
          <SaveAnalysisModal
            isOpen={saveAnalysisModal}
            closeModal={closeSaveAnalysisModal}
            closeModalX={closeSaveAnalysisModal}
            remainingSaves={remainingSaves()}
            optiList={passedOptiList}
            spouseOptiList={passedSpouseOptiList}
          />
          {/* Header Box */}
          <Box
            display={"flex"}
            padding={"15px 10px"}
            flexDir={"column"}
            alignItems={"flex-start"}
            gap={"10px"}
            alignSelf={"stretch"}
            borderRadius={"5px"}
            border={"1px solid #00A2D4"}
            bg={"#fff"}
          >
            {/* Header Text */}

            <Box display={"flex"} flexDirection={"column"} gap={"10px"}>
              <HStack>
                <Text
                  color={"#004069"}
                  fontSize={"14px"}
                  fontWeight={"600"}
                  fontFamily={"Nunito"}
                  fontStyle={"normal"}
                  lineHeight={"130%"}
                >
                  Strategy:
                </Text>

                <Text
                  color={"#00A2D4"}
                  textAlign={"right"}
                  fontSize={"14px"}
                  fontFamily={"Nunito"}
                  fontStyle={"normal"}
                  fontWeight={"800"}
                  lineHeight={"130%"}
                >
                  {passedOptiList?.optimizationType}
                </Text>
              </HStack>
              <HStack>
                <Text
                  color={"#004069"}
                  fontSize={"14px"}
                  fontWeight={"600"}
                  fontFamily={"Nunito"}
                  fontStyle={"normal"}
                  lineHeight={"130%"}
                >
                  Analysis date:
                </Text>
                <Text
                  color={"#00A2D4"}
                  textAlign={"right"}
                  fontSize={"14px"}
                  fontFamily={"Nunito"}
                  fontStyle={"normal"}
                  fontWeight={"800"}
                  lineHeight={"130%"}
                >
                  {setRunDate()}
                </Text>
              </HStack>
              <HStack>
                <Text
                  color={"#004069"}
                  fontFamily={"Nunito"}
                  fontSize={"14px"}
                  fontStyle={"normal"}
                  fontWeight={"600"}
                  lineHeight={"130%"}
                >
                  {userInfo.FirstName} {userInfo.LastName}
                  {spouseStatus && (
                    <span>
                      , {userInfo.spouseFirstName} {userInfo.spouseLastName}{" "}
                    </span>
                  )}
                </Text>
              </HStack>
            </Box>

            {/* Header Click Options */}
          </Box>

          <DragDropContext>
            <Droppable droppableId="1">
              {(provided) => (
                <Box
                  {...provided.draggableProps}
                  {...provided.dragHandleProps}
                  ref={provided.innerRef}
                  w={"100%"}
                >
                  {dashboardLayout.overallLayout
                    ?.slice(0, 1)
                    ?.map((card, index) => (
                      <DashboardLayout card={card} index={index} />
                    ))}
                  {provided.placeholder}
                </Box>
              )}
            </Droppable>
          </DragDropContext>
        </Box>
      )}
      {!isSmallScreen && (
        <Box
          display={"flex"}
          flexDirection={"column"}
          alignItems={"center"}
          gap={"20px"}
          w={"100%"}
        >
          <ErrorModal
            setErrorModalShown={setErrorModalShown}
            isOpen={errorModal}
            closeModal={closeErrorModal}
          />
          <Modal show={showModal} onClose={() => setShowModal(false)} />
          <StripeUpgradeModal
            isOpen={showStripeModal}
            closeModal={UpgradeStripeModal}
          />
          <SaveAnalysisModal
            isOpen={saveAnalysisModal}
            closeModal={closeSaveAnalysisModal}
            closeModalX={closeSaveAnalysisModal}
            remainingSaves={remainingSaves()}
            optiList={passedOptiList}
            spouseOptiList={passedSpouseOptiList}
          />
          {/* Header Box */}
          <Box
            display={"flex"}
            padding={"20px"}
            justifyContent={"space-between"}
            alignItems={"center"}
            alignSelf={"stretch"}
            borderRadius={"5px"}
            border={"1px solid #00A2D4"}
            bg={"#fff"}
            w={"100%"}
          >
            {/* Header Text */}
            <Box
              display={"flex"}
              flexDirection={"column"}
              gap={"10px"}
              flexGrow={1}
            >
              <HStack>
                <Text
                  color={"#004069"}
                  fontSize={"20px"}
                  fontWeight={"600"}
                  fontFamily={"Nunito"}
                  fontStyle={"normal"}
                  lineHeight={"130%"}
                >
                  Strategy:
                </Text>

                <Text
                  color={"#00A2D4"}
                  textAlign={"right"}
                  fontSize={"20px"}
                  fontFamily={"Nunito"}
                  fontStyle={"normal"}
                  fontWeight={"800"}
                  lineHeight={"130%"}
                >
                  {passedOptiList?.optimizationType}
                </Text>
              </HStack>
              <HStack>
                <Text
                  color={"#004069"}
                  fontSize={"20px"}
                  fontWeight={"600"}
                  fontFamily={"Nunito"}
                  fontStyle={"normal"}
                  lineHeight={"130%"}
                >
                  Analysis date:
                </Text>
                <Text
                  color={"#00A2D4"}
                  textAlign={"right"}
                  fontSize={"20px"}
                  fontFamily={"Nunito"}
                  fontStyle={"normal"}
                  fontWeight={"800"}
                  lineHeight={"130%"}
                >
                  {setRunDate()}
                </Text>
              </HStack>
              <Box
                display={"flex"}
                gap={"5px"}
                alignItems={isLargerThan1200 ? "flex-start" : "center"}
                justifyContent={"flex-start"}
                flexDirection={isLargerThan1200 ? "column" : "row"}
              >
                <Text
                  color={"#004069"}
                  fontFamily={"Nunito"}
                  fontSize={"20px"}
                  fontStyle={"normal"}
                  fontWeight={"600"}
                  lineHeight={"130%"}
                >
                  {userInfo.FirstName} {userInfo.LastName}
                  {spouseStatus && (
                    <span>
                      , {userInfo.spouseFirstName} {userInfo.spouseLastName}{" "}
                    </span>
                  )}
                </Text>
                {spouseStatus && (
                  <DropDownButton
                    placeholder={"Combined"}
                    value={spousePage}
                    dropDownItems={[
                      { key: "joint-portfolio", value: "Combined" },
                      {
                        key: "spouse-one-portfolio",
                        value: `${userInfo.FirstName} ${userInfo.LastName}`,
                      },
                      {
                        key: "spouse-two-portfolio",
                        value: `${userInfo.spouseFirstName} ${userInfo.spouseLastName}`,
                      },
                    ]}
                    onChange={(key) => {
                      spouseDataChange(key);
                      setSpousePage(key);
                    }}
                    customWidth={"40%"}
                    MenuCustomWidth={isLargerThan1200 ? "100%" : "40%"}
                  />
                )}
              </Box>
            </Box>

            {/* Header Click Options */}
            {!isLargerThan1200 && (
              <Box
                display={"flex"}
                flexDirection={"column"}
                alignItems={"flex-start"}
              >
                <Box>
                  {stripePlan?.items?.data?.[0]?.price?.lookup_key !=
                    "OPTIML_ESSENTIALS" &&
                    stripePlan?.items?.data?.[0]?.price?.lookup_key !=
                      "OPTIML_ESSENTIALS_M" &&
                    stripePlan && (
                      <Button
                        w={"296px"}
                        h={"50px"}
                        flexShrink={0}
                        borderRadius={"5px"}
                        border={"1px solid #00A2D4"}
                        padding={"10px"}
                        justifyContent={"space-between"}
                        bg={"#fff"}
                        onClick={() => {
                          // Only call onSubmitAdd if remainingSaves is greater than 0
                          if (remainingSaves() > 0) {
                            openSaveAnalysis();
                          }
                        }}
                      >
                        <Icon
                          as={AnalysisArchiveIcon}
                          svgwidth={"20px"}
                          svgheight={"20px"}
                        />
                        <Box
                          display={"flex"}
                          w={"204px"}
                          h={"22px"}
                          flexDirection={"column"}
                          justifyContent={"center"}
                          flexShrink={0}
                        >
                          <Text
                            color={"#004069"}
                            fontSize={"14px"}
                            fontFamily={"Nunito"}
                            fontStyle={"normal"}
                            fontWeight={"600"}
                            lineHeight={"normal"}
                            letterSpacing={"0.14px"}
                          >
                            Save analysis to my archive
                          </Text>
                        </Box>
                        |
                        <Box
                          display={"flex"}
                          w={"22px"}
                          h={"22px"}
                          flexDirection={"column"}
                          justifyContent={"center"}
                          flexShrink={0}
                        >
                          <Text
                            color={"#7AC381"}
                            fontSize={"14px"}
                            fontFamily={"Nunito"}
                            fontStyle={"normal"}
                            fontWeight={"600"}
                            lineHeight={"normal"}
                            letterSpacing={"0.14px"}
                          >
                            {remainingSaves()}
                          </Text>
                        </Box>
                      </Button>
                    )}

                  {(stripePlan?.items?.data?.[0]?.price?.lookup_key ===
                    "OPTIML_ESSENTIALS" ||
                    stripePlan?.items?.data?.[0]?.price?.lookup_key ===
                      "OPTIML_ESSENTIALS_M" ||
                    !stripePlan) && (
                    <div
                      className="dashboard-download-btns save-analysis-btn"
                      onClick={() => {
                        stripePlan ? UpgradeStripeModal() : null;
                      }}
                      style={{
                        cursor:
                          remainingSaves() > 0 ? "pointer" : "not-allowed", // Changes cursor based on remainingSaves
                        opacity: remainingSaves() > 0 ? 1 : 0.5, // Changes opacity based on remainingSaves
                      }}
                    >
                      <Icon
                        as={AnalysisArchiveIcon}
                        svgwidth={"20px"}
                        svgheight={"20px"}
                      />
                      <span style={{ color: "#0F9CD6" }}>
                        Save analysis to my archive
                      </span>{" "}
                      <span style={{ color: "#D9D9D9" }}>|</span>{" "}
                      <strong>{remainingSaves()}</strong>
                      {stripePlan && (
                        <div
                          className="coming-soon-overlay"
                          style={{ borderRadius: "0" }}
                        >
                          <div className="coming-soon-text">UPGRADE PLAN</div>
                        </div>
                      )}
                      {!stripePlan && (
                        <div
                          className="coming-soon-overlay"
                          style={{ borderRadius: "0" }}
                        >
                          <div className="coming-soon-text">Coming Soon</div>
                        </div>
                      )}
                    </div>
                  )}

                  {stripePlan?.items?.data?.[0]?.price?.lookup_key !==
                    "OPTIML_ESSENTIALS" &&
                    stripePlan?.items?.data?.[0]?.price?.lookup_key !==
                      "OPTIML_ESSENTIALS_M" &&
                    stripePlan && (
                      <div className="pencil-csv-btns-container">
                        {/* <span
                          className="dashboard-download-btns"
                          onClick={() => setIsDragDisabled(!isDragDisabled)}
                          style={{ color: "#51459E" }}
                        >
                          <Icon as={ManageDashboardPenIcon} color={"#51459E"} />
                          {isDragDisabled
                            ? "Manage dashboard"
                            : "Save dashboard"}
                        </span> */}
                        <span
                          onClick={() => exportToExcel(optiList)}
                          className="dashboard-download-btns"
                          style={{ color: "#00A2D4", width: "30%" }}
                        >
                          <Icon as={CSVDownloadIcon} />
                          CSV
                        </span>
                      </div>
                    )}
                  {(stripePlan?.items?.data?.[0]?.price?.lookup_key ===
                    "OPTIML_ESSENTIALS" ||
                    stripePlan?.items?.data?.[0]?.price?.lookup_key ===
                      "OPTIML_ESSENTIALS_M" ||
                    !stripePlan) && (
                    <div
                      onClick={() => {
                        stripePlan ? UpgradeStripeModal() : null;
                      }}
                      className="pencil-csv-btns-container"
                    >
                      {/* <span
                        className="dashboard-download-btns"
                        style={{ backgroundColor: "rgba(0, 123, 255, 0.5)" }}
                      >
                        <img src={pencilIcon} alt="" />
                        {isDragDisabled ? "Manage dashboard" : "Save dashboard"}
                      </span> */}
                      <span
                        className="dashboard-download-btns"
                        style={{ backgroundColor: "rgba(0, 123, 255, 0.5)" }}
                      >
                        CSV
                        <img src={CSVButton} width="14px" alt="" />
                      </span>
                    </div>
                  )}
                </Box>
              </Box>
            )}
          </Box>

          <Box w={"100%"}>
            {dashboardLayout.overallLayout?.slice(0, 1)?.map((card, index) => (
              <>
                {userPopupNotification?.DashboardVideo === 1 &&
                  (passedOptiList?.optimizationType !== "Error Value" ? (
                    <VideoModal
                      isOpen={isVideoModalOpen}
                      closeModal={closeVideoModal}
                      videoName={"Dashboard.mp4"}
                      title="Dashboard Page"
                    />
                  ) : (
                    showVideo && (
                      <VideoModal
                        isOpen={isVideoModalOpen}
                        closeModal={closeVideoModal}
                        videoName={"Dashboard.mp4"}
                        title="Dashboard Page"
                      />
                    )
                  ))}
                <DashboardLayout card={card} index={index} />
              </>
            ))}
          </Box>
        </Box>
      )}
    </Box>
  );
}

export default EndOfLifePageSpouse;
