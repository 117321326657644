import {
  Box,
  HStack,
  Icon,
  Image,
  Text,
  VStack,
  useColorModeValue,
  useMediaQuery,
} from "@chakra-ui/react";
import { round } from "lodash";
import React, { useContext, useState } from "react";
import OltreLogo from "../../img/Optiml_logo.svg";
import { useStore } from "../../store";
import { EndOfAnalysisBalanceIcon } from "../CustomIcons";
import { SpouseContext } from "../SpouseContext";
import { StripeContext } from "../StripeContext";
import StripeUpgradeModal from "../StripeUpgradeModal";

function safeAdd(...values) {
  return values?.reduce((sum, val) => sum + (Number(val) || 0), 0);
}

function Popup({ onClose }) {
  return (
    <div className="popup-oas mobile-hidden">
      <p className="oas-popup-text">
        Please note, although you should apply for your OAS at age 70, there is
        a high likelihood that due to your income level and the claw back nature
        of OAS, you will likely have reduced or no funds from this program.
      </p>
    </div>
  );
}

function OSFAPopup({ onClose, assetList, currencyFormat }) {
  const savingsPercentage = round(
    (safeAdd(assetList?.optimizedEOLAmountAfterTax, -assetList?.ruleOfThumb) /
      assetList?.ruleOfThumb) *
      100
  );

  const bgColor = useColorModeValue("gray.100", "gray.800");
  const textColor = useColorModeValue("#004069", "white");
  const headerColor = useColorModeValue("#0F9CD6", "#0F9CD6");

  return (
    <Box
      position="absolute"
      display="flex"
      flexDirection="column"
      p={4}
      mt={4}
      ml={-4}
      bg={bgColor}
      borderRadius="md"
      boxShadow="lg"
      zIndex="1000"
      minWidth="27rem"
      maxWidth="600px"
    >
      <Text
        fontSize="xl"
        fontWeight="bold"
        color={headerColor}
        width="100%"
        textAlign="center"
        mb={4}
      >
        End of analysis after tax value
      </Text>

      <HStack justifyContent="space-between" width="100%">
        <VStack align="start">
          <Text fontWeight="500">Traditional plan:</Text>
          <Image src={OltreLogo} alt="Oltre Logo" width="6rem" />
        </VStack>

        <VStack align="flex-start" gap={8} spacing={4}>
          <Text textAlign="right">
            {assetList?.ruleOfThumb
              ? currencyFormat(assetList?.ruleOfThumb)
              : "Not Possible"}
          </Text>
          <Text textAlign="right">
            {currencyFormat(assetList?.optimizedEOLAmountAfterTax)}
          </Text>
        </VStack>

        <Box
          position="relative"
          width="100px"
          height="100px"
          border="3px solid #0F9CD6"
          color="#0F9CD6"
          borderRadius="50%"
          display="flex"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
          textAlign="center"
        >
          <Text fontSize="2rem">
            {savingsPercentage < 500 ? savingsPercentage : "100"}%
          </Text>
          <Text fontSize="1rem" color="#004069" fontWeight="bold">
            Savings
          </Text>
        </Box>
      </HStack>

      <Text
        className="additional-header"
        fontSize="sm"
        marginTop="1rem"
        color={textColor}
      >
        Investment accounts only. Other assets not included.
      </Text>
    </Box>
  );
}

function EstateBalance(props) {
  const [net, setNet] = useState("No");
  const {
    isSmallScreen,
    optiList,
    currencyFormat,
    spouseOptiList,
    spousePrefix,
    spousePage,
    assetList,
    isInsights,
  } = props;

  return (
    <>
      {isSmallScreen && (
        <Box display={"flex"} flexDirection={"row"} padding={0}>
          <Icon
            as={EndOfAnalysisBalanceIcon}
            svgwidth={"40px"}
            svgheight={"50px"}
          />
          <VStack alignItems={"flex-start"} justifyContent={"center"} gap={0}>
            {!isInsights && (
              <Text
                color={"#004069"}
                fontFamily={"Nunito"}
                fontSize={"16px"}
                fontStyle={"normal"}
                fontWeight={"800"}
                lineHeight={"normal"}
                textAlign={"center"}
              >
                {net === "No" && spousePage === "joint-portfolio" && (
                  <div>
                    {currencyFormat(
                      safeAdd(
                        optiList?.optimizedEOLAmount,
                        optiList?.PrimaryResidence?.Value?.[
                          optiList?.PrimaryResidence?.Value?.length - 1
                        ],
                        optiList?.businessYearlyValue?.[
                          optiList?.businessYearlyValue?.length - 1
                        ],
                        optiList?.[`${spousePrefix}_pension`]?.Value?.[
                          optiList?.[`${spousePrefix}_pension`]?.Value?.length -
                            1
                        ],
                        spouseOptiList?.sps2_pension?.Value?.[
                          spouseOptiList?.sps2_pension?.Value?.length - 1
                        ],
                        optiList?.IncomePropertyYearlyValue?.[
                          optiList?.IncomePropertyYearlyValue?.length - 1
                        ],
                        optiList?.RecreationPropertyYearlyValue?.[
                          optiList?.RecreationPropertyYearlyValue?.length - 1
                        ],
                        optiList?.LandYearlyValue?.[
                          optiList?.LandYearlyValue?.length - 1
                        ],
                        optiList?.lifeInsuranceYearlyValue?.[
                          optiList?.lifeInsuranceYearlyValue?.length - 1
                        ],
                        optiList?.otherAssetsYearlyValue?.[
                          optiList?.otherAssetsYearlyValue?.length - 1
                        ]
                      )
                    )}
                  </div>
                )}
                {net === "Yes" && spousePage === "joint-portfolio" && (
                  <div>
                    {currencyFormat(
                      safeAdd(
                        optiList?.optimizedEOLAmount,
                        optiList?.PrimaryResidence?.Value?.[
                          optiList?.PrimaryResidence?.Value?.length - 1
                        ],
                        optiList?.businessYearlyValue?.[
                          optiList?.businessYearlyValue?.length - 1
                        ],
                        optiList?.[`${spousePrefix}_pension`]?.Value?.[
                          optiList?.[`${spousePrefix}_pension`]?.Value?.length -
                            1
                        ],
                        spouseOptiList?.sps2_pension?.Value?.[
                          spouseOptiList?.sps2_pension?.Value?.length - 1
                        ],
                        optiList?.IncomePropertyYearlyValue?.[
                          optiList?.IncomePropertyYearlyValue?.length - 1
                        ],
                        optiList?.RecreationPropertyYearlyValue?.[
                          optiList?.RecreationPropertyYearlyValue?.length - 1
                        ],
                        optiList?.LandYearlyValue?.[
                          optiList?.LandYearlyValue?.length - 1
                        ],
                        optiList?.lifeInsuranceYearlyValue?.[
                          optiList?.lifeInsuranceYearlyValue?.length - 1
                        ],
                        optiList?.otherAssetsYearlyValue?.[
                          optiList?.otherAssetsYearlyValue?.length - 1
                        ]
                      ) -
                        safeAdd(
                          optiList?.totalDebt?.[
                            optiList?.totalDebt?.length - 1
                          ],
                          optiList?.IncomePropertyYearlyBalance?.[
                            optiList?.IncomePropertyYearlyBalance?.length - 1
                          ],
                          optiList?.RecreationPropertyYearlyBalance?.[
                            optiList?.RecreationPropertyYearlyBalance?.length -
                              1
                          ],
                          optiList?.LandYearlyValue?.[
                            optiList?.LandYearlyValue?.length - 1
                          ],
                          optiList?.PrimaryResidence?.Balance?.[
                            optiList?.PrimaryResidence?.Balance?.length - 1
                          ]
                        )
                    )}
                  </div>
                )}
                {net === "No" &&
                  (spousePage === "spouse-two-portfolio" ||
                    spousePage === "spouse-one-portfolio") && (
                    <div>
                      {currencyFormat(
                        safeAdd(
                          optiList?.[`${spousePrefix}_tfsaFunds`]?.[
                            optiList?.[`${spousePrefix}_tfsaFunds`].length - 1
                          ],
                          optiList?.[`${spousePrefix}_registeredFunds`]?.[
                            optiList?.[`${spousePrefix}_registeredFunds`]
                              .length - 1
                          ],
                          optiList?.[`${spousePrefix}_liraFunds`]?.[
                            optiList?.[`${spousePrefix}_liraFunds`].length - 1
                          ],
                          optiList?.[`${spousePrefix}_nonregisteredFunds`]?.[
                            optiList?.[`${spousePrefix}_nonregisteredFunds`]
                              .length - 1
                          ],
                          optiList?.[`${spousePrefix}_pension`]?.Value?.[
                            optiList?.[`${spousePrefix}_pension`]?.Value
                              ?.length - 1
                          ],
                          assetList?.PrimaryResidence?.Value?.[
                            assetList?.PrimaryResidence?.Value?.length - 1
                          ] / 2,
                          assetList?.businessYearlyValue?.[
                            assetList?.businessYearlyValue?.length - 1
                          ] / 2,
                          assetList?.IncomePropertyYearlyValue?.[
                            assetList?.IncomePropertyYearlyValue?.length - 1
                          ] / 2,
                          assetList?.RecreationPropertyYearlyValue?.[
                            assetList?.RecreationPropertyYearlyValue?.length - 1
                          ] / 2,
                          assetList?.LandYearlyValue?.[
                            assetList?.LandYearlyValue?.length - 1
                          ] / 2,
                          assetList?.lifeInsuranceYearlyValue?.[
                            assetList?.lifeInsuranceYearlyValue?.length - 1
                          ],
                          assetList?.otherAssetsYearlyValue?.[
                            assetList?.otherAssetsYearlyValue?.length - 1
                          ] / 2
                        )
                      )}
                    </div>
                  )}
                {net === "Yes" &&
                  (spousePage === "spouse-two-portfolio" ||
                    spousePage === "spouse-one-portfolio") && (
                    <div>
                      {currencyFormat(
                        safeAdd(
                          optiList?.[`${spousePrefix}_tfsaFunds`]?.[
                            optiList?.[`${spousePrefix}_tfsaFunds`].length - 1
                          ],
                          optiList?.[`${spousePrefix}_registeredFunds`]?.[
                            optiList?.[`${spousePrefix}_registeredFunds`]
                              .length - 1
                          ],
                          optiList?.[`${spousePrefix}_liraFunds`]?.[
                            optiList?.[`${spousePrefix}_liraFunds`].length - 1
                          ],
                          optiList?.[`${spousePrefix}_nonregisteredFunds`]?.[
                            optiList?.[`${spousePrefix}_nonregisteredFunds`]
                              .length - 1
                          ],
                          optiList?.[`${spousePrefix}_pension`]?.Value?.[
                            optiList?.[`${spousePrefix}_pension`]?.Value
                              ?.length - 1
                          ],
                          assetList?.PrimaryResidence?.Value?.[
                            assetList?.PrimaryResidence?.Value?.length - 1
                          ] / 2,
                          assetList?.businessYearlyValue?.[
                            assetList?.businessYearlyValue?.length - 1
                          ] / 2,
                          assetList?.IncomePropertyYearlyValue?.[
                            assetList?.IncomePropertyYearlyValue?.length - 1
                          ] / 2,
                          assetList?.RecreationPropertyYearlyValue?.[
                            assetList?.RecreationPropertyYearlyValue?.length - 1
                          ] / 2,
                          assetList?.LandYearlyValue?.[
                            assetList?.LandYearlyValue?.length - 1
                          ] / 2,
                          assetList?.lifeInsuranceYearlyValue?.[
                            assetList?.lifeInsuranceYearlyValue?.length - 1
                          ],
                          assetList?.otherAssetsYearlyValue?.[
                            assetList?.otherAssetsYearlyValue?.length - 1
                          ] / 2
                        ) -
                          -safeAdd(
                            assetList?.totalDebt?.[
                              assetList?.totalDebt?.length - 1
                            ] / 2,
                            assetList?.IncomePropertyYearlyBalance?.[
                              assetList?.IncomePropertyYearlyBalance?.length - 1
                            ] / 2,
                            assetList?.RecreationPropertyYearlyBalance?.[
                              assetList?.RecreationPropertyYearlyBalance
                                ?.length - 1
                            ] / 2,
                            assetList?.LandYearlyValue?.[
                              assetList?.LandYearlyValue?.length - 1
                            ] / 2,
                            assetList?.PrimaryResidence?.Balance?.[
                              assetList?.PrimaryResidence?.Balance?.length - 1
                            ] / 2
                          )
                      )}
                    </div>
                  )}
              </Text>
            )}
            {isInsights && (
              <Text
                color={"#004069"}
                fontFamily={"Nunito"}
                fontSize={"16px"}
                fontStyle={"normal"}
                fontWeight={"800"}
                lineHeight={"normal"}
                textAlign={"center"}
              >
                {currencyFormat(assetList?.optimizedEOLAmountAfterTax)}
              </Text>
            )}
            {net === "No" && (
              <Text
                color={"#030229"}
                fontFamily={"Nunito"}
                fontSize={"8px"}
                fontStyle={"normal"}
                fontWeight={"400"}
                lineHeight={"normal"}
              >
                {isInsights
                  ? "Optiml™ After Tax Estate Balance"
                  : " Final Portfolio Balance"}
              </Text>
            )}
          </VStack>
        </Box>
      )}
      {!isSmallScreen && (
        <Box display={"flex"} flexDirection={"row"} gap={"20px"} padding={0}>
          <Icon
            as={EndOfAnalysisBalanceIcon}
            svgwidth={"50px"}
            svgheight={"54px"}
          />
          <VStack alignItems={"flex-end"}>
            {!isInsights && (
              <Text
                color={"#004069"}
                fontFamily={"Nunito"}
                fontSize={"22px"}
                fontStyle={"normal"}
                fontWeight={"800"}
                lineHeight={"normal"}
                alignSelf={"flex-end"}
              >
                {net === "No" && spousePage === "joint-portfolio" && (
                  <div>
                    {currencyFormat(
                      safeAdd(
                        optiList?.optimizedEOLAmount,
                        optiList?.PrimaryResidence?.Value?.[
                          optiList?.PrimaryResidence?.Value?.length - 1
                        ],
                        optiList?.businessYearlyValue?.[
                          optiList?.businessYearlyValue?.length - 1
                        ],
                        optiList?.[`${spousePrefix}_pension`]?.Value?.[
                          optiList?.[`${spousePrefix}_pension`]?.Value?.length -
                            1
                        ],
                        spouseOptiList?.sps2_pension?.Value?.[
                          spouseOptiList?.sps2_pension?.Value?.length - 1
                        ],
                        optiList?.IncomePropertyYearlyValue?.[
                          optiList?.IncomePropertyYearlyValue?.length - 1
                        ],
                        optiList?.RecreationPropertyYearlyValue?.[
                          optiList?.RecreationPropertyYearlyValue?.length - 1
                        ],
                        optiList?.LandYearlyValue?.[
                          optiList?.LandYearlyValue?.length - 1
                        ],
                        optiList?.lifeInsuranceYearlyValue?.[
                          optiList?.lifeInsuranceYearlyValue?.length - 1
                        ],
                        optiList?.otherAssetsYearlyValue?.[
                          optiList?.otherAssetsYearlyValue?.length - 1
                        ]
                      )
                    )}
                  </div>
                )}
                {net === "Yes" && spousePage === "joint-portfolio" && (
                  <div>
                    {currencyFormat(
                      safeAdd(
                        optiList?.optimizedEOLAmount,
                        optiList?.PrimaryResidence?.Value?.[
                          optiList?.PrimaryResidence?.Value?.length - 1
                        ],
                        optiList?.businessYearlyValue?.[
                          optiList?.businessYearlyValue?.length - 1
                        ],
                        optiList?.[`${spousePrefix}_pension`]?.Value?.[
                          optiList?.[`${spousePrefix}_pension`]?.Value?.length -
                            1
                        ],
                        spouseOptiList?.sps2_pension?.Value?.[
                          spouseOptiList?.sps2_pension?.Value?.length - 1
                        ],
                        optiList?.IncomePropertyYearlyValue?.[
                          optiList?.IncomePropertyYearlyValue?.length - 1
                        ],
                        optiList?.RecreationPropertyYearlyValue?.[
                          optiList?.RecreationPropertyYearlyValue?.length - 1
                        ],
                        optiList?.LandYearlyValue?.[
                          optiList?.LandYearlyValue?.length - 1
                        ],
                        optiList?.lifeInsuranceYearlyValue?.[
                          optiList?.lifeInsuranceYearlyValue?.length - 1
                        ],
                        optiList?.otherAssetsYearlyValue?.[
                          optiList?.otherAssetsYearlyValue?.length - 1
                        ]
                      ) -
                        safeAdd(
                          optiList?.totalDebt?.[
                            optiList?.totalDebt?.length - 1
                          ],
                          optiList?.IncomePropertyYearlyBalance?.[
                            optiList?.IncomePropertyYearlyBalance?.length - 1
                          ],
                          optiList?.RecreationPropertyYearlyBalance?.[
                            optiList?.RecreationPropertyYearlyBalance?.length -
                              1
                          ],
                          optiList?.LandYearlyValue?.[
                            optiList?.LandYearlyValue?.length - 1
                          ],
                          optiList?.PrimaryResidence?.Balance?.[
                            optiList?.PrimaryResidence?.Balance?.length - 1
                          ]
                        )
                    )}
                  </div>
                )}
                {net === "No" &&
                  (spousePage === "spouse-two-portfolio" ||
                    spousePage === "spouse-one-portfolio") && (
                    <div>
                      {currencyFormat(
                        safeAdd(
                          optiList?.[`${spousePrefix}_tfsaFunds`]?.[
                            optiList?.[`${spousePrefix}_tfsaFunds`].length - 1
                          ],
                          optiList?.[`${spousePrefix}_registeredFunds`]?.[
                            optiList?.[`${spousePrefix}_registeredFunds`]
                              .length - 1
                          ],
                          optiList?.[`${spousePrefix}_liraFunds`]?.[
                            optiList?.[`${spousePrefix}_liraFunds`].length - 1
                          ],
                          optiList?.[`${spousePrefix}_nonregisteredFunds`]?.[
                            optiList?.[`${spousePrefix}_nonregisteredFunds`]
                              .length - 1
                          ],
                          optiList?.[`${spousePrefix}_pension`]?.Value?.[
                            optiList?.[`${spousePrefix}_pension`]?.Value
                              ?.length - 1
                          ],
                          assetList?.PrimaryResidence?.Value?.[
                            assetList?.PrimaryResidence?.Value?.length - 1
                          ] / 2,
                          assetList?.businessYearlyValue?.[
                            assetList?.businessYearlyValue?.length - 1
                          ] / 2,
                          assetList?.IncomePropertyYearlyValue?.[
                            assetList?.IncomePropertyYearlyValue?.length - 1
                          ] / 2,
                          assetList?.RecreationPropertyYearlyValue?.[
                            assetList?.RecreationPropertyYearlyValue?.length - 1
                          ] / 2,
                          assetList?.LandYearlyValue?.[
                            assetList?.LandYearlyValue?.length - 1
                          ] / 2,
                          assetList?.lifeInsuranceYearlyValue?.[
                            assetList?.lifeInsuranceYearlyValue?.length - 1
                          ],
                          assetList?.otherAssetsYearlyValue?.[
                            assetList?.otherAssetsYearlyValue?.length - 1
                          ] / 2
                        )
                      )}
                    </div>
                  )}
                {net === "Yes" &&
                  (spousePage === "spouse-two-portfolio" ||
                    spousePage === "spouse-one-portfolio") && (
                    <div>
                      {currencyFormat(
                        safeAdd(
                          optiList?.[`${spousePrefix}_tfsaFunds`]?.[
                            optiList?.[`${spousePrefix}_tfsaFunds`].length - 1
                          ],
                          optiList?.[`${spousePrefix}_registeredFunds`]?.[
                            optiList?.[`${spousePrefix}_registeredFunds`]
                              .length - 1
                          ],
                          optiList?.[`${spousePrefix}_liraFunds`]?.[
                            optiList?.[`${spousePrefix}_liraFunds`].length - 1
                          ],
                          optiList?.[`${spousePrefix}_nonregisteredFunds`]?.[
                            optiList?.[`${spousePrefix}_nonregisteredFunds`]
                              .length - 1
                          ],
                          optiList?.[`${spousePrefix}_pension`]?.Value?.[
                            optiList?.[`${spousePrefix}_pension`]?.Value
                              ?.length - 1
                          ],
                          assetList?.PrimaryResidence?.Value?.[
                            assetList?.PrimaryResidence?.Value?.length - 1
                          ] / 2,
                          assetList?.businessYearlyValue?.[
                            assetList?.businessYearlyValue?.length - 1
                          ] / 2,
                          assetList?.IncomePropertyYearlyValue?.[
                            assetList?.IncomePropertyYearlyValue?.length - 1
                          ] / 2,
                          assetList?.RecreationPropertyYearlyValue?.[
                            assetList?.RecreationPropertyYearlyValue?.length - 1
                          ] / 2,
                          assetList?.LandYearlyValue?.[
                            assetList?.LandYearlyValue?.length - 1
                          ] / 2,
                          assetList?.lifeInsuranceYearlyValue?.[
                            assetList?.lifeInsuranceYearlyValue?.length - 1
                          ],
                          assetList?.otherAssetsYearlyValue?.[
                            assetList?.otherAssetsYearlyValue?.length - 1
                          ] / 2
                        ) -
                          -safeAdd(
                            assetList?.totalDebt?.[
                              assetList?.totalDebt?.length - 1
                            ] / 2,
                            assetList?.IncomePropertyYearlyBalance?.[
                              assetList?.IncomePropertyYearlyBalance?.length - 1
                            ] / 2,
                            assetList?.RecreationPropertyYearlyBalance?.[
                              assetList?.RecreationPropertyYearlyBalance
                                ?.length - 1
                            ] / 2,
                            assetList?.LandYearlyValue?.[
                              assetList?.LandYearlyValue?.length - 1
                            ] / 2,
                            assetList?.PrimaryResidence?.Balance?.[
                              assetList?.PrimaryResidence?.Balance?.length - 1
                            ] / 2
                          )
                      )}
                    </div>
                  )}
              </Text>
            )}
            {isInsights && (
              <Text
                color={"#004069"}
                fontFamily={"Nunito"}
                fontSize={"22px"}
                fontStyle={"normal"}
                fontWeight={"800"}
                lineHeight={"normal"}
                alignSelf={isInsights ? "flex-start" : "flex-end"}
              >
                {currencyFormat(assetList?.optimizedEOLAmountAfterTax)}
              </Text>
            )}
            {net === "No" && (
              <Text
                color={"#030229"}
                fontFamily={"Nunito"}
                fontSize={"14px"}
                fontStyle={"normal"}
                fontWeight={"400"}
                lineHeight={"normal"}
                maxW={isInsights && "155px"}
              >
                {isInsights
                  ? "Optiml™ After Tax Estate Balance"
                  : " Final Portfolio Balance"}
              </Text>
            )}
            {net === "Yes" && (
              <Text
                color={"#030229"}
                fontFamily={"Nunito"}
                fontSize={"14px"}
                fontStyle={"normal"}
                fontWeight={"400"}
                lineHeight={"normal"}
              >
                {isInsights
                  ? "Optiml™ After Tax Estate Balance"
                  : "Final Net Worth"}
              </Text>
            )}
            {safeAdd(
              assetList?.totalDebt?.[assetList?.totalDebt?.length - 1],
              assetList?.IncomePropertyYearlyBalance?.[
                assetList?.IncomePropertyYearlyBalance?.length - 1
              ],
              assetList?.RecreationPropertyYearlyBalance?.[
                assetList?.RecreationPropertyYearlyBalance?.length - 1
              ],
              assetList?.LandYearlyValue?.[
                assetList?.LandYearlyValue?.length - 1
              ],
              assetList?.PrimaryResidence?.Balance?.[
                assetList?.PrimaryResidence?.Balance?.length - 1
              ]
            ) > 1 && (
              <div>
                <select
                  className="select-container"
                  value={net}
                  onChange={(e) => {
                    setNet(e.target.value);
                  }}
                >
                  <option value="No">Portfolio Balance</option>
                  <option value="Yes">Net Worth</option>
                </select>
              </div>
            )}
          </VStack>
        </Box>
      )}
    </>
  );
}

function OSFA(props) {
  const [net, setNet] = useState("No");
  const {
    isSmallScreen,
    optiList,
    currencyFormat,
    spouseOptiList,
    spousePrefix,
    spousePage,
    assetList,
  } = props;

  return (
    <>
      {isSmallScreen && (
        <Box display={"flex"} flexDirection={"row"} padding={0}>
          <Icon
            as={EndOfAnalysisBalanceIcon}
            svgwidth={"40px"}
            svgheight={"50px"}
          />
          <VStack alignItems={"flex-start"} justifyContent={"center"} gap={0}>
            <Text
              color={"#004069"}
              fontFamily={"Nunito"}
              fontSize={"16px"}
              fontStyle={"normal"}
              fontWeight={"800"}
              lineHeight={"normal"}
              textAlign={"center"}
            >
              {currencyFormat(assetList?.ruleOfThumb)}
            </Text>
            {net === "No" && (
              <Text
                color={"#030229"}
                fontFamily={"Nunito"}
                fontSize={"8px"}
                fontStyle={"normal"}
                fontWeight={"400"}
                lineHeight={"normal"}
              >
                OSFA After Tax Estate Balance
              </Text>
            )}
          </VStack>
        </Box>
      )}
      {!isSmallScreen && (
        <Box display={"flex"} flexDirection={"row"} gap={"20px"} padding={0}>
          <Icon
            as={EndOfAnalysisBalanceIcon}
            svgwidth={"50px"}
            svgheight={"54px"}
          />
          <VStack alignItems={"flex-end"}>
            <Text
              color={"#004069"}
              fontFamily={"Nunito"}
              fontSize={"22px"}
              fontStyle={"normal"}
              fontWeight={"800"}
              lineHeight={"normal"}
              alignSelf={"flex-start"}
            >
              {currencyFormat(assetList?.ruleOfThumb)}
            </Text>
            {net === "No" && (
              <Text
                color={"#030229"}
                fontFamily={"Nunito"}
                fontSize={"14px"}
                fontStyle={"normal"}
                fontWeight={"400"}
                lineHeight={"normal"}
                maxW={"140px"}
              >
                OSFA After Tax Estate Balance
              </Text>
            )}
            {net === "Yes" && (
              <Text
                color={"#030229"}
                fontFamily={"Nunito"}
                fontSize={"14px"}
                fontStyle={"normal"}
                fontWeight={"400"}
                lineHeight={"normal"}
              >
                OSFA After Tax Estate Balance
              </Text>
            )}
            {safeAdd(
              assetList?.totalDebt?.[assetList?.totalDebt?.length - 1],
              assetList?.IncomePropertyYearlyBalance?.[
                assetList?.IncomePropertyYearlyBalance?.length - 1
              ],
              assetList?.RecreationPropertyYearlyBalance?.[
                assetList?.RecreationPropertyYearlyBalance?.length - 1
              ],
              assetList?.LandYearlyValue?.[
                assetList?.LandYearlyValue?.length - 1
              ],
              assetList?.PrimaryResidence?.Balance?.[
                assetList?.PrimaryResidence?.Balance?.length - 1
              ]
            ) > 1 && (
              <div>
                <select
                  className="select-container"
                  value={net}
                  onChange={(e) => {
                    setNet(e.target.value);
                  }}
                >
                  <option value="No">Portfolio Balance</option>
                  <option value="Yes">Net Worth</option>
                </select>
              </div>
            )}
          </VStack>
        </Box>
      )}
    </>
  );
}

function CompassScore(props) {
  const {
    isSmallScreen,
    optiList,
    spousePrefix,
    currencyFormat,
    assetList,
    showpop,
  } = props;

  const [showPopup, setShowPopup] = useState(false);

  const handleHover = () => {
    setShowPopup(true);
  };

  const handleClose = () => {
    setShowPopup(false);
  };

  const TotalExtra = assetList?.extraSpending?.reduce(
    (accumulator, currentValue) => {
      return safeAdd(accumulator, currentValue);
    }
  );

  const totalExpenses =
    assetList?.expenses?.reduce((acc, expense) => acc + expense, 0) || 0;
  const percentage = (TotalExtra / totalExpenses) * 100;
  const roundedPercentage = Math.round(percentage);

  return (
    <>
      {isSmallScreen && (
        <>
          {showPopup && (
            <div
              style={{ cursor: "pointer" }}
              onMouseEnter={handleHover}
              onMouseLeave={handleClose}
            >
              <OSFAPopup
                onClose={handleClose}
                assetList={assetList}
                currencyFormat={currencyFormat}
              />
            </div>
          )}
          {assetList?.optimizationType === "Max Value" && (
            <Box display={"flex"} flexDirection={"row"} gap={"10px"}>
              <Box
                display={"flex"}
                w={"30px"}
                height={"30px"}
                flexDirection={"column"}
                justifyContent={"center"}
                alignItems={"center"}
                borderRadius={"500px"}
                bg={"#FFF4F0"}
              >
                <Box
                  display={"flex"}
                  w={"38px"}
                  h={"23px"}
                  flexDirection={"column"}
                  justifyContent={"center"}
                  alignItems={"center"}
                  flexShrink={0}
                >
                  {assetList?.optimizationType === "Max Value" && (
                    <Text
                      color={"#FF8F6B"}
                      fontFamily={"Nunito"}
                      fontSize={"14px"}
                      fontStyle={"normal"}
                      fontWeight={"800"}
                      lineHeight={"normal"}
                    >
                      {round(
                        ((assetList?.optimizedEOLAmountAfterTax -
                          assetList?.ruleOfThumb) /
                          assetList?.ruleOfThumb) *
                          100
                      ) < 500
                        ? round(
                            ((assetList?.optimizedEOLAmountAfterTax -
                              assetList?.ruleOfThumb) /
                              assetList?.ruleOfThumb) *
                              100
                          )
                        : 100}
                      %
                    </Text>
                  )}
                </Box>
              </Box>
              <VStack
                alignItems={"flex-start"}
                justifyContent={"center"}
                gap={0}
              >
                {assetList?.optimizationType === "Max Value" && (
                  <Text
                    color={"#004069"}
                    fontFamily={"Nunito"}
                    fontSize={"16px"}
                    fontStyle={"normal"}
                    fontWeight={"800"}
                    lineHeight={"normal"}
                  >
                    {currencyFormat(
                      safeAdd(
                        assetList?.optimizedEOLAmountAfterTax,
                        -assetList?.ruleOfThumb
                      )
                    )}
                  </Text>
                )}
                <Text
                  color={"#030229"}
                  fontFamily={"Nunito"}
                  fontSize={"8px"}
                  fontStyle={"normal"}
                  fontWeight={"400"}
                  lineHeight={"normal"}
                >
                  After-tax savings
                </Text>
              </VStack>
            </Box>
          )}
          {assetList?.optimizationType != "Max Value" && (
            <Box
              display={"flex"}
              flexDirection={"row"}
              gap={"10px"}
              justifyContent={"center"}
            >
              <Box
                display={"flex"}
                w={"30px"}
                height={"30px"}
                flexDirection={"column"}
                justifyContent={"center"}
                alignItems={"flex-start"}
                borderRadius={"500px"}
                bg={"#FFF4F0"}
              >
                <Box
                  display={"flex"}
                  w={"38px"}
                  h={"23px"}
                  flexDirection={"row"}
                  justifyContent={"center"}
                  flexShrink={0}
                >
                  <Text
                    color={"#FF8F6B"}
                    fontFamily={"Nunito"}
                    fontSize={"14px"}
                    fontStyle={"normal"}
                    fontWeight={"800"}
                    lineHeight={"normal"}
                  >
                    {roundedPercentage}%
                  </Text>
                </Box>
              </Box>
              <VStack
                alignItems={"flex-start"}
                gap={"0px"}
                justifyContent={"center"}
                flex={"1 0 0"}
              >
                <Text
                  color={"#004069"}
                  fontFamily={"Nunito"}
                  fontSize={"16px"}
                  fontStyle={"normal"}
                  fontWeight={"800"}
                  lineHeight={"normal"}
                  textAlign={"center"}
                >
                  {assetList?.optimizationType != "Max Value" && (
                    <Text
                      color={"#004069"}
                      fontFamily={"Nunito"}
                      fontSize={"16px"}
                      fontStyle={"normal"}
                      fontWeight={"800"}
                      lineHeight={"normal"}
                    >
                      {currencyFormat(TotalExtra)}
                      {/* <span style={{ marginLeft: "0.1rem", fontSize: "7px" }}>
                        {currencyFormat(
                          TotalExtra / assetList?.expenses?.length
                        )}
                        /Year
                      </span> */}
                    </Text>
                  )}
                </Text>
                <Text
                  color={"#030229"}
                  fontFamily={"Nunito"}
                  fontSize={"8px"}
                  fontStyle={"normal"}
                  fontWeight={"400"}
                  lineHeight={"normal"}
                >
                  Life Time Extra Spending
                </Text>
              </VStack>
            </Box>
          )}
        </>
      )}
      {!isSmallScreen && (
        <>
          {/* <div className="compass-score-overlay">
        Coming Soon
      </div> */}

          {!showpop && showPopup && (
            <div
              style={{ cursor: "pointer" }}
              onMouseEnter={handleHover}
              onMouseLeave={handleClose}
            >
              <OSFAPopup
                onClose={handleClose}
                assetList={assetList}
                currencyFormat={currencyFormat}
              />
            </div>
          )}

          {assetList?.optimizationType === "Max Value" && (
            <Box
              display={"flex"}
              flexDirection={"row"}
              gap={"20px"}
              onMouseEnter={handleHover}
              onMouseLeave={handleClose}
            >
              <Box
                display={"flex"}
                w={"50px"}
                height={"50px"}
                flexDirection={"column"}
                justifyContent={"center"}
                alignItems={"center"}
                borderRadius={"500px"}
                bg={"#FFF4F0"}
              >
                <Box
                  display={"flex"}
                  w={"70px"}
                  flexDirection={"row"}
                  justifyContent={"center"}
                  flexShrink={0}
                >
                  {assetList?.optimizationType === "Max Value" && (
                    <div style={{ cursor: "pointer" }}>
                      <Text
                        color={"#FF8F6B"}
                        fontFamily={"Nunito"}
                        fontSize={"18px"}
                        fontStyle={"normal"}
                        fontWeight={"800"}
                        lineHeight={"normal"}
                      >
                        {round(
                          ((assetList?.optimizedEOLAmountAfterTax -
                            assetList?.ruleOfThumb) /
                            assetList?.ruleOfThumb) *
                            100
                        ) < 500
                          ? round(
                              ((assetList?.optimizedEOLAmountAfterTax -
                                assetList?.ruleOfThumb) /
                                assetList?.ruleOfThumb) *
                                100
                            )
                          : 100}
                        %
                      </Text>
                    </div>
                  )}
                </Box>
              </Box>
              <VStack alignItems={"flex-end"}>
                {assetList?.optimizationType === "Max Value" && (
                  <Text
                    color={"#004069"}
                    fontFamily={"Nunito"}
                    fontSize={"22px"}
                    fontStyle={"normal"}
                    fontWeight={"800"}
                    lineHeight={"normal"}
                    alignSelf={"flex-end"}
                  >
                    {currencyFormat(
                      safeAdd(
                        assetList?.optimizedEOLAmountAfterTax,
                        -assetList?.ruleOfThumb
                      )
                    )}
                  </Text>
                )}
                <Text
                  color={"#030229"}
                  fontFamily={"Nunito"}
                  fontSize={"14px"}
                  fontStyle={"normal"}
                  fontWeight={"400"}
                  lineHeight={"normal"}
                >
                  After-tax savings
                </Text>
              </VStack>
            </Box>
          )}
          {assetList?.optimizationType != "Max Value" && (
            <Box display={"flex"} flexDirection={"row"} gap={"20px"}>
              <Box
                display={"flex"}
                w={"50px"}
                height={"50px"}
                flexDirection={"column"}
                justifyContent={"center"}
                alignItems={"center"}
                borderRadius={"500px"}
                bg={"#FFF4F0"}
              >
                <Box
                  display={"flex"}
                  w={"45px"}
                  h={"23px"}
                  flexDirection={"column"}
                  justifyContent={"center"}
                  alignItems={"center"}
                  flexShrink={0}
                >
                  <Text
                    color={"#FF8F6B"}
                    fontFamily={"Nunito"}
                    fontSize={"16px"}
                    fontStyle={"normal"}
                    fontWeight={"800"}
                    lineHeight={"normal"}
                  >
                    {roundedPercentage}%
                  </Text>
                </Box>
              </Box>
              <VStack alignItems={"flex-start"}>
                <Text
                  color={"#004069"}
                  fontFamily={"Nunito"}
                  fontSize={"22px"}
                  fontStyle={"normal"}
                  fontWeight={"800"}
                  lineHeight={"normal"}
                  alignSelf={"flex-end"}
                >
                  {assetList?.optimizationType != "Max Value" && (
                    <div>
                      {currencyFormat(TotalExtra)}
                      {/* <span
                        style={{ marginLeft: "0.5rem", fontSize: "0.7rem" }}
                      >
                        {currencyFormat(
                          TotalExtra / assetList?.expenses?.length
                        )}
                        /Year
                      </span> */}
                    </div>
                  )}
                </Text>
                <Text
                  color={"#030229"}
                  fontFamily={"Nunito"}
                  fontSize={"14px"}
                  fontStyle={"normal"}
                  fontWeight={"400"}
                  lineHeight={"normal"}
                >
                  Life Time Extra Spending
                </Text>
              </VStack>
            </Box>
          )}
        </>
      )}
    </>
  );
}

function OptimlValue(props) {
  const { isSmallScreen, currencyFormat, assetList } = props;

  const TotalExtra = assetList?.extraSpending?.reduce(
    (accumulator, currentValue) => {
      return safeAdd(accumulator, currentValue);
    }
  );

  const { stripePlan, setStripePlan } = useContext(StripeContext);

  const optimlCost = stripePlan?.items?.data?.[0]?.plan?.amount / 100;

  const totalExpenses =
    assetList?.expenses?.reduce((acc, expense) => acc + expense, 0) || 0;
  const percentage = (TotalExtra / totalExpenses) * 100;
  const roundedPercentage = Math.round(percentage);

  return (
    <>
      {isSmallScreen && (
        <>
          {assetList?.optimizationType === "Max Value" && (
            <Box display={"flex"} flexDirection={"row"} gap={"10px"}>
              <Box
                display={"flex"}
                w={"30px"}
                height={"30px"}
                flexDirection={"column"}
                justifyContent={"center"}
                alignItems={"center"}
                borderRadius={"500px"}
                bg={"#FFF4F0"}
              >
                <Box
                  display={"flex"}
                  w={"38px"}
                  h={"23px"}
                  flexDirection={"column"}
                  justifyContent={"center"}
                  alignItems={"center"}
                  flexShrink={0}
                >
                  {assetList?.optimizationType === "Max Value" && (
                    <Text
                      color={"#FF8F6B"}
                      fontFamily={"Nunito"}
                      fontSize={"14px"}
                      fontStyle={"normal"}
                      fontWeight={"800"}
                      lineHeight={"normal"}
                    >
                      {round(
                        optimlCost /
                          ((assetList?.optimizedEOLAmountAfterTax,
                          -assetList?.ruleOfThumb) /
                            assetList?.expenses?.length)
                      ) * 100}
                      %
                    </Text>
                  )}
                </Box>
              </Box>
              <VStack
                alignItems={"flex-start"}
                justifyContent={"center"}
                gap={0}
              >
                {assetList?.optimizationType === "Max Value" && (
                  <Text
                    color={"#004069"}
                    fontFamily={"Nunito"}
                    fontSize={"16px"}
                    fontStyle={"normal"}
                    fontWeight={"800"}
                    lineHeight={"normal"}
                  >
                    {Math.round(
                      (assetList?.optimizedEOLAmountAfterTax -
                        assetList?.ruleOfThumb) /
                        (assetList?.expenses?.length || 1) /
                        optimlCost
                    )}
                    X
                  </Text>
                )}
                <Text
                  color={"#030229"}
                  fontFamily={"Nunito"}
                  fontSize={"8px"}
                  fontStyle={"normal"}
                  fontWeight={"400"}
                  lineHeight={"normal"}
                >
                  Annual ROI Using Optiml
                </Text>
              </VStack>
            </Box>
          )}
        </>
      )}
      {!isSmallScreen && (
        <>
          {assetList?.optimizationType === "Max Value" && (
            <Box display={"flex"} flexDirection={"row"} gap={"20px"}>
              <Box
                display={"flex"}
                w={"50px"}
                height={"50px"}
                flexDirection={"column"}
                justifyContent={"center"}
                alignItems={"center"}
                borderRadius={"500px"}
                bg={"#FFF4F0"}
              >
                <Box
                  display={"flex"}
                  w={"70px"}
                  flexDirection={"row"}
                  justifyContent={"center"}
                  flexShrink={0}
                >
                  {assetList?.optimizationType === "Max Value" && (
                    <div style={{ cursor: "pointer" }}>
                      <Text
                        color={"#FF8F6B"}
                        fontFamily={"Nunito"}
                        fontSize={"18px"}
                        fontStyle={"normal"}
                        fontWeight={"800"}
                        lineHeight={"normal"}
                      >
                        {Math.round(
                          (assetList?.optimizedEOLAmountAfterTax -
                            assetList?.ruleOfThumb) /
                            (assetList?.expenses?.length || 1) /
                            optimlCost
                        )}
                        X
                      </Text>
                    </div>
                  )}
                </Box>
              </Box>
              <VStack alignItems={"flex-end"}>
                {assetList?.optimizationType === "Max Value" && (
                  <Text
                    color={"#004069"}
                    fontFamily={"Nunito"}
                    fontSize={"22px"}
                    fontStyle={"normal"}
                    fontWeight={"800"}
                    lineHeight={"normal"}
                    alignSelf={"flex-end"}
                  >
                    {currencyFormat(
                      safeAdd(
                        assetList?.optimizedEOLAmountAfterTax,
                        -assetList?.ruleOfThumb
                      ) / assetList?.expenses?.length
                    )}
                  </Text>
                )}
                <Text
                  color={"#030229"}
                  fontFamily={"Nunito"}
                  fontSize={"14px"}
                  fontStyle={"normal"}
                  fontWeight={"400"}
                  lineHeight={"normal"}
                >
                  Annual ROI Using Optiml
                </Text>
              </VStack>
            </Box>
          )}
        </>
      )}
    </>
  );
}

function NetWorth(props) {
  const {
    isSmallScreen,
    currencyFormat,
    assetList,
    spousePrefix,
    spouseOptiList,
  } = props;

  return (
    <>
      {isSmallScreen && (
        <>
          <Box display={"flex"} flexDirection={"row"} gap={"10px"}>
            <Box
              display={"flex"}
              w={"38px"}
              h={"23px"}
              flexDirection={"column"}
              justifyContent={"center"}
              alignItems={"center"}
              flexShrink={0}
            >
              <Icon
                as={EndOfAnalysisBalanceIcon}
                svgwidth={"40px"}
                svgheight={"50px"}
              />
            </Box>

            <VStack alignItems={"flex-start"} justifyContent={"center"} gap={0}>
              <Text
                color={"#004069"}
                fontFamily={"Nunito"}
                fontSize={"16px"}
                fontStyle={"normal"}
                fontWeight={"800"}
                lineHeight={"normal"}
              >
                {currencyFormat(
                  safeAdd(
                    assetList?.[`${spousePrefix}_registeredFunds`]?.[0],
                    assetList?.[`${spousePrefix}_nonregisteredFunds`]?.[0],
                    assetList?.[`${spousePrefix}_tfsaFunds`]?.[0],
                    assetList?.[`${spousePrefix}_fixedIncomeTotal`]?.[0],
                    assetList?.[`${spousePrefix}_liraFunds`]?.[0],
                    assetList?.[`${spousePrefix}_pension`]?.Value?.[0],
                    assetList?.[`${spousePrefix}_fhsaFunds`]?.[0],
                    spouseOptiList?.sps2_fhsaFunds?.[0],
                    spouseOptiList?.sps2_nonregisteredFunds?.[0],
                    spouseOptiList?.sps2_tfsaFunds?.[0],
                    spouseOptiList?.sps2_fixedIncomeTotal?.[0],
                    spouseOptiList?.sps2_registeredFunds?.[0],
                    spouseOptiList?.sps2_liraFunds?.[0],
                    spouseOptiList?.sps2_pension?.Value?.[0],
                    assetList?.PrimaryResidence?.Value?.[0],
                    assetList?.businessYearlyValue?.[0],
                    assetList?.IncomePropertyYearlyValue?.[0],
                    assetList?.RecreationPropertyYearlyValue?.[0],
                    assetList?.LandYearlyValue?.[0],
                    assetList?.lifeInsuranceYearlyValue?.[0],
                    assetList?.otherAssetsYearlyValue?.[0]
                  ) -
                    safeAdd(
                      assetList?.totalDebt?.[0],
                      assetList?.IncomePropertyYearlyBalance?.[0],
                      assetList?.RecreationPropertyYearlyBalance?.[0],
                      assetList?.LandYearlyValue?.[0],
                      assetList?.PrimaryResidence?.Balance?.[0]
                    )
                )}
              </Text>

              <Text
                color={"#030229"}
                fontFamily={"Nunito"}
                fontSize={"8px"}
                fontStyle={"normal"}
                fontWeight={"400"}
                lineHeight={"normal"}
              >
                Annual ROI Using Optiml
              </Text>
            </VStack>
          </Box>
        </>
      )}
      {!isSmallScreen && (
        <>
          <Box display={"flex"} flexDirection={"row"} gap={"20px"}>
            <Box
              display={"flex"}
              w={"70px"}
              flexDirection={"row"}
              justifyContent={"center"}
              flexShrink={0}
            >
              <Icon
                as={EndOfAnalysisBalanceIcon}
                svgwidth={"50px"}
                svgheight={"54px"}
              />
            </Box>
            <VStack alignItems={"flex-end"}>
              <Text
                color={"#004069"}
                fontFamily={"Nunito"}
                fontSize={"22px"}
                fontStyle={"normal"}
                fontWeight={"800"}
                lineHeight={"normal"}
                alignSelf={"flex-end"}
              >
                {currencyFormat(
                  safeAdd(
                    assetList?.[`${spousePrefix}_registeredFunds`]?.[0],
                    assetList?.[`${spousePrefix}_nonregisteredFunds`]?.[0],
                    assetList?.[`${spousePrefix}_tfsaFunds`]?.[0],
                    assetList?.[`${spousePrefix}_fixedIncomeTotal`]?.[0],
                    assetList?.[`${spousePrefix}_liraFunds`]?.[0],
                    assetList?.[`${spousePrefix}_pension`]?.Value?.[0],
                    assetList?.[`${spousePrefix}_fhsaFunds`]?.[0],
                    spouseOptiList?.sps2_fhsaFunds?.[0],
                    spouseOptiList?.sps2_nonregisteredFunds?.[0],
                    spouseOptiList?.sps2_tfsaFunds?.[0],
                    spouseOptiList?.sps2_fixedIncomeTotal?.[0],
                    spouseOptiList?.sps2_registeredFunds?.[0],
                    spouseOptiList?.sps2_liraFunds?.[0],
                    spouseOptiList?.sps2_pension?.Value?.[0],
                    assetList?.PrimaryResidence?.Value?.[0],
                    assetList?.businessYearlyValue?.[0],
                    assetList?.IncomePropertyYearlyValue?.[0],
                    assetList?.RecreationPropertyYearlyValue?.[0],
                    assetList?.LandYearlyValue?.[0],
                    assetList?.lifeInsuranceYearlyValue?.[0],
                    assetList?.otherAssetsYearlyValue?.[0]
                  ) -
                    safeAdd(
                      assetList?.totalDebt?.[0],
                      assetList?.IncomePropertyYearlyBalance?.[0],
                      assetList?.RecreationPropertyYearlyBalance?.[0],
                      assetList?.LandYearlyValue?.[0],
                      assetList?.PrimaryResidence?.Balance?.[0]
                    )
                )}
              </Text>

              <Text
                color={"#030229"}
                fontFamily={"Nunito"}
                fontSize={"14px"}
                fontStyle={"normal"}
                fontWeight={"400"}
                lineHeight={"normal"}
              >
                Current Net Worth
              </Text>
            </VStack>
          </Box>
        </>
      )}
    </>
  );
}

function AverageTaxRate(props) {
  const { optiList, spouseOptiList, averageTaxRate, sumArray, spousePrefix } =
    props;

  const spouse1avg = averageTaxRate(
    sumArray(optiList?.[`${spousePrefix}_taxableIncome`]),
    safeAdd(
      sumArray(optiList?.[`${spousePrefix}_incomeTaxes`]),
      sumArray(optiList?.[`${spousePrefix}_cppContributions`]),
      sumArray(optiList?.[`${spousePrefix}_eiPremiums`])
    )
  );

  const spouse2avg = averageTaxRate(
    sumArray(spouseOptiList?.sps2_taxableIncome),
    safeAdd(
      sumArray(spouseOptiList?.sps2_incomeTaxes),
      sumArray(spouseOptiList?.sps2_cppContributions),
      sumArray(spouseOptiList?.sps2_eiPremiums)
    )
  );

  const avg = (spouse1avg + spouse2avg) / 2;

  return (
    <>
      <Box display={"flex"} flexDirection={"row"} gap={"20px"}>
        <VStack>
          <Box
            display={"flex"}
            w={"50px"}
            height={"50px"}
            flexDirection={"column"}
            justifyContent={"center"}
            alignItems={"center"}
            borderRadius={"500px"}
            bg={"#FFF4F0"}
          >
            <Box
              display={"flex"}
              w={"45px"}
              h={"23px"}
              flexDirection={"column"}
              justifyContent={"center"}
              alignItems={"center"}
              flexShrink={0}
            >
              <Text
                color={"#FF8F6B"}
                fontFamily={"Nunito"}
                fontSize={"16px"}
                fontStyle={"normal"}
                fontWeight={"800"}
                lineHeight={"normal"}
              >
                {avg}%
              </Text>
            </Box>
          </Box>
          <Text
            color={"#030229"}
            fontFamily={"Nunito"}
            fontSize={"14px"}
            fontStyle={"normal"}
            fontWeight={"400"}
            lineHeight={"normal"}
          >
            Avg. Tax rate
          </Text>
        </VStack>
      </Box>
    </>
  );
}

function OptimalCPPAge(props) {
  const { stripePlan, setStripePlan } = useContext(StripeContext);
  const { spouseStatus, setSpouseStatus } = useContext(SpouseContext);
  const blurStyle = {
    filter: "blur(5px)",
    cursor: "pointer",
  };

  const [showStripeModal, setShowStripeModal] = useState(false);

  function UpgradeStripeModal() {
    setShowStripeModal(!showStripeModal);
  }

  const calculateDaysLeft = (trialEndTimestamp) => {
    const currentDate = new Date();
    const trialEndDate = new Date(trialEndTimestamp * 1000); // Convert to milliseconds
    const differenceInTime = trialEndDate.getTime() - currentDate.getTime();
    const differenceInDays = Math.ceil(differenceInTime / (1000 * 3600 * 24));
    return differenceInDays;
  };

  const {
    isSmallScreen,
    optiList,
    spouseOptiList,
    currentYear,
    getAge,
    withdrawCppAge,
    spousePrefix,
  } = props;
  const userIncomeInfo = useStore((store) => store.userIncomeInfo);
  const userInfo = useStore((store) => store.userInfo);
  const getInitials = (name) => {
    if (!name) return "";
    const words = name.split(" ");
    const initials = words.map((word) => word[0]).join("");
    return initials.toUpperCase();
  };

  return (
    <>
      {isSmallScreen && (
        <Box
          display={"flex"}
          flexDirection={"row"}
          gap={"10px"}
          justifyContent={"center"}
        >
          <Box
            display={"flex"}
            w={"30px"}
            height={"30px"}
            flexDirection={"column"}
            justifyContent={"center"}
            alignItems={"center"}
            borderRadius={"500px"}
            bg={"#DDF0DF"}
          >
            <Text
              color={"#7AC381"}
              fontFamily={"Nunito"}
              fontSize={"12px"}
              fontStyle={"normal"}
              fontWeight={"800"}
              lineHeight={"normal"}
            >
              CPP
            </Text>
          </Box>
          <VStack alignItems={"flex-start"} justifyContent={"center"} gap={0}>
            <HStack gap={1}>
              {(!stripePlan?.trial_end ||
                calculateDaysLeft(stripePlan?.trial_end) < 1) && (
                <>
                  <Text
                    color={"#004069"}
                    fontFamily={"Nunito"}
                    fontSize={"16px"}
                    fontStyle={"normal"}
                    fontWeight={"800"}
                    lineHeight={"normal"}
                  >
                    {getInitials(optiList?.[`${spousePrefix}_name`])}
                    {getInitials(optiList?.[`${spousePrefix}_lastName`])}:
                  </Text>
                  {userIncomeInfo?.hasStartedCPP !== "Yes" && (
                    <Text
                      color={"#7AC381"}
                      fontFamily={"Nunito"}
                      fontSize={"16px"}
                      fontStyle={"normal"}
                      fontWeight={"800"}
                      lineHeight={"normal"}
                    >
                      {withdrawCppAge(
                        optiList?.[`${spousePrefix}_cpp`],
                        getAge(
                          optiList?.[`${spousePrefix}_birthday`],
                          currentYear
                        )
                      )}
                      {userIncomeInfo?.hasStartedCPP === "Yes" && (
                        <Text>N/A</Text>
                      )}
                    </Text>
                  )}
                  <Text
                    color={"#004069"}
                    fontFamily={"Nunito"}
                    fontSize={"16px"}
                    fontStyle={"normal"}
                    fontWeight={"800"}
                    lineHeight={"normal"}
                  >
                    |
                  </Text>

                  <Text
                    color={"#004069"}
                    fontFamily={"Nunito"}
                    fontSize={"16px"}
                    fontStyle={"normal"}
                    fontWeight={"800"}
                    lineHeight={"normal"}
                  >
                    {getInitials(spouseOptiList?.sps2_name)}
                    {getInitials(spouseOptiList?.sps2_lastName)}:
                  </Text>
                  {spouseOptiList?.sps2_name &&
                    userIncomeInfo?.spouseHasStartedCPP !== "Yes" && (
                      <>
                        <Text
                          color={"#7AC381"}
                          fontFamily={"Nunito"}
                          fontSize={"16px"}
                          fontStyle={"normal"}
                          fontWeight={"800"}
                          lineHeight={"normal"}
                        >
                          <span>
                            {withdrawCppAge(
                              spouseOptiList?.sps2_cpp,
                              getAge(spouseOptiList?.sps2_birthday, currentYear)
                            )}
                            {spouseOptiList?.sps2_name &&
                              userIncomeInfo?.spouseHasStartedCPP === "Yes" && (
                                <span>N/A</span>
                              )}
                          </span>
                        </Text>
                      </>
                    )}
                </>
              )}
              {calculateDaysLeft(stripePlan?.trial_end) >= 1 && (
                <>
                  <Text
                    color={"#004069"}
                    fontFamily={"Nunito"}
                    fontSize={"16px"}
                    fontStyle={"normal"}
                    fontWeight={"800"}
                    lineHeight={"normal"}
                  >
                    {getInitials(optiList?.[`${spousePrefix}_name`])}
                    {getInitials(optiList?.[`${spousePrefix}_lastName`])}:
                  </Text>
                  {userIncomeInfo?.hasStartedCPP !== "Yes" && (
                    <Text
                      color={"#7AC381"}
                      fontFamily={"Nunito"}
                      fontSize={"16px"}
                      fontStyle={"normal"}
                      fontWeight={"800"}
                      lineHeight={"normal"}
                      onClick={UpgradeStripeModal}
                      style={blurStyle}
                    >
                      XX
                      {userIncomeInfo?.hasStartedCPP === "Yes" && (
                        <Text>N/A</Text>
                      )}
                    </Text>
                  )}
                  <Text
                    color={"#004069"}
                    fontFamily={"Nunito"}
                    fontSize={"16px"}
                    fontStyle={"normal"}
                    fontWeight={"800"}
                    lineHeight={"normal"}
                  >
                    |
                  </Text>

                  <Text
                    color={"#004069"}
                    fontFamily={"Nunito"}
                    fontSize={"16px"}
                    fontStyle={"normal"}
                    fontWeight={"800"}
                    lineHeight={"normal"}
                  >
                    {getInitials(spouseOptiList?.sps2_name)}
                    {getInitials(spouseOptiList?.sps2_lastName)}:
                  </Text>
                  {spouseOptiList?.sps2_name &&
                    userIncomeInfo?.spouseHasStartedCPP !== "Yes" && (
                      <>
                        <Text
                          color={"#7AC381"}
                          fontFamily={"Nunito"}
                          fontSize={"16px"}
                          fontStyle={"normal"}
                          fontWeight={"800"}
                          lineHeight={"normal"}
                          onClick={UpgradeStripeModal}
                          style={blurStyle}
                        >
                          <span>
                            XX
                            {spouseOptiList?.sps2_name &&
                              userIncomeInfo?.spouseHasStartedCPP === "Yes" && (
                                <span>N/A</span>
                              )}
                          </span>
                        </Text>
                      </>
                    )}
                </>
              )}
            </HStack>
            {userInfo?.Province != "QC" && (
              <HStack gap={1}>
                <Text
                  color={"#004069"}
                  fontFamily={"Nunito"}
                  fontSize={"8px"}
                  fontStyle={"normal"}
                  fontWeight={"800"}
                  lineHeight={"normal"}
                >
                  CPP
                </Text>
                <Text
                  color={"#030229"}
                  fontFamily={"Nunito"}
                  fontSize={"8px"}
                  fontStyle={"normal"}
                  fontWeight={"400"}
                  lineHeight={"normal"}
                >
                  application age
                </Text>
              </HStack>
            )}
            {userInfo?.Province === "QC" && (
              <Text
                color={"#030229"}
                fontFamily={"Nunito"}
                fontSize={"8px"}
                fontStyle={"normal"}
                fontWeight={"400"}
                lineHeight={"normal"}
              >
                QPP application age
              </Text>
            )}
          </VStack>
        </Box>
      )}
      {!isSmallScreen && (
        <Box display={"flex"} flexDirection={"row"} gap={"20px"}>
          <Box
            display={"flex"}
            w={"50px"}
            height={"50px"}
            flexDirection={"column"}
            justifyContent={"center"}
            alignItems={"center"}
            borderRadius={"500px"}
            bg={"#DDF0DF"}
          >
            <Text
              color={"#7AC381"}
              fontFamily={"Nunito"}
              fontSize={"20px"}
              fontStyle={"normal"}
              fontWeight={"800"}
              lineHeight={"normal"}
            >
              CPP
            </Text>
          </Box>
          <VStack alignItems={"flex-start"}>
            <HStack>
              {(!stripePlan?.trial_end ||
                calculateDaysLeft(stripePlan?.trial_end) < 1) && (
                <>
                  <Text
                    color={"#004069"}
                    fontFamily={"Nunito"}
                    fontSize={"22px"}
                    fontStyle={"normal"}
                    fontWeight={"800"}
                    lineHeight={"normal"}
                  >
                    {getInitials(optiList?.[`${spousePrefix}_name`])}
                    {getInitials(optiList?.[`${spousePrefix}_lastName`])}:
                  </Text>
                  {userIncomeInfo?.hasStartedCPP !== "Yes" && (
                    <Text
                      color={"#7AC381"}
                      fontFamily={"Nunito"}
                      fontSize={"22px"}
                      fontStyle={"normal"}
                      fontWeight={"800"}
                      lineHeight={"normal"}
                    >
                      {withdrawCppAge(
                        optiList?.[`${spousePrefix}_cpp`],
                        getAge(
                          optiList?.[`${spousePrefix}_birthday`],
                          currentYear
                        )
                      )}
                      {userIncomeInfo?.hasStartedCPP === "Yes" && (
                        <Text>N/A</Text>
                      )}
                    </Text>
                  )}

                  {spouseOptiList !== "" && (
                    <>
                      {spouseStatus && (
                        <Text
                          color={"#004069"}
                          fontFamily={"Nunito"}
                          fontSize={"22px"}
                          fontStyle={"normal"}
                          fontWeight={"800"}
                          lineHeight={"normal"}
                        >
                          |
                        </Text>
                      )}
                      {spouseStatus && (
                        <Text
                          color={"#004069"}
                          fontFamily={"Nunito"}
                          fontSize={"22px"}
                          fontStyle={"normal"}
                          fontWeight={"800"}
                          lineHeight={"normal"}
                        >
                          {getInitials(spouseOptiList?.sps2_name)}
                          {getInitials(spouseOptiList?.sps2_lastName)}:
                        </Text>
                      )}
                      {spouseOptiList?.sps2_name &&
                        userIncomeInfo?.spouseHasStartedCPP !== "Yes" && (
                          <>
                            <Text
                              color={"#7AC381"}
                              fontFamily={"Nunito"}
                              fontSize={"22px"}
                              fontStyle={"normal"}
                              fontWeight={"800"}
                              lineHeight={"normal"}
                            >
                              <span>
                                {withdrawCppAge(
                                  spouseOptiList?.sps2_cpp,
                                  getAge(
                                    spouseOptiList?.sps2_birthday,
                                    currentYear
                                  )
                                )}
                                {spouseOptiList?.sps2_name &&
                                  userIncomeInfo?.spouseHasStartedCPP ===
                                    "Yes" && <span>N/A</span>}
                              </span>
                            </Text>
                          </>
                        )}
                    </>
                  )}
                </>
              )}
              {calculateDaysLeft(stripePlan?.trial_end) >= 1 && (
                <>
                  <Text
                    color={"#004069"}
                    fontFamily={"Nunito"}
                    fontSize={"22px"}
                    fontStyle={"normal"}
                    fontWeight={"800"}
                    lineHeight={"normal"}
                  >
                    {getInitials(optiList?.[`${spousePrefix}_name`])}
                    {getInitials(optiList?.[`${spousePrefix}_lastName`])}:
                  </Text>
                  {userIncomeInfo?.hasStartedCPP !== "Yes" && (
                    <Text
                      color={"#7AC381"}
                      fontFamily={"Nunito"}
                      fontSize={"22px"}
                      fontStyle={"normal"}
                      fontWeight={"800"}
                      lineHeight={"normal"}
                      onClick={UpgradeStripeModal}
                      style={blurStyle}
                    >
                      XX
                      {userIncomeInfo?.hasStartedCPP === "Yes" && (
                        <Text>N/A</Text>
                      )}
                    </Text>
                  )}

                  {spouseOptiList !== "" && (
                    <>
                      {spouseStatus && (
                        <Text
                          color={"#004069"}
                          fontFamily={"Nunito"}
                          fontSize={"22px"}
                          fontStyle={"normal"}
                          fontWeight={"800"}
                          lineHeight={"normal"}
                        >
                          |
                        </Text>
                      )}
                      {spouseStatus && (
                        <Text
                          color={"#004069"}
                          fontFamily={"Nunito"}
                          fontSize={"22px"}
                          fontStyle={"normal"}
                          fontWeight={"800"}
                          lineHeight={"normal"}
                        >
                          {getInitials(spouseOptiList?.sps2_name)}
                          {getInitials(spouseOptiList?.sps2_lastName)}:
                        </Text>
                      )}
                      {spouseOptiList?.sps2_name &&
                        userIncomeInfo?.spouseHasStartedCPP !== "Yes" && (
                          <>
                            <Text
                              color={"#7AC381"}
                              fontFamily={"Nunito"}
                              fontSize={"22px"}
                              fontStyle={"normal"}
                              fontWeight={"800"}
                              lineHeight={"normal"}
                              onClick={UpgradeStripeModal}
                              style={blurStyle}
                            >
                              <span>
                                XX
                                {spouseOptiList?.sps2_name &&
                                  userIncomeInfo?.spouseHasStartedCPP ===
                                    "Yes" && <span>N/A</span>}
                              </span>
                            </Text>
                          </>
                        )}
                    </>
                  )}
                </>
              )}
            </HStack>
            {userInfo?.Province != "QC" && (
              <Text
                color={"#030229"}
                fontFamily={"Nunito"}
                fontSize={"14px"}
                fontStyle={"normal"}
                fontWeight={"400"}
                lineHeight={"normal"}
              >
                CPP application age
              </Text>
            )}
            {userInfo?.Province === "QC" && (
              <Text
                color={"#030229"}
                fontFamily={"Nunito"}
                fontSize={"14px"}
                fontStyle={"normal"}
                fontWeight={"400"}
                lineHeight={"normal"}
              >
                QPP application age
              </Text>
            )}
          </VStack>
        </Box>
      )}
      <StripeUpgradeModal
        isOpen={showStripeModal}
        closeModal={UpgradeStripeModal}
      />
    </>
  );
}

function OptimalOASAge(props) {
  const { stripePlan, setStripePlan } = useContext(StripeContext);
  const { spouseStatus, setSpouseStatus } = useContext(SpouseContext);
  const blurStyle = {
    filter: "blur(5px)",
    cursor: "pointer",
  };

  const calculateDaysLeft = (trialEndTimestamp) => {
    const currentDate = new Date();
    const trialEndDate = new Date(trialEndTimestamp * 1000); // Convert to milliseconds
    const differenceInTime = trialEndDate.getTime() - currentDate.getTime();
    const differenceInDays = Math.ceil(differenceInTime / (1000 * 3600 * 24));
    return differenceInDays;
  };

  const [showStripeModal, setShowStripeModal] = useState(false);

  function UpgradeStripeModal() {
    setShowStripeModal(!showStripeModal);
  }
  const [showPopup, setShowPopup] = useState(false);

  const handleHover = () => {
    setShowPopup(true);
  };

  const handleClose = () => {
    setShowPopup(false);
  };

  const getInitials = (name) => {
    if (!name) return "";
    const words = name.split(" ");
    const initials = words.map((word) => word[0]).join("");
    return initials.toUpperCase();
  };

  const {
    isSmallScreen,
    optiList,
    spouseOptiList,
    currentYear,
    getAge,
    withdrawCppAge,
    spousePrefix,
  } = props;

  const userIncomeInfo = useStore((store) => store.userIncomeInfo);
  return (
    <>
      {isSmallScreen && (
        <Box
          display={"flex"}
          flexDirection={"row"}
          justifyContent={"center"}
          gap={"10px"}
        >
          <Box
            display={"flex"}
            w={"32px"}
            height={"30px"}
            flexDirection={"column"}
            justifyContent={"center"}
            alignItems={"center"}
            borderRadius={"500px"}
            bg={"rgba(77, 196, 239, 0.20)"}
          >
            <Text
              color={"#4DC4EF"}
              fontFamily={"Nunito"}
              fontSize={"12px"}
              fontStyle={"normal"}
              fontWeight={"800"}
              lineHeight={"normal"}
            >
              OAS
            </Text>
          </Box>
          <VStack alignItems={"flex-start"} justifyContent={"center"} gap={0}>
            <HStack gap={1}>
              {(!stripePlan?.trial_end ||
                calculateDaysLeft(stripePlan?.trial_end) < 1) && (
                <>
                  <Text
                    color={"#004069"}
                    fontFamily={"Nunito"}
                    fontSize={"16px"}
                    fontStyle={"normal"}
                    fontWeight={"800"}
                    lineHeight={"normal"}
                  >
                    {getInitials(optiList?.[`${spousePrefix}_name`])}
                    {getInitials(optiList?.[`${spousePrefix}_lastName`])}:
                  </Text>
                  {userIncomeInfo?.hasStartedOAS !== "Yes" && (
                    <Text
                      color={"#4DC4EF"}
                      fontFamily={"Nunito"}
                      fontSize={"16px"}
                      fontStyle={"normal"}
                      fontWeight={"800"}
                      lineHeight={"normal"}
                    >
                      {withdrawCppAge(
                        optiList?.[`${spousePrefix}_oas`],
                        getAge(
                          optiList?.[`${spousePrefix}_birthday`],
                          currentYear
                        )
                      )}
                    </Text>
                  )}
                  {userIncomeInfo?.hasStartedOAS === "Yes" && <Text>N/A</Text>}
                  <Text
                    color={"#004069"}
                    fontFamily={"Nunito"}
                    fontSize={"16px"}
                    fontStyle={"normal"}
                    fontWeight={"800"}
                    lineHeight={"normal"}
                  >
                    |
                  </Text>
                  <Text
                    color={"#004069"}
                    fontFamily={"Nunito"}
                    fontSize={"16px"}
                    fontStyle={"normal"}
                    fontWeight={"800"}
                    lineHeight={"normal"}
                  >
                    {getInitials(spouseOptiList?.sps2_name)}
                    {getInitials(spouseOptiList?.sps2_lastName)}:
                  </Text>
                  {spouseOptiList?.sps2_name &&
                    userIncomeInfo?.spouseHasStartedOAS !== "Yes" && (
                      <Text
                        color={"#4DC4EF"}
                        fontFamily={"Nunito"}
                        fontSize={"16px"}
                        fontStyle={"normal"}
                        fontWeight={"800"}
                        lineHeight={"normal"}
                      >
                        {withdrawCppAge(
                          spouseOptiList?.sps2_oas,
                          getAge(spouseOptiList?.sps2_birthday, currentYear)
                        )}
                      </Text>
                    )}
                  {spouseOptiList?.sps2_name &&
                    userIncomeInfo?.spouseHasStartedOAS === "Yes" && (
                      <span>N/A</span>
                    )}
                  {showPopup &&
                    (userIncomeInfo?.spouseHasStartedOAS !== "Yes" ||
                      userIncomeInfo?.hasStartedOAS !== "Yes") &&
                    (withdrawCppAge(
                      optiList?.[`${spousePrefix}_oas`],
                      getAge(
                        optiList?.[`${spousePrefix}_birthday`],
                        currentYear
                      )
                    ) >= 70 ||
                      withdrawCppAge(
                        spouseOptiList?.sps2_oas,
                        getAge(spouseOptiList?.sps2_birthday, currentYear)
                      ) >= 70) && <Popup onClose={handleClose} />}
                </>
              )}
              {calculateDaysLeft(stripePlan?.trial_end) >= 1 && (
                <>
                  <Text
                    color={"#004069"}
                    fontFamily={"Nunito"}
                    fontSize={"16px"}
                    fontStyle={"normal"}
                    fontWeight={"800"}
                    lineHeight={"normal"}
                  >
                    {getInitials(optiList?.[`${spousePrefix}_name`])}
                    {getInitials(optiList?.[`${spousePrefix}_lastName`])}:
                  </Text>
                  {userIncomeInfo?.hasStartedOAS !== "Yes" && (
                    <Text
                      color={"#4DC4EF"}
                      fontFamily={"Nunito"}
                      fontSize={"16px"}
                      fontStyle={"normal"}
                      fontWeight={"800"}
                      lineHeight={"normal"}
                      onClick={UpgradeStripeModal}
                      style={blurStyle}
                    >
                      XX
                    </Text>
                  )}
                  {userIncomeInfo?.hasStartedOAS === "Yes" && <Text>N/A</Text>}
                  <Text
                    color={"#004069"}
                    fontFamily={"Nunito"}
                    fontSize={"16px"}
                    fontStyle={"normal"}
                    fontWeight={"800"}
                    lineHeight={"normal"}
                  >
                    |
                  </Text>
                  <Text
                    color={"#004069"}
                    fontFamily={"Nunito"}
                    fontSize={"16px"}
                    fontStyle={"normal"}
                    fontWeight={"800"}
                    lineHeight={"normal"}
                  >
                    {getInitials(spouseOptiList?.sps2_name)}
                    {getInitials(spouseOptiList?.sps2_lastName)}:
                  </Text>
                  {spouseOptiList?.sps2_name &&
                    userIncomeInfo?.spouseHasStartedOAS !== "Yes" && (
                      <Text
                        color={"#4DC4EF"}
                        fontFamily={"Nunito"}
                        fontSize={"16px"}
                        fontStyle={"normal"}
                        fontWeight={"800"}
                        lineHeight={"normal"}
                        onClick={UpgradeStripeModal}
                        style={blurStyle}
                      >
                        XX
                      </Text>
                    )}
                  {spouseOptiList?.sps2_name &&
                    userIncomeInfo?.spouseHasStartedOAS === "Yes" && (
                      <span>N/A</span>
                    )}
                  {showPopup &&
                    (userIncomeInfo?.spouseHasStartedOAS !== "Yes" ||
                      userIncomeInfo?.hasStartedOAS !== "Yes") &&
                    (withdrawCppAge(
                      optiList?.[`${spousePrefix}_oas`],
                      getAge(
                        optiList?.[`${spousePrefix}_birthday`],
                        currentYear
                      )
                    ) >= 70 ||
                      withdrawCppAge(
                        spouseOptiList?.sps2_oas,
                        getAge(spouseOptiList?.sps2_birthday, currentYear)
                      ) >= 70) && <Popup onClose={handleClose} />}
                </>
              )}
            </HStack>
            <HStack gap={1}>
              <Text
                color={"#004069"}
                fontFamily={"Nunito"}
                fontSize={"8px"}
                fontStyle={"normal"}
                fontWeight={"800"}
                lineHeight={"normal"}
              >
                OAS
              </Text>
              <Text
                color={"#030229"}
                fontFamily={"Nunito"}
                fontSize={"8px"}
                fontStyle={"normal"}
                fontWeight={"400"}
                lineHeight={"normal"}
              >
                application age
              </Text>
            </HStack>
          </VStack>
        </Box>
      )}
      {!isSmallScreen && (
        <Box display={"flex"} flexDirection={"row"} gap={"20px"}>
          <Box
            display={"flex"}
            w={"50px"}
            height={"50px"}
            flexDirection={"column"}
            justifyContent={"center"}
            alignItems={"center"}
            borderRadius={"500px"}
            bg={"rgba(77, 196, 239, 0.20)"}
          >
            <Text
              color={"#4DC4EF"}
              fontFamily={"Nunito"}
              fontSize={"20px"}
              fontStyle={"normal"}
              fontWeight={"800"}
              lineHeight={"normal"}
            >
              OAS
            </Text>
          </Box>
          <VStack alignItems={"flex-start"}>
            <HStack>
              {(!stripePlan?.trial_end ||
                calculateDaysLeft(stripePlan?.trial_end) < 1) && (
                <>
                  <Text
                    color={"#004069"}
                    fontFamily={"Nunito"}
                    fontSize={"22px"}
                    fontStyle={"normal"}
                    fontWeight={"800"}
                    lineHeight={"normal"}
                  >
                    {getInitials(optiList?.[`${spousePrefix}_name`])}
                    {getInitials(optiList?.[`${spousePrefix}_lastName`])}:
                  </Text>
                  {userIncomeInfo?.hasStartedOAS !== "Yes" && (
                    <Text
                      color={"#4DC4EF"}
                      fontFamily={"Nunito"}
                      fontSize={"22px"}
                      fontStyle={"normal"}
                      fontWeight={"800"}
                      lineHeight={"normal"}
                    >
                      {withdrawCppAge(
                        optiList?.[`${spousePrefix}_oas`],
                        getAge(
                          optiList?.[`${spousePrefix}_birthday`],
                          currentYear
                        )
                      )}
                    </Text>
                  )}
                  {userIncomeInfo?.hasStartedOAS === "Yes" && <Text>N/A</Text>}
                  {spouseOptiList !== "" && (
                    <>
                      {spouseStatus && (
                        <Text
                          color={"#004069"}
                          fontFamily={"Nunito"}
                          fontSize={"22px"}
                          fontStyle={"normal"}
                          fontWeight={"800"}
                          lineHeight={"normal"}
                        >
                          |
                        </Text>
                      )}
                      {spouseStatus && (
                        <Text
                          color={"#004069"}
                          fontFamily={"Nunito"}
                          fontSize={"22px"}
                          fontStyle={"normal"}
                          fontWeight={"800"}
                          lineHeight={"normal"}
                        >
                          {getInitials(spouseOptiList?.sps2_name)}
                          {getInitials(spouseOptiList?.sps2_lastName)}:
                        </Text>
                      )}
                      {spouseOptiList?.sps2_name &&
                        userIncomeInfo?.spouseHasStartedOAS !== "Yes" && (
                          <Text
                            color={"#4DC4EF"}
                            fontFamily={"Nunito"}
                            fontSize={"22px"}
                            fontStyle={"normal"}
                            fontWeight={"800"}
                            lineHeight={"normal"}
                          >
                            {withdrawCppAge(
                              spouseOptiList?.sps2_oas,
                              getAge(spouseOptiList?.sps2_birthday, currentYear)
                            )}
                          </Text>
                        )}
                      {spouseOptiList?.sps2_name &&
                        userIncomeInfo?.spouseHasStartedOAS === "Yes" && (
                          <span>N/A</span>
                        )}
                    </>
                  )}
                  {showPopup &&
                    (userIncomeInfo?.spouseHasStartedOAS !== "Yes" ||
                      userIncomeInfo?.hasStartedOAS !== "Yes") &&
                    (withdrawCppAge(
                      optiList?.[`${spousePrefix}_oas`],
                      getAge(
                        optiList?.[`${spousePrefix}_birthday`],
                        currentYear
                      )
                    ) >= 70 ||
                      withdrawCppAge(
                        spouseOptiList?.sps2_oas,
                        getAge(spouseOptiList?.sps2_birthday, currentYear)
                      ) >= 70) && <Popup onClose={handleClose} />}
                </>
              )}
              {calculateDaysLeft(stripePlan?.trial_end) >= 1 && (
                <>
                  <Text
                    color={"#004069"}
                    fontFamily={"Nunito"}
                    fontSize={"22px"}
                    fontStyle={"normal"}
                    fontWeight={"800"}
                    lineHeight={"normal"}
                  >
                    {getInitials(optiList?.[`${spousePrefix}_name`])}
                    {getInitials(optiList?.[`${spousePrefix}_lastName`])}:
                  </Text>
                  {userIncomeInfo?.hasStartedOAS !== "Yes" && (
                    <Text
                      color={"#4DC4EF"}
                      fontFamily={"Nunito"}
                      fontSize={"22px"}
                      fontStyle={"normal"}
                      fontWeight={"800"}
                      lineHeight={"normal"}
                      onClick={UpgradeStripeModal}
                      style={blurStyle}
                    >
                      XX
                    </Text>
                  )}
                  {userIncomeInfo?.hasStartedOAS === "Yes" && <Text>N/A</Text>}
                  {spouseOptiList !== "" && (
                    <>
                      {spouseStatus && (
                        <Text
                          color={"#004069"}
                          fontFamily={"Nunito"}
                          fontSize={"22px"}
                          fontStyle={"normal"}
                          fontWeight={"800"}
                          lineHeight={"normal"}
                        >
                          |
                        </Text>
                      )}
                      {spouseStatus && (
                        <Text
                          color={"#004069"}
                          fontFamily={"Nunito"}
                          fontSize={"22px"}
                          fontStyle={"normal"}
                          fontWeight={"800"}
                          lineHeight={"normal"}
                        >
                          {getInitials(spouseOptiList?.sps2_name)}
                          {getInitials(spouseOptiList?.sps2_lastName)}:
                        </Text>
                      )}
                      {spouseOptiList?.sps2_name &&
                        userIncomeInfo?.spouseHasStartedOAS !== "Yes" && (
                          <Text
                            color={"#4DC4EF"}
                            fontFamily={"Nunito"}
                            fontSize={"22px"}
                            fontStyle={"normal"}
                            fontWeight={"800"}
                            lineHeight={"normal"}
                            onClick={UpgradeStripeModal}
                            style={blurStyle}
                          >
                            XX
                          </Text>
                        )}
                      {spouseOptiList?.sps2_name &&
                        userIncomeInfo?.spouseHasStartedOAS === "Yes" && (
                          <span>N/A</span>
                        )}
                    </>
                  )}
                  {showPopup &&
                    (userIncomeInfo?.spouseHasStartedOAS !== "Yes" ||
                      userIncomeInfo?.hasStartedOAS !== "Yes") &&
                    (withdrawCppAge(
                      optiList?.[`${spousePrefix}_oas`],
                      getAge(
                        optiList?.[`${spousePrefix}_birthday`],
                        currentYear
                      )
                    ) >= 70 ||
                      withdrawCppAge(
                        spouseOptiList?.sps2_oas,
                        getAge(spouseOptiList?.sps2_birthday, currentYear)
                      ) >= 70) && <Popup onClose={handleClose} />}
                </>
              )}
            </HStack>

            <Text
              color={"#030229"}
              fontFamily={"Nunito"}
              fontSize={"14px"}
              fontStyle={"normal"}
              fontWeight={"400"}
              lineHeight={"normal"}
            >
              OAS application age
            </Text>
          </VStack>
        </Box>
      )}
      <StripeUpgradeModal
        isOpen={showStripeModal}
        closeModal={UpgradeStripeModal}
      />
    </>
  );
}

function DashboardSmallCard(props) {
  const {
    card,
    optiList,
    assetsList,
    spouseOptiList,
    currencyFormat,
    currentYear,
    getAge,
    withdrawCppAge,
    averageTaxRate,
    sumArray,
    spousePrefix,
    spousePage,
    showpop,
    isInsights,
  } = props;

  const [isLargerThan500] = useMediaQuery("(min-width: 500px)");
  const isSmallScreen = !isLargerThan500;
  return (
    <>
      {card === "estateBalance" && (
        <EstateBalance
          isSmallScreen={isSmallScreen}
          optiList={optiList}
          currencyFormat={currencyFormat}
          spouseOptiList={spouseOptiList}
          spousePrefix={spousePrefix}
          spousePage={spousePage}
          assetList={assetsList}
          isInsights={isInsights}
        />
      )}
      {card === "optimalCPPAge" && (
        <OptimalCPPAge
          isSmallScreen={isSmallScreen}
          optiList={optiList}
          spouseOptiList={spouseOptiList}
          currentYear={currentYear}
          getAge={getAge}
          withdrawCppAge={withdrawCppAge}
          spousePrefix={spousePrefix}
        />
      )}
      {card === "optimalOASAge" && (
        <OptimalOASAge
          isSmallScreen={isSmallScreen}
          optiList={optiList}
          spouseOptiList={spouseOptiList}
          currentYear={currentYear}
          getAge={getAge}
          withdrawCppAge={withdrawCppAge}
          spousePrefix={spousePrefix}
        />
      )}
      {!isSmallScreen && card === "averageTaxRate" && (
        <AverageTaxRate
          isSmallScreen={isSmallScreen}
          optiList={optiList}
          spouseOptiList={spouseOptiList}
          averageTaxRate={averageTaxRate}
          sumArray={sumArray}
          spousePrefix={spousePrefix}
        />
      )}

      {card === "compassScore" && (
        <CompassScore
          isSmallScreen={isSmallScreen}
          optiList={optiList}
          spousePrefix={spousePrefix}
          currencyFormat={currencyFormat}
          assetList={assetsList}
          showpop={showpop}
        />
      )}
      {isInsights && card === "OSFA" ? (
        <OSFA
          isSmallScreen={isSmallScreen}
          optiList={optiList}
          currencyFormat={currencyFormat}
          spouseOptiList={spouseOptiList}
          spousePrefix={spousePrefix}
          spousePage={spousePage}
          assetList={assetsList}
        />
      ) : null}
      {isInsights && card === "optimlValue" ? (
        <OptimlValue
          isSmallScreen={isSmallScreen}
          currencyFormat={currencyFormat}
          assetList={assetsList}
        />
      ) : null}
      {isInsights && card === "netWorth" ? (
        <NetWorth
          isSmallScreen={isSmallScreen}
          currencyFormat={currencyFormat}
          assetList={assetsList}
          spousePrefix={spousePrefix}
          spouseOptiList={spouseOptiList}
        />
      ) : null}
    </>
  );
}

export default DashboardSmallCard;
