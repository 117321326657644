import { Box, Flex, VStack } from "@chakra-ui/layout";
import { Icon, Text, Tooltip, useMediaQuery } from "@chakra-ui/react";
import { useAuth0 } from "@auth0/auth0-react";
import axios from "axios";
import React, {
  forwardRef,
  useContext,
  useEffect,
  useImperativeHandle,
  useState,
} from "react";
import { useStore } from "../../../store";
import {
  AddAssetsIcon,
  RealEstateIcon,
  InputBoxTitleBubbleTip,
  PersonalAssetsIcon,
  LifeInsuranceIcon,
  BusinessIcon,
  InheritanceIcon,
  CloseIcon,
} from "../../CustomIcons";
import GlobalBodyPage from "../../GlobalContainer/BodyPage";
import Link from "../../Link";
import TableComponent from "../../TableComponent";
import { StripeContext } from "../../StripeContext";
import StripeUpgradeModal from "../../StripeUpgradeModal";

const Assets = forwardRef((props, ref) => {
  const { user, getAccessTokenSilently } = useAuth0();

  useImperativeHandle(ref, () => ({
    submitHandler,
    checkMandatoryFields,
  }));

  const checkMandatoryFields = () => {
    return true;
  };

  const axiosInstance = axios.create();
  async function getToken() {
    // Replace this with your Auth0 token retrieval logic
    const token = await getAccessTokenSilently({
      audience:
        "https://os2lsiwff43wfpb5ggyawt6qri0mgcro.lambda-url.us-east-2.on.aws/",
      scope: "write:optimization",
    });
    return token;
  }

  // Add a request interceptor
  axiosInstance.interceptors.request.use(
    async (config) => {
      const token = await getToken();
      config.headers.Authorization = `Bearer ${token}`;
      return config;
    },
    (error) => {
      // Do something with request error
      return Promise.reject(error);
    }
  );
  const [isLargerThan768] = useMediaQuery("(min-width: 500px)");
  const isMobile = !isLargerThan768;

  const { stripePlan, setStripePlan } = useContext(StripeContext);

  const [realEstateArray, setRealEstateArray] = useState([]);
  const [lifeInsuranceArray, setLifeInsuranceArray] = useState([]);
  const [businessArray, setBusinessArray] = useState([]);
  const [otherAssetsArray, setOtherAssetsArray] = useState([]);
  const [inheritanceArray, setInheritanceArray] = useState([]);

  const [editing, setEditing] = useState(false);
  const [editingIndex, setEditingIndex] = useState(null);
  const [showDeleteModal, setShowDeleteModal] = useState(null);

  const userEditAssetInfo = useStore((store) => store.userEditAssetInfo);
  const setUserEditAssetInfo = useStore((store) => store.setUserEditAssetInfo);

  const userPrimaryResidenceInfo = useStore(
    (store) => store.userPrimaryResidenceInfo
  );
  const deleteUserPrimaryResidenceInfo = useStore(
    (store) => store.deleteUserPrimaryResidenceInfo
  );

  const userCottageInfo = useStore((store) => store.userCottageInfo);
  const deleteUserCottageInfo = useStore(
    (store) => store.deleteUserCottageInfo
  );

  const userPropertiesInfo = useStore((store) => store.userPropertiesInfo);
  const deleteUserPropertiesInfo = useStore(
    (store) => store.deleteUserPropertiesInfo
  );

  const userOtherAssetsInfo = useStore((store) => store.userOtherAssetsInfo);
  const deleteUserOtherAssetsInfo = useStore(
    (store) => store.deleteUserOtherAssetsInfo
  );

  const userLifeInsuranceInfo = useStore(
    (store) => store.userLifeInsuranceInfo
  );
  const deleteUserLifeInsuranceInfo = useStore(
    (store) => store.deleteUserLifeInsuranceInfo
  );

  const userInheritanceInfo = useStore((store) => store.userInheritanceInfo);
  const deleteUserInheritanceInfo = useStore(
    (store) => store.deleteUserInheritanceInfo
  );

  const userBusinessInfo = useStore((store) => store.userBusinessInfo);
  const deleteUserBusinessInfo = useStore(
    (store) => store.deleteUserBusinessInfo
  );

  const userCompanyInfo = useStore((store) => store.userCompanyInfo);
  const deleteUserCompanyInfo = useStore(
    (store) => store.deleteUserCompanyInfo
  );

  const userLandInfo = useStore((store) => store.userLandInfo);
  const deleteUserLandInfo = useStore((store) => store.deleteUserLandInfo);

  const setUserPageLockInfo = useStore((store) => store.setUserPageLockInfo);
  const userPageLockInfo = useStore((store) => store.userPageLockInfo);

  useEffect(() => {
    setUserEditAssetInfo({})
  }, [])

  useEffect(() => {
    const updatedRealEstateArray = [];
    const updatedOtherAssetArray = [];
    const updateInheritanceArray = [];
    const updateLifeInsuranceArray = [];
    const updateBusinessArray = [];

    setUserEditAssetInfo({})

    if (userPrimaryResidenceInfo[0] != null) {
      updatedRealEstateArray.push(userPrimaryResidenceInfo[0]);
    }

    if (userCottageInfo != null) {
      updatedRealEstateArray.push(...userCottageInfo);
    }

    if (userPropertiesInfo != null) {
      updatedRealEstateArray.push(...userPropertiesInfo);
    }

    if (userLandInfo != null) {
      updatedRealEstateArray.push(...userLandInfo);
    }

    if (userOtherAssetsInfo != null) {
      updatedOtherAssetArray.push(...userOtherAssetsInfo);
    }

    if (userInheritanceInfo != null) {
      updateInheritanceArray.push(...userInheritanceInfo);
    }

    if (userLifeInsuranceInfo != null) {
      updateLifeInsuranceArray.push(...userLifeInsuranceInfo);
    }

    if (userBusinessInfo != null) {
      updateBusinessArray.push(...userBusinessInfo);
    }

    if (userCompanyInfo != null) {
      updateBusinessArray.push(...userCompanyInfo);
    }

    setRealEstateArray(updatedRealEstateArray);
    setOtherAssetsArray(updatedOtherAssetArray);
    setInheritanceArray(updateInheritanceArray);
    setLifeInsuranceArray(updateLifeInsuranceArray);
    setBusinessArray(updateBusinessArray);
  }, [
    userPrimaryResidenceInfo,
    userCottageInfo,
    userPropertiesInfo,
    userLandInfo,
    userOtherAssetsInfo,
    userLifeInsuranceInfo,
    userBusinessInfo,
    userCompanyInfo,
    userInheritanceInfo,
  ]);

  const totalAssetsArray = [
    ...realEstateArray,
    ...lifeInsuranceArray,
    ...businessArray,
    ...otherAssetsArray,
    ...inheritanceArray,
  ];

  const submitHandler = () => {
    //Set Page Lock Info In Database:
    const pageLockURL = (action) =>
      `${
        process.env.REACT_APP_NODE_API_GATEWAY
      }/CompassLambdaApi/NEW_USER_PAGE_LOCK/${action}?PageLockID=${user.sub.replace(
        "|",
        "%7c"
      )}&IncomePage=${1}&InvestmentsPage=${1}&ExpensesPage=${1}`;
    axiosInstance
      .get(pageLockURL("add"))
      .then((res) => {
        //Unlock Income Sidebar:
        setUserPageLockInfo({
          ...userPageLockInfo,
          ExpensesPage: 1,
        });
        console.log(res);
        if (res.data.code === "ER_DUP_ENTRY") {
          axiosInstance.get(pageLockURL("update")).then((res) => {
            console.log(res);
            //Unlock Income Sidebar:
            setUserPageLockInfo({
              ...userPageLockInfo,
              ExpensesPage: 1,
            });
          });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [totalAssetsArray]);

  const Assets = [
    {
      title: "Real Estate",
      icon: RealEstateIcon,
      path: "/real-estate",
      stripe: true,
    },
    {
      title: "Life Insurance",
      icon: LifeInsuranceIcon,
      path: "/life-insurance-form",
      stripe: true,
    },
    {
      title: "Business",
      icon: BusinessIcon,
      path: "/business-page",
      stripe:
        stripePlan?.items?.data?.[0]?.price?.lookup_key === "PREMIUM" ||
        stripePlan?.items?.data?.[0]?.price?.lookup_key === "PREMIUM_M" ||
        stripePlan?.items?.data?.[0]?.price?.lookup_key === "PRO_M" ||
        stripePlan?.items?.data?.[0]?.price?.lookup_key === "PRO",
    },
    {
      title: "Personal Assets",
      icon: PersonalAssetsIcon,
      path: "/personal-assets-form",
      stripe: true,
    },
    {
      title: "Inheritance",
      icon: InheritanceIcon,
      path: "/inheritance-form",
      stripe: true,
    },
  ];

  const onDeleteRow = (i) => {
    setEditingIndex(i);
    setShowDeleteModal(true);
  };

  const editField = (i, array) => {
    const data = {
      list: array[i],
      editingIndex: i,
    };
    setUserEditAssetInfo(data);
    if (array[i].name === "Primary residence") {
      window.history.pushState({}, "", "/primary-residence-form");
      const navEvent = new PopStateEvent("popstate");
      window.dispatchEvent(navEvent);
    } else if (array[i].name === "Recreation property") {
      window.history.pushState({}, "", "/recreational-property-form");
      const navEvent = new PopStateEvent("popstate");
      window.dispatchEvent(navEvent);
    } else if (array[i].name === "Income property") {
      window.history.pushState({}, "", "/income-property-form");
      const navEvent = new PopStateEvent("popstate");
      window.dispatchEvent(navEvent);
    } else if (array[i].name === "Land") {
      window.history.pushState({}, "", "/land-property-form");
      const navEvent = new PopStateEvent("popstate");
      window.dispatchEvent(navEvent);
    } else if (
      array[i].name === "Term life insurance" ||
      array[i].name === "Whole life insurance" ||
      array[i].name === "Whole life - limited payments"
    ) {
      window.history.pushState({}, "", "/life-insurance-form");
      const navEvent = new PopStateEvent("popstate");
      window.dispatchEvent(navEvent);
    } else if (array[i].name === "Company") {
      window.history.pushState({}, "", "/business-form");
      const navEvent = new PopStateEvent("popstate");
      window.dispatchEvent(navEvent);
    } else if (array[i].name === "Investment Funds") {
      window.history.pushState({}, "", "/investment-funds-form");
      const navEvent = new PopStateEvent("popstate");
      window.dispatchEvent(navEvent);
    } else if (array[i].name === "Inheritance") {
      window.history.pushState({}, "", "/inheritance-form");
      const navEvent = new PopStateEvent("popstate");
      window.dispatchEvent(navEvent);
    } else {
      window.history.pushState({}, "", "/personal-assets-form");
      const navEvent = new PopStateEvent("popstate");
      window.dispatchEvent(navEvent);
    }
  };

  const DeleteRealEstateModal = () => {
    let activeArray = totalAssetsArray;

    const removeRealEstateModal = () => {
      if (activeArray[editingIndex].name === "Primary residence") {
        const deletePrimaryResUrl = (action) =>
          `${process.env.REACT_APP_NODE_API_GATEWAY}/CompassLambdaApi/REAL_ESTATE/${action}?Primary_Res_Number=${activeArray[editingIndex].Primary_Res_Number}`;
        axiosInstance.get(deletePrimaryResUrl("delete")).then((res) => {
          console.log("Delete res", res);
          deleteUserPrimaryResidenceInfo(
            activeArray[editingIndex].Primary_Res_Number
          );
          removeField(editingIndex, activeArray);
          setEditingIndex(null);
          setEditing(false);
        });
      } else if (activeArray[editingIndex].name === "Recreation property") {
        const deleteCottageURL = (action) =>
          `${process.env.REACT_APP_NODE_API_GATEWAY}/CompassLambdaApi/COTTAGE/${action}?Cottage_Number=${activeArray[editingIndex].Cottage_Number}`;
        axiosInstance.get(deleteCottageURL("delete")).then((res) => {
          console.log("Delete res", res);
          deleteUserCottageInfo(activeArray[editingIndex].Cottage_Number);
          removeField(editingIndex, activeArray);
          setEditingIndex(null);
          setEditing(false);
        });
      } else if (activeArray[editingIndex].name === "Income property") {
        const deletePropertyURL = (action) =>
          `${process.env.REACT_APP_NODE_API_GATEWAY}/CompassLambdaApi/PROPERTIES/${action}?Property_Number=${activeArray[editingIndex].Property_Number}`;
        axiosInstance.get(deletePropertyURL("delete")).then((res) => {
          console.log("Delete res", res);
          deleteUserPropertiesInfo(activeArray[editingIndex].Property_Number);
          removeField(editingIndex, activeArray);
          setEditingIndex(null);
          setEditing(false);
        });
      } else if (activeArray[editingIndex].name === "Land") {
        const deletePropertyURL = (action) =>
          `${process.env.REACT_APP_NODE_API_GATEWAY}/CompassLambdaApi/LAND/${action}?landNumber=${activeArray[editingIndex].landNumber}`;
        axiosInstance.get(deletePropertyURL("delete")).then((res) => {
          console.log("Delete res", res);
          deleteUserLandInfo(activeArray[editingIndex].landNumber);
          removeField(editingIndex, activeArray);
          setEditingIndex(null);
          setEditing(false);
        });
      }else if (
        activeArray[editingIndex].name === "Term life insurance" ||
        activeArray[editingIndex].name === "Whole life insurance" ||
        activeArray[editingIndex].name === "Whole life - limited payments"
      ) {
        const lifeInsuranceURL = (action) =>
          `${process.env.REACT_APP_NODE_API_GATEWAY}/CompassLambdaApi/LIFE_INSURANCE/${action}?Life_Insurance_Number=${activeArray[editingIndex].Life_Insurance_Number}`;
        axiosInstance.get(lifeInsuranceURL("delete")).then((res) => {
          console.log("Delete res", res);
          deleteUserLifeInsuranceInfo(
            activeArray[editingIndex].Life_Insurance_Number
          );
          removeField(editingIndex, activeArray);
          setEditingIndex(null);
          setEditing(false);
        });
      } else if (activeArray[editingIndex].name === "Inheritance") {
        const InheritanceURL = (action) =>
          `${process.env.REACT_APP_NODE_API_GATEWAY}/CompassLambdaApi/INHERITANCE/${action}?InheritanceNumber=${activeArray[editingIndex].InheritanceNumber}`;
        axiosInstance.get(InheritanceURL("delete")).then((res) => {
          console.log("Delete res", res);
          deleteUserInheritanceInfo(
            activeArray[editingIndex].InheritanceNumber
          );
          removeFieldInheritance(editingIndex, activeArray);
          setEditingIndex(null);
          setEditing(false);
        });
      } else if (activeArray[editingIndex].name === "Investment Funds") {
        const deletePropertyURL = (action) =>
          `${process.env.REACT_APP_NODE_API_GATEWAY}/CompassLambdaApi/BUSINESS/${action}?businessNumber=${activeArray[editingIndex].businessNumber}`;
        axiosInstance.get(deletePropertyURL("delete")).then((res) => {
          console.log("Delete res", res);
          deleteUserBusinessInfo(activeArray[editingIndex].businessNumber);
          removeField(editingIndex, activeArray);
          setEditingIndex(null);
          setEditing(false);
        });
      } else if (activeArray[editingIndex].name === "Company") {
        const deletePropertyURL = (action) =>
          `${process.env.REACT_APP_NODE_API_GATEWAY}/CompassLambdaApi/COMPANY/${action}?companyNumber=${activeArray[editingIndex].companyNumber}`;
        axiosInstance.get(deletePropertyURL("delete")).then((res) => {
          console.log("Delete res", res);
          deleteUserCompanyInfo(activeArray[editingIndex].companyNumber);
          removeField(editingIndex, activeArray);
          setEditingIndex(null);
          setEditing(false);
        });
      } else {
        const deleteOtherAssetURL = (action) =>
          `${process.env.REACT_APP_NODE_API_GATEWAY}/CompassLambdaApi/OTHER_ASSETS/${action}?Other_Assets_Number=${activeArray[editingIndex].Other_Assets_Number}`;
        axiosInstance.get(deleteOtherAssetURL("delete")).then((res) => {
          console.log("Delete res", res);
          deleteUserOtherAssetsInfo(
            activeArray[editingIndex].Other_Assets_Number
          );
          removeFieldOtherAsset(editingIndex, activeArray);
          setEditingIndex(null);
          setEditing(false);
        });
      }
    };

    if (!showDeleteModal) {
      return null;
    }
    const [yesClicked, setYesClicked] = useState(false);
    const [noClicked, setNoClicked] = useState(false);

    return (
      <div className="delete-income-modal" style={{
        display: "flex",
        alignItems: "center",
        width:"100%",
        maxWidth:"700px",
        justifyContent: "center",
        top: "50%",
        left: "50%",
        transform: isMobile
          ? "translate(-50%, -50%)"
          : "translate(-50%, -50%)",
      }} id="delete-income-modal">
          <Box
          display={"flex"}
          width={"100%"}
          padding={"10px 10px 0px 0px"}
          marginLeft={"5px"}
          alignItems={"flex-end"}
          justifyContent={"flex-end"}
          onClick={() => setShowDeleteModal(false)}
          cursor={"pointer"}
        >
          <CloseIcon
            onClick={() => setShowDeleteModal(false)}
            svgheight={"20px"}
            svgwidth={"20px"}
          />
        </Box>
        <Box
          display={"flex"}
          padding={"0px 25px 25px 25px"}
          flexDirection={"column"}
          alignItems={"center"}
          gap={"10px"}
          alignSelf={"stretch"}
          width={"100%"}
        >
          <Text
            color={"#0093D0"}
            fontFamily={"Nunito"}
            fontSize={"24px"}
            fontStyle={"normal"}
            fontWeight={"600"}
            lineHeight={"normal"}
            textDecor={"underline"}
          >
          Are you sure you want to remove this asset?
        </Text>
        <div className="content"></div>
        <div className="actions">
          <button  className={`decision-button yes-button ${
              yesClicked ? "yes-clicked" : ""
            }`}
            onClick={() => {
              setYesClicked(true);
              setNoClicked(false);
              removeRealEstateModal();
            }}>Yes</button>
          <button  className={`decision-button no-button ${
              noClicked ? "yes-clicked" : ""
            }`}
            onClick={() => {
              {
                setNoClicked(true);
                setYesClicked(false);
                setShowDeleteModal(false);
              }
            }}>No</button>
        </div>
        </Box>
      </div>
    );
  };

  const removeField = (i, array) => {
    array.splice(i, 1);
    setRealEstateArray((prevArray) => [...prevArray]);
    setShowDeleteModal(false);
  };

  const removeFieldOtherAsset = (i, array) => {
    array.splice(i, 1);
    setOtherAssetsArray((prevArray) => [...prevArray]);
    setShowDeleteModal(false);
  };

  const removeFieldInheritance = (i, array) => {
    array.splice(i, 1)
    setInheritanceArray((prevArray) => [...prevArray]);
    setShowDeleteModal(false)
  }

  const [showStripeModal, setShowStripeModal] = useState(false);

  function UpgradeStripeModal() {
    setShowStripeModal(!showStripeModal);
  }

  const columns = [
    { header: "Name", field: "name" },
    { header: "Category", field: "category" },
    { header: "Value", field: "value" },
  ];

  const data = totalAssetsArray.map((item) => ({
    name:
      item?.Cottage_Address || item?.businessName || item?.companyName || item?.landAddress || item?.inheritanceName
        ? item?.Cottage_Address || item?.businessName || item?.companyName || item?.landAddress || item?.inheritanceName
        : item.name,
    category:
      item?.name === "Primary residence" ||
      item?.name === "Recreation property" ||
      item?.name === "Income property" ||
      item?.name === "Land"
        ? "Real Estate"
        : [
            "Term life insurance",
            "Whole life insurance",
            "Whole life - limited payments",
          ].includes(item?.name)
        ? "Life Insurance"
        : item?.name === "Investment Funds" || item?.name === "Company"
        ? "Business"
        : item?.name === "Inheritance"
        ? "Inheritance"
        : "Personal Assets",
    value: item?.value || item?.Other_Asset_Value || item?.ccpcValue,
    link:
      item?.name === "Primary residence"
        ? "/primary-residence-form"
        : item?.name === "Recreation property"
        ? "/recreational-property-form"
        : item?.name === "Income property"
        ? "/income-property-form"
        : item?.name === "Land"
        ? "/land-property-form"
        : [
            "Term life insurance",
            "Whole life insurance",
            "Whole life - limited payments",
          ].includes(item?.name)
        ? "/life-insurance-form"
        : item?.name === "Investment Funds"
        ? "/investment-funds-form"
        : item?.name === "Company"
        ? "/business-form"
        : item?.name === "Inheritance"
        ? "/inheritance-form"
        : "/other-assets-page",
  }));

  return (
    <Flex
      display="flex"
      flexWrap="wrap" // Enable wrapping
      justifyContent="flex-start"
      alignItems="start"
      width={"100%"}
    >
      {!isMobile && (
        <VStack alignItems={"flex-start"} width={"100%"} mt={-2}>
          <GlobalBodyPage
            Heading="Assets"
            mainframeContent={
              <>
                <StripeUpgradeModal
                  isOpen={showStripeModal}
                  closeModal={UpgradeStripeModal}
                />
                <Box
                  display={"flex"}
                  width={"100%"}
                  padding={"10px"}
                  flexDirection={"row"}
                  gap={"14px"}
                  alignItems={"flex-start"}
                  alignContent={"flex-start"}
                  rowGap={"10px"}
                  alignSelf={"stretch"}
                  flexWrap={"wrap"}
                  flex={"1 0 auto"}
                  borderRadius={"10px"}
                  border={"1px solid var(--Optiml---Vibrant-Sky-Blue, #00A2D4)"}
                  bg={"#FFF"}
                >
                  <DeleteRealEstateModal />
                  {data.length > 0 && (
                    <TableComponent
                      columns={columns}
                      data={data}
                      editField={editField}
                      onDeleteRow={onDeleteRow}
                      rawData={totalAssetsArray}
                    />
                  )}
                  {/* Assets Box */}
                  {Assets.map((asset) => (
                    <Box
                      display={"flex"}
                      minH={"121px"}
                      padding={"6px"}
                      gap={"5px"}
                      flexDirection={"column"}
                      alignItems={"center"}
                      borderRadius={"10px"}
                      border={"1px solid #0093D0"}
                      bg={"#F3FAFF"}
                      boxShadow={"0px 4px 4px 0px #00A2D4"}
                    >
                      <Box
                        display={"flex"}
                        justifyContent={"flex-end"}
                        alignItems={"flex-start"}
                        gap={"10px"}
                        alignSelf={"stretch"}
                      >
                        <Tooltip label="Click to add you asset" placement="right" hasArrow>
                          <span>
                            <Icon
                              as={InputBoxTitleBubbleTip}
                              cursor={"pointer"}
                              w={"9px"}
                              h={"9px"}
                            />
                          </span>
                        </Tooltip>
                      </Box>
                      <Box
                        display={"flex"}
                        justifyContent={"center"}
                        alignItems={"end"}
                        w={"40px"}
                        h={"40px"}
                        overflow={"hidden"}
                        position={"relative"}
                      >
                        <Icon as={asset.icon} h={"100%"} w={"100%"} />
                      </Box>
                      <Box
                        display={"flex"}
                        width={"99px"}
                        height={"17px"}
                        flexDirection={"column"}
                        justifyContent={"center"}
                      >
                        <Text
                          color={"#00466D"}
                          fontFamily={"Nunito"}
                          fontSize={"13px"}
                          fontStyle={"normal"}
                          fontWeight={"700"}
                          lineHeight={"normal"}
                          textAlign={"center"}
                        >
                          {asset.title}
                        </Text>
                      </Box>

                      {/* Add Button Box */}
                      {asset.stripe && (
                        <Link href={asset.path}>
                          <Box
                            display={"flex"}
                            padding={"3px 20px"}
                            justifyContent={"center"}
                            alignItems={"center"}
                            gap={"6px"}
                            alignSelf={"stretch"}
                            borderRadius={"5px"}
                            border={"1px solid #00A2D4"}
                            bg={"#FFF"}
                            cursor={"pointer"}
                          >
                            <Icon as={AddAssetsIcon} w={"12px"} h={"12px"} />
                            <Text
                              color={"#00A2D4"}
                              fontFamily={"Nunito"}
                              fontSize={"10px"}
                              fontStyle={"normal"}
                              fontWeight={"700"}
                              lineHeight={"normal"}
                            >
                              Add
                            </Text>
                          </Box>
                        </Link>
                      )}
                      {!asset.stripe && (
                        <Box
                          display={"flex"}
                          padding={"3px 15px"}
                          justifyContent={"center"}
                          alignItems={"center"}
                          gap={"6px"}
                          alignSelf={"stretch"}
                          borderRadius={"5px"}
                          border={"1px solid #00A2D4"}
                          bg={"#FFF"}
                          cursor={"pointer"}
                          onClick={UpgradeStripeModal}
                        >
                          <Icon as={AddAssetsIcon} w={"12px"} h={"12px"} />
                          <Text
                            color={"#00A2D4"}
                            fontFamily={"Nunito"}
                            fontSize={"10px"}
                            fontStyle={"normal"}
                            fontWeight={"700"}
                            lineHeight={"normal"}
                          >
                            Upgrade
                          </Text>
                        </Box>
                      )}
                    </Box>
                  ))}
                </Box>
              </>
            }
            videoName={"Assets Page"}
            video={"Assets_Page.mp4"}
          />
        </VStack>
      )}
      {isMobile && (
        <VStack alignItems={"flex-start"} width={"100%"} mt={-2}>
          <GlobalBodyPage
            Heading="Assets"
            mainframeContent={
              <>
                <StripeUpgradeModal
                  isOpen={showStripeModal}
                  closeModal={UpgradeStripeModal}
                />
                <Box
                  display={"flex"}
                  width={"100%"}
                  padding={"10px"}
                  justifyContent={"center"}
                  alignItems={"flex-start"}
                  alignContent={"flex-start"}
                  rowGap={"10px"}
                  gap={"10px"}
                  alignSelf={"stretch"}
                  flexWrap={"wrap"}
                  flex={"1 0 auto"}
                  borderRadius={"10px"}
                  border={"1px solid var(--Optiml---Vibrant-Sky-Blue, #00A2D4)"}
                  bg={"#FFF"}
                >
                  <DeleteRealEstateModal />
                  {data.length > 0 && (
                    <TableComponent
                      columns={columns}
                      data={data}
                      editField={editField}
                      onDeleteRow={onDeleteRow}
                      rawData={totalAssetsArray}
                    />
                  )}
                  {/* Assets Box */}
                  {Assets.map((asset) => (
                    <Box
                      display={"flex"}
                      minH={"121px"}
                      padding={"10px"}
                      flexDirection={"column"}
                      alignItems={"center"}
                      gap={"5px"}
                      borderRadius={"10px"}
                      border={"1px solid #0093D0"}
                      bg={"#F3FAFF"}
                      boxShadow={"0px 4px 4px 0px #00A2D4"}
                    >
                      <Box
                        display={"flex"}
                        justifyContent={"flex-end"}
                        alignItems={"flex-start"}
                        gap={"10px"}
                        alignSelf={"stretch"}
                      >
                        <Tooltip label="Click to add your asset" placement="right" hasArrow>
                          <span>
                            <Icon
                              as={InputBoxTitleBubbleTip}
                              cursor={"pointer"}
                              w={"9px"}
                              h={"9px"}
                            />
                          </span>
                        </Tooltip>
                      </Box>
                      <Box
                        display={"flex"}
                        justifyContent={"center"}
                        alignItems={"center"}
                        w={"40px"}
                        h={"40px"}
                      >
                        <Icon as={asset.icon} />
                      </Box>
                      <Box
                        display={"flex"}
                        width={"99px"}
                        height={"17px"}
                        flexDirection={"column"}
                        justifyContent={"center"}
                      >
                        <Text
                          color={"#00466D"}
                          fontFamily={"Nunito"}
                          fontSize={"13px"}
                          fontStyle={"normal"}
                          fontWeight={"700"}
                          lineHeight={"normal"}
                          textAlign={"center"}
                        >
                          {asset.title}
                        </Text>
                      </Box>

                      {/* Add Button Box */}
                      {asset.stripe && (
                        <Link href={asset.path}>
                          <Box
                            display={"flex"}
                            padding={"3px 20px"}
                            justifyContent={"center"}
                            alignItems={"center"}
                            gap={"6px"}
                            alignSelf={"stretch"}
                            borderRadius={"5px"}
                            border={"1px solid #00A2D4"}
                            bg={"#FFF"}
                            cursor={"pointer"}
                          >
                            <Icon as={AddAssetsIcon} w={"12px"} h={"12px"} />
                            <Text
                              color={"#00A2D4"}
                              fontFamily={"Nunito"}
                              fontSize={"10px"}
                              fontStyle={"normal"}
                              fontWeight={"700"}
                              lineHeight={"normal"}
                            >
                              Add
                            </Text>
                          </Box>
                        </Link>
                      )}
                      {!asset.stripe && (
                        <Box
                          display={"flex"}
                          padding={"3px 15px"}
                          justifyContent={"center"}
                          alignItems={"center"}
                          gap={"6px"}
                          alignSelf={"stretch"}
                          borderRadius={"5px"}
                          border={"1px solid #00A2D4"}
                          bg={"#FFF"}
                          cursor={"pointer"}
                          onClick={UpgradeStripeModal}
                        >
                          <Icon as={AddAssetsIcon} w={"12px"} h={"12px"} />
                          <Text
                            color={"#00A2D4"}
                            fontFamily={"Nunito"}
                            fontSize={"10px"}
                            fontStyle={"normal"}
                            fontWeight={"700"}
                            lineHeight={"normal"}
                          >
                            Upgrade
                          </Text>
                        </Box>
                      )}
                    </Box>
                  ))}
                </Box>
              </>
            }
            videoName={"Assets Page"}
            video={"Assets_Page.mp4"}
          />
        </VStack>
      )}
    </Flex>
  );
});

export default Assets;
