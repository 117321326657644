import React, { useState, useEffect } from "react";
import { Box, Heading, Text, CircularProgress } from "@chakra-ui/react";

const LoadingCards = ({ cards, interval = 10000 }) => {
    const [currentCard, setCurrentCard] = useState(0);
    const [opacity, setOpacity] = useState(1);
    const [shuffledCards, setShuffledCards] = useState([]);

    useEffect(() => {
      const shuffleCards = (cards) => {
        const firstThreeCards = cards.slice(0, 3); // Slicing the first three cards
        const otherCards = cards.slice(3); // Slicing the rest of the cards

        // Shuffle the remaining cards
        for (let i = otherCards.length - 1; i > 0; i--) {
          const j = Math.floor(Math.random() * (i + 1));
          [otherCards[i], otherCards[j]] = [otherCards[j], otherCards[i]];
        }

        return [...firstThreeCards, ...otherCards]; // Concatenating the first three cards with the shuffled cards
      };

      setShuffledCards(shuffleCards(cards));
    }, [cards]);

    useEffect(() => {
      const intervalId = setInterval(() => {
        setOpacity(0); // Fade out the card

        setTimeout(() => {
          setCurrentCard(prevCard => {
            // Move to the next card, and loop back after showing all cards
            const nextCardIndex = (prevCard + 1) % shuffledCards.length;
            // Ensure first three cards are shown sequentially first
            return nextCardIndex === 3 ? 3 + Math.floor(Math.random() * (shuffledCards.length - 3)) : nextCardIndex;
          });
          setOpacity(1); // Fade in the new card
        }, 500); // Half the interval to switch card at halfway point of fade
      }, interval);

      return () => clearInterval(intervalId);
    }, [shuffledCards, interval]);

    const card = shuffledCards[currentCard];

    return (
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        width="100%"
        p="20px"
        borderRadius="5px"
        border="1px solid #00A2D4"
        bg="#F3FAFF"
        opacity={opacity}
        transition="opacity 0.5s ease-in-out"
      >
        <Heading
          color="#00A2D4"
          fontSize="24px"
          fontWeight="bold"
          lineHeight="1.2"
          mb="4"
        >
          {card?.title}
        </Heading>
        <Text
          color="#00A2D4"
          fontFamily="Nunito"
          fontSize="16px"
          fontWeight="600"
          lineHeight="1.5"
          mb="4"
          textAlign={"center"}
        >
          {card?.message}
        </Text>
        <CircularProgress isIndeterminate color="blue.300" />
      </Box>
    );
};

export default LoadingCards;
