// tooltips.js
const TooltipContent = {
  startedCPPPayments: `This information can be found on your My Service Canada Account. If you haven't started collecting CPP, you don't need to input the exact number right now. You can select 'No' and input an average estimate. Once you have this information, you can update your profile at any time. `,
  monthlyCPPPayments:
    "This information can be found on recent bank statements or by logging into your My Service Canada Account.",
  useCPPAverageYesNo:
    "If you are unsure about what your CPP monthly payments will be you can use the monthly average. Otherwise enter your custom amount.",
  enterMonthlyCPPPayout: "Enter your monthly CPP payout.",
  havePensionYesNo:
    "If you are uncertain whether or not you are a member of an employer pension plan, you can review any HR documents from your employer, or reach out to your HR department for clarification.",
  pensionPlanType:
    "If you are unsure about the type of plan your employer offers, you can usually find this information in your plan documents or by contacting your HR department or plan administrator.",
  pensionPlanUncertain:
    "If you are uncertain, you can usually find this information in your plan documents or by contacting your HR department or plan administrator.",
  pensionPlanStartCollecting:
    "The year that you will start receiving your pension is most likely the year you retire. You can usually find this information in your plan documents or by contacting your HR department or plan administrator. If you are already receiving your pension, select Already Receiving.",
  pensionEmployerContribution:
    "Some employers will match a percentage of the pension contributions of their employees. If you are uncertain whether or not this applies to you, you can usually find this information in your plan documents or by contacting your HR department or plan administrator.",
  pensionGrowth:
    "How much do you anticipate your pension fund to grow by annually?",
  pensionIndex:
    "If your pension is indexed, that means it will increase by CPI (~2%) annually when you start receiving it. If your pension is going to stay fixed annually then select No.",
  startedOASPayments:
    "To receive the full Old Age Security (OAS) amount, you need to have lived in Canada for 40 years between the ages of 18 and 65. If you haven't met this requirement, don't worry – you can still qualify for OAS benefits based on the number of years you've lived in Canada. Simply select 'No' and specify the number of years you have lived or will have lived in Canada between ages 18 and 65.",
  startedOASThisYear:
    "If you started receiving your OAS benefits this year, select 'Yes'.",
  startedOASMonth:
    "Select the month in which you started to receive your OAS benefits.",
  monthlyOASPayments:
    "This information can typically be found in your Service Canada Account (MSCA) or via your bank statements.",
  yearsLivedCanadaOAS:
    "Enter the number of years you will have lived in Canada for by the time you turn 65 (only include years in which you were above the age of 18).",
    otherIncomeYesNo: `Do you have additional income sources apart from your primary salary? This includes secondary employment, annuities, pensions from other countries, other pensions (e.g., disability, survivors), or lump-sum payments. Note: Income from business ownership or rental properties is accounted for in the 'Investments/Assets' section.`,
  otherIncomeName:
    "In this field, you can enter a name or title for your income source. This is for your reference and will help you identify the income when you need to access or manage it later.",
  otherIncomeFrequency:
    "If the income is a one-time payment, whether this year or in the future, choose 'one-time payment'. If you anticipate this income will occur on a yearly basis, please select 'annual'.",
  otherIncomeAmount: "Please input the total pre-tax annual amount.",
  otherIncomeStartAge:
    "The default age is set to your current age, indicating that you are currently receiving this income?. If you anticipate a future lump sum or annual income, please select the age at which you expect to start receiving this income?.",
  otherIncomeStopAge:
    "If you are uncertain of when this income may stop, please input an estimated age as a placeholder. You can adjust or update this information at any time.",
  otherIncomeGrowth:
    "Do you expect this income to increase annually? If so, the default growth rate aligns with the Consumer Price Index (CPI). If not, input 0% for no growth. You can adjust this rate as necessary.",
  employmentIncomeYesNo:
    "If you are currently an employee or self-employed and receive a salary, please select 'Yes.' If you are retired, or not currently employed, please select 'No'.",
  employmentType:
    "If you work for a company and receive a regular salary, choose 'Employee'. If you work for yourself, select 'Self-Employed'.",
  employmentIncomeAmount: "Input your pre-tax annual income.",
  employmentIncomeDeductions:
    "Please input the dollar amount you can deduct from your taxable income as a self-employed individual.",
  employmentIncomeInsuranceYesNo:
    "Please indicate whether or not you participate in the Employment Insurance (EI) program.",
  employmentRetirementAge:
    "Select your planned age of retirement. You can change this at anytime and run a new analysis.",
  employmentIncomeGrowth:
    "Please select your anticipated annual salary growth rate for the analysis. Typically, salaries grow by the Consumer Price Index (CPI). You can adjust this rate as needed.",
  rrspYesNo:
    "An RRSP (Registered Retirement Savings Plan) is a savings account in Canada that helps you save for retirement by letting you delay paying taxes on the money you put in and any earnings it makes until you take the money out. An RRIF (Registered Retirement Income Fund) is what your RRSP can be turned into when you're ready to start taking money out during retirement, providing you with regular payments and still offering some tax benefits. If you do not currently have a RRSP account, you can simply select 'No'. Please note the analysis may suggest opening a RRSP, as it can be a valuable investment vehicle for achieving your estate goals in a tax-efficient manner.",
  rrspToRRIF:
    "You may have converted your RRSP to a RRIF to start drawing down a minimum percentage annually. Although this is not required until age 71, some choose to do so. If you have select yes, if not select no.",
  rrspTotalValue:
    "You can find this information by checking your online banking or contacting your financial advisor. If you have multiple RRSP accounts, please enter their combined total value.",
  rrspContributionsThisYear:
    "Please input the total amount you've contributed to your RRSP during the current calendar year up to the present date. If you have not contributed to your RRSP this year, please input 0, or leave this field blank.",
  rrspEmployerContributionYesNo:
    "If you are unsure if your employer contributes to your RRSP, you can confirm this by reviewing your pay stubs, checking your bank deposit history, or consulting your HR department.",
  rrspEmployerContribution:
    "Employer contribution structures typically range between 3% and 6% of an employee's salary. If you are uncertain, you can verify this by reviewing your pay stubs, checking your bank deposit history, or consulting your HR department.",
  rrspGrowth:
    "Based on your RRSP's historical growth and your future expectations, please enter an annual growth rate. This rate represents how much your RRSP is projected to grow each year for the length of the analysis.",
  debtYesNo:
    "Here, you may enter car loans, student debt, lines of credit, and more. If you have any outstanding debts you'd like to include in your analysis, you can add them here.",
  debtName:
    "In this field, you can enter a name or title for your debt. This is for your reference and will help you identify the debt.",
  debtBalance: "Please enter the current outstanding balance of your debt.",
  debtMinimumPayment:
    "Please input the minimum monthly payments you contribute toward your debt.",
  debtInterest:
    "Interest on debt refers to the percentage you're charged for borrowing money. If you're uncertain about the exact rate, it can be found on your loan statements or agreements.",
  expenseAmount:
    "Your annual after-tax expenses include your day-to-day spending on groceries, utilities, fuel, holidays, and regular expenses. Do not include any deposits into your investment accounts, as this is only for household and personal expenses.",
  expenseGrowth:
    "Two methods are available to forecast expense growth in your analysis. Standard growth rate simulates the growth of your expenses annually by your inputted growth value. The Consumer Expense Index will grow your expenses relative to your salary and age in a custom Optiml model.",
  expenseCPI:
    "Whether you choose to project the growth of your expenses using Standard growth rate or the Consumer Expense Index, both models rely on a value for growth. By default, the historical value of CPI is displayed. However, you have the flexibility to manually override this setting.",
  ageToRun:
    "This question refers to the specific age at which you would like the financial analysis to project your financial situation. Your financial plan will start at your current age, and end at the age that you input.",
  tfsaYesNo:
    "If you do not currently have a TFSA account, select 'No'. Please note the analysis may suggest opening a TFSA, as it is a valuable investment vehicle for achieving your estate goals in a tax-efficient manner.",
  tfsaTotalValue:
    "You can find this information by checking your online banking or contacting your financial advisor. If you have multiple TFSA accounts, please input their combined total value.",
  contThisYear: "Please input the amount you have deposited into your account this year. If you are unsure of the exact number, an estimate will help us determine how much of you annual income has already been used towards your savings this year.",
  tfsaContributionRoomYesNo:
    "If you haven't maximized your TFSA contributions, you may have unused contribution room. You can find this information by logging into your My Service Canada Account.",
  tfsaContributionRoom:
    "Please indicate the total value of your unused contribution room.",
  tfsaGrowth:
    "Based on your TFSA's historical growth and your future expectations, please enter the annual growth rate. This rate represents how much your TFSA is projected to grow each year for the length of the analysis.",
  otherRegisteredAccountsDropdown:
    "At this stage, the LIRA is the only additional registered investment vehicle available to select. If you have a Registered account not listed in the drop down menu, we encourage you to email us and request it to be added. Your feedback will help us improve our service.",
  liraDoYouYesNo:
    "Please indicate whether you have a Locked in retirement account, or a Life Income Fund. Typically you will have one of these after leaving a job where you had a pension fund. If you do not then select No.",
  liraLifYesNo:
    "If you're unsure whether your LIRA has been converted to a Life Income Fund (LIF), you can check your account statements from your financial institution or pension plan administrator. The conversion typically occurs as you approach retirement age, and you should receive documentation indicating the change.",
  liraLifValue:
    "The balance of your LIRA/LIF can be found using your online banking or by checking recent statements. Please provide the accurate and up-to-date total value.",
  liraLifGrowth:
    "Based on the historical growth of your LIRA/LIF and your future expectations, please enter the annual growth rate. This rate represents how much your LIRA/LIF is projected to grow each year for the length of the analysis.",
  nonregYesNo:
    "Non-registered accounts are all other investments you have that are not held within registered accounts, such as your TFSA and RRSP. These accounts are subject to different tax treatment, and it's important to include them for a comprehensive financial analysis.",
  nonregTotalValue:
    "If you have multiple investments in your non-registered accounts, please enter their total combined value.",
  nonregACB:
    "The Adjusted Cost Base (ACB), often called Book Value, is the initial purchase value of your investments. To find this, check your online banking statements or consult your financial advisor. Please note, just as you totalled your non-registered investments, you will need to enter the total (ACB) of all your investments. If unavailable to locate this information, a provisional measure is to use 80% of your current non-registered investment value. This is a placeholder to move forward with your analysis. When you are able to access accurate figures, update the (ACB) and run a revised analysis.",
  nonregGrowth:
    "Based on the historical growth of your non-registered account(s) and your future expectations, please enter the annual growth rate. This rate represents how much your non-registered investments are projected to grow each year for the length of the analysis. This growth includes any reinvested dividends.",
  nonregIncomeBearing:
    "Are any of your non-registered investments dividend-bearing? If you are uncertain, you can verify this by checking your online banking statements.",
  nonregDividendsEligible:
    "Eligible dividends are those paid by Canadian corporations and typically receive favorable tax treatment. Publicly traded companies often distribute eligible dividends, which can benefit from tax credits. To find the eligible annual dividend amount, review your investment statements or tax documents. These dividends are often labeled as 'eligible' on your statements. Please input the $ value of all eligible dividends below.",
  nonregDividendsNonEligible:
    "Non-eligible dividends are typically paid by Canadian corporations but don't qualify for the same tax advantages as eligible dividends. Generally speaking, private companies' dividends are classified as non-eligible. You can locate the non-eligible annual dividend amount on your investment statements or tax documents. These dividends are often labeled as 'non-eligible' on your statements. Please input the $ value of all non-eligible dividends below.",
  nonregDividendsForeign:
    "Foreign dividends come from international investments. To determine the foreign annual dividend amount, review your investment statements or tax documents. These dividends are typically reported separately from Canadian dividends and are labeled as 'foreign' or 'international' on your statements. Please input the $ value of all foreign dividends below.",
  primaryResValue:
    "Input the current value of your home. If you don't know the value of your home, you can input an educated estimate. You have the flexibility to update this data at any time to ensure the accuracy of your financial analysis.",
  primaryResMonthlyMortgage:
    "The 'Monthly mortgage payment' is the amount you pay each month toward your home loan. You can find this figure on your bank statements, mortgage documents, or your online banking portal under the mortgage section.",
  primaryResMortgageBalance:
    "‘Remaining mortgage balance’ refers to the amount you still owe on your mortgage. You can find this information on your mortgage statement, usually available through your online mortgage account or via mailed statements from your mortgage provider.",
  primaryResInterestRate:
    "To find your current mortgage interest rate, you can refer to your most recent mortgage statement, your mortgage contract, or your online banking account with your mortgage lender.",
  primaryResGrowth:
    "The growth rate refers to how you anticipate the value of your property to grow each year",
  primaryResSaleYesNo:
    "Whether you're considering selling your home in the future or simply want to understand how its sale might impact your financial picture, providing this information helps us consider the potential liquidation of the property in your financial analysis. If you're not planning to sell right now, simple select 'no'. You can update this at any time to reflect changes in your plans.",
  primaryResSaleAge:
    "If you're unsure about the exact time you may sell, you can provide an estimated year. You can update the sale year at any time to align with your changing plans.",
  recPropertyAddress:
    "The address is simply for your reference and helps you identify the asset when you need to access or manage it later.",
  recPropertyValue:
    "Input the current value of your recreational property. If you don't know the value, you can input an educated estimate based on available resources. You have the flexibility to update this data at any time to ensure the accuracy of your financial analysis.",
  recPropertyGrowth:
    "Input the anticipated annual growth of your recreational property value.",
  recPropertyMonthlyMortgage:
    "To determine your current monthly mortgage payments, you can review your recent bank statements or your mortgage lender's online portal.",
  recPropertyMortgageBalance:
    "‘Remaining mortgage balance’ refers to the amount you still owe on your mortgage. You can find this information on your mortgage statement, usually available through your online mortgage account or via mailed statements from your mortgage provider.",
  recPropertyInterestRate:
    "To find your current mortgage interest rate, you can refer to your most recent mortgage statement, your mortgage contract, or your online banking account with your mortgage lender.",
  recPropertySaleYesNo:
    "Whether you're considering selling your recreation property in the future or simply want to understand how its sale might impact your financial picture, providing this information helps us consider the potential liquidation of the property in your financial analysis. If you're not planning to sell right now, simple select 'no'. You can update this at any time to reflect changes in your plans.",
  recPropertySaleAge:
    "If you're unsure about the exact time you may sell, you can provide an estimated year. You can update the sale year at any time to align with your changing plans.",
  recPropertyPurchasePrice:
    "Please enter the price at which you purchased this recreation property.",
  recPropertyCapitalImprovements:
    "Please enter the total value of any capital improvements that have been made to this property.",
  incomePropertyAddress:
    "The address is simply for your reference and helps you identify the asset when you need to access or manage it later.",
  incomePropertyValue:
    "If you don't know the value of your income property, you can input an educated estimate based on available resources. You have the flexibility to update this data at any time to ensure the accuracy of your financial analysis. If your property is worth $1,000,000 and you own 50%, then enter $500,000.",
  incomePropertyEBITDA:
    "Annual EBITDA stands for 'Earnings Before Interest, Taxes, Depreciation, and Amortization' for a full year.",
  incomePropertyGrowth:
    "The growth rate refers to how you anticipate the value of your income property and EBITDA to grow each year",
  incomePropertyOwnership:
    "Input your ownership %  of the income property. If you're conducting the analysis with your spouse, please provide the combined total ownership.",
  incomePropertyMortgageBalance:
    "‘Remaining mortgage balance’ refers to the amount you still owe on your mortgage. You can find this information on your mortgage statement, usually available through your online mortgage account or via mailed statements from your mortgage provider.",
  incomePropertyInterestRate:
    "To find your current mortgage interest rate, you can refer to your most recent mortgage statement, your mortgage contract, or your online banking account with your mortgage lender.",
  incomePropertyMonthlyMortgage:
    "To determine your current monthly mortgage payments, you can review your recent bank statements or your mortgage lender's online portal.",
  incomePropertyPurchasePrice:
    "Please enter the price at which you purchased this income property.",
  incomePropertyCapitalImprovements:
    "Please enter the total value of any capital improvements that have been made to this property.",
  incomePropertyUCC:
    "'Undepreciated Capital Cost' (UCC) signifies the remaining value of the property that hasn't yet been entirely depreciated for tax purposes, impacting annual depreciation expenses and the property's overall value assessment. You can find this information by reviewing your previous tax return.",
  incomePropertySaleYesNo:
    "Whether you're considering selling your income property in the future or simply want to understand how its sale might impact your financial picture, providing this information helps us consider the potential liquidation of the property in your financial analysis. If you're not planning to sell right now, simple select 'no'. You can update this at any time to reflect changes in your plans.",
  incomePropertySaleAge:
    "If you're unsure about the exact time you may sell, you can provide an estimated year. You can update the sale year at any time to align with your changing plans.",
  incomePropertyPreTaxProceedsEstimate:
    "This value is the estimated pre-tax proceeds that will result from selling this property in the year specified.",
  landAddress: "What is the address for your land property",
  landValue: "Please enter the current value of your piece of land. If your land is worth $200,000 and you own 50%, then enter $100,000.",
  landGrowth: "By what percentage will the value of your land grow annually?",
  landOwnership:
    "If you own this entire piece of land on your own or with your spouse enter 100%. Otherwise please indicate what portion of the property you own.",
  landPurchasePrice:
    "Please enter how much you originally paid for this piece of land.",
  landCapitalImprovements:
    "If you have put any money or capital improvement into the land to increase the value, please indicate that amount here. ",
  landMortgage: "How much do you still owe on this piece of land if any?",
  landPayment: "How much is your monthly payment for this piece of land?",
  landMortgageRate: "What is the interest rate on your land debt loan?",
  landPlanToSell:
    "Do you have any plans to sell this land in the future? If so select yes and estimate when you may sell this land.",
  landSellYear:
    "When do you believe you will sell this land? If you don't have a specific date yet, you can estimate and then enter a more accurate date in the future.",
  lifeInsuranceType:
    "Please select your life insurance policy type. If you are unsure about your policy type, you can typically find this information by contacting your insurance provider, reviewing your policy documents, or using your insurance provider's website or app.",
  lifeInsuranceValue:
    "Please enter the value of your life insurance policy. If you are unsure about your policy type, you can typically find this information by contacting your insurance provider, reviewing your policy documents, or using your insurance provider's website or app.",
  lifeInsurancePremiums:
    "Please select your monthly life insurance premiums. If you are unsure about your policy type, you can typically find this information by contacting your insurance provider, reviewing your policy documents, or using your insurance provider's website or app.",
  lifeInsuranceTermEnd:
    "Please enter the term end date for your life insurance policy. If you are unsure about your policy type, you can typically find this information by contacting your insurance provider, reviewing your policy documents, or using your insurance provider's website or app.",
  lifeInsurancePaymentEnd:
    "Please enter the term end date for your life insurance policy. If you are unsure about your policy type, you can typically find this information by contacting your insurance provider, reviewing your policy documents, or using your insurance provider's website or app.",
  otherAssetName:
    "In this field, you can enter a name or title for your asset. This is for your reference and will help you identify the asset as needed.",
  otherAssetValue: "Please enter the current value of the asset.",
  otherAssetGrowth:
    "The growth rate refers to how you anticipate the value of your asset to grow each year.",
  fixedIncomeYesNo:
    "A fixed income investment account consists of securities that provide regular, predictable returns through interest payments, such as government and corporate bonds. These investments are favored for their lower risk and steady income generation, making them suitable for risk-averse investors and those seeking stability in their financial planning.",
  fixedIncomeName: "Type of fixed income such as GIC, Bond, etc",
  fixedIncomePrincipleAmount: "The principal amount of a GIC/fixed income account is the initial sum of money you invest when you open the account.",
  fixedIncomeInterest: "Growth that the account will receive each year.",
  fixedIncomeInterestType:
    "Does the interest compound annually, or will the dollar value amount growth be the same each year?",
  fixedIncomeMaturityDate:
    "The date your fixed income account will close and be cashed out.",
    fixedIncomeStart:
    "The date you opened and began your fixed income or GIC account. This information ensures tax accuracy by determining the growth of your principal amount and the taxes you’ve already paid.",
  cashYesNo:
    "Do you have an non invested cash savings you want to include in your analysis? This can be in your checking or savings account.",
  cashValue: "Total value of all your cash savings balances.",
  cashMinimumAmount:
    "Indicate the minimum cash savings you always want to store, this will ensure that we do not use these funds to spend or invest.",
  inheritanceValue:
    "Add your projected value of what you think your total inheritance will be.",
  inheritanceStart: "When do you anticipate to receive this inheritance?",
  companyName: "Enter the operating companies name here.",
  companyValue:
    "What is the current value of your business. If you don't have a specific number please estimate what it would be worth if you were to sell the business today. If your Business is worth $1,000,000 and you own 50%, then enter $500,000.",
  companyOwnership:
    "If this is 100% your company or yours and your spouses company leave this it 100%. Lower this percentage if you have other outside company investors to accurately portray your ownership.",
  companyGrowth:
    "Please estimate your anticipated annual growth of your company value.",
  companyDividends:
    "A annual dividend from this operating company is different from a T4 salary you might take as income from the business. Dividends would be eligible or non eligible cash received from the business's generated revenue.",
  companyEligibleDividends:
    "An eligible dividend comes from a smaller privately owned canadian company.",
  companyNonEligibleDividend:
    "An non-eligible dividend is also from a Canadian company, but from a larger publicly traded company.",
  companyDividendGrowth:
    "By how much do you think these dividend payouts will increase annually?",
  companySellYesNo:
    "If you anticipate to ever sell your business select Yes, if not select No.",
  companySellYear:
    "Please choose which year you expect to sell this business. If you are unsure, please estimate and then update when you have more accurate information.",
  companySellValue:
    "Please indicate what you think you will receive in after tax dollars from the sale of this business.",
  holdingName:
    "If you holding company or CCPC investment funds account has a name please enter that here.",
  holdingProvince:
    "Select the province or territory you CCPC investments funds are registered.",
  holdingValue: "What is the current value of the investments in your fund?",
  holdingACB:
    "What is the book value or adjusted cost base, meaning the original price you bought in at for all the investments in this fund? If you are unsure, we recommend using a value of 80-90% of the funds total value until you can get more accurate information.",
  holdingGrowth:
    "What will this funds annual growth be? Similar to the growth rate asked for your other investment accounts, this is the growth of those investments held in the CCPC.",
  holdingIncomeBearingYesNo:
    "Are any of the investments held in your CCPC income bearing, meaning do they payout a dividend each year?",
  holdingEligibleDividendIncome:
    "Eligible dividends are those paid by Canadian corporations and typically receive favorable tax treatment. Publicly traded companies often distribute eligible dividends, which can benefit from tax credits. To find the eligible annual dividend amount, review your investment statements or tax documents. These dividends are often labeled as 'eligible' on your statements. Please input the $ value of all eligible dividends below.",
  holdingNonEligibleDividendIncome:
    "Non-eligible dividends are typically paid by Canadian corporations but don't qualify for the same tax advantages as eligible dividends. Generally speaking, private companies' dividends are classified as non-eligible. You can locate the non-eligible annual dividend amount on your investment statements or tax documents. These dividends are often labeled as 'non-eligible' on your statements. Please input the $ value of all non-eligible dividends below.",
  holdingForeignDividendIncome:
    "Foreign dividends come from international investments. To determine the foreign annual dividend amount, review your investment statements or tax documents. These dividends are typically reported separately from Canadian dividends and are labeled as 'foreign' or 'international' on your statements. Please input the $ value of all foreign dividends below.",
  holdingReinvest:
    "This would include any funds from operations that you plan to re-invest into this investment account annually.",
  holdingReinvestGrowth:
    "As you continue to reinvest funds from operations back into this holding investment fund, by how much will this increase annually?",
  holdingERDTOH:
    "To calculate the RDTOH at the end of the tax year, add up the following amounts: the RDTOH balance at the end of the previous tax year (minus any dividend refund issued to the corporation in the previous year); the refundable portion of Part I tax from line 450; Part IV tax calculated on line 360 of Schedule 3; and any balance of RDTOH transferred from a predecessor corporation on amalgamation, or from a wound-up subsidiary corporation. The RDTOH at the end of the tax year is from line 485 of the T2 Return corresponding to the tax year end.",
  holdingGRIP:
    "The GRIP is a balance that generally reflects taxable income that has not benefited from the small business deduction or any other special tax rate. Use Schedule 53 on line 590, General Rate Income Pool (GRIP) Calculation, to determine the GRIP and file it with your T2 return. You should file this schedule if you paid an eligible dividend in the tax year, or if your GRIP balance changed, to ensure that the GRIP balance on our records is correct.",
  holdingCDA:
    "A CDA is a notional account that keeps track of various tax-free surpluses accumulated by a small business designated as a Canadian controlled private corporation, or CCPC. Those surpluses can be paid out as tax-free capital dividends to shareholders. A corporation’s CDA balance can include: Capital gains and losses, Dividends received from another company, Proceeds from a life insurance policy in excess of the Adjusted Cost Base of the policy, Gains and losses on the sale of some fixed assets.",
  holdingLoanYesNo:
    "Do you personally or your spouse have a loan to the corporation? If you do select yes and input the amount so that the repayment of this loan will be factored in to the optimization.",
  holdingLoan:
    "Please enter how much you currently have outstaring as a loan to the fund.",
  goalName:
    "Please enter a specific name for this goal. Although you may have assigned a category to this goal, it is great to differentiate in the optimization. Think places for travel or the specific good/service.",
  goalAmount:
    "Enter your planned or desired spend for your goal. Our optimization will do what it can to ensure you achieve this goal if it is possible!",
  goalStart:
    "When or by what age are you planning to have this goal achieved by? The Optiml tool will make it happen during this year!",
  goalEnd:
    "How long will you be budgeting for this education spend? By what age or date will you no longer require this additional spend?",
  fhsaYesNo:
    "Please select whether you have a First Home Savings Account currently opened.",
  fhsaYearOpened:
    "Please select when you opened the FHSA. The FHSA first came to be in 2023.",
  fhsaValue:
    "What is the total value of your FHSA. If you have multiple please sum up the values.",
  fhsaDoUnusedRoom:
    "You are currently allowed to contribute $8,000 a year into the FHSA, and your carry over room can move forward for 2 years. Meaning the most contribution room you could have is $16,000 if you haven't contributed in 2 years.",
  fhsaUnusedRoom: "Please enter the room up to $16,000.",
  fhsaGrowth: "What do you anticipate the growth of your FHSA to be annually?",
  fhsaPurchaseHomeYear:
    "The FHSA is primarily used for purchasing your first home. When do you want to purchase this home? ",
  fhsaHomeValue:
    "Please estimate what you believe the value of your first home to be. Our Optiml engine will use this account along with your other accounts to try and simulate purchasing your first home in this analysis.",
  fhsaRent:
    "To accurately simulate the purchase of this home we will reduce your expenses by your rent in the year of home purchase, and then increase it by the calculated mortgage and other costs that will come along with home purchase.",
  fhsaEligible:
    "Even though you don't have a First Home Savings Account, you may still be eligible to open one. If you already own a home you are not eligible, otherwise select Yes.",
  fhsaPurchaseHomeGoal:
    "Just because you are eligible to open a FHSA doesn't mean you wish to purchase a home. If you don't wish to ever buy a home we can still use this account to offset your taxes similar to a RRSP. But if you do wish to purchase a home some day please select Yes.",
};

export default TooltipContent;
