import axios from "axios";
import React, {
  forwardRef,
  useContext,
  useEffect,
  useImperativeHandle,
  useState,
} from "react";
import { useStore } from "../../../store";
import { useAuth0 } from "@auth0/auth0-react";
import { Flex, VStack, Box, Text, useMediaQuery } from "@chakra-ui/react";
import GlobalBodyPage from "../../GlobalContainer/BodyPage";
import DropDownButton from "../../InputFields/dropDownButton";
import NormalInputButton from "../../InputFields/normalInputButton";
import TableComponent from "../../TableComponent";
import TooltipContent from "../../TooltipContent";
import { CloseIcon } from "../../CustomIcons";

const IncomePropertyPage = forwardRef((props, ref) => {
  const { getAccessTokenSilently, user } = useAuth0();

  useImperativeHandle(ref, () => ({
    submitHandler,
    checkMandatoryFields,
  }));

  const submitHandler = () => {
    setUserEditAssetInfo({});
    return true;
  };

  const checkMandatoryFields = () => {
    return true;
  };

  const axiosInstance = axios.create();
  async function getToken() {
    // Replace this with your Auth0 token retrieval logic
    const token = await getAccessTokenSilently({
      audience:
        "https://os2lsiwff43wfpb5ggyawt6qri0mgcro.lambda-url.us-east-2.on.aws/",
      scope: "write:optimization",
    });
    return token;
  }

  // Add a request interceptor
  axiosInstance.interceptors.request.use(
    async (config) => {
      const token = await getToken();
      config.headers.Authorization = `Bearer ${token}`;
      return config;
    },
    (error) => {
      // Do something with request error
      return Promise.reject(error);
    }
  );
  const userEditAssetInfo = useStore((store) => store.userEditAssetInfo);
  const setUserEditAssetInfo = useStore((store) => store.setUserEditAssetInfo);

  const [incomePropertyTitle, setIncomePropertyTitle] = useState("");
  const [incomePropertyValue, setIncomePropertyValue] = useState(0);
  const [incomePropertyNetIncome, setIncomePropertyNetIncome] = useState(0);
  const [incomePropertyGrowthRate, setIncomePropertyGrowthRate] = useState(0);
  const [incomePropertyOwnership, setIncomePropertyOwnership] = useState(100);
  const [incomePropertyPlansToSell, setIncomePropertyPlansToSell] =
    useState("");
  const [incomePropertyPurchasePrice, setIncomePropertyPurchasePrice] =
    useState(0);
  const [
    incomePropertyCapitalImprovements,
    setIncomePropertyCapitalImprovements,
  ] = useState(0);
  const [incomePropertyMortgagePayments, setIncomePropertyMortgagePayments] =
    useState(0);
  const [incomePropertyDepreciation, setIncomePropertyDepreciation] =
    useState(0);
  const [incomePropertyPlansToSellYear, setIncomePropertyPlansToSellYear] =
    useState("");
  const [incomePropertyPurchaseYear, setIncomePropertyPurchaseYear] =
    useState("");
  const [incomePropertyCalculatedValue, setIncomePropertyCalculatedValue] =
    useState(0);
  const [incomePropertyMortgageValue, setIncomePropertyMortgageValue] =
    useState(0);
  const [incomePropertyMortgageRate, setIncomePropertyMortgageRate] =
    useState(0);
  const [incomePropertyPreTaxProceeds, setIncomePropertyPreTaxProceeds] =
    useState("");
  const [incomePropertyNumber, setIncomePropertyNumber] = useState();

  const [isLargerThan768] = useMediaQuery("(min-width: 500px)");
  const isMobile = !isLargerThan768;

  const [realEstateArray, setRealEstateArray] = useState([]);
  const [editing, setEditing] = useState(false);
  const [editingIndex, setEditingIndex] = useState(null);
  const [showDeleteModal, setShowDeleteModal] = useState(null);

  const [adding, setAdding] = useState(false);

  const userPropertiesInfo = useStore((store) => store.userPropertiesInfo);
  const setUserPropertiesInfo = useStore(
    (store) => store.setUserPropertiesInfo
  );
  const updateUserPropertiesInfo = useStore(
    (store) => store.updateUserPropertiesInfo
  );
  const deleteUserPropertiesInfo = useStore(
    (store) => store.deleteUserPropertiesInfo
  );

  const userInfo = useStore((store) => store.userInfo);

  const {
    FirstName: firstName,
    LastName: lastName,
    DateOfBirth: currentAge,
    spouseFirstName,
    spouseLastName,
    runAge,
  } = userInfo;

  function getAge(birthday, currentDate) {
    // Convert the birthday string into a Date object
    const birthDate = new Date(birthday);

    // Calculate the age in years
    let age = currentDate.getFullYear() - birthDate.getFullYear();

    // Decrement the age if the current month and day are before the birth month and day
    if (
      currentDate.getMonth() < birthDate.getMonth() ||
      (currentDate.getMonth() === birthDate.getMonth() &&
        currentDate.getDate() <= birthDate.getDate())
    ) {
      age -= 1;
    }

    return age;
  }

  const moneyToNumber = (value) => {
    let numValue = value.toString().replace(/[$,\s]/g, "");
    numValue = Number(numValue);
    return numValue;
  };

  const Select = (userAge) => {
    const currentDate = new Date();
    const currentYear = currentDate.getFullYear();
    let age = getAge(userAge, currentDate); // Assuming getAge returns the starting age.
    const runAgeList = [];
    for (let i = age; i <= 100; i++) {
      runAgeList.push({ key: i, value: currentYear + (i - age) }); // Convert i to string if necessary
    }
    return runAgeList;
  };

  const SelectYearsPast = (userAge) => {
    const currentDate = new Date();
    const currentYear = currentDate.getFullYear();
    let age = getAge(userAge, currentDate); // Assuming getAge returns the user's current age.
    const runAgeList = [];
    for (let i = age; i >= 0; i--) {
      runAgeList.push({ key: i, value: currentYear - (age - i) }); // Convert i to string if necessary
    }
    return runAgeList;
  };

  const currencyFormat = (money) => {
    if (money === 0) {
      return "$0"; // Handle zero explicitly
    } else if (money === "") {
      return "";
    } else {
      const intTotal = Math.round(Math.round(money * 100) / 100);
      const currencyCad = new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "USD",
        minimumFractionDigits: 0,
      }).format(intTotal);
      return currencyCad;
    }
  };

  useEffect(() => {
    if (
      incomePropertyPlansToSell &&
      incomePropertyMortgageValue &&
      incomePropertyPlansToSellYear &&
      incomePropertyMortgageRate &&
      incomePropertyMortgagePayments &&
      incomePropertyValue &&
      incomePropertyGrowthRate
    ) {
      const currentDate = new Date();
      const userAge = getAge(currentAge, currentDate);
      const numYearsToSell = incomePropertyPlansToSellYear - userAge;
      const currentMonth = new Date().getMonth();
      const mortgageBalance = [];
      const modifiedRate =
        Math.pow(1 + incomePropertyMortgageRate / 100 / 2, 2) - 1;
      const monthlyRate = Math.pow(1 + modifiedRate, 1 / 12) - 1;
      mortgageBalance.push(incomePropertyMortgageValue);
      if (numYearsToSell == 0) {
        for (let i = 0; i < 12 - currentMonth - 1; i++) {
          var interest = monthlyRate * mortgageBalance[i];
          var principal = incomePropertyMortgagePayments - interest;
          var nextMonthMortgage = Math.max(mortgageBalance[i] - principal, 0);
          // console.log("Current Mortgage: ", mortgageBalance[i], " Interest: ", interest, "Principal", principal, "Next Year Mortgage", nextMonthMortgage)
          mortgageBalance.push(nextMonthMortgage);
        }
      } else {
        for (
          let i = 0;
          i < Math.max(0, numYearsToSell * 12 + (12 - currentMonth) - 1);
          i++
        ) {
          var interest = monthlyRate * mortgageBalance[i];
          var principal = incomePropertyMortgagePayments - interest;
          // console.log("Mortgage: ", mortgageBalance[i], "Interest: ", interest, "Principal: ", principal, "Month: ", i)
          var nextMonthMortgage = Math.max(mortgageBalance[i] - principal, 0);
          // console.log("Current Mortgage: ", mortgageBalance[i], " Interest: ", interest, "Principal", principal, "Next Year Mortgage", nextMonthMortgage)
          mortgageBalance.push(nextMonthMortgage);
        }
      }
      const sellingMortgageBalance =
        mortgageBalance[mortgageBalance.length - 1];
      let sellingPropertyValue = incomePropertyValue;
      for (let i = 0; i < numYearsToSell; i++) {
        sellingPropertyValue =
          sellingPropertyValue * (1 + incomePropertyGrowthRate / 100);
      }
      let preTaxValue = sellingMortgageBalance
        ? sellingPropertyValue - sellingMortgageBalance
        : sellingPropertyValue;
      setIncomePropertyPreTaxProceeds(preTaxValue);
    }
  }, [
    incomePropertyValue,
    incomePropertyMortgageValue,
    incomePropertyMortgageRate,
    incomePropertyNetIncome,
    incomePropertyGrowthRate,
    incomePropertyOwnership,
    incomePropertyPlansToSell,
    incomePropertyPurchasePrice,
    incomePropertyCapitalImprovements,
    incomePropertyMortgagePayments,
    incomePropertyDepreciation,
    incomePropertyPlansToSellYear,
  ]);

  const addRealEstate = (e, list) => {
    if (editing) {
      setRealEstateArray((prevArray) => {
        let newArray = [...prevArray];
        newArray[editingIndex] = list;
        onSubmitRealEstateUpdate(e, newArray[editingIndex]);
        return newArray;
      });
      setEditing(false);
      setEditingIndex(null);
    } else {
      setRealEstateArray((prevArray) => {
        let newArray = [...prevArray, list];
        onSubmitRealEstateAdd(e, newArray[newArray.length - 1]);
        return newArray;
      });
    }

    setIncomePropertyTitle("");
    setIncomePropertyValue(0);
    setIncomePropertyNetIncome(0);
    setIncomePropertyGrowthRate(0);
    setIncomePropertyOwnership(100);
    setIncomePropertyMortgageRate(0);
    setIncomePropertyMortgageValue(0);
    setIncomePropertyPlansToSell("");
    setIncomePropertyPurchasePrice(0);
    setIncomePropertyCapitalImprovements(0);
    setIncomePropertyMortgagePayments(0);
    setIncomePropertyDepreciation(0);
    setIncomePropertyPlansToSellYear(85);
    setIncomePropertyPurchaseYear(0);
    setIncomePropertyCalculatedValue(0);
    setAdding(false);
  };

  const [errors, setErrors] = useState({});

  const checkMandatoryAddUpdateFields = () => {
    let newErrors = {};

    // Income property conditions
    if (!incomePropertyValue)
      newErrors.incomePropertyValue = "Income property value is required";
    // if (!incomePropertyOwnership)
    //   newErrors.incomePropertyOwnership =
    //     "Income property ownership is required";
    if (!incomePropertyPlansToSell)
      newErrors.incomePropertyPlansToSell =
        "Plan to sell income property is required";
    if (!incomePropertyPurchasePrice)
      newErrors.incomePropertyPurchasePrice =
        "Income property purchase price is required";
    if (
      incomePropertyPlansToSell === "Yes" &&
      (!incomePropertyPlansToSellYear || !incomePropertyPurchaseYear)
    ) {
      if (!incomePropertyPlansToSellYear)
        newErrors.incomePropertyPlansToSellYear =
          "Year to sell income property is required";
      if (!incomePropertyPurchaseYear)
        newErrors.incomePropertyPurchaseYear =
          "Income property purchase year is required";
    }
    if (incomePropertyGrowthRate > 20)
      newErrors.incomePropertyGrowthRate =
        "Income property growth rate must be less than 20%";
    if (incomePropertyMortgageRate > 20)
      newErrors.incomePropertyMortgageRate =
        "Mortgage growth rate must be less than 20%";
    const effectiveMonthlyInterest =
      (1 + ((1 + incomePropertyMortgageRate / 200) ** 2 - 1)) ** (1 / 12) - 1;

    if (
      incomePropertyMortgageValue > 0 &&
      incomePropertyMortgagePayments <
        incomePropertyMortgageValue * effectiveMonthlyInterest
    ) {
      newErrors.incomePropertyMortgagePayments =
        "Monthly payment is less than the effective monthly interest.";
    }

    if (incomePropertyMortgageValue > 0 && incomePropertyMortgagePayments < 1) {
      newErrors.incomePropertyMortgagePayments =
        "Monthly payment is less than 1.";
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0; // Return true if no errors
  };

  useEffect(() => {
    console.log(userEditAssetInfo);
    if (userEditAssetInfo?.editAsset?.name === "Income property") {
      setIncomePropertyTitle(userEditAssetInfo?.editAsset?.Cottage_Address);
      setIncomePropertyValue(userEditAssetInfo?.editAsset?.value);
      setIncomePropertyNetIncome(
        userEditAssetInfo?.editAsset?.Income_Property_Annual_Income
      );
      setIncomePropertyGrowthRate(
        userEditAssetInfo?.editAsset?.Income_Property_Growth
      );
      // setIncomePropertyOwnership(
      //   userEditAssetInfo?.editAsset?.Income_Property_Ownership
      // );
      setIncomePropertyMortgageRate(
        userEditAssetInfo?.editAsset?.Income_Property_Mortgage_Rate
      );
      setIncomePropertyPlansToSell(
        userEditAssetInfo?.editAsset?.Do_Sell_Income_Property
      );
      setIncomePropertyPurchasePrice(
        userEditAssetInfo?.editAsset?.Income_Property_Purchase_Price
      );
      setIncomePropertyCapitalImprovements(
        userEditAssetInfo?.editAsset?.Income_Property_Capital_Improvements
      );
      setIncomePropertyMortgagePayments(
        userEditAssetInfo?.editAsset?.Income_Property_Mortgage_Payments
      );
      setIncomePropertyDepreciation(
        userEditAssetInfo?.editAsset?.Income_Property_Depreciation
      );
      setIncomePropertyPlansToSellYear(
        userEditAssetInfo?.editAsset?.Income_Property_Sell_Date
      );
      setIncomePropertyPurchaseYear(
        userEditAssetInfo?.editAsset?.Income_Property_Purchase_Age
      );
      setIncomePropertyCalculatedValue(
        userEditAssetInfo?.editAsset?.Income_Property_Calculated_Value
      );
      setIncomePropertyNumber(userEditAssetInfo?.editAsset?.Property_Number);
      setIncomePropertyMortgageValue(
        userEditAssetInfo?.editAsset?.Income_Property_Mortgage_Balance
      );
      setEditing(true);
      setAdding(true);
      setEditingIndex(userEditAssetInfo?.editIndex);
    }
  }, [userEditAssetInfo]);

  useEffect(() => {
    const updatedRealEstateArray = [];

    if (userPropertiesInfo != null) {
      updatedRealEstateArray.push(...userPropertiesInfo);
    }

    setRealEstateArray(updatedRealEstateArray);
  }, [userPropertiesInfo]);

  const onDeleteRow = (i) => {
    setEditingIndex(i);
    setShowDeleteModal(true);
  };

  const editField = (i, array) => {
    setAdding(true);
    if (array[i].name === "Income property") {
      setIncomePropertyTitle(array[i]?.Cottage_Address);
      setIncomePropertyValue(array[i]?.value);
      setIncomePropertyNetIncome(array[i]?.Income_Property_Annual_Income);
      setIncomePropertyGrowthRate(array[i]?.Income_Property_Growth);
      // setIncomePropertyOwnership(array[i]?.Income_Property_Ownership);
      setIncomePropertyMortgageRate(array[i]?.Income_Property_Mortgage_Rate);
      setIncomePropertyPlansToSell(array[i]?.Do_Sell_Income_Property);
      setIncomePropertyPurchasePrice(array[i]?.Income_Property_Purchase_Price);
      setIncomePropertyCapitalImprovements(
        array[i]?.Income_Property_Capital_Improvements
      );
      setIncomePropertyMortgagePayments(
        array[i]?.Income_Property_Mortgage_Payments
      );
      setIncomePropertyDepreciation(array[i]?.Income_Property_Depreciation);
      setIncomePropertyPlansToSellYear(array[i]?.Income_Property_Sell_Date);
      setIncomePropertyPurchaseYear(array[i]?.Income_Property_Purchase_Age);
      setIncomePropertyCalculatedValue(
        array[i]?.Income_Property_Calculated_Value
      );
      setIncomePropertyNumber(array[i]?.Property_Number);
      setIncomePropertyMortgageValue(
        array[i]?.Income_Property_Mortgage_Balance
      );
    }
    setEditing(true);
    setEditingIndex(i);
  };

  const DeleteRealEstateModal = () => {
    let activeArray = realEstateArray;

    const removeRealEstateModal = () => {
      const deletePropertyURL = (action) =>
        `${process.env.REACT_APP_NODE_API_GATEWAY}/CompassLambdaApi/PROPERTIES/${action}?Property_Number=${activeArray[editingIndex].Property_Number}`;
      axiosInstance.get(deletePropertyURL("delete")).then((res) => {
        console.log("Delete res", res);
        deleteUserPropertiesInfo(realEstateArray[editingIndex].Property_Number);
        removeField(editingIndex, activeArray);
        setEditingIndex(null);
        setEditing(false);
      });
    };

    if (!showDeleteModal) {
      return null;
    }

    const [yesClicked, setYesClicked] = useState(false);
    const [noClicked, setNoClicked] = useState(false);
    return (
      <div
        className="delete-income-modal"
        style={{
          display: "flex",
          alignItems: "center",
          width: "100%",
          maxWidth: "700px",
          justifyContent: "center",
          top: "50%",
          left: "50%",
          transform: isMobile
            ? "translate(-50%, -50%)"
            : "translate(-50%, -50%)",
        }}
        id="delete-income-modal"
      >
        <Box
          display={"flex"}
          width={"100%"}
          padding={"10px 10px 0px 0px"}
          marginLeft={"5px"}
          alignItems={"flex-end"}
          justifyContent={"flex-end"}
          onClick={() => setShowDeleteModal(false)}
          cursor={"pointer"}
        >
          <CloseIcon
            onClick={() => setShowDeleteModal(false)}
            svgheight={"20px"}
            svgwidth={"20px"}
          />
        </Box>
        <Box
          display={"flex"}
          padding={"0px 25px 25px 25px"}
          flexDirection={"column"}
          alignItems={"center"}
          gap={"10px"}
          alignSelf={"stretch"}
          width={"100%"}
        >
          <Text
            color={"#0093D0"}
            fontFamily={"Nunito"}
            fontSize={"24px"}
            fontStyle={"normal"}
            fontWeight={"600"}
            lineHeight={"normal"}
            textDecor={"underline"}
          >
            Are you sure you want to remove this real estate asset?
          </Text>
          <div className="content"></div>
          <div className="actions">
            <button
              className={`decision-button yes-button ${
                yesClicked ? "yes-clicked" : ""
              }`}
              onClick={() => {
                setYesClicked(true);
                setNoClicked(false);
                removeRealEstateModal();
              }}
            >
              Yes
            </button>
            <button
              className={`decision-button no-button ${
                noClicked ? "yes-clicked" : ""
              }`}
              onClick={() => {
                {
                  setNoClicked(true);
                  setYesClicked(false);
                  setShowDeleteModal(false);
                }
              }}
            >
              No
            </button>
          </div>
        </Box>
      </div>
    );
  };

  const removeField = (i, array) => {
    array.splice(i, 1);
    setRealEstateArray((prevArray) => [...prevArray]);
    setShowDeleteModal(false);
  };

  const onSubmitRealEstateAdd = (e, newArray) => {
    e.preventDefault();
    if (newArray.name === "Income property") {
      const propertiesURL = (action) =>
        `${
          process.env.REACT_APP_NODE_API_GATEWAY
        }/CompassLambdaApi/PROPERTIES/${action}?PropertyID=${user.sub.replace(
          "|",
          "%7c"
        )}&value=${newArray.value}&Income_Property_Annual_Income=${
          newArray.Income_Property_Annual_Income
        }&Income_Property_Growth=${
          newArray.Income_Property_Growth
        }&Income_Property_Ownership=${
          newArray.Income_Property_Ownership
        }&Do_Sell_Income_Property=${
          newArray.Do_Sell_Income_Property
        }&Income_Property_Purchase_Price=${
          newArray.Income_Property_Purchase_Price
        }&Income_Property_Mortgage_Payments=${
          newArray.Income_Property_Mortgage_Payments
        }&Income_Property_Depreciation=${
          newArray.Income_Property_Depreciation
        }&Income_Property_Sell_Date=${
          newArray.Income_Property_Sell_Date
        }&Income_Property_Calculated_Value=${
          newArray.Income_Property_Calculated_Value
        }&Income_Property_Mortgage_Balance=${
          newArray.Income_Property_Mortgage_Balance
        }&name=${newArray.name}&Income_Property_Mortgage_Rate=${
          newArray.Income_Property_Mortgage_Rate
        }&Cottage_Address=${
          newArray.Cottage_Address
        }&Income_Property_Capital_Improvements=${
          newArray.Income_Property_Capital_Improvements
        }&Income_Property_Purchase_Age=${
          newArray.Income_Property_Purchase_Age
        }`;
      axiosInstance
        .get(propertiesURL("add"))
        .then((res) => {
          console.log(res);
          const newData = [
            {
              value: newArray.value,
              Income_Property_Annual_Income:
                newArray.Income_Property_Annual_Income,
              Income_Property_Growth: newArray.Income_Property_Growth,
              Income_Property_Ownership: newArray.Income_Property_Ownership,
              Do_Sell_Income_Property: newArray.Do_Sell_Income_Property,
              Income_Property_Purchase_Price:
                newArray.Income_Property_Purchase_Price,
              Income_Property_Mortgage_Payments:
                newArray.Income_Property_Mortgage_Payments,
              Income_Property_Depreciation:
                newArray.Income_Property_Depreciation,
              Income_Property_Sell_Date: newArray.Income_Property_Sell_Date,
              Income_Property_Calculated_Value:
                newArray.Income_Property_Calculated_Value,
              Income_Property_Mortgage_Balance:
                newArray.Income_Property_Mortgage_Balance,
              name: newArray.name,
              Income_Property_Mortgage_Rate:
                newArray.Income_Property_Mortgage_Rate,
              Cottage_Address: newArray.Cottage_Address,
              Income_Property_Capital_Improvements:
                newArray.Income_Property_Capital_Improvements,
              Property_Number: res.data.lastInsertedId,
              Income_Property_Purchase_Age:
                newArray.Income_Property_Purchase_Age,
            },
          ];
          setUserPropertiesInfo(newData);
          setUserEditAssetInfo({});
        })
        .catch((error) => {
          console.error(error);
        });
    }
  };

  const onSubmitRealEstateUpdate = (e, newArray) => {
    e.preventDefault();
    let z = newArray.Property_Number;
    if (newArray.name === "Income property") {
      const propertiesURL = (action) =>
        `${
          process.env.REACT_APP_NODE_API_GATEWAY
        }/CompassLambdaApi/PROPERTIES/${action}?PropertyID=${user.sub.replace(
          "|",
          "%7c"
        )}&value=${newArray.value}&Income_Property_Annual_Income=${
          newArray.Income_Property_Annual_Income
        }&Income_Property_Growth=${
          newArray.Income_Property_Growth
        }&Income_Property_Ownership=${
          newArray.Income_Property_Ownership
        }&Do_Sell_Income_Property=${
          newArray.Do_Sell_Income_Property
        }&Income_Property_Purchase_Price=${
          newArray.Income_Property_Purchase_Price
        }&Income_Property_Mortgage_Payments=${
          newArray.Income_Property_Mortgage_Payments
        }&Income_Property_Depreciation=${
          newArray.Income_Property_Depreciation
        }&Income_Property_Sell_Date=${
          newArray.Income_Property_Sell_Date
        }&Income_Property_Calculated_Value=${
          newArray.Income_Property_Calculated_Value
        }&Income_Property_Mortgage_Balance=${
          newArray.Income_Property_Mortgage_Balance
        }&name=${newArray.name}&Income_Property_Mortgage_Rate=${
          newArray.Income_Property_Mortgage_Rate
        }&Cottage_Address=${
          newArray.Cottage_Address
        }&Income_Property_Capital_Improvements=${
          newArray.Income_Property_Capital_Improvements
        }&Property_Number=${
          newArray.Property_Number
        }&Income_Property_Purchase_Age=${
          newArray.Income_Property_Purchase_Age
        }`;
      axiosInstance
        .get(propertiesURL("update"))
        .then((res) => {
          console.log(res);
          const newData = {
            value: newArray.value,
            Income_Property_Annual_Income:
              newArray.Income_Property_Annual_Income,
            Income_Property_Growth: newArray.Income_Property_Growth,
            Income_Property_Ownership: newArray.Income_Property_Ownership,
            Do_Sell_Income_Property: newArray.Do_Sell_Income_Property,
            Income_Property_Purchase_Price:
              newArray.Income_Property_Purchase_Price,
            Income_Property_Mortgage_Payments:
              newArray.Income_Property_Mortgage_Payments,
            Income_Property_Depreciation: newArray.Income_Property_Depreciation,
            Income_Property_Sell_Date: newArray.Income_Property_Sell_Date,
            Income_Property_Calculated_Value:
              newArray.Income_Property_Calculated_Value,
            Income_Property_Mortgage_Balance:
              newArray.Income_Property_Mortgage_Balance,
            name: newArray.name,
            Income_Property_Mortgage_Rate:
              newArray.Income_Property_Mortgage_Rate,
            Cottage_Address: newArray.Cottage_Address,
            Income_Property_Capital_Improvements:
              newArray.Income_Property_Capital_Improvements,
            Property_Number: newArray.Property_Number,
            Income_Property_Purchase_Age: newArray.Income_Property_Purchase_Age,
          };
          updateUserPropertiesInfo(z, newData);
          setUserEditAssetInfo({});
        })
        .catch((error) => {
          console.error(error);
        });
    }
  };

  const renderAddUpdateRecreational = () => {
    if (
      !incomePropertyValue ||
      // !incomePropertyOwnership ||
      !incomePropertyPlansToSell ||
      !incomePropertyPurchasePrice ||
      incomePropertyGrowthRate > 20 ||
      incomePropertyMortgageRate > 20 ||
      (incomePropertyPlansToSell === "Yes" &&
        (!incomePropertyPlansToSellYear || !incomePropertyPurchaseYear)) ||
      (incomePropertyMortgageValue > 0 &&
        incomePropertyMortgagePayments <
          incomePropertyMortgageValue *
            ((1 + ((1 + incomePropertyMortgageRate / 200) ** 2 - 1)) **
              (1 / 12) -
              1)) ||
      (incomePropertyMortgageValue > 0 && incomePropertyMortgagePayments < 1)
    ) {
      return (
        <Box
          display={"flex"}
          padding={"8px 48px"}
          marginTop={"20px"}
          justifyContent={"flex-end"}
          alignItems={"center"}
          gap={"5px"}
          borderRadius={"5px"}
          bg={"#27AE60"}
          cursor={"pointer"}
          onClick={checkMandatoryAddUpdateFields}
        >
          <Box
            display={"flex"}
            width={"76px"}
            height={"21px"}
            flexDirection={"column"}
            justifyContent={"center"}
          >
            <Text
              color={"#FFFFFF"}
              textAlign={"center"}
              fontFamily={"Nunito"}
              fontSize={"16px"}
              fontStyle={"normal"}
              fontWeight={"600"}
              lineHeight={"normal"}
            >
              {editing ? "Update" : "Add"}
            </Text>
          </Box>
        </Box>
      );
    } else {
      return (
        <Box
          display={"flex"}
          padding={"8px 48px"}
          marginTop={"20px"}
          justifyContent={"flex-end"}
          alignItems={"center"}
          gap={"5px"}
          borderRadius={"5px"}
          bg={"#27AE60"}
          cursor={"pointer"}
          onClick={(e) =>
            addRealEstate(e, {
              name: "Income property",
              value: incomePropertyValue,
              Income_Property_Annual_Income: incomePropertyNetIncome,
              Income_Property_Growth: incomePropertyGrowthRate,
              Income_Property_Ownership: incomePropertyOwnership,
              Do_Sell_Income_Property: incomePropertyPlansToSell,
              Income_Property_Purchase_Price: incomePropertyPurchasePrice,
              Income_Property_Capital_Improvements:
                incomePropertyCapitalImprovements,
              Income_Property_Mortgage_Payments: incomePropertyMortgagePayments,
              Income_Property_Depreciation: incomePropertyDepreciation,
              Income_Property_Sell_Date: incomePropertyPlansToSellYear,
              Income_Property_Purchase_Age: incomePropertyPurchaseYear,
              Income_Property_Calculated_Value: incomePropertyCalculatedValue,
              Cottage_Address: incomePropertyTitle,
              Income_Property_Mortgage_Balance: incomePropertyMortgageValue,
              Income_Property_Mortgage_Rate: incomePropertyMortgageRate,
              ...(editing ? { Property_Number: incomePropertyNumber } : {}), // add field conditionally
            })
          }
        >
          <Box
            display={"flex"}
            width={"76px"}
            height={"21px"}
            flexDirection={"column"}
            justifyContent={"center"}
            cursor={"pointer"}
          >
            <Text
              color={"#FFFFFF"}
              textAlign={"center"}
              fontFamily={"Nunito"}
              fontSize={"16px"}
              fontStyle={"normal"}
              fontWeight={"600"}
              lineHeight={"normal"}
            >
              {editing ? "Update" : "Add"}
            </Text>
          </Box>
        </Box>
      );
    }
  };

  const columns = [
    { header: "Name", field: "name" },
    { header: "Value", field: "value" },
  ];

  const data = realEstateArray.map((item) => ({
    name: item?.Cottage_Address ? item?.Cottage_Address : item.name,
    value: item?.value,
    link: "/income-property-form",
  }));

  const handleClick = () => {
    setAdding(true);
  };

  return (
    <Flex
      display="flex"
      flexWrap="wrap" // Enable wrapping
      justifyContent="flex-start"
      alignItems="start"
      width={"100%"}
    >
      {!isMobile && (
        <VStack alignItems={"flex-start"} width={"100%"} mt={-2}>
          <GlobalBodyPage
            Heading="Income Property"
            mainframeContent={
              <>
                <Box
                  display={"flex"}
                  width={"100%"}
                  flexDirection={"row"}
                  paddingTop={"10px"}
                  alignItems={"flex-end"}
                  justifyContent={"space-between"}
                  gap={"10px"}
                  flexWrap={"wrap"}
                >
                  <DeleteRealEstateModal />
                  {data.length > 0 && !adding && (
                    <TableComponent
                      columns={columns}
                      data={data}
                      editField={editField}
                      onDeleteRow={onDeleteRow}
                      rawData={realEstateArray}
                    />
                  )}
                  {(data.length < 1 || adding) && (
                    <>
                      <Text
                        alignSelf={"stretch"}
                        color={"var(--Optiml---Deep-Ocean-Blue, #007EB5)"}
                        fontFamily={"Nunito"}
                        fontSize={"16px"}
                        fontStyle={"normal"}
                        fontWeight={"600"}
                        lineHeight={"normal"}
                        width={"100%"}
                      >
                        Property details:
                      </Text>
                      <NormalInputButton
                        Heading={"Address"}
                        value={incomePropertyTitle}
                        onChange={(e) => {
                          setIncomePropertyTitle(e.target.value);
                        }}
                        customWidth={"100%"}
                        InputWidth={"100%"}
                        error={errors.incomePropertyTitle}
                        tooltipContent={TooltipContent.incomePropertyAddress}
                      />
                      <NormalInputButton
                        Heading={
                          "Enter the dollar value of your ownership of the property"
                        }
                        value={incomePropertyValue}
                        onChange={(e) => {
                          setIncomePropertyValue(moneyToNumber(e.target.value));
                        }}
                        customWidth={"100%"}
                        placeholder={"$"}
                        useThousandSeparator={true}
                        error={errors.incomePropertyValue}
                        tooltipContent={TooltipContent.incomePropertyValue}
                      />
                      <Text
                        alignSelf={"stretch"}
                        color={"var(--Optiml---Deep-Ocean-Blue, #007EB5)"}
                        fontFamily={"Nunito"}
                        fontSize={"16px"}
                        fontStyle={"normal"}
                        fontWeight={"600"}
                        lineHeight={"normal"}
                        width={"100%"}
                      >
                        Financial performance:
                      </Text>
                      <NormalInputButton
                        Heading={"Annual EBITDA"}
                        value={incomePropertyNetIncome}
                        onChange={(e) => {
                          setIncomePropertyNetIncome(
                            moneyToNumber(e.target.value)
                          );
                        }}
                        placeholder={"$"}
                        useThousandSeparator={true}
                        error={errors.incomePropertyNetIncome}
                        tooltipContent={TooltipContent.incomePropertyEBITDA}
                      />
                      <NormalInputButton
                        Heading={"Growth rate"}
                        value={incomePropertyGrowthRate}
                        onChange={(e) => {
                          const noSymbol = e.target.value.replace("%", "");
                          const growthNum = parseFloat(noSymbol);
                          setIncomePropertyGrowthRate(
                            !isNaN(growthNum)
                              ? parseFloat(growthNum.toFixed(2))
                              : 0
                          );
                        }}
                        placeholder={"%"}
                        usePercentage={true}
                        error={errors.incomePropertyGrowthRate}
                        tooltipContent={TooltipContent.incomePropertyGrowth}
                      />
                      <Text
                        alignSelf={"stretch"}
                        color={"var(--Optiml---Deep-Ocean-Blue, #007EB5)"}
                        fontFamily={"Nunito"}
                        fontSize={"16px"}
                        fontStyle={"normal"}
                        fontWeight={"600"}
                        lineHeight={"normal"}
                        width={"100%"}
                      >
                        Investment:
                      </Text>
                      {/* <NormalInputButton
                        Heading={"Ownership"}
                        value={incomePropertyOwnership}
                        onChange={(e) => {
                          const noSymbol = e.target.value.replace("%", "");
                          const growthNum = parseFloat(noSymbol);
                          setIncomePropertyOwnership(
                            !isNaN(growthNum)
                              ? parseFloat(growthNum.toFixed(2))
                              : 0
                          );
                        }}
                        placeholder={"%"}
                        usePercentage={true}
                        error={errors.incomePropertyOwnership}
                        tooltipContent={TooltipContent.incomePropertyOwnership}
                      /> */}
                      <NormalInputButton
                        Heading={"Purchase price"}
                        value={incomePropertyPurchasePrice}
                        onChange={(e) => {
                          setIncomePropertyPurchasePrice(
                            moneyToNumber(e.target.value)
                          );
                        }}
                        placeholder={"$"}
                        useThousandSeparator={true}
                        error={errors.incomePropertyPurchasePrice}
                        tooltipContent={
                          TooltipContent.incomePropertyPurchasePrice
                        }
                      />
                      <NormalInputButton
                        Heading={"Capital improvements"}
                        value={incomePropertyCapitalImprovements}
                        onChange={(e) => {
                          setIncomePropertyCapitalImprovements(
                            moneyToNumber(e.target.value)
                          );
                        }}
                        placeholder={"$"}
                        useThousandSeparator={true}
                        customWidth={"100%"}
                        error={errors.incomePropertyCapitalImprovements}
                        tooltipContent={
                          TooltipContent.incomePropertyCapitalImprovements
                        }
                      />

                      <Text
                        alignSelf={"stretch"}
                        color={"var(--Optiml---Deep-Ocean-Blue, #007EB5)"}
                        fontFamily={"Nunito"}
                        fontSize={"16px"}
                        fontStyle={"normal"}
                        fontWeight={"600"}
                        lineHeight={"normal"}
                        width={"100%"}
                      >
                        Mortgage information:
                      </Text>
                      <NormalInputButton
                        Heading={"Remaining mortgage balance"}
                        value={incomePropertyMortgageValue}
                        onChange={(e) => {
                          setIncomePropertyMortgageValue(
                            moneyToNumber(e.target.value)
                          );
                        }}
                        placeholder={"$"}
                        useThousandSeparator={true}
                        error={errors.incomePropertyMortgageValue}
                        tooltipContent={
                          TooltipContent.incomePropertyMortgageBalance
                        }
                      />
                      <NormalInputButton
                        Heading={"Monthly mortgage payment"}
                        value={incomePropertyMortgagePayments}
                        onChange={(e) => {
                          setIncomePropertyMortgagePayments(
                            moneyToNumber(e.target.value)
                          );
                        }}
                        placeholder={"$"}
                        useThousandSeparator={true}
                        error={errors.incomePropertyMortgagePayments}
                        tooltipContent={
                          TooltipContent.incomePropertyMonthlyMortgage
                        }
                      />
                      <NormalInputButton
                        Heading={"Mortgage interest rate"}
                        value={incomePropertyMortgageRate}
                        onChange={(e) => {
                          const noSymbol = e.target.value.replace("%", "");
                          const growthNum = parseFloat(noSymbol);
                          setIncomePropertyMortgageRate(
                            !isNaN(growthNum)
                              ? parseFloat(growthNum.toFixed(2))
                              : 0
                          );
                        }}
                        placeholder={"%"}
                        usePercentage={true}
                        error={errors.incomePropertyMortgageRate}
                        tooltipContent={
                          TooltipContent.incomePropertyInterestRate
                        }
                      />
                      <Text
                        alignSelf={"stretch"}
                        color={"var(--Optiml---Deep-Ocean-Blue, #007EB5)"}
                        fontFamily={"Nunito"}
                        fontSize={"16px"}
                        fontStyle={"normal"}
                        fontWeight={"600"}
                        lineHeight={"normal"}
                        width={"100%"}
                      >
                        Depreciation and Taxation:
                      </Text>
                      <NormalInputButton
                        Heading={"Undepreciated capital cost"}
                        value={incomePropertyDepreciation}
                        onChange={(e) => {
                          setIncomePropertyDepreciation(
                            moneyToNumber(e.target.value)
                          );
                        }}
                        placeholder={"$"}
                        useThousandSeparator={true}
                        error={errors.incomePropertyDepreciation}
                        tooltipContent={TooltipContent.incomePropertyUCC}
                      />
                      <Text
                        alignSelf={"stretch"}
                        color={"var(--Optiml---Deep-Ocean-Blue, #007EB5)"}
                        fontFamily={"Nunito"}
                        fontSize={"16px"}
                        fontStyle={"normal"}
                        fontWeight={"600"}
                        lineHeight={"normal"}
                        width={"100%"}
                      >
                        Future Real Estate Plans
                      </Text>
                      <DropDownButton
                        Heading={
                          "Do you have plans to sell your rental property?"
                        }
                        placeholder={"Select"}
                        dropDownItems={[
                          { key: "Yes", value: "Yes" },
                          { key: "No", value: "No" },
                        ]}
                        value={incomePropertyPlansToSell}
                        onChange={(key) => {
                          setIncomePropertyPlansToSell(key);
                        }}
                        customWidth={"100%"}
                        error={errors.incomePropertyPlansToSell}
                        tooltipContent={TooltipContent.incomePropertySaleYesNo}
                      />
                      {incomePropertyPlansToSell === "Yes" && (
                        <>
                          <DropDownButton
                            Heading={
                              "When do you anticipate you may sell your property?"
                            }
                            placeholder={"Select"}
                            dropDownItems={Select(currentAge)}
                            value={incomePropertyPlansToSellYear}
                            onChange={(key) => {
                              setIncomePropertyPlansToSellYear(key);
                            }}
                            customWidth={"100%"}
                            error={errors.incomePropertyPlansToSellYear}
                            tooltipContent={
                              TooltipContent.incomePropertySaleAge
                            }
                          />
                          <DropDownButton
                            Heading={"When did you purchase the property?"}
                            placeholder={"Select"}
                            dropDownItems={SelectYearsPast(currentAge)}
                            value={incomePropertyPurchaseYear}
                            onChange={(key) => {
                              setIncomePropertyPurchaseYear(key);
                            }}
                            customWidth={"100%"}
                            error={errors.incomePropertyPurchaseYear}
                          />
                          <Text
                            alignSelf={"stretch"}
                            color={"var(--Optiml---Deep-Ocean-Blue, #007EB5)"}
                            fontFamily={"Nunito"}
                            fontSize={"16px"}
                            fontStyle={"normal"}
                            fontWeight={"600"}
                            lineHeight={"normal"}
                            width={"100%"}
                          >
                            Estimated Pre Tax Value of Property:{" "}
                            {currencyFormat(incomePropertyPreTaxProceeds)}
                          </Text>
                        </>
                      )}
                    </>
                  )}
                </Box>
                {data.length > 0 && !adding && (
                  <Box
                    display={"flex"}
                    justifyContent={"flex-start"}
                    alignItems={"center"}
                    alignSelf={"stretch"}
                    onClick={handleClick}
                  >
                    <Box
                      display={"flex"}
                      padding={"8px 48px"}
                      marginTop={"20px"}
                      justifyContent={"flex-end"}
                      alignItems={"center"}
                      gap={"5px"}
                      borderRadius={"5px"}
                      bg={"#27AE60"}
                      cursor={"pointer"}
                    >
                      <Box
                        display={"flex"}
                        width={"76px"}
                        height={"21px"}
                        flexDirection={"column"}
                        justifyContent={"center"}
                      >
                        <Text
                          color={"#FFFFFF"}
                          textAlign={"center"}
                          fontFamily={"Nunito"}
                          fontSize={"16px"}
                          fontStyle={"normal"}
                          fontWeight={"600"}
                          lineHeight={"normal"}
                        >
                          Add
                        </Text>
                      </Box>
                    </Box>
                  </Box>
                )}
                {(data.length < 1 || adding) && (
                  <Box
                    display={"flex"}
                    justifyContent={"flex-end"}
                    alignItems={"center"}
                    alignSelf={"stretch"}
                  >
                    {renderAddUpdateRecreational()}
                  </Box>
                )}
              </>
            }
            videoName={"Income Property Page"}
            video={"Income_property.mp4"}
          />
        </VStack>
      )}
      {isMobile && (
        <VStack alignItems={"flex-start"} width={"100%"} mt={-2}>
          <GlobalBodyPage
            Heading="Income Property"
            mainframeContent={
              <>
                <Box
                  display={"flex"}
                  width={"100%"}
                  flexDirection={"row"}
                  paddingTop={"10px"}
                  alignItems={"flex-end"}
                  justifyContent={"space-between"}
                  gap={"10px"}
                  flexWrap={"wrap"}
                >
                  <DeleteRealEstateModal />
                  {data.length > 0 && !adding && (
                    <TableComponent
                      columns={columns}
                      data={data}
                      editField={editField}
                      onDeleteRow={onDeleteRow}
                      rawData={realEstateArray}
                    />
                  )}
                  {(data.length < 1 || adding) && (
                    <>
                      <Text
                        alignSelf={"stretch"}
                        color={"var(--Optiml---Deep-Ocean-Blue, #007EB5)"}
                        fontFamily={"Nunito"}
                        fontSize={"16px"}
                        fontStyle={"normal"}
                        fontWeight={"600"}
                        lineHeight={"normal"}
                        width={"100%"}
                      >
                        Property details:
                      </Text>
                      <NormalInputButton
                        Heading={"Address"}
                        value={incomePropertyTitle}
                        onChange={(e) => {
                          setIncomePropertyTitle(e.target.value);
                        }}
                        customWidth={"100%"}
                        InputWidth={"100%"}
                        error={errors.incomePropertyTitle}
                        tooltipContent={TooltipContent.incomePropertyAddress}
                      />
                      <NormalInputButton
                        Heading={
                          "Enter the dollar value of your ownership of the property"
                        }
                        value={incomePropertyValue}
                        onChange={(e) => {
                          setIncomePropertyValue(moneyToNumber(e.target.value));
                        }}
                        customWidth={"100%"}
                        InputWidth={"100%"}
                        placeholder={"$"}
                        useThousandSeparator={true}
                        error={errors.incomePropertyValue}
                        tooltipContent={TooltipContent.incomePropertyValue}
                      />
                      <Text
                        alignSelf={"stretch"}
                        color={"var(--Optiml---Deep-Ocean-Blue, #007EB5)"}
                        fontFamily={"Nunito"}
                        fontSize={"16px"}
                        fontStyle={"normal"}
                        fontWeight={"600"}
                        lineHeight={"normal"}
                        width={"100%"}
                      >
                        Financial performance:
                      </Text>
                      <NormalInputButton
                        Heading={"Annual EBITDA"}
                        value={incomePropertyNetIncome}
                        onChange={(e) => {
                          setIncomePropertyNetIncome(
                            moneyToNumber(e.target.value)
                          );
                        }}
                        placeholder={"$"}
                        useThousandSeparator={true}
                        InputWidth={"100%"}
                        error={errors.incomePropertyNetIncome}
                        tooltipContent={TooltipContent.incomePropertyEBITDA}
                      />
                      <NormalInputButton
                        Heading={"Growth rate"}
                        value={incomePropertyGrowthRate}
                        onChange={(e) => {
                          const noSymbol = e.target.value.replace("%", "");
                          const growthNum = parseFloat(noSymbol);
                          setIncomePropertyGrowthRate(
                            !isNaN(growthNum)
                              ? parseFloat(growthNum.toFixed(2))
                              : 0
                          );
                        }}
                        placeholder={"%"}
                        usePercentage={true}
                        InputWidth={"100%"}
                        error={errors.incomePropertyGrowthRate}
                        tooltipContent={TooltipContent.incomePropertyGrowth}
                      />
                      <Text
                        alignSelf={"stretch"}
                        color={"var(--Optiml---Deep-Ocean-Blue, #007EB5)"}
                        fontFamily={"Nunito"}
                        fontSize={"16px"}
                        fontStyle={"normal"}
                        fontWeight={"600"}
                        lineHeight={"normal"}
                        width={"100%"}
                      >
                        Investment:
                      </Text>
                      {/* <NormalInputButton
                        Heading={"Ownership"}
                        value={incomePropertyOwnership}
                        onChange={(e) => {
                          const noSymbol = e.target.value.replace("%", "");
                          const growthNum = parseFloat(noSymbol);
                          setIncomePropertyOwnership(
                            !isNaN(growthNum)
                              ? parseFloat(growthNum.toFixed(2))
                              : 0
                          );
                        }}
                        placeholder={"%"}
                        usePercentage={true}
                        InputWidth={"100%"}
                        error={errors.incomePropertyOwnership}
                        tooltipContent={TooltipContent.incomePropertyOwnership}
                      /> */}
                      <NormalInputButton
                        Heading={"Purchase price"}
                        value={incomePropertyPurchasePrice}
                        onChange={(e) => {
                          setIncomePropertyPurchasePrice(
                            moneyToNumber(e.target.value)
                          );
                        }}
                        placeholder={"$"}
                        useThousandSeparator={true}
                        InputWidth={"100%"}
                        error={errors.incomePropertyPurchasePrice}
                        tooltipContent={
                          TooltipContent.incomePropertyPurchasePrice
                        }
                      />
                      <NormalInputButton
                        Heading={"Capital improvements"}
                        value={incomePropertyCapitalImprovements}
                        onChange={(e) => {
                          setIncomePropertyCapitalImprovements(
                            moneyToNumber(e.target.value)
                          );
                        }}
                        placeholder={"$"}
                        useThousandSeparator={true}
                        customWidth={"100%"}
                        InputWidth={"100%"}
                        error={errors.incomePropertyCapitalImprovements}
                      />

                      <Text
                        alignSelf={"stretch"}
                        color={"var(--Optiml---Deep-Ocean-Blue, #007EB5)"}
                        fontFamily={"Nunito"}
                        fontSize={"16px"}
                        fontStyle={"normal"}
                        fontWeight={"600"}
                        lineHeight={"normal"}
                        width={"100%"}
                      >
                        Mortgage information:
                      </Text>
                      <NormalInputButton
                        Heading={"Remaining mortgage balance"}
                        value={incomePropertyMortgageValue}
                        onChange={(e) => {
                          setIncomePropertyMortgageValue(
                            moneyToNumber(e.target.value)
                          );
                        }}
                        placeholder={"$"}
                        useThousandSeparator={true}
                        InputWidth={"100%"}
                        error={errors.incomePropertyMortgageValue}
                        tooltipContent={
                          TooltipContent.incomePropertyMortgageBalance
                        }
                      />
                      <NormalInputButton
                        Heading={"Monthly mortgage payment"}
                        value={incomePropertyMortgagePayments}
                        onChange={(e) => {
                          setIncomePropertyMortgagePayments(
                            moneyToNumber(e.target.value)
                          );
                        }}
                        placeholder={"$"}
                        useThousandSeparator={true}
                        InputWidth={"100%"}
                        error={errors.incomePropertyMortgagePayments}
                        tooltipContent={
                          TooltipContent.incomePropertyMonthlyMortgage
                        }
                      />
                      <NormalInputButton
                        Heading={"Mortgage interest rate"}
                        value={incomePropertyMortgageRate}
                        onChange={(e) => {
                          const noSymbol = e.target.value.replace("%", "");
                          const growthNum = parseFloat(noSymbol);
                          setIncomePropertyMortgageRate(
                            !isNaN(growthNum)
                              ? parseFloat(growthNum.toFixed(2))
                              : 0
                          );
                        }}
                        placeholder={"%"}
                        usePercentage={true}
                        InputWidth={"100%"}
                        error={errors.incomePropertyMortgageRate}
                        tooltipContent={
                          TooltipContent.incomePropertyInterestRate
                        }
                      />
                      <Text
                        alignSelf={"stretch"}
                        color={"var(--Optiml---Deep-Ocean-Blue, #007EB5)"}
                        fontFamily={"Nunito"}
                        fontSize={"16px"}
                        fontStyle={"normal"}
                        fontWeight={"600"}
                        lineHeight={"normal"}
                        width={"100%"}
                      >
                        Depreciation and Taxation:
                      </Text>
                      <NormalInputButton
                        Heading={"Undepreciated capital cost"}
                        value={incomePropertyDepreciation}
                        onChange={(e) => {
                          setIncomePropertyDepreciation(e.target.value);
                        }}
                        placeholder={"$"}
                        useThousandSeparator={true}
                        InputWidth={"100%"}
                        error={errors.incomePropertyDepreciation}
                        tooltipContent={TooltipContent.incomePropertyUCC}
                      />
                      <Text
                        alignSelf={"stretch"}
                        color={"var(--Optiml---Deep-Ocean-Blue, #007EB5)"}
                        fontFamily={"Nunito"}
                        fontSize={"16px"}
                        fontStyle={"normal"}
                        fontWeight={"600"}
                        lineHeight={"normal"}
                        width={"100%"}
                      >
                        Future Real Estate Plans
                      </Text>
                      <DropDownButton
                        Heading={
                          "Do you have plans to sell your rental property?"
                        }
                        placeholder={"Select"}
                        dropDownItems={[
                          { key: "Yes", value: "Yes" },
                          { key: "No", value: "No" },
                        ]}
                        value={incomePropertyPlansToSell}
                        onChange={(key) => {
                          setIncomePropertyPlansToSell(key);
                        }}
                        customWidth={"100%"}
                        MenuCustomWidth={"100%"}
                        error={errors.incomePropertyPlansToSell}
                        tooltipContent={TooltipContent.incomePropertySaleYesNo}
                      />
                      {incomePropertyPlansToSell === "Yes" && (
                        <>
                          <DropDownButton
                            Heading={
                              "When do you anticipate you may sell your property?"
                            }
                            placeholder={"Select"}
                            dropDownItems={Select(currentAge)}
                            value={incomePropertyPlansToSellYear}
                            onChange={(key) => {
                              setIncomePropertyPlansToSellYear(key);
                            }}
                            customWidth={"100%"}
                            MenuCustomWidth={"100%"}
                            error={errors.incomePropertyPlansToSellYear}
                            tooltipContent={
                              TooltipContent.incomePropertySaleAge
                            }
                          />
                          <DropDownButton
                            Heading={"When did you purchase the property?"}
                            placeholder={"Select"}
                            dropDownItems={SelectYearsPast(currentAge)}
                            value={incomePropertyPurchaseYear}
                            onChange={(key) => {
                              setIncomePropertyPurchaseYear(key);
                            }}
                            customWidth={"100%"}
                            MenuCustomWidth={"100%"}
                            error={errors.incomePropertyPurchaseYear}
                            tooltipContent={
                              TooltipContent.incomePropertyPurchasePrice
                            }
                          />
                          <Text
                            alignSelf={"stretch"}
                            color={"var(--Optiml---Deep-Ocean-Blue, #007EB5)"}
                            fontFamily={"Nunito"}
                            fontSize={"16px"}
                            fontStyle={"normal"}
                            fontWeight={"600"}
                            lineHeight={"normal"}
                            width={"100%"}
                          >
                            Estimated Pre Tax Value of Property:{" "}
                            {currencyFormat(incomePropertyPreTaxProceeds)}
                          </Text>
                        </>
                      )}
                    </>
                  )}
                </Box>
                {data.length > 0 && !adding && (
                  <Box
                    display={"flex"}
                    justifyContent={"flex-start"}
                    alignItems={"center"}
                    alignSelf={"stretch"}
                  >
                    <Box
                      display={"flex"}
                      padding={"8px 48px"}
                      marginTop={"20px"}
                      justifyContent={"flex-end"}
                      alignItems={"center"}
                      gap={"5px"}
                      borderRadius={"5px"}
                      bg={"#27AE60"}
                      cursor={"pointer"}
                      onClick={handleClick}
                    >
                      <Box
                        display={"flex"}
                        width={"76px"}
                        height={"21px"}
                        flexDirection={"column"}
                        justifyContent={"center"}
                      >
                        <Text
                          color={"#FFFFFF"}
                          textAlign={"center"}
                          fontFamily={"Nunito"}
                          fontSize={"16px"}
                          fontStyle={"normal"}
                          fontWeight={"600"}
                          lineHeight={"normal"}
                        >
                          Add
                        </Text>
                      </Box>
                    </Box>
                  </Box>
                )}
                {(data.length < 1 || adding) && (
                  <Box
                    display={"flex"}
                    justifyContent={"flex-end"}
                    alignItems={"center"}
                    alignSelf={"stretch"}
                  >
                    {renderAddUpdateRecreational()}
                  </Box>
                )}
              </>
            }
            videoName={"Income Property Page"}
            video={"Income_property.mp4"}
          />
        </VStack>
      )}
    </Flex>
  );
});

export default IncomePropertyPage;
