import React from 'react';
import HttpsRedirect from 'react-https-redirect';
import ReactDOM from 'react-dom/client';
import App from './App';
import './index.css';
import './styleguide.css';
import { Auth0Provider } from '@auth0/auth0-react';
import { config } from '@fortawesome/fontawesome-pro';
// import { Amplify } from 'aws-amplify';
// import awsExports from './aws-exports';
// Amplify.configure(awsExports);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <HttpsRedirect>
    <Auth0Provider
      domain={process.env.REACT_APP_AUTH0_DOMAIN}
      clientId={process.env.REACT_APP_AUTH0_CLIENT_ID}
      redirectUri={window.location.origin}
      audience={process.env.REACT_APP_AUDIENCE}
      scope="write:optimization"
    >
      <App />
    </Auth0Provider>
  </HttpsRedirect>,
);
