import { Box, Flex, Text, VStack, useMediaQuery } from "@chakra-ui/react";
import axios from "axios";
import React, {
  forwardRef,
  useContext,
  useEffect,
  useImperativeHandle,
  useState,
} from "react";
import { useStore } from "../../../store";
import { useAuth0 } from "@auth0/auth0-react";
import GlobalBodyPage from "../../GlobalContainer/BodyPage";
import DropDownButton from "../../InputFields/dropDownButton";
import NormalInputButton from "../../InputFields/normalInputButton";
import TableComponent from "../../TableComponent";
import TooltipContent from "../../TooltipContent";
import { CloseIcon } from "../../CustomIcons";

const InheritancePage = forwardRef((props, ref) => {
  const { getAccessTokenSilently, user } = useAuth0();

  useImperativeHandle(ref, () => ({
    submitHandler,
    checkMandatoryFields,
  }));

  const submitHandler = () => {
    setUserEditAssetInfo({});
    return true;
  };

  const checkMandatoryFields = () => {
    return true;
  };

  const axiosInstance = axios.create();
  async function getToken() {
    // Replace this with your Auth0 token retrieval logic
    const token = await getAccessTokenSilently({
      audience:
        "https://os2lsiwff43wfpb5ggyawt6qri0mgcro.lambda-url.us-east-2.on.aws/",
      scope: "write:optimization",
    });
    return token;
  }

  // Add a request interceptor
  axiosInstance.interceptors.request.use(
    async (config) => {
      const token = await getToken();
      config.headers.Authorization = `Bearer ${token}`;
      return config;
    },
    (error) => {
      // Do something with request error
      return Promise.reject(error);
    }
  );
  const userEditAssetInfo = useStore((store) => store.userEditAssetInfo);
  const setUserEditAssetInfo = useStore((store) => store.setUserEditAssetInfo);

  const userInheritanceInfo = useStore((store) => store.userInheritanceInfo);
  const setUserInheritanceInfo = useStore(
    (store) => store.setUserInheritanceInfo
  );
  const updateUserInheritanceInfo = useStore(
    (store) => store.updateUserInheritanceInfo
  );
  const deleteUserInheritanceInfo = useStore(
    (store) => store.deleteUserInheritanceInfo
  );
  const [inheritanceArray, setInheritanceArray] = useState([]);

  const [InheritanceName, setInheritanceName] = useState("");
  const [InheritanceValue, setInheritanceValue] = useState(0);
  const [InheritanceStartDate, setInheritanceStartDate] = useState("");
  const [InheritanceNumber, setInheritanceNumber] = useState();

  const [editing, setEditing] = useState(false);
  const [editingIndex, setEditingIndex] = useState(null);
  const [showDeleteModal, setShowDeleteModal] = useState(null);

  const [adding, setAdding] = useState(false);

  const userInfo = useStore((store) => store.userInfo);

  const {
    FirstName: firstName,
    LastName: lastName,
    DateOfBirth: currentAge,
    spouseFirstName,
    spouseLastName,
    runAge,
  } = userInfo;

  useEffect(() => {
    console.log(userEditAssetInfo);
    if (userEditAssetInfo?.editAsset?.name === "Inheritance") {
      setInheritanceName(userEditAssetInfo?.editAsset?.inheritanceName);
      setInheritanceValue(userEditAssetInfo?.editAsset?.value);
      setInheritanceStartDate(userEditAssetInfo?.editAsset?.startAge);
      setInheritanceNumber(userEditAssetInfo?.editAsset?.InheritanceNumber);
      setEditing(true);
      setAdding(true);
      setEditingIndex(userEditAssetInfo?.editIndex);
    }
  }, [userEditAssetInfo]);

  useEffect(() => {
    const updateInheritanceArray = [];

    if (userInheritanceInfo != null) {
      updateInheritanceArray.push(...userInheritanceInfo);
    }

    setInheritanceArray(updateInheritanceArray);
  }, [userInheritanceInfo]);

  const [isLargerThan768] = useMediaQuery("(min-width: 500px)");
  const isMobile = !isLargerThan768;

  const onDeleteRow = (i) => {
    setEditingIndex(i);
    setShowDeleteModal(true);
  };

  const editField = (i, array) => {
    setAdding(true);
    setInheritanceName(array[i]?.inheritanceName);
    setInheritanceValue(array[i]?.value);
    setInheritanceStartDate(array[i]?.startAge);
    setInheritanceNumber(array[i]?.InheritanceNumber);
    setEditing(true);
    setEditingIndex(i);
  };

  const DeleteRealEstateModal = () => {
    let activeArray = inheritanceArray;

    const removeRealEstateModal = () => {
      const InheritanceURL = (action) =>
        `${process.env.REACT_APP_NODE_API_GATEWAY}/CompassLambdaApi/INHERITANCE/${action}?InheritanceNumber=${activeArray[editingIndex].InheritanceNumber}`;
      axiosInstance.get(InheritanceURL("delete")).then((res) => {
        console.log("Delete res", res);
        deleteUserInheritanceInfo(
          inheritanceArray[editingIndex].InheritanceNumber
        );
        setShowDeleteModal(false);
        setEditingIndex(null);
        setEditing(false);
      });
    };

    if (!showDeleteModal) {
      return null;
    }

    const [yesClicked, setYesClicked] = useState(false);
    const [noClicked, setNoClicked] = useState(false);
    return (
      <div
        className="delete-income-modal"
        style={{
          display: "flex",
          alignItems: "center",
          width: "100%",
          maxWidth: "700px",
          justifyContent: "center",
          top: "50%",
          left: "50%",
          transform: isMobile
            ? "translate(-50%, -50%)"
            : "translate(-50%, -50%)",
        }}
        id="delete-income-modal"
      >
        <Box
          display={"flex"}
          width={"100%"}
          padding={"10px 10px 0px 0px"}
          marginLeft={"5px"}
          alignItems={"flex-end"}
          justifyContent={"flex-end"}
          onClick={() => setShowDeleteModal(false)}
          cursor={"pointer"}
        >
          <CloseIcon
            onClick={() => setShowDeleteModal(false)}
            svgheight={"20px"}
            svgwidth={"20px"}
          />
        </Box>
        <Box
          display={"flex"}
          padding={"0px 25px 25px 25px"}
          flexDirection={"column"}
          alignItems={"center"}
          gap={"10px"}
          alignSelf={"stretch"}
          width={"100%"}
        >
          <Text
            color={"#0093D0"}
            fontFamily={"Nunito"}
            fontSize={"24px"}
            fontStyle={"normal"}
            fontWeight={"600"}
            lineHeight={"normal"}
            textDecor={"underline"}
          >
            Are you sure you want to remove this inheritance?
          </Text>
          <div className="content"></div>
          <div className="actions">
            <button
              className={`decision-button yes-button ${
                yesClicked ? "yes-clicked" : ""
              }`}
              onClick={() => {
                setYesClicked(true);
                setNoClicked(false);
                removeRealEstateModal();
              }}
            >
              Yes
            </button>
            <button
              className={`decision-button no-button ${
                noClicked ? "yes-clicked" : ""
              }`}
              onClick={() => {
                {
                  setNoClicked(true);
                  setYesClicked(false);
                  setShowDeleteModal(false);
                }
              }}
            >
              No
            </button>
          </div>
        </Box>
      </div>
    );
  };

  const moneyToNumber = (value) => {
    let numValue = value.toString().replace(/[$,\s]/g, "");
    numValue = Number(numValue);
    return numValue;
  };

  const onSubmitInheritanceAdd = (e, newArray) => {
    e.preventDefault();
    const primaryResURL = (action) =>
      `${
        process.env.REACT_APP_NODE_API_GATEWAY
      }/CompassLambdaApi/INHERITANCE/${action}?InheritanceID=${user.sub.replace(
        "|",
        "%7c"
      )}&value=${newArray.value}&name=${newArray.name}&startAge=${
        newArray.startAge
      }&inheritanceName=${newArray.inheritanceName}`;
    axiosInstance
      .get(primaryResURL("add"))
      .then((res) => {
        console.log(res);
        const newData = [
          {
            value: newArray.value,
            name: newArray.name,
            inheritanceName: newArray.inheritanceName,
            startAge: newArray.startAge,
            InheritanceNumber: res.data.lastInsertedId,
          },
        ];
        setUserInheritanceInfo(newData);
        setUserEditAssetInfo({});
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const onSubmitInheritanceUpdate = (e, newArray) => {
    e.preventDefault();
    let x = newArray.InheritanceNumber;
    const otherAssetsURL = (action) =>
      `${
        process.env.REACT_APP_NODE_API_GATEWAY
      }/CompassLambdaApi/INHERITANCE/${action}?InheritanceID=${user.sub.replace(
        "|",
        "%7c"
      )}&value=${newArray.value}&name=${newArray.name}&startAge=${
        newArray.startAge
      }&inheritanceName=${newArray.inheritanceName}&InheritanceNumber=${
        newArray.InheritanceNumber
      }`;
    axiosInstance
      .get(otherAssetsURL("update"))
      .then((res) => {
        console.log(res);
        const newData = {
          value: newArray.value,
          name: newArray.name,
          inheritanceName: newArray.inheritanceName,
          startAge: newArray.startAge,
          InheritanceNumber: newArray.InheritanceNumber,
        };
        updateUserInheritanceInfo(x, newData);
        setUserEditAssetInfo({});
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const addInheritance = (e, list) => {
    if (editing) {
      setInheritanceArray((prevArray) => {
        let newArray = [...prevArray];
        newArray[editingIndex] = list;
        onSubmitInheritanceUpdate(e, newArray[editingIndex]);
        return newArray;
      });
      setEditing(false);
      setEditingIndex(null);
    } else {
      setInheritanceArray((prevArray) => {
        let newArray = [...prevArray, list];
        onSubmitInheritanceAdd(e, newArray[newArray.length - 1]);
        return newArray;
      });
    }
    setInheritanceValue(0);
    setInheritanceStartDate("");
    setAdding(false);
  };

  const [errors, setErrors] = useState({});

  const checkMandatoryAddUpdateFields = () => {
    let newErrors = {};

    if (!InheritanceName) {
      newErrors.InheritanceName = "Name required";
    }
    if (!InheritanceValue) {
      newErrors.InheritanceValue = "Value is required";
    }
    if (!InheritanceStartDate) {
      newErrors.InheritanceStartDate = "Estimated date is required";
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0; // Return true if no errors
  };

  const renderAddUpdateRecreational = () => {
    if (!InheritanceValue || !InheritanceStartDate) {
      return (
        <Box
          display={"flex"}
          padding={"8px 48px"}
          marginTop={"20px"}
          justifyContent={"flex-end"}
          alignItems={"center"}
          gap={"5px"}
          borderRadius={"5px"}
          bg={"#27AE60"}
          cursor={"pointer"}
          onClick={checkMandatoryAddUpdateFields}
        >
          <Box
            display={"flex"}
            width={"76px"}
            height={"21px"}
            flexDirection={"column"}
            justifyContent={"center"}
          >
            <Text
              color={"#FFFFFF"}
              textAlign={"center"}
              fontFamily={"Nunito"}
              fontSize={"16px"}
              fontStyle={"normal"}
              fontWeight={"600"}
              lineHeight={"normal"}
            >
              {editing ? "Update" : "Add"}
            </Text>
          </Box>
        </Box>
      );
    } else {
      return (
        <Box
          display={"flex"}
          padding={"8px 48px"}
          marginTop={"20px"}
          justifyContent={"flex-end"}
          alignItems={"center"}
          gap={"5px"}
          borderRadius={"5px"}
          bg={"#27AE60"}
          cursor={"pointer"}
          onClick={(e) =>
            addInheritance(e, {
              name: "Inheritance",
              inheritanceName: InheritanceName,
              value: InheritanceValue,
              startAge: InheritanceStartDate,
              ...(editing ? { InheritanceNumber: InheritanceNumber } : {}), // add field conditionally
            })
          }
        >
          <Box
            display={"flex"}
            width={"76px"}
            height={"21px"}
            flexDirection={"column"}
            justifyContent={"center"}
            cursor={"pointer"}
          >
            <Text
              color={"#FFFFFF"}
              textAlign={"center"}
              fontFamily={"Nunito"}
              fontSize={"16px"}
              fontStyle={"normal"}
              fontWeight={"600"}
              lineHeight={"normal"}
            >
              {editing ? "Update" : "Add"}
            </Text>
          </Box>
        </Box>
      );
    }
  };

  const columns = [
    { header: "Name", field: "name" },
    { header: "Value", field: "value" },
  ];

  const data = inheritanceArray.map((item) => ({
    name: item.inheritanceName,
    value: item?.value,
    link: "/inheritance-form",
  }));

  const handleClick = () => {
    setAdding(true);
  };

  function getAge(birthday, currentDate) {
    // Convert the birthday string into a Date object
    const birthDate = new Date(birthday);

    // Calculate the age in years
    let age = currentDate.getFullYear() - birthDate.getFullYear();

    // Decrement the age if the current month and day are before the birth month and day
    if (
      currentDate.getMonth() < birthDate.getMonth() ||
      (currentDate.getMonth() === birthDate.getMonth() &&
        currentDate.getDate() <= birthDate.getDate())
    ) {
      age -= 1;
    }

    return age;
  }

  const Select = (userAge) => {
    const currentDate = new Date();
    const currentYear = currentDate.getFullYear();
    let age = getAge(userAge, currentDate); // Assuming getAge returns the starting age.
    const runAgeList = [];
    for (let i = age; i <= 100; i++) {
      runAgeList.push({ key: i, value: currentYear + (i - age) }); // Convert i to string if necessary
    }
    return runAgeList;
  };

  return (
    <Flex
      display="flex"
      flexWrap="wrap" // Enable wrapping
      justifyContent="flex-start"
      alignItems="start"
      width={"100%"}
    >
      {!isMobile && (
        <VStack alignItems={"flex-start"} width={"100%"} mt={-2}>
          <GlobalBodyPage
            Heading="Inheritance"
            mainframeContent={
              <>
                <Box
                  display={"flex"}
                  width={"100%"}
                  flexDirection={"row"}
                  alignItems={"flex-end"}
                  justifyContent={"space-between"}
                  gap={"10px"}
                  flexWrap={"wrap"}
                >
                  <DeleteRealEstateModal />
                  {data.length > 0 && !editing && !adding && (
                    <TableComponent
                      columns={columns}
                      data={data}
                      editField={editField}
                      onDeleteRow={onDeleteRow}
                      rawData={inheritanceArray}
                    />
                  )}
                  {(data.length < 1 || adding) && (
                    <>
                      <NormalInputButton
                        Heading={"Title/Name"}
                        value={InheritanceName}
                        onChange={(e) => {
                          setInheritanceName(e.target.value);
                        }}
                        customWidth={"100%"}
                        InputWidth={"100%"}
                        error={errors.InheritanceName}
                      />
                      <NormalInputButton
                        Heading={"After tax inheritance value"}
                        value={InheritanceValue}
                        onChange={(e) => {
                          setInheritanceValue(moneyToNumber(e.target.value));
                        }}
                        customWidth={"100%"}
                        placeholder={"$"}
                        useThousandSeparator={true}
                        error={errors.InheritanceValue}
                        tooltipContent={TooltipContent.inheritanceValue}
                      />
                      <DropDownButton
                        Heading={"When will you receive the inheritance?"}
                        placeholder={"Select"}
                        dropDownItems={Select(currentAge)}
                        customWidth={"100%"}
                        value={InheritanceStartDate}
                        onChange={(key) => {
                          setInheritanceStartDate(key);
                        }}
                        error={errors.InheritanceStartDate}
                        tooltipContent={TooltipContent.inheritanceStart}
                      />
                    </>
                  )}
                </Box>
                {data.length > 0 && !adding && (
                  <Box
                    display={"flex"}
                    justifyContent={"flex-start"}
                    alignItems={"center"}
                    alignSelf={"stretch"}
                  >
                    <Box
                      display={"flex"}
                      padding={"8px 48px"}
                      marginTop={"20px"}
                      justifyContent={"flex-end"}
                      alignItems={"center"}
                      gap={"5px"}
                      borderRadius={"5px"}
                      bg={"#27AE60"}
                      cursor={"pointer"}
                      onClick={handleClick}
                    >
                      <Box
                        display={"flex"}
                        width={"76px"}
                        height={"21px"}
                        flexDirection={"column"}
                        justifyContent={"center"}
                      >
                        <Text
                          color={"#FFFFFF"}
                          textAlign={"center"}
                          fontFamily={"Nunito"}
                          fontSize={"16px"}
                          fontStyle={"normal"}
                          fontWeight={"600"}
                          lineHeight={"normal"}
                        >
                          Add
                        </Text>
                      </Box>
                    </Box>
                  </Box>
                )}
                {(data.length < 1 || adding) && (
                  <Box
                    display={"flex"}
                    justifyContent={"flex-end"}
                    alignItems={"center"}
                    alignSelf={"stretch"}
                  >
                    {renderAddUpdateRecreational()}
                  </Box>
                )}
              </>
            }
          />
        </VStack>
      )}
      {isMobile && (
        <VStack alignItems={"flex-start"} width={"100%"} mt={-2}>
          <GlobalBodyPage
            Heading="Land"
            mainframeContent={
              <>
                <Box
                  display={"flex"}
                  width={"100%"}
                  flexDirection={"row"}
                  alignItems={"flex-end"}
                  justifyContent={"space-between"}
                  gap={"10px"}
                  flexWrap={"wrap"}
                >
                  <DeleteRealEstateModal />
                  {data.length > 0 && !editing && !adding && (
                    <TableComponent
                      columns={columns}
                      data={data}
                      editField={editField}
                      onDeleteRow={onDeleteRow}
                      rawData={inheritanceArray}
                    />
                  )}
                  {(data.length < 1 || adding) && (
                    <>
                      <NormalInputButton
                        Heading={"Title/Name"}
                        value={InheritanceName}
                        onChange={(e) => {
                          setInheritanceName(e.target.value);
                        }}
                        customWidth={"100%"}
                        InputWidth={"100%"}
                        error={errors.InheritanceName}
                      />
                      <NormalInputButton
                        Heading={"After tax inheritance value"}
                        value={InheritanceValue}
                        onChange={(e) => {
                          setInheritanceValue(moneyToNumber(e.target.value));
                        }}
                        customWidth={"100%"}
                        placeholder={"$"}
                        useThousandSeparator={true}
                        InputWidth={"100%"}
                        error={errors.InheritanceValue}
                        tooltipContent={TooltipContent.inheritanceValue}
                      />
                      <DropDownButton
                        Heading={"When will you receive the inheritance?"}
                        placeholder={"Select"}
                        dropDownItems={Select(currentAge)}
                        customWidth={"100%"}
                        MenuCustomWidth={"100%"}
                        value={InheritanceStartDate}
                        onChange={(key) => {
                          setInheritanceStartDate(key);
                        }}
                        error={errors.InheritanceStartDate}
                        tooltipContent={TooltipContent.inheritanceStart}
                      />
                    </>
                  )}
                </Box>
                {data.length > 0 && !adding && (
                  <Box
                    display={"flex"}
                    justifyContent={"flex-start"}
                    alignItems={"center"}
                    alignSelf={"stretch"}
                  >
                    <Box
                      display={"flex"}
                      padding={"8px 48px"}
                      marginTop={"20px"}
                      justifyContent={"flex-end"}
                      alignItems={"center"}
                      gap={"5px"}
                      borderRadius={"5px"}
                      bg={"#27AE60"}
                      cursor={"pointer"}
                      onClick={handleClick}
                    >
                      <Box
                        display={"flex"}
                        width={"76px"}
                        height={"21px"}
                        flexDirection={"column"}
                        justifyContent={"center"}
                      >
                        <Text
                          color={"#FFFFFF"}
                          textAlign={"center"}
                          fontFamily={"Nunito"}
                          fontSize={"16px"}
                          fontStyle={"normal"}
                          fontWeight={"600"}
                          lineHeight={"normal"}
                        >
                          Add
                        </Text>
                      </Box>
                    </Box>
                  </Box>
                )}
                {(data.length < 1 || adding) && (
                  <Box
                    display={"flex"}
                    justifyContent={"flex-end"}
                    alignItems={"center"}
                    alignSelf={"stretch"}
                  >
                    {renderAddUpdateRecreational()}
                  </Box>
                )}
              </>
            }
          />
        </VStack>
      )}
    </Flex>
  );
});

export default InheritancePage;
