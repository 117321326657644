import { Box, Flex, Text, VStack, useMediaQuery } from "@chakra-ui/react";
import { useAuth0 } from "@auth0/auth0-react";
import axios from "axios";
import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useContext,
  useState,
} from "react";
import ExpenseGraph from "../../ExpenseGraph";
import ExpenseGrowthTable from "../../ExpenseGrowthTable";
import { SpouseContext } from "../../SpouseContext";
import { useStore } from "../../../store";
import GlobalBodyPage from "../../GlobalContainer/BodyPage";
import DropDownButton from "../../InputFields/dropDownButton";
import NormalInputButton from "../../InputFields/normalInputButton";
import TableComponent from "../../TableComponent";
import TooltipContent from "../../TooltipContent";
import { CloseIcon } from "../../CustomIcons";

const HouseHoldExpensesForm = forwardRef((props, ref) => {
  const { setChangedData } = props;
  const { getAccessTokenSilently, user } = useAuth0();

  useImperativeHandle(ref, () => ({
    submitHandler,
    checkMandatoryFields,
  }));

  const axiosInstance = axios.create();
  async function getToken() {
    // Replace this with your Auth0 token retrieval logic
    const token = await getAccessTokenSilently({
      audience:
        "https://os2lsiwff43wfpb5ggyawt6qri0mgcro.lambda-url.us-east-2.on.aws/",
      scope: "write:optimization",
    });
    return token;
  }

  // Add a request interceptor
  axiosInstance.interceptors.request.use(
    async (config) => {
      const token = await getToken();
      config.headers.Authorization = `Bearer ${token}`;
      return config;
    },
    (error) => {
      // Do something with request error
      return Promise.reject(error);
    }
  );
  const [loading, setLoading] = useState(false);
  const { spouseStatus, setSpouseStatus } = useContext(SpouseContext);
  const [expensesArrayTotal, setExpensesArrayTotal] = useState([]);
  const [expenseGrowthOverYears, setExpenseGrowthOverYears] = useState([]);
  const [
    totalExpenseOverYearsMortgages,
    setTotalExpenseGrowthOverYearsMortgages,
  ] = useState([]);
  const [totalExpenseOverYearsDebt, setTotalExpenseGrowthOverYearsDebt] =
    useState([]);
  const [
    totalExpenseOverYearsInsurance,
    setTotalExpenseGrowthOverYearsInsurance,
  ] = useState([]);

  const userInfo = useStore((store) => store.userInfo);
  const setUserInfo = useStore((store) => store.setUserInfo);
  const userExpensesInfo = useStore((store) => store.userExpensesInfo);
  const userDebtInfo = useStore((store) => store.userDebtInfo);
  const userCottageInfo = useStore((store) => store.userCottageInfo);
  const userPrimaryResidenceInfo = useStore(
    (store) => store.userPrimaryResidenceInfo
  );
  const userPropertiesInfo = useStore((store) => store.userPropertiesInfo);
  const userLandInfo = useStore((store) => store?.userLandInfo);
  const setUserExpensesInfo = useStore((store) => store.setUserExpensesInfo);
  const userLifeInsuranceInfo = useStore(
    (store) => store.userLifeInsuranceInfo
  );

  const deleteUserPrimaryResidenceInfo = useStore(
    (store) => store.deleteUserPrimaryResidenceInfo
  );
  const deleteUserCottageInfo = useStore(
    (store) => store.deleteUserCottageInfo
  );
  const deleteUserPropertiesInfo = useStore(
    (store) => store.deleteUserPropertiesInfo
  );
  const deleteUserLandInfo = useStore((store) => store?.deleteUserLandInfo);
  const deleteUserLifeInsuranceInfo = useStore(
    (store) => store.deleteUserLifeInsuranceInfo
  );
  const deleteUserDebtInfo = useStore((store) => store.deleteUserDebtInfo);

  const userEditAssetInfo = useStore((store) => store.userEditAssetInfo);
  const setUserEditAssetInfo = useStore((store) => store.setUserEditAssetInfo);

  const setUserPageLockInfo = useStore((store) => store.setUserPageLockInfo);
  const userPageLockInfo = useStore((store) => store.userPageLockInfo);

  const {
    FirstName: firstName,
    LastName: lastName,
    DateOfBirth: currentAge,
    spouseFirstName,
    spouseLastName,
    spouseDateOfBirth: spouseAge,
  } = userInfo;

  const [editing, setEditing] = useState(false);
  const [editingIndex, setEditingIndex] = useState(null);
  const [showDeleteModal, setShowDeleteModal] = useState(null);

  const [otherExpensesTotal, setOtherExpensesTotal] = useState(0);
  const [expenseGrowthRate, setExpenseGrowthRate] = useState("");
  const [CPI, setCPI] = useState(2);
  const [runAge, setRunAge] = useState();

  const [isLargerThan768] = useMediaQuery("(min-width: 500px)");
  const isMobile = !isLargerThan768;

  const yearToDate = (year) => {
    const date = new Date();
    date.setFullYear(year);
    return date;
  };

  const currentDate = new Date();
  const userAge = getAge(currentAge, currentDate);

  useEffect(() => {
    if (Number(runAge) <= userAge) {
      setRunAge();
    }
  }, [runAge, userAge]);

  const calculateTotalExpenses = (expenses) => {
    const additionalExpensesMortgages =
      calcAdditionalExpensesMortgages(expenses);
    const additionalExpensesDebt = calcAdditionalExpensesDebt(expenses);
    const additionalExpensesInsurance =
      calcAdditionalExpensesInsurance(expenses);
    setTotalExpenseGrowthOverYearsMortgages(additionalExpensesMortgages);
    setTotalExpenseGrowthOverYearsDebt(additionalExpensesDebt);
    setTotalExpenseGrowthOverYearsInsurance(additionalExpensesInsurance);
  };

  const getExpenseYears = () => {
    const currentDate = new Date();
    const currentYear = currentDate.getFullYear();
    const userAge = getAge(currentAge, currentDate);
    const tmpRunAge = runAge;
    const fullRun = tmpRunAge - userAge;
    if (fullRun > 0 && Number.isInteger(fullRun)) {
      return [...Array(fullRun).keys()].map((i) => i + currentYear);
    } else {
      // Return an empty array or handle the error appropriately
      return [];
    }
  };

  useEffect(() => {
    const updatedExpenseTotalArray = [];
    setUserEditAssetInfo({});

    if (userPrimaryResidenceInfo?.length >= 1) {
      updatedExpenseTotalArray?.push(userPrimaryResidenceInfo?.[0]);
    }

    if (userCottageInfo?.length >= 1) {
      updatedExpenseTotalArray?.push(...userCottageInfo);
    }

    if (userPropertiesInfo?.length >= 1) {
      updatedExpenseTotalArray?.push(...userPropertiesInfo);
    }

    if (userLandInfo?.length >= 1) {
      updatedExpenseTotalArray?.push(...userLandInfo);
    }

    if (userDebtInfo?.length >= 1) {
      updatedExpenseTotalArray?.push(...userDebtInfo);
    }

    if (userLifeInsuranceInfo?.length >= 1) {
      updatedExpenseTotalArray?.push(...userLifeInsuranceInfo);
    }
    setExpensesArrayTotal(updatedExpenseTotalArray);
    if (runAge) {
      calculateTotalExpenses(updatedExpenseTotalArray);
    }
  }, [
    userPrimaryResidenceInfo,
    userCottageInfo,
    userPropertiesInfo,
    userLandInfo,
    userDebtInfo,
    userLifeInsuranceInfo,
    loading,
    runAge,
    expenseGrowthRate,
    CPI,
  ]);

  useEffect(() => {
    if (!runAge && Number(userInfo?.runAge) > userAge) {
      setRunAge(Number(userInfo?.runAge));
    }
    if (userExpensesInfo) {
      setOtherExpensesTotal(userExpensesInfo?.total);
      setExpenseGrowthRate(userExpensesInfo?.growth);
      setCPI(userExpensesInfo?.expenseCPI ?? 2);
      setExpenseGrowthOverYears(userExpensesInfo?.expenseArray);
      setLoading(true);
    } else {
      setExpenseGrowthOverYears([]);
      setLoading(true);
    }
  }, []);

  useEffect(() => {
    if (typeof CPI != "undefined") {
      setTimeout(() => {
        if (userExpensesInfo?.total > 0) {
          if (
            userExpensesInfo?.total !== otherExpensesTotal ||
            userExpensesInfo?.expenseCPI !== CPI ||
            Number(userInfo?.runAge) !== runAge ||
            userInfo?.DateOfBirth !== currentAge ||
            userExpensesInfo?.growth !== expenseGrowthRate ||
            userExpensesInfo?.expenseArray.length !==
              calculateAnnualGrowthArray(
                otherExpensesTotal,
                CPI,
                getExpenseYears("primary").length,
                "primary",
                expenseGrowthRate
              ).length
          ) {
            if (
              typeof otherExpensesTotal !== "undefined" &&
              typeof CPI !== "undefined" &&
              typeof runAge !== "undefined" &&
              typeof expenseGrowthRate !== "undefined"
            ) {
              calculateAnnualGrowthArray(
                otherExpensesTotal,
                CPI,
                getExpenseYears("primary").length,
                "primary",
                expenseGrowthRate
              );
            }
          } else if (
            userExpensesInfo?.total == otherExpensesTotal ||
            userExpensesInfo?.expenseCPI == CPI ||
            Number(userInfo?.runAge) == runAge ||
            userExpensesInfo?.growth !== expenseGrowthRate
          ) {
            setExpenseGrowthOverYears(userExpensesInfo?.expenseArray);
            calculateTotalExpenses(expensesArrayTotal);
          }
        } else {
          calculateAnnualGrowthArray(
            otherExpensesTotal,
            CPI,
            getExpenseYears("primary").length,
            "primary",
            expenseGrowthRate
          );
        }
      }, 10);
    }
  }, [otherExpensesTotal, CPI, runAge, expenseGrowthRate]);

  function calculateAnnualGrowthArray(
    initialValue,
    percentage,
    years,
    user,
    expenseGrowthRate
  ) {
    if (typeof initialValue === "string") {
      initialValue = moneyToNumber(initialValue);
    }
    const growthArray = [initialValue];
    const currentDate = new Date();

    if (expenseGrowthRate == "Consumer Expense Index") {
      let currentExpenses = initialValue;
      let userAge = getAge(currentAge, currentDate);

      for (let year = 1; year < years; year++) {
        // Start from year 1 as initial expenses are already in the array
        let ageFactor = 1;

        if (userAge < 35) {
          ageFactor += (92286 - 75494) / (75494 * 9); // Average annual growth until age 35
        } else if (userAge >= 35 && userAge < 45) {
          ageFactor += (107072 - 92286) / (92286 * 10); // Average annual growth from age 35 to 45
        } else if (userAge >= 45 && userAge < 55) {
          ageFactor += (90092 - 107072) / (107072 * 10); // Average annual decrease from age 45 to 55
        } else if (userAge >= 55 && userAge < 65) {
          ageFactor += (63789 - 90092) / (90092 * 10); // Average annual decrease from age 55 to 65
        } else if (userAge >= 65) {
          ageFactor -= (90092 - 63789) / (90092 * 10); // Constant annual decrease after 65
        }

        // Apply the age factor for this year's expenses
        currentExpenses *= ageFactor;
        // Apply the general inflation or expense growth percentage
        currentExpenses *= 1 + percentage / 100;
        growthArray.push(parseFloat(currentExpenses.toFixed(2))); // Rounded to two decimal places

        userAge++;
      }
    } else {
      for (let i = 1; i < years; i++) {
        const currentValue = growthArray[i - 1] * (1 + percentage / 100);
        growthArray.push(parseInt(currentValue.toFixed(2)));
      }
    }

    setExpenseGrowthOverYears(growthArray);

    return growthArray;
  }

  const calcAdditionalExpensesMortgages = (expenses) => {
    // Initialize the result array with zeros
    if (!runAge || Number(runAge) <= userAge) {
      return null;
    }
    const currentDate = new Date();
    const result = new Array(
      runAge - getAge(currentAge, currentDate) || 0
    ).fill(0);

    expenses?.forEach((expense) => {
      if (!expense) return;
      // Destructure with default values to avoid undefined issues
      let {
        Primary_Res_Mortgage_Balance = 0,
        Primary_Res_Monthly_Payments = 0,
        Primary_Res_Growth = 0,
        Cottage_Mortgage_Balance = 0,
        Cottage_Monthly_Payments = 0,
        Cottage_Growth = 0,
        Income_Property_Mortgage_Balance = 0,
        Income_Property_Mortgage_Payments = 0,
        Income_Property_Mortgage_Rate = 0,
      } = expense;

      const userAge = getAge(currentAge, currentDate);

      // Use logical OR to fall back to 0 if all values are falsy
      let mortgage =
        Primary_Res_Monthly_Payments * 12 ||
        Cottage_Monthly_Payments * 12 ||
        Income_Property_Mortgage_Payments * 12 ||
        0;
      let mortgage_balance =
        Primary_Res_Mortgage_Balance ||
        Cottage_Mortgage_Balance ||
        Income_Property_Mortgage_Balance ||
        0;
      let mortgage_growth =
        Primary_Res_Growth ||
        Cottage_Growth ||
        Income_Property_Mortgage_Rate ||
        0;

      let year = 0;

      // Ensure mortgage_growth is applied correctly by checking for growth rates explicitly defined as 0
      mortgage_growth = mortgage_growth === 0 ? 0 : mortgage_growth || 0;

      // Process while mortgage_balance is positive and within the expenseGrowthOverYears length
      while (
        mortgage_balance > 0 &&
        year < runAge - getAge(currentAge, currentDate)
      ) {
        // Subtract mortgage from the mortgage balance
        mortgage_balance -= mortgage;

        // Add the mortgage value to the corresponding year in the result array
        result[year] += mortgage;

        // Increase the mortgage balance by the mortgage growth for the next iteration
        // Ensure that mortgage_growth is a percentage (if applicable) and applied correctly
        mortgage_balance += (mortgage_balance * mortgage_growth) / 100 || 0;

        // Move to the next year
        year++;
      }
    });

    return result;
  };

  const calcAdditionalExpensesDebt = (expenses) => {
    // Initialize the result array with zeros
    if (!runAge || Number(runAge) <= userAge) {
      return null;
    }
    const currentDate = new Date();
    const result = new Array(
      runAge - getAge(currentAge, currentDate) || 0
    ).fill(0);
    expenses?.forEach((expense) => {
      if (!expense) return;
      // Destructure with default values to avoid undefined issues
      let {
        Amount_Borrowed = 0,
        Debt_Monthly_Payments = 0,
        Debt_Interest = 0,
      } = expense;

      const userAge = getAge(currentAge, currentDate);

      // Use logical OR to fall back to 0 if all values are falsy
      let mortgage = Debt_Monthly_Payments * 12 || 0;
      let mortgage_balance = Amount_Borrowed || 0;
      let mortgage_growth = Debt_Interest || 0;

      let year = 0;

      // Ensure mortgage_growth is applied correctly by checking for growth rates explicitly defined as 0
      mortgage_growth = mortgage_growth === 0 ? 0 : mortgage_growth || 0;

      // Process while mortgage_balance is positive and within the expenseGrowthOverYears length
      while (
        mortgage_balance > 0 &&
        year < runAge - getAge(currentAge, currentDate)
      ) {
        // Subtract mortgage from the mortgage balance
        mortgage_balance -= mortgage;

        // Add the mortgage value to the corresponding year in the result array
        result[year] += mortgage;

        // Increase the mortgage balance by the mortgage growth for the next iteration
        // Ensure that mortgage_growth is a percentage (if applicable) and applied correctly
        mortgage_balance += (mortgage_balance * mortgage_growth) / 100 || 0;

        // Move to the next year
        year++;
      }
    });

    return result;
  };

  const calcAdditionalExpensesInsurance = (expenses) => {
    // Initialize the result array with zeros
    if (!runAge || Number(runAge) <= userAge) {
      return null;
    }
    const currentDate = new Date();
    const result = new Array(
      runAge - getAge(currentAge, currentDate) || 0
    ).fill(0);

    expenses?.forEach((expense) => {
      if (!expense) return;
      // Destructure with default values to avoid undefined issues
      let { Monthly_Premiums = 0, Life_Insurance_End_Date = 0 } = expense;
      const userAge = getAge(currentAge, currentDate);
      const lifePayments =
        (Life_Insurance_End_Date
          ? Life_Insurance_End_Date
          : runAge
          ? runAge
          : 100) - userAge;

      // Use logical OR to fall back to 0 if all values are falsy
      let mortgage = Monthly_Premiums * 12 || 0;
      let mortgage_balance = lifePayments * (Monthly_Premiums * 12) || 0;
      let mortgage_growth = 0;

      let year = 0;

      // Ensure mortgage_growth is applied correctly by checking for growth rates explicitly defined as 0
      mortgage_growth = mortgage_growth === 0 ? 0 : mortgage_growth || 0;

      // Process while mortgage_balance is positive and within the expenseGrowthOverYears length
      while (
        mortgage_balance > 0 &&
        year < runAge - getAge(currentAge, currentDate)
      ) {
        // Subtract mortgage from the mortgage balance
        mortgage_balance -= mortgage;

        // Add the mortgage value to the corresponding year in the result array
        result[year] += mortgage;

        // Increase the mortgage balance by the mortgage growth for the next iteration
        // Ensure that mortgage_growth is a percentage (if applicable) and applied correctly
        mortgage_balance += (mortgage_balance * mortgage_growth) / 100 || 0;

        // Move to the next year
        year++;
      }
    });

    return result;
  };

  const moneyToNumber = (value) => {
    if (
      value === null ||
      value === undefined ||
      value.toString().trim() === ""
    ) {
      return 0;
    }
    let numValue = value.toString().replace(/[$,\s]/g, "");
    numValue = Number(numValue);
    return numValue;
  };

  const currencyFormat = (money) => {
    if (money === 0) {
      return "$0"; // Handle zero explicitly
    } else if (money === "") {
      return "";
    } else {
      const intTotal = Math.round(Math.round(money * 100) / 100);
      const currencyCad = new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "USD",
        minimumFractionDigits: 0,
      }).format(intTotal);
      return currencyCad;
    }
  };

  const unlockSidebar = () => {
    //Set Page Lock Info In Database:
    const pageLockURL = (action) =>
      `${
        process.env.REACT_APP_NODE_API_GATEWAY
      }/CompassLambdaApi/NEW_USER_PAGE_LOCK/${action}?PageLockID=${user.sub.replace(
        "|",
        "%7c"
      )}&IncomePage=${1}&InvestmentsPage=${1}&ExpensesPage=${1}&OptimizationPage=${1}`;
    axiosInstance
      .get(pageLockURL("add"))
      .then((res) => {
        //Unlock Income Sidebar:
        setUserPageLockInfo({
          ...userPageLockInfo,
          OptimizationPage: 1,
        });
        console.log(res);
        if (res.data.code === "ER_DUP_ENTRY") {
          axiosInstance.get(pageLockURL("update")).then((res) => {
            console.log(res);
            //Unlock Income Sidebar:
            setUserPageLockInfo({
              ...userPageLockInfo,
              OptimizationPage: 1,
            });
          });
        }
      })
      .catch((error) => {
        console.log(error);
      });
    //
  };
  const [errors, setErrors] = useState({});

  const checkMandatoryFields = () => {
    let newErrors = {};
    if (!otherExpensesTotal)
      newErrors.otherExpensesTotal = "Total Living Expenses required";

    if (!runAge) newErrors.runAge = "Run to analysis age required";
    if (!expenseGrowthRate)
      newErrors.expenseGrowthRate = "Expenses growth option required";

    if (!CPI || CPI > 20)
      newErrors.CPI =
        "Rate of return is required and must be greater than 0% and less than 20%";

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0; // Return true if no errors
  };

  const submitHandler = (e) => {
    unlockSidebar();
    if (e) {
      e.preventDefault();
    }
    // setIsEditMode(!isEditMode);
    var expArr = JSON.stringify(expenseGrowthOverYears);
    const expenseURL = (action) =>
      `${
        process.env.REACT_APP_NODE_API_GATEWAY
      }/CompassLambdaApi/EXPENSES/${action}?ExpenseID=${user.sub.replace(
        "|",
        "%7c"
      )}&total=${otherExpensesTotal}&growth=${expenseGrowthRate}&expenseCPI=${CPI}&expenseArray=${expArr}`;
    axiosInstance
      .get(expenseURL("add"))
      .then((res) => {
        setUserExpensesInfo({
          ...userExpensesInfo,
          total: otherExpensesTotal,
          growth: expenseGrowthRate,
          expenseCPI: CPI,
          expenseArray: expArr,
        });
        console.log(res);
        if (res.data.code === "ER_DUP_ENTRY") {
          axiosInstance.get(expenseURL("update")).then((res) => {
            console.log(res);
            setUserExpensesInfo({
              ...userExpensesInfo,
              total: otherExpensesTotal,
              growth: expenseGrowthRate,
              expenseCPI: CPI,
              expenseArray: expArr,
            });
          });
        }
        axiosInstance
          .get(
            `${
              process.env.REACT_APP_NODE_API_GATEWAY
            }/CompassLambdaApi/USERS_RunAge/update?UserID=${user.sub.replace(
              "|",
              "%7c"
            )}&runAge=${runAge}`
          )
          .then((res) => {
            console.log(res);
            setUserInfo({
              ...userInfo,
              runAge: runAge,
            });
          })
          .catch((error) => {
            console.log(error);
          });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  function onUpdateGrowthOverYears(user, val) {
    setChangedData(true);
    setExpenseGrowthOverYears(val);
  }

  function getAge(birthday, currentDate) {
    // Get the current date
    // const currentDate = new Date();

    // Convert the birthday string into a Date object
    const birthDate = new Date(birthday);

    // Calculate the age in years
    let age = currentDate.getFullYear() - birthDate.getFullYear();

    // Decrement the age if the current month and day are before the birth month and day
    if (
      currentDate.getMonth() < birthDate.getMonth() ||
      (currentDate.getMonth() === birthDate.getMonth() &&
        currentDate.getDate() <= birthDate.getDate())
    ) {
      age -= 1;
    }

    return age;
  }

  const Select = (userAge) => {
    const currentDate = new Date();
    let age = getAge(userAge, currentDate) + 1; // Assuming getAge returns the starting age.
    const runAgeList = [];
    for (let i = age; i <= 100; i++) {
      runAgeList.push({ key: i, value: i }); // Convert i to string if necessary
    }
    return runAgeList;
  };

  const onDeleteRow = (i) => {
    setEditingIndex(i);
    setShowDeleteModal(true);
  };

  const DeleteRealEstateModal = () => {
    let activeArray = expensesArrayTotal;
    const removeRealEstateModal = () => {
      if (activeArray[editingIndex].name === "Primary residence") {
        const deletePrimaryResUrl = (action) =>
          `${process.env.REACT_APP_NODE_API_GATEWAY}/CompassLambdaApi/REAL_ESTATE/${action}?Primary_Res_Number=${activeArray[editingIndex].Primary_Res_Number}`;
        axiosInstance.get(deletePrimaryResUrl("delete")).then((res) => {
          console.log("Delete res", res);
          deleteUserPrimaryResidenceInfo(
            activeArray[editingIndex].Primary_Res_Number
          );

          setEditingIndex(null);
          setEditing(false);
          setShowDeleteModal(false);
        });
      } else if (activeArray[editingIndex].name === "Recreation property") {
        const deleteCottageURL = (action) =>
          `${process.env.REACT_APP_NODE_API_GATEWAY}/CompassLambdaApi/COTTAGE/${action}?Cottage_Number=${activeArray[editingIndex].Cottage_Number}`;
        axiosInstance.get(deleteCottageURL("delete")).then((res) => {
          console.log("Delete res", res);
          deleteUserCottageInfo(activeArray[editingIndex].Cottage_Number);
          setEditingIndex(null);
          setEditing(false);
          setShowDeleteModal(false);
        });
      } else if (activeArray[editingIndex].name === "Income property") {
        const deletePropertyURL = (action) =>
          `${process.env.REACT_APP_NODE_API_GATEWAY}/CompassLambdaApi/PROPERTIES/${action}?Property_Number=${activeArray[editingIndex].Property_Number}`;
        axiosInstance.get(deletePropertyURL("delete")).then((res) => {
          console.log("Delete res", res);
          deleteUserPropertiesInfo(activeArray[editingIndex].Property_Number);
          setEditingIndex(null);
          setEditing(false);
          setShowDeleteModal(false);
        });
      } else if (activeArray[editingIndex].name === "Land") {
        const deletePropertyURL = (action) =>
          `${process.env.REACT_APP_NODE_API_GATEWAY}/CompassLambdaApi/LAND/${action}?landNumber=${activeArray[editingIndex].landNumber}`;
        axiosInstance.get(deletePropertyURL("delete")).then((res) => {
          console.log("Delete res", res);
          deleteUserLandInfo(activeArray[editingIndex].landNumber);
          setEditingIndex(null);
          setEditing(false);
          setShowDeleteModal(false);
        });
      } else if (
        activeArray[editingIndex].name === "Term life insurance" ||
        activeArray[editingIndex].name === "Whole life insurance" ||
        activeArray[editingIndex].name === "Whole life - limited payments"
      ) {
        const lifeInsuranceURL = (action) =>
          `${process.env.REACT_APP_NODE_API_GATEWAY}/CompassLambdaApi/LIFE_INSURANCE/${action}?Life_Insurance_Number=${activeArray[editingIndex].Life_Insurance_Number}`;
        axiosInstance.get(lifeInsuranceURL("delete")).then((res) => {
          console.log("Delete res", res);
          deleteUserLifeInsuranceInfo(
            activeArray[editingIndex].Life_Insurance_Number
          );
          setShowDeleteModal(false);
          setEditingIndex(null);
          setEditing(false);
        });
      } else {
        const deleteDebtUrl = (action) =>
          `${process.env.REACT_APP_NODE_API_GATEWAY}/CompassLambdaApi/DEBT/${action}?Debt_Number=${activeArray[editingIndex].Debt_Number}`;
        axiosInstance.get(deleteDebtUrl("delete")).then((res) => {
          // console.log('Delete res', res)
          deleteUserDebtInfo(activeArray[editingIndex].Debt_Number);
          setShowDeleteModal(false);
          setEditingIndex(null);
          setEditing(false);
        });
      }
    };
    const [isLargerThan500] = useMediaQuery("(min-width: 500px)");
    const isMobile = !isLargerThan500;
    if (!showDeleteModal) {
      return null;
    }

    const [yesClicked, setYesClicked] = useState(false);
    const [noClicked, setNoClicked] = useState(false);
    return (
      <div
        className="delete-income-modal"
        style={{
          display: "flex",
          alignItems: "center",
          width:"100%",
          maxWidth:"700px",
          justifyContent: "center",
          top: "50%",
          left: "50%",
          transform: isMobile
            ? "translate(-50%, -50%)"
            : "translate(-50%, -50%)",
        }}
        id="delete-income-modal"
      >
        <Box
          display={"flex"}
          width={"100%"}
          padding={"10px 10px 0px 0px"}
          marginLeft={"5px"}
          alignItems={"flex-end"}
          justifyContent={"flex-end"}
          onClick={() => setShowDeleteModal(false)}
          cursor={"pointer"}
        >
          <CloseIcon
            onClick={() => setShowDeleteModal(false)}
            svgheight={"20px"}
            svgwidth={"20px"}
          />
        </Box>
        <Box
          display={"flex"}
          padding={"0px 25px 25px 25px"}
          flexDirection={"column"}
          alignItems={"center"}
          gap={"10px"}
          alignSelf={"stretch"}
          width={"100%"}
        >
          <Text
            color={"#0093D0"}
            fontFamily={"Nunito"}
            fontSize={"24px"}
            fontStyle={"normal"}
            fontWeight={"600"}
            lineHeight={"normal"}
            textDecor={"underline"}
          >
          Are you sure you want to remove this asset?
        </Text>
        <div className="content"></div>
        <div className="actions">
          <button className={`decision-button yes-button ${
              yesClicked ? "yes-clicked" : ""
            }`}
            onClick={() => {
              setYesClicked(true);
              setNoClicked(false);
              removeRealEstateModal();
            }}>Yes</button>
          <button className={`decision-button no-button ${
              noClicked ? "yes-clicked" : ""
            }`}
            onClick={() => {
              {
                setNoClicked(true);
                setYesClicked(false);
                setShowDeleteModal(false);
              }
            }}>No</button>
        </div>
        </Box>
      </div>
    );
  };

  const editField = (i, array) => {
    const data = {
      list: array[i],
      editingIndex: i,
    };
    setUserEditAssetInfo(data);
    if (array[i].name === "Primary residence") {
      window.history.pushState({}, "", "/primary-residence-form");
      const navEvent = new PopStateEvent("popstate");
      window.dispatchEvent(navEvent);
    } else if (array[i].name === "Recreation property") {
      window.history.pushState({}, "", "/recreational-property-form");
      const navEvent = new PopStateEvent("popstate");
      window.dispatchEvent(navEvent);
    } else if (array[i].name === "Income property") {
      window.history.pushState({}, "", "/income-property-form");
      const navEvent = new PopStateEvent("popstate");
      window.dispatchEvent(navEvent);
    } else if (array[i].name === "Land") {
      window.history.pushState({}, "", "/land-property-form");
      const navEvent = new PopStateEvent("popstate");
      window.dispatchEvent(navEvent);
    } else if (
      array[i].name === "Term life insurance" ||
      array[i].name === "Whole life insurance" ||
      array[i].name === "Whole life - limited payments"
    ) {
      window.history.pushState({}, "", "/life-insurance-form");
      const navEvent = new PopStateEvent("popstate");
      window.dispatchEvent(navEvent);
    } else if (array[i].name === "Company") {
      window.history.pushState({}, "", "/business-form");
      const navEvent = new PopStateEvent("popstate");
      window.dispatchEvent(navEvent);
    } else if (array[i].name === "Investment Funds") {
      window.history.pushState({}, "", "/investment-funds-form");
      const navEvent = new PopStateEvent("popstate");
      window.dispatchEvent(navEvent);
    } else if (array[i].name === "Inheritance") {
      window.history.pushState({}, "", "/inheritance-form");
      const navEvent = new PopStateEvent("popstate");
      window.dispatchEvent(navEvent);
    } else if (array[i].Debt_Name) {
      window.history.pushState({}, "", "/expenses-debt");
      const navEvent = new PopStateEvent("popstate");
      window.dispatchEvent(navEvent);
    } else {
      window.history.pushState({}, "", "/personal-assets-form");
      const navEvent = new PopStateEvent("popstate");
      window.dispatchEvent(navEvent);
    }
  };

  const columns = [
    { header: "Name", field: "name" },
    { header: "Balance Owing", field: "owing" },
    { header: "Annual Payment", field: "payment" },
  ];

  const data = expensesArrayTotal.map((item) => ({
    name:
      item?.Debt_Name ||
      item?.Cottage_Address ||
      item?.landAddress ||
      item?.name,
    owing: currencyFormat(
      item?.Amount_Borrowed ||
        item?.Primary_Res_Mortgage_Balance ||
        item?.Cottage_Mortgage_Balance ||
        item?.Income_Property_Mortgage_Balance ||
        item?.landDebt ||
        0
    ),
    payment:
      item?.Debt_Monthly_Payments * 12 ||
      item?.landDebtPayments * 12 ||
      item?.Primary_Res_Monthly_Payments * 12 ||
      item?.Cottage_Monthly_Payments * 12 ||
      item?.Income_Property_Mortgage_Payments *
        12 *
        (item?.Income_Property_Ownership / 100) ||
      item?.Monthly_Premiums * 12,
  }));

  return (
    <Flex
      display="flex"
      flexWrap="wrap" // Enable wrapping
      justifyContent="flex-start"
      alignItems="start"
      width={"100%"}
    >
      {!isMobile && (
        <VStack alignItems={"flex-start"} width={"100%"} mt={-2}>
          <GlobalBodyPage
            Heading="Total Expenses"
            mainframeContent={
              <>
                <Box
                  display={"flex"}
                  width={"100%"}
                  flexDirection={"row"}
                  alignItems={"flex-end"}
                  justifyContent={"space-between"}
                  gap={"10px"}
                  flexWrap={"wrap"}
                >
                  {data.length > 0 && (
                    <Box
                      width={"100%"}
                      height={"auto"}
                      flexShrink={0}
                      border={
                        " 1px solid var(--Optiml---Vibrant-Sky-Blue, #00A2D4"
                      }
                      borderRadius={"10px"}
                      bg={"#FFFFFF"}
                    >
                      {/* Expense Table */}
                      <DeleteRealEstateModal />

                      <TableComponent
                        columns={columns}
                        data={data}
                        editField={editField}
                        onDeleteRow={onDeleteRow}
                        rawData={expensesArrayTotal}
                      />
                    </Box>
                  )}
                  <Text
                    alignSelf={"stretch"}
                    color={"var(--Optiml---Deep-Ocean-Blue, #007EB5)"}
                    fontFamily={"Nunito"}
                    fontSize={"16px"}
                    fontStyle={"normal"}
                    fontWeight={"600"}
                    lineHeight={"normal"}
                    width={"100%"}
                  >
                    Annual Living Expenses
                  </Text>
                  <NormalInputButton
                    Heading={`Total annual after-tax expenses ${
                      data.length > 0
                        ? "(excluding previously expenses listed in table above)"
                        : ""
                    }`}
                    value={otherExpensesTotal}
                    onChange={(e) => {
                      setChangedData(true);
                      setOtherExpensesTotal(moneyToNumber(e.target.value));
                    }}
                    customWidth={"100%"}
                    placeholder={"$"}
                    useThousandSeparator={true}
                    error={errors.otherExpensesTotal}
                    tooltipContent={TooltipContent.expenseAmount}
                  />
                  <Text
                    alignSelf={"stretch"}
                    color={"var(--Optiml---Deep-Ocean-Blue, #007EB5)"}
                    fontFamily={"Nunito"}
                    fontSize={"16px"}
                    fontStyle={"normal"}
                    fontWeight={"600"}
                    lineHeight={"normal"}
                    width={"100%"}
                  >
                    Expense Growth Projection
                  </Text>
                  <DropDownButton
                    Heading={
                      "How would you like to project the growth of your expenses? Choose between growing with a standard growth rate, or incorporate the Consumer Expense Index model."
                    }
                    placeholder={"Select"}
                    dropDownItems={[
                      { key: "CPI", value: "Standard Growth Rate" },
                      {
                        key: "Consumer Expense Index",
                        value: "Consumer Expense Index",
                      },
                    ]}
                    customWidth={"100%"}
                    value={expenseGrowthRate}
                    onChange={(key) => {
                      setChangedData(true);
                      setExpenseGrowthRate(key);
                    }}
                    error={errors.expenseGrowthRate}
                    tooltipContent={TooltipContent.expenseGrowth}
                  />
                  <NormalInputButton
                    Heading={"Expenses Growth Rate"}
                    value={CPI}
                    onChange={(e) => {
                      setChangedData(true);
                      const noSymbol = e.target.value.replace("%", "");
                      const growthNum = parseFloat(noSymbol);
                      setCPI(
                        !isNaN(growthNum)
                          ? parseFloat(growthNum.toFixed(2))
                          : ""
                      );
                    }}
                    placeholder={"%"}
                    error={errors.CPI}
                    usePercentage={true}
                    tooltipContent={TooltipContent.expenseCPI}
                  />
                  <DropDownButton
                    Heading={"Length of Analysis"}
                    placeholder={"Select"}
                    dropDownItems={Select(currentAge)}
                    value={runAge}
                    error={errors.runAge}
                    onChange={(key) => {
                      setChangedData(true);
                      setRunAge(key);
                    }}
                    tooltipContent={TooltipContent.ageToRun}
                  />
                  <Box
                    width={"100%"}
                    height={"auto"}
                    flexShrink={0}
                    border={
                      " 1px solid var(--Optiml---Vibrant-Sky-Blue, #00A2D4"
                    }
                    borderRadius={"10px"}
                    bg={"#FFFFFF"}
                  >
                    {loading &&
                      currentAge &&
                      runAge &&
                      otherExpensesTotal !== 0 && (
                        <ExpenseGrowthTable
                          expenses={moneyToNumber(otherExpensesTotal)}
                          getAge={getAge}
                          getSpouseAge={spouseAge}
                          currentAge={currentAge}
                          runAge={runAge}
                          yearToDate={yearToDate}
                          currencyFormat={currencyFormat}
                          moneyToNumber={moneyToNumber}
                          growthOverYears={expenseGrowthOverYears}
                          setGrowthOverYears={onUpdateGrowthOverYears}
                          mortgageExpenses={totalExpenseOverYearsMortgages}
                          debtExpenses={totalExpenseOverYearsDebt}
                          insuranceExpenses={totalExpenseOverYearsInsurance}
                          isEditMode={true}
                          user="primary"
                        />
                      )}
                  </Box>
                  <Box
                    width={"100%"}
                    height={"auto"}
                    flexShrink={0}
                    border={
                      " 1px solid var(--Optiml---Vibrant-Sky-Blue, #00A2D4"
                    }
                    borderRadius={"10px"}
                    bg={"#FFFFFF"}
                  >
                    {loading &&
                      currentAge &&
                      runAge &&
                      otherExpensesTotal !== 0 && (
                        <ExpenseGraph
                          getAge={getAge}
                          currentAge={currentAge}
                          runAge={runAge}
                          growthOverYears={expenseGrowthOverYears.map(
                            (expense, index) =>
                              expense +
                              (totalExpenseOverYearsMortgages[index] || 0) +
                              (totalExpenseOverYearsDebt[index] || 0) +
                              (totalExpenseOverYearsInsurance[index] || 0)
                          )}
                          mortgageExpenses={totalExpenseOverYearsMortgages}
                          householdExpenses={expenseGrowthOverYears}
                          debtExpenses={totalExpenseOverYearsDebt}
                          insuranceExpenses={totalExpenseOverYearsInsurance}
                        />
                      )}
                  </Box>
                </Box>
              </>
            }
            videoName={"Expenses Page"}
            video={"Annual_Expenses.mp4"}
          />
        </VStack>
      )}
      {isMobile && (
        <VStack alignItems={"flex-start"} width={"100%"} mt={-2}>
          <GlobalBodyPage
            Heading="Total Expenses"
            mainframeContent={
              <>
                <Box
                  display={"flex"}
                  width={"100%"}
                  flexDirection={"row"}
                  alignItems={"flex-end"}
                  justifyContent={"space-between"}
                  gap={"10px"}
                  flexWrap={"wrap"}
                >
                  {data.length > 0 && (
                    <Box
                      width={"100%"}
                      height={"auto"}
                      flexGrow={0}
                      border={
                        " 1px solid var(--Optiml---Vibrant-Sky-Blue, #00A2D4"
                      }
                      borderRadius={"10px"}
                      bg={"#FFFFFF"}
                    >
                      {/* Expense Table */}
                      <DeleteRealEstateModal />

                      <TableComponent
                        columns={columns}
                        data={data}
                        editField={editField}
                        onDeleteRow={onDeleteRow}
                        rawData={expensesArrayTotal}
                      />
                    </Box>
                  )}
                  <Text
                    alignSelf={"stretch"}
                    color={"var(--Optiml---Deep-Ocean-Blue, #007EB5)"}
                    fontFamily={"Nunito"}
                    fontSize={"16px"}
                    fontStyle={"normal"}
                    fontWeight={"600"}
                    lineHeight={"normal"}
                    width={"100%"}
                  >
                    Annual Living Expenses
                  </Text>
                  <NormalInputButton
                    Heading={`Total annual after-tax expenses ${
                      data.length > 0
                        ? "(excluding previously expenses listed in table above)"
                        : ""
                    }`}
                    value={otherExpensesTotal}
                    onChange={(e) => {
                      setChangedData(true);
                      setOtherExpensesTotal(moneyToNumber(e.target.value));
                    }}
                    customWidth={"100%"}
                    InputWidth={"100%"}
                    placeholder={"$"}
                    useThousandSeparator={true}
                    error={errors.otherExpensesTotal}
                    tooltipContent={TooltipContent.expenseAmount}
                  />
                  <Text
                    alignSelf={"stretch"}
                    color={"var(--Optiml---Deep-Ocean-Blue, #007EB5)"}
                    fontFamily={"Nunito"}
                    fontSize={"16px"}
                    fontStyle={"normal"}
                    fontWeight={"600"}
                    lineHeight={"normal"}
                    width={"100%"}
                  >
                    Expense Growth Projection
                  </Text>
                  <DropDownButton
                    Heading={
                      "How would you like to project the growth of your expenses? Choose between growing with a standard growth rate, or incorporate the Consumer Expense Index model."
                    }
                    placeholder={"Select"}
                    dropDownItems={[
                      { key: "CPI", value: "Standard Growth Rate" },
                      {
                        key: "Consumer Expense Index",
                        value: "Consumer Expense Index",
                      },
                    ]}
                    customWidth={"100%"}
                    value={expenseGrowthRate}
                    onChange={(key) => {
                      setChangedData(true);
                      setExpenseGrowthRate(key);
                    }}
                    MenuCustomWidth={"100%"}
                    error={errors.expenseGrowthRate}
                    tooltipContent={TooltipContent.expenseGrowth}
                  />
                  <NormalInputButton
                    Heading={"Expenses Growth Rate"}
                    value={CPI}
                    onChange={(e) => {
                      setChangedData(true);
                      const noSymbol = e.target.value.replace("%", "");
                      const growthNum = parseFloat(noSymbol);
                      setCPI(
                        !isNaN(growthNum)
                          ? parseFloat(growthNum.toFixed(2))
                          : ""
                      );
                    }}
                    placeholder={"%"}
                    customWidth={"100%"}
                    InputWidth={"100%"}
                    error={errors.CPI}
                    usePercentage={true}
                    tooltipContent={TooltipContent.expenseCPI}
                  />
                  <DropDownButton
                    Heading={"Length of Analysis"}
                    // placeholder={"Select"}
                    dropDownItems={Select(currentAge)}
                    customWidth={"100%"}
                    MenuCustomWidth={"100%"}
                    value={runAge}
                    error={errors.runAge}
                    onChange={(key) => {
                      setChangedData(true);
                      setRunAge(key);
                    }}
                    tooltipContent={TooltipContent.ageToRun}
                  />
                  <Box
                    width={"100%"}
                    height={"auto"}
                    flexShrink={0}
                    border={
                      " 1px solid var(--Optiml---Vibrant-Sky-Blue, #00A2D4"
                    }
                    borderRadius={"10px"}
                    bg={"#FFFFFF"}
                  >
                    {/* Yearly Expense Table */}
                    {loading &&
                      currentAge &&
                      runAge &&
                      otherExpensesTotal !== 0 && (
                        <ExpenseGrowthTable
                          expenses={moneyToNumber(otherExpensesTotal)}
                          getAge={getAge}
                          getSpouseAge={spouseAge}
                          currentAge={currentAge}
                          runAge={runAge}
                          yearToDate={yearToDate}
                          currencyFormat={currencyFormat}
                          moneyToNumber={moneyToNumber}
                          growthOverYears={expenseGrowthOverYears}
                          setGrowthOverYears={onUpdateGrowthOverYears}
                          mortgageExpenses={totalExpenseOverYearsMortgages}
                          debtExpenses={totalExpenseOverYearsDebt}
                          insuranceExpenses={totalExpenseOverYearsInsurance}
                          isEditMode={true}
                          user="primary"
                        />
                      )}
                  </Box>
                  <Box
                    width={"100%"}
                    height={"auto"}
                    flexShrink={0}
                    border={
                      " 1px solid var(--Optiml---Vibrant-Sky-Blue, #00A2D4"
                    }
                    borderRadius={"10px"}
                    bg={"#FFFFFF"}
                  >
                    {/* Graph */}
                    {loading &&
                      currentAge &&
                      runAge &&
                      otherExpensesTotal !== 0 && (
                        <ExpenseGraph
                          getAge={getAge}
                          currentAge={currentAge}
                          runAge={runAge}
                          growthOverYears={expenseGrowthOverYears.map(
                            (expense, index) =>
                              expense +
                              (totalExpenseOverYearsMortgages[index] || 0) +
                              (totalExpenseOverYearsDebt[index] || 0) +
                              (totalExpenseOverYearsInsurance[index] || 0)
                          )}
                          mortgageExpenses={totalExpenseOverYearsMortgages}
                          householdExpenses={expenseGrowthOverYears}
                          debtExpenses={totalExpenseOverYearsDebt}
                          insuranceExpenses={totalExpenseOverYearsInsurance}
                        />
                      )}
                  </Box>
                </Box>
              </>
            }
            videoName={"Expenses Page"}
            video={"Annual_Expenses.mp4"}
          />
        </VStack>
      )}
    </Flex>
  );
});

export default HouseHoldExpensesForm;
