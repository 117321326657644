import React, { useState, useEffect } from "react";
import ReactEcharts from "echarts-for-react";
import { border } from "@chakra-ui/react";

function ExpenseGraph(props) {
  const {
    getAge,
    currentAge,
    runAge,
    mortgageExpenses,
    householdExpenses,
    debtExpenses,
    insuranceExpenses,
  } = props;

  const currencyFormat = (money) => {
    if (money === 0) {
      return "$0";
    } else if (money === "") {
      return "";
    } else {
      const intTotal = Math.round(money * 100) / 100;
      return new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "USD",
        minimumFractionDigits: 0,
      }).format(intTotal);
    }
  };

  const getYears = () => {
    const currentDate = new Date();
    const currentYear = currentDate.getFullYear();
    const userAge = getAge(currentAge, currentDate);
    const fullRun = runAge - userAge;
    return fullRun > 0 && Number.isInteger(fullRun)
      ? Array.from({ length: fullRun }, (_, i) => currentYear + i)
      : [];
  };

  const xAxis = getYears();
  const [key, setKey] = useState(1);

  useEffect(() => {
    // Force re-render to trigger ECharts update
    setKey(key === 1 ? 2 : 1);
  }, [props]);

  const allZero = (data) => !data.some((item) => item > 0);

  let option = {
    
    title: {
      text: "Total Expenses",
      left: "center",
    },
    tooltip: {
      trigger: "axis",
      axisPointer: { type: "line" },
      formatter: function (params) {
        let result = `${params[0].axisValueLabel}<br/>`;
        params.forEach((item) => {
          result += `${item.marker} ${item.seriesName}: ${currencyFormat(
            Math.round(item.data)
          )}<br/>`;
        });
        return result;
      },
    },
    grid: { left: "3%", right: "4%", bottom: "3%", containLabel: true },
    xAxis: [{ type: "category", boundaryGap: true, data: xAxis }],
    yAxis: [
      {
        type: "value",
        axisLabel: {
          formatter: (value) => `$${new Intl.NumberFormat().format(value)}`,
        },
      },
    ],
    series: [
      {
        name: "Living Expenses",
        type: "bar",
        stack: "Total",
        color: "#00466D",
        data: householdExpenses,
      },
      {
        name: "Mortgage Payments",
        type: "bar",
        stack: "Total",
        color: "#007EB5",
        data: mortgageExpenses,
      },
      {
        name: "Debt Payments",
        type: "bar",
        stack: "Total",
        color: "#73B879",
        data: debtExpenses,
      },
      {
        name: "Insurance Payments",
        type: "bar",
        stack: "Total",
        color: "#00A2D4",
        data: insuranceExpenses,
      },
    ].filter((series) => !allZero(series.data)), // Filter out series where all data points are zero
  };

  return (
    <ReactEcharts
      key={key}
      option={option}
      notMerge={true}
      className="salary-graph"
      style={{ height: "260px", maxWidth: "100%", borderRadius: "5px" }}
    />
  );
}

export default ExpenseGraph;
