import { useAuth0 } from "@auth0/auth0-react";
import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import { useStore } from "../store";
// import { useNavigate } from 'react-router-dom';
import {
  Box,
  Button,
  Center,
  HStack,
  Icon,
  Modal,
  ModalBody,
  ModalContent,
  ModalOverlay,
  Text,
  useDisclosure,
  useMediaQuery,
} from "@chakra-ui/react";
import $ from "jquery";
import { useLocation } from "react-router-dom";
import optiNoise from "../Sounds/optiNoise.mp3";
import {
  CloseIcon,
  MissingInformationIcon,
  OptimizeRocketIcon,
  OptimlWhiteBGIcon,
  ResolveIssueIcon,
} from "./CustomIcons";
import ErrorPage from "./ErrorPage";
import "./LifeStrategy.css";
import Link from "./Link";
import LoadingScreenMessages from "./LoadingScreenMessages";
import { SpouseContext } from "./SpouseContext";

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

function InputSummary({ setHeading, updateBackButtonStatus }) {
  const { getAccessTokenSilently, user } = useAuth0();

  const query = useQuery();
  const strategy = query.get("strategy");

  const axiosInstance = axios.create();
  async function getToken() {
    // Replace this with your Auth0 token retrieval logic
    const token = await getAccessTokenSilently({
      audience:
        "https://os2lsiwff43wfpb5ggyawt6qri0mgcro.lambda-url.us-east-2.on.aws/",
      scope: "write:optimization",
    });
    return token;
  }

  // Add a request interceptor
  axiosInstance.interceptors.request.use(
    async (config) => {
      const token = await getToken();
      config.headers.Authorization = `Bearer ${token}`;
      return config;
    },
    (error) => {
      // Do something with request error
      return Promise.reject(error);
    }
  );
  const [optimize, setOptimize] = useState(strategy);
  const [custom, setCustom] = useState(false);
  const [customValue, setCustomValue] = useState("");
  const [loading, setLoading] = useState(false);
  const [loadingCircles, setLoadingCircles] = useState(false);
  const [optiList, setOptiList] = useState("");
  const [spouseOptilist, setSpouseOptilist] = useState("");
  const [choosePlan, setChoosePlan] = useState(false);
  const [invGrowth, setInveGrowth] = useState("0.06");
  const [cpi, setCPI] = useState("0.02");
  // const [runAge, setRunAge] = useState();
  const [errorPage, setErrorPage] = useState(false);
  const [select, setSelected] = useState("Select");
  const [selectOne, setSelectedOne] = useState("Select");
  const [selectTwo, setSelectedTwo] = useState("Select");
  const [includeAssets, setIncludeAssets] = useState(true);
  const [includePrimaryRes, setIncludePrimaryRes] = useState(true);
  const [includeRecreationProperty, setIncludeRecreationProperty] =
    useState(true);
  const [includeIncomeProperty, setIncludeIncomeProperty] = useState(true);
  const [includeLand, setIncludeLand] = useState(true);
  const [includeBusiness, setIncludeBusiness] = useState(true);
  const [includeLifeInsurance, setIncludeLifeInsurance] = useState(true);
  const [assetWarning, setAssetWarning] = useState(false);
  const [sumAssetValue, setSumAssetValue] = useState(0);
  const [currentAge, setCurrentAge] = useState("");
  const [runToAge, setRunToAge] = useState("");
  const { spouseStatus, setSpouseStatus } = useContext(SpouseContext);
  const userInfo = useStore((store) => store.userInfo);
  const {
    FirstName: firstName,
    LastName: lastName,
    DateOfBirth,
    spouseFirstName,
    spouseLastName,
    runAge,
    spouseRunAge,
  } = userInfo;

  // const userInfo = useStore((store) => store.userInfo);
  const userSetValueInfo = useStore((store) => store.userSetValueInfo);
  const userLiraInfo = useStore((store) => store.userLiraInfo);
  const userExpensesInfo = useStore((store) => store.userExpensesInfo);
  const setUserExpensesInfo = useStore((store) => store.setUserExpensesInfo);
  const userGoalInfo = useStore((store) => store.userGoalInfo);
  const setUserGoalInfo = useStore((store) => store.setUserGoalInfo);
  const userIncomeInfo = useStore((store) => store.userIncomeInfo);
  const userRRSPInfo = useStore((store) => store.userRRSPInfo);
  const userTFSAInfo = useStore((store) => store.userTFSAInfo);
  const userFHSAInfo = useStore((store) => store.userFHSAInfo);

  const userNonRegInvestmentsInfo = useStore(
    (store) => store.userNonRegInvestmentsInfo
  );
  const userOtherAssetsInfo = useStore((store) => store.userOtherAssetsInfo);
  const userInheritanceInfo = useStore((store) => store.userInheritanceInfo);
  const userDebtInfo = useStore((store) => store.userDebtInfo);
  const userOptiData = useStore((store) => store.userOptiData);
  const userOtherIncomeInfo = useStore((store) => store.userOtherIncomeInfo);
  const userFixedIncomeInfo = useStore((store) => store.userFixedIncomeInfo);
  const userPensionInfo = useStore((store) => store.userPensionInfo);
  const userPrimaryResidenceInfo = useStore(
    (store) => store.userPrimaryResidenceInfo
  );
  const userCottageInfo = useStore((store) => store.userCottageInfo);
  const userPropertiesInfo = useStore((store) => store.userPropertiesInfo);
  const userLandInfo = useStore((store) => store.userLandInfo);
  const userBusinessInfo = useStore((store) => store.userBusinessInfo);
  const userCompanyInfo = useStore((store) => store.userCompanyInfo);
  const userLifeInsuranceInfo = useStore(
    (store) => store.userLifeInsuranceInfo
  );
  const userCASHInfo = useStore((store) => store.userCASHInfo);
  const setUserOptiData = useStore((store) => store.setUserOptiData);
  const setUserInsightsData = useStore((store) => store.setUserInsightsData);

  const [isVideoModalOpen, setIsVideoModalOpen] = useState(false);
  const [videName, setVideoName] = useState("Max_Value.mp4");
  const [videoTitle, setVideoTitle] = useState("Max Value Strategy");
  const openVideoModal = (name, title) => {
    setVideoName(name);
    setVideoTitle(title);
    setIsVideoModalOpen(true);
  };

  const closeVideoModal = () => {
    setIsVideoModalOpen(false);
  };

  useEffect(() => {
    setOptimize(strategy);
  }, [strategy]);

  const audioTune = new Audio(optiNoise);

  useEffect(() => {
    if (optimize === "Set Value") {
      setCustom(true);
      setCustomValue(userSetValueInfo?.SetValue);
      setIncludeAssets(userSetValueInfo?.IncludeAssets);
      setIncludePrimaryRes(userSetValueInfo?.IncludePrimaryRes);
      setIncludeRecreationProperty(userSetValueInfo?.IncludeRecreationProperty);
      setIncludeIncomeProperty(userSetValueInfo?.IncludeIncomeProperty);
      setIncludeLand(userSetValueInfo?.IncludeLand);
      setIncludeBusiness(userSetValueInfo?.IncludeBusiness);
      setIncludeLifeInsurance(userSetValueInfo?.IncludeLifeInsurance);
    } else {
      setCustom(false);
    }
  }, [userSetValueInfo, optimize]);

  useEffect(() => {
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 100);
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [select, selectOne, selectTwo]);

  useEffect(() => {
    if (
      userInfo?.runAge - getAge(userInfo?.DateOfBirth) !=
      userExpensesInfo?.expenseArray.length
    ) {
      calculateAnnualGrowthArray(
        userExpensesInfo?.total,
        userExpensesInfo?.expenseCPI,
        userInfo?.runAge - getAge(userInfo?.DateOfBirth),
        "primary",
        userExpensesInfo?.growth
      );
    }
  }, []);

  function calculateAnnualGrowthArray(
    initialValue,
    percentage,
    years,
    user,
    expenseGrowthRate
  ) {
    console.log("Mismatched List, rebuilding...");
    if (typeof initialValue === "string") {
      initialValue = moneyToNumber(initialValue);
    }
    const growthArray = [initialValue];

    if (expenseGrowthRate == "Consumer Expense Index") {
      let currentExpenses = initialValue;
      let userAge = getAge(currentAge);

      for (let year = 1; year < years; year++) {
        // Start from year 1 as initial expenses are already in the array
        let ageFactor = 1;

        if (userAge < 35) {
          ageFactor += (92286 - 75494) / (75494 * 9); // Average annual growth until age 35
        } else if (userAge >= 35 && userAge < 45) {
          ageFactor += (107072 - 92286) / (92286 * 10); // Average annual growth from age 35 to 45
        } else if (userAge >= 45 && userAge < 55) {
          ageFactor += (90092 - 107072) / (107072 * 10); // Average annual decrease from age 45 to 55
        } else if (userAge >= 55 && userAge < 65) {
          ageFactor += (63789 - 90092) / (90092 * 10); // Average annual decrease from age 55 to 65
        } else if (userAge >= 65) {
          ageFactor -= (90092 - 63789) / (90092 * 10); // Constant annual decrease after 65
        }

        // Apply the age factor for this year's expenses
        currentExpenses *= ageFactor;
        // Apply the general inflation or expense growth percentage
        currentExpenses *= 1 + percentage / 100;
        growthArray.push(parseFloat(currentExpenses.toFixed(2))); // Rounded to two decimal places

        userAge++;
      }
    } else {
      for (let i = 1; i < years; i++) {
        const currentValue = growthArray[i - 1] * (1 + percentage / 100);
        growthArray.push(parseInt(currentValue.toFixed(2)));
      }
    }

    setUserExpensesInfo({
      ...userExpensesInfo,
      expenseArray: growthArray,
    });

    return growthArray;
  }

  function getRunAgeYear() {
    // Get the current date
    const currentDate = new Date();
    const currentYear = currentDate.getFullYear();
    // Convert the birthday string into a Date object
    const birthDate = new Date(DateOfBirth);

    // Calculate the age in years
    let age = currentDate.getFullYear() - birthDate.getFullYear();

    // Decrement the age if the current month and day are before the birth month and day
    if (
      currentDate.getMonth() < birthDate.getMonth() ||
      (currentDate.getMonth() === birthDate.getMonth() &&
        currentDate.getDate() <= birthDate.getDate())
    ) {
      age -= 1;
    }

    return runAge - age + currentYear;
  }

  const currentDate = new Date();
  const age = getAge(userInfo?.DateOfBirth, currentDate);

  const preFlightIssueChecks = [
    {
      name: "CCPC Investment Fund Incomplete",
      link: "/investment-funds-form",
      condition: (userBusinessInfo) =>
        userBusinessInfo?.length === 1 &&
        !userBusinessInfo?.[0]?.businessLocation,
    },
    {
      name: "Expenses Data Incomplete",
      link: "/expenses-household",
      condition: (userInfo) => !userInfo?.runAge || userInfo?.runAge <= age,
    },
    {
      name: "Fixed Income Start & Maturity Date",
      link: "/fixed-income-page",
      condition: (userFixedIncomeInfo) =>
        userFixedIncomeInfo?.some((info) => !info.fixedIncomeStart),
    },
  ];

  const PopupComponent = ({ onSubmit }) => {
    const [isAnyConditionTrue, setIsAnyConditionTrue] = useState(false);
    const [activeIssue, setActiveIssue] = useState(null);
    const [isOpen, setIsOpen] = useState(false);
    const [optimizeIconColor2, setOptimizeIconColor2] = useState("#0F9CD6");

    useEffect(() => {
      const foundIssue = preFlightIssueChecks.find((issue) => {
        if (issue.name === "CCPC Investment Fund Incomplete") {
          return issue.condition(userBusinessInfo);
        } else if (issue.name === "Expenses Data Incomplete") {
          return issue.condition(userInfo);
        } else if (issue.name === "Fixed Income Start & Maturity Date") {
          return issue.condition(userFixedIncomeInfo);
        }
        return false;
      });

      if (foundIssue) {
        setActiveIssue(foundIssue);
        setIsAnyConditionTrue(true);
      } else {
        setIsAnyConditionTrue(false);
      }
    }, [userBusinessInfo, userInfo, userFixedIncomeInfo]);

    const onOpen = () => setIsOpen(true);
    const onClose = () => setIsOpen(false);

    const handleClick = (e) => {
      if (isAnyConditionTrue) {
        onOpen();
      } else {
        onSubmit(e);
      }
    };

    return (
      <>
        <div
          className="grid-item grid-item-13"
          onClick={handleClick}
          style={{ cursor: "pointer" }}
        >
          <Icon as={OptimizeRocketIcon} svgheight={"32px"} svgwidth={"31px"} />
          <Text
            color={"#FFF"}
            textAlign={"center"}
            fontFamily={"Nunito"}
            fontSize={"20px"}
            fontStyle={"normal"}
            fontWeight={"600"}
            lineHeight={"normal"}
          >
            Optimize
          </Text>
        </div>

        <Modal isOpen={isOpen} onClose={onClose} isCentered>
          <ModalOverlay />
          <ModalContent
            borderRadius="10px"
            border="2px solid #00A2D4"
            padding="20px"
            width="550px"
            maxW="90%"
          >
            <Box
              position="absolute"
              top="10px"
              right="10px"
              cursor="pointer"
              onClick={onClose}
              width="100%"
              display="flex"
              justifyContent="flex-end"
            >
              <Icon as={CloseIcon} w="18px" h="18px" />
            </Box>
            <ModalBody padding="0">
              {activeIssue && (
                <Box
                  display="flex"
                  alignItems="center"
                  textAlign="center"
                  flexDirection="column"
                  gap="10px"
                  borderRadius="10px"
                  bg="#FFF"
                  padding="20px"
                >
                  <Box
                    display="flex"
                    flexDirection="column"
                    alignItems="center"
                    w="100%"
                  >
                    <Text
                      color="#00A2D4"
                      fontFamily="Nunito"
                      fontSize="24px"
                      fontWeight="600"
                      textDecoration="underline"
                    >
                      Missing Information
                    </Text>
                    <Box
                      display="flex"
                      flexDirection="column"
                      alignItems="center"
                      gap="5px"
                      w="100%"
                      marginTop={"15px"}
                    >
                      <HStack alignItems="center">
                        <Icon as={MissingInformationIcon} w="30px" h="30px" />
                        <Text
                          color="#004069"
                          fontFamily="Nunito"
                          fontSize="12px"
                          fontWeight="700"
                        >
                          {activeIssue.name} Incomplete
                        </Text>
                      </HStack>
                      <Link href={activeIssue.link} w="100%">
                        <Button
                          padding="15px 30px 15px 30px"
                          width="100%"
                          height={"30px"}
                          justifyContent="center"
                          alignItems="center"
                          gap="10px"
                          borderRadius="5px"
                          bg="white"
                          color="#0F9CD6"
                          border="2px solid #0F9CD6"
                          leftIcon={
                            <ResolveIssueIcon
                              svgwidth="30px"
                              svgheight="30px"
                              color={optimizeIconColor2}
                            />
                          }
                          _hover={{ bg: "#0F9CD6", color: "white" }}
                          onMouseEnter={() => setOptimizeIconColor2("white")}
                          onMouseLeave={() => setOptimizeIconColor2("#0F9CD6")}
                        >
                          <Text
                            fontSize="16px"
                            fontWeight="800"
                            color={optimizeIconColor2}
                          >
                            Resolve Issue
                          </Text>
                        </Button>
                      </Link>
                    </Box>
                  </Box>
                </Box>
              )}
            </ModalBody>
          </ModalContent>
        </Modal>
      </>
    );
  };

  const InsightsRuns = async (json, removeCount = 1, addCount = 1, config, spouse = false) => {
    // Determine the keys for the salaries based on the spouse flag
    const salaryKeys = spouse ? ['SPS1_Salary', 'SPS2_Salary'] : ['Salary'];
  
    for (const salaryKey of salaryKeys) {
      const salaryString = json[salaryKey];
      const salaryArray = JSON.parse(salaryString);
  
      if (!salaryArray || salaryArray.length < removeCount) {
        console.error(`Error: ${salaryKey} data is missing or has fewer than ${removeCount} elements.`);
        continue; // Skip further processing for this key
      }
  
      const lastSalaryValue = salaryArray[salaryArray.length - 1]; // Last element of the Salary array
  
      // Clone and modify the original JSON for removed and duplicated salaries
      const jsonWithSalaryRemoved = {
        ...json,
        [salaryKey]: JSON.stringify(salaryArray.slice(0, -removeCount)),
      };
      const jsonWithSalaryDuplicated = {
        ...json,
        [salaryKey]: JSON.stringify([...salaryArray, ...new Array(addCount).fill(lastSalaryValue)]),
      };
  
      try {
        // Prepare API calls for both modified JSON objects
        const requestRemoved = axios.post(`${process.env.REACT_APP_ENGINE_DOMAIN}`, jsonWithSalaryRemoved, { timeout: 900000, ...config });
        const requestDuplicated = axios.post(`${process.env.REACT_APP_ENGINE_DOMAIN}`, jsonWithSalaryDuplicated, { timeout: 900000, ...config });
  
        // Execute both requests concurrently and wait for both to complete
        const [responseRemoved, responseDuplicated] = await Promise.all([requestRemoved, requestDuplicated]);
  
        const retireEarlyData = JSON.stringify(responseRemoved.data.solutions?.[0]);
        const retireLateData = JSON.stringify(responseDuplicated.data.solutions?.[0]);
  
        // Construct the insights object with results from both requests
        const insightsObject = {
          insightsID: user.sub,
          retireEarly: retireEarlyData,
          retireLate: retireLateData,
          cppEarly: "{}",
          cppLate: "{}",
          oasEarly: "{}",
          oasLate: "{}"
        };
  
        const insightsStoreData = {
          retireEarly: retireEarlyData,
          retireLate: retireLateData,
          cppEarly: "{}",
          cppLate: "{}",
          oasEarly: "{}",
          oasLate: "{}"
        };
        setUserInsightsData(insightsStoreData);
  
        // Attempt to add new insights data
        const res = await axiosInstance.post(`${process.env.REACT_APP_NODE_API_GATEWAY}/CompassLambdaApi/INSIGHTS/add`, insightsObject);
        console.log(res);
  
        if (res.data.code === "ER_DUP_ENTRY") {
          // Update existing insights data if entry exists
          const updateRes = await axiosInstance.post(`${process.env.REACT_APP_NODE_API_GATEWAY}/CompassLambdaApi/INSIGHTS/update`, insightsObject);
          console.log(updateRes);
        }
      } catch (error) {
        console.error("Error during API operations:", error);
      }
    }
  };
  

  const onSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
    const numCustomValue = moneyToNumber(customValue);
    const planRunDate = new Date();
    if (spouseStatus === false) {
      const AccessTokenPromise = getAccessTokenSilently({
        audience:
          "https://os2lsiwff43wfpb5ggyawt6qri0mgcro.lambda-url.us-east-2.on.aws/",
        scope: "write:optimization",
      });
      Promise.all([AccessTokenPromise]).then((values) => {
        var AccessToken = values[0];
        const config = {
          headers: {
            Authorization: `Bearer ${AccessToken}`,
            "content-type": "application/json",
          },
        };
        setCurrentAge(userInfo?.DateOfBirth);
        setRunToAge(userInfo?.runAge);
        let singleFoundItem = userPensionInfo?.find(
          (item) => item.spouseCheck === 0
        );
        const jsonObject = JSON.stringify({
          // Personal
          Email: user.email,
          Married: "False",
          Name: userInfo?.FirstName,
          LastName: userInfo?.LastName,
          Birthday: userInfo?.DateOfBirth,
          AgeToRun: userInfo?.runAge,
          Province: userInfo?.Province,
          planRunDate: planRunDate,
          // INCOME
          Salary: JSON.stringify(userIncomeInfo?.Salary_Array)
            ? JSON.stringify(userIncomeInfo?.Salary_Array)
            : [0],
          SelfEmployed: userIncomeInfo?.Employment_Type
            ? userIncomeInfo?.Employment_Type
            : "Employed",
          paysEI: userIncomeInfo?.Do_You_EI ? userIncomeInfo?.Do_You_EI : "Yes",
          Deductions: userIncomeInfo?.Deductions
            ? userIncomeInfo?.Deductions
            : 0,
          // CPP
          CPP: Math.max(
            userIncomeInfo?.Monthly_Retirement_Pension,
            userIncomeInfo?.Monthly_Retirement_Pension
          )
            ? Math.max(
                userIncomeInfo?.Monthly_Retirement_Pension,
                userIncomeInfo?.Monthly_Retirement_Pension
              )
            : 831,
          hasStartedCPP: userIncomeInfo?.hasStartedCPP
            ? userIncomeInfo?.hasStartedCPP
            : "No",
          hasStartedCPPThisYear: userIncomeInfo?.hasStartedCPPThisYear
            ? userIncomeInfo?.hasStartedCPPThisYear
            : "No",
          cppMonth: userIncomeInfo?.cppMonth ? userIncomeInfo?.cppMonth : "",

          // OAS
          OAS_40_Years: userIncomeInfo?.OAS_40_Years
            ? userIncomeInfo?.OAS_40_Years
            : "",
          OAS_Years: userIncomeInfo?.OAS_Years ? userIncomeInfo?.OAS_Years : "",
          hasStartedOAS: userIncomeInfo?.hasStartedOAS
            ? userIncomeInfo?.hasStartedOAS
            : "No",
          hasStartedOASThisYear: userIncomeInfo?.hasStartedOASThisYear
            ? userIncomeInfo?.hasStartedOASThisYear
            : "No",
          oasMonth: userIncomeInfo?.oasMonth ? userIncomeInfo?.oasMonth : "",
          oasAmount: userIncomeInfo?.oasAmount ? userIncomeInfo?.oasAmount : 0,

          //
          Pension: {
            Type: singleFoundItem
              ? singleFoundItem?.Pension_Type === "Defined Benefit Plan"
                ? "DB"
                : "DC"
              : "",
            Value: singleFoundItem ? Number(singleFoundItem?.Pension) : 0,
            StartAge: singleFoundItem ? Number(singleFoundItem?.pensionAge) : 0,
            EmployerContribution: singleFoundItem
              ? Number(singleFoundItem?.Employer_Cont) / 100
              : 0,
            SelfContribution: singleFoundItem
              ? Number(singleFoundItem?.Personal_Cont) / 100
              : 0,
            Growth: singleFoundItem
              ? Number(singleFoundItem?.Pension_Index) / 100
              : 0,
          },
          // Other Income
          Other_Income: userOtherIncomeInfo?.filter(
            (item) => item?.spouseCheck === 0
          )
            ? userOtherIncomeInfo?.filter((item) => item?.spouseCheck === 0)
            : [],
          // TFSA
          TFSA: Math.max(Number(userTFSAInfo?.totalValue ?? 0), 0),
          TFSA_ContributionRoom: Math.max(
            Number(userTFSAInfo?.TFSA_Room ?? 0),
            0
          ),
          TFSA_Growth: Math.max(Number(userTFSAInfo?.TFSA_ROR ?? 6) / 100, 0),
          TFSA_CONT_THIS_YEAR: Math.max(
            Number(userTFSAInfo?.tfsaContThisYear ?? 0),
            0
          ),

          // RRSP
          RRSP: Math.max(Number(userRRSPInfo?.totalValue ?? 0), 0),
          RRSP_TO_RRIF: userRRSPInfo?.hasRRIF ? userRRSPInfo?.hasRRIF : "No",
          RRSP_Contribution: Math.max(
            Number(userRRSPInfo?.RRSP_Cont_This_Year ?? 0),
            0
          ),
          RRSP_SelfContributionPercentage: Math.max(
            Number(userRRSPInfo?.Employer_Cont_Percent ?? 0) / 100,
            0
          ),
          RRSP_EmployerContributionPercentage: Math.max(
            Number(userRRSPInfo?.Employer_Cont_Percent ?? 0) / 100,
            0
          ),
          RRSP_Growth: Math.max(Number(userRRSPInfo?.RRSP_ROR ?? 6) / 100, 0),

          // FHSA
          FHSA: userFHSAInfo,

          // LIRA
          LIRA: Math.max(Number(userLiraInfo?.SPS1_LIRA_VALUE ?? 0), 0),
          LIRA_Growth: Math.max(Number(userLiraInfo?.LIRA_ROR ?? 6) / 100, 0),
          LIRA_isLif: userLiraInfo?.Has_LIRA_LIF
            ? userLiraInfo?.Has_LIRA_LIF
            : "LIRA",

          // Non Registered
          NONREG: Math.max(
            Number(userNonRegInvestmentsInfo?.totalValue ?? 0),
            0
          ),
          ACB: Math.max(Number(userNonRegInvestmentsInfo?.ACB ?? 0), 0),
          NONREG_Growth: Math.max(
            Number(userNonRegInvestmentsInfo?.Non_Reg_ROR ?? 6) / 100,
            0
          ),
          Eligible_Div:
            (userNonRegInvestmentsInfo?.totalValue ?? 0) > 0
              ? Math.max(
                  Number(userNonRegInvestmentsInfo?.Eligible_Div ?? 0) /
                    userNonRegInvestmentsInfo?.totalValue,
                  0
                )
              : 0,
          Non_Eligible_Div:
            (userNonRegInvestmentsInfo?.totalValue ?? 0) > 0
              ? Math.max(
                  Number(userNonRegInvestmentsInfo?.Non_Eligible_Div ?? 0) /
                    userNonRegInvestmentsInfo?.totalValue,
                  0
                )
              : 0,
          Foreign_Div:
            (userNonRegInvestmentsInfo?.totalValue ?? 0) > 0
              ? Math.max(
                  Number(userNonRegInvestmentsInfo?.Foreign_Div ?? 0) /
                    userNonRegInvestmentsInfo?.totalValue,
                  0
                )
              : 0,
          NONREG_CONT_THIS_YEAR: Math.max(
            Number(userNonRegInvestmentsInfo?.nonRegContThisYear ?? 0),
            0
          ),
          // CASH
          cashTotal: Math.max(Number(userCASHInfo?.value ?? 0), 0),
          minCashBalance: Math.max(Number(userCASHInfo?.minCashValue ?? 0), 0),

          // Fixed Income
          fixedIncome: userFixedIncomeInfo?.filter(
            (item) => item?.spouseCheck === 0
          )
            ? userFixedIncomeInfo?.filter((item) => item?.spouseCheck === 0)
            : [],

          // Other Assets
          PrimaryResidence: userPrimaryResidenceInfo[0]
            ? userPrimaryResidenceInfo[0]
            : {},
          RecreationalProperty: userCottageInfo ? userCottageInfo : [],
          IncomeProperty: userPropertiesInfo ? userPropertiesInfo : [],
          Land: userLandInfo ? userLandInfo : [],
          Business: userBusinessInfo?.[0] ? userBusinessInfo?.[0] : {},
          Company: userCompanyInfo ? userCompanyInfo : {},
          LifeInsurance: userLifeInsuranceInfo ? userLifeInsuranceInfo : [],
          OtherAssets: userOtherAssetsInfo ? userOtherAssetsInfo : [],
          Inheritance: userInheritanceInfo ? userInheritanceInfo : [],
          // DEBT
          Debts: userDebtInfo ? userDebtInfo : [],
          // Expenses
          expenseArray: JSON.stringify(userExpensesInfo?.expenseArray)
            ? JSON.stringify(userExpensesInfo?.expenseArray)
            : [],
          CPI:
            Number(userExpensesInfo?.expenseCPI) / 100
              ? Number(userExpensesInfo?.expenseCPI) / 100
              : 0,
          // ExpenseGrowth: userExpensesInfo?.growth,
          // GOALS
          Goals: userGoalInfo ? userGoalInfo : [],

          // Final Parameters
          OptimizationType: optimize,
          SetValue: numCustomValue,
          IncludeAssets: includeAssets,
          IncludePrimaryRes: includePrimaryRes,
          IncludeRecreationProperty: includeRecreationProperty,
          IncludeIncomeProperty: includeIncomeProperty,
          IncludeLand: includeLand,
          IncludeBusiness: includeBusiness,
          IncludeLifeInsurance: includeLifeInsurance,
        });
        // console.log(jsonObject);
        // NEED TO TURN ON FOR INSIGHTS
        // InsightsRuns(JSON.parse(jsonObject), 1, 1, config, false);
        axios
          .post(`${process.env.REACT_APP_ENGINE_DOMAIN}`, jsonObject, {
            timeout: 900000, // 15 minutes in milliseconds
            ...config,
          })
          .then((response) => {
            console.log(response);
            if (response.data.hasOwnProperty("Error")) {
              setErrorPage(true);
              setLoading(false);
            } else {
              setOptiList(response?.data?.solutions[0]);
              setLoadingCircles(true);
              const spouseOneData = JSON.stringify(
                response?.data?.solutions?.[0]
              );
              const spouseOneROT = JSON.stringify(
                response?.data?.solutions?.[1]
              );

              const testObject = {
                OPTI_DATA_ID: user.sub,
                user_opti_data: spouseOneData,
                spouse1_ROT: spouseOneROT,
              };

              const singleStoreOptiData = {
                user_opti_data: spouseOneData,
                spouse1_ROT: spouseOneROT,
              };
              setUserOptiData(singleStoreOptiData);
              axiosInstance
                .post(
                  `${process.env.REACT_APP_NODE_API_GATEWAY}/CompassLambdaApi/JSON_OPTI_DATA/add`,
                  testObject
                )
                .then((res) => {
                  console.log(res);
                  if (res.data.code === "ER_DUP_ENTRY") {
                    axiosInstance
                      .post(
                        `${process.env.REACT_APP_NODE_API_GATEWAY}/CompassLambdaApi/JSON_OPTI_DATA/update`,
                        testObject
                      )
                      .then((res) => {
                        console.log(res);

                        setTimeout(() => {
                          setChoosePlan(true);
                          audioTune.play();
                        }, 500);
                      });
                  } else {
                    setTimeout(() => {
                      setChoosePlan(true);
                      audioTune.play();
                    }, 500);
                  }
                })
                .catch((error) => {
                  console.log(error);
                });
            }
          })
          .catch((error) => {
            setErrorPage(true);
            setLoading(false);
          });
      });
    } else {
      const AccessTokenPromise = getAccessTokenSilently({
        audience:
          "https://os2lsiwff43wfpb5ggyawt6qri0mgcro.lambda-url.us-east-2.on.aws/",
        scope: "write:optimization",
      });
      Promise.all([AccessTokenPromise]).then((values) => {
        var AccessToken = values[0];
        const config = {
          headers: {
            Authorization: `Bearer ${AccessToken}`,
            "content-type": "application/json",
          },
        };
        setCurrentAge(userInfo?.DateOfBirth);
        setRunToAge(userInfo?.runAge);
        let singleFoundItem = userPensionInfo?.find(
          (item) => item?.spouseCheck === 0
        );
        let foundItem = userPensionInfo?.find(
          (item) => item?.spouseCheck === 1
        );
        const jsonObject = JSON.stringify({
          // Spouse 1
          // Personal Info
          Email: user.email,
          Married: "True",
          SPS1_Name: userInfo?.FirstName,
          SPS1_LastName: userInfo?.LastName,
          SPS1_Birthday: userInfo?.DateOfBirth,
          AgeToRun: userInfo?.runAge,
          Province: userInfo?.Province,
          planRunDate: planRunDate,
          // INCOME
          SPS1_Salary: JSON.stringify(userIncomeInfo?.Salary_Array)
            ? JSON.stringify(userIncomeInfo?.Salary_Array)
            : [0],
          SPS1_SelfEmployed: userIncomeInfo?.Employment_Type
            ? userIncomeInfo?.Employment_Type
            : "",
          SPS1_paysEI: userIncomeInfo?.Do_You_EI
            ? userIncomeInfo?.Do_You_EI
            : "Yes",
          SPS1_Deductions: userIncomeInfo?.Deductions
            ? userIncomeInfo?.Deductions
            : 0,
          // CPP
          SPS1_CPP: Math.max(
            userIncomeInfo?.Monthly_Retirement_Pension,
            userIncomeInfo?.Monthly_Retirement_Pension
          )
            ? Math.max(
                userIncomeInfo?.Monthly_Retirement_Pension,
                userIncomeInfo?.Monthly_Retirement_Pension
              )
            : 831,
          SPS1_hasStartedCPP: userIncomeInfo?.hasStartedCPP
            ? userIncomeInfo?.hasStartedCPP
            : "No",
          SPS1_hasStartedCPPThisYear: userIncomeInfo?.hasStartedCPPThisYear
            ? userIncomeInfo?.hasStartedCPPThisYear
            : "No",
          SPS1_cppMonth: userIncomeInfo?.cppMonth
            ? userIncomeInfo?.cppMonth
            : "",
          // OAS
          SPS1_OAS_40_Years: userIncomeInfo?.OAS_40_Years
            ? userIncomeInfo?.OAS_40_Years
            : "",
          SPS1_OAS_Years: userIncomeInfo?.OAS_Years
            ? userIncomeInfo?.OAS_Years
            : "",
          SPS1_hasStartedOAS: userIncomeInfo?.hasStartedOAS
            ? userIncomeInfo?.hasStartedOAS
            : "No",
          SPS1_hasStartedOASThisYear: userIncomeInfo?.hasStartedOASThisYear
            ? userIncomeInfo?.hasStartedOASThisYear
            : "No",
          SPS1_oasMonth: userIncomeInfo?.oasMonth
            ? userIncomeInfo?.oasMonth
            : "",
          SPS1_oasAmount: userIncomeInfo?.oasAmount
            ? userIncomeInfo?.oasAmount
            : 0,
          //
          SPS1_Pension: {
            Type: singleFoundItem
              ? singleFoundItem?.Pension_Type === "Defined Benefit Plan"
                ? "DB"
                : "DC"
              : "",
            Value: singleFoundItem ? Number(singleFoundItem?.Pension) : 0,
            StartAge: singleFoundItem ? Number(singleFoundItem?.pensionAge) : 0,
            EmployerContribution: singleFoundItem
              ? Number(singleFoundItem?.Employer_Cont) / 100
              : 0,
            SelfContribution: singleFoundItem
              ? Number(singleFoundItem?.Personal_Cont) / 100
              : 0,
            Growth: singleFoundItem
              ? Number(singleFoundItem?.Pension_Index) / 100
              : 0,
          },
          // Other Income
          SPS1_Other_Income: userOtherIncomeInfo?.filter(
            (item) => item.spouseCheck === 0
          )
            ? userOtherIncomeInfo?.filter((item) => item.spouseCheck === 0)
            : [],
          // TFSA
          SPS1_TFSA: Math.max(Number(userTFSAInfo?.totalValue ?? 0), 0),
          SPS1_TFSA_ContributionRoom: Math.max(
            Number(userTFSAInfo?.TFSA_Room ?? 0),
            0
          ),
          SPS1_TFSA_Growth: Math.max(
            Number(userTFSAInfo?.TFSA_ROR ?? 6) / 100,
            0
          ),
          SPS1_TFSA_CONT_THIS_YEAR: Math.max(
            Number(userTFSAInfo?.tfsaContThisYear ?? 0),
            0
          ),
          // RRSP
          SPS1_RRSP: Math.max(Number(userRRSPInfo?.totalValue ?? 0), 0),
          SPS1_RRSP_TO_RRIF: userRRSPInfo?.hasRRIF
            ? userRRSPInfo.hasRRIF
            : "No",
          SPS1_RRSP_Contribution: Math.max(
            Number(userRRSPInfo?.RRSP_Cont_This_Year ?? 0),
            0
          ),
          SPS1_RRSP_SelfContributionPercentage:
            Number(userRRSPInfo?.Employer_Cont_Percent ?? 0) / 100,
          SPS1_RRSP_EmployerContributionPercentage:
            Number(userRRSPInfo?.Employer_Cont_Percent ?? 0) / 100,
          SPS1_RRSP_Growth: Math.max(
            Number(userRRSPInfo?.RRSP_ROR ?? 6) / 100,
            0
          ),
          // FHSA
          FHSA: userFHSAInfo,
          // LIRA
          SPS1_LIRA: Math.max(Number(userLiraInfo?.SPS1_LIRA_VALUE ?? 0), 0),
          SPS1_LIRA_Growth: Math.max(
            Number(userLiraInfo?.LIRA_ROR ?? 6) / 100,
            0
          ),
          SPS1_LIRA_isLif: userLiraInfo?.Has_LIRA_LIF ?? "",
          // Non Registered
          SPS1_NONREG: Math.max(
            Number(userNonRegInvestmentsInfo?.totalValue ?? 0),
            0
          ),
          SPS1_ACB: Math.max(Number(userNonRegInvestmentsInfo?.ACB ?? 0), 0),
          SPS1_NONREG_Growth: Math.max(
            Number(userNonRegInvestmentsInfo?.Non_Reg_ROR ?? 6) / 100,
            0
          ),
          SPS1_Eligible_Div:
            (userNonRegInvestmentsInfo?.totalValue ?? 0) > 0
              ? Math.max(
                  Number(userNonRegInvestmentsInfo?.Eligible_Div ?? 0) /
                    userNonRegInvestmentsInfo?.totalValue,
                  0
                )
              : 0,
          SPS1_Non_Eligible_Div:
            (userNonRegInvestmentsInfo?.totalValue ?? 0) > 0
              ? Math.max(
                  Number(userNonRegInvestmentsInfo?.Non_Eligible_Div ?? 0) /
                    userNonRegInvestmentsInfo?.totalValue,
                  0
                )
              : 0,
          SPS1_Foreign_Div:
            (userNonRegInvestmentsInfo?.totalValue ?? 0) > 0
              ? Math.max(
                  Number(userNonRegInvestmentsInfo?.Foreign_Div ?? 0) /
                    userNonRegInvestmentsInfo?.totalValue,
                  0
                )
              : 0,
          SPS1_NONREG_CONT_THIS_YEAR: Math.max(
            Number(userNonRegInvestmentsInfo?.nonRegContThisYear ?? 0),
            0
          ),
          // CASH
          SPS1_cashTotal: Math.max(Number(userCASHInfo?.value ?? 0), 0),
          SPS1_minCashBalance: Math.max(
            Number(userCASHInfo?.minCashValue ?? 0),
            0
          ),

          // Fixed Income
          SPS1_fixedIncome: userFixedIncomeInfo?.filter(
            (item) => item?.spouseCheck === 0
          )
            ? userFixedIncomeInfo?.filter((item) => item?.spouseCheck === 0)
            : [],

          // Other Assets
          PrimaryResidence: userPrimaryResidenceInfo?.[0]
            ? userPrimaryResidenceInfo?.[0]
            : {},
          RecreationalProperty: userCottageInfo ? userCottageInfo : [],
          IncomeProperty: userPropertiesInfo ? userPropertiesInfo : [],
          Land: userLandInfo ? userLandInfo : [],
          Business: userBusinessInfo?.[0] ? userBusinessInfo?.[0] : {},
          Company: userCompanyInfo ? userCompanyInfo : {},
          LifeInsurance: userLifeInsuranceInfo ? userLifeInsuranceInfo : [],
          OtherAssets: userOtherAssetsInfo ? userOtherAssetsInfo : [],
          Inheritance: userInheritanceInfo ? userInheritanceInfo : [],
          // DEBT
          Debts: userDebtInfo ? userDebtInfo : [],
          // Expenses
          expenseArray: JSON.stringify(userExpensesInfo?.expenseArray)
            ? JSON.stringify(userExpensesInfo?.expenseArray)
            : [],
          CPI:
            Number(userExpensesInfo?.expenseCPI) / 100
              ? Number(userExpensesInfo?.expenseCPI) / 100
              : 0,
          // ExpenseGrowth: userExpensesInfo?.growth,
          // GOALS
          Goals: userGoalInfo ? userGoalInfo : [],

          // Final Parameters
          OptimizationType: optimize,
          SetValue: numCustomValue,
          IncludeAssets: includeAssets,
          IncludePrimaryRes: includePrimaryRes,
          IncludeRecreationProperty: includeRecreationProperty,
          IncludeIncomeProperty: includeIncomeProperty,
          IncludeLand: includeLand,
          IncludeBusiness: includeBusiness,
          IncludeLifeInsurance: includeLifeInsurance,

          // Spouse 2

          // Personal
          SPS2_Name: userInfo?.spouseFirstName,
          SPS2_LastName: userInfo?.spouseLastName,
          SPS2_Birthday: userInfo?.spouseDateOfBirth,
          // INCOME
          SPS2_Salary: JSON.stringify(userIncomeInfo?.spouseSalary_Array)
            ? JSON.stringify(userIncomeInfo?.spouseSalary_Array)
            : [0],
          SPS2_SelfEmployed: userIncomeInfo?.spouseEmployment_Type
            ? userIncomeInfo?.spouseEmployment_Type
            : "",
          SPS2_paysEI: userIncomeInfo?.spouseDo_You_EI
            ? userIncomeInfo?.spouseDo_You_EI
            : "Yes",
          SPS2_Deductions: userIncomeInfo?.spouseDeductions
            ? userIncomeInfo?.spouseDeductions
            : 0,
          // CPP
          SPS2_CPP: Math.max(
            userIncomeInfo?.spouseMonthly_Retirement_Pension,
            userIncomeInfo?.spouseMonthly_Retirement_Pension
          )
            ? Math.max(
                userIncomeInfo?.spouseMonthly_Retirement_Pension,
                userIncomeInfo?.spouseMonthly_Retirement_Pension
              )
            : 831,
          SPS2_spouseHasStartedCPP: userIncomeInfo?.spouseHasStartedCPP
            ? userIncomeInfo?.spouseHasStartedCPP
            : "No",
          SPS2_spouseHasStartedCPPThisYear:
            userIncomeInfo?.spouseHasStartedCPPThisYear
              ? userIncomeInfo?.spouseHasStartedCPPThisYear
              : "No",
          SPS2_spouseCPPMonth: userIncomeInfo?.spouseCPPMonth
            ? userIncomeInfo?.spouseCPPMonth
            : "",
          // OAS
          SPS2_spouseOAS_40_Years: userIncomeInfo?.spouseOAS_40_Years
            ? userIncomeInfo?.spouseOAS_40_Years
            : "",
          SPS2_spouseOAS_Years: userIncomeInfo?.spouseOAS_Years
            ? userIncomeInfo?.spouseOAS_Years
            : "",
          SPS2_spouseHasStartedOAS: userIncomeInfo?.spouseHasStartedOAS
            ? userIncomeInfo?.spouseHasStartedOAS
            : "No",
          SPS2_spouseHasStartedOASThisYear:
            userIncomeInfo?.spouseHasStartedOASThisYear
              ? userIncomeInfo?.spouseHasStartedOASThisYear
              : "No",
          SPS2_spouseOASMonth: userIncomeInfo?.spouseOASMonth
            ? userIncomeInfo?.spouseOASMonth
            : "No",
          SPS2_spouseOASAmount: userIncomeInfo?.spouseOASAmount
            ? userIncomeInfo?.spouseOASAmount
            : 0,
          //
          SPS2_Pension: {
            Type: foundItem
              ? foundItem?.Pension_Type === "Defined Benefit Plan"
                ? "DB"
                : "DC"
              : "",
            Value: foundItem ? Number(foundItem?.Pension ?? 0) : 0,
            StartAge: foundItem ? Number(foundItem?.pensionAge) : 0,
            EmployerContribution: foundItem
              ? Number(foundItem?.Employer_Cont ?? 0) / 100
              : 0,
            SelfContribution: foundItem
              ? Number(foundItem?.Personal_Cont ?? 0) / 100
              : 0,
            Growth: foundItem ? Number(foundItem?.Pension_Index ?? 0) / 100 : 0,
          },
          // Other Income
          SPS2_Other_Income: userOtherIncomeInfo?.filter(
            (item) => item.spouseCheck === 1
          )
            ? userOtherIncomeInfo?.filter((item) => item.spouseCheck === 1)
            : [],
          // TFSA
          SPS2_TFSA: Math.max(Number(userTFSAInfo?.spouseTotalValue ?? 0), 0),
          SPS2_TFSA_ContributionRoom: Math.max(
            Number(userTFSAInfo?.spouseTFSA_Room ?? 0),
            0
          ),
          SPS2_TFSA_Growth: Math.max(
            Number(userTFSAInfo?.spouseTFSA_ROR ?? 6) / 100,
            0
          ),
          SPS2_TFSA_CONT_THIS_YEAR: Math.max(
            Number(userTFSAInfo?.spouseTfsaContThisYear ?? 0),
            0
          ),
          // RRSP
          SPS2_RRSP: Math.max(Number(userRRSPInfo?.spouseTotalValue ?? 0), 0),
          SPS2_RRSP_TO_RRIF: userRRSPInfo?.spouseHasRRIF
            ? userRRSPInfo.spouseHasRRIF
            : "No",
          SPS2_RRSP_Contribution: Math.max(
            Number(userRRSPInfo?.spouseRRSP_Cont_This_Year ?? 0),
            0
          ),
          SPS2_RRSP_SelfContributionPercentage:
            Number(userRRSPInfo?.spouseEmployer_Cont_Percent ?? 0) / 100,
          SPS2_RRSP_EmployerContributionPercentage:
            Number(userRRSPInfo?.spouseEmployer_Cont_Percent ?? 0) / 100,
          SPS2_RRSP_Growth: Math.max(
            Number(userRRSPInfo?.spouseRRSP_ROR ?? 6) / 100,
            0
          ),
          // LIRA
          SPS2_LIRA: Math.max(Number(userLiraInfo?.SPS2_LIRA_VALUE ?? 0), 0),
          SPS2_LIRA_Growth: Math.max(
            Number(userLiraInfo?.spouseLIRA_ROR ?? 6) / 100,
            0
          ),
          SPS2_LIRA_isLif: userLiraInfo?.spouseHas_LIRA_LIF ?? "",
          // Non Registered
          SPS2_NONREG: Math.max(
            Number(userNonRegInvestmentsInfo?.spouseTotalValue ?? 0),
            0
          ),
          SPS2_ACB: Math.max(
            Number(userNonRegInvestmentsInfo?.spouseACB ?? 0),
            0
          ),
          SPS2_NONREG_Growth: Math.max(
            Number(userNonRegInvestmentsInfo?.spouseNon_Reg_ROR ?? 6) / 100,
            0
          ),

          SPS2_Eligible_Div:
            (userNonRegInvestmentsInfo?.spouseTotalValue ?? 0) > 0
              ? Math.max(
                  Number(userNonRegInvestmentsInfo?.Spouse_Eligible_Div ?? 0) /
                    userNonRegInvestmentsInfo?.spouseTotalValue,
                  0
                )
              : 0,

          SPS2_Non_Eligible_Div:
            (userNonRegInvestmentsInfo?.spouseTotalValue ?? 0) > 0
              ? Math.max(
                  Number(
                    userNonRegInvestmentsInfo?.Spouse_Non_Eligible_Div ?? 0
                  ) / userNonRegInvestmentsInfo?.spouseTotalValue,
                  0
                )
              : 0,

          SPS2_Foreign_Div:
            (userNonRegInvestmentsInfo?.spouseTotalValue ?? 0) > 0
              ? Math.max(
                  Number(userNonRegInvestmentsInfo?.Spouse_Foreign_Div ?? 0) /
                    userNonRegInvestmentsInfo?.spouseTotalValue,
                  0
                )
              : 0,
          SPS2_NONREG_CONT_THIS_YEAR: Math.max(
            Number(userNonRegInvestmentsInfo?.spouseNonRegContThisYear ?? 0),
            0
          ),
          // CASH
          SPS2_cashTotal: Math.max(Number(userCASHInfo?.spouseValue ?? 0), 0),
          SPS2_minCashBalance: Math.max(
            Number(userCASHInfo?.spouseMinCashValue ?? 0),
            0
          ),

          // Fixed Income
          SPS2_fixedIncome: userFixedIncomeInfo?.filter(
            (item) => item?.spouseCheck === 1
          )
            ? userFixedIncomeInfo?.filter((item) => item?.spouseCheck === 1)
            : [],
        });
                // NEED TO TURN ON FOR INSIGHTS
        // InsightsRuns(JSON.parse(jsonObject), 1, 1, config, true);
        axios
          .post(`${process.env.REACT_APP_ENGINE_DOMAIN}`, jsonObject, {
            timeout: 900000, // 15 minutes in milliseconds
            ...config,
          })
          .then((response) => {
            // console.log('HERE ', response);
            if (response.data.hasOwnProperty("Error")) {
              setErrorPage(true);
              setLoading(false);
            } else {
              setOptiList(response?.data?.solutions[0]?.sps1[0]);
              setSpouseOptilist(response?.data?.solutions[0]?.sps2[0]);
              setLoadingCircles(true);
              // setChoosePlan(true);
              const spouseData = JSON.stringify(
                response?.data?.solutions[0]?.sps1[0]
              );
              const spouseTwoData = JSON.stringify(
                response?.data?.solutions[0]?.sps2[0]
              );

              let spouse1_ROT = {};
              let spouse2_ROT = {};

              // Check if 'sps1' and 'sps2' are arrays and have at least one element
              if (
                response?.data?.solutions[1]?.sps1 &&
                response.data.solutions[1].sps1.length > 0
              ) {
                spouse1_ROT = JSON.stringify(
                  response.data.solutions[1].sps1[0]
                );
                spouse2_ROT = JSON.stringify(
                  response.data.solutions[1].sps2[0]
                );
              } else {
                spouse1_ROT = JSON.stringify(
                  response.data.solutions[0].sps1[0]
                );
                spouse2_ROT = JSON.stringify(
                  response.data.solutions[0].sps2[0]
                );
              }
              const spouseObject = {
                OPTI_DATA_ID: user.sub,
                user_opti_data: spouseData,
                spouse1_ROT: spouse1_ROT,
              };

              const spouseTwoObject = {
                OPTI_DATA_ID: user.sub,
                spouse_opti_data: spouseTwoData,
                spouse2_ROT: spouse2_ROT,
              };
              const storeOptiData = {
                user_opti_data: spouseData,
                spouse_opti_data: spouseTwoData,
                spouse1_ROT: spouse1_ROT,
                spouse2_ROT: spouse2_ROT,
              };
              setUserOptiData(storeOptiData);
              axiosInstance
                .post(
                  `${process.env.REACT_APP_NODE_API_GATEWAY}/CompassLambdaApi/JSON_OPTI_DATA/add`,
                  spouseObject
                )
                .then((res) => {
                  console.log("S1", res);
                  if (res.data.code === "ER_DUP_ENTRY") {
                    axiosInstance
                      .post(
                        `${process.env.REACT_APP_NODE_API_GATEWAY}/CompassLambdaApi/JSON_OPTI_DATA/update`,
                        spouseObject
                      )
                      .then((res) => {
                        console.log("S1", res);
                      });
                  }
                  console.log(res);
                  axiosInstance
                    .post(
                      `${process.env.REACT_APP_NODE_API_GATEWAY}/CompassLambdaApi/JSON_OPTI_DATA/addspouse`,
                      spouseTwoObject
                    )
                    .then((res) => {
                      console.log("S2", res);
                      if (res.data.code === "ER_DUP_ENTRY") {
                        axiosInstance
                          .post(
                            `${process.env.REACT_APP_NODE_API_GATEWAY}/CompassLambdaApi/JSON_OPTI_DATA/updatespouse`,
                            spouseTwoObject
                          )
                          .then((res) => {
                            console.log("S2", res);

                            setTimeout(() => {
                              setChoosePlan(true);
                              audioTune.play();
                            }, 500);
                          });
                      } else {
                        setTimeout(() => {
                          setChoosePlan(true);
                          audioTune.play();
                        }, 500);
                      }
                      console.log(res);
                    })
                    .catch((err) => {
                      console.log(err);
                    });
                })
                .catch((err) => {
                  console.log(err);
                });
            }
          })
          .catch((error) => {
            setErrorPage(true);
            setLoading(false);

            console.log(error);
          });
      });
    }
  };

  const choosePlanFunc = () => {
    setChoosePlan(true);
  };

  const optiListFunc = (list) => {
    setOptiList(list);
  };

  const currencyFormat = (money) => {
    if (money == "") {
      return "";
    } else {
      const intTotal = Math.round(Math.round(money * 100) / 100);
      const currencyCad = new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "USD",
        minimumFractionDigits: 0,
      }).format(intTotal);
      return currencyCad;
    }
  };

  const moneyToNumber = (value) => {
    let stringValue = String(value); // Convert to string
    let numValue = stringValue.replace(/\D/g, "");
    numValue = Number(numValue);

    return numValue;
  };

  function getAge(birthday) {
    const currentDate = new Date();
    // Get the current date
    // const currentDate = new Date();

    // Convert the birthday string into a Date object
    const birthDate = new Date(birthday);

    // Calculate the age in years
    let age = currentDate.getFullYear() - birthDate.getFullYear();

    // Decrement the age if the current month and day are before the birth month and day
    if (
      currentDate.getMonth() < birthDate.getMonth() ||
      (currentDate.getMonth() === birthDate.getMonth() &&
        currentDate.getDate() <= birthDate.getDate())
    ) {
      age -= 1;
    }

    return age;
  }

  function safeAdd(...values) {
    return values.reduce((sum, val) => sum + (Number(val) || 0), 0);
  }

  let totalMonthlyPremiums = userLifeInsuranceInfo?.reduce(
    (sum, item) => sum + (item?.Monthly_Premiums || 0),
    0
  );
  let totalIncomePropertyMortgagePayments = userPropertiesInfo?.reduce(
    (sum, item) => sum + (item?.Income_Property_Mortgage_Payments || 0),
    0
  );
  let totalLandPayments = userLandInfo?.reduce(
    (sum, item) => sum + (item?.landDebtPayments || 0),
    0
  );
  let totalCottageMonthlyPayments = userCottageInfo?.reduce(
    (sum, item) => sum + (item?.Cottage_Monthly_Payments || 0),
    0
  );
  let totalDebtMonthlyPayments = userDebtInfo?.reduce(
    (sum, item) => sum + (item?.Debt_Monthly_Payments || 0),
    0
  );

  let totalIncomePropertyMortgageBalance = userPropertiesInfo?.reduce(
    (sum, item) => sum + (item?.Income_Property_Mortgage_Balance || 0),
    0
  );
  let totalLandDebtBalance = userLandInfo?.reduce(
    (sum, item) => sum + (item?.landDebt || 0),
    0
  );
  let totalCottageMonthlyBalance = userCottageInfo?.reduce(
    (sum, item) => sum + (item?.Cottage_Mortgage_Balance || 0),
    0
  );

  let totalMortgageBalance = safeAdd(
    totalIncomePropertyMortgageBalance,
    totalLandDebtBalance,
    totalCottageMonthlyBalance,
    userPrimaryResidenceInfo?.[0]?.Primary_Res_Mortgage_Balance
  );

  let totalAnnualPayments =
    safeAdd(
      totalMonthlyPremiums,
      totalIncomePropertyMortgagePayments,
      totalLandPayments,
      totalCottageMonthlyPayments,
      totalDebtMonthlyPayments
    ) * 12;

  let totalIncomePropertyValue = userPropertiesInfo?.reduce(
    (sum, item) => sum + (item?.value || 0),
    0
  );
  let totalLandValue = userLandInfo?.reduce(
    (sum, item) => sum + (item?.value || 0),
    0
  );
  let totalCottageValue = userCottageInfo?.reduce(
    (sum, item) => sum + (item?.value || 0),
    0
  );
  let totalLifeInsuranceValue = userLifeInsuranceInfo?.reduce(
    (sum, item) => sum + (item?.value || 0),
    0
  );
  let totalOtherAssetValue = userOtherAssetsInfo?.reduce(
    (sum, item) => sum + (item?.Other_Asset_Value || 0),
    0
  );
  let totalBusinessValue = userCompanyInfo?.reduce(
    (sum, item) => sum + (item?.value || 0),
    0
  );
  let totalBusinessInvestments = userBusinessInfo?.reduce(
    (sum, item) => sum + (item?.ccpcValue || 0),
    0
  );

  let totalAssetValue = safeAdd(
    totalIncomePropertyValue,
    totalLandValue,
    totalCottageValue,
    totalLifeInsuranceValue,
    totalOtherAssetValue,
    totalBusinessValue,
    totalBusinessInvestments
  );

  let totalPension = userPensionInfo?.reduce((acc, curr) => {
    return curr?.Pension_Type !== "Defined Benefit Plan" &&
      curr?.spouseCheck === 0
      ? acc + curr?.Pension
      : acc;
  }, 0)
    ? userPensionInfo?.reduce((acc, curr) => {
        return curr?.Pension_Type !== "Defined Benefit Plan" &&
          curr?.spouseCheck === 0
          ? acc + curr?.Pension
          : acc;
      }, 0)
    : 0;

  let totalPensionIncome = userPensionInfo?.reduce((acc, curr) => {
    return curr?.Pension_Type === "Defined Benefit Plan" &&
      curr?.spouseCheck === 0 &&
      Number(curr?.pensionAge) <= Number(getAge(userInfo?.DateOfBirth))
      ? acc + curr?.Pension
      : acc;
  }, 0)
    ? userPensionInfo?.reduce((acc, curr) => {
        return curr?.Pension_Type === "Defined Benefit Plan" &&
          curr?.spouseCheck === 0 &&
          Number(curr?.pensionAge) <= Number(getAge(userInfo?.DateOfBirth))
          ? acc + curr?.Pension
          : acc;
      }, 0)
    : 0;

  let totalOtherIncome = userOtherIncomeInfo?.reduce((acc, curr) => {
    return curr?.spouseCheck === 0 &&
      Number(curr?.Other_Income_Start_Age) <=
        Number(getAge(userInfo?.DateOfBirth))
      ? acc + curr?.Other_Income_Amount
      : acc;
  }, 0)
    ? userOtherIncomeInfo?.reduce((acc, curr) => {
        return curr?.spouseCheck === 0 &&
          Number(curr?.Other_Income_Start_Age) <=
            Number(getAge(userInfo?.DateOfBirth))
          ? acc + curr?.Other_Income_Amount
          : acc;
      }, 0)
    : 0;

  let totalCPPIncome =
    userIncomeInfo?.hasStartedCPP === "Yes"
      ? userIncomeInfo?.Monthly_Retirement_Pension
      : 0;

  let totalOASIncome =
    userIncomeInfo?.hasStartedOAS === "Yes" ? userIncomeInfo?.oasAmount : 0;

  let totalIncomePropertyIncome = userPropertiesInfo?.reduce(
    (sum, item) => sum + (item?.Income_Property_Annual_Income || 0),
    0
  );

  let spouseTotalPension = userPensionInfo?.reduce((acc, curr) => {
    return curr?.Pension_Type !== "Defined Benefit Plan" &&
      curr?.spouseCheck === 1
      ? acc + curr?.Pension
      : acc;
  }, 0)
    ? userPensionInfo?.reduce((acc, curr) => {
        return curr?.Pension_Type !== "Defined Benefit Plan" &&
          curr?.spouseCheck === 1
          ? acc + curr?.Pension
          : acc;
      }, 0)
    : 0;

  let spouseTotalPensionIncome = userPensionInfo?.reduce((acc, curr) => {
    return curr?.Pension_Type === "Defined Benefit Plan" &&
      curr?.spouseCheck === 1 &&
      Number(curr?.pensionAge) <= Number(getAge(userInfo?.spouseDateOfBirth))
      ? acc + curr?.Pension
      : acc;
  }, 0)
    ? userPensionInfo?.reduce((acc, curr) => {
        return curr?.Pension_Type === "Defined Benefit Plan" &&
          curr?.spouseCheck === 1 &&
          Number(curr?.pensionAge) <=
            Number(getAge(userInfo?.spouseDateOfBirth))
          ? acc + curr?.Pension
          : acc;
      }, 0)
    : 0;

  let spouseTotalOtherIncome = userOtherIncomeInfo?.reduce((acc, curr) => {
    return curr?.spouseCheck === 1 &&
      Number(curr?.Other_Income_Start_Age) <=
        Number(getAge(userInfo?.spouseDateOfBirth))
      ? acc + curr?.Other_Income_Amount
      : acc;
  }, 0)
    ? userOtherIncomeInfo?.reduce((acc, curr) => {
        return curr?.spouseCheck === 1 &&
          Number(curr?.Other_Income_Start_Age) <=
            Number(getAge(userInfo?.spouseDateOfBirth))
          ? acc + curr?.Other_Income_Amount
          : acc;
      }, 0)
    : 0;

  let spouseTotalCPPIncome =
    userIncomeInfo?.spouseHasStartedCPP === "Yes"
      ? userIncomeInfo?.spouseMonthly_Retirement_Pension
      : 0;

  let spouseTotalOASIncome =
    userIncomeInfo?.spouseHasStartedOAS === "Yes"
      ? userIncomeInfo?.spouseOASAmount
      : 0;

  let PensionGrowth = userPensionInfo?.reduce((acc, curr) => {
    return curr?.Pension_Type !== "Defined Benefit Plan" &&
      curr?.spouseCheck === 0
      ? acc + curr?.Pension_Index
      : acc;
  }, 0)
    ? userPensionInfo?.reduce((acc, curr) => {
        return curr?.Pension_Type !== "Defined Benefit Plan" &&
          curr?.spouseCheck === 0
          ? acc + curr?.Pension_Index
          : acc;
      }, 0)
    : 0;

  let spousePensionGrowth = userPensionInfo?.reduce((acc, curr) => {
    return curr?.Pension_Type !== "Defined Benefit Plan" &&
      curr?.spouseCheck === 1
      ? acc + curr?.Pension_Index
      : acc;
  }, 0)
    ? userPensionInfo?.reduce((acc, curr) => {
        return curr?.Pension_Type !== "Defined Benefit Plan" &&
          curr?.spouseCheck === 1
          ? acc + curr?.Pension_Index
          : acc;
      }, 0)
    : 0;

  let totalDebtValue = userDebtInfo?.reduce(
    (sum, item) => sum + (item?.Amount_Borrowed || 0),
    0
  );

  let totalFixedIncome = userFixedIncomeInfo?.reduce((sum, item) => {
    if (item?.spouseCheck === 0) {
      return sum + (item?.fixedIncomeValue || 0); // Adding 0 if fixedIncomeValue is undefined
    } else {
      return sum;
    }
  }, 0); // Starting sum from 0

  let totalSpouseFixedIncome = userFixedIncomeInfo?.reduce((sum, item) => {
    if (item?.spouseCheck === 1) {
      return sum + (item?.fixedIncomeValue || 0); // Adding 0 if fixedIncomeValue is undefined
    } else {
      return sum;
    }
  }, 0); // Starting sum from 0

  let filteredItems = userFixedIncomeInfo?.filter(
    (item) => item?.spouseCheck === 0
  );
  let totalInterest = filteredItems.reduce(
    (sum, item) => sum + (item?.fixedIncomeInterest || 0),
    0
  );
  let averageInterest =
    filteredItems.length > 0 ? totalInterest / filteredItems.length : 0;

  let filteredItemsSpouse = userFixedIncomeInfo?.filter(
    (item) => item?.spouseCheck === 1
  );
  let totalInterestSpouse = filteredItemsSpouse.reduce(
    (sum, item) => sum + (item?.fixedIncomeInterest || 0),
    0
  );

  let averageInterestSpouse =
    filteredItemsSpouse?.length > 0
      ? totalInterestSpouse / filteredItemsSpouse?.length
      : 0;

  // Assuming userIncomeInfo.Salary_Array and userIncomeInfo.spouseSalary_Array are your arrays
  const salaryArray = userIncomeInfo?.Salary_Array || [];
  const spouseSalaryArray = userIncomeInfo?.spouseSalary_Array || [];

  // Determine the length of the longer array
  const maxLength = Math.max(salaryArray.length, spouseSalaryArray.length);

  // Create a new array where each element is the sum of the elements from the two arrays at each index
  const combinedArray = Array.from({ length: maxLength }, (_, index) => {
    const salary = salaryArray[index] || 0;
    const spouseSalary = spouseSalaryArray[index] || 0;
    return salary + spouseSalary;
  });

  const routeToDash = (e) => {
    $("#dashboard-btn-id").trigger("click");
  };

  document.body.style.overflowY = "scroll";

  const { isOpen, onOpen, onClose } = useDisclosure();

  const data = [
    { label: "Business", value: "$1,500,000" },
    { label: "Business", value: "$1,500,000" },
    { label: "Business", value: "$1,500,000" },
  ];

  const CheckboxItem = ({ label, value }) => (
    <Box
      display={"flex"}
      alignItems="center"
      alignContent={"center"}
      justifyContent="space-between"
      width="100%"
    >
      <Checkbox>{label}</Checkbox>
      <Text>{value}</Text>
    </Box>
  );
  useEffect(() => {
    if (loading) {
      setHeading("Generating your new analysis");
    } else {
      setHeading("");
    }
  }, [loading, setHeading]);

  const [isLargerThan768] = useMediaQuery("(min-width: 1000px)");
  const isMobile = !isLargerThan768;
  const [isLargerThan1200] = useMediaQuery(
    "(min-width: 500px) and (max-width: 1250px)"
  );

  const investmentData = ({ Heading, value, avg, max }) => {
    return (
      <HStack justifyContent={"space-between"} width={"100%"}>
        <Box
          display={"flex"}
          justifyContent={"space-between"}
          width={isLargerThan1200 ? "40%" : "60%"}
          alignItems={"stretch"}
        >
          <Text
            color={"#00466D"}
            fontFamily={"Nunito"}
            fontStyle={"normal"}
            fontWeight={"600"}
            lineHeight={"130%"}
            className="strategy-plan-user-info-blue"
          >
            {" "}
            {Heading}:{" "}
          </Text>
          <span className="strategy-plan-user-info-blue">{value}</span>
        </Box>

        {max && (
          <Box
            display={"flex"}
            justifyContent={"flex-end"}
            alignItems={"flex-start"}
            gap={"5px"}
          >
            <Text
              color={"#00466D"}
              fontFamily={"Nunito"}
              fontStyle={"normal"}
              fontWeight={"600"}
              lineHeight={"130%"}
              className="strategy-plan-user-info-blue"
            >
              Growth:
            </Text>
            <Text
              flexShrink={0}
              color={"#00A2D4"}
              textAlign={"right"}
              fontFamily={"Nunito"}
              fontStyle={"normal"}
              fontWeight={"800"}
              lineHeight={"130%"}
              className="strategy-plan-user-info-blue"
            >
              {Math.max(Number(max), 0)}%
            </Text>
          </Box>
        )}
        {avg >= 0 && (
          <Box
            display={"flex"}
            justifyContent={"flex-end"}
            alignItems={"flex-start"}
            gap={"5px"}
          >
            <Text
              color={"#00466D"}
              fontFamily={"Nunito"}
              fontStyle={"normal"}
              fontWeight={"600"}
              lineHeight={"130%"}
              className="strategy-plan-user-info-blue"
            >
              {" "}
              Growth:{" "}
            </Text>
            <span className="strategy-plan-user-info-blue">
              {avg}%
              <Text
                color={"#00A2D4"}
                fontFamily={"Nunito"}
                fontStyle={"normal"}
                fontWeight={"500"}
                lineHeight={"130%"}
                as={"span"}
              ></Text>
            </span>
          </Box>
        )}
      </HStack>
    );
  };

  const OptimlIconHeight = isMobile
    ? "50px"
    : isLargerThan1200
    ? "40%"
    : "94px";
  const OptimlIconWidth = isMobile ? "50px" : isLargerThan1200 ? "40%" : "94px";

  return (
    <>
      {!choosePlan && (
        <>
          {loading ? (
            <>
              {updateBackButtonStatus(true)}
              <LoadingScreenMessages
                loading={loadingCircles}
                strategy={optimize}
                age={currentAge}
                run={runToAge}
                duration={runAge - getAge(userInfo?.DateOfBirth)}
                married={spouseStatus}
              />
            </>
          ) : (
            <>
              {updateBackButtonStatus(false)}
              {errorPage && <ErrorPage />}
              {!errorPage &&
                select != "Selected" &&
                selectOne != "Selected" &&
                selectTwo != "Selected" && (
                  <>
                    {!spouseStatus && (
                      <div className="box-container-lifestategy">
                        <div className="grid-header">
                          <Box
                            display={"flex"}
                            flexDirection={"column"}
                            alignItems={"flex-start"}
                            gap={"10px"}
                          >
                            <p className="strategy-plan-user-info ">
                              Strategy:
                              <span
                                className="strategy-plan-user-info-blue"
                                style={{ marginLeft: "5px" }}
                              >
                                {strategy}
                              </span>
                            </p>
                            {custom && (
                              <Link href="/lifestrategy">
                                <p className="strategy-plan-user-info ">
                                  Set Value Amount:
                                  <span
                                    className="strategy-plan-user-info-blue"
                                    style={{ marginLeft: "5px" }}
                                  >
                                    {currencyFormat(customValue)}
                                  </span>
                                </p>
                              </Link>
                            )}
                            <Link href="/expenses-household">
                              <p className="strategy-plan-user-info ">
                                Analysis Duration:
                                <span
                                  className="strategy-plan-user-info-blue"
                                  style={{ marginLeft: "5px" }}
                                >
                                  {runAge - getAge(userInfo?.DateOfBirth)} years
                                </span>
                              </p>
                            </Link>
                            <Link href="/expenses-household">
                              <p className="strategy-plan-user-info ">
                                Consumer Price Index:
                                <span
                                  className="strategy-plan-user-info-blue"
                                  style={{ marginLeft: "5px" }}
                                >
                                  {/* {userExpensesInfo?.expenseCPI}% */}
                                  2%
                                </span>
                              </p>
                            </Link>

                            <Link href="/expenses-household">
                              <p className="strategy-plan-user-info ">
                                Running to year:
                                <span
                                  className="strategy-plan-user-info-blue"
                                  style={{ marginLeft: "5px" }}
                                >
                                  {getRunAgeYear()}
                                </span>
                              </p>
                            </Link>
                          </Box>
                          <Icon
                            as={OptimlWhiteBGIcon}
                            svgheight={OptimlIconHeight}
                            svgwidth={OptimlIconWidth}
                          />
                        </div>

                        <div className="grid-container">
                          <div className="grid-item grid-item-2">
                            <p className="strategy-plan-user-info strategy-plan-header-font">
                              {firstName} {lastName}
                            </p>

                            <Text
                              color={"#007EB5"}
                              textAlign={"left"}
                              fontFamily={"Nunito"}
                              fontSize={"12px"}
                              fontStyle={"normal"}
                              fontWeight={"800"}
                              lineHeight={"130%"}
                            >
                              Investments balances
                            </Text>
                            <Link href="/investment-tfsa-page">
                              {investmentData({
                                Heading: "TFSA",
                                value: userTFSAInfo?.totalValue
                                  ? currencyFormat(userTFSAInfo?.totalValue)
                                  : "$0",
                                max: userTFSAInfo?.TFSA_ROR ?? 6,
                              })}
                            </Link>
                            <Link href="/investment-fhsa-page">
                              {investmentData({
                                Heading: "FHSA",
                                value: userFHSAInfo?.fhsaValue
                                  ? currencyFormat(userFHSAInfo?.fhsaValue)
                                  : "$0",
                                max: userFHSAInfo?.fhsaGrowth ?? 6,
                              })}
                            </Link>
                            <Link href="/rrsp-page">
                              {investmentData({
                                Heading: "RRSP",
                                value: userRRSPInfo?.totalValue
                                  ? currencyFormat(userRRSPInfo?.totalValue)
                                  : "$0",
                                max: userRRSPInfo?.RRSP_ROR ?? 6,
                              })}
                            </Link>
                            <Link href="/non-reg-page">
                              {investmentData({
                                Heading: "Non-Reg",
                                value: userNonRegInvestmentsInfo?.totalValue
                                  ? currencyFormat(
                                      userNonRegInvestmentsInfo?.totalValue
                                    )
                                  : "$0",
                                max:
                                  userNonRegInvestmentsInfo?.Non_Reg_ROR ?? 6,
                              })}
                            </Link>
                            {totalFixedIncome > 0 && (
                              <Link href="/fixed-income-page">
                                {investmentData({
                                  Heading: "Fixed Income",
                                  value: totalFixedIncome
                                    ? currencyFormat(totalFixedIncome)
                                    : "$0",
                                  avg: averageInterest,
                                })}
                              </Link>
                            )}
                            <Link href="/cash-savings">
                              {investmentData({
                                Heading: "Cash",
                                value: userCASHInfo?.value
                                  ? currencyFormat(userCASHInfo?.value)
                                  : "$0",
                              })}
                            </Link>

                            {totalPension > 0 && (
                              <Link href="/income-employer-pension">
                                {investmentData({
                                  Heading: "Pension",
                                  value: totalPension
                                    ? currencyFormat(totalPension)
                                    : "$0",
                                  max: PensionGrowth ?? 6,
                                })}
                              </Link>
                            )}
                            {userLiraInfo?.SPS1_LIRA_VALUE > 0 && (
                              <Link href="/other-reg-page">
                                {investmentData({
                                  Heading: "LIRA",
                                  value: userLiraInfo?.SPS1_LIRA_VALUE
                                    ? currencyFormat(
                                        userLiraInfo?.SPS1_LIRA_VALUE
                                      )
                                    : "$0",
                                  max: userLiraInfo?.LIRA_ROR ?? 6,
                                })}
                              </Link>
                            )}

                            <Box
                              display={"flex"}
                              flexDirection={"row"}
                              justifyContent={"space-between"}
                              alignItems={"flex-start"}
                              alignSelf={"stretch"}
                              width={"100%"}
                            >
                              <Box
                                display={"flex"}
                                justifyContent={"space-between"}
                                alignItems={"flex-start"}
                                w={"100%"}
                              >
                                <Text
                                  color={"#00466D"}
                                  fontFamily={"Nunito"}
                                  fontStyle={"normal"}
                                  fontWeight={"900"}
                                  lineHeight={"130%"}
                                  className="strategy-plan-user-info-blue"
                                >
                                  Total:
                                </Text>
                                <span className="strategy-plan-user-info-blue strategy-plan-header-font">
                                  {safeAdd(
                                    userTFSAInfo?.totalValue,
                                    userFHSAInfo?.fhsaValue,
                                    userLiraInfo?.SPS1_LIRA_VALUE,
                                    totalPension,
                                    userRRSPInfo?.totalValue,
                                    userNonRegInvestmentsInfo?.totalValue,
                                    userCASHInfo?.value,
                                    totalFixedIncome
                                  )
                                    ? currencyFormat(
                                        safeAdd(
                                          userTFSAInfo?.totalValue,
                                          userFHSAInfo?.fhsaValue,
                                          userLiraInfo?.SPS1_LIRA_VALUE,
                                          totalPension,
                                          userRRSPInfo?.totalValue,
                                          userNonRegInvestmentsInfo?.totalValue,
                                          userCASHInfo?.value,
                                          totalFixedIncome
                                        )
                                      )
                                    : "$0"}
                                </span>
                              </Box>
                            </Box>

                            {/* <div className="right-content">
              <div className="activity-summary-graph-container mobile-hidden">
                <BarGraph
                  combinedTFSA={userTFSAInfo?.totalValue}
                  combinedRRSP={userRRSPInfo?.totalValue}
                  combinedNonReg={userNonRegInvestmentsInfo?.totalValue}
                  combinedCash={userCASHInfo?.value}
                  combinedFixedIncome={totalFixedIncome}
                  combinedLIRA={userLiraInfo?.SPS1_LIRA_VALUE}
                  combinedPensionValue={totalPension}
                  combinedTotal={
                    userTFSAInfo?.totalValue +
                    totalPension +
                    userLiraInfo?.SPS1_LIRA_VALUE +
                    userRRSPInfo?.totalValue +
                    userNonRegInvestmentsInfo?.totalValue +
                    userCASHInfo?.value +
                    totalFixedIncome
                  }
                />
              </div>
            </div> */}
                          </div>

                          <div className="grid-item grid-item-3">
                            <Text
                              color={"#00A2D4"}
                              textAlign={"left"}
                              fontFamily={"Nunito"}
                              fontSize={"14px"}
                              fontStyle={"normal"}
                              fontWeight={"800"}
                              lineHeight={"130%"}
                            >
                              Financial Overview
                            </Text>
                            <Link href="/revenue">
                              <HStack
                                justifyContent={"space-between"}
                                width={"100%"}
                              >
                                <Text
                                  color={"#00466D"}
                                  fontFamily={"Nunito"}
                                  fontStyle={"normal"}
                                  fontWeight={"600"}
                                  lineHeight={"130%"}
                                  className="strategy-plan-user-info-blue"
                                >
                                  Annual Income:
                                </Text>
                                <Text
                                  width={"90px"}
                                  color={"#00A2D4"}
                                  textAlign={"right"}
                                  fontFamily={"Nunito"}
                                  fontStyle={"normal"}
                                  fontWeight={"800"}
                                  lineHeight={"130%"}
                                  className="strategy-plan-user-info-blue"
                                >
                                  {safeAdd(
                                    userIncomeInfo?.Salary,
                                    totalPensionIncome,
                                    totalIncomePropertyIncome,
                                    totalOtherIncome,
                                    totalCPPIncome,
                                    totalOASIncome
                                  )
                                    ? currencyFormat(
                                        safeAdd(
                                          userIncomeInfo?.Salary,
                                          totalPensionIncome,
                                          totalIncomePropertyIncome,
                                          totalOtherIncome,
                                          totalCPPIncome,
                                          totalOASIncome
                                        )
                                      )
                                    : "$0"}
                                </Text>
                              </HStack>
                            </Link>
                            <Link href="/expenses-household">
                              <HStack
                                justifyContent={"space-between"}
                                width={"100%"}
                              >
                                <Text
                                  color={"#00466D"}
                                  fontFamily={"Nunito"}
                                  fontStyle={"normal"}
                                  fontWeight={"600"}
                                  lineHeight={"130%"}
                                  className="strategy-plan-user-info-blue"
                                >
                                  Living Expenses:
                                </Text>
                                <span className="strategy-plan-user-info-blue">
                                  {userExpensesInfo?.total
                                    ? currencyFormat(userExpensesInfo?.total)
                                    : "$0"}
                                </span>
                              </HStack>
                            </Link>
                            <Link href="/expenses-household">
                              <HStack
                                justifyContent={"space-between"}
                                width={"100%"}
                              >
                                <Text
                                  color={"#00466D"}
                                  fontFamily={"Nunito"}
                                  fontStyle={"normal"}
                                  fontWeight={"600"}
                                  lineHeight={"130%"}
                                  className="strategy-plan-user-info-blue"
                                >
                                  Fixed Expenses:
                                </Text>
                                <span className="strategy-plan-user-info-blue">
                                  {safeAdd(
                                    totalAnnualPayments,
                                    userPrimaryResidenceInfo?.[0]
                                      ?.Primary_Res_Monthly_Payments * 12
                                  )
                                    ? currencyFormat(
                                        safeAdd(
                                          totalAnnualPayments,
                                          userPrimaryResidenceInfo?.[0]
                                            ?.Primary_Res_Monthly_Payments * 12
                                        )
                                      )
                                    : "$0"}
                                </span>
                              </HStack>
                            </Link>

                            <HStack
                              justifyContent={"space-between"}
                              width={"100%"}
                            >
                              <Text
                                color={"#00466D"}
                                fontFamily={"Nunito"}
                                fontStyle={"normal"}
                                fontWeight={"900"}
                                lineHeight={"130%"}
                                className="strategy-plan-user-info-blue"
                              >
                                Total Expenses:
                              </Text>
                              <span className="strategy-plan-user-info-blue strategy-plan-header-font">
                                {safeAdd(
                                  userExpensesInfo?.total,
                                  totalAnnualPayments,
                                  userPrimaryResidenceInfo?.[0]
                                    ?.Primary_Res_Monthly_Payments * 12
                                )
                                  ? currencyFormat(
                                      safeAdd(
                                        userExpensesInfo?.total,
                                        totalAnnualPayments,
                                        userPrimaryResidenceInfo?.[0]
                                          ?.Primary_Res_Monthly_Payments * 12
                                      )
                                    )
                                  : "$0"}
                              </span>
                            </HStack>

                            {/* <div className="right-content mobile-hidden">
                                            <FinancialOverviewGraph getAge={getAge} currentAge={userInfo?.DateOfBirth} retireAge={runAge} growthOverYearsSalary={userIncomeInfo?.Salary_Array} growthOverYearsExpenses={userExpensesInfo?.expenseArray} />
                                        </div> */}
                          </div>

                          <div className="grid-item grid-item-4">
                            <Text
                              color={"#00A2D4"}
                              textAlign={"left"}
                              fontFamily={"Nunito"}
                              fontSize={"14px"}
                              fontStyle={"normal"}
                              fontWeight={"800"}
                              lineHeight={"130%"}
                            >
                              Debt
                            </Text>
                            <Link href="/real-estate">
                              <HStack
                                justifyContent={"space-between"}
                                width={"100%"}
                              >
                                <Text
                                  color={"#00466D"}
                                  fontFamily={"Nunito"}
                                  fontStyle={"normal"}
                                  fontWeight={"600"}
                                  lineHeight={"130%"}
                                  className="strategy-plan-user-info-blue"
                                >
                                  Mortgage Balances:
                                </Text>
                                <span className="strategy-plan-user-info-blue">
                                  {totalMortgageBalance
                                    ? currencyFormat(totalMortgageBalance)
                                    : "$0"}
                                </span>
                              </HStack>
                            </Link>
                            <Link href="/expenses-debt">
                              <HStack
                                justifyContent={"space-between"}
                                width={"100%"}
                              >
                                <Text
                                  color={"#00466D"}
                                  fontFamily={"Nunito"}
                                  fontStyle={"normal"}
                                  fontWeight={"600"}
                                  lineHeight={"130%"}
                                  className="strategy-plan-user-info-blue"
                                >
                                  Outstanding Debt:
                                </Text>
                                <span className="strategy-plan-user-info-blue">
                                  {totalDebtValue
                                    ? currencyFormat(totalDebtValue)
                                    : "$0"}
                                </span>
                              </HStack>
                            </Link>
                            <HStack
                              justifyContent={"space-between"}
                              width={"100%"}
                            >
                              <Text
                                color={"#00466D"}
                                fontFamily={"Nunito"}
                                fontStyle={"normal"}
                                fontWeight={"900"}
                                lineHeight={"130%"}
                                className="strategy-plan-user-info-blue"
                              >
                                Total Debt:
                              </Text>
                              <span className="strategy-plan-user-info-blue strategy-plan-header-font">
                                {safeAdd(totalDebtValue, totalMortgageBalance)
                                  ? currencyFormat(
                                      safeAdd(
                                        totalDebtValue,
                                        totalMortgageBalance
                                      )
                                    )
                                  : "$0"}
                              </span>
                            </HStack>
                          </div>

                          <div className="grid-item grid-item-5">
                            <Text
                              color={"#00A2D4"}
                              textAlign={"left"}
                              fontFamily={"Nunito"}
                              fontSize={"14px"}
                              fontStyle={"normal"}
                              fontWeight={"800"}
                              lineHeight={"130%"}
                            >
                              Assets
                            </Text>
                            <Link href="/real-estate">
                              <HStack
                                justifyContent={"space-between"}
                                width={"100%"}
                              >
                                <Text
                                  color={"#00466D"}
                                  fontFamily={"Nunito"}
                                  fontStyle={"normal"}
                                  fontWeight={"600"}
                                  lineHeight={"130%"}
                                  className="strategy-plan-user-info-blue"
                                >
                                  Real Estate
                                </Text>
                                <span className="strategy-plan-user-info-blue">
                                  {safeAdd(
                                    totalIncomePropertyValue,
                                    totalCottageValue,
                                    totalLandValue,
                                    userPrimaryResidenceInfo?.[0]?.value
                                  )
                                    ? currencyFormat(
                                        safeAdd(
                                          totalIncomePropertyValue,
                                          totalCottageValue,
                                          totalLandValue,
                                          userPrimaryResidenceInfo?.[0]?.value
                                        )
                                      )
                                    : "$0"}
                                </span>
                              </HStack>
                            </Link>
                            <Link href="/life-insurance-form">
                              <HStack
                                justifyContent={"space-between"}
                                width={"100%"}
                              >
                                <Text
                                  color={"#00466D"}
                                  fontFamily={"Nunito"}
                                  fontStyle={"normal"}
                                  fontWeight={"600"}
                                  lineHeight={"130%"}
                                  className="strategy-plan-user-info-blue"
                                >
                                  Life Insurance:
                                </Text>
                                <span className="strategy-plan-user-info-blue">
                                  {totalLifeInsuranceValue
                                    ? currencyFormat(totalLifeInsuranceValue)
                                    : "$0"}
                                </span>
                              </HStack>
                            </Link>
                            <Link href="/personal-assets-form">
                              <HStack
                                justifyContent={"space-between"}
                                width={"100%"}
                              >
                                <Text
                                  color={"#00466D"}
                                  fontFamily={"Nunito"}
                                  fontStyle={"normal"}
                                  fontWeight={"600"}
                                  lineHeight={"130%"}
                                  className="strategy-plan-user-info-blue"
                                >
                                  Personal Assets:
                                </Text>
                                <span className="strategy-plan-user-info-blue">
                                  {totalOtherAssetValue
                                    ? currencyFormat(totalOtherAssetValue)
                                    : "$0"}
                                </span>
                              </HStack>
                            </Link>
                            <Link href="/business-page">
                              <HStack
                                justifyContent={"space-between"}
                                width={"100%"}
                              >
                                <Text
                                  color={"#00466D"}
                                  fontFamily={"Nunito"}
                                  fontStyle={"normal"}
                                  fontWeight={"600"}
                                  lineHeight={"130%"}
                                  className="strategy-plan-user-info-blue"
                                >
                                  Business:
                                </Text>
                                <span className="strategy-plan-user-info-blue">
                                  {totalBusinessValue
                                    ? currencyFormat(
                                        safeAdd(totalBusinessValue)
                                      )
                                    : "$0"}
                                </span>
                              </HStack>
                            </Link>
                            <Link href="/business-page">
                              <HStack
                                justifyContent={"space-between"}
                                width={"100%"}
                              >
                                <Text
                                  color={"#00466D"}
                                  fontFamily={"Nunito"}
                                  fontStyle={"normal"}
                                  fontWeight={"600"}
                                  lineHeight={"130%"}
                                  className="strategy-plan-user-info-blue"
                                >
                                  CCPC Investments:
                                </Text>
                                <span className="strategy-plan-user-info-blue">
                                  {totalBusinessInvestments
                                    ? currencyFormat(
                                        safeAdd(totalBusinessInvestments)
                                      )
                                    : "$0"}
                                </span>
                              </HStack>
                            </Link>
                            <Link href="/other-assets-page">
                              <HStack
                                justifyContent={"space-between"}
                                width={"100%"}
                              >
                                <Text
                                  color={"#00466D"}
                                  fontFamily={"Nunito"}
                                  fontStyle={"normal"}
                                  fontWeight={"900"}
                                  lineHeight={"130%"}
                                  className="strategy-plan-user-info-blue"
                                >
                                  Total Assets:
                                </Text>
                                <span className="strategy-plan-user-info-blue strategy-plan-header-font">
                                  {safeAdd(
                                    totalAssetValue,
                                    userPrimaryResidenceInfo?.[0]?.value
                                  )
                                    ? currencyFormat(
                                        safeAdd(
                                          totalAssetValue,
                                          userPrimaryResidenceInfo?.[0]?.value
                                        )
                                      )
                                    : "$0"}
                                </span>
                              </HStack>
                            </Link>

                            {/* <div className="right-content">
                                  <div className="activity-summary-graph-container mobile-hidden">
                                    <BarGraph
                                      combinedAssets={safeAdd(
                                        totalIncomePropertyValue,
                                        totalCottageValue,
                                        userPrimaryResidenceInfo?.[0]?.value
                                      )}
                                      combinedLifeInsuranceBalances={totalLifeInsuranceValue}
                                      combinedPersonAssetsBalance={totalOtherAssetValue}
                                      combinedTotal={safeAdd(
                                        totalAssetValue,
                                        userPrimaryResidenceInfo?.[0]?.value
                                      )}
                                    />
                                  </div>
                                </div> */}
                          </div>
                          <div className="grid-item grid-item-12">
                            <Text
                              color={"#00A2D4"}
                              fontFamily={"Nunito"}
                              fontSize={"14px"}
                              fontStyle={"normal"}
                              fontWeight={"800"}
                              lineHeight={"130%"}
                            >
                              Current Net Worth
                            </Text>
                            <Text
                              width={"251px"}
                              textAlign={"left"}
                              color={"#00466D"}
                              fontFamily={"Nunito"}
                              fontSize={"32px"}
                              fontStyle={"normal"}
                              fontWeight={"900"}
                              lineHeight={"130%"}
                            >
                              {currencyFormat(
                                safeAdd(
                                  userTFSAInfo?.totalValue || 0,
                                  userFHSAInfo?.fhsaValue || 0,
                                  userNonRegInvestmentsInfo?.totalValue || 0,
                                  userCASHInfo?.value || 0,
                                  totalFixedIncome || 0,
                                  userRRSPInfo?.totalValue || 0,
                                  userPrimaryResidenceInfo[0]?.value || 0,
                                  userLiraInfo?.SPS1_LIRA_VALUE || 0,
                                  userLifeInsuranceInfo?.reduce(
                                    (acc, curr) => acc + (curr?.value || 0),
                                    0
                                  ),
                                  userOtherAssetsInfo?.reduce(
                                    (acc, curr) =>
                                      acc + (curr?.Other_Asset_Value || 0),
                                    0
                                  ),
                                  userCottageInfo?.reduce(
                                    (acc, curr) => acc + (curr?.value || 0),
                                    0
                                  ),
                                  userPropertiesInfo?.reduce(
                                    (acc, curr) => acc + (curr?.value || 0),
                                    0
                                  ),
                                  totalLandValue,
                                  totalBusinessValue || 0,
                                  totalBusinessInvestments || 0,
                                  userPensionInfo?.reduce((acc, curr) => {
                                    return curr?.Pension_Type !==
                                      "Defined Benefit Plan" &&
                                      curr?.spouseCheck === 0
                                      ? acc + (curr?.Pension || 0)
                                      : acc;
                                  }, 0)
                                ) -
                                  safeAdd(
                                    userDebtInfo?.reduce(
                                      (acc, curr) =>
                                        acc + (curr?.Amount_Borrowed || 0),
                                      0
                                    ),
                                    userPrimaryResidenceInfo?.reduce(
                                      (acc, curr) =>
                                        acc +
                                        (curr?.Primary_Res_Mortgage_Balance ||
                                          0),
                                      0
                                    ),
                                    userCottageInfo?.reduce(
                                      (acc, curr) =>
                                        acc +
                                        (curr?.Cottage_Mortgage_Balance || 0),
                                      0
                                    ),
                                    userPropertiesInfo?.reduce(
                                      (acc, curr) =>
                                        acc +
                                        (curr?.Income_Property_Mortgage_Balance ||
                                          0),
                                      0
                                    ),
                                    totalLandDebtBalance
                                  )
                              )}
                            </Text>
                          </div>
                          {/* <div
                            className="grid-item grid-item-13"
                            onClick={onSubmit}
                            hover
                            style={{ cursor: "pointer" }}
                          >
                            <Icon
                              as={OptimizeRocketIcon}
                              svgheight={"32px"}
                              svgwidth={"31px"}
                            />
                            <Text
                              color={"#FFF"}
                              textAlign={"center"}
                              fontFamily={"Nunito"}
                              fontSize={"20px"}
                              fontStyle={"normal"}
                              fontWeight={"600"}
                              lineHeight={"normal"}
                            >
                              Optimize
                            </Text>
                          </div> */}
                          <PopupComponent onSubmit={onSubmit} />
                        </div>
                      </div>
                    )}
                    {/* SPOUSE VERSION */}
                    {spouseStatus && (
                      <div className="box-container-lifestategy">
                        <div className="grid-header">
                          <Box
                            display={"flex"}
                            flexDirection={"column"}
                            alignItems={"flex-start"}
                            gap={"10px"}
                          >
                            <p className="strategy-plan-user-info">
                              Strategy:
                              <span
                                className="strategy-plan-user-info-blue"
                                style={{ marginLeft: "5px" }}
                              >
                                {strategy}
                              </span>
                            </p>
                            {custom && (
                              <Link href="/lifestrategy">
                                <p className="strategy-plan-user-info ">
                                  Set Value Amount:
                                  <span
                                    className="strategy-plan-user-info-blue"
                                    style={{ marginLeft: "5px" }}
                                  >
                                    {currencyFormat(customValue)}
                                  </span>
                                </p>
                              </Link>
                            )}
                            <Link href="/expenses-household">
                              <p className="strategy-plan-user-info ">
                                Analysis Duration:
                                <span
                                  className="strategy-plan-user-info-blue"
                                  style={{ marginLeft: "5px" }}
                                >
                                  {runAge - getAge(userInfo?.DateOfBirth)} years
                                </span>
                              </p>
                            </Link>
                            <Link href="/expenses-household">
                              <p className="strategy-plan-user-info ">
                                Consumer Price Index:
                                <span
                                  className="strategy-plan-user-info-blue"
                                  style={{ marginLeft: "5px" }}
                                >
                                  {/* {userExpensesInfo?.expenseCPI}% */}
                                  2%
                                </span>
                              </p>
                            </Link>

                            <Link href="/expenses-household">
                              <p className="strategy-plan-user-info ">
                                Running to year:
                                <span
                                  className="strategy-plan-user-info-blue"
                                  style={{ marginLeft: "5px" }}
                                >
                                  {getRunAgeYear()}
                                </span>
                              </p>
                            </Link>
                          </Box>
                          <Icon
                            as={OptimlWhiteBGIcon}
                            svgheight={isMobile ? "70px" : "94px"}
                            svgwidth={isMobile ? "70px" : "94px"}
                          />
                        </div>

                        <div className="grid-container">
                          <div className="grid-item grid-item-2">
                            <p className="strategy-plan-user-info strategy-plan-header-font">
                              {firstName} {lastName}
                            </p>

                            <Text
                              color={"#007EB5"}
                              textAlign={"left"}
                              fontFamily={"Nunito"}
                              fontSize={"12px"}
                              fontStyle={"normal"}
                              fontWeight={"800"}
                              lineHeight={"130%"}
                            >
                              Investments balances
                            </Text>
                            <Link href="/investment-tfsa-page">
                              {investmentData({
                                Heading: "TFSA",
                                value: userTFSAInfo?.totalValue
                                  ? currencyFormat(userTFSAInfo?.totalValue)
                                  : "$0",
                                max: userTFSAInfo?.TFSA_ROR ?? 6,
                              })}
                            </Link>
                            <Link href="/investment-fhsa-page">
                              {investmentData({
                                Heading: "FHSA",
                                value: userFHSAInfo?.fhsaValue
                                  ? currencyFormat(userFHSAInfo?.fhsaValue)
                                  : "$0",
                                max: userFHSAInfo?.fhsaGrowth ?? 6,
                              })}
                            </Link>
                            <Link href="/rrsp-page">
                              {investmentData({
                                Heading: "RRSP",
                                value: userRRSPInfo?.totalValue
                                  ? currencyFormat(userRRSPInfo?.totalValue)
                                  : "$0",
                                max: userRRSPInfo?.RRSP_ROR ?? 6,
                              })}
                              {/* <Box
                                display={"flex"}
                                flexDirection={"row"}
                                justifyContent={"space-between"}
                                alignItems={"flex-start"}
                                alignSelf={"stretch"}
                              >
                                <Box
                                  display={"flex"}
                                  justifyContent={"space-between"}
                                  width={"185px"}
                                  alignItems={"flex-start"}
                                >
                                  <Text
                                    color={"#00466D"}
                                    fontFamily={"Nunito"}
                                    fontStyle={"normal"}
                                    fontWeight={"600"}
                                    lineHeight={"130%"}
                                    className="strategy-plan-user-info-blue"
                                  >
                                    RRSP:
                                  </Text>
                                  <Text
                                    flexShrink={0}
                                    color={"#00A2D4"}
                                    textAlign={"right"}
                                    fontFamily={"Nunito"}
                                    fontStyle={"normal"}
                                    fontWeight={"800"}
                                    lineHeight={"130%"}
                                    className="strategy-plan-user-info-blue"
                                  >
                                   
                                  </Text>
                                </Box>
                                <Box
                                  display={"flex"}
                                  justifyContent={"flex-end"}
                                  alignItems={"flex-start"}
                                  gap={"5px"}
                                  w={"125px"}
                                >
                                  <Text
                                    color={"#00466D"}
                                    fontFamily={"Nunito"}
                                    fontStyle={"normal"}
                                    fontWeight={"600"}
                                    lineHeight={"130%"}
                                    className="strategy-plan-user-info-blue"
                                  >
                                    Growth:
                                  </Text>
                                  <span className="strategy-plan-user-info-blue">
                                    {Math.max(
                                      Number(userRRSPInfo?.RRSP_ROR ?? 6),
                                      0
                                    )}
                                    %
                                  </span>
                                </Box>
                              </Box> */}
                            </Link>
                            <Link href="/non-reg-page">
                              {investmentData({
                                Heading: "Non-Reg",
                                value: userNonRegInvestmentsInfo?.totalValue
                                  ? currencyFormat(
                                      userNonRegInvestmentsInfo?.totalValue
                                    )
                                  : "$0",
                                max:
                                  userNonRegInvestmentsInfo?.Non_Reg_ROR ?? 6,
                              })}
                            </Link>
                            {totalFixedIncome > 0 && (
                              <Link href="/fixed-income-page">
                                {investmentData({
                                  Heading: "Fixed Income",
                                  value: totalFixedIncome
                                    ? currencyFormat(totalFixedIncome)
                                    : "$0",
                                  avg: averageInterest,
                                })}
                                {/* <Box
                                  display={"flex"}
                                  flexDirection={"row"}
                                  justifyContent={"space-between"}
                                  alignItems={"flex-start"}
                                  alignSelf={"stretch"}
                                >
                                  <Box
                                    display={"flex"}
                                    justifyContent={"space-between"}
                                    width={"185px"}
                                    alignItems={"flex-start"}
                                  >
                                    <Text
                                      color={"#00466D"}
                                      fontFamily={"Nunito"}
                                      fontStyle={"normal"}
                                      fontWeight={"600"}
                                      lineHeight={"130%"}
                                      className="strategy-plan-user-info-blue"
                                    >
                                      {" "}
                                      Fixed Income:{" "}
                                    </Text>

                                    <span className="strategy-plan-user-info-blue">
                                      
                                      
                                    </span>
                                  </Box>
                                  <Box
                                    display={"flex"}
                                    justifyContent={"flex-end"}
                                    alignItems={"flex-start"}
                                    gap={"5px"}
                                    w={"135px"}
                                  >
                                    <Text
                                      color={"#00466D"}
                                      fontFamily={"Nunito"}
                                      fontStyle={"normal"}
                                      fontWeight={"600"}
                                      lineHeight={"130%"}
                                      className="strategy-plan-user-info-blue"
                                    >
                                      Growth:
                                    </Text>
                                    <span className="strategy-plan-user-info-blue">
                                      {Math.floor()}%
                                      <Text
                                        color={"#00A2D4"}
                                        fontFamily={"Nunito"}
                                        fontStyle={"normal"}
                                        fontWeight={"500"}
                                        lineHeight={"130%"}
                                        as={"span"}
                                      >
                                        {" "}
                                        avg
                                      </Text>
                                    </span>
                                  </Box>
                                </Box> */}
                              </Link>
                            )}
                            <Link href="/cash-savings">
                              {investmentData({
                                Heading: "Cash",
                                value: userCASHInfo?.value
                                  ? currencyFormat(userCASHInfo?.value)
                                  : "$0",
                              })}

                              {/* <Box
                                  display={"flex"}
                                  justifyContent={"space-between"}
                                  width={"185px"}
                                  alignItems={"flex-start"}
                                >
                                  <Text
                                    color={"#00466D"}
                                    fontFamily={"Nunito"}
                                    fontStyle={"normal"}
                                    fontWeight={"600"}
                                    lineHeight={"130%"}
                                    className="strategy-plan-user-info-blue"
                                  >
                                    Cash
                                  </Text>

                                  <span className="strategy-plan-user-info-blue">
                                    {userCASHInfo?.value
                                      ? currencyFormat(userCASHInfo?.value)
                                      : "$0"}
                                  </span>
                                </Box> */}
                              {/* <Box
                                  display={"flex"}
                                  justifyContent={"flex-end"}
                                  alignItems={"flex-start"}
                                  gap={"5px"}
                                  w={"125px"}
                                >
                                  <Text
                                    color={"#00466D"}
                                    fontFamily={"Nunito"}
                                    fontSize={"14px"}
                                    fontStyle={"normal"}
                                    fontWeight={"600"}
                                    lineHeight={"130%"}
                                  >
                                    Growth:
                                  </Text>
                                  <Text
                                    color={"#00A2D4"}
                                    fontFamily={"Nunito"}
                                    fontSize={"14px"}
                                    fontStyle={"normal"}
                                    fontWeight={"800"}
                                    lineHeight={"130%"}
                                  >
                                    0%
                                  </Text>
                                  <Text
                                    color={"#00A2D4"}
                                    fontFamily={"Nunito"}
                                    fontSize={"14px"}
                                    fontStyle={"normal"}
                                    fontWeight={"500"}
                                    lineHeight={"130%"}
                                  >
                                    avg
                                  </Text>
                                </Box> */}
                            </Link>

                            {totalPension > 0 && (
                              <Link href="/income-employer-pension">
                                <HStack
                                  justifyContent={"space-between"}
                                  width={"100%"}
                                >
                                  <Box
                                    display={"flex"}
                                    justifyContent={"space-between"}
                                    width={"185px"}
                                    alignItems={"flex-start"}
                                  >
                                    <Text
                                      color={"#00466D"}
                                      fontFamily={"Nunito"}
                                      fontSize={"14px"}
                                      fontStyle={"normal"}
                                      fontWeight={"600"}
                                      lineHeight={"130%"}
                                    >
                                      Pension:
                                    </Text>

                                    <span className="strategy-plan-user-info-blue">
                                      {totalPension
                                        ? currencyFormat(totalPension)
                                        : "$0"}
                                    </span>
                                  </Box>
                                  <Box
                                    display={"flex"}
                                    justifyContent={"flex-end"}
                                    alignItems={"flex-start"}
                                    gap={"5px"}
                                    w={"125px"}
                                  >
                                    <Text
                                      color={"#00466D"}
                                      fontFamily={"Nunito"}
                                      fontStyle={"normal"}
                                      fontWeight={"600"}
                                      lineHeight={"130%"}
                                      className="strategy-plan-user-info-blue"
                                    >
                                      Growth:
                                    </Text>
                                    <span className="strategy-plan-user-info-blue">
                                      {Math.max(Number(PensionGrowth ?? 6), 0)}%
                                    </span>
                                  </Box>
                                </HStack>
                              </Link>
                            )}
                            {userLiraInfo?.SPS1_LIRA_VALUE > 0 && (
                              <Link href="/other-reg-page">
                                {investmentData({
                                  Heading: "LIRA",
                                  value: userLiraInfo?.SPS1_LIRA_VALUE
                                    ? currencyFormat(
                                        userLiraInfo?.SPS1_LIRA_VALUE
                                      )
                                    : "$0",
                                  max: userLiraInfo?.LIRA_ROR ?? 6,
                                })}
                              </Link>
                            )}

                            <HStack
                              justifyContent={"space-between"}
                              width={"100%"}
                            >
                              <Text
                                color={"#00466D"}
                                fontFamily={"Nunito"}
                                fontSize={"14px"}
                                fontStyle={"normal"}
                                fontWeight={"900"}
                                lineHeight={"130%"}
                              >
                                Total:
                              </Text>
                              <span className="strategy-plan-user-info-blue strategy-plan-header-font">
                                {safeAdd(
                                  userTFSAInfo?.totalValue,
                                  userFHSAInfo?.fhsaValue,
                                  userLiraInfo?.SPS1_LIRA_VALUE,
                                  totalPension,
                                  userRRSPInfo?.totalValue,
                                  userNonRegInvestmentsInfo?.totalValue,
                                  userCASHInfo?.value,
                                  totalFixedIncome
                                )
                                  ? currencyFormat(
                                      safeAdd(
                                        userTFSAInfo?.totalValue,
                                        userFHSAInfo?.fhsaValue,
                                        userLiraInfo?.SPS1_LIRA_VALUE,
                                        totalPension,
                                        userRRSPInfo?.totalValue,
                                        userNonRegInvestmentsInfo?.totalValue,
                                        userCASHInfo?.value,
                                        totalFixedIncome
                                      )
                                    )
                                  : "$0"}
                              </span>
                            </HStack>
                            {/* <div className="right-content">
                              <div className="activity-summary-graph-container">
                                <BarGraph
                                  combinedTFSA={userTFSAInfo?.totalValue}
                                  combinedRRSP={userRRSPInfo?.totalValue}
                                  combinedNonReg={
                                    userNonRegInvestmentsInfo?.totalValue
                                  }
                                  combinedCash={userCASHInfo?.value}
                                  combinedFixedIncome={totalFixedIncome}
                                  combinedLIRA={userLiraInfo?.SPS1_LIRA_VALUE}
                                  combinedPensionValue={totalPension}
                                  combinedTotal={
                                    userTFSAInfo?.totalValue +
                                    totalPension +
                                    userLiraInfo?.SPS1_LIRA_VALUE +
                                    userRRSPInfo?.totalValue +
                                    userNonRegInvestmentsInfo?.totalValue +
                                    userCASHInfo?.value +
                                    totalFixedIncome
                                  }
                                />
                              </div>
                            </div> */}
                          </div>
                          <div className="grid-item grid-item-7">
                            <p className="strategy-plan-user-info strategy-plan-header-font">
                              {spouseFirstName} {spouseLastName}
                            </p>
                            <Text
                              color={"#007EB5"}
                              textAlign={"left"}
                              fontFamily={"Nunito"}
                              fontSize={"12px"}
                              fontStyle={"normal"}
                              fontWeight={"800"}
                              lineHeight={"130%"}
                            >
                              Investments balances
                            </Text>
                            <Link href="/investment-tfsa-page">
                              {investmentData({
                                Heading: "TFSA",
                                value: userTFSAInfo?.spouseTotalValue
                                  ? currencyFormat(
                                      userTFSAInfo?.spouseTotalValue
                                    )
                                  : "$0",
                                max: userTFSAInfo?.spouseTFSA_ROR ?? 6,
                              })}
                            </Link>
                            <Link href="/investment-fhsa-page">
                              {investmentData({
                                Heading: "FHSA",
                                value: userFHSAInfo?.spouseFhsaValue
                                  ? currencyFormat(
                                      userFHSAInfo?.spouseFhsaValue
                                    )
                                  : "$0",
                                max: userFHSAInfo?.spouseFhsaGrowth ?? 6,
                              })}
                            </Link>
                            <Link href="/rrsp-page">
                              {investmentData({
                                Heading: "RRSP",
                                value: userRRSPInfo?.spouseTotalValue
                                  ? currencyFormat(
                                      userRRSPInfo?.spouseTotalValue
                                    )
                                  : "$0",
                                max: userRRSPInfo?.spouseRRSP_ROR ?? 6,
                              })}
                            </Link>
                            <Link href="/non-reg-page">
                              {investmentData({
                                Heading: "Non-Reg",
                                value:
                                  userNonRegInvestmentsInfo?.spouseTotalValue
                                    ? currencyFormat(
                                        userNonRegInvestmentsInfo?.spouseTotalValue
                                      )
                                    : "$0",
                                max:
                                  userNonRegInvestmentsInfo?.spouseNon_Reg_ROR ??
                                  6,
                              })}
                            </Link>
                            {totalSpouseFixedIncome > 0 && (
                              <Link href="/fixed-income-page">
                                {investmentData({
                                  Heading: "Fixed Income",
                                  value: totalSpouseFixedIncome
                                    ? currencyFormat(totalSpouseFixedIncome)
                                    : "$0",
                                  avg: averageInterestSpouse,
                                })}
                              </Link>
                            )}
                            <Link href="/cash-savings">
                              {investmentData({
                                Heading: "Cash",
                                value: userCASHInfo?.spouseValue
                                  ? currencyFormat(userCASHInfo?.spouseValue)
                                  : "$0",
                              })}
                            </Link>

                            {spouseTotalPension > 0 && (
                              <Link href="/income-employer-pension">
                                {investmentData({
                                  Heading: "Pension",
                                  value: spouseTotalPension
                                    ? currencyFormat(spouseTotalPension)
                                    : "$0",
                                  max: spousePensionGrowth ?? 6,
                                })}
                              </Link>
                            )}
                            {userLiraInfo?.SPS2_LIRA_VALUE > 0 && (
                              <Link href="/other-reg-page">
                                {investmentData({
                                  Heading: "LIRA",
                                  value: userLiraInfo?.SPS2_LIRA_VALUE
                                    ? currencyFormat(
                                        userLiraInfo?.SPS2_LIRA_VALUE
                                      )
                                    : "$0",
                                  max: userLiraInfo?.spouseLIRA_ROR ?? 6,
                                })}
                              </Link>
                            )}
                            <HStack
                              justifyContent={"space-between"}
                              width={"100%"}
                            >
                              <Text
                                color={"#00466D"}
                                fontFamily={"Nunito"}
                                fontSize={"14px"}
                                fontStyle={"normal"}
                                fontWeight={"900"}
                                lineHeight={"130%"}
                              >
                                Total:
                              </Text>
                              <span className="strategy-plan-user-info-blue strategy-plan-header-font">
                                {safeAdd(
                                  userTFSAInfo?.spouseTotalValue,
                                  userFHSAInfo?.spouseFhsaValue,
                                  userLiraInfo?.SPS2_LIRA_VALUE,
                                  spouseTotalPension,
                                  userRRSPInfo?.spouseTotalValue,
                                  userNonRegInvestmentsInfo?.spouseTotalValue,
                                  userCASHInfo?.spouseValue,
                                  totalSpouseFixedIncome
                                )
                                  ? currencyFormat(
                                      safeAdd(
                                        userTFSAInfo?.spouseTotalValue,
                                        userFHSAInfo?.spouseFhsaValue,
                                        userLiraInfo?.SPS2_LIRA_VALUE,
                                        spouseTotalPension,
                                        userRRSPInfo?.spouseTotalValue,
                                        userNonRegInvestmentsInfo?.spouseTotalValue,
                                        userCASHInfo?.spouseValue,
                                        totalSpouseFixedIncome
                                      )
                                    )
                                  : "$0"}
                              </span>
                            </HStack>

                            {/* <div className="right-content">
                              <div className="activity-summary-graph-container">
                                <BarGraph
                                  combinedTFSA={userTFSAInfo?.spouseTotalValue}
                                  combinedRRSP={userRRSPInfo?.spouseTotalValue}
                                  combinedNonReg={
                                    userNonRegInvestmentsInfo?.spouseTotalValue
                                  }
                                  combinedCash={userCASHInfo?.spouseValue}
                                  combinedFixedIncome={totalSpouseFixedIncome}
                                  combinedLIRA={userLiraInfo?.SPS2_LIRA_VALUE}
                                  combinedPensionValue={spouseTotalPension}
                                  combinedTotal={
                                    userTFSAInfo?.spouseTotalValue +
                                    spouseTotalPension +
                                    userLiraInfo?.SPS2_LIRA_VALUE +
                                    userRRSPInfo?.spouseTotalValue +
                                    userNonRegInvestmentsInfo?.spouseTotalValue +
                                    userCASHInfo?.spouseValue +
                                    totalSpouseFixedIncome
                                  }
                                />
                              </div>
                            </div> */}
                          </div>

                          <div className="grid-item grid-item-8">
                            <Text
                              color={"#00A2D4"}
                              textAlign={"left"}
                              fontFamily={"Nunito"}
                              fontSize={"14px"}
                              fontStyle={"normal"}
                              fontWeight={"800"}
                              lineHeight={"130%"}
                            >
                              Financial Overview
                            </Text>
                            <Link href="/revenue">
                              <HStack
                                justifyContent={"space-between"}
                                width={"100%"}
                              >
                                <Text
                                  color={"#00466D"}
                                  fontFamily={"Nunito"}
                                  fontStyle={"normal"}
                                  fontWeight={"600"}
                                  lineHeight={"130%"}
                                  className="strategy-plan-user-info-blue"
                                >
                                  Combined Income:
                                </Text>
                                <Text
                                  width={"90px"}
                                  color={"#00A2D4"}
                                  textAlign={"right"}
                                  fontFamily={"Nunito"}
                                  fontStyle={"normal"}
                                  fontWeight={"800"}
                                  lineHeight={"130%"}
                                  className="strategy-plan-user-info-blue"
                                >
                                  {safeAdd(
                                    userIncomeInfo?.Salary,
                                    totalPensionIncome,
                                    userIncomeInfo?.spouseIncome,
                                    spouseTotalPensionIncome,
                                    spouseTotalOtherIncome,
                                    totalIncomePropertyIncome,
                                    totalOtherIncome,
                                    totalCPPIncome,
                                    spouseTotalCPPIncome,
                                    totalOASIncome,
                                    spouseTotalOASIncome
                                  )
                                    ? currencyFormat(
                                        safeAdd(
                                          userIncomeInfo?.Salary,
                                          totalPensionIncome,
                                          userIncomeInfo?.spouseIncome,
                                          spouseTotalPensionIncome,
                                          spouseTotalOtherIncome,
                                          totalIncomePropertyIncome,
                                          totalOtherIncome,
                                          totalCPPIncome,
                                          spouseTotalCPPIncome,
                                          totalOASIncome,
                                          spouseTotalOASIncome
                                        )
                                      )
                                    : "$0"}
                                </Text>
                              </HStack>
                            </Link>
                            <Link href="/expenses-household">
                              <HStack
                                justifyContent={"space-between"}
                                width={"100%"}
                              >
                                <Text
                                  color={"#00466D"}
                                  fontFamily={"Nunito"}
                                  fontStyle={"normal"}
                                  fontWeight={"600"}
                                  lineHeight={"130%"}
                                  className="strategy-plan-user-info-blue"
                                >
                                  Living Expenses:
                                </Text>
                                <Text
                                  width={"90px"}
                                  color={"#00A2D4"}
                                  textAlign={"right"}
                                  fontFamily={"Nunito"}
                                  fontStyle={"normal"}
                                  fontWeight={"800"}
                                  lineHeight={"130%"}
                                  className="strategy-plan-user-info-blue"
                                >
                                  {userExpensesInfo?.total
                                    ? currencyFormat(userExpensesInfo?.total)
                                    : "$0"}
                                </Text>
                              </HStack>
                            </Link>
                            <Link href="/expenses-household">
                              <HStack
                                justifyContent={"space-between"}
                                width={"100%"}
                              >
                                <Text
                                  color={"#00466D"}
                                  fontFamily={"Nunito"}
                                  fontStyle={"normal"}
                                  fontWeight={"600"}
                                  lineHeight={"130%"}
                                  className="strategy-plan-user-info-blue"
                                >
                                  Fixed Expenses:
                                </Text>
                                <Text
                                  width={"90px"}
                                  color={"#00A2D4"}
                                  textAlign={"right"}
                                  fontFamily={"Nunito"}
                                  fontStyle={"normal"}
                                  fontWeight={"800"}
                                  lineHeight={"130%"}
                                  className="strategy-plan-user-info-blue"
                                >
                                  {safeAdd(
                                    totalAnnualPayments,
                                    userPrimaryResidenceInfo?.[0]
                                      ?.Primary_Res_Monthly_Payments * 12
                                  )
                                    ? currencyFormat(
                                        safeAdd(
                                          totalAnnualPayments,
                                          userPrimaryResidenceInfo?.[0]
                                            ?.Primary_Res_Monthly_Payments * 12
                                        )
                                      )
                                    : "$0"}
                                </Text>
                              </HStack>
                            </Link>
                            <HStack
                              justifyContent={"space-between"}
                              width={"100%"}
                            >
                              <Text
                                color={"#00466D"}
                                fontFamily={"Nunito"}
                                fontStyle={"normal"}
                                fontWeight={"900"}
                                lineHeight={"130%"}
                                className="strategy-plan-user-info-blue"
                              >
                                Total Expenses:
                              </Text>
                              <span className="strategy-plan-user-info-blue strategy-plan-header-font">
                                {safeAdd(
                                  userExpensesInfo?.total,
                                  totalAnnualPayments,
                                  userPrimaryResidenceInfo?.[0]
                                    ?.Primary_Res_Monthly_Payments * 12
                                )
                                  ? currencyFormat(
                                      safeAdd(
                                        userExpensesInfo?.total,
                                        totalAnnualPayments,
                                        userPrimaryResidenceInfo?.[0]
                                          ?.Primary_Res_Monthly_Payments * 12
                                      )
                                    )
                                  : "$0"}
                              </span>
                            </HStack>

                            {/* <div className="right-content">
                                            <FinancialOverviewGraph getAge={getAge} currentAge={userInfo?.DateOfBirth} retireAge={runAge} growthOverYearsSalary={combinedArray} growthOverYearsExpenses={userExpensesInfo?.expenseArray} />
                                        </div> */}
                          </div>

                          <div className="grid-item grid-item-9">
                            <Text
                              color={"#00A2D4"}
                              textAlign={"left"}
                              fontFamily={"Nunito"}
                              fontSize={"14px"}
                              fontStyle={"normal"}
                              fontWeight={"800"}
                              lineHeight={"130%"}
                            >
                              Debt
                            </Text>
                            <Link href="/real-estate">
                              <HStack
                                justifyContent={"space-between"}
                                width={"100%"}
                              >
                                <Text
                                  color={"#00466D"}
                                  fontFamily={"Nunito"}
                                  fontStyle={"normal"}
                                  fontWeight={"600"}
                                  lineHeight={"130%"}
                                  className="strategy-plan-user-info-blue"
                                >
                                  Mortgage Balances:
                                </Text>
                                <span className="strategy-plan-user-info-blue">
                                  {totalMortgageBalance
                                    ? currencyFormat(totalMortgageBalance)
                                    : "$0"}
                                </span>
                              </HStack>
                            </Link>
                            <Link href="/expenses-debt">
                              <HStack
                                justifyContent={"space-between"}
                                width={"100%"}
                              >
                                <Text
                                  color={"#00466D"}
                                  fontFamily={"Nunito"}
                                  fontStyle={"normal"}
                                  fontWeight={"600"}
                                  lineHeight={"130%"}
                                  className="strategy-plan-user-info-blue"
                                >
                                  Outstanding Debt:
                                </Text>
                                <span className="strategy-plan-user-info-blue">
                                  {totalDebtValue
                                    ? currencyFormat(totalDebtValue)
                                    : "$0"}
                                </span>
                              </HStack>
                            </Link>
                            <HStack
                              justifyContent={"space-between"}
                              width={"100%"}
                            >
                              <Text
                                color={"#00466D"}
                                fontFamily={"Nunito"}
                                fontStyle={"normal"}
                                fontWeight={"900"}
                                lineHeight={"130%"}
                                className="strategy-plan-user-info-blue"
                              >
                                Total Debt:
                              </Text>

                              <span className="strategy-plan-user-info-blue strategy-plan-header-font">
                                {safeAdd(totalDebtValue, totalMortgageBalance)
                                  ? currencyFormat(
                                      safeAdd(
                                        totalDebtValue,
                                        totalMortgageBalance
                                      )
                                    )
                                  : "$0"}
                              </span>
                            </HStack>
                          </div>

                          <div className="grid-item grid-item-10">
                            <Text
                              color={"#00A2D4"}
                              textAlign={"left"}
                              fontFamily={"Nunito"}
                              fontSize={"14px"}
                              fontStyle={"normal"}
                              fontWeight={"800"}
                              lineHeight={"130%"}
                            >
                              Assets
                            </Text>
                            <Link href="/real-estate">
                              <HStack
                                justifyContent={"space-between"}
                                width={"100%"}
                              >
                                <Text
                                  color={"#00466D"}
                                  fontFamily={"Nunito"}
                                  fontStyle={"normal"}
                                  fontWeight={"600"}
                                  lineHeight={"130%"}
                                  className="strategy-plan-user-info-blue"
                                >
                                  Real Estate
                                </Text>
                                <span className="strategy-plan-user-info-blue">
                                  {safeAdd(
                                    totalIncomePropertyValue,
                                    totalCottageValue,
                                    totalLandValue,
                                    userPrimaryResidenceInfo?.[0]?.value
                                  )
                                    ? currencyFormat(
                                        safeAdd(
                                          totalIncomePropertyValue,
                                          totalCottageValue,
                                          totalLandValue,
                                          userPrimaryResidenceInfo?.[0]?.value
                                        )
                                      )
                                    : "$0"}
                                </span>
                              </HStack>
                            </Link>
                            <Link href="/life-insurance-form">
                              <HStack
                                justifyContent={"space-between"}
                                width={"100%"}
                              >
                                <Text
                                  color={"#00466D"}
                                  fontFamily={"Nunito"}
                                  fontStyle={"normal"}
                                  fontWeight={"600"}
                                  lineHeight={"130%"}
                                  className="strategy-plan-user-info-blue"
                                >
                                  Life Insurance:
                                </Text>
                                <span className="strategy-plan-user-info-blue">
                                  {totalLifeInsuranceValue
                                    ? currencyFormat(totalLifeInsuranceValue)
                                    : "$0"}
                                </span>
                              </HStack>
                            </Link>
                            <Link href="/personal-assets-form">
                              <HStack
                                justifyContent={"space-between"}
                                width={"100%"}
                              >
                                <Text
                                  color={"#00466D"}
                                  fontFamily={"Nunito"}
                                  fontStyle={"normal"}
                                  fontWeight={"600"}
                                  lineHeight={"130%"}
                                  className="strategy-plan-user-info-blue"
                                >
                                  Personal Assets:
                                </Text>
                                <span className="strategy-plan-user-info-blue">
                                  {totalOtherAssetValue
                                    ? currencyFormat(totalOtherAssetValue)
                                    : "$0"}
                                </span>
                              </HStack>
                            </Link>
                            <Link href="/business-page">
                              <HStack
                                justifyContent={"space-between"}
                                width={"100%"}
                              >
                                <Text
                                  color={"#00466D"}
                                  fontFamily={"Nunito"}
                                  fontStyle={"normal"}
                                  fontWeight={"600"}
                                  lineHeight={"130%"}
                                  className="strategy-plan-user-info-blue"
                                >
                                  Business:
                                </Text>
                                <span className="strategy-plan-user-info-blue">
                                  {totalBusinessValue
                                    ? currencyFormat(
                                        safeAdd(totalBusinessValue)
                                      )
                                    : "$0"}
                                </span>
                              </HStack>
                            </Link>
                            <Link href="/business-page">
                              <HStack
                                justifyContent={"space-between"}
                                width={"100%"}
                              >
                                <Text
                                  color={"#00466D"}
                                  fontFamily={"Nunito"}
                                  fontStyle={"normal"}
                                  fontWeight={"600"}
                                  lineHeight={"130%"}
                                  className="strategy-plan-user-info-blue"
                                >
                                  CCPC Investments:
                                </Text>
                                <span className="strategy-plan-user-info-blue">
                                  {totalBusinessInvestments
                                    ? currencyFormat(
                                        safeAdd(totalBusinessInvestments)
                                      )
                                    : "$0"}
                                </span>
                              </HStack>
                            </Link>
                            <Link href="/other-assets-page">
                              <HStack
                                justifyContent={"space-between"}
                                width={"100%"}
                              >
                                <Text
                                  color={"#00466D"}
                                  fontFamily={"Nunito"}
                                  fontStyle={"normal"}
                                  fontWeight={"900"}
                                  lineHeight={"130%"}
                                  className="strategy-plan-user-info-blue"
                                >
                                  Total Assets:
                                </Text>
                                <span className="strategy-plan-user-info-blue strategy-plan-header-font">
                                  {safeAdd(
                                    totalAssetValue,
                                    userPrimaryResidenceInfo?.[0]?.value
                                  )
                                    ? currencyFormat(
                                        safeAdd(
                                          totalAssetValue,
                                          userPrimaryResidenceInfo?.[0]?.value
                                        )
                                      )
                                    : "$0"}
                                </span>
                              </HStack>
                            </Link>

                            {/* <div className="right-content">
                              <div className="activity-summary-graph-container">
                                <BarGraph
                                  combinedAssets={safeAdd(
                                    totalIncomePropertyValue,
                                    totalCottageValue,
                                    userPrimaryResidenceInfo?.[0]?.value
                                  )}
                                  combinedLifeInsuranceBalances={
                                    totalLifeInsuranceValue
                                  }
                                  combinedPersonAssetsBalance={
                                    totalOtherAssetValue
                                  }
                                  combinedTotal={safeAdd(
                                    totalAssetValue,
                                    userPrimaryResidenceInfo?.[0]?.value
                                  )}
                                />
                              </div>
                            </div> */}
                          </div>

                          <div className="grid-item grid-item-11">
                            <Text
                              color={"#00A2D4"}
                              textAlign={"left"}
                              fontFamily={"Nunito"}
                              fontSize={"14px"}
                              fontStyle={"normal"}
                              fontWeight={"800"}
                              lineHeight={"130%"}
                            >
                              Combined Net Worth
                            </Text>
                            <Text
                              width={"251px"}
                              flexShrink={0}
                              color={"#00466D"}
                              fontFamily={"Nunito"}
                              fontSize={"32px"}
                              fontStyle={"normal"}
                              fontWeight={"900"}
                              lineHeight={"130%"}
                            >
                              {currencyFormat(
                                safeAdd(
                                  userTFSAInfo?.totalValue || 0,
                                  userTFSAInfo?.spouseTotalValue || 0,
                                  userFHSAInfo?.spouseFhsaValue || 0,
                                  userFHSAInfo?.fhsaValue || 0,
                                  userNonRegInvestmentsInfo?.totalValue || 0,
                                  userNonRegInvestmentsInfo?.spouseTotalValue ||
                                    0,
                                  userCASHInfo?.value || 0,
                                  userCASHInfo?.spouseValue || 0,
                                  totalFixedIncome || 0,
                                  totalSpouseFixedIncome || 0,
                                  userRRSPInfo?.totalValue || 0,
                                  userRRSPInfo?.spouseTotalValue || 0,
                                  userPrimaryResidenceInfo[0]?.value || 0,
                                  userLiraInfo?.SPS1_LIRA_VALUE || 0,
                                  userLiraInfo?.SPS2_LIRA_VALUE || 0,
                                  userCottageInfo?.reduce(
                                    (acc, curr) => acc + (curr?.value || 0),
                                    0
                                  ),
                                  userPropertiesInfo?.reduce(
                                    (acc, curr) => acc + (curr?.value || 0),
                                    0
                                  ),
                                  totalLandValue,
                                  userLifeInsuranceInfo?.reduce(
                                    (acc, curr) => acc + (curr?.value || 0),
                                    0
                                  ),
                                  userOtherAssetsInfo?.reduce(
                                    (acc, curr) =>
                                      acc + (curr?.Other_Asset_Value || 0),
                                    0
                                  ),
                                  totalBusinessValue || 0,
                                  totalBusinessInvestments || 0,
                                  userPensionInfo?.reduce((acc, curr) => {
                                    return curr?.Pension_Type !==
                                      "Defined Benefit Plan"
                                      ? acc + (curr?.Pension || 0)
                                      : acc;
                                  }, 0)
                                ) -
                                  safeAdd(
                                    userDebtInfo?.reduce(
                                      (acc, curr) =>
                                        acc + (curr?.Amount_Borrowed || 0),
                                      0
                                    ),
                                    userPrimaryResidenceInfo?.reduce(
                                      (acc, curr) =>
                                        acc +
                                        (curr?.Primary_Res_Mortgage_Balance ||
                                          0),
                                      0
                                    ),
                                    userCottageInfo?.reduce(
                                      (acc, curr) =>
                                        acc +
                                        (curr?.Cottage_Mortgage_Balance || 0),
                                      0
                                    ),
                                    userPropertiesInfo?.reduce(
                                      (acc, curr) =>
                                        acc +
                                        (curr?.Income_Property_Mortgage_Balance ||
                                          0),
                                      0
                                    ),
                                    totalLandDebtBalance
                                  )
                              )}
                            </Text>
                          </div>
                          {/* <div
                            className="grid-item grid-item-13"
                            onClick={onSubmit}
                            hover
                            style={{ cursor: "pointer" }}
                          >
                            <Icon
                              as={OptimizeRocketIcon}
                              svgheight={"32px"}
                              svgwidth={"31px"}
                            />
                            <Text
                              color={"#FFF"}
                              textAlign={"center"}
                              fontFamily={"Nunito"}
                              fontSize={"20px"}
                              fontStyle={"normal"}
                              fontWeight={"600"}
                              lineHeight={"normal"}
                            >
                              Optimize
                            </Text>
                          </div> */}
                          <PopupComponent onSubmit={onSubmit} />
                        </div>
                      </div>
                    )}
                    {!spouseStatus ? (
                      <div>
                        {/* {choosePlan && <EndOfLifePage optiList={optiList} optiNoise={audioTune}/>} */}
                        {choosePlan && routeToDash()}
                      </div>
                    ) : (
                      <div>
                        {/* {choosePlan && <EndOfLifePageSpouse optiList={optiList} spouseOptiList={spouseOptilist} optiNoise={audioTune} />} */}
                        {choosePlan && routeToDash()}
                      </div>
                    )}
                  </>
                )}
            </>
          )}
        </>
      )}
    </>
  );
}

export default InputSummary;
