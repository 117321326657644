import "bootstrap/dist/css/bootstrap.min.css";
import React, { useEffect, useState } from "react";
// import Button from 'react-bootstrap/Button';
import { useAuth0 } from "@auth0/auth0-react";
import { Button, Text } from "@chakra-ui/react";
import axios from "axios";

const MultiFactorSection = () => {
  const { getAccessTokenSilently, user, loginWithRedirect } = useAuth0();
  const [submitStatus, setSubmitStatus] = useState("idle"); // 'idle', 'success', or 'error'
  const [isEnrolledInMFA, setIsEnrolledInMFA] = useState(null);
  const axiosInstance = axios.create();
  async function getToken() {
    // Replace this with your Auth0 token retrieval logic
    const token = await getAccessTokenSilently({
      audience:
        "https://os2lsiwff43wfpb5ggyawt6qri0mgcro.lambda-url.us-east-2.on.aws/",
      scope: "write:optimization",
    });
    return token;
  }
  // Add a request interceptor
  axiosInstance.interceptors.request.use(
    async (config) => {
      const token = await getToken();
      config.headers.Authorization = `Bearer ${token}`;
      return config;
    },
    (error) => {
      // Do something with request error
      return Promise.reject(error);
    }
  );

  useEffect(() => {
    const check_params = {
      user_id: user.sub,
      action: "Check_MFA",
    };
    axiosInstance
      .post(process.env.REACT_APP_AUTH0_MANAGEMENT_API, check_params)
      .then((res) => {
        console.log(res.data.two_factor_enabled);
        setIsEnrolledInMFA(res.data.two_factor_enabled);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  const handleChangeMFA = () => {
    const check_params = {
      user_id: user.sub,
      action: "Update_MFA",
      enable_2fa: !isEnrolledInMFA,
    };
    axiosInstance
      .post(process.env.REACT_APP_AUTH0_MANAGEMENT_API, check_params)
      .then((res) => {
        if (!isEnrolledInMFA) {
          loginWithRedirect();
        } else {
          setIsEnrolledInMFA(false);
        }
        console.log(res);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <>
      <Text
        alignSelf={"stretch"}
        color={"#00A2D4"}
        fontFamily={"Nunito"}
        fontSize={"16px"}
        fontStyle={"normal"}
        fontWeight={"700"}
        lineHeight={"normal"}
      >
        Two-factor Authentication
      </Text>
      <>
        {
          <>
            <Text
              alignSelf={"stretch"}
              color={"#004069"}
              fontFamily={"Nunito"}
              fontSize={"14px"}
              fontStyle={"normal"}
              fontWeight={"400"}
              lineHeight={"normal"}
            >
              Enabling this will provide an extra layer of security to your
              account. When logging in, we will ask for a special authentication
              code from your device.
            </Text>

            {isEnrolledInMFA === null ? null : isEnrolledInMFA ? ( // Show nothing if isEnrolledInMFA is null
              <Button
                padding={"5px 10px"}
                bg={"#00A2D4"}
                onClick={handleChangeMFA}
              >
                <Text
                  color={"#FFF"}
                  fontFamily={"Nunito"}
                  fontSize={"12px"}
                  fontStyle={"normal"}
                  fontWeight={"600"}
                  lineHeight={"normal"}
                >
                  Disable Two-Factor Authentication
                </Text>
              </Button>
            ) : (
              <Button
                padding={"5px 10px"}
                bg={"#00A2D4"}
                onClick={handleChangeMFA}
              >
                <Text
                  color={"#FFF"}
                  fontFamily={"Nunito"}
                  fontSize={"12px"}
                  fontStyle={"normal"}
                  fontWeight={"600"}
                  lineHeight={"normal"}
                >
                  Enable Two-Factor Authentication
                </Text>
              </Button>
            )}
          </>
        }
      </>
    </>
  );
};

export default MultiFactorSection;
