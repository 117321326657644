import React, { useState } from "react";
import { useStore } from "../../store";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronRight } from "@fortawesome/pro-light-svg-icons";
import "bootstrap/dist/css/bootstrap.min.css";
import Collapse from "react-bootstrap/Collapse";
import Card from "react-bootstrap/Card";
// import Button from 'react-bootstrap/Button';
import { useAuth0 } from "@auth0/auth0-react";
import axios from "axios";
import { Button, Text } from "@chakra-ui/react";

const ChangePasswordSection = () => {
  const logoutMultiplier = useStore((store) => store.logoutMultiplier);
  const setLogoutMultiplier = useStore((store) => store.setLogoutMultiplier);
  const { user } = useAuth0();
  const [submitStatus, setSubmitStatus] = useState("idle"); // 'idle', 'success', or 'error'
  const isGmailUser = user.sub.includes("google-oauth2|");

  const handleChangePassword = () => {
    var options = {
      method: "POST",
      url: "https://dev-ro0qsq4z.us.auth0.com/dbconnections/change_password",
      headers: { "content-type": "application/json" },
      data: {
        client_id: process.env.REACT_APP_AUTH0_CLIENT_ID,
        email: user.email,
        connection: "Username-Password-Authentication",
      },
    };
    axios
      .request(options)
      .then(function (response) {
        setSubmitStatus("success");
      })
      .catch(function (error) {
        setSubmitStatus("error");
        console.error(error);
      });
  };

  return (
    <>
      <Text
        alignSelf={"stretch"}
        color={"#00A2D4"}
        fontFamily={"Nunito"}
        fontSize={"16px"}
        fontStyle={"normal"}
        fontWeight={"700"}
        lineHeight={"normal"}
      >
        Change Password
      </Text>

      {isGmailUser ? (
        `It appears that your account was authenticated through Google. Any changes to your password will need to be done through Google.`
      ) : submitStatus === "idle" ? (
        <>
          <Text
            alignSelf={"stretch"}
            color={"#004069"}
            fontFamily={"Nunito"}
            fontSize={"14px"}
            fontStyle={"normal"}
            fontWeight={"400"}
            lineHeight={"normal"}
          >
            Upon pressing "Change Password", an email will be sent to{" "}
            {user.email} containing a link and instructions to change your
            password.
          </Text>
          <Button
            border={"1px solid #00A2D4"}
            padding={"5px 18px"}
            borderRadius={"5px"}
            bg={"#00A2D4"}
            onClick={handleChangePassword}
          >
            {" "}
            <Text
              color={"#fff"}
              fontFamily={"Nunito"}
              fontSize={"12px"}
              fontStyle={"normal"}
              fontWeight={"600"}
              lineHeight={"normal"}
            >
              {" "}
              Reset Password{" "}
            </Text>
          </Button>
        </>
      ) : submitStatus === "success" ? (
        <div>An email has been sent to {user.email}.</div>
      ) : (
        <div>
          An unknown error has occured. Please contact{" "}
          <a href="mailto:support@optiml.ca">
            support@optiml.ca
          </a>{" "}
          for assistance.
        </div>
      )}
    </>
  );
};

export default ChangePasswordSection;
