import { useAuth0 } from "@auth0/auth0-react";
import {
  Box,
  Button,
  Flex,
  Icon,
  Input,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  IconButton,
  Text,
  VStack,
  useMediaQuery,
} from "@chakra-ui/react";
import axios from "axios";
import React, {
  forwardRef,
  useContext,
  useEffect,
  useImperativeHandle,
  useState,
} from "react";
import { useStore } from "../../store";
import {
  AddSpouseIcon,
  CloseIcon,
  ImageUploadIcon,
  ProfileHoverIcon,
} from "../CustomIcons";
import GlobalBodyPage from "../GlobalContainer/BodyPage";
import DatePickerButton from "../InputFields/datePickerButton";
import DropDownButton from "../InputFields/dropDownButton";
import NormalInputButton from "../InputFields/normalInputButton";
import { SpouseContext } from "../SpouseContext";
import { StripeContext } from "../StripeContext";
import { DeleteIcon } from "@chakra-ui/icons";

const CreateProfileForm = forwardRef((props, ref) => {
  const { setChangedData } = props;
  const { getAccessTokenSilently } = useAuth0();

  useImperativeHandle(ref, () => ({
    submitHandler,
    checkMandatoryFields,
  }));
  const axiosInstance = axios.create();
  async function getToken() {
    // Replace this with your Auth0 token retrieval logic
    const token = await getAccessTokenSilently({
      audience:
        "https://os2lsiwff43wfpb5ggyawt6qri0mgcro.lambda-url.us-east-2.on.aws/",
      scope: "write:optimization",
    });
    return token;
  }

  // Add a request interceptor
  axiosInstance.interceptors.request.use(
    async (config) => {
      const token = await getToken();
      config.headers.Authorization = `Bearer ${token}`;
      return config;
    },
    (error) => {
      // Do something with request error
      return Promise.reject(error);
    }
  );

  const ThreeDotsIcon = () => (
    <Box display="flex" alignItems="center" justifyContent="center">
      <Box w={1} h={1} bg="blue.500" borderRadius="50%" mr={1}></Box>
      <Box w={1} h={1} bg="blue.500" borderRadius="50%" mr={1}></Box>
      <Box w={1} h={1} bg="blue.500" borderRadius="50%"></Box>
    </Box>
  );
  const { user, logout } = useAuth0();
  const { spouseStatus, setSpouseStatus } = useContext(SpouseContext);
  const { stripePlan, setStripePlan } = useContext(StripeContext);
  const [showContent, setShowContent] = useState(false);
  const [isLargerThan768] = useMediaQuery("(min-width: 1200px)");
  const isMobile = !isLargerThan768;

  const [FirstName, setFirstName] = useState("");
  const [LastName, setLastName] = useState("");
  const [spouseFirstName, setSpouseFirstName] = useState("");
  const [spouseLastName, setSpouseLastName] = useState("");
  const [Email, setEmail] = useState(user.email);
  const [DateOfBirth, setDateOfBirth] = useState("");
  const [spouseDateOfBirth, setSpouseDateOfBirth] = useState("");
  const [Country, setCountry] = useState("");
  const [Province, setProvince] = useState("");
  const [MaritalStatus, setMaritalStatus] = useState("Single");
  const [language, setLanguage] = useState("");
  const [hasSpouse, setHasSpouse] = useState(false);

  const userInfo = useStore((store) => store.userInfo);
  const savedUser = userInfo;
  const setUserInfo = useStore((store) => store.setUserInfo);
  const hideProgressBar = useStore((store) => store.hideProgressBar);
  const userOptiData = useStore((store) => store.userOptiData);
  const setUserOptiData = useStore((store) => store.setUserOptiData);
  const setShowNewOptimization = useStore(
    (store) => store.setShowNewOptimization
  );

  const [errors, setErrors] = useState({});

  const setUserPageLockInfo = useStore((store) => store.setUserPageLockInfo);
  const userPageLockInfo = useStore((store) => store.userPageLockInfo);

  const currentYear = new Date().getFullYear();
  const minYear = currentYear - 120; // Assuming the oldest possible age is 120 years
  const maxYear = currentYear;

  // Format the min and max year in yyyy-mm-dd format
  const minDate = `${minYear}-01-01`;
  const maxDate = `${maxYear}-12-31`;

  const [newUser, setNewUser] = useState();
  const [isEditMode, setIsEditMode] = useState(false);

  const [imageError, setImageError] = useState(false);

  const [spouseImageError, setSpouseImageError] = useState(false);


  const [profilePicUrl, setProfilePicUrl] = useState();
  const [profilePicUrlSpouse, setProfilePicUrlSpouse] = useState();

  useEffect(() => {
    if (userOptiData?.primaryUserData?.expenses?.length > 1) {
      setNewUser(false);
    } else if (userOptiData?.primaryUserData?.expenses?.length === undefined) {
      setNewUser(true);
      setIsEditMode(true);
    }
  }, [userOptiData]);

  useEffect(() => {
    if (savedUser?.FirstName) {
      setFirstName(savedUser?.FirstName);
      setLastName(savedUser?.LastName);
      setSpouseFirstName(savedUser?.spouseFirstName);
      setSpouseLastName(savedUser?.spouseLastName);
      setDateOfBirth(savedUser?.DateOfBirth);
      setSpouseDateOfBirth(savedUser?.spouseDateOfBirth);
      setCountry(savedUser?.Country);
      setProvince(savedUser?.Province);
      setMaritalStatus(savedUser?.MaritalStatus);
      setProfilePicUrl(savedUser?.Picture);
      setProfilePicUrlSpouse(savedUser?.spousePicture)

      if (savedUser?.MaritalStatus === "married") {
        setSpouseStatus(true);
        setHasSpouse(true);
        setShowContent(true);
      }
      setUserInfo({ ...userInfo, Email: user.email });
    }
  }, []);



  const checkMandatoryFields = () => {
    let newErrors = {};
    if (!FirstName) newErrors.FirstName = "First Name is required";
    if (!LastName) newErrors.LastName = "Last Name is required";
    if (!Email) newErrors.Email = "Email is required";
    if (!DateOfBirth) {
      newErrors.DateOfBirth = "Date of Birth is required";
    } else {
      const currentDate = new Date();
      const inputDate = new Date(DateOfBirth);

      // Set the time part of the date to 00:00:00 to ensure accurate comparison
      currentDate.setHours(0, 0, 0, 0);
      inputDate.setHours(0, 0, 0, 0);

      if (inputDate > currentDate) {
        newErrors.DateOfBirth = "Date of Birth cannot be in the future.";
      }
    }

    if (!Province) newErrors.Province = "Province is required";

    if (hasSpouse) {
      if (!spouseFirstName)
        newErrors.spouseFirstName = "First Name is required";
      if (!spouseLastName) newErrors.spouseLastName = "Last Name is required";
      if (!spouseDateOfBirth)
        newErrors.spouseDateOfBirth = "Date of Birth is required";
      else {
        const currentDate = new Date();
        const inputDate = new Date(spouseDateOfBirth);

        // Set the time part of the date to 00:00:00 to ensure accurate comparison
        currentDate.setHours(0, 0, 0, 0);
        inputDate.setHours(0, 0, 0, 0);

        if (inputDate > currentDate) {
          newErrors.spouseDateOfBirth =
            "Date of Birth cannot be in the future.";
        }
      }
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0; // Return true if no errors
  };

  const lockSidebar = () => {
    //Set Page Lock Info In Database:
    const pageLockURL = (action) =>
      `${
        process.env.REACT_APP_NODE_API_GATEWAY
      }/CompassLambdaApi/NEW_USER_PAGE_LOCK/${action}?PageLockID=${user.sub.replace(
        "|",
        "%7c"
      )}&IncomePage=${0}&InvestmentsPage=${0}&ExpensesPage=${0}&OptimizationPage=${0}`;
    axiosInstance
      .get(pageLockURL("add"))
      .then((res) => {
        //Unlock Income Sidebar:
        setUserPageLockInfo({
          ...userPageLockInfo,
          IncomePage: 0,
          InvestmentsPage: 0,
          ExpensesPage: 0,
          OptimizationPage: 0,
        });
        console.log(res);
        if (res.data.code === "ER_DUP_ENTRY") {
          axiosInstance.get(pageLockURL("update")).then((res) => {
            console.log(res);
            //Unlock Income Sidebar:
            setUserPageLockInfo({
              ...userPageLockInfo,
              IncomePage: 0,
              InvestmentsPage: 0,
              ExpensesPage: 0,
              OptimizationPage: 0,
            });
          });
        }
      })
      .catch((error) => {
        console.log(error);
      });
    //
  };

  const submitHandler = (e) => {
    //Set Page Lock Info In Database:
    if (e) {
      e.preventDefault();
    }

    console.log(
      "All mandatory fields are filled. Proceeding with submission..."
    );
    const pageLockURL = (action) =>
      `${
        process.env.REACT_APP_NODE_API_GATEWAY
      }/CompassLambdaApi/NEW_USER_PAGE_LOCK/${action}?PageLockID=${user.sub.replace(
        "|",
        "%7c"
      )}&IncomePage=${0}&InvestmentsPage=${0}&ExpensesPage=${0}&OptimizationPage=${0}`;
    axiosInstance
      .get(pageLockURL("add"))
      .then((res) => {
        if (res.data.code != "ER_DUP_ENTRY") {
          setUserPageLockInfo({
            ...userPageLockInfo,
            IncomePage: 0,
            InvestmentsPage: 0,
            ExpensesPage: 0,
            OptimizationPage: 0,
          });
        }
      })
      .catch((error) => {
        console.log(error);
      });
    // let tempRunAge = 100
    setIsEditMode(!isEditMode);

    let status = MaritalStatus;
    if (hasSpouse == false) {
      status = "single";
    }
    try {
      axiosInstance
        .get(
          `${
            process.env.REACT_APP_NODE_API_GATEWAY
          }/CompassLambdaApi/USERS/add?UserID=${user.sub.replace(
            "|",
            "%7c"
          )}&FirstName=${FirstName}&LastName=${LastName}&Email=${Email}&DateOfBirth=${DateOfBirth}&Country=${Country}&Province=${Province}&MaritalStatus=${status}&spouseFirstName=${spouseFirstName}&spouseLastName=${spouseLastName}&spouseDateOfBirth=${spouseDateOfBirth}`
        )
        .then((res) => {
          setUserInfo({
            ...userInfo,
            FirstName: FirstName,
            LastName: LastName,
            Email: Email,
            DateOfBirth: DateOfBirth,
            Province: Province,
            MaritalStatus: status,
            spouseFirstName: spouseFirstName,
            spouseLastName: spouseLastName,
            spouseDateOfBirth: spouseDateOfBirth,
            Picture: profilePicUrl,
            spousePicture: profilePicUrlSpouse,
            // runAge: tempRunAge
          });
          console.log(res);
          if (res.data.code === "ER_DUP_ENTRY") {
            axiosInstance
              .get(
                `${
                  process.env.REACT_APP_NODE_API_GATEWAY
                }/CompassLambdaApi/USERS/update?UserID=${user.sub.replace(
                  "|",
                  "%7c"
                )}&FirstName=${FirstName}&LastName=${LastName}&Email=${Email}&DateOfBirth=${DateOfBirth}&Country=${Country}&Province=${Province}&MaritalStatus=${status}&spouseFirstName=${spouseFirstName}&spouseLastName=${spouseLastName}&spouseDateOfBirth=${spouseDateOfBirth}`
              )
              .then((res) => {
                console.log(res);
                setUserInfo({
                  ...userInfo,
                  FirstName: FirstName,
                  LastName: LastName,
                  Email: Email,
                  DateOfBirth: DateOfBirth,
                  Province: Province,
                  MaritalStatus: status,
                  spouseFirstName: spouseFirstName,
                  spouseLastName: spouseLastName,
                  spouseDateOfBirth: spouseDateOfBirth,
                  Picture: profilePicUrl,
                  spousePicture: profilePicUrlSpouse,
                  // runAge: tempRunAge
                });
              });
          }
        });
    } catch (err) {
      console.error(err);
    }
  };

  function deleteSpouse() {
    getMaritalStatus("single");
    setShowContent(false);
    setShowDeleteModal(false);
    setUserOptiData({});
    setShowNewOptimization(true);
    setNewUser(true);
    lockSidebar();
  }

  function getMaritalStatus(marStatus) {
    setUserInfo({ ...userInfo, MaritalStatus: marStatus });
    if (marStatus === "married") {
      setHasSpouse(true);
      setShowContent(true);
      setMaritalStatus("married");
      setSpouseStatus(true);

      setUserOptiData({});
      setShowNewOptimization(true);
      setNewUser(true);
      lockSidebar();
    } else {
      setSpouseStatus(false);
      setHasSpouse(false);
      setShowContent(false);
      setUserOptiData({});
      setShowNewOptimization(true);
      setNewUser(true);
      lockSidebar();
    }
    return marStatus;
  }

  const provinces = [
    { key: "AB", value: "Alberta" },
    { key: "BC", value: "British Columbia" },
    { key: "MB", value: "Manitoba" },
    { key: "NB", value: "New Brunswick" },
    { key: "NL", value: "Newfoundland and Labrador" },
    { key: "NS", value: "Nova Scotia" },
    { key: "NT", value: "Northwest Territories" },
    { key: "NU", value: "Nunavut" },
    { key: "ON", value: "Ontario" },
    { key: "PEI", value: "Prince Edward Island" },
    { key: "QC", value: "Quebec" },
    { key: "SK", value: "Saskatchewan" },
    { key: "YT", value: "Yukon" },
  ];

  const countries = [{ key: "Canada", value: "Canada" }];

  const languages = [{ key: "EN", value: "English" }];

  const [isSpouse1Hovered, setIsSpouse1Hovered] = useState(false);
  const [isSpouse2Hovered, setIsSpouse2Hovered] = useState(false);


  useEffect(() => {
    // Configure AWS SDK with environment variables
    function loadNewPicture(){
        AWS.config.update({
          accessKeyId: process.env.REACT_APP_PICTURE_ID,
          secretAccessKey: process.env.REACT_APP_PROFILE_SECRET,
          region: "us-east-2",
        });
        const s3 = new AWS.S3();
        const params = {
          Bucket: "optiml-user-pictures",
          Key: "profile-pics/" + user?.sub, // Unique key per user
          Expires: 3600, // Valid for 1 hour
        };
    
        const spouseParams = {
          Bucket: "optiml-user-pictures",
          Key: "profile-pics/" + user?.sub + "spouse", // Unique key per user
          Expires: 3600, // Valid for 1 hour
        };
    
        s3.getSignedUrl("getObject", params, (err, url) => {
          if (err) {
            console.error("Error generating pre-signed URL:", err);
          } else {
            setProfilePicUrl(url);
          }
        });
    
        s3.getSignedUrl("getObject", spouseParams, (err, url) => {
          if (err) {
            console.error("Error generating pre-signed URL:", err);
          } else {
            setProfilePicUrlSpouse(url);
          }
        });
    }
    // if(!savedUser?.Picture || !savedUser?.spousePicture){
      loadNewPicture()
      // setUserInfo({
      //   ...userInfo,
      //   Picture: profilePicUrl,
      //   spousePicture: profilePicUrlSpouse,
      // });
    // }

  }, [profilePicUrl, profilePicUrlSpouse]);

  const handleUpload = (file) => {
    // Configure AWS SDK with environment variables
    AWS.config.update({
      accessKeyId: process.env.REACT_APP_PICTURE_ID,
      secretAccessKey: process.env.REACT_APP_PROFILE_SECRET,
      region: "us-east-2",
    });
    const s3 = new AWS.S3();
    const params = {
      Bucket: "optiml-user-pictures",
      Key: "profile-pics/" + user?.sub, // Ensure this matches your GET key
      Expires: 3600, // 1 hour
      ContentType: file?.type,
    };

    // Check file size (replace 2 with your desired maximum size in MB)
    const fileSize = file.size / 1024 / 1024; // size in MB
    if (fileSize > 2) {
      alert("File size exceeds 2MB");
      return;
    }

    // Check file type
    const fileType = file?.type;
    const allowedFileTypes = [
      "image/jpeg",
      "image/png",
      "image/jpg",
      "image/heic",
    ];
    if (!allowedFileTypes.includes(fileType)) {
      alert(
        "Invalid file type. Only JPEG, PNG, JPG, and HEIC files are allowed."
      );
      return;
    }

    s3.getSignedUrl("putObject", params, (err, url) => {
      if (err) {
        console.error("Error generating pre-signed URL for upload:", err);
        return;
      }

      fetch(url, {
        method: "PUT",
        headers: {
          "Content-Type": file?.type,
        },
        body: file,
      })
        .then((response) => {
          if (response.ok) {
            console.log("Successfully uploaded profile picture.");
            // Optionally refresh the displayed profile picture URL

            setProfilePicUrl(url.split("?")[0]); // Removing query params
            setUserInfo({
              ...userInfo,
              Picture: url.split("?")[0],
            });
          } else {
            console.log(response);
            console.error("Failed to upload profile picture.");
          }
        })
        .catch((error) => console.error("Error uploading file:", error));
    });
  };

  const handleUploadSpouse = (file) => {
    // Configure AWS SDK with environment variables
    AWS.config.update({
      accessKeyId: process.env.REACT_APP_PICTURE_ID,
      secretAccessKey: process.env.REACT_APP_PROFILE_SECRET,
      region: "us-east-2",
    });
    const s3 = new AWS.S3();
    const params = {
      Bucket: "optiml-user-pictures",
      Key: "profile-pics/" + user?.sub + "spouse", // Ensure this matches your GET key
      Expires: 3600, // 1 hour
      ContentType: file?.type,
    };

    // Check file size (replace 2 with your desired maximum size in MB)
    const fileSize = file.size / 1024 / 1024; // size in MB
    if (fileSize > 2) {
      alert("File size exceeds 2MB");
      return;
    }

    // Check file type
    const fileType = file?.type;
    const allowedFileTypes = [
      "image/jpeg",
      "image/png",
      "image/jpg",
      "image/heic",
    ];
    if (!allowedFileTypes.includes(fileType)) {
      alert(
        "Invalid file type. Only JPEG, PNG, JPG, and HEIC files are allowed."
      );
      return;
    }

    s3.getSignedUrl("putObject", params, (err, url) => {
      if (err) {
        console.error("Error generating pre-signed URL for upload:", err);
        return;
      }

      fetch(url, {
        method: "PUT",
        headers: {
          "Content-Type": file?.type,
        },
        body: file,
      })
        .then((response) => {
          if (response.ok) {
            console.log("Successfully uploaded profile picture.");
            // Optionally refresh the displayed profile picture URL
            setProfilePicUrlSpouse(url.split("?")[0]); // Removing query params
            setUserInfo({
              ...userInfo,
              spousePicture: url.split("?")[0],
            });
          } else {
            console.error("Failed to upload profile picture.");
          }
        })
        .catch((error) => console.error("Error uploading file:", error));
    });
  };


  const [isDropdownVisible, setDropdownVisible] = useState(false);

  const [isLargerThan1200] = useMediaQuery(
    "(min-width: 500px) and (max-width: 1250px)"
  );

  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const DeleteSpouseModal = () => {
    const [yesClicked, setYesClicked] = useState(false);
    const [noClicked, setNoClicked] = useState(false);
    return (
      <div
        className="delete-income-modal"
        style={{
          display: "flex",
          alignItems: "center",
          width: "100%",
          maxWidth: "700px",
          justifyContent: "center",
          zIndex: "100",
          position: "fixed",
          top: "50%",
          left: "50%",
          transform: isMobile
            ? "translate(-50%, -50%)"
            : "translate(-50%, -50%)",
        }}
        id="delete-income-modal"
      >
        <Box
          display={"flex"}
          width={"100%"}
          padding={"10px 10px 0px 0px"}
          marginLeft={"5px"}
          alignItems={"flex-end"}
          justifyContent={"flex-end"}
          onClick={() => setShowDeleteModal(false)}
          cursor={"pointer"}
        >
          <CloseIcon
            onClick={() => setShowDeleteModal(false)}
            svgheight={"20px"}
            svgwidth={"20px"}
          />
        </Box>
        <Box
          display={"flex"}
          padding={"0px 25px 25px 25px"}
          flexDirection={"column"}
          alignItems={"center"}
          gap={"10px"}
          alignSelf={"stretch"}
          width={"100%"}
        >
          <Text
            color={"#0093D0"}
            fontFamily={"Nunito"}
            fontSize={"24px"}
            fontStyle={"normal"}
            fontWeight={"600"}
            lineHeight={"normal"}
            textDecor={"underline"}
          >
            Are you sure?
          </Text>
          <Text
            alignSelf={"stretch"}
            color={"#004069"}
            textAlign={"center"}
            fontFamily={"Nunito"}
            fontSize={"14px"}
            fontStyle={"normal"}
            fontWeight={"600"}
            lineHeight={"normal"}
          >
            Removing your spouse will require you to go through the setup
            process again and run a new analysis. This is necessary because your
            financial plan will significantly change as you transition to a
            single-person plan.
          </Text>
          <div className="actions">
            <button
              className={`decision-button yes-button ${
                yesClicked ? "yes-clicked" : ""
              }`}
              onClick={() => {
                setYesClicked(true);
                setNoClicked(false);
                deleteSpouse();
              }}
            >
              Yes
            </button>
            <button
              className={`decision-button no-button ${
                noClicked ? "yes-clicked" : ""
              }`}
              onClick={() => {
                {
                  setNoClicked(true);
                  setYesClicked(false);
                  setShowDeleteModal(false);
                }
              }}
            >
              No
            </button>
          </div>
        </Box>
      </div>
    );
  };

  return (
    <Flex
      display="flex"
      flexWrap="wrap" // Enable wrapping
      justifyContent="flex-start"
      alignItems="start"
      w="100%"
    >
      {isMobile && (
        <VStack spacing={4} align="stretch" w="full">
          <GlobalBodyPage
            Heading={"Profile"}
            mainframeContent={
              <VStack spacing={4} alignItems={"flex-start"} width={"100%"}>
                <Flex justify="space-between" w="full">
                  <Box
                    display="flex"
                    flexDirection="column"
                    justifyContent="center"
                    alignItems="center"
                    width="70px"
                    height="70px"
                    // padding="10px"
                    // gap="10px"
                    borderRadius="50%"
                    border="1px solid #0093D0"
                    background="#FFF"
                    position="relative"
                    overflow="hidden"
                    cursor="pointer"
                    onMouseEnter={() => setIsSpouse1Hovered(true)}
                    onMouseLeave={() => setIsSpouse1Hovered(false)}
                    onClick={() =>
                      document.getElementById("file-input-1").click()
                    }
                  >
                    <input
                      id="file-input-1"
                      type="file"
                      style={{ display: "none" }}
                      onChange={(e) => {
                        handleUpload(e.target.files[0]);
                        setChangedData(true);
                      }}
                    />
                    {!imageError && profilePicUrl ? (
                      <img
                        src={profilePicUrl}
                        alt="Profile"
                        style={{
                          width: "100%",
                          height: "100%",
                          objectFit: "cover",
                          objectPosition: "top center",
                          justifyContent: "center",
                        }}
                        onError={() => setImageError(true)}
                      />
                    ) : (
                      <Icon as={ImageUploadIcon} />
                    )}
                    {isSpouse1Hovered && (
                      <Box
                        position="absolute"
                        bottom="0"
                        left="0"
                        width="100%"
                        height="35px"
                        background="rgba(217, 217, 217, 0.8)"
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                        transition="bottom 0.3s ease"
                        borderBottomLeftRadius="35px"
                        borderBottomRightRadius="35px"
                      >
                        <ProfileHoverIcon width="20px" height="17px" />
                      </Box>
                    )}
                    <Input type="file" id="file-input" display="none" />
                  </Box>
                </Flex>

                <Flex
                  justify="space-between"
                  w="100%"
                  alignItems="flex-end"
                  gap={"20px"}
                  flexWrap={["wrap", "nowrap"]}
                >
                  <NormalInputButton
                    Heading="First Name"
                    placeholder="First Name"
                    value={FirstName}
                    onChange={(e) => {
                      setFirstName(e.target.value);
                      setChangedData(true);
                    }}
                    error={errors.FirstName}
                    InputWidth={"100%"}
                  />
                  <NormalInputButton
                    Heading="Last Name"
                    placeholder="Last Name"
                    value={LastName}
                    onChange={(e) => {
                      setLastName(e.target.value);
                      setChangedData(true);
                    }}
                    error={errors.LastName}
                    InputWidth={"100%"}
                  />
                </Flex>
                <Flex
                  justify="space-between"
                  w="full"
                  alignItems="flex-end"
                  gap={"20px"}
                  flexWrap={["wrap", "nowrap"]}
                >
                  <NormalInputButton
                    Heading="Email"
                    placeholder="Email"
                    value={Email}
                    onChange={(e) => {
                      setEmail(e.target.value);
                      setChangedData(true);
                    }}
                    error={errors.Email}
                    InputWidth={"100%"}
                    disabled={Email !== ""}
                  />
                  <DatePickerButton
                    Heading="Date of Birth"
                    placeholder="xx/xx/xxxx"
                    value={DateOfBirth}
                    onChange={(e) => {
                      setNewUser(true);
                      lockSidebar();
                      setDateOfBirth(e.target.value);
                      setUserOptiData({});
                      setShowNewOptimization(true);
                      setUserInfo({ ...userInfo, DateOfBirth: e.target.value });
                    }}
                    maxYear={2024}
                    error={errors.DateOfBirth}
                  />
                </Flex>
                <Flex
                  justify="space-between"
                  w="full"
                  alignItems="flex-end"
                  gap={"20px"}
                  flexWrap={["wrap", "nowrap"]}
                >
                  {/* <DropDownButton
            Heading={"Country"}
            placeholder={"Country"}
            dropDownItems={countries}
            value={Country}
            onChange={(key) => {
              setCountry(key);
            }}
          /> */}
                  <DropDownButton
                    Heading={"Province/Territory"}
                    placeholder={"Select"}
                    dropDownItems={provinces}
                    value={Province}
                    onChange={(key) => {
                      setProvince(key);
                      setChangedData(true);
                    }}
                    customWidth={"100%"}
                    MenuCustomWidth={isLargerThan1200 ? "45%" : "100%"}
                    error={errors.Province}
                  />
                </Flex>
                <Flex
                  justify="space-between"
                  w="full"
                  alignItems="flex-end"
                  gap={"20px"}
                  flexWrap={["wrap", "nowrap"]}
                >
                  {/* <DropDownButton
            Heading={"Language"}
            placeholder={"English"}
            dropDownItems={languages}
          /> */}
                </Flex>
                <Flex justify="space-between" w="full">
                  {showContent ? (
                    <>
                      <VStack
                        spacing={4}
                        align="stretch"
                        w="full"
                        alignItems="flex-end"
                      >
                        <Flex justifyContent={"flex-end"}>
                          <Popover>
                            <PopoverTrigger>
                              <IconButton
                                aria-label="Options"
                                icon={<ThreeDotsIcon />}
                                size="sm"
                                variant="ghost"
                              />
                            </PopoverTrigger>
                            <PopoverContent
                              width="-webkit-fill-available"
                              _focus={{ boxShadow: "none" }}
                            >
                              <PopoverArrow />
                              <PopoverBody>
                                <Button
                                  leftIcon={<DeleteIcon />}
                                  colorScheme="red"
                                  variant="ghost"
                                  width="full"
                                  justifyContent="flex-start"
                                  size="sm"
                                  onClick={() => setShowDeleteModal(true)}
                                >
                                  Remove Spouse
                                </Button>
                              </PopoverBody>
                            </PopoverContent>
                          </Popover>
                        </Flex>
                        {showDeleteModal && <DeleteSpouseModal />}
                        <Flex justify="space-between" w="full">
                          <Box
                            display="flex"
                            flexDirection="column"
                            justifyContent="center"
                            alignItems="center"
                            width="70px"
                            height="70px"
                            // padding="10px"
                            // gap="10px"
                            borderRadius="50%"
                            border="1px solid #0093D0"
                            background="#FFF"
                            position="relative"
                            overflow="hidden"
                            cursor="pointer"
                            onMouseEnter={() => setIsSpouse2Hovered(true)}
                            onMouseLeave={() => setIsSpouse2Hovered(false)}
                            onClick={() =>
                              document.getElementById("file-input-2").click()
                            }
                            onChange={(e) => {
                              handleUploadSpouse(e.target.files[0]);
                              setChangedData(true);
                            }}
                          >
                            {!spouseImageError && profilePicUrlSpouse ? (
                              <img
                                src={profilePicUrlSpouse}
                                alt="Profile"
                                style={{
                                  width: "100%",
                                  height: "100%",
                                  objectFit: "cover",
                                  objectPosition: "top center",
                                  justifyContent: "center",
                                }}
                                onError={() => setSpouseImageError(true)}
                              />
                            ) : (
                              <Icon as={ImageUploadIcon} />
                            )}
                            {isSpouse2Hovered && (
                              <Box
                                position="absolute"
                                bottom="0"
                                left="0"
                                width="100%"
                                height="35px"
                                background="rgba(217, 217, 217, 0.8)"
                                display="flex"
                                justifyContent="center"
                                alignItems="center"
                                transition="bottom 0.3s ease"
                                borderBottomLeftRadius="35px"
                                borderBottomRightRadius="35px"
                              >
                                <ProfileHoverIcon width="20px" height="17px" />
                              </Box>
                            )}
                            <Input
                              type="file"
                              id="file-input-2"
                              display="none"
                            />
                          </Box>
                        </Flex>

                        <Flex
                          justify="space-between"
                          w="full"
                          alignItems="flex-end"
                          gap={"20px"}
                          flexWrap={["wrap", "nowrap"]}
                        >
                          <NormalInputButton
                            Heading="First Name"
                            placeholder="First Name"
                            value={spouseFirstName}
                            onChange={(e) => {
                              setSpouseFirstName(e.target.value);
                              setChangedData(true);
                            }}
                            error={errors.spouseFirstName}
                            InputWidth={"100%"}
                          />
                          <NormalInputButton
                            Heading="Last Name"
                            placeholder="Last Name"
                            value={spouseLastName}
                            onChange={(e) => {
                              setSpouseLastName(e.target.value);
                              setChangedData(true);
                            }}
                            error={errors.spouseLastName}
                            InputWidth={"100%"}
                          />
                        </Flex>
                        <Flex
                          justify="space-between"
                          w="full"
                          gap={"20px"}
                          flexWrap={["wrap", "nowrap"]}
                        >
                          {/* <NormalInputButton
                      Heading="Email"
                      placeholder="Email Address"
                    /> */}
                          <DatePickerButton
                            Heading="Date of Birth"
                            placeholder="xx/xx/xxxx"
                            value={spouseDateOfBirth}
                            onChange={(e) => {
                              setNewUser(true);
                              lockSidebar();
                              setSpouseDateOfBirth(e.target.value);
                              setUserOptiData({});
                              setShowNewOptimization(true);
                              setUserInfo({
                                ...userInfo,
                                spouseDateOfBirth: e.target.value,
                              });
                            }}
                            maxYear={2024}
                            error={errors.spouseDateOfBirth}
                          />
                        </Flex>
                      </VStack>
                    </>
                  ) : (
                    <>
                      <Button
                        onClick={() => getMaritalStatus("married")}
                        bg={""}
                      >
                        <Icon as={AddSpouseIcon} />
                        <Text w={"120px"} alignSelf={"center"}>
                          Add Spouse
                        </Text>
                      </Button>
                    </>
                  )}
                </Flex>
              </VStack>
            }
            videoName={"Profile Page"}
            video={"Profile_Page.mp4"}
          />
        </VStack>
      )}
      {!isMobile && (
        <VStack spacing={4} align="stretch" w="full" mt={-4}>
          <GlobalBodyPage
            Heading={"Profile"}
            mainframeContent={
              <VStack spacing={4} alignItems={"flex-start"} width={"100%"}>
                <Flex justify="space-between" w="full">
                  <Box
                    display="flex"
                    flexDirection="column"
                    justifyContent="center"
                    alignItems="center"
                    width="70px"
                    height="70px"
                    // padding="10px"
                    // gap="10px"
                    borderRadius="50%"
                    border="1px solid #0093D0"
                    background="#FFF"
                    position="relative"
                    overflow="hidden"
                    cursor="pointer"
                    onMouseEnter={() => setIsSpouse1Hovered(true)}
                    onMouseLeave={() => setIsSpouse1Hovered(false)}
                    onClick={() =>
                      document.getElementById("file-input-1").click()
                    }
                  >
                    <input
                      id="file-input-1"
                      type="file"
                      style={{ display: "none" }}
                      onChange={(e) => {
                        handleUpload(e.target.files[0]);
                        setChangedData(true);
                      }}
                    />
                    {!imageError && profilePicUrl ? (
                      <img
                        src={profilePicUrl}
                        alt="Profile"
                        style={{
                          width: "100%",
                          height: "100%",
                          objectFit: "cover",
                          objectPosition: "top center",
                          justifyContent: "center",
                        }}
                        onError={() => setImageError(true)}
                      />
                    ) : (
                      <Icon as={ImageUploadIcon} />
                    )}
                    {isSpouse1Hovered && (
                      <Box
                        position="absolute"
                        bottom="0"
                        left="0"
                        width="100%"
                        height="35px"
                        background="rgba(217, 217, 217, 0.8)"
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                        transition="bottom 0.3s ease"
                        borderBottomLeftRadius="35px"
                        borderBottomRightRadius="35px"
                      >
                        <ProfileHoverIcon width="20px" height="17px" />
                      </Box>
                    )}
                    <Input type="file" id="file-input" display="none" />
                  </Box>
                </Flex>

                <Flex justify="space-between" w="full" alignItems="flex-end">
                  <NormalInputButton
                    Heading="First Name"
                    placeholder="First Name"
                    value={FirstName}
                    onChange={(e) => {
                      setFirstName(e.target.value);
                      setChangedData(true);
                    }}
                    error={errors.FirstName}
                  />
                  <NormalInputButton
                    Heading="Last Name"
                    placeholder="Last Name"
                    value={LastName}
                    onChange={(e) => {
                      setLastName(e.target.value);
                      setChangedData(true);
                    }}
                    error={errors.LastName}
                  />
                </Flex>
                <Flex justify="space-between" w="full" alignItems="flex-end">
                  <NormalInputButton
                    Heading="Email"
                    placeholder="Email Address"
                    value={Email}
                    onChange={(e) => {
                      setEmail(e.target.value);
                      setChangedData(true);
                    }}
                    error={errors.Email}
                    disabled={Email !== ""}
                  />
                  <DatePickerButton
                    Heading="Date of Birth"
                    placeholder="xx/xx/xxxx"
                    value={DateOfBirth}
                    onChange={(e) => {
                      setNewUser(true);
                      lockSidebar();
                      setDateOfBirth(e.target.value);
                      setUserOptiData({});
                      setShowNewOptimization(true);
                      setUserInfo({ ...userInfo, DateOfBirth: e.target.value });
                    }}
                    maxYear={2024}
                    error={errors.DateOfBirth}
                  />
                </Flex>
                <Flex justify="space-between" w="full" alignItems="flex-end">
                  {/* <DropDownButton
            Heading={"Country"}
            placeholder={"Country"}
            dropDownItems={countries}
            value={Country}
            onChange={(key) => {
              setCountry(key);
            }}
          /> */}
                  <DropDownButton
                    Heading={"Province/Territory"}
                    placeholder={"Select"}
                    dropDownItems={provinces}
                    value={Province}
                    onChange={(key) => {
                      setProvince(key);
                      setChangedData(true);
                    }}
                    error={errors.Province}
                  />
                </Flex>
                <Flex justify="space-between" w="full" alignItems="flex-end">
                  {/* <DropDownButton
            Heading={"Language"}
            placeholder={"English"}
            dropDownItems={languages}
          /> */}
                </Flex>
                <Flex justify="space-between" w="full">
                  {showContent ? (
                    <>
                      <VStack
                        spacing={4}
                        align="stretch"
                        w="full"
                        alignItems="flex-end"
                      >
                        <Flex justifyContent={"flex-end"}>
                          <Popover>
                            <PopoverTrigger>
                              <IconButton
                                aria-label="Options"
                                icon={<ThreeDotsIcon />}
                                size="sm"
                                variant="ghost"
                              />
                            </PopoverTrigger>
                            <PopoverContent
                              width="-webkit-fill-available"
                              _focus={{ boxShadow: "none" }}
                            >
                              <PopoverArrow />
                              <PopoverBody>
                                <Button
                                  leftIcon={<DeleteIcon />}
                                  colorScheme="red"
                                  variant="ghost"
                                  width="full"
                                  justifyContent="flex-start"
                                  size="sm"
                                  onClick={() => setShowDeleteModal(true)}
                                >
                                  Remove Spouse
                                </Button>
                              </PopoverBody>
                            </PopoverContent>
                          </Popover>
                        </Flex>
                        {showDeleteModal && <DeleteSpouseModal />}
                        <Flex justify="space-between" w="full">
                          <Box
                            display="flex"
                            flexDirection="column"
                            justifyContent="center"
                            alignItems="center"
                            width="70px"
                            height="70px"
                            // padding="10px"
                            // gap="10px"
                            borderRadius="50%"
                            border="1px solid #0093D0"
                            background="#FFF"
                            position="relative"
                            overflow="hidden"
                            cursor="pointer"
                            onMouseEnter={() => setIsSpouse2Hovered(true)}
                            onMouseLeave={() => setIsSpouse2Hovered(false)}
                            onClick={() =>
                              document.getElementById("file-input-2").click()
                            }
                            onChange={(e) => {
                              handleUploadSpouse(e.target.files[0]);
                              setChangedData(true);
                            }}
                          >
                            {!spouseImageError && profilePicUrlSpouse ? (
                              <img
                                src={profilePicUrlSpouse}
                                alt="Profile"
                                style={{
                                  width: "100%",
                                  height: "100%",
                                  objectFit: "cover",
                                  objectPosition: "top center",
                                  justifyContent: "center",
                                }}
                                // borderRadius="50%"
                                onError={() => setSpouseImageError(true)}
                              />
                            ) : (
                              <Icon as={ImageUploadIcon} />
                            )}
                            {isSpouse2Hovered && (
                              <Box
                                position="absolute"
                                bottom="0"
                                left="0"
                                width="100%"
                                height="35px"
                                background="rgba(217, 217, 217, 0.8)"
                                display="flex"
                                justifyContent="center"
                                alignItems="center"
                                transition="bottom 0.3s ease"
                                borderBottomLeftRadius="35px"
                                borderBottomRightRadius="35px"
                              >
                                <ProfileHoverIcon width="20px" height="17px" />
                              </Box>
                            )}
                            <Input
                              type="file"
                              id="file-input-2"
                              display="none"
                            />
                          </Box>
                        </Flex>

                        <Flex
                          justify="space-between"
                          w="full"
                          alignItems="flex-end"
                        >
                          <NormalInputButton
                            Heading="First Name"
                            placeholder="First Name"
                            value={spouseFirstName}
                            onChange={(e) => {
                              setSpouseFirstName(e.target.value);
                              setChangedData(true);
                            }}
                            error={errors.spouseFirstName}
                          />
                          <NormalInputButton
                            Heading="Last Name"
                            placeholder="Last Name"
                            value={spouseLastName}
                            onChange={(e) => {
                              setSpouseLastName(e.target.value);
                              setChangedData(true);
                            }}
                            error={errors.spouseLastName}
                          />
                        </Flex>
                        <Flex justify="space-between" w="full">
                          {/* <NormalInputButton
                    Heading="Email"
                    placeholder="Email Address"
                  /> */}
                          <DatePickerButton
                            Heading="Date of Birth"
                            placeholder="xx/xx/xxxx"
                            value={spouseDateOfBirth}
                            onChange={(e) => {
                              setNewUser(true);
                              lockSidebar();
                              setSpouseDateOfBirth(e.target.value);
                              setUserOptiData({});
                              setShowNewOptimization(true);
                              setUserInfo({
                                ...userInfo,
                                spouseDateOfBirth: e.target.value,
                              });
                            }}
                            maxYear={2024}
                            error={errors.spouseDateOfBirth}
                          />
                        </Flex>
                      </VStack>
                    </>
                  ) : (
                    <>
                      <Button
                        onClick={() => getMaritalStatus("married")}
                        bg={""}
                      >
                        <Icon as={AddSpouseIcon} />
                        <Text w={"120px"} alignSelf={"center"}>
                          Add Spouse
                        </Text>
                      </Button>
                    </>
                  )}
                </Flex>
              </VStack>
            }
            videoName={"Profile Page"}
            video={"Profile_Page.mp4"}
          />
        </VStack>
      )}
    </Flex>
  );
});

export default CreateProfileForm;
