import { Flex, Heading, VStack, useMediaQuery, Box } from "@chakra-ui/react";
import { useAuth0 } from "@auth0/auth0-react";
import axios from "axios";
import React, {
  forwardRef,
  useContext,
  useEffect,
  useImperativeHandle,
  useState,
} from "react";
import { useStore } from "../../store";
import { SpouseContext } from "../SpouseContext";
import GlobalBodyPage from "../GlobalContainer/BodyPage";
import DropDownButton from "../InputFields/dropDownButton";
import NormalInputButton from "../InputFields/normalInputButton";
import TooltipContent from "../TooltipContent";
import { error } from "jquery";

const RrspAccount = forwardRef((props, ref) => {
  const { setChangedData } = props;
  useImperativeHandle(ref, () => ({
    submitHandler,
    checkMandatoryFields,
  }));
  const { getAccessTokenSilently } = useAuth0();

  const axiosInstance = axios.create();
  async function getToken() {
    // Replace this with your Auth0 token retrieval logic
    const token = await getAccessTokenSilently({
      audience:
        "https://os2lsiwff43wfpb5ggyawt6qri0mgcro.lambda-url.us-east-2.on.aws/",
      scope: "write:optimization",
    });
    return token;
  }

  // Add a request interceptor
  axiosInstance.interceptors.request.use(
    async (config) => {
      const token = await getToken();
      config.headers.Authorization = `Bearer ${token}`;
      return config;
    },
    (error) => {
      // Do something with request error
      return Promise.reject(error);
    }
  );

  // Spouse 1 Data

  const [hasRrsp, setHasRrsp] = useState();
  const [hasRRIF, setHasRRIF] = useState();
  const [rrsp, setRrsp] = useState("0");
  const [rrspContribution, setRrspContribution] = useState("0");
  const [rateOfReturn, setRateOfReturn] = useState(6);
  const [hasContribution, setHasContribution] = useState("No");
  const [employerContribution, setEmployerContribution] = useState("0");
  // FRINGE CODE Potentially load baring - hopefully employee figure out one day
  const [hasContributionRoom, setHasContributionRoom] = useState("Yes");
  // FRINGE CODE

  // Spouse 2 Data
  const [spouseHasRrsp, setSpouseHasRrsp] = useState();
  const [rrspSpouse, setRrspSpouse] = useState("0");
  const [spouseHasRRIF, setSpouseHasRRIF] = useState();
  const [spouseRrspContribution, setSpouseRrspContribution] = useState("0");
  const [spouseHasContribution, setSpouseHasContribution] = useState("No");
  const [spouseEmployerContribution, setSpouseEmployerContribution] =
    useState("0");
  const [spouseRateOfReturn, setSpouseRateOfReturn] = useState(6);

  const [isLargerThan768] = useMediaQuery("(min-width: 500px)");
  const isMobile = !isLargerThan768;

  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [spouseName, setSpouseName] = useState("");
  const [spouseLastName, setSpouseLastName] = useState("");

  const [DOB, setDOB] = useState("");
  const { spouseStatus, setSpouseStatus } = useContext(SpouseContext);

  const userRRSPInfo = useStore((store) => store.userRRSPInfo);
  const setUserRRSPInfo = useStore((store) => store.setUserRRSPInfo);
  const userInfo = useStore((store) => store.userInfo);
  const userIncomeInfo = useStore((store) => store.userIncomeInfo);

  const [isEditMode, setIsEditMode] = useState(false);

  const userOptiData = useStore((store) => store.userOptiData);
  const [newUser, setNewUser] = useState();

  useEffect(() => {
    if (userOptiData?.primaryUserData?.expenses?.length > 1) {
      setNewUser(false);
    } else if (userOptiData?.primaryUserData?.expenses?.length === undefined) {
      setNewUser(true);
      setIsEditMode(true);
    }
  }, [userOptiData]);

  
  const [errors, setErrors] = useState({});
  const [spouseErrors, setSpouseErrors] = useState({});

  useEffect(() => {
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 100);
  }, [errors, spouseErrors]);

  // Function to toggle edit mode
  const toggleEditMode = () => {
    setIsEditMode(!isEditMode);
  };

  const { user, logout } = useAuth0();

  const currencyFormat = (money) => {
    if (money === 0) {
      return "$0"; // Handle zero explicitly
    } else if (money === "") {
      return "";
    } else {
      const intTotal = Math.round(Math.round(money * 100) / 100);
      const currencyCad = new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "USD",
        minimumFractionDigits: 0,
      }).format(intTotal);
      return currencyCad;
    }
  };

  useEffect(() => {
    if (userRRSPInfo?.RRSP_ROR !== null) {
      setRateOfReturn(userRRSPInfo?.RRSP_ROR ?? 6);
      setSpouseRateOfReturn(userRRSPInfo?.spouseRRSP_ROR ?? 6);
    }
    if (userRRSPInfo !== null) {
      setRrsp(userRRSPInfo?.totalValue);
      setRrspSpouse(userRRSPInfo?.spouseTotalValue);

      setHasRrsp(userRRSPInfo?.Do_You_RRSP);
      setHasRRIF(userRRSPInfo.hasRRIF);
      setRrspContribution(userRRSPInfo?.RRSP_Cont_This_Year);
      setHasContribution(userRRSPInfo?.Does_Employer_RRSP);
      setEmployerContribution(userRRSPInfo?.Employer_Cont_Percent);
      setHasContributionRoom(userRRSPInfo?.RRSP_Cont_Room);
      setRateOfReturn(userRRSPInfo?.RRSP_ROR ?? 6);

      setSpouseHasRrsp(userRRSPInfo?.spouseDo_You_RRSP);
      setSpouseHasRRIF(userRRSPInfo?.spouseHasRRIF);
      setSpouseRrspContribution(userRRSPInfo?.spouseRRSP_Cont_This_Year);
      setSpouseHasContribution(userRRSPInfo?.spouseDoes_Employer_RRSP);
      setSpouseEmployerContribution(userRRSPInfo?.spouseEmployer_Cont_Percent);
      setSpouseRateOfReturn(userRRSPInfo?.spouseRRSP_ROR ?? 6);
    }
    if (userInfo !== null) {
      setSpouseName(userInfo?.spouseFirstName);
      setSpouseLastName(userInfo?.spouseLastName);
      setFirstName(userInfo?.FirstName);
      setLastName(userInfo?.LastName);
      setDOB(userInfo?.DateOfBirth);
    }
  }, []);

  const submitHandler = (e) => {
    // e.preventDefault();
    setIsEditMode(!isEditMode);
    // console.log('RRSP: ', rrsp, hasRrsp, rrspContribution, hasContribution, employerContribution, hasContributionRoom, rateOfReturn);
    let RRSP = rrsp;
    let convertRRIF = hasRRIF;
    let EmployerContribution = employerContribution;
    let RRSPContribution = rrspContribution;
    let SpouseRRSP = rrspSpouse;
    let convertSpouseRRIF = spouseHasRRIF;
    let SpouseEmployerContribution = spouseEmployerContribution;
    let SpouseRRSPContribution = spouseRrspContribution;
    if (hasRrsp == "No") {
      RRSP = 0;
      EmployerContribution = 0;
      RRSPContribution = 0;
      convertRRIF = "No";
      setHasRRIF("No");
      setRrsp(0);
      setEmployerContribution(0);
      setRrspContribution(0);
    }
    if (hasContribution == "No") {
      EmployerContribution = 0;
      setEmployerContribution(0);
    }
    if (spouseHasRrsp == "No") {
      SpouseRRSP = 0;
      SpouseEmployerContribution = 0;
      SpouseRRSPContribution = 0;
      convertSpouseRRIF = "No";
      setSpouseHasRRIF("No");
      setRrspSpouse(0);
      setSpouseEmployerContribution(0);
      setSpouseRrspContribution(0);
    }
    if (spouseHasContribution == "No") {
      SpouseEmployerContribution = 0;
      setSpouseEmployerContribution(0);
    }
    const rrspURL = (action) =>
      `${
        process.env.REACT_APP_NODE_API_GATEWAY
      }/CompassLambdaApi/RRSP/${action}?RRSPID=${user.sub.replace(
        "|",
        "%7c"
      )}&totalValue=${RRSP}&Do_You_RRSP=${hasRrsp}&RRSP_Cont_This_Year=${RRSPContribution}&Does_Employer_RRSP=${hasContribution}&RRSP_Cont_Room=${hasContributionRoom}&RRSP_ROR=${rateOfReturn}&Employer_Cont_Percent=${EmployerContribution}&spouseTotalValue=${SpouseRRSP}&spouseDo_You_RRSP=${spouseHasRrsp}&spouseRRSP_Cont_This_Year=${SpouseRRSPContribution}&spouseDoes_Employer_RRSP=${spouseHasContribution}&spouseRRSP_Cont_Room=${hasContributionRoom}&spouseRRSP_ROR=${spouseRateOfReturn}&spouseEmployer_Cont_Percent=${SpouseEmployerContribution}&hasRRIF=${convertRRIF}&spouseHasRRIF=${convertSpouseRRIF}`;
    axiosInstance
      .get(rrspURL("add"))
      .then((res) => {
        console.log(res);
        setUserRRSPInfo({
          ...userRRSPInfo,
          totalValue: RRSP,
          Do_You_RRSP: hasRrsp,
          hasRRIF: convertRRIF,
          RRSP_Cont_This_Year: RRSPContribution,
          Does_Employer_RRSP: hasContribution,
          RRSP_Cont_Room: hasContributionRoom,
          RRSP_ROR: rateOfReturn,
          Employer_Cont_Percent: EmployerContribution,
          spouseTotalValue: SpouseRRSP,
          spouseDo_You_RRSP: spouseHasRrsp,
          spouseHasRRIF: convertSpouseRRIF,
          spouseRRSP_Cont_This_Year: SpouseRRSPContribution,
          spouseDoes_Employer_RRSP: spouseHasContribution,
          spouseRRSP_Cont_Room: hasContributionRoom,
          spouseRRSP_ROR: spouseRateOfReturn,
          spouseEmployer_Cont_Percent: SpouseEmployerContribution,
        });
        if (res.data.code === "ER_DUP_ENTRY") {
          axiosInstance.get(rrspURL("update")).then((res) => {
            console.log(res);
            setUserRRSPInfo({
              ...userRRSPInfo,
              totalValue: RRSP,
              Do_You_RRSP: hasRrsp,
              hasRRIF: convertRRIF,
              RRSP_Cont_This_Year: RRSPContribution,
              Does_Employer_RRSP: hasContribution,
              RRSP_Cont_Room: hasContributionRoom,
              RRSP_ROR: rateOfReturn,
              Employer_Cont_Percent: EmployerContribution,
              spouseTotalValue: SpouseRRSP,
              spouseDo_You_RRSP: spouseHasRrsp,
              spouseHasRRIF: convertSpouseRRIF,
              spouseRRSP_Cont_This_Year: SpouseRRSPContribution,
              spouseDoes_Employer_RRSP: spouseHasContribution,
              spouseRRSP_Cont_Room: hasContributionRoom,
              spouseRRSP_ROR: spouseRateOfReturn,
              spouseEmployer_Cont_Percent: SpouseEmployerContribution,
            });
          });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };


  const checkMandatoryFields = () => {
    console.log(errors, spouseErrors);
    let newErrors = {};
    let newSpouseErrors = {};

    // Individual checks

    if (!hasRrsp || hasRrsp === "")
      newErrors.hasRrsp = "RRSP status is required";
    if (hasRrsp === "Yes") {
      if (!rrsp) newErrors.rrsp = "RRSP value is required";
      if (!hasRRIF) newErrors.hasRRIF = "RRIF status is required";
      if (
        !hasContribution &&
        userIncomeInfo?.Salary > 0 &&
        userIncomeInfo?.Employment_Type === "Employee" &&
        hasRRIF === "No"
      )
        newErrors.hasContribution = "Contribution status is required";
      if (
        hasContribution === "Yes" &&
        hasRRIF === "No" &&
        (!employerContribution || employerContribution > 10) &&
        userIncomeInfo?.Salary > 0
      )
        newErrors.employerContribution =
          "Employer contribution is required and must be less than 10%";

      if (!rateOfReturn || rateOfReturn > 20)
        newErrors.rateOfReturn =
          "Rate of return is required and must be greater than 0% and less than 20%"
    }

    // Spouse checks
    if (spouseStatus) {
      if (!spouseHasRrsp || spouseHasRrsp === "")
        newSpouseErrors.hasRrsp = "RRSP status is required";
      if (spouseHasRrsp === "Yes") {
        if (!rrspSpouse) newSpouseErrors.rrsp = "RRSP value is required";
        if (!spouseHasRRIF) newSpouseErrors.hasRRIF = "RRIF status is required";
        if (
          !spouseHasContribution &&
          userIncomeInfo?.spouseIncome > 0 &&
          userIncomeInfo?.spouseEmployment_Type === "Employee" &&
          spouseHasRRIF === "No"
        )
          newSpouseErrors.hasContribution = "Contribution status is required";
        if (
          spouseHasContribution === "Yes" &&
          spouseHasRRIF === "No" &&
          spouseEmployerContribution !== false && // Check if spouseEmployerContribution is not false
          (!spouseEmployerContribution || spouseEmployerContribution > 10) &&
          userIncomeInfo?.spouseIncome > 0
        )
          newSpouseErrors.employerContribution =
            "Employer contribution is required and must be less than 10%";
        if (!spouseRateOfReturn || spouseRateOfReturn > 20)
          newSpouseErrors.rateOfReturn =
            "Rate of return is required and must be greater than 0% and less than 20%"
      }
    }

    setSpouseErrors(newSpouseErrors);
    setErrors(newErrors);
    return (
      Object.keys(newErrors).length === 0 &&
      Object.keys(newSpouseErrors).length === 0
    ); // Return true if no errors
  };

  const moneyToNumber = (value) => {
    let numValue = value.replace(/[$,\s]/g, "");
    numValue = Number(numValue);
    return numValue;
  };

  return (
    <Flex
      display="flex"
      flexWrap="wrap" // Enable wrapping
      justifyContent="flex-start"
      alignItems="start"
      width={"100%"}
    >
      {!isMobile && (
        <VStack alignItems={"flex-start"} width={"100%"} mt={-2}>
          <GlobalBodyPage
            Heading="Registered Retirement Savings Plan (RRSP)"
            mainframeContent={
              <>
                <Flex justify="space-between" w="100%">
                  <Heading
                    height={"27px"}
                    alignSelf={"stretch"}
                    color={"#00466D"}
                    fontFamily={"Nunito"}
                    fontSize={"20px"}
                    fontStyle={"normal"}
                    fontWeight={"700"}
                    lineHeight={"normal"}
                  >
                    {firstName} {lastName}
                  </Heading>
                </Flex>
                <Box
                  display={"flex"}
                  width={"100%"}
                  flexDirection={"row"}
                  paddingTop={"10px"}
                  alignItems={"flex-end"}
                  justifyContent={"space-between"}
                  gap={"10px"}
                  flexWrap={"wrap"}
                >
                  <DropDownButton
                    Heading={"Do you have a RRSP/RRIF?"}
                    placeholder={"Select"}
                    dropDownItems={[
                      { key: "Yes", value: "Yes" },
                      { key: "No", value: "No" },
                    ]}
                    value={hasRrsp}
                    onChange={(key) => {
                      setChangedData(true);
                      setHasRrsp(key);
                    }}
                    customWidth={"100%"}
                    error={errors.hasRrsp}
                    tooltipContent={TooltipContent.rrspYesNo}
                  />
                  {hasRrsp === "Yes" && (
                    <DropDownButton
                      Heading={"Has your RRSP been converted to a RRIF?"}
                      placeholder={"Select"}
                      dropDownItems={[
                        { key: "Yes", value: "Yes" },
                        { key: "No", value: "No" },
                      ]}
                      value={hasRRIF}
                      onChange={(key) => {
                        setChangedData(true);
                        setHasRRIF(key);
                      }}
                      customWidth={"100%"}
                      error={errors.hasRRIF}
                      tooltipContent={TooltipContent.rrspToRRIF}
                    />
                  )}
                  {hasRrsp === "Yes" && hasRRIF === "Yes" && (
                    <RrspConverted
                      spouseCurrentValue={rrsp}
                      setSpouseCurrentValue={setRrsp}
                      spouseAnnualReturn={rateOfReturn}
                      setSpouseAnnualReturn={setRateOfReturn}
                      moneyToNumber={moneyToNumber}
                      errors={errors}
                      setChangedData={setChangedData}
                    />
                  )}
                  {hasRrsp === "Yes" && hasRRIF === "No" && (
                    <RrspNotConverted
                      spouseCurrentValue={rrsp}
                      setSpouseCurrentValue={setRrsp}
                      spouseAnnualReturn={rateOfReturn}
                      setSpouseAnnualReturn={setRateOfReturn}
                      spouseContribution={rrspContribution}
                      setSpouseContribution={setRrspContribution}
                      spouseEmployerContributes={hasContribution}
                      setSpouseEmployerContributes={setHasContribution}
                      spouseEmployerContribution={employerContribution}
                      setSpouseEmployerContribution={setEmployerContribution}
                      moneyToNumber={moneyToNumber}
                      errors={errors}
                      salary={userIncomeInfo.Salary}
                      employmentType={userIncomeInfo?.Employment_Type}
                      setChangedData={setChangedData}
                    />
                  )}
                </Box>
              </>
            }
            videoName={"RRSP Page"}
            video={"RRSP.mp4"}
          />
          {spouseStatus && (
            <GlobalBodyPage
              mainframeContent={
                <VStack spacing={4} alignItems={"flex-start"} width={"100%"}>
                  <Flex justify="space-between" w="100%">
                    <Heading
                      height={"27px"}
                      alignSelf={"stretch"}
                      color={"#00466D"}
                      fontFamily={"Nunito"}
                      fontSize={"20px"}
                      fontStyle={"normal"}
                      fontWeight={"700"}
                      lineHeight={"normal"}
                    >
                      {spouseName} {spouseLastName}
                    </Heading>
                  </Flex>
                  <DropDownButton
                    Heading={"Do you have a RRSP/RRIF?"}
                    placeholder={"Select"}
                    dropDownItems={[
                      { key: "Yes", value: "Yes" },
                      { key: "No", value: "No" },
                    ]}
                    value={spouseHasRrsp}
                    onChange={(key) => {
                      setChangedData(true);
                      setSpouseHasRrsp(key);
                    }}
                    customWidth={"100%"}
                    error={spouseErrors.hasRrsp}
                    tooltipContent={TooltipContent.rrspYesNo}
                  />
                  {spouseHasRrsp === "Yes" && (
                    <>
                      <DropDownButton
                        Heading={"Has your RRSP been converted to a RRIF?"}
                        placeholder={"Select"}
                        dropDownItems={[
                          { key: "Yes", value: "Yes" },
                          { key: "No", value: "No" },
                        ]}
                        value={spouseHasRRIF}
                        onChange={(key) => {
                          setChangedData(true);
                          setSpouseHasRRIF(key);
                        }}
                        customWidth={"100%"}
                        error={spouseErrors.hasRRIF}
                        tooltipContent={TooltipContent.rrspToRRIF}
                      />
                      {spouseHasRrsp === "Yes" && spouseHasRRIF === "Yes" && (
                        <RrspConverted
                          spouseCurrentValue={rrspSpouse}
                          setSpouseCurrentValue={setRrspSpouse}
                          spouseAnnualReturn={spouseRateOfReturn}
                          setSpouseAnnualReturn={setSpouseRateOfReturn}
                          moneyToNumber={moneyToNumber}
                          errors={spouseErrors}
                          setChangedData={setChangedData}
                        />
                      )}
                      {spouseHasRrsp === "Yes" && spouseHasRRIF === "No" && (
                        <RrspNotConverted
                          spouseCurrentValue={rrspSpouse}
                          setSpouseCurrentValue={setRrspSpouse}
                          spouseAnnualReturn={spouseRateOfReturn}
                          setSpouseAnnualReturn={setSpouseRateOfReturn}
                          spouseContribution={spouseRrspContribution}
                          setSpouseContribution={setSpouseRrspContribution}
                          spouseEmployerContributes={spouseHasContribution}
                          setSpouseEmployerContributes={
                            setSpouseHasContribution
                          }
                          spouseEmployerContribution={
                            spouseEmployerContribution
                          }
                          setSpouseEmployerContribution={
                            setSpouseEmployerContribution
                          }
                          moneyToNumber={moneyToNumber}
                          errors={spouseErrors}
                          salary={userIncomeInfo.spouseIncome}
                          employmentType={userIncomeInfo?.spouseEmployment_Type}
                          setChangedData={setChangedData}
                        />
                      )}
                    </>
                  )}
                </VStack>
              }
            />
          )}
        </VStack>
      )}
      {isMobile && (
        <VStack alignItems={"flex-start"} width={"100%"}>
          <GlobalBodyPage
            Heading="Registered Retirement Savings Plan (RRSP)"
            mainframeContent={
              <>
                <Flex justify="space-between" w="100%">
                  <Heading
                    height={"27px"}
                    alignSelf={"stretch"}
                    color={"#00466D"}
                    fontFamily={"Nunito"}
                    fontSize={"20px"}
                    fontStyle={"normal"}
                    fontWeight={"700"}
                    lineHeight={"normal"}
                  >
                    {firstName} {lastName}
                  </Heading>
                </Flex>
                <Box
                  display={"flex"}
                  width={"100%"}
                  flexDirection={"row"}
                  alignItems={"flex-end"}
                  justifyContent={"space-between"}
                  gap={"10px"}
                  flexWrap={"wrap"}
                >
                  <DropDownButton
                    Heading={"Do you have a RRSP/RRIF?"}
                    placeholder={"Select"}
                    dropDownItems={[
                      { key: "Yes", value: "Yes" },
                      { key: "No", value: "No" },
                    ]}
                    value={hasRrsp}
                    onChange={(key) => {
                      setChangedData(true);
                      setHasRrsp(key);
                    }}
                    error={errors.hasRrsp}
                    tooltipContent={TooltipContent.rrspYesNo}
                    customWidth={"100%"}
                    MenuCustomWidth={"100%"}
                  />
                  {hasRrsp === "Yes" && (
                    <DropDownButton
                      Heading={"Has your RRSP been converted to a RRIF?"}
                      placeholder={"Select"}
                      dropDownItems={[
                        { key: "Yes", value: "Yes" },
                        { key: "No", value: "No" },
                      ]}
                      value={hasRRIF}
                      onChange={(key) => {
                        setChangedData(true);
                        setHasRRIF(key);
                      }}
                      MenuCustomWidth={"100%"}
                      error={errors.hasRRIF}
                      tooltipContent={TooltipContent.rrspToRRIF}
                    />
                  )}
                  {hasRrsp === "Yes" && hasRRIF === "Yes" && (
                    <RrspConverted
                      spouseCurrentValue={rrsp}
                      setSpouseCurrentValue={setRrsp}
                      spouseAnnualReturn={rateOfReturn}
                      setSpouseAnnualReturn={setRateOfReturn}
                      moneyToNumber={moneyToNumber}
                      errors={errors}
                      setChangedData={setChangedData}
                    />
                  )}

                  {hasRrsp === "Yes" && hasRRIF === "No" && (
                    <RrspNotConverted
                      spouseCurrentValue={rrsp}
                      setSpouseCurrentValue={setRrsp}
                      spouseAnnualReturn={rateOfReturn}
                      setSpouseAnnualReturn={setRateOfReturn}
                      spouseContribution={rrspContribution}
                      setSpouseContribution={setRrspContribution}
                      spouseEmployerContributes={hasContribution}
                      setSpouseEmployerContributes={setHasContribution}
                      spouseEmployerContribution={employerContribution}
                      setSpouseEmployerContribution={setEmployerContribution}
                      moneyToNumber={moneyToNumber}
                      errors={errors}
                      salary={userIncomeInfo.Salary}
                      employmentType={userIncomeInfo?.Employment_Type}
                      setChangedData={setChangedData}
                    />
                  )}
                </Box>
              </>
            }
            videoName={"RRSP Page"}
            video={"RRSP.mp4"}
          />
          {spouseStatus && (
            <GlobalBodyPage
              mainframeContent={
                <VStack spacing={4} alignItems={"flex-start"} width={"100%"}>
                  <Flex justify="space-between" w="100%">
                    <Heading
                      height={"27px"}
                      alignSelf={"stretch"}
                      color={"#00466D"}
                      fontFamily={"Nunito"}
                      fontSize={"20px"}
                      fontStyle={"normal"}
                      fontWeight={"700"}
                      lineHeight={"normal"}
                    >
                      {spouseName} {spouseLastName}
                    </Heading>
                  </Flex>
                  <DropDownButton
                    Heading={"Do you have a RRSP/RRIF?"}
                    placeholder={"Select"}
                    dropDownItems={[
                      { key: "Yes", value: "Yes" },
                      { key: "No", value: "No" },
                    ]}
                    value={spouseHasRrsp}
                    onChange={(key) => {
                      setChangedData(true);
                      setSpouseHasRrsp(key);
                    }}
                    error={spouseErrors.hasRrsp}
                    tooltipContent={TooltipContent.rrspYesNo}
                    customWidth={"100%"}
                    MenuCustomWidth={"100%"}
                  />
                  {spouseHasRrsp === "Yes" && (
                    <VStack>
                      <DropDownButton
                        Heading={"Has your RRSP been converted to a RRIF?"}
                        placeholder={"Select"}
                        dropDownItems={[
                          { key: "Yes", value: "Yes" },
                          { key: "No", value: "No" },
                        ]}
                        value={spouseHasRRIF}
                        onChange={(key) => {
                          setChangedData(true);
                          setSpouseHasRRIF(key);
                        }}
                        customWidth={"100%"}
                        MenuCustomWidth={"100%"}
                        error={spouseErrors.hasRRIF}
                        tooltipContent={TooltipContent.rrspToRRIF}
                      />
                      {spouseHasRrsp === "Yes" && spouseHasRRIF === "Yes" && (
                        <RrspConverted
                          spouseCurrentValue={rrspSpouse}
                          setSpouseCurrentValue={setRrspSpouse}
                          spouseAnnualReturn={spouseRateOfReturn}
                          setSpouseAnnualReturn={setSpouseRateOfReturn}
                          moneyToNumber={moneyToNumber}
                          errors={spouseErrors}
                          setChangedData={setChangedData}
                        />
                      )}
                      {spouseHasRrsp === "Yes" && spouseHasRRIF === "No" && (
                        <RrspNotConverted
                          spouseCurrentValue={rrspSpouse}
                          setSpouseCurrentValue={setRrspSpouse}
                          spouseAnnualReturn={spouseRateOfReturn}
                          setSpouseAnnualReturn={setSpouseRateOfReturn}
                          spouseContribution={spouseRrspContribution}
                          setSpouseContribution={setSpouseRrspContribution}
                          spouseEmployerContributes={spouseHasContribution}
                          setSpouseEmployerContributes={
                            setSpouseHasContribution
                          }
                          spouseEmployerContribution={
                            spouseEmployerContribution
                          }
                          setSpouseEmployerContribution={
                            setSpouseEmployerContribution
                          }
                          moneyToNumber={moneyToNumber}
                          errors={spouseErrors}
                          salary={userIncomeInfo.spouseIncome}
                          employmentType={userIncomeInfo?.spouseEmployment_Type}
                          setChangedData={setChangedData}
                        />
                      )}
                    </VStack>
                  )}
                </VStack>
              }
            />
          )}
        </VStack>
      )}
    </Flex>
  );
});

function RrspConverted({
  spouseCurrentValue,
  setSpouseCurrentValue,
  spouseAnnualReturn,
  setSpouseAnnualReturn,
  moneyToNumber,
  errors,
  setChangedData,
}) {
  const [isLargerThan768] = useMediaQuery("(min-width: 500px)");
  const isMobile = !isLargerThan768;
  return (
    <>
      {isMobile && (
        <>
          <NormalInputButton
            Heading={"What is the current total value?"}
            placeholder={"$"}
            value={spouseCurrentValue}
            onChange={(e) => {
              setChangedData(true);
              setSpouseCurrentValue(moneyToNumber(e.target.value));
            }}
            customWidth={"100%"}
            useThousandSeparator={true}
            InputWidth={"100%"}
            error={errors.rrsp}
            tooltipContent={TooltipContent.rrspTotalValue}
          />
          <NormalInputButton
            Heading={"Expected annual rate of return?"}
            placeholder={"%"}
            value={spouseAnnualReturn}
            onChange={(e) => {
              setChangedData(true);
              const noSymbol = e.target.value.replace("%", "");
              const growthNum = parseFloat(noSymbol);
              setSpouseAnnualReturn(
                !isNaN(growthNum) ? parseFloat(growthNum.toFixed(2)) : ""
              );
            }}
            customWidth={"100%"}
            usePercentage={true}
            InputWidth={"100%"}
            error={errors.rateOfReturn}
            tooltipContent={TooltipContent.rrspGrowth}
          />
        </>
      )}
      {!isMobile && (
        <>
          <NormalInputButton
            Heading={"What is the current total value?"}
            placeholder={"$"}
            value={spouseCurrentValue}
            onChange={(e) => {
              setChangedData(true);
              setSpouseCurrentValue(moneyToNumber(e.target.value));
            }}
            customWidth={"100%"}
            useThousandSeparator={true}
            error={errors.rrsp}
            tooltipContent={TooltipContent.rrspTotalValue}
          />
          <NormalInputButton
            Heading={"Expected annual rate of return?"}
            placeholder={"%"}
            value={spouseAnnualReturn}
            onChange={(e) => {
              setChangedData(true);
              const noSymbol = e.target.value.replace("%", "");
              const growthNum = parseFloat(noSymbol);
              setSpouseAnnualReturn(
                !isNaN(growthNum) ? parseFloat(growthNum.toFixed(2)) : ""
              );
            }}
            usePercentage={true}
            customWidth={"100%"}
            error={errors.rateOfReturn}
            tooltipContent={TooltipContent.rrspGrowth}
          />
        </>
      )}
    </>
  );
}

function RrspNotConverted({
  spouseCurrentValue,
  setSpouseCurrentValue,
  spouseAnnualReturn,
  setSpouseAnnualReturn,
  spouseContribution,
  setSpouseContribution,
  spouseEmployerContributes,
  setSpouseEmployerContributes,
  spouseEmployerContribution,
  setSpouseEmployerContribution,
  moneyToNumber,
  errors,
  salary,
  employmentType,
  setChangedData,
}) {
  const [isLargerThan768] = useMediaQuery("(min-width: 500px)");
  const isMobile = !isLargerThan768;
  return (
    <Flex
      display="flex"
      flexWrap="wrap"
      justifyContent="flex-start"
      alignItems="start"
      width={"100%"}
      gap={"10px"}
    >
      {isMobile && (
        <>
          <NormalInputButton
            Heading={"What is the current total value?"}
            placeholder={"$"}
            value={spouseCurrentValue}
            onChange={(e) => {
              setChangedData(true);
              setSpouseCurrentValue(moneyToNumber(e.target.value));
            }}
            customWidth={"100%"}
            useThousandSeparator={true}
            InputWidth={"100%"}
            error={errors.rrsp}
            tooltipContent={TooltipContent.rrspTotalValue}
          />
          <NormalInputButton
            Heading={"How much have you contributed to your RRSP this year?"}
            placeholder={"$"}
            value={spouseContribution}
            onChange={(e) => {
              setChangedData(true);
              setSpouseContribution(moneyToNumber(e.target.value));
            }}
            customWidth={"100%"}
            useThousandSeparator={true}
            InputWidth={"100%"}
            tooltipContent={TooltipContent.rrspContributionsThisYear}
          />
          {salary > 0 && employmentType === "Employee" && (
            <>
              <DropDownButton
                Heading={"Does your employer contribute to your RRSP?"}
                placeholder={"Select"}
                dropDownItems={[
                  { key: "Yes", value: "Yes" },
                  { key: "No", value: "No" },
                ]}
                value={spouseEmployerContributes}
                onChange={(key) => {
                  setChangedData(true);
                  setSpouseEmployerContributes(key);
                }}
                customWidth={"100%"}
                MenuCustomWidth={"100%"}
                error={errors.hasContribution}
                tooltipContent={TooltipContent.rrspEmployerContributionYesNo}
              />
              {spouseEmployerContributes === "Yes" && (
                <NormalInputButton
                  Heading={"Employer contribution % of salary"}
                  placeholder={"%"}
                  value={spouseEmployerContribution}
                  onChange={(e) => {
                    const noSymbol = e.target.value.replace("%", "");
                    const growthNum = parseFloat(noSymbol);
                    const newValue = !isNaN(growthNum)
                      ? parseFloat(growthNum.toFixed(2))
                      : "";
                    if (newValue <= 10) {
                      setSpouseEmployerContribution(newValue);
                      errors.employerContribution = "";
                    } else if (newValue > 10) {
                      errors.employerContribution =
                        "Employer contribution must be less than 10%";
                      setSpouseEmployerContribution(10);
                    }
                    setChangedData(true);
                  }}
                  customWidth={"100%"}
                  usePercentage={true}
                  InputWidth={"100%"}
                  error={errors.employerContribution}
                  tooltipContent={TooltipContent.rrspEmployerContribution}
                />
              )}
            </>
          )}

          <NormalInputButton
            Heading={"Expected annual rate of return?"}
            placeholder={"%"}
            value={spouseAnnualReturn}
            onChange={(e) => {
              setChangedData(true);
              const noSymbol = e.target.value.replace("%", "");
              const growthNum = parseFloat(noSymbol);
              setSpouseAnnualReturn(
                !isNaN(growthNum) ? parseFloat(growthNum.toFixed(2)) : ""
              );
            }}
            customWidth={"100%"}
            usePercentage={true}
            InputWidth={"100%"}
            error={errors.rateOfReturn}
            tooltipContent={TooltipContent.rrspGrowth}
          />
        </>
      )}
      {!isMobile && (
        <>
          <NormalInputButton
            Heading={"What is the current total value?"}
            placeholder={"$"}
            value={spouseCurrentValue}
            onChange={(e) => {
              setChangedData(true);
              setSpouseCurrentValue(moneyToNumber(e.target.value));
            }}
            customWidth={"100%"}
            useThousandSeparator={true}
            error={errors.rrsp}
            tooltipContent={TooltipContent.rrspTotalValue}
          />
          <NormalInputButton
            Heading={"How much have you contributed to your RRSP this year?"}
            placeholder={"$"}
            value={spouseContribution}
            onChange={(e) => {
              setChangedData(true);
              setSpouseContribution(moneyToNumber(e.target.value));
            }}
            customWidth={"100%"}
            useThousandSeparator={true}
            tooltipContent={TooltipContent.rrspContributionsThisYear}
          />
          {salary > 0 && employmentType === "Employee" && (
            <>
              <DropDownButton
                Heading={"Does your employer contribute to your RRSP?"}
                placeholder={"Select"}
                dropDownItems={[
                  { key: "Yes", value: "Yes" },
                  { key: "No", value: "No" },
                ]}
                value={spouseEmployerContributes}
                onChange={(key) => {
                  setChangedData(true);
                  setSpouseEmployerContributes(key);
                }}
                customWidth={"100%"}
                error={errors.hasContribution}
                tooltipContent={TooltipContent.rrspEmployerContributionYesNo}
              />
              {spouseEmployerContributes === "Yes" && (
                <NormalInputButton
                  Heading={"Employer contribution % of salary"}
                  placeholder={"%"}
                  value={spouseEmployerContribution}
                  onChange={(e) => {
                    setChangedData(true);
                    const noSymbol = e.target.value.replace("%", "");
                    const growthNum = parseFloat(noSymbol);
                    const newValue = !isNaN(growthNum)
                      ? parseFloat(growthNum.toFixed(2))
                      : "";
                    if (newValue <= 10) {
                      setSpouseEmployerContribution(newValue);
                      errors.employerContribution = "";
                    } else if (newValue > 10) {
                      errors.employerContribution =
                        "Employer contribution must be less than 10%";
                      setSpouseEmployerContribution(10);
                    }
                    setChangedData(true);
                  }}
                  customWidth={"100%"}
                  usePercentage={true}
                  error={errors.employerContribution}
                  tooltipContent={TooltipContent.rrspEmployerContribution}
                />
              )}
            </>
          )}

          <NormalInputButton
            Heading={"Expected annual rate of return?"}
            placeholder={"%"}
            value={spouseAnnualReturn}
            onChange={(e) => {
              setChangedData(true);
              const noSymbol = e.target.value.replace("%", "");
              const growthNum = parseFloat(noSymbol);
              setSpouseAnnualReturn(
                !isNaN(growthNum) ? parseFloat(growthNum.toFixed(2)) : ""
              );
            }}
            customWidth={"100%"}
            usePercentage={true}
            error={errors.rateOfReturn}
            tooltipContent={TooltipContent.rrspGrowth}
          />
        </>
      )}
    </Flex>
  );
}
export default RrspAccount;
