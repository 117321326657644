import { SearchIcon } from "@chakra-ui/icons";
import {
  Box,
  HStack,
  Icon,
  Image,
  Input,
  Text,
  useMediaQuery,
  VStack,
} from "@chakra-ui/react";
import GlobalBodyPage from "../GlobalContainer/BodyPage";
import Link from "../Link";
import styles from "./HelpPage.css";
import {
  CallIcon,
  ChatBubbleIcon,
  EmailIcon,
  EndOfAnalysisBalanceIcon,
} from "../CustomIcons";
import OltreChatBubble from "../OltreChatBubble";
import { useState } from "react";

function HelpPage() {
  const helpContent = [
    {
      heading: "How to create a budget",
      content:
        "Creating a budget is the first step to financial freedom. Here are some tips to help you get started.",
      image: "assets/img/COVER_IMAGES/welcome.jpg",
      url: "/dashboard-page",
    },
    {
      heading: "How to save money",
      content:
        "Saving money is important for your future. Here are some tips to help you get started.",
      image: "assets/img/COVER_IMAGES/welcome.jpg",
      url: "/",
    },
    {
      heading: "How to invest",
      content:
        "Investing is the key to building wealth. Here are some tips to help you get started.",
      image: "assets/img/COVER_IMAGES/welcome.jpg",
      url: "/dashboard-page",
    },
    {
      heading: "How to pay off debt",
      content:
        "Paying off debt is the first step to financial freedom. Here are some tips to help you get started.",
      image: "assets/img/COVER_IMAGES/welcome.jpg",
      url: "/",
    },
    {
      heading: "How to build credit",
      content:
        "Building credit is important for your financial future. Here are some tips to help you get started.",
      image: "assets/img/COVER_IMAGES/welcome.jpg",
      url: "/dashboard-page",
    },
    {
      heading: "How to buy a home",
      content:
        "Buying a home is a big decision. Here are some tips to help you get started.",
      image: "assets/img/COVER_IMAGES/welcome.jpg",
      url: "/",
    },
  ];
  const HeaderSearch = () => {
    // useEffect(() => {
    //   if (matches.length > 0) {
    //     const match = matches[currentMatchIndex];
    //     if (itemRefs.current[match.id]) {
    //       const item = itemRefs.current[match.id].current;
    //       if (item) {
    //         item.scrollIntoView({
    //           behavior: "smooth",
    //         });
    //       }
    //     } else {
    //       console.warn(`No ref found for id: ${match.id}`);
    //     }
    //   }
    // }, [currentMatchIndex, matches]);
    return (
      <Box
        display={"flex"}
        justifyContent={"space-between"}
        alignItems={"center"}
        alignSelf={"stretch"}
        flexWrap={"wrap"}
        marginBottom={"10px"}
        w={"-webkit-fill-available"}
      >
        {/* <Text
              color={"#00A2D4"}
              fontFamily={"Nunito"}
              fontSize={"20px"}
              fontStyle={"normal"}
              fontWeight={"700"}
              lineHeight={"normal"}
              textAlign={"center"}
            >
              Video Library
            </Text> */}
        <Box display={"flex"} alignItems={"center"}>
          <Box
            display={"flex"}
            width={"250px"}
            height={"50px"}
            padding={"16px 0px 16px 12px"}
            justifyContent={"flex-end"}
            alignItems={"center"}
            gap={"6px"}
            flexShrink={0}
            borderRadius={"12px"}
            background={"#F3FAFF"}
          >
            <Icon as={SearchIcon} boxSize="12px" color="#004069" />
            <Input
              //   ref={inputRef}
              placeholder="Search for help"
              //   value={searchTerm}
              //   onChange={handleSearchChange}
              border="none"
              height="100%"
              width="100%"
              padding="0 8px"
              _focus={{ boxShadow: "none" }}
              //   onKeyPress={(event) => {
              //     if (event.key === "Enter") {
              //       jumpToContent(searchTerm);
              //     }
              //   }}
            />
          </Box>
          {/* <Button
                // onClick={() => jumpToContent(searchTerm)}
                // disabled={!searchTerm}
              >
                Jump to Content
              </Button> */}
        </Box>
      </Box>
    );
  };

  const [isLargerThan500] = useMediaQuery("(max-width: 500px)");
  const [showOltreChatBubble, setShowOltreChatBubble] = useState(false);

  const toggleOltreChatBubble = () => {
    setShowOltreChatBubble(!showOltreChatBubble);
  };
  return (
    <VStack alignItems={"flex-start"} width={"100%"} mt={-2}>
      <GlobalBodyPage
        Heading={""}
        mainframeContent={
          <VStack padding={"5px 5px 35px 5px"}>
            <HeaderSearch />
            <Box
              display={"flex"}
              justifyContent={"space-between"}
              alignItems={"flex-start"}
              alignContent={"flex-start"}
              rowGap={"40px"}
              alignSelf={"stretch"}
              flexWrap={"wrap"}
            >
              {helpContent?.map((content) => (
                <Link
                  key={content.heading}
                  href={content.url}
                  style={{ textDecoration: "none" }}
                  className="HelpLink"
                >
                  {/*Help Box*/}
                  <Box
                    display={"flex"}
                    maxWidth={isLargerThan500 ? "100%" : "30%"}
                    flexDirection={"column"}
                    alignItems={"flex-start"}
                    gap={"10px"}
                  >
                    {/*Image box*/}
                    <Box
                      width={"100%"}
                      height={"auto"}
                      alignSelf={"stretch"}
                      borderRadius={"5px"}
                      bg={"#D9D9D9"}
                    >
                      <Image // Replace this with your thumbnail component
                        src={content.image} // Replace this with your thumbnail URL
                        minW={"100%"}
                        minH={"100%"}
                      />
                    </Box>
                    <Box
                      display={"flex"}
                      flexDirection={"column"}
                      alignItems={"flex-start"}
                      gap={"5px"}
                      alignSelf={"stretch"}
                    >
                      <Text
                        alignSelf={"stretch"}
                        color={"#979797"}
                        fontFamily={"Nunito"}
                        fontSize={"14px"}
                        fontStyle={"normal"}
                        fontWeight={"600"}
                        lineHeight={"normal"}
                      >
                        # Post
                      </Text>
                      <Text
                        color={"#00A2D4"}
                        fontFamily={"Nunito"}
                        fontSize={"20px"}
                        fontStyle={"normal"}
                        fontWeight={"600"}
                        lineHeight={"normal"}
                      >
                        {content.heading}
                      </Text>
                      <Text
                        color={"#004069"}
                        fontFamily={"Nunito"}
                        fontSize={"16px"}
                        fontStyle={"normal"}
                        fontWeight={"600"}
                        lineHeight={"normal"}
                      >
                        {content.content}
                      </Text>
                    </Box>
                  </Box>
                </Link>
              ))}
              <Box
                display={"flex"}
                flexDirection={"column"}
                alignItems={"center"}
                gap={"25px"}
                alignSelf={"stretch"}
                width={"100%"}
              >
                <Box
                  display={"flex"}
                  width={"100%"}
                  alignItems={"center"}
                  justifyContent={"center"}
                >
                  <Text
                    alignSelf={"stretch"}
                    color={"#00466D"}
                    textAlign={"center"}
                    fontFamily={"Nunito"}
                    fontSize={"24px"}
                    fontStyle={"normal"}
                    fontWeight={"800"}
                    lineHeight={"32px"}
                  >
                    Contact
                  </Text>
                </Box>
                <Box
                  display={"flex"}
                  gap={isLargerThan500 && "20px"}
                  flexDirection={isLargerThan500 ? "column" : "row"}
                  width={"100%"}
                  justifyContent={"space-between"}
                >
                  {/* Email Box */}
                  <a href="mailto:support@optiml.ca" target="_blank">
                    <Box display={"flex"} alignItems={"center"} gap={"10px"}>
                      <Box
                        display={"flex"}
                        width={"56px"}
                        height={"56px"}
                        padding={"14px"}
                        justifyContent={"center"}
                        alignItems={"center"}
                        borderRadius={"12px"}
                        bg={"#E1F5FE"}
                      >
                        <Icon
                          as={EmailIcon}
                          svgwidth={"28px"}
                          svgheight={"28px"}
                        />
                      </Box>
                      <VStack alignItems={"flex-end"}>
                        <Text
                          color={"#004069"}
                          fontFamily={"Nunito"}
                          fontSize={"16px"}
                          fontStyle={"normal"}
                          fontWeight={"900"}
                          lineHeight={"16px"}
                          letterSpacing={"0.5px"}
                          alignSelf={"flex-start"}
                        >
                          Email Support
                        </Text>

                        <Text
                          color={"#004069"}
                          fontFamily={"Nunito"}
                          fontSize={"14px"}
                          fontStyle={"normal"}
                          fontWeight={"400"}
                          lineHeight={"14px"}
                          letterSpacing={"0.5px"}
                        >
                          Send an email to
                          <br />
                          support@optiml.ca
                        </Text>
                      </VStack>
                    </Box>
                  </a>
                  {/* Chatbot Box */}
                  <Box display={"flex"} alignItems={"center"} gap={"10px"} onClick={toggleOltreChatBubble} cursor={"pointer"}>
                    <Box
                      display={"flex"}
                      width={"56px"}
                      height={"56px"}
                      padding={"14px"}
                      justifyContent={"center"}
                      alignItems={"center"}
                      borderRadius={"12px"}
                      bg={"#E1F5FE"}
                    >
                      <Icon
                        as={ChatBubbleIcon}
                        svgwidth={"28px"}
                        svgheight={"28px"}
                      />
                    </Box>
                    <VStack alignItems={"flex-end"}>
                      <Text
                        color={"#004069"}
                        fontFamily={"Nunito"}
                        fontSize={"16px"}
                        fontStyle={"normal"}
                        fontWeight={"900"}
                        lineHeight={"16px"}
                        letterSpacing={"0.5px"}
                        alignSelf={"flex-start"}
                      >
                        Chatbot
                      </Text>

                      <Text
                        color={"#004069"}
                        fontFamily={"Nunito"}
                        fontSize={"14px"}
                        fontStyle={"normal"}
                        fontWeight={"400"}
                        lineHeight={"14px"}
                        letterSpacing={"0.5px"}
                      >
                        {""}
                        <br />
                        Chat with Eva
                      </Text>
                    </VStack>
                    
                  </Box>
                  {/* Call Box */}
                  <Box display={"flex"} alignItems={"center"} gap={"10px"}>
                    <Box
                      display={"flex"}
                      width={"56px"}
                      height={"56px"}
                      padding={"14px"}
                      justifyContent={"center"}
                      alignItems={"center"}
                      borderRadius={"12px"}
                      bg={"#E1F5FE"}
                    >
                      <Icon
                        as={CallIcon}
                        svgwidth={"28px"}
                        svgheight={"28px"}
                      />
                    </Box>
                    <VStack alignItems={"flex-end"}>
                      <Text
                        color={"#004069"}
                        fontFamily={"Nunito"}
                        fontSize={"16px"}
                        fontStyle={"normal"}
                        fontWeight={"900"}
                        lineHeight={"16px"}
                        letterSpacing={"0.5px"}
                        alignSelf={"flex-start"}
                      >
                        Call
                      </Text>

                      <Text
                        color={"#004069"}
                        fontFamily={"Nunito"}
                        fontSize={"14px"}
                        fontStyle={"normal"}
                        fontWeight={"400"}
                        lineHeight={"14px"}
                        letterSpacing={"0.5px"}
                      >
                        {" "}
                        <br />
                        (902) XXX XXXX
                      </Text>
                    </VStack>
                  </Box>
                </Box>
              </Box>
            </Box>
          </VStack>
        }
      />
      {showOltreChatBubble && <OltreChatBubble preSetQuestion={" "}/>}
    </VStack>
  );
}

export default HelpPage;
