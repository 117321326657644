import React, { useState, useEffect } from "react";
import ReactEcharts from "echarts-for-react";
import { useStore } from "../store";
import FilterIcon from "../img/filter-icon.png";
import zIndex from "@mui/material/styles/zIndex";

const currencyFormat = (money) => {
  if (money === 0) {
    return "$0"; // Handle zero explicitly
  } else if (money === "") {
    return "";
  } else {
    const intTotal = Math.round(Math.round(money * 100) / 100);
    const currencyCad = new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD",
      minimumFractionDigits: 0,
    }).format(intTotal);
    return currencyCad;
  }
};

function IncomeLineGraph(props) {
  const {
    title,
    graphClicked,
    SalaryData,
    CPPData,
    OASData,
    PensionData,
    RentalData,
    BusinessData,
    InheritanceData,
    OtherData,
    AssetSaleData,
    totalData,
    currentAge,
    spouseAge,
  } = props;
  const userInfo = useStore((store) => store.userInfo);
  const cppLabel = userInfo?.Province != "QC" ? "CPP" : "QPP";
  const currentYear = new Date().getFullYear();
  const xAxis = SalaryData?.map((item) => item.x);
  const xAxisWithAge = xAxis?.map((year) => {
    const age = currentAge + (year - currentYear);
    const spouse = spouseAge ? spouseAge + (year - currentYear) : "";
    return spouseAge ? `${year}\n(${age} | ${spouse})` : `${year}\n(${age})`;
  });
  const yAxisSalary = SalaryData?.map((item) => item.y);
  const yAxisCPP = CPPData?.map((item) => item.y);
  const yAxisOAS = OASData?.map((item) => item.y);
  const yAxisPension = PensionData?.map((item) => item.y);
  const yAxisRental = RentalData?.map((item) => item.y);
  const yAxisBusiness = BusinessData?.map((item) => item.y);
  const yAxisOther = OtherData?.map((item) => item.y);
  const yAxisAssetSale = AssetSaleData?.map((item) => item.y);
  const yAxisInheritance = InheritanceData?.map((item) => item.y);
  const yAxisTotalPortfolio = totalData?.map((item) => item.y);

  const [showDropdown, setShowDropdown] = useState(false);
  const [showAll, setShowAll] = useState(true);
  const [showSalary, setShowSalary] = useState(false);
  const [showCPP, setShowCPP] = useState(false);
  const [showOAS, setShowOAS] = useState(false);
  const [showPension, setShowPension] = useState(false);
  const [showBusiness, setShowBusiness] = useState(false);
  const [showOther, setShowOther] = useState(false);
  const [showAssetSale, setShowAssetSale] = useState(false);
  const [showInheritance, setShowInheritance] = useState(false);
  const [showRentalProperty, setShowRentalProperty] = useState(false);

  const [showTotal, setShowTotal] = useState(false);
  const [colorScheme, setColorScheme] = useState([
    "#73B879",
    "#30AB9D",
    "#00A2D4",
    "#007EB5",
    "#00466D",
    "#00304A",
    "#001A2A",
    "red",
    "#147984",
  ]);

  useEffect(() => {
    selectGraph(graphClicked);
  }, [graphClicked]);

  useEffect(() => {
    if (showSalary) setColorScheme(["#73B879"]);
    if (showCPP) setColorScheme(["#30AB9D"]);
    if (showOAS) setColorScheme(["#00A2D4"]);
    if (showPension) setColorScheme(["#007EB5"]);
    if (showBusiness) setColorScheme(["#00466D"]);
    if (showOther) setColorScheme(["#00304A"]);
    if (showRentalProperty) setColorScheme(["#001A2A"]);
    if (showTotal) setColorScheme(["#00A2D4"]);
    if (showAssetSale) setColorScheme(["#147984"]);
    if (showInheritance) setColorScheme(["red"]);
    if (showAll)
      setColorScheme([
        "#73B879",
        "#30AB9D",
        "#00A2D4",
        "#007EB5",
        "#00466D",
        "#00304A",
        "#001A2A",
        "red",
        "#147984",
      ]);
  }, [
    showSalary,
    showCPP,
    showOAS,
    showPension,
    showTotal,
    showAll,
    showBusiness,
    showOther,
    showRentalProperty,
    showAssetSale,
    showInheritance,
  ]);

  function numFormatter(num) {
    if (num > 999 && num < 10000) {
      return "$" + (num / 1000).toFixed(1) + "K";
    } else if (num > 9999 && num < 1000000) {
      return "$" + (num / 1000).toFixed(0) + "K";
    } else if (num > 1000000) {
      return "$" + (num / 1000000).toFixed(1) + "M";
    } else if (num < 900) {
      return "$" + num;
    }
  }

  const headers = [
    { name: "Salary", show: SalaryData?.some((obj) => obj.y !== 0) },
    { name: cppLabel, show: CPPData?.some((obj) => obj.y !== 0) },
    { name: "OAS", show: OASData?.some((obj) => obj.y !== 0) },
    { name: "Pension", show: PensionData?.some((obj) => obj.y !== 0) },
    {
      name: "Business Dividends",
      show: BusinessData?.some((obj) => obj.y !== 0),
    },
    { name: "Other Income", show: OtherData?.some((obj) => obj.y !== 0) },
    { name: "Rental Income", show: RentalData?.some((obj) => obj.y !== 0) },
    { name: "Asset Sale", show: AssetSaleData?.some((obj) => obj.y !== 0) },
    { name: "Inheritance", show: InheritanceData?.some((obj) => obj.y !== 0) },
    { name: "Total", show: totalData?.some((obj) => obj.y !== 0) },
  ];

  const dataToRender = headers
    .filter((header) => header.show)
    ?.map((header) => header.name);

  const option = {
    color: colorScheme,
    title: {
      text: window.innerWidth <=500 ? "Overview":title,
      left: "center",
      top: "0%",
      textStyle: {
        lineHeight: window.innerWidth <= 480 && !showAll ? -30 : null,
      },
    },
    tooltip: {
      trigger: "axis",
      axisPointer: {
        type: "shadow", // Use 'line' for X-axis
      },
      formatter: function (params) {
        let result = params[0].axisValueLabel + "<br/>";
        params.forEach(function (item) {
          result +=
            item.marker +
            " " +
            item.seriesName +
            ": " +
            currencyFormat(item.data) +
            "<br/>";
        });
        return result;
      },
    },
    legend: {
      data: dataToRender,
      top: "10%",
      bottom: 100,
      left: window.innerWidth <= 500 && showAll ? 0 : "center",
      right:0,
      selectedMode: false,
      show: window.innerWidth <= 480 && showAll ? false : true,
    },
    toolbox: {
      feature: {
        saveAsImage: {
          iconStyle: {
            borderColor: "#004069",
          },
        },
      },
    },
    grid: {
      left: 3,
      right: 3,
      bottom: "0%",
      containLabel: true,
      zIndex: 0,
    },
    xAxis: [
      {
        type: "category",
        boundaryGap: ["20%", "20%"],
        data: xAxisWithAge ? xAxisWithAge : [],
      },
    ],
    yAxis: [
      {
        type: "value",
        axisLabel: {
          formatter: function (value) {
            return numFormatter(value);
          },
        },
      },
    ],
    series: [
      (showSalary || showAll) &&
        SalaryData?.some((obj) => obj.y !== 0) && {
          name: "Salary",
          type: "bar",
          itemStyle: {
            borderRadius: !showAll ? [5, 5, 0, 0] : "",
          },
          stack: "Total",
          smooth: true,
          areaStyle: {
            color: {
              type: "linear",
              x: 0,
              y: 0,
              x2: 0,
              y2: 1,
              colorStops: [
                {
                  offset: 0,
                  color: "#73B879", // color at 0%
                },
                {
                  offset: 1,
                  color: "#73B879", // color at 100%
                },
              ],
              global: false, // default is false
            },
          },
          emphasis: {
            focus: "series",
          },
          data: yAxisSalary,
        },
      (showCPP || showAll) &&
        CPPData?.some((obj) => obj.y !== 0) && {
          name: cppLabel,
          type: "bar",
          itemStyle: {
            borderRadius: !showAll ? [5, 5, 0, 0] : "",
          },
          stack: "Total",
          smooth: true,
          areaStyle: {
            color: {
              type: "linear",
              x: 0,
              y: 0,
              x2: 0,
              y2: 1,
              colorStops: [
                {
                  offset: 0,
                  color: "#30AB9D", // color at 0%
                },
                {
                  offset: 1,
                  color: "#30AB9D", // color at 100%
                },
              ],
              global: false, // default is false
            },
          },
          emphasis: {
            focus: "series",
          },
          data: yAxisCPP,
        },
      (showOAS || showAll) &&
        OASData?.some((obj) => obj.y !== 0) && {
          name: "OAS",
          type: "bar",
          itemStyle: {
            borderRadius: !showAll ? [5, 5, 0, 0] : "",
          },
          stack: "Total",
          smooth: true,
          areaStyle: {
            color: {
              type: "linear",
              x: 0,
              y: 0,
              x2: 0,
              y2: 1,
              colorStops: [
                {
                  offset: 0,
                  color: "#00A2D4", // color at 0%
                },
                {
                  offset: 1,
                  color: "#00A2D4", // color at 100%
                },
              ],
              global: false, // default is false
            },
          },
          emphasis: {
            focus: "series",
          },
          data: yAxisOAS,
        },
      (showPension || showAll) &&
        PensionData?.some((obj) => obj.y !== 0) && {
          name: "Pension",
          type: "bar",
          itemStyle: {
            borderRadius: !showAll ? [5, 5, 0, 0] : "",
          },
          stack: "Total",
          smooth: true,
          areaStyle: {
            color: {
              type: "linear",
              x: 0,
              y: 0,
              x2: 0,
              y2: 1,
              colorStops: [
                {
                  offset: 0,
                  color: "#007EB5", // color at 0%
                },
                {
                  offset: 1,
                  color: "#007EB5", // color at 100%
                },
              ],
              global: false, // default is false
            },
          },
          emphasis: {
            focus: "series",
          },
          data: yAxisPension,
        },
      (showBusiness || showAll) &&
        BusinessData?.some((obj) => obj.y !== 0) && {
          name: "Business Dividends",
          type: "bar",
          itemStyle: {
            borderRadius: !showAll ? [5, 5, 0, 0] : "",
          },
          stack: "Total",
          smooth: true,
          areaStyle: {
            color: {
              type: "linear",
              x: 0,
              y: 0,
              x2: 0,
              y2: 1,
              colorStops: [
                {
                  offset: 0,
                  color: "#00466D", // color at 0%
                },
                {
                  offset: 1,
                  color: "#00466D", // color at 100%
                },
              ],
              global: false, // default is false
            },
          },
          emphasis: {
            focus: "series",
          },
          data: yAxisBusiness,
        },
      (showOther || showAll) &&
        OtherData?.some((obj) => obj.y !== 0) && {
          name: "Other Income",
          type: "bar",
          itemStyle: {
            borderRadius: !showAll ? [5, 5, 0, 0] : "",
          },
          stack: "Total",
          smooth: true,
          areaStyle: {
            color: {
              type: "linear",
              x: 0,
              y: 0,
              x2: 0,
              y2: 1,
              colorStops: [
                {
                  offset: 0,
                  color: "#00304A", // color at 0%
                },
                {
                  offset: 1,
                  color: "#00304A", // color at 100%
                },
              ],
              global: false, // default is false
            },
          },
          emphasis: {
            focus: "series",
          },
          data: yAxisOther,
        },
      (showRentalProperty || showAll) &&
        RentalData?.some((obj) => obj.y !== 0) && {
          name: "Rental Income",
          type: "bar",
          itemStyle: {
            borderRadius: !showAll ? [5, 5, 0, 0] : "",
          },
          stack: "Total",
          smooth: true,
          areaStyle: {
            color: {
              type: "linear",
              x: 0,
              y: 0,
              x2: 0,
              y2: 1,
              colorStops: [
                {
                  offset: 0,
                  color: "#001A2A", // color at 0%
                },
                {
                  offset: 1,
                  color: "#001A2A", // color at 100%
                },
              ],
              global: false, // default is false
            },
          },
          emphasis: {
            focus: "series",
          },
          data: yAxisRental,
        },
      (showAssetSale || showAll) &&
        AssetSaleData?.some((obj) => obj.y !== 0) && {
          name: "Asset Sale",
          type: "bar",
          itemStyle: {
            borderRadius: !showAll ? [5, 5, 0, 0] : "",
          },
          stack: "Total",
          smooth: true,
          areaStyle: {
            color: {
              type: "linear",
              x: 0,
              y: 0,
              x2: 0,
              y2: 1,
              colorStops: [
                {
                  offset: 0,
                  color: "#147984", // color at 0%
                },
                {
                  offset: 1,
                  color: "#147984", // color at 100%
                },
              ],
              global: false, // default is false
            },
          },
          emphasis: {
            focus: "series",
          },
          data: yAxisAssetSale,
        },
      (showInheritance || showAll) &&
        InheritanceData?.some((obj) => obj.y !== 0) && {
          name: "Inheritance",
          type: "bar",
          itemStyle: {
            borderRadius: !showAll ? [5, 5, 0, 0] : "",
          },
          stack: "Total",
          smooth: true,
          areaStyle: {
            color: {
              type: "linear",
              x: 0,
              y: 0,
              x2: 0,
              y2: 1,
              colorStops: [
                {
                  offset: 0,
                  color: "red", // color at 0%
                },
                {
                  offset: 1,
                  color: "red", // color at 100%
                },
              ],
              global: false, // default is false
            },
          },
          emphasis: {
            focus: "series",
          },
          data: yAxisInheritance,
        },
      showTotal && {
        name: "Total",
        type: "bar",
        itemStyle: {
          borderRadius: !showAll ? [5, 5, 0, 0] : "",
        },
        stack: "Total",
        smooth: true,
        areaStyle: {
          color: {
            type: "linear",
            x: 0,
            y: 0,
            x2: 0,
            y2: 1,
            colorStops: [
              {
                offset: 0,
                color: "#2BD900", // color at 0%
              },
              {
                offset: 1,
                color: "#2BD900", // color at 100%
              },
            ],
            global: false, // default is false
          },
        },
        emphasis: {
          focus: "series",
        },
        data: yAxisTotalPortfolio,
      },
    ],
  };
  const eChartsRef = React.useRef(0);

  function selectGraph(graph) {
    if (graph === "Total") {
      setShowTotal(true);
      setShowAll(false);
    } else setShowTotal(false);
    if (graph === "Salary") {
      setShowSalary(true);
      setShowAll(false);
    } else setShowSalary(false);
    if (graph === cppLabel) {
      setShowCPP(true);
      setShowAll(false);
    } else setShowCPP(false);
    if (graph === "OAS") {
      setShowOAS(true);
      setShowAll(false);
    } else setShowOAS(false);
    if (graph === "Pension") {
      setShowPension(true);
      setShowAll(false);
    } else setShowPension(false);
    if (graph === "Rental") {
      setShowRentalProperty(true);
      setShowAll(false);
    } else setShowRentalProperty(false);
    if (graph === "Business Dividends") {
      setShowBusiness(true);
      setShowAll(false);
    } else setShowBusiness(false);
    if (graph === "Other") {
      setShowOther(true);
      setShowAll(false);
    } else setShowOther(false);
    if (graph === "Asset Sale") {
      setShowAssetSale(true);
      setShowAll(false);
    } else setShowAssetSale(false);
    if (graph === "Inheritance") {
      setShowInheritance(true);
      setShowAll(false);
    } else setShowInheritance(false);
    if (graph === "Overview") {
      setShowAll(true);
    } else setShowAll(false);
  }

  return (
    <div
    className="dashboard-graph-container"
    style={{ border: "none", borderRadius: "none", boxShadow: "none", padding: '0'}}
  >
      <div
        tabIndex={0}
        className="dropdown"
        onClick={() => setShowDropdown(!showDropdown)}
        onBlur={() => setShowDropdown(showDropdown)}
      >
        <img src={FilterIcon} className="filter-icon" alt="filter-icon" />
        {showDropdown && (
          <div className="dropdown-items">
            <ul>
              <div>Display</div>
              <li
                className={showTotal ? "dropdown-item-selected" : undefined}
                onClick={() => selectGraph("Total")}
              >
                Total Income
              </li>
              <li
                className={showAll ? "dropdown-item-selected" : undefined}
                onClick={() => selectGraph("Overview")}
              >
                All
              </li>
              {SalaryData?.some((obj) => obj.y !== 0) && (
                <li
                  className={showSalary ? "dropdown-item-selected" : undefined}
                  onClick={() => selectGraph("Salary")}
                >
                  Salary
                </li>
              )}
              {CPPData?.some((obj) => obj.y !== 0) && (
                <li
                  className={showCPP ? "dropdown-item-selected" : undefined}
                  onClick={() => selectGraph(cppLabel)}
                >
                  {cppLabel}
                </li>
              )}
              {OASData?.some((obj) => obj.y !== 0) && (
                <li
                  className={showOAS ? "dropdown-item-selected" : undefined}
                  onClick={() => selectGraph("OAS")}
                >
                  OAS
                </li>
              )}
              {PensionData?.some((obj) => obj.y !== 0) && (
                <li
                  className={showPension ? "dropdown-item-selected" : undefined}
                  onClick={() => selectGraph("Pension")}
                >
                  Pension
                </li>
              )}
              {RentalData?.some((obj) => obj.y !== 0) && (
                <li
                  className={
                    showRentalProperty ? "dropdown-item-selected" : undefined
                  }
                  onClick={() => selectGraph("Rental")}
                >
                  Rental Income
                </li>
              )}
              {AssetSaleData?.some((obj) => obj.y !== 0) && (
                <li
                  className={
                    showAssetSale ? "dropdown-item-selected" : undefined
                  }
                  onClick={() => selectGraph("Asset Sale")}
                >
                  Asset Sale
                </li>
              )}
              {BusinessData?.some((obj) => obj.y !== 0) && (
                <li
                  className={
                    showBusiness ? "dropdown-item-selected" : undefined
                  }
                  onClick={() => selectGraph("Business Dividends")}
                >
                  Business Dividends
                </li>
              )}
              {InheritanceData?.some((obj) => obj.y !== 0) && (
                <li
                  className={
                    showInheritance ? "dropdown-item-selected" : undefined
                  }
                  onClick={() => selectGraph("Inheritance")}
                >
                  Inheritance
                </li>
              )}
              {OtherData?.some((obj) => obj.y !== 0) && (
                <li
                  className={showOther ? "dropdown-item-selected" : undefined}
                  onClick={() => selectGraph("Other")}
                >
                  Other
                </li>
              )}
            </ul>
          </div>
        )}
      </div>
      <ReactEcharts
        style={{ maxWidth: "100%", height:'300px' }}
        ref={eChartsRef}
        option={option}
        className="portfolio-balance-chart"
        notMerge
      />
    </div>
  );
}

export default IncomeLineGraph;
