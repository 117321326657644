import axios from "axios";

const askCustomGPT = async (userQuestion, accessToken) => {
    try {
        const config = {
            headers: {
                Authorization: `Bearer ${accessToken}`,
                'Content-Type': 'application/json',
            },
        };
        const endpoint = 'https://osprjaknk5srkeh35j6jl63vyq0ihupu.lambda-url.us-east-2.on.aws/';
        const response = await axios.post(endpoint, { message: userQuestion }, config);
        return response.data;
    } catch (error) {
        console.error('Error in askCustomGPT:', error);
        throw error;
    }
};

export default askCustomGPT;
