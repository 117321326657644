import { Box, VStack, Link, HStack, Text, Icon } from "@chakra-ui/react";
import React from "react";

function CustomBox({ item, selectedOption, handleSelect, resetTimer }) {
  return (
    <Box
      as="button"
      borderRadius="5px"
      alignItems={"center"}
      gap={"15px"}
      padding={"5px 2px"}
      width={"200px"}
      display={"flex"}
      background={selectedOption === item.label ? "#C2E5F6" : "transparent"} // Background color based on selection
      _hover={selectedOption !== item.label ? { background: "#E9E9E9" } : {}}
      color="#004069"
      onClick={() => {
        if (typeof item.label === 'string') {
          handleSelect(item.label);
          if (resetTimer) {
            resetTimer();
          }
        }
      }}
      
    >
      <VStack spacing={"10px"} align="flex-start">
        <Link key={item.label} href="#" style={{ textDecoration: "none" }}>
          <HStack justifyContent="center" gap={"9px"} alignItems={"end"}>
            <Box flexShrink={0} objectFit={"contain"}>
              <Icon boxSize={"20px"}>
              {React.isValidElement(item.icon) ? item.icon : null}
              </Icon>
            </Box>
            <Box>
              <Text 
              fontFamily="Nunito"
              fontSize={"16px"}
              fontStyle={"normal"}
              fontWeight={"600"}
              lineHeight={"normal"}
              >{item.label}</Text>
            </Box>
          </HStack>
        </Link>
      </VStack>
    </Box>
  );
}

export default CustomBox;
