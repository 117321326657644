import axios from 'axios';

export const getUserLogin = (user, axiosInstance) => axiosInstance.get(`${process.env.REACT_APP_NODE_API_GATEWAY}/CompassLambdaApi/USERS/UserLogin?UserID=${user?.sub?.replace('|', '%7c')}`).then((res) => res?.data?.data[0]);

export const getUserInfo = (user, axiosInstance) => axiosInstance.get(`${process.env.REACT_APP_NODE_API_GATEWAY}/CompassLambdaApi/USERS/UserInfo?UserID=${user?.sub?.replace('|', '%7c')}`).then((res) => res?.data?.data);

export const  getUserNotificationInfo = (user, axiosInstance) => axiosInstance.get(`${process.env.REACT_APP_NODE_API_GATEWAY}/CompassLambdaApi/NOTIFICATIONS/UserInfo?UserID=${user?.sub?.replace('|', '%7c')}`).then((res) => res?.data?.data[0]);

export const getUserIncomeInfo = (user, axiosInstance) => axiosInstance.get(`${process.env.REACT_APP_NODE_API_GATEWAY}/CompassLambdaApi/INCOME/UserInfo?UserID=${user?.sub?.replace('|', '%7c')}`).then((res) => res?.data?.data[0]);

export const getUserOtherIncomeInfo = (user, axiosInstance) => axiosInstance.get(`${process.env.REACT_APP_NODE_API_GATEWAY}/CompassLambdaApi/INCOME-OTHER/UserInfo?UserID=${user?.sub?.replace('|', '%7c')}`).then((res) => res?.data?.data);

export const getUserLiraInfo = (user, axiosInstance) => axiosInstance.get(`${process.env.REACT_APP_NODE_API_GATEWAY}/CompassLambdaApi/LIRA/UserInfo?UserID=${user?.sub?.replace('|', '%7c')}`).then((res) => res?.data?.data[0]);

export const getUserLifInfo = (user, axiosInstance) => axiosInstance.get(`${process.env.REACT_APP_NODE_API_GATEWAY}/CompassLambdaApi/LIF/UserInfo?UserID=${user?.sub?.replace('|', '%7c')}`).then((res) => res?.data?.data[0]);

export const getUserExpensesInfo = (user, axiosInstance) => axiosInstance.get(`${process.env.REACT_APP_NODE_API_GATEWAY}/CompassLambdaApi/EXPENSES/UserInfo?UserID=${user?.sub?.replace('|', '%7c')}`).then((res) => res?.data?.data[0]);

export const getUserRRSPInfo = (user, axiosInstance) => axiosInstance.get(`${process.env.REACT_APP_NODE_API_GATEWAY}/CompassLambdaApi/RRSP/UserInfo?UserID=${user?.sub?.replace('|', '%7c')}`).then((res) => res?.data?.data[0]);

export const getUserFHSAInfo = (user, axiosInstance) => axiosInstance.get(`${process.env.REACT_APP_NODE_API_GATEWAY}/CompassLambdaApi/FHSA/UserInfo?UserID=${user?.sub?.replace('|', '%7c')}`).then((res) => res?.data?.data[0]);

export const getUserTFSAInfo = (user, axiosInstance) => axiosInstance.get(`${process.env.REACT_APP_NODE_API_GATEWAY}/CompassLambdaApi/TFSA/UserInfo?UserID=${user?.sub?.replace('|', '%7c')}`).then((res) => res?.data?.data[0]);

export const getUserCASHInfo = (user, axiosInstance) => axiosInstance.get(`${process.env.REACT_APP_NODE_API_GATEWAY}/CompassLambdaApi/CASH/UserInfo?UserID=${user?.sub?.replace('|', '%7c')}`).then((res) => res?.data?.data[0]);

export const getUserNonRegInvestmentsInfo = (user, axiosInstance) => axiosInstance.get(`${process.env.REACT_APP_NODE_API_GATEWAY}/CompassLambdaApi/NON_REGISTERED_INVESTMENTS/UserInfo?UserID=${user?.sub?.replace('|', '%7c')}`).then((res) => res?.data?.data[0]);

export const getUserFixedIncomeInfo = (user, axiosInstance) => axiosInstance.get(`${process.env.REACT_APP_NODE_API_GATEWAY}/CompassLambdaApi/FIXED_INCOME/UserInfo?UserID=${user?.sub?.replace('|', '%7c')}`).then((res) => res?.data?.data);

export const getUserOtherAssetsInfo = (user, axiosInstance) => axiosInstance.get(`${process.env.REACT_APP_NODE_API_GATEWAY}/CompassLambdaApi/OTHER_ASSETS/UserInfo?UserID=${user?.sub?.replace('|', '%7c')}`).then((res) => res?.data?.data);

export const getUserInheritanceInfo = (user, axiosInstance) => axiosInstance.get(`${process.env.REACT_APP_NODE_API_GATEWAY}/CompassLambdaApi/INHERITANCE/UserInfo?UserID=${user?.sub?.replace('|', '%7c')}`).then((res) => res?.data?.data);

export const getUserOptiData = (user, axiosInstance) => axiosInstance.get(`${process.env.REACT_APP_NODE_API_GATEWAY}/CompassLambdaApi/JSON_OPTI_DATA/UserInfo?OPTI_DATA_ID=${user?.sub?.replace('|', '%7c')}`).then((res) => res?.data?.data[0]);

export const getUserInsightsData = (user, axiosInstance) => axiosInstance.get(`${process.env.REACT_APP_NODE_API_GATEWAY}/CompassLambdaApi/INSIGHTS/UserInfo?insightsID=${user?.sub?.replace('|', '%7c')}`).then((res) => res?.data?.data[0]);

export const getUserWealthicaData = (user, axiosInstance) => axiosInstance.get(`${process.env.REACT_APP_NODE_API_GATEWAY}/CompassLambdaApi/WEALTHICA_DATA/UserInfo?idWEALTHICA_DATA=${user?.sub?.replace('|', '%7c')}`).then((res) => res?.data?.data[0]);

export const getUserDashboardLayout = (user, axiosInstance) => axiosInstance.get(`${process.env.REACT_APP_NODE_API_GATEWAY}/CompassLambdaApi/DASHBOARD_STORE/Dashboard_List?UserID=google-oauth2%7c101623852700674488863`).then((res) => res?.data?.data);

export const getUserDebtInfo = (user, axiosInstance) => axiosInstance.get(`${process.env.REACT_APP_NODE_API_GATEWAY}/CompassLambdaApi/DEBT/UserInfo?UserID=${user?.sub?.replace('|', '%7c')}`).then((res) => res?.data?.data)

export const getUserGoalInfo = (user, axiosInstance) => axiosInstance.get(`${process.env.REACT_APP_NODE_API_GATEWAY}/CompassLambdaApi/GOALS/UserInfo?UserID=${user?.sub?.replace('|', '%7c')}`).then((res) => res?.data?.data)

export const getUserPensionInfo = (user, axiosInstance) => axiosInstance.get(`${process.env.REACT_APP_NODE_API_GATEWAY}/CompassLambdaApi/PENSION/UserInfo?UserID=${user?.sub?.replace('|', '%7c')}`).then((res) => res?.data?.data)

export const getUserPrimaryResidenceInfo = (user, axiosInstance) => axiosInstance.get(`${process.env.REACT_APP_NODE_API_GATEWAY}/CompassLambdaApi/REAL_ESTATE/UserInfo?UserID=${user?.sub?.replace('|', '%7c')}`).then((res) => res?.data?.data)

export const getUserCottageInfo = (user, axiosInstance) => axiosInstance.get(`${process.env.REACT_APP_NODE_API_GATEWAY}/CompassLambdaApi/COTTAGE/UserInfo?UserID=${user?.sub?.replace('|', '%7c')}`).then((res) => res?.data?.data)

export const getUserPropertiesInfo = (user, axiosInstance) => axiosInstance.get(`${process.env.REACT_APP_NODE_API_GATEWAY}/CompassLambdaApi/PROPERTIES/UserInfo?UserID=${user?.sub?.replace('|', '%7c')}`).then((res) => res?.data?.data);

export const getUserLandInfo = (user, axiosInstance) => axiosInstance.get(`${process.env.REACT_APP_NODE_API_GATEWAY}/CompassLambdaApi/LAND/UserInfo?UserID=${user?.sub?.replace('|', '%7c')}`).then((res) => res?.data?.data)

export const getUserBusinessInfo = (user, axiosInstance) => axiosInstance.get(`${process.env.REACT_APP_NODE_API_GATEWAY}/CompassLambdaApi/BUSINESS/UserInfo?UserID=${user?.sub?.replace('|', '%7c')}`).then((res) => res?.data?.data)

export const getUserCompanyInfo = (user, axiosInstance) => axiosInstance.get(`${process.env.REACT_APP_NODE_API_GATEWAY}/CompassLambdaApi/COMPANY/UserInfo?UserID=${user?.sub?.replace('|', '%7c')}`).then((res) => res?.data?.data)

export const getUserLifeInsuranceInfo = (user, axiosInstance) => axiosInstance.get(`${process.env.REACT_APP_NODE_API_GATEWAY}/CompassLambdaApi/LIFE_INSURANCE/UserInfo?UserID=${user?.sub?.replace('|', '%7c')}`).then((res) => res?.data?.data)

export const getUserPageLockInfo = (user, axiosInstance) => axiosInstance.get(`${process.env.REACT_APP_NODE_API_GATEWAY}/CompassLambdaApi/NEW_USER_PAGE_LOCK/UserInfo?UserID=${user?.sub?.replace('|', '%7c')}`).then((res) => res?.data?.data)

export const getUserShowDashVideo = (user, axiosInstance) => axiosInstance.get(`${process.env.REACT_APP_NODE_API_GATEWAY}/CompassLambdaApi/SHOW_VIDEO/UserInfo?UserID=${user?.sub?.replace('|', '%7c')}`).then((res) => res?.data?.data?.[0])

export const getUserArchiveInfo = (user, axiosInstance) => axiosInstance.get(`${process.env.REACT_APP_NODE_API_GATEWAY}/CompassLambdaApi/ANALYSIS_ARCHIVE/UserInfo?UserID=${user?.sub?.replace('|', '%7c')}`).then((res) => res?.data?.data)

export const getLogoutMultiplier = (user, axiosInstance) => axiosInstance.get(`${process.env.REACT_APP_NODE_API_GATEWAY}/CompassLambdaApi/SETTINGS/get?UserID=${user?.sub?.replace('|', '%7c')}`).then((res) => res?.data?.data[0])