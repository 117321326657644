import React, { useState } from "react";
import axios from "axios";
import "./EmailVerify.css"; // Import the CSS file for styling
import OltreLogo from "../../img/Beta logo.svg";
import { OptimlBetaIcon } from "../CustomIcons";
import { useAuth0 } from "@auth0/auth0-react";

function EmailVerify() {
  const { user, getAccessTokenSilently } = useAuth0();
  const [message, setMessage] = useState("");

  const axiosInstance = axios.create();
  async function getToken() {
    // Replace this with your Auth0 token retrieval logic
    const token = await getAccessTokenSilently({
      audience:
        "https://os2lsiwff43wfpb5ggyawt6qri0mgcro.lambda-url.us-east-2.on.aws/",
      scope: "write:optimization",
    });
    return token;
  }
  // Add a request interceptor
  axiosInstance.interceptors.request.use(
    async (config) => {
      const token = await getToken();
      config.headers.Authorization = `Bearer ${token}`;
      return config;
    },
    (error) => {
      // Do something with request error
      return Promise.reject(error);
    }
  );

  const handleResendVerification = async () => {
    const auth0_params = {
      user_id: user.sub,
      action: "Send_Email_Verification",
    };
    try {
      axiosInstance
        .post(process.env.REACT_APP_AUTH0_MANAGEMENT_API, auth0_params)
        .then((response) => {
          if (response.ok) {
            setMessage(
              "Verification email sent successfully. Please check your inbox."
            );
          } else {
            setMessage(
              "Failed to resend verification email. Please try again later and make sure your email is valid."
            );
          }
        })
        .catch((error) => {
          setMessage(
            "Failed to resend verification email. Please try again later."
          );
          console.log(error);
      })
    } catch (error) {
      setMessage("An error occurred. Please try again later.");
      console.error("Error resending verification email:", error);
    }
  };

  return (
    <div className="email-verify-container">
      <div className="content-verify">
        {/* <img src={OltreLogo} alt="Logo" className="logo-verify" /> */}
        <OptimlBetaIcon />
        <h1 className="h1-verify">Email Verification Needed</h1>
        <p className="p-verify">
          Please check your email, including your junk or spam folder, for a
          message with a verification link. We’ve sent it to you to verify your
          email address.
        </p>
        <button className="resend-button" onClick={handleResendVerification}>
          Resend Verification Email
        </button>
        {message && <p className="message">{message}</p>}
      </div>
    </div>
  );
}

export default EmailVerify;
