import { useAuth0 } from "@auth0/auth0-react";
import { faCircleXmark } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from "axios";
import isEqual from "lodash/isEqual";
import React, { useContext, useState, useEffect } from "react";
import Modal from "react-modal";
import { useStore } from "../../store";
import Link from "../Link";
import { SpouseContext } from "../SpouseContext";
import "./saveAnalysisModal.css";
import { Button, Icon, Text, useMediaQuery } from "@chakra-ui/react";
import { CheckIcon, CloseIcon } from "../CustomIcons";
import { transform } from "lodash";
Modal.setAppElement("#root"); // Set the root element for accessibility
// Custom CSS directly in the same file

const isMobile = window.innerWidth <= 1200;
const modalStyles = {
  overlay: {
    zIndex: 1000,
    backgroundColor: "rgba(0, 0, 0, 0)",
    display: "flex",
    alignItems: "stretch",
    justifyContent: "center",
    top: "55%",
    left: "55%",
    transform: isMobile ? "translate(-61%, -50%)" : "translate(-50%, -50%)",
  },
  content: {
    inset: "auto", // Override default inset
    border: "1px solid #00A2D4",
    position: "fixed",
    backgroundColor: "#FFF",
    borderRadius: "10px",
    padding: "0px",
    display: "flex",
    flexDirection: "column",
    alignItems: "stretch",
    width: isMobile ? "175%" : "80%", // Adjust width for mobile
    maxWidth: isMobile ? "" : "600px", // Maximum width for mobile
    maxHeight: isMobile ? "80vh" : "auto", // Maximum height for mobile
  },
};
const SaveAnalysisModal = ({
  isOpen,
  closeModal,
  saveAnalysis,
  remainingSaves,
  optiList,
  spouseOptiList,
  launch,
  closeModalX,
}) => {
  const [name, setName] = useState("");
  const [saveThisAnalysis, setSaveThisAnalysis] = useState("");
  const [yesClicked, setYesClicked] = useState(false);
  const userArchiveInfo = useStore((store) => store.userArchiveInfo);
  const { spouseStatus, setSpouseStatus } = useContext(SpouseContext);
  const userInfo = useStore((store) => store.userInfo);

  const userOptiData = useStore((store) => store.userOptiData);
  const userInsightsData = useStore((store => store.userInsightsData));

  const setUserArchiveInfo = useStore((store) => store.setUserArchiveInfo);
  const [confirmSaved, setConfirmSaved] = useState("Confirm");

  const { getAccessTokenSilently } = useAuth0();
  const { user } = useAuth0();

  const axiosInstance = axios.create();
  async function getToken() {
    // Replace this with your Auth0 token retrieval logic
    const token = await getAccessTokenSilently({
      audience:
        "https://os2lsiwff43wfpb5ggyawt6qri0mgcro.lambda-url.us-east-2.on.aws/",
      scope: "write:optimization",
    });
    return token;
  }

  // Add a request interceptor
  axiosInstance.interceptors.request.use(
    async (config) => {
      const token = await getToken();
      config.headers.Authorization = `Bearer ${token}`;
      return config;
    },
    (error) => {
      // Do something with request error
      return Promise.reject(error);
    }
  );

  const [spousePrefix, setSpousePrefix] = useState("sps1");

  const currentYear = new Date();

  const [localError, setLocalError] = useState("");

  const onInputChange = (event) => {
    const nameValue = event.target.value;

    if (event.target.value.length <= 30) {
      setLocalError("");
      setName(nameValue);
    } else {
      setLocalError("Maximum characters of 30 exceeded");
    }
  };

  function checkIfAnalysisIsSaved() {
    return userArchiveInfo.some((item) => {
      if (spouseStatus) {
        return (
          isEqual(
            optiList?.sps1_nonregisteredWithdrawals,
            JSON.parse(item?.Archive_Opti_Data)?.sps1_nonregisteredWithdrawals
          ) &&
          isEqual(
            spouseOptiList?.sps2_nonregisteredWithdrawals,
            JSON.parse(item?.Archive_Spouse_Opti_Data)
              ?.sps2_nonregisteredWithdrawals
          ) &&
          isEqual(
            optiList?.sps1_provincialTaxes,
            JSON.parse(item?.Archive_Opti_Data)?.sps1_provincialTaxes
          ) &&
          isEqual(
            optiList?.optimizedEOLAmount,
            JSON.parse(item?.Archive_Opti_Data)?.optimizedEOLAmount
          )
        );
      } else {
        return (
          isEqual(
            optiList?.sps1_nonregisteredWithdrawals,
            JSON.parse(item?.Archive_Opti_Data)?.sps1_nonregisteredWithdrawals
          ) &&
          isEqual(
            optiList?.sps1_provincialTaxes,
            JSON.parse(item?.Archive_Opti_Data)?.sps1_provincialTaxes
          ) &&
          isEqual(
            optiList?.optimizedEOLAmount,
            JSON.parse(item?.Archive_Opti_Data)?.optimizedEOLAmount
          )
        );
      }
    });
  }
  function getAge(birthday, currentDate) {
    // Convert the birthday string into a Date object
    const birthDate = new Date(birthday);

    // Calculate the age in years
    let age = currentDate?.getFullYear() - birthDate?.getFullYear();

    // Decrement the age if the current month and day are before the birth month and day
    if (
      currentDate?.getMonth() < birthDate?.getMonth() ||
      (currentDate?.getMonth() === birthDate?.getMonth() &&
        currentDate?.getDate() < birthDate?.getDate())
    ) {
      age -= 1;
    }

    return age;
  }

  function formatDate(dateString) {
    const date = new Date(dateString);
    const options = { month: "long", day: "numeric", year: "numeric" };
    const formatted = date?.toLocaleDateString("en-US", options);

    const day = date?.getDate();
    let suffix = "th";
    if (day === 1 || day === 21 || day === 31) {
      suffix = "st";
    } else if (day === 2 || day === 22) {
      suffix = "nd";
    } else if (day === 3 || day === 23) {
      suffix = "rd";
    }

    const dayWithSuffix = day + suffix;

    return formatted.replace(day.toString(), dayWithSuffix);
  }

  // Function to get the current run date in ISO format
  const setRunDate = () => {
    if (optiList?.planRunDate !== null) {
      const dateOfPlan = new Date(optiList?.planRunDate);
      // console?.log(dateOfPlan.toLocaleDateString());
      return formatDate(optiList?.planRunDate);
    } else {
      const today = new Date();
      return formatDate(today);
    }
  };

  // Function to safely add multiple values (handling null/undefined values)
  const safeAdd = (...values) =>
    values.reduce((sum, value) => sum + (value || 0), 0);

  const [isSubmitting, setIsSubmitting] = useState(false);

  // Function to handle the save operation for analysis
  const onSubmitAdd = (e) => {
    e.preventDefault();
    if (isSubmitting) return;
    setIsSubmitting(true);
    setConfirmSaved("Saved");
    const year = new Date().getFullYear();
    const finalYear =
      year + (userInfo?.runAge - getAge(userInfo?.DateOfBirth, currentYear));
    const analysisDuration = `${year} - ${finalYear}`;
    const spouseOneData = JSON.stringify(optiList);
    const spouseTwoData = spouseOptiList
      ? JSON.stringify(spouseOptiList)
      : null;

    const analysisObject = {
      Archive_ID: user.sub,
      Archive_Strategy: optiList?.optimizationType,
      Archive_Estate: safeAdd(
        optiList?.optimizedEOLAmount,
        optiList?.PrimaryResidence?.Value?.[
          optiList?.PrimaryResidence?.Value?.length - 1
        ],
        optiList?.businessYearlyValue?.[
          optiList?.businessYearlyValue?.length - 1
        ],
        optiList?.[`${spousePrefix}_pension`]?.Value?.[
          optiList?.[`${spousePrefix}_pension`]?.Value?.length - 1
        ],
        spouseOptiList?.sps2_pension?.Value?.[
          spouseOptiList?.sps2_pension?.Value?.length - 1
        ],
        optiList?.IncomePropertyYearlyValue?.[
          optiList?.IncomePropertyYearlyValue?.length - 1
        ],
        optiList?.RecreationPropertyYearlyValue?.[
          optiList?.RecreationPropertyYearlyValue?.length - 1
        ],
        optiList?.LandYearlyValue?.[
          optiList?.LandYearlyValue
            ?.length - 1
        ],
        optiList?.lifeInsuranceYearlyValue?.[
          optiList?.lifeInsuranceYearlyValue?.length - 1
        ],
        optiList?.otherAssetsYearlyValue?.[
          optiList?.otherAssetsYearlyValue?.length - 1
        ]
      ),
      Archive_Date: setRunDate(),
      Archive_Opti_Data: spouseOneData,
      Archive_Spouse_Opti_Data: spouseTwoData,
      Archive_Name: name,
      Archive_Duration: analysisDuration,
      Archive_ROT: JSON.stringify(userOptiData?.spouse1_ROT),
      Archive_Spouse_ROT: JSON.stringify(userOptiData?.spouse2_ROT),
      Archive_Retire_Early: JSON.stringify(userInsightsData?.retireEarly),
      Archive_Retire_Late: JSON.stringify(userInsightsData?.retireLate),
    };

    const archiveURL = `${process.env.REACT_APP_NODE_API_GATEWAY}/CompassLambdaApi/ANALYSIS_ARCHIVE/add`;
    axiosInstance
      .post(archiveURL, analysisObject)
      .then((res) => {
        console.log('HERE: ', res);
        const newData = [
          {
            Archive_Strategy: optiList?.optimizationType,
            Archive_Estate: safeAdd(
              optiList?.optimizedEOLAmount,
              optiList?.PrimaryResidence?.Value?.[
                optiList?.PrimaryResidence?.Value?.length - 1
              ],
              optiList?.businessYearlyValue?.[
                optiList?.businessYearlyValue?.length - 1
              ],
              optiList?.[`${spousePrefix}_pension`]?.Value?.[
                optiList?.[`${spousePrefix}_pension`]?.Value?.length - 1
              ],
              spouseOptiList?.sps2_pension?.Value?.[
                spouseOptiList?.sps2_pension?.Value?.length - 1
              ],
              optiList?.IncomePropertyYearlyValue?.[
                optiList?.IncomePropertyYearlyValue?.length - 1
              ],
              optiList?.RecreationPropertyYearlyValue?.[
                optiList?.RecreationPropertyYearlyValue?.length - 1
              ],
              optiList?.LandYearlyValue?.[
                optiList?.LandYearlyValue
                  ?.length - 1
              ],
              optiList?.lifeInsuranceYearlyValue?.[
                optiList?.lifeInsuranceYearlyValue?.length - 1
              ],
              optiList?.otherAssetsYearlyValue?.[
                optiList?.otherAssetsYearlyValue?.length - 1
              ]
            ),
            Archive_Date: setRunDate(),
            Archive_Opti_Data: spouseOneData,
            Archive_Spouse_Opti_Data: spouseTwoData,
            Archive_Number: res.data.lastInsertedId,
            Archive_Name: name,
            Archive_Duration: analysisDuration,
            Archive_ROT: JSON.stringify(userOptiData?.spouse1_ROT),
            Archive_Spouse_ROT: JSON.stringify(userOptiData?.spouse2_ROT),
            Archive_Retire_Early: JSON.stringify(userInsightsData?.retireEarly),
            Archive_Retire_Late: JSON.stringify(userInsightsData?.retireLate),
          },
        ];
        setUserArchiveInfo(newData);
        setConfirmSaved("Saved");
        setIsSubmitting(false);
      })
      .catch((error) => {
        console.log(error);
        setIsSubmitting(false);
      });
  };

  useEffect(() => {
    setConfirmSaved("Confirm");
    setName("");
    setSaveThisAnalysis("");
  }, []);

  return (
    <>
      <Modal
        isOpen={isOpen}
        onRequestClose={closeModal}
        contentLabel="Help Video"
        style={modalStyles}
      >
        <div
          className="close-btn"
          onClick={closeModalX}
          style={{ cursor: "pointer" }}
        >
          <CloseIcon svgheight={"20px"} svgwidth={"20px"} />
        </div>

        <div className="save-analysis-modal-title-container">
          <Text
            color={"#0093D0"}
            fontFamily={"Nunito"}
            fontSize={"24px"}
            fontStyle={"normal"}
            fontWeight={"600"}
            lineHeight={"normal"}
            textDecorationLine={"underline"}
          >
            Save my analysis
          </Text>
          <Text
            color={"#004069"}
            textAlign={"center"}
            fontFamily={"Nunito"}
            fontSize={"14px"}
            fontStyle={"normal"}
            fontWeight={"700"}
            lineHeight={"normal"}
          >
            Available saves remaining:
            <Text
              as={"span"}
              color={"#0093D0"}
              fontFamily={"Nunito"}
              fontSize={"14px"}
              fontStyle={"normal"}
              fontWeight={"700"}
              lineHeight={"normal"}
            >
              {"  "}
              {remainingSaves}
            </Text>
          </Text>
          <Text
            alignSelf={"stretch"}
            color={"#004069"}
            textAlign={"center"}
            fontFamily={"Nunito"}
            fontSize={"12px"}
            fontStyle={"normal"}
            fontWeight={"600"}
            lineHeight={"normal"}
          >
            Save your current analysis to your account for future access,
            allowing you to revisit and review it anytime while still having the
            flexibility to run different or alternative analyses.
          </Text>

          {/* COMING FROM DASHBOARD, NOT LAUNCHING ARCHIVE */}
          {!checkIfAnalysisIsSaved() && confirmSaved != "Saved" && !launch && (
            <div className="input-container-archive">
              <div className="input-container-archive">
                <label
                  className="input-label-archive"
                  style={{
                    textAlign: "center",
                    color: "#004069",
                    fontSize: "14px",
                    fontStyle: "normal",
                    fontWeight: "700",
                    lineHeight: "normal",
                    fontFamily: "Nunito",
                  }}
                >
                  Name of saved analysis
                </label>
                <input
                  type="text"
                  style={{ minWidth: "20rem" }}
                  id="savedAnalysisName"
                  className="analysis-name-input"
                  placeholder="Enter name here"
                  maxLength={30}
                  value={name}
                  onChange={onInputChange}
                  bg={localError ? "#FFD2D2" : "#EDF2F7"} // Change background color on error
                  border={localError ? "1px solid #E53E3E" : "none"} // Optional: add border color on error
                />
              </div>

              {localError && (
                <Text color="#E53E3E" fontSize="sm">
                  {localError}
                </Text> // Display error message
              )}
            </div>
          )}
          {checkIfAnalysisIsSaved() && confirmSaved != "Saved" && (
            <>
              <h3 className="save-analysis-already-saved">
                You have already saved this analysis in your archive
              </h3>
            </>
          )}
          {name && !launch && (
            <>
              {confirmSaved != "Saved" && (
                <Text
                  color={"#004069"}
                  textAlign={"center"}
                  fontFamily={"Nunito"}
                  fontSize={"14px"}
                  fontStyle={"normal"}
                  fontWeight={"700"}
                  lineHeight={"normal"}
                >
                  Save
                </Text>
              )}
              {confirmSaved != "Saved" && (
                <div className="decision-buttons-container">
                  <Button
                    display={"flex"}
                    padding={"5px 30px"}
                    alignItems={"center"}
                    gap={"10px"}
                    background={"#00A2D4"}
                    borderRadius={"100px"}
                    border={"1px solid #00A2D4"}
                    className={`decision-button yes-button ${
                      yesClicked ? "yes-clicked" : ""
                    }`}
                    onClick={(e) => {
                      setYesClicked(true);
                      onSubmitAdd(e);
                      setSaveThisAnalysis("Yes");
                    }}
                  >
                    <Text
                      color={"#FFF"}
                      fontFamily={"Nunito"}
                      fontSize={"14px"}
                      fontStyle={"normal"}
                      fontWeight={"800"}
                      lineHeight={"normal"}
                    >
                      Yes
                    </Text>
                  </Button>
                  <Button
                    display={"flex"}
                    padding={"5px 30px"}
                    alignItems={"center"}
                    gap={"10px"}
                    background={"#FFF"}
                    borderRadius={"100px"}
                    border={"1px solid #00A2D4"}
                    className="decision-button no-button"
                    onClick={() => {
                      setSaveThisAnalysis("No");
                      closeModal();
                      setYesClicked(false);
                    }}
                  >
                    <Text
                      color={"#00A2D4"}
                      fontFamily={"Nunito"}
                      fontSize={"14px"}
                      fontStyle={"normal"}
                      fontWeight={"800"}
                      lineHeight={"normal"}
                    >
                      No
                    </Text>
                  </Button>
                </div>
              )}
            </>
          )}
          {/* ARCHIVE LAUNCHING */}
          {confirmSaved != "Saved" && launch && (
            <div className="decision-buttons-container">
              <Button
                display={"flex"}
                padding={"5px 30px"}
                alignItems={"center"}
                gap={"10px"}
                background={"#FFF"}
                borderRadius={"100px"}
                border={"1px solid #00A2D4"}
                className={`decision-button yes-button ${
                  yesClicked ? "yes-clicked" : ""
                }`}
                onClick={() => {
                  setYesClicked(true);
                  setSaveThisAnalysis("Yes");
                }}
              >
                <Text
                  color={yesClicked ? "white" : "#00A2D4"}
                  fontFamily={"Nunito"}
                  fontSize={"14px"}
                  fontStyle={"normal"}
                  fontWeight={"800"}
                  lineHeight={"normal"}
                >
                  Yes
                </Text>
              </Button>
              <Button
                display={"flex"}
                padding={"5px 30px"}
                alignItems={"center"}
                gap={"10px"}
                background={"#FFF"}
                borderRadius={"100px"}
                border={"1px solid #00A2D4"}
                className="decision-button no-button"
                onClick={() => {
                  setSaveThisAnalysis("No");
                  closeModal();
                  setYesClicked(false);
                }}
              >
                <Text
                  color={"#00A2D4"}
                  fontFamily={"Nunito"}
                  fontSize={"14px"}
                  fontStyle={"normal"}
                  fontWeight={"800"}
                  lineHeight={"normal"}
                >
                  No
                </Text>
              </Button>
            </div>
          )}
          {!checkIfAnalysisIsSaved() &&
            confirmSaved != "Saved" &&
            launch &&
            saveThisAnalysis === "Yes" && (
              <div className="input-container-archive">
                <div className="input-container-archive">
                  <label
                    className="input-label-archive"
                    style={{
                      textAlign: "center",
                      color: "#004069",
                      fontSize: "14px",
                      fontStyle: "normal",
                      fontWeight: "700",
                      lineHeight: "normal",
                      fontFamily: "Nunito",
                    }}
                  >
                    Name of saved analysis
                  </label>
                  <input
                    type="text"
                    style={{ minWidth: "20rem" }}
                    id="savedAnalysisName"
                    className="analysis-name-input"
                    placeholder="Enter name here"
                    maxLength={30}
                    value={name}
                    onChange={onInputChange}
                    bg={localError ? "#FFD2D2" : "#EDF2F7"} // Change background color on error
                    border={localError ? "1px solid #E53E3E" : "none"} // Optional: add border color on error
                  />
                </div>

                {localError && (
                  <Text color="#E53E3E" fontSize="sm">
                    {localError}
                  </Text> // Display error message
                )}
              </div>
            )}
          {name && launch && (
            <Button
              display={"inline-flex"}
              padding={"10px 30px"}
              alignItems={"center"}
              gap={"10px"}
              borderRadius={"100px"}
              border={"1px solid #27AE60"}
              bg={"#27AE60"}
              boxShadow={"0px 1px 4px 0px #84C98B"}
              className="decision-button-saved"
            >
              <Text
                color={"white"}
                textAlign={"center"}
                fontFamily={"Nunito"}
                fontSize={"14px"}
                fontStyle={"normal"}
                fontWeight={"700"}
                lineHeight={"normal"}
                onClick={(e) => {
                  onSubmitAdd(e);
                  closeModal();
                }}
              >
                Launch
              </Text>
            </Button>
          )}
          {confirmSaved === "Saved" && (
            <div className="decision-buttons-container">
              <Link href="/analysis-archive">
                <Button
                  display={"inline-flex"}
                  padding={"10px 30px"}
                  alignItems={"center"}
                  gap={"10px"}
                  borderRadius={"100px"}
                  border={"1px solid #27AE60"}
                  bg={"#27AE60"}
                  boxShadow={"0px 1px 4px 0px #84C98B"}
                  className="decision-button-saved"
                >
                  <Icon
                    as={CheckIcon}
                    svgheight={"16px"}
                    svgwidth={"16px"}
                    InnerfillColour={"#FFF"}
                    outerfillColour={"#27AE60"}
                  />
                  <Text
                    color={"#FFF"}
                    fontFamily={"Nunito"}
                    fontSize={"14px"}
                    fontStyle={"normal"}
                    fontWeight={"800"}
                    lineHeight={"normal"}
                  >
                    {confirmSaved}: Click to View
                  </Text>
                </Button>
              </Link>
            </div>
          )}
        </div>
      </Modal>
    </>
  );
};
export default SaveAnalysisModal;
