import { useAuth0 } from "@auth0/auth0-react";
import { Box, Divider, Flex, VStack } from "@chakra-ui/react";
import React, { useContext, useState } from "react";
import Modal from "../../components/Modal";
import GlobalBodyPage from "../GlobalContainer/BodyPage";
import { StripeContext } from "../StripeContext";
import "./PasswordAndSecurityPage.css";
import ChangePasswordSection from "./changePassword";
import MultiFactorSection from "./mfa";
import TimeoutSection from "./timeout";

function PasswordAndSecurityPage() {
  const { stripePlan, setStripePlan } = useContext(StripeContext);
  const [showModal, setShowModal] = useState(false);
  const [containerWidth, setContainerWidth] = useState(null);
  const [userMetadata, setUserMetadata] = useState(null);

  const { user, isAuthenticated, getAccessTokenSilently } = useAuth0();

  return (
    <Flex
      display="flex"
      flexWrap="wrap" // Enable wrapping
      justifyContent="flex-start"
      alignItems="start"
      width={"100%"}
      gap={0}
    >
      <VStack alignItems={"flex-start"} width={"100%"} mt={-2} gap={0}>
        <Modal show={showModal} onClose={() => setShowModal(false)} />

        <GlobalBodyPage
          Heading={"Password & Security"}
          mainframeContent={
            <Box
              display={"flex"}
              flexDirection={"column"}
              alignItems={"flex-start"}
              gap={"20px"}
              alignSelf={"stretch"}
            >
              <ChangePasswordSection />
              <Divider color={"#B3B3BF"} margin={0} />
              <MultiFactorSection />
              <Divider color={"#B3B3BF"} margin={0} />
              <TimeoutSection />
            </Box>
          }
        />
      </VStack>
    </Flex>
  );
}

export default PasswordAndSecurityPage;
