import { useAuth0 } from "@auth0/auth0-react";
import {
  Box,
  Flex,
  Heading,
  Table,
  Td,
  Text,
  Th,
  VStack,
  useMediaQuery,
} from "@chakra-ui/react";
import axios from "axios";
import React, {
  forwardRef,
  useContext,
  useEffect,
  useImperativeHandle,
  useState,
} from "react";
import { useStore } from "../../store";
import GlobalBodyPage from "../GlobalContainer/BodyPage";
import DropDownButton from "../InputFields/dropDownButton";
import NormalInputButton from "../InputFields/normalInputButton";
import { SpouseContext } from "../SpouseContext";
import TooltipContent from "../TooltipContent";
import { useTransform } from "framer-motion";

const Fhsa = forwardRef((props, ref) => {
  const { setChangedData } = props;
  useImperativeHandle(ref, () => ({
    submitHandler,
    checkMandatoryFields,
  }));
  const { getAccessTokenSilently } = useAuth0();

  const axiosInstance = axios.create();
  async function getToken() {
    // Replace this with your Auth0 token retrieval logic
    const token = await getAccessTokenSilently({
      audience:
        "https://os2lsiwff43wfpb5ggyawt6qri0mgcro.lambda-url.us-east-2.on.aws/",
      scope: "write:optimization",
    });
    return token;
  }

  // Add a request interceptor
  axiosInstance.interceptors.request.use(
    async (config) => {
      const token = await getToken();
      config.headers.Authorization = `Bearer ${token}`;
      return config;
    },
    (error) => {
      // Do something with request error
      return Promise.reject(error);
    }
  );

  // Spouse 1 Data
  const [doFhsa, setDoFhsa] = useState("");
  const [fhsaOpened, setFhsaOpened] = useState("");
  const [fhsaValue, setFhsaValue] = useState("");
  const [doFhsaRoom, setDoFhsaRoom] = useState("");
  const [fhsaContributionThisYear, setFhsaContributionThisYear] = useState("");
  const [fhsaRoom, setFhsaRoom] = useState("");
  const [fhsaGrowth, setFhsaGrowth] = useState("");
  const [whenPurchaseHome, setWhenPurchaseHome] = useState("");
  const [homePurchasePrice, setHomePurchasePrice] = useState("");
  const [downPayment, setDownPayment] = useState("");
  const [currentRent, setCurrentRent] = useState("");
  const [fhsaEligible, setFhsaEligible] = useState("");
  const [purchaseHomeGoal, setPurchaseHomeGoal] = useState("");
  const [fhsaRental, setFhsaRental] = useState("");
  const [fhsaRentalAmount, setFhsaRentalAmount] = useState("");

  // Spouse 2 Data
  const [spouseDoFhsa, setSpouseDoFhsa] = useState("");
  const [spouseFhsaOpened, setSpouseFhsaOpened] = useState("");
  const [spouseFhsaValue, setSpouseFhsaValue] = useState("");
  const [spouseDoFhsaRoom, setSpouseDoFhsaRoom] = useState("");
  const [spouseFhsaRoom, setSpouseFhsaRoom] = useState("");
  const [spouseFhsaContributionThisYear, setSpouseFhsaContributionThisYear] =
    useState("");
  const [spouseFhsaGrowth, setSpouseFhsaGrowth] = useState("");
  const [spouseFhsaEligible, setSpouseFhsaEligible] = useState("");
  const [spouseWhenPurchaseHome, setSpouseWhenPurchaseHome] = useState("");
  const { spouseStatus, setSpouseStatus } = useContext(SpouseContext);

  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [spouseName, setSpouseName] = useState("");
  const [spouseLastName, setSpouseLastName] = useState("");
  const [currentAge, setCurrentAge] = useState("");
  const [spouseCurrentAge, setSpouseCurrentAge] = useState("");

  const userFHSAInfo = useStore((store) => store.userFHSAInfo);
  const setUserFHSAInfo = useStore((store) => store.setUserFHSAInfo);
  const userInfo = useStore((store) => store.userInfo);
  const userPrimaryResidenceInfo = useStore(
    (store) => store.userPrimaryResidenceInfo
  );

  const [isLargerThan500] = useMediaQuery("(min-width: 500px)");
  const isMobile = !isLargerThan500;

  const [errors, setErrors] = useState({});
  const [spouseErrors, setSpouseErrors] = useState({});

  useEffect(() => {
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 100);
  }, [errors, spouseErrors]);

  const { user, logout } = useAuth0();

  useEffect(() => {
    if (userFHSAInfo !== null) {
      // Spouse 1
      setDoFhsa(userFHSAInfo?.doFhsa);
      setFhsaOpened(userFHSAInfo?.fhsaOpened);
      setFhsaValue(userFHSAInfo?.fhsaValue);
      setDoFhsaRoom(userFHSAInfo.doFhsaRoom);
      setFhsaContributionThisYear(userFHSAInfo?.fhsaContributionThisYear);
      setFhsaRoom(userFHSAInfo?.fhsaRoom);
      setFhsaGrowth(userFHSAInfo?.fhsaGrowth ?? 6);
      setWhenPurchaseHome(userFHSAInfo?.whenPurchaseHome);
      setHomePurchasePrice(userFHSAInfo?.homePurchasePrice);
      setCurrentRent(userFHSAInfo?.currentRent);
      setFhsaEligible(userFHSAInfo?.fhsaEligible);
      setPurchaseHomeGoal(userFHSAInfo?.purchaseHomeGoal);
      setDownPayment(userFHSAInfo?.downPayment);
      setFhsaRental(userFHSAInfo?.fhsaRentHomeYesNo);
      setFhsaRentalAmount(userFHSAInfo?.fhsaRentHomeAmount);

      // Spouse 2
      setSpouseDoFhsa(userFHSAInfo?.spouseDoFhsa);
      setSpouseFhsaOpened(userFHSAInfo?.spouseOpenedFhsa);
      setSpouseFhsaEligible(userFHSAInfo?.spouseFhsaEligible);
      setSpouseFhsaValue(userFHSAInfo?.spouseFhsaValue);
      setSpouseDoFhsaRoom(userFHSAInfo.spouseDoFhsaRoom);
      setSpouseFhsaContributionThisYear(
        userFHSAInfo?.spouseFhsaContributionThisYear
      );
      setSpouseFhsaRoom(userFHSAInfo?.spouseFhsaRoom);
      setSpouseFhsaGrowth(userFHSAInfo?.spouseFhsaGrowth ?? 6);
      setSpouseWhenPurchaseHome(userFHSAInfo?.spouseWhenPurchaseHome);
    }
    if (userInfo !== null) {
      setSpouseName(userInfo?.spouseFirstName);
      setSpouseLastName(userInfo?.spouseLastName);
      setFirstName(userInfo?.FirstName);
      setLastName(userInfo?.LastName);
      setCurrentAge(userInfo?.DateOfBirth);
      setSpouseCurrentAge(userInfo?.spouseDateOfBirth);
    }
  }, []);

  useEffect(() => {
    if (userPrimaryResidenceInfo?.length >= 1) {
      setDoFhsa("No");
      setFhsaEligible("No");
      setSpouseDoFhsa("No");
      setSpouseFhsaEligible("No");
    }
  }, [userPrimaryResidenceInfo]);
  
  useEffect(() => {
    if (doFhsa === "No" && fhsaEligible === "No" && spouseDoFhsa === "No" && spouseFhsaEligible === "No") {
      submitHandler();
    }
  }, [doFhsa, fhsaEligible, spouseDoFhsa, spouseFhsaEligible]);

  const submitHandler = (e) => {
    if (e) {
      e.preventDefault();
    }
    let value = fhsaValue;
    let room = fhsaRoom;
    let thisYear = fhsaContributionThisYear;
    let spouseValue = spouseFhsaValue;
    let spouseRoom = spouseFhsaRoom;
    let spouseThisYear = spouseFhsaContributionThisYear;
    let eligible = fhsaEligible;
    let spouseEligible = spouseFhsaEligible;
    let homePurchaseGoal = purchaseHomeGoal;


    if (doFhsa === "No") {
      value = 0;
      room = 8000;
      thisYear = 0;
    }
    if (doFhsa === "Yes") {
      eligible = "Yes";
      homePurchaseGoal="Yes";
    }
    if (doFhsaRoom === "No") {
      room = 0;
    }
    if (spouseDoFhsa === "No") {
      spouseValue = 0;
      spouseRoom = 8000;
      spouseThisYear = 0;
    }
    if (spouseDoFhsaRoom === "No") {
      spouseRoom = 0;
    }
    if (spouseDoFhsa === "Yes") {
      spouseEligible = "Yes";
    }

    const rrspURL = (action) =>
      `${
        process.env.REACT_APP_NODE_API_GATEWAY
      }/CompassLambdaApi/FHSA/${action}?fhsaID=${user.sub.replace(
        "|",
        "%7c"
      )}&doFhsa=${doFhsa}&fhsaOpened=${
        fhsaOpened ?? 0
      }&fhsaValue=${value}&doFhsaRoom=${doFhsaRoom}&fhsaRoom=${
        room ?? 0
      }&fhsaGrowth=${fhsaGrowth ?? 0}&whenPurchaseHome=${
        whenPurchaseHome ?? 0
      }&homePurchasePrice=${homePurchasePrice ?? 0}&currentRent=${
        currentRent ?? 0
      }&spouseDoFhsa=${spouseDoFhsa}&spouseOpenedFhsa=${
        spouseFhsaOpened ?? 0
      }&spouseFhsaValue=${spouseValue}&spouseDoFhsaRoom=${spouseDoFhsaRoom}&spouseFhsaRoom=${
        spouseRoom ?? 0
      }&spouseFhsaGrowth=${
        spouseFhsaGrowth ?? 0
      }&fhsaEligible=${eligible}&purchaseHomeGoal=${homePurchaseGoal}&spouseFhsaEligible=${spouseEligible}&downPayment=${
        downPayment ?? 0
      }&fhsaRentHomeYesNo=${fhsaRental}&fhsaRentHomeAmount=${
        fhsaRentalAmount ?? 0
      }&fhsaContributionThisYear=${
        thisYear ?? 0
      }&spouseFhsaContributionThisYear=${
        spouseThisYear ?? 0
      }&spouseWhenPurchaseHome=${spouseWhenPurchaseHome ?? 0}`;
    axiosInstance
      .get(rrspURL("add"))
      .then((res) => {
        console.log(res);
        setUserFHSAInfo({
          ...userFHSAInfo,
          doFhsa: doFhsa,
          fhsaOpened: fhsaOpened ?? 0,
          fhsaValue: value,
          doFhsaRoom: doFhsaRoom,
          fhsaRoom: room ?? 0,
          fhsaGrowth: fhsaGrowth ?? 6,
          whenPurchaseHome: whenPurchaseHome ?? 0,
          homePurchasePrice: homePurchasePrice ?? 0,
          currentRent: currentRent ?? 0,
          spouseDoFhsa: spouseDoFhsa,
          spouseOpenedFhsa: spouseFhsaOpened ?? 0,
          spouseFhsaValue: spouseValue,
          spouseDoFhsaRoom: spouseDoFhsaRoom,
          spouseFhsaRoom: spouseRoom ?? 0,
          spouseFhsaGrowth: spouseFhsaGrowth ?? 6,
          fhsaEligible: eligible,
          purchaseHomeGoal: homePurchaseGoal,
          spouseFhsaEligible: spouseEligible,
          downPayment: downPayment ?? 0,
          fhsaRentHomeYesNo: fhsaRental,
          fhsaRentHomeAmount: fhsaRentalAmount ?? 0,
          fhsaContributionThisYear: thisYear ?? 0,
          spouseFhsaContributionThisYear: spouseThisYear ?? 0,
          spouseWhenPurchaseHome: spouseWhenPurchaseHome ?? 0,
        });
        if (res.data.code === "ER_DUP_ENTRY") {
          axiosInstance.get(rrspURL("update")).then((res) => {
            console.log(res);
            setUserFHSAInfo({
              ...userFHSAInfo,
              doFhsa: doFhsa,
              fhsaOpened: fhsaOpened ?? 0,
              fhsaValue: value,
              doFhsaRoom: doFhsaRoom,
              fhsaRoom: room ?? 0,
              fhsaGrowth: fhsaGrowth ?? 6,
              whenPurchaseHome: whenPurchaseHome ?? 0,
              homePurchasePrice: homePurchasePrice ?? 0,
              currentRent: currentRent ?? 0,
              spouseDoFhsa: spouseDoFhsa,
              spouseOpenedFhsa: spouseFhsaOpened ?? 0,
              spouseFhsaValue: spouseValue,
              spouseDoFhsaRoom: spouseDoFhsaRoom,
              spouseFhsaRoom: spouseRoom ?? 0,
              spouseFhsaGrowth: spouseFhsaGrowth ?? 6,
              fhsaEligible: eligible,
              purchaseHomeGoal: homePurchaseGoal,
              spouseFhsaEligible: spouseEligible,
              downPayment: downPayment ?? 0,
              fhsaRentHomeYesNo: fhsaRental,
              fhsaRentHomeAmount: fhsaRentalAmount ?? 0,
              fhsaContributionThisYear: thisYear ?? 0,
              spouseFhsaContributionThisYear: spouseThisYear ?? 0,
              spouseWhenPurchaseHome: spouseWhenPurchaseHome ?? 0,
            });
          });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };



  const checkMandatoryFields = () => {
    let newErrors = {};
    let newSpouseErrors = {};

    // Individual checks

    if (!doFhsa || doFhsa === "") newErrors.doFhsa = "FHSA status is required";
    if (doFhsa === "Yes") {
      if (!fhsaValue) newErrors.fhsaValue = "FHSA value is required";
      if (!fhsaOpened)
        newErrors.fhsaOpened = "Date of opening FHSA is required";
      if (!doFhsaRoom) newErrors.doFhsaRoom = "Contribution status is required";
      if (
        doFhsaRoom === "Yes" &&
        (!fhsaRoom || fhsaRoom > 16000 - (fhsaContributionThisYear ?? 0))
      ) {
        newErrors.fhsaRoom = `FHSA Room amount is required, and cannot exceed ${
          16000 - (fhsaContributionThisYear ?? 0)
        }`;
      }
      if (fhsaContributionThisYear > 8000)
        newErrors.fhsaContributionThisYear =
          "Maximum contribution of $8,000 per year.";

      if (!fhsaGrowth || fhsaGrowth > 20)
        newErrors.fhsaGrowth =
          "Rate of return is required and must be less than 20%";
      if (!whenPurchaseHome)
        newErrors.whenPurchaseHome = "Date of home purchase estimate required";
      if (!homePurchasePrice)
        newErrors.homePurchasePrice =
          "Estimated price of home value is required";
      if (!currentRent)
        newErrors.currentRent = "Current monthly rent value is required";
      // if (!fhsaRental) newErrors.fhsaRental = "Required selection field";
      // if (fhsaRental === "Yes") {
      //   if (!fhsaRentalAmount)
      //     newErrors.fhsaRentalAmount =
      //       "Please indicate how much rental income you expect to collect.";
      // }
    }
    if (doFhsa === "No") {
      if (!fhsaEligible)
        newErrors.fhsaEligible = "FHSA eligibility status is required";
      if (fhsaEligible === "Yes") {
        if (!purchaseHomeGoal)
          newErrors.purchaseHomeGoal =
            "Status of home purchase goal is required";
        if (purchaseHomeGoal === "Yes") {
          if (!whenPurchaseHome)
            newErrors.whenPurchaseHome =
              "Date of home purchase estimate required";
          if (!homePurchasePrice)
            newErrors.homePurchasePrice =
              "Estimated price of home value is required";
          if (!currentRent)
            newErrors.currentRent = "Current monthly rent value is required";
        }
      }
    }

    // Spouse checks
    if (spouseStatus) {
      if (!spouseDoFhsa || spouseDoFhsa === "")
        newSpouseErrors.spouseDoFhsa = "FHSA status is required";
      if (spouseDoFhsa === "Yes") {
        if (!spouseFhsaValue)
          newSpouseErrors.spouseFhsaValue = "FHSA value is required";
        if (!spouseFhsaOpened)
          newSpouseErrors.spouseFhsaOpened = "Date of opening FHSA is required";
        if (!spouseDoFhsaRoom)
          newSpouseErrors.spouseDoFhsaRoom = "Contribution status is required";
        if (
          spouseDoFhsaRoom === "Yes" &&
          (!spouseFhsaRoom ||
            spouseFhsaRoom > 16000 - (spouseFhsaContributionThisYear ?? 0))
        ) {
          newSpouseErrors.spouseFhsaRoom = `FHSA Room amount is required and cannot exceed ${
            16000 - (spouseFhsaContributionThisYear ?? 0)
          }`;
        }

        if (spouseFhsaContributionThisYear > 8000)
          newSpouseErrors.spouseFhsaContributionThisYear =
            "Maximum contribution of $8,000 per year.";
        if (!spouseFhsaGrowth || spouseFhsaGrowth > 20)
          newSpouseErrors.spouseFhsaGrowth =
            "Rate of return is required and must be less than 20%";
      }
      if (spouseDoFhsa === "No") {
        if (!spouseFhsaEligible)
          newSpouseErrors.spouseFhsaEligible =
            "FHSA eligibility status is required";
      }
    }

    setSpouseErrors(newSpouseErrors);
    setErrors(newErrors);
    return (
      Object.keys(newErrors).length === 0 &&
      Object.keys(newSpouseErrors).length === 0
    ); // Return true if no errors
  };

  const moneyToNumber = (value) => {
    let numValue = value.replace(/[$,\s]/g, "");
    numValue = Number(numValue);
    return numValue;
  };

  function getAge(birthday, currentDate) {
    // Convert the birthday string into a Date object
    const birthDate = new Date(birthday);

    // Calculate the age in years
    let age = currentDate.getFullYear() - birthDate.getFullYear();

    // Decrement the age if the current month and day are before the birth month and day
    if (
      currentDate.getMonth() < birthDate.getMonth() ||
      (currentDate.getMonth() === birthDate.getMonth() &&
        currentDate.getDate() <= birthDate.getDate())
    ) {
      age -= 1;
    }

    return age;
  }

  const currentDate = new Date();
  const currentYear = currentDate.getFullYear();

  const ageDifference =
    getAge(spouseCurrentAge, currentDate) - getAge(currentAge, currentDate);

  const handleAgeChange = (key) => {
    setChangedData(true);
    setWhenPurchaseHome(key);
    if (spouseStatus) {
      setSpouseWhenPurchaseHome(key + ageDifference);
    }
  };

  const Select = (userAge, fhsaOpened) => {
    const currentDate = new Date();
    const currentYear = currentDate.getFullYear();
    let age = getAge(userAge, currentDate); // Assuming getAge returns the starting age.
    const runAgeList = [];
    for (let i = age; i <= (fhsaOpened ? fhsaOpened : age) + 15; i++) {
      runAgeList.push({ key: i, value: currentYear + (i - age) }); // Convert i to string if necessary
    }
    return runAgeList;
  };

  const SelectFHSAStartYear = (userAge) => {
    const currentDate = new Date();
    const currentYear = currentDate.getFullYear();
    let age = getAge(userAge, currentDate); // Assuming getAge returns the starting age.
    const runAgeList = [];

    for (let year = currentYear; year >= 2023; year--) {
      runAgeList.push({ key: age, value: year });
      age--;
    }

    return runAgeList;
  };

  const currencyFormat = (money) => {
    if (money === 0) {
      return "$0"; // Handle zero explicitly
    } else if (money === "") {
      return "";
    } else {
      const intTotal = Math.round(Math.round(money * 100) / 100);
      const currencyCad = new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "USD",
        minimumFractionDigits: 0,
      }).format(intTotal);
      return currencyCad;
    }
  };

  const calculateDownPayment = (homePrice) => {
    let downPayment = 0;

    if (homePrice <= 500000) {
      downPayment = homePrice * 0.05;
    } else if (homePrice <= 999999) {
      downPayment = 500000 * 0.05 + (homePrice - 500000) * 0.1;
    } else {
      downPayment = 500000 * 0.05 + 500000 * 0.1 + (homePrice - 1000000) * 0.2;
    }

    return downPayment;
  };

  function calculateMonthlyMortgage(
    homeValue,
    downPayment,
    annualInterestRate,
    loanTermYears = 25
  ) {
    // Calculate the loan principal
    const loanPrincipal = homeValue - downPayment;

    // Convert annual interest rate to monthly interest rate
    const monthlyInterestRate = annualInterestRate / 12 / 100;

    // Calculate the number of payments (loan term in months)
    const numberOfPayments = loanTermYears * 12;

    // Calculate the monthly mortgage payment using the formula
    const monthlyPayment =
      (loanPrincipal *
        monthlyInterestRate *
        Math.pow(1 + monthlyInterestRate, numberOfPayments)) /
      (Math.pow(1 + monthlyInterestRate, numberOfPayments) - 1);

    return monthlyPayment;
  }

  useEffect(() => {
    if (downPayment !== calculateDownPayment(homePurchasePrice)) {
      setDownPayment(calculateDownPayment(homePurchasePrice));
    }
  }, [homePurchasePrice]);

  return (
    <VStack alignItems={"flex-start"} width={"100%"} mt={-2}>
      <GlobalBodyPage
        Heading={"First Home Savings Account"}
        mainframeContent={
          <VStack spacing={4} alignItems={"flex-start"} width={"100%"}>
            <Flex justify="space-between" w="100%">
              <Heading
                height={"27px"}
                alignSelf={"stretch"}
                color={"#00466D"}
                fontFamily={"Nunito"}
                fontSize={"20px"}
                fontStyle={"normal"}
                fontWeight={"700"}
                lineHeight={"normal"}
              >
                {firstName} {lastName}
              </Heading>
            </Flex>
            {userPrimaryResidenceInfo?.length >= 1 && (
              <Box
                display={"flex"}
                alignSelf={"stretch"}
                padding={"10px"}
                flexDirection={"column"}
                justifyContent={"center"}
                alignItems={"center"}
                gap={"10px"}
                borderRadius={"10px"}
                border={"1px solid #00A2D4"}
                bg={"rgba(0, 162, 212, 0.10)"}
              >
                <Text
                  color={"#00466D"}
                  fontFamily={"Nunito"}
                  fontSize={"15px"}
                  fontStyle={"normal"}
                  fontWeight={"600"}
                  lineHeight={"normal"}
                >
                  You already have a Primary Residence listed on your Assets
                  Page, which makes you ineligible for the FHSA.
                </Text>
              </Box>
            )}
            <DropDownButton
              Heading={"Do you have a FHSA?"}
              placeholder={"Select"}
              dropDownItems={[
                { key: "Yes", value: "Yes" },
                { key: "No", value: "No" },
              ]}
              value={doFhsa}
              onChange={(key) => {
                setChangedData(true);
                setDoFhsa(key);
              }}
              customWidth={"100%"}
              MenuCustomWidth={isMobile && "100%"}
              error={errors.doFhsa}
              tooltipContent={TooltipContent.fhsaYesNo}
              disabled={userPrimaryResidenceInfo?.length >= 1}
            />
            {doFhsa === "Yes" && (
              <>
                <DropDownButton
                  Heading={"What year was it opened?"}
                  placeholder={"Select"}
                  dropDownItems={SelectFHSAStartYear(currentAge)}
                  value={fhsaOpened}
                  onChange={(key) => {
                    setChangedData(true);
                    setFhsaOpened(key);
                  }}
                  customWidth={"100%"}
                  MenuCustomWidth={isMobile && "100%"}
                  error={errors.fhsaOpened}
                  tooltipContent={TooltipContent.fhsaYearOpened}
                />
                <NormalInputButton
                  Heading={"What is the current total value?"}
                  placeholder={"$"}
                  value={fhsaValue}
                  onChange={(e) => {
                    setFhsaValue(moneyToNumber(e.target.value));
                    setChangedData(true);
                  }}
                  customWidth={"100%"}
                  InputWidth={isMobile && "100%"}
                  useThousandSeparator={true}
                  error={errors.fhsaValue}
                  tooltipContent={TooltipContent.fhsaValue}
                />

                <NormalInputButton
                  Heading={
                    "How much have you contributed to your FHSA this year?"
                  }
                  placeholder={"$"}
                  value={fhsaContributionThisYear}
                  onChange={(e) => {
                    setFhsaContributionThisYear(moneyToNumber(e.target.value));
                    setChangedData(true);
                  }}
                  customWidth={"100%"}
                  InputWidth={isMobile && "100%"}
                  useThousandSeparator={true}
                  error={errors.fhsaContributionThisYear}
                  tooltipContent={TooltipContent.contThisYear}
                />
                <DropDownButton
                  Heading={"Do you have any unused contribution room?"}
                  placeholder={"Select"}
                  dropDownItems={[
                    { key: "Yes", value: "Yes" },
                    { key: "No", value: "No" },
                  ]}
                  value={doFhsaRoom}
                  onChange={(key) => {
                    setChangedData(true);
                    setDoFhsaRoom(key);
                  }}
                  customWidth={"100%"}
                  MenuCustomWidth={isMobile && "100%"}
                  error={errors.doFhsaRoom}
                  tooltipContent={TooltipContent.fhsaDoUnusedRoom}
                />
                {doFhsaRoom === "Yes" && (
                  <>
                    <NormalInputButton
                      Heading={"Value of the remaining contribution room"}
                      placeholder={"$"}
                      value={fhsaRoom}
                      onChange={(e) => {
                        setFhsaRoom(moneyToNumber(e.target.value));
                        setChangedData(true);
                      }}
                      customWidth={"100%"}
                      InputWidth={isMobile && "100%"}
                      useThousandSeparator={true}
                      error={errors.fhsaRoom}
                      tooltipContent={TooltipContent.fhsaUnusedRoom}
                    />
                  </>
                )}
                <NormalInputButton
                  Heading={"Expected annual rate of return?"}
                  placeholder={"%"}
                  value={fhsaGrowth}
                  onChange={(e) => {
                    setChangedData(true);
                    const noSymbol = e.target.value.replace("%", "");
                    const growthNum = parseFloat(noSymbol);
                    setFhsaGrowth(
                      !isNaN(growthNum) ? parseFloat(growthNum.toFixed(2)) : ""
                    );
                  }}
                  customWidth={"100%"}
                  InputWidth={isMobile && "100%"}
                  usePercentage={true}
                  error={errors.fhsaGrowth}
                  tooltipContent={TooltipContent.fhsaGrowth}
                />
                <Box
                  display={"flex"}
                  alignSelf={"stretch"}
                  maxWidth={"100%"}
                  padding={"10px"}
                  flexDirection={"column"}
                  justifyContent={"center"}
                  alignItems={"center"}
                  gap={"10px"}
                  borderRadius={"10px"}
                  border={"1px solid #00A2D4"}
                  bg={"rgba(0, 162, 212, 0.10)"}
                >
                  <Text
                    color={"#00466D"}
                    fontFamily={"Nunito"}
                    fontSize={"15px"}
                    fontStyle={"normal"}
                    fontWeight={"600"}
                    lineHeight={"normal"}
                  >
                    Years remaining to use FHSA to purchase your first home
                    before it is converted into your RRSP:
                    <Text
                      as={"span"}
                      color={"#0093D0"}
                      fontFamily={"Nunito"}
                      fontSize={"15px"}
                      fontStyle={"normal"}
                      fontWeight={"600"}
                      lineHeight={"normal"}
                    >
                      {" "}
                      {fhsaOpened - getAge(currentAge, currentDate) + 15}
                    </Text>
                  </Text>
                </Box>
                <DropDownButton
                  Heading={
                    "When do you anticipate you may purchase your first home?"
                  }
                  placeholder={"Select"}
                  dropDownItems={Select(currentAge, fhsaOpened)}
                  value={whenPurchaseHome}
                  onChange={handleAgeChange}
                  customWidth={"100%"}
                  MenuCustomWidth={isMobile && "100%"}
                  error={errors.whenPurchaseHome}
                  tooltipContent={TooltipContent.fhsaPurchaseHomeYear}
                />
                <NormalInputButton
                  Heading={
                    "What is the estimated purchase price of your first home?"
                  }
                  placeholder={"$"}
                  value={homePurchasePrice}
                  onChange={(e) => {
                    setHomePurchasePrice(moneyToNumber(e.target.value));
                    setChangedData(true);
                  }}
                  customWidth={"100%"}
                  InputWidth={isMobile && "100%"}
                  useThousandSeparator={true}
                  error={errors.homePurchasePrice}
                  tooltipContent={TooltipContent.fhsaHomeValue}
                />

                {homePurchasePrice > 0 && (
                  <>
                    <Text
                      color={"#007EB5"}
                      fontFamily={"Nunito"}
                      fontSize={"16px"}
                      fontStyle={"normal"}
                      fontWeight={"600"}
                      lineHeight={"normal"}
                    >
                      Table represents the down-payment for your{" "}
                      {currencyFormat(homePurchasePrice)} home
                    </Text>
                    <Table
                      variant={"simple"}
                      bg={"#E6F6FB"}
                      padding={"15px"}
                      alignSelf={"stretch"}
                      display={"flex"}
                      flexDirection={"row"}
                      justifyContent={"space-between"}
                      alignContent={"space-between"}
                      alignItems={isMobile && "center"}
                      gap={"5px"}
                    >
                      <Box
                        display={"flex"}
                        flexDir={"column"}
                        gap={"5px"}
                        alignItems={"flex-start"}
                      >
                        <Th
                          color={"#030229"}
                          fontFamily={"Nunito"}
                          fontSize={isMobile ? "10px" : "14px"}
                          fontStyle={"normal"}
                          fontWeight={"400"}
                          lineHeight={"normal"}
                          padding={0}
                          letterSpacing={0}
                          textTransform={"none"}
                          overflowWrap={"break-word"}
                          whiteSpace={isMobile && "normal"}
                        >
                          Purchase Price of Home
                        </Th>
                        <Td
                          color={"#00A2D4"}
                          fontFamily={"Nunito"}
                          fontSize={isMobile ? "10px" : "14px"}
                          fontStyle={"normal"}
                          fontWeight={"600"}
                          lineHeight={"normal"}
                          padding={0}
                          letterSpacing={0}
                          textTransform={"none"}
                        >
                          First{" "}
                          {homePurchasePrice >= 500000
                            ? "$500,000"
                            : currencyFormat(homePurchasePrice)}
                        </Td>
                        {homePurchasePrice > 500000 && (
                          <Td
                            color={"#00A2D4"}
                            fontFamily={"Nunito"}
                            fontSize={isMobile ? "10px" : "14px"}
                            fontStyle={"normal"}
                            fontWeight={"600"}
                            lineHeight={"normal"}
                            padding={0}
                            letterSpacing={0}
                            textTransform={"none"}
                          >
                            Next{" "}
                            {homePurchasePrice >= 1000000
                              ? "$500,000"
                              : currencyFormat(homePurchasePrice - 500000)}
                          </Td>
                        )}
                        {homePurchasePrice > 1000000 && (
                          <Td
                            color={"#00A2D4"}
                            fontFamily={"Nunito"}
                            fontSize={isMobile ? "10px" : "14px"}
                            fontStyle={"normal"}
                            fontWeight={"600"}
                            lineHeight={"normal"}
                            padding={0}
                            letterSpacing={0}
                            textTransform={"none"}
                          >
                            Last {currencyFormat(homePurchasePrice - 1000000)}
                          </Td>
                        )}
                        <Td
                          color={"#00A2D4"}
                          fontFamily={"Nunito"}
                          fontSize={isMobile ? "10px" : "14px"}
                          fontStyle={"normal"}
                          fontWeight={"800"}
                          lineHeight={"normal"}
                          padding={0}
                          letterSpacing={0}
                          textTransform={"none"}
                        >
                          Total
                        </Td>
                      </Box>
                      <Box
                        display={"flex"}
                        flexDir={"column"}
                        gap={"5px"}
                        alignItems={"stretch"}
                        marginTop={isMobile && "-6px"}
                      >
                        <Th
                          color={"#030229"}
                          fontFamily={"Nunito"}
                          fontSize={isMobile ? "10px" : "14px"}
                          fontStyle={"normal"}
                          fontWeight={"400"}
                          lineHeight={"normal"}
                          padding={0}
                          letterSpacing={0}
                          textTransform={"none"}
                        >
                          Calculation
                        </Th>
                        <Td
                          color={"#00A2D4"}
                          fontFamily={"Nunito"}
                          fontSize={isMobile ? "10px" : "14px"}
                          fontStyle={"normal"}
                          fontWeight={"600"}
                          lineHeight={"normal"}
                          padding={0}
                          letterSpacing={0}
                          textTransform={"none"}
                        >
                          5% of{" "}
                          {homePurchasePrice >= 500000
                            ? "$500,000"
                            : currencyFormat(homePurchasePrice)}
                        </Td>
                        {homePurchasePrice > 500000 && (
                          <Td
                            color={"#00A2D4"}
                            fontFamily={"Nunito"}
                            fontSize={isMobile ? "10px" : "14px"}
                            fontStyle={"normal"}
                            fontWeight={"600"}
                            lineHeight={"normal"}
                            padding={0}
                            letterSpacing={0}
                            textTransform={"none"}
                          >
                            10% of{" "}
                            {homePurchasePrice >= 1000000
                              ? "$500,000"
                              : currencyFormat(homePurchasePrice - 500000)}
                          </Td>
                        )}
                        {homePurchasePrice > 1000000 && (
                          <Td
                            color={"#00A2D4"}
                            fontFamily={"Nunito"}
                            fontSize={isMobile ? "10px" : "14px"}
                            fontStyle={"normal"}
                            fontWeight={"800"}
                            lineHeight={"normal"}
                            padding={0}
                            letterSpacing={0}
                            textTransform={"none"}
                          >
                            20% of {currencyFormat(homePurchasePrice - 1000000)}
                          </Td>
                        )}
                      </Box>
                      <Box
                        display={"flex"}
                        flexDir={"column"}
                        gap={"5px"}
                        alignItems={"flex-end"}
                      >
                        <Th
                          color={"#030229"}
                          fontFamily={"Nunito"}
                          fontSize={isMobile ? "10px" : "14px"}
                          fontStyle={"normal"}
                          fontWeight={"400"}
                          lineHeight={"normal"}
                          padding={0}
                          letterSpacing={0}
                          textTransform={"none"}
                          whiteSpace={isMobile && "normal"}
                          textAlign={"end"}
                        >
                          Total Down Payment Amount
                        </Th>
                        <Td
                          color={"#00A2D4"}
                          fontFamily={"Nunito"}
                          fontSize={isMobile ? "10px" : "14px"}
                          fontStyle={"normal"}
                          fontWeight={"600"}
                          lineHeight={"normal"}
                          padding={0}
                          letterSpacing={0}
                          textTransform={"none"}
                        >
                          {homePurchasePrice >= 500000
                            ? "$25,000"
                            : currencyFormat(homePurchasePrice * 0.05)}
                        </Td>
                        {homePurchasePrice > 500000 && (
                          <Td
                            color={"#00A2D4"}
                            fontFamily={"Nunito"}
                            fontSize={isMobile ? "10px" : "14px"}
                            fontStyle={"normal"}
                            fontWeight={"600"}
                            lineHeight={"normal"}
                            padding={0}
                            letterSpacing={0}
                            textTransform={"none"}
                          >
                            {homePurchasePrice >= 1000000
                              ? "$50,000"
                              : currencyFormat(
                                  (homePurchasePrice - 500000) * 0.1
                                )}
                          </Td>
                        )}
                        {homePurchasePrice > 1000000 && (
                          <Td
                            color={"#00A2D4"}
                            fontFamily={"Nunito"}
                            fontSize={isMobile ? "10px" : "14px"}
                            fontStyle={"normal"}
                            fontWeight={"600"}
                            lineHeight={"normal"}
                            padding={0}
                            letterSpacing={0}
                            textTransform={"none"}
                          >
                            {currencyFormat(
                              (homePurchasePrice - 1000000) * 0.2
                            )}
                          </Td>
                        )}
                        <Td
                          color={"#00A2D4"}
                          fontFamily={"Nunito"}
                          fontSize={isMobile ? "10px" : "14px"}
                          fontStyle={"normal"}
                          fontWeight={"800"}
                          lineHeight={"normal"}
                          padding={0}
                          letterSpacing={0}
                          textTransform={"none"}
                        >
                          {currencyFormat(downPayment)}
                        </Td>
                      </Box>
                    </Table>
                  </>
                )}
                <NormalInputButton
                  Heading={
                    "To accurately simulate the purchase of your first home and mortgage payments, we need to know your current monthly rent. This will ensure that your rent is no longer included in your future annual expenses once your mortgage starts."
                  }
                  placeholder={"$"}
                  value={currentRent}
                  onChange={(e) => {
                    setCurrentRent(moneyToNumber(e.target.value));
                    setChangedData(true);
                  }}
                  customWidth={"100%"}
                  InputWidth={isMobile && "100%"}
                  useThousandSeparator={true}
                  error={errors.currentRent}
                  tooltipContent={TooltipContent.fhsaRent}
                />
                <Box
                  display={"flex"}
                  flexDir={"column"}
                  gap={"5px"}
                  alignItems={"flex-end"}
                >
                  <Text
                    color={"#007EB5"}
                    fontFamily={"Nunito"}
                    fontSize={"16px"}
                    fontStyle={"normal"}
                    fontWeight={"600"}
                    lineHeight={"normal"}
                  >
                    Using an interest rate of 5% on a 25 year mortgage, we've
                    calculated your monthly mortgage payments to be{" "}
                    {currencyFormat(
                      calculateMonthlyMortgage(
                        homePurchasePrice,
                        downPayment,
                        5,
                        25
                      )
                    )}
                  </Text>
                </Box>
                {/* <DropDownButton
                  Heading={
                    "Will you be collecting rent for any rooms or units within the property?"
                  }
                  placeholder={"Select"}
                  dropDownItems={[
                    { key: "Yes", value: "Yes" },
                    { key: "No", value: "No" },
                  ]}
                  value={fhsaRental}
                  onChange={(key) => {
                    setChangedData(true);
                    setFhsaRental(key);
                  }}
                  customWidth={"100%"}
                  MenuCustomWidth={isMobile && "100%"}
                  error={errors.fhsaRental}
                  tooltipContent={TooltipContent.fhsaDoUnusedRoom}
                />
                {fhsaRental === "Yes" && (
                  <>
                    <NormalInputButton
                      Heading={"Monthly rental income"}
                      placeholder={"$"}
                      value={fhsaRentalAmount}
                      onChange={(e) => {
                        setFhsaRentalAmount(moneyToNumber(e.target.value));
                        setChangedData(true);
                      }}
                      customWidth={"100%"}
                      InputWidth={isMobile && "100%"}
                      useThousandSeparator={true}
                      error={errors.fhsaRentalAmount}
                      tooltipContent={TooltipContent.fhsaUnusedRoom}
                    />
                  </>
                )} */}
              </>
            )}
            {doFhsa === "No" && (
              <>
                <DropDownButton
                  Heading={"Are you eligible for the FHSA?"}
                  placeholder={"Select"}
                  dropDownItems={[
                    { key: "Yes", value: "Yes" },
                    { key: "No", value: "No" },
                  ]}
                  value={fhsaEligible}
                  onChange={(key) => {
                    setChangedData(true);
                    setFhsaEligible(key);
                  }}
                  customWidth={"100%"}
                  MenuCustomWidth={isMobile && "100%"}
                  error={errors.fhsaEligible}
                  tooltipContent={TooltipContent.fhsaEligible}
                  disabled={userPrimaryResidenceInfo?.length >= 1}
                />
                {fhsaEligible === "Yes" && (
                  <>
                    <DropDownButton
                      Heading={
                        "Is it your goal to purchase your first home within the next 15 years?"
                      }
                      placeholder={"Select"}
                      dropDownItems={[
                        { key: "Yes", value: "Yes" },
                        { key: "No", value: "No" },
                      ]}
                      value={purchaseHomeGoal}
                      onChange={(key) => {
                        setChangedData(true);
                        setPurchaseHomeGoal(key);
                      }}
                      customWidth={"100%"}
                      MenuCustomWidth={isMobile && "100%"}
                      error={errors.purchaseHomeGoal}
                      tooltipContent={TooltipContent.fhsaPurchaseHomeGoal}
                    />
                    {purchaseHomeGoal === "Yes" && (
                      <>
                        <DropDownButton
                          Heading={
                            "When do you plan on purchasing your first home?"
                          }
                          placeholder={"Select"}
                          dropDownItems={Select(currentAge, fhsaOpened)}
                          value={whenPurchaseHome}
                          onChange={handleAgeChange}
                          customWidth={"100%"}
                          MenuCustomWidth={isMobile && "100%"}
                          error={errors.whenPurchaseHome}
                          tooltipContent={TooltipContent.fhsaPurchaseHomeYear}
                        />
                        <NormalInputButton
                          Heading={
                            "What is the estimated purchase price of your first home?"
                          }
                          placeholder={"$"}
                          value={homePurchasePrice}
                          onChange={(e) => {
                            setHomePurchasePrice(moneyToNumber(e.target.value));
                            setChangedData(true);
                          }}
                          customWidth={"100%"}
                          InputWidth={isMobile && "100%"}
                          useThousandSeparator={true}
                          error={errors.homePurchasePrice}
                          tooltipContent={TooltipContent.fhsaHomeValue}
                        />

                        <Text
                          color={"#007EB5"}
                          fontFamily={"Nunito"}
                          fontSize={"16px"}
                          fontStyle={"normal"}
                          fontWeight={"600"}
                          lineHeight={"normal"}
                        >
                          Table represents the down-payment for your{" "}
                          {currencyFormat(homePurchasePrice)} home
                        </Text>
                        {homePurchasePrice && (
                          <Table
                            variant={"simple"}
                            bg={"#E6F6FB"}
                            padding={"15px"}
                            alignSelf={"stretch"}
                            display={"flex"}
                            flexDirection={"row"}
                            justifyContent={"space-between"}
                            alignContent={"space-between"}
                            alignItems={isMobile && "center"}
                            gap={"5px"}
                          >
                            <Box
                              display={"flex"}
                              flexDir={"column"}
                              gap={"5px"}
                              alignItems={"flex-start"}
                            >
                              <Th
                                color={"#030229"}
                                fontFamily={"Nunito"}
                                fontSize={isMobile ? "10px" : "14px"}
                                fontStyle={"normal"}
                                fontWeight={"400"}
                                lineHeight={"normal"}
                                padding={0}
                                letterSpacing={0}
                                textTransform={"none"}
                                overflowWrap={"break-word"}
                                whiteSpace={isMobile && "normal"}
                              >
                                Purchase Price of Home
                              </Th>
                              <Td
                                color={"#00A2D4"}
                                fontFamily={"Nunito"}
                                fontSize={isMobile ? "10px" : "14px"}
                                fontStyle={"normal"}
                                fontWeight={"600"}
                                lineHeight={"normal"}
                                padding={0}
                                letterSpacing={0}
                                textTransform={"none"}
                              >
                                First{" "}
                                {homePurchasePrice >= 500000
                                  ? "$500,000"
                                  : currencyFormat(homePurchasePrice)}
                              </Td>
                              {homePurchasePrice > 500000 && (
                                <Td
                                  color={"#00A2D4"}
                                  fontFamily={"Nunito"}
                                  fontSize={isMobile ? "10px" : "14px"}
                                  fontStyle={"normal"}
                                  fontWeight={"600"}
                                  lineHeight={"normal"}
                                  padding={0}
                                  letterSpacing={0}
                                  textTransform={"none"}
                                >
                                  Next{" "}
                                  {homePurchasePrice >= 1000000
                                    ? "$500,000"
                                    : currencyFormat(
                                        homePurchasePrice - 500000
                                      )}
                                </Td>
                              )}
                              {homePurchasePrice > 1000000 && (
                                <Td
                                  color={"#00A2D4"}
                                  fontFamily={"Nunito"}
                                  fontSize={isMobile ? "10px" : "14px"}
                                  fontStyle={"normal"}
                                  fontWeight={"600"}
                                  lineHeight={"normal"}
                                  padding={0}
                                  letterSpacing={0}
                                  textTransform={"none"}
                                >
                                  Last{" "}
                                  {currencyFormat(homePurchasePrice - 1000000)}
                                </Td>
                              )}
                              <Td
                                color={"#00A2D4"}
                                fontFamily={"Nunito"}
                                fontSize={isMobile ? "10px" : "14px"}
                                fontStyle={"normal"}
                                fontWeight={"800"}
                                lineHeight={"normal"}
                                padding={0}
                                letterSpacing={0}
                                textTransform={"none"}
                              >
                                Total
                              </Td>
                            </Box>
                            <Box
                              display={"flex"}
                              flexDir={"column"}
                              gap={"5px"}
                              alignItems={"stretch"}
                              marginTop={isMobile && "-6px"}
                            >
                              <Th
                                color={"#030229"}
                                fontFamily={"Nunito"}
                                fontSize={isMobile ? "10px" : "14px"}
                                fontStyle={"normal"}
                                fontWeight={"400"}
                                lineHeight={"normal"}
                                padding={0}
                                letterSpacing={0}
                                textTransform={"none"}
                              >
                                Calculation
                              </Th>
                              <Td
                                color={"#00A2D4"}
                                fontFamily={"Nunito"}
                                fontSize={isMobile ? "10px" : "14px"}
                                fontStyle={"normal"}
                                fontWeight={"600"}
                                lineHeight={"normal"}
                                padding={0}
                                letterSpacing={0}
                                textTransform={"none"}
                              >
                                5% of{" "}
                                {homePurchasePrice >= 500000
                                  ? "$500,000"
                                  : currencyFormat(homePurchasePrice)}
                              </Td>
                              {homePurchasePrice > 500000 && (
                                <Td
                                  color={"#00A2D4"}
                                  fontFamily={"Nunito"}
                                  fontSize={isMobile ? "10px" : "14px"}
                                  fontStyle={"normal"}
                                  fontWeight={"600"}
                                  lineHeight={"normal"}
                                  padding={0}
                                  letterSpacing={0}
                                  textTransform={"none"}
                                >
                                  10% of{" "}
                                  {homePurchasePrice >= 1000000
                                    ? "$500,000"
                                    : currencyFormat(
                                        homePurchasePrice - 500000
                                      )}
                                </Td>
                              )}
                              {homePurchasePrice > 1000000 && (
                                <Td
                                  color={"#00A2D4"}
                                  fontFamily={"Nunito"}
                                  fontSize={isMobile ? "10px" : "14px"}
                                  fontStyle={"normal"}
                                  fontWeight={"800"}
                                  lineHeight={"normal"}
                                  padding={0}
                                  letterSpacing={0}
                                  textTransform={"none"}
                                >
                                  20% of{" "}
                                  {currencyFormat(homePurchasePrice - 1000000)}
                                </Td>
                              )}
                            </Box>
                            <Box
                              display={"flex"}
                              flexDir={"column"}
                              gap={"5px"}
                              alignItems={"flex-end"}
                            >
                              <Th
                                color={"#030229"}
                                fontFamily={"Nunito"}
                                fontSize={isMobile ? "10px" : "14px"}
                                fontStyle={"normal"}
                                fontWeight={"400"}
                                lineHeight={"normal"}
                                padding={0}
                                letterSpacing={0}
                                textTransform={"none"}
                                whiteSpace={isMobile && "normal"}
                                textAlign={"end"}
                              >
                                Total Down Payment Amount
                              </Th>
                              <Td
                                color={"#00A2D4"}
                                fontFamily={"Nunito"}
                                fontSize={isMobile ? "10px" : "14px"}
                                fontStyle={"normal"}
                                fontWeight={"600"}
                                lineHeight={"normal"}
                                padding={0}
                                letterSpacing={0}
                                textTransform={"none"}
                              >
                                {homePurchasePrice >= 500000
                                  ? "$25,000"
                                  : currencyFormat(homePurchasePrice * 0.05)}
                              </Td>
                              {homePurchasePrice > 500000 && (
                                <Td
                                  color={"#00A2D4"}
                                  fontFamily={"Nunito"}
                                  fontSize={isMobile ? "10px" : "14px"}
                                  fontStyle={"normal"}
                                  fontWeight={"600"}
                                  lineHeight={"normal"}
                                  padding={0}
                                  letterSpacing={0}
                                  textTransform={"none"}
                                >
                                  {homePurchasePrice >= 1000000
                                    ? "$50,000"
                                    : currencyFormat(
                                        (homePurchasePrice - 500000) * 0.1
                                      )}
                                </Td>
                              )}
                              {homePurchasePrice > 1000000 && (
                                <Td
                                  color={"#00A2D4"}
                                  fontFamily={"Nunito"}
                                  fontSize={isMobile ? "10px" : "14px"}
                                  fontStyle={"normal"}
                                  fontWeight={"600"}
                                  lineHeight={"normal"}
                                  padding={0}
                                  letterSpacing={0}
                                  textTransform={"none"}
                                >
                                  {currencyFormat(
                                    (homePurchasePrice - 1000000) * 0.2
                                  )}
                                </Td>
                              )}
                              <Td
                                color={"#00A2D4"}
                                fontFamily={"Nunito"}
                                fontSize={isMobile ? "10px" : "14px"}
                                fontStyle={"normal"}
                                fontWeight={"800"}
                                lineHeight={"normal"}
                                padding={0}
                                letterSpacing={0}
                                textTransform={"none"}
                              >
                                {currencyFormat(downPayment)}
                              </Td>
                            </Box>
                          </Table>
                        )}
                        <NormalInputButton
                          Heading={
                            "To accurately simulate the purchase of your first home and mortgage payments, we need to know your current monthly rent. This will ensure that your rent is no longer included in your future annual expenses once your mortgage starts."
                          }
                          placeholder={"$"}
                          value={currentRent}
                          onChange={(e) => {
                            setCurrentRent(moneyToNumber(e.target.value));
                            setChangedData(true);
                          }}
                          customWidth={"100%"}
                          InputWidth={isMobile && "100%"}
                          useThousandSeparator={true}
                          error={errors.currentRent}
                          tooltipContent={TooltipContent.fhsaRent}
                        />

                        <Box
                          display={"flex"}
                          flexDir={"column"}
                          gap={"5px"}
                          alignItems={"flex-end"}
                        >
                          <Text
                            color={"#007EB5"}
                            fontFamily={"Nunito"}
                            fontSize={"16px"}
                            fontStyle={"normal"}
                            fontWeight={"600"}
                            lineHeight={"normal"}
                          >
                            Using an interest rate of 5% on a 25 year mortgage,
                            we've calculated your monthly mortgage payments to
                            be{" "}
                            {currencyFormat(
                              calculateMonthlyMortgage(
                                homePurchasePrice,
                                downPayment,
                                5,
                                25
                              )
                            )}
                          </Text>
                        </Box>
                        {/* <DropDownButton
                          Heading={
                            "Will you be collecting rent for any rooms or units within the property?"
                          }
                          placeholder={"Select"}
                          dropDownItems={[
                            { key: "Yes", value: "Yes" },
                            { key: "No", value: "No" },
                          ]}
                          value={fhsaRental}
                          onChange={(key) => {
                            setChangedData(true);
                            setFhsaRental(key);
                          }}
                          customWidth={"100%"}
                          MenuCustomWidth={isMobile && "100%"}
                          error={errors.fhsaRental}
                          tooltipContent={TooltipContent.fhsaDoUnusedRoom}
                        />
                        {fhsaRental === "Yes" && (
                          <>
                            <NormalInputButton
                              Heading={"Monthly rental income"}
                              placeholder={"$"}
                              value={fhsaRentalAmount}
                              onChange={(e) => {
                                setFhsaRentalAmount(
                                  moneyToNumber(e.target.value)
                                );
                                setChangedData(true);
                              }}
                              customWidth={"100%"}
                              InputWidth={isMobile && "100%"}
                              useThousandSeparator={true}
                              error={errors.fhsaRentalAmount}
                              tooltipContent={TooltipContent.fhsaUnusedRoom}
                            />
                          </>
                        )} */}
                      </>
                    )}

                    {purchaseHomeGoal === "No" && (
                      <Box
                        display={"flex"}
                        alignSelf={"stretch"}
                        maxWidth={"100%"}
                        padding={"10px"}
                        flexDirection={"column"}
                        justifyContent={"center"}
                        alignItems={"center"}
                        gap={"10px"}
                        borderRadius={"10px"}
                        border={"1px solid #00A2D4"}
                        bg={"rgba(0, 162, 212, 0.10)"}
                      >
                        <Text
                          color={"#00A2D4"}
                          fontFamily={"Nunito"}
                          fontSize={"15px"}
                          fontStyle={"normal"}
                          fontWeight={"600"}
                          lineHeight={"normal"}
                        >
                          The analysis may still recommend opening an FHSA to
                          take advantage of tax benefits and tax-free growth. At
                          the end of the 15-year period eligibility, your FHSA
                          will be converted into your RRSP.
                        </Text>
                      </Box>
                    )}
                  </>
                )}
              </>
            )}
          </VStack>
        }
        // videoName={"TFSA Page"}
        // video={"TFSA.mp4"}
      />
      {spouseStatus && (
        <GlobalBodyPage
          mainframeContent={
            <VStack spacing={4} alignItems={"flex-start"} width={"100%"}>
              <Flex justify="space-between" w="100%">
                <Heading
                  height={"27px"}
                  alignSelf={"stretch"}
                  color={"#00466D"}
                  fontFamily={"Nunito"}
                  fontSize={"20px"}
                  fontStyle={"normal"}
                  fontWeight={"700"}
                  lineHeight={"normal"}
                >
                  {spouseName} {spouseLastName}
                </Heading>
              </Flex>
              {userPrimaryResidenceInfo?.length >= 1 && (
                <Box
                  display={"flex"}
                  alignSelf={"stretch"}
                  padding={"10px"}
                  flexDirection={"column"}
                  justifyContent={"center"}
                  alignItems={"center"}
                  gap={"10px"}
                  borderRadius={"10px"}
                  border={"1px solid #00A2D4"}
                  bg={"rgba(0, 162, 212, 0.10)"}
                >
                  <Text
                    color={"#00466D"}
                    fontFamily={"Nunito"}
                    fontSize={"15px"}
                    fontStyle={"normal"}
                    fontWeight={"600"}
                    lineHeight={"normal"}
                  >
                    You already have a Primary Residence listed on your Assets
                    Page, which makes you ineligible for the FHSA.
                  </Text>
                </Box>
              )}
              <DropDownButton
                Heading={"Do you have a FHSA?"}
                placeholder={"Select"}
                dropDownItems={[
                  { key: "Yes", value: "Yes" },
                  { key: "No", value: "No" },
                ]}
                value={spouseDoFhsa}
                onChange={(key) => {
                  setChangedData(true);
                  setSpouseDoFhsa(key);
                }}
                customWidth={"100%"}
                MenuCustomWidth={isMobile && "100%"}
                error={spouseErrors.spouseDoFhsa}
                tooltipContent={TooltipContent.fhsaYesNo}
                disabled={userPrimaryResidenceInfo?.length >= 1}
              />
              {spouseDoFhsa === "Yes" && (
                <>
                  <DropDownButton
                    Heading={"What year was it opened?"}
                    placeholder={"Select"}
                    dropDownItems={SelectFHSAStartYear(spouseCurrentAge)}
                    value={spouseFhsaOpened}
                    onChange={(key) => {
                      setChangedData(true);
                      setSpouseFhsaOpened(key);
                    }}
                    customWidth={"100%"}
                    MenuCustomWidth={isMobile && "100%"}
                    error={spouseErrors.spouseFhsaOpened}
                    tooltipContent={TooltipContent.fhsaYearOpened}
                  />
                  <NormalInputButton
                    Heading={"What is the current total value?"}
                    placeholder={"$"}
                    value={spouseFhsaValue}
                    onChange={(e) => {
                      setSpouseFhsaValue(moneyToNumber(e.target.value));
                      setChangedData(true);
                    }}
                    customWidth={"100%"}
                    InputWidth={isMobile && "100%"}
                    useThousandSeparator={true}
                    error={spouseErrors.spouseFhsaValue}
                    tooltipContent={TooltipContent.fhsaValue}
                  />
                  <NormalInputButton
                    Heading={
                      "How much have you contributed to your FHSA this year?"
                    }
                    placeholder={"$"}
                    value={spouseFhsaContributionThisYear}
                    onChange={(e) => {
                      setSpouseFhsaContributionThisYear(
                        moneyToNumber(e.target.value)
                      );
                      setChangedData(true);
                    }}
                    customWidth={"100%"}
                    InputWidth={isMobile && "100%"}
                    useThousandSeparator={true}
                    error={spouseErrors.spouseFhsaContributionThisYear}
                    tooltipContent={TooltipContent.contThisYear}
                  />
                  <DropDownButton
                    Heading={"Do you have any unused contribution room?"}
                    placeholder={"Select"}
                    dropDownItems={[
                      { key: "Yes", value: "Yes" },
                      { key: "No", value: "No" },
                    ]}
                    value={spouseDoFhsaRoom}
                    onChange={(key) => {
                      setChangedData(true);
                      setSpouseDoFhsaRoom(key);
                    }}
                    customWidth={"100%"}
                    MenuCustomWidth={isMobile && "100%"}
                    error={spouseErrors.spouseDoFhsaRoom}
                    tooltipContent={TooltipContent.fhsaDoUnusedRoom}
                  />
                  {spouseDoFhsaRoom === "Yes" && (
                    <>
                      <NormalInputButton
                        Heading={"Value of the remaining contribution room"}
                        placeholder={"$"}
                        value={spouseFhsaRoom}
                        onChange={(e) => {
                          setSpouseFhsaRoom(moneyToNumber(e.target.value));
                          setChangedData(true);
                        }}
                        customWidth={"100%"}
                        InputWidth={isMobile && "100%"}
                        useThousandSeparator={true}
                        error={spouseErrors.spouseFhsaRoom}
                        tooltipContent={TooltipContent.fhsaUnusedRoom}
                      />
                    </>
                  )}
                  <NormalInputButton
                    Heading={"Expected annual rate of return?"}
                    placeholder={"%"}
                    value={spouseFhsaGrowth}
                    onChange={(e) => {
                      setChangedData(true);
                      const noSymbol = e.target.value.replace("%", "");
                      const growthNum = parseFloat(noSymbol);
                      setSpouseFhsaGrowth(
                        !isNaN(growthNum)
                          ? parseFloat(growthNum.toFixed(2))
                          : ""
                      );
                    }}
                    customWidth={"100%"}
                    InputWidth={isMobile && "100%"}
                    usePercentage={true}
                    error={spouseErrors.spouseFhsaGrowth}
                    tooltipContent={TooltipContent.fhsaGrowth}
                  />
                  <Box
                    display={"flex"}
                    alignSelf={"stretch"}
                    padding={"10px"}
                    flexDirection={"column"}
                    justifyContent={"center"}
                    alignItems={"center"}
                    gap={"10px"}
                    borderRadius={"10px"}
                    border={"1px solid #00A2D4"}
                    bg={"rgba(0, 162, 212, 0.10)"}
                  >
                    <Text
                      color={"#00466D"}
                      fontFamily={"Nunito"}
                      fontSize={"15px"}
                      fontStyle={"normal"}
                      fontWeight={"600"}
                      lineHeight={"normal"}
                    >
                      Years remaining to use FHSA to purchase your first home
                      before it is converted into your RRSP:
                      <Text
                        as={"span"}
                        color={"#0093D0"}
                        fontFamily={"Nunito"}
                        fontSize={"15px"}
                        fontStyle={"normal"}
                        fontWeight={"600"}
                        lineHeight={"normal"}
                      >
                        {" "}
                        {spouseFhsaOpened -
                          getAge(spouseCurrentAge, currentDate) +
                          15}
                      </Text>
                    </Text>
                  </Box>
                </>
              )}
              {spouseDoFhsa === "No" && (
                <DropDownButton
                  Heading={"Are you eligible to qualify for the FHSA?"}
                  placeholder={"Select"}
                  dropDownItems={[
                    { key: "Yes", value: "Yes" },
                    { key: "No", value: "No" },
                  ]}
                  value={spouseFhsaEligible}
                  onChange={(key) => {
                    setChangedData(true);
                    setSpouseFhsaEligible(key);
                  }}
                  customWidth={"100%"}
                  MenuCustomWidth={isMobile && "100%"}
                  error={spouseErrors.spouseFhsaEligible}
                  tooltipContent={TooltipContent.fhsaEligible}
                  disabled={userPrimaryResidenceInfo?.length >= 1}
                />
              )}
            </VStack>
          }
        />
      )}
    </VStack>
  );
});

export default Fhsa;
