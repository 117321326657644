import React from 'react';
import LargeLoader from '../../img/LargeOptmlLoader.gif';
import SmallLoader from '../../img/SmallOptmlLoader.gif';
import './CompassSpinner.css';

function CompassSpinner(props) {
    const { loading, className, small } = props;

    return (
      <div className={className} style={{ display: loading ? 'flex' : 'none' }}>
        {loading && !small && (
          <img src={LargeLoader} style={{ width: '40%'}}/>
        )}
        {loading && small && (
          <img style={{ background: 'transparent', width: '30rem'}} src={SmallLoader} />
        )}
      </div>
    );
}

export default CompassSpinner;
