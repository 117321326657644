import {
  Box,
  HStack,
  Heading,
  Icon,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Text,
  VStack,
  useMediaQuery,
} from "@chakra-ui/react";
import {
  faBusinessFront,
  faCar,
  faGlassCheers,
  faGraduationCap,
  faHandHoldingUsd,
  faHouse,
  faInfoSquare,
  faPlane,
  faRingsWedding,
  faSailboat,
  faStar,
} from "@fortawesome/pro-regular-svg-icons";
import { faCircleCheck } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import html2canvas from "html2canvas";
import React, { useContext, useEffect, useState } from "react";
import { FaCircle } from "react-icons/fa";
import Modal from "../../components/Modal";
import { useStore } from "../../store";
import { NextYearIcon } from "../CustomIcons";
import { SpouseContext } from "../SpouseContext";
import { StripeContext } from "../StripeContext";
import StripeUpgradeModal from "../StripeUpgradeModal";
import "./ActionPlanPage.css";
import DepositsWithdrawalsGraph from "./actionPlanGraph";
import DownloadPDF from "./actionPlanPDF";
import OltreChatBubble from "../OltreChatBubble";
import DropDownButton from "../InputFields/dropDownButton";

function ActionPlanPage() {
  const { spouseStatus, setSpouseStatus } = useContext(SpouseContext);
  const [year, setYear] = useState(new Date().getFullYear());
  const [nextYear, setNextYear] = useState(year + 1);
  const [listPlace, setListPlace] = useState(0);
  const [graphImage, setGraphImage] = useState();
  const [spouseGraphImage, setSpouseGraphImage] = useState();
  const [ageStart, setAgeStart] = useState(false);
  const [ageEnd, setAgeEnd] = useState(true);
  const [showModal, setShowModal] = useState(false);
  const [hoverCSV, setHoverCSV] = useState(false);
  const [activePage, setActivePage] = useState("actionPlan");

  const userInfo = useStore((store) => store.userInfo);
  const userOptiData = useStore((store) => store.userOptiData);
  const userExpensesInfo = useStore((store) => store.userExpensesInfo);
  const userTFSAInfo = useStore((store) => store.userTFSAInfo);
  const userFHSAInfo = useStore((store) => store.userFHSAInfo);
  const userNonRegInvestmentsInfo = useStore(
    (store) => store.userNonRegInvestmentsInfo
  );
  const userFixedIncomeInfo = useStore((store) => store.userFixedIncomeInfo);
  const userRRSPInfo = useStore((store) => store.userRRSPInfo);
  const userLiraInfo = useStore((store) => store.userLiraInfo);
  const userPensionInfo = useStore((store) => store.userPensionInfo);
  const userBusinessInfo = useStore((store) => store.userBusinessInfo);
  let singleFoundItem = userPensionInfo?.find(
    (item) =>
      item?.spouseCheck === 0 && item?.Pension_Type != "Defined Benefit Plan"
  );
  let spouseFoundItem = userPensionInfo?.find(
    (item) =>
      item?.spouseCheck === 1 && item?.Pension_Type != "Defined Benefit Plan"
  );
  let filteredItems = userFixedIncomeInfo?.filter(
    (item) => item?.spouseCheck === 0
  );
  let totalInterest = filteredItems.reduce(
    (sum, item) => sum + (item?.fixedIncomeInterest || 0),
    0
  );
  let averageInterest =
    filteredItems?.length > 0 ? totalInterest / filteredItems?.length : 0;

  let filteredItemsSpouse = userFixedIncomeInfo?.filter(
    (item) => item?.spouseCheck === 1
  );
  let totalInterestSpouse = filteredItemsSpouse.reduce(
    (sum, item) => sum + (item?.fixedIncomeInterest || 0),
    0
  );

  let averageInterestSpouse =
    filteredItemsSpouse?.length > 0
      ? totalInterestSpouse / filteredItemsSpouse?.length
      : 0;

  const {
    FirstName: firstName,
    LastName: lastName,
    spouseFirstName,
    spouseLastName,
  } = userInfo;
  const setShowNewOptimization = useStore(
    (store) => store.setShowNewOptimization
  );
  setShowNewOptimization(false);

  const primaryUserAge = getAge(userOptiData?.primaryUserData?.sps1_birthday);
  const spouseAge = getAge(userOptiData?.spouseData?.sps2_birthday);

  const [minTrx, setMinTrx] = useState(50);

  const { stripePlan, setStripePlan } = useContext(StripeContext);
  const [showStripeModal, setShowStripeModal] = useState(false);

  function UpgradeStripeModal() {
    setShowStripeModal(!showStripeModal);
  }

  useEffect(() => {
    const captureGraph = async () => {
      try {
        // Check if the main graph element exists
        const graphElement = document.getElementById("graphContainer");
        if (!graphElement) {
          console.error("Main graph container not found.");
          return; // Exit if the element is not found
        }

        const canvas = await html2canvas(graphElement, {
          scale: 6, // Increase scale to improve image quality
          useCORS: true, // Helps with loading images from external domains
        });
        const image = canvas.toDataURL("image/png");
        setGraphImage(image); // Update state with the image data

        // Check if there is a spouse and if the spouse's graph element exists
        if (spouseStatus) {
          const spouseGraphElement = document.getElementById(
            "spouseGraphContainer"
          );
          if (!spouseGraphElement) {
            console.error("Spouse graph container not found.");
            return; // Exit if the spouse element is not found
          }
          const spouseCanvas = await html2canvas(spouseGraphElement, {
            scale: 6,
            useCORS: true,
          });
          const spouseImage = spouseCanvas.toDataURL("image/png");
          setSpouseGraphImage(spouseImage); // Update state with the image data
        }
      } catch (error) {
        console.error("Failed to capture graph:", error);
      }
    };

    // Set a timeout to allow for potential delays in rendering
    const timer = setTimeout(() => {
      captureGraph();
    }, 1000);

    // Clean up the timer when the component unmounts or the dependencies change
    return () => clearTimeout(timer);
  }, []); // Dependencies are empty to run only once on mount

  function getAge(birthday, currentDate) {
    currentDate ? currentDate : (currentDate = new Date());
    // Convert the birthday string into a Date object
    const birthDate = new Date(birthday);

    // Calculate the age in years
    let age = currentDate.getFullYear() - birthDate.getFullYear();

    // Decrement the age if the current month and day are before the birth month and day
    if (
      currentDate.getMonth() < birthDate.getMonth() ||
      (currentDate.getMonth() === birthDate.getMonth() &&
        currentDate.getDate() <= birthDate.getDate())
    ) {
      age -= 1;
    }

    return age;
  }

  function recalculateAge(user) {
    const currentDate = new Date();
    const diff = year - currentDate.getFullYear();
    if (user === "primary") {
      return primaryUserAge + diff;
    }
    return spouseAge + diff;
  }

  function safeAdd(...values) {
    return values?.reduce((sum, val) => sum + (Number(val) || 0), 0);
  }

  const currencyFormatListShift = (money, growth) => {
    const moneyArr = [];

    // Create a new array without the first element
    const shiftedMoney = money?.slice(1);

    // Add the growth value to the last element and append to the array
    // const lastValue = shiftedMoney[shiftedMoney.length - 1];
    // shiftedMoney.push(lastValue + growth);

    for (let i = 0; i < shiftedMoney?.length; i++) {
      var intTotal = Math.round(Math.round(shiftedMoney[i] * 100) / 100);
      if (intTotal > -2 && intTotal < 0.25) {
        intTotal = 0;
      }
      var currencyCad = new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "USD",
        minimumFractionDigits: 0,
      }).format(intTotal);
      moneyArr[i] = currencyCad;
    }

    return moneyArr;
  };

  const currencyFormatList = (money) => {
    const moneyArr = [];
    for (let i = 0; i < money?.length; i++) {
      var intTotal = Math.round(Math.round(money[i] * 100) / 100);
      if (intTotal > -2 && intTotal < 0.25) {
        intTotal = 0;
      }
      var currencyCad = new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "USD",
        minimumFractionDigits: 0,
      }).format(intTotal);
      moneyArr[i] = currencyCad;
    }
    return moneyArr;
  };

  const yearHeaders = (birthday) => {
    const Headers = [];
    // Headers[0] = '';
    const currentYear = new Date();
    const age = getAge(birthday, currentYear);
    for (let i = 0; i < userOptiData?.primaryUserData?.expenses?.length; i++) {
      Headers[i] = (age + i).toString();
    }
    return Headers;
  };

  function formatDate(dateString) {
    const date = new Date(dateString);
    const options = { month: "long", day: "numeric", year: "numeric" };
    const formatted = date?.toLocaleDateString("en-US", options);

    const day = date?.getDate();
    let suffix = "th";
    if (day === 1 || day === 21 || day === 31) {
      suffix = "st";
    } else if (day === 2 || day === 22) {
      suffix = "nd";
    } else if (day === 3 || day === 23) {
      suffix = "rd";
    }

    const dayWithSuffix = day + suffix;

    return formatted?.replace(day?.toString(), dayWithSuffix);
  }

  // Usage
  <p className="action-plan-small-box-subheader">
    {formatDate(userOptiData?.primaryUserData?.planRunDate)}
  </p>;

  const currencyFormat = (money) => {
    var intTotal = Math.round(Math.round(money * 100) / 100);
    if (intTotal > -2 && intTotal < 0.25) {
      intTotal = 0;
    }
    // Math.round(intTotal);
    var currencyCad = new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD",
      minimumFractionDigits: 0,
    }).format(intTotal);
    return currencyCad;
  };

  const yearToDate = (year) => {
    const date = new Date();
    date?.setFullYear(year);
    // console?.log(date);
    return date;
  };

  // useEffect(() => {
  //   if (activePage != "actionPlan") {
  //     setYear(new Date().getFullYear());
  //     setNextYear(year + 1);
  //     setListPlace(0);
  //     setAgeStart(false);
  //     setAgeEnd(true);
  //   }
  // }, [activePage]);

  useEffect(() => {
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 100);
  }, []);

  function MonthlyDeposits(props) {
    const { goal, account } = props;
    const [saveDeposits, setSaveDeposits] = useState(false);

    // Get the current month and year
    const currentDate = new Date();
    const currentMonth = currentDate.getMonth(); // 0-indexed
    const currentYear = currentDate.getFullYear();

    // Calculate number of months left in the year
    const monthsLeftInYear = 12 - currentMonth;

    // Initialize state for monthly deposits, each initialized to 0
    const [monthlyData, setMonthlyData] = useState(Array(12).fill(0));

    const handleInputChange = (index, value) => {
      const newData = [...monthlyData];
      newData[index] = parseFloat(value); // Ensure value is parsed as float
      setMonthlyData(newData);
    };

    const saveDeposit = () => {
      setSaveDeposits(!saveDeposits);
    };

    // Calculate total monthly target
    const totalMonthlyTarget = goal / 12;

    // Calculate total deposit for all months
    const totalDeposit = monthlyData?.reduce(
      (total, deposit) => total + deposit,
      0
    );

    const RemainingDeposit = monthlyData?.reduce(
      (total, deposit) => total - deposit,
      goal
    );

    const progress =
      Math.min(totalDeposit / goal, 100) * 100
        ? Math.min(totalDeposit / goal, 100) * 100
        : 0;

    return (
      <div style={{ margin: "auto", width: "auto", height: "auto" }}>
        <div className="table-headers-action">
          <div className="table-header-left-action">{account}</div>
          <div className="table-header-right-action">
            Annual Deposit Goal:{" "}
            <span style={{ color: "#0F9CD6" }}>{currencyFormat(goal)}</span>
          </div>
        </div>
        <table
          style={{ width: "auto", marginLeft: "0", marginTop: "0" }}
          className="action-plan-input-table"
        >
          <tbody>
            {monthlyData?.map((deposit, index) => (
              <tr className="deposit-input-table" key={index}>
                <td className="deposit-input-table-month">
                  {new Date(currentYear, index).toLocaleString("en-US", {
                    month: "long",
                  })}
                </td>
                <td className="deposit-input-table-monthly-target">
                  Monthly Target: <br />
                  <span style={{ color: "#004069", marginLeft: "0.5rem" }}>
                    {currencyFormat(totalMonthlyTarget)}
                  </span>
                </td>
                <td className="deposit-input-table-monthly-target">
                  <label htmlFor={`actualDeposit${index}`}>
                    Actual Deposit
                  </label>
                  <div className="input-icon-wrapper-action">
                    <input
                      id={`actualDeposit${index}`}
                      className="deposit-input-box"
                      disabled={saveDeposits}
                      type="number"
                      value={deposit}
                      onChange={(e) => handleInputChange(index, e.target.value)}
                    />
                    {saveDeposits && (
                      <FontAwesomeIcon
                        icon={faCircleCheck}
                        size="l"
                        style={{ color: "green" }}
                        onClick={saveDeposit}
                      />
                    )}
                    {!saveDeposits && (
                      <FontAwesomeIcon
                        icon={faCircleCheck}
                        size="l"
                        style={{ color: "grey" }}
                        onClick={saveDeposit}
                      />
                    )}
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
          {/* <tfoot style={{ height: '5rem'}}>
          <tr>
            <th className='deposit-input-table-month'>Remaining Deposits Balance:</th>
            
            <td style={{ textAlign: 'center'}}></td>
          </tr>
        </tfoot> */}
        </table>
        <div className="Footer-Deposit-balance">
          Remaining Deposits Balance:{" "}
          <span style={{ color: "#0F9CD6" }}>
            {currencyFormat(RemainingDeposit)}
          </span>
        </div>
        <div className="progress-bar">
          <div className="progress-bar-fill" style={{ width: `${progress}%` }}>
            {progress?.toFixed(2)}%
          </div>
        </div>
      </div>
    );
  }

  const calculateDaysLeft = (trialEndTimestamp) => {
    const currentDate = new Date();
    const trialEndDate = new Date(trialEndTimestamp * 1000); // Convert to milliseconds
    const differenceInTime = trialEndDate?.getTime() - currentDate?.getTime();
    const differenceInDays = Math.ceil(differenceInTime / (1000 * 3600 * 24));
    return differenceInDays;
  };

  const withdrawCppAge = (cpp, spouseBday) => {
    let age = 70; // Default age set to 70

    for (let i = 0; i < cpp?.length; i++) {
      if (cpp?.[i] > 0) {
        age = i;
        break;
      }
    }

    return Math.min(age + spouseBday, 70);
  };

  function findFirstZeroYear(array) {
    // Check if the first value is 0
    if (!array) {
      return 0;
    }
    if (array[0] === 0) {
      return null;
    }

    // Iterate through the array to find the first 0
    for (let i = 0; i < array?.length; i++) {
      if (array[i] === 0) {
        return currentYear + i;
      }
    }

    // If no zero is found, return null
    return null;
  }

  const years = [];

  const currentYear = new Date().getFullYear();
  // for (let i = ageStart; i <= ageEnd - 1; i++) {
  //   years?.push(i);
  // }

  const userEndDate =
    userOptiData?.primaryUserData?.expenses?.length + new Date().getFullYear();

  for (let i = currentYear; i <= userEndDate - 1; i++) {
    years?.push(i);
  }

  const [isClicked, setIsClicked] = useState(false);

  const [isLargerThan768] = useMediaQuery("(min-width: 1200px)");
  const isMobile = !isLargerThan768;

  const fontSize = isMobile ? "14px" : "20px";

  function findNonZeroYear(array) {
    for (let i = 0; i < array?.length; i++) {
      if (array[i] !== 0) {
        return currentYear + i;
      }
    }
    return null; // or any default value you prefer if all values are 0
  }

  const TimelineCard = ({ events }) => {
    const groupedEvents = events?.reduce((acc, event) => {
      const { year } = event;
      if (year != null) {
        if (!acc[year]) {
          acc[year] = [];
        }
        acc[year].push(event);
      }
      return acc;
    }, {});

    return (
      <Box>
        {Object.keys(groupedEvents).map((year) => (
          <Box
            key={year}
            borderWidth="1px"
            borderRadius="lg"
            overflow="hidden"
            mb={6}
            border="none"
          >
            <Heading
              as="h3"
              size="lg"
              mb={4}
              color="rgba(0, 162, 212, 1)"
              fontFamily="Nunito"
              fontWeight="800"
            >
              {year}
            </Heading>
            <VStack align="start" spacing={4} position="relative">
              {groupedEvents[year].map((event, index) => (
                <HStack key={index} align="start" spacing={3} width="full">
                  <VStack align="center" spacing={0} position="relative">
                    <Box
                      as={FaCircle}
                      size="12px"
                      borderWidth="2px"
                      borderColor="rgba(0, 162, 212, 1)"
                      borderRadius="full"
                      marginTop="2px"
                      color="white"
                      zIndex="1"
                    />
                  </VStack>
                  {/* {index < groupedEvents[year].length - 1 && ( */}
                  <Box
                    position="absolute"
                    top="12px"
                    bottom="-12px"
                    marginLeft={"5px"}
                    width="2px"
                    h={"100%"}
                    backgroundColor="rgba(0, 162, 212, 1)"
                    zIndex="0"
                  />
                  {/* )} */}
                  <Box>
                    <HStack color="#00466D" align="center" spacing={3}>
                      <Icon
                        as={FontAwesomeIcon}
                        icon={event?.icon}
                        boxSize={6}
                      />
                      <Text fontWeight="bold">{event?.subHeading}</Text>
                    </HStack>
                    <Text
                      fontFamily="Nunito"
                      fontSize="14px"
                      fontWeight="600"
                      mt={2}
                    >
                      {event?.writeUp}
                    </Text>
                  </Box>
                </HStack>
              ))}
            </VStack>
          </Box>
        ))}
      </Box>
    );
  };

  const spouse1RetireYear = findFirstZeroYear(
    userOptiData?.primaryUserData?.sps1_salary
  );
  const spouse2RetireYear = findFirstZeroYear(
    userOptiData?.spouseData?.sps2_salary
  );

  const retirementEvents = {
    [spouse1RetireYear]: `${userOptiData?.primaryUserData?.sps1_name}'s retirement`,
    [spouse2RetireYear]: spouseStatus
      ? `${userOptiData?.spouseData?.sps2_name}'s retirement`
      : "",
  };

  const generateTimelineData = () => {
    const timelineData = [
      {
        subHeading: `${firstName} - APPLY FOR CANADIAN PENSION PLAN`,
        icon: faInfoSquare,
        writeUp: (
          <>
            The analysis has determined that your optimal age to begin receiving
            your Canadian Pension Plan (CPP) benefits is{" "}
            {withdrawCppAge(
              userOptiData?.primaryUserData?.sps1_cpp,
              getAge(userOptiData?.primaryUserData?.sps1_birthday)
            )}{" "}
            years old. It typically takes around 120 days (or about four months)
            to process a CPP retirement pension application. To ensure you start
            receiving your CPP payments on time, we strongly advise applying no
            later than August of this year. This timeline will help avoid any
            delays and ensure a smooth transition into receiving your pension
            benefits. To begin your application process, please visit the{" "}
            <a
              href="https://www.canada.ca/en/services/benefits/publicpensions/cpp/cpp-benefit/apply.html"
              target="_blank"
              rel="noopener noreferrer"
              style={{
                color: "#0f9cd6",
                fontStyle: "underline",
                fontWeight: "600",
              }}
            >
              My Services Canada Account (MSCA)
            </a>
            .
          </>
        ),
        year:
          currentYear +
          (withdrawCppAge(
            userOptiData?.primaryUserData?.sps1_cpp,
            getAge(userOptiData?.primaryUserData?.sps1_birthday)
          ) -
            getAge(userOptiData?.primaryUserData?.sps1_birthday)),
      },
      {
        subHeading: `${firstName} - APPLY FOR OLD AGE SECURITY`,
        icon: faHandHoldingUsd,
        writeUp: (
          <>
            The analysis has determined that your optimal age to begin receiving
            your Old Age Security (OAS) benefits is{" "}
            {withdrawCppAge(
              userOptiData?.primaryUserData?.sps1_oas,
              getAge(userOptiData?.primaryUserData?.sps1_birthday)
            )}{" "}
            years old. It typically takes around 120 days (or about four months)
            to process an OAS application. To ensure you start receiving your
            OAS payments on time, we strongly advise applying no later than
            August of this year. This timeline will help avoid any delays and
            ensure a smooth transition into receiving your pension benefits. To
            begin your application process, please visit the{" "}
            <a
              href="https://www.canada.ca/en/services/benefits/publicpensions/cpp/old-age-security/apply.html"
              target="_blank"
              rel="noopener noreferrer"
              style={{
                color: "#0f9cd6",
                fontStyle: "underline",
                fontWeight: "600",
              }}
            >
              My Services Canada Account (MSCA)
            </a>
            .
          </>
        ),
        year:
          currentYear +
          (withdrawCppAge(
            userOptiData?.primaryUserData?.sps1_oas,
            getAge(userOptiData?.primaryUserData?.sps1_birthday)
          ) -
            getAge(userOptiData?.primaryUserData?.sps1_birthday)),
      },
      {
        subHeading: `${firstName} - RETIREMENT`,
        icon: faGlassCheers,
        writeUp: `Congratulations on your retirement! Be sure to continue following your Action Plan to achieve your Optiml retirement goals.`,
        year: findFirstZeroYear(userOptiData?.primaryUserData?.sps1_salary),
      },
      {
        subHeading: "SALE OF PRIMARY RESIDENCE",
        icon: faHouse,
        writeUp: `Based on the information you provided, you indicated selling
        your primary residence in the year ${
          currentYear +
          (withdrawCppAge(
            userOptiData?.primaryUserData?.PrimaryResidence?.Sale,
            getAge(userOptiData?.primaryUserData?.sps1_birthday)
          ) -
            getAge(userOptiData?.primaryUserData?.sps1_birthday))
        }. Our analysis will guide you
        on how to best allocate the after-tax proceeds from the sale of your home.
        This strategy aims to optimize the financial benefits you receive from
        this transaction, ensuring you make the most informed decisions
        regarding the distribution of these funds into your investment accounts.`,
        year: findNonZeroYear(
          userOptiData?.primaryUserData?.PrimaryResidence?.Sale
        ),
      },
    ];
    // Handle IncomePropertyYearlySale
    userOptiData?.primaryUserData?.IncomePropertyYearlySale?.forEach(
      (sale, index) => {
        if (sale !== 0) {
          timelineData?.push({
            subHeading: `SALE OF INCOME PROPERTY`,
            icon: faHouse,
            writeUp: `Based on the information you provided, you indicated
            selling your Income Property in the year ${currentYear + index}.
            Our analysis will guide you on how to best allocate
            the after-tax proceeds from the sale of your home.
            This strategy aims to optimize the financial
            benefits you receive from this transaction, ensuring
            you make the most informed decisions regarding the
            distribution of these funds into your investment
            accounts.`,
            year: currentYear + index,
          });
        }
      }
    );

    // FHSA Convert and Primary Res Purchase
    userOptiData?.primaryUserData?.PrimaryResidence?.DownPayment?.forEach(
      (sale, index) => {
        if (sale !== 0) {
          timelineData?.push({
            subHeading: `PURCHASE OF FIRST HOME`,
            icon: faHouse,
            writeUp: `Congratulations! By following the Optiml plan, you'll be able to 
            purchase your first home in ${
              currentYear + index
            }. Through effective use of the First Home 
            Savings Account (FHSA) and other investment vehicles, you've successfully 
            saved for the down payment. Your FHSA will be fully utilized to cover these 
            costs, and any remaining funds will be transferred to your RRSP. Starting in 
            ${
              currentYear + index
            }, you will see a decrease in your annual rent expenses, as well as the 
            addition of the home asset and all associated costs to your financial plan.`,
            year: currentYear + index,
          });
        }
      }
    );

    // Ensure that we're dealing with a valid and non-empty array of goals
    if (userOptiData?.primaryUserData?.goals?.length > 0) {
      userOptiData?.primaryUserData?.goals?.forEach((goal, index) => {
        // Check if there's any non-zero value in the Budget array
        const hasActiveBudget = goal?.Budget?.some((budget) => budget !== 0);

        if (hasActiveBudget) {
          // Find the year where the budget is not zero, if needed
          const activeYearIndex = goal?.Budget?.findIndex(
            (budget) => budget !== 0
          );

          // Construct a timeline entry for each goal with an active budget
          timelineData?.push({
            subHeading: `${goal?.Name}`, // Display the name of the goal
            icon:
              goal?.Type === "Vacation"
                ? faPlane
                : goal?.Type === "Vehicle"
                ? faCar
                : goal?.Type === "Boat"
                ? faSailboat
                : goal?.Type === "Wedding"
                ? faRingsWedding
                : goal?.Type === "Renovations"
                ? faHouse
                : goal?.Type === "Education"
                ? faGraduationCap
                : faStar, // Default icon if none of the types match
            writeUp: `Congratulation, enjoy your ${goal?.Type} goal coming true, thanks to your Optiml plan!`, // Placeholder text
            year: currentYear + activeYearIndex, // Calculate the year based on the non-zero budget index
          });
        }
      });
    }

    // Handle Recreation Property Sale
    userOptiData?.primaryUserData?.RecreationPropertyYearlySale?.forEach(
      (sale, index) => {
        if (sale !== 0) {
          timelineData?.push({
            subHeading: `SALE OF RECREATIONAL PROPERTY`,
            icon: faHouse,
            writeUp: `Based on the information you provided, you indicated
            selling your recreational property in the year ${
              currentYear + index
            }.
            Our analysis will guide you on how to best allocate
            the after-tax proceeds from the sale of your home.
            This strategy aims to optimize the financial
            benefits you receive from this transaction, ensuring
            you make the most informed decisions regarding the
            distribution of these funds into your investment
            accounts.`,
            year: currentYear + index,
          });
        }
      }
    );

    // Handle Land Sale
    userOptiData?.primaryUserData?.LandYearlySale?.forEach((sale, index) => {
      if (sale !== 0) {
        timelineData?.push({
          subHeading: `SALE OF LAND`,
          icon: faHouse,
          writeUp: `Based on the information you provided, you indicated
            selling your land in the year ${currentYear + index}.
            Our analysis will guide you on how to best allocate
            the after-tax proceeds from the sale of this land.
            This strategy aims to optimize the financial
            benefits you receive from this transaction, ensuring
            you make the most informed decisions regarding the
            distribution of these funds into your investment
            accounts.`,
          year: currentYear + index,
        });
      }
    });

    // Handle Business Sale
    userOptiData?.primaryUserData?.businessYearlySale?.forEach(
      (sale, index) => {
        if (sale !== 0) {
          timelineData?.push({
            subHeading: `SALE OF BUSINESS`,
            icon: faBusinessFront,
            writeUp: `Based on the information you provided, you indicated
            selling your business in the year ${currentYear + index}.
            Our analysis will guide you on how to best allocate
            the after-tax proceeds from the sale of your business.
            This strategy aims to optimize the financial
            benefits you receive from this transaction, ensuring
            you make the most informed decisions regarding the
            distribution of these funds into your investment
            accounts.`,
            year: currentYear + index,
          });
        }
      }
    );

    if (spouseStatus) {
      timelineData?.push(
        {
          subHeading: `${spouseFirstName} - APPLY FOR CANADIAN PENSION PLAN`,
          icon: faInfoSquare,
          writeUp: (
            <>
              The analysis has determined that your optimal age to begin
              receiving your Canadian Pension Plan (CPP) benefits is{" "}
              {withdrawCppAge(
                userOptiData?.spouseData?.sps2_cpp,
                getAge(userOptiData?.spouseData?.sps2_birthday)
              )}{" "}
              years old. It typically takes around 120 days (or about four
              months) to process a CPP retirement pension application. To ensure
              you start receiving your CPP payments on time, we strongly advise
              applying no later than August of this year. This timeline will
              help avoid any delays and ensure a smooth transition into
              receiving your pension benefits. To begin your application
              process, please visit the{" "}
              <a
                href="https://www.canada.ca/en/services/benefits/publicpensions/cpp/cpp-benefit/apply.html"
                target="_blank"
                rel="noopener noreferrer"
                style={{
                  color: "#0f9cd6",
                  fontStyle: "underline",
                  fontWeight: "600",
                }}
              >
                My Services Canada Account (MSCA)
              </a>
              .
            </>
          ),
          year:
            currentYear +
            (withdrawCppAge(
              userOptiData?.spouseData?.sps2_cpp,
              getAge(userOptiData?.spouseData?.sps2_birthday)
            ) -
              getAge(userOptiData?.spouseData?.sps2_birthday)),
        },
        {
          subHeading: `${spouseFirstName} - APPLY FOR OLD AGE SECURITY`,
          icon: faHandHoldingUsd,
          writeUp: (
            <>
              The analysis has determined that your optimal age to begin
              receiving your Old Age Security (OAS) benefits is{" "}
              {withdrawCppAge(
                userOptiData?.spouseData?.sps2_oas,
                getAge(userOptiData?.spouseData?.sps2_birthday)
              )}{" "}
              years old. It typically takes around 120 days (or about four
              months) to process an OAS application. To ensure you start
              receiving your OAS payments on time, we strongly advise applying
              no later than August of this year. This timeline will help avoid
              any delays and ensure a smooth transition into receiving your
              pension benefits. To begin your application process, please visit
              the{" "}
              <a
                href="https://www.canada.ca/en/services/benefits/publicpensions/cpp/old-age-security/apply.html"
                target="_blank"
                rel="noopener noreferrer"
                style={{
                  color: "#0f9cd6",
                  fontStyle: "underline",
                  fontWeight: "600",
                }}
              >
                My Services Canada Account (MSCA)
              </a>
              .
            </>
          ),
          year:
            currentYear +
            (withdrawCppAge(
              userOptiData?.spouseData?.sps2_oas,
              getAge(userOptiData?.spouseData?.sps2_birthday)
            ) -
              getAge(userOptiData?.spouseData?.sps2_birthday)),
        },
        {
          subHeading: `${spouseFirstName} - RETIREMENT`,
          icon: faGlassCheers,
          writeUp: `Congratulations on your retirement! Be sure to continue following your Action Plan to achieve your Optiml retirement goals.`,
          year: findFirstZeroYear(userOptiData?.spouseData?.sps2_salary),
        }
      );
    }

    return timelineData;
  };

  const timelineData = generateTimelineData();

  const currentDate = new Date(); // Get the current date
  const currentMonth = currentDate.getMonth(); // Get the current month (0-indexed, January is 0)
  const monthsLeft = listPlace !== 0 ? 12 : 12 - currentMonth;

  const [isLargerThan1200] = useMediaQuery(
    "(min-width: 500px) and (max-width: 1250px)"
  );

  return (
    <>
      <StripeUpgradeModal
        isOpen={showStripeModal}
        closeModal={UpgradeStripeModal}
      />
      <Modal show={showModal} onClose={() => setShowModal(false)} />
      <Box
        width={"100%"}
        bg={"#F3FAFF"}
        paddingTop={"15px"}
        display={"flex"}
        alignContent={"center"}
        alignItems={"center"}
        justifyContent={"center"}
      >
        <div className="action-plan-main-box">
          {/* <div className="toggle-container">
                  <button
                    className={`toggle-button ${activePage === 'actionPlan' ? 'active' : ''}`}
                    onClick={() => setActivePage('actionPlan')}
                  >
                    Action Plan
                  </button>
                  WILL ADD SOME DAY
                  {currentYear === year && <button
                    className={`toggle-button ${activePage === 'progressTracker' ? 'active' : 'inactive'}`}
                    onClick={() => setActivePage('progressTracker')}
                  >
                    Progress Tracker
                  </button>}
                </div> */}
          <Box
            display={"flex"}
            padding={"20px"}
            flexDirection={"column"}
            alignItems={"flex-start"}
            alignSelf={"stretch"}
            justifyContent={"space-between"}
            gap={"10px"}
            borderRadius={"5px"}
            border={"1px solid #00A2D4"}
            bg={"#FFF"}
          >
            <HStack justifyContent={"space-between"} alignSelf={"stretch"}>
              <Text
                color={"#00A2D4"}
                fontFamily={"Nunito"}
                fontSize={isMobile ? "16px" : "30px"}
                fontStyle={"normal"}
                fontWeight={"700"}
                lineHeight={"normal"}
              >
                Welcome to your {year} Action Plan
              </Text>
              {activePage === "actionPlan" &&
                (!stripePlan?.trial_end ||
                  calculateDaysLeft(stripePlan?.trial_end) < 1) && (
                  <Menu>
                    <MenuButton>
                      <HStack
                        justifyContent={"end"}
                        gap={"10px"}
                        cursor={"pointer"}
                      >
                        <Text
                          color={"#00A2D4"}
                          textAlign={"right"}
                          fontFamily={"Nunito"}
                          fontSize={isMobile ? "13px" : "20px"}
                          fontStyle={"normal"}
                          fontWeight={"700"}
                          lineHeight={"130%"}
                        >
                          Year
                        </Text>
                        {ageEnd && (
                          <Icon
                            as={NextYearIcon}
                            svgwidth={isMobile ? "13px" : "15px"}
                            svgheight={isMobile ? "13px" : "15px"}
                            style={{
                              transform: isClicked
                                ? "rotate(90deg)"
                                : "rotate(0deg)",
                              transition: "transform 0.3s ease-in-out",
                            }}
                            onClick={() => setIsClicked(!isClicked)}
                          />
                        )}
                      </HStack>
                    </MenuButton>
                    <MenuList
                      bg={"#00466D"}
                      w={"150px"}
                      h={"300px"}
                      flexShrink={0}
                      overflowY={"auto"}
                      borderRadius={"5px"}
                    >
                      {years?.map((yr, index) => (
                        <MenuItem
                          bg={"#00466D"}
                          key={index}
                          onClick={(e) => {
                            e.preventDefault();
                            setListPlace(index);
                            setYear(yr);
                            setAgeEnd(true);
                            setAgeStart(true);
                            setNextYear(yr + 1);
                          }}
                        >
                          <Text
                            color={"#FFF"}
                            fontFamily={"Nunito"}
                            fontSize={"15px"}
                            fontStyle={"normal"}
                            fontWeight={"700"}
                            lineHeight={"normal"}
                          >
                            {yr}
                            {retirementEvents[yr] && (
                              <Text as={"span"} fontSize={"12px"}>
                                {" "}
                                - {retirementEvents[yr]}
                              </Text>
                            )}
                          </Text>
                        </MenuItem>
                      ))}
                    </MenuList>
                  </Menu>
                )}
              {activePage === "actionPlan" &&
                calculateDaysLeft(stripePlan?.trial_end) >= 1 && (
                  <Menu>
                    <MenuButton>
                      <HStack
                        justifyContent={"end"}
                        gap={"10px"}
                        cursor={"pointer"}
                      >
                        <Text
                          color={"#00A2D4"}
                          textAlign={"right"}
                          fontFamily={"Nunito"}
                          fontSize={isMobile ? "13px" : "20px"}
                          fontStyle={"normal"}
                          fontWeight={"700"}
                          lineHeight={"130%"}
                        >
                          Year
                        </Text>
                        {ageEnd && (
                          <Icon
                            as={NextYearIcon}
                            svgwidth={isMobile ? "13px" : "15px"}
                            svgheight={isMobile ? "13px" : "15px"}
                            style={{
                              transform: isClicked
                                ? "rotate(90deg)"
                                : "rotate(0deg)",
                              transition: "transform 0.3s ease-in-out",
                            }}
                            onClick={() => setIsClicked(!isClicked)}
                          />
                        )}
                      </HStack>
                    </MenuButton>
                    <MenuList
                      bg={"#00466D"}
                      w={"150px"}
                      h={"300px"}
                      flexShrink={0}
                      overflowY={"auto"}
                      borderRadius={"5px"}
                    >
                      {years?.map((yr, index) => (
                        <MenuItem
                          bg={"#00466D"}
                          key={index}
                          onClick={UpgradeStripeModal}
                        >
                          <Text
                            color={"#FFF"}
                            fontFamily={"Nunito"}
                            fontSize={"15px"}
                            fontStyle={"normal"}
                            fontWeight={"700"}
                            lineHeight={"normal"}
                          >
                            {yr}
                            {retirementEvents[yr] && (
                              <Text as={"span"} fontSize={"12px"}>
                                {" "}
                                - {retirementEvents[yr]}
                              </Text>
                            )}
                          </Text>
                        </MenuItem>
                      ))}
                    </MenuList>
                  </Menu>
                )}
            </HStack>
            <Box
              display={"flex"}
              flexDirection={"column"}
              alignItems={"flex-start"}
              gap={"10px"}
            >
              <HStack>
                <Text
                  color={"#004069"}
                  fontFamily={"Nunito"}
                  fontSize={isMobile ? "14px" : "16px"}
                  fontStyle={"normal"}
                  fontWeight={"600"}
                  lineHeight={"130%"}
                >
                  Strategy:
                </Text>
                <Text
                  color={"#00A2D4"}
                  textAlign={"right"}
                  fontFamily={"Nunito"}
                  fontSize={isMobile ? "14px" : "16px"}
                  fontStyle={"normal"}
                  fontWeight={"800"}
                  lineHeight={"130%"}
                >
                  {userOptiData?.primaryUserData?.optimizationType}
                </Text>
              </HStack>
              <HStack>
                <Text
                  color={"#004069"}
                  fontFamily={"Nunito"}
                  fontSize={isMobile ? "14px" : "16px"}
                  fontStyle={"normal"}
                  fontWeight={"600"}
                  lineHeight={"130%"}
                >
                  Analysis Date:
                </Text>
                <Text
                  color={"#00A2D4"}
                  textAlign={"right"}
                  fontFamily={"Nunito"}
                  fontSize={isMobile ? "14px" : "16px"}
                  fontStyle={"normal"}
                  fontWeight={"800"}
                  lineHeight={"130%"}
                >
                  {formatDate(userOptiData?.primaryUserData?.planRunDate)}
                </Text>
              </HStack>
              <HStack>
                <Text
                  color={"#004069"}
                  fontFamily={"Nunito"}
                  fontSize={isMobile ? "14px" : "16px"}
                  fontStyle={"normal"}
                  fontWeight={"600"}
                  lineHeight={"130%"}
                >
                  Minimum Transaction limit:
                </Text>
                {/* <Text
                  color={"#00A2D4"}
                  textAlign={"right"}
                  fontFamily={"Nunito"}
                  fontSize={isMobile ? "14px" : "16px"}
                  fontStyle={"normal"}
                  fontWeight={"800"}
                  lineHeight={"130%"}
                >
                  ${minTrx}
                </Text> */}
                <DropDownButton
                  placeholder={"Select"}
                  dropDownItems={[
                    { key: 5, value: "$5.00" },
                    { key: 50, value: "$50.00" },
                    { key: 500, value: "$500.00" },
                    { key: 1000, value: "$1,000.00" },
                    { key: 5000, value: "$5,000.00" },
                  ]}
                  value={minTrx}
                  onChange={(key) => {
                    setMinTrx(key);
                  }}
                  // customWidth={"100%"}
                  // MenuCustomWidth={isMobile && "100%"}
                />
              </HStack>
            </Box>
          </Box>
          {!isMobile && (
            <Box
              display={"flex"}
              padding={"20px"}
              flexDirection={"column"}
              alignItems={"flex-start"}
              gap={"10px"}
              alignSelf={"stretch"}
              borderRadius={"5px"}
              border={"1px solid #00A2D4"}
              bg={"#F2FAFD"}
            >
              <Box
                display={"flex"}
                padding={"20px"}
                justifyContent={"space-between"}
                alignItems={"center"}
                alignSelf={"stretch"}
                border={"1px solid #00A2D4"}
                borderRadius={"5px"}
                bg={"#FFF"}
              >
                <Text
                  color={"#004069"}
                  fontFamily={"Nunito"}
                  fontSize={isMobile ? "12px" : "19px"}
                  fontStyle={"normal"}
                  fontWeight={"600"}
                  lineHeight={"normal"}
                  width={"90%"}
                >
                  To ensure you stay on track with your financial goals, it's
                  important to focus on the following key areas this year:
                </Text>

                {stripePlan?.items?.data?.[0]?.price?.lookup_key !=
                  "OPTIML_ESSENTIALS" &&
                  stripePlan?.items?.data?.[0]?.price?.lookup_key !=
                    "OPTIML_ESSENTIALS_M" &&
                  listPlace === 0 && (
                    <div className="action-plan-download-btns">
                      <div
                        className="action-plan-download-btn"
                        onMouseEnter={() => setHoverCSV(true)}
                        onMouseLeave={() => setHoverCSV(false)}
                      >
                        <DownloadPDF
                          spouse1Data={userOptiData?.primaryUserData}
                          spouse2Data={userOptiData?.spouseData}
                          firstName={firstName}
                          lastName={lastName}
                          spouseFirstName={spouseFirstName}
                          spouseLastName={spouseLastName}
                          year={year}
                          index={listPlace}
                          graphImage={graphImage}
                          spouseStatus={spouseStatus}
                          spouseGraphImage={spouseGraphImage}
                          userTFSAInfo={userTFSAInfo}
                          userFHSAInfo={userFHSAInfo}
                          userNonRegInvestmentsInfo={userNonRegInvestmentsInfo}
                          userFixedIncomeInfo={userFixedIncomeInfo}
                          userRRSPInfo={userRRSPInfo}
                          userLiraInfo={userLiraInfo}
                          userPensionInfo={userPensionInfo}
                          userBusinessInfo={userBusinessInfo}
                        />
                      </div>
                    </div>
                  )}
              </Box>

              <Box
                display={"flex"}
                padding={"20px"}
                flexDirection={"column"}
                alignItems={"flex-start"}
                gap={"10px"}
                alignSelf={"stretch"}
                borderRadius={"5px"}
                border={"1px solid #1EADD9"}
                bg={"#FFF"}
              >
                <Text
                  alignSelf={"stretch"}
                  color={"#004069"}
                  fontFamily={"Nunito"}
                  fontSize={"24px"}
                  fontStyle={"normal"}
                  fontWeight={"700"}
                  lineHeight={"normal"}
                >
                  {firstName}, this year you need to...
                </Text>
                <Box
                  display={"flex"}
                  justifyContent={"space-between"}
                  alignItems={"center"}
                  alignSelf={"stretch"}
                  bg={"#FFF"}
                >
                  <Box
                    display={"flex"}
                    width={"48%"}
                    padding={"10px"}
                    flexDirection={"column"}
                    alignItems={"flex-start"}
                    gap={"10px"}
                    alignSelf={"stretch"}
                    bg={"#FFF"}
                  >
                    <VStack spacing={"10px"} alignItems={"baseline"}>
                      {userOptiData?.primaryUserData?.sps1_tfsaWithdrawals?.[
                        listPlace
                      ] >= minTrx && (
                        <li className="action-plan-instructions">
                          Withdraw{" "}
                          <Text
                            as={"span"}
                            color={"#00A2D4"}
                            fontFamily={"Nunito"}
                            fontSize={fontSize}
                            fontStyle={"normal"}
                            fontWeight={"800"}
                            lineHeight={"normal"}
                          >
                            {currencyFormat(
                              userOptiData?.primaryUserData
                                ?.sps1_tfsaWithdrawals?.[listPlace]
                            )}{" "}
                          </Text>
                          from your
                          <Text
                            as={"span"}
                            color={"#004069"}
                            fontFamily={"Nunito"}
                            fontSize={fontSize}
                            fontStyle={"normal"}
                            fontWeight={"800"}
                            lineHeight={"normal"}
                          >
                            {" "}
                            TFSA{" "}
                          </Text>
                          <br />
                          <Text
                            as={"span"}
                            color={"#00A2D4"}
                            fontFamily={"Nunito"}
                            fontSize={fontSize}
                            fontStyle={"normal"}
                            fontWeight={"800"}
                            lineHeight={"normal"}
                            ml={"26px"}
                          >
                            {currencyFormat(
                              userOptiData?.primaryUserData
                                ?.sps1_tfsaWithdrawals?.[listPlace] / monthsLeft
                            )}{" "}
                          </Text>
                          per month
                          <br />
                          <Text
                            color={"#004069"}
                            fontFamily={"Nunito"}
                            fontSize={"14px"}
                            fontStyle={"normal"}
                            fontWeight={"400"}
                            lineHeight={"normal"}
                            ml={"26px"}
                            mt={"10px"}
                          >
                            Expected annual growth rate:{" "}
                            {userTFSAInfo?.TFSA_ROR}%
                          </Text>
                        </li>
                      )}
                      {userOptiData?.primaryUserData?.sps1_fhsaWithdrawals?.[
                        listPlace
                      ] >= minTrx && (
                        <li className="action-plan-instructions">
                          Withdraw{" "}
                          <Text
                            as={"span"}
                            color={"#00A2D4"}
                            fontFamily={"Nunito"}
                            fontSize={fontSize}
                            fontStyle={"normal"}
                            fontWeight={"800"}
                            lineHeight={"normal"}
                          >
                            {currencyFormat(
                              userOptiData?.primaryUserData
                                ?.sps1_fhsaWithdrawals?.[listPlace]
                            )}{" "}
                          </Text>
                          from your
                          <Text
                            as={"span"}
                            color={"#004069"}
                            fontFamily={"Nunito"}
                            fontSize={fontSize}
                            fontStyle={"normal"}
                            fontWeight={"800"}
                            lineHeight={"normal"}
                          >
                            {" "}
                            FHSA{" "}
                          </Text>
                          <br />
                          <Text
                            as={"span"}
                            color={"#00A2D4"}
                            fontFamily={"Nunito"}
                            fontSize={fontSize}
                            fontStyle={"normal"}
                            fontWeight={"800"}
                            lineHeight={"normal"}
                            ml={"26px"}
                          >
                            {currencyFormat(
                              userOptiData?.primaryUserData
                                ?.sps1_fhsaWithdrawals?.[listPlace]
                            )}{" "}
                          </Text>
                          during home purchase month
                          <br />
                          <Text
                            color={"#004069"}
                            fontFamily={"Nunito"}
                            fontSize={"14px"}
                            fontStyle={"normal"}
                            fontWeight={"400"}
                            lineHeight={"normal"}
                            ml={"26px"}
                            mt={"10px"}
                          >
                            Expected annual growth rate:{" "}
                            {userFHSAInfo?.fhsaGrowth}%
                          </Text>
                        </li>
                      )}
                      {userOptiData?.primaryUserData
                        ?.sps1_registeredWithdrawals?.[listPlace] >= minTrx && (
                        <li className="action-plan-instructions">
                          Withdraw{" "}
                          <Text
                            as={"span"}
                            color={"#00A2D4"}
                            fontFamily={"Nunito"}
                            fontSize={fontSize}
                            fontStyle={"normal"}
                            fontWeight={"800"}
                            lineHeight={"normal"}
                          >
                            {currencyFormat(
                              userOptiData?.primaryUserData
                                ?.sps1_registeredWithdrawals?.[listPlace]
                            )}{" "}
                          </Text>
                          from your
                          <Text
                            as={"span"}
                            color={"#004069"}
                            fontFamily={"Nunito"}
                            fontSize={fontSize}
                            fontStyle={"normal"}
                            fontWeight={"800"}
                            lineHeight={"normal"}
                          >
                            {" "}
                            RRSP{" "}
                          </Text>
                          <br />
                          <Text
                            as={"span"}
                            color={"#00A2D4"}
                            fontFamily={"Nunito"}
                            fontSize={fontSize}
                            fontStyle={"normal"}
                            fontWeight={"800"}
                            lineHeight={"normal"}
                            ml={"26px"}
                          >
                            {currencyFormat(
                              userOptiData?.primaryUserData
                                ?.sps1_registeredWithdrawals?.[listPlace] /
                                monthsLeft
                            )}{" "}
                          </Text>
                          per month
                          <br />
                          <Text
                            color={"#004069"}
                            fontFamily={"Nunito"}
                            fontSize={"14px"}
                            fontStyle={"normal"}
                            fontWeight={"400"}
                            lineHeight={"normal"}
                            ml={"26px"}
                            mt={"10px"}
                          >
                            Expected annual growth rate:{" "}
                            {userRRSPInfo?.RRSP_ROR}%
                          </Text>
                        </li>
                      )}
                      {userOptiData?.primaryUserData
                        ?.sps1_nonregisteredWithdrawals?.[listPlace] >=
                        minTrx && (
                        <li className="action-plan-instructions">
                          Withdraw{" "}
                          <Text
                            as={"span"}
                            color={"#00A2D4"}
                            fontFamily={"Nunito"}
                            fontSize={fontSize}
                            fontStyle={"normal"}
                            fontWeight={"800"}
                            lineHeight={"normal"}
                          >
                            {currencyFormat(
                              userOptiData?.primaryUserData
                                ?.sps1_nonregisteredWithdrawals?.[listPlace]
                            )}{" "}
                          </Text>
                          from your
                          <Text
                            as={"span"}
                            color={"#004069"}
                            fontFamily={"Nunito"}
                            fontSize={fontSize}
                            fontStyle={"normal"}
                            fontWeight={"800"}
                            lineHeight={"normal"}
                          >
                            {" "}
                            Non-Registered{" "}
                          </Text>
                          <br />
                          <Text
                            as={"span"}
                            color={"#00A2D4"}
                            fontFamily={"Nunito"}
                            fontSize={fontSize}
                            fontStyle={"normal"}
                            fontWeight={"800"}
                            lineHeight={"normal"}
                            ml={"26px"}
                          >
                            {currencyFormat(
                              userOptiData?.primaryUserData
                                ?.sps1_nonregisteredWithdrawals?.[listPlace] /
                                monthsLeft
                            )}{" "}
                          </Text>
                          per month
                          <br />
                          <Text
                            color={"#004069"}
                            fontFamily={"Nunito"}
                            fontSize={"14px"}
                            fontStyle={"normal"}
                            fontWeight={"400"}
                            lineHeight={"normal"}
                            ml={"26px"}
                            mt={"10px"}
                          >
                            Expected annual growth rate:{" "}
                            {userNonRegInvestmentsInfo?.Non_Reg_ROR}%
                          </Text>
                        </li>
                      )}
                      {userOptiData?.primaryUserData?.sps1_cashWithdrawals?.[
                        listPlace
                      ] >= minTrx && (
                        <li className="action-plan-instructions">
                          Withdraw{" "}
                          <Text
                            as={"span"}
                            color={"#00A2D4"}
                            fontFamily={"Nunito"}
                            fontSize={fontSize}
                            fontStyle={"normal"}
                            fontWeight={"800"}
                            lineHeight={"normal"}
                          >
                            {currencyFormat(
                              userOptiData?.primaryUserData
                                ?.sps1_cashWithdrawals?.[listPlace]
                            )}{" "}
                          </Text>
                          from your
                          <Text
                            as={"span"}
                            color={"#004069"}
                            fontFamily={"Nunito"}
                            fontSize={fontSize}
                            fontStyle={"normal"}
                            fontWeight={"800"}
                            lineHeight={"normal"}
                          >
                            {" "}
                            Cash Savings{" "}
                          </Text>
                          <br />
                          <Text
                            as={"span"}
                            color={"#00A2D4"}
                            fontFamily={"Nunito"}
                            fontSize={fontSize}
                            fontStyle={"normal"}
                            fontWeight={"800"}
                            lineHeight={"normal"}
                            ml={"26px"}
                          >
                            {currencyFormat(
                              userOptiData?.primaryUserData
                                ?.sps1_cashWithdrawals?.[listPlace] / monthsLeft
                            )}{" "}
                          </Text>
                          per month
                        </li>
                      )}
                      {userOptiData?.primaryUserData
                        ?.sps1_fixedIncomeWithdrawals?.[listPlace] >=
                        minTrx && (
                        <li className="action-plan-instructions">
                          Withdraw{" "}
                          <Text
                            as={"span"}
                            color={"#00A2D4"}
                            fontFamily={"Nunito"}
                            fontSize={fontSize}
                            fontStyle={"normal"}
                            fontWeight={"800"}
                            lineHeight={"normal"}
                          >
                            {currencyFormat(
                              userOptiData?.primaryUserData
                                ?.sps1_fixedIncomeWithdrawals?.[listPlace]
                            )}{" "}
                          </Text>
                          from your
                          <Text
                            as={"span"}
                            color={"#004069"}
                            fontFamily={"Nunito"}
                            fontSize={fontSize}
                            fontStyle={"normal"}
                            fontWeight={"800"}
                            lineHeight={"normal"}
                          >
                            {" "}
                            Fixed Income{" "}
                          </Text>
                          <br />
                          <Text
                            as={"span"}
                            color={"#00A2D4"}
                            fontFamily={"Nunito"}
                            fontSize={fontSize}
                            fontStyle={"normal"}
                            fontWeight={"800"}
                            lineHeight={"normal"}
                            ml={"26px"}
                          >
                            {currencyFormat(
                              userOptiData?.primaryUserData
                                ?.sps1_fixedIncomeWithdrawals?.[listPlace] /
                                monthsLeft
                            )}{" "}
                          </Text>
                          per month
                          <br />
                          <Text
                            color={"#004069"}
                            fontFamily={"Nunito"}
                            fontSize={"14px"}
                            fontStyle={"normal"}
                            fontWeight={"400"}
                            lineHeight={"normal"}
                            ml={"26px"}
                            mt={"10px"}
                          >
                            Expected annual growth rate: {averageInterest}%
                          </Text>
                        </li>
                      )}
                      {userOptiData?.primaryUserData?.sps1_liraWithdrawals?.[
                        listPlace
                      ] >= minTrx && (
                        <li className="action-plan-instructions">
                          Withdraw{" "}
                          <Text
                            as={"span"}
                            color={"#00A2D4"}
                            fontFamily={"Nunito"}
                            fontSize={fontSize}
                            fontStyle={"normal"}
                            fontWeight={"800"}
                            lineHeight={"normal"}
                          >
                            {currencyFormat(
                              userOptiData?.primaryUserData
                                ?.sps1_liraWithdrawals?.[listPlace]
                            )}{" "}
                          </Text>
                          from your
                          <Text
                            as={"span"}
                            color={"#004069"}
                            fontFamily={"Nunito"}
                            fontSize={fontSize}
                            fontStyle={"normal"}
                            fontWeight={"800"}
                            lineHeight={"normal"}
                          >
                            {" "}
                            LIRA{" "}
                          </Text>
                          <Text
                            as={"span"}
                            color={"#00A2D4"}
                            fontFamily={"Nunito"}
                            fontSize={fontSize}
                            fontStyle={"normal"}
                            fontWeight={"800"}
                            lineHeight={"normal"}
                          >
                            {currencyFormat(
                              userOptiData?.primaryUserData
                                ?.sps1_liraWithdrawals?.[listPlace] / monthsLeft
                            )}{" "}
                          </Text>
                          per month
                          <br />
                          <Text
                            color={"#004069"}
                            fontFamily={"Nunito"}
                            fontSize={"14px"}
                            fontStyle={"normal"}
                            fontWeight={"400"}
                            lineHeight={"normal"}
                            ml={"26px"}
                            mt={"10px"}
                          >
                            Expected annual growth rate:{" "}
                            {userLiraInfo?.LIRA_ROR}%
                          </Text>
                        </li>
                      )}
                      {userOptiData?.primaryUserData?.sps1_tfsaContributions?.[
                        listPlace
                      ] >= minTrx && (
                        <li className="action-plan-instructions">
                          Deposit{" "}
                          <Text
                            as={"span"}
                            color={"#00A2D4"}
                            fontFamily={"Nunito"}
                            fontSize={fontSize}
                            fontStyle={"normal"}
                            fontWeight={"800"}
                            lineHeight={"normal"}
                          >
                            {currencyFormat(
                              userOptiData?.primaryUserData
                                ?.sps1_tfsaContributions?.[listPlace]
                            )}{" "}
                          </Text>
                          into your
                          <Text
                            as={"span"}
                            color={"#004069"}
                            fontFamily={"Nunito"}
                            fontSize={fontSize}
                            fontStyle={"normal"}
                            fontWeight={"800"}
                            lineHeight={"normal"}
                          >
                            {" "}
                            TFSA{" "}
                          </Text>
                          <Text
                            as={"span"}
                            color={"#00A2D4"}
                            fontFamily={"Nunito"}
                            fontSize={fontSize}
                            fontStyle={"normal"}
                            fontWeight={"800"}
                            lineHeight={"normal"}
                          >
                            {currencyFormat(
                              userOptiData?.primaryUserData
                                ?.sps1_tfsaContributions?.[listPlace] /
                                monthsLeft
                            )}{" "}
                          </Text>
                          per month
                          <br />
                          <Text
                            color={"#004069"}
                            fontFamily={"Nunito"}
                            fontSize={"14px"}
                            fontStyle={"normal"}
                            fontWeight={"400"}
                            lineHeight={"normal"}
                            ml={"26px"}
                            mt={"10px"}
                          >
                            Expected annual growth rate:{" "}
                            {userTFSAInfo?.TFSA_ROR}%
                          </Text>
                        </li>
                      )}
                      {userOptiData?.primaryUserData?.sps1_fhsaContributions?.[
                        listPlace
                      ] >= minTrx && (
                        <li className="action-plan-instructions">
                          Deposit{" "}
                          <Text
                            as={"span"}
                            color={"#00A2D4"}
                            fontFamily={"Nunito"}
                            fontSize={fontSize}
                            fontStyle={"normal"}
                            fontWeight={"800"}
                            lineHeight={"normal"}
                          >
                            {currencyFormat(
                              userOptiData?.primaryUserData
                                ?.sps1_fhsaContributions?.[listPlace]
                            )}{" "}
                          </Text>
                          into your
                          <Text
                            as={"span"}
                            color={"#004069"}
                            fontFamily={"Nunito"}
                            fontSize={fontSize}
                            fontStyle={"normal"}
                            fontWeight={"800"}
                            lineHeight={"normal"}
                          >
                            {" "}
                            FHSA{" "}
                          </Text>
                          <Text
                            as={"span"}
                            color={"#00A2D4"}
                            fontFamily={"Nunito"}
                            fontSize={fontSize}
                            fontStyle={"normal"}
                            fontWeight={"800"}
                            lineHeight={"normal"}
                          >
                            {currencyFormat(
                              userOptiData?.primaryUserData
                                ?.sps1_fhsaContributions?.[listPlace] /
                                monthsLeft
                            )}{" "}
                          </Text>
                          per month
                          <br />
                          <Text
                            color={"#004069"}
                            fontFamily={"Nunito"}
                            fontSize={"14px"}
                            fontStyle={"normal"}
                            fontWeight={"400"}
                            lineHeight={"normal"}
                            ml={"26px"}
                            mt={"10px"}
                          >
                            Expected annual growth rate:{" "}
                            {userFHSAInfo?.fhsaGrowth}%
                          </Text>
                        </li>
                      )}
                      {safeAdd(
                        userOptiData?.primaryUserData
                          ?.sps1_registeredContributions?.[listPlace],
                        userOptiData?.primaryUserData
                          ?.sps1_registeredContributionsMatched?.[listPlace]
                      ) >= minTrx && (
                        <li className="action-plan-instructions">
                          Deposit{" "}
                          <Text
                            as={"span"}
                            color={"#00A2D4"}
                            fontFamily={"Nunito"}
                            fontSize={fontSize}
                            fontStyle={"normal"}
                            fontWeight={"800"}
                            lineHeight={"normal"}
                          >
                            {currencyFormat(
                              safeAdd(
                                userOptiData?.primaryUserData
                                  ?.sps1_registeredContributions?.[listPlace],
                                userOptiData?.primaryUserData
                                  ?.sps1_registeredContributionsMatched?.[
                                  listPlace
                                ]
                              )
                            )}{" "}
                          </Text>
                          into your
                          <Text
                            as={"span"}
                            color={"#004069"}
                            fontFamily={"Nunito"}
                            fontSize={fontSize}
                            fontStyle={"normal"}
                            fontWeight={"800"}
                            lineHeight={"normal"}
                          >
                            {" "}
                            RRSP{" "}
                          </Text>
                          <br />
                          <Text
                            as={"span"}
                            color={"#00A2D4"}
                            fontFamily={"Nunito"}
                            fontSize={fontSize}
                            fontStyle={"normal"}
                            fontWeight={"800"}
                            lineHeight={"normal"}
                            ml={"26px"}
                          >
                            {currencyFormat(
                              safeAdd(
                                userOptiData?.primaryUserData
                                  ?.sps1_registeredContributions?.[listPlace] /
                                  monthsLeft,
                                userOptiData?.primaryUserData
                                  ?.sps1_registeredContributionsMatched?.[
                                  listPlace
                                ] / monthsLeft
                              )
                            )}{" "}
                          </Text>
                          per month
                          <br />
                          <Text
                            color={"#004069"}
                            fontFamily={"Nunito"}
                            fontSize={"14px"}
                            fontStyle={"normal"}
                            fontWeight={"400"}
                            lineHeight={"normal"}
                            ml={"26px"}
                            mt={"10px"}
                          >
                            Expected annual growth rate:{" "}
                            {userRRSPInfo?.RRSP_ROR}%
                          </Text>
                        </li>
                      )}
                      {userOptiData?.primaryUserData
                        ?.sps1_nonRegisteredContributions?.[listPlace] >=
                        minTrx && (
                        <li className="action-plan-instructions">
                          Deposit{" "}
                          <Text
                            as={"span"}
                            color={"#00A2D4"}
                            fontFamily={"Nunito"}
                            fontSize={fontSize}
                            fontStyle={"normal"}
                            fontWeight={"800"}
                            lineHeight={"normal"}
                          >
                            {currencyFormat(
                              userOptiData?.primaryUserData
                                ?.sps1_nonRegisteredContributions?.[listPlace]
                            )}{" "}
                          </Text>
                          into your
                          <Text
                            as={"span"}
                            color={"#004069"}
                            fontFamily={"Nunito"}
                            fontSize={fontSize}
                            fontStyle={"normal"}
                            fontWeight={"800"}
                            lineHeight={"normal"}
                          >
                            {" "}
                            Non-Registered{" "}
                          </Text>
                          <br />
                          <Text
                            as={"span"}
                            color={"#00A2D4"}
                            fontFamily={"Nunito"}
                            fontSize={fontSize}
                            fontStyle={"normal"}
                            fontWeight={"800"}
                            lineHeight={"normal"}
                            ml={"26px"}
                          >
                            {currencyFormat(
                              userOptiData?.primaryUserData
                                ?.sps1_nonRegisteredContributions?.[listPlace] /
                                monthsLeft
                            )}{" "}
                          </Text>
                          per month
                          <br />
                          <Text
                            color={"#004069"}
                            fontFamily={"Nunito"}
                            fontSize={"14px"}
                            fontStyle={"normal"}
                            fontWeight={"400"}
                            lineHeight={"normal"}
                            ml={"26px"}
                            mt={"10px"}
                          >
                            Expected annual growth rate:{" "}
                            {userNonRegInvestmentsInfo?.Non_Reg_ROR}%
                          </Text>
                        </li>
                      )}
                      {/* {userOptiData?.primaryUserData
                        ?.sps1_registeredContributionsMatched?.[listPlace] >=
                        minTrx && (
                        <li className="action-plan-instructions">
                          Your employer will contribute{" "}
                          <Text
                            as={"span"}
                            color={"#00A2D4"}
                            fontFamily={"Nunito"}
                            fontSize={fontSize}
                            fontStyle={"normal"}
                            fontWeight={"800"}
                            lineHeight={"normal"}
                          >
                            {currencyFormat(
                              userOptiData?.primaryUserData
                                ?.sps1_registeredContributionsMatched?.[
                                listPlace
                              ]
                            )}{" "}
                          </Text>
                          into your
                          <Text
                            as={"span"}
                            color={"#004069"}
                            fontFamily={"Nunito"}
                            fontSize={fontSize}
                            fontStyle={"normal"}
                            fontWeight={"800"}
                            lineHeight={"normal"}
                          >
                            {" "}
                            RRSP {""}
                          </Text>
                          <br />
                          <Text
                            as={"span"}
                            color={"#00A2D4"}
                            fontFamily={"Nunito"}
                            fontSize={fontSize}
                            fontStyle={"normal"}
                            fontWeight={"800"}
                            lineHeight={"normal"}
                          >
                            {" "}
                            {currencyFormat(
                              userOptiData?.primaryUserData
                                ?.sps1_registeredContributionsMatched?.[
                                listPlace
                              ] / monthsLeft
                            )}{" "}
                          </Text>
                          per month
                          <br />
                          <Text
                            color={"#004069"}
                            fontFamily={"Nunito"}
                            fontSize={"14px"}
                            fontStyle={"normal"}
                            fontWeight={"400"}
                            lineHeight={"normal"}
                            ml={"26px"}
                            mt={"10px"}
                          >
                            Expected annual growth rate:{" "}
                            {userRRSPInfo?.RRSP_ROR}%
                          </Text>
                        </li>
                      )} */}
                      {userOptiData?.primaryUserData?.sps1_pension
                        ?.EmployerContributions?.[listPlace] >= minTrx && (
                        <li className="action-plan-instructions">
                          Contribute{" "}
                          <Text
                            as={"span"}
                            color={"#00A2D4"}
                            fontFamily={"Nunito"}
                            fontSize={fontSize}
                            fontStyle={"normal"}
                            fontWeight={"800"}
                            lineHeight={"normal"}
                          >
                            {currencyFormat(
                              userOptiData?.primaryUserData?.sps1_pension
                                ?.EmployerContributions?.[listPlace]
                            )}{" "}
                          </Text>
                          into your
                          <Text
                            as={"span"}
                            color={"#004069"}
                            fontFamily={"Nunito"}
                            fontSize={fontSize}
                            fontStyle={"normal"}
                            fontWeight={"800"}
                            lineHeight={"normal"}
                          >
                            {" "}
                            Pension{" "}
                          </Text>
                          <Text
                            as={"span"}
                            color={"#00A2D4"}
                            fontFamily={"Nunito"}
                            fontSize={fontSize}
                            fontStyle={"normal"}
                            fontWeight={"800"}
                            lineHeight={"normal"}
                          >
                            {currencyFormat(
                              userOptiData?.primaryUserData?.sps1_pension
                                ?.EmployerContributions?.[listPlace] /
                                monthsLeft
                            )}{" "}
                          </Text>
                          per month
                          <br />
                          <Text
                            color={"#004069"}
                            fontFamily={"Nunito"}
                            fontSize={"14px"}
                            fontStyle={"normal"}
                            fontWeight={"400"}
                            lineHeight={"normal"}
                            ml={"26px"}
                            mt={"10px"}
                          >
                            Expected annual growth rate:{" "}
                            {singleFoundItem?.Pension_Index}%
                          </Text>
                        </li>
                      )}
                      {/* {userOptiData?.primaryUserData?.sps1_pension
                        ?.EmployerContributions?.[listPlace] >= minTrx && (
                        <li className="action-plan-instructions">
                          Your employer will contribute{" "}
                          <Text
                            as={"span"}
                            color={"#00A2D4"}
                            fontFamily={"Nunito"}
                            fontSize={fontSize}
                            fontStyle={"normal"}
                            fontWeight={"800"}
                            lineHeight={"normal"}
                          >
                            {currencyFormat(
                              userOptiData?.primaryUserData?.sps1_pension
                                ?.EmployerContributions?.[listPlace]
                            )}{" "}
                          </Text>
                          into your
                          <Text
                            as={"span"}
                            color={"#004069"}
                            fontFamily={"Nunito"}
                            fontSize={fontSize}
                            fontStyle={"normal"}
                            fontWeight={"800"}
                            lineHeight={"normal"}
                          >
                            {" "}
                            Pension{" "}
                          </Text>
                          <Text
                            as={"span"}
                            color={"#00A2D4"}
                            fontFamily={"Nunito"}
                            fontSize={fontSize}
                            fontStyle={"normal"}
                            fontWeight={"800"}
                            lineHeight={"normal"}
                          >
                            {currencyFormat(
                              userOptiData?.primaryUserData?.sps1_pension
                                ?.EmployerContributions?.[listPlace] / monthsLeft
                            )}{" "}
                          </Text>
                          per month
                          <br />
                          <Text
                            color={"#004069"}
                            fontFamily={"Nunito"}
                            fontSize={"14px"}
                            fontStyle={"normal"}
                            fontWeight={"400"}
                            lineHeight={"normal"}
                            ml={"26px"}
                            mt={"10px"}
                          >
                            Expected annual growth rate:{" "}
                            {singleFoundItem?.Pension_Index}%
                          </Text>
                        </li>
                      )} */}
                      {(userOptiData?.primaryUserData
                        ?.ccpcYearlyCapitalDividendsIssued?.[listPlace] >=
                        minTrx ||
                        userOptiData?.primaryUserData
                          ?.ccpcYearlyEligibleDividendsIssued?.[listPlace] >=
                          minTrx ||
                        userOptiData?.primaryUserData
                          ?.ccpcYearlyNonEligibleDividendsIssued?.[listPlace] >=
                          minTrx ||
                        userOptiData?.primaryUserData
                          ?.ccpcYearlyShareholderLoanRepayments?.[listPlace] >=
                          minTrx) && (
                        <li className="action-plan-instructions">
                          <Text
                            as={"span"}
                            color={"#004069"}
                            fontFamily={"Nunito"}
                            fontSize={fontSize}
                            fontStyle={"normal"}
                            fontWeight={"800"}
                            lineHeight={"normal"}
                          >
                            CCPC Dividends Issued
                          </Text>
                        </li>
                      )}
                      {userOptiData?.primaryUserData
                        ?.ccpcYearlyCapitalDividendsIssued?.[listPlace] >=
                        minTrx && (
                        <li className="action-plan-instructions">
                          Issue{" "}
                          <Text
                            as={"span"}
                            color={"#00A2D4"}
                            fontFamily={"Nunito"}
                            fontSize={fontSize}
                            fontStyle={"normal"}
                            fontWeight={"800"}
                            lineHeight={"normal"}
                          >
                            {spouseStatus &&
                              currencyFormat(
                                userOptiData?.primaryUserData
                                  ?.ccpcYearlyCapitalDividendsIssued?.[
                                  listPlace
                                ] / 2
                              )}
                            {!spouseStatus &&
                              currencyFormat(
                                userOptiData?.primaryUserData
                                  ?.ccpcYearlyCapitalDividendsIssued?.[
                                  listPlace
                                ]
                              )}{" "}
                          </Text>
                          as a
                          <Text
                            as={"span"}
                            color={"#004069"}
                            fontFamily={"Nunito"}
                            fontSize={fontSize}
                            fontStyle={"normal"}
                            fontWeight={"800"}
                            lineHeight={"normal"}
                          >
                            {" "}
                            Capital Dividend
                          </Text>
                        </li>
                      )}
                      {userOptiData?.primaryUserData
                        ?.ccpcYearlyEligibleDividendsIssued?.[listPlace] >=
                        minTrx && (
                        <li className="action-plan-instructions">
                          Issue{" "}
                          <Text
                            as={"span"}
                            color={"#00A2D4"}
                            fontFamily={"Nunito"}
                            fontSize={fontSize}
                            fontStyle={"normal"}
                            fontWeight={"800"}
                            lineHeight={"normal"}
                          >
                            {spouseStatus &&
                              currencyFormat(
                                userOptiData?.primaryUserData
                                  ?.ccpcYearlyEligibleDividendsIssued?.[
                                  listPlace
                                ] / 2
                              )}
                            {!spouseStatus &&
                              currencyFormat(
                                userOptiData?.primaryUserData
                                  ?.ccpcYearlyEligibleDividendsIssued?.[
                                  listPlace
                                ]
                              )}{" "}
                          </Text>
                          as a
                          <Text
                            as={"span"}
                            color={"#004069"}
                            fontFamily={"Nunito"}
                            fontSize={fontSize}
                            fontStyle={"normal"}
                            fontWeight={"800"}
                            lineHeight={"normal"}
                          >
                            {" "}
                            Eligible dividend
                          </Text>
                        </li>
                      )}
                      {userOptiData?.primaryUserData
                        ?.ccpcYearlyNonEligibleDividendsIssued?.[listPlace] >=
                        minTrx && (
                        <li className="action-plan-instructions">
                          Issue{" "}
                          <Text
                            as={"span"}
                            color={"#00A2D4"}
                            fontFamily={"Nunito"}
                            fontSize={fontSize}
                            fontStyle={"normal"}
                            fontWeight={"800"}
                            lineHeight={"normal"}
                          >
                            {spouseStatus &&
                              currencyFormat(
                                userOptiData?.primaryUserData
                                  ?.ccpcYearlyNonEligibleDividendsIssued?.[
                                  listPlace
                                ] / 2
                              )}
                            {!spouseStatus &&
                              currencyFormat(
                                userOptiData?.primaryUserData
                                  ?.ccpcYearlyNonEligibleDividendsIssued?.[
                                  listPlace
                                ]
                              )}{" "}
                          </Text>
                          as a
                          <Text
                            as={"span"}
                            color={"#004069"}
                            fontFamily={"Nunito"}
                            fontSize={fontSize}
                            fontStyle={"normal"}
                            fontWeight={"800"}
                            lineHeight={"normal"}
                          >
                            {" "}
                            Non-Eligible dividend
                          </Text>
                        </li>
                      )}
                      {userOptiData?.primaryUserData
                        ?.ccpcYearlyShareholderLoanRepayments?.[listPlace] >=
                        minTrx && (
                        <li className="action-plan-instructions">
                          Issue{" "}
                          <Text
                            as={"span"}
                            color={"#00A2D4"}
                            fontFamily={"Nunito"}
                            fontSize={fontSize}
                            fontStyle={"normal"}
                            fontWeight={"800"}
                            lineHeight={"normal"}
                          >
                            {spouseStatus &&
                              currencyFormat(
                                userOptiData?.primaryUserData
                                  ?.ccpcYearlyShareholderLoanRepayments?.[
                                  listPlace
                                ] / 2
                              )}
                            {!spouseStatus &&
                              currencyFormat(
                                userOptiData?.primaryUserData
                                  ?.ccpcYearlyShareholderLoanRepayments?.[
                                  listPlace
                                ]
                              )}{" "}
                          </Text>
                          as a
                          <Text
                            as={"span"}
                            color={"#004069"}
                            fontFamily={"Nunito"}
                            fontSize={fontSize}
                            fontStyle={"normal"}
                            fontWeight={"800"}
                            lineHeight={"normal"}
                          >
                            {" "}
                            Loan Repayment
                          </Text>
                        </li>
                      )}
                    </VStack>
                  </Box>
                  <Box
                    display={"flex"}
                    width={"48%"}
                    padding={"10px"}
                    flexDirection={"column"}
                    alignItems={"center"} // Change from flex-start to center to center the VStack horizontally
                    gap={"10px"}
                    alignSelf={"stretch"}
                    bg={"#FFF"}
                  >
                    <VStack spacing="10px" width="100%" alignItems="center">
                      <Box
                        display="flex"
                        padding="10px"
                        justifyContent="center"
                        alignItems="center"
                        gap="10px"
                        alignSelf="stretch"
                        border="1px solid #00A2D4"
                        bg="#FFF"
                        marginBottom={"5%"}
                        borderRadius="5px"
                        width="100%"
                        height="auto" // Ensure the container has sufficient height
                        id="graphContainer"
                      >
                        <DepositsWithdrawalsGraph
                          tfsaDeposits={
                            userOptiData?.primaryUserData
                              ?.sps1_tfsaContributions?.[listPlace]
                          }
                          tfsaWithdrawals={
                            userOptiData?.primaryUserData
                              ?.sps1_tfsaWithdrawals?.[listPlace]
                          }
                          fhsaDeposits={
                            userOptiData?.primaryUserData
                              ?.sps1_fhsaContributions?.[listPlace]
                          }
                          fhsaWithdrawals={
                            userOptiData?.primaryUserData
                              ?.sps1_fhsaWithdrawals?.[listPlace]
                          }
                          rrspDeposits={safeAdd(
                            userOptiData?.primaryUserData
                              ?.sps1_registeredContributions?.[listPlace],
                            userOptiData?.primaryUserData
                              ?.sps1_registeredContributionsMatched?.[listPlace]
                          )}
                          rrspWithdrawals={
                            userOptiData?.primaryUserData
                              ?.sps1_registeredWithdrawals?.[listPlace]
                          }
                          nonRegDeposits={
                            userOptiData?.primaryUserData
                              ?.sps1_nonRegisteredContributions?.[listPlace]
                          }
                          nonRegWithdrawals={
                            userOptiData?.primaryUserData
                              ?.sps1_nonregisteredWithdrawals?.[listPlace]
                          }
                          liraWithdrawals={
                            userOptiData?.primaryUserData
                              ?.sps1_liraWithdrawals?.[listPlace]
                          }
                          cashWithdrawals={
                            userOptiData?.primaryUserData
                              ?.sps1_cashWithdrawals?.[listPlace]
                          }
                          fixedWithdrawals={
                            userOptiData?.primaryUserData
                              ?.sps1_fixedIncomeWithdrawals?.[listPlace]
                          }
                          pensionDeposits={
                            userOptiData?.primaryUserData?.sps1_pension
                              ?.EmployerContributions?.[listPlace]
                          }
                          eligibleDividends={
                            spouseStatus
                              ? userOptiData?.primaryUserData
                                  ?.ccpcYearlyEligibleDividendsIssued?.[
                                  listPlace
                                ] / 2
                              : userOptiData?.primaryUserData
                                  ?.ccpcYearlyEligibleDividendsIssued?.[
                                  listPlace
                                ]
                          }
                          nonEligibleDividends={
                            spouseStatus
                              ? userOptiData?.primaryUserData
                                  ?.ccpcYearlyNonEligibleDividendsIssued?.[
                                  listPlace
                                ] / 2
                              : userOptiData?.primaryUserData
                                  ?.ccpcYearlyNonEligibleDividendsIssued?.[
                                  listPlace
                                ]
                          }
                          capitalDividends={
                            spouseStatus
                              ? userOptiData?.primaryUserData
                                  ?.ccpcYearlyCapitalDividendsIssued?.[
                                  listPlace
                                ] / 2
                              : userOptiData?.primaryUserData
                                  ?.ccpcYearlyCapitalDividendsIssued?.[
                                  listPlace
                                ]
                          }
                          loanRepayments={
                            spouseStatus
                              ? userOptiData?.primaryUserData
                                  ?.ccpcYearlyShareholderLoanRepayments?.[
                                  listPlace
                                ] / 2
                              : userOptiData?.primaryUserData
                                  ?.ccpcYearlyShareholderLoanRepayments?.[
                                  listPlace
                                ]
                          }
                          height="280px"
                        />
                      </Box>
                    </VStack>
                  </Box>
                </Box>
              </Box>

              {/** Spouse 2 **/}
              {spouseStatus && (
                <Box
                  display={"flex"}
                  padding={"20px"}
                  flexDirection={"column"}
                  alignItems={"flex-start"}
                  gap={"10px"}
                  alignSelf={"stretch"}
                  borderRadius={"5px"}
                  border={"1px solid #1EADD9"}
                  bg={"#FFF"}
                >
                  <Text
                    alignSelf={"stretch"}
                    color={"#004069"}
                    fontFamily={"Nunito"}
                    fontSize={"24px"}
                    fontStyle={"normal"}
                    fontWeight={"700"}
                    lineHeight={"normal"}
                  >
                    {spouseFirstName}, this year you need to...
                  </Text>
                  <Box
                    display={"flex"}
                    justifyContent={"space-between"}
                    alignItems={"center"}
                    alignSelf={"stretch"}
                    bg={"#FFF"}
                  >
                    <Box
                      display={"flex"}
                      width={"48%"}
                      padding={"10px"}
                      flexDirection={"column"}
                      alignItems={"flex-start"}
                      gap={"10px"}
                      alignSelf={"stretch"}
                      bg={"#FFF"}
                    >
                      <VStack spacing={"10px"} alignItems={"baseline"}>
                        {userOptiData?.spouseData?.sps2_tfsaWithdrawals?.[
                          listPlace
                        ] >= minTrx && (
                          <li className="action-plan-instructions">
                            Withdraw{" "}
                            <Text
                              as={"span"}
                              color={"#00A2D4"}
                              fontFamily={"Nunito"}
                              fontSize={fontSize}
                              fontStyle={"normal"}
                              fontWeight={"800"}
                              lineHeight={"normal"}
                            >
                              {currencyFormat(
                                userOptiData?.spouseData
                                  ?.sps2_tfsaWithdrawals?.[listPlace]
                              )}{" "}
                            </Text>
                            from your
                            <Text
                              as={"span"}
                              color={"#004069"}
                              fontFamily={"Nunito"}
                              fontSize={fontSize}
                              fontStyle={"normal"}
                              fontWeight={"800"}
                              lineHeight={"normal"}
                            >
                              {" "}
                              TFSA{" "}
                            </Text>
                            <br />
                            <Text
                              as={"span"}
                              color={"#00A2D4"}
                              fontFamily={"Nunito"}
                              fontSize={fontSize}
                              fontStyle={"normal"}
                              fontWeight={"800"}
                              lineHeight={"normal"}
                              ml={"26px"}
                            >
                              {currencyFormat(
                                userOptiData?.spouseData
                                  ?.sps2_tfsaWithdrawals?.[listPlace] /
                                  monthsLeft
                              )}{" "}
                            </Text>
                            per month
                            <br />
                            <Text
                              color={"#004069"}
                              fontFamily={"Nunito"}
                              fontSize={"14px"}
                              fontStyle={"normal"}
                              fontWeight={"400"}
                              lineHeight={"normal"}
                              ml={"26px"}
                              mt={"10px"}
                            >
                              Expected annual growth rate:{" "}
                              {userTFSAInfo?.spouseTFSA_ROR}%
                            </Text>
                          </li>
                        )}
                        {userOptiData?.spouseData?.sps2_fhsaWithdrawals?.[
                          listPlace
                        ] >= minTrx && (
                          <li className="action-plan-instructions">
                            Withdraw{" "}
                            <Text
                              as={"span"}
                              color={"#00A2D4"}
                              fontFamily={"Nunito"}
                              fontSize={fontSize}
                              fontStyle={"normal"}
                              fontWeight={"800"}
                              lineHeight={"normal"}
                            >
                              {currencyFormat(
                                userOptiData?.spouseData
                                  ?.sps2_fhsaWithdrawals?.[listPlace]
                              )}{" "}
                            </Text>
                            from your
                            <Text
                              as={"span"}
                              color={"#004069"}
                              fontFamily={"Nunito"}
                              fontSize={fontSize}
                              fontStyle={"normal"}
                              fontWeight={"800"}
                              lineHeight={"normal"}
                            >
                              {" "}
                              FHSA{" "}
                            </Text>
                            <br />
                            <Text
                              as={"span"}
                              color={"#00A2D4"}
                              fontFamily={"Nunito"}
                              fontSize={fontSize}
                              fontStyle={"normal"}
                              fontWeight={"800"}
                              lineHeight={"normal"}
                              ml={"26px"}
                            >
                              {currencyFormat(
                                userOptiData?.spouseData
                                  ?.sps2_fhsaWithdrawals?.[listPlace]
                              )}{" "}
                            </Text>
                            during home purchase month
                            <br />
                            <Text
                              color={"#004069"}
                              fontFamily={"Nunito"}
                              fontSize={"14px"}
                              fontStyle={"normal"}
                              fontWeight={"400"}
                              lineHeight={"normal"}
                              ml={"26px"}
                              mt={"10px"}
                            >
                              Expected annual growth rate:{" "}
                              {userFHSAInfo?.spouseFhsaGrowth}%
                            </Text>
                          </li>
                        )}
                        {userOptiData?.spouseData?.sps2_registeredWithdrawals?.[
                          listPlace
                        ] >= minTrx && (
                          <li className="action-plan-instructions">
                            Withdraw{" "}
                            <Text
                              as={"span"}
                              color={"#00A2D4"}
                              fontFamily={"Nunito"}
                              fontSize={fontSize}
                              fontStyle={"normal"}
                              fontWeight={"800"}
                              lineHeight={"normal"}
                            >
                              {currencyFormat(
                                userOptiData?.spouseData
                                  ?.sps2_registeredWithdrawals?.[listPlace]
                              )}{" "}
                            </Text>
                            from your
                            <Text
                              as={"span"}
                              color={"#004069"}
                              fontFamily={"Nunito"}
                              fontSize={fontSize}
                              fontStyle={"normal"}
                              fontWeight={"800"}
                              lineHeight={"normal"}
                            >
                              {" "}
                              RRSP{" "}
                            </Text>
                            <br />
                            <Text
                              as={"span"}
                              color={"#00A2D4"}
                              fontFamily={"Nunito"}
                              fontSize={fontSize}
                              fontStyle={"normal"}
                              fontWeight={"800"}
                              lineHeight={"normal"}
                              ml={"26px"}
                            >
                              {currencyFormat(
                                userOptiData?.spouseData
                                  ?.sps2_registeredWithdrawals?.[listPlace] /
                                  monthsLeft
                              )}{" "}
                            </Text>
                            per month
                            <br />
                            <Text
                              color={"#004069"}
                              fontFamily={"Nunito"}
                              fontSize={"14px"}
                              fontStyle={"normal"}
                              fontWeight={"400"}
                              lineHeight={"normal"}
                              ml={"26px"}
                              mt={"10px"}
                            >
                              Expected annual growth rate:{" "}
                              {userRRSPInfo?.spouseRRSP_ROR}%
                            </Text>
                          </li>
                        )}
                        {userOptiData?.spouseData
                          ?.sps2_nonregisteredWithdrawals?.[listPlace] >=
                          minTrx && (
                          <li className="action-plan-instructions">
                            Withdraw{" "}
                            <Text
                              as={"span"}
                              color={"#00A2D4"}
                              fontFamily={"Nunito"}
                              fontSize={fontSize}
                              fontStyle={"normal"}
                              fontWeight={"800"}
                              lineHeight={"normal"}
                            >
                              {currencyFormat(
                                userOptiData?.spouseData
                                  ?.sps2_nonregisteredWithdrawals?.[listPlace]
                              )}{" "}
                            </Text>
                            from your
                            <Text
                              as={"span"}
                              color={"#004069"}
                              fontFamily={"Nunito"}
                              fontSize={fontSize}
                              fontStyle={"normal"}
                              fontWeight={"800"}
                              lineHeight={"normal"}
                            >
                              {" "}
                              Non-Registered{" "}
                            </Text>
                            <br />
                            <Text
                              as={"span"}
                              color={"#00A2D4"}
                              fontFamily={"Nunito"}
                              fontSize={fontSize}
                              fontStyle={"normal"}
                              fontWeight={"800"}
                              lineHeight={"normal"}
                              ml={"26px"}
                            >
                              {currencyFormat(
                                userOptiData?.spouseData
                                  ?.sps2_nonregisteredWithdrawals?.[listPlace] /
                                  monthsLeft
                              )}{" "}
                            </Text>
                            per month
                            <br />
                            <Text
                              color={"#004069"}
                              fontFamily={"Nunito"}
                              fontSize={"14px"}
                              fontStyle={"normal"}
                              fontWeight={"400"}
                              lineHeight={"normal"}
                              ml={"26px"}
                              mt={"10px"}
                            >
                              Expected annual growth rate:{" "}
                              {userNonRegInvestmentsInfo?.spouseNon_Reg_ROR}%
                            </Text>
                          </li>
                        )}
                        {userOptiData?.spouseData?.sps2_cashWithdrawals?.[
                          listPlace
                        ] >= minTrx && (
                          <li className="action-plan-instructions">
                            Withdraw{" "}
                            <Text
                              as={"span"}
                              color={"#00A2D4"}
                              fontFamily={"Nunito"}
                              fontSize={fontSize}
                              fontStyle={"normal"}
                              fontWeight={"800"}
                              lineHeight={"normal"}
                            >
                              {currencyFormat(
                                userOptiData?.spouseData
                                  ?.sps2_cashWithdrawals?.[listPlace]
                              )}{" "}
                            </Text>
                            from your
                            <Text
                              as={"span"}
                              color={"#004069"}
                              fontFamily={"Nunito"}
                              fontSize={fontSize}
                              fontStyle={"normal"}
                              fontWeight={"800"}
                              lineHeight={"normal"}
                            >
                              {" "}
                              Cash Savings{" "}
                            </Text>
                            <br />
                            <Text
                              as={"span"}
                              color={"#00A2D4"}
                              fontFamily={"Nunito"}
                              fontSize={fontSize}
                              fontStyle={"normal"}
                              fontWeight={"800"}
                              lineHeight={"normal"}
                              ml={"26px"}
                            >
                              {currencyFormat(
                                userOptiData?.spouseData
                                  ?.sps2_cashWithdrawals?.[listPlace] /
                                  monthsLeft
                              )}{" "}
                            </Text>
                            per month
                          </li>
                        )}
                        {userOptiData?.spouseData
                          ?.sps2_fixedIncomeWithdrawals?.[listPlace] >=
                          minTrx && (
                          <li className="action-plan-instructions">
                            Withdraw{" "}
                            <Text
                              as={"span"}
                              color={"#00A2D4"}
                              fontFamily={"Nunito"}
                              fontSize={fontSize}
                              fontStyle={"normal"}
                              fontWeight={"800"}
                              lineHeight={"normal"}
                            >
                              {currencyFormat(
                                userOptiData?.spouseData
                                  ?.sps2_fixedIncomeWithdrawals?.[listPlace]
                              )}{" "}
                            </Text>
                            from your
                            <Text
                              as={"span"}
                              color={"#004069"}
                              fontFamily={"Nunito"}
                              fontSize={fontSize}
                              fontStyle={"normal"}
                              fontWeight={"800"}
                              lineHeight={"normal"}
                            >
                              {" "}
                              Fixed Income{" "}
                            </Text>
                            <br />
                            <Text
                              as={"span"}
                              color={"#00A2D4"}
                              fontFamily={"Nunito"}
                              fontSize={fontSize}
                              fontStyle={"normal"}
                              fontWeight={"800"}
                              lineHeight={"normal"}
                              ml={"26px"}
                            >
                              {currencyFormat(
                                userOptiData?.spouseData
                                  ?.sps2_fixedIncomeWithdrawals?.[listPlace] /
                                  monthsLeft
                              )}{" "}
                            </Text>
                            per month
                            <br />
                            <Text
                              color={"#004069"}
                              fontFamily={"Nunito"}
                              fontSize={"14px"}
                              fontStyle={"normal"}
                              fontWeight={"400"}
                              lineHeight={"normal"}
                              ml={"26px"}
                              mt={"10px"}
                            >
                              Expected annual growth rate: {averageInterest}%
                            </Text>
                          </li>
                        )}
                        {userOptiData?.spouseData?.sps2_liraWithdrawals?.[
                          listPlace
                        ] >= minTrx && (
                          <li className="action-plan-instructions">
                            Withdraw{" "}
                            <Text
                              as={"span"}
                              color={"#00A2D4"}
                              fontFamily={"Nunito"}
                              fontSize={fontSize}
                              fontStyle={"normal"}
                              fontWeight={"800"}
                              lineHeight={"normal"}
                            >
                              {currencyFormat(
                                userOptiData?.spouseData
                                  ?.sps2_liraWithdrawals?.[listPlace]
                              )}{" "}
                            </Text>
                            from your
                            <Text
                              as={"span"}
                              color={"#004069"}
                              fontFamily={"Nunito"}
                              fontSize={fontSize}
                              fontStyle={"normal"}
                              fontWeight={"800"}
                              lineHeight={"normal"}
                            >
                              {" "}
                              LIRA{" "}
                            </Text>
                            <Text
                              as={"span"}
                              color={"#00A2D4"}
                              fontFamily={"Nunito"}
                              fontSize={fontSize}
                              fontStyle={"normal"}
                              fontWeight={"800"}
                              lineHeight={"normal"}
                            >
                              {currencyFormat(
                                userOptiData?.spouseData
                                  ?.sps2_liraWithdrawals?.[listPlace] /
                                  monthsLeft
                              )}{" "}
                            </Text>
                            per month
                            <br />
                            <Text
                              color={"#004069"}
                              fontFamily={"Nunito"}
                              fontSize={"14px"}
                              fontStyle={"normal"}
                              fontWeight={"400"}
                              lineHeight={"normal"}
                              ml={"26px"}
                              mt={"10px"}
                            >
                              Expected annual growth rate:{" "}
                              {userLiraInfo?.spouseLIRA_ROR}%
                            </Text>
                          </li>
                        )}
                        {userOptiData?.spouseData?.sps2_tfsaContributions?.[
                          listPlace
                        ] >= minTrx && (
                          <li className="action-plan-instructions">
                            Deposit{" "}
                            <Text
                              as={"span"}
                              color={"#00A2D4"}
                              fontFamily={"Nunito"}
                              fontSize={fontSize}
                              fontStyle={"normal"}
                              fontWeight={"800"}
                              lineHeight={"normal"}
                            >
                              {currencyFormat(
                                userOptiData?.spouseData
                                  ?.sps2_tfsaContributions?.[listPlace]
                              )}{" "}
                            </Text>
                            into your
                            <Text
                              as={"span"}
                              color={"#004069"}
                              fontFamily={"Nunito"}
                              fontSize={fontSize}
                              fontStyle={"normal"}
                              fontWeight={"800"}
                              lineHeight={"normal"}
                            >
                              {" "}
                              TFSA{" "}
                            </Text>
                            <Text
                              as={"span"}
                              color={"#00A2D4"}
                              fontFamily={"Nunito"}
                              fontSize={fontSize}
                              fontStyle={"normal"}
                              fontWeight={"800"}
                              lineHeight={"normal"}
                            >
                              {currencyFormat(
                                userOptiData?.spouseData
                                  ?.sps2_tfsaContributions?.[listPlace] /
                                  monthsLeft
                              )}{" "}
                            </Text>
                            per month
                            <br />
                            <Text
                              color={"#004069"}
                              fontFamily={"Nunito"}
                              fontSize={"14px"}
                              fontStyle={"normal"}
                              fontWeight={"400"}
                              lineHeight={"normal"}
                              ml={"26px"}
                              mt={"10px"}
                            >
                              Expected annual growth rate:{" "}
                              {userTFSAInfo?.spouseTFSA_ROR}%
                            </Text>
                          </li>
                        )}
                        {userOptiData?.spouseData?.sps2_fhsaContributions?.[
                          listPlace
                        ] >= minTrx && (
                          <li className="action-plan-instructions">
                            Deposit{" "}
                            <Text
                              as={"span"}
                              color={"#00A2D4"}
                              fontFamily={"Nunito"}
                              fontSize={fontSize}
                              fontStyle={"normal"}
                              fontWeight={"800"}
                              lineHeight={"normal"}
                            >
                              {currencyFormat(
                                userOptiData?.spouseData
                                  ?.sps2_fhsaContributions?.[listPlace]
                              )}{" "}
                            </Text>
                            into your
                            <Text
                              as={"span"}
                              color={"#004069"}
                              fontFamily={"Nunito"}
                              fontSize={fontSize}
                              fontStyle={"normal"}
                              fontWeight={"800"}
                              lineHeight={"normal"}
                            >
                              {" "}
                              FHSA{" "}
                            </Text>
                            <Text
                              as={"span"}
                              color={"#00A2D4"}
                              fontFamily={"Nunito"}
                              fontSize={fontSize}
                              fontStyle={"normal"}
                              fontWeight={"800"}
                              lineHeight={"normal"}
                            >
                              {currencyFormat(
                                userOptiData?.spouseData
                                  ?.sps2_fhsaContributions?.[listPlace] /
                                  monthsLeft
                              )}{" "}
                            </Text>
                            per month
                            <br />
                            <Text
                              color={"#004069"}
                              fontFamily={"Nunito"}
                              fontSize={"14px"}
                              fontStyle={"normal"}
                              fontWeight={"400"}
                              lineHeight={"normal"}
                              ml={"26px"}
                              mt={"10px"}
                            >
                              Expected annual growth rate:{" "}
                              {userFHSAInfo?.spouseFhsaGrowth}%
                            </Text>
                          </li>
                        )}
                        {safeAdd(
                          userOptiData?.spouseData
                            ?.sps2_registeredContributions?.[listPlace],
                          userOptiData?.spouseData
                            ?.sps2_registeredContributionsMatched?.[listPlace]
                        ) >= minTrx && (
                          <li className="action-plan-instructions">
                            Deposit{" "}
                            <Text
                              as={"span"}
                              color={"#00A2D4"}
                              fontFamily={"Nunito"}
                              fontSize={fontSize}
                              fontStyle={"normal"}
                              fontWeight={"800"}
                              lineHeight={"normal"}
                            >
                              {currencyFormat(
                                safeAdd(
                                  userOptiData?.spouseData
                                    ?.sps2_registeredContributions?.[listPlace],
                                  userOptiData?.spouseData
                                    ?.sps2_registeredContributionsMatched?.[
                                    listPlace
                                  ]
                                )
                              )}{" "}
                            </Text>
                            into your
                            <Text
                              as={"span"}
                              color={"#004069"}
                              fontFamily={"Nunito"}
                              fontSize={fontSize}
                              fontStyle={"normal"}
                              fontWeight={"800"}
                              lineHeight={"normal"}
                            >
                              {" "}
                              RRSP{" "}
                            </Text>
                            <br />
                            <Text
                              as={"span"}
                              color={"#00A2D4"}
                              fontFamily={"Nunito"}
                              fontSize={fontSize}
                              fontStyle={"normal"}
                              fontWeight={"800"}
                              lineHeight={"normal"}
                              ml={"26px"}
                            >
                              {currencyFormat(
                                safeAdd(
                                  userOptiData?.spouseData
                                    ?.sps2_registeredContributions?.[
                                    listPlace
                                  ] / monthsLeft,
                                  userOptiData?.spouseData
                                    ?.sps2_registeredContributionsMatched?.[
                                    listPlace
                                  ] / monthsLeft
                                )
                              )}{" "}
                            </Text>
                            per month
                            <br />
                            <Text
                              color={"#004069"}
                              fontFamily={"Nunito"}
                              fontSize={"14px"}
                              fontStyle={"normal"}
                              fontWeight={"400"}
                              lineHeight={"normal"}
                              ml={"26px"}
                              mt={"10px"}
                            >
                              Expected annual growth rate:{" "}
                              {userRRSPInfo?.spouseRRSP_ROR}%
                            </Text>
                          </li>
                        )}
                        {userOptiData?.spouseData
                          ?.sps2_nonRegisteredContributions?.[listPlace] >=
                          minTrx && (
                          <li className="action-plan-instructions">
                            Deposit{" "}
                            <Text
                              as={"span"}
                              color={"#00A2D4"}
                              fontFamily={"Nunito"}
                              fontSize={fontSize}
                              fontStyle={"normal"}
                              fontWeight={"800"}
                              lineHeight={"normal"}
                            >
                              {currencyFormat(
                                userOptiData?.spouseData
                                  ?.sps2_nonRegisteredContributions?.[listPlace]
                              )}{" "}
                            </Text>
                            into your
                            <Text
                              as={"span"}
                              color={"#004069"}
                              fontFamily={"Nunito"}
                              fontSize={fontSize}
                              fontStyle={"normal"}
                              fontWeight={"800"}
                              lineHeight={"normal"}
                            >
                              {" "}
                              Non-Registered{" "}
                            </Text>
                            <br />
                            <Text
                              as={"span"}
                              color={"#00A2D4"}
                              fontFamily={"Nunito"}
                              fontSize={fontSize}
                              fontStyle={"normal"}
                              fontWeight={"800"}
                              lineHeight={"normal"}
                              ml={"26px"}
                            >
                              {currencyFormat(
                                userOptiData?.spouseData
                                  ?.sps2_nonRegisteredContributions?.[
                                  listPlace
                                ] / monthsLeft
                              )}{" "}
                            </Text>
                            per month
                            <br />
                            <Text
                              color={"#004069"}
                              fontFamily={"Nunito"}
                              fontSize={"14px"}
                              fontStyle={"normal"}
                              fontWeight={"400"}
                              lineHeight={"normal"}
                              ml={"26px"}
                              mt={"10px"}
                            >
                              Expected annual growth rate:{" "}
                              {userNonRegInvestmentsInfo?.spouseNon_Reg_ROR}%
                            </Text>
                          </li>
                        )}
                        {/* {userOptiData?.spouseData
                          ?.sps2_registeredContributionsMatched?.[listPlace] >=
                          minTrx && (
                          <li className="action-plan-instructions">
                            Your employer will contribute{" "}
                            <Text
                              as={"span"}
                              color={"#00A2D4"}
                              fontFamily={"Nunito"}
                              fontSize={fontSize}
                              fontStyle={"normal"}
                              fontWeight={"800"}
                              lineHeight={"normal"}
                            >
                              {currencyFormat(
                                userOptiData?.spouseData
                                  ?.sps2_registeredContributionsMatched?.[
                                  listPlace
                                ]
                              )}{" "}
                            </Text>
                            into your
                            <Text
                              as={"span"}
                              color={"#004069"}
                              fontFamily={"Nunito"}
                              fontSize={fontSize}
                              fontStyle={"normal"}
                              fontWeight={"800"}
                              lineHeight={"normal"}
                            >
                              {" "}
                              RRSP {""}
                            </Text>
                            <br />
                            <Text
                              as={"span"}
                              color={"#00A2D4"}
                              fontFamily={"Nunito"}
                              fontSize={fontSize}
                              fontStyle={"normal"}
                              fontWeight={"800"}
                              lineHeight={"normal"}
                            >
                              {" "}
                              {currencyFormat(
                                userOptiData?.spouseData
                                  ?.sps2_registeredContributionsMatched?.[
                                  listPlace
                                ] / monthsLeft
                              )}{" "}
                            </Text>
                            per month
                            <br />
                            <Text
                              color={"#004069"}
                              fontFamily={"Nunito"}
                              fontSize={"14px"}
                              fontStyle={"normal"}
                              fontWeight={"400"}
                              lineHeight={"normal"}
                              ml={"26px"}
                              mt={"10px"}
                            >
                              Expected annual growth rate:{" "}
                              {userRRSPInfo?.spouseRRSP_ROR}%
                            </Text>
                          </li>
                        )} */}
                        {userOptiData?.spouseData?.sps2_pension
                          ?.EmployerContributions?.[listPlace] >= minTrx && (
                          <li className="action-plan-instructions">
                            Contribute{" "}
                            <Text
                              as={"span"}
                              color={"#00A2D4"}
                              fontFamily={"Nunito"}
                              fontSize={fontSize}
                              fontStyle={"normal"}
                              fontWeight={"800"}
                              lineHeight={"normal"}
                            >
                              {currencyFormat(
                                userOptiData?.spouseData?.sps2_pension
                                  ?.EmployerContributions?.[listPlace]
                              )}{" "}
                            </Text>
                            into your
                            <Text
                              as={"span"}
                              color={"#004069"}
                              fontFamily={"Nunito"}
                              fontSize={fontSize}
                              fontStyle={"normal"}
                              fontWeight={"800"}
                              lineHeight={"normal"}
                            >
                              {" "}
                              Pension{" "}
                            </Text>
                            <Text
                              as={"span"}
                              color={"#00A2D4"}
                              fontFamily={"Nunito"}
                              fontSize={fontSize}
                              fontStyle={"normal"}
                              fontWeight={"800"}
                              lineHeight={"normal"}
                            >
                              {currencyFormat(
                                userOptiData?.spouseData?.sps2_pension
                                  ?.EmployerContributions?.[listPlace] /
                                  monthsLeft
                              )}{" "}
                            </Text>
                            per month
                            <br />
                            <Text
                              color={"#004069"}
                              fontFamily={"Nunito"}
                              fontSize={"14px"}
                              fontStyle={"normal"}
                              fontWeight={"400"}
                              lineHeight={"normal"}
                              ml={"26px"}
                              mt={"10px"}
                            >
                              Expected annual growth rate:{" "}
                              {spouseFoundItem?.Pension_Index}%
                            </Text>
                          </li>
                        )}
                        {/* {userOptiData?.spouseData?.sps2_pension
                          ?.EmployerContributions?.[listPlace] >= minTrx && (
                          <li className="action-plan-instructions">
                            Your employer will contribute{" "}
                            <Text
                              as={"span"}
                              color={"#00A2D4"}
                              fontFamily={"Nunito"}
                              fontSize={fontSize}
                              fontStyle={"normal"}
                              fontWeight={"800"}
                              lineHeight={"normal"}
                            >
                              {currencyFormat(
                                userOptiData?.spouseData?.sps2_pension
                                  ?.EmployerContributions?.[listPlace]
                              )}{" "}
                            </Text>
                            into your
                            <Text
                              as={"span"}
                              color={"#004069"}
                              fontFamily={"Nunito"}
                              fontSize={fontSize}
                              fontStyle={"normal"}
                              fontWeight={"800"}
                              lineHeight={"normal"}
                            >
                              {" "}
                              Pension{" "}
                            </Text>
                            <Text
                              as={"span"}
                              color={"#00A2D4"}
                              fontFamily={"Nunito"}
                              fontSize={fontSize}
                              fontStyle={"normal"}
                              fontWeight={"800"}
                              lineHeight={"normal"}
                            >
                              {currencyFormat(
                                userOptiData?.spouseData?.sps2_pension
                                  ?.EmployerContributions?.[listPlace] / monthsLeft
                              )}{" "}
                            </Text>
                            per month
                            <br />
                            <Text
                              color={"#004069"}
                              fontFamily={"Nunito"}
                              fontSize={"14px"}
                              fontStyle={"normal"}
                              fontWeight={"400"}
                              lineHeight={"normal"}
                              ml={"26px"}
                              mt={"10px"}
                            >
                              Expected annual growth rate:{" "}
                              {spouseFoundItem?.Pension_Index}%
                            </Text>
                          </li>
                        )} */}
                        {(userOptiData?.primaryUserData
                          ?.ccpcYearlyCapitalDividendsIssued?.[listPlace] >=
                          minTrx ||
                          userOptiData?.primaryUserData
                            ?.ccpcYearlyEligibleDividendsIssued?.[listPlace] >=
                            minTrx ||
                          userOptiData?.primaryUserData
                            ?.ccpcYearlyNonEligibleDividendsIssued?.[
                            listPlace
                          ] >= minTrx ||
                          userOptiData?.primaryUserData
                            ?.ccpcYearlyShareholderLoanRepayments?.[
                            listPlace
                          ] >= minTrx) && (
                          <li className="action-plan-instructions">
                            <Text
                              as={"span"}
                              color={"#004069"}
                              fontFamily={"Nunito"}
                              fontSize={fontSize}
                              fontStyle={"normal"}
                              fontWeight={"800"}
                              lineHeight={"normal"}
                            >
                              CCPC Dividends Issued
                            </Text>
                          </li>
                        )}
                        {userOptiData?.primaryUserData
                          ?.ccpcYearlyCapitalDividendsIssued?.[listPlace] >=
                          minTrx && (
                          <li className="action-plan-instructions">
                            Issue{" "}
                            <Text
                              as={"span"}
                              color={"#00A2D4"}
                              fontFamily={"Nunito"}
                              fontSize={fontSize}
                              fontStyle={"normal"}
                              fontWeight={"800"}
                              lineHeight={"normal"}
                            >
                              {currencyFormat(
                                userOptiData?.primaryUserData
                                  ?.ccpcYearlyCapitalDividendsIssued?.[
                                  listPlace
                                ] / 2
                              )}{" "}
                            </Text>
                            as a
                            <Text
                              as={"span"}
                              color={"#004069"}
                              fontFamily={"Nunito"}
                              fontSize={fontSize}
                              fontStyle={"normal"}
                              fontWeight={"800"}
                              lineHeight={"normal"}
                            >
                              {" "}
                              Capital dividend
                            </Text>
                          </li>
                        )}
                        {userOptiData?.primaryUserData
                          ?.ccpcYearlyEligibleDividendsIssued?.[listPlace] >=
                          minTrx && (
                          <li className="action-plan-instructions">
                            Issue{" "}
                            <Text
                              as={"span"}
                              color={"#00A2D4"}
                              fontFamily={"Nunito"}
                              fontSize={fontSize}
                              fontStyle={"normal"}
                              fontWeight={"800"}
                              lineHeight={"normal"}
                            >
                              {currencyFormat(
                                userOptiData?.primaryUserData
                                  ?.ccpcYearlyEligibleDividendsIssued?.[
                                  listPlace
                                ] / 2
                              )}{" "}
                            </Text>
                            as a
                            <Text
                              as={"span"}
                              color={"#004069"}
                              fontFamily={"Nunito"}
                              fontSize={fontSize}
                              fontStyle={"normal"}
                              fontWeight={"800"}
                              lineHeight={"normal"}
                            >
                              {" "}
                              Eligible dividend
                            </Text>
                          </li>
                        )}
                        {userOptiData?.primaryUserData
                          ?.ccpcYearlyNonEligibleDividendsIssued?.[listPlace] >=
                          minTrx && (
                          <li className="action-plan-instructions">
                            Issue{" "}
                            <Text
                              as={"span"}
                              color={"#00A2D4"}
                              fontFamily={"Nunito"}
                              fontSize={fontSize}
                              fontStyle={"normal"}
                              fontWeight={"800"}
                              lineHeight={"normal"}
                            >
                              {currencyFormat(
                                userOptiData?.primaryUserData
                                  ?.ccpcYearlyNonEligibleDividendsIssued?.[
                                  listPlace
                                ] / 2
                              )}{" "}
                            </Text>
                            as a
                            <Text
                              as={"span"}
                              color={"#004069"}
                              fontFamily={"Nunito"}
                              fontSize={fontSize}
                              fontStyle={"normal"}
                              fontWeight={"800"}
                              lineHeight={"normal"}
                            >
                              {" "}
                              Non-Eligible dividend
                            </Text>
                          </li>
                        )}
                        {userOptiData?.primaryUserData
                          ?.ccpcYearlyShareholderLoanRepayments?.[listPlace] >=
                          minTrx && (
                          <li className="action-plan-instructions">
                            Issue{" "}
                            <Text
                              as={"span"}
                              color={"#00A2D4"}
                              fontFamily={"Nunito"}
                              fontSize={fontSize}
                              fontStyle={"normal"}
                              fontWeight={"800"}
                              lineHeight={"normal"}
                            >
                              {currencyFormat(
                                userOptiData?.primaryUserData
                                  ?.ccpcYearlyShareholderLoanRepayments?.[
                                  listPlace
                                ] / 2
                              )}{" "}
                            </Text>
                            as a
                            <Text
                              as={"span"}
                              color={"#004069"}
                              fontFamily={"Nunito"}
                              fontSize={fontSize}
                              fontStyle={"normal"}
                              fontWeight={"800"}
                              lineHeight={"normal"}
                            >
                              {" "}
                              Loan Repayment
                            </Text>
                          </li>
                        )}
                      </VStack>
                    </Box>
                    <Box
                      display={"flex"}
                      width={"48%"}
                      padding={"10px"}
                      flexDirection={"column"}
                      alignItems={"center"} // Change from flex-start to center to center the VStack horizontally
                      gap={"10px"}
                      alignSelf={"stretch"}
                      bg={"#FFF"}
                    >
                      <VStack spacing="10px" width="100%" alignItems="center">
                        <Box
                          display="flex"
                          padding="10px"
                          justifyContent="center"
                          alignItems="center"
                          gap="10px"
                          alignSelf="stretch"
                          border="1px solid #00A2D4"
                          bg="#FFF"
                          borderRadius="5px"
                          marginBottom={"5%"}
                          width="100%"
                          height="auto" // Ensure the container has sufficient height
                          id="spouseGraphContainer"
                        >
                          <DepositsWithdrawalsGraph
                            tfsaDeposits={
                              userOptiData?.spouseData
                                ?.sps2_tfsaContributions?.[listPlace]
                            }
                            tfsaWithdrawals={
                              userOptiData?.spouseData?.sps2_tfsaWithdrawals?.[
                                listPlace
                              ]
                            }
                            fhsaDeposits={
                              userOptiData?.spouseData
                                ?.sps2_fhsaContributions?.[listPlace]
                            }
                            fhsaWithdrawals={
                              userOptiData?.spouseData?.sps2_fhsaWithdrawals?.[
                                listPlace
                              ]
                            }
                            rrspDeposits={safeAdd(
                              userOptiData?.spouseData
                                ?.sps2_registeredContributions?.[listPlace],
                              userOptiData?.spouseData
                                ?.sps2_registeredContributionsMatched?.[
                                listPlace
                              ]
                            )}
                            rrspWithdrawals={
                              userOptiData?.spouseData
                                ?.sps2_registeredWithdrawals?.[listPlace]
                            }
                            nonRegDeposits={
                              userOptiData?.spouseData
                                ?.sps2_nonRegisteredContributions?.[listPlace]
                            }
                            nonRegWithdrawals={
                              userOptiData?.spouseData
                                ?.sps2_nonregisteredWithdrawals?.[listPlace]
                            }
                            liraWithdrawals={
                              userOptiData?.spouseData?.sps2_liraWithdrawals?.[
                                listPlace
                              ]
                            }
                            cashWithdrawals={
                              userOptiData?.spouseData?.sps2_cashWithdrawals?.[
                                listPlace
                              ]
                            }
                            fixedWithdrawals={
                              userOptiData?.spouseData
                                ?.sps2_fixedIncomeWithdrawals?.[listPlace]
                            }
                            pensionDeposits={
                              userOptiData?.spouseData?.sps2_pension
                                ?.EmployerContributions?.[listPlace]
                            }
                            eligibleDividends={
                              userOptiData?.primaryUserData
                                ?.ccpcYearlyEligibleDividendsIssued?.[
                                listPlace
                              ] / 2
                            }
                            nonEligibleDividends={
                              userOptiData?.primaryUserData
                                ?.ccpcYearlyNonEligibleDividendsIssued?.[
                                listPlace
                              ] / 2
                            }
                            capitalDividends={
                              userOptiData?.primaryUserData
                                ?.ccpcYearlyCapitalDividendsIssued?.[
                                listPlace
                              ] / 2
                            }
                            loanRepayments={
                              userOptiData?.primaryUserData
                                ?.ccpcYearlyShareholderLoanRepayments?.[
                                listPlace
                              ] / 2
                            }
                            height="280px"
                          />
                        </Box>
                      </VStack>
                    </Box>
                  </Box>
                </Box>
              )}
              <Box
                display={"flex"}
                padding={"20px"}
                flexDirection={"column"}
                alignItems={"flex-start"}
                gap={"10px"}
                alignSelf={"stretch"}
                borderRadius={"5px"}
                border={"1px solid #1EADD9"}
                bg={"#FFF"}
              >
                <Text
                  color={"#00A2D4"}
                  fontFamily={"Nunito"}
                  fontSize={isMobile ? "16px" : "30px"}
                  fontStyle={"normal"}
                  fontWeight={"600"}
                  lineHeight={"normal"}
                >
                  Lifetime Milestones
                </Text>
              </Box>
              <Box
                display={"flex"}
                padding={"20px"}
                flexDirection={"column"}
                alignItems={"flex-start"}
                gap={"10px"}
                alignSelf={"stretch"}
                borderRadius={"5px"}
                border={"1px solid #1EADD9"}
                bg={"#FFF"}
              >
                <TimelineCard events={timelineData} />
              </Box>
            </Box>
          )}
          {isMobile && (
            <>
              <Box
                display={"flex"}
                padding={"15px"}
                justifyContent={"space-between"}
                alignItems={"center"}
                alignSelf={"stretch"}
                border={"1px solid #00A2D4"}
                borderRadius={"5px"}
                gap={"5px"}
                bg={"#FFF"}
              >
                <Text
                  color={"#004069"}
                  fontFamily={"Nunito"}
                  // fontSize={isMobile ? "12px" : "19px"}
                  fontStyle={"normal"}
                  fontWeight={"600"}
                  lineHeight={"normal"}
                  width={"100%"}
                >
                  To ensure you stay on track with your financial goals, it's
                  important to focus on the following key areas this year:
                </Text>
                {stripePlan?.items?.data?.[0]?.price?.lookup_key !=
                  "OPTIML_ESSENTIALS" &&
                  stripePlan?.items?.data?.[0]?.price?.lookup_key !=
                    "OPTIML_ESSENTIALS_M" &&
                  listPlace === 0 && (
                    <div className="action-plan-download-btns">
                      <div
                        className="action-plan-download-btn"
                        onMouseEnter={() => setHoverCSV(true)}
                        onMouseLeave={() => setHoverCSV(false)}
                      >
                        <DownloadPDF
                          spouse1Data={userOptiData?.primaryUserData}
                          spouse2Data={userOptiData?.spouseData}
                          firstName={firstName}
                          lastName={lastName}
                          spouseFirstName={spouseFirstName}
                          spouseLastName={spouseLastName}
                          year={year}
                          index={listPlace}
                          graphImage={graphImage}
                          spouseStatus={spouseStatus}
                          spouseGraphImage={spouseGraphImage}
                          userTFSAInfo={userTFSAInfo}
                          userFHSAInfo={userFHSAInfo}
                          userNonRegInvestmentsInfo={userNonRegInvestmentsInfo}
                          userFixedIncomeInfo={userFixedIncomeInfo}
                          userRRSPInfo={userRRSPInfo}
                          userLiraInfo={userLiraInfo}
                          userPensionInfo={userPensionInfo}
                          userBusinessInfo={userBusinessInfo}
                        />
                      </div>
                    </div>
                  )}
              </Box>

              <Box
                display={"flex"}
                padding={"10px"}
                flexDirection={"column"}
                alignItems={"flex-start"}
                gap={"10px"}
                alignSelf={"stretch"}
                borderRadius={"5px"}
                border={"1px solid #1EADD9"}
                bg={"#FFF"}
              >
                <Text
                  alignSelf={"stretch"}
                  color={"#004069"}
                  fontFamily={"Nunito"}
                  fontSize={isMobile ? "14px" : "24px"}
                  fontStyle={"normal"}
                  fontWeight={"700"}
                  lineHeight={"normal"}
                >
                  {firstName}, this year you need to...
                </Text>
                <Box
                  display={"flex"}
                  justifyContent={"space-between"}
                  alignItems={"center"}
                  alignSelf={"stretch"}
                  bg={"#FFF"}
                >
                  <Box
                    display={"flex"}
                    width={"100%"}
                    flexDirection={"column"}
                    alignItems={"flex-start"}
                    gap={"10px"}
                    alignSelf={"stretch"}
                    bg={"#FFF"}
                  >
                    <VStack alignItems={"baseline"} alignSelf={"stretch"}>
                      {userOptiData?.primaryUserData?.sps1_tfsaWithdrawals?.[
                        listPlace
                      ] >= minTrx && (
                        <li className="action-plan-instructions">
                          <Text
                            color={"#004069"}
                            fontFamily={"Nunito"}
                            fontSize={"16px"}
                            fontStyle={"normal"}
                            fontWeight={"400"}
                            lineHeight={"normal"}
                            display={"inline"}
                          >
                            Withdraw{" "}
                            <Text
                              as={"span"}
                              color={"#00A2D4"}
                              fontFamily={"Nunito"}
                              fontSize={fontSize}
                              fontStyle={"normal"}
                              fontWeight={"800"}
                              lineHeight={"normal"}
                            >
                              {currencyFormat(
                                userOptiData?.primaryUserData
                                  ?.sps1_tfsaWithdrawals?.[listPlace]
                              )}{" "}
                            </Text>
                            from your
                            <Text
                              as={"span"}
                              color={"#004069"}
                              fontFamily={"Nunito"}
                              fontSize={fontSize}
                              fontStyle={"normal"}
                              fontWeight={"800"}
                              lineHeight={"normal"}
                            >
                              {" "}
                              TFSA{" "}
                            </Text>
                            <br />
                            <Text
                              as={"span"}
                              color={"#00A2D4"}
                              fontFamily={"Nunito"}
                              fontSize={fontSize}
                              fontStyle={"normal"}
                              fontWeight={"800"}
                              lineHeight={"normal"}
                              ml={"26px"}
                            >
                              {currencyFormat(
                                userOptiData?.primaryUserData
                                  ?.sps1_tfsaWithdrawals?.[listPlace] /
                                  monthsLeft
                              )}{" "}
                            </Text>
                            per month
                          </Text>
                          <br />
                          <Text
                            color={"#004069"}
                            fontFamily={"Nunito"}
                            fontSize={"14px"}
                            fontStyle={"normal"}
                            fontWeight={"400"}
                            lineHeight={"normal"}
                            ml={"26px"}
                          >
                            Expected annual growth rate:{" "}
                            {userTFSAInfo?.TFSA_ROR}%
                          </Text>
                        </li>
                      )}
                      {userOptiData?.primaryUserData?.sps1_fhsaWithdrawals?.[
                        listPlace
                      ] >= minTrx && (
                        <li className="action-plan-instructions">
                          <Text
                            color={"#004069"}
                            fontFamily={"Nunito"}
                            fontSize={"16px"}
                            fontStyle={"normal"}
                            fontWeight={"400"}
                            lineHeight={"normal"}
                            display={"inline"}
                          >
                            Withdraw{" "}
                            <Text
                              as={"span"}
                              color={"#00A2D4"}
                              fontFamily={"Nunito"}
                              fontSize={fontSize}
                              fontStyle={"normal"}
                              fontWeight={"800"}
                              lineHeight={"normal"}
                            >
                              {currencyFormat(
                                userOptiData?.primaryUserData
                                  ?.sps1_fhsaWithdrawals?.[listPlace]
                              )}{" "}
                            </Text>
                            from your
                            <Text
                              as={"span"}
                              color={"#004069"}
                              fontFamily={"Nunito"}
                              fontSize={fontSize}
                              fontStyle={"normal"}
                              fontWeight={"800"}
                              lineHeight={"normal"}
                            >
                              {" "}
                              FHSA{" "}
                            </Text>
                            <br />
                            <Text
                              as={"span"}
                              color={"#00A2D4"}
                              fontFamily={"Nunito"}
                              fontSize={fontSize}
                              fontStyle={"normal"}
                              fontWeight={"800"}
                              lineHeight={"normal"}
                              ml={"26px"}
                            >
                              {currencyFormat(
                                userOptiData?.primaryUserData
                                  ?.sps1_fhsaWithdrawals?.[listPlace]
                              )}{" "}
                            </Text>
                            during home purchase month
                          </Text>
                          <br />
                          <Text
                            color={"#004069"}
                            fontFamily={"Nunito"}
                            fontSize={"14px"}
                            fontStyle={"normal"}
                            fontWeight={"400"}
                            lineHeight={"normal"}
                            ml={"26px"}
                          >
                            Expected annual growth rate:{" "}
                            {userFHSAInfo?.fhsaGrowth}%
                          </Text>
                        </li>
                      )}
                      {userOptiData?.primaryUserData
                        ?.sps1_registeredWithdrawals?.[listPlace] >= minTrx && (
                        <li className="action-plan-instructions">
                          <Text
                            color={"#004069"}
                            fontFamily={"Nunito"}
                            fontSize={"16px"}
                            fontStyle={"normal"}
                            fontWeight={"400"}
                            lineHeight={"normal"}
                            display={"inline"}
                          >
                            Withdraw{" "}
                            <Text
                              as={"span"}
                              color={"#00A2D4"}
                              fontFamily={"Nunito"}
                              fontSize={fontSize}
                              fontStyle={"normal"}
                              fontWeight={"800"}
                              lineHeight={"normal"}
                            >
                              {currencyFormat(
                                userOptiData?.primaryUserData
                                  ?.sps1_registeredWithdrawals?.[listPlace]
                              )}{" "}
                            </Text>
                            from your
                            <Text
                              as={"span"}
                              color={"#004069"}
                              fontFamily={"Nunito"}
                              fontSize={fontSize}
                              fontStyle={"normal"}
                              fontWeight={"800"}
                              lineHeight={"normal"}
                            >
                              {" "}
                              RRSP{" "}
                            </Text>
                            <br />
                            <Text
                              as={"span"}
                              color={"#00A2D4"}
                              fontFamily={"Nunito"}
                              fontSize={fontSize}
                              fontStyle={"normal"}
                              fontWeight={"800"}
                              lineHeight={"normal"}
                              ml={"26px"}
                            >
                              {currencyFormat(
                                userOptiData?.primaryUserData
                                  ?.sps1_registeredWithdrawals?.[listPlace] /
                                  monthsLeft
                              )}{" "}
                            </Text>
                            per month
                          </Text>
                          <br />
                          <Text
                            color={"#004069"}
                            fontFamily={"Nunito"}
                            fontSize={"14px"}
                            fontStyle={"normal"}
                            fontWeight={"400"}
                            lineHeight={"normal"}
                            ml={"26px"}
                          >
                            Expected annual growth rate:{" "}
                            {userRRSPInfo?.RRSP_ROR}%
                          </Text>
                        </li>
                      )}
                      {userOptiData?.primaryUserData
                        ?.sps1_nonregisteredWithdrawals?.[listPlace] >=
                        minTrx && (
                        <li className="action-plan-instructions">
                          <Text
                            color={"#004069"}
                            fontFamily={"Nunito"}
                            fontSize={"16px"}
                            fontStyle={"normal"}
                            fontWeight={"400"}
                            lineHeight={"normal"}
                            display={"inline"}
                          >
                            Withdraw{" "}
                            <Text
                              as={"span"}
                              color={"#00A2D4"}
                              fontFamily={"Nunito"}
                              fontSize={fontSize}
                              fontStyle={"normal"}
                              fontWeight={"800"}
                              lineHeight={"normal"}
                            >
                              {currencyFormat(
                                userOptiData?.primaryUserData
                                  ?.sps1_nonregisteredWithdrawals?.[listPlace]
                              )}{" "}
                            </Text>
                            from your
                            <Text
                              as={"span"}
                              color={"#004069"}
                              fontFamily={"Nunito"}
                              fontSize={fontSize}
                              fontStyle={"normal"}
                              fontWeight={"800"}
                              lineHeight={"normal"}
                            >
                              {" "}
                              Non-Registered{" "}
                            </Text>
                            <br />
                            <Text
                              as={"span"}
                              color={"#00A2D4"}
                              fontFamily={"Nunito"}
                              fontSize={fontSize}
                              fontStyle={"normal"}
                              fontWeight={"800"}
                              lineHeight={"normal"}
                              ml={"26px"}
                            >
                              {currencyFormat(
                                userOptiData?.primaryUserData
                                  ?.sps1_nonregisteredWithdrawals?.[listPlace] /
                                  monthsLeft
                              )}{" "}
                            </Text>
                            per month
                          </Text>
                          <br />
                          <Text
                            color={"#004069"}
                            fontFamily={"Nunito"}
                            fontSize={"14px"}
                            fontStyle={"normal"}
                            fontWeight={"400"}
                            lineHeight={"normal"}
                            ml={"26px"}
                          >
                            Expected annual growth rate:{" "}
                            {userNonRegInvestmentsInfo?.Non_Reg_ROR}%
                          </Text>
                        </li>
                      )}
                      {userOptiData?.primaryUserData?.sps1_cashWithdrawals?.[
                        listPlace
                      ] >= minTrx && (
                        <li className="action-plan-instructions">
                          <Text
                            color={"#004069"}
                            fontFamily={"Nunito"}
                            fontSize={"16px"}
                            fontStyle={"normal"}
                            fontWeight={"400"}
                            lineHeight={"normal"}
                            display={"inline"}
                          >
                            Withdraw{" "}
                            <Text
                              as={"span"}
                              color={"#00A2D4"}
                              fontFamily={"Nunito"}
                              fontSize={fontSize}
                              fontStyle={"normal"}
                              fontWeight={"800"}
                              lineHeight={"normal"}
                            >
                              {currencyFormat(
                                userOptiData?.primaryUserData
                                  ?.sps1_cashWithdrawals?.[listPlace]
                              )}{" "}
                            </Text>
                            from your
                            <Text
                              as={"span"}
                              color={"#004069"}
                              fontFamily={"Nunito"}
                              fontSize={fontSize}
                              fontStyle={"normal"}
                              fontWeight={"800"}
                              lineHeight={"normal"}
                            >
                              {" "}
                              Cash Savings{" "}
                            </Text>
                            <br />
                            <Text
                              as={"span"}
                              color={"#00A2D4"}
                              fontFamily={"Nunito"}
                              fontSize={fontSize}
                              fontStyle={"normal"}
                              fontWeight={"800"}
                              lineHeight={"normal"}
                              ml={"26px"}
                            >
                              {currencyFormat(
                                userOptiData?.primaryUserData
                                  ?.sps1_cashWithdrawals?.[listPlace] /
                                  monthsLeft
                              )}{" "}
                            </Text>
                            per month
                          </Text>
                        </li>
                      )}
                      {userOptiData?.primaryUserData
                        ?.sps1_fixedIncomeWithdrawals?.[listPlace] >=
                        minTrx && (
                        <li className="action-plan-instructions">
                          <Text
                            color={"#004069"}
                            fontFamily={"Nunito"}
                            fontSize={"16px"}
                            fontStyle={"normal"}
                            fontWeight={"400"}
                            lineHeight={"normal"}
                            display={"inline"}
                          >
                            Withdraw{" "}
                            <Text
                              as={"span"}
                              color={"#00A2D4"}
                              fontFamily={"Nunito"}
                              fontSize={fontSize}
                              fontStyle={"normal"}
                              fontWeight={"800"}
                              lineHeight={"normal"}
                            >
                              {currencyFormat(
                                userOptiData?.primaryUserData
                                  ?.sps1_fixedIncomeWithdrawals?.[listPlace]
                              )}{" "}
                            </Text>
                            from your
                            <Text
                              as={"span"}
                              color={"#004069"}
                              fontFamily={"Nunito"}
                              fontSize={fontSize}
                              fontStyle={"normal"}
                              fontWeight={"800"}
                              lineHeight={"normal"}
                            >
                              {" "}
                              Fixed Income{" "}
                            </Text>
                            <br />
                            <Text
                              as={"span"}
                              color={"#00A2D4"}
                              fontFamily={"Nunito"}
                              fontSize={fontSize}
                              fontStyle={"normal"}
                              fontWeight={"800"}
                              lineHeight={"normal"}
                              ml={"26px"}
                            >
                              {currencyFormat(
                                userOptiData?.primaryUserData
                                  ?.sps1_fixedIncomeWithdrawals?.[listPlace] /
                                  monthsLeft
                              )}{" "}
                            </Text>
                            per month
                          </Text>
                          <br />
                          <Text
                            color={"#004069"}
                            fontFamily={"Nunito"}
                            fontSize={"14px"}
                            fontStyle={"normal"}
                            fontWeight={"400"}
                            lineHeight={"normal"}
                            ml={"26px"}
                          >
                            Expected annual growth rate: {averageInterest}%
                          </Text>
                        </li>
                      )}
                      {userOptiData?.primaryUserData?.sps1_liraWithdrawals?.[
                        listPlace
                      ] >= minTrx && (
                        <li className="action-plan-instructions">
                          <Text
                            color={"#004069"}
                            fontFamily={"Nunito"}
                            fontSize={"16px"}
                            fontStyle={"normal"}
                            fontWeight={"400"}
                            lineHeight={"normal"}
                            display={"inline"}
                          >
                            Withdraw{" "}
                            <Text
                              as={"span"}
                              color={"#00A2D4"}
                              fontFamily={"Nunito"}
                              fontSize={fontSize}
                              fontStyle={"normal"}
                              fontWeight={"800"}
                              lineHeight={"normal"}
                            >
                              {currencyFormat(
                                userOptiData?.primaryUserData
                                  ?.sps1_liraWithdrawals?.[listPlace]
                              )}{" "}
                            </Text>
                            from your
                            <Text
                              as={"span"}
                              color={"#004069"}
                              fontFamily={"Nunito"}
                              fontSize={fontSize}
                              fontStyle={"normal"}
                              fontWeight={"800"}
                              lineHeight={"normal"}
                            >
                              {" "}
                              LIRA{" "}
                            </Text>
                            <Text
                              as={"span"}
                              color={"#00A2D4"}
                              fontFamily={"Nunito"}
                              fontSize={fontSize}
                              fontStyle={"normal"}
                              fontWeight={"800"}
                              lineHeight={"normal"}
                            >
                              {currencyFormat(
                                userOptiData?.primaryUserData
                                  ?.sps1_liraWithdrawals?.[listPlace] /
                                  monthsLeft
                              )}{" "}
                            </Text>
                            per month
                          </Text>
                          <br />
                          <Text
                            color={"#004069"}
                            fontFamily={"Nunito"}
                            fontSize={"14px"}
                            fontStyle={"normal"}
                            fontWeight={"400"}
                            lineHeight={"normal"}
                            ml={"26px"}
                          >
                            Expected annual growth rate:{" "}
                            {userLiraInfo?.LIRA_ROR}%
                          </Text>
                        </li>
                      )}
                      {userOptiData?.primaryUserData?.sps1_tfsaContributions?.[
                        listPlace
                      ] >= minTrx && (
                        <li className="action-plan-instructions">
                          <Text
                            color={"#004069"}
                            fontFamily={"Nunito"}
                            fontSize={"16px"}
                            fontStyle={"normal"}
                            fontWeight={"400"}
                            lineHeight={"normal"}
                            display={"inline"}
                          >
                            Deposit{" "}
                            <Text
                              as={"span"}
                              color={"#00A2D4"}
                              fontFamily={"Nunito"}
                              fontSize={fontSize}
                              fontStyle={"normal"}
                              fontWeight={"800"}
                              lineHeight={"normal"}
                            >
                              {currencyFormat(
                                userOptiData?.primaryUserData
                                  ?.sps1_tfsaContributions?.[listPlace]
                              )}{" "}
                            </Text>
                            into your
                            <Text
                              as={"span"}
                              color={"#004069"}
                              fontFamily={"Nunito"}
                              fontSize={fontSize}
                              fontStyle={"normal"}
                              fontWeight={"800"}
                              lineHeight={"normal"}
                            >
                              {" "}
                              TFSA{" "}
                            </Text>
                            <Text
                              as={"span"}
                              color={"#00A2D4"}
                              fontFamily={"Nunito"}
                              fontSize={fontSize}
                              fontStyle={"normal"}
                              fontWeight={"800"}
                              lineHeight={"normal"}
                            >
                              {currencyFormat(
                                userOptiData?.primaryUserData
                                  ?.sps1_tfsaContributions?.[listPlace] /
                                  monthsLeft
                              )}{" "}
                            </Text>
                            per month
                          </Text>
                          <br />
                          <Text
                            color={"#004069"}
                            fontFamily={"Nunito"}
                            fontSize={"14px"}
                            fontStyle={"normal"}
                            fontWeight={"400"}
                            lineHeight={"normal"}
                            ml={"26px"}
                          >
                            Expected annual growth rate:{" "}
                            {userTFSAInfo?.TFSA_ROR}%
                          </Text>
                        </li>
                      )}
                      {userOptiData?.primaryUserData?.sps1_fhsaContributions?.[
                        listPlace
                      ] >= minTrx && (
                        <li className="action-plan-instructions">
                          <Text
                            color={"#004069"}
                            fontFamily={"Nunito"}
                            fontSize={"16px"}
                            fontStyle={"normal"}
                            fontWeight={"400"}
                            lineHeight={"normal"}
                            display={"inline"}
                          >
                            Deposit{" "}
                            <Text
                              as={"span"}
                              color={"#00A2D4"}
                              fontFamily={"Nunito"}
                              fontSize={fontSize}
                              fontStyle={"normal"}
                              fontWeight={"800"}
                              lineHeight={"normal"}
                            >
                              {currencyFormat(
                                userOptiData?.primaryUserData
                                  ?.sps1_fhsaContributions?.[listPlace]
                              )}{" "}
                            </Text>
                            into your
                            <Text
                              as={"span"}
                              color={"#004069"}
                              fontFamily={"Nunito"}
                              fontSize={fontSize}
                              fontStyle={"normal"}
                              fontWeight={"800"}
                              lineHeight={"normal"}
                            >
                              {" "}
                              FHSA{" "}
                            </Text>
                            <Text
                              as={"span"}
                              color={"#00A2D4"}
                              fontFamily={"Nunito"}
                              fontSize={fontSize}
                              fontStyle={"normal"}
                              fontWeight={"800"}
                              lineHeight={"normal"}
                            >
                              {currencyFormat(
                                userOptiData?.primaryUserData
                                  ?.sps1_fhsaContributions?.[listPlace] /
                                  monthsLeft
                              )}{" "}
                            </Text>
                            per month
                          </Text>
                          <br />
                          <Text
                            color={"#004069"}
                            fontFamily={"Nunito"}
                            fontSize={"14px"}
                            fontStyle={"normal"}
                            fontWeight={"400"}
                            lineHeight={"normal"}
                            ml={"26px"}
                          >
                            Expected annual growth rate:{" "}
                            {userFHSAInfo?.fhsaGrowth}%
                          </Text>
                        </li>
                      )}
                      {safeAdd(
                        userOptiData?.primaryUserData
                          ?.sps1_registeredContributions?.[listPlace],
                        userOptiData?.primaryUserData
                          ?.sps1_registeredContributionsMatched?.[listPlace]
                      ) >= minTrx && (
                        <li className="action-plan-instructions">
                          <Text
                            color={"#004069"}
                            fontFamily={"Nunito"}
                            fontSize={"16px"}
                            fontStyle={"normal"}
                            fontWeight={"400"}
                            lineHeight={"normal"}
                            display={"inline"}
                          >
                            Deposit{" "}
                            <Text
                              as={"span"}
                              color={"#00A2D4"}
                              fontFamily={"Nunito"}
                              fontSize={fontSize}
                              fontStyle={"normal"}
                              fontWeight={"800"}
                              lineHeight={"normal"}
                            >
                              {currencyFormat(
                                safeAdd(
                                  userOptiData?.primaryUserData
                                    ?.sps1_registeredContributions?.[listPlace],
                                  userOptiData?.primaryUserData
                                    ?.sps1_registeredContributionsMatched?.[
                                    listPlace
                                  ]
                                )
                              )}{" "}
                            </Text>
                            into your
                            <Text
                              as={"span"}
                              color={"#004069"}
                              fontFamily={"Nunito"}
                              fontSize={fontSize}
                              fontStyle={"normal"}
                              fontWeight={"800"}
                              lineHeight={"normal"}
                            >
                              {" "}
                              RRSP{" "}
                            </Text>
                            <br />
                            <Text
                              as={"span"}
                              color={"#00A2D4"}
                              fontFamily={"Nunito"}
                              fontSize={fontSize}
                              fontStyle={"normal"}
                              fontWeight={"800"}
                              lineHeight={"normal"}
                              ml={"26px"}
                            >
                              {currencyFormat(
                                safeAdd(
                                  userOptiData?.primaryUserData
                                    ?.sps1_registeredContributions?.[
                                    listPlace
                                  ] / monthsLeft,
                                  userOptiData?.primaryUserData
                                    ?.sps1_registeredContributionsMatched?.[
                                    listPlace
                                  ] / monthsLeft
                                )
                              )}{" "}
                            </Text>
                            per month
                          </Text>
                          <br />
                          <Text
                            color={"#004069"}
                            fontFamily={"Nunito"}
                            fontSize={"14px"}
                            fontStyle={"normal"}
                            fontWeight={"400"}
                            lineHeight={"normal"}
                            ml={"26px"}
                          >
                            Expected annual growth rate:{" "}
                            {userRRSPInfo?.RRSP_ROR}%
                          </Text>
                        </li>
                      )}
                      {userOptiData?.primaryUserData
                        ?.sps1_nonRegisteredContributions?.[listPlace] >=
                        minTrx && (
                        <li className="action-plan-instructions">
                          <Text
                            color={"#004069"}
                            fontFamily={"Nunito"}
                            fontSize={"16px"}
                            fontStyle={"normal"}
                            fontWeight={"400"}
                            lineHeight={"normal"}
                            display={"inline"}
                          >
                            Deposit{" "}
                            <Text
                              as={"span"}
                              color={"#00A2D4"}
                              fontFamily={"Nunito"}
                              fontSize={fontSize}
                              fontStyle={"normal"}
                              fontWeight={"800"}
                              lineHeight={"normal"}
                            >
                              {currencyFormat(
                                userOptiData?.primaryUserData
                                  ?.sps1_nonRegisteredContributions?.[listPlace]
                              )}{" "}
                            </Text>
                            into your
                            <Text
                              as={"span"}
                              color={"#004069"}
                              fontFamily={"Nunito"}
                              fontSize={fontSize}
                              fontStyle={"normal"}
                              fontWeight={"800"}
                              lineHeight={"normal"}
                            >
                              {" "}
                              Non-Registered{" "}
                            </Text>
                            <br />
                            <Text
                              as={"span"}
                              color={"#00A2D4"}
                              fontFamily={"Nunito"}
                              fontSize={fontSize}
                              fontStyle={"normal"}
                              fontWeight={"800"}
                              lineHeight={"normal"}
                              ml={"26px"}
                            >
                              {currencyFormat(
                                userOptiData?.primaryUserData
                                  ?.sps1_nonRegisteredContributions?.[
                                  listPlace
                                ] / monthsLeft
                              )}{" "}
                            </Text>
                            per month
                          </Text>
                          <br />
                          <Text
                            color={"#004069"}
                            fontFamily={"Nunito"}
                            fontSize={"14px"}
                            fontStyle={"normal"}
                            fontWeight={"400"}
                            lineHeight={"normal"}
                            ml={"26px"}
                          >
                            Expected annual growth rate:{" "}
                            {userNonRegInvestmentsInfo?.Non_Reg_ROR}%
                          </Text>
                        </li>
                      )}
                      {/* {userOptiData?.primaryUserData
                        ?.sps1_registeredContributionsMatched?.[listPlace] >=
                        minTrx && (
                        <li className="action-plan-instructions">
                          <Text
                            color={"#004069"}
                            fontFamily={"Nunito"}
                            fontSize={"16px"}
                            fontStyle={"normal"}
                            fontWeight={"400"}
                            lineHeight={"normal"}
                            display={"inline"}
                          >
                            Your employer will contribute{" "}
                            <Text
                              as={"span"}
                              color={"#00A2D4"}
                              fontFamily={"Nunito"}
                              fontSize={fontSize}
                              fontStyle={"normal"}
                              fontWeight={"800"}
                              lineHeight={"normal"}
                            >
                              {currencyFormat(
                                userOptiData?.primaryUserData
                                  ?.sps1_registeredContributionsMatched?.[
                                  listPlace
                                ]
                              )}{" "}
                            </Text>
                            into your
                            <Text
                              as={"span"}
                              color={"#004069"}
                              fontFamily={"Nunito"}
                              fontSize={fontSize}
                              fontStyle={"normal"}
                              fontWeight={"800"}
                              lineHeight={"normal"}
                            >
                              {" "}
                              RRSP {""}
                            </Text>
                            <br />
                            <Text
                              as={"span"}
                              color={"#00A2D4"}
                              fontFamily={"Nunito"}
                              fontSize={fontSize}
                              fontStyle={"normal"}
                              fontWeight={"800"}
                              lineHeight={"normal"}
                            >
                              {" "}
                              {currencyFormat(
                                userOptiData?.primaryUserData
                                  ?.sps1_registeredContributionsMatched?.[
                                  listPlace
                                ] / monthsLeft
                              )}{" "}
                            </Text>
                            per month
                          </Text>
                          <br />
                          <Text
                            color={"#004069"}
                            fontFamily={"Nunito"}
                            fontSize={"14px"}
                            fontStyle={"normal"}
                            fontWeight={"400"}
                            lineHeight={"normal"}
                            ml={"26px"}
                          >
                            Expected annual growth rate:{" "}
                            {userRRSPInfo?.RRSP_ROR}%
                          </Text>
                        </li>
                      )} */}
                      {userOptiData?.primaryUserData?.sps1_pension
                        ?.EmployerContributions?.[listPlace] >= minTrx && (
                        <li className="action-plan-instructions">
                          <Text
                            color={"#004069"}
                            fontFamily={"Nunito"}
                            fontSize={"16px"}
                            fontStyle={"normal"}
                            fontWeight={"400"}
                            lineHeight={"normal"}
                            display={"inline"}
                          >
                            Contribute{" "}
                            <Text
                              as={"span"}
                              color={"#00A2D4"}
                              fontFamily={"Nunito"}
                              fontSize={fontSize}
                              fontStyle={"normal"}
                              fontWeight={"800"}
                              lineHeight={"normal"}
                            >
                              {currencyFormat(
                                userOptiData?.primaryUserData?.sps1_pension
                                  ?.EmployerContributions?.[listPlace]
                              )}{" "}
                            </Text>
                            into your
                            <Text
                              as={"span"}
                              color={"#004069"}
                              fontFamily={"Nunito"}
                              fontSize={fontSize}
                              fontStyle={"normal"}
                              fontWeight={"800"}
                              lineHeight={"normal"}
                            >
                              {" "}
                              Pension{" "}
                            </Text>
                            <Text
                              as={"span"}
                              color={"#00A2D4"}
                              fontFamily={"Nunito"}
                              fontSize={fontSize}
                              fontStyle={"normal"}
                              fontWeight={"800"}
                              lineHeight={"normal"}
                            >
                              {currencyFormat(
                                userOptiData?.primaryUserData?.sps1_pension
                                  ?.EmployerContributions?.[listPlace] /
                                  monthsLeft
                              )}{" "}
                            </Text>
                            per month
                          </Text>
                          <br />
                          <Text
                            color={"#004069"}
                            fontFamily={"Nunito"}
                            fontSize={"14px"}
                            fontStyle={"normal"}
                            fontWeight={"400"}
                            lineHeight={"normal"}
                            ml={"26px"}
                            mt={"10px"}
                          >
                            Expected annual growth rate:{" "}
                            {singleFoundItem?.Pension_Index}%
                          </Text>
                        </li>
                      )}
                      {/* {userOptiData?.primaryUserData?.sps1_pension
                        ?.EmployerContributions?.[listPlace] >= minTrx && (
                        <li className="action-plan-instructions">
                          <Text
                            color={"#004069"}
                            fontFamily={"Nunito"}
                            fontSize={"16px"}
                            fontStyle={"normal"}
                            fontWeight={"400"}
                            lineHeight={"normal"}
                            display={"inline"}
                          >
                            Your employer will contribute{" "}
                            <Text
                              as={"span"}
                              color={"#00A2D4"}
                              fontFamily={"Nunito"}
                              fontSize={fontSize}
                              fontStyle={"normal"}
                              fontWeight={"800"}
                              lineHeight={"normal"}
                            >
                              {currencyFormat(
                                userOptiData?.primaryUserData?.sps1_pension
                                  ?.EmployerContributions?.[listPlace]
                              )}{" "}
                            </Text>
                            into your
                            <Text
                              as={"span"}
                              color={"#004069"}
                              fontFamily={"Nunito"}
                              fontSize={fontSize}
                              fontStyle={"normal"}
                              fontWeight={"800"}
                              lineHeight={"normal"}
                            >
                              {" "}
                              Pension{" "}
                            </Text>
                            <Text
                              as={"span"}
                              color={"#00A2D4"}
                              fontFamily={"Nunito"}
                              fontSize={fontSize}
                              fontStyle={"normal"}
                              fontWeight={"800"}
                              lineHeight={"normal"}
                            >
                              {currencyFormat(
                                userOptiData?.primaryUserData?.sps1_pension
                                  ?.EmployerContributions?.[listPlace] / monthsLeft
                              )}{" "}
                            </Text>
                            per month
                          </Text>
                          <br />
                          <Text
                            color={"#004069"}
                            fontFamily={"Nunito"}
                            fontSize={"14px"}
                            fontStyle={"normal"}
                            fontWeight={"400"}
                            lineHeight={"normal"}
                            ml={"26px"}
                          >
                            Expected annual growth rate:{" "}
                            {singleFoundItem?.Pension_Index}%
                          </Text>
                        </li>
                      )} */}

                      {(userOptiData?.primaryUserData
                        ?.ccpcYearlyCapitalDividendsIssued?.[listPlace] >=
                        minTrx ||
                        userOptiData?.primaryUserData
                          ?.ccpcYearlyEligibleDividendsIssued?.[listPlace] >=
                          minTrx ||
                        userOptiData?.primaryUserData
                          ?.ccpcYearlyNonEligibleDividendsIssued?.[listPlace] >=
                          minTrx ||
                        userOptiData?.primaryUserData
                          ?.ccpcYearlyShareholderLoanRepayments?.[listPlace] >=
                          minTrx) && (
                        <li className="action-plan-instructions">
                          <Text
                            as={"span"}
                            color={"#004069"}
                            fontFamily={"Nunito"}
                            fontSize={fontSize}
                            fontStyle={"normal"}
                            fontWeight={"800"}
                            lineHeight={"normal"}
                          >
                            CCPC Dividends Issued
                          </Text>
                        </li>
                      )}
                      {userOptiData?.primaryUserData
                        ?.ccpcYearlyCapitalDividendsIssued?.[listPlace] >=
                        minTrx && (
                        <li className="action-plan-instructions">
                          <Text
                            color={"#004069"}
                            fontFamily={"Nunito"}
                            fontSize={"16px"}
                            fontStyle={"normal"}
                            fontWeight={"400"}
                            lineHeight={"normal"}
                            display={"inline"}
                          >
                            Issue{" "}
                            <Text
                              as={"span"}
                              color={"#00A2D4"}
                              fontFamily={"Nunito"}
                              fontSize={fontSize}
                              fontStyle={"normal"}
                              fontWeight={"800"}
                              lineHeight={"normal"}
                            >
                              {spouseStatus &&
                                currencyFormat(
                                  userOptiData?.primaryUserData
                                    ?.ccpcYearlyCapitalDividendsIssued?.[
                                    listPlace
                                  ] / 2
                                )}
                              {!spouseStatus &&
                                currencyFormat(
                                  userOptiData?.primaryUserData
                                    ?.ccpcYearlyCapitalDividendsIssued?.[
                                    listPlace
                                  ]
                                )}{" "}
                            </Text>
                            as a
                          </Text>
                          <Text
                            as={"span"}
                            color={"#004069"}
                            fontFamily={"Nunito"}
                            fontSize={fontSize}
                            fontStyle={"normal"}
                            fontWeight={"800"}
                            lineHeight={"normal"}
                          >
                            {" "}
                            Capital dividend
                          </Text>
                        </li>
                      )}
                      {userOptiData?.primaryUserData
                        ?.ccpcYearlyEligibleDividendsIssued?.[listPlace] >=
                        minTrx && (
                        <li className="action-plan-instructions">
                          <Text
                            color={"#004069"}
                            fontFamily={"Nunito"}
                            fontSize={"16px"}
                            fontStyle={"normal"}
                            fontWeight={"400"}
                            lineHeight={"normal"}
                            display={"inline"}
                          >
                            Issue{" "}
                            <Text
                              as={"span"}
                              color={"#00A2D4"}
                              fontFamily={"Nunito"}
                              fontSize={fontSize}
                              fontStyle={"normal"}
                              fontWeight={"800"}
                              lineHeight={"normal"}
                            >
                              {spouseStatus &&
                                currencyFormat(
                                  userOptiData?.primaryUserData
                                    ?.ccpcYearlyEligibleDividendsIssued?.[
                                    listPlace
                                  ] / 2
                                )}
                              {!spouseStatus &&
                                currencyFormat(
                                  userOptiData?.primaryUserData
                                    ?.ccpcYearlyEligibleDividendsIssued?.[
                                    listPlace
                                  ]
                                )}{" "}
                            </Text>
                            as a
                          </Text>
                          <Text
                            as={"span"}
                            color={"#004069"}
                            fontFamily={"Nunito"}
                            fontSize={fontSize}
                            fontStyle={"normal"}
                            fontWeight={"800"}
                            lineHeight={"normal"}
                          >
                            {" "}
                            Eligible dividend
                          </Text>
                        </li>
                      )}
                      {userOptiData?.primaryUserData
                        ?.ccpcYearlyNonEligibleDividendsIssued?.[listPlace] >=
                        minTrx && (
                        <li className="action-plan-instructions">
                          <Text
                            color={"#004069"}
                            fontFamily={"Nunito"}
                            fontSize={"16px"}
                            fontStyle={"normal"}
                            fontWeight={"400"}
                            lineHeight={"normal"}
                            display={"inline"}
                          >
                            Issue{" "}
                            <Text
                              as={"span"}
                              color={"#00A2D4"}
                              fontFamily={"Nunito"}
                              fontSize={fontSize}
                              fontStyle={"normal"}
                              fontWeight={"800"}
                              lineHeight={"normal"}
                            >
                              {spouseStatus &&
                                currencyFormat(
                                  userOptiData?.primaryUserData
                                    ?.ccpcYearlyNonEligibleDividendsIssued?.[
                                    listPlace
                                  ] / 2
                                )}
                              {!spouseStatus &&
                                currencyFormat(
                                  userOptiData?.primaryUserData
                                    ?.ccpcYearlyNonEligibleDividendsIssued?.[
                                    listPlace
                                  ]
                                )}{" "}
                            </Text>
                            as a
                          </Text>
                          <Text
                            as={"span"}
                            color={"#004069"}
                            fontFamily={"Nunito"}
                            fontSize={fontSize}
                            fontStyle={"normal"}
                            fontWeight={"800"}
                            lineHeight={"normal"}
                          >
                            {" "}
                            Non-Eligible dividend
                          </Text>
                        </li>
                      )}
                      {userOptiData?.primaryUserData
                        ?.ccpcYearlyShareholderLoanRepayments?.[listPlace] >=
                        minTrx && (
                        <li className="action-plan-instructions">
                          <Text
                            color={"#004069"}
                            fontFamily={"Nunito"}
                            fontSize={"16px"}
                            fontStyle={"normal"}
                            fontWeight={"400"}
                            lineHeight={"normal"}
                            display={"inline"}
                          >
                            Issue{" "}
                            <Text
                              as={"span"}
                              color={"#00A2D4"}
                              fontFamily={"Nunito"}
                              fontSize={fontSize}
                              fontStyle={"normal"}
                              fontWeight={"800"}
                              lineHeight={"normal"}
                            >
                              {spouseStatus &&
                                currencyFormat(
                                  userOptiData?.primaryUserData
                                    ?.ccpcYearlyShareholderLoanRepayments?.[
                                    listPlace
                                  ] / 2
                                )}
                              {!spouseStatus &&
                                currencyFormat(
                                  userOptiData?.primaryUserData
                                    ?.ccpcYearlyShareholderLoanRepayments?.[
                                    listPlace
                                  ]
                                )}{" "}
                            </Text>
                            as a
                          </Text>
                          <Text
                            as={"span"}
                            color={"#004069"}
                            fontFamily={"Nunito"}
                            fontSize={fontSize}
                            fontStyle={"normal"}
                            fontWeight={"800"}
                            lineHeight={"normal"}
                          >
                            {" "}
                            Loan Repayment
                          </Text>
                        </li>
                      )}
                      <Box
                        display={"flex"}
                        padding={"10px"}
                        justifyContent={"center"}
                        alignItems={"center"}
                        gap={"10px"}
                        alignSelf={"stretch"}
                        border={"1px solid #00A2D4"}
                        bg={"#FFF"}
                        borderRadius={"5px"}
                        id="graphContainer"
                      >
                        <DepositsWithdrawalsGraph
                          tfsaDeposits={
                            userOptiData?.primaryUserData
                              ?.sps1_tfsaContributions?.[listPlace]
                          }
                          tfsaWithdrawals={
                            userOptiData?.primaryUserData
                              ?.sps1_tfsaWithdrawals?.[listPlace]
                          }
                          fhsaDeposits={
                            userOptiData?.primaryUserData
                              ?.sps1_fhsaContributions?.[listPlace]
                          }
                          fhsaWithdrawals={
                            userOptiData?.primaryUserData
                              ?.sps1_fhsaWithdrawals?.[listPlace]
                          }
                          rrspDeposits={safeAdd(
                            userOptiData?.primaryUserData
                              ?.sps1_registeredContributions?.[listPlace],
                            userOptiData?.primaryUserData
                              ?.sps1_registeredContributionsMatched?.[listPlace]
                          )}
                          rrspWithdrawals={
                            userOptiData?.primaryUserData
                              ?.sps1_registeredWithdrawals?.[listPlace]
                          }
                          nonRegDeposits={
                            userOptiData?.primaryUserData
                              ?.sps1_nonRegisteredContributions?.[listPlace]
                          }
                          nonRegWithdrawals={
                            userOptiData?.primaryUserData
                              ?.sps1_nonregisteredWithdrawals?.[listPlace]
                          }
                          liraWithdrawals={
                            userOptiData?.primaryUserData
                              ?.sps1_liraWithdrawals?.[listPlace]
                          }
                          cashWithdrawals={
                            userOptiData?.primaryUserData
                              ?.sps1_cashWithdrawals?.[listPlace]
                          }
                          fixedWithdrawals={
                            userOptiData?.primaryUserData
                              ?.sps1_fixedIncomeWithdrawals?.[listPlace]
                          }
                          pensionDeposits={
                            userOptiData?.primaryUserData?.sps1_pension
                              ?.EmployerContributions?.[listPlace]
                          }
                          eligibleDividends={
                            spouseStatus
                              ? userOptiData?.primaryUserData
                                  ?.ccpcYearlyEligibleDividendsIssued?.[
                                  listPlace
                                ] / 2
                              : userOptiData?.primaryUserData
                                  ?.ccpcYearlyEligibleDividendsIssued?.[
                                  listPlace
                                ]
                          }
                          nonEligibleDividends={
                            spouseStatus
                              ? userOptiData?.primaryUserData
                                  ?.ccpcYearlyNonEligibleDividendsIssued?.[
                                  listPlace
                                ] / 2
                              : userOptiData?.primaryUserData
                                  ?.ccpcYearlyNonEligibleDividendsIssued?.[
                                  listPlace
                                ]
                          }
                          capitalDividends={
                            spouseStatus
                              ? userOptiData?.primaryUserData
                                  ?.ccpcYearlyCapitalDividendsIssued?.[
                                  listPlace
                                ] / 2
                              : userOptiData?.primaryUserData
                                  ?.ccpcYearlyCapitalDividendsIssued?.[
                                  listPlace
                                ]
                          }
                          loanRepayments={
                            spouseStatus
                              ? userOptiData?.primaryUserData
                                  ?.ccpcYearlyShareholderLoanRepayments?.[
                                  listPlace
                                ] / 2
                              : userOptiData?.primaryUserData
                                  ?.ccpcYearlyShareholderLoanRepayments?.[
                                  listPlace
                                ]
                          }
                          height="280px"
                        />
                      </Box>
                    </VStack>
                  </Box>
                </Box>
              </Box>

              {/** Spouse 2 **/}
              {spouseStatus && (
                <Box
                  display={"flex"}
                  padding={"10px"}
                  flexDirection={"column"}
                  alignItems={"flex-start"}
                  gap={"10px"}
                  alignSelf={"stretch"}
                  borderRadius={"5px"}
                  border={"1px solid #1EADD9"}
                  bg={"#FFF"}
                >
                  <Text
                    alignSelf={"stretch"}
                    color={"#004069"}
                    fontFamily={"Nunito"}
                    fontSize={"14px"}
                    fontStyle={"normal"}
                    fontWeight={"700"}
                    lineHeight={"normal"}
                  >
                    {spouseFirstName}, this year you need to...
                  </Text>
                  <Box
                    display={"flex"}
                    justifyContent={"space-between"}
                    alignItems={"center"}
                    alignSelf={"stretch"}
                    bg={"#FFF"}
                  >
                    <Box
                      display={"flex"}
                      width={"100%"}
                      flexDirection={"column"}
                      alignItems={"flex-start"}
                      gap={"10px"}
                      alignSelf={"stretch"}
                      bg={"#FFF"}
                    >
                      <VStack alignItems={"baseline"} alignSelf={"stretch"}>
                        {userOptiData?.spouseData?.sps2_tfsaWithdrawals?.[
                          listPlace
                        ] >= minTrx && (
                          <li className="action-plan-instructions">
                            <Text
                              color={"#004069"}
                              fontFamily={"Nunito"}
                              fontSize={"16px"}
                              fontStyle={"normal"}
                              fontWeight={"400"}
                              lineHeight={"normal"}
                              display={"inline"}
                            >
                              Withdraw{" "}
                              <Text
                                as={"span"}
                                color={"#00A2D4"}
                                fontFamily={"Nunito"}
                                fontSize={fontSize}
                                fontStyle={"normal"}
                                fontWeight={"800"}
                                lineHeight={"normal"}
                              >
                                {currencyFormat(
                                  userOptiData?.spouseData
                                    ?.sps2_tfsaWithdrawals?.[listPlace]
                                )}{" "}
                              </Text>
                              from your
                              <Text
                                as={"span"}
                                color={"#004069"}
                                fontFamily={"Nunito"}
                                fontSize={fontSize}
                                fontStyle={"normal"}
                                fontWeight={"800"}
                                lineHeight={"normal"}
                              >
                                {" "}
                                TFSA{" "}
                              </Text>
                              <br />
                              <Text
                                as={"span"}
                                color={"#00A2D4"}
                                fontFamily={"Nunito"}
                                fontSize={fontSize}
                                fontStyle={"normal"}
                                fontWeight={"800"}
                                lineHeight={"normal"}
                                ml={"26px"}
                              >
                                {currencyFormat(
                                  userOptiData?.spouseData
                                    ?.sps2_tfsaWithdrawals?.[listPlace] /
                                    monthsLeft
                                )}{" "}
                              </Text>
                              per month
                            </Text>
                            <br />
                            <Text
                              color={"#004069"}
                              fontFamily={"Nunito"}
                              fontSize={"14px"}
                              fontStyle={"normal"}
                              fontWeight={"400"}
                              lineHeight={"normal"}
                              ml={"26px"}
                            >
                              Expected annual growth rate:{" "}
                              {userTFSAInfo?.spouseTFSA_ROR}%
                            </Text>
                          </li>
                        )}
                        {userOptiData?.spouseData?.sps2_fhsaWithdrawals?.[
                          listPlace
                        ] >= minTrx && (
                          <li className="action-plan-instructions">
                            <Text
                              color={"#004069"}
                              fontFamily={"Nunito"}
                              fontSize={"16px"}
                              fontStyle={"normal"}
                              fontWeight={"400"}
                              lineHeight={"normal"}
                              display={"inline"}
                            >
                              Withdraw{" "}
                              <Text
                                as={"span"}
                                color={"#00A2D4"}
                                fontFamily={"Nunito"}
                                fontSize={fontSize}
                                fontStyle={"normal"}
                                fontWeight={"800"}
                                lineHeight={"normal"}
                              >
                                {currencyFormat(
                                  userOptiData?.spouseData
                                    ?.sps2_fhsaWithdrawals?.[listPlace]
                                )}{" "}
                              </Text>
                              from your
                              <Text
                                as={"span"}
                                color={"#004069"}
                                fontFamily={"Nunito"}
                                fontSize={fontSize}
                                fontStyle={"normal"}
                                fontWeight={"800"}
                                lineHeight={"normal"}
                              >
                                {" "}
                                FHSA{" "}
                              </Text>
                              <br />
                              <Text
                                as={"span"}
                                color={"#00A2D4"}
                                fontFamily={"Nunito"}
                                fontSize={fontSize}
                                fontStyle={"normal"}
                                fontWeight={"800"}
                                lineHeight={"normal"}
                                ml={"26px"}
                              >
                                {currencyFormat(
                                  userOptiData?.spouseData
                                    ?.sps2_tfsaWithdrawals?.[listPlace]
                                )}{" "}
                              </Text>
                              during home purchase month
                            </Text>
                            <br />
                            <Text
                              color={"#004069"}
                              fontFamily={"Nunito"}
                              fontSize={"14px"}
                              fontStyle={"normal"}
                              fontWeight={"400"}
                              lineHeight={"normal"}
                              ml={"26px"}
                            >
                              Expected annual growth rate:{" "}
                              {userFHSAInfo?.spouseFhsaGrowth}%
                            </Text>
                          </li>
                        )}
                        {userOptiData?.spouseData?.sps2_registeredWithdrawals?.[
                          listPlace
                        ] >= minTrx && (
                          <li className="action-plan-instructions">
                            <Text
                              color={"#004069"}
                              fontFamily={"Nunito"}
                              fontSize={"16px"}
                              fontStyle={"normal"}
                              fontWeight={"400"}
                              lineHeight={"normal"}
                              display={"inline"}
                            >
                              Withdraw{" "}
                              <Text
                                as={"span"}
                                color={"#00A2D4"}
                                fontFamily={"Nunito"}
                                fontSize={fontSize}
                                fontStyle={"normal"}
                                fontWeight={"800"}
                                lineHeight={"normal"}
                              >
                                {currencyFormat(
                                  userOptiData?.spouseData
                                    ?.sps2_registeredWithdrawals?.[listPlace]
                                )}{" "}
                              </Text>
                              from your
                              <Text
                                as={"span"}
                                color={"#004069"}
                                fontFamily={"Nunito"}
                                fontSize={fontSize}
                                fontStyle={"normal"}
                                fontWeight={"800"}
                                lineHeight={"normal"}
                              >
                                {" "}
                                RRSP{" "}
                              </Text>
                              <br />
                              <Text
                                as={"span"}
                                color={"#00A2D4"}
                                fontFamily={"Nunito"}
                                fontSize={fontSize}
                                fontStyle={"normal"}
                                fontWeight={"800"}
                                lineHeight={"normal"}
                                ml={"26px"}
                              >
                                {currencyFormat(
                                  userOptiData?.spouseData
                                    ?.sps2_registeredWithdrawals?.[listPlace] /
                                    monthsLeft
                                )}{" "}
                              </Text>
                              per month
                            </Text>
                            <br />
                            <Text
                              color={"#004069"}
                              fontFamily={"Nunito"}
                              fontSize={"14px"}
                              fontStyle={"normal"}
                              fontWeight={"400"}
                              lineHeight={"normal"}
                              ml={"26px"}
                            >
                              Expected annual growth rate:{" "}
                              {userRRSPInfo?.spouseRRSP_ROR}%
                            </Text>
                          </li>
                        )}
                        {userOptiData?.spouseData
                          ?.sps2_nonregisteredWithdrawals?.[listPlace] >=
                          minTrx && (
                          <li className="action-plan-instructions">
                            <Text
                              color={"#004069"}
                              fontFamily={"Nunito"}
                              fontSize={"16px"}
                              fontStyle={"normal"}
                              fontWeight={"400"}
                              lineHeight={"normal"}
                              display={"inline"}
                            >
                              Withdraw{" "}
                              <Text
                                as={"span"}
                                color={"#00A2D4"}
                                fontFamily={"Nunito"}
                                fontSize={fontSize}
                                fontStyle={"normal"}
                                fontWeight={"800"}
                                lineHeight={"normal"}
                              >
                                {currencyFormat(
                                  userOptiData?.spouseData
                                    ?.sps2_nonregisteredWithdrawals?.[listPlace]
                                )}{" "}
                              </Text>
                              from your
                              <Text
                                as={"span"}
                                color={"#004069"}
                                fontFamily={"Nunito"}
                                fontSize={fontSize}
                                fontStyle={"normal"}
                                fontWeight={"800"}
                                lineHeight={"normal"}
                              >
                                {" "}
                                Non-Registered{" "}
                              </Text>
                              <br />
                              <Text
                                as={"span"}
                                color={"#00A2D4"}
                                fontFamily={"Nunito"}
                                fontSize={fontSize}
                                fontStyle={"normal"}
                                fontWeight={"800"}
                                lineHeight={"normal"}
                                ml={"26px"}
                              >
                                {currencyFormat(
                                  userOptiData?.spouseData
                                    ?.sps2_nonregisteredWithdrawals?.[
                                    listPlace
                                  ] / monthsLeft
                                )}{" "}
                              </Text>
                              per month
                            </Text>
                            <br />
                            <Text
                              color={"#004069"}
                              fontFamily={"Nunito"}
                              fontSize={"14px"}
                              fontStyle={"normal"}
                              fontWeight={"400"}
                              lineHeight={"normal"}
                              ml={"26px"}
                            >
                              Expected annual growth rate:{" "}
                              {userNonRegInvestmentsInfo?.spouseNon_Reg_ROR}%
                            </Text>
                          </li>
                        )}
                        {userOptiData?.spouseData?.sps2_cashWithdrawals?.[
                          listPlace
                        ] >= minTrx && (
                          <li className="action-plan-instructions">
                            <Text
                              color={"#004069"}
                              fontFamily={"Nunito"}
                              fontSize={"16px"}
                              fontStyle={"normal"}
                              fontWeight={"400"}
                              lineHeight={"normal"}
                              display={"inline"}
                            >
                              Withdraw{" "}
                              <Text
                                as={"span"}
                                color={"#00A2D4"}
                                fontFamily={"Nunito"}
                                fontSize={fontSize}
                                fontStyle={"normal"}
                                fontWeight={"800"}
                                lineHeight={"normal"}
                              >
                                {currencyFormat(
                                  userOptiData?.spouseData
                                    ?.sps2_cashWithdrawals?.[listPlace]
                                )}{" "}
                              </Text>
                              from your
                              <Text
                                as={"span"}
                                color={"#004069"}
                                fontFamily={"Nunito"}
                                fontSize={fontSize}
                                fontStyle={"normal"}
                                fontWeight={"800"}
                                lineHeight={"normal"}
                              >
                                {" "}
                                Cash Savings{" "}
                              </Text>
                              <br />
                              <Text
                                as={"span"}
                                color={"#00A2D4"}
                                fontFamily={"Nunito"}
                                fontSize={fontSize}
                                fontStyle={"normal"}
                                fontWeight={"800"}
                                lineHeight={"normal"}
                                ml={"26px"}
                              >
                                {currencyFormat(
                                  userOptiData?.spouseData
                                    ?.sps2_cashWithdrawals?.[listPlace] /
                                    monthsLeft
                                )}{" "}
                              </Text>
                              per month
                            </Text>
                          </li>
                        )}
                        {userOptiData?.spouseData
                          ?.sps2_fixedIncomeWithdrawals?.[listPlace] >=
                          minTrx && (
                          <li className="action-plan-instructions">
                            <Text
                              color={"#004069"}
                              fontFamily={"Nunito"}
                              fontSize={"16px"}
                              fontStyle={"normal"}
                              fontWeight={"400"}
                              lineHeight={"normal"}
                              display={"inline"}
                            >
                              Withdraw{" "}
                              <Text
                                as={"span"}
                                color={"#00A2D4"}
                                fontFamily={"Nunito"}
                                fontSize={fontSize}
                                fontStyle={"normal"}
                                fontWeight={"800"}
                                lineHeight={"normal"}
                              >
                                {currencyFormat(
                                  userOptiData?.spouseData
                                    ?.sps2_fixedIncomeWithdrawals?.[listPlace]
                                )}{" "}
                              </Text>
                              from your
                              <Text
                                as={"span"}
                                color={"#004069"}
                                fontFamily={"Nunito"}
                                fontSize={fontSize}
                                fontStyle={"normal"}
                                fontWeight={"800"}
                                lineHeight={"normal"}
                              >
                                {" "}
                                Fixed Income{" "}
                              </Text>
                              <br />
                              <Text
                                as={"span"}
                                color={"#00A2D4"}
                                fontFamily={"Nunito"}
                                fontSize={fontSize}
                                fontStyle={"normal"}
                                fontWeight={"800"}
                                lineHeight={"normal"}
                                ml={"26px"}
                              >
                                {currencyFormat(
                                  userOptiData?.spouseData
                                    ?.sps2_fixedIncomeWithdrawals?.[listPlace] /
                                    monthsLeft
                                )}{" "}
                              </Text>
                              per month
                            </Text>
                            <br />
                            <Text
                              color={"#004069"}
                              fontFamily={"Nunito"}
                              fontSize={"14px"}
                              fontStyle={"normal"}
                              fontWeight={"400"}
                              lineHeight={"normal"}
                              ml={"26px"}
                            >
                              Expected annual growth rate:{" "}
                              {averageInterestSpouse}%
                            </Text>
                          </li>
                        )}
                        {userOptiData?.spouseData?.sps2_liraWithdrawals?.[
                          listPlace
                        ] >= minTrx && (
                          <li className="action-plan-instructions">
                            <Text
                              color={"#004069"}
                              fontFamily={"Nunito"}
                              fontSize={"16px"}
                              fontStyle={"normal"}
                              fontWeight={"400"}
                              lineHeight={"normal"}
                              display={"inline"}
                            >
                              Withdraw{" "}
                              <Text
                                as={"span"}
                                color={"#00A2D4"}
                                fontFamily={"Nunito"}
                                fontSize={fontSize}
                                fontStyle={"normal"}
                                fontWeight={"800"}
                                lineHeight={"normal"}
                              >
                                {currencyFormat(
                                  userOptiData?.spouseData
                                    ?.sps2_liraWithdrawals?.[listPlace]
                                )}{" "}
                              </Text>
                              from your
                              <Text
                                as={"span"}
                                color={"#004069"}
                                fontFamily={"Nunito"}
                                fontSize={fontSize}
                                fontStyle={"normal"}
                                fontWeight={"800"}
                                lineHeight={"normal"}
                              >
                                {" "}
                                LIRA{" "}
                              </Text>
                              <Text
                                as={"span"}
                                color={"#00A2D4"}
                                fontFamily={"Nunito"}
                                fontSize={fontSize}
                                fontStyle={"normal"}
                                fontWeight={"800"}
                                lineHeight={"normal"}
                              >
                                {currencyFormat(
                                  userOptiData?.spouseData
                                    ?.sps2_liraWithdrawals?.[listPlace] /
                                    monthsLeft
                                )}{" "}
                              </Text>
                              per month
                            </Text>
                            <br />
                            <Text
                              color={"#004069"}
                              fontFamily={"Nunito"}
                              fontSize={"14px"}
                              fontStyle={"normal"}
                              fontWeight={"400"}
                              lineHeight={"normal"}
                              ml={"26px"}
                            >
                              Expected annual growth rate:{" "}
                              {userLiraInfo?.spouseLIRA_ROR}%
                            </Text>
                          </li>
                        )}
                        {userOptiData?.spouseData?.sps2_tfsaContributions?.[
                          listPlace
                        ] >= minTrx && (
                          <li className="action-plan-instructions">
                            <Text
                              color={"#004069"}
                              fontFamily={"Nunito"}
                              fontSize={"16px"}
                              fontStyle={"normal"}
                              fontWeight={"400"}
                              lineHeight={"normal"}
                              display={"inline"}
                            >
                              Deposit{" "}
                              <Text
                                as={"span"}
                                color={"#00A2D4"}
                                fontFamily={"Nunito"}
                                fontSize={fontSize}
                                fontStyle={"normal"}
                                fontWeight={"800"}
                                lineHeight={"normal"}
                              >
                                {currencyFormat(
                                  userOptiData?.spouseData
                                    ?.sps2_tfsaContributions?.[listPlace]
                                )}{" "}
                              </Text>
                              into your
                              <Text
                                as={"span"}
                                color={"#004069"}
                                fontFamily={"Nunito"}
                                fontSize={fontSize}
                                fontStyle={"normal"}
                                fontWeight={"800"}
                                lineHeight={"normal"}
                              >
                                {" "}
                                TFSA{" "}
                              </Text>
                              <Text
                                as={"span"}
                                color={"#00A2D4"}
                                fontFamily={"Nunito"}
                                fontSize={fontSize}
                                fontStyle={"normal"}
                                fontWeight={"800"}
                                lineHeight={"normal"}
                              >
                                {currencyFormat(
                                  userOptiData?.spouseData
                                    ?.sps2_tfsaContributions?.[listPlace] /
                                    monthsLeft
                                )}{" "}
                              </Text>
                              per month
                            </Text>
                            <br />
                            <Text
                              color={"#004069"}
                              fontFamily={"Nunito"}
                              fontSize={"14px"}
                              fontStyle={"normal"}
                              fontWeight={"400"}
                              lineHeight={"normal"}
                              ml={"26px"}
                            >
                              Expected annual growth rate:{" "}
                              {userTFSAInfo?.spouseTFSA_ROR}%
                            </Text>
                          </li>
                        )}
                        {userOptiData?.spouseData?.sps2_fhsaContributions?.[
                          listPlace
                        ] >= minTrx && (
                          <li className="action-plan-instructions">
                            <Text
                              color={"#004069"}
                              fontFamily={"Nunito"}
                              fontSize={"16px"}
                              fontStyle={"normal"}
                              fontWeight={"400"}
                              lineHeight={"normal"}
                              display={"inline"}
                            >
                              Deposit{" "}
                              <Text
                                as={"span"}
                                color={"#00A2D4"}
                                fontFamily={"Nunito"}
                                fontSize={fontSize}
                                fontStyle={"normal"}
                                fontWeight={"800"}
                                lineHeight={"normal"}
                              >
                                {currencyFormat(
                                  userOptiData?.spouseData
                                    ?.sps2_fhsaContributions?.[listPlace]
                                )}{" "}
                              </Text>
                              into your
                              <Text
                                as={"span"}
                                color={"#004069"}
                                fontFamily={"Nunito"}
                                fontSize={fontSize}
                                fontStyle={"normal"}
                                fontWeight={"800"}
                                lineHeight={"normal"}
                              >
                                {" "}
                                FHSA{" "}
                              </Text>
                              <Text
                                as={"span"}
                                color={"#00A2D4"}
                                fontFamily={"Nunito"}
                                fontSize={fontSize}
                                fontStyle={"normal"}
                                fontWeight={"800"}
                                lineHeight={"normal"}
                              >
                                {currencyFormat(
                                  userOptiData?.spouseData
                                    ?.sps2_fhsaContributions?.[listPlace] /
                                    monthsLeft
                                )}{" "}
                              </Text>
                              per month
                            </Text>
                            <br />
                            <Text
                              color={"#004069"}
                              fontFamily={"Nunito"}
                              fontSize={"14px"}
                              fontStyle={"normal"}
                              fontWeight={"400"}
                              lineHeight={"normal"}
                              ml={"26px"}
                            >
                              Expected annual growth rate:{" "}
                              {userFHSAInfo?.spouseFhsaGrowth}%
                            </Text>
                          </li>
                        )}
                        {safeAdd(
                          userOptiData?.spouseData
                            ?.sps2_registeredContributions?.[listPlace],
                          userOptiData?.spouseData
                            ?.sps2_registeredContributionsMatched?.[listPlace]
                        ) >= minTrx && (
                          <li className="action-plan-instructions">
                            <Text
                              color={"#004069"}
                              fontFamily={"Nunito"}
                              fontSize={"16px"}
                              fontStyle={"normal"}
                              fontWeight={"400"}
                              lineHeight={"normal"}
                              display={"inline"}
                            >
                              Deposit{" "}
                              <Text
                                as={"span"}
                                color={"#00A2D4"}
                                fontFamily={"Nunito"}
                                fontSize={fontSize}
                                fontStyle={"normal"}
                                fontWeight={"800"}
                                lineHeight={"normal"}
                              >
                                {currencyFormat(
                                  safeAdd(
                                    userOptiData?.spouseData
                                      ?.sps2_registeredContributions?.[
                                      listPlace
                                    ],
                                    userOptiData?.spouseData
                                      ?.sps2_registeredContributionsMatched?.[
                                      listPlace
                                    ]
                                  )
                                )}{" "}
                              </Text>
                              into your
                              <Text
                                as={"span"}
                                color={"#004069"}
                                fontFamily={"Nunito"}
                                fontSize={fontSize}
                                fontStyle={"normal"}
                                fontWeight={"800"}
                                lineHeight={"normal"}
                              >
                                {" "}
                                RRSP{" "}
                              </Text>
                              <br />
                              <Text
                                as={"span"}
                                color={"#00A2D4"}
                                fontFamily={"Nunito"}
                                fontSize={fontSize}
                                fontStyle={"normal"}
                                fontWeight={"800"}
                                lineHeight={"normal"}
                                ml={"26px"}
                              >
                                {currencyFormat(
                                  safeAdd(
                                    userOptiData?.spouseData
                                      ?.sps2_registeredContributions?.[
                                      listPlace
                                    ] / monthsLeft,
                                    userOptiData?.primaryUserData
                                      ?.sps1_registeredContributionsMatched?.[
                                      listPlace
                                    ] / monthsLeft
                                  )
                                )}{" "}
                              </Text>
                              per month
                            </Text>
                            <br />
                            <Text
                              color={"#004069"}
                              fontFamily={"Nunito"}
                              fontSize={"14px"}
                              fontStyle={"normal"}
                              fontWeight={"400"}
                              lineHeight={"normal"}
                              ml={"26px"}
                            >
                              Expected annual growth rate:{" "}
                              {userRRSPInfo?.spouseRRSP_ROR}%
                            </Text>
                          </li>
                        )}
                        {userOptiData?.spouseData
                          ?.sps2_nonRegisteredContributions?.[listPlace] >=
                          minTrx && (
                          <li className="action-plan-instructions">
                            <Text
                              color={"#004069"}
                              fontFamily={"Nunito"}
                              fontSize={"16px"}
                              fontStyle={"normal"}
                              fontWeight={"400"}
                              lineHeight={"normal"}
                              display={"inline"}
                            >
                              Deposit{" "}
                              <Text
                                as={"span"}
                                color={"#00A2D4"}
                                fontFamily={"Nunito"}
                                fontSize={fontSize}
                                fontStyle={"normal"}
                                fontWeight={"800"}
                                lineHeight={"normal"}
                              >
                                {currencyFormat(
                                  userOptiData?.spouseData
                                    ?.sps2_nonRegisteredContributions?.[
                                    listPlace
                                  ]
                                )}{" "}
                              </Text>
                              into your
                              <Text
                                as={"span"}
                                color={"#004069"}
                                fontFamily={"Nunito"}
                                fontSize={fontSize}
                                fontStyle={"normal"}
                                fontWeight={"800"}
                                lineHeight={"normal"}
                              >
                                {" "}
                                Non-Registered{" "}
                              </Text>
                              <br />
                              <Text
                                as={"span"}
                                color={"#00A2D4"}
                                fontFamily={"Nunito"}
                                fontSize={fontSize}
                                fontStyle={"normal"}
                                fontWeight={"800"}
                                lineHeight={"normal"}
                                ml={"26px"}
                              >
                                {currencyFormat(
                                  userOptiData?.spouseData
                                    ?.sps2_nonRegisteredContributions?.[
                                    listPlace
                                  ] / monthsLeft
                                )}{" "}
                              </Text>
                              per month
                            </Text>
                            <br />
                            <Text
                              color={"#004069"}
                              fontFamily={"Nunito"}
                              fontSize={"14px"}
                              fontStyle={"normal"}
                              fontWeight={"400"}
                              lineHeight={"normal"}
                              ml={"26px"}
                            >
                              Expected annual growth rate:{" "}
                              {userNonRegInvestmentsInfo?.spouseNon_Reg_ROR}%
                            </Text>
                          </li>
                        )}
                        {/* 
                      <Box
                        display={"flex"}
                        padding={"20px"}
                        justifyContent={"center"}
                        alignItems={"center"}
                        gap={"10px"}
                        alignSelf={"stretch"}
                        border={"1px solid #00A2D4"}
                        bg={"#FFF"}
                        borderRadius={"5px"}
                      ></Box> */}
                        {/* {userOptiData?.spouseData
                          ?.sps2_registeredContributionsMatched?.[listPlace] >=
                          minTrx && (
                          <li className="action-plan-instructions">
                            <Text
                              color={"#004069"}
                              fontFamily={"Nunito"}
                              fontSize={"16px"}
                              fontStyle={"normal"}
                              fontWeight={"400"}
                              lineHeight={"normal"}
                              display={"inline"}
                            >
                              Your employer will contribute{" "}
                              <Text
                                as={"span"}
                                color={"#00A2D4"}
                                fontFamily={"Nunito"}
                                fontSize={fontSize}
                                fontStyle={"normal"}
                                fontWeight={"800"}
                                lineHeight={"normal"}
                              >
                                {currencyFormat(
                                  userOptiData?.spouseData
                                    ?.sps2_registeredContributionsMatched?.[
                                    listPlace
                                  ]
                                )}{" "}
                              </Text>
                              into your
                              <Text
                                as={"span"}
                                color={"#004069"}
                                fontFamily={"Nunito"}
                                fontSize={fontSize}
                                fontStyle={"normal"}
                                fontWeight={"800"}
                                lineHeight={"normal"}
                              >
                                {" "}
                                RRSP {""}
                              </Text>
                              <br />
                              <Text
                                as={"span"}
                                color={"#00A2D4"}
                                fontFamily={"Nunito"}
                                fontSize={fontSize}
                                fontStyle={"normal"}
                                fontWeight={"800"}
                                lineHeight={"normal"}
                              >
                                {" "}
                                {currencyFormat(
                                  userOptiData?.spouseData
                                    ?.sps2_registeredContributionsMatched?.[
                                    listPlace
                                  ] / monthsLeft
                                )}{" "}
                              </Text>
                              per month
                            </Text>
                            <br />
                            <Text
                              color={"#004069"}
                              fontFamily={"Nunito"}
                              fontSize={"14px"}
                              fontStyle={"normal"}
                              fontWeight={"400"}
                              lineHeight={"normal"}
                              ml={"26px"}
                            >
                              Expected annual growth rate:{" "}
                              {userRRSPInfo?.spouseRRSP_ROR}%
                            </Text>
                          </li>
                        )} */}
                        {userOptiData?.spouseData?.sps2_pension
                          ?.EmployerContributions?.[listPlace] >= minTrx && (
                          <li className="action-plan-instructions">
                            <Text
                              color={"#004069"}
                              fontFamily={"Nunito"}
                              fontSize={"16px"}
                              fontStyle={"normal"}
                              fontWeight={"400"}
                              lineHeight={"normal"}
                              display={"inline"}
                            >
                              Contribute{" "}
                              <Text
                                as={"span"}
                                color={"#00A2D4"}
                                fontFamily={"Nunito"}
                                fontSize={fontSize}
                                fontStyle={"normal"}
                                fontWeight={"800"}
                                lineHeight={"normal"}
                              >
                                {currencyFormat(
                                  userOptiData?.spouseData?.sps2_pension
                                    ?.EmployerContributions?.[listPlace]
                                )}{" "}
                              </Text>
                              into your
                              <Text
                                as={"span"}
                                color={"#004069"}
                                fontFamily={"Nunito"}
                                fontSize={fontSize}
                                fontStyle={"normal"}
                                fontWeight={"800"}
                                lineHeight={"normal"}
                              >
                                {" "}
                                Pension{" "}
                              </Text>
                              <Text
                                as={"span"}
                                color={"#00A2D4"}
                                fontFamily={"Nunito"}
                                fontSize={fontSize}
                                fontStyle={"normal"}
                                fontWeight={"800"}
                                lineHeight={"normal"}
                              >
                                {currencyFormat(
                                  userOptiData?.spouseData?.sps2_pension
                                    ?.EmployerContributions?.[listPlace] /
                                    monthsLeft
                                )}{" "}
                              </Text>
                              per month
                            </Text>
                            <br />
                            <Text
                              color={"#004069"}
                              fontFamily={"Nunito"}
                              fontSize={"14px"}
                              fontStyle={"normal"}
                              fontWeight={"400"}
                              lineHeight={"normal"}
                              ml={"26px"}
                              mt={"10px"}
                            >
                              Expected annual growth rate:{" "}
                              {spouseFoundItem?.Pension_Index}%
                            </Text>
                          </li>
                        )}
                        {/* {userOptiData?.spouseData?.sps2_pension
                          ?.EmployerContributions?.[listPlace] >= minTrx && (
                          <li className="action-plan-instructions">
                            <Text
                              color={"#004069"}
                              fontFamily={"Nunito"}
                              fontSize={"16px"}
                              fontStyle={"normal"}
                              fontWeight={"400"}
                              lineHeight={"normal"}
                              display={"inline"}
                            >
                              Your employer will contribute{" "}
                              <Text
                                as={"span"}
                                color={"#00A2D4"}
                                fontFamily={"Nunito"}
                                fontSize={fontSize}
                                fontStyle={"normal"}
                                fontWeight={"800"}
                                lineHeight={"normal"}
                              >
                                {currencyFormat(
                                  userOptiData?.spouseData?.sps2_pension
                                    ?.EmployerContributions?.[listPlace]
                                )}{" "}
                              </Text>
                              into your
                              <Text
                                as={"span"}
                                color={"#004069"}
                                fontFamily={"Nunito"}
                                fontSize={fontSize}
                                fontStyle={"normal"}
                                fontWeight={"800"}
                                lineHeight={"normal"}
                              >
                                {" "}
                                Pension{" "}
                              </Text>
                              <Text
                                as={"span"}
                                color={"#00A2D4"}
                                fontFamily={"Nunito"}
                                fontSize={fontSize}
                                fontStyle={"normal"}
                                fontWeight={"800"}
                                lineHeight={"normal"}
                              >
                                {currencyFormat(
                                  userOptiData?.spouseData?.sps2_pension
                                    ?.EmployerContributions?.[listPlace] / monthsLeft
                                )}{" "}
                              </Text>
                              per month
                            </Text>
                            <br />
                            <Text
                              color={"#004069"}
                              fontFamily={"Nunito"}
                              fontSize={"14px"}
                              fontStyle={"normal"}
                              fontWeight={"400"}
                              lineHeight={"normal"}
                              ml={"26px"}
                            >
                              Expected annual growth rate:{" "}
                              {spouseFoundItem?.Pension_Index}%
                            </Text>
                          </li>
                        )} */}
                        {(userOptiData?.primaryUserData
                          ?.ccpcYearlyCapitalDividendsIssued?.[listPlace] >=
                          minTrx ||
                          userOptiData?.primaryUserData
                            ?.ccpcYearlyEligibleDividendsIssued?.[listPlace] >=
                            minTrx ||
                          userOptiData?.primaryUserData
                            ?.ccpcYearlyNonEligibleDividendsIssued?.[
                            listPlace
                          ] >= minTrx ||
                          userOptiData?.primaryUserData
                            ?.ccpcYearlyShareholderLoanRepayments?.[
                            listPlace
                          ] >= minTrx) && (
                          <li className="action-plan-instructions">
                            <Text
                              as={"span"}
                              color={"#004069"}
                              fontFamily={"Nunito"}
                              fontSize={fontSize}
                              fontStyle={"normal"}
                              fontWeight={"800"}
                              lineHeight={"normal"}
                            >
                              CCPC Dividends Issued
                            </Text>
                          </li>
                        )}
                        {userOptiData?.primaryUserData
                          ?.ccpcYearlyCapitalDividendsIssued?.[listPlace] >=
                          minTrx && (
                          <li className="action-plan-instructions">
                            <Text
                              color={"#004069"}
                              fontFamily={"Nunito"}
                              fontSize={"16px"}
                              fontStyle={"normal"}
                              fontWeight={"400"}
                              lineHeight={"normal"}
                              display={"inline"}
                            >
                              Issue{" "}
                              <Text
                                as={"span"}
                                color={"#00A2D4"}
                                fontFamily={"Nunito"}
                                fontSize={fontSize}
                                fontStyle={"normal"}
                                fontWeight={"800"}
                                lineHeight={"normal"}
                              >
                                {currencyFormat(
                                  userOptiData?.primaryUserData
                                    ?.ccpcYearlyCapitalDividendsIssued?.[
                                    listPlace
                                  ] / 2
                                )}{" "}
                              </Text>
                              as a
                            </Text>
                            <Text
                              as={"span"}
                              color={"#004069"}
                              fontFamily={"Nunito"}
                              fontSize={fontSize}
                              fontStyle={"normal"}
                              fontWeight={"800"}
                              lineHeight={"normal"}
                            >
                              {" "}
                              Capital dividend
                            </Text>
                          </li>
                        )}
                        {userOptiData?.primaryUserData
                          ?.ccpcYearlyEligibleDividendsIssued?.[listPlace] >=
                          minTrx && (
                          <li className="action-plan-instructions">
                            <Text
                              color={"#004069"}
                              fontFamily={"Nunito"}
                              fontSize={"16px"}
                              fontStyle={"normal"}
                              fontWeight={"400"}
                              lineHeight={"normal"}
                              display={"inline"}
                            >
                              Issue{" "}
                              <Text
                                as={"span"}
                                color={"#00A2D4"}
                                fontFamily={"Nunito"}
                                fontSize={fontSize}
                                fontStyle={"normal"}
                                fontWeight={"800"}
                                lineHeight={"normal"}
                              >
                                {currencyFormat(
                                  userOptiData?.primaryUserData
                                    ?.ccpcYearlyEligibleDividendsIssued?.[
                                    listPlace
                                  ] / 2
                                )}{" "}
                              </Text>
                              as a
                            </Text>
                            <Text
                              as={"span"}
                              color={"#004069"}
                              fontFamily={"Nunito"}
                              fontSize={fontSize}
                              fontStyle={"normal"}
                              fontWeight={"800"}
                              lineHeight={"normal"}
                            >
                              {" "}
                              Eligible dividend
                            </Text>
                          </li>
                        )}
                        {userOptiData?.primaryUserData
                          ?.ccpcYearlyNonEligibleDividendsIssued?.[listPlace] >=
                          minTrx && (
                          <li className="action-plan-instructions">
                            <Text
                              color={"#004069"}
                              fontFamily={"Nunito"}
                              fontSize={"16px"}
                              fontStyle={"normal"}
                              fontWeight={"400"}
                              lineHeight={"normal"}
                              display={"inline"}
                            >
                              Issue{" "}
                              <Text
                                as={"span"}
                                color={"#00A2D4"}
                                fontFamily={"Nunito"}
                                fontSize={fontSize}
                                fontStyle={"normal"}
                                fontWeight={"800"}
                                lineHeight={"normal"}
                              >
                                {currencyFormat(
                                  userOptiData?.primaryUserData
                                    ?.ccpcYearlyNonEligibleDividendsIssued?.[
                                    listPlace
                                  ] / 2
                                )}{" "}
                              </Text>
                              as a
                            </Text>
                            <Text
                              as={"span"}
                              color={"#004069"}
                              fontFamily={"Nunito"}
                              fontSize={fontSize}
                              fontStyle={"normal"}
                              fontWeight={"800"}
                              lineHeight={"normal"}
                            >
                              {" "}
                              Non-Eligible dividend
                            </Text>
                          </li>
                        )}
                        {userOptiData?.primaryUserData
                          ?.ccpcYearlyShareholderLoanRepayments?.[listPlace] >=
                          minTrx && (
                          <li className="action-plan-instructions">
                            <Text
                              color={"#004069"}
                              fontFamily={"Nunito"}
                              fontSize={"16px"}
                              fontStyle={"normal"}
                              fontWeight={"400"}
                              lineHeight={"normal"}
                              display={"inline"}
                            >
                              Issue{" "}
                              <Text
                                as={"span"}
                                color={"#00A2D4"}
                                fontFamily={"Nunito"}
                                fontSize={fontSize}
                                fontStyle={"normal"}
                                fontWeight={"800"}
                                lineHeight={"normal"}
                              >
                                {currencyFormat(
                                  userOptiData?.primaryUserData
                                    ?.ccpcYearlyShareholderLoanRepayments?.[
                                    listPlace
                                  ] / 2
                                )}{" "}
                              </Text>
                              as a
                            </Text>
                            <Text
                              as={"span"}
                              color={"#004069"}
                              fontFamily={"Nunito"}
                              fontSize={fontSize}
                              fontStyle={"normal"}
                              fontWeight={"800"}
                              lineHeight={"normal"}
                            >
                              {" "}
                              Loan Repayment
                            </Text>
                          </li>
                        )}
                        <Box
                          display={"flex"}
                          padding={"10px"}
                          justifyContent={"center"}
                          alignItems={"center"}
                          gap={"10px"}
                          alignSelf={"stretch"}
                          border={"1px solid #00A2D4"}
                          bg={"#FFF"}
                          borderRadius={"5px"}
                          id="spouseGraphContainer"
                        >
                          <DepositsWithdrawalsGraph
                            tfsaDeposits={
                              userOptiData?.spouseData
                                ?.sps2_tfsaContributions?.[listPlace]
                            }
                            tfsaWithdrawals={
                              userOptiData?.spouseData?.sps2_tfsaWithdrawals?.[
                                listPlace
                              ]
                            }
                            fhsaDeposits={
                              userOptiData?.spouseData
                                ?.sps2_fhsaContributions?.[listPlace]
                            }
                            fhsaWithdrawals={
                              userOptiData?.spouseData?.sps2_fhsaWithdrawals?.[
                                listPlace
                              ]
                            }
                            rrspDeposits={safeAdd(
                              userOptiData?.spouseData
                                ?.sps2_registeredContributions?.[listPlace],
                              userOptiData?.spouseData
                                ?.sps2_registeredContributionsMatched?.[
                                listPlace
                              ]
                            )}
                            rrspWithdrawals={
                              userOptiData?.spouseData
                                ?.sps2_registeredWithdrawals?.[listPlace]
                            }
                            nonRegDeposits={
                              userOptiData?.spouseData
                                ?.sps2_nonRegisteredContributions?.[listPlace]
                            }
                            nonRegWithdrawals={
                              userOptiData?.spouseData
                                ?.sps2_nonregisteredWithdrawals?.[listPlace]
                            }
                            liraWithdrawals={
                              userOptiData?.spouseData?.sps2_liraWithdrawals?.[
                                listPlace
                              ]
                            }
                            cashWithdrawals={
                              userOptiData?.spouseData?.sps2_cashWithdrawals?.[
                                listPlace
                              ]
                            }
                            fixedWithdrawals={
                              userOptiData?.spouseData
                                ?.sps2_fixedIncomeWithdrawals?.[listPlace]
                            }
                            pensionDeposits={
                              userOptiData?.spouseData?.sps2_pension
                                ?.EmployerContributions?.[listPlace]
                            }
                            eligibleDividends={
                              userOptiData?.primaryUserData
                                ?.ccpcYearlyEligibleDividendsIssued?.[
                                listPlace
                              ] / 2
                            }
                            nonEligibleDividends={
                              userOptiData?.primaryUserData
                                ?.ccpcYearlyNonEligibleDividendsIssued?.[
                                listPlace
                              ] / 2
                            }
                            capitalDividends={
                              userOptiData?.primaryUserData
                                ?.ccpcYearlyCapitalDividendsIssued?.[
                                listPlace
                              ] / 2
                            }
                            loanRepayments={
                              userOptiData?.primaryUserData
                                ?.ccpcYearlyShareholderLoanRepayments?.[
                                listPlace
                              ] / 2
                            }
                            height="280px"
                          />
                        </Box>
                      </VStack>
                    </Box>
                  </Box>
                </Box>
              )}
              <Box
                display={"flex"}
                padding={"20px"}
                flexDirection={"column"}
                alignItems={"flex-start"}
                gap={"10px"}
                alignSelf={"stretch"}
                borderRadius={"5px"}
                border={"1px solid #1EADD9"}
                bg={"#FFF"}
              >
                <Text
                  color={"#00A2D4"}
                  fontFamily={"Nunito"}
                  fontSize={isMobile ? "16px" : "30px"}
                  fontStyle={"normal"}
                  fontWeight={"600"}
                  lineHeight={"normal"}
                >
                  Lifetime Milestones
                </Text>
              </Box>
              <Box
                display={"flex"}
                padding={"20px"}
                flexDirection={"column"}
                alignItems={"flex-start"}
                gap={"10px"}
                alignSelf={"stretch"}
                borderRadius={"5px"}
                border={"1px solid #1EADD9"}
                bg={"#FFF"}
              >
                <TimelineCard events={timelineData} />
              </Box>
            </>
          )}
        </div>
      </Box>
      <OltreChatBubble />
    </>
  );
}

export default ActionPlanPage;
