import React, { useState, useEffect, useRef } from 'react';
import './OltreChatBubble.css'; // Import your CSS file here
import oltreHelper from "../../img/CompassOltreIconScore.svg"
import { useAuth0 } from '@auth0/auth0-react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import axios from 'axios';
import askCustomGPT from './askCustomGPT';
import { faCommentLines } from '@fortawesome/pro-regular-svg-icons';
import { useStore } from '../../store';


const OltreChatBubble = (props) => {
    const { preSetQuestion } = props;
    const [isOpen, setIsOpen] = useState(false);
    const userInfo = useStore((store) => store.userInfo);
    const [userQuestion, setUserQuestion] = useState('');
    const [messages, setMessages] = useState([
        {
            text: `Hi ${userInfo.FirstName}! My name is Eva (Electronic Virtual Assistant). I am here to assist you with any questions or information you need while using Optiml™. I am currently in a BETA stage and may not know all the correct answers. Please ensure to use full sentences and only ask 1 question at a time to make me work best. Thank you for you patience, and how can I help you today?`,
            type: 'bot' // Assuming 'bot' is the type for messages from Eva
        }
    ]);
    const [isLoading, setIsLoading] = useState(false); // State for loading indicator

    const { getAccessTokenSilently } = useAuth0();

    useEffect(() => {
        if(preSetQuestion){
            setIsOpen(true)
            handleSendMessage(preSetQuestion);        
        }
    }, [preSetQuestion])
    

    const toggleChat = () => setIsOpen(!isOpen);

    const handleSendMessage = async (question) => {
        const userQuestionToSend = question || userQuestion;
        if(userQuestionToSend.trim()) {
            setMessages(prevMessages => [...prevMessages, { text: (userQuestion ? userQuestion : preSetQuestion), type: 'user' }]);
            setUserQuestion("");
            setIsLoading(true); // Set loading to true

            console.log('Here1')

            try {
                setIsLoading(true);
                const accessToken = await getAccessTokenSilently({
                    audience: 'https://os2lsiwff43wfpb5ggyawt6qri0mgcro.lambda-url.us-east-2.on.aws/',
                    scope: 'write:optimization',
                });
                const response = await askCustomGPT((userQuestion ? userQuestion : preSetQuestion), accessToken);
                console.log('Frontend', response)
                if (response && response?.Response) {
                    setMessages(prevMessages => [...prevMessages, { text: response.Response, type: 'bot' }]);
                } else {
                    // Handle the case where response.body is not available
                    setMessages(prevMessages => [...prevMessages, { text: "No response from server", type: 'bot' }]);
                }
            } catch (error) {
                console.error('Error: ', error);
                setMessages(prevMessages => [...prevMessages, { text: "There is currently a high volume of users on the system. Please ask again later.", type: 'bot' }]);
            } finally {
                setIsLoading(false);
            }
        }
    };

    const messagesEndRef = useRef(null);

    const formatMessage = (message) => {
        // Split the message into paragraphs first
        return message.split('\n').map((paragraph, index) => {
          // For each paragraph, split by "**" and toggle bold for every other fragment
          const parts = paragraph.split('**');
          const formattedParagraph = parts.map((part, partIndex) => {
            // Apply bold styling to every other part
            if (partIndex % 2 === 1) {
              return <strong key={partIndex}>{part}</strong>;
            } else {
              return part;
            }
          });
      
          return (
            <p key={index} style={{ marginBottom: '1rem' }}>
              {formattedParagraph}
            </p>
          );
        });
      };

    const scrollToBottom = () => {
        messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
    };

    useEffect(() => {
        scrollToBottom();
    }, [messages]);

    return (
        <div className="chat-bubble-container">
            {isOpen && <div className="chat-interface">
                <div className="chat-header">
                    <h1 style={{fontSize: '1.8rem', width: '100%', textAlign: 'left'}}><FontAwesomeIcon className='chat-bubble-icon' icon={faCommentLines} size='xl' />Electronic Virtual Assistant</h1>
                    <p style={{ textAlign: 'left', paddingLeft: '1rem', color: "#FFF"}}>Questions? I'm here to help!</p>
                </div>
                <div className="messages-container">
                    {messages.map((message, index) => (
                        <>
                            {message.type === 'user' && <h2 style={{ textAlign: 'right', fontFamily: 'Nunito', fontSize: '1.3rem', color: '#0F9CD6', fontWeight: '800', margin: '0.3rem'}}>You</h2>}
                            {message.type === 'bot' && <h2 style={{ textAlign: 'left', fontFamily: 'Nunito', fontSize: '1.3rem', color: '#0F9CD6', fontWeight: '800', margin: '0.3rem'}}><FontAwesomeIcon style={{marginRight: '0.5rem'}} icon={faCommentLines} size='xl' />Eva</h2>}
                            <div key={index} className={`message ${message.type}`}>{formatMessage(message.text)}</div>
                        </>
                    ))}
                    <div ref={messagesEndRef} />
                </div>

                {!isLoading && (
                    <textarea
                        className="oltre-help-input"
                        placeholder="Ask a question..."
                        value={userQuestion}
                        onChange={(e) => setUserQuestion(e.target.value)}
                        onKeyDown={(e) => e.key === 'Enter' && !e.shiftKey && handleSendMessage()}
                        disabled={isLoading}
                    />
                )}


                {/* Display a loading indicator when waiting for a response */}
                {isLoading && (
                    <div className="typing-message">
                        Eva is typing<span className="typing-indicator"></span>
                    </div>
                )}
                    


                {/* More chat interface elements here */}
            </div>}
            <div className="chat-bubble" onClick={toggleChat}>
                {!isOpen && <FontAwesomeIcon className='chat-bubble-icon' icon={faCommentLines} size='2xl' />}
                {isOpen && <div className='chat-bubble-icon'>
                    <h1 style={{ alignSelf: 'center', margin: '1.5rem', fontFamily: 'Nunito', fontSize: '2rem'}}>X</h1>
                    </div>}
                {/* <img className='chat-bubble-icon' src={oltreHelper} alt="Chat bubble icon" /> */}
            </div>
        </div>
    );
};

export default OltreChatBubble;
