import React, { useState, useEffect } from 'react';
import ReactEcharts from 'echarts-for-react';

function SalaryGraph(props) {

  const currencyFormat = (money) => {
    if(money == ''){
        return ''
    }
    else{
        const intTotal = Math.round(Math.round(money * 100) / 100);
        const currencyCad = new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: 'USD',
            minimumFractionDigits: 0,
        }).format(intTotal);
        return currencyCad;
    }
  };

    const { getAge, currentAge, retireAge, growthOverYears } = props;


    const getYears = () => {
        const currentDate = new Date();
        const currentYear = currentDate.getFullYear();
        const userAge = getAge(currentAge, currentDate);
        const fullRun = retireAge - userAge + 1;
        if (fullRun > 0 && Number.isInteger(fullRun)) {
          return [...Array(fullRun).keys()].map((i) => i + currentYear);
        } else {
            // Return an empty array or handle the error appropriately
            return [];
        }
    };

    const xAxis = getYears();

    const [key, setKey] = useState(1)

    useEffect(() => {
      option = {...option}
      // using key to trigger chart re-render of graph
      // React Echarts is tricky to get to rerender
      if (key ===1) {setKey(2)}
      else if (key ===2) {setKey(1)}
    }, [growthOverYears]);

    let option = {
      title: {
        text: 'Salary',      // This adds the "Salary" heading
        left: 'center'
      },
      notMerge: true,
      tooltip: {
        trigger: 'axis',
        axisPointer: {
          type: 'line', // Use 'line' for X-axis
        },
        formatter: function(params) {
          let result = params[0].axisValueLabel + '<br/>';
          params.forEach(function (item) {
            result += item.marker + ' ' + item.seriesName + ': ' + currencyFormat(item.data) + '<br/>';
          });
          return result;
        }
      },
      legend: {
        show: false,
      },
      toolbox: {
        feature: {
          saveAsImage: {}
        }
      },
      grid: {
        left: '1%', // Increase this value to provide more space for y-axis labels
        right: '4%',
        bottom: '3%',
        containLabel: true
      },      
      xAxis: [
        {
          type: 'category',
          boundaryGap: true,
          data: xAxis
        }
      ],
      yAxis: [
        {
          type: 'value',
          axisLabel: {
            formatter: function(value) {
              // This formats the y-axis labels with a currency symbol and a comma every thousand
              return `$${new Intl.NumberFormat().format(value)}`;
            }
          }
        }
    ],
    
      series: [
        {
          name: 'Salary',
          type: 'bar',
          stack: 'Total',
          areaStyle: {},
          emphasis: {
            focus: 'series'
          },
          color: '#0F9CD6',
          smooth: true,
          data: growthOverYears
        },
      ]
    };

  

  return (
    <ReactEcharts key={key} option={option} notMerge={true} className="salary-graph" style={{ height: '260px', borderRadius: '5px' }} />
  );
}

export default SalaryGraph;
