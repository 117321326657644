import React, { useState, useEffect } from "react";
import ReactEcharts from "echarts-for-react";
import FilterIcon from "../img/filter-icon.png";
import { position } from "@chakra-ui/react";

const currencyFormat = (money) => {
  if (money === 0) {
    return "$0"; // Handle zero explicitly
  } else if (money === "") {
    return "";
  } else {
    const intTotal = Math.round(Math.round(money * 100) / 100);
    const currencyCad = new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD",
      minimumFractionDigits: 0,
    }).format(intTotal);
    return currencyCad;
  }
};

function ExpensesLineGraph(props) {
  const {
    title,
    mortgageData,
    insuranceData,
    debtData,
    goalsData,
    householdData,
    downPayment,
    extraSpend,
    totalData,
    currentAge,
    spouseAge,
  } = props;



  const xAxis = householdData?.map((item) => item.x);
  const xAxisWithAge = xAxis?.map((year) => {
    const currentYear = new Date().getFullYear();
    const age = currentAge + (year - currentYear);
    const spouse = spouseAge ? spouseAge + (year - currentYear) : "";
    return spouseAge ? `${year}\n(${age} | ${spouse})` : `${year}\n(${age})`;
  });
  const yAxisMortgage = mortgageData?.map((item) => item.y);
  const yAxisInsurance = insuranceData?.map((item) => item.y);
  const yAxisDownPayment = downPayment?.map((item) => item.y);
  const yAxisDebt = debtData?.map((item) => item.y);
  const yAxisGoal = goalsData?.map((item) => item.y);
  const yAxisHousehold = householdData?.map((item) => item.y);
  const yAxisExtraSpend = extraSpend?.map((item) => item.y);
  const yAxisTotalPortfolio = totalData?.map((item) => item.y);

  const [showDropdown, setShowDropdown] = useState(false);
  const [showAll, setShowAll] = useState(true);
  const [showMortgage, setShowMortgage] = useState(false);
  const [showInsurance, setShowInsurance] = useState(false);
  const [showDownPayment, setShowDownPayment] = useState(false);
  const [showDebt, setShowDebt] = useState(false);
  const [showGoals, setShowGoals] = useState(false);
  const [showHousehold, setShowHousehold] = useState(false);
  const [showExtraSpend, setShowExtraSpend] = useState(false);
  const [showTotal, setShowTotal] = useState(false);
  const [colorScheme, setColorScheme] = useState([
    "#73B879",
    "#30AB9D",
    "#00A2D4",
    "#007EB5",
    "#00466D",
    "#00304A",
  ]);

  function numFormatter(num) {
    if (num > 999 && num < 10000) {
      return "$" + (num / 1000).toFixed(1) + "K";
    } else if (num > 9999 && num < 1000000) {
      return "$" + (num / 1000).toFixed(0) + "K";
    } else if (num > 1000000) {
      return "$" + (num / 1000000).toFixed(1) + "M";
    } else if (num < 900) {
      return "$" + num;
    }
  }

  useEffect(() => {
    if (showMortgage) setColorScheme(["#73B879"]);
    if (showInsurance) setColorScheme(["#30AB9D"]);
    if (showDownPayment) setColorScheme(["purple"]);
    if (showDebt) setColorScheme(["#2BD900"]);
    if (showGoals) setColorScheme(["#00304A"]);
    if (showHousehold) setColorScheme(["#007EB5"]);
    if (showTotal) setColorScheme(["#00A2D4"]);
    if (showExtraSpend) setColorScheme(["#00466D"]);
    if (showAll)
      setColorScheme([
        "#73B879",
        "#30AB9D",
        "#00A2D4",
        "#007EB5",
        "#00466D",
        "#00304A",
      ]);
  }, [
    showMortgage,
    showInsurance,
    showDebt,
    showGoals,
    showHousehold,
    showTotal,
    showExtraSpend,
    showAll,
  ]);

  [
    "Mortgage",
    "Life Insurance Premium",
    "Down Payment",
    "Debt",
    "Goals",
    "Living Expenses",
    "Extra Spending",
    "Total",
  ];

  const headers = [
    { name: "Mortgage", show: mortgageData?.some((obj) => obj.y !== 0) },
    {
      name: "Life Insurance Premium",
      show: insuranceData?.some((obj) => obj.y !== 0),
    },
    { name: "Down Payment", show: downPayment?.some((obj) => obj.y !== 0) },
    { name: "Debt", show: debtData?.some((obj) => obj.y !== 0) },
    { name: "Goals", show: goalsData?.some((obj) => obj.y !== 0) },
    {
      name: "Living Expenses",
      show: householdData?.some((obj) => obj.y !== 0),
    },
    { name: "Extra Spending", show: extraSpend?.some((obj) => obj.y > 5) },
    { name: "Total", show: totalData?.some((obj) => obj.y !== 0) },
  ];

  const dataToRender = headers
    .filter((header) => header.show)
    ?.some((header) => header.name);

  const option = {
    color: colorScheme,
    title: {
      text: window.innerWidth <=500 ? "Overview":title,
      left: "center",
      top: "0%",
      textStyle: {
        lineHeight: window.innerWidth <= 480 && !showAll ? -30 : null,
      },
    },
    tooltip: {
      trigger: "axis",
      axisPointer: {
        type: "shadow", // Use 'line' for X-axis
      },
      formatter: function (params) {
        let result = params[0].axisValueLabel + "<br/>";
        params.forEach(function (item) {
          result +=
            item.marker +
            " " +
            item.seriesName +
            ": " +
            currencyFormat(item.data) +
            "<br/>";
        });
        return result;
      },
    },
    legend: {
      data: dataToRender,
      top: "10%",
      selectedMode: false,
      show: window.innerWidth <= 480 && showAll ? false : true,
    },
    toolbox: {
      feature: {
        saveAsImage: {
          iconStyle: {
            borderColor: "#004069",
          },
        },
      },
    },
    grid: {
      left: 3,
      right: 3,
      bottom: "0%",
      containLabel: true,
    },
    xAxis: [
      {
        type: "category",
        boundaryGap: ["20%", "20%"],
        data: xAxisWithAge ? xAxisWithAge : [],
      },
    ],
    yAxis: [
      {
        type: "value",
        axisLabel: {
          formatter: function (value) {
            return numFormatter(value);
          },
        },
      },
    ],
    series: [
      (showMortgage || showAll) &&
        mortgageData?.some((obj) => obj.y !== 0) && {
          name: "Mortgage",
          type: "bar",
          itemStyle: {
            borderRadius: !showAll ? [5, 5, 0, 0] : "", // Apply rounding to the top-left and top-right corners
            // This sets the top corners' radius to 5 (creating a rounded effect)
            // and leaves the bottom corners squared (radius 0).
          },
          stack: "Total",
          smooth: true,
          areaStyle: {
            color: {
              type: "linear",
              x: 0,
              y: 0,
              x2: 0,
              y2: 1,
              colorStops: [
                {
                  offset: 0,
                  color: "#73B879", // color at 0%
                },
                {
                  offset: 1,
                  color: "#73B879", // color at 100%
                },
              ],
              global: false, // default is false
            },
          },
          emphasis: {
            focus: "series",
          },
          data: yAxisMortgage,
        },
      (showInsurance || showAll) &&
        insuranceData?.some((obj) => obj.y !== 0) && {
          name: "Life Insurance Premium",
          type: "bar",
          itemStyle: {
            borderRadius: !showAll ? [5, 5, 0, 0] : "", // Apply rounding to the top-left and top-right corners
            // This sets the top corners' radius to 5 (creating a rounded effect)
            // and leaves the bottom corners squared (radius 0).
          },
          stack: "Total",
          smooth: true,
          areaStyle: {
            color: {
              type: "linear",
              x: 0,
              y: 0,
              x2: 0,
              y2: 1,
              colorStops: [
                {
                  offset: 0,
                  color: "#30AB9D", // color at 0%
                },
                {
                  offset: 1,
                  color: "#30AB9D", // color at 100%
                },
              ],
              global: false, // default is false
            },
          },
          emphasis: {
            focus: "series",
          },
          data: yAxisInsurance,
        },
      (showDownPayment || showAll) &&
        downPayment?.some((obj) => obj.y !== 0) && {
          name: "Down Payment",
          type: "bar",
          itemStyle: {
            borderRadius: !showAll ? [5, 5, 0, 0] : "", // Apply rounding to the top-left and top-right corners
            // This sets the top corners' radius to 5 (creating a rounded effect)
            // and leaves the bottom corners squared (radius 0).
          },
          stack: "Total",
          smooth: true,
          areaStyle: {
            color: {
              type: "linear",
              x: 0,
              y: 0,
              x2: 0,
              y2: 1,
              colorStops: [
                {
                  offset: 0,
                  color: "purple", // color at 0%
                },
                {
                  offset: 1,
                  color: "purple", // color at 100%
                },
              ],
              global: false, // default is false
            },
          },
          emphasis: {
            focus: "series",
          },
          data: yAxisDownPayment,
        },
      (showDebt || showAll) &&
        debtData?.some((obj) => obj.y !== 0) && {
          name: "Debt",
          type: "bar",
          itemStyle: {
            borderRadius: !showAll ? [5, 5, 0, 0] : "", // Apply rounding to the top-left and top-right corners
            // This sets the top corners' radius to 5 (creating a rounded effect)
            // and leaves the bottom corners squared (radius 0).
          },
          stack: "Total",
          smooth: true,
          areaStyle: {
            color: {
              type: "linear",
              x: 0,
              y: 0,
              x2: 0,
              y2: 1,
              colorStops: [
                {
                  offset: 0,
                  color: "#00A2D4", // color at 0%
                },
                {
                  offset: 1,
                  color: "#00A2D4", // color at 100%
                },
              ],
              global: false, // default is false
            },
          },
          emphasis: {
            focus: "series",
          },
          data: yAxisDebt,
        },
      (showGoals || showAll) &&
        goalsData?.some((obj) => obj.y !== 0) && {
          name: "Goals",
          type: "bar",
          itemStyle: {
            borderRadius: !showAll ? [5, 5, 0, 0] : "", // Apply rounding to the top-left and top-right corners
            // This sets the top corners' radius to 5 (creating a rounded effect)
            // and leaves the bottom corners squared (radius 0).
          },
          stack: "Total",
          smooth: true,
          areaStyle: {
            color: {
              type: "linear",
              x: 0,
              y: 0,
              x2: 0,
              y2: 1,
              colorStops: [
                {
                  offset: 0,
                  color: "#00304A", // color at 0%
                },
                {
                  offset: 1,
                  color: "#00304A", // color at 100%
                },
              ],
              global: false, // default is false
            },
          },
          emphasis: {
            focus: "series",
          },
          data: yAxisGoal,
        },
      (showHousehold || showAll) &&
        householdData?.some((obj) => obj.y !== 0) && {
          name: "Living Expenses",
          type: "bar",
          itemStyle: {
            borderRadius: !showAll ? [5, 5, 0, 0] : "", // Apply rounding to the top-left and top-right corners
            // This sets the top corners' radius to 5 (creating a rounded effect)
            // and leaves the bottom corners squared (radius 0).
          },
          stack: "Total",
          smooth: true,
          areaStyle: {
            color: {
              type: "linear",
              x: 0,
              y: 0,
              x2: 0,
              y2: 1,
              colorStops: [
                {
                  offset: 0,
                  color: "#007EB5", // color at 0%
                },
                {
                  offset: 1,
                  color: "#007EB5", // color at 100%
                },
              ],
              global: false, // default is false
            },
          },
          emphasis: {
            focus: "series",
          },
          data: yAxisHousehold,
        },
      (showExtraSpend || showAll) &&
        extraSpend?.some((obj) => obj.y > 5) && {
          name: "Extra Spending",
          type: "bar",
          itemStyle: {
            borderRadius: !showAll ? [5, 5, 0, 0] : "", // Apply rounding to the top-left and top-right corners
            // This sets the top corners' radius to 5 (creating a rounded effect)
            // and leaves the bottom corners squared (radius 0).
          },
          stack: "Total",
          smooth: true,
          areaStyle: {
            color: {
              type: "linear",
              x: 0,
              y: 0,
              x2: 0,
              y2: 1,
              colorStops: [
                {
                  offset: 0,
                  color: "#00466D", // color at 0%
                },
                {
                  offset: 1,
                  color: "#00466D", // color at 100%
                },
              ],
              global: false, // default is false
            },
          },
          emphasis: {
            focus: "series",
          },
          data: yAxisExtraSpend,
        },
      showTotal && {
        name: "Total",
        type: "bar",
        itemStyle: {
          borderRadius: !showAll ? [5, 5, 0, 0] : "", // Apply rounding to the top-left and top-right corners
          // This sets the top corners' radius to 5 (creating a rounded effect)
          // and leaves the bottom corners squared (radius 0).
        },
        stack: "Total",
        smooth: true,
        areaStyle: {
          color: {
            type: "linear",
            x: 0,
            y: 0,
            x2: 0,
            y2: 1,
            colorStops: [
              {
                offset: 0,
                color: "#2BD900", // color at 0%
              },
              {
                offset: 1,
                color: "#2BD900", // color at 100%
              },
            ],
            global: false, // default is false
          },
        },
        emphasis: {
          focus: "series",
        },
        data: yAxisTotalPortfolio,
      },
    ],
  };
  const eChartsRef = React.useRef(0);

  function selectGraph(graph) {
    if (graph === "Total") {
      setShowTotal(true);
      setShowAll(false);
    } else setShowTotal(false);
    if (graph === "Mortgage") {
      setShowMortgage(true);
      setShowAll(false);
    } else setShowMortgage(false);
    if (graph === "Life Insurance Premium") {
      setShowInsurance(true);
      setShowAll(false);
    } else setShowInsurance(false);
    if (graph === "Down Payment") {
      setShowDownPayment(true);
      setShowAll(false);
    } else setShowDownPayment(false);
    if (graph === "Debt") {
      setShowDebt(true);
      setShowAll(false);
    } else setShowDebt(false);
    if (graph === "Goals") {
      setShowGoals(true);
      setShowAll(false);
    } else setShowGoals(false);
    if (graph === "Living Expenses") {
      setShowHousehold(true);
      setShowAll(false);
    } else setShowHousehold(false);
    if (graph === "Extra Spending") {
      setShowExtraSpend(true);
      setShowAll(false);
    } else setShowExtraSpend(false);
    if (graph === "All") {
      setShowAll(true);
    } else setShowAll(false);
  }

  return (
    <div
    className="dashboard-graph-container"
    style={{ border: "none", borderRadius: "none", boxShadow: "none", padding: '0'}}
  >
      <div
        tabIndex={0}
        className="dropdown"
        onClick={() => setShowDropdown(!showDropdown)}
        onBlur={() => setShowDropdown(showDropdown)}
      >
        <img src={FilterIcon} className="filter-icon" alt="filter-icon" />
        {showDropdown && (
          <div className="dropdown-items" style={{ maxWidth: "100%" }}>
            <ul>
              <div>Display</div>
              <li
                className={showTotal ? "dropdown-item-selected" : undefined}
                onClick={() => selectGraph("Total")}
              >
                Total Expenses
              </li>
              <li
                className={showAll ? "dropdown-item-selected" : undefined}
                onClick={() => selectGraph("All")}
              >
                All
              </li>
              {mortgageData?.some((obj) => obj.y !== 0) && (
                <li
                  className={
                    showMortgage ? "dropdown-item-selected" : undefined
                  }
                  onClick={() => selectGraph("Mortgage")}
                >
                  Mortgage
                </li>
              )}
              {insuranceData?.some((obj) => obj.y !== 0) && (
                <li
                  className={
                    showInsurance ? "dropdown-item-selected" : undefined
                  }
                  onClick={() => selectGraph("Life Insurance Premium")}
                >
                  Life Insurance Premium
                </li>
              )}
              {downPayment?.some((obj) => obj.y !== 0) && (
                <li
                  className={
                    showDownPayment ? "dropdown-item-selected" : undefined
                  }
                  onClick={() => selectGraph("Down Payment")}
                >
                  Down Payment
                </li>
              )}
              {debtData?.some((obj) => obj.y !== 0) && (
                <li
                  className={showDebt ? "dropdown-item-selected" : undefined}
                  onClick={() => selectGraph("Debt")}
                >
                  Debt
                </li>
              )}
              {goalsData?.some((obj) => obj.y !== 0) && (
                <li
                  className={showGoals ? "dropdown-item-selected" : undefined}
                  onClick={() => selectGraph("Goals")}
                >
                  Goals
                </li>
              )}
              {householdData?.some((obj) => obj.y !== 0) && (
                <li
                  className={
                    showHousehold ? "dropdown-item-selected" : undefined
                  }
                  onClick={() => selectGraph("Living Expenses")}
                >
                  Living Expenses
                </li>
              )}
              {extraSpend?.some((obj) => obj.y > 5) && (
                <li
                  className={
                    showExtraSpend ? "dropdown-item-selected" : undefined
                  }
                  onClick={() => selectGraph("Extra Spending")}
                >
                  Extra Spending
                </li>
              )}
            </ul>
          </div>
        )}
      </div>
      <ReactEcharts
        style={{ maxWidth: "100%"}}
        ref={eChartsRef}
        option={option}
        className="portfolio-balance-chart"
        notMerge
      />
    </div>
  );
}

export default ExpensesLineGraph;
