import { create } from "zustand";

const currencyFormat = (money) => {
  const intTotal = Math.floor(Math.round(money * 100) / 100);
  const currencyCad = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
    minimumFractionDigits: 0,
  }).format(intTotal);
  return currencyCad;
};

const store = (set) => ({
  // USERS/UserInfo
  userInfo: null,
  // LIRA/UserInfo
  userLiraInfo: null,
  // LIF/UserInfo
  userLifInfo: null,
  // EXPENSES/UserInfo
  userExpensesInfo: null,
  // INCOME/UserInfo
  userIncomeInfo: null,
  // RRSP/UserInfo
  userRRSPInfo: null,
  // FHSA/UserInfo
  userFHSAInfo: null,
  // TFSA/UserInfo
  userTFSAInfo: null,
  // NON_REGISTERED_INVESTMENTS/UserInfo
  userNonRegInvestmentsInfo: null,
  // CASH/UserInfo
  userCASHInfo: null,
  // OTHER_ASSETS/UserInfo
  userOtherAssetsInfo: null,
  // INHERITANCE/UserInfo
  userInheritanceInfo: null,
  // JSON_OPTI_DATA/UserInfo
  userOptiData: null,
  // INSIGHTS/UserInfo
  userInsightsData: null,
  // WEALTHICA_DATA/UserInfo
  userWealthicaData: null,
  //DEBT/UserInfo
  userDebtInfo: [],
  // Goals
  userGoalInfo: [],
  // Other Income
  userOtherIncomeInfo: [],
  // Fixed Income
  userFixedIncomeInfo: [],
  userPensionInfo: [],
  userArchiveInfo: [],
  userPrimaryResidenceInfo: [],
  userCottageInfo: [],
  userPropertiesInfo: [],
  userLandInfo: [],
  userLifeInsuranceInfo: [],
  userBusinessInfo: [],
  userCompanyInfo: null,
  userEditAssetInfo: [],
  userSetValueInfo: [],
  userNotificationInfo: null,
  showNewOptimization: true,
  userPopupNotification: {
    DashboardVideo: 1,
    NewAnalysis: 1,
  },
  dashboardLayout: {
    smallCards: [
      "estateBalance",
      "OSFA",
      "optimlValue",
      "netWorth",
      "compassScore",
      "optimalCPPAge",
      "optimalOASAge",
      "averageTaxRate",
    ],
    largeCards: [
      "income",
      "deposits",
      "withdrawals",
      "taxes",
      "budgetSummary",
      "portfolioBalance",
      "debt",
      "netWorth",
    ],
    overallLayout: ["smallCards", "largeCards", "graph"],
  },
  hideProgressBar: false,
  mobileSideBarOpen: false,
  logoutMultiplier: 1,
  setUserInfo: (data) => set(() => ({ userInfo: data })),
  setUserNotificationInfo: (data) =>
    set(() => ({
      userNotificationInfo: {
        email: data?.email,
        rrspContDeadline: data?.rrspContDeadline,
        tfsaContRoom: data?.tfsaContRoom,
        rrspContRoom: data?.rrspContRoom,

        taxDeadline: data?.taxDeadline,
        taxBracket: data?.taxBracket,
        estateRegulations: data?.estateRegulations,
        activityReminder: data?.activityReminder,
      },
    })),
  setLogoutMultiplier: (data) => set(() => ({ logoutMultiplier: data })),
  setUserLiraInfo: (data) =>
    set(() => ({
      userLiraInfo: {
        SPS1_LIRA_VALUE: data?.SPS1_LIRA_VALUE,
        Do_You_LIRA: data?.Do_You_LIRA,
        Has_LIRA_LIF: data?.Has_LIRA_LIF,
        LIRA_ROR: data?.LIRA_ROR,
        SPS2_LIRA_VALUE: data?.SPS2_LIRA_VALUE,
        spouseDo_You_LIRA: data?.spouseDo_You_LIRA,
        spouseHas_LIRA_LIF: data?.spouseHas_LIRA_LIF,
        spouseLIRA_ROR: data?.spouseLIRA_ROR,
      },
    })),
  setUserLifInfo: (data) => set(() => ({ userLifInfo: data })),
  setDashboardLayout: (data) => set(() => ({ dashboardLayout: data })),
  setUserExpensesInfo: (data) =>
    set(() => ({
      userExpensesInfo: {
        total: data?.total,
        expenseArray:
          data?.expenseArray !== null
            ? typeof data?.expenseArray === "string"
              ? JSON.parse(data?.expenseArray)
              : data?.expenseArray
            : null,
        expenseCPI: data?.expenseCPI ? data?.expenseCPI : 2,
        growth: data?.growth,
        debtArray: data?.debtArray !== null ? data?.debtArray : null,
      },
    })),
  setUserRRSPInfo: (data) =>
    set(() => ({
      userRRSPInfo: {
        totalValue: data?.totalValue,
        spouseTotalValue: data?.spouseTotalValue,
        annualCont: currencyFormat(data?.annualCont),
        spouseAnnualCont: currencyFormat(data?.spouseAnnualCont),
        inflation: data?.inflation,
        spouseInflation: data?.spouseInflation,
        stopAge: data?.stopAge,
        spouseStopAge: data?.spouseStopAge,
        Do_You_RRSP: data?.Do_You_RRSP,
        RRSP_Cont_This_Year: data?.RRSP_Cont_This_Year,
        Does_Employer_RRSP: data?.Does_Employer_RRSP,
        RRSP_Cont_Room: data?.RRSP_Cont_Room,
        RRSP_ROR: data?.RRSP_ROR ?? 6,
        Employer_Cont_Percent: data?.Employer_Cont_Percent,
        spouseDo_You_RRSP: data?.spouseDo_You_RRSP,
        spouseRRSP_Cont_This_Year: data?.spouseRRSP_Cont_This_Year,
        spouseDoes_Employer_RRSP: data?.spouseDoes_Employer_RRSP,
        spouseRRSP_ROR: data?.spouseRRSP_ROR ?? 6,
        spouseEmployer_Cont_Percent: data?.spouseEmployer_Cont_Percent,
        hasRRIF: data?.hasRRIF,
        spouseHasRRIF: data?.spouseHasRRIF,
      },
    })),
  setUserTFSAInfo: (data) =>
    set(() => ({
      userTFSAInfo: {
        totalValue: data?.totalValue,
        annualCont: currencyFormat(data?.annualCont),
        spouseAnnualCont: currencyFormat(data?.spouseAnnualCont),
        inflation: data?.inflation,
        spouseInflation: data?.spouseInflation,
        stopAge: data?.stopAge,
        spouseStopAge: data?.spouseStopAge,
        Do_You_TFSA: data?.Do_You_TFSA,
        Do_TFSA_Room: data?.Do_TFSA_Room,
        TFSA_Room: data?.TFSA_Room,
        TFSA_ROR: data?.TFSA_ROR ?? 6,
        spouseTotalValue: data?.spouseTotalValue,
        spouseDo_You_TFSA: data?.spouseDo_You_TFSA,
        spouseDo_TFSA_Room: data?.spouseDo_TFSA_Room,
        spouseTFSA_Room: data?.spouseTFSA_Room,
        spouseTFSA_ROR: data?.spouseTFSA_ROR ?? 6,
        tfsaContThisYear: data?.tfsaContThisYear ?? 0,
        spouseTfsaContThisYear: data?.spouseTfsaContThisYear ?? 0,
      },
    })),
  // FHSA
  setUserFHSAInfo: (data) =>
    set(() => ({
      userFHSAInfo: {
        doFhsa: data?.doFhsa,
        fhsaOpened: data?.fhsaOpened,
        fhsaValue: data?.fhsaValue ?? 0,
        doFhsaRoom: data?.doFhsaRoom,
        fhsaRoom: data?.fhsaRoom ?? 0,
        fhsaGrowth: data?.fhsaGrowth ?? 6,
        whenPurchaseHome: data?.whenPurchaseHome,
        homePurchasePrice: data?.homePurchasePrice ?? 0,
        currentRent: data?.currentRent ?? 0,
        spouseDoFhsa: data?.spouseDoFhsa,
        spouseOpenedFhsa: data?.spouseOpenedFhsa,
        spouseFhsaValue: data?.spouseFhsaValue ?? 0,
        spouseDoFhsaRoom: data?.spouseDoFhsaRoom,
        spouseFhsaRoom: data?.spouseFhsaRoom ?? 0,
        spouseFhsaGrowth: data?.spouseFhsaGrowth ?? 6,
        fhsaEligible: data?.fhsaEligible,
        purchaseHomeGoal: data?.purchaseHomeGoal,
        spouseFhsaEligible: data?.spouseFhsaEligible,
        downPayment: data?.downPayment ?? 0,
        fhsaRentHomeYesNo: data?.fhsaRentHomeYesNo,
        fhsaRentHomeAmount: data?.fhsaRentHomeAmount ?? 0,
        fhsaContributionThisYear: data?.fhsaContributionThisYear ?? 0,
        spouseFhsaContributionThisYear:
          data?.spouseFhsaContributionThisYear ?? 0,
        spouseWhenPurchaseHome: data?.spouseWhenPurchaseHome ?? 0,
      },
    })),
  setUserNonRegInvestmentsInfo: (data) =>
    set(() => ({
      userNonRegInvestmentsInfo: {
        totalValue: data?.totalValue,
        ACB: data?.ACB,
        Non_Eligible_Div: data?.Non_Eligible_Div,
        Eligible_Div: data?.Eligible_Div,
        Foreign_Div: data?.Foreign_Div,
        Do_You_Non_Reg: data?.Do_You_Non_Reg,
        Non_Reg_Income_Bearing: data?.Non_Reg_Income_Bearing,
        Non_Reg_ROR: data?.Non_Reg_ROR ?? 6,
        nonRegContThisYear: data?.nonRegContThisYear ?? 0,

        spouseTotalValue: data?.spouseTotalValue,
        spouseACB: data?.spouseACB,
        Spouse_Non_Eligible_Div: data?.Spouse_Non_Eligible_Div,
        Spouse_Eligible_Div: data?.Spouse_Eligible_Div,
        Spouse_Foreign_Div: data?.Spouse_Foreign_Div,
        spouseDo_You_Non_Reg: data?.spouseDo_You_Non_Reg,
        spouseNon_Reg_Income_Bearing: data?.spouseNon_Reg_Income_Bearing,
        spouseNon_Reg_ROR: data?.spouseNon_Reg_ROR ?? 6,
        spouseNonRegContThisYear: data?.spouseNonRegContThisYear ?? 0,
      },
    })),
  setUserCASHInfo: (data) =>
    set(() => ({
      userCASHInfo: {
        value: data?.value,
        hasCash: data?.hasCash,
        minCash: data?.minCash,
        minCashValue: data?.minCashValue,

        spouseValue: data?.spouseValue,
        spouseHasCash: data?.spouseHasCash,
        spouseMinCash: data?.spouseMinCash,
        spouseMinCashValue: data?.spouseMinCashValue,
      },
    })),
  setUserOptiData: (data) =>
    set(() => ({
      userOptiData: {
        primaryUserData:
          data && data?.user_opti_data ? JSON.parse(data?.user_opti_data) : {},
        spouseData:
          data && data?.spouse_opti_data
            ? JSON.parse(data?.spouse_opti_data)
            : {},

        spouse1_ROT:
          data && data?.spouse1_ROT ? JSON.parse(data?.spouse1_ROT) : {},
        spouse2_ROT:
          data && data?.spouse2_ROT ? JSON.parse(data?.spouse2_ROT) : {},
      },
    })),

  setUserInsightsData: (data) =>
    set(() => ({
      userInsightsData: {
        retireEarly:
          data && data?.retireEarly ? JSON.parse(data?.retireEarly) : {},
        retireLate:
          data && data?.retireLate ? JSON.parse(data?.retireLate) : {},
        cppEarly: data && data?.cppEarly ? JSON.parse(data?.cppEarly) : {},
        cppLate: data && data?.cppLate ? JSON.parse(data?.cppLate) : {},
        oasEarly: data && data?.oasEarly ? JSON.parse(data?.oasEarly) : {},
        oasLate: data && data?.oasLate ? JSON.parse(data?.oasLate) : {},
      },
    })),

  setUserEditAssetInfo: (data) =>
    set(() => ({
      userEditAssetInfo: {
        editAsset: data ? data.list : {},
        editIndex: data ? data.editingIndex : 0,
      },
    })),

  setUserSetValueInfo: (data) =>
    set(() => ({
      userSetValueInfo: {
        SetValue: data ? data.SetValue : 0,
        IncludeAssets: data ? data.IncludeAssets : true,
        IncludePrimaryRes: data ? data?.IncludePrimaryRes : true,
        IncludeRecreationProperty: data
          ? data?.IncludeRecreationProperty
          : true,
        IncludeIncomeProperty: data ? data?.IncludeIncomeProperty : true,
        IncludeLand: data ? data?.IncludeLand : true,
        IncludeBusiness: data ? data?.IncludeBusiness : true,
        IncludeLifeInsurance: data ? data?.IncludeLifeInsurance : true,
      },
    })),

  setUserWealthicaData: (data) =>
    set(() => ({
      userWealthicaData: {
        spouse1Investments:
          data && data?.spouse1Investments
            ? JSON.parse(data?.spouse1Investments)
            : {},

        spouse2Investments:
          data && data?.spouse2Investments
            ? JSON.parse(data?.spouse2Investments)
            : {},

        jointInvestments:
          data && data?.jointInvestments
            ? JSON.parse(data?.jointInvestments)
            : {},

        ignoreAccounts:
          data && data?.ignoreAccounts ? JSON.parse(data?.ignoreAccounts) : {},
        spouse1Date: data?.spouse1Date,
        spouse2Date: data?.spouse2Date,
        jointDate: data?.jointDate,
      },
    })),

  setShowNewOptimization: (data) =>
    set(() => ({
      showNewOptimization: data,
    })),
  setShowDashboardVideo: (data) =>
    set(() => ({
      userPopupNotification: {
        DashboardVideo:
          data?.DashboardVideo !== undefined && data?.DashboardVideo !== null
            ? data.DashboardVideo
            : 1,
        NewAnalysis:
          data?.NewAnalysis !== undefined && data?.NewAnalysis !== null
            ? data.NewAnalysis
            : 1,
      },
    })),
  setHideProgressBar: (data) =>
    set(() => ({
      // hideProgressBar: data,
      // switch to above code when you're ready to hide progress bar
      hideProgressBar: false,
    })),
  setMobileSidebarOpen: (data) => set(() => ({ mobileSideBarOpen: data })),
  updateUserDebtInfo: (debtNumber, updatedDebtData) =>
    set((state) => ({
      userDebtInfo: state.userDebtInfo?.map((info) =>
        info.Debt_Number === debtNumber ? { ...info, ...updatedDebtData } : info
      ),
    })),
  deleteUserDebtInfo: (debtNumber) =>
    set((state) => ({
      userDebtInfo: state.userDebtInfo.filter(
        (info) => info.Debt_Number !== debtNumber
      ),
    })),
  setUserDebtInfo: (data) =>
    set((state) => {
      const updatedUserDebtInfo = [...state.userDebtInfo, ...data];
      return { userDebtInfo: updatedUserDebtInfo };
    }),

  // GOALS:
  updateUserGoalInfo: (goalNumber, updatedGoalData) =>
    set((state) => ({
      userGoalInfo: state.userGoalInfo?.map((info) =>
        info.goalNumber === goalNumber ? { ...info, ...updatedGoalData } : info
      ),
    })),

  deleteUserGoalInfo: (goalNumber) =>
    set((state) => ({
      userGoalInfo: state.userGoalInfo.filter(
        (info) => info.goalNumber !== goalNumber
      ),
    })),

  setUserGoalInfo: (data) =>
    set((state) => {
      const updatedUserGoalInfo = [...state.userGoalInfo, ...data];
      return { userGoalInfo: updatedUserGoalInfo };
    }),

  setUserOtherIncomeInfo: (data) =>
    set((state) => {
      const currentStateData = state.userOtherIncomeInfo
        ? state.userOtherIncomeInfo
        : [];
      const newData = data ? data : [];
      const updatedUserOtherIncomeInfo = [...currentStateData, ...newData];
      return { userOtherIncomeInfo: updatedUserOtherIncomeInfo };
    }),
  updateUserOtherIncomeInfo: (otherIncomeNumber, updatedOtherIncomeData) =>
    set((state) => ({
      userOtherIncomeInfo: state.userOtherIncomeInfo?.map((info) =>
        info.Other_Income_Number === otherIncomeNumber
          ? { ...info, ...updatedOtherIncomeData }
          : info
      ),
    })),
  deleteUserOtherIncomeInfo: (otherIncomeNumber) =>
    set((state) => ({
      userOtherIncomeInfo: state.userOtherIncomeInfo.filter(
        (info) => info.Other_Income_Number !== otherIncomeNumber
      ),
    })),
  // FIXED INCOME
  setUserFixedIncomeInfo: (data) =>
    set((state) => {
      const currentStateData = state.userFixedIncomeInfo
        ? state.userFixedIncomeInfo
        : [];
      const newData = data ? data : [];
      const updatedUserFixedIncomeInfo = [...currentStateData, ...newData];
      return { userFixedIncomeInfo: updatedUserFixedIncomeInfo };
    }),
  updateUserFixedIncomeInfo: (FixedIncomeNumber, updatedFixedIncomeData) =>
    set((state) => ({
      userFixedIncomeInfo: state.userFixedIncomeInfo?.map((info) =>
        info.fixedIncomeNumber === FixedIncomeNumber
          ? { ...info, ...updatedFixedIncomeData }
          : info
      ),
    })),
  deleteUserFixedIncomeInfo: (FixedIncomeNumber) =>
    set((state) => ({
      userFixedIncomeInfo: state.userFixedIncomeInfo.filter(
        (info) => info.fixedIncomeNumber !== FixedIncomeNumber
      ),
    })),
  // END FIXED INCOME
  setUserIncomeInfo: (data) =>
    set(() => ({
      userIncomeInfo: {
        Salary: data?.Salary,
        Salary_Array:
          data?.Salary_Array !== null
            ? typeof data?.Salary_Array === "string"
              ? JSON.parse(data?.Salary_Array)
              : data?.Salary_Array
            : null,
        salaryStopAge: data?.salaryStopAge,
        Currently_Employed: data?.Currently_Employed,
        Employment_Type: data?.Employment_Type,
        Salary_Growth: data?.Salary_Growth ? Number(data?.Salary_Growth) : 0,
        CPP: data?.CPP,
        Do_You_CPP: data?.Do_You_CPP,
        Monthly_Retirement_Pension: data?.Monthly_Retirement_Pension,
        Would_Monthly_Avg: data?.Would_Monthly_Avg,
        OAS_40_Years: data?.OAS_40_Years,
        OAS_Years: data?.OAS_Years,
        Deductions: data?.Deductions,
        Do_You_EI: data?.Do_You_EI,
        hasStartedCPP: data?.hasStartedCPP,
        hasStartedCPPThisYear: data?.hasStartedCPPThisYear,
        cppMonth: data?.cppMonth,
        hasStartedOAS: data?.hasStartedOAS,
        hasStartedOASThisYear: data?.hasStartedOASThisYear,
        oasMonth: data?.oasMonth,
        oasAmount: data?.oasAmount,

        spouseIncome: data?.spouseIncome,
        spouseSalary_Array:
          data?.spouseSalary_Array !== null
            ? typeof data?.spouseSalary_Array === "string"
              ? JSON.parse(data?.spouseSalary_Array)
              : data?.spouseSalary_Array
            : null,
        spousePension: data?.spousePension,
        spouseSalaryStopAge: data?.spouseSalaryStopAge,
        spousePensionAge: data?.spousePensionAge,
        spouseCurrently_Employed: data?.spouseCurrently_Employed,
        spouseEmployment_Type: data?.spouseEmployment_Type,
        spouseSalary_Growth: data?.spouseSalary_Growth
          ? Number(data?.spouseSalary_Growth)
          : 0,
        spouseDeductions: data?.spouseDeductions,
        spouseDo_You_EI: data?.spouseDo_You_EI,
        spouseDo_You_CPP: data?.spouseDo_You_CPP,
        spouseCPP: data?.spouseCPP,
        spouseMonthly_Retirement_Pension:
          data?.spouseMonthly_Retirement_Pension,
        spouseWould_Monthly_Avg: data?.spouseWould_Monthly_Avg,
        spouseOAS_40_Years: data?.spouseOAS_40_Years,
        spouseOAS_Years: data?.spouseOAS_Years,
        spouseHasStartedCPP: data?.spouseHasStartedCPP,
        spouseHasStartedCPPThisYear: data?.spouseHasStartedCPPThisYear,
        spouseCPPMonth: data?.spouseCPPMonth,
        spouseHasStartedOAS: data?.spouseHasStartedOAS,
        spouseHasStartedOASThisYear: data?.spouseHasStartedOASThisYear,
        spouseOASMonth: data?.spouseOASMonth,
        spouseOASAmount: data?.spouseOASAmount,
      },
    })),
  setUserPensionInfo: (data) =>
    set((state) => {
      const currentStateData = state.userPensionInfo
        ? state.userPensionInfo
        : [];
      const newData = data ? data : [];
      const updatedUserPensionInfo = [...currentStateData, ...newData];
      return { userPensionInfo: updatedUserPensionInfo };
    }),
  updateUserPensionInfo: (pensionNumber, updatedPensionData) =>
    set((state) => ({
      userPensionInfo: state.userPensionInfo?.map((info) =>
        info.Pension_Number === pensionNumber
          ? { ...info, ...updatedPensionData }
          : info
      ),
    })),
  deleteUserPensionInfo: (pensionNumber) =>
    set((state) => ({
      userPensionInfo: state.userPensionInfo.filter(
        (info) => info.Pension_Number !== pensionNumber
      ),
    })),

  setUserPrimaryResidenceInfo: (data) =>
    set((state) => {
      const currentStateData = state.userPrimaryResidenceInfo
        ? state.userPrimaryResidenceInfo
        : [];
      const newData = data ? data : [];
      const updatedUserPrimaryResidenceInfo = [...currentStateData, ...newData];
      return { userPrimaryResidenceInfo: updatedUserPrimaryResidenceInfo };
    }),
  updateUserPrimaryResidenceInfo: (
    primaryResNumber,
    updatedPrimaryResidenceData
  ) =>
    set((state) => ({
      userPrimaryResidenceInfo: state.userPrimaryResidenceInfo?.map((info) =>
        info.Primary_Res_Number === primaryResNumber
          ? { ...info, ...updatedPrimaryResidenceData }
          : info
      ),
    })),
  deleteUserPrimaryResidenceInfo: (primaryResNumber) =>
    set((state) => ({
      userPrimaryResidenceInfo: state.userPrimaryResidenceInfo.filter(
        (info) => info.Primary_Res_Number !== primaryResNumber
      ),
    })),

  setUserCottageInfo: (data) =>
    set((state) => {
      const currentStateData = state.userCottageInfo
        ? state.userCottageInfo
        : [];
      const newData = data ? data : [];
      const updatedUserCottageInfo = [...currentStateData, ...newData];
      return { userCottageInfo: updatedUserCottageInfo };
    }),
  updateUserCottageInfo: (cottageNumber, updatedCottageData) =>
    set((state) => ({
      userCottageInfo: state.userCottageInfo?.map((info) =>
        info.Cottage_Number === cottageNumber
          ? { ...info, ...updatedCottageData }
          : info
      ),
    })),
  deleteUserCottageInfo: (cottageNumber) =>
    set((state) => ({
      userCottageInfo: state.userCottageInfo.filter(
        (info) => info.Cottage_Number !== cottageNumber
      ),
    })),

  setUserPropertiesInfo: (data) =>
    set((state) => {
      const currentStateData = state.userPropertiesInfo
        ? state.userPropertiesInfo
        : [];
      const newData = data ? data : [];
      const updatedUserPropertiesInfo = [...currentStateData, ...newData];
      return { userPropertiesInfo: updatedUserPropertiesInfo };
    }),
  updateUserPropertiesInfo: (PropertiesNumber, updatedPropertiesData) =>
    set((state) => ({
      userPropertiesInfo: state.userPropertiesInfo?.map((info) =>
        info.Property_Number === PropertiesNumber
          ? { ...info, ...updatedPropertiesData }
          : info
      ),
    })),
  deleteUserPropertiesInfo: (PropertiesNumber) =>
    set((state) => ({
      userPropertiesInfo: state.userPropertiesInfo.filter(
        (info) => info.Property_Number !== PropertiesNumber
      ),
    })),

  // LAND
  setUserLandInfo: (data) =>
    set((state) => {
      const currentStateData = state.userLandInfo ? state.userLandInfo : [];
      const newData = data ? data : [];
      const updatedUserLandInfo = [...currentStateData, ...newData];
      return { userLandInfo: updatedUserLandInfo };
    }),
  updateUserLandInfo: (LandNumber, updatedLandData) =>
    set((state) => ({
      userLandInfo: state.userLandInfo?.map((info) =>
        info.landNumber === LandNumber ? { ...info, ...updatedLandData } : info
      ),
    })),
  deleteUserLandInfo: (LandNumber) =>
    set((state) => ({
      userLandInfo: state.userLandInfo.filter(
        (info) => info.landNumber !== LandNumber
      ),
    })),
  // END Land

  // Business:
  setUserBusinessInfo: (data) =>
    set((state) => {
      const currentStateData = state.userBusinessInfo
        ? state.userBusinessInfo
        : [];
      const newData = data ? data : [];
      const updatedUserBusinessInfo = [...currentStateData, ...newData];
      return { userBusinessInfo: updatedUserBusinessInfo };
    }),
  updateUserBusinessInfo: (BusinessNumber, updatedBusinessData) =>
    set((state) => ({
      userBusinessInfo: state.userBusinessInfo?.map((info) =>
        info.businessNumber === BusinessNumber
          ? { ...info, ...updatedBusinessData }
          : info
      ),
    })),
  deleteUserBusinessInfo: (BusinessNumber) =>
    set((state) => ({
      userBusinessInfo: state.userBusinessInfo.filter(
        (info) => info.businessNumber !== BusinessNumber
      ),
    })),

  // Company:
  setUserCompanyInfo: (data) =>
    set((state) => {
      const currentStateData = state.userCompanyInfo
        ? state.userCompanyInfo
        : [];
      const newData = data ? data : [];
      const updatedUserCompanyInfo = [...currentStateData, ...newData];
      return { userCompanyInfo: updatedUserCompanyInfo };
    }),
  updateUserCompanyInfo: (CompanyNumber, updatedCompanyData) =>
    set((state) => ({
      userCompanyInfo: state.userCompanyInfo?.map((info) =>
        info.companyNumber === CompanyNumber
          ? { ...info, ...updatedCompanyData }
          : info
      ),
    })),
  deleteUserCompanyInfo: (CompanyNumber) =>
    set((state) => ({
      userCompanyInfo: state.userCompanyInfo.filter(
        (info) => info.companyNumber !== CompanyNumber
      ),
    })),

  setUserOtherAssetsInfo: (data) =>
    set((state) => {
      const currentStateData = state.userOtherAssetsInfo
        ? state.userOtherAssetsInfo
        : [];
      const newData = data ? data : [];
      const updatedUserOtherAssetsInfo = [...currentStateData, ...newData];
      return { userOtherAssetsInfo: updatedUserOtherAssetsInfo };
    }),
  updateUserOtherAssetsInfo: (OtherAssetsNumber, updatedOtherAssetsData) =>
    set((state) => ({
      userOtherAssetsInfo: state.userOtherAssetsInfo?.map((info) =>
        info.Other_Assets_Number === OtherAssetsNumber
          ? { ...info, ...updatedOtherAssetsData }
          : info
      ),
    })),
  deleteUserOtherAssetsInfo: (OtherAssetsNumber) =>
    set((state) => ({
      userOtherAssetsInfo: state.userOtherAssetsInfo.filter(
        (info) => info.Other_Assets_Number !== OtherAssetsNumber
      ),
    })),

  setUserInheritanceInfo: (data) =>
    set((state) => {
      const currentStateData = state.userInheritanceInfo
        ? state.userInheritanceInfo
        : [];
      const newData = data ? data : [];
      const updatedUserInheritanceInfo = [...currentStateData, ...newData];
      return { userInheritanceInfo: updatedUserInheritanceInfo };
    }),
  updateUserInheritanceInfo: (InheritanceNumber, updatedInheritanceData) =>
    set((state) => ({
      userInheritanceInfo: state.userInheritanceInfo?.map((info) =>
        info.InheritanceNumber === InheritanceNumber
          ? { ...info, ...updatedInheritanceData }
          : info
      ),
    })),
  deleteUserInheritanceInfo: (InheritanceNumber) =>
    set((state) => ({
      userInheritanceInfo: state.userInheritanceInfo.filter(
        (info) => info.InheritanceNumber !== InheritanceNumber
      ),
    })),

  setUserLifeInsuranceInfo: (data) =>
    set((state) => {
      const currentStateData = state.userLifeInsuranceInfo
        ? state.userLifeInsuranceInfo
        : [];
      const newData = data ? data : [];
      const updatedUserLifeInsuranceInfo = [...currentStateData, ...newData];
      return { userLifeInsuranceInfo: updatedUserLifeInsuranceInfo };
    }),
  updateUserLifeInsuranceInfo: (
    LifeInsuranceNumber,
    updatedLifeInsuranceData
  ) =>
    set((state) => ({
      userLifeInsuranceInfo: state.userLifeInsuranceInfo?.map((info) =>
        info.Life_Insurance_Number === LifeInsuranceNumber
          ? { ...info, ...updatedLifeInsuranceData }
          : info
      ),
    })),
  deleteUserLifeInsuranceInfo: (LifeInsuranceNumber) =>
    set((state) => ({
      userLifeInsuranceInfo: state.userLifeInsuranceInfo.filter(
        (info) => info.Life_Insurance_Number !== LifeInsuranceNumber
      ),
    })),

  userPageLockInfo: true,
  setUserPageLockInfo: (data) =>
    set((state) => ({
      userPageLockInfo: {
        IncomePage: data?.IncomePage,
        InvestmentsPage: data?.InvestmentsPage,
        ExpensesPage: data?.ExpensesPage,
        OptimizationPage: data?.OptimizationPage,
      },
    })),

  setUserArchiveInfo: (data) =>
    set((state) => {
      const currentStateData = state.userArchiveInfo
        ? state.userArchiveInfo
        : [];
      const newData = data ? data : [];
      const updatedUserArchiveInfo = [...currentStateData, ...newData];
      return { userArchiveInfo: updatedUserArchiveInfo };
    }),
  deleteUserArchiveInfo: (archiveNumber) =>
    set((state) => ({
      userArchiveInfo: state.userArchiveInfo.filter(
        (info) => info.Archive_Number !== archiveNumber
      ),
    })),
});

export const useStore = create(store);
