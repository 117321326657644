import React, { useState, useContext } from "react";
import { useStore } from "../store";
import Link from "./Link";
import { useAuth0 } from "@auth0/auth0-react";
import isEqual from "lodash/isEqual";
import axios from "axios";
import betaIcon from "../img/Beta icon.svg";
import { SpouseContext } from "./SpouseContext";
import { StripeContext } from "./StripeContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "./modal.css";
import "../components/Save Analysis Modal/saveAnalysisModal.css";
import { faRocket } from "@fortawesome/pro-light-svg-icons";
import {
  Box,
  Button,
  Icon,
  Input,
  Text,
  useMediaQuery,
} from "@chakra-ui/react";
import { CheckIcon, CloseIcon, LaunchNewOptimizationIcon } from "./CustomIcons";
import StripeUpgradeModal from "./StripeUpgradeModal";

function Modal(props) {
  const [name, setName] = useState("");
  const [saveAnalysis, setSaveAnalysis] = useState("");
  const [analysisSaved, setAnalysisSaved] = useState(false);
  const setShowNewOptimization = useStore(
    (store) => store.setShowNewOptimization
  );
  const [spousePrefix, setSpousePrefix] = useState("sps1");
  const setMobileSidebarOpen = useStore((store) => store.setMobileSidebarOpen);
  const setUserArchiveInfo = useStore((store) => store.setUserArchiveInfo);
  const [yesClicked, setYesClicked] = useState(false);
  const [noClicked, setNoClicked] = useState(false);
  const userArchiveInfo = useStore((store) => store.userArchiveInfo);
  const userInfo = useStore((store) => store.userInfo);
  const userOptiData = useStore((store) => store.userOptiData);
  const userInsightsData = useStore((store) => store.userInsightsData);
  const [saveConfirm, setSaveConfirm] = useState("Confirm");
  const { spouseStatus, setSpouseStatus } = useContext(SpouseContext);

  const { stripePlan, setStripePlan } = useContext(StripeContext);

  const { user, getAccessTokenSilently } = useAuth0();

  const axiosInstance = axios.create();
  async function getToken() {
    // Replace this with your Auth0 token retrieval logic
    const token = await getAccessTokenSilently({
      audience:
        "https://os2lsiwff43wfpb5ggyawt6qri0mgcro.lambda-url.us-east-2.on.aws/",
      scope: "write:optimization",
    });
    return token;
  }

  // Add a request interceptor
  axiosInstance.interceptors.request.use(
    async (config) => {
      const token = await getToken();
      config.headers.Authorization = `Bearer ${token}`;
      return config;
    },
    (error) => {
      // Do something with request error
      return Promise.reject(error);
    }
  );

  function getAge(birthday, currentDate) {
    // Convert the birthday string into a Date object
    const birthDate = new Date(birthday);

    // Calculate the age in years
    let age = currentDate?.getFullYear() - birthDate?.getFullYear();

    // Decrement the age if the current month and day are before the birth month and day
    if (
      currentDate?.getMonth() < birthDate?.getMonth() ||
      (currentDate?.getMonth() === birthDate?.getMonth() &&
        currentDate?.getDate() < birthDate?.getDate())
    ) {
      age -= 1;
    }

    return age;
  }

  const [showStripeModal, setShowStripeModal] = useState(false);
  function UpgradeStripeModal() {
    setShowStripeModal(!showStripeModal);
  }
  function formatDate(dateString) {
    const date = new Date(dateString);
    const options = { month: "long", day: "numeric", year: "numeric" };
    const formatted = date?.toLocaleDateString("en-US", options);

    const day = date?.getDate();
    let suffix = "th";
    if (day === 1 || day === 21 || day === 31) {
      suffix = "st";
    } else if (day === 2 || day === 22) {
      suffix = "nd";
    } else if (day === 3 || day === 23) {
      suffix = "rd";
    }

    const dayWithSuffix = day + suffix;

    return formatted.replace(day.toString(), dayWithSuffix);
  }

  const setRunDate = () => {
    if (userOptiData?.primaryUserData?.planRunDate !== null) {
      const dateOfPlan = new Date(userOptiData?.primaryUserData?.planRunDate);
      // console?.log(dateOfPlan.toLocaleDateString());
      return formatDate(userOptiData?.primaryUserData?.planRunDate);
    } else {
      const today = new Date();
      return formatDate(today);
    }
  };

  const onSubmitAdd = (e) => {
    e.preventDefault();
    const currentYear = new Date();
    setAnalysisSaved(true);
    setSaveConfirm("Saved");
    const year = new Date().getFullYear();
    const finalYear =
      year + (userInfo?.runAge - getAge(userInfo?.DateOfBirth, currentYear));
    // console.log("Adding Archive", name, userOptiData?.primaryUserData, userOptiData?.spouseData, currentYear)
    const analysisDuration = `${year} - ${finalYear}`;
    // setSaveAnalysisModal(false)
    const spouseOneData = JSON.stringify(userOptiData?.primaryUserData);
    const spouseTwoData = userOptiData?.spouseData
      ? JSON.stringify(userOptiData?.spouseData)
      : null;

    const analysisObject = {
      Archive_ID: user.sub,
      Archive_Strategy: userOptiData?.primaryUserData?.optimizationType,
      Archive_Estate: safeAdd(
        userOptiData?.primaryUserData?.optimizedEOLAmount,
        userOptiData?.primaryUserData?.PrimaryResidence?.Value?.[
          userOptiData?.primaryUserData?.PrimaryResidence?.Value?.length - 1
        ],
        userOptiData?.primaryUserData?.[`${spousePrefix}_pension`]?.Value?.[
          userOptiData?.primaryUserData?.[`${spousePrefix}_pension`]?.Value
            ?.length - 1
        ],
        userOptiData?.spouseData?.sps2_pension?.Value?.[
          userOptiData?.spouseData?.sps2_pension?.Value?.length - 1
        ],
        userOptiData?.primaryUserData?.IncomePropertyYearlyValue?.[
          userOptiData?.primaryUserData?.IncomePropertyYearlyValue?.length - 1
        ],
        userOptiData?.primaryUserData?.RecreationPropertyYearlyValue?.[
          userOptiData?.primaryUserData?.RecreationPropertyYearlyValue?.length -
            1
        ],
        userOptiData?.primaryUserData?.businessYearlyValue?.[
          userOptiData?.primaryUserData?.businessYearlyValue?.length - 1
        ],
        userOptiData?.primaryUserData?.LandYearlyValue?.[
          userOptiData?.primaryUserData?.LandYearlyValue?.length - 1
        ],
        userOptiData?.primaryUserData?.lifeInsuranceYearlyValue?.[
          userOptiData?.primaryUserData?.lifeInsuranceYearlyValue?.length - 1
        ],
        userOptiData?.primaryUserData?.otherAssetsYearlyValue?.[
          userOptiData?.primaryUserData?.otherAssetsYearlyValue?.length - 1
        ]
      ),
      Archive_Date: setRunDate(),
      Archive_Opti_Data: spouseOneData,
      Archive_Spouse_Opti_Data: spouseTwoData,
      Archive_Name: name,
      Archive_Duration: analysisDuration,
      Archive_ROT: JSON.stringify(userOptiData?.spouse1_ROT),
      Archive_Spouse_ROT: JSON.stringify(userOptiData?.spouse2_ROT),
      Archive_Retire_Early: JSON.stringify(userInsightsData?.retireEarly),
      Archive_Retire_Late: JSON.stringify(userInsightsData?.retireLate),
    };

    const archiveURL = `${process.env.REACT_APP_NODE_API_GATEWAY}/CompassLambdaApi/ANALYSIS_ARCHIVE/add`;
    axiosInstance
      .post(archiveURL, analysisObject)
      .then((res) => {
        console.log(res);
        const newData = [
          {
            Archive_Strategy: userOptiData?.primaryUserData?.optimizationType,
            Archive_Estate: safeAdd(
              userOptiData?.primaryUserData?.optimizedEOLAmount,
              userOptiData?.primaryUserData?.PrimaryResidence?.Value?.[
                userOptiData?.primaryUserData?.PrimaryResidence?.Value?.length -
                  1
              ],
              userOptiData?.primaryUserData?.[`${spousePrefix}_pension`]
                ?.Value?.[
                userOptiData?.primaryUserData?.[`${spousePrefix}_pension`]
                  ?.Value?.length - 1
              ],
              userOptiData?.spouseData?.sps2_pension?.Value?.[
                userOptiData?.spouseData?.sps2_pension?.Value?.length - 1
              ],
              userOptiData?.primaryUserData?.IncomePropertyYearlyValue?.[
                userOptiData?.primaryUserData?.IncomePropertyYearlyValue
                  ?.length - 1
              ],
              userOptiData?.primaryUserData?.RecreationPropertyYearlyValue?.[
                userOptiData?.primaryUserData?.RecreationPropertyYearlyValue
                  ?.length - 1
              ],
              userOptiData?.primaryUserData?.businessYearlyValue?.[
                userOptiData?.primaryUserData?.businessYearlyValue?.length - 1
              ],
              userOptiData?.primaryUserData?.LandYearlyValue?.[
                userOptiData?.primaryUserData?.LandYearlyValue?.length - 1
              ],
              userOptiData?.primaryUserData?.lifeInsuranceYearlyValue?.[
                userOptiData?.primaryUserData?.lifeInsuranceYearlyValue
                  ?.length - 1
              ],
              userOptiData?.primaryUserData?.otherAssetsYearlyValue?.[
                userOptiData?.primaryUserData?.otherAssetsYearlyValue?.length -
                  1
              ]
            ),
            Archive_Date: setRunDate(),
            Archive_Opti_Data: spouseOneData,
            Archive_Spouse_Opti_Data: spouseTwoData,
            Archive_Number: res.data.lastInsertedId,
            Archive_Name: name,
            Archive_Duration: analysisDuration,
            Archive_ROT: JSON.stringify(userOptiData?.spouse1_ROT),
            Archive_Spouse_ROT: JSON.stringify(userOptiData?.spouse2_ROT),
            Archive_Retire_Early: JSON.stringify(userInsightsData?.retireEarly),
            Archive_Retire_Late: JSON.stringify(userInsightsData?.retireLate),
          },
        ];
        setUserArchiveInfo(newData);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  function remainingSaves() {
    if (
      stripePlan?.items?.data?.[0]?.price?.lookup_key === "PREMIUM" ||
      stripePlan?.items?.data?.[0]?.price?.lookup_key === "PREMIUM_M"
    ) {
      return 20 - userArchiveInfo.length;
    }
    if (
      stripePlan?.items?.data?.[0]?.price?.lookup_key === "PRO" ||
      stripePlan?.items?.data?.[0]?.price?.lookup_key === "PRO_M"
    ) {
      return 10 - userArchiveInfo.length;
    }
  }

  const onClose = (e) => {
    props.onClose && props.onClose(e);
    setSaveAnalysis("");
    setAnalysisSaved(false);
    setNoClicked(false);
    setYesClicked(false);
    setSaveConfirm("Confirm");
  };

  function safeAdd(...values) {
    return values.reduce((sum, val) => sum + (Number(val) || 0), 0);
  }

  function checkIfAnalysisIsSaved() {
    return userArchiveInfo?.some((item) => {
      if (spouseStatus) {
        return (
          isEqual(
            userOptiData?.primaryUserData?.sps1_nonregisteredWithdrawals,
            JSON.parse(item?.Archive_Opti_Data)?.sps1_nonregisteredWithdrawals
          ) &&
          isEqual(
            userOptiData?.spouseData?.sps2_nonregisteredWithdrawals,
            JSON.parse(item?.Archive_Spouse_Opti_Data)
              ?.sps2_nonregisteredWithdrawals
          ) &&
          isEqual(
            userOptiData?.primaryUserData?.sps1_provincialTaxes,
            JSON.parse(item?.Archive_Opti_Data)?.sps1_provincialTaxes
          ) &&
          isEqual(
            userOptiData?.primaryUserData?.optimizedEOLAmount,
            JSON.parse(item?.Archive_Opti_Data)?.optimizedEOLAmount
          )
        );
      } else {
        return (
          isEqual(
            userOptiData?.primaryUserData?.sps1_nonregisteredWithdrawals,
            JSON.parse(item?.Archive_Opti_Data)?.sps1_nonregisteredWithdrawals
          ) &&
          isEqual(
            userOptiData?.primaryUserData?.sps1_provincialTaxes,
            JSON.parse(item?.Archive_Opti_Data)?.sps1_provincialTaxes
          ) &&
          isEqual(
            userOptiData?.primaryUserData?.optimizedEOLAmount,
            JSON.parse(item?.Archive_Opti_Data)?.optimizedEOLAmount
          )
        );
      }
    });
  }

  const [isInputVisible, setIsInputVisible] = useState(false);

  const handleButtonClick = () => {
    setIsInputVisible(true);
  };

  const [isLargerThan500] = useMediaQuery("(min-width: 500px)");
  const isMobile = !isLargerThan500;
  const [hover, setHover] = useState(false);

  const newOptimization = (e) => {
    setShowNewOptimization(true);
    setMobileSidebarOpen(false);
    onClose();
    e.preventDefault();
    window.history.pushState({}, "", "/revenue");
    const navEvent = new PopStateEvent("popstate");
    window.dispatchEvent(navEvent);
  };

  if (!props.show) {
    return null;
  }
  return (
    <>
      <StripeUpgradeModal
        isOpen={showStripeModal}
        closeModal={UpgradeStripeModal}
      />
      <div className="new-optimization-modal" id="modal">
        <div
          className="close-btn"
          onClick={onClose}
          style={{ cursor: "pointer", padding: "10px 10px 0px 0px" }}
        >
          <CloseIcon onClick={onClose} svgheight={"20px"} svgwidth={"20px"} />
        </div>
        <Box
          display={"flex"}
          padding={"0px 25px 25px 25px"}
          flexDirection={"column"}
          alignItems={"center"}
          gap={"10px"}
          alignSelf={"stretch"}
          width={"100%"}
        >
          <Text
            color={"#0093D0"}
            fontFamily={"Nunito"}
            fontSize={"24px"}
            fontStyle={"normal"}
            fontWeight={"600"}
            lineHeight={"normal"}
            textDecor={"underline"}
          >
            Update my plan
          </Text>
          {
            <Text
              color={"#004069"}
              textAlign={"center"}
              fontFamily={"Nunito"}
              fontSize={"14px"}
              fontStyle={"normal"}
              fontWeight={"700"}
              lineHeight={"normal"}
            >
              Available saves remaining:
              <Text
                as={"span"}
                color={"#0093D0"}
                fontFamily={"Nunito"}
                fontSize={"14px"}
                fontStyle={"normal"}
                fontWeight={"700"}
                lineHeight={"normal"}
              >
                {"  "}
                {remainingSaves() > 0 ? remainingSaves() : 0}
              </Text>
            </Text>
          }
          {
            <Text
              alignSelf={"stretch"}
              color={"#004069"}
              textAlign={"center"}
              fontFamily={"Nunito"}
              fontSize={"12px"}
              fontStyle={"normal"}
              fontWeight={"600"}
              lineHeight={"normal"}
            >
              Starting a new optimization will override your previous analysis.
              However, your earlier data will remain accessible for updates.
              When you initiate the new analysis, it will override the previous
              one.
            </Text>
          }
          {saveConfirm !== "Saved" && (
            <>
              {((!checkIfAnalysisIsSaved() && remainingSaves() > 0) ||
                stripePlan?.items?.data?.[0]?.price?.lookup_key ===
                  "OPTIML_ESSENTIALS" ||
                stripePlan?.items?.data?.[0]?.price?.lookup_key ===
                  "OPTIML_ESSENTIALS_M") && (
                <Text
                  color={"#004069"}
                  textAlign={"center"}
                  fontFamily={"Nunito"}
                  fontSize={"14px"}
                  fontStyle={"normal"}
                  fontWeight={"700"}
                  lineHeight={"normal"}
                >
                  Save current analysis to your archive before proceeding?
                </Text>
              )}

              {((!checkIfAnalysisIsSaved() && remainingSaves() > 0) ||
                stripePlan?.items?.data?.[0]?.price?.lookup_key ===
                  "OPTIML_ESSENTIALS" ||
                stripePlan?.items?.data?.[0]?.price?.lookup_key ===
                  "OPTIML_ESSENTIALS_M") && (
                <div className="decision-buttons-container" style={{}}>
                  <button
                    className={`decision-button yes-button ${
                      yesClicked ? "yes-clicked" : ""
                    }`}
                    onClick={() => {
                      setYesClicked(true);
                      setNoClicked(false);
                      setSaveAnalysis("Yes");
                    }}
                  >
                    Yes
                  </button>
                  <button
                    className={`decision-button no-button ${
                      noClicked ? "yes-clicked" : ""
                    }`}
                    onClick={() => {
                      {
                        setNoClicked(true);
                        setYesClicked(false);
                        setSaveAnalysis("No");
                      }
                    }}
                  >
                    No
                  </button>
                </div>
              )}
              {saveAnalysis === "Yes" &&
                saveConfirm != "Saved" &&
                (stripePlan?.items?.data?.[0]?.price?.lookup_key !=
                  "OPTIML_ESSENTIALS" &&
                stripePlan?.items?.data?.[0]?.price?.lookup_key !=
                  "OPTIML_ESSENTIALS_M" ? (
                  <>
                    <div className="input-container-archive">
                      <label
                        className="input-label-archive"
                        style={{ textAlign: "center" }}
                      >
                        Name of saved analysis
                      </label>

                      <Input
                        type="text"
                        style={{ minWidth: "20rem" }}
                        id="savedAnalysisName"
                        className="analysis-name-input"
                        placeholder="Enter name here"
                        maxLength={30}
                        value={name}
                        onChange={(e) => {
                          setName(e.target.value);
                        }}
                      />
                    </div>
                  </>
                ) : (
                  <>
                    <Button
                      onClick={UpgradeStripeModal}
                      display={"flex"}
                      padding={"10px 25px"}
                      justifyContent={"center"}
                      alignItems={"center"}
                      gap={"10px"}
                      borderRadius={"5px"}
                      border={"1px solid #00A2D4"}
                      bg={"#00A2D4"}
                    >
                      <Text
                        color={"#fff"}
                        fontFamily={"Nunito"}
                        fontSize={"16px"}
                        fontStyle={"normal"}
                        fontWeight={"600"}
                        lineHeight={"normal"}
                      >
                        UPGRADE NOW
                      </Text>
                    </Button>

                    <Text
                      color={"#7F7F7F"}
                      textAlign={"center"}
                      fontFamily={"Nunito"}
                      fontSize={"12px"}
                      fontStyle={"normal"}
                      fontWeight={"400"}
                      lineHeight={"normal"}
                    >
                      Upgrade to enable the ability to save analysis to your
                      account.
                    </Text>
                  </>
                ))}
              {name &&
                saveConfirm != "Saved" &&
                saveAnalysis === "Yes" &&
                stripePlan?.items?.data?.[0]?.price?.lookup_key !=
                  "OPTIML_ESSENTIALS" &&
                stripePlan?.items?.data?.[0]?.price?.lookup_key !=
                  "OPTIML_ESSENTIALS_M" && (
                  <div
                    className="decision-buttons-container"
                    style={{
                      boxShadow: "0px 1px 4px 0px #84C98B",
                      borderRadius: "100px",
                    }}
                  >
                    <button
                      className="decision-button-confirm"
                      onClick={onSubmitAdd}
                    >
                      Save
                    </button>
                  </div>
                )}
            </>
          )}
          {name &&
            saveConfirm === "Saved" &&
            saveAnalysis === "Yes" &&
            stripePlan?.items?.data?.[0]?.price?.lookup_key !=
              "OPTIML_ESSENTIALS" &&
            stripePlan?.items?.data?.[0]?.price?.lookup_key !=
              "OPTIML_ESSENTIALS_M" && (
              <div className="decision-buttons-container" style={{}}>
                <Button
                  display={"flex"}
                  padding={"10px 30px"}
                  alignItems={"center"}
                  gap={"10px"}
                  justifyContent={"center"}
                  borderRadius={"100px"}
                  border={"1px solid #27AE60"}
                  bg={"#27AE60"}
                  boxShadow={"0px 1px 4px 0px #84C98B"}
                >
                  <Icon
                    as={CheckIcon}
                    svgheight={"16px"}
                    svgwidth={"16px"}
                    InnerfillColour={"#FFF"}
                    outerfillColour={"#27AE60"}
                  />

                  <Text
                    color={"#fff"}
                    fontFamily={"Nunito"}
                    fontSize={"14px"}
                    fontStyle={"normal"}
                    fontWeight={"800"}
                    lineHeight={"normal"}
                  >
                    {saveConfirm}
                  </Text>
                </Button>
              </div>
            )}
          {(analysisSaved ||
            checkIfAnalysisIsSaved() ||
            (remainingSaves() > 0 && noClicked) ||
            ((stripePlan?.items?.data?.[0]?.price?.lookup_key ===
              "OPTIML_ESSENTIALS" ||
              stripePlan?.items?.data?.[0]?.price?.lookup_key ===
                "OPTIML_ESSENTIALS_M") &&
              noClicked) ||
            remainingSaves() <= 0) && (
            <button
              className="launch-optimization"
              onMouseEnter={() => setHover(true)}
              onMouseLeave={() => setHover(false)}
              onClick={(e) => {
                newOptimization(e);
                onClose();
              }}
            >
              <LaunchNewOptimizationIcon
                color={hover && "#FFF"}
                className="icon"
                svgheight={"21px"}
                svgwidth={"21px"}
              />
              <span style={{ margin: "0.5rem" }}>Launch New Optimization</span>
            </button>
          )}
        </Box>
      </div>
    </>
  );
}

export default Modal;
