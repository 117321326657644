import { Flex, Heading, VStack, useMediaQuery } from "@chakra-ui/react";
import { useAuth0 } from "@auth0/auth0-react";
import axios from "axios";
import React, {
  useContext,
  useEffect,
  useState,
  useImperativeHandle,
  forwardRef,
} from "react";
import { useStore } from "../../store";
import { SpouseContext } from "../SpouseContext";
import GlobalBodyPage from "../GlobalContainer/BodyPage";
import DropDownButton from "../InputFields/dropDownButton";
import NormalInputButton from "../InputFields/normalInputButton";
import TooltipContent from "../TooltipContent";

const OasPage = forwardRef((props, ref) => {
  const { setChangedData } = props;
  useImperativeHandle(ref, () => ({
    submitHandler,
    checkMandatoryFields,
  }));

  const [isLargerThan768] = useMediaQuery("(min-width: 500px)");
  const isMobile = !isLargerThan768;
  const [isLargerThan1200] = useMediaQuery(
    "(min-width: 500px) and (max-width: 1250px)"
  );
  const [spouse1answer, setSpouse1Answer] = useState("");
  const [spouse2answer, setSpouse2Answer] = useState("");
  const { getAccessTokenSilently } = useAuth0();

  const axiosInstance = axios.create();
  async function getToken() {
    // Replace this with your Auth0 token retrieval logic
    const token = await getAccessTokenSilently({
      audience:
        "https://os2lsiwff43wfpb5ggyawt6qri0mgcro.lambda-url.us-east-2.on.aws/",
      scope: "write:optimization",
    });
    return token;
  }

  // Add a request interceptor
  axiosInstance.interceptors.request.use(
    async (config) => {
      const token = await getToken();
      config.headers.Authorization = `Bearer ${token}`;
      return config;
    },
    (error) => {
      // Do something with request error
      return Promise.reject(error);
    }
  );
  const { spouseStatus, setSpouseStatus } = useContext(SpouseContext);
  const [residencyDuration, setResidencyDuration] = useState("");
  const [residencyYear, setResidencyYear] = useState();
  const [spouseResidencyDuration, setSpouseResidencyDuration] = useState("");
  const [spouseResidencyYear, setSpouseResidencyYear] = useState();

  const [olderThan65, setOlderThan65] = useState(false);
  const [haveStartedOAS, setHaveStartedOAS] = useState("");
  const [haveStartedThisYear, setHaveStartedThisYear] = useState("");
  const [monthStartedOAS, setMonthStartedOAS] = useState("");
  const [monthlyOASAmount, setMonthlyOASAmount] = useState("");

  const [spouseOlderThan65, setSpouseOlderThan65] = useState(false);
  const [spouseHaveStartedOAS, setSpouseHaveStartedOAS] = useState("");
  const [spouseHaveStartedThisYear, setSpouseHaveStartedThisYear] =
    useState("");
  const [spouseMonthStartedOAS, setSpouseMonthStartedOAS] = useState("");
  const [spouseMonthlyOASAmount, setSpouseMonthlyOASAmount] = useState("");

  const { user, logout } = useAuth0();
  const userInfo = useStore((store) => store.userInfo);
  const userIncomeInfo = useStore((store) => store.userIncomeInfo);
  const setUserIncomeInfo = useStore((store) => store.setUserIncomeInfo);

  const [errors, setErrors] = useState({});
  const [spouseErrors, setSpouseErrors] = useState({});

  const [isEditMode, setIsEditMode] = useState(false);

  const userOptiData = useStore((store) => store.userOptiData);
  const [newUser, setNewUser] = useState();

  useEffect(() => {
    if (userOptiData?.primaryUserData?.expenses?.length > 1) {
      setNewUser(false);
    } else if (userOptiData?.primaryUserData?.expenses?.length === undefined) {
      setNewUser(true);
      setIsEditMode(true);
    }
  }, [userOptiData]);

  useEffect(() => {
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 100);
  }, [errors, spouseErrors]);

  // Function to toggle edit mode
  const toggleEditMode = () => {
    setIsEditMode(!isEditMode);
  };

  const {
    FirstName: firstName,
    LastName: lastName,
    DateOfBirth,
    spouseFirstName,
    spouseLastName,
    spouseDateOfBirth,
  } = userInfo;

  useEffect(() => {
    if (getAge(DateOfBirth) >= 65) {
      setOlderThan65(true);
    }
    if (getAge(spouseDateOfBirth) >= 65) {
      setSpouseOlderThan65(true);
    }
    const fetchData = async () => {
      if (userIncomeInfo?.OAS_40_Years !== null) {
        setResidencyDuration(userIncomeInfo?.OAS_40_Years);
        setResidencyYear(userIncomeInfo?.OAS_Years);
        setHaveStartedOAS(userIncomeInfo?.hasStartedOAS);
        setHaveStartedThisYear(userIncomeInfo?.hasStartedOASThisYear);
        setMonthStartedOAS(userIncomeInfo?.oasMonth);
        setMonthlyOASAmount(userIncomeInfo?.oasAmount);

        setSpouseResidencyDuration(userIncomeInfo?.spouseOAS_40_Years);
        setSpouseResidencyYear(userIncomeInfo?.spouseOAS_Years);
        setSpouseHaveStartedOAS(userIncomeInfo?.spouseHasStartedOAS);
        setSpouseHaveStartedThisYear(
          userIncomeInfo?.spouseHasStartedOASThisYear
        );
        setSpouseMonthStartedOAS(userIncomeInfo?.spouseOASMonth);
        setSpouseMonthlyOASAmount(userIncomeInfo?.spouseOASAmount);
        if (getAge(DateOfBirth) < 65) {
          setHaveStartedOAS("No");
          setHaveStartedThisYear("No");
          setMonthStartedOAS("");
          setMonthlyOASAmount("");
        }
        if (getAge(spouseDateOfBirth) < 65) {
          setSpouseHaveStartedOAS("No");
          setSpouseHaveStartedThisYear("No");
          setSpouseMonthStartedOAS("");
          setSpouseMonthlyOASAmount("");
        }
      } else {
        setResidencyDuration("");
        setResidencyYear("");
      }
    };
    fetchData().catch(console.error);
  }, []);

  function getAge(birthday) {
    // Get the current date
    const currentDate = new Date();

    // Convert the birthday string into a Date object
    const birthDate = new Date(birthday);

    // Calculate the age in years
    let age = currentDate.getFullYear() - birthDate.getFullYear();

    // Decrement the age if the current month and day are before the birth month and day
    if (
      currentDate.getMonth() < birthDate.getMonth() ||
      (currentDate.getMonth() === birthDate.getMonth() &&
        currentDate.getDate() <= birthDate.getDate())
    ) {
      age -= 1;
    }

    return age;
  }

  const selectResidencyYear = () => {
    const yearList = [];
    for (let i = 1; i <= 39; i++) {
      yearList.push({ key: i, value: i.toString() });
    }
    return yearList;
  };

  const moneyToNumber = (value) => {
    let numValue = 0;
    if (value) {
      numValue = value.replace(/[$,\s]/g, "");
      numValue = Number(numValue);
    }
    return numValue;
  };

  const submitHandler = (e) => {
    if (e) {
      e.preventDefault();
    }
    setIsEditMode(!isEditMode);
    const incomeURL = (action) =>
      `${
        process.env.REACT_APP_NODE_API_GATEWAY
      }/CompassLambdaApi/INCOME-OAS/${action}?IncomeID=${user.sub.replace(
        "|",
        "%7c"
      )}&OAS_40_Years=${residencyDuration}&OAS_Years=${residencyYear}&spouseOAS_40_Years=${spouseResidencyDuration}&spouseOAS_Years=${spouseResidencyYear}&hasStartedOAS=${haveStartedOAS}&hasStartedOASThisYear=${haveStartedThisYear}&oasMonth=${monthStartedOAS}&oasAmount=${monthlyOASAmount}&spouseHasStartedOAS=${spouseHaveStartedOAS}&spouseHasStartedOASThisYear=${spouseHaveStartedThisYear}&spouseOASMonth=${spouseMonthStartedOAS}&spouseOASAmount=${spouseMonthlyOASAmount}`;
    axiosInstance
      .get(incomeURL("add"))
      .then((res) => {
        setUserIncomeInfo({
          ...userIncomeInfo,
          OAS_40_Years: residencyDuration,
          OAS_Years: residencyYear,
          hasStartedOAS: haveStartedOAS,
          hasStartedOASThisYear: haveStartedThisYear,
          oasMonth: monthStartedOAS,
          oasAmount: monthlyOASAmount,
          spouseOAS_40_Years: spouseResidencyDuration,
          spouseOAS_Years: spouseResidencyYear,
          spouseHasStartedOAS: spouseHaveStartedOAS,
          spouseHasStartedOASThisYear: spouseHaveStartedThisYear,
          spouseOASMonth: spouseMonthStartedOAS,
          spouseOASAmount: spouseMonthlyOASAmount,
        });
        if (res.data.code === "ER_DUP_ENTRY") {
          axiosInstance.get(incomeURL("update")).then((res) => {
            console.log(res);
            setUserIncomeInfo({
              ...userIncomeInfo,
              OAS_40_Years: residencyDuration,
              OAS_Years: residencyYear,
              hasStartedOAS: haveStartedOAS,
              hasStartedOASThisYear: haveStartedThisYear,
              oasMonth: monthStartedOAS,
              oasAmount: monthlyOASAmount,
              spouseOAS_40_Years: spouseResidencyDuration,
              spouseOAS_Years: spouseResidencyYear,
              spouseHasStartedOAS: spouseHaveStartedOAS,
              spouseHasStartedOASThisYear: spouseHaveStartedThisYear,
              spouseOASMonth: spouseMonthStartedOAS,
              spouseOASAmount: spouseMonthlyOASAmount,
            });
          });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const checkMandatoryFields = () => {
    let newErrors = {};
    let newSpouseErrors = {};
    // Individual checks
    if (
      (!olderThan65 || haveStartedOAS === "No") &&
      (!residencyDuration || residencyDuration === "")
    ) {
      newErrors.residencyDuration = "Years lived in Canada field required";
    }
    if (residencyDuration === "No" && !residencyYear) {
      newErrors.residencyYear = "Please select number of years";
    }
    if (olderThan65) {
      if (!haveStartedOAS) {
        newErrors.haveStartedOAS = "Status of OAS start is required";
      }
      if (
        haveStartedOAS === "Yes" &&
        (!haveStartedThisYear || !monthlyOASAmount)
      ) {
        newErrors.haveStartedThisYear = "Year of OAS start is required";
        newErrors.monthlyOASAmount = "OAS salary is required";
      }if(haveStartedOAS === "Yes" && monthlyOASAmount > 691) {
        newErrors.monthlyOASAmount = "OAS salary cannot be more than $691";
      }
      if (haveStartedThisYear === "Yes" && !monthStartedOAS) {
        newErrors.monthStartedOAS = "Month of OAS start is required";
      }
    }

    // Spouse checks
    if (spouseStatus) {
      if (
        (!spouseOlderThan65 || spouseHaveStartedOAS === "No") &&
        (!spouseResidencyDuration || spouseResidencyDuration === "")
      ) {
        newSpouseErrors.residencyDuration =
          "Years lived in Canada field required";
      }
      if (spouseResidencyDuration === "No" && !spouseResidencyYear) {
        newSpouseErrors.residencyYear = "Please select number of years";
      }
      if (spouseOlderThan65) {
        if (!spouseHaveStartedOAS) {
          newSpouseErrors.haveStartedOAS = "Status of OAS start is required";
        }
        if (
          spouseHaveStartedOAS === "Yes" &&
          (!spouseHaveStartedThisYear || !spouseMonthlyOASAmount)
        ) {
          newSpouseErrors.haveStartedThisYear = "Year of OAS start is required";
          newSpouseErrors.monthlyOASAmount = "OAS salary is required";
        }if(spouseHaveStartedOAS === "Yes" && spouseMonthlyOASAmount > 691) {
          newSpouseErrors.monthlyOASAmount = "OAS salary cannot be more than $691";
        }
        if (spouseHaveStartedThisYear === "Yes" && !spouseMonthStartedOAS) {
          newSpouseErrors.monthStartedOAS = "Month of OAS start is required";
        }
      }
    }

    setSpouseErrors(newSpouseErrors);
    setErrors(newErrors);
    return (
      Object.keys(newErrors).length === 0 &&
      Object.keys(newSpouseErrors).length === 0
    );
  };

  return (
    <Flex
      display="flex"
      flexWrap="wrap" // Enable wrapping
      justifyContent="flex-start"
      alignItems="start"
      width={"100%"}
    >
      {!isMobile && (
        <VStack alignItems={"flex-start"} width={"100%"} mt={-2}>
          <GlobalBodyPage
            Heading={"Old Age Security (OAS)"}
            mainframeContent={
              <>
                {!olderThan65 && (
                  <VStack spacing={4} alignItems={"flex-start"} width={"100%"}>
                    <Flex justify="space-between" w="100%">
                      <Heading
                        height={"27px"}
                        alignSelf={"stretch"}
                        color={"#00466D"}
                        fontFamily={"Nunito"}
                        fontSize={"20px"}
                        fontStyle={"normal"}
                        fontWeight={"700"}
                        lineHeight={"normal"}
                      >
                        {firstName} {lastName}
                      </Heading>
                    </Flex>
                    <DropDownButton
                      Heading={
                        "Will you have lived in Canada for at least 40 years between age 18 and 65?"
                      }
                      placeholder={"Select"}
                      dropDownItems={[
                        { key: "Yes", value: "Yes" },
                        { key: "No", value: "No" },
                      ]}
                      value={residencyDuration}
                      onChange={(key) => {
                        setResidencyDuration(key);
                        setChangedData(true);
                      }}
                      customWidth={"100%"}
                      error={errors.residencyDuration}
                      tooltipContent={TooltipContent.startedOASPayments}
                    />
                    {residencyDuration === "No" && (
                      <DropDownButton
                        Heading={
                          "How many years will you have lived in Canada between age 18 and 65?"
                        }
                        placeholder={"Select"}
                        dropDownItems={selectResidencyYear()}
                        value={residencyYear}
                        onChange={(key) => {
                          setResidencyYear(key);
                          setChangedData(true);
                        }}
                        customWidth={"100%"}
                        error={errors.residencyYear}
                        tooltipContent={TooltipContent.yearsLivedCanadaOAS}
                      />
                    )}
                  </VStack>
                )}
                {olderThan65 && (
                  <VStack spacing={4} alignItems={"flex-start"} width={"100%"}>
                    <Flex justify="space-between" w="100%">
                      <Heading
                        height={"27px"}
                        alignSelf={"stretch"}
                        color={"#00466D"}
                        fontFamily={"Nunito"}
                        fontSize={"20px"}
                        fontStyle={"normal"}
                        fontWeight={"700"}
                        lineHeight={"normal"}
                      >
                        {firstName} {lastName}
                      </Heading>
                    </Flex>
                    <DropDownButton
                      Heading={
                        "Have you started receiving monthly OAS payments?"
                      }
                      placeholder={"Select"}
                      dropDownItems={[
                        { key: "Yes", value: "Yes" },
                        { key: "No", value: "No" },
                      ]}
                      value={haveStartedOAS}
                      onChange={(key) => {
                        setHaveStartedOAS(key);
                        setChangedData(true);
                      }}
                      customWidth={"100%"}
                      error={errors.haveStartedOAS}
                      tooltipContent={TooltipContent.startedOASPayments}
                    />
                    {haveStartedOAS === "Yes" && (
                      <StartedOAS
                        haveStartedThisYear={haveStartedThisYear}
                        setHaveStartedThisYear={setHaveStartedThisYear}
                        monthStartedOAS={monthStartedOAS}
                        setMonthStartedOAS={setMonthStartedOAS}
                        monthlyOASAmount={monthlyOASAmount}
                        setMonthlyOASAmount={setMonthlyOASAmount}
                        errors={errors}
                        moneyToNumber={moneyToNumber}
                        TooltipContent={TooltipContent}
                        setChangedData={setChangedData}
                      />
                    )}
                    {haveStartedOAS === "No" && (
                      <DropDownButton
                        Heading={
                          "Will you have lived in Canada for at least 40 years between age 18 and 65?"
                        }
                        placeholder={"Select"}
                        dropDownItems={[
                          { key: "Yes", value: "Yes" },
                          { key: "No", value: "No" },
                        ]}
                        value={residencyDuration}
                        onChange={(key) => {
                          setResidencyDuration(key);
                          setChangedData(true);
                        }}
                        customWidth={"100%"}
                        error={errors.residencyDuration}
                        tooltipContent={TooltipContent.startedOASPayments}
                      />
                    )}
                    {residencyDuration === "No" && haveStartedOAS === "No" && (
                      <DropDownButton
                        Heading={
                          "How many years will you have lived in Canada between age 18 and 65?"
                        }
                        placeholder={"Select"}
                        dropDownItems={selectResidencyYear()}
                        value={residencyYear}
                        onChange={(key) => {
                          setResidencyYear(key);
                          setChangedData(true);
                        }}
                        customWidth={"100%"}
                        error={errors.residencyYear}
                        tooltipContent={TooltipContent.yearsLivedCanadaOAS}
                      />
                    )}
                  </VStack>
                )}
              </>
            }
            videoName={"OAS Page"}
            video={"Old_Age_Security.mp4"}
          />

          {spouseStatus && (
            <GlobalBodyPage
              mainframeContent={
                <>
                  {!spouseOlderThan65 && (
                    <VStack
                      spacing={4}
                      alignItems={"flex-start"}
                      width={"100%"}
                    >
                      <Flex justify="space-between" w="100%">
                        <Heading
                          height={"27px"}
                          alignSelf={"stretch"}
                          color={"#00466D"}
                          fontFamily={"Nunito"}
                          fontSize={"20px"}
                          fontStyle={"normal"}
                          fontWeight={"700"}
                          lineHeight={"normal"}
                        >
                          {spouseFirstName} {spouseLastName}
                        </Heading>
                      </Flex>
                      <DropDownButton
                        Heading={
                          "Will you have lived in Canada for at least 40 years between age 18 and 65?"
                        }
                        placeholder={"Select"}
                        dropDownItems={[
                          { key: "Yes", value: "Yes" },
                          { key: "No", value: "No" },
                        ]}
                        value={spouseResidencyDuration}
                        onChange={(key) => {
                          setSpouseResidencyDuration(key);
                          setChangedData(true);
                        }}
                        customWidth={"100%"}
                        tooltipContent={TooltipContent.startedOASPayments}
                      />
                      {spouseResidencyDuration === "No" && (
                        <DropDownButton
                          Heading={
                            "How many years will you have lived in Canada between age 18 and 65?"
                          }
                          placeholder={"Select"}
                          dropDownItems={selectResidencyYear()}
                          value={spouseResidencyYear}
                          onChange={(key) => {
                            setSpouseResidencyYear(key);
                            setChangedData(true);
                          }}
                          customWidth={"100%"}
                          error={spouseErrors.residencyYear}
                          tooltipContent={TooltipContent.yearsLivedCanadaOAS}
                        />
                      )}
                    </VStack>
                  )}
                  {spouseOlderThan65 && (
                    <VStack
                      spacing={4}
                      alignItems={"flex-start"}
                      width={"100%"}
                    >
                      <Flex justify="space-between" w="100%">
                        <Heading
                          height={"27px"}
                          alignSelf={"stretch"}
                          color={"#00466D"}
                          fontFamily={"Nunito"}
                          fontSize={"20px"}
                          fontStyle={"normal"}
                          fontWeight={"700"}
                          lineHeight={"normal"}
                        >
                          {spouseFirstName} {spouseLastName}
                        </Heading>
                      </Flex>
                      <DropDownButton
                        Heading={
                          "Have you started receiving monthly OAS payments?"
                        }
                        placeholder={"Select"}
                        dropDownItems={[
                          { key: "Yes", value: "Yes" },
                          { key: "No", value: "No" },
                        ]}
                        value={spouseHaveStartedOAS}
                        onChange={(key) => {
                          setSpouseHaveStartedOAS(key);
                          setChangedData(true);
                        }}
                        customWidth={"100%"}
                        error={spouseErrors.haveStartedOAS}
                        tooltipContent={TooltipContent.startedOASPayments}
                      />
                      {spouseHaveStartedOAS === "Yes" && (
                        <StartedOAS
                          haveStartedThisYear={spouseHaveStartedThisYear}
                          setHaveStartedThisYear={setSpouseHaveStartedThisYear}
                          monthStartedOAS={spouseMonthStartedOAS}
                          setMonthStartedOAS={setSpouseMonthStartedOAS}
                          monthlyOASAmount={spouseMonthlyOASAmount}
                          setMonthlyOASAmount={setSpouseMonthlyOASAmount}
                          errors={spouseErrors}
                          moneyToNumber={moneyToNumber}
                          TooltipContent={TooltipContent}
                          setChangedData={setChangedData}
                        />
                      )}
                      {spouseHaveStartedOAS === "No" && (
                        <DropDownButton
                          Heading={
                            "Will you have lived in Canada for at least 40 years between age 18 and 65?"
                          }
                          placeholder={"Select"}
                          dropDownItems={[
                            { key: "Yes", value: "Yes" },
                            { key: "No", value: "No" },
                          ]}
                          value={spouseResidencyDuration}
                          onChange={(key) => {
                            setSpouseResidencyDuration(key);
                            setChangedData(true);
                          }}
                          customWidth={"100%"}
                          error={spouseErrors.residencyDuration}
                          tooltipContent={TooltipContent.startedOASPayments}
                        />
                      )}
                      {spouseResidencyDuration === "No" &&
                        spouseHaveStartedOAS === "No" && (
                          <DropDownButton
                            Heading={
                              "How many years will you have lived in Canada between age 18 and 65?"
                            }
                            placeholder={"Select"}
                            dropDownItems={selectResidencyYear()}
                            value={spouseResidencyYear}
                            onChange={(key) => {
                              setChangedData(true);
                              setSpouseResidencyYear(key);
                            }}
                            customWidth={"100%"}
                            error={spouseErrors.residencyYear}
                            tooltipContent={TooltipContent.yearsLivedCanadaOAS}
                          />
                        )}
                    </VStack>
                  )}
                </>
              }
            />
          )}
        </VStack>
      )}

      {isMobile && (
        <VStack spacing={4} alignItems={"flex-start"} width={"100%"}>
          <GlobalBodyPage
            Heading={"Old Age Security (OAS)"}
            mainframeContent={
              <>
                {!olderThan65 && (
                  <VStack spacing={4} alignItems={"flex-start"} width={"100%"}>
                    <Flex justify="space-between" w="100%">
                      <Heading
                        height={"27px"}
                        alignSelf={"stretch"}
                        color={"#00466D"}
                        fontFamily={"Nunito"}
                        fontSize={"20px"}
                        fontStyle={"normal"}
                        fontWeight={"700"}
                        lineHeight={"normal"}
                      >
                        {firstName} {lastName}
                      </Heading>
                    </Flex>
                    <DropDownButton
                      Heading={
                        "Will you have lived in Canada for at least 40 years between age 18 and 65?"
                      }
                      placeholder={"Select"}
                      dropDownItems={[
                        { key: "Yes", value: "Yes" },
                        { key: "No", value: "No" },
                      ]}
                      value={residencyDuration}
                      onChange={(key) => {
                        setChangedData(true);
                        setResidencyDuration(key);
                      }}
                      customWidth={"100%"}
                      MenuCustomWidth={"100%"}
                      tooltipContent={TooltipContent.startedOASPayments}
                    />
                    {residencyDuration === "No" && (
                      <DropDownButton
                        Heading={
                          "How many years will you have lived in Canada between age 18 and 65?"
                        }
                        placeholder={"Select"}
                        dropDownItems={selectResidencyYear()}
                        value={residencyYear}
                        onChange={(value) => {
                          setChangedData(true);
                          setResidencyYear(value);
                        }}
                        customWidth={"100%"}
                        MenuCustomWidth={"100%"}
                        tooltipContent={TooltipContent.yearsLivedCanadaOAS}
                      />
                    )}
                  </VStack>
                )}
                {olderThan65 && (
                  <VStack spacing={4} alignItems={"flex-start"} width={"100%"}>
                    <Flex justify="space-between" w="100%">
                      <Heading
                        height={"27px"}
                        alignSelf={"stretch"}
                        color={"#00466D"}
                        fontFamily={"Nunito"}
                        fontSize={"20px"}
                        fontStyle={"normal"}
                        fontWeight={"700"}
                        lineHeight={"normal"}
                      >
                        {firstName} {lastName}
                      </Heading>
                    </Flex>
                    <DropDownButton
                      Heading={
                        "Have you started receiving monthly OAS payments?"
                      }
                      placeholder={"Select"}
                      dropDownItems={[
                        { key: "Yes", value: "Yes" },
                        { key: "No", value: "No" },
                      ]}
                      value={haveStartedOAS}
                      onChange={(key) => {
                        setChangedData(true);
                        setHaveStartedOAS(key);
                      }}
                      customWidth={"100%"}
                      error={errors.haveStartedOAS}
                      tooltipContent={TooltipContent.startedOASPayments}
                    />
                    {haveStartedOAS === "Yes" && (
                      <StartedOAS
                        haveStartedThisYear={haveStartedThisYear}
                        setHaveStartedThisYear={setHaveStartedThisYear}
                        monthStartedOAS={monthStartedOAS}
                        setMonthStartedOAS={setMonthStartedOAS}
                        monthlyOASAmount={monthlyOASAmount}
                        setMonthlyOASAmount={setMonthlyOASAmount}
                        errors={errors}
                        moneyToNumber={moneyToNumber}
                        TooltipContent={TooltipContent}
                        setChangedData={setChangedData}
                      />
                    )}
                    {haveStartedOAS === "No" && (
                      <DropDownButton
                        Heading={
                          "Will you have lived in Canada for at least 40 years between age 18 and 65?"
                        }
                        placeholder={"Select"}
                        dropDownItems={[
                          { key: "Yes", value: "Yes" },
                          { key: "No", value: "No" },
                        ]}
                        value={residencyDuration}
                        onChange={(key) => {
                          setChangedData(true);
                          setResidencyDuration(key);
                        }}
                        customWidth={"100%"}
                        MenuCustomWidth={"100%"}
                        tooltipContent={TooltipContent.startedOASPayments}
                      />
                    )}
                    {residencyDuration === "No" && haveStartedOAS === "No" && (
                      <DropDownButton
                        Heading={
                          "How many years will you have lived in Canada between age 18 and 65?"
                        }
                        placeholder={"Select"}
                        dropDownItems={selectResidencyYear()}
                        value={residencyYear}
                        onChange={(value) => {
                          setChangedData(true);
                          setResidencyYear(value);
                        }}
                        customWidth={"100%"}
                        MenuCustomWidth={"100%"}
                        tooltipContent={TooltipContent.yearsLivedCanadaOAS}
                      />
                    )}
                  </VStack>
                )}
              </>
            }
            videoName={"OAS Page"}
            video={"Old_Age_Security.mp4"}
          />

          {spouseStatus && (
            <GlobalBodyPage
              mainframeContent={
                <>
                  {!spouseOlderThan65 && (
                    <VStack
                      spacing={4}
                      alignItems={"flex-start"}
                      width={"100%"}
                    >
                      <Flex justify="space-between" w="100%">
                        <Heading
                          height={"27px"}
                          alignSelf={"stretch"}
                          color={"#00466D"}
                          fontFamily={"Nunito"}
                          fontSize={"20px"}
                          fontStyle={"normal"}
                          fontWeight={"700"}
                          lineHeight={"normal"}
                        >
                          {spouseFirstName} {spouseLastName}
                        </Heading>
                      </Flex>
                      <DropDownButton
                        Heading={
                          "Will you have lived in Canada for at least 40 years between age 18 and 65?"
                        }
                        placeholder={"Select"}
                        dropDownItems={[
                          { key: "Yes", value: "Yes" },
                          { key: "No", value: "No" },
                        ]}
                        value={spouseResidencyDuration}
                        onChange={(key) => {
                          setChangedData(true);
                          setSpouseResidencyDuration(key);
                        }}
                        customWidth={"100%"}
                        MenuCustomWidth={"100%"}
                        tooltipContent={TooltipContent.startedOASPayments}
                      />
                      {spouseResidencyDuration === "No" && (
                        <DropDownButton
                          Heading={
                            "How many years will you have lived in Canada between age 18 and 65?"
                          }
                          placeholder={"Select"}
                          dropDownItems={selectResidencyYear()}
                          value={spouseResidencyYear}
                          onChange={(key) => {
                            setSpouseResidencyYear(key);
                            setChangedData(true);
                          }}
                          error={spouseErrors.residencyYear}
                          customWidth={"100%"}
                          MenuCustomWidth={"100%"}
                          tooltipContent={TooltipContent.yearsLivedCanadaOAS}
                        />
                      )}
                    </VStack>
                  )}
                  {spouseOlderThan65 && (
                    <VStack
                      spacing={4}
                      alignItems={"flex-start"}
                      width={"100%"}
                    >
                      <Flex justify="space-between" w="100%">
                        <Heading
                          height={"27px"}
                          alignSelf={"stretch"}
                          color={"#00466D"}
                          fontFamily={"Nunito"}
                          fontSize={"20px"}
                          fontStyle={"normal"}
                          fontWeight={"700"}
                          lineHeight={"normal"}
                        >
                          {spouseFirstName} {spouseLastName}
                        </Heading>
                      </Flex>
                      <DropDownButton
                        Heading={
                          "Have you started receiving monthly OAS payments?"
                        }
                        placeholder={"Select"}
                        dropDownItems={[
                          { key: "Yes", value: "Yes" },
                          { key: "No", value: "No" },
                        ]}
                        value={spouseHaveStartedOAS}
                        onChange={(key) => {
                          setChangedData(true);
                          setSpouseHaveStartedOAS(key);
                        }}
                        customWidth={"100%"}
                        error={spouseErrors.haveStartedOAS}
                        tooltipContent={TooltipContent.startedOASPayments}
                      />
                      {spouseHaveStartedOAS === "Yes" && (
                        <StartedOAS
                          haveStartedThisYear={spouseHaveStartedThisYear}
                          setHaveStartedThisYear={setSpouseHaveStartedThisYear}
                          monthStartedOAS={spouseMonthStartedOAS}
                          setMonthStartedOAS={setSpouseMonthStartedOAS}
                          monthlyOASAmount={spouseMonthlyOASAmount}
                          setMonthlyOASAmount={setSpouseMonthlyOASAmount}
                          errors={spouseErrors}
                          moneyToNumber={moneyToNumber}
                          TooltipContent={TooltipContent}
                          setChangedData={setChangedData}
                        />
                      )}
                      {spouseHaveStartedOAS === "No" && (
                        <DropDownButton
                          Heading={
                            "Will you have lived in Canada for at least 40 years between age 18 and 65?"
                          }
                          placeholder={"Select"}
                          dropDownItems={[
                            { key: "Yes", value: "Yes" },
                            { key: "No", value: "No" },
                          ]}
                          value={spouseResidencyDuration}
                          onChange={(key) => {
                            setChangedData(true);
                            setSpouseResidencyDuration(key);
                          }}
                          customWidth={"100%"}
                          MenuCustomWidth={"100%"}
                          tooltipContent={TooltipContent.startedOASPayments}
                        />
                      )}
                      {spouseResidencyDuration === "No" &&
                        spouseHaveStartedOAS === "No" && (
                          <DropDownButton
                            Heading={
                              "How many years will you have lived in Canada between age 18 and 65?"
                            }
                            placeholder={"Select"}
                            dropDownItems={selectResidencyYear()}
                            value={spouseResidencyYear}
                            onChange={(key) => {
                              setSpouseResidencyYear(key);
                              setChangedData(true);
                            }}
                            customWidth={"100%"}
                            error={spouseErrors.residencyYear}
                            MenuCustomWidth={"100%"}
                            tooltipContent={TooltipContent.yearsLivedCanadaOAS}
                          />
                        )}
                    </VStack>
                  )}
                </>
              }
            />
          )}
        </VStack>
      )}
    </Flex>
  );
});

function StartedOAS({
  haveStartedThisYear,
  setHaveStartedThisYear,
  monthStartedOAS,
  setMonthStartedOAS,
  monthlyOASAmount,
  setMonthlyOASAmount,
  errors,
  moneyToNumber,
  TooltipContent,
  setChangedData,
}) {
  const monthDropDown = [
    { key: "January", value: "January" },
    { key: "February", value: "February" },
    { key: "March", value: "March" },
    { key: "April", value: "April" },
    { key: "May", value: "May" },
    { key: "June", value: "June" },
    { key: "July", value: "July" },
    { key: "August", value: "August" },
    { key: "September", value: "September" },
    { key: "October", value: "October" },
    { key: "November", value: "November" },
    { key: "December", value: "December" },
  ];
    // Get the current month (0-11, where 0 is January and 11 is December)
const currentMonth = new Date().getMonth();

// Filter the monthDropDown to include only the months up to the current month
const filteredMonthDropDown = monthDropDown.slice(0, currentMonth + 1);
  return (
    <Flex w="100%" flexDirection={"column"} gap={"10px"}>
      <Flex justify="space-between" w="full" alignItems="flex-end">
        <DropDownButton
          Heading={"Did you start taking your OAS this year?"}
          placeholder={"Select"}
          dropDownItems={[
            { key: "Yes", value: "Yes" },
            { key: "No", value: "No" },
          ]}
          customWidth={"100%"}
          value={haveStartedThisYear}
          onChange={(key) => {
            setChangedData(true);
            setHaveStartedThisYear(key);
          }}
          error={errors.haveStartedThisYear}
          tooltipContent={TooltipContent.startedOASThisYear}
        />
      </Flex>
      {haveStartedThisYear === "Yes" && (
        <Flex justify="space-between" w="full" alignItems="flex-end">
          <DropDownButton
            Heading={"What month did you start receiving OAS?"}
            placeholder={"Select"}
            dropDownItems={filteredMonthDropDown}
            customWidth={"100%"}
            value={monthStartedOAS}
            onChange={(key) => {
              setChangedData(true);
              setMonthStartedOAS(key);
            }}
            error={errors.monthStartedOAS}
            tooltipContent={TooltipContent.startedOASMonth}
          />
        </Flex>
      )}
      {haveStartedThisYear && (
        <NormalInputButton
          Heading={"Enter your monthly OAS payment."}
          placeholder={"$"}
          value={monthlyOASAmount}
          onChange={(e) => {
            const newValue = moneyToNumber(e.target.value);
            if (newValue <= 691) {
              setMonthlyOASAmount(newValue);
              errors.monthlyOASAmount = "";
            } else if (newValue > 691) {
              errors.monthlyOASAmount =
                "The maximum monthly CPP payment is $691";
              setMonthlyOASAmount(691);
            }
            setChangedData(true);
          }}
          customWidth={"100%"}
          useThousandSeparator={true}
          error={errors.monthlyOASAmount}
          tooltipContent={TooltipContent.monthlyOASPayments}
        />
      )}
    </Flex>
  );
}

export default OasPage;
