import { Box, Flex, Heading, Text, VStack } from "@chakra-ui/layout";
import { useMediaQuery } from "@chakra-ui/media-query";
import { useAuth0 } from "@auth0/auth0-react";
import axios from "axios";
import React, {
  forwardRef,
  useContext,
  useEffect,
  useImperativeHandle,
  useState,
} from "react";
import { useStore } from "../../store";
import { SpouseContext } from "../SpouseContext";
import DropDownButton from "../InputFields/dropDownButton";
import NormalInputButton from "../InputFields/normalInputButton";
import GlobalBodyPage from "../GlobalContainer/BodyPage";
import TooltipContent from "../TooltipContent";

const NonRegistered = forwardRef((props, ref) => {
  const { setChangedData } = props;
  useImperativeHandle(ref, () => ({
    submitHandler,
    checkMandatoryFields,
  }));

  const { getAccessTokenSilently } = useAuth0();

  const axiosInstance = axios.create();
  async function getToken() {
    // Replace this with your Auth0 token retrieval logic
    const token = await getAccessTokenSilently({
      audience:
        "https://os2lsiwff43wfpb5ggyawt6qri0mgcro.lambda-url.us-east-2.on.aws/",
      scope: "write:optimization",
    });
    return token;
  }

  // Add a request interceptor
  axiosInstance.interceptors.request.use(
    async (config) => {
      const token = await getToken();
      config.headers.Authorization = `Bearer ${token}`;
      return config;
    },
    (error) => {
      // Do something with request error
      return Promise.reject(error);
    }
  );
  const [isLargerThan768] = useMediaQuery("(min-width: 500px)");
  const isMobile = !isLargerThan768;

  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [spouseName, setSpouseName] = useState("");
  const [spouseLastName, setSpouseLastName] = useState("");
  const { spouseStatus, setSpouseStatus } = useContext(SpouseContext);

  const userNonRegInvestmentsInfo = useStore(
    (store) => store.userNonRegInvestmentsInfo
  );
  const setUserNonRegInvestmentsInfo = useStore(
    (store) => store.setUserNonRegInvestmentsInfo
  );
  const userInfo = useStore((store) => store.userInfo);

  const [isEditMode, setIsEditMode] = useState(false);

  const userOptiData = useStore((store) => store.userOptiData);
  const [newUser, setNewUser] = useState();

  useEffect(() => {
    if (userOptiData?.primaryUserData?.expenses?.length > 1) {
      setNewUser(false);
    } else if (userOptiData?.primaryUserData?.expenses?.length === undefined) {
      setNewUser(true);
      setIsEditMode(true);
    }
  }, [userOptiData]);

  const [errors, setErrors] = useState({});
  const [spouseErrors, setSpouseErrors] = useState({});

  useEffect(() => {
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 100);
  }, [errors, spouseErrors]);

  // Function to toggle edit mode
  const toggleEditMode = () => {
    setIsEditMode(!isEditMode);
  };

  const { user, logout } = useAuth0();

  // Spouse 1
  const [hasNonReg, setHasNonReg] = useState("");
  const [ACB, setACB] = useState("");
  const [totalNonReg, setTotalNonReg] = useState("");
  const [rateOfReturn, setRateOfReturn] = useState("6");
  const [incomeBearing, setIncomeBearing] = useState("");
  const [eligibleDiv, setEligibleDiv] = useState("");
  const [nonEligibleDiv, setNonEligibleDiv] = useState("");
  const [foreignDiv, setForeignDiv] = useState("");
  const [nonRegContThisYear, setNonRegContThisYear] = useState();

  // Spouse 2
  const [spouseHasNonReg, setSpouseHasNonReg] = useState();
  const [spouseACB, setSpouseACB] = useState("");
  const [spouseTotalNonReg, setSpouseTotalNonReg] = useState("");
  const [spouseRateOfReturn, setSpouseRateOfReturn] = useState("6");
  const [spouseIncomeBearing, setSpouseIncomeBearing] = useState("");
  const [spouseEligibleDiv, setSpouseEligibleDiv] = useState("");
  const [spouseNonEligibleDiv, setSpouseNonEligibleDiv] = useState("");
  const [spouseForeignDiv, setSpouseForeignDiv] = useState("");
  const [spouseNonRegContThisYear, setSpouseNonRegContThisYear] = useState();

  const currencyFormat = (money) => {
    if (money == "") {
      return "";
    } else {
      const intTotal = Math.round(Math.round(money * 100) / 100);
      const currencyCad = new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "USD",
        minimumFractionDigits: 0,
      }).format(intTotal);
      return currencyCad;
    }
  };

  useEffect(() => {
    if (userNonRegInvestmentsInfo !== null) {
      setTotalNonReg(userNonRegInvestmentsInfo?.totalValue);
      setHasNonReg(userNonRegInvestmentsInfo?.Do_You_Non_Reg);
      setACB(userNonRegInvestmentsInfo?.ACB);
      setRateOfReturn(userNonRegInvestmentsInfo?.Non_Reg_ROR);
      setIncomeBearing(userNonRegInvestmentsInfo?.Non_Reg_Income_Bearing);
      setEligibleDiv(userNonRegInvestmentsInfo?.Eligible_Div);
      setNonEligibleDiv(userNonRegInvestmentsInfo?.Non_Eligible_Div);
      setForeignDiv(userNonRegInvestmentsInfo?.Foreign_Div);
      setNonRegContThisYear(userNonRegInvestmentsInfo?.nonRegContThisYear);

      setSpouseTotalNonReg(userNonRegInvestmentsInfo?.spouseTotalValue);
      setSpouseHasNonReg(userNonRegInvestmentsInfo?.spouseDo_You_Non_Reg);
      setSpouseACB(userNonRegInvestmentsInfo?.spouseACB);
      setSpouseRateOfReturn(userNonRegInvestmentsInfo?.spouseNon_Reg_ROR);
      setSpouseIncomeBearing(
        userNonRegInvestmentsInfo?.spouseNon_Reg_Income_Bearing
      );
      setSpouseEligibleDiv(userNonRegInvestmentsInfo?.Spouse_Eligible_Div);
      setSpouseNonEligibleDiv(
        userNonRegInvestmentsInfo?.Spouse_Non_Eligible_Div
      );
      setSpouseForeignDiv(userNonRegInvestmentsInfo?.Spouse_Foreign_Div);
      setSpouseNonRegContThisYear(
        userNonRegInvestmentsInfo?.spouseNonRegContThisYear
      );
    }
    if (userInfo !== null) {
      setSpouseName(userInfo?.spouseFirstName);
      setSpouseLastName(userInfo?.spouseLastName);
      setFirstName(userInfo?.FirstName);
      setLastName(userInfo?.LastName);
    }
  }, []);

  const submitHandler = (e) => {
    if (e) {
      e.preventDefault();
    }
    setIsEditMode(!isEditMode);
    let TotalNonReg = totalNonReg;
    let acb = ACB;
    let EligibleDiv = eligibleDiv;
    let NonEligibleDiv = nonEligibleDiv;
    let ForeignDiv = foreignDiv;
    let contThisYear = nonRegContThisYear;

    let SpouseTotalNonReg = spouseTotalNonReg;
    let SpouseAcb = spouseACB;
    let SpouseEligibleDiv = spouseEligibleDiv;
    let SpouseNonEligibleDiv = spouseNonEligibleDiv;
    let SpouseForeignDiv = spouseForeignDiv;
    let spouseContThisYear = spouseNonRegContThisYear;

    if (hasNonReg == "No") {
      TotalNonReg = 0;
      acb = 0;
      EligibleDiv = 0;
      NonEligibleDiv = 0;
      ForeignDiv = 0;
      contThisYear = 0;
      setTotalNonReg(0);
      setACB(0);
      setEligibleDiv(0);
      setNonEligibleDiv(0);
      setForeignDiv(0);
    }
    if (incomeBearing == "No") {
      EligibleDiv = 0;
      NonEligibleDiv = 0;
      ForeignDiv = 0;
      setEligibleDiv(0);
      setNonEligibleDiv(0);
      setForeignDiv(0);
    }

    if (spouseHasNonReg == "No") {
      SpouseTotalNonReg = 0;
      SpouseAcb = 0;
      SpouseEligibleDiv = 0;
      SpouseNonEligibleDiv = 0;
      SpouseForeignDiv = 0;
      spouseContThisYear = 0;
      setSpouseTotalNonReg(0);
      setSpouseACB(0);
      setSpouseEligibleDiv(0);
      setSpouseNonEligibleDiv(0);
      setSpouseForeignDiv(0);
    }
    if (spouseIncomeBearing == "No") {
      SpouseEligibleDiv = 0;
      SpouseNonEligibleDiv = 0;
      SpouseForeignDiv = 0;
      setSpouseEligibleDiv(0);
      setSpouseNonEligibleDiv(0);
      setSpouseForeignDiv(0);
    }

    // console.log('Non Reg: ', totalNonReg, hasNonReg, ACB, rateOfReturn, incomeBearing, eligibleDiv, nonEligibleDiv, foreignDiv);
    const nonRegURL = (action) =>
      `${
        process.env.REACT_APP_NODE_API_GATEWAY
      }/CompassLambdaApi/NON_REGISTERED_INVESTMENTS/${action}?NonRegisteredInvestmentID=${user.sub.replace(
        "|",
        "%7c"
      )}&totalValue=${TotalNonReg}&Do_You_Non_Reg=${hasNonReg}&ACB=${acb}&Non_Reg_ROR=${rateOfReturn}&Non_Reg_Income_Bearing=${incomeBearing}&nonEligibleDiv=${NonEligibleDiv}&eligibleDiv=${EligibleDiv}&foreignDiv=${ForeignDiv}&spouseTotalValue=${SpouseTotalNonReg}&spouseDo_You_Non_Reg=${spouseHasNonReg}&spouseACB=${SpouseAcb}&spouseNon_Reg_ROR=${spouseRateOfReturn}&spouseNon_Reg_Income_Bearing=${spouseIncomeBearing}&spouseNonEligibleDiv=${SpouseNonEligibleDiv}&spouseEligibleDiv=${SpouseEligibleDiv}&spouseForeignDiv=${SpouseForeignDiv}&nonRegContThisYear=${contThisYear}&spouseNonRegContThisYear=${spouseContThisYear}`;
    axiosInstance
      .get(nonRegURL("add"))
      .then((res) => {
        setUserNonRegInvestmentsInfo({
          ...userNonRegInvestmentsInfo,
          totalValue: TotalNonReg,
          Do_You_Non_Reg: hasNonReg,
          ACB: acb,
          Non_Reg_ROR: rateOfReturn,
          Non_Reg_Income_Bearing: incomeBearing,
          Eligible_Div: EligibleDiv,
          Non_Eligible_Div: NonEligibleDiv,
          Foreign_Div: ForeignDiv,
          spouseTotalValue: SpouseTotalNonReg,
          spouseDo_You_Non_Reg: spouseHasNonReg,
          spouseACB: SpouseAcb,
          spouseNon_Reg_ROR: spouseRateOfReturn,
          spouseNon_Reg_Income_Bearing: spouseIncomeBearing,
          Spouse_Eligible_Div: SpouseEligibleDiv,
          Spouse_Non_Eligible_Div: SpouseNonEligibleDiv,
          Spouse_Foreign_Div: SpouseForeignDiv,
          nonRegContThisYear: contThisYear,
          spouseNonRegContThisYear: spouseContThisYear,
        });
        if (res.data.code === "ER_DUP_ENTRY") {
          axiosInstance.get(nonRegURL("update")).then((res) => {
            console.log(res);
            setUserNonRegInvestmentsInfo({
              ...userNonRegInvestmentsInfo,
              totalValue: TotalNonReg,
              Do_You_Non_Reg: hasNonReg,
              ACB: acb,
              Non_Reg_ROR: rateOfReturn,
              Non_Reg_Income_Bearing: incomeBearing,
              Eligible_Div: EligibleDiv,
              Non_Eligible_Div: NonEligibleDiv,
              Foreign_Div: ForeignDiv,
              spouseTotalValue: SpouseTotalNonReg,
              spouseDo_You_Non_Reg: spouseHasNonReg,
              spouseACB: SpouseAcb,
              spouseNon_Reg_ROR: spouseRateOfReturn,
              spouseNon_Reg_Income_Bearing: spouseIncomeBearing,
              Spouse_Eligible_Div: SpouseEligibleDiv,
              Spouse_Non_Eligible_Div: SpouseNonEligibleDiv,
              Spouse_Foreign_Div: SpouseForeignDiv,
              nonRegContThisYear: contThisYear,
              spouseNonRegContThisYear: spouseContThisYear,
            });
          });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const moneyToNumber = (value) => {
    let numValue = value.replace(/[$,\s]/g, "");
    numValue = Number(numValue);
    return numValue;
  };

  function safeAdd(...values) {
    return values.reduce((sum, val) => sum + (Number(val) || 0), 0);
  }

  const checkMandatoryFields = () => {
    let newErrors = {};
    let newSpouseErrors = {};

    // Individual checks
    if (!hasNonReg || hasNonReg === "") {
      newErrors.hasNonReg = "Non-Registered status is required";
    }
    if (hasNonReg === "Yes") {
      if (!totalNonReg)
        newErrors.totalNonReg = "Total Non-Registered value is required";
      if (!ACB) newErrors.ACB = "ACB is required";
      if (!rateOfReturn || rateOfReturn > 20)
        newErrors.rateOfReturn =
          "Rate of return is required and must be greater than 0% and less than 20%"
      if (!incomeBearing)
        newErrors.incomeBearing = "Income bearing status is required";
    }

    if (
      incomeBearing === "Yes" &&
      (totalNonReg * (rateOfReturn / 100) <
        safeAdd(nonEligibleDiv, eligibleDiv, foreignDiv) || (!nonEligibleDiv && !eligibleDiv && !foreignDiv))
    ) {
      newErrors.dividendCheck =
        "Total dividends cannot be higher than account rate of return and must be greater than 0";
    }

    // Spouse checks
    if (spouseStatus) {
      if (!spouseHasNonReg || spouseHasNonReg === "") {
        newSpouseErrors.hasNonReg = "Non-Registered status is required";
      }
      if (spouseHasNonReg === "Yes") {
        if (!spouseTotalNonReg)
          newSpouseErrors.totalNonReg =
            "Total Non-Registered value is required";
        if (!spouseACB) newSpouseErrors.ACB = "ACB is required";
        if (!spouseRateOfReturn || spouseRateOfReturn > 20)
          newSpouseErrors.rateOfReturn =
            "Rate of return is required and must be greater than 0% and less than 20%"
        if (!spouseIncomeBearing)
          newSpouseErrors.incomeBearing = "Income bearing status is required";
      }

      if (
        spouseIncomeBearing === "Yes" &&
        (spouseTotalNonReg * (spouseRateOfReturn / 100) <
          safeAdd(spouseNonEligibleDiv, spouseEligibleDiv, spouseForeignDiv) || (!spouseNonEligibleDiv && !spouseEligibleDiv && !spouseForeignDiv))
      ) {
        newSpouseErrors.dividendCheck =
          "Total dividends cannot be higher than account rate of return and must be greater than 0";
      }
    }

    setSpouseErrors(newSpouseErrors);
    setErrors(newErrors);
    return (
      Object.keys(newErrors).length === 0 &&
      Object.keys(newSpouseErrors).length === 0
    ); // Return true if no errors
  };

  return (
    <Flex
      display="flex"
      flexWrap="wrap" // Enable wrapping
      justifyContent="flex-start"
      alignItems="start"
      width={"100%"}
    >
      {!isMobile && (
        <VStack alignItems={"flex-start"} width={"100%"} mt={-2}>
          <GlobalBodyPage
            Heading="Non-Registered"
            mainframeContent={
              <>
                <Flex justify="space-between" w="100%">
                  <Heading
                    height={"27px"}
                    alignSelf={"stretch"}
                    color={"#00466D"}
                    fontFamily={"Nunito"}
                    fontSize={"20px"}
                    fontStyle={"normal"}
                    fontWeight={"700"}
                    lineHeight={"normal"}
                  >
                    {firstName} {lastName}
                  </Heading>
                </Flex>
                <Box
                  display={"flex"}
                  width={"100%"}
                  flexDirection={"row"}
                  paddingTop={"10px"}
                  alignItems={"flex-end"}
                  justifyContent={"space-between"}
                  gap={"10px"}
                  flexWrap={"wrap"}
                >
                  <DropDownButton
                    Heading={"Do you have any non-registered investments?"}
                    placeholder={"Select"}
                    dropDownItems={[
                      { key: "Yes", value: "Yes" },
                      { key: "No", value: "No" },
                    ]}
                    value={hasNonReg}
                    onChange={(key) => {
                      setChangedData(true);
                      setHasNonReg(key);
                    }}
                    customWidth={"100%"}
                    error={errors.hasNonReg}
                    tooltipContent={TooltipContent.nonregYesNo}
                  />
                  {hasNonReg === "Yes" && (
                    <HaveNonRegistered
                      spouseAnnualReturn={rateOfReturn}
                      setSpouseAnnualReturn={setRateOfReturn}
                      spouseBookValue={ACB}
                      setSpouseBookValue={setACB}
                      spouseCurrentValue={totalNonReg}
                      setSpouseCurrentValue={setTotalNonReg}
                      spouseIncomeBearing={incomeBearing}
                      setSpouseIncomeBearing={setIncomeBearing}
                      spouseEligibleDividendIncome={eligibleDiv}
                      setSpouseEligibleDividendIncome={setEligibleDiv}
                      spouseNonEligibleDividendIncome={nonEligibleDiv}
                      setSpouseNonEligibleDividendIncome={setNonEligibleDiv}
                      spouseForeignDividendIncome={foreignDiv}
                      setSpouseForeignDividendIncome={setForeignDiv}
                      contThisYear={nonRegContThisYear}
                      setContThisYear={setNonRegContThisYear}
                      moneyToNumber={moneyToNumber}
                      errors={errors}
                      setChangedData={setChangedData}
                    />
                  )}
                </Box>
              </>
            }
            videoName={"Non-Registered Investments Page"}
            video={"Non-Registered_Investments.mp4"}
          />
          {spouseStatus && (
            <GlobalBodyPage
              mainframeContent={
                <>
                  <Flex justify="space-between" w="100%">
                    <Heading
                      height={"27px"}
                      alignSelf={"stretch"}
                      color={"#00466D"}
                      fontFamily={"Nunito"}
                      fontSize={"20px"}
                      fontStyle={"normal"}
                      fontWeight={"700"}
                      lineHeight={"normal"}
                    >
                      {spouseName} {spouseLastName}
                    </Heading>
                  </Flex>
                  <Box
                    display={"flex"}
                    width={"100%"}
                    flexDirection={"row"}
                    paddingTop={"10px"}
                    alignItems={"flex-end"}
                    justifyContent={"space-between"}
                    gap={"10px"}
                    flexWrap={"wrap"}
                  >
                    <DropDownButton
                      Heading={"Do you have any non-registered investments?"}
                      placeholder={"Select"}
                      dropDownItems={[
                        { key: "Yes", value: "Yes" },
                        { key: "No", value: "No" },
                      ]}
                      value={spouseHasNonReg}
                      onChange={(key) => {
                        setChangedData(true);
                        setSpouseHasNonReg(key);
                      }}
                      customWidth={"100%"}
                      error={spouseErrors.hasNonReg}
                      tooltipContent={TooltipContent.nonregYesNo}
                    />
                    {spouseHasNonReg === "Yes" && (
                      <HaveNonRegistered
                        spouseAnnualReturn={spouseRateOfReturn}
                        setSpouseAnnualReturn={setSpouseRateOfReturn}
                        spouseBookValue={spouseACB}
                        setSpouseBookValue={setSpouseACB}
                        spouseCurrentValue={spouseTotalNonReg}
                        setSpouseCurrentValue={setSpouseTotalNonReg}
                        spouseIncomeBearing={spouseIncomeBearing}
                        setSpouseIncomeBearing={setSpouseIncomeBearing}
                        spouseEligibleDividendIncome={spouseEligibleDiv}
                        setSpouseEligibleDividendIncome={setSpouseEligibleDiv}
                        spouseNonEligibleDividendIncome={spouseNonEligibleDiv}
                        setSpouseNonEligibleDividendIncome={
                          setSpouseNonEligibleDiv
                        }
                        spouseForeignDividendIncome={spouseForeignDiv}
                        setSpouseForeignDividendIncome={setSpouseForeignDiv}
                        contThisYear={spouseNonRegContThisYear}
                        setContThisYear={setSpouseNonRegContThisYear}
                        moneyToNumber={moneyToNumber}
                        errors={spouseErrors}
                        setChangedData={setChangedData}
                      />
                    )}
                  </Box>
                </>
              }
            />
          )}
        </VStack>
      )}
      {isMobile && (
        <VStack alignItems={"flex-start"} width={"100%"} mt={-2}>
          <GlobalBodyPage
            Heading="Non-Registered"
            mainframeContent={
              <>
                <Flex justify="space-between" w="100%">
                  <Heading
                    height={"27px"}
                    alignSelf={"stretch"}
                    color={"#00466D"}
                    fontFamily={"Nunito"}
                    fontSize={"20px"}
                    fontStyle={"normal"}
                    fontWeight={"700"}
                    lineHeight={"normal"}
                  >
                    {firstName} {lastName}
                  </Heading>
                </Flex>
                <Box
                  display={"flex"}
                  width={"100%"}
                  flexDirection={"row"}
                  paddingTop={"10px"}
                  alignItems={"flex-end"}
                  justifyContent={"space-between"}
                  gap={"10px"}
                  flexWrap={"wrap"}
                >
                  <DropDownButton
                    Heading={"Do you have any non-registered investments?"}
                    placeholder={"Select"}
                    dropDownItems={[
                      { key: "Yes", value: "Yes" },
                      { key: "No", value: "No" },
                    ]}
                    value={hasNonReg}
                    onChange={(key) => {
                      setChangedData(true);
                      setHasNonReg(key);
                    }}
                    MenuCustomWidth={"100%"}
                    error={errors.hasNonReg}
                    tooltipContent={TooltipContent.nonregYesNo}
                  />
                  {hasNonReg === "Yes" && (
                    <HaveNonRegistered
                      spouseAnnualReturn={rateOfReturn}
                      setSpouseAnnualReturn={setRateOfReturn}
                      spouseBookValue={ACB}
                      setSpouseBookValue={setACB}
                      spouseCurrentValue={totalNonReg}
                      setSpouseCurrentValue={setTotalNonReg}
                      spouseIncomeBearing={incomeBearing}
                      setSpouseIncomeBearing={setIncomeBearing}
                      spouseEligibleDividendIncome={eligibleDiv}
                      setSpouseEligibleDividendIncome={setEligibleDiv}
                      spouseNonEligibleDividendIncome={nonEligibleDiv}
                      setSpouseNonEligibleDividendIncome={setNonEligibleDiv}
                      spouseForeignDividendIncome={foreignDiv}
                      setSpouseForeignDividendIncome={setForeignDiv}
                      contThisYear={nonRegContThisYear}
                      setContThisYear={setNonRegContThisYear}
                      moneyToNumber={moneyToNumber}
                      errors={errors}
                      setChangedData={setChangedData}
                    />
                  )}
                </Box>
              </>
            }
            videoName={"Non-Registered Investments Page"}
            video={"Non-Registered_Investments.mp4"}
          />
          {spouseStatus && (
            <GlobalBodyPage
              mainframeContent={
                <>
                  <Flex justify="space-between" w="100%">
                    <Heading
                      height={"27px"}
                      alignSelf={"stretch"}
                      color={"#00466D"}
                      fontFamily={"Nunito"}
                      fontSize={"20px"}
                      fontStyle={"normal"}
                      fontWeight={"700"}
                      lineHeight={"normal"}
                    >
                      {spouseName} {spouseLastName}
                    </Heading>
                  </Flex>
                  <Box
                    display={"flex"}
                    width={"100%"}
                    flexDirection={"row"}
                    paddingTop={"10px"}
                    alignItems={"flex-end"}
                    justifyContent={"space-between"}
                    gap={"10px"}
                    flexWrap={"wrap"}
                  >
                    <DropDownButton
                      Heading={"Do you have any non-registered investments?"}
                      placeholder={"Select"}
                      dropDownItems={[
                        { key: "Yes", value: "Yes" },
                        { key: "No", value: "No" },
                      ]}
                      value={spouseHasNonReg}
                      onChange={(key) => {
                        setChangedData(true);
                        setSpouseHasNonReg(key);
                      }}
                      MenuCustomWidth={"100%"}
                      error={spouseErrors.hasNonReg}
                      tooltipContent={TooltipContent.nonregYesNo}
                    />
                    {spouseHasNonReg === "Yes" && (
                      <HaveNonRegistered
                        spouseAnnualReturn={spouseRateOfReturn}
                        setSpouseAnnualReturn={setSpouseRateOfReturn}
                        spouseBookValue={spouseACB}
                        setSpouseBookValue={setSpouseACB}
                        spouseCurrentValue={spouseTotalNonReg}
                        setSpouseCurrentValue={setSpouseTotalNonReg}
                        spouseIncomeBearing={spouseIncomeBearing}
                        setSpouseIncomeBearing={setSpouseIncomeBearing}
                        spouseEligibleDividendIncome={spouseEligibleDiv}
                        setSpouseEligibleDividendIncome={setSpouseEligibleDiv}
                        spouseNonEligibleDividendIncome={spouseNonEligibleDiv}
                        setSpouseNonEligibleDividendIncome={
                          setSpouseNonEligibleDiv
                        }
                        spouseForeignDividendIncome={spouseForeignDiv}
                        setSpouseForeignDividendIncome={setSpouseForeignDiv}
                        contThisYear={spouseNonRegContThisYear}
                        setContThisYear={setSpouseNonRegContThisYear}
                        moneyToNumber={moneyToNumber}
                        errors={spouseErrors}
                        setChangedData={setChangedData}
                      />
                    )}
                  </Box>
                </>
              }
            />
          )}
        </VStack>
      )}
    </Flex>
  );
});

function HaveNonRegistered({
  spouseCurrentValue,
  setSpouseCurrentValue,
  spouseAnnualReturn,
  setSpouseAnnualReturn,
  spouseBookValue,
  setSpouseBookValue,
  spouseIncomeBearing,
  setSpouseIncomeBearing,
  spouseEligibleDividendIncome,
  setSpouseEligibleDividendIncome,
  spouseNonEligibleDividendIncome,
  setSpouseNonEligibleDividendIncome,
  spouseForeignDividendIncome,
  setSpouseForeignDividendIncome,
  contThisYear,
  setContThisYear,
  moneyToNumber,
  errors,
  setChangedData,
}) {
  const [isLargerThan768] = useMediaQuery("(min-width: 500px");
  const isMobile = !isLargerThan768;

  return (
    <>
      {isMobile && (
        <>
          <Text
            color={"var(--Optiml---Deep-Ocean-Blue, #007EB5)"}
            fontFamily={"Nunito"}
            fontSize={"16px"}
            fontStyle={"normal"}
            fontWeight={"600"}
            lineHeight={"normal"}
            width={"100%"}
          >
            Investment Details
          </Text>
          <NormalInputButton
            Heading={"Total value of your non-registered investments?"}
            placeholder={"$"}
            value={spouseCurrentValue}
            onChange={(e) => {
              setChangedData(true);
              setSpouseCurrentValue(moneyToNumber(e.target.value));
            }}
            useThousandSeparator={true}
            InputWidth={"100%"}
            error={errors.totalNonReg}
            tooltipContent={TooltipContent.nonregTotalValue}
          />

          <NormalInputButton
            Heading={"Book Value"}
            placeholder={"$"}
            value={spouseBookValue}
            onChange={(e) => {
              setChangedData(true);
              setSpouseBookValue(moneyToNumber(e.target.value));
            }}
            useThousandSeparator={true}
            InputWidth={"100%"}
            error={errors.ACB}
            tooltipContent={TooltipContent.nonregACB}
          />
          <NormalInputButton
            Heading={
              "How much have you contributed to your non-registered investments this year?"
            }
            placeholder={"$"}
            value={contThisYear}
            onChange={(e) => {
              setChangedData(true);
              setContThisYear(moneyToNumber(e.target.value));
            }}
            useThousandSeparator={true}
            customWidth={"100%"}
            MenuCustomWidth={"100%"}
            error={errors.totalNonReg}
            tooltipContent={TooltipContent.contThisYear}
          />
          <NormalInputButton
            Heading={"Expected annual rate of return?"}
            placeholder={"%"}
            value={spouseAnnualReturn}
            onChange={(e) => {
              setChangedData(true);
              const noSymbol = e.target.value.replace("%", "");
              const growthNum = parseFloat(noSymbol);
              setSpouseAnnualReturn(
                !isNaN(growthNum) ? parseFloat(growthNum.toFixed(2)) : ""
              );
            }}
            usePercentage={true}
            InputWidth={"100%"}
            customWidth={"100%"}
            error={errors.rateOfReturn}
            tooltipContent={TooltipContent.nonregGrowth}
          />
          <Text
            color={"var(--Optiml---Deep-Ocean-Blue, #007EB5)"}
            fontFamily={"Nunito"}
            fontSize={"16px"}
            fontStyle={"normal"}
            fontWeight={"600"}
            lineHeight={"normal"}
            width={"100%"}
          >
            Income from Investments
          </Text>
          <DropDownButton
            Heading={
              "Are any of your non-registered investments income bearing?"
            }
            placeholder={"Select"}
            dropDownItems={[
              { key: "Yes", value: "Yes" },
              { key: "No", value: "No" },
            ]}
            value={spouseIncomeBearing}
            onChange={(key) => {
              setChangedData(true);
              setSpouseIncomeBearing(key);
            }}
            customWidth={"100%"}
            MenuCustomWidth={"100%"}
            error={errors.incomeBearing}
            tooltipContent={TooltipContent.nonregIncomeBearing}
          />
          {spouseIncomeBearing === "Yes" && (
            <>
              <NormalInputButton
                Heading={"Annual Eligible Dividend Income"}
                placeholder={"$"}
                value={spouseEligibleDividendIncome}
                onChange={(e) => {
                  setChangedData(true);
                  setSpouseEligibleDividendIncome(
                    moneyToNumber(e.target.value)
                  );
                }}
                customWidth={"100%"}
                useThousandSeparator={true}
                InputWidth={"100%"}
                error={errors.dividendCheck}
                tooltipContent={TooltipContent.nonregDividendsEligible}
              />
              <NormalInputButton
                Heading={"Annual Non Eligible Dividend Income"}
                placeholder={"$"}
                value={spouseNonEligibleDividendIncome}
                onChange={(e) => {
                  setChangedData(true);
                  setSpouseNonEligibleDividendIncome(
                    moneyToNumber(e.target.value)
                  );
                }}
                customWidth={"100%"}
                useThousandSeparator={true}
                InputWidth={"100%"}
                error={errors.dividendCheck}
                tooltipContent={TooltipContent.nonregDividendsNonEligible}
              />
              <NormalInputButton
                Heading={"Annual Foreign Dividend Income"}
                placeholder={"$"}
                value={spouseForeignDividendIncome}
                onChange={(e) => {
                  setChangedData(true);
                  setSpouseForeignDividendIncome(moneyToNumber(e.target.value));
                }}
                customWidth={"100%"}
                useThousandSeparator={true}
                InputWidth={"100%"}
                error={errors.dividendCheck}
                tooltipContent={TooltipContent.nonregDividendsForeign}
              />
            </>
          )}
        </>
      )}
      {!isMobile && (
        <>
          <Text
            color={"var(--Optiml---Deep-Ocean-Blue, #007EB5)"}
            fontFamily={"Nunito"}
            fontSize={"16px"}
            fontStyle={"normal"}
            fontWeight={"600"}
            lineHeight={"normal"}
            width={"100%"}
          >
            Investment Details
          </Text>
          <NormalInputButton
            Heading={"Total value of your non-registered investments?"}
            placeholder={"$"}
            value={spouseCurrentValue}
            onChange={(e) => {
              setChangedData(true);
              setSpouseCurrentValue(moneyToNumber(e.target.value));
            }}
            useThousandSeparator={true}
            error={errors.totalNonReg}
            tooltipContent={TooltipContent.nonregTotalValue}
          />

          <NormalInputButton
            Heading={"Book Value"}
            placeholder={"$"}
            value={spouseBookValue}
            onChange={(e) => {
              setChangedData(true);
              setSpouseBookValue(moneyToNumber(e.target.value));
            }}
            useThousandSeparator={true}
            error={errors.ACB}
            tooltipContent={TooltipContent.nonregACB}
          />
          <NormalInputButton
            Heading={
              "How much have you contributed to your non-registered investments this year?"
            }
            placeholder={"$"}
            value={contThisYear}
            onChange={(e) => {
              setChangedData(true);
              setContThisYear(moneyToNumber(e.target.value));
            }}
            useThousandSeparator={true}
            customWidth={"100%"}
            error={errors.totalNonReg}
            tooltipContent={TooltipContent.contThisYear}
          />
          <NormalInputButton
            Heading={"Expected annual rate of return?"}
            placeholder={"%"}
            value={spouseAnnualReturn}
            onChange={(e) => {
              setChangedData(true);
              const noSymbol = e.target.value.replace("%", "");
              const growthNum = parseFloat(noSymbol);
              setSpouseAnnualReturn(
                !isNaN(growthNum) ? parseFloat(growthNum.toFixed(2)) : ""
              );
            }}
            usePercentage={true}
            customWidth={"100%"}
            error={errors.rateOfReturn}
            tooltipContent={TooltipContent.nonregGrowth}
          />
          <Text
            color={"var(--Optiml---Deep-Ocean-Blue, #007EB5)"}
            fontFamily={"Nunito"}
            fontSize={"16px"}
            fontStyle={"normal"}
            fontWeight={"600"}
            lineHeight={"normal"}
            width={"100%"}
          >
            Income from Investments
          </Text>
          <DropDownButton
            Heading={
              "Are any of your non-registered investments income bearing?"
            }
            placeholder={"Select"}
            dropDownItems={[
              { key: "Yes", value: "Yes" },
              { key: "No", value: "No" },
            ]}
            value={spouseIncomeBearing}
            onChange={(key) => {
              setChangedData(true);
              setSpouseIncomeBearing(key);
            }}
            customWidth={"100%"}
            error={errors.incomeBearing}
            tooltipContent={TooltipContent.nonregIncomeBearing}
          />
          {spouseIncomeBearing === "Yes" && (
            <>
              <NormalInputButton
                Heading={"Annual Eligible Dividend Income"}
                placeholder={"$"}
                value={spouseEligibleDividendIncome}
                onChange={(e) => {
                  setChangedData(true);
                  setSpouseEligibleDividendIncome(
                    moneyToNumber(e.target.value)
                  );
                }}
                customWidth={"100%"}
                useThousandSeparator={true}
                error={errors.dividendCheck}
                tooltipContent={TooltipContent.nonregDividendsEligible}
              />
              <NormalInputButton
                Heading={"Annual Non Eligible Dividend Income"}
                placeholder={"$"}
                value={spouseNonEligibleDividendIncome}
                onChange={(e) => {
                  setChangedData(true);
                  setSpouseNonEligibleDividendIncome(
                    moneyToNumber(e.target.value)
                  );
                }}
                customWidth={"100%"}
                useThousandSeparator={true}
                error={errors.dividendCheck}
                tooltipContent={TooltipContent.nonregDividendsNonEligible}
              />
              <NormalInputButton
                Heading={"Annual Foreign Dividend Income"}
                placeholder={"$"}
                value={spouseForeignDividendIncome}
                onChange={(e) => {
                  setChangedData(true);
                  setSpouseForeignDividendIncome(moneyToNumber(e.target.value));
                }}
                customWidth={"100%"}
                useThousandSeparator={true}
                error={errors.dividendCheck}
                tooltipContent={TooltipContent.nonregDividendsForeign}
              />
            </>
          )}
        </>
      )}
    </>
  );
}

export default NonRegistered;
