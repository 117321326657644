import React from "react";
import { useStore } from "../store";

const Link = ({ className, href, children, disabled }) => {
  const setMobileSidebarOpen = useStore((store) => store.setMobileSidebarOpen);

  const onClick = (event) => {
    if (event.metaKey || event.ctrlKey) {
      return;
    }

    event.preventDefault();
    window.history.pushState({}, "", href);

    // // console.log(children)

    const navEvent = new PopStateEvent("popstate");
    window.dispatchEvent(navEvent);
    setTimeout(() => {
      setMobileSidebarOpen(false);
    }, 100);
  };

  return (
    <>
      {disabled && (
        <p
          className={className}
          style={{
            textDecoration: "none",
            cursor: disabled ? "not-allowed" : "pointer",
          }}
        >
          {children}
        </p>
      )}
      {!disabled && (
        <p
          onClick={onClick}
          className={className}
          href={href}
          style={{ textDecoration: "none", cursor: "pointer" }}
        >
          {children}
        </p>
      )}
    </>
  );
};

export default Link;
