import { Box, Button, Text } from "@chakra-ui/react";

function ManualConfiguration() {
  return (
    <Box display={"flex"} flexDirection={"column"} w={"100%"} gap={"20px"}>
      <Box
        display={"flex"}
        padding={"25px"}
        alignItems={"flex-start"}
        gap={"10px"}
        alignSelf={"stretch"}
        borderRadius={"5px"}
        border={"1px solid #00A2D4"}
        bg={"#F3FAFF"}
      >
        <Text
          flex={"1 0 0"}
          color={"#004069"}
          fontFamily={"Nunito"}
          fontSize={"16px"}
          fontStyle={"normal"}
          fontWeight={"700"}
          lineHeight={"normal"}
        >
          Manual configuration allows you to set your own deposit and withdrawal
          order, as well as choose the age you want to start receiving Old Age
          Security and Canadian/Quebec Pension Plan benefits. While the Optiml™
          plan offers unmatched optimization, this feature provides flexibility
          for those who prefer to customize their plan or stress test Optiml™'s
          results.
        </Text>
      </Box>

      <Button
        borderRadius={"5px"}
        bg={"#00A2D4"}
        textColor={"#FFF"}
        width={"fit-content"}
        padding={"10px 25px"}
      >
        Upgrade Now
      </Button>
    </Box>
  );
}

export default ManualConfiguration;
