import { useAuth0 } from "@auth0/auth0-react";
import {
  Box,
  Button,
  Flex,
  Heading,
  IconButton,
  Image,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Select,
  Text,
  VStack,
  useMediaQuery,
} from "@chakra-ui/react";
import {
  faDeleteRight,
  faGear,
  faSync,
} from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from "axios";
import React, {
  forwardRef,
  useContext,
  useEffect,
  useImperativeHandle,
  useMemo,
  useState,
} from "react";
import Modal from "../../components/Modal";
import { useStore } from "../../store";
import CompassSpinner from "../CompassSpinner";
import GlobalBodyPage from "../GlobalContainer/BodyPage";
import { SpouseContext } from "../SpouseContext";
import { StripeContext } from "../StripeContext";
import StripeUpgradeModal from "../StripeUpgradeModal";
import WealthicaAPI from "../Wealthica API";

import "./InvestmentAccountsPage.css";
import { CloseIcon } from "../CustomIcons";

const InvestmentAccountsPage = forwardRef((props, ref) => {
  const { setChangedData } = props;
  const { user, getAccessTokenSilently } = useAuth0();

  const [isLargerThan768] = useMediaQuery("(min-width: 500px)");
  const isMobile = !isLargerThan768;

  useImperativeHandle(ref, () => ({
    submitHandler,
    checkMandatoryFields,
  }));

  useEffect(() => {
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 100);
  }, []);

  const axiosInstance = axios.create();
  async function getToken() {
    // Replace this with your Auth0 token retrieval logic
    const token = await getAccessTokenSilently({
      audience:
        "https://os2lsiwff43wfpb5ggyawt6qri0mgcro.lambda-url.us-east-2.on.aws/",
      scope: "write:optimization",
    });
    return token;
  }

  // Add a request interceptor
  axiosInstance.interceptors.request.use(
    async (config) => {
      const token = await getToken();
      config.headers.Authorization = `Bearer ${token}`;
      return config;
    },
    (error) => {
      // Do something with request error
      return Promise.reject(error);
    }
  );

  const [showModal, setShowModal] = useState(false);
  const { stripePlan, setStripePlan } = useContext(StripeContext);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showReconnectModal, setShowReconnectModal] = useState(false);
  const [selectedAccount, setSelectedAccount] = useState({
    name: "",
    id: "",
    user: "",
  });
  const [wealthicaData, setWealthicaData] = useState();
  const [spouseWealthicaData, setSpouseWealthicaData] = useState();
  const [jointWealthicaData, setJointWealthicaData] = useState();
  const [ignoreData, setIgnoreData] = useState();
  const [showStripeModal, setShowStripeModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loadOptiml, setLoadOptiml] = useState(false);
  const [spouseLoading, setSpouseLoading] = useState(false);
  const [jointLoading, setJointLoading] = useState(false);
  const [totals, setTotals] = useState({});
  const [spouseTotals, setSpouseTotals] = useState({});
  const [date, setDate] = useState();
  const [spouseDate, setSpouseDate] = useState();
  const [jointDate, setJointDate] = useState();

  const [errorAccounts, setErrorAccounts] = useState([]);
  const [showErrorModal, setShowErrorModal] = useState(false);

  const [newUser, setNewUser] = useState();

  const { spouseStatus, setSpouseStatus } = useContext(SpouseContext);

  const setUserTFSAInfo = useStore((store) => store.setUserTFSAInfo);
  const setUserRRSPInfo = useStore((store) => store.setUserRRSPInfo);
  const setUserFHSAInfo = useStore((store) => store.setUserFHSAInfo);
  const setUserNonRegInvestmentsInfo = useStore(
    (store) => store.setUserNonRegInvestmentsInfo
  );
  const setUserLiraInfo = useStore((store) => store.setUserLiraInfo);
  const setUserCASHInfo = useStore((store) => store.setUserCASHInfo);
  const setUserBusinessInfo = useStore((store) => store.setUserBusinessInfo);
  const updateUserBusinessInfo = useStore(
    (store) => store?.updateUserBusinessInfo
  );
  const setUserWealthicaData = useStore((store) => store.setUserWealthicaData);
  // const setUserOptiData = useStore((store) => store.setUserOptiData);
  // const setShowNewOptimization = useStore((store) => store.setShowNewOptimization)

  const userInfo = useStore((store) => store.userInfo);
  const userOptiData = useStore((store) => store.userOptiData);
  const userWealthicaData = useStore((store) => store.userWealthicaData);
  const userRRSPInfo = useStore((store) => store.userRRSPInfo);
  const userFHSAInfo = useStore((store) => store.userFHSAInfo);
  const userTFSAInfo = useStore((store) => store.userTFSAInfo);
  const userLiraInfo = useStore((store) => store.userLiraInfo);
  const userCASHInfo = useStore((store) => store.userCASHInfo);
  const userBusinessInfo = useStore((store) => store.userBusinessInfo);
  const userNonRegInvestmentsInfo = useStore(
    (store) => store.userNonRegInvestmentsInfo
  );

  useEffect(() => {
    if (userWealthicaData?.spouse1Investments?.length > 0) {
      setWealthicaData(userWealthicaData?.spouse1Investments);
    }
    if (spouseStatus && userWealthicaData?.spouse2Investments?.length > 0) {
      setSpouseWealthicaData(userWealthicaData?.spouse2Investments);
    }
    if (spouseStatus && userWealthicaData?.jointInvestments?.length > 0) {
      setJointWealthicaData(userWealthicaData?.jointInvestments);
    }
    if (userWealthicaData?.ignoreAccounts?.length > 0) {
      setIgnoreData(userWealthicaData?.ignoreAccounts);
    }
    if (userWealthicaData?.spouse1Date) {
      setDate(userWealthicaData?.spouse1Date);
    }
    if (userWealthicaData?.spouse2Date) {
      setSpouseDate(userWealthicaData?.spouse2Date);
    }
    if (userWealthicaData?.jointDate) {
      setJointDate(userWealthicaData?.jointDate);
    }
  }, []);

  const currencyFormat = (money) => {
    if (money == "") {
      return "$0";
    } else if (money == 0) {
      return "$0";
    } else {
      const intTotal = Math.round(Math.round(money * 100) / 100);
      const currencyCad = new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "USD",
        minimumFractionDigits: 0,
      }).format(intTotal);
      return currencyCad;
    }
  };

  const [errors, setErrors] = useState({});

  const checkMandatoryFields = () => {
    let newErrors = {};

    // Helper function to check investments within institutions
    const checkInvestments = (data, dataType) => {
      Object.keys(data).forEach((institutionKey) => {
        const institution = data[institutionKey];
        institution.investments.forEach((investment) => {
          if (!investment.type) {
            // Initialize the data type error array if not already initialized
            if (!newErrors[dataType]) {
              newErrors[dataType] = {};
            }
            // Initialize the institution error array if not already initialized
            if (!newErrors[dataType][institutionKey]) {
              newErrors[dataType][institutionKey] = [];
            }
            newErrors[dataType][institutionKey].push(
              `Missing type in investment for institution ${institutionKey}`
            );
          }
        });
      });
    };

    // Check accounts in wealthicaData
    if (wealthicaData) {
      checkInvestments(wealthicaData, "wealthicaData");
    }

    // Check spouseWealthicaData and jointWealthicaData if spouseStatus is true
    if (spouseStatus) {
      if (spouseWealthicaData) {
        checkInvestments(spouseWealthicaData, "spouseWealthicaData");
      }
      if (jointWealthicaData) {
        checkInvestments(jointWealthicaData, "jointWealthicaData");
      }
    }

    // Update errors state
    setErrors(newErrors);

    // Return true if no errors
    return Object.keys(newErrors).length === 0;
  };

  function safeAdd(...values) {
    return values.reduce((sum, val) => sum + (Number(val) || 0), 0);
  }

  const submitHandler = (e, delData, account) => {
    setLoadOptiml(true);
    if (e) {
      e.preventDefault();
    }
    if (
      stripePlan?.items?.data?.[0]?.price?.lookup_key === "OPTIML_ESSENTIALS" ||
      stripePlan?.items?.data?.[0]?.price?.lookup_key === "OPTIML_ESSENTIALS_M"
    ) {
      return true;
    }
    let spouseData = (wealthicaData || [])?.filter(
      (institution) => institution?.sync_status !== "error"
    );
    let spouse2Data = (spouseWealthicaData || [])?.filter(
      (institution) => institution?.sync_status !== "error"
    );
    let jointSpouseData = (jointWealthicaData || [])?.filter(
      (institution) => institution?.sync_status !== "error"
    );

    if (delData && account) {
      if (account === "SPS1") {
        spouseData = delData;
      } else if (account === "SPS2") {
        spouse2Data = delData;
      } else if (account === "Joint") {
        jointSpouseData = delData;
      }
    }

    const totalRRSP = calculateTotalRRSP(spouseData);
    const spouseTotalRRSP = calculateTotalRRSP(spouseStatus ? spouse2Data : []);
    const jointTotalRRSP = calculateTotalRRSP(
      spouseStatus ? jointSpouseData : []
    );

    const totalFHSA = calculateTotalFHSA(spouseData);
    const spouseTotalFHSA = calculateTotalFHSA(spouseStatus ? spouse2Data : []);
    const jointTotalFHSA = calculateTotalFHSA(
      spouseStatus ? jointSpouseData : []
    );

    const totalTFSA = calculateTotalTFSA(spouseData);
    const spouseTotalTFSA = calculateTotalTFSA(spouseStatus ? spouse2Data : []);
    const jointTotalTFSA = calculateTotalTFSA(
      spouseStatus ? jointSpouseData : []
    );

    const totalNonReg = calculateTotalNonReg(spouseData);
    const nonRegACB = calculateTotalNonRegBookValue(spouseData);

    const spouseTotalNonReg = calculateTotalNonReg(
      spouseStatus ? spouse2Data : []
    );
    const spouseNonRegACB = calculateTotalNonRegBookValue(
      spouseStatus ? spouse2Data : []
    );

    const jointTotalNonReg = calculateTotalNonReg(
      spouseStatus ? jointSpouseData : []
    );
    const jointNonRegACB = calculateTotalNonRegBookValue(
      spouseStatus ? jointSpouseData : []
    );

    const totalCASH = calculateTotalCASH(spouseData);
    const spouseTotalCASH = calculateTotalCASH(spouseStatus ? spouse2Data : []);
    const jointTotalCASH = calculateTotalCASH(
      spouseStatus ? jointSpouseData : []
    );

    const totalLIRA = calculateTotalLIRA(spouseData);
    const spouseTotalLIRA = calculateTotalLIRA(spouseStatus ? spouse2Data : []);
    const jointTotalLIRA = calculateTotalLIRA(
      spouseStatus ? jointSpouseData : []
    );

    const totalCCPC = calculateTotalCCPC(spouseData);
    const spouseTotalCCPC = calculateTotalCCPC(spouseStatus ? spouse2Data : []);
    const jointTotalCCPC = calculateTotalCCPC(
      spouseStatus ? jointSpouseData : []
    );

    const spouseOneData = JSON.stringify(spouseData);
    const spouseTwoData = JSON.stringify(spouseStatus ? spouse2Data : []);
    const jointData = JSON.stringify(spouseStatus ? jointSpouseData : []);
    const ignoreAccountsData = JSON.stringify(
      delData && account ? [] : ignoreData
    );

    const wealthicaDataObject = {
      idWEALTHICA_DATA: user.sub,
      spouse1Investments: spouseOneData ? spouseOneData : "[{}]",
      spouse2Investments: spouseTwoData ? spouseTwoData : "[{}]",
      jointInvestments: jointData ? jointData : "[{}]",
      ignoreAccounts: ignoreAccountsData ? ignoreAccountsData : "[{}]",
      spouse1Date: date,
      spouse2Date: spouseDate,
      jointDate: jointDate,
    };
    const storeWealthica = {
      spouse1Investments: spouseOneData ? spouseOneData : "[{}]",
      spouse2Investments: spouseTwoData ? spouseTwoData : "[{}]",
      jointInvestments: jointData ? jointData : "[{}]",
      ignoreAccounts: ignoreAccountsData ? ignoreAccountsData : "[{}]",
      spouse1Date: date,
      spouse2Date: spouseDate,
      jointDate: jointDate,
    };

    const wealthicaURI = (action) =>
      `${process.env.REACT_APP_NODE_API_GATEWAY}/CompassLambdaApi/WEALTHICA_DATA/${action}`;
    axiosInstance
      .post(wealthicaURI("add"), wealthicaDataObject)
      .then((res) => {
        if (res.data.code === "ER_DUP_ENTRY") {
          axiosInstance
            .post(wealthicaURI("update"), wealthicaDataObject)
            .then((res) => {
              console.log(res);
            });
        }
        setUserWealthicaData(storeWealthica);
        setUserRRSPInfo({
          ...userRRSPInfo,
          totalValue:
            safeAdd(totalRRSP, jointTotalRRSP / 2) > 0
              ? safeAdd(totalRRSP, jointTotalRRSP / 2)
              : userRRSPInfo?.totalValue,
          Do_You_RRSP:
            safeAdd(totalRRSP, jointTotalRRSP / 2) > 0
              ? "Yes"
              : userRRSPInfo?.Do_You_RRSP,
          RRSP_ROR: userRRSPInfo?.RRSP_ROR ? userRRSPInfo?.RRSP_ROR : 6,
          spouseTotalValue:
            spouseStatus && safeAdd(spouseTotalRRSP, jointTotalRRSP / 2) > 0
              ? safeAdd(spouseTotalRRSP, jointTotalRRSP / 2)
              : userRRSPInfo?.spouseTotalValue,
          spouseDo_You_RRSP:
            spouseStatus && safeAdd(spouseTotalRRSP, jointTotalRRSP / 2) > 0
              ? "Yes"
              : userRRSPInfo?.spouseDo_You_RRSP,
          spouseRRSP_ROR:
            spouseStatus && userRRSPInfo?.spouseRRSP_ROR
              ? userRRSPInfo?.spouseRRSP_ROR
              : 6,
        });
        setUserFHSAInfo({
          ...userFHSAInfo,
          doFhsa:
            safeAdd(totalFHSA, jointTotalFHSA / 2) > 0
              ? "Yes"
              : userFHSAInfo?.doFhsa,
          fhsaValue:
            safeAdd(totalFHSA, jointTotalFHSA / 2) > 0
              ? safeAdd(totalFHSA, jointTotalFHSA / 2)
              : userFHSAInfo?.fhsaValue,
          fhsaGrowth: userFHSAInfo?.fhsaGrowth ?? 6,
          spouseDoFhsa:
            safeAdd(spouseTotalFHSA, jointTotalFHSA / 2) > 0
              ? "Yes"
              : userFHSAInfo?.spouseDoFhsa,
          spouseFhsaValue:
            safeAdd(spouseTotalFHSA, jointTotalFHSA / 2) > 0
              ? safeAdd(spouseTotalFHSA, jointTotalFHSA / 2)
              : userFHSAInfo?.spouseFhsaValue,
          spouseFhsaGrowth: userFHSAInfo?.spouseFhsaGrowth ?? 6,
        });
        setUserTFSAInfo({
          ...userTFSAInfo,
          totalValue:
            safeAdd(totalTFSA, jointTotalTFSA / 2) > 0
              ? safeAdd(totalTFSA, jointTotalTFSA / 2)
              : userTFSAInfo?.totalValue,
          Do_You_TFSA:
            safeAdd(totalTFSA, jointTotalTFSA / 2) > 0
              ? "Yes"
              : userTFSAInfo?.Do_You_TFSA,
          TFSA_ROR: userTFSAInfo?.TFSA_ROR ? userTFSAInfo?.TFSA_ROR : 6,

          spouseTotalValue:
            spouseStatus && safeAdd(spouseTotalTFSA, jointTotalTFSA / 2) > 0
              ? safeAdd(spouseTotalTFSA, jointTotalTFSA / 2)
              : userTFSAInfo?.spouseTotalValue,
          spouseDo_You_TFSA:
            spouseStatus && safeAdd(spouseTotalTFSA, jointTotalTFSA / 2) > 0
              ? "Yes"
              : userTFSAInfo?.spouseDo_You_TFSA,
          spouseTFSA_ROR:
            spouseStatus && userTFSAInfo?.spouseTFSA_ROR
              ? userTFSAInfo?.spouseTFSA_ROR
              : 6,
        });
        setUserNonRegInvestmentsInfo({
          ...userNonRegInvestmentsInfo,
          totalValue:
            safeAdd(totalNonReg, jointTotalNonReg / 2) > 0
              ? safeAdd(totalNonReg, jointTotalNonReg / 2)
              : userNonRegInvestmentsInfo?.totalValue,
          Do_You_Non_Reg:
            safeAdd(totalNonReg, jointTotalNonReg / 2) > 0
              ? "Yes"
              : userNonRegInvestmentsInfo?.Do_You_Non_Reg,
          Non_Reg_ROR: userNonRegInvestmentsInfo?.Non_Reg_ROR
            ? userNonRegInvestmentsInfo?.Non_Reg_ROR
            : 6,
          ACB:
            safeAdd(nonRegACB, jointNonRegACB / 2) > 0
              ? safeAdd(nonRegACB, jointNonRegACB / 2)
              : userNonRegInvestmentsInfo?.ACB,

          spouseTotalValue:
            spouseStatus && safeAdd(spouseTotalNonReg, jointTotalNonReg / 2) > 0
              ? safeAdd(spouseTotalNonReg, jointTotalNonReg / 2)
              : userNonRegInvestmentsInfo?.spouseTotalValue,
          spouseDo_You_Non_Reg:
            spouseStatus && safeAdd(spouseTotalNonReg, jointTotalNonReg / 2) > 0
              ? "Yes"
              : userNonRegInvestmentsInfo?.spouseDo_You_Non_Reg,
          spouseNon_Reg_ROR: userNonRegInvestmentsInfo?.spouseNon_Reg_ROR
            ? userNonRegInvestmentsInfo?.spouseNon_Reg_ROR
            : 6,
          spouseACB:
            safeAdd(spouseNonRegACB, jointNonRegACB / 2) > 0
              ? safeAdd(spouseNonRegACB, jointNonRegACB / 2)
              : userNonRegInvestmentsInfo?.spouseACB,
        });
        setUserCASHInfo({
          ...userCASHInfo,
          value:
            safeAdd(totalCASH, jointTotalCASH / 2) > 0
              ? safeAdd(totalCASH, jointTotalCASH / 2)
              : userCASHInfo?.value,
          hasCash:
            safeAdd(totalCASH, jointTotalCASH / 2) > 0
              ? "Yes"
              : userCASHInfo?.hasCash,
          minCash: userCASHInfo?.minCash,
          minCashValue:
            userCASHInfo?.minCash === "Yes" ? userCASHInfo?.minCashValue : 0,

          spouseValue:
            spouseStatus && safeAdd(spouseTotalCASH, jointTotalCASH / 2) > 0
              ? safeAdd(spouseTotalCASH, jointTotalCASH / 2)
              : userCASHInfo?.spouseValue,
          spouseHasCash:
            spouseStatus && safeAdd(spouseTotalCASH, jointTotalCASH / 2) > 0
              ? "Yes"
              : userCASHInfo?.spouseHasCash,
          spouseMinCash:
            spouseStatus && userCASHInfo?.spouseMinCash
              ? userCASHInfo?.spouseMinCash
              : userCASHInfo?.spouseMinCash,
          spouseMinCashValue:
            spouseStatus && userCASHInfo?.spouseMinCash === "Yes"
              ? userCASHInfo?.spouseMinCashValue
              : 0,
        });
        setUserLiraInfo({
          ...userLiraInfo,
          SPS1_LIRA_VALUE:
            safeAdd(totalLIRA, jointTotalLIRA / 2) > 0
              ? safeAdd(totalLIRA, jointTotalLIRA / 2)
              : userLiraInfo?.SPS1_LIRA_VALUE,
          Do_You_LIRA:
            safeAdd(totalLIRA, jointTotalLIRA / 2) > 0
              ? "Locked-In Retirement Account (LIRA)"
              : userLiraInfo?.Do_You_LIRA,
          Has_LIRA_LIF: userLiraInfo?.Has_LIRA_LIF
            ? userLiraInfo?.Has_LIRA_LIF
            : "LIRA",
          LIRA_ROR: userLiraInfo?.LIRA_ROR ? userLiraInfo?.LIRA_ROR : 6,

          SPS2_LIRA_VALUE:
            spouseStatus && safeAdd(spouseTotalLIRA, jointTotalLIRA / 2) > 0
              ? safeAdd(spouseTotalLIRA, jointTotalLIRA / 2)
              : userLiraInfo?.SPS2_LIRA_VALUE,
          spouseDo_You_LIRA:
            spouseStatus && safeAdd(spouseTotalLIRA, jointTotalLIRA / 2) > 0
              ? "Locked-In Retirement Account (LIRA)"
              : userLiraInfo?.spouseDo_You_LIRA,
          spouseHas_LIRA_LIF:
            spouseStatus && userLiraInfo?.spouseHas_LIRA_LIF
              ? userLiraInfo?.spouseHas_LIRA_LIF
              : "LIRA",
          spouseLIRA_ROR:
            spouseStatus && userLiraInfo?.spouseLIRA_ROR
              ? userLiraInfo?.spouseLIRA_ROR
              : 6,
        });
        if (userBusinessInfo?.length === 1) {
          onSubmitBusinessUpdate(
            userBusinessInfo?.[0],
            totalCCPC,
            spouseTotalCCPC,
            jointTotalCCPC
          );
        }
        if (
          userBusinessInfo?.length === 0 &&
          safeAdd(totalCCPC, spouseTotalCCPC, jointTotalCCPC)
        ) {
          onSubmitBusinessAdd(totalCCPC, spouseTotalCCPC, jointTotalCCPC);
        }
        setTimeout(() => {
          setLoadOptiml(false);
        }, 1000);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const onSubmitBusinessAdd = (totalCCPC, spouseTotalCCPC, jointTotalCCPC) => {
    const primaryResURL = (action) =>
      `${
        process.env.REACT_APP_NODE_API_GATEWAY
      }/CompassLambdaApi/BUSINESS/${action}?businessID=${user.sub.replace(
        "|",
        "%7c"
      )}&name=${"Investment Funds"}&businessName=${""}&ccpcValue=${safeAdd(
        totalCCPC,
        spouseTotalCCPC,
        jointTotalCCPC
      )}&ccpcGrowth=${0}&ccpcIncomeBearing=${""}&doBusinessInvestments=${"Yes"}&ccpcACB=${safeAdd(
        totalCCPC,
        spouseTotalCCPC,
        jointTotalCCPC
      )}&businessEligible=${0}&businessNonEligible=${0}&businessForeign=${0}&businessOperationsFundsInvested=${0}&businessOperationsFundsGrowth=${0}&ERDTOH=${0}&NERDTOH=${0}&GRIP=${0}&CDA=${0}&businessHasDebt=${""}&businessDebt=${0}&businessLocation=${""}`;
    axiosInstance
      .get(primaryResURL("add"))
      .then((res) => {
        console.log(res);
        const newData = [
          {
            name: "Investment Funds",
            businessName: "",
            ccpcValue: safeAdd(totalCCPC, spouseTotalCCPC, jointTotalCCPC),
            ccpcGrowth: 0,
            ccpcIncomeBearing: "",
            doBusinessInvestments: "Yes",
            ccpcACB: safeAdd(totalCCPC, spouseTotalCCPC, jointTotalCCPC),
            businessEligible: 0,
            businessNonEligible: 0,
            businessForeign: 0,
            businessOperationsFundsInvested: 0,
            businessOperationsFundsGrowth: 0,
            ERDTOH: 0,
            NERDTOH: 0,
            GRIP: 0,
            CDA: 0,
            businessHasDebt: "",
            businessDebt: 0,
            businessLocation: "",
            businessNumber: res.data.lastInsertedId,
          },
        ];
        setUserBusinessInfo(newData);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const onSubmitBusinessUpdate = (
    newArray,
    totalCCPC,
    spouseTotalCCPC,
    jointTotalCCPC
  ) => {
    let x = newArray.businessNumber;
    if (newArray.name == "Investment Funds") {
      const otherAssetsURL = (action) =>
        `${
          process.env.REACT_APP_NODE_API_GATEWAY
        }/CompassLambdaApi/BUSINESS/${action}?businessID=${user.sub.replace(
          "|",
          "%7c"
        )}&name=${newArray.name}&businessName=${
          newArray.businessName
        }&ccpcValue=${
          safeAdd(totalCCPC, spouseTotalCCPC, jointTotalCCPC) > 0
            ? safeAdd(totalCCPC, spouseTotalCCPC, jointTotalCCPC)
            : newArray.ccpcValue
        }&ccpcGrowth=${newArray.ccpcGrowth}&ccpcIncomeBearing=${
          newArray.ccpcIncomeBearing
        }&doBusinessInvestments=${newArray.doBusinessInvestments}&ccpcACB=${
          newArray.ccpcACB
        }&businessEligible=${newArray.businessEligible}&businessNonEligible=${
          newArray.businessNonEligible
        }&businessForeign=${
          newArray.businessForeign
        }&businessOperationsFundsInvested=${
          newArray.businessOperationsFundsInvested
        }&businessOperationsFundsGrowth=${
          newArray.businessOperationsFundsGrowth
        }&ERDTOH=${newArray.ERDTOH}&NERDTOH=${newArray.NERDTOH}&GRIP=${
          newArray.GRIP
        }&CDA=${newArray.CDA}&businessHasDebt=${
          newArray.businessHasDebt
        }&businessDebt=${newArray.businessDebt}&businessLocation=${
          newArray.businessLocation
        }&businessNumber=${newArray.businessNumber}`;
      axiosInstance
        .get(otherAssetsURL("update"))
        .then((res) => {
          console.log(res);
          const newData = {
            name: newArray.name,
            businessName: newArray.businessName,
            ccpcValue:
              safeAdd(totalCCPC, spouseTotalCCPC, jointTotalCCPC) > 0
                ? safeAdd(totalCCPC, spouseTotalCCPC, jointTotalCCPC)
                : newArray.ccpcValue,
            ccpcGrowth: newArray.ccpcGrowth,
            ccpcIncomeBearing: newArray.ccpcIncomeBearing,
            doBusinessInvestments: newArray.doBusinessInvestments,
            ccpcACB: newArray.ccpcACB,
            businessEligible: newArray.businessEligible,
            businessNonEligible: newArray.businessNonEligible,
            businessForeign: newArray.businessForeign,
            businessOperationsFundsInvested:
              newArray.businessOperationsFundsInvested,
            businessOperationsFundsGrowth:
              newArray.businessOperationsFundsGrowth,
            ERDTOH: newArray.ERDTOH,
            NERDTOH: newArray.NERDTOH,
            GRIP: newArray.GRIP,
            CDA: newArray.CDA,
            businessHasDebt: newArray.businessHasDebt,
            businessDebt: newArray.businessDebt,
            businessLocation: newArray.businessLocation,
            businessNumber: newArray.businessNumber,
          };
          updateUserBusinessInfo(x, newData);
        })
        .catch((error) => {
          console.error(error);
        });
    }
  };

  function UpgradeStripeModal() {
    setShowStripeModal(!showStripeModal);
  }

  const convertToCAD = (amount, currency) => {
    // Dummy conversion rates, you should replace this with real conversion logic or API calls
    const conversionRates = {
      usd: 1.25,
      eur: 1.5,
      gbp: 1.75,
    };

    return amount * (conversionRates[currency.toLowerCase()] || 1);
  };

  const processData = (
    apiData,
    userData,
    spouseUserData,
    otherData,
    ignoreData
  ) => {
    setChangedData(true);

    // Map for quick lookup of user data using institution _id
    const userDataMap = new Map(
      userData?.map((institution) => [
        institution?._id,
        {
          ...institution,
          investmentsMap: new Map(
            institution?.investments?.map((inv) => [inv?._id, inv])
          ),
        },
      ])
    );

    // Map for quick lookup of spouse's data using institution _id
    const spouseDataMap = new Map(
      spouseUserData?.map((institution) => [
        institution?._id,
        {
          ...institution,
          investmentsMap: new Map(
            institution?.investments?.map((inv) => [inv?._id, inv])
          ),
        },
      ])
    );

    // Map for quick lookup of other data using institution _id
    const otherDataMap = new Map(
      otherData?.map((institution) => [
        institution?._id,
        {
          ...institution,
          investmentsMap: new Map(
            institution?.investments?.map((inv) => [inv?._id, inv])
          ),
        },
      ])
    );

    // Map for quick lookup of ignore data using institution _id
    const ignoreDataMap = new Map(
      ignoreData?.map((institution) => [
        institution?._id,
        {
          ...institution,
          investmentsMap: new Map(
            institution?.investments?.map((inv) => [inv?._id, inv])
          ),
        },
      ])
    );

    // Process API data and merge or add new entries
    const processedData = apiData
      ?.map((apiInstitution) => {
        const userInstitution = userDataMap?.get(apiInstitution?._id);
        const spouseInstitution = spouseDataMap?.get(apiInstitution?._id);
        const otherInstitution = otherDataMap?.get(apiInstitution?._id);
        const ignoreInstitution = ignoreDataMap?.get(apiInstitution?._id);

        if (apiInstitution?.sync_status === "error") {
          setErrorAccounts(apiInstitution);
          if (userInstitution) {
            return apiInstitution;
          } else if (
            !spouseInstitution &&
            !otherInstitution &&
            !ignoreInstitution
          ) {
            return apiInstitution;
          }
          return null;
        }

        if (userInstitution) {
          const mergedInvestments = apiInstitution?.investments
            ?.map((apiInvestment) => {
              const userInvestment = userInstitution?.investmentsMap?.get(
                apiInvestment?._id
              );
              const spouseInvestment = spouseInstitution?.investmentsMap?.get(
                apiInvestment?._id
              );
              const otherInvestment = otherInstitution?.investmentsMap?.get(
                apiInvestment?._id
              );
              const ignoreInvestment = ignoreInstitution?.investmentsMap?.get(
                apiInvestment?._id
              );
              if (userInvestment) {
                const convertedCurrencyValue =
                  apiInvestment?.currency !== "cad"
                    ? convertToCAD(
                        apiInvestment?.currency_value,
                        apiInvestment?.currency
                      )
                    : apiInvestment?.currency_value;
                return {
                  ...userInvestment,
                  currency_value: convertedCurrencyValue,
                  currency: "cad",
                };
              } else if (
                !spouseInvestment &&
                !otherInvestment &&
                !ignoreInvestment
              ) {
                const convertedCurrencyValue =
                  apiInvestment?.currency !== "cad"
                    ? convertToCAD(
                        apiInvestment?.currency_value,
                        apiInvestment?.currency
                      )
                    : apiInvestment?.currency_value;
                return {
                  ...apiInvestment,
                  currency_value: convertedCurrencyValue,
                  currency: "cad",
                };
              }
              return null;
            })
            ?.filter((investment) => investment !== null);

          return {
            ...userInstitution,
            investments: mergedInvestments,
            sync_date: apiInstitution?.sync_date,
          };
        } else if (
          !spouseInstitution &&
          !otherInstitution &&
          !ignoreInstitution
        ) {
          return apiInstitution;
        }
        return null;
      })
      ?.filter((institution) => institution !== null);

    return processedData;
  };

  const calculateTotalRRSP = (data) => {
    if (!data || data?.[0]?.length === 0) return null; // Early return for empty or undefined data
    return data?.reduce((total, institution) => {
      // Filter out investments that are of type 'rrsp' and sum their values
      if (!institution?.investments) return null; // Early return for empty or undefined data
      const rrspTotal = institution?.investments
        ?.filter((investment) => investment?.type === "rrsp")
        .reduce((sum, rrsp) => sum + rrsp?.currency_value, 0);
      return total + rrspTotal;
    }, 0);
  };

  const calculateTotalFHSA = (data) => {
    if (!data || data?.[0]?.length === 0) return null; // Early return for empty or undefined data
    return data?.reduce((total, institution) => {
      // Filter out investments that are of type 'fhsa' and sum their values
      if (!institution?.investments) return null; // Early return for empty or undefined data
      const rrspTotal = institution?.investments
        ?.filter((investment) => investment?.type === "fhsa")
        .reduce((sum, fhsa) => sum + fhsa?.currency_value, 0);
      return total + rrspTotal;
    }, 0);
  };

  const calculateTotalTFSA = (data) => {
    if (!data || data?.[0]?.length === 0) return null; // Early return for empty or undefined data
    return data?.reduce((total, institution) => {
      // Filter out investments that are of type 'tfsa' and sum their values
      if (!institution?.investments) return null; // Early return for empty or undefined data
      const tfsaTotal = institution?.investments
        ?.filter((investment) => investment?.type === "tfsa")
        .reduce((sum, tfsa) => sum + tfsa?.currency_value, 0);
      return total + tfsaTotal;
    }, 0);
  };

  const calculateTotalNonReg = (data) => {
    if (!data || data?.[0]?.length === 0) return null; // Early return for empty or undefined data
    return data?.reduce((total, institution) => {
      // Filter out investments that are of type 'non-reg' and sum their values
      if (!institution?.investments) return null; // Early return for empty or undefined data
      const nonRegTotal = institution.investments
        ?.filter((investment) => investment?.type === "non-reg")
        .reduce((sum, nonreg) => sum + nonreg?.currency_value, 0);
      return total + nonRegTotal;
    }, 0);
  };

  const calculateTotalNonRegBookValue = (data) => {
    if (!data || data?.[0]?.length === 0) return null; // Early return for empty or undefined data
    return data?.reduce((total, institution) => {
      if (!institution?.investments) return null; // Early return for empty or undefined data
      // Filter out investments that are of type 'non-reg'
      const nonRegTotal = institution.investments
        ?.filter((investment) => investment?.type === "non-reg")
        .reduce((sum, nonreg) => {
          // Assume each non-reg investment has a 'positions' array
          const positionsTotal = nonreg.positions.reduce((posSum, position) => {
            return posSum + (position?.book_value || 0);
          }, 0);
          return sum + positionsTotal;
        }, 0);
      return total + nonRegTotal;
    }, 0);
  };

  const calculateTotalCASH = (data) => {
    if (!data || data?.[0]?.length === 0) return null; // Early return for empty or undefined data
    return data?.reduce((total, institution) => {
      if (!institution?.investments) return null; // Early return for empty or undefined data
      // Filter out investments that are of type 'cash' and sum their values
      const cashTotal = institution.investments
        ?.filter((investment) => investment?.type === "cash")
        .reduce((sum, cash) => sum + cash?.currency_value, 0);
      return total + cashTotal;
    }, 0);
  };

  const calculateTotalLIRA = (data) => {
    if (!data || data?.[0]?.length === 0) return null; // Early return for empty or undefined data
    return data?.reduce((total, institution) => {
      if (!institution?.investments) return null; // Early return for empty or undefined data
      // Filter out investments that are of type 'lira' and sum their values
      const liraTotal = institution.investments
        ?.filter((investment) => investment?.type === "lira")
        .reduce((sum, lira) => sum + lira?.currency_value, 0);
      return total + liraTotal;
    }, 0);
  };

  const calculateTotalCCPC = (data) => {
    if (!data || data?.[0]?.length === 0) return null; // Early return for empty or undefined data
    return data?.reduce((total, institution) => {
      if (!institution?.investments) return null; // Early return for empty or undefined data
      // Filter out investments that are of type 'ccpc' and sum their values
      const ccpcTotal = institution.investments
        ?.filter((investment) => investment?.type === "ccpc")
        .reduce((sum, ccpc) => sum + ccpc?.currency_value, 0);
      return total + ccpcTotal;
    }, 0);
  };

  const calculateTotals = (data) => {
    const totals = {};
    data?.forEach((institution) => {
      institution?.investments?.forEach((investment) => {
        const ownerKey = institution?.user;
        const typeKey = investment?.type;
        const currentValue = investment?.currency_value;

        if (!totals[ownerKey]) {
          totals[ownerKey] = {};
        }
        if (!totals[ownerKey][typeKey]) {
          totals[ownerKey][typeKey] = 0;
        }
        totals[ownerKey][typeKey] += currentValue;
      });
    });
    return totals;
  };

  const InvestmentSummary = ({ totals }) => {
    const accountTypes = Array.from(
      new Set(
        Object.values(totals).flatMap((ownerTotals) => Object.keys(ownerTotals))
      )
    ).sort();

    // Prepare grid style dynamically
    const gridStyle = {
      gridTemplateColumns: `repeat(${accountTypes?.length}, 1fr)`, // Creates equal columns based on the number of types
    };

    return (
      <div
        className="investment-summary"
        style={{ display: accountTypes?.length > 0 ? "block" : "none" }}
      >
        <div
          style={{
            width: "100%",
            fontSize: "1.5rem",
            borderBottom: "2px solid #004069",
          }}
          className="investment-summary-header"
        >
          <div className="investment-summary-cell">
            Total Investment Balances
          </div>
        </div>
        <div className="investment-summary-header" style={gridStyle}>
          {accountTypes?.map((type) => (
            <div key={type} className="investment-summary-cell">
              {type?.toUpperCase()}
            </div>
          ))}
        </div>
        {Object.entries(totals)?.map(([owner, ownerTotals], idx) => (
          <div key={idx} className="investment-summary-row" style={gridStyle}>
            {accountTypes?.map((type, i) => (
              <div key={i} className="investment-summary-cell">
                {ownerTotals[type] ? currencyFormat(ownerTotals[type]) : 0}
              </div>
            ))}
          </div>
        ))}
      </div>
    );
  };

  const [updatePending, setUpdatePending] = useState(false);

  useEffect(() => {
    if (updatePending) {
      submitHandler();
      setUpdatePending(false); // Reset the flag after submitting
    }
  }, [updatePending]); // Add other dependencies if needed

  const updateInvestmentData = (
    investmentId,
    newType,
    newOwner,
    ownerChange
  ) => {
    let investmentToTransfer;
    let institutionName;
    let institutionType;
    let institutionID;

    // Remove the investment from the current owner
    const removeFromData = (data) =>
      data?.map((institution) => ({
        ...institution,
        investments: institution?.investments?.filter((investment) => {
          if (investment?._id === investmentId) {
            investmentToTransfer = {
              ...investment,
              type: newType,
              user: newOwner,
            };
            institutionName = institution?.name; // Capture the institution name
            institutionType = institution?.type;
            institutionID = institution?._id;
            return false; // Remove this investment
          }
          return true;
        }),
      }));

    const updateAndSetPending = () => {
      setUpdatePending(true);
    };

    if (newOwner === "SPS1" && ownerChange) {
      const newSpouseData = removeFromData(spouseWealthicaData);
      const newJointData = removeFromData(jointWealthicaData);
      const newIgnoreData = removeFromData(ignoreData);

      const filteredSpouseData = newSpouseData?.filter(
        (institution) => institution?.investments?.length >= 1
      );

      const filteredJointData = newJointData?.filter(
        (institution) => institution?.investments?.length >= 1
      );

      const filteredIgnoreData = newIgnoreData?.filter(
        (institution) => institution?.investments?.length >= 1
      );

      // Add to the correct institution in primary data or create a new one
      let newWealthicaData = addOrUpdateInstitution(
        wealthicaData,
        institutionName,
        investmentToTransfer,
        institutionType,
        institutionID
      );

      // Update state
      setSpouseWealthicaData(
        filteredSpouseData?.length > 0 ? filteredSpouseData : null
      );
      setJointWealthicaData(
        filteredJointData?.length > 0 ? filteredJointData : null
      );
      setIgnoreData(filteredIgnoreData?.length > 0 ? filteredIgnoreData : null);
      setWealthicaData(newWealthicaData);
      updateAndSetPending(); // Call this function at the end of each branch
    } else if (newOwner === "SPS2" && ownerChange) {
      const newWealthicaData = removeFromData(wealthicaData);
      const newJointData = removeFromData(jointWealthicaData);
      const newIgnoreData = removeFromData(ignoreData);

      const filteredData = newWealthicaData?.filter(
        (institution) => institution?.investments?.length >= 1
      );

      const filteredJointData = newJointData?.filter(
        (institution) => institution?.investments?.length >= 1
      );

      const filteredIgnoreData = newIgnoreData?.filter(
        (institution) => institution?.investments?.length >= 1
      );

      // Add to the correct institution in spouse data or create a new one
      let newSpouseData = addOrUpdateInstitution(
        spouseWealthicaData,
        institutionName,
        investmentToTransfer,
        institutionType,
        institutionID
      );

      // Update state
      setWealthicaData(filteredData?.length > 0 ? filteredData : null);
      setJointWealthicaData(
        filteredJointData?.length > 0 ? filteredJointData : null
      );
      setIgnoreData(filteredIgnoreData?.length > 0 ? filteredIgnoreData : null);
      setSpouseWealthicaData(newSpouseData);
      updateAndSetPending(); // Call this function at the end of each branch
    } else if (newOwner === "Joint" && ownerChange) {
      const newWealthicaData = removeFromData(wealthicaData);
      const newSpouseData = removeFromData(spouseWealthicaData);
      const newIgnoreData = removeFromData(ignoreData);

      const filteredData = newWealthicaData?.filter(
        (institution) => institution?.investments?.length >= 1
      );

      const filteredSpouseData = newSpouseData?.filter(
        (institution) => institution?.investments?.length >= 1
      );

      const filteredIgnoreData = newIgnoreData?.filter(
        (institution) => institution?.investments?.length >= 1
      );

      // Add to the correct institution in primary data or create a new one
      let jointWealthicaDataNew = addOrUpdateInstitution(
        jointWealthicaData,
        institutionName,
        investmentToTransfer,
        institutionType,
        institutionID
      );

      // Update state
      setSpouseWealthicaData(
        filteredSpouseData?.length > 0 ? filteredSpouseData : null
      );
      setWealthicaData(filteredData?.length > 0 ? filteredData : null);
      setIgnoreData(filteredIgnoreData?.length > 0 ? filteredIgnoreData : null);
      setJointWealthicaData(jointWealthicaDataNew);
      updateAndSetPending(); // Call this function at the end of each branch
    } else if (newOwner === "SPS1" && !ownerChange) {
      setWealthicaData((prevData) =>
        prevData?.map((institution) => ({
          ...institution,
          investments: institution?.investments?.map((investment) =>
            investment._id === investmentId
              ? { ...investment, type: newType, user: newOwner }
              : investment
          ),
        }))
      );
      updateAndSetPending(); // Call this function at the end of each branch
    } else if (newOwner === "SPS2" && !ownerChange) {
      setSpouseWealthicaData((prevData) =>
        prevData?.map((institution) => ({
          ...institution,
          investments: institution?.investments?.map((investment) =>
            investment._id === investmentId
              ? { ...investment, type: newType, user: newOwner }
              : investment
          ),
        }))
      );
      updateAndSetPending(); // Call this function at the end of each branch
    } else if (newOwner === "Joint" && !ownerChange) {
      setJointWealthicaData((prevData) =>
        prevData?.map((institution) => ({
          ...institution,
          investments: institution?.investments?.map((investment) =>
            investment._id === investmentId
              ? { ...investment, type: newType, user: newOwner }
              : investment
          ),
        }))
      );
      updateAndSetPending(); // Call this function at the end of each branch
    } else if (newOwner === "None") {
      const newData = removeFromData(wealthicaData);
      const newDataSpouse = removeFromData(spouseWealthicaData);
      const newJointData = removeFromData(jointWealthicaData);

      let ignoreWealthicaDataNew = addOrUpdateInstitution(
        ignoreData,
        institutionName,
        investmentToTransfer,
        institutionType,
        institutionID
      );

      setIgnoreData(ignoreWealthicaDataNew);
      setWealthicaData(newData);
      setSpouseWealthicaData(newDataSpouse);
      setJointWealthicaData(newJointData);
      updateAndSetPending(); // Call this function at the end of each branch
    }
  };

  // Helper function to add the investment to the correct institution or create a new one if necessary
  const addOrUpdateInstitution = (
    data,
    institutionName,
    investment,
    institutionType,
    institutionID
  ) => {
    let institutionFound = false;

    // Ensure updatedData is initialized properly, even if data is undefined or null
    let updatedData = data
      ? data?.map((institution) => {
          if (institution?.name === institutionName) {
            institutionFound = true;
            return {
              ...institution,
              investments: [...institution.investments, investment],
            };
          }
          return institution;
        })
      : [];

    // If no institution was found, create a new one
    if (!institutionFound && updatedData) {
      updatedData.push({
        name: institutionName,
        type: institutionType,
        _id: institutionID,
        investments: [investment],
      });
    }

    return updatedData;
  };

  const addOrUpdateInstitutionJoint = (
    data,
    institutionName,
    investment,
    institutionType,
    institutionID
  ) => {
    let institutionFound = false;

    // Modify the investment object before using it
    const modifiedInvestment = {
      ...investment,
      currency_value: investment.currency_value / 2, // Divide the currency value by 2
    };

    // Ensure updatedData is initialized properly, even if data is undefined or null
    let updatedData = data
      ? data?.map((institution) => {
          if (institution?.name === institutionName) {
            institutionFound = true;
            return {
              ...institution,
              investments: [...institution.investments, modifiedInvestment], // Use modified investment
            };
          }
          return institution;
        })
      : [];

    // If no institution was found, create a new one
    if (!institutionFound && updatedData) {
      updatedData.push({
        name: institutionName,
        type: institutionType,
        _id: institutionID,
        investments: [modifiedInvestment], // Use modified investment
      });
    }

    return updatedData;
  };

  const findLatestSyncDate = (data, date) => {
    if (!data || data.length === 0) return null; // Early return for empty or undefined data

    // Filter out entries with 'error' status before reduction
    const filteredData = data.filter(
      (institution) => institution.sync_status !== "error"
    );

    if (filteredData.length === 0) return new Date(date); // Return the external date if no valid records exist

    const latestDate = filteredData.reduce((latest, institution) => {
      const currentDate = new Date(institution.sync_date);
      return latest > currentDate ? latest : currentDate;
    }, new Date(0)); // Start with Unix epoch as the earliest date

    return latestDate > date ? latestDate : date;
  };

  useEffect(() => {
    if (wealthicaData) {
      const newTotals = calculateTotals(wealthicaData);
      setTotals(newTotals);
      setDate(findLatestSyncDate(wealthicaData, date));
    }
  }, [wealthicaData]);

  useEffect(() => {
    if (spouseWealthicaData) {
      const newSpouseTotals = calculateTotals(spouseWealthicaData);
      setSpouseTotals(newSpouseTotals);
      setSpouseDate(findLatestSyncDate(spouseWealthicaData, spouseDate));
    }
  }, [spouseWealthicaData]);

  useEffect(() => {
    if (jointWealthicaData) {
      setJointDate(findLatestSyncDate(jointWealthicaData, jointDate));
    }
  }, [jointWealthicaData]);

  const ConfirmDeleteModal = ({
    isOpen,
    onClose,
    account,
    deleteComponent,
  }) => {
    if (!isOpen) return null;

    const [yesClicked, setYesClicked] = useState(false);
    const [noClicked, setNoClicked] = useState(false);

    return (
      <div
        className="delete-income-modal"
        style={{
          display: "flex",
          alignItems: "center",
          width: "100%",
          maxWidth: "700px",
          justifyContent: "center",
          top: "50%",
          left: "50%",
          transform: isMobile
            ? "translate(-50%, -50%)"
            : "translate(-50%, -50%)",
        }}
        id="delete-income-modal"
      >
        <Box
          display={"flex"}
          width={"100%"}
          padding={"10px 10px 0px 0px"}
          marginLeft={"5px"}
          alignItems={"flex-end"}
          justifyContent={"flex-end"}
          onClick={() => setShowDeleteModal(false)}
          cursor={"pointer"}
        >
          <CloseIcon
            onClick={() => setShowDeleteModal(false)}
            svgheight={"20px"}
            svgwidth={"20px"}
          />
        </Box>
        <Box
          display={"flex"}
          padding={"0px 25px 25px 25px"}
          flexDirection={"column"}
          alignItems={"center"}
          gap={"10px"}
          alignSelf={"stretch"}
          width={"100%"}
        >
          <Text
            color={"#0093D0"}
            fontFamily={"Nunito"}
            fontSize={"24px"}
            fontStyle={"normal"}
            fontWeight={"600"}
            lineHeight={"normal"}
            textDecor={"underline"}
          >
            Delete Account
          </Text>
          <p
            style={{
              fontFamily: "Nunito",
              fontSize: "12px",
              fontWeight: "600",
            }}
          >
            Deleting your {account.name} account will remove all linked
            investment accounts within {account.name}. To reconnect these
            investment accounts, you will need to re-sync your {account.name}{" "}
            account.
          </p>
          <p
            style={{
              fontFamily: "Nunito",
              fontSize: "12px",
              fontWeight: "600",
            }}
          >
            Are you sure?
          </p>
          <Box display={"flex"} justifyContent={"center"} gap={"10px"}>
            {/* <button
                className={`decision-button yes-button ${
                  yesClicked ? "yes-clicked" : ""
                }`}
                style={{ backgroundColor: "green", color: "white" }}
                onClick={() => {
                  setYesClicked(true);
                  setNoClicked(false);
                  deleteComponent;
                }}
              >
                Yes
              </button> */}
            {deleteComponent}
            <button
              className={`decision-button no-button ${
                noClicked ? "yes-clicked" : ""
              }`}
              style={{ backgroundColor: "red", color: "white" }}
              onClick={onClose}
            >
              No
            </button>
          </Box>
        </Box>
      </div>
    );
  };

  const ConfirmReconnectModal = ({
    isOpen,
    onClose,
    account,
    deleteComponent,
  }) => {
    if (!isOpen) return null;

    return (
      <div
        style={{
          position: "fixed",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          backgroundColor: "rgba(0,0,0,0.6)",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div
          style={{
            position: "relative",
            backgroundColor: "white",
            padding: "20px",
            borderRadius: "10px",
            maxWidth: "450px",
            textAlign: "center",
            border: "2px solid rgba(0, 162, 212, 1)",
          }}
        >
          <button
            onClick={onClose}
            style={{
              position: "absolute",
              top: "10px",
              right: "10px",
              background: "none",
              border: "none",
              fontSize: "20px",
              fontWeight: "bold",
              cursor: "pointer",
            }}
          >
            &times;
          </button>
          <h2
            style={{
              color: "rgba(0, 162, 212, 1)",
              fontFamily: "Nunito",
              fontWeight: "600",
              fontSize: "20px",
              alignContent: "center",
              textDecoration: "underline",
            }}
          >
            Security Timeout
          </h2>
          <p
            style={{
              fontFamily: "Nunito",
              fontSize: "12px",
              fontWeight: "600",
            }}
          >
            The institution {account.name} has been disconnected for security
            reasons. Please click to reconnect your institution.
          </p>
          <Box
            display={"flex"}
            justifyContent={"center"}
            gap={"10px"}
            m={"15px"}
            onClick={onClose}
          >
            {deleteComponent}
          </Box>
        </div>
      </div>
    );
  };

  // Component to display each institution's information

  const DisplayCards = ({ data, accountOwnerName, updateInvestmentData }) => {
    const [popoverOpen, setPopoverOpen] = useState(null);
    const [ignorePopoverOpen, setIgnorePopoverOpen] = useState(null);
    const [showIgnoreModal, setShowIgnoreModal] = useState(false);
    const [selectedInvestment, setSelectedInvestment] = useState(null);
    const [selectedInstitution, setSelectedInstitution] = useState(null);

    const selections = useMemo(() => {
      const initSelections = {};
      data?.forEach((institution) => {
        institution?.investments?.forEach((investment) => {
          initSelections[investment?._id] = {
            accountType: investment?.type,
            accountOwner: accountOwnerName,
          };
        });
      });
      return initSelections;
    }, [data, accountOwnerName]);

    const ConfirmIgnoreModal = ({ isOpen, onClose, account, institution }) => {
      if (!isOpen) return null;

      const [yesClicked, setYesClicked] = useState(false);
      const [noClicked, setNoClicked] = useState(false);

      return (
        <div
          className="delete-income-modal"
          style={{
            display: "flex",
            alignItems: "center",
            width: "100%",
            maxWidth: "700px",
            justifyContent: "center",
            top: "50%",
            left: "50%",
            transform: isMobile
              ? "translate(-50%, -50%)"
              : "translate(-50%, -50%)",
          }}
          id="delete-income-modal"
        >
          <Box
            display={"flex"}
            width={"100%"}
            padding={"10px 10px 0px 0px"}
            marginLeft={"5px"}
            alignItems={"flex-end"}
            justifyContent={"flex-end"}
            onClick={() => setShowIgnoreModal(false)}
            cursor={"pointer"}
          >
            <CloseIcon
              onClick={() => setShowIgnoreModal(false)}
              svgheight={"20px"}
              svgwidth={"20px"}
            />
          </Box>
          <Box
            display={"flex"}
            padding={"0px 25px 25px 25px"}
            flexDirection={"column"}
            alignItems={"center"}
            gap={"10px"}
            alignSelf={"stretch"}
            width={"100%"}
          >
            <Text
              color={"#0093D0"}
              fontFamily={"Nunito"}
              fontSize={"24px"}
              fontStyle={"normal"}
              fontWeight={"600"}
              lineHeight={"normal"}
              textDecor={"underline"}
            >
              Remove Investment
            </Text>
            <p
              style={{
                fontFamily: "Nunito",
                fontSize: "14px",
                fontWeight: "600",
              }}
            >
              Removing this {account?.name} will prevent it from displaying or
              updating in Optiml. To reconnect this account in the future, you
              will need to re-sync your entire {institution?.name} account.
            </p>
            <p
              style={{
                fontFamily: "Nunito",
                fontSize: "12px",
                fontWeight: "600",
              }}
            >
              Are you sure?
            </p>
            <Box display={"flex"} justifyContent={"center"} gap={"10px"}>
              <button
                className={`decision-button yes-button ${
                  yesClicked ? "yes-clicked" : ""
                }`}
                style={{ backgroundColor: "green", color: "white" }}
                onClick={() => {
                  setYesClicked(true);
                  setNoClicked(false);
                  ignoreClicked(account);
                }}
              >
                Yes
              </button>

              <button
                className={`decision-button no-button ${
                  noClicked ? "yes-clicked" : ""
                }`}
                style={{ backgroundColor: "red", color: "white" }}
                onClick={() => {
                  {
                    setNoClicked(true);
                    setYesClicked(false);
                    onClose();
                  }
                }}
              >
                No
              </button>
            </Box>
          </Box>
        </div>
      );
    };

    const gearClicked = (institution) => {
      setSelectedAccount({
        name: institution?.name,
        id: institution?._id,
        user: user?.sub,
        loading:
          accountOwnerName === "SPS1"
            ? setLoading
            : accountOwnerName === "SPS2"
            ? setSpouseLoading
            : setJointLoading,
        accountOwner: accountOwnerName,
      });
      setPopoverOpen(null);
      setShowDeleteModal(true);
    };

    const reconnectClicked = (institution) => {
      setSelectedAccount({
        name: institution?.name,
        id: institution?._id,
        user: user?.sub,
        loading:
          accountOwnerName === "SPS1"
            ? setLoading
            : accountOwnerName === "SPS2"
            ? setSpouseLoading
            : setJointLoading,
        accountOwner: accountOwnerName,
      });
      setPopoverOpen(null);
      setShowReconnectModal(true);
    };

    const ignoreClicked = (investment) => {
      updateInvestmentData(investment?._id, investment?.type, "None", true);
      setShowIgnoreModal(false);
      setIgnorePopoverOpen(null);
    };

    const handleAccountTypeChange = (investmentId, newType) => {
      const newOwner = selections[investmentId]?.accountOwner;
      updateInvestmentData(investmentId, newType, newOwner, false);
    };

    const handleAccountOwnerChange = (investmentId, newOwner) => {
      const newType = selections[investmentId]?.accountType;
      updateInvestmentData(investmentId, newType, newOwner, true);
    };

    const ThreeDotsIcon = () => (
      <Box display="flex" alignItems="center" justifyContent="center">
        <Box w={1} h={1} bg="blue.500" borderRadius="50%" mr={1}></Box>
        <Box w={1} h={1} bg="blue.500" borderRadius="50%" mr={1}></Box>
        <Box w={1} h={1} bg="blue.500" borderRadius="50%"></Box>
      </Box>
    );

    return (
      <Flex
        wrap="wrap"
        borderRadius="10px"
        border="1px solid var(--Optiml---Vibrant-Sky-Blue, #00A2D4)"
        mb={"10px"}
        padding={"15px 15px 0 15px"}
      >
        {accountOwnerName === "SPS1" && (
          <h2 className="wealthica-name-header">
            {userInfo?.FirstName}'s Linked Investments
          </h2>
        )}
        {accountOwnerName === "SPS2" && (
          <h2 className="wealthica-name-header">
            {userInfo?.spouseFirstName}'s Linked Investments
          </h2>
        )}
        {accountOwnerName === "Joint" && (
          <h2 className="wealthica-name-header">Joint Linked Investments</h2>
        )}
        {data?.map((institution) => (
          <Box
            key={institution?._id}
            bg="#FFF"
            // p="15px"
            w="100%"
            borderRadius="10px"
            fontSize={isMobile ? "12px" : ""}
          >
            <Flex alignItems={"flex-start"} justifyContent="space-between">
              <Flex alignItems="center">
                <Image
                  mr="16px"
                  borderRadius="full"
                  boxSize="2rem"
                  src={`https://app.wealthica.com/images/institutions/${institution?.type}.png`}
                  alt={institution?.name}
                />
                <Heading as="h3" size="sm">
                  {institution?.name}
                </Heading>
              </Flex>
              <Popover
                isOpen={popoverOpen === institution?._id}
                onClose={() => setPopoverOpen(null)}
                placement="left"
              >
                <PopoverTrigger>
                  <IconButton
                    bg={"#FFF"}
                    aria-label="Options"
                    alignItems={"flex-start"}
                    color={"#0F9CD6"}
                    icon={<FontAwesomeIcon icon={faGear} size="xl" />}
                    size="lg"
                    onClick={() => setPopoverOpen(institution?._id)}
                  />
                </PopoverTrigger>
                <PopoverContent
                  borderRadius={"10px"}
                  display={"flex"}
                  width={"auto"}
                >
                  <PopoverArrow />
                  <PopoverBody
                    display="flex"
                    alignItems="center"
                    onClick={() => gearClicked(institution)}
                    cursor="pointer"
                    color={"rgba(0, 70, 109, 1)"}
                    border={"1px solid rgba(175, 178, 175, 1)"}
                    fontFamily={"Nunito"}
                    fontSize={"14px"}
                    fontWeight={"600"}
                    borderRadius={"5px"}
                    padding={"10px"}
                    justifyContent={"space-between"}
                  >
                    <FontAwesomeIcon icon={faDeleteRight} size="xl" />
                    <Text
                      fontFamily={"Nunito"}
                      fontSize={"14px"}
                      fontWeight={"600"}
                      color={"rgba(0, 70, 109, 1)"}
                      marginLeft={"10px"}
                    >
                      Remove {institution?.name}?
                    </Text>
                  </PopoverBody>
                </PopoverContent>
              </Popover>
            </Flex>
            <Text
              font="Nunito"
              fontSize="10px"
              fontWeight="600"
              color="rgba(160, 160, 160, 1)"
              mb="16px"
            >
              Date Synced:{" "}
              {new Date(institution.sync_date)?.toLocaleDateString()}
            </Text>
            {institution?.investments?.map((investment) => (
              <Box
                key={`${institution?._id}-${investment?._id}`}
                mb="16px"
                w="100%"
                borderBottom="1px solid"
                borderColor="gray.200"
              >
                <Flex
                  justifyContent="space-between"
                  alignItems="center"
                  w="100%"
                >
                  {institution?.sync_status != "error" && (
                    <Text
                      fontWeight="bold"
                      color={"#0F9CD6"}
                      marginRight={"10px"}
                    >
                      {currencyFormat(investment?.currency_value)} CAD
                    </Text>
                  )}
                  {institution?.sync_status === "error" && (
                    <Button
                      fontWeight="bold"
                      color={"#0F9CD6"}
                      marginRight={"10px"}
                      gap={"10px"}
                      onClick={() => {
                        reconnectClicked(institution);
                      }}
                    >
                      {!isMobile && <FontAwesomeIcon icon={faSync} size="xl" />}
                      Reconnect
                    </Button>
                  )}
                  <Flex
                    flexDirection="row"
                    gap={isMobile ? "5px" : "15px"}
                    alignItems="center"
                    mb={"5px"}
                  >
                    <Select
                      value={selections[investment?._id]?.accountType || ""}
                      onChange={(e) => {
                        handleAccountTypeChange(
                          investment?._id,
                          e.target.value
                        );
                        setChangedData(true);
                      }}
                      sx={{
                        "--input-padding": isMobile
                          ? "2px"
                          : "var(--chakra-space-4)",
                        "--chakra-space-8": isMobile
                          ? "2px"
                          : "var(--chakra-space-8)",
                        paddingInlineEnd: isMobile
                          ? "2px"
                          : "var(--chakra-space-12)",
                        paddingInlineStart: "var(--input-padding)",
                        width: isMobile ? "75px" : "100%",
                        height: "var(--input-height)",
                        fontSize: isMobile ? "12px" : "var(--input-font-size)",
                        borderRadius: "var(--input-border-radius)",
                        outline: "2px solid transparent",
                        outlineOffset: "2px",
                        appearance: "none",
                        transitionProperty:
                          "var(--chakra-transition-property-common)",
                        transitionDuration:
                          "var(--chakra-transition-duration-normal)",
                        paddingBottom: "1px",
                        lineHeight: "var(--chakra-lineHeights-normal)",
                        background: "inherit",
                        border: "1px solid",
                        borderColor: !selections[investment?._id]?.accountType
                          ? "red"
                          : "inherit", // Red border if there's an error
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                      }}
                    >
                      <option value="">Account Type</option>
                      <option value="tfsa">TFSA</option>
                      <option value="rrsp">RRSP</option>
                      <option value="fhsa">FHSA</option>
                      <option value="non-reg">
                        {!isMobile ? "Non-Registered" : "Non-Reg"}
                      </option>
                      <option value="cash">CASH</option>
                      <option value="lira">LIRA</option>
                      <option value="ccpc">CCPC</option>
                    </Select>
                    <Select
                      value={selections[investment?._id]?.accountOwner || ""}
                      onChange={(e) => {
                        handleAccountOwnerChange(
                          investment?._id,
                          e.target.value
                        );
                        setChangedData(true);
                      }}
                      sx={{
                        "--input-padding": isMobile
                          ? "2px"
                          : "var(--chakra-space-4)",
                        "--chakra-space-8": isMobile
                          ? "2px"
                          : "var(--chakra-space-8)",
                        paddingInlineEnd: "var(--chakra-space-8)",
                        paddingInlineStart: "var(--input-padding)",
                        width: isMobile ? "75px" : "100%",
                        height: "var(--input-height)",
                        fontSize: isMobile ? "12px" : "var(--input-font-size)",
                        borderRadius: "var(--input-border-radius)",
                        outline: "2px solid transparent",
                        outlineOffset: "2px",
                        appearance: "none",
                        transitionProperty:
                          "var(--chakra-transition-property-common)",
                        transitionDuration:
                          "var(--chakra-transition-duration-normal)",
                        paddingBottom: "1px",
                        lineHeight: "var(--chakra-lineHeights-normal)",
                        background: "inherit",
                        border: "1px solid",
                        borderColor: "inherit",
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                      }}
                    >
                      <option value="SPS1">{userInfo?.FirstName}</option>
                      {spouseStatus && (
                        <>
                          <option value="SPS2">
                            {userInfo?.spouseFirstName}
                          </option>
                          <option value="Joint">Joint</option>
                        </>
                      )}
                    </Select>
                    {institution?.investments?.length > 1 && (
                      <Popover
                        isOpen={ignorePopoverOpen === investment?._id}
                        onClose={() => setIgnorePopoverOpen(null)}
                        placement="bottom-end"
                      >
                        <PopoverTrigger>
                          <IconButton
                            aria-label="Options"
                            icon={<ThreeDotsIcon />}
                            size="sm"
                            variant="ghost"
                            onClick={() => {
                              setIgnorePopoverOpen(investment?._id);
                              setSelectedInvestment(investment);
                              setSelectedInstitution(institution);
                            }}
                          />
                        </PopoverTrigger>
                        <PopoverContent>
                          <PopoverArrow />
                          <PopoverBody
                            display="flex"
                            alignItems="center"
                            onClick={() => {
                              setShowIgnoreModal(true);
                              setIgnorePopoverOpen(null);
                            }}
                            cursor="pointer"
                            color={"rgba(0, 70, 109, 1)"}
                            border={"1px solid rgba(175, 178, 175, 1)"}
                            fontFamily={"Nunito"}
                            fontSize={"14px"}
                            fontWeight={"600"}
                            borderRadius={"5px"}
                            padding={"10px"}
                            justifyContent={"space-between"}
                          >
                            <FontAwesomeIcon icon={faDeleteRight} size="xl" />
                            <Text
                              fontFamily={"Nunito"}
                              fontSize={"14px"}
                              fontWeight={"600"}
                              color={"rgba(0, 70, 109, 1)"}
                              marginLeft={"10px"}
                            >
                              Ignore {investment?.name}?
                            </Text>
                          </PopoverBody>
                        </PopoverContent>
                      </Popover>
                    )}
                    {institution?.investments?.length === 1 && (
                      <Popover
                        isOpen={ignorePopoverOpen === investment?._id}
                        onClose={() => setIgnorePopoverOpen(null)}
                        placement="bottom-end"
                      >
                        <PopoverTrigger>
                          <IconButton
                            aria-label="Options"
                            icon={<ThreeDotsIcon />}
                            size="sm"
                            variant="ghost"
                            onClick={() => {
                              setIgnorePopoverOpen(investment?._id);
                              setSelectedInvestment(investment);
                              setSelectedInstitution(institution);
                            }}
                          />
                        </PopoverTrigger>
                        <PopoverContent>
                          <PopoverArrow />
                          <PopoverBody
                            display="flex"
                            alignItems="center"
                            onClick={() => {
                              setIgnorePopoverOpen(null);
                              gearClicked(institution);
                            }}
                            cursor="pointer"
                            color={"rgba(0, 70, 109, 1)"}
                            border={"1px solid rgba(175, 178, 175, 1)"}
                            fontFamily={"Nunito"}
                            fontSize={"14px"}
                            fontWeight={"600"}
                            borderRadius={"5px"}
                            padding={"10px"}
                            justifyContent={"space-between"}
                          >
                            <FontAwesomeIcon icon={faDeleteRight} size="xl" />
                            <Text
                              fontFamily={"Nunito"}
                              fontSize={"14px"}
                              fontWeight={"600"}
                              color={"rgba(0, 70, 109, 1)"}
                              marginLeft={"10px"}
                            >
                              Remove {institution?.name}?
                            </Text>
                          </PopoverBody>
                        </PopoverContent>
                      </Popover>
                    )}
                  </Flex>
                </Flex>
              </Box>
            ))}
          </Box>
        ))}
        {showIgnoreModal && (
          <ConfirmIgnoreModal
            isOpen={showIgnoreModal}
            onClose={() => setShowIgnoreModal(false)}
            account={selectedInvestment}
            institution={selectedInstitution}
          />
        )}
      </Flex>
    );
  };

  let currentDate = new Date();

  return (
    <Flex
      display="flex"
      flexWrap="wrap" // Enable wrapping
      justifyContent="flex-start"
      alignItems="start"
      w="100%"
    >
      <VStack spacing={4} align="stretch" w="full" mt={-4}>
        <GlobalBodyPage
          Heading="Connected Investments"
          mainframeContent={
            <div style={{ width: "100%" }}>
              <Modal show={showModal} onClose={() => setShowModal(false)} />
              <StripeUpgradeModal
                isOpen={showStripeModal}
                closeModal={UpgradeStripeModal}
              />
              {stripePlan &&
                stripePlan?.items?.data?.[0]?.price?.lookup_key !== "PRO" &&
                stripePlan?.items?.data?.[0]?.price?.lookup_key !== "PRO_M" &&
                stripePlan?.items?.data?.[0]?.price?.lookup_key !==
                  "PREMIUM_M" &&
                stripePlan?.items?.data?.[0]?.price?.lookup_key !==
                  "PREMIUM" && (
                  <Box
                    display={"flex"}
                    flexDirection={"column"}
                    alignItems={"flex-start"}
                    gap={"19px"}
                    alignSelf={"stretch"}
                  >
                    <div
                      style={{
                        padding: "1rem",
                        width: "100%",
                        fontSize: "12px",
                        fontFamily: "Nunito",
                      }}
                      className="other-income-info-banner"
                    >
                      Accelerate your account setup by linking your bank
                      accounts. Designed with utmost security in mind, this
                      feature streamlines the consolidation of investments
                      associated with your chosen bank accounts. Effortlessly
                      integrate your financial details, whether spread across
                      various banks or contained within a single institution,
                      ensuring a seamless experience.
                    </div>
                    <div className="investment-account-setup-items">
                      <div className="sub-box">
                        {" "}
                        <Text>TFSA</Text>
                        <Text>RRSP</Text>
                        <Text>Non-Registered</Text>
                        <Text>CCPC</Text>
                        <Text>LIRA</Text>
                      </div>

                      <div className="sub-box">
                        <div className="column">
                          <div className="bullet-item">
                            <span className="bullet-dot"></span>
                            <span>Fast-Tracks Account Setup</span>
                          </div>
                          <div className="bullet-item">
                            <span className="bullet-dot"></span>
                            <span>Saves Time</span>
                          </div>
                        </div>
                        <div className="column">
                          <div className="bullet-item">
                            <span className="bullet-dot"></span>
                            <span>Efficient Future Updates</span>
                          </div>
                          <div className="bullet-item">
                            <span className="bullet-dot"></span>
                            <span>Consolidates Investments Easily</span>
                          </div>
                        </div>
                      </div>
                    </div>

                    <Box
                      display={"flex"}
                      padding={"10px"}
                      flexDirection={"column"}
                      justifyContent={"center"}
                      alignItems={"center"}
                      gap={"10px"}
                      alignSelf={"stretch"}
                      borderRadius={"5px"}
                      border={"1px solid #00A2D4"}
                      bg={"rgba(0, 162, 212, 0.10)"}
                    >
                      <Text
                        color={"#00A2D4"}
                        textAlign={"center"}
                        fontFamily={"Nunito"}
                        fontSize={"14px"}
                        fontStyle={"normal"}
                        fontWeight={"700"}
                        lineHeight={"normal"}
                      >
                        Upgrade to unlock the ability to link your investment
                        accounts.
                      </Text>
                      {stripePlan && (
                        <Button
                          onClick={UpgradeStripeModal}
                          display={"flex"}
                          padding={"10px"}
                          justifyContent={"center"}
                          alignItems={"center"}
                          gap={"10px"}
                          borderRadius={"5px"}
                          border={"1px solid #00A2D4"}
                          bg={"#FFF"}
                          _hover={{ backgroundColor: "#0D8BC4" }}
                          _active={{ backgroundColor: "#0B7AB2" }}
                          _focus={{
                            boxShadow:
                              "0px 0px 0px 3px rgba(15, 156, 214, 0.5)",
                          }}
                          className="ccpc-upgrade-btn"
                        >
                          <Text
                            color={"#00A2D4"}
                            fontFamily={"Nunito"}
                            fontSize={"16px"}
                            fontStyle={"normal"}
                            fontWeight={"600"}
                            lineHeight={"normal"}
                          >
                            Upgrade Now
                          </Text>
                        </Button>
                      )}
                    </Box>
                    {/* <div
                      className="wealthica-save-back-btn-container"
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        zIndex: "400",
                        width: "100%",
                      }}
                    >
                      <Link href="/revenue">
                        <button className="wealthica-save-rates-btn">
                          <FontAwesomeIcon icon={faCircleCheck} size="l" />
                          <span style={{ marginLeft: "1rem" }}>
                            Skip for now
                          </span>
                        </button>
                      </Link>
                    </div> */}
                  </Box>
                )}

              {stripePlan &&
                stripePlan?.items?.data?.[0]?.price?.lookup_key !==
                  "OPTIML_ESSENTIALS" &&
                stripePlan?.items?.data?.[0]?.price?.lookup_key !==
                  "OPTIML_ESSENTIALS_M" &&
                stripePlan && (
                  <div>
                    {/* <h1 className="change-plan-1-page nunito-bold-black-20px">Connected Investment Accounts</h1> */}
                    {!wealthicaData && (
                      <div style={{ gap: "10px" }}>
                        <div
                          style={{
                            padding: "1rem",
                            width: "100%",
                            marginBottom: "15px",
                          }}
                          className="other-income-info-banner"
                        >
                          Accelerate your account setup by linking your bank
                          accounts. Designed with utmost security in mind, this
                          feature streamlines the consolidation of Investments
                          associated with your chosen bank accounts.
                          Effortlessly integrate your financial details, whether
                          spread across various banks or contained within a
                          single institution, ensuring a seamless experience.
                        </div>
                        <div className="investment-account-setup-items">
                          <div className="sub-box">
                            {" "}
                            <Text>TFSA</Text>
                            <Text>RRSP</Text>
                            <Text>Non-Registered</Text>
                            <Text>CCPC</Text>
                            <Text>LIRA</Text>
                          </div>
                          <div className="sub-box">
                            <div className="column">
                              <div className="bullet-item">
                                <span className="bullet-dot"></span>
                                <span>Fast-Tracks Account Setup</span>
                              </div>
                              <div className="bullet-item">
                                <span className="bullet-dot"></span>
                                <span>Saves Time</span>
                              </div>
                            </div>
                            <div className="column">
                              <div className="bullet-item">
                                <span className="bullet-dot"></span>
                                <span>Efficient Future Updates</span>
                              </div>
                              <div className="bullet-item">
                                <span className="bullet-dot"></span>
                                <span>Consolidates Investments Easily</span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                    {!wealthicaData && (
                      <h2 className="wealthica-name-header">
                        {userInfo?.FirstName}'s Linked Investments
                      </h2>
                    )}
                    {/* {totals && wealthicaData && <InvestmentSummary totals={totals} />} */}
                    {/* SPOUSE 1 DATA */}
                    {wealthicaData && !loading && (
                      <DisplayCards
                        className="display-cards"
                        data={wealthicaData}
                        accountOwnerName="SPS1"
                        updateInvestmentData={updateInvestmentData}
                      />
                    )}
                    {loading && (
                      <CompassSpinner
                        loading={loading}
                        className="Wealthica-Loader"
                        small={true}
                      />
                    )}
                    <div className="wealthica-buttons">
                      <WealthicaAPI
                        onDataReceived={(apiData) => {
                          // Call processData with both the new API data and existing user data
                          const updatedData = processData(
                            apiData,
                            wealthicaData,
                            spouseWealthicaData,
                            jointWealthicaData,
                            ignoreData
                          );
                          setWealthicaData(updatedData);
                        }}
                        newAccount="Add"
                        userID={user?.sub}
                        accID={selectedAccount?.id}
                        setLoading={setLoading}
                        dateToCheck={date}
                        data={wealthicaData}
                      />

                      <WealthicaAPI
                        onDataReceived={(apiData) => {
                          // Call processData with both the new API data and existing user data
                          const updatedData = processData(
                            apiData,
                            wealthicaData,
                            spouseWealthicaData,
                            jointWealthicaData,
                            ignoreData
                          );
                          setWealthicaData(updatedData);
                          setDate(currentDate);
                        }}
                        newAccount="Sync"
                        userID={user?.sub}
                        accID={selectedAccount?.id}
                        setLoading={setLoading}
                        dateToCheck={date}
                        data={wealthicaData}
                      />
                      {/* {!loadOptiml && (wealthicaData || spouseWealthicaData) && <button onClick={submitHandler} className='load-wealthica-optiml-btn'>
                        <FontAwesomeIcon icon={faLoader} size='xl' />
                        <span className='sync-accounts-title'>Load Balances Into Optiml</span>
                      </button>}
                      {loadOptiml && <button className='load-wealthica-optiml-btn'>
                        <FontAwesomeIcon icon={faLoader} size='xl' />
                        <span className='sync-accounts-title'>Loading...</span>
                      </button>} */}
                    </div>

                    {/* SPOUSE 2 DATA */}
                    {spouseStatus && !spouseWealthicaData && (
                      <h2
                        style={{ marginTop: "15px" }}
                        className="wealthica-name-header"
                      >
                        {userInfo?.spouseFirstName}'s Linked Investments
                      </h2>
                    )}
                    {spouseWealthicaData && !spouseLoading && (
                      <DisplayCards
                        className="display-cards"
                        data={spouseWealthicaData}
                        accountOwnerName="SPS2"
                        updateInvestmentData={updateInvestmentData}
                      />
                    )}
                    {spouseLoading && (
                      <CompassSpinner
                        loading={spouseLoading}
                        className="Wealthica-Loader"
                        small={true}
                      />
                    )}
                    {spouseStatus && (
                      <div className="wealthica-buttons">
                        <WealthicaAPI
                          onDataReceived={(apiData) => {
                            // Call processData with both the new API data and existing user data
                            const updatedData = processData(
                              apiData,
                              spouseWealthicaData,
                              wealthicaData,
                              jointWealthicaData,
                              ignoreData
                            );
                            setSpouseWealthicaData(updatedData);
                          }}
                          newAccount="Add"
                          userID={user?.sub}
                          accID={selectedAccount?.id}
                          setLoading={setSpouseLoading}
                          dateToCheck={spouseDate}
                          data={wealthicaData}
                        />
                        <WealthicaAPI
                          onDataReceived={(apiData) => {
                            // Call processData with both the new API data and existing user data
                            const updatedData = processData(
                              apiData,
                              spouseWealthicaData,
                              wealthicaData,
                              jointWealthicaData,
                              ignoreData
                            );
                            setSpouseWealthicaData(updatedData);
                            setSpouseDate(currentDate);
                          }}
                          newAccount="Sync"
                          userID={user?.sub}
                          accID={selectedAccount?.id}
                          setLoading={setSpouseLoading}
                          dateToCheck={spouseDate}
                          data={spouseWealthicaData}
                        />
                      </div>
                    )}

                    {/* JOINT ACCOUNTS */}
                    {spouseStatus && !jointWealthicaData && (
                      <h2
                        style={{ marginTop: "15px" }}
                        className="wealthica-name-header"
                      >
                        Joint Accounts
                      </h2>
                    )}
                    {jointWealthicaData && !jointLoading && (
                      <DisplayCards
                        className="display-cards"
                        data={jointWealthicaData}
                        accountOwnerName="Joint"
                        updateInvestmentData={updateInvestmentData}
                      />
                    )}
                    {jointLoading && (
                      <CompassSpinner
                        loading={jointLoading}
                        className="Wealthica-Loader"
                        small={true}
                      />
                    )}
                    {spouseStatus && (
                      <div className="wealthica-buttons">
                        <WealthicaAPI
                          onDataReceived={(apiData) => {
                            // Call processData with both the new API data and existing user data
                            const updatedData = processData(
                              apiData,
                              jointWealthicaData,
                              spouseWealthicaData,
                              wealthicaData,
                              ignoreData
                            );
                            setJointWealthicaData(updatedData);
                          }}
                          newAccount="Add"
                          userID={user?.sub}
                          accID={selectedAccount?.id}
                          setLoading={setJointLoading}
                          dateToCheck={jointDate}
                          data={wealthicaData}
                        />
                        <WealthicaAPI
                          onDataReceived={(apiData) => {
                            // Call processData with both the new API data and existing user data
                            const updatedData = processData(
                              apiData,
                              jointWealthicaData,
                              spouseWealthicaData,
                              wealthicaData,
                              ignoreData
                            );
                            setJointWealthicaData(updatedData);
                            setJointDate(currentDate);
                          }}
                          newAccount="Sync"
                          userID={user?.sub}
                          accID={selectedAccount?.id}
                          setLoading={setJointLoading}
                          dateToCheck={jointDate}
                          data={jointWealthicaData}
                        />
                      </div>
                    )}

                    <div
                      className="wealthica-save-back-btn-container"
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        zIndex: "400",
                        width: "100%",
                      }}
                    >
                      {/* {(wealthicaData || spouseWealthicaData) && (
                        <Link href="/revenue">
                          <button
                            onClick={submitHandler}
                            className="wealthica-save-rates-btn"
                            style={{ marginTop: "1rem" }}
                          >
                            <FontAwesomeIcon icon={faCircleCheck} size="l" />
                            <span style={{ marginLeft: "1rem" }}>Save</span>
                          </button>
                        </Link>
                      )}
                      {!wealthicaData && !spouseWealthicaData && (
                        <Link href="/revenue">
                          <button className="wealthica-save-rates-btn">
                            <FontAwesomeIcon icon={faCircleCheck} size="l" />
                            <span style={{ marginLeft: "1rem" }}>
                              Skip for now
                            </span>
                          </button>
                        </Link>
                      )} */}
                    </div>
                    {showDeleteModal && (
                      <ConfirmDeleteModal
                        isOpen={showDeleteModal}
                        onClose={() => setShowDeleteModal(false)}
                        account={selectedAccount}
                        deleteComponent={
                          <div>
                            <WealthicaAPI
                              onDataReceived={(apiData) => {
                                // Call processData with both the new API data and existing user data
                                const updatedData = processData(
                                  apiData,
                                  wealthicaData,
                                  spouseWealthicaData,
                                  jointWealthicaData,
                                  ignoreData
                                );
                                const spouseUpdatedData = processData(
                                  apiData,
                                  spouseWealthicaData,
                                  wealthicaData,
                                  jointWealthicaData,
                                  ignoreData
                                );
                                const jointUpdatedData = processData(
                                  apiData,
                                  jointWealthicaData,
                                  wealthicaData,
                                  spouseWealthicaData,
                                  ignoreData
                                );
                                if (selectedAccount?.accountOwner === "SPS1") {
                                  setWealthicaData(updatedData);
                                  submitHandler(
                                    false,
                                    updatedData,
                                    selectedAccount?.accountOwner
                                  );
                                } else if (
                                  selectedAccount?.accountOwner === "SPS2"
                                ) {
                                  setSpouseWealthicaData(spouseUpdatedData);
                                  submitHandler(
                                    false,
                                    spouseUpdatedData,
                                    selectedAccount?.accountOwner
                                  );
                                } else if (
                                  selectedAccount?.accountOwner === "Joint"
                                ) {
                                  setJointWealthicaData(jointUpdatedData);
                                  submitHandler(
                                    false,
                                    jointUpdatedData,
                                    selectedAccount?.accountOwner
                                  );
                                }

                                setShowDeleteModal(false); // Optionally close after success
                              }}
                              newAccount="Delete"
                              userID={selectedAccount?.user}
                              accID={selectedAccount?.id}
                              setLoading={selectedAccount?.loading}
                              data={wealthicaData}
                            />
                          </div>
                        }
                      />
                    )}
                    {showReconnectModal && (
                      <ConfirmReconnectModal
                        isOpen={showReconnectModal}
                        onClose={() => setShowReconnectModal(false)}
                        account={selectedAccount}
                        deleteComponent={
                          <div>
                            <WealthicaAPI
                              onDataReceived={(apiData) => {
                                // Call processData with both the new API data and existing user data
                                const updatedData = processData(
                                  apiData,
                                  wealthicaData,
                                  spouseWealthicaData,
                                  jointWealthicaData,
                                  ignoreData
                                );
                                const spouseUpdatedData = processData(
                                  apiData,
                                  spouseWealthicaData,
                                  wealthicaData,
                                  jointWealthicaData,
                                  ignoreData
                                );
                                const jointUpdatedData = processData(
                                  apiData,
                                  jointWealthicaData,
                                  wealthicaData,
                                  spouseWealthicaData,
                                  ignoreData
                                );
                                if (selectedAccount?.accountOwner === "SPS1") {
                                  setWealthicaData(updatedData);
                                  submitHandler(
                                    false,
                                    updatedData,
                                    selectedAccount?.accountOwner
                                  );
                                } else if (
                                  selectedAccount?.accountOwner === "SPS2"
                                ) {
                                  setSpouseWealthicaData(spouseUpdatedData);
                                  submitHandler(
                                    false,
                                    spouseUpdatedData,
                                    selectedAccount?.accountOwner
                                  );
                                } else if (
                                  selectedAccount?.accountOwner === "Joint"
                                ) {
                                  setJointWealthicaData(jointUpdatedData);
                                  submitHandler(
                                    false,
                                    jointUpdatedData,
                                    selectedAccount?.accountOwner
                                  );
                                }

                                setShowDeleteModal(false); // Optionally close after success
                              }}
                              newAccount="Delete/Add"
                              userID={selectedAccount?.user}
                              accID={selectedAccount?.id}
                              setLoading={selectedAccount?.loading}
                              data={wealthicaData}
                            />
                          </div>
                        }
                      />
                    )}
                  </div>
                )}
            </div>
          }
        />
      </VStack>
    </Flex>
  );
});

export default InvestmentAccountsPage;
