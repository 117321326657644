import {
  Box,
  Button,
  Flex,
  Icon,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  Modal,
  Text,
  VStack,
  useDisclosure,
  ModalHeader,
  useMediaQuery,
  HStack,
  Input,
  Image,
} from "@chakra-ui/react";
import AWS from "aws-sdk";
import React, { useEffect, useRef, useState } from "react";
import { CloseIcon, VideoPlayCustomIcon } from "../CustomIcons";
import GlobalBodyPage from "../GlobalContainer/BodyPage";
import "./TutorialVideoPage.css";

function TutorialVideoPage() {
  const [showModal, setShowModal] = useState(false);
  const [activeItem, setActiveItem] = useState("Welcome");
  const [activeVideo, setActiveVideo] = useState("Welcome.mp4");
  const [presignedUrl, setPresignedUrl] = useState(null);

  useEffect(() => {
    // Configure AWS SDK with environment variables
    AWS.config.update({
      accessKeyId: "AKIA2BWNKJSSRZAU3UV2",
      secretAccessKey: "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",
      region: "us-east-2",
    });

    // Create a new S3 instance
    const s3 = new AWS.S3();

    // Specify the bucket and object key
    const bucketName = "oltrefinancialhelpvideos";
    const objectKey = activeVideo;

    // Set the expiration time for the pre-signed URL (in seconds)
    const expirationTime = 3600; // 1 hour

    // Generate a pre-signed URL
    const params = {
      Bucket: bucketName,
      Key: objectKey,
      Expires: expirationTime,
    };
    s3.getSignedUrl("getObject", params, (err, url) => {
      if (err) {
        console.error("Error generating pre-signed URL:", err);
      } else {
        setPresignedUrl(url);
      }
    });
  }, [activeVideo]);

  const handleItemClick = (identifier, videName) => {
    setActiveItem(identifier);
    setActiveVideo(videName);
  };

  const handleItemClickAndOpen = (label, url) => {
    handleItemClick(label, url);
    onOpen();
  };

  const highlightText = (text, highlight) => {
    if (!highlight) return text;

    const parts = text.split(new RegExp(`(${highlight})`, "gi"));
    return parts.map((part, index) =>
      part.toLowerCase() === highlight.toLowerCase() ? (
        <span key={index} style={{ backgroundColor: "yellow" }}>
          {part}
        </span>
      ) : (
        part
      )
    );
  };

  const [searchTerm, setSearchTerm] = useState("");
  const [currentMatchIndex, setCurrentMatchIndex] = useState(0);
  const [matches, setMatches] = useState([]);
  const itemRefs = useRef({});

  const videoItems = [
    {
      label: "Welcome",
      url: "Welcome.mp4",
      thumbnailPath: "assets/img/COVER_IMAGES/welcome.jpg",
    },

    {
      label: "Settings",
      children: [
        {
          label: "Growth Rates",
          url: "GrowthRates_3.mp4",
          thumbnailPath: "assets/img/COVER_IMAGES/growth_rates.jpg",
        },
        {
          label: "Profile Page",
          url: "Profile_Page.mp4",
          thumbnailPath: "assets/img/COVER_IMAGES/profile_page.jpg",
        },
      ],
    },
    {
      label: "Income",
      children: [
        {
          label: "Salary Page",
          url: "Income_page.mp4",
          thumbnailPath: "assets/img/COVER_IMAGES/salary.jpg",
        },
        {
          label: "CPP Page",
          url: "CPP_Page.mp4",
          thumbnailPath: "assets/img/COVER_IMAGES/CPP.jpg",
        },
        {
          label: "OAS Page",
          url: "Old_Age_Security.mp4",
          thumbnailPath: "assets/img/COVER_IMAGES/OAS.jpg",
        },
        {
          label: "Pension Page",
          url: "Pension_Page.mp4",
          thumbnailPath: "assets/img/COVER_IMAGES/Pension.png",
        },
        {
          label: "Other Income Page",
          url: "Other_Income_Page.mp4",
          thumbnailPath: "assets/img/COVER_IMAGES/Other_income.jpg",
        },
      ],
    },
    {
      label: "Investments",
      children: [
        {
          label: "Registered",
          subChildren: [
            {
              label: "TFSA",
              url: "TFSA.mp4",
              thumbnailPath: "assets/img/COVER_IMAGES/TFSA.jpg",
            },
            {
              label: "RRSP",
              url: "RRSP.mp4",
              thumbnailPath: "assets/img/COVER_IMAGES/RRSP.jpg",
            },
            {
              label: "LIRA",
              url: "Other_registered_accounts.mp4",
              thumbnailPath: "assets/img/COVER_IMAGES/Registered_Other.jpg",
            },
          ],
        },
        {
          label: "Non-registered",
          subChildren: [
            {
              label: "Non-registered - Equities",
              url: "Non-Registered_Investments.mp4",
              thumbnailPath: "assets/img/COVER_IMAGES/Non_Reg.jpg",
            },

            {
              label: "Fixed Income",
              url: "Fixed_Income_Page.mp4",
              thumbnailPath: "assets/img/COVER_IMAGES/Fixed_income.png",
            },
            // { label: "Non-registered - Fixed Income", url: "Fixed_Income.mp4" },
          ],
        },
      ],
    },
    {
      label: "Assets",
      children: [
        {
          label: "Real Estate",
          subChildren: [
            {
              label: "Primary Residence",
              url: "Primary_Residence.mp4",
              thumbnailPath: "assets/img/COVER_IMAGES/Primary_Residence.jpg",
            },
            {
              label: "Recreational Property",
              url: "Recreational_Property.mp4",
              thumbnailPath: "assets/img/COVER_IMAGES/Recreation_Property.jpg",
            },
            {
              label: "Income Property",
              url: "Income_property.mp4",
              thumbnailPath: "assets/img/COVER_IMAGES/Income_Property.jpg",
            },
          ],
        },
        {
          label: "Insurance",
          subChildren: [
            {
              label: "Life Insurance",
              url: "Life_Insurance.mp4",
              thumbnailPath: "assets/img/COVER_IMAGES/Life_Insurance.jpg",
            },
          ],
        },
        {
          label: "Business - Canadian-controlled private corporation (CCPC)",
          subChildren: [
            {
              label: "Business",
              url: "Business.mp4",
              thumbnailPath: "assets/img/COVER_IMAGES/Business(CCPC).png",
            },
          ],
        },
        {
          label: "Personal Assets",
          subChildren: [
            {
              label: "Personal Assets",
              url: "Personal_assets.mp4",
              thumbnailPath: "assets/img/COVER_IMAGES/Personal_Assets.jpg",
            },
          ],
        },
      ],
    },
    {
      label: "Expenses",
      children: [
        {
          label: "Debt",
          url: "DEBT.mp4",
          thumbnailPath: "assets/img/COVER_IMAGES/Debt.jpg",
        },
        {
          label: "Annual Expenses",
          url: "Annual_Expenses.mp4",
          thumbnailPath: "assets/img/COVER_IMAGES/Expenses.jpg",
        },
      ],
    },
    {
      label: "Optimization Strategies",
      children: [
        {
          label: "Max Value",
          url: "Max_Value.mp4",
          thumbnailPath: "assets/img/COVER_IMAGES/Max_Value.jpg",
        },
        {
          label: "Set Value",
          url: "Set_value.mp4",
          thumbnailPath: "assets/img/COVER_IMAGES/Set_Value.jpg",
        },
        {
          label: "Max Spend",
          url: "Max_spend.mp4",
          thumbnailPath: "assets/img/COVER_IMAGES/Max_Spend.jpg",
        },
      ],
    },
    {
      label: "Navigating",
      url: "Dashboard.mp4",
      thumbnailPath: "assets/img/COVER_IMAGES/Dashboard.jpg",
    },
  ];

  const generateSectionRefs = (items) => {
    let refs = {};
    items.forEach((item) => {
      const sectionRef = item.label.replace(/ /g, "");
      refs[sectionRef] = React.useRef(null);
      if (item.children) {
        const childRefs = generateSectionRefs(item.children);
        refs = { ...refs, ...childRefs };
      }
      if (item.subChildren) {
        const subChildRefs = generateSectionRefs(item.subChildren);
        refs = { ...refs, ...subChildRefs };
      }
    });
    return refs;
  };
  const sectionRefs = generateSectionRefs(videoItems);

  const jumpToContent = (label) => {
    label = label.toLowerCase(); // convert label to lowercase
    const sectionRef = Object.keys(sectionRefs).find(
      (ref) => ref.toLowerCase() === label
    ); // find the ref case-insensitively

    if (sectionRef && sectionRefs[sectionRef]) {
      const section = sectionRefs[sectionRef].current;
      if (section) {
        section.scrollIntoView({ behavior: "smooth" });
      }
    } else {
      console.warn(`No ref found for label: ${label}`);
    }
  };

  useEffect(() => {
    videoItems.forEach((item) => {
      if (!itemRefs.current[item.label]) {
        itemRefs.current[item.label] = React.createRef();
      }
    });
  }, []);

  const handleSearchChange = (event) => {
    const term = event.target.value.toLowerCase(); // convert term to lowercase
    setSearchTerm(term);

    if (term) {
      const newMatches = videoItems.filter(
        (item) => item.label.toLowerCase().includes(term) // convert label to lowercase
      );
      setMatches(newMatches);
      setCurrentMatchIndex(0);

      if (
        newMatches.length > 0 &&
        itemRefs.current[newMatches[0].id]?.current
      ) {
        itemRefs.current[newMatches[0].id].current.scrollIntoView({
          behavior: "smooth",
        });
      }
    } else {
      setMatches([]);
    }
  };
  const inputRef = useRef();

  useEffect(() => {
    inputRef.current.focus();
  }, [searchTerm, matches]);

  const HeaderSearch = () => {
    useEffect(() => {
      if (matches.length > 0) {
        const match = matches[currentMatchIndex];
        if (itemRefs.current[match.id]) {
          const item = itemRefs.current[match.id].current;
          if (item) {
            item.scrollIntoView({
              behavior: "smooth",
            });
          }
        } else {
          console.warn(`No ref found for id: ${match.id}`);
        }
      }
    }, [currentMatchIndex, matches]);
    return (
      <Box
        display={"flex"}
        justifyContent={"space-between"}
        alignItems={"center"}
        alignSelf={"stretch"}
        flexWrap={"wrap"}
        marginBottom={"10px"}
        w={"-webkit-fill-available"}
      >
        <Text
          color={"#00A2D4"}
          fontFamily={"Nunito"}
          fontSize={"20px"}
          fontStyle={"normal"}
          fontWeight={"700"}
          lineHeight={"normal"}
          textAlign={"center"}
        >
          Video Library
        </Text>
        <Box display={"flex"} alignItems={"center"}>
          <Box
            display={"flex"}
            width={"173px"}
            height={"28px"}
            flexShrink={0}
            border={"1px solid #00A2D4"}
            borderRadius={"5px"}
            background={"#F5FAFF"}
          >
            <Input
              ref={inputRef}
              placeholder="Search"
              value={searchTerm}
              onChange={handleSearchChange}
              border="none"
              height="100%"
              width="100%"
              padding="0 8px"
              _focus={{ boxShadow: "none" }}
              onKeyPress={(event) => {
                if (event.key === "Enter") {
                  jumpToContent(searchTerm);
                }
              }}
            />
          </Box>
          <Button
            onClick={() => jumpToContent(searchTerm)}
            disabled={!searchTerm}
          >
            Jump to Content
          </Button>
        </Box>
      </Box>
    );
  };

  const { isOpen, onOpen, onClose } = useDisclosure();
  //Collapsible Item:
  // const CollapsibleListItem = ({ label, children }) => {
  //   const [open, setOpen] = useState(() => {
  //     var flag = false;
  //     for (var i = 0; i < children.length; i++) {
  //       if (children[i].props.children === activeItem) {
  //         flag = true;
  //       }
  //     }
  //     return flag;
  //   });

  //   const handleToggle = (event) => {
  //     event.stopPropagation();
  //     setOpen(!open);
  //   };

  //   return (
  //     <li style={{ cursor: "pointer" }}>
  //       <span
  //         onClick={(e) => handleToggle(e)}
  //         className="collapsible-list-item"
  //       >
  //         {label}
  //       </span>
  //       <Collapse in={open}>
  //         <ul class="innerListItem">{children}</ul>
  //       </Collapse>
  //     </li>
  //   );
  // };

  const [isLargerThan1200] = useMediaQuery(
    "(min-width: 500px) and (max-width: 1280px)"
  );
  const isMobile = !isLargerThan1200;

  const [isLargerThan500] = useMediaQuery("(min-width: 500px)");

  return (
    <Box
      width={"100%"}
      bg={"#F3FAFF"}
      paddingTop={isMobile ? "" : "15px"}
      display={"flex"}
      alignContent={"center"}
      alignItems={"center"}
      justifyContent={"center"}
    >
      <div className="tutorial-main-box">
        <GlobalBodyPage
          mainframeContent={
            <Box
              display={"flex"}
              padding={isMobile ? "0px" : "35px"}
              gap={"10px"}
              flexDirection={"column"}
              alignItems={"flex-start"}
              alignSelf={"stretch"}
              width={"-webkit-fill-available"}
            >
              <HeaderSearch />

              <Modal
                isOpen={isOpen}
                onClose={onClose}
                isCentered={true}
                size={isLargerThan1200 ? "lg":"3xl"}
                width={"100%"}
              >
                <ModalOverlay />
                <ModalContent>
                  <VStack
                    alignItems={"flex-end"}
                    paddingTop={"15px"}
                    gap={"10px"}
                    spacing={"10px"}
                    w={"100%"}
                  >
                    <Box display={"flex"} onClick={onClose} cursor={"pointer"} padding={"0px 10px 0px 0px"} >
                      <CloseIcon
                        onClick={onClose}
                        svgheight={"20px"}
                        svgwidth={"20px"}
                      />
                    </Box>
                    <div class="videoContentContainer">
                      {presignedUrl && (
                        <iframe
                          title="Embedded Video"
                          style={{
                            width: "100%",
                            height: "100%",
                          }}
                          src={presignedUrl}
                          allow="encrypted-media"
                          allowFullScreen
                        ></iframe>
                      )}
                    </div>
                  </VStack>
                </ModalContent>
              </Modal>

              {videoItems.map((item) =>
                item.children ? (
                  <Box
                    display={"flex"}
                    padding={"20px"}
                    alignItems={"flex-start"}
                    gap={!isLargerThan500 ? "10px" : "10px 22px"}
                    flexWrap={"wrap"}
                    alignSelf={"stretch"}
                    borderRadius={"10px"}
                    border={"1px solid #00A2D4"}
                    bg={"#F3FAFF"}
                    key={item.label}
                    ref={sectionRefs[item.label]}
                  >
                    <Text
                      color={"#00466D"}
                      fontFamily={"Nunito"}
                      fontSize={"20px"}
                      fontStyle={"normal"}
                      fontWeight={"700"}
                      lineHeight={"normal"}
                      width={"100%"}
                    >
                      {highlightText(`${item.label}`, searchTerm)}
                    </Text>

                    {item.children.map((child) => {
                      if (child.subChildren && child.subChildren.length > 0) {
                        return (
                          <>
                            <Text
                              color={"#00466D"}
                              fontFamily={"Nunito"}
                              fontSize={"15px"}
                              fontStyle={"normal"}
                              fontWeight={"700"}
                              lineHeight={"normal"}
                              width={"100%"}
                              ref={sectionRefs[child.label]}
                            >
                              {highlightText(`${child.label}`, searchTerm)}
                            </Text>

                            {child.subChildren.map((subChild) => (
                              <Box
                                display={"flex"}
                                flexDirection={"column"}
                                alignItems={"center"}
                                gap={"3px"}
                                cursor={"pointer"}
                                width={!isLargerThan500 ? "48%" : "134px"}
                                onClick={() =>
                                  handleItemClickAndOpen(
                                    subChild.label,
                                    subChild.url
                                  )
                                }
                              >
                                <Box
                                  display={"flex"}
                                  width={
                                    !isLargerThan500
                                      ? "-webkit-fll-available"
                                      : "134px"
                                  }
                                  height={"89px"}
                                  padding={"1px"}
                                  alignItems={"center"}
                                  border={"1px solid #00A2D4"}
                                  borderRadius={"5px"}
                                  bg={"#E6E6E6"}
                                  cursor={"pointer"}
                                  onClick={() =>
                                    handleItemClickAndOpen(
                                      subChild.label,
                                      subChild.url
                                    )
                                  }
                                >
                                  <Image // Replace this with your thumbnail component
                                    src={subChild.thumbnailPath} // Replace this with your thumbnail URL
                                    minW={"100%"}
                                    minH={"100%"}
                                  />
                                  <Icon
                                    as={VideoPlayCustomIcon}
                                    svgwidth={"82px"}
                                    svgheight={"82px"}
                                  />
                                </Box>

                                <Text
                                  color={"#0093D0"}
                                  fontFamily={"Nunito"}
                                  fontSize={"14px"}
                                  fontStyle={"normal"}
                                  fontWeight={"700"}
                                  lineHeight={"normal"}
                                  textAlign={"center"}
                                  id="VideoTitle"
                                >
                                  {subChild.label}
                                </Text>
                              </Box>
                            ))}
                          </>
                        );
                      } else {
                        return (
                          <Box
                            display={"flex"}
                            flexDirection={"column"}
                            alignItems={"center"}
                            gap={"3px"}
                            cursor={"pointer"}
                            width={!isLargerThan500 ? "48%" : "134px"}
                            cursor={"pointer"}
                            onClick={() =>
                              handleItemClickAndOpen(child.label, child.url)
                            }
                          >
                            <Box
                              display={"flex"}
                              width={
                                !isLargerThan500
                                  ? "-webkit-fll-available"
                                  : "134px"
                              }
                              height={"89px"}
                              padding={"1px"}
                              alignItems={"center"}
                              border={"1px solid #00A2D4"}
                              borderRadius={"5px"}
                              bg={"#E6E6E6"}
                              cursor={"pointer"}
                              onClick={() =>
                                handleItemClickAndOpen(child.label, child.url)
                              }
                            >
                              <Image // Replace this with your thumbnail component
                                src={child.thumbnailPath} // Replace this with your thumbnail URL
                                minW={"100%"}
                                minH={"100%"}
                              />
                              <Icon
                                as={VideoPlayCustomIcon}
                                svgwidth={"82px"}
                                svgheight={"82px"}
                              />
                            </Box>

                            <Text
                              color={"#0093D0"}
                              fontFamily={"Nunito"}
                              fontSize={"14px"}
                              fontStyle={"normal"}
                              fontWeight={"700"}
                              lineHeight={"normal"}
                              textAlign={"center"}
                              id="VideoTitle"
                            >
                              {child.label}
                            </Text>
                          </Box>
                        );
                      }
                    })}
                  </Box>
                ) : (
                  <Box
                    display={"flex"}
                    padding={"20px"}
                    flexDirection={"column"}
                    alignItems={"flex-start"}
                    gap={!isLargerThan500 ? "10px" : "10px 22px"}
                    alignSelf={"stretch"}
                    flexWrap={"wrap"}
                    borderRadius={"10px"}
                    border={"1px solid #00A2D4"}
                    bg={"#F3FAFF"}
                    key={item.label}
                  >
                    <Text
                      color={"#00466D"}
                      fontFamily={"Nunito"}
                      fontSize={"20px"}
                      fontStyle={"normal"}
                      fontWeight={"700"}
                      lineHeight={"normal"}
                      id="VideoTitle"
                    >
                      {item.label}
                    </Text>
                    <Box
                      display={"flex"}
                      flexDirection={"column"}
                      alignItems={"center"}
                      gap={"3px"}
                      cursor={"pointer"}
                      width={!isLargerThan500 ? "48%" : "134px"}
                      cursor={"pointer"}
                      onClick={() =>
                        handleItemClickAndOpen(item.label, item.url)
                      }
                    >
                      <Box
                        display={"flex"}
                        width={
                          !isLargerThan500 ? "-webkit-fll-available" : "134px"
                        }
                        height={"89px"}
                        padding={"1px"}
                        alignItems={"center"}
                        border={"1px solid #00A2D4"}
                        borderRadius={"5px"}
                        bg={"#E6E6E6"}
                        onClick={() =>
                          handleItemClickAndOpen(item.label, item.url)
                        }
                      >
                        <Image // Replace this with your thumbnail component
                          src={item.thumbnailPath} // Replace this with your thumbnail URL
                          minW={"100%"}
                          minH={"100%"}
                        />
                        <Icon
                          as={VideoPlayCustomIcon}
                          svgwidth={"82px"}
                          svgheight={"82px"}
                        />
                      </Box>

                      <Text
                        color={"#0093D0"}
                        fontFamily={"Nunito"}
                        fontSize={"14px"}
                        fontStyle={"normal"}
                        fontWeight={"700"}
                        lineHeight={"normal"}
                        textAlign={"center"}
                        id="VideoTitle"
                      >
                        {item.label}
                      </Text>
                    </Box>
                  </Box>
                )
              )}
            </Box>
          }
        />
      </div>
    </Box>
  );
}

{
  /* <ul class="vertical-list">
                  <li className={`mainListItem ${activeItem === 'Welcome' ? 'active' : ''}`} onClick={(e) => handleItemClick('Welcome', 'Welcome.mp4', e)}>Welcome</li>
                  <li className={`mainListItem ${activeItem === 'Profile Page' ? 'active' : ''}`} onClick={(e) => handleItemClick('Profile Page', 'Profile_Page.mp4', e)}>Profile Page</li>
                  <li className={`mainListItem ${activeItem === 'Settings Page' ? 'active' : ''}`} onClick={(e) => handleItemClick('Settings Page', 'GrowthRates_3.mp4', e)}>Settings Page</li>
                  <CollapsibleListItem label="Income">
                    <li className={`innerListItem ${activeItem === 'Salary Page' ? 'active' : ''}`} onClick={(e) => handleItemClick('Salary Page', 'Income_page.mp4', e)}>Salary Page</li>
                    <li className={`innerListItem ${activeItem === 'CPP Page' ? 'active' : ''}`} onClick={(e) => handleItemClick('CPP Page', 'CPP_Page.mp4', e)}>CPP Page</li>
                    <li className={`innerListItem ${activeItem === 'OAS Page' ? 'active' : ''}`} onClick={(e) => handleItemClick('OAS Page', 'Old_Age_Security.mp4', e)}>OAS Page</li>
                    <li className={`innerListItem ${activeItem === 'Pension Page' ? 'active' : ''}`} onClick={(e) => handleItemClick('Pension Page', 'Pension_Page.mp4', e)}>Pension Page</li>
                    <li className={`innerListItem ${activeItem === 'Other Income Page' ? 'active' : ''}`} onClick={(e) => handleItemClick('Other Income Page', 'Other_Income_Page.mp4', e)}>Other Income Page</li>
                  </CollapsibleListItem>
                  <CollapsibleListItem label="Investments">
                    <li className={`innerListItem ${activeItem === 'TFSA' ? 'active' : ''}`} onClick={(e) => handleItemClick('TFSA', 'TFSA.mp4', e)}>TFSA</li>
                    <li className={`innerListItem ${activeItem === 'RRSP' ? 'active' : ''}`} onClick={(e) => handleItemClick('RRSP', 'RRSP.mp4', e)}>RRSP</li>
                    <li className={`innerListItem ${activeItem === 'Other' ? 'active' : ''}`} onClick={(e) => handleItemClick('Other', 'Other_registered_accounts.mp4', e)}>Other</li>
                    <li className={`innerListItem ${activeItem === 'Non-registered - Equities' ? 'active' : ''}`} onClick={(e) => handleItemClick('Non-registered - Equities', 'Non-Registered_Investments.mp4', e)}>Non-registered - Equities</li>
                    <li className={`innerListItem ${activeItem === 'Fixed Income' ? 'active' : ''}`} onClick={(e) => handleItemClick('Fixed Income', 'Fixed_Income_Page.mp4', e)}>Fixed Income</li>
                    <li className={`innerListItem ${activeItem === 'Non-registered - Fixed Income' ? 'active' : ''}`} onClick={(e) => handleItemClick('Non-registered - Fixed Income', e)}>Non-registered - Fixed Income</li>
                  </CollapsibleListItem>
                  <CollapsibleListItem label="Assets">
                    <li className={`innerListItem ${activeItem === 'Primary Residence' ? 'active' : ''}`} onClick={(e) => handleItemClick('Primary Residence', 'Primary_Residence.mp4', e)}>Primary Residence</li>
                    <li className={`innerListItem ${activeItem === 'Recreational Property' ? 'active' : ''}`} onClick={(e) => handleItemClick('Recreational Property', 'Recreational_Property.mp4', e)}>Recreational Property</li>
                    <li className={`innerListItem ${activeItem === 'Income Property' ? 'active' : ''}`} onClick={(e) => handleItemClick('Income Property', 'Income_property.mp4', e)}>Income Property</li>
                    <li className={`innerListItem ${activeItem === 'Life Insurance' ? 'active' : ''}`} onClick={(e) => handleItemClick('Life Insurance', 'Life_Insurance.mp4', e)}>Life Insurance</li>
                    <li className={`innerListItem ${activeItem === 'Business' ? 'active' : ''}`} onClick={(e) => handleItemClick('Business', e)}>Business</li>
                    <li className={`innerListItem ${activeItem === 'Personal Assets' ? 'active' : ''}`} onClick={(e) => handleItemClick('Personal Assets', 'Personal_assets.mp4', e)}>Personal Assets</li>
                  </CollapsibleListItem>
                  <CollapsibleListItem label="Expenses">
                    <li className={`innerListItem ${activeItem === 'Debt' ? 'active' : ''}`} onClick={(e) => handleItemClick('Debt', 'DEBT.mp4', e)}>Debt</li>
                    <li className={`innerListItem ${activeItem === 'Annual Expenses' ? 'active' : ''}`} onClick={(e) => handleItemClick('Annual Expenses', 'Annual_Expenses.mp4', e)}>Annual Expenses</li>
                  </CollapsibleListItem>
                  <CollapsibleListItem label="Optimization Strategies">
                    <li className={`innerListItem ${activeItem === 'Max Value' ? 'active' : ''}`} onClick={(e) => handleItemClick('Max Value', 'Max_Value.mp4', e)}>Max Value</li>
                    <li className={`innerListItem ${activeItem === 'Set Value' ? 'active' : ''}`} onClick={(e) => handleItemClick('Set Value', 'Set_value.mp4', e)}>Set Value</li>
                    <li className={`innerListItem ${activeItem === 'Max Spend' ? 'active' : ''}`} onClick={(e) => handleItemClick('Max Spend', 'Max_spend.mp4', e)}>Max Spend</li>
                  </CollapsibleListItem>
                  <li className={`mainListItem ${activeItem === 'Navigating Dashboard' ? 'active' : ''}`} onClick={(e) => handleItemClick('Navigating Dashboard', 'Dashboard.mp4', e)}>Navigating Dashboard</li>
                </ul> */
}
export default TutorialVideoPage;
