const { useMediaQuery, Box, Text } = require("@chakra-ui/react");

function DashboardSubCards({Heading, mainframecontent, Graph }){

    const [isLargerThan500] = useMediaQuery("(min-width: 500px)");
    const isSmallScreen = !isLargerThan500;
    const [isLargerThan1200] = useMediaQuery(
      "(min-width: 500px) and (max-width: 1250px)"
    );

    return (
        <Box
        display={"flex"}
        width={"48%"}
        padding={"20px"}
        justifyContent={isLargerThan1200? "flex-end":"space-between"}
        border={"1px solid #00A2D4"}
        borderRadius={"5px"}
        bg={"#FFF"}
        flexDirection={isLargerThan1200 && "column-reverse"}
      >
        <Box
          display={"flex"}
          width={isLargerThan1200 ? "100%":"60%"}
          flexDir={"column"}
          alignItems={"flex-start"}
          gap={"10px"}
        >
          <Text
            color={"#00A2D4"}
            textAlign={"right"}
            fontFamily={"Nunito"}
            fontSize={"24px"}
            fontStyle={"normal"}
            fontWeight={"800"}
            lineHeight={"130%"}
          >
            {Heading}
          </Text>

          {mainframecontent}
        </Box>
        <Box
          display={"flex"}
          alignSelf={isLargerThan1200 ? "center" : "flex-start"}
          fill={"#00A2D4"}
        >
          {Graph}
        </Box>
      </Box>
    );
}

export default DashboardSubCards;