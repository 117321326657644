import React, { useEffect, useState } from "react";
import axios from "axios";
import { useAuth0 } from "@auth0/auth0-react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  Button,
  Text,
  Box,
  Flex,
  useDisclosure,
  Checkbox,
  Icon,
  Input,
  VStack,
  useMediaQuery,
} from "@chakra-ui/react";
import {
  CheckedIcon,
  CheckIcon,
  CloseIcon,
  HelpSubmitIcon,
  OptimizeIcon,
  UpdateMyPlanIcon,
} from "./CustomIcons";
import Link from "./Link";
import { CheckCircleIcon } from "@chakra-ui/icons";
import { useStore } from "../store";
import NormalInputButton from "./InputFields/normalInputButton";
import DropDownButton from "./InputFields/dropDownButton";
import CompassSpinner from "./CompassSpinner";
import { set } from "lodash";
import { faLoveseat } from "@fortawesome/sharp-regular-svg-icons";

const HelpPopup = ({ isOpen, onClose }) => {
  const userOptiData = useStore((store) => store.userOptiData);
  const { user } = useAuth0();

  const [issue, setIssue] = useState("");
  const [message, setMessage] = useState("");
  const [submitSuccess, setSubmitSuccess] = useState(false);

  const handleSubmit = async () => {
    if (checkMandatoryFields()) {
      // Ensuring all required fields are filled
      const postData = {
        firstName: userOptiData?.primaryUserData?.sps1_name,
        lastName: userOptiData?.primaryUserData?.sps1_lastName,
        email: user?.email,
        issue: issue,
        message: message,
        action: "Send_Email",
      };

      try {
        const response = await axios.post(
          "https://e7qd4ebh7jephynxs4x2fjrdli0oflzl.lambda-url.us-east-2.on.aws/",
          postData
        );
        console.log("Email sent successfully:", response.data);
        setLoading(false);
        setSubmitSuccess(true);
      } catch (error) {
        console.error("Error sending email:", error);
        setSubmitSuccess(false); // Optionally handle error state in UI
      }
    } else {
      console.log("Mandatory fields are missing");
    }
  };

  const handleClose = () => {
    setSubmitSuccess(false); // Reset submit success state
    onClose(); // Close the modal
  };

  useEffect(() => {
    if (!isOpen) {
      setSubmitSuccess(false); // Reset submit success state when modal is closed
      setIssue(""); // Reset issue
      setMessage(""); // Reset message
    }
  }, [isOpen]);
  const [errors, setErrors] = useState({});
  const checkMandatoryFields = () => {
    let newErrors = {};
    if (!issue) newErrors.issue = "Issue type required";

    if (!message) newErrors.message = "Message required";

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0; // Return true if no errors
  };

  const [isLargerThan500] = useMediaQuery("(min-width: 500px)");
  const [loading, setLoading] = useState(false);

  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered>
      <ModalOverlay />
      <ModalContent
        border="2px solid #0F9CD6"
        borderRadius="10px"
        width="90%" // Ensure it scales down on smaller screens
        maxWidth="600px" // Set a larger width
        padding={!isLargerThan500 ? "0px 15px 15px 15px" : "0px 50px 50px 50px"}
      >
        <Box
          display={"flex"}
          margin={!isLargerThan500 ? "15px 0px 0px 0px" : "20px -30px 0px 0px"}
          paddingBottom={"15px"}
          justifyContent={"flex-end"}
          alignItems={"flex-start"}
          gap={"10px"}
          alignSelf={"stretch"}
          onClick={handleClose}
          _hover={{
            cursor: "pointer",
          }}
        >
          <Icon as={CloseIcon} svgwidth={"18px"} svgheight={"18px"} />
        </Box>
        {loading && (
          <CompassSpinner
            loading={loading}
            className="Wealthica-Loader"
            small={true}
          />
        )}

        {!loading && (
          <ModalBody
            display={"flex"}
            width={"100%"}
            flexDirection={"column"}
            alignItems={"center"}
            gap={"20px"}
            flexShrink={0}
            bg={"#FFF"}
            padding={0}
          >
            <Box
              display={"flex"}
              flexDirection={"column"}
              alignItems={"center"}
              gap={"5px"}
            >
              <Text
                color={"#00466D"}
                fontFamily={"Nunito"}
                fontSize={"24px"}
                fontStyle={"normal"}
                fontWeight={"700"}
                lineHeight={"normal"}
              >
                Help Desk
              </Text>
              <Text
                color={"#979797"}
                fontFamily={"Nunito"}
                fontSize={"15px"}
                fontStyle={"normal"}
                fontWeight={"400"}
                lineHeight={"normal"}
              >
                Please let us know how we can help you.
              </Text>
            </Box>
            <Box
              display={"flex"}
              padding={"20px"}
              flexDirection={"row"}
              alignItems={"flex-end"}
              justifyContent={"space-between"}
              rowGap={"10px"}
              flexWrap={"wrap"}
              width={"100%"}
              gap={"15px"}
              alignSelf={"stretch"}
              borderRadius={"10px"}
              border={"1px solid #00A2D4"}
              bg={"#FFF"}
            >
              {!submitSuccess && (
                <>
                  <NormalInputButton
                    Heading={"First Name"}
                    value={userOptiData?.primaryUserData?.sps1_name}
                    disabled={true}
                    InputWidth={!isLargerThan500 && "100%"}
                  />
                  <NormalInputButton
                    Heading={"Last Name"}
                    value={userOptiData?.primaryUserData?.sps1_lastName}
                    disabled={true}
                    InputWidth={!isLargerThan500 && "100%"}
                  />
                  <NormalInputButton
                    Heading={"Email"}
                    value={user?.email}
                    disabled={true}
                    customWidth={"100%"}
                    InputWidth={!isLargerThan500 && "100%"}
                  />
                  <DropDownButton
                    Heading={"Issue"}
                    placeholder={"Select Issue"}
                    value={issue}
                    dropDownItems={[
                      { key: "Customer Support", value: "Customer Support" },
                      { key: "Report an Issue", value: "Report an Issue" },
                      {
                        key: "New Feature Request",
                        value: "New Feature Request",
                      },
                      { key: "Other", value: "Other" },
                    ]}
                    onChange={(key) => setIssue(key)}
                    customWidth={"100%"}
                    MenuCustomWidth={!isLargerThan500 && "100%"}
                    error={errors.issue}
                  />
                  <VStack w={"100%"}>
                    <Text
                      width={"100%"}
                      color={"#00466D"}
                      fontFamily={"Nunito"}
                      fontSize={"16px"}
                      fontStyle={"normal"}
                      fontWeight={"600"}
                      lineHeight={"normal"}
                    >
                      Message
                    </Text>
                    <Input
                      as={"textarea"}
                      borderRadius={"10px"}
                      bg={"#EDF2F7"}
                      minHeight={"223px"}
                      alignItems={"flex-start"}
                      value={message}
                      onChange={(e) => setMessage(e.target.value)}
                    />
                  </VStack>
                  <Box
                    display={"flex"}
                    justifyContent={"space-between"}
                    alignItems={"flex-end"}
                    alignSelf={"stretch"}
                    width={"100%"}
                    gap={!isLargerThan500 && "20px"}
                  >
                    <Button
                      display={"flex"}
                      padding={"8px 48px"}
                      justifyContent={"center"}
                      alignItems={"center"}
                      gap={"5px"}
                      minW={isLargerThan500 && "172px"}
                      borderRadius={"5px"}
                      border={"1px solid #00A2D4"}
                      onClick={handleClose}
                    >
                      <Text
                        color={"#00A2D4"}
                        fontFamily={"Nunito"}
                        fontSize={"16px"}
                        fontStyle={"normal"}
                        fontWeight={"600"}
                        lineHeight={"normal"}
                      >
                        Close
                      </Text>
                    </Button>
                    <Button
                      display={"flex"}
                      padding={"8px 48px"}
                      justifyContent={"center"}
                      alignItems={"center"}
                      gap={"10px"}
                      bg={"#00A2D4"}
                      borderRadius={"5px"}
                      border={"1px solid #00A2D4"}
                      maxW={"172px"}
                      onClick={() => {
                        handleSubmit();
                        setLoading(true);
                      }}
                    >
                      <Text
                        color={"#FFF"}
                        fontFamily={"Nunito"}
                        fontSize={"16px"}
                        fontStyle={"normal"}
                        fontWeight={"600"}
                        lineHeight={"normal"}
                      >
                        Submit
                      </Text>
                      <HelpSubmitIcon svgwidth={"20px"} svgheight={"20px"} />
                    </Button>
                  </Box>
                </>
              )}
              {submitSuccess && (
                <>
                  <Box
                    display={"flex"}
                    justifyContent={"center"}
                    alignItems={"center"}
                    alignContent={"flex-start"}
                    gap={"10px"}
                    alignSelf={"stretch"}
                    flexWrap={"wrap"}
                    bg={"#FFF"}
                    w={"100%"}
                  >
                    <Text
                      color={"#00466D"}
                      fontFamily={"Nunito"}
                      fontSize={"24px"}
                      fontStyle={"normal"}
                      fontWeight={"700"}
                      lineHeight={"normal"}
                      width={"100%"}
                      align={"center"}
                    >
                      Message Sent
                    </Text>
                    <Icon
                      as={CheckIcon}
                      svgwidth={"25px"}
                      svgheight={"25px"}
                      InnerfillColour={"#84C98B"}
                      outerfillColour={"#FFF"}
                    />
                    <Text
                      alignSelf={"stretch"}
                      color={"#979797"}
                      textAlign={"center"}
                      fontFamily={"Nunito"}
                      fontSize={"14px"}
                      fontStyle={"normal"}
                      fontWeight={"600"}
                      lineHeight={"normal"}
                    >
                      Thank you for reaching out! A member of the Optiml support
                      team will get in touch with you via email as soon as
                      possible. We appreciate your patience and look forward to
                      assisting you with any questions or concerns you may have
                    </Text>
                  </Box>
                </>
              )}
            </Box>
            {submitSuccess && (
              <Box
                display={"flex"}
                justifyContent={"center"}
                alignItems={"flex-end"}
                alignSelf={"stretch"}
                width={"100%"}
              >
                <Button
                  display={"flex"}
                  padding={"8px 48px"}
                  justifyContent={"center"}
                  alignItems={"center"}
                  gap={"5px"}
                  minW={"172px"}
                  borderRadius={"5px"}
                  border={"1px solid #00A2D4"}
                  bg={"#00A2D4"}
                  onClick={handleClose}
                >
                  <Text
                    color={"#FFF"}
                    fontFamily={"Nunito"}
                    fontSize={"16px"}
                    fontStyle={"normal"}
                    fontWeight={"600"}
                    lineHeight={"normal"}
                  >
                    Close
                  </Text>
                </Button>
              </Box>
            )}
          </ModalBody>
        )}
      </ModalContent>
    </Modal>
  );
};

export default HelpPopup;
