import { Box, Flex, Icon, Text } from "@chakra-ui/react";
import React, { useState } from "react";
import { PlayIcon } from "../CustomIcons";
import VideoModal from "../VideoModal/VideoModal";

function GlobalBodyPage({ Heading, mainframeContent, videoName, video }) {
  const [isVideoModalOpen, setIsVideoModalOpen] = useState(false);

  const openVideoModal = () => {
    setIsVideoModalOpen(true);
  };

  const closeVideoModal = () => {
    setIsVideoModalOpen(false);
  };
  return (
    <>
      <VideoModal
        isOpen={isVideoModalOpen}
        closeModal={closeVideoModal}
        videoName={video}
        title={videoName}
      />
      <Box
        display="flex"
        padding="15px"
        flexDirection="column"
        alignItems="flex-start"
        gap="20px"
        flex="1 0 0"
        alignSelf="stretch"
        borderRadius="10px"
        border="1px solid var(--Optiml---Vibrant-Sky-Blue, #00A2D4)"
        bg="#FFF"
      >
        {Heading && (
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            alignSelf="stretch"
          >
            <Text
              color="#00A2D4"
              fontFamily="Nunito"
              fontSize="20px"
              fontStyle="normal"
              fontWeight="700"
              lineHeight="normal"
            >
              {Heading}
            </Text>
            {video && (
              <Box cursor={"pointer"} onClick={openVideoModal}>
                <Icon boxSize="25px" as={PlayIcon} svgheight={"20px"} svgwidth={"20px"} />
              </Box>
            )}
          </Box>
        )}
        <Flex
          flexDirection="column"
          alignItems="flex-start"
          // flex="1 0 auto"
          alignSelf="stretch"
          bg="#FFF"
          overflow="hidden" // Handle inner content overflow
          wrap="wrap" // Allow elements to wrap
        >
          {mainframeContent}
        </Flex>
      </Box>
    </>
  );
}

export default GlobalBodyPage;
