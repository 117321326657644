import { Box, Flex, VStack } from "@chakra-ui/layout";
import { Icon, Text, Tooltip, useMediaQuery, Button } from "@chakra-ui/react";
import axios from "axios";
import { useAuth0 } from "@auth0/auth0-react";
import React, {
  forwardRef,
  useContext,
  useEffect,
  useImperativeHandle,
  useState,
} from "react";
import { useStore } from "../../../store";
import {
  AddAssetsIcon,
  BoatIcon,
  CloseIcon,
  EducationIcon,
  InputBoxTitleBubbleTip,
  RenovationIcon,
  VacationIcon,
  VehicleIcon,
  WeddingIcon,
  otherIcon,
} from "../../CustomIcons";
import GlobalBodyPage from "../../GlobalContainer/BodyPage";
import DropDownButton from "../../InputFields/dropDownButton";
import NormalInputButton from "../../InputFields/normalInputButton";
import TableComponent from "../../TableComponent";
import TooltipContent from "../../TooltipContent";

function GoalsIcon({ setSelectedGoal, onDeleteRow, editField, goalsArray }) {
  const [isLargerThan768] = useMediaQuery("(min-width: 500px)");
  const isMobile = !isLargerThan768;

  const GoalsList = [
    {
      title: "Vacation",
      icon: VacationIcon,
    },
    {
      title: "Vehicle",
      icon: VehicleIcon,
    },
    {
      title: "Boat",
      icon: BoatIcon,
    },
    {
      title: "Renovation",
      icon: RenovationIcon,
    },
    {
      title: "Wedding",
      icon: WeddingIcon,
    },
    {
      title: "Education",
      icon: EducationIcon,
    },
    {
      title: "Other",
      icon: otherIcon,
    },
  ];

  const columns = [
    { header: "Name", field: "name" },
    { header: "Age", field: "age" },
    { header: "Value", field: "value" },
  ];

  const data = goalsArray.map((item) => ({
    name: item.goalName,
    age: item.goalEnd ? `${item.goalStart} - ${item.goalEnd}` : item.goalStart,
    value: item.goalBudget,
  }));

  return (
    <Flex
      display="flex"
      flexWrap="wrap"
      justifyContent="flex-start"
      alignItems="start"
      width={"100%"}
    >
      {!isMobile && (
        <VStack alignItems={"flex-start"} width={"100%"} mt={-2}>
          <GlobalBodyPage
            Heading="Goals"
            mainframeContent={
              <>
                <Box
                  display={"flex"}
                  width={"100%"}
                  padding={"10px"}
                  justifyContent={"Center"}
                  alignItems={"flex-start"}
                  alignContent={"flex-start"}
                  rowGap={"10px"}
                  gap={"10px"}
                  alignSelf={"stretch"}
                  flexWrap={"wrap"}
                  flex={"1 0 auto"}
                  borderRadius={"10px"}
                  border={"1px solid var(--Optiml---Vibrant-Sky-Blue, #00A2D4)"}
                  bg={"#FFF"}
                >
                  {data.length > 0 && (
                    <TableComponent
                      columns={columns}
                      data={data}
                      editField={editField}
                      onDeleteRow={onDeleteRow}
                      rawData={goalsArray}
                    />
                  )}
                  {GoalsList.map((asset) => (
                    <Box
                      key={asset.title}
                      display={"flex"}
                      minH={"121px"}
                      flexDirection={"column"}
                      padding={"4px"}
                      alignItems={"center"}
                      borderRadius={"10px"}
                      border={"1px solid #0093D0"}
                      bg={"#F3FAFF"}
                      boxShadow={"0px 4px 4px 0px #00A2D4"}
                    >
                      <Box
                        display={"flex"}
                        justifyContent={"flex-end"}
                        alignItems={"flex-start"}
                        gap={"10px"}
                        alignSelf={"stretch"}
                      >
                        <Tooltip
                          label="Please choose the type of goal you would like to add."
                          placement="right"
                          hasArrow
                        >
                          <span>
                            <Icon
                              as={InputBoxTitleBubbleTip}
                              cursor={"pointer"}
                              w={"9px"}
                              h={"9px"}
                            />
                          </span>
                        </Tooltip>
                      </Box>
                      <Box
                        display={"flex"}
                        justifyContent={"center"}
                        alignItems={"center"}
                        w={"40px"}
                        h={"40px"}
                      >
                        <Icon as={asset.icon} />
                      </Box>
                      <Box
                        display={"flex"}
                        width={"105px"}
                        height={"17px"}
                        flexDirection={"column"}
                        justifyContent={"center"}
                      >
                        <Text
                          color={"#00466D"}
                          fontFamily={"Nunito"}
                          fontSize={"13px"}
                          fontStyle={"normal"}
                          fontWeight={"700"}
                          lineHeight={"normal"}
                          textAlign={"center"}
                        >
                          {asset.title}
                        </Text>
                      </Box>
                      <Box
                        display={"flex"}
                        padding={"3px 20px"}
                        justifyContent={"center"}
                        alignItems={"center"}
                        gap={"6px"}
                        alignSelf={"stretch"}
                        borderRadius={"5px"}
                        border={"1px solid #00A2D4"}
                        bg={"#FFF"}
                        cursor={"pointer"}
                        onClick={() => setSelectedGoal(asset.title)}
                      >
                        <Icon as={AddAssetsIcon} w={"12px"} h={"12px"} />
                        <Text
                          color={"#00A2D4"}
                          fontFamily={"Nunito"}
                          fontSize={"10px"}
                          fontStyle={"normal"}
                          fontWeight={"700"}
                          lineHeight={"normal"}
                        >
                          Add
                        </Text>
                      </Box>
                    </Box>
                  ))}
                </Box>
              </>
            }
          />
        </VStack>
      )}
      {isMobile && (
        <VStack alignItems={"flex-start"} width={"100%"} mt={-2}>
          <GlobalBodyPage
            Heading="Goals"
            mainframeContent={
              <>
                <Box
                  display={"flex"}
                  width={"100%"}
                  padding={"10px"}
                  flexDirection={"row"}
                  justifyContent={"center"}
                  alignItems={"flex-start"}
                  alignContent={"flex-start"}
                  rowGap={"10px"}
                  gap={"5px"}
                  alignSelf={"stretch"}
                  flexWrap={"wrap"}
                  flex={"1 0 auto"}
                  borderRadius={"10px"}
                  border={"1px solid var(--Optiml---Vibrant-Sky-Blue, #00A2D4)"}
                  bg={"#FFF"}
                >
                  {data.length > 0 && (
                    <TableComponent
                      columns={columns}
                      data={data}
                      editField={editField}
                      onDeleteRow={onDeleteRow}
                      rawData={goalsArray}
                    />
                  )}
                  {GoalsList.map((asset) => (
                    <Box
                      key={asset.title}
                      display={"flex"}
                      minH={"121px"}
                      padding={"10px"}
                      flexDirection={"column"}
                      alignItems={"center"}
                      gap={"5px"}
                      borderRadius={"10px"}
                      border={"1px solid #0093D0"}
                      bg={"#F3FAFF"}
                      boxShadow={"0px 4px 4px 0px #00A2D4"}
                    >
                      <Box
                        display={"flex"}
                        justifyContent={"flex-end"}
                        alignItems={"flex-start"}
                        gap={"10px"}
                        alignSelf={"stretch"}
                      >
                        <Tooltip
                          label="Please choose the type of goal you would like to add."
                          placement="right"
                          hasArrow
                        >
                          <span>
                            <Icon
                              as={InputBoxTitleBubbleTip}
                              cursor={"pointer"}
                              w={"9px"}
                              h={"9px"}
                            />
                          </span>
                        </Tooltip>
                      </Box>
                      <Box
                        display={"flex"}
                        justifyContent={"center"}
                        alignItems={"center"}
                        w={"40px"}
                        h={"40px"}
                      >
                        <Icon as={asset.icon} />
                      </Box>
                      <Box
                        display={"flex"}
                        width={"105px"}
                        height={"17px"}
                        flexDirection={"column"}
                        justifyContent={"center"}
                      >
                        <Text
                          color={"#00466D"}
                          fontFamily={"Nunito"}
                          fontSize={"13px"}
                          fontStyle={"normal"}
                          fontWeight={"700"}
                          lineHeight={"normal"}
                          textAlign={"center"}
                        >
                          {asset.title}
                        </Text>
                      </Box>
                      <Box
                        display={"flex"}
                        padding={"3px 20px"}
                        justifyContent={"center"}
                        alignItems={"center"}
                        gap={"6px"}
                        alignSelf={"stretch"}
                        borderRadius={"5px"}
                        border={"1px solid #00A2D4"}
                        bg={"#FFF"}
                        cursor={"pointer"}
                        onClick={() => setSelectedGoal(asset.title)}
                      >
                        <Icon as={AddAssetsIcon} w={"12px"} h={"12px"} />
                        <Text
                          color={"#00A2D4"}
                          fontFamily={"Nunito"}
                          fontSize={"10px"}
                          fontStyle={"normal"}
                          fontWeight={"700"}
                          lineHeight={"normal"}
                        >
                          Add
                        </Text>
                      </Box>
                    </Box>
                  ))}
                </Box>
              </>
            }
          />
        </VStack>
      )}
    </Flex>
  );
}

function GoalsForm({
  Heading,
  goalName,
  setGoalName,
  goalBudget,
  setGoalBudget,
  goalTimeFrame,
  setGoalTimeFrame,
  educationEnd,
  setEducationEnd,
  moneyToNumber,
  renderAddUpdate,
  goalsArray,
  Select,
  currentAge,
  onDeleteRow,
  editField,
  errors,
  TooltipContent,
}) {
  const [isLargerThan768] = useMediaQuery("(min-width: 500px)");

  const isMobile = !isLargerThan768;

  const columns = [
    { header: "Name", field: "name" },
    { header: "Age", field: "age" },
    { header: "Value", field: "value" },
  ];

  const data = goalsArray.map((item) => ({
    name: item.goalName,
    age: item.goalStart,
    value: item.goalBudget,
  }));

  return (
    <Flex
      display="flex"
      flexWrap="wrap"
      justifyContent="flex-start"
      alignItems="start"
      width={"100%"}
    >
      {!isMobile && (
        <VStack alignItems={"flex-start"} width={"100%"} mt={-2}>
          <GlobalBodyPage
            Heading={Heading}
            mainframeContent={
              <>
                {Heading === "Education" && (
                  <Box
                    display={"flex"}
                    width={"100%"}
                    flexDirection={"row"}
                    paddingTop={"10px"}
                    alignItems={"flex-end"}
                    justifyContent={"space-between"}
                    gap={"10px"}
                    flexWrap={"wrap"}
                  >
                    <NormalInputButton
                      Heading={"Name/Title"}
                      value={goalName}
                      onChange={(e) => {
                        setGoalName(e.target.value);
                      }}
                      customWidth={"100%"}
                      InputWidth={"100%"}
                      error={errors.goalName}
                      tooltipContent={TooltipContent.goalName}
                    />
                    <NormalInputButton
                      Heading={"What is your annual budget for this goal?"}
                      value={goalBudget}
                      onChange={(e) => {
                        setGoalBudget(moneyToNumber(e.target.value));
                      }}
                      placeholder={"$"}
                      useThousandSeparator={true}
                      error={errors.goalBudget}
                      tooltipContent={TooltipContent.goalAmount}
                    />
                    <DropDownButton
                      Heading={"When will this start?"}
                      placeholder={"Select"}
                      dropDownItems={Select(currentAge)}
                      value={goalTimeFrame}
                      onChange={(key) => {
                        setGoalTimeFrame(key);
                      }}
                      error={errors.goalTimeFrame}
                      tooltipContent={TooltipContent.goalStart}
                    />
                    <DropDownButton
                      Heading={"When will this end?"}
                      placeholder={"Select"}
                      dropDownItems={Select(currentAge)}
                      value={educationEnd}
                      onChange={(key) => {
                        setEducationEnd(key);
                      }}
                      error={errors.educationEnd}
                      tooltipContent={TooltipContent.goalEnd}
                    />
                  </Box>
                )}
                {Heading != "Education" && (
                  <Box
                    display={"flex"}
                    width={"100%"}
                    flexDirection={"row"}
                    paddingTop={"10px"}
                    alignItems={"flex-end"}
                    justifyContent={"space-between"}
                    gap={"10px"}
                    flexWrap={"wrap"}
                  >
                    <NormalInputButton
                      Heading={"Name/Title"}
                      value={goalName}
                      onChange={(e) => {
                        setGoalName(e.target.value);
                      }}
                      customWidth={"100%"}
                      InputWidth={"100%"}
                      error={errors.goalName}
                      tooltipContent={TooltipContent.goalName}
                    />
                    <NormalInputButton
                      Heading={"What is your budget for this goal?"}
                      value={goalBudget}
                      onChange={(e) => {
                        setGoalBudget(moneyToNumber(e.target.value));
                      }}
                      placeholder={"$"}
                      useThousandSeparator={true}
                      error={errors.goalBudget}
                      tooltipContent={TooltipContent.goalAmount}
                    />
                    <DropDownButton
                      Heading={"When do you plan to achieve this goal?"}
                      placeholder={"Select"}
                      dropDownItems={Select(currentAge)}
                      value={goalTimeFrame}
                      onChange={(key) => {
                        setGoalTimeFrame(key);
                      }}
                      error={errors.goalTimeFrame}
                      tooltipContent={TooltipContent.goalStart}
                    />
                  </Box>
                )}

                {renderAddUpdate(goalsArray)}
              </>
            }
          />
        </VStack>
      )}
      {isMobile && (
        <VStack spacing={4} alignItems={"flex-start"} width={"100%"}>
          <GlobalBodyPage
            Heading={Heading}
            mainframeContent={
              <>
                {Heading === "Education" && (
                  <Box
                    display={"flex"}
                    width={"100%"}
                    flexDirection={"row"}
                    paddingTop={"10px"}
                    alignItems={"flex-end"}
                    justifyContent={"space-between"}
                    gap={"10px"}
                    flexWrap={"wrap"}
                  >
                    <NormalInputButton
                      Heading={"Name/Title"}
                      value={goalName}
                      onChange={(e) => {
                        setGoalName(e.target.value);
                      }}
                      customWidth={"100%"}
                      InputWidth={"100%"}
                      error={errors.goalName}
                      tooltipContent={TooltipContent.goalName}
                    />
                    <NormalInputButton
                      Heading={"What is your annual budget for this goal?"}
                      value={goalBudget}
                      onChange={(e) => {
                        setGoalBudget(moneyToNumber(e.target.value));
                      }}
                      placeholder={"$"}
                      useThousandSeparator={true}
                      customWidth={"100%"}
                      InputWidth={"100%"}
                      error={errors.goalBudget}
                      tooltipContent={TooltipContent.goalAmount}
                    />
                    <DropDownButton
                      Heading={"When will this start?"}
                      placeholder={"Select"}
                      dropDownItems={Select(currentAge)}
                      value={goalTimeFrame}
                      onChange={(key) => {
                        setGoalTimeFrame(key);
                      }}
                      customWidth={"100%"}
                      MenuCustomWidth={"100%"}
                      error={errors.goalTimeFrame}
                      tooltipContent={TooltipContent.goalStart}
                    />
                    <DropDownButton
                      Heading={"When will this end?"}
                      placeholder={"Select"}
                      dropDownItems={Select(currentAge)}
                      value={educationEnd}
                      onChange={(key) => {
                        setEducationEnd(key);
                      }}
                      customWidth={"100%"}
                      MenuCustomWidth={"100%"}
                      error={errors.educationEnd}
                      tooltipContent={TooltipContent.goalEnd}
                    />
                  </Box>
                )}
                {Heading != "Education" && (
                  <Box
                    display={"flex"}
                    width={"100%"}
                    flexDirection={"row"}
                    paddingTop={"10px"}
                    alignItems={"flex-end"}
                    justifyContent={"space-between"}
                    gap={"10px"}
                    flexWrap={"wrap"}
                  >
                    <NormalInputButton
                      Heading={"Name/Title"}
                      value={goalName}
                      onChange={(e) => {
                        setGoalName(e.target.value);
                      }}
                      customWidth={"100%"}
                      InputWidth={"100%"}
                      error={errors.goalName}
                      tooltipContent={TooltipContent.goalName}
                    />
                    <NormalInputButton
                      Heading={"What is your budget for this goal?"}
                      value={goalBudget}
                      onChange={(e) => {
                        setGoalBudget(moneyToNumber(e.target.value));
                      }}
                      placeholder={"$"}
                      useThousandSeparator={true}
                      customWidth={"100%"}
                      InputWidth={"100%"}
                      error={errors.goalBudget}
                      tooltipContent={TooltipContent.goalAmount}
                    />
                    <DropDownButton
                      Heading={"When do you plan to achieve this goal?"}
                      placeholder={"Select"}
                      dropDownItems={Select(currentAge)}
                      value={goalTimeFrame}
                      onChange={(key) => {
                        setGoalTimeFrame(key);
                      }}
                      customWidth={"100%"}
                      MenuCustomWidth={"100%"}
                      error={errors.goalTimeFrame}
                      tooltipContent={TooltipContent.goalStart}
                    />
                  </Box>
                )}

                {renderAddUpdate(goalsArray)}
              </>
            }
          />
        </VStack>
      )}
    </Flex>
  );
}

const Goals = forwardRef((props, ref) => {
  const { getAccessTokenSilently } = useAuth0();

  useImperativeHandle(ref, () => ({
    submitHandler,
    checkMandatoryFields,
  }));

  const checkMandatoryFields = () => {
    return true;
  };

  const submitHandler = () => {
    return true;
  };

  const axiosInstance = axios.create();
  async function getToken() {
    // Replace this with your Auth0 token retrieval logic
    const token = await getAccessTokenSilently({
      audience:
        "https://os2lsiwff43wfpb5ggyawt6qri0mgcro.lambda-url.us-east-2.on.aws/",
      scope: "write:optimization",
    });
    return token;
  }

  // Add a request interceptor
  axiosInstance.interceptors.request.use(
    async (config) => {
      const token = await getToken();
      config.headers.Authorization = `Bearer ${token}`;
      return config;
    },
    (error) => {
      // Do something with request error
      return Promise.reject(error);
    }
  );
  const { user, logout } = useAuth0();

  const userInfo = useStore((store) => store.userInfo);
  const userGoalInfo = useStore((store) => store.userGoalInfo);
  const setUserGoalInfo = useStore((store) => store.setUserGoalInfo);
  const updateUserGoalInfo = useStore((store) => store.updateUserGoalInfo);
  const deleteUserGoalInfo = useStore((store) => store.deleteUserGoalInfo);

  const {
    FirstName: firstName,
    LastName: lastName,
    DateOfBirth: currentAge,
    spouseFirstName,
    spouseLastName,
    spouseDateOfBirth: spouseAge,
  } = userInfo;

  const [goalName, setGoalName] = useState("");
  const [goalBudget, setGoalBudget] = useState("");
  const [goalTimeFrame, setGoalTimeFrame] = useState("");
  const [educationEnd, setEducationEnd] = useState("");
  const [goalNumber, setGoalNumber] = useState();

  const [goalsArray, setGoalsArray] = useState([]);

  const [editing, setEditing] = useState(false);
  const [editingIndex, setEditingIndex] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const [selectedGoal, setSelectedGoal] = useState(null);

  const handleBack = () => {
    setSelectedGoal(null);
  };

  useEffect(() => {
    if (userGoalInfo?.length > 0) {
      setGoalsArray(userGoalInfo);
    }
  }, [userGoalInfo]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [userGoalInfo]);

  const moneyToNumber = (value) => {
    let numValue = value.replace(/[$,\s]/g, "");
    numValue = Number(numValue);
    return numValue;
  };

  const [errors, setErrors] = useState({});

  const checkMandatoryAddUpdateFields = () => {
    let newErrors = {};

    // Goal conditions
    if (!goalName) {
      newErrors.goalName = "Goal name is required";
    }
    if (!goalBudget) {
      newErrors.goalBudget = "Goal budget is required";
    }
    if (!goalTimeFrame) {
      newErrors.goalTimeFrame = "Goal date is required";
    }
    if (selectedGoal === "Education" && !educationEnd) {
      newErrors.educationEnd = "Goal end date required";
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0; // Return true if no errors
  };

  const renderAddUpdate = (arr) => {
    if (
      !goalName ||
      !goalBudget ||
      !goalTimeFrame ||
      (selectedGoal === "Education" && !educationEnd)
    ) {
      return (
        <Flex justifyContent={"flex-start"} marginTop={"20px"}>
          <Button
            bg="#27AE60"
            w="172px"
            h="37px"
            align="flex-start"
            onClick={checkMandatoryAddUpdateFields}
            // disabled={!isFieldsValid}
          >
            <Text
              color={"#FFFFFF"}
              fontFamily={"Nunito"}
              fontSize={"14px"}
              fontStyle={"normal"}
              fontWeight={"700"}
              lineHeight={"normal"}
            >
              {editing ? "Update" : "Add"}
            </Text>
          </Button>
        </Flex>
      );
    } else {
      return (
        <Flex justifyContent={"flex-start"} marginTop={"20px"}>
          <Button
            bg="#27AE60"
            w="172px"
            h="37px"
            align="flex-start"
            onClick={(e) => addGoalItem(e, arr)}
            // disabled={!isFieldsValid}
          >
            <Text
              color={"#FFFFFF"}
              fontFamily={"Nunito"}
              fontSize={"14px"}
              fontStyle={"normal"}
              fontWeight={"700"}
              lineHeight={"normal"}
            >
              {editing ? "Update" : "Add"}
            </Text>
          </Button>
        </Flex>
      );
    }
  };

  const onSubmitUpdate = (e, newArray) => {
    e.preventDefault();
    let endDate = educationEnd;
    if (selectedGoal !== "Education") {
      endDate = 0;
    }
    const goalURL = (action) =>
      `${
        process.env.REACT_APP_NODE_API_GATEWAY
      }/CompassLambdaApi/GOALS/${action}?goalID=${user.sub.replace(
        "|",
        "%7c"
      )}&goalName=${goalName}&goalBudget=${goalBudget}&goalStart=${goalTimeFrame}&goalEnd=${endDate}&goalNumber=${goalNumber}&goalType=${selectedGoal}`;
    axiosInstance
      .get(goalURL("update"))
      .then((res) => {
        console.log(res);
        const newData = {
          goalName: goalName,
          goalBudget: goalBudget,
          goalStart: goalTimeFrame,
          goalEnd: endDate,
          goalNumber: goalNumber,
          goalType: selectedGoal,
        };
        // console.log(newData)
        updateUserGoalInfo(goalNumber, newData);
        // console.log(userDebtInfo)
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const onSubmitAdd = (e) => {
    e.preventDefault();
    let endDate = educationEnd;
    if (selectedGoal != "Education") {
      endDate = 0;
    }
    const goalURL = (action) =>
      `${
        process.env.REACT_APP_NODE_API_GATEWAY
      }/CompassLambdaApi/GOALS/${action}?goalID=${user.sub.replace(
        "|",
        "%7c"
      )}&goalName=${goalName}&goalBudget=${goalBudget}&goalStart=${goalTimeFrame}&goalEnd=${endDate}&goalType=${selectedGoal}`;
    axiosInstance
      .get(goalURL("add"))
      .then((res) => {
        console.log(res);
        const newData = [
          {
            goalName: goalName,
            goalBudget: goalBudget,
            goalStart: goalTimeFrame,
            goalEnd: endDate,
            goalNumber: res.data.lastInsertedId,
            goalType: selectedGoal,
          },
        ];
        setUserGoalInfo(newData);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const editField = (i) => {
    setGoalName(goalsArray[i]?.goalName);
    setGoalBudget(goalsArray[i]?.goalBudget);
    setGoalTimeFrame(goalsArray[i].goalStart);
    setEducationEnd(goalsArray[i]?.goalEnd);
    setGoalNumber(goalsArray[i]?.goalNumber);
    setSelectedGoal(goalsArray?.[i]?.goalType);
    setEditing(true);
    setEditingIndex(i);
  };

  const onDeleteRow = (i, array) => {
    setEditingIndex(i);
    setShowDeleteModal(true);
  };

  const DeleteIncomeModal = () => {
    const removeGoalModal = () => {
      const deleteGoalUrl = (action) =>
        `${process.env.REACT_APP_NODE_API_GATEWAY}/CompassLambdaApi/GOALS/${action}?goalNumber=${goalsArray[editingIndex].goalNumber}`;
      axiosInstance.get(deleteGoalUrl("delete")).then((res) => {
        deleteUserGoalInfo(goalsArray[editingIndex].goalNumber);
        removeField(editingIndex);
        setEditingIndex(null);
        setEditing(false);
      });
    };

    if (!showDeleteModal) {
      return null;
    }

    const [isLargerThan500] = useMediaQuery
    ("(min-width: 500px)");
  const isMobile = !isLargerThan500;
    const [yesClicked, setYesClicked] = useState(false);
    const [noClicked, setNoClicked] = useState(false);
    return (
      <div
        className="delete-income-modal"
        style={{
          display: "flex",
          alignItems: "center",
          width: "100%",
          maxWidth: "700px",
          justifyContent: "center",
          top: "50%",
          left: "50%",
          transform: isMobile
            ? "translate(-50%, -50%)"
            : "translate(-50%, -50%)",
        }}
        id="delete-income-modal"
      >
        <Box
          display={"flex"}
          width={"100%"}
          padding={"10px 10px 0px 0px"}
          marginLeft={"5px"}
          alignItems={"flex-end"}
          justifyContent={"flex-end"}
          onClick={() => setShowDeleteModal(false)}
          cursor={"pointer"}
        >
          <CloseIcon
            onClick={() => setShowDeleteModal(false)}
            svgheight={"20px"}
            svgwidth={"20px"}
          />
        </Box>
        <Box
          display={"flex"}
          padding={"0px 25px 25px 25px"}
          flexDirection={"column"}
          alignItems={"center"}
          gap={"10px"}
          alignSelf={"stretch"}
          width={"100%"}
        >
          <Text
            color={"#0093D0"}
            fontFamily={"Nunito"}
            fontSize={"24px"}
            fontStyle={"normal"}
            fontWeight={"600"}
            lineHeight={"normal"}
            textDecor={"underline"}
          >
            Are you sure you want to remove this goal?
          </Text>
          <div className="content"></div>
          <div className="actions">
            <button
              className={`decision-button yes-button ${
                yesClicked ? "yes-clicked" : ""
              }`}
              onClick={() => {
                setYesClicked(true);
                setNoClicked(false);
                removeGoalModal();
              }}
            >
              Yes
            </button>
            <button
              className={`decision-button no-button ${
                noClicked ? "yes-clicked" : ""
              }`}
              onClick={() => {
                {
                  setNoClicked(true);
                  setYesClicked(false);
                  setShowDeleteModal(false);
                }
              }}
            >
              No
            </button>
          </div>
        </Box>
      </div>
    );
  };

  const addGoalItem = (e) => {
    if (editing) {
      setGoalsArray((prevArray) => {
        // Create a copy of the original array
        let newArray = [...prevArray];

        // Replace the object at editingIndex with the new object
        newArray[editingIndex] = {
          goalName: goalName,
          goalBudget: goalBudget,
          goalStart: goalTimeFrame,
          goalEnd: educationEnd,
          goalNumber: goalNumber,
          goalType: selectedGoal,
        };

        onSubmitUpdate(e, newArray);

        return newArray;
      });
      setEditing(false);
      setEditingIndex(null);
    } else {
      setGoalsArray((prevArray) => {
        let newArray = [
          ...prevArray,
          {
            goalName: goalName,
            goalBudget: goalBudget,
            goalStart: goalTimeFrame,
            goalEnd: educationEnd,
            goalType: selectedGoal,
          },
        ];
        onSubmitAdd(e);
        return newArray;
      });
    }
    setGoalName("");
    setGoalBudget("");
    setGoalTimeFrame("No");
    setEducationEnd("");
    setSelectedGoal("");
  };

  const removeField = (i) => {
    goalsArray.splice(i, 1);
    setGoalsArray((prevArray) => [...prevArray]);
    setShowDeleteModal(false);
  };

  function getAge(birthday, currentDate) {
    // Get the current date
    // const currentDate = new Date();

    // Convert the birthday string into a Date object
    const birthDate = new Date(birthday);

    // Calculate the age in years
    let age = currentDate.getFullYear() - birthDate.getFullYear();

    // Decrement the age if the current month and day are before the birth month and day
    if (
      currentDate.getMonth() < birthDate.getMonth() ||
      (currentDate.getMonth() === birthDate.getMonth() &&
        currentDate.getDate() <= birthDate.getDate())
    ) {
      age -= 1;
    }

    return age;
  }

  const Select = (userAge) => {
    const currentDate = new Date();
    const currentYear = new Date().getFullYear();
    let age = getAge(userAge, currentDate); // Assuming getAge returns the starting age.
    const runAgeList = [];
    for (let i = age; i <= 100; i++) {
      // Now, instead of combining age and year into a string, create an object with separate properties
      runAgeList.push({ 
        key: i, 
        value: `${currentYear + (i - age)}`, // Only display the year in the dropdown
        age: i // Keep age as a separate property, not to be displayed but can be used programmatically
      });
    }
    return runAgeList;
  };

  // const Select = (userAge) => {
  //   const currentDate = new Date();
  //   const currentYear = new Date().getFullYear();
  //   let age = getAge(userAge, currentDate); // Assuming getAge returns the starting age.
  //   const runAgeList = [];
  //   for (let i = age; i <= 100; i++) {
  //     runAgeList.push({ key: i, value: `${i} ${currentYear + (i - age + 1)}` }); // Convert i to string if necessary
  //   }
  //   return runAgeList;
  // };

  return selectedGoal ? (
    <>
      <GoalsForm
        Heading={selectedGoal}
        onBack={handleBack}
        goalName={goalName}
        setGoalName={setGoalName}
        goalBudget={goalBudget}
        setGoalBudget={setGoalBudget}
        goalTimeFrame={goalTimeFrame}
        setGoalTimeFrame={setGoalTimeFrame}
        educationEnd={educationEnd}
        setEducationEnd={setEducationEnd}
        moneyToNumber={moneyToNumber}
        renderAddUpdate={renderAddUpdate}
        goalsArray={goalsArray}
        Select={Select}
        currentAge={currentAge}
        onDeleteRow={onDeleteRow}
        editField={editField}
        errors={errors}
        TooltipContent={TooltipContent}
      />
    </>
  ) : (
    <>
      <DeleteIncomeModal />
      <GoalsIcon
        setSelectedGoal={setSelectedGoal}
        onDeleteRow={onDeleteRow}
        editField={editField}
        goalsArray={goalsArray}
      />
    </>
  );
});

export default Goals;
