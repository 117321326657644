import {
  Box,
  Button,
  Flex,
  Icon,
  Progress,
  Text,
  calc,
  useDisclosure,
} from "@chakra-ui/react";
import React, { useEffect, useRef, useState } from "react";
import { useStore } from "../store";
import { CheckIcon, ProfilePercentIcon } from "./CustomIcons";
import Link from "./Link";
import SidebarPageButtons from "./SidebarPageButtons";
import Popup from "./PlanUpdatePopup";
import OltreChatBubble from "./OltreChatBubble";

function GlobalContainer({
  Heading,
  MainContainerHeading,
  item,
  currentPage,
  mainframeContent,
  link,
  progress,
  backLink,
  mainFrameCustomWidth,
  notShowChatBubble,
}) {
  const [dataSave, setDataSave] = useState(true);
  const [changedData, setChangedData] = useState(false);
  const contentRef = useRef(null);
  const userOptiData = useStore((store) => store.userOptiData);
  const userPopupNotification = useStore(
    (store) => store.userPopupNotification
  );
  const { isOpen, onOpen, onClose } = useDisclosure();

  useEffect(() => {
    if (userOptiData?.primaryUserData?.expenses?.length > 1) {
      setDataSave(true);
    } else {
      setDataSave(false);
    }
  }, [userOptiData]);

  const handleNextClick = () => {
    if (contentRef.current && contentRef.current.checkMandatoryFields()) {
      contentRef.current.submitHandler(); // Call the method directly
      window.history.pushState({}, "", link);
      const navEvent = new PopStateEvent("popstate");
      window.dispatchEvent(navEvent);
    } else {
      // If validation fails, do nothing or provide feedback
      console.error("Validation failed. Please fill all required fields.");
    }
  };
  const handleSaveClick = () => {
    if (contentRef.current && contentRef.current.checkMandatoryFields()) {
      contentRef.current.submitHandler(); // Call the method directly
      onOpen();
    } else {
      // If validation fails, do nothing or provide feedback
      console.error("Validation failed. Please fill all required fields.");
    }
  };

  const ProgressBar = ({ value }) => {
    const getColorScheme = (value) => {
      if (value <= 25) {
        return "linear-gradient(90deg, #0093D0, #00B4EB)";
      } else if (value <= 50) {
        return "linear-gradient(90deg, #0093D0, #00B4EB)"; // Orange gradient
      } else if (value <= 75) {
        return "linear-gradient(90deg, #0093D0 0%, #00B4EB 50%, #2DBDB6 75%)"; // Yellow gradient
      } else {
        return "linear-gradient(90deg, #0093D0 0%, #00B4EB 50%, #2DBDB6 75%, #84C98B 100%)"; // Green gradient
      }
    };

    return (
      <Progress
        value={value}
        height="19px"
        width="733px"
        flexShrink={0}
        borderRadius="10px"
        bg="#D8D8D8"
        sx={{
          "& > div": {
            backgroundImage: getColorScheme(value),
            transition: "width 0.2s ease-in-out",
          },
        }}
      />
    );
  };

  const ProgressStep = ({ value, steps }) => {
    return (
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        position="relative"
      >
        <Box position="relative" width="733px">
          <ProgressBar value={value} />
          {steps.slice(0, -1).map((step, index) => (
            <Box
              key={index}
              position="absolute"
              top="-7px"
              left={`calc(${(index / (steps.length - 1)) * 85}%)`}
              paddingLeft={"30%"}
              transform="translateX(-50%)"
              display="flex"
              flexDirection="column"
              alignItems="center"
            >
              {value >= ((index + 1) / steps.length) * 95 && (
                <Icon as={CheckIcon} svgwidth={"31px"}  svgheight={"31px"}/>
              )}
            </Box>
          ))}
          <Box
            display={"flex"}
            flexDirection={"row"}
            justifyContent={"space-between"}
            alignItems={"center"}
            padding={"0px 40px"}
            paddingTop={"5px"}
          >
            {steps.map((step) => (
              <Text
                fontSize="14px"
                color="#00466D"
                fontFamily={"Nunito"}
                fontStyle={"normal"}
                fontWeight={"600"}
                lineHeight={"normal"}
                textAlign={"center"}
              >
                {step.label}
              </Text>
            ))}
          </Box>
        </Box>
      </Box>
    );
  };

  const subMenu = item.length > 0;

  return (
    <Flex
      display="flex"
      flexDirection="column"
      alignItems="center"
      gap="20px"
      bg={"#F3FAFF"}
      flex={"1 0 0"}
      w={"100%"}
    >
      <Box
        display="flex"
        w={
          mainFrameCustomWidth
            ? calc((100 % -2) * mainFrameCustomWidth)
            : "900px"
        } // Subtract 240px from the total width
        flexDirection="column"
        alignItems="center"
        gap="20px"
        flexShrink="0"
        bg={"#F3FAFF"}
        paddingBottom={"50px"}
        minH={"calc(100vh - 90px)"}
      >
        {/* Progress bar box */}
        {!dataSave && (
          <Box
            display={"flex"}
            padding={"15px 20px"}
            justifyContent={"space-between"}
            width={"100%"}
            border={"1px solid #00A2D4"}
            borderRadius={"10px"}
            background={"#FFF"}
            marginTop={"20px"}
            alignSelf={"center"}
            height={"104px"}
          >
            <Box
              display={"flex"}
              flexDirection={"column"}
              alignItems={"flex-start"}
              gap={"10px"}
              flex={"1 0 0"}
              bg={"#FFF"}
            >
              <Text>Your account setup progress</Text>
              <ProgressStep
                value={progress}
                steps={[
                  { label: "Profile", value: 16 },
                  { label: "Income", value: 32 },
                  { label: "Investments", value: 48 },
                  { label: "Assets", value: 64 },
                  { label: "Expenses", value: 80 },
                  { label: "Strategy", value: 100 },
                ]}
              />
            </Box>
            <Box w={"74px"} h={"74px"} flexShrink={0}>
              <Icon as={ProfilePercentIcon} w={"100%"} h={"100%"} />
              <Box
                display={"flex"}
                top={"50%"}
                left={"50%"}
                transform={"translate(0, -165%)"}
                flexDirection={"column"}
                justifyContent={"center"}
                flexShrink={0}
              >
                <Text
                  color={"#00A2D4"}
                  textAlign={"center"}
                  fontFamily={"Nunito"}
                  fontSize={"24px"}
                  fontStyle={"normal"}
                  fontWeight={"800"}
                  lineHeight={"130%"}
                >
                  {progress + "%"}
                </Text>
              </Box>
            </Box>
          </Box>
        )}
        {/* Flex container for heading and mainframe box */}
        <Flex
          display={"flex"}
          flexDirection={"row"}
          spacing="10px"
          justifyContent={"space-between"}
          width={"100%"}
          marginTop={dataSave ? "2rem" : 0}
          flexGrow={1}
        >
          {/* Heading box */}
          {item.length > 0 && (
            <Box
              display={"flex"}
              flexDirection={"column"}
              alignItems={"flex-start"}
              alignSelf={"stretch"}
              borderRadius={"10px"}
              border={"1px solid #00A2D4"}
              gap={"10px"}
              w={"240px"}
              h={"inherit"}
              padding={"20px"}
              bg={"#FFF"}
            >
              <Box
                display={"flex"}
                width={"200px"}
                padding={"10px 0px"}
                flexDirection={"column"}
                alignItems={"flex-start"}
                gap={"10px"}
              >
                <Box
                  display={"flex"}
                  h="31px"
                  flexDirection={"column"}
                  justifyContent={"center"}
                  alignSelf={"stretch"}
                >
                  <Text
                    color={"var(--Optiml---Midnight-Blue, #00466D)"}
                    fontFamily={"Nunito"}
                    fontSize={"32px"}
                    fontStyle={"normal"}
                    fontWeight={"700"}
                    lineHeight={"normal"}
                  >
                    {Heading}
                  </Text>
                </Box>
                {/* Subheading box */}
                <SidebarPageButtons items={item} currentPage={currentPage} />
              </Box>
            </Box>
          )}
          {/* mainframe box */}

          <Box
            display={"flex"}
            width={"100%"}
            padding={"50px"}
            flexDirection={"column"}
            alignItems={"flex-start"}
            gap={"20px"}
            alignSelf={"stretch"}
            borderRadius={"10px"}
            border={"1px solid var(--Optiml---Vibrant-Sky-Blue, #00A2D4)"}
            bg={"#FFF"}
            marginLeft={subMenu ? "10px" : ""}
          >
            {MainContainerHeading !== undefined ? (
            <Box>
              <Text
                color={"var(--Optiml---Midnight-Blue, #00466D)"}
                fontFamily={"Nunito"}
                fontSize={"24px"}
                fontStyle={"normal"}
                fontWeight={"700"}
                lineHeight={"normal"}
              >
                 {MainContainerHeading}
              </Text>
            </Box>
          ) : ( <Box>
            <Text
              color={"var(--Optiml---Midnight-Blue, #00466D)"}
              fontFamily={"Nunito"}
              fontSize={"24px"}
              fontStyle={"normal"}
              fontWeight={"700"}
              lineHeight={"normal"}
            >
               {Heading}
            </Text>
          </Box>)}
            {/* main container box */}
            {React.cloneElement(mainframeContent, {
              ref: contentRef,
              setChangedData: setChangedData,
            })}
            {/* Button box */}
            {(!dataSave || backLink === "/lifestrategy") && (
              <Box
                display="flex"
                flexDirection="row" // Change this to row for horizontal layout
                justifyContent="space-between" // This will space out the children across the full width
                alignItems="center" // Align items vertically in the center
                alignSelf="stretch"
                width="100%" // Ensure the box takes the full width of its container
              >
                {backLink ? (
                  <Link href={backLink}>
                    <Button
                      padding="8px 48px"
                      justifyContent="center"
                      alignItems="center"
                      gap="5px"
                      borderRadius="5px"
                      bg="#00A2D4"
                    >
                      <Text
                        color="#FFF"
                        textAlign="center"
                        fontFamily="Nunito"
                        fontSize="16px"
                        fontStyle="normal"
                        fontWeight="600"
                        lineHeight="normal"
                      >
                        Back
                      </Text>
                    </Button>
                  </Link>
                ) : (
                  <Box width="8px" height="48px" opacity={0} /> // Invisible spacer with similar dimensions to the button
                )}

                {link && (
                  <Button
                    padding="8px 48px"
                    justifyContent="center"
                    alignItems="center"
                    gap="5px"
                    borderRadius="5px"
                    bg="#00A2D4"
                    onClick={() => handleNextClick()}
                  >
                    <Text
                      color="#FFF"
                      textAlign="center"
                      fontFamily="Nunito"
                      fontSize="16px"
                      fontStyle="normal"
                      fontWeight="600"
                      lineHeight="normal"
                    >
                      Next
                    </Text>
                  </Button>
                )}
              </Box>
            )}
            {dataSave && changedData && (
              <Box
                display="flex"
                flexDirection="row" // Change this to row for horizontal layout
                justifyContent="space-between" // This will space out the children across the full width
                alignItems="center" // Align items vertically in the center
                alignSelf="stretch"
                width="100%" // Ensure the box takes the full width of its container
              >
                <Box width="8px" height="48px" opacity={0} />

                <Button
                  padding="8px 48px"
                  justifyContent="center"
                  alignItems="center"
                  gap="5px"
                  borderRadius="5px"
                  bg="#27AE60"
                  onClick={() => handleSaveClick()}
                >
                  <Text
                    color="#FFF"
                    textAlign="center"
                    fontFamily="Nunito"
                    fontSize="16px"
                    fontStyle="normal"
                    fontWeight="600"
                    lineHeight="normal"
                  >
                    {isOpen ? "Saved" : "Save"}
                  </Text>
                </Button>
              </Box>
            )}
            {userPopupNotification?.NewAnalysis=== 1 && (
              <Popup isOpen={isOpen} onClose={onClose} link={link} setChangedData={setChangedData} />
            )}
          </Box>
        </Flex>
      </Box>
      {!notShowChatBubble &&
      <OltreChatBubble />
      }
    </Flex>
  );
}

export default GlobalContainer;
