import { useMediaQuery } from "@chakra-ui/react";
import React, { useContext, useEffect, useState } from "react";
import optiNoise from "../../Sounds/optiNoise.mp3";
import { useStore } from "../../store";
import EndOfLifePageSpouse from "../EndOfLifePageSpouse";
import EndOfLifePage from "../EndOfLifePage";
import OltreChatBubble from "../OltreChatBubble";
import { SpouseContext } from "../SpouseContext";
import "./DashboardHomePage.css";

function DashboardHomePage() {
  const [loading, setLoading] = useState(false);
  const { spouseStatus, setSpouseStatus } = useContext(SpouseContext);
  const [noData, setNoData] = useState(false);
  const [optiList, setOptiList] = useState("");
  const [spouseOptiList, setSpouseOptiList] = useState("");
  const [spouseTwoOptiList, setSpouseTwoOptiList] = useState("");
  const [showModal, setShowModal] = useState(false);

  const userInfo = useStore((store) => store.userInfo);
  const userOptiData = useStore((store) => store.userOptiData);

  const audioTune = new Audio(optiNoise);

  useEffect(() => {
    const fetchMarStatus = async () => {
      if (userInfo !== null) {
        if (userInfo?.MaritalStatus === "married") {
          setSpouseStatus(true);
        }
      }
      const maritalStatus = userInfo?.MaritalStatus;
      if (
        userOptiData !== null ||
        typeof userOptiData !== "undefined" ||
        userOptiData?.primaryUserData?.expenses?.length > 1
      ) {
        // const { primaryUserData, spouseData: spouseUserData } = userOptiData;
        if (maritalStatus === "married") {
          setSpouseStatus(true);
          setSpouseOptiList(userOptiData?.primaryUserData);
          setSpouseTwoOptiList(userOptiData?.spouseData);
          setLoading(true);
        }
        if (maritalStatus !== "married") {
          setOptiList(userOptiData?.primaryUserData);
          setLoading(true);
        }
      } else {
        setNoData(true);
        setLoading(false);
      }
    };

    fetchMarStatus().catch(console.error);
  }, []);

  const [isLargerThan500] = useMediaQuery("(max-width: 500px)");
  const [isLargerThan1870] = useMediaQuery("(min-width: 1870px)");
  return (
    <div
      style={{
        padding: isLargerThan500 ? "15px" : "50px",
        width: "100%",
        alignItems: "center",
        display: "flex",
        flexDirection: "column",
        background:"#F3FAFF"
      }}
    >
      {!spouseStatus ? (
         <div
         style={{
           width: isLargerThan1870 ? "80%" : "100%",
           display: "flex",
           flexDirection: "column",
           alignItems: "center",
         }}
       >
          {loading && (
            <EndOfLifePageSpouse
              passedOptiList={optiList}
              spouseOptiList={""}
              spouse1_ROT={userOptiData?.spouse1_ROT}
              spouse2_ROT={""}
            />
          )}
        </div>
      ) : (
        <div
          style={{
            width: isLargerThan1870 ? "80%" : "100%",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          {loading && (
            <EndOfLifePageSpouse
              passedOptiList={spouseOptiList}
              passedSpouseOptiList={spouseTwoOptiList}
              spouse1_ROT={userOptiData?.spouse1_ROT}
              spouse2_ROT={userOptiData?.spouse2_ROT}
            />
          )}
        </div>
      )}
      <OltreChatBubble />
    </div>
  );
}

export default DashboardHomePage;
