import {
  Box,
  Flex,
  Heading,
  Text,
  VStack,
  useMediaQuery,
} from "@chakra-ui/react";
import { useAuth0 } from "@auth0/auth0-react";
import axios from "axios";
import React, {
  forwardRef,
  useContext,
  useEffect,
  useImperativeHandle,
  useState,
} from "react";
import { useStore } from "../../store";
import { SpouseContext } from "../SpouseContext";
import GlobalBodyPage from "../GlobalContainer/BodyPage";
import DropDownButton from "../InputFields/dropDownButton";
import NormalInputButton from "../InputFields/normalInputButton";
import TooltipContent from "../TooltipContent";

const CashSavingsNonRegistered = forwardRef((props, ref) => {
  const { setChangedData } = props;
  const { user, getAccessTokenSilently } = useAuth0();
  const axiosInstance = axios.create();
  async function getToken() {
    // Replace this with your Auth0 token retrieval logic
    const token = await getAccessTokenSilently({
      audience:
        "https://os2lsiwff43wfpb5ggyawt6qri0mgcro.lambda-url.us-east-2.on.aws/",
      scope: "write:optimization",
    });
    return token;
  }

  // Add a request interceptor
  axiosInstance.interceptors.request.use(
    async (config) => {
      const token = await getToken();
      config.headers.Authorization = `Bearer ${token}`;
      return config;
    },
    (error) => {
      // Do something with request error
      return Promise.reject(error);
    }
  );

  useImperativeHandle(ref, () => ({
    submitHandler,
    checkMandatoryFields,
  }));

  const [errors, setErrors] = useState({});
  const [spouseErrors, setSpouseErrors] = useState({});

  useEffect(() => {
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 100);
  }, [errors, spouseErrors]);

  const [isLargerThan768] = useMediaQuery("(min-width: 500px)");
  const isMobile = !isLargerThan768;

  const [age, setAge] = useState("");
  const [spouseAge, setSpouseAge] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [spouseName, setSpouseName] = useState("");
  const [spouseLastName, setSpouseLastName] = useState("");

  const { spouseStatus, setSpouseStatus } = useContext(SpouseContext);

  const userCASHInfo = useStore((store) => store.userCASHInfo);
  const setUserCASHInfo = useStore((store) => store.setUserCASHInfo);
  const userInfo = useStore((store) => store.userInfo);

  const [isEditMode, setIsEditMode] = useState(false);

  const userOptiData = useStore((store) => store.userOptiData);
  const [newUser, setNewUser] = useState();

  useEffect(() => {
    if (userOptiData?.primaryUserData?.expenses?.length > 1) {
      setNewUser(false);
    } else if (userOptiData?.primaryUserData?.expenses?.length === undefined) {
      setNewUser(true);
      setIsEditMode(true);
    }
  }, [userOptiData]);

  // Function to toggle edit mode
  const toggleEditMode = () => {
    setIsEditMode(!isEditMode);
  };

  // SPOUSE 1 Data
  const [hasCash, setHasCash] = useState();
  const [cashValue, setCashValue] = useState(0);
  const [minCash, setMinCash] = useState("");
  const [minCashValue, setMinCashValue] = useState(0);

  // Spouse 2 Data
  const [spouseHasCash, setSpouseHasCash] = useState();
  const [spouseCashValue, setSpouseCashValue] = useState(0);
  const [spouseMinCash, setSpouseMinCash] = useState();
  const [spouseMinCashValue, setSpouseMinCashValue] = useState(0);

  useEffect(() => {
    if (userCASHInfo !== null) {
      setCashValue(userCASHInfo?.value);
      setHasCash(userCASHInfo?.hasCash);
      setMinCash(userCASHInfo?.minCash);
      setMinCashValue(userCASHInfo?.minCashValue);
      setSpouseCashValue(userCASHInfo?.spouseValue);
      setSpouseHasCash(userCASHInfo?.spouseHasCash);
      setSpouseMinCash(userCASHInfo?.spouseMinCash);
      setSpouseMinCashValue(userCASHInfo?.spouseMinCashValue);
    }
    if (userInfo !== null) {
      setSpouseName(userInfo?.spouseFirstName);
      setSpouseLastName(userInfo?.spouseLastName);
      setFirstName(userInfo?.FirstName);
      setLastName(userInfo?.LastName);
      setAge(userInfo?.DateOfBirth);
      setSpouseAge(userInfo?.spouseDateOfBirth);
    }
  }, []);

  function getAge(birthday, currentDate) {
    // Get the current date
    // const currentDate = new Date();

    // Convert the birthday string into a Date object
    const birthDate = new Date(birthday);

    // Calculate the age in years
    let age = currentDate.getFullYear() - birthDate.getFullYear();

    // Decrement the age if the current month and day are before the birth month and day
    if (
      currentDate.getMonth() < birthDate.getMonth() ||
      (currentDate.getMonth() === birthDate.getMonth() &&
        currentDate.getDate() <= birthDate.getDate())
    ) {
      age -= 1;
    }

    return age;
  }

  const checkMandatoryFields = () => {
    let newErrors = {};
    let newSpouseErrors = {};

    // Individual checks
    if (!hasCash || hasCash === "") {
      newErrors.hasCash = "Cash status is required";
    }
    if (hasCash === "Yes" && !cashValue) {
      newErrors.cashValue = "Cash value is required";
    }
    if (hasCash === "Yes" && !minCash) {
      newErrors.minCash = "Cash Strategy Required";
    }
    if (hasCash === "Yes" && minCash === "Yes" && minCashValue >= cashValue) {
      newErrors.minCashValue = "Minimum cash value cannot exceed your current total";
    }

    // Spouse checks
    if (spouseStatus) {
      if (!spouseHasCash || spouseHasCash === "") {
        newSpouseErrors.hasCash = "Cash status is required";
      }
      if (spouseHasCash === "Yes" && !spouseCashValue) {
        newSpouseErrors.cashValue = "Cash value is required";
      }
      if (spouseHasCash === "Yes" && !spouseMinCash) {
        newErrors.minCash = "Cash Strategy Required";
      }
      if (
        spouseHasCash === "Yes" &&
        spouseMinCash === "Yes" &&
        spouseMinCashValue >= spouseCashValue
      ) {
        newSpouseErrors.minCashValue = "Minimum cash value cannot exceed your current total";
      }
    }

    setSpouseErrors(newSpouseErrors);
    setErrors(newErrors);
    return (
      Object.keys(newErrors).length === 0 &&
      Object.keys(newSpouseErrors).length === 0
    ); // Return true if no errors
  };

  const submitHandler = (e) => {
    if (e) {
      e.preventDefault();
    }
    setIsEditMode(!isEditMode);
    let ValueCash = cashValue;
    let SpouseValueCash = spouseCashValue;
    let MinimumCash = minCashValue
    let SpouseMinimumCash = spouseMinCashValue
    if (hasCash == "No") {
      ValueCash = 0;
      setCashValue(0);
      MinimumCash = 0
    }
    if(minCash === "No"){
      MinimumCash = 0
    }
    if (spouseHasCash == "No") {
      SpouseValueCash = 0;
      setSpouseCashValue(0);
      SpouseMinimumCash = 0;
    }
    if(spouseMinCash === "No"){
      SpouseMinimumCash = 0
    }
    const cashURL = (action) =>
      `${
        process.env.REACT_APP_NODE_API_GATEWAY
      }/CompassLambdaApi/CASH/${action}?CashID=${user.sub.replace(
        "|",
        "%7c"
      )}&value=${ValueCash}&hasCash=${hasCash}&minCash=${minCash}&minCashValue=${MinimumCash}&spouseValue=${SpouseValueCash}&spouseHasCash=${spouseHasCash}&spouseMinCash=${spouseMinCash}&spouseMinCashValue=${SpouseMinimumCash}`;
    axiosInstance
      .get(cashURL("add"))
      .then((res) => {
        setUserCASHInfo({
          ...userCASHInfo,
          value: ValueCash,
          hasCash: hasCash,
          minCash: minCash,
          minCashValue: MinimumCash,

          spouseValue: SpouseValueCash,
          spouseHasCash: spouseHasCash,
          spouseMinCash: spouseMinCash,
          spouseMinCashValue: SpouseMinimumCash,
        });
        if (res.data.code === "ER_DUP_ENTRY") {
          axiosInstance.get(cashURL("update")).then((res) => {
            console.log(res);
            setUserCASHInfo({
              ...userCASHInfo,
              value: ValueCash,
              hasCash: hasCash,
              minCash: minCash,
              minCashValue: MinimumCash,

              spouseValue: SpouseValueCash,
              spouseHasCash: spouseHasCash,
              spouseMinCash: spouseMinCash,
              spouseMinCashValue: SpouseMinimumCash,
            });
          });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const moneyToNumber = (value) => {
    var numValue = value.replace(/[$,\s]/g, "");
    numValue = Number(numValue);
    return numValue;
  };

  return (
    <Flex
      display="flex"
      flexWrap="wrap" // Enable wrapping
      justifyContent="flex-start"
      alignItems="start"
      width={"100%"}
    >
      {!isMobile && (
        <VStack alignItems={"flex-start"} width={"100%"} mt={-2}>
          <GlobalBodyPage
            Heading="Cash Savings"
            mainframeContent={
              <>
                <Flex justify="space-between" w="100%">
                  <Heading
                    height={"27px"}
                    alignSelf={"stretch"}
                    color={"#00466D"}
                    fontFamily={"Nunito"}
                    fontSize={"20px"}
                    fontStyle={"normal"}
                    fontWeight={"700"}
                    lineHeight={"normal"}
                  >
                    {firstName} {lastName}
                  </Heading>
                </Flex>
                <Box
                  display={"flex"}
                  width={"100%"}
                  flexDirection={"row"}
                  paddingTop={"10px"}
                  alignItems={"flex-end"}
                  justifyContent={"space-between"}
                  gap={"10px"}
                  flexWrap={"wrap"}
                >
                  <DropDownButton
                    Heading={"Do you have a cash savings account?"}
                    placeholder={"Select"}
                    dropDownItems={[
                      { key: "Yes", value: "Yes" },
                      { key: "No", value: "No" },
                    ]}
                    value={hasCash}
                    onChange={(key) => {
                      setChangedData(true);
                      setHasCash(key);
                    }}
                    customWidth={"100%"}
                    error={errors.hasCash}
                    tooltipContent={TooltipContent.cashYesNo}
                  />
                  {hasCash === "Yes" && (
                    <CashQuestion
                      spouseCurrentTotal={cashValue}
                      setSpouseCurrentTotal={setCashValue}
                      spouseStrategy={minCash}
                      setSpouseStrategy={setMinCash}
                      spouseCashBalance={minCashValue}
                      setSpouseCashBalance={setMinCashValue}
                      moneyToNumber={moneyToNumber}
                      errors={errors}
                      setChangedData={setChangedData}
                    />
                  )}
                </Box>
              </>
            }
            videoName={"Cash Savings Page"}
            video={"Cash_Savings.mp4"}
          />
          {spouseStatus && (
            <GlobalBodyPage
              mainframeContent={
                <>
                  <Flex justify="space-between" w="100%">
                    <Heading
                      height={"27px"}
                      alignSelf={"stretch"}
                      color={"#00466D"}
                      fontFamily={"Nunito"}
                      fontSize={"20px"}
                      fontStyle={"normal"}
                      fontWeight={"700"}
                      lineHeight={"normal"}
                    >
                      {spouseName} {spouseLastName}
                    </Heading>
                  </Flex>
                  <Box
                    display={"flex"}
                    width={"100%"}
                    flexDirection={"row"}
                    paddingTop={"10px"}
                    alignItems={"flex-end"}
                    justifyContent={"space-between"}
                    gap={"10px"}
                    flexWrap={"wrap"}
                  >
                    <DropDownButton
                      Heading={"Do you have a cash savings account?"}
                      placeholder={"Select"}
                      dropDownItems={[
                        { key: "Yes", value: "Yes" },
                        { key: "No", value: "No" },
                      ]}
                      value={spouseHasCash}
                      onChange={(key) => {
                        setChangedData(true);
                        setSpouseHasCash(key);
                      }}
                      customWidth={"100%"}
                      error={spouseErrors.hasCash}
                      tooltipContent={TooltipContent.cashYesNo}
                    />
                    {spouseHasCash === "Yes" && (
                      <CashQuestion
                        spouseCurrentTotal={spouseCashValue}
                        setSpouseCurrentTotal={setSpouseCashValue}
                        spouseStrategy={spouseMinCash}
                        setSpouseStrategy={setSpouseMinCash}
                        spouseCashBalance={spouseMinCashValue}
                        setSpouseCashBalance={setSpouseMinCashValue}
                        moneyToNumber={moneyToNumber}
                        errors={spouseErrors}
                        setChangedData={setChangedData}
                      />
                    )}
                  </Box>
                </>
              }
            />
          )}
        </VStack>
      )}
      {isMobile && (
        <VStack alignItems={"flex-start"} width={"100%"} mt={-2}>
          <GlobalBodyPage
            Heading="Cash Savings"
            mainframeContent={
              <>
                <Flex justify="space-between" w="100%">
                  <Heading
                    height={"27px"}
                    alignSelf={"stretch"}
                    color={"#00466D"}
                    fontFamily={"Nunito"}
                    fontSize={"20px"}
                    fontStyle={"normal"}
                    fontWeight={"700"}
                    lineHeight={"normal"}
                  >
                    {firstName} {lastName}
                  </Heading>
                </Flex>
                <Box
                  display={"flex"}
                  width={"100%"}
                  flexDirection={"row"}
                  paddingTop={"10px"}
                  alignItems={"flex-end"}
                  justifyContent={"space-between"}
                  gap={"10px"}
                  flexWrap={"wrap"}
                >
                  <DropDownButton
                    Heading={"Do you have a cash savings account?"}
                    placeholder={"Select"}
                    dropDownItems={[
                      { key: "Yes", value: "Yes" },
                      { key: "No", value: "No" },
                    ]}
                    value={hasCash}
                    onChange={(key) => {
                      setChangedData(true);
                      setHasCash(key);
                    }}
                    customWidth={"100%"}
                    MenuCustomWidth={"100%"}
                    tooltipContent={TooltipContent.cashYesNo}
                  />
                  {hasCash === "Yes" && (
                    <CashQuestion
                      spouseCurrentTotal={cashValue}
                      setSpouseCurrentTotal={setCashValue}
                      spouseStrategy={minCash}
                      setSpouseStrategy={setMinCash}
                      spouseCashBalance={minCashValue}
                      setSpouseCashBalance={setMinCashValue}
                      moneyToNumber={moneyToNumber}
                      errors={errors}
                      setChangedData={setChangedData}
                    />
                  )}
                </Box>
              </>
            }
            videoName={"Cash Savings Page"}
            video={"Cash_Savings.mp4"}
          />
          {spouseStatus && (
            <GlobalBodyPage
              mainframeContent={
                <>
                  <Flex justify="space-between" w="100%">
                    <Heading
                      height={"27px"}
                      alignSelf={"stretch"}
                      color={"#00466D"}
                      fontFamily={"Nunito"}
                      fontSize={"20px"}
                      fontStyle={"normal"}
                      fontWeight={"700"}
                      lineHeight={"normal"}
                    >
                      {spouseName} {spouseLastName}
                    </Heading>
                  </Flex>
                  <Box
                    display={"flex"}
                    width={"100%"}
                    flexDirection={"row"}
                    paddingTop={"10px"}
                    alignItems={"flex-end"}
                    justifyContent={"space-between"}
                    gap={"10px"}
                    flexWrap={"wrap"}
                  >
                    <DropDownButton
                      Heading={"Do you have a cash savings account?"}
                      placeholder={"Select"}
                      dropDownItems={[
                        { key: "Yes", value: "Yes" },
                        { key: "No", value: "No" },
                      ]}
                      value={spouseHasCash}
                      onChange={(key) => {
                        setChangedData(true);
                        setSpouseHasCash(key);
                      }}
                      customWidth={"100%"}
                      MenuCustomWidth={"100%"}
                      tooltipContent={TooltipContent.cashYesNo}
                    />
                    {spouseHasCash === "Yes" && (
                      <CashQuestion
                        spouseCurrentTotal={spouseCashValue}
                        setSpouseCurrentTotal={setSpouseCashValue}
                        spouseStrategy={spouseMinCash}
                        setSpouseStrategy={setSpouseMinCash}
                        spouseCashBalance={spouseMinCashValue}
                        setSpouseCashBalance={setSpouseMinCashValue}
                        moneyToNumber={moneyToNumber}
                        errors={spouseErrors}
                        setChangedData={setChangedData}
                      />
                    )}
                  </Box>
                </>
              }
            />
          )}
        </VStack>
      )}
    </Flex>
  );
});

function CashQuestion({
  spouseCurrentTotal,
  setSpouseCurrentTotal,
  spouseStrategy,
  setSpouseStrategy,
  spouseCashBalance,
  setSpouseCashBalance,
  moneyToNumber,
  errors,
  setChangedData,
}) {
  const [isLargerThan768] = useMediaQuery("(min-width: 500px)");
  const isMobile = !isLargerThan768;
  return (
    <>
      {isMobile && (
        <>
          <NormalInputButton
            Heading={"Current total value"}
            placeholder={"$"}
            value={spouseCurrentTotal}
            onChange={(e) => {
              setChangedData(true);
              setSpouseCurrentTotal(moneyToNumber(e.target.value));
            }}
            useThousandSeparator={true}
            InputWidth={"100%"}
            error={errors.cashValue}
            tooltipContent={TooltipContent.cashValue}
          />
          <Box
            display={"flex"}
            padding={"20px 10px"}
            flexDirection={"column"}
            justifyContent={"center"}
            alignItems={"center"}
            gap={"10px"}
            borderRadius={"10px"}
            border={"1px solid #00A2D4"}
            bg={"rgba(0, 162, 212, 0.1)"}
          >
            <Text
              alignSelf={"stretch"}
              color={"#00A2D4"}
              fontFamily={"Nunito"}
              fontSize={"14px"}
              fontStyle={"normal"}
              fontWeight={"700"}
              lineHeight={"normal"}
              textAlign={"center"}
            >
              Optiml is designed to strategically distribute your cash savings
              into investment accounts, maximizing your estate’s potential. You
              have the option to retain a minimum cash balance for rainy day
              funds and instant liquidity, or allow Optiml to fully optimize
              your cash savings through the best investment strategies,
              enhancing the overall value of your estate.
            </Text>
          </Box>
          <DropDownButton
            Heading={"Strategy"}
            placeholder={"Select"}
            dropDownItems={[
              { key: "Yes", value: "Maintain minimum cash balance" },
              { key: "No", value: "Optimize entire cash savings" },
            ]}
            value={spouseStrategy}
            onChange={(key) => {
              setChangedData(true);
              setSpouseStrategy(key);
            }}
            MenuCustomWidth={"100%"}
            error={errors.minCash}
          />
          {spouseStrategy === "Yes" && (
            <NormalInputButton
              Heading={"Minimum cash balance value"}
              placeholder={"$"}
              value={spouseCashBalance}
              onChange={(e) => {
                setChangedData(true);
                setSpouseCashBalance(moneyToNumber(e.target.value));
              }}
              useThousandSeparator={true}
              InputWidth={"100%"}
              error={errors.minCashValue}
              tooltipContent={TooltipContent.cashMinimumAmount}
            />
          )}
        </>
      )}

      {!isMobile && (
        <>
          <NormalInputButton
            Heading={"Current total value"}
            placeholder={"$"}
            value={spouseCurrentTotal}
            onChange={(e) => {
              setChangedData(true);
              setSpouseCurrentTotal(moneyToNumber(e.target.value));
            }}
            useThousandSeparator={true}
            error={errors.cashValue}
            tooltipContent={TooltipContent.cashValue}
          />
          <Box
            display={"flex"}
            padding={"20px 10px"}
            flexDirection={"column"}
            justifyContent={"center"}
            alignItems={"center"}
            gap={"10px"}
            borderRadius={"10px"}
            border={"1px solid #00A2D4"}
            bg={"rgba(0, 162, 212, 0.1)"}
          >
            <Text
              alignSelf={"stretch"}
              color={"#00A2D4"}
              fontFamily={"Nunito"}
              fontSize={"14px"}
              fontStyle={"normal"}
              fontWeight={"700"}
              lineHeight={"normal"}
              textAlign={"center"}
            >
              Optiml is designed to strategically distribute your cash savings
              into investment accounts, maximizing your estate’s potential. You
              have the option to retain a minimum cash balance for rainy day
              funds and instant liquidity, or allow Optiml to fully optimize
              your cash savings through the best investment strategies,
              enhancing the overall value of your estate.
            </Text>
          </Box>
          <DropDownButton
            Heading={"Strategy"}
            placeholder={"Select"}
            dropDownItems={[
              { key: "Yes", value: "Maintain minimum cash balance" },
              { key: "No", value: "Optimize entire cash savings" },
            ]}
            value={spouseStrategy}
            onChange={(key) => {
              setChangedData(true);
              setSpouseStrategy(key);
            }}
            customWidth={"100%"}
            error={errors.minCash}
          />
          {spouseStrategy === "Yes" && (
            <NormalInputButton
              Heading={"Minimum cash balance value"}
              placeholder={"$"}
              value={spouseCashBalance}
              onChange={(e) => {
                setChangedData(true);
                setSpouseCashBalance(moneyToNumber(e.target.value));
              }}
              useThousandSeparator={true}
              customWidth={"100%"}
              error={errors.minCashValue}
              tooltipContent={TooltipContent.cashMinimumAmount}
            />
          )}
        </>
      )}
    </>
  );
}

export default CashSavingsNonRegistered;
