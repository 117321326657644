import { Flex, Heading, VStack, useMediaQuery } from "@chakra-ui/react";
import { useAuth0 } from "@auth0/auth0-react";
import axios from "axios";
import React, {
  forwardRef,
  useContext,
  useEffect,
  useImperativeHandle,
  useState,
} from "react";
import { useStore } from "../../store";
import { SpouseContext } from "../SpouseContext";
import GlobalBodyPage from "../GlobalContainer/BodyPage";
import DropDownButton from "../InputFields/dropDownButton";
import NormalInputButton from "../InputFields/normalInputButton";
import TooltipContent from "../TooltipContent";

const TfsaAccount = forwardRef((props, ref) => {
  const { setChangedData } = props;
  useImperativeHandle(ref, () => ({
    submitHandler,
    checkMandatoryFields,
  }));
  const { getAccessTokenSilently } = useAuth0();

  const axiosInstance = axios.create();
  async function getToken() {
    // Replace this with your Auth0 token retrieval logic
    const token = await getAccessTokenSilently({
      audience:
        "https://os2lsiwff43wfpb5ggyawt6qri0mgcro.lambda-url.us-east-2.on.aws/",
      scope: "write:optimization",
    });
    return token;
  }

  // Add a request interceptor
  axiosInstance.interceptors.request.use(
    async (config) => {
      const token = await getToken();
      config.headers.Authorization = `Bearer ${token}`;
      return config;
    },
    (error) => {
      // Do something with request error
      return Promise.reject(error);
    }
  );
  const [isLargerThan768] = useMediaQuery("(min-width: 500px)");
  const isMobile = !isLargerThan768;

  const [age, setAge] = useState("");
  const [spouseAge, setSpouseAge] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [spouseName, setSpouseName] = useState("");
  const [spouseLastName, setSpouseLastName] = useState("");
  const { spouseStatus, setSpouseStatus } = useContext(SpouseContext);

  const userTFSAInfo = useStore((store) => store.userTFSAInfo);
  const setUserTFSAInfo = useStore((store) => store.setUserTFSAInfo);
  const userInfo = useStore((store) => store.userInfo);

  const [isEditMode, setIsEditMode] = useState(false);

  const userOptiData = useStore((store) => store.userOptiData);
  const [newUser, setNewUser] = useState();

  useEffect(() => {
    if (userOptiData?.primaryUserData?.expenses?.length > 1) {
      setNewUser(false);
    } else if (userOptiData?.primaryUserData?.expenses?.length === undefined) {
      setNewUser(true);
      setIsEditMode(true);
    }
  }, [userOptiData]);

  // Function to toggle edit mode
  const toggleEditMode = () => {
    setIsEditMode(!isEditMode);
  };

  const { user, logout } = useAuth0();

  // Spouse 1 Data
  const [hasTfsa, setHasTfsa] = useState();
  const [tfsaValue, setTfsaValue] = useState("0");
  const [unusedContribution, setUnusedContribution] = useState("");
  const [contributionRoom, setContributionRoom] = useState("0");
  const [rateOfReturn, setRateOfReturn] = useState(6);
  const [tfsaContThisYear, setTfsaContThisYear] = useState();

  // Spouse 2 Data
  const [spouseHasTfsa, setSpouseHasTfsa] = useState();
  const [spouseTfsaValue, setSpouseTfsaValue] = useState("0");
  const [spouseUnusedContribution, setSpouseUnusedContribution] = useState("");
  const [spouseContributionRoom, setSpouseContributionRoom] = useState("0");
  const [spouseRateOfReturn, setSpouseRateOfReturn] = useState(6);
  const [spouseTfsaContThisYear, setSpouseTfsaContThisYear] = useState();

  useEffect(() => {
    if (userTFSAInfo?.TFSA_ROR !== null) {
      setRateOfReturn(userTFSAInfo?.TFSA_ROR ?? 6);
      setSpouseRateOfReturn(userTFSAInfo?.spouseTFSA_ROR ?? 6);
    }
    if (userTFSAInfo !== null) {
      // SPOUSE 1
      setTfsaValue(userTFSAInfo?.totalValue);
      setHasTfsa(userTFSAInfo?.Do_You_TFSA);
      setUnusedContribution(userTFSAInfo?.Do_TFSA_Room);
      setContributionRoom(userTFSAInfo?.TFSA_Room);
      setRateOfReturn(userTFSAInfo?.TFSA_ROR ?? 6);
      setTfsaContThisYear(userTFSAInfo?.tfsaContThisYear);

      // SPOUSE
      setSpouseHasTfsa(userTFSAInfo?.spouseDo_You_TFSA);
      setSpouseTfsaValue(userTFSAInfo?.spouseTotalValue);
      setSpouseUnusedContribution(userTFSAInfo?.spouseDo_TFSA_Room);
      setSpouseContributionRoom(userTFSAInfo?.spouseTFSA_Room);
      setSpouseRateOfReturn(userTFSAInfo?.spouseTFSA_ROR ?? 6);
      setSpouseTfsaContThisYear(userTFSAInfo?.spouseTfsaContThisYear);
    } else {
      setTfsaValue(0);
      setSpouseTfsaValue(0);
      setSpouseAge(0);
    }
    if (userInfo !== null) {
      setSpouseName(userInfo?.spouseFirstName);
      setSpouseLastName(userInfo?.spouseLastName);
      setFirstName(userInfo?.FirstName);
      setLastName(userInfo?.LastName);
      setAge(userInfo?.DateOfBirth);
      setSpouseAge(userInfo?.spouseDateOfBirth);
    }
  }, []);

  const [errors, setErrors] = useState({});

  useEffect(() => {
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 100);
  }, [errors]);

  function getAge(birthday, currentDate) {
    // Get the current date
    // const currentDate = new Date();

    // Convert the birthday string into a Date object
    const birthDate = new Date(birthday);

    // Calculate the age in years
    let age = currentDate.getFullYear() - birthDate.getFullYear();

    // Decrement the age if the current month and day are before the birth month and day
    if (
      currentDate.getMonth() < birthDate.getMonth() ||
      (currentDate.getMonth() === birthDate.getMonth() &&
        currentDate.getDate() <= birthDate.getDate())
    ) {
      age -= 1;
    }

    return age;
  }

  const calculateTFSARoom = (userAge) => {
    const currentDate = new Date();
    const currentAge = getAge(userAge, currentDate);
    // NEEDS MANUAL UPDATES ANNUALLY!
    const currentYear = 2024;
    const tfsaLimits = {
      2009: 5000,
      2010: 5000,
      2011: 5000,
      2012: 5000,
      2013: 5500,
      2014: 5500,
      2015: 10000,
      2016: 5500,
      2017: 5500,
      2018: 5500,
      2019: 6000,
      2020: 6000,
      2021: 6000,
      2022: 6000,
      2023: 6500,
      2024: 7000,
    };
    let contributionRoom = 0;
    const ageOfEligibility = 18; // Age at which one can start contributing to TFSA
    const firstEligibleYear = currentYear - currentAge + ageOfEligibility;

    for (let year = firstEligibleYear; year <= currentYear; year++) {
      if (tfsaLimits[year]) {
        contributionRoom += tfsaLimits[year];
      }
    }

    return contributionRoom;
  };

  const checkMandatoryFields = () => {
    let newErrors = {};

    if (!hasTfsa || hasTfsa === "") {
      newErrors.hasTfsa = "TFSA status is required";
    } else if (hasTfsa === "Yes") {
      if (!tfsaValue) newErrors.tfsaValue = "TFSA value is required";
      if (!unusedContribution)
        newErrors.unusedContribution = "Unused contribution status is required";
      if (unusedContribution === "Yes" && !contributionRoom)
        newErrors.contributionRoom = "Contribution room is required";
      if (!rateOfReturn || rateOfReturn > 20)
        newErrors.rateOfReturn =
          "Rate of return is required and must be greater than 0% and less than 20%"
    }

    if (spouseStatus) {
      if (!spouseHasTfsa || spouseHasTfsa === "") {
        newErrors.spouseHasTfsa = "TFSA status is required";
      } else if (spouseHasTfsa === "Yes") {
        if (!spouseTfsaValue)
          newErrors.spouseTfsaValue = "TFSA value is required";
        if (!spouseUnusedContribution)
          newErrors.spouseUnusedContribution =
            "Unused contribution status is required";
        if (spouseUnusedContribution === "Yes" && !spouseContributionRoom)
          newErrors.spouseContributionRoom = "Contribution room is required";
        if (!spouseRateOfReturn || spouseRateOfReturn > 20)
          newErrors.spouseRateOfReturn =
            "Rate of return is required and must be greater than 0% and less than 20%"
      }
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0; // Return true if no errors
  };

  const submitHandler = (e) => {
    if (e) {
      e.preventDefault();
    }
    setIsEditMode(!isEditMode);
    let ValueTFSA = tfsaValue;
    let ContributionRoom = contributionRoom;
    let SpouseTFSAValue = spouseTfsaValue;
    let SpouseContributionRoom = spouseContributionRoom;
    let contThisYear = tfsaContThisYear;
    let spouseContThisYear = spouseTfsaContThisYear;
    if (hasTfsa == "No") {
      ValueTFSA = 0;
      ContributionRoom = calculateTFSARoom(age);
      setTfsaValue(0);
      setContributionRoom(calculateTFSARoom(age));
      contThisYear = 0;
    }
    if (unusedContribution == "No") {
      ContributionRoom = 0;
      setContributionRoom(0);
    }
    if (spouseHasTfsa == "No") {
      SpouseTFSAValue = 0;
      SpouseContributionRoom = calculateTFSARoom(spouseAge);
      setSpouseTfsaValue(0);
      setSpouseContributionRoom(calculateTFSARoom(spouseAge));
      spouseContThisYear = 0;
    }
    if (spouseUnusedContribution == "No") {
      SpouseContributionRoom = 0;
      setSpouseContributionRoom(0);
    }
    const tfsaURL = (action) =>
      `${
        process.env.REACT_APP_NODE_API_GATEWAY
      }/CompassLambdaApi/TFSA/${action}?TFSAID=${user.sub.replace(
        "|",
        "%7c"
      )}&totalValue=${ValueTFSA}&Do_You_TFSA=${hasTfsa}&Do_TFSA_Room=${unusedContribution}&TFSA_Room=${ContributionRoom}&TFSA_ROR=${rateOfReturn}&spouseTotalValue=${SpouseTFSAValue}&spouseDo_You_TFSA=${spouseHasTfsa}&spouseDo_TFSA_Room=${spouseUnusedContribution}&spouseTFSA_Room=${SpouseContributionRoom}&spouseTFSA_ROR=${spouseRateOfReturn}&tfsaContThisYear=${contThisYear}&spouseTfsaContThisYear=${spouseContThisYear}`;
    axiosInstance
      .get(tfsaURL("add"))
      .then((res) => {
        setUserTFSAInfo({
          ...userTFSAInfo,
          totalValue: ValueTFSA,
          Do_You_TFSA: hasTfsa,
          Do_TFSA_Room: unusedContribution,
          TFSA_Room: ContributionRoom,
          TFSA_ROR: rateOfReturn,
          spouseTotalValue: SpouseTFSAValue,
          spouseDo_You_TFSA: spouseHasTfsa,
          spouseDo_TFSA_Room: spouseUnusedContribution,
          spouseTFSA_Room: SpouseContributionRoom,
          spouseTFSA_ROR: spouseRateOfReturn,
          tfsaContThisYear: contThisYear,
          spouseTfsaContThisYear: spouseContThisYear,
        });
        if (res.data.code === "ER_DUP_ENTRY") {
          axiosInstance.get(tfsaURL("update")).then((res) => {
            console.log(res);
            setUserTFSAInfo({
              ...userTFSAInfo,
              totalValue: ValueTFSA,
              Do_You_TFSA: hasTfsa,
              Do_TFSA_Room: unusedContribution,
              TFSA_Room: ContributionRoom,
              TFSA_ROR: rateOfReturn,
              spouseTotalValue: SpouseTFSAValue,
              spouseDo_You_TFSA: spouseHasTfsa,
              spouseDo_TFSA_Room: spouseUnusedContribution,
              spouseTFSA_Room: SpouseContributionRoom,
              spouseTFSA_ROR: spouseRateOfReturn,
              tfsaContThisYear: contThisYear,
              spouseTfsaContThisYear: spouseContThisYear,
            });
          });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const moneyToNumber = (value) => {
    var numValue = value.replace(/[$,\s]/g, "");
    numValue = Number(numValue);
    return numValue;
  };

  return (
    <Flex
      display="flex"
      flexWrap="wrap" // Enable wrapping
      justifyContent="flex-start"
      alignItems="start"
      width={"100%"}
    >
      {!isMobile && (
        <VStack alignItems={"flex-start"} width={"100%"} mt={-2}>
          <GlobalBodyPage
            Heading="Tax-Free Savings Account"
            mainframeContent={
              <VStack spacing={4} alignItems={"flex-start"} width={"100%"}>
                <Flex justify="space-between" w="100%">
                  <Heading
                    height={"27px"}
                    alignSelf={"stretch"}
                    color={"#00466D"}
                    fontFamily={"Nunito"}
                    fontSize={"20px"}
                    fontStyle={"normal"}
                    fontWeight={"700"}
                    lineHeight={"normal"}
                  >
                    {firstName} {lastName}
                  </Heading>
                </Flex>
                <DropDownButton
                  Heading={"Do you have a TFSA?"}
                  placeholder={"Select"}
                  dropDownItems={[
                    { key: "Yes", value: "Yes" },
                    { key: "No", value: "No" },
                  ]}
                  value={hasTfsa}
                  onChange={(key) => {
                    setChangedData(true);
                    setHasTfsa(key);
                  }}
                  customWidth={"100%"}
                  error={errors.hasTfsa}
                  tooltipContent={TooltipContent.tfsaYesNo}
                />
                {hasTfsa === "Yes" && (
                  <>
                    <NormalInputButton
                      Heading={"What is the current total value?"}
                      placeholder={"$"}
                      value={tfsaValue}
                      onChange={(e) => {
                        setTfsaValue(moneyToNumber(e.target.value));
                        setChangedData(true);
                      }}
                      customWidth={"100%"}
                      useThousandSeparator={true}
                      error={errors.tfsaValue}
                      tooltipContent={TooltipContent.tfsaTotalValue}
                    />
                    <NormalInputButton
                      Heading={
                        "How much have you already contributed to your TFSA this year?"
                      }
                      placeholder={"$"}
                      value={tfsaContThisYear}
                      onChange={(e) => {
                        setTfsaContThisYear(moneyToNumber(e.target.value));
                        setChangedData(true);
                      }}
                      customWidth={"100%"}
                      useThousandSeparator={true}
                      tooltipContent={TooltipContent.contThisYear}
                    />
                    <DropDownButton
                      Heading={"Do you have any unused contribution room?"}
                      placeholder={"Select"}
                      dropDownItems={[
                        { key: "Yes", value: "Yes" },
                        { key: "No", value: "No" },
                      ]}
                      value={unusedContribution}
                      onChange={(key) => {
                        setChangedData(true);
                        setUnusedContribution(key);
                      }}
                      customWidth={"100%"}
                      error={errors.unusedContribution}
                      tooltipContent={TooltipContent.tfsaContributionRoomYesNo}
                    />
                    {unusedContribution === "Yes" && (
                      <NormalInputButton
                        Heading={"Value of the remaining contribution room?"}
                        placeholder={"$"}
                        value={contributionRoom}
                        onChange={(e) => {
                          setContributionRoom(moneyToNumber(e.target.value));
                          setChangedData(true);
                        }}
                        customWidth={"100%"}
                        useThousandSeparator={true}
                        error={errors.contributionRoom}
                        tooltipContent={TooltipContent.tfsaContributionRoom}
                      />
                    )}
                    <NormalInputButton
                      Heading={"Expected annual rate of return?"}
                      placeholder={"%"}
                      value={rateOfReturn}
                      onChange={(e) => {
                        const noSymbol = e.target.value.replace("%", "");
                        const growthNum = parseFloat(noSymbol);
                        setRateOfReturn(
                          !isNaN(growthNum)
                            ? parseFloat(growthNum.toFixed(2))
                            : ""
                        );
                        setChangedData(true);
                      }}
                      customWidth={"100%"}
                      usePercentage={true}
                      error={errors.rateOfReturn}
                      tooltipContent={TooltipContent.tfsaGrowth}
                    />
                  </>
                )}
              </VStack>
            }
            videoName={"TFSA Page"}
            video={"TFSA.mp4"}
          />
          {spouseStatus && (
            <GlobalBodyPage
              mainframeContent={
                <VStack spacing={4} alignItems={"flex-start"} width={"100%"}>
                  <Flex justify="space-between" w="100%">
                    <Heading
                      height={"27px"}
                      alignSelf={"stretch"}
                      color={"#00466D"}
                      fontFamily={"Nunito"}
                      fontSize={"20px"}
                      fontStyle={"normal"}
                      fontWeight={"700"}
                      lineHeight={"normal"}
                    >
                      {spouseName} {spouseLastName}
                    </Heading>
                  </Flex>
                  <DropDownButton
                    Heading={"Do you have a TFSA?"}
                    placeholder={"Select"}
                    dropDownItems={[
                      { key: "Yes", value: "Yes" },
                      { key: "No", value: "No" },
                    ]}
                    value={spouseHasTfsa}
                    onChange={(key) => {
                      setChangedData(true);
                      setSpouseHasTfsa(key);
                    }}
                    customWidth={"100%"}
                    error={errors.spouseHasTfsa}
                    tooltipContent={TooltipContent.tfsaYesNo}
                  />
                  {spouseHasTfsa === "Yes" && (
                    <>
                      <NormalInputButton
                        Heading={"What is the current total value?"}
                        placeholder={"$"}
                        value={spouseTfsaValue}
                        onChange={(e) => {
                          setSpouseTfsaValue(moneyToNumber(e.target.value));
                          setChangedData(true);
                        }}
                        customWidth={"100%"}
                        useThousandSeparator={true}
                        error={errors.spouseTfsaValue}
                        tooltipContent={TooltipContent.tfsaTotalValue}
                      />
                      <NormalInputButton
                        Heading={
                          "How much have you already contributed to your TFSA this year?"
                        }
                        placeholder={"$"}
                        value={spouseTfsaContThisYear}
                        onChange={(e) => {
                          setSpouseTfsaContThisYear(
                            moneyToNumber(e.target.value)
                          );
                          setChangedData(true);
                        }}
                        customWidth={"100%"}
                        useThousandSeparator={true}
                        tooltipContent={TooltipContent.contThisYear}
                      />
                      <DropDownButton
                        Heading={"Do you have any unused contribution room?"}
                        placeholder={"Select"}
                        dropDownItems={[
                          { key: "Yes", value: "Yes" },
                          { key: "No", value: "No" },
                        ]}
                        value={spouseUnusedContribution}
                        onChange={(key) => {
                          setChangedData(true);
                          setSpouseUnusedContribution(key);
                        }}
                        customWidth={"100%"}
                        error={errors.spouseUnusedContribution}
                        tooltipContent={
                          TooltipContent.tfsaContributionRoomYesNo
                        }
                      />

                      {spouseUnusedContribution === "Yes" && (
                        <NormalInputButton
                          Heading={"Value of the remaining contribution room?"}
                          placeholder={"$"}
                          value={spouseContributionRoom}
                          onChange={(e) => {
                            setSpouseContributionRoom(
                              moneyToNumber(e.target.value)
                            );
                            setChangedData(true);
                          }}
                          customWidth={"100%"}
                          useThousandSeparator={true}
                          error={errors.spouseContributionRoom}
                          tooltipContent={TooltipContent.tfsaContributionRoom}
                        />
                      )}
                      <NormalInputButton
                        Heading={"Expected annual rate of return?"}
                        placeholder={"%"}
                        value={spouseRateOfReturn}
                        onChange={(e) => {
                          const noSymbol = e.target.value.replace("%", "");
                          const growthNum = parseFloat(noSymbol);
                          setSpouseRateOfReturn(
                            !isNaN(growthNum)
                              ? parseFloat(growthNum.toFixed(2))
                              : ""
                          );
                          setChangedData(true);
                        }}
                        customWidth={"100%"}
                        usePercentage={true}
                        error={errors.spouseRateOfReturn}
                        tooltipContent={TooltipContent.tfsaGrowth}
                      />
                    </>
                  )}
                </VStack>
              }
            />
          )}
        </VStack>
      )}
      {isMobile && (
        <VStack spacing={4} alignItems={"flex-start"} width={"100%"}>
          <GlobalBodyPage
            Heading="Tax-Free Savings Account"
            mainframeContent={
              <VStack spacing={4} alignItems={"flex-start"} width={"100%"}>
                <Flex justify="space-between" w="100%">
                  <Heading
                    height={"27px"}
                    alignSelf={"stretch"}
                    color={"#00466D"}
                    fontFamily={"Nunito"}
                    fontSize={"20px"}
                    fontStyle={"normal"}
                    fontWeight={"700"}
                    lineHeight={"normal"}
                  >
                    {firstName} {lastName}
                  </Heading>
                </Flex>
                <DropDownButton
                  Heading={"Do you have a TFSA?"}
                  placeholder={"Select"}
                  dropDownItems={[
                    { key: "Yes", value: "Yes" },
                    { key: "No", value: "No" },
                  ]}
                  value={hasTfsa}
                  onChange={(key) => {
                    setChangedData(true);
                    setHasTfsa(key);
                  }}
                  customWidth={"100%"}
                  MenuCustomWidth={"100%"}
                  error={errors.hasTfsa}
                  tooltipContent={TooltipContent.tfsaYesNo}
                />
                {hasTfsa === "Yes" && (
                  <VStack>
                    <NormalInputButton
                      Heading={"What is the current total value?"}
                      placeholder={"$"}
                      value={tfsaValue}
                      onChange={(e) => {
                        setChangedData(true);
                        setTfsaValue(moneyToNumber(e.target.value));
                      }}
                      customWidth={"100%"}
                      useThousandSeparator={true}
                      InputWidth={"100%"}
                      error={errors.tfsaValue}
                      tooltipContent={TooltipContent.tfsaTotalValue}
                    />
                    <NormalInputButton
                      Heading={
                        "How much have you already contributed to your TFSA this year?"
                      }
                      placeholder={"$"}
                      value={tfsaContThisYear}
                      onChange={(e) => {
                        setChangedData(true);
                        setTfsaContThisYear(moneyToNumber(e.target.value));
                      }}
                      customWidth={"100%"}
                      useThousandSeparator={true}
                      InputWidth={"100%"}
                      tooltipContent={TooltipContent.contThisYear}
                    />
                    <DropDownButton
                      Heading={"Do you have any unused contribution room?"}
                      placeholder={"Select"}
                      dropDownItems={[
                        { key: "Yes", value: "Yes" },
                        { key: "No", value: "No" },
                      ]}
                      value={unusedContribution}
                      onChange={(key) => {
                        setChangedData(true);
                        setUnusedContribution(key);
                      }}
                      customWidth={"100%"}
                      MenuCustomWidth={"100%"}
                      error={errors.unusedContribution}
                      tooltipContent={TooltipContent.tfsaContributionRoomYesNo}
                    />
                    {unusedContribution === "Yes" && (
                      <NormalInputButton
                        Heading={"Value of the remaining contribution room?"}
                        placeholder={"$"}
                        value={contributionRoom}
                        onChange={(e) => {
                          setChangedData(true);
                          setContributionRoom(moneyToNumber(e.target.value));
                        }}
                        customWidth={"100%"}
                        useThousandSeparator={true}
                        InputWidth={"100%"}
                        error={errors.contributionRoom}
                        tooltipContent={TooltipContent.tfsaContributionRoom}
                      />
                    )}
                    <NormalInputButton
                      Heading={"Expected annual rate of return?"}
                      placeholder={"%"}
                      value={rateOfReturn}
                      onChange={(e) => {
                        setChangedData(true);
                        const noSymbol = e.target.value.replace("%", "");
                        const growthNum = parseFloat(noSymbol);
                        setRateOfReturn(
                          !isNaN(growthNum)
                            ? parseFloat(growthNum.toFixed(2))
                            : ""
                        );
                      }}
                      customWidth={"100%"}
                      usePercentage={true}
                      InputWidth={"100%"}
                      error={errors.rateOfReturn}
                      tooltipContent={TooltipContent.tfsaGrowth}
                    />
                  </VStack>
                )}
              </VStack>
            }
            videoName={"TFSA Page"}
            video={"TFSA.mp4"}
          />
          {spouseStatus && (
            <GlobalBodyPage
              mainframeContent={
                <VStack spacing={4} alignItems={"flex-start"} width={"100%"}>
                  <Flex justify="space-between" w="100%">
                    <Heading
                      height={"27px"}
                      alignSelf={"stretch"}
                      color={"#00466D"}
                      fontFamily={"Nunito"}
                      fontSize={"20px"}
                      fontStyle={"normal"}
                      fontWeight={"700"}
                      lineHeight={"normal"}
                    >
                      {spouseName} {spouseLastName}
                    </Heading>
                  </Flex>
                  <DropDownButton
                    Heading={"Do you have a TFSA?"}
                    placeholder={"Select"}
                    dropDownItems={[
                      { key: "Yes", value: "Yes" },
                      { key: "No", value: "No" },
                    ]}
                    value={spouseHasTfsa}
                    onChange={(key) => {
                      setChangedData(true);
                      setSpouseHasTfsa(key);
                    }}
                    customWidth={"100%"}
                    MenuCustomWidth={"100%"}
                    error={errors.spouseHasTfsa}
                    tooltipContent={TooltipContent.tfsaYesNo}
                  />
                  {spouseHasTfsa === "Yes" && (
                    <VStack>
                      <NormalInputButton
                        Heading={"What is the current total value?"}
                        placeholder={"$"}
                        value={spouseTfsaValue}
                        onChange={(e) => {
                          setChangedData(true);
                          setSpouseTfsaValue(moneyToNumber(e.target.value));
                        }}
                        customWidth={"100%"}
                        useThousandSeparator={true}
                        InputWidth={"100%"}
                        error={errors.spouseTfsaValue}
                        tooltipContent={TooltipContent.tfsaTotalValue}
                      />
                      <NormalInputButton
                        Heading={
                          "How much have you already contributed to your TFSA this year?"
                        }
                        placeholder={"$"}
                        value={spouseTfsaContThisYear}
                        onChange={(e) => {
                          setSpouseTfsaContThisYear(
                            moneyToNumber(e.target.value)
                          );
                          setChangedData(true);
                        }}
                        customWidth={"100%"}
                        useThousandSeparator={true}
                        InputWidth={"100%"}
                        tooltipContent={TooltipContent.contThisYear}
                      />
                      <DropDownButton
                        Heading={"Do you have any unused contribution room?"}
                        placeholder={"Select"}
                        dropDownItems={[
                          { key: "Yes", value: "Yes" },
                          { key: "No", value: "No" },
                        ]}
                        value={spouseUnusedContribution}
                        onChange={(key) => {
                          setChangedData(true);
                          setSpouseUnusedContribution(key);
                        }}
                        customWidth={"100%"}
                        MenuCustomWidth={"100%"}
                        error={errors.spouseUnusedContribution}
                        tooltipContent={
                          TooltipContent.tfsaContributionRoomYesNo
                        }
                      />
                      {spouseUnusedContribution === "Yes" && (
                        <NormalInputButton
                          Heading={"Value of the remaining contribution room?"}
                          placeholder={"$"}
                          value={spouseContributionRoom}
                          onChange={(e) => {
                            setChangedData(true);
                            setSpouseContributionRoom(
                              moneyToNumber(e.target.value)
                            );
                          }}
                          customWidth={"100%"}
                          useThousandSeparator={true}
                          InputWidth={"100%"}
                          error={errors.spouseContributionRoom}
                          tooltipContent={TooltipContent.tfsaContributionRoom}
                        />
                      )}
                      <NormalInputButton
                        Heading={"Expected annual rate of return?"}
                        placeholder={"%"}
                        value={spouseRateOfReturn}
                        onChange={(e) => {
                          setChangedData(true);
                          const noSymbol = e.target.value.replace("%", "");
                          const growthNum = parseFloat(noSymbol);
                          setSpouseRateOfReturn(
                            !isNaN(growthNum)
                              ? parseFloat(growthNum.toFixed(2))
                              : ""
                          );
                        }}
                        customWidth={"100%"}
                        usePercentage={true}
                        InputWidth={"100%"}
                        error={errors.spouseRateOfReturn}
                        tooltipContent={TooltipContent.tfsaGrowth}
                      />
                    </VStack>
                  )}
                </VStack>
              }
            />
          )}
        </VStack>
      )}
    </Flex>
  );
});

export default TfsaAccount;
