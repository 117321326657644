import {
  Box,
  Flex,
  HStack,
  Image,
  PopoverTrigger,
  Show,
  Text,
  Popover,
  PopoverContent,
} from "@chakra-ui/react";
import React, { useState, useEffect, useRef, useContext } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { useStore } from "../../store";
import { StripeContext } from "../StripeContext";
import Link from "../Link";
import {
  LinkBankAccountIcon,
  LogoutIcon,
  ProfileIcon,
  SettingIcon,
  TutorialVideoIcon,
} from "../CustomIcons";

function Header() {
  const [selectedOption, setSelectedOption] = useState(null);
  const userInfo = useStore((store) => store.userInfo);
  const { FirstName: firstName, LastName: lastName } = userInfo;
  const showNewOptimization = useStore((store) => store.showNewOptimization);
  const userOptiData = useStore((store) => store.userOptiData);
  const [newUser, setNewUser] = useState();
  const { user, logout } = useAuth0();
  const [profileIcon, setProfileIcon] = useState();

  const { stripePlan, setStripePlan } = useContext(StripeContext);

  useEffect(() => {
    if (userOptiData?.primaryUserData?.expenses?.length > 1) {
      setNewUser(false);
    } else if (userOptiData?.primaryUserData?.expenses?.length === undefined) {
      setNewUser(true);
    }
    setProfileIcon(userInfo?.Picture)
  }, [userOptiData, userInfo]);

  useEffect(() => {
    // Configure AWS SDK with environment variables
    AWS.config.update({
      accessKeyId: process.env.REACT_APP_PICTURE_ID,
      secretAccessKey: process.env.REACT_APP_PROFILE_SECRET,
      region: "us-east-2",
    });
    const s3 = new AWS.S3();
    const params = {
      Bucket: "optiml-user-pictures",
      Key: "profile-pics/" + user?.sub, // Unique key per user
      Expires: 3600, // Valid for 1 hour
    };

    s3.getSignedUrl("getObject", params, (err, url) => {
      if (err) {
        console.error("Error generating pre-signed URL:", err);
      } else {
        setProfileIcon(url);
      }
    });
  }, [profileIcon]);


  const navigation = [
    {
      label: "Tutorial videos",
      icon: <TutorialVideoIcon />,
      path: "/tutorial-page",
    },
    !newUser &&
      !showNewOptimization && {
        label: "Settings",
        icon: <SettingIcon />,
        path: "/",
      },
    {
      label: "Link Investment Accounts",
      icon: <LinkBankAccountIcon svgwidth={"20px"} svgheight={"18px"} />,
      path: "/investments-account",
    },
  ].filter(Boolean);

  const popUpProfile = [
    { label: "View Profile", icon: <ProfileIcon />, path: "/" },
    { label: "Logout", icon: <LogoutIcon /> },
  ];

  const handleSelect = (label) => {
    setSelectedOption(label);
  };

  const [showHeader, setShowHeader] = useState(true);
  const lastScrollY = useRef(0);

  useEffect(() => {
    const handleScroll = () => {
      const currentScrollY = window.scrollY;

      if (currentScrollY < lastScrollY.current) {
        setShowHeader(true);
      } else if (currentScrollY > 100 && currentScrollY > lastScrollY.current) {
        setShowHeader(false);
      }
      lastScrollY.current = currentScrollY;
    };

    window.addEventListener("scroll", handleScroll, { passive: true });

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const [imageError, setImageError] = useState(false);

  return (
    <Flex
      display="flex"
      width="100%"
      position="sticky"
      top="0"
      zIndex="1000"
      padding="20px 50px"
      justifyContent="space-between"
      alignItems="center"
      bg="#FFF"
      borderBottom={"1px solid #0F9CD6"}
      opacity={showHeader ? 1 : 0}
      transition="opacity 0.5s ease-in-out"
      pointerEvents={showHeader ? "auto" : "none"}
    >
      {/* Heading buttons */}
      <Box
        display={"flex"}
        alignItems={"flex-start"}
        gap={"20px"}
        background={"#FFF"}
      >
        {!newUser &&
          navigation.map((item) => (
            <Show breakpoint="">
              <Link
                key={item.label}
                href={item.path}
                style={{ textDecoration: "none" }}
              >
                <Box
                  as="button"
                  display={"flex"}
                  padding={"10px"}
                  paddingX={"20px"}
                  alignItems={"center"}
                  gap={"15px"}
                  borderRadius="5px"
                  _hover={{ background: "#E9E9E9" }}
                  color="#004069"
                  onClick={() => handleSelect(item.label)}
                >
                  <HStack
                    justifyContent="center"
                    gap={"15px"}
                    alignItems={"baseline"}
                  >
                    <Box fontSize={"20px"} objectFit={"fill"}>
                      {item.icon}
                    </Box>
                    <Box justifyItems={"baseline"}>
                      <Text
                        fontFamily="Nunito"
                        fontSize={"16px"}
                        fontStyle={"normal"}
                        fontWeight={"600"}
                        lineHeight={"normal"}
                      >
                        {item.label}
                      </Text>
                    </Box>
                  </HStack>
                </Box>
              </Link>
            </Show>
          ))}
      </Box>
      {/* User Profile */}
      <Popover placement="left">
        <PopoverTrigger>
          <Box
            display={"flex"}
            alignItems={"center"}
            gap={"12px"}
            cursor={"pointer"}
          >
            <Text
              color={"#004069"}
              fontFamily={"Nunito"}
              fontSize={"16px"}
              fontStyle={"normal"}
              fontWeight={"600"}
              lineHeight={"normal"}
            >
              {`Welcome, ${firstName}`}
            </Text>
            {/* Profile Image pop up box */}
            <Box>
              <Box
                display="flex"
                flexDirection="column"
                justifyContent="start"
                alignItems="center"
                width="50px"
                height="50px"
                // gap="10px"
                borderRadius="50%"
                border="1px solid #0093D0"
                background="#FFF"
                position="relative"
                overflow="hidden"
              >
                {!imageError && profileIcon ? (
                  <img
                    src={profileIcon}
                    alt="Profile"
                    style={{
                      width: "100%",
                      height: "100%",
                      objectFit: "cover",
                      objectPosition: "top center",
                      justifyContent: "center",
                    }}
                    onError={() => setImageError(true)}
                  />
                ) : (
                  <div className="profile-picture">
                    {firstName.charAt(0)}
                    {lastName.charAt(0)}
                  </div>
                )}
              </Box>
            </Box>
          </Box>
        </PopoverTrigger>
        <PopoverContent w={"200px"} marginTop={"25px"}>
          <Box
            display={"inline-flex"}
            padding={"10px"}
            flexDirection={"column"}
            alignItems={"flex-start"}
            gap={"10px"}
            borderRadius={"5px"}
            border={"1px solid #D9D9D9"}
            bg={"#FFF"}
          >
            {popUpProfile.map((item) =>
              item.path ? (
                <Link
                  key={item.label}
                  href={item.path}
                  style={{ textDecoration: "none" }}
                >
                  <HStack
                    justifyContent="center"
                    gap={"15px"}
                    alignItems={"baseline"}
                    paddingLeft={"10px"}
                  >
                    <Box fontSize={"20px"} objectFit={"fill"}>
                      {item.icon}
                    </Box>
                    <Box justifyItems={"baseline"}>
                      <Text
                        fontFamily="Nunito"
                        fontSize={"16px"}
                        fontStyle={"normal"}
                        fontWeight={"600"}
                        lineHeight={"normal"}
                      >
                        {item.label}
                      </Text>
                    </Box>
                  </HStack>
                </Link>
              ) : (
                <Box
                  key={item.label}
                  onClick={logout}
                  style={{ textDecoration: "none", cursor: "pointer" }}
                >
                  <HStack
                    justifyContent="center"
                    gap={"15px"}
                    alignItems={"baseline"}
                    paddingLeft={"10px"}
                  >
                    <Box fontSize={"20px"} objectFit={"fill"}>
                      {item.icon}
                    </Box>
                    <Box justifyItems={"baseline"}>
                      <Text
                        fontFamily="Nunito"
                        fontSize={"16px"}
                        fontStyle={"normal"}
                        fontWeight={"600"}
                        lineHeight={"normal"}
                      >
                        {item.label}
                      </Text>
                    </Box>
                  </HStack>
                </Box>
              )
            )}
          </Box>
        </PopoverContent>
      </Popover>
    </Flex>
  );
}

export default Header;
