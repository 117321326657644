import React, { useState, useEffect } from "react";
import axios from "axios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLoader } from "@fortawesome/pro-light-svg-icons";
import { useAuth0 } from "@auth0/auth0-react";
// import Wealthica from "wealthica-sdk-js";
import "./WealthicaAPI.css";
import {
  faDeleteLeft,
  faPlus,
  faRemove,
  faSync,
} from "@fortawesome/pro-regular-svg-icons";
import { Button, useMediaQuery } from "@chakra-ui/react";

const WealthicaAPI = ({
  onDataReceived,
  newAccount,
  userID,
  setLoading,
  accID,
  dateToCheck,
  data,
}) => {
  const { user } = useAuth0();
  const [showPopup, setShowPopup] = useState(false);

  const [isLargerThan768] = useMediaQuery("(min-width: 500px)");
  const isMobile = !isLargerThan768;

  const ConnectWealthica = async (userID, accID) => {
    setLoading(true);
    try {
      const wealthica = Wealthica.init({
        clientId: "4js2qfh0q8e89s9r21v0jnv0uv",
        authEndpoint:
          "https://kkybmic3bt7lnstkueoymn36da0rthhz.lambda-url.us-east-2.on.aws/wealthica/auth", // 
        auth: {
          params: { username: userID }, // Use the function parameter directly
        },
      });
      // const test = userSession.connect(token)
      if (newAccount === "Add") {
        const userSession = wealthica.login(); // Renamed to avoid shadowing
        const token = await userSession.getToken();
        getWealthicaData(userSession, token);
      } else if (newAccount === "Sync") {
        console.log("Sync", userID);
        const userSession = wealthica.login(userID);
        const institutions = await userSession.institutions.getList();
        setLoading(false);
        console.log("SYNCED: ", institutions);
        onDataReceived(institutions);
      } else if (newAccount === "Delete") {
        const userSession = wealthica.login(userID);
        await userSession.institutions.remove(accID);
        const institutions = await userSession.institutions.getList();
        setLoading(false);
        onDataReceived(institutions);
      } else if (newAccount === "Delete/Add") {
        const userSession = wealthica.login(userID);

        // First, perform the delete operation
        await userSession.institutions.remove(accID);
        const institutions = await userSession.institutions.getList();
        // Now perform the add operation
        const userSessionAdd = wealthica.login();
        const token = await userSessionAdd.getToken();
        onDataReceived(institutions);
        getWealthicaData(userSessionAdd, token);
      }
    } catch (error) {
      console.error("Error fetching account data:", error);
      // Handle error appropriately
    }
  };

  const getWealthicaData = async (userSession, token) => {
    const axiosInstance = axios.create();
    axiosInstance.interceptors.request.use(
      async (config) => {
        config.headers.Authorization = `Bearer ${token}`;
        return config;
      },
      (error) => {
        return Promise.reject(error);
      }
    );
    try {
      userSession
        .connect({
          providerGroups: ["core", "thirdparty"],
        })
        .onConnection((institutionId) => {
          const institution = userSession.institutions.getOne(institutionId);
          axiosInstance
            .get("https://api.wealthica.com/institutions")
            .then((res) => {
              setLoading(false);
              onDataReceived(res.data);
            });
        });
    } catch (error) {
      console.error("Error fetching account data:", error);
    }
  };

  const isDateMoreThanOneDayOld = (dateToCheck) => {
    const currentDate = new Date();
    const dateToCompare = new Date(dateToCheck);

    // Check if the dateToCompare is invalid
    if (isNaN(dateToCompare.getTime())) {
      return true; // Return true if dateToCheck is invalid
    }

    // Reset time components for both dates to compare only the date part
    currentDate.setHours(0, 0, 0, 0);
    dateToCompare.setHours(0, 0, 0, 0);
    return currentDate > dateToCompare;
  };

  const isDateMoreThanOneMinuteOld = (dateToCheck) => {
    const oneMinute = 60 * 60 * 1000; // One minute in milliseconds
    const currentDate = new Date();
    const dateToCompare = new Date(dateToCheck);
    return currentDate - dateToCompare > oneMinute;
  };

  const canSync = isDateMoreThanOneDayOld(dateToCheck);

  return (
    <>
      {newAccount === "Sync" && canSync && data?.length >= 1 && (
        <div
          style={{ backgroundColor: "#0F9CD6", marginBottom: "10px" }}
          className="sync-accounts-container"
          onClick={() => ConnectWealthica(userID, accID)}
        >
          <FontAwesomeIcon icon={faSync} size="xl" />
          <div className="sync-accounts-title ">
            Refresh Investment Balances
          </div>
        </div>
      )}
      {newAccount === "Sync" && !canSync && data?.length >= 1 && (
        <div
          style={{ backgroundColor: "#cccccc", marginBottom: "10px" }} // Disabled button color
          className="sync-accounts-container"
        >
          <FontAwesomeIcon icon={faSync} size="xl" />
          <div className="sync-accounts-title ">One Refresh Per Day</div>
        </div>
      )}
      {newAccount === "Add" && (
        <div
          className="sync-accounts-container"
          onClick={() => ConnectWealthica(userID, accID)}
        >
          <FontAwesomeIcon icon={faPlus} size="xl" />
          {data?.length >= 1 && (
            <div className="sync-accounts-title ">Add New</div>
          )}
          {!data && (
            <div className="sync-accounts-title ">Link Investment account</div>
          )}
        </div>
      )}
      {newAccount === "Delete" && (
       <button
          className="decision-button yes-button"
          style={{ backgroundColor: "green", color: "white" }}
          onClick={() => ConnectWealthica(userID, accID)}
        >
          Yes
        </button>
      )}
      {newAccount === "Delete/Add" && (
        <Button
          fontWeight="bold"
          color={"#0F9CD6"}
          marginRight={"10px"}
          gap={"10px"}
          onClick={() => ConnectWealthica(userID, accID)}
        >
          {!isMobile && <FontAwesomeIcon icon={faSync} size="xl" />}
          Reconnect
        </Button>
      )}
    </>
  );
};

export default WealthicaAPI;
