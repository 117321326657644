import {
  Box,
  HStack,
  Icon,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Table,
  Tbody,
  Td,
  Text,
  Tr,
  useMediaQuery,
  Flex,
  Progress,
} from "@chakra-ui/react";
import { useState, useContext } from "react";
import { useStore } from "../../store";
import { NextYearIcon } from "../CustomIcons";
import { SpouseContext } from "../SpouseContext";
import DashboardSmallCard from "../EndOfLifePageSpouse/DashboardSmallCard";
import PieChartContainer from "./insightsCharts";

function InsightsOverview(props) {
  const userOptiData = useStore((store) => store.userOptiData);
  const userInsightsData = useStore((store) => store.userInsightsData);
  const dashboardLayout = useStore((store) => store.dashboardLayout);
  const { spouseStatus, setSpouseStatus } = useContext(SpouseContext);
  const userInfo = useStore((store) => store.userInfo);

  console.log(userInsightsData)

  const {
    FirstName: firstName,
    LastName: lastName,
    DateOfBirth: currentAge,
    spouseFirstName,
    spouseLastName,
    spouseDateOfBirth: spouseAge,
    runAge,
  } = userInfo;

  const passedOptiList = userOptiData?.primaryUserData;
  const passedSpouseOptiList = userOptiData?.spouseData;
  const [optiList, setOptiList] = useState(passedOptiList);
  const [assetsList, setAssetsList] = useState(passedOptiList);
  const [spouseOptiList, setSpouseOptiList] = useState(spouseStatus ? passedSpouseOptiList : {});
  const [spousePrefix, setSpousePrefix] = useState("sps1");
  const [spousePage, setSpousePage] = useState("joint-portfolio");
  const [listPlace, setListPlace] = useState(0);

  const currentYear = new Date();

  function getAge(birthday, currentDate) {
    // Convert the birthday string into a Date object
    const birthDate = new Date(birthday);

    // Calculate the age in years
    let age = currentDate?.getFullYear() - birthDate?.getFullYear();

    // Decrement the age if the current month and day are before the birth month and day
    if (
      currentDate?.getMonth() < birthDate?.getMonth() ||
      (currentDate?.getMonth() === birthDate?.getMonth() &&
        currentDate?.getDate() < birthDate?.getDate())
    ) {
      age -= 1;
    }

    return age;
  }

  const withdrawCppAge = (cpp, spouseBday) => {
    let age = 70; // Default age set to 70

    for (let i = 0; i < cpp?.length; i++) {
      if (cpp?.[i] > 0) {
        age = i;
        break;
      }
    }

    return Math.min(age + spouseBday, 70);
  };

  const currencyFormat = (money) => {
    var intTotal = Math.round(Math.round(money * 100) / 100);
    if (intTotal > -2 && intTotal < 0.25) {
      intTotal = 0;
    }
    // Math.round(intTotal);
    var currencyCad = new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD",
      minimumFractionDigits: 0,
    }).format(intTotal);
    return currencyCad;
  };

  const averageTaxRate = (total, tax) => {
    // Check if total is zero, null, or undefined
    if (total === 0 || total === null || total === undefined) {
      return 0; // or return null, depending on how you want to handle this case
    }

    const percent = (tax / total) * 100;
    const x = Math.round(percent);
    return x;
  };

  const sumArray = (arr) => {
    var sum = arr?.reduce((a, b) => a + b, 0);
    return sum;
  };

  function safeAdd(...values) {
    return values.reduce((sum, val) => sum + (Number(val) || 0), 0);
  }

  // Function to generate the data
  const generateSavingsData = (optiList, spouseOptiList) => {
    return optiList?.[`${spousePrefix}_tfsaFunds`]?.map((e, i) =>
      safeAdd(
        e,
        spouseOptiList?.sps2_tfsaFunds?.[i],
        optiList?.[`${spousePrefix}_fhsaFunds`]?.[i],
        spouseOptiList?.sps2_fhsaFunds?.[i],
        optiList?.[`${spousePrefix}_registeredFunds`]?.[i],
        spouseOptiList?.sps2_registeredFunds?.[i],
        optiList?.[`${spousePrefix}_nonregisteredFunds`]?.[i],
        spouseOptiList?.sps2_nonregisteredFunds?.[i],
        optiList?.[`${spousePrefix}_liraFunds`]?.[i],
        spouseOptiList?.sps2_liraFunds?.[i],
        optiList?.[`${spousePrefix}_pension`]?.Value?.[i],
        spouseOptiList?.sps2_pension?.Value?.[i],
        optiList?.ccpcYearlyBalance?.[i],
        optiList?.[`${spousePrefix}_cashFunds`]?.[i],
        spouseOptiList?.sps2_cashFunds?.[i],
        optiList?.[`${spousePrefix}_fixedIncomeTotal`]?.[i],
        spouseOptiList?.sps2_fixedIncomeTotal?.[i]
      )
    );
  };

  const currentDate = new Date();

  const getYears = () => {
    const currentDate = new Date();
    const currentYear = currentDate.getFullYear();
    const userAge = getAge(currentAge, currentDate);
    const fullRun = assetsList?.expenses?.length + 1;
    if (fullRun > 0 && Number.isInteger(fullRun)) {
      return [...Array(fullRun).keys()].map((i) => i + currentYear);
    } else {
      // Return an empty array or handle the error appropriately
      return [];
    }
  };

  const yearToDate = (year) => {
    const date = new Date();
    date.setFullYear(year);
    return date;
  };

  const TotalExtra = assetsList?.extraSpending?.reduce(
    (accumulator, currentValue) => {
      return safeAdd(accumulator, currentValue);
    }
  );

  const averageCanadianSavingsHousehold = {
    20: 0, // No specific data for 20-year-olds; typically lower due to starting careers and paying off student loans
    21: 0,
    22: 0,
    23: 0,
    24: 0,
    25: 0,
    26: 0,
    27: 0,
    28: 0,
    29: 0,
    30: 11549, // SnappyRates
    31: 12000, // Estimated increase
    32: 13000, // Estimated increase
    33: 14000, // Estimated increase
    34: 15000, // Estimated increase
    35: 33021, // SnappyRates
    36: 35000, // Estimated increase
    37: 37000, // Estimated increase
    38: 39000, // Estimated increase
    39: 41000, // Estimated increase
    40: 61983, // SnappyRates
    41: 64000, // Estimated increase
    42: 67000, // Estimated increase
    43: 70000, // Estimated increase
    44: 73000, // Estimated increase
    45: 101048, // SnappyRates
    46: 105000, // Estimated increase
    47: 110000, // Estimated increase
    48: 115000, // Estimated increase
    49: 120000, // Estimated increase
    50: 153741, // SnappyRates
    51: 160000, // Estimated increase
    52: 167000, // Estimated increase
    53: 174000, // Estimated increase
    54: 181000, // Estimated increase
    55: 224816, // SnappyRates
    56: 230000, // Estimated increase
    57: 236000, // Estimated increase
    58: 242000, // Estimated increase
    59: 248000, // Estimated increase
    60: 320686, // SnappyRates
    61: 330000, // Estimated increase
    62: 340000, // Estimated increase
    63: 350000, // Estimated increase
    64: 360000, // Estimated increase
    65: 377300, // SnappyRates
    66: 390000, // Estimated increase
    67: 405000, // Estimated increase
    68: 420000, // Estimated increase
    69: 435000, // Estimated increase
    70: 450000, // Estimated increase
    71: 460000, // Estimated increase
    72: 470000, // Estimated increase
    73: 480000, // Estimated increase
    74: 490000, // Estimated increase
    75: 500000, // Estimated increase
    76: 510000, // Estimated increase
    77: 520000, // Estimated increase
    78: 530000, // Estimated increase
    79: 540000, // Estimated increase
    80: 550000, // Estimated increase
    81: 560000, // Estimated increase
    82: 570000, // Estimated increase
    83: 580000, // Estimated increase
    84: 590000, // Estimated increase
    85: 600000, // Estimated increase
    86: 610000, // Estimated increase
    87: 620000, // Estimated increase
    88: 630000, // Estimated increase
    89: 640000, // Estimated increase
    90: 650000, // Estimated increase
    91: 660000, // Estimated increase
    92: 670000, // Estimated increase
    93: 680000, // Estimated increase
    94: 690000, // Estimated increase
    95: 700000, // Estimated increase
    96: 710000, // Estimated increase
    97: 720000, // Estimated increase
    98: 730000, // Estimated increase
    99: 740000, // Estimated increase
  };

  const averageCanadianSavingsIndividual = {
    20: 0, // No specific data for 20-year-olds; typically lower due to starting careers and paying off student loans
    21: 0,
    22: 0,
    23: 0,
    24: 0,
    25: 0,
    26: 0,
    27: 0,
    28: 0,
    29: 0,
    30: 11549, // SnappyRates
    31: 12000, // Estimated increase
    32: 13000, // Estimated increase
    33: 14000, // Estimated increase
    34: 15000, // Estimated increase
    35: 33021, // SnappyRates
    36: 35000, // Estimated increase
    37: 37000, // Estimated increase
    38: 39000, // Estimated increase
    39: 41000, // Estimated increase
    40: 61983, // SnappyRates
    41: 64000, // Estimated increase
    42: 67000, // Estimated increase
    43: 70000, // Estimated increase
    44: 73000, // Estimated increase
    45: 101048, // SnappyRates
    46: 105000, // Estimated increase
    47: 110000, // Estimated increase
    48: 115000, // Estimated increase
    49: 120000, // Estimated increase
    50: 153741, // SnappyRates
    51: 160000, // Estimated increase
    52: 167000, // Estimated increase
    53: 174000, // Estimated increase
    54: 181000, // Estimated increase
    55: 224816, // SnappyRates
    56: 230000, // Estimated increase
    57: 236000, // Estimated increase
    58: 242000, // Estimated increase
    59: 248000, // Estimated increase
    60: 320686, // SnappyRates
    61: 330000, // Estimated increase
    62: 340000, // Estimated increase
    63: 350000, // Estimated increase
    64: 360000, // Estimated increase
    65: 377300, // SnappyRates
    66: 390000, // Estimated increase
    67: 405000, // Estimated increase
    68: 420000, // Estimated increase
    69: 435000, // Estimated increase
    70: 450000, // Estimated increase
    71: 460000, // Estimated increase
    72: 470000, // Estimated increase
    73: 480000, // Estimated increase
    74: 490000, // Estimated increase
    75: 500000, // Estimated increase
    76: 510000, // Estimated increase
    77: 520000, // Estimated increase
    78: 530000, // Estimated increase
    79: 540000, // Estimated increase
    80: 550000, // Estimated increase
    81: 560000, // Estimated increase
    82: 570000, // Estimated increase
    83: 580000, // Estimated increase
    84: 590000, // Estimated increase
    85: 600000, // Estimated increase
    86: 610000, // Estimated increase
    87: 620000, // Estimated increase
    88: 630000, // Estimated increase
    89: 640000, // Estimated increase
    90: 650000, // Estimated increase
    91: 660000, // Estimated increase
    92: 670000, // Estimated increase
    93: 680000, // Estimated increase
    94: 690000, // Estimated increase
    95: 700000, // Estimated increase
    96: 710000, // Estimated increase
    97: 720000, // Estimated increase
    98: 730000, // Estimated increase
    99: 740000, // Estimated increase
  };

  const [isLargerThan1200] = useMediaQuery(
    "(min-width: 500px) and (max-width: 1250px)"
  );
  const excludedCards = ["optimlValue", "netWorth"];

  const savingsData = generateSavingsData(
    userOptiData?.primaryUserData,
    userOptiData?.spouseData
  );

  const years = getYears();
  const ageData = years.map((year) => ({
    year,
    age: getAge(currentAge, yearToDate(year)),
    spouseAge: spouseStatus ? getAge(spouseAge, yearToDate(year)) : null,
  }));

  const header = "Sample Pie Chart";
  const dataValues = [10, 20, 30, 40];
  const dataNames = ["Category A", "Category B", "Category C", "Category D"];

  const [isLargerThan1300] = useMediaQuery("(min-width: 1300px)");
  function getGCD(a, b) {
    if (!b) {
      return a;
    }
    return getGCD(b, a % b);
  }

  function getRatio(a, b) {
    // Find the greatest common divisor
    const gcd = getGCD(a, b);
    let reducedA = a / gcd;
    let reducedB = b / gcd;

    // Ensure the ratio numbers are under 10
    while (reducedA >= 10 || reducedB >= 10) {
      reducedA /= 2;
      reducedB /= 2;
    }

    // Format to two decimal places
    reducedA = Math.round(reducedA);
    reducedB = Math.round(reducedB);

    return `${reducedA}:${reducedB}`;
  }

  function findFirstZeroYear(array) {
    // Check if the first value is 0
    if (!array) {
      return 0;
    }
    if (array[0] === 0) {
      return null;
    }

    let x = 0;

    // Iterate through the array to find the first 0
    for (let i = 0; i < array?.length; i++) {
      if (array[i] === 0) {
        return x + i;
      }
    }

    // If no zero is found, return null
    return null;
  }

  const spouse1RetireYear = findFirstZeroYear(
    userOptiData?.primaryUserData?.sps1_salary
  );

  const financialData = {
    totalIncome: safeAdd(
      optiList?.[`${spousePrefix}_salary`]?.[listPlace],
      optiList?.[`${spousePrefix}_pension`]?.Benefits?.[listPlace],
      optiList?.[`${spousePrefix}_cpp`]?.[listPlace],
      optiList?.[`${spousePrefix}_oas`]?.[listPlace],
      optiList?.[`${spousePrefix}_otherIncomeTotal`]?.[listPlace],

      spouseOptiList?.sps2_salary?.[listPlace],
      spouseOptiList?.sps2_pension?.Benefits?.[listPlace],
      spouseOptiList?.sps2_cpp?.[listPlace],
      spouseOptiList?.sps2_oas?.[listPlace],
      spouseOptiList?.sps2_otherIncomeTotal?.[listPlace]
    ),
    totalInvestmentSavings: safeAdd(
      optiList?.[`${spousePrefix}_registeredInitialContributions`]?.[listPlace],
      optiList?.[`${spousePrefix}_nonRegisteredInitialContributions`]?.[listPlace],
      optiList?.[`${spousePrefix}_tfsaInitialContributions`]?.[listPlace],
      optiList?.[`${spousePrefix}_fhsaInitialContributions`]?.[listPlace],
      optiList?.sps2_registeredInitialContributions?.[listPlace],
      optiList?.sps2_nonRegisteredInitialContributions?.[listPlace],
      optiList?.sps2_tfsaInitialContributions?.[listPlace],
      optiList?.sps2_fhsaInitialContributions?.[listPlace],
      optiList?.[`${spousePrefix}_registeredContributionsMatched`]
        ?.EmployerContributions?.[listPlace],
      optiList?.sps2_registeredContributionsMatched?.EmployerContributions?.[listPlace],
      optiList?.[`${spousePrefix}_pension`]?.EmployerContributions?.[listPlace],
      optiList?.sps2_pension?.EmployerContributions?.[listPlace],
      optiList?.[`${spousePrefix}_registeredContributions`]?.[listPlace],
      optiList?.[`${spousePrefix}_registeredContributionsMatched`]?.[listPlace],
      optiList?.[`${spousePrefix}_nonRegisteredContributions`]?.[listPlace],
      optiList?.[`${spousePrefix}_tfsaContributions`]?.[listPlace],
      optiList?.[`${spousePrefix}_fhsaContributions`]?.[listPlace],
      optiList?.[`${spousePrefix}_pension`]?.EmployerContributions?.[listPlace],

      spouseOptiList?.sps2_registeredContributions?.[listPlace],
      spouseOptiList?.sps2_registeredContributionsMatched?.[listPlace],
      spouseOptiList?.sps2_nonRegisteredContributions?.[listPlace],
      spouseOptiList?.sps2_tfsaContributions?.[listPlace],
      spouseOptiList?.sps2_fhsaContributions?.[listPlace],
      spouseOptiList?.sps2_pension?.EmployerContributions?.[listPlace]
    ),
    averageSavingsRate: 6.9, // Average savings rate for comparison
    recommendedSavings: 15, // What you should be savings annually recommended
  };

  const SavingsRateInsight = ({ financialData }) => {
    const savingsRate =
      (financialData.totalInvestmentSavings / financialData.totalIncome) * 100;

    return (
      <Box
        display={"flex"}
        padding={"20px"}
        flexDir={"column"}
        alignItems={"flex-s"}
        gap={"5px"}
        borderRadius={"5px"}
        border={"1px solid #00A2D4"}
        bg={"#FFF"}
        w={"100%"}
        // p={4}
        // mb={4}
      >
        <Text fontSize="xl" fontWeight="bold" mb={2}>
          Savings Rate Analysis
        </Text>
        <Text>Total Income: {currencyFormat(financialData.totalIncome)}</Text>
        <Text>
          Total Investment Savings:{" "}
          {currencyFormat(financialData.totalInvestmentSavings)}
        </Text>
        <Text>Your Savings Rate: {savingsRate.toFixed(2)}%</Text>
        <Text>Recommended Minimum Savings Rate: {financialData.recommendedSavings}%</Text>
        <Text>Average Savings Rate: {financialData.averageSavingsRate}%</Text>
        <Flex alignItems="center" mt={2}>
          <Text width="150px">Your Rate</Text>
          <Progress
            value={savingsRate}
            colorScheme="green"
            size="lg"
            width="100%"
          />
        </Flex>
        <Flex alignItems="center" mt={2}>
          <Text width="150px">Recommended Minimum Rate</Text>
          <Progress
            value={financialData.recommendedSavings}
            colorScheme="blue"
            size="lg"
            width="100%"
          />
        </Flex>
        <Flex alignItems="center" mt={2}>
          <Text width="150px">Average Rate</Text>
          <Progress
            value={financialData.averageSavingsRate}
            colorScheme="red"
            size="lg"
            width="100%"
          />
        </Flex>
        {savingsRate < financialData.averageSavingsRate && (
          <Text color="red.500" mt={2}>
            Your savings rate is below the average.
          </Text>
        )}
        {savingsRate >= financialData.averageSavingsRate && (
          <Text color="green.500" mt={2}>
            Great job! Your savings rate is above the average.
          </Text>
        )}
      </Box>
    );
  };


  const InsightsYear = () => {
    const [isLargerThan1200] = useMediaQuery(
      "(min-width: 500px) and (max-width: 1250px)"
    );
    const [ageEnd, setAgeEnd] = useState(true);
    const [isClicked, setIsClicked] = useState(false);
  
    const years = [];

    const currentYear = new Date().getFullYear();
  
    const userEndDate =
    userOptiData?.primaryUserData?.expenses?.length + new Date().getFullYear();
  
  for (let i = currentYear; i <= userEndDate - 1; i++) {
    years?.push(i);
  }
  
  return (
    <Menu placement="bottom-end">
      <MenuButton>
        <HStack
          justifyContent={"end"}
          gap={"10px"}
          alignContent={"center"}
          alignItems={"center"}
        >
          <Text
            color={"#00A2D4"}
            textAlign={"right"}
            fontFamily={"Nunito"}
            fontSize={"20px"}
            fontStyle={"normal"}
            fontWeight={"700"}
            lineHeight={"130%"}
          >
            Year
          </Text>

          {ageEnd && (
            <Icon
              as={NextYearIcon}
              svgwidth={"15px"}
              svgheight={"15px"}
              style={{
                transform: isClicked ? "rotate(90deg)" : "rotate(0deg)",
                transition: "transform 0.3s ease-in-out",
              }}
              onClick={() => setIsClicked(!isClicked)}
            />
          )}
        </HStack>
      </MenuButton>
      <MenuList
        bg={"#00466D"}
        w={"150px"}
        h={"300px"}
        flexShrink={0}
        overflowY={"auto"}
        borderRadius={"5px"}
      >
        {years.map((yr, index) => (
          <MenuItem
            bg={"#00466D"}
            key={index}
            onClick={(e) => {
              e.preventDefault();
              setListPlace(index);
              setAgeEnd(true);
            }}
          >
            <Text
              color={"#FFF"}
              fontFamily={"Nunito"}
              fontSize={"15px"}
              fontStyle={"normal"}
              fontWeight={"700"}
              lineHeight={"normal"}
            >
              {yr}
            </Text>
          </MenuItem>
        ))}
      </MenuList>
    </Menu>
  );
};

  return (
    <Box display={"flex"} flexDirection={"column"} w={"100%"} gap={"10px"}>
      <InsightsYear />
      <HStack gap={"10px"}>
        {dashboardLayout.smallCards
          ?.filter((card) => excludedCards.includes(card))
          .map((card, index) => (
            <Box
              key={index}
              display="flex"
              justifyContent="center"
              alignSelf="stretch"
              flexDirection="column"
              bg={"#FFF"}
              padding={"15px 20px"}
              alignItems={"center"}
              borderRadius={"5px"}
              border={"1px solid #00A2D4"}
              width={isLargerThan1200 ? "48%" : "100%"}
            >
              <DashboardSmallCard
                card={card}
                optiList={optiList}
                assetsList={assetsList}
                spouseOptiList={spouseOptiList}
                currentYear={currentYear}
                getAge={getAge}
                withdrawCppAge={withdrawCppAge}
                currencyFormat={currencyFormat}
                averageTaxRate={averageTaxRate}
                sumArray={sumArray}
                spousePrefix={spousePrefix}
                spousePage={spousePage}
                showpop={true}
                isInsights={true}
              />
            </Box>
          ))}
      </HStack>
      <HStack
        gap={"10px"}
        alignSelf="stretch"
        width={"100%"}
        display={"flex"}
        justifyContent={"space-between"}
      >
        <Box
          display={"flex"}
          padding={"20px"}
          flexDir={"column"}
          justifyContent={"center"}
          alignItems={"flex-start"}
          gap={"15px"}
          borderRadius={"5px"}
          border={"1px solid #00A2D4"}
          bg={"#FFF"}
        >
          <PieChartContainer
            header={"Allocation of Income"}
            dataValues={[
              safeAdd(
                optiList?.[
                  `${spousePrefix}_registeredInitialContributions`
                ]?.[listPlace],
                optiList?.[
                  `${spousePrefix}_nonRegisteredInitialContributions`
                ]?.[listPlace],
                optiList?.[`${spousePrefix}_tfsaInitialContributions`]?.[listPlace],
                optiList?.[`${spousePrefix}_fhsaInitialContributions`]?.[listPlace],
                optiList?.sps2_registeredInitialContributions?.[listPlace],
                optiList?.sps2_nonRegisteredInitialContributions?.[listPlace],
                optiList?.sps2_tfsaInitialContributions?.[listPlace],
                optiList?.sps2_fhsaInitialContributions?.[listPlace],
                optiList?.[`${spousePrefix}_registeredContributionsMatched`]
                  ?.EmployerContributions?.[listPlace],
                optiList?.sps2_registeredContributionsMatched
                  ?.EmployerContributions?.[listPlace],
                optiList?.[`${spousePrefix}_pension`]
                  ?.EmployerContributions?.[listPlace],
                optiList?.sps2_pension?.EmployerContributions?.[listPlace]
              ),
              safeAdd(
                optiList?.[`${spousePrefix}_registeredContributions`]?.[listPlace],
                optiList?.[
                  `${spousePrefix}_registeredContributionsMatched`
                ]?.[listPlace],
                optiList?.[`${spousePrefix}_nonRegisteredContributions`]?.[listPlace],
                optiList?.[`${spousePrefix}_tfsaContributions`]?.[listPlace],
                optiList?.[`${spousePrefix}_fhsaContributions`]?.[listPlace],
                optiList?.[`${spousePrefix}_pension`]
                  ?.EmployerContributions?.[listPlace],

                spouseOptiList?.sps2_registeredContributions?.[listPlace],
                spouseOptiList?.sps2_registeredContributionsMatched?.[listPlace],
                spouseOptiList?.sps2_nonRegisteredContributions?.[listPlace],
                spouseOptiList?.sps2_tfsaContributions?.[listPlace],
                spouseOptiList?.sps2_fhsaContributions?.[listPlace],
                spouseOptiList?.sps2_pension?.EmployerContributions?.[listPlace]
              ),
              safeAdd(
                assetsList?.expenses?.[listPlace],
                -1 * assetsList?.PrimaryResidence?.RentDecrease?.[listPlace]
              ),
              safeAdd(
                assetsList?.totalDebtPayments?.[listPlace],
                assetsList?.PrimaryResidence?.Payments?.[listPlace],
                assetsList?.RecreationPropertyYearlyPayments?.[listPlace],
                assetsList?.LandYearlyPayments?.[listPlace],
                assetsList?.IncomePropertyYearlyPayments?.[listPlace],
                assetsList?.lifeInsuranceYearlyPayments?.[listPlace],
                assetsList?.PrimaryResidence?.DownPayment?.[listPlace]
              ),
            ]}
            dataNames={[
              "Deposits you've already made",
              "Optiml Investments",
              "Household Expenses",
              "Fixed Expenses",
            ]}
          />
        </Box>
        <Box>
          <SavingsRateInsight financialData={financialData} />
        </Box>
        {/* <Box
          display={"flex"}
          padding={"20px"}
          flexDir={"column"}
          justifyContent={"center"}
          alignItems={"flex-start"}
          gap={"15px"}
          borderRadius={"5px"}
          border={"1px solid #00A2D4"}
          bg={"#FFF"}
        >
          <PieChartContainer
            header={"Allocation of Income"}
            dataValues={[
              safeAdd(
                optiList?.[
                  `${spousePrefix}_registeredInitialContributions`
                ]?.[listPlace],
                optiList?.[
                  `${spousePrefix}_nonRegisteredInitialContributions`
                ]?.[listPlace],
                optiList?.[`${spousePrefix}_tfsaInitialContributions`]?.[listPlace],
                optiList?.[`${spousePrefix}_fhsaInitialContributions`]?.[listPlace],
                optiList?.sps2_registeredInitialContributions?.[listPlace],
                optiList?.sps2_nonRegisteredInitialContributions?.[listPlace],
                optiList?.sps2_tfsaInitialContributions?.[listPlace],
                optiList?.sps2_fhsaInitialContributions?.[listPlace],
                optiList?.[`${spousePrefix}_registeredContributionsMatched`]
                  ?.EmployerContributions?.[listPlace],
                optiList?.sps2_registeredContributionsMatched
                  ?.EmployerContributions?.[listPlace],
                optiList?.[`${spousePrefix}_pension`]
                  ?.EmployerContributions?.[listPlace],
                optiList?.sps2_pension?.EmployerContributions?.[listPlace]
              ),
              safeAdd(
                optiList?.[`${spousePrefix}_registeredContributions`]?.[listPlace],
                optiList?.[
                  `${spousePrefix}_registeredContributionsMatched`
                ]?.[listPlace],
                optiList?.[`${spousePrefix}_nonRegisteredContributions`]?.[listPlace],
                optiList?.[`${spousePrefix}_tfsaContributions`]?.[listPlace],
                optiList?.[`${spousePrefix}_fhsaContributions`]?.[listPlace],
                optiList?.[`${spousePrefix}_pension`]
                  ?.EmployerContributions?.[listPlace],

                spouseOptiList?.sps2_registeredContributions?.[listPlace],
                spouseOptiList?.sps2_registeredContributionsMatched?.[listPlace],
                spouseOptiList?.sps2_nonRegisteredContributions?.[listPlace],
                spouseOptiList?.sps2_tfsaContributions?.[listPlace],
                spouseOptiList?.sps2_fhsaContributions?.[listPlace],
                spouseOptiList?.sps2_pension?.EmployerContributions?.[listPlace]
              ),
              safeAdd(
                assetsList?.expenses?.[listPlace],
                -1 * assetsList?.PrimaryResidence?.RentDecrease?.[listPlace]
              ),
              safeAdd(
                assetsList?.totalDebtPayments?.[listPlace],
                assetsList?.PrimaryResidence?.Payments?.[listPlace],
                assetsList?.RecreationPropertyYearlyPayments?.[listPlace],
                assetsList?.LandYearlyPayments?.[listPlace],
                assetsList?.IncomePropertyYearlyPayments?.[listPlace],
                assetsList?.lifeInsuranceYearlyPayments?.[listPlace],
                assetsList?.PrimaryResidence?.DownPayment?.[listPlace]
              ),
            ]}
            dataNames={[
              "Deposits you've already made",
              "Optiml Investments",
              "Household Expenses",
              "Fixed Expenses",
            ]}
          />
        </Box> */}
      </HStack>
      <HStack
        gap={"10px"}
        alignSelf="stretch"
        width={"100%"}
        display={"flex"}
        justifyContent={"space-between"}
      >
        <Box
          display={"flex"}
          padding={"20px"}
          flexDir={"column"}
          alignItems={"center"}
          gap={"15px"}
          borderRadius={"5px"}
          border={"1px solid #00A2D4"}
          bg={"#FFF"}
          // w={"fit-content"}
        >
          <Text
            color={"#00466D"}
            fontFamily={"Nunito"}
            fontSize={"16px"}
            fontStyle={"normal"}
            fontWeight={"700"}
            lineHeight={"normal"}
            letterSpacing={"1px"}
          >
            {" "}
            Retirement Scenario
          </Text>
          <Table variant={"simple"} borderRadius={"5px"}>
            <Tbody
              borderRadius={"none"}
              bg={"none"}
              sx={{
                "& tr:nth-of-type(odd)": {
                  backgroundColor: "#ECF9FF",
                },
                "& tr:nth-of-type(even)": {
                  backgroundColor: "#FFF",
                },
              }}
            >
              <Tr>
                <Td
                  borderRight={"1px solid #80D0E9"}
                  borderBottom={"1px solid #80D0E9"}
                  padding={"10px"}
                ></Td>
                <Td
                  color={"#00466D"}
                  fontFamily={"Nunito"}
                  fontSize={"16px"}
                  fontStyle={"normal"}
                  fontWeight={"600"}
                  lineHeight={"normal"}
                  letterSpacing={"1px"}
                  padding={"10px"}
                  textAlign={"end"}
                  borderBottom={"1px solid #80D0E9"}
                >
                  {assetsList?.optimizationType === "Max Value"
                    ? "End-of-Analysis Balance"
                    : "Life Time Extra Spending"}
                </Td>
              </Tr>
              <Tr>
                <Td
                  color={"#00466D"}
                  fontFamily={"Nunito"}
                  fontSize={"16px"}
                  fontStyle={"normal"}
                  fontWeight={"600"}
                  lineHeight={"normal"}
                  letterSpacing={"1px"}
                  padding={"10px"}
                  textAlign={"start"}
                  borderRight={"1px solid #80D0E9"}
                  borderBottom={"1px solid #80D0E9"}
                >
                  Retire One Year Earlier -{" "}
                  {getAge(currentAge, currentDate) + (spouse1RetireYear - 2)}
                </Td>
                {assetsList?.optimizationType === "Max Value" && (
                  <Td
                    color={"#00466D"}
                    fontFamily={"Nunito"}
                    fontSize={"16px"}
                    fontStyle={"normal"}
                    fontWeight={"600"}
                    lineHeight={"normal"}
                    letterSpacing={"1px"}
                    padding={"10px"}
                    textAlign={"end"}
                    borderBottom={"1px solid #80D0E9"}
                  >

                    {spouseStatus
                      ? currencyFormat(
                          userInsightsData?.retireEarly?.sps1?.[0]
                            ?.optimizedEOLAmount
                        )
                      : currencyFormat(
                          userInsightsData?.retireEarly?.optimizedEOLAmount
                        )}
                  </Td>
                )}
                {assetsList?.optimizationType != "Max Value" && (
                  <Td
                    color={"#00466D"}
                    fontFamily={"Nunito"}
                    fontSize={"16px"}
                    fontStyle={"normal"}
                    fontWeight={"600"}
                    lineHeight={"normal"}
                    letterSpacing={"1px"}
                    padding={"10px"}
                    textAlign={"end"}
                    borderBottom={"1px solid #80D0E9"}
                  >
                    {spouseStatus
                      ? currencyFormat(
                          userInsightsData?.retireEarly?.sps1?.[0]?.extraSpending?.reduce(
                            (accumulator, currentValue) => {
                              return safeAdd(accumulator, currentValue);
                            }
                          )
                        )
                      : currencyFormat(
                          userInsightsData?.retireEarly?.extraSpending?.reduce(
                            (accumulator, currentValue) => {
                              return safeAdd(accumulator, currentValue);
                            }
                          )
                        )}
                  </Td>
                )}
              </Tr>
              <Tr>
                <Td
                  color={"#00466D"}
                  fontFamily={"Nunito"}
                  fontSize={"16px"}
                  fontStyle={"normal"}
                  fontWeight={"600"}
                  lineHeight={"normal"}
                  letterSpacing={"1px"}
                  padding={"10px"}
                  textAlign={"start"}
                  borderRight={"1px solid #80D0E9"}
                  borderBottom={"1px solid #80D0E9"}
                >
                  Actual Retirement Year -{" "}
                  {getAge(currentAge, currentDate) + (spouse1RetireYear - 1)}
                </Td>
                {assetsList?.optimizationType === "Max Value" && (
                  <Td
                    color={"#00466D"}
                    fontFamily={"Nunito"}
                    fontSize={"16px"}
                    fontStyle={"normal"}
                    fontWeight={"600"}
                    lineHeight={"normal"}
                    letterSpacing={"1px"}
                    padding={"10px"}
                    textAlign={"end"}
                    borderBottom={"1px solid #80D0E9"}
                  >
                    {currencyFormat(
                      userOptiData?.primaryUserData?.optimizedEOLAmount
                    )}
                  </Td>
                )}
                {assetsList?.optimizationType != "Max Value" && (
                  <Td
                    color={"#00466D"}
                    fontFamily={"Nunito"}
                    fontSize={"16px"}
                    fontStyle={"normal"}
                    fontWeight={"600"}
                    lineHeight={"normal"}
                    letterSpacing={"1px"}
                    padding={"10px"}
                    textAlign={"end"}
                    borderBottom={"1px solid #80D0E9"}
                  >
                    {currencyFormat(
                      userOptiData?.primaryUserData?.extraSpending?.reduce(
                        (accumulator, currentValue) => {
                          return safeAdd(accumulator, currentValue);
                        }
                      )
                    )}
                  </Td>
                )}
              </Tr>
              <Tr>
                <Td
                  color={"#00466D"}
                  fontFamily={"Nunito"}
                  fontSize={"16px"}
                  fontStyle={"normal"}
                  fontWeight={"600"}
                  lineHeight={"normal"}
                  letterSpacing={"1px"}
                  padding={"10px"}
                  textAlign={"start"}
                  borderRight={"1px solid #80D0E9"}
                >
                  Retire One Year Later -{" "}
                  {getAge(currentAge, currentDate) + spouse1RetireYear}
                </Td>
                {assetsList?.optimizationType === "Max Value" && (
                  <Td
                    color={"#00466D"}
                    fontFamily={"Nunito"}
                    fontSize={"16px"}
                    fontStyle={"normal"}
                    fontWeight={"600"}
                    lineHeight={"normal"}
                    letterSpacing={"1px"}
                    padding={"10px"}
                    textAlign={"end"}
                  >
                    {spouseStatus
                      ? currencyFormat(
                          userInsightsData?.retireLate?.sps1?.[0]
                            ?.optimizedEOLAmount
                        )
                      : currencyFormat(
                          userInsightsData?.retireLate?.optimizedEOLAmount
                        )}
                  </Td>
                )}
                {assetsList?.optimizationType != "Max Value" && (
                  <Td
                    color={"#00466D"}
                    fontFamily={"Nunito"}
                    fontSize={"16px"}
                    fontStyle={"normal"}
                    fontWeight={"600"}
                    lineHeight={"normal"}
                    letterSpacing={"1px"}
                    padding={"10px"}
                    textAlign={"end"}
                  >
                    {spouseStatus
                      ? currencyFormat(
                          userInsightsData?.retireLate?.sps1?.[0]?.extraSpending?.reduce(
                            (accumulator, currentValue) => {
                              return safeAdd(accumulator, currentValue);
                            }
                          )
                        )
                      : currencyFormat(
                          userInsightsData?.retireLate?.extraSpending?.reduce(
                            (accumulator, currentValue) => {
                              return safeAdd(accumulator, currentValue);
                            }
                          )
                        )}
                  </Td>
                )}
              </Tr>
            </Tbody>
          </Table>
        </Box>
        <Box
          display={"flex"}
          padding={"20px"}
          flexDir={"column"}
          justifyContent={"center"}
          alignItems={"flex-start"}
          gap={"15px"}
          borderRadius={"5px"}
          border={"1px solid #00A2D4"}
          bg={"#FFF"}
        >
          <PieChartContainer
            header={`Debt to Equity ratio - ${getRatio(
              safeAdd(
                assetsList?.[`${spousePrefix}_registeredFunds`]?.[listPlace],
                assetsList?.[`${spousePrefix}_nonregisteredFunds`]?.[listPlace],
                assetsList?.[`${spousePrefix}_tfsaFunds`]?.[listPlace],
                assetsList?.[`${spousePrefix}_fixedIncomeTotal`]?.[listPlace],
                assetsList?.[`${spousePrefix}_liraFunds`]?.[listPlace],
                assetsList?.[`${spousePrefix}_pension`]?.Value?.[listPlace],
                assetsList?.[`${spousePrefix}_fhsaFunds`]?.[listPlace],
                spouseOptiList?.sps2_fhsaFunds?.[listPlace],
                spouseOptiList?.sps2_nonregisteredFunds?.[listPlace],
                spouseOptiList?.sps2_tfsaFunds?.[listPlace],
                spouseOptiList?.sps2_fixedIncomeTotal?.[listPlace],
                spouseOptiList?.sps2_registeredFunds?.[listPlace],
                spouseOptiList?.sps2_liraFunds?.[listPlace],
                spouseOptiList?.sps2_pension?.Value?.[listPlace],
                assetsList?.PrimaryResidence?.Value?.[listPlace],
                assetsList?.businessYearlyValue?.[listPlace],
                assetsList?.IncomePropertyYearlyValue?.[listPlace],
                assetsList?.RecreationPropertyYearlyValue?.[listPlace],
                assetsList?.LandYearlyValue?.[listPlace],
                assetsList?.lifeInsuranceYearlyValue?.[listPlace],
                assetsList?.otherAssetsYearlyValue?.[listPlace]
              ),
              safeAdd(
                assetsList?.totalDebt?.[listPlace],
                assetsList?.IncomePropertyYearlyBalance?.[listPlace],
                assetsList?.RecreationPropertyYearlyBalance?.[listPlace],
                assetsList?.LandYearlyValue?.[listPlace],
                assetsList?.PrimaryResidence?.Balance?.[listPlace]
              )
            )}`}
            dataValues={[
              safeAdd(
                assetsList?.[`${spousePrefix}_registeredFunds`]?.[listPlace],
                assetsList?.[`${spousePrefix}_nonregisteredFunds`]?.[listPlace],
                assetsList?.[`${spousePrefix}_tfsaFunds`]?.[listPlace],
                assetsList?.[`${spousePrefix}_fixedIncomeTotal`]?.[listPlace],
                assetsList?.[`${spousePrefix}_liraFunds`]?.[listPlace],
                assetsList?.[`${spousePrefix}_pension`]?.Value?.[listPlace],
                assetsList?.[`${spousePrefix}_fhsaFunds`]?.[listPlace],
                spouseOptiList?.sps2_fhsaFunds?.[listPlace],
                spouseOptiList?.sps2_nonregisteredFunds?.[listPlace],
                spouseOptiList?.sps2_tfsaFunds?.[listPlace],
                spouseOptiList?.sps2_fixedIncomeTotal?.[listPlace],
                spouseOptiList?.sps2_registeredFunds?.[listPlace],
                spouseOptiList?.sps2_liraFunds?.[listPlace],
                spouseOptiList?.sps2_pension?.Value?.[listPlace],
                assetsList?.PrimaryResidence?.Value?.[listPlace],
                assetsList?.businessYearlyValue?.[listPlace],
                assetsList?.IncomePropertyYearlyValue?.[listPlace],
                assetsList?.RecreationPropertyYearlyValue?.[listPlace],
                assetsList?.LandYearlyValue?.[listPlace],
                assetsList?.lifeInsuranceYearlyValue?.[listPlace],
                assetsList?.otherAssetsYearlyValue?.[listPlace]
              ),
              safeAdd(
                assetsList?.totalDebt?.[listPlace],
                assetsList?.IncomePropertyYearlyBalance?.[listPlace],
                assetsList?.RecreationPropertyYearlyBalance?.[listPlace],
                assetsList?.LandYearlyValue?.[listPlace],
                assetsList?.PrimaryResidence?.Balance?.[listPlace]
              ),
            ]}
            dataNames={["Equity", "Debt"]}
          />
        </Box>
      </HStack>
      <Box
        display={"flex"}
        padding={"20px"}
        flexDir={"column"}
        alignItems={"center"}
        gap={"15px"}
        borderRadius={"5px"}
        border={"1px solid #00A2D4"}
        bg={"#FFF"}
        w={"100%"}
        overflow={"hidden"}
      >
        <Text
          color={"#00466D"}
          fontFamily={"Nunito"}
          fontSize={"16px"}
          fontStyle={"normal"}
          fontWeight={"700"}
          lineHeight={"normal"}
          letterSpacing={"1px"}
        >
          {" "}
          Retirement Savings
        </Text>
        <Table
          variant={"simple"}
          borderRadius={"5px"}
          overflow={"scroll"}
          overflowX={"auto"}
        >
          <Tbody
            borderRadius={"none"}
            overflowX={"hidden"}
            bg={"none"}
            sx={{
              "& tr:nth-of-type(odd)": {
                backgroundColor: "#ECF9FF",
              },
              "& tr:nth-of-type(even)": {
                backgroundColor: "#FFF",
              },
            }}
          >
            <Tr overflow={"auto"}>
              <Td
                color={"#00466D"}
                fontFamily={"Nunito"}
                fontSize={"16px"}
                fontStyle={"normal"}
                fontWeight={"600"}
                lineHeight={"normal"}
                letterSpacing={"1px"}
                padding={"10px"}
                textAlign={"end"}
                borderBottom={"1px solid #80D0E9"}
                borderRight={"1px solid #80D0E9"}
              >
                Age
              </Td>
              {years.map((year, index) => (
                <Td
                  key={index}
                  color={"#00466D"}
                  fontFamily={"Nunito"}
                  fontSize={"16px"}
                  fontStyle={"normal"}
                  fontWeight={"600"}
                  lineHeight={"normal"}
                  letterSpacing={"1px"}
                  padding={"10px"}
                  textAlign={"end"}
                  borderBottom={"1px solid #80D0E9"}
                  borderRight={
                    index < years.length - 1 ? "1px solid #80D0E9" : "none"
                  }
                >
                  {ageData[index].age}
                  {spouseStatus && <span> | {ageData[index].spouseAge}</span>}
                </Td>
              ))}
            </Tr>
            <Tr>
              <Td
                color={"#00466D"}
                fontFamily={"Nunito"}
                fontSize={"16px"}
                fontStyle={"normal"}
                fontWeight={"600"}
                lineHeight={"normal"}
                letterSpacing={"1px"}
                padding={"10px"}
                textAlign={"start"}
                borderRight={"1px solid #80D0E9"}
                borderBottom={"1px solid #80D0E9"}
              >
                {spouseStatus
                  ? "Your Household Savings"
                  : "Your Investment Savings"}
              </Td>
              {savingsData.map((cell, index) => (
                <Td
                  key={index}
                  color={"#00466D"}
                  fontFamily={"Nunito"}
                  fontSize={"16px"}
                  fontStyle={"normal"}
                  fontWeight={"600"}
                  lineHeight={"normal"}
                  letterSpacing={"1px"}
                  padding={"10px"}
                  textAlign={"end"}
                  borderRight={"1px solid #80D0E9"}
                  borderBottom={"1px solid #80D0E9"}
                >
                  {currencyFormat(cell)}
                </Td>
              ))}
            </Tr>
            <Tr>
              <Td
                color={"#00466D"}
                fontFamily={"Nunito"}
                fontSize={"16px"}
                fontStyle={"normal"}
                fontWeight={"600"}
                lineHeight={"normal"}
                letterSpacing={"1px"}
                padding={"10px"}
                textAlign={"start"}
                borderRight={"1px solid #80D0E9"}
                borderBottom={"1px solid #80D0E9"}
              >
                {spouseStatus
                  ? "Average Canadian Household Savings"
                  : "Average Canadian Savings"}
              </Td>
              {ageData.map((data, index) => (
                <Td
                  key={index}
                  color={"#00466D"}
                  fontFamily={"Nunito"}
                  fontSize={"16px"}
                  fontStyle={"normal"}
                  fontWeight={"600"}
                  lineHeight={"normal"}
                  letterSpacing={"1px"}
                  padding={"10px"}
                  textAlign={"end"}
                  borderRight={"1px solid #80D0E9"}
                  borderBottom={"1px solid #80D0E9"}
                >
                  {spouseStatus
                    ? currencyFormat(
                        averageCanadianSavingsHousehold[
                          getAge(currentAge, currentDate) + index
                        ] || 0
                      )
                    : currencyFormat(
                        averageCanadianSavingsIndividual[
                          getAge(currentAge, currentDate) + index
                        ] || 0
                      )}
                </Td>
              ))}
            </Tr>
            {/* <Tr>
              <Td
                color={"#00466D"}
                fontFamily={"Nunito"}
                fontSize={"16px"}
                fontStyle={"normal"}
                fontWeight={"600"}
                lineHeight={"normal"}
                letterSpacing={"1px"}
                padding={"10px"}
                textAlign={"start"}
                borderRight={"1px solid #80D0E9"}
                borderBottom={"1px solid #80D0E9"}
              >
                Average Optiml User
              </Td>
              <Td
                color={"#00466D"}
                fontFamily={"Nunito"}
                fontSize={"16px"}
                fontStyle={"normal"}
                fontWeight={"600"}
                lineHeight={"normal"}
                letterSpacing={"1px"}
                padding={"10px"}
                textAlign={"end"}
                borderBottom={"1px solid #80D0E9"}
                borderRight={"1px solid #80D0E9"}
              >
                $1,345,000
              </Td>
            </Tr> */}
          </Tbody>
        </Table>
      </Box>
    </Box>
  );
}


export default InsightsOverview;
