import React, { useState, useEffect } from "react";
import ReactEcharts from "echarts-for-react";
import { Box, useMediaQuery } from "@chakra-ui/react";

function BarGraph(props) {
  const filterZeroValues = (data) => {
    return data.filter(
      (entry) => getPiePercentage(entry.value, combinedTotalIncome) > 0
    );
  };

  const filterZeroValuesForTotal = (data) => {
    return data.filter(
      (entry) => getPiePercentage(entry.value, combinedTotal) > 0
    );
  };

  const {
    type,
    income,
    combinedCPP,
    combinedOAS,
    combinedPension,
    combinedOtherIncome,
    combinedAssetSaleIncome,
    combinedInheritanceIncome,
    combinedTotalIncome,
    combinedTFSA,
    combinedFHSA,
    combinedRRSP,
    combinedNonReg,
    combinedCash,
    combinedFixedIncome,
    combinedBusinessDividendWithdrawals,
    combinedLIRA,
    combinedAssets,
    combinedBusiness,
    combinedBusinessInvestments,
    combinedPersonAssetsBalance,
    combinedIncomePropertyIncome,
    combinedBusinessIncome,
    combinedPensionContributions,
    combinedRRSPContributions,
    combinedTotal,
    combinedLifeInsuranceBalances,
    combinedPensionValue,
    provincial,
    federal,
    cpp,
    ei,
    totalIncome,
    totalTaxes,
    totalDeposits,
    thisYearDeposits,
    totalExpenses,
    extraSpending,
    totalGoals,
    combinedPrimaryResMortgage,
    combinedRecreationMortgage,
    combinedLandMortgage,
    combinedIncomePropertyMortgage,
    combinedLoans,
    totalPortfolio,
    totalDebt,
  } = props;
  let option = [];

  const getPiePercentage = (value, total) => {
    return Math.round((value / total) * 100);
  };

  // const combinedTotal = combinedTFSA + combinedRRSP + combinedNonReg + combinedAssets + combinedLIRA + (combinedPensionContributions*2) + combinedRRSPContributions;
  const incomeOptions = {
    tooltip: {
      trigger: "item",
      formatter: function (params) {
        return `${params.seriesName} <br/>${
          params.name
        } : $${new Intl.NumberFormat().format(params.value)} (${
          params.percent
        }%)`;
      },
      position: function (point, params, dom, rect, size) {
        // Calculate tooltip position to stay within the screen
        const { viewSize, contentSize } = size;
        const [x, y] = point;
        const tooltipWidth = contentSize[0];
        const tooltipHeight = contentSize[1];

        let xPos = x;
        let yPos = y;

        if (x + tooltipWidth > viewSize[0]) {
          xPos = x - tooltipWidth; // Move left if exceeds right edge
        }
        if (y + tooltipHeight > viewSize[1]) {
          yPos = y - tooltipHeight; // Move up if exceeds bottom edge
        }
        if (xPos < 0) {
          xPos = 0; // Ensure it doesn't go beyond left edge
        }
        if (yPos < 0) {
          yPos = 0; // Ensure it doesn't go beyond top edge
        }

        return [xPos, yPos];
      },
    },
    series: [
      {
        name: !totalIncome ? "Income Sources" : "Spending Summary",
        type: "pie",
        radius: ["40%", "70%"],
        data: filterZeroValues([
          {
            value: Math.round(combinedPension),
            name: "Pension",
            itemStyle: { color: "#73B879" },
          },
          {
            value: Math.round(combinedOAS),
            name: "OAS",
            itemStyle: { color: "#30AB9D" },
          },
          {
            value: Math.round(combinedCPP),
            name: "CPP",
            itemStyle: { color: "#00A2D4" },
          },
          {
            value: Math.round(income),
            name: "Salary",
            itemStyle: { color: "#007EB5" },
          },
          {
            value: Math.round(combinedIncomePropertyIncome),
            name: "Rental Income",
            itemStyle: { color: "#00466D" },
          },
          {
            value: Math.round(combinedBusinessIncome),
            name: "Business Dividends",
            itemStyle: { color: "#00304A" },
          },
          {
            value: Math.round(combinedAssetSaleIncome),
            name: "Asset Sale",
            itemStyle: { color: "#00304A" },
          },
          {
            value: Math.round(combinedInheritanceIncome),
            name: "Inheritance",
            itemStyle: { color: "#00304A" },
          },
          {
            value: Math.round(combinedOtherIncome),
            name: "Other Income",
            itemStyle: { color: "#001A2A" },
          },
          // Spending Summary
          {
            value: Math.round(totalIncome),
            name: "Total Income",
            itemStyle: { color: "#007EB5" },
          },
          {
            value: Math.round(totalTaxes),
            name: "Total Taxes",
            itemStyle: { color: "#00466D" },
          },
          {
            value: Math.round(totalDeposits),
            name: "Total Deposits",
            itemStyle: { color: "#73B879" },
          },
          {
            value: Math.round(thisYearDeposits),
            name: "Deposits Already Made This Year",
            itemStyle: { color: "#00304A" },
          },
          {
            value: Math.round(totalExpenses),
            name: "Expenses",
            itemStyle: { color: "#30AB9D" },
          },
          {
            value: Math.round(extraSpending),
            name: "Extra Spending",
            itemStyle: { color: "#00304A" },
          },
          {
            value: Math.round(totalGoals),
            name: "Goals",
            itemStyle: { color: "#001A2A" },
          },
        ]),
        emphasis: {
          itemStyle: {
            shadowBlur: 10,
            shadowOffsetX: 0,
            shadowColor: "rgba(0, 0, 0, 0.5)",
          },
          label: {
            show: true,
            formatter: (params) => {
              return "";
            },
          },
        },
        label: {
          show: false, // <-- This hides the labels by default
        },
        labelLine: {
          show: false,
        },
      },
    ],
  };

  const theRest = {
    tooltip: {
      trigger: "item",
      formatter: function (params) {
        return `${params.seriesName} <br/>${
          params.name
        } : $${new Intl.NumberFormat().format(params.value)} (${
          params.percent
        }%)`;
      },
      position: function (point, params, dom, rect, size) {
        // Calculate tooltip position to stay within the screen
        const { viewSize, contentSize } = size;
        const [x, y] = point;
        const tooltipWidth = contentSize[0];
        const tooltipHeight = contentSize[1];

        let xPos = x;
        let yPos = y;

        if (x + tooltipWidth > viewSize[0]) {
          xPos = x - tooltipWidth; // Move left if exceeds right edge
        }
        if (y + tooltipHeight > viewSize[1]) {
          yPos = y - tooltipHeight; // Move up if exceeds bottom edge
        }
        if (xPos < 0) {
          xPos = 0; // Ensure it doesn't go beyond left edge
        }
        if (yPos < 0) {
          yPos = 0; // Ensure it doesn't go beyond top edge
        }

        return [xPos, yPos];
      },
    },
    series: [
      {
        name: provincial
          ? "Taxes"
          : totalPortfolio
          ? "Net Worth"
          : combinedPrimaryResMortgage ||
            combinedRecreationMortgage ||
            combinedLandMortgage ||
            combinedIncomePropertyMortgage ||
            combinedLoans
          ? "Debt"
          : type === "deposits"
          ? "Deposits"
          : type === "withdrawals"
          ? "Withdrawals"
          : "Portfolio Balance",
        type: "pie",
        radius: ["40%", "70%"],
        data: filterZeroValuesForTotal([
          {
            value: Math.round(combinedRRSP),
            name: "RRSP",
            itemStyle: { color: "#73B879" },
          },
          {
            value: Math.round(combinedTFSA),
            name: "TFSA",
            itemStyle: { color: "#30AB9D" },
          },
          {
            value: Math.round(combinedFHSA),
            name: "FHSA",
            itemStyle: { color: "#007EB5" },
          },
          {
            value: Math.round(combinedNonReg),
            name: "Non-Registered",
            itemStyle: { color: "#00A2D4" },
          },
          {
            value: Math.round(combinedCash),
            name: "Cash",
            itemStyle: { color: "#00A2D4" },
          },
          {
            value: Math.round(combinedFixedIncome),
            name: "Fixed Income",
            itemStyle: { color: "#00A2D4" },
          },
          {
            value: Math.round(combinedBusinessDividendWithdrawals),
            name: "CCPC Investment Dividends",
            itemStyle: { color: "purple" },
          },
          {
            value: Math.round(combinedLIRA),
            name: "LIRA",
            itemStyle: { color: "#007EB5" },
          },
          {
            value: Math.round(combinedAssets),
            name: "Real Estate",
            itemStyle: { color: "#00466D" },
          },
          {
            value: Math.round(combinedBusiness),
            name: "Business",
            itemStyle: { color: "green" },
          },
          {
            value: Math.round(combinedBusinessInvestments),
            name: "CCPC Investment Fund",
            itemStyle: { color: "purple" },
          },
          {
            value: Math.round(combinedPersonAssetsBalance),
            name: "Personal Assets",
            itemStyle: { color: "blue" },
          },
          {
            value: Math.round(combinedPensionContributions),
            name: "Employer Pension Contribution",
            itemStyle: { color: "#00304A" },
          },
          {
            value: Math.round(combinedRRSPContributions),
            name: "Employer RRSP Contribution",
            itemStyle: { color: "#00304A" },
          },
          {
            value: Math.round(combinedPensionContributions),
            name: "Pension Contributions",
            itemStyle: { color: "#001A2A" },
          },
          {
            value: Math.round(combinedLifeInsuranceBalances),
            name: "Life Insurance",
            itemStyle: { color: "#147984" },
          },
          {
            value: Math.round(combinedPensionValue),
            name: "Pension Value",
            itemStyle: { color: "#06426F" },
          },
          {
            value: Math.round(provincial),
            name: "Provincial",
            itemStyle: { color: "#73B879" },
          },
          {
            value: Math.round(federal),
            name: "Federal",
            itemStyle: { color: "#30AB9D" },
          },
          {
            value: Math.round(cpp),
            name: "CPP Payments",
            itemStyle: { color: "#147984" },
          },
          {
            value: Math.round(ei),
            name: "EI Payments",
            itemStyle: { color: "#06426F" },
          },
          // DEBT
          {
            value: Math.round(combinedPrimaryResMortgage),
            name: "Home Mortgage",
            itemStyle: { color: "#73B879" },
          },
          {
            value: Math.round(combinedRecreationMortgage),
            name: "Cottage Mortgage",
            itemStyle: { color: "#30AB9D" },
          },
          {
            value: Math.round(combinedLandMortgage),
            name: "Cottage Mortgage",
            itemStyle: { color: "#73B879" },
          },
          {
            value: Math.round(combinedIncomePropertyMortgage),
            name: "Income Property Mortgage",
            itemStyle: { color: "#147984" },
          },
          {
            value: Math.round(combinedLoans),
            name: "Loans",
            itemStyle: { color: "#06426F" },
          },
          // Net Worth
          {
            value: Math.round(totalPortfolio),
            name: "Total Portfolio",
            itemStyle: { color: "#73B879" },
          },
          {
            value: Math.round(totalDebt),
            name: "Total Debt",
            itemStyle: { color: "#30AB9D" },
          },
        ]),
        emphasis: {
          itemStyle: {
            shadowBlur: 10,
            shadowOffsetX: 0,
            shadowColor: "rgba(0, 0, 0, 0.5)",
          },
          label: {
            show: true,
            formatter: (params) => {
              return "";
            },
          },
        },
        label: {
          show: false, // <-- This hides the labels by default
        },
        labelLine: {
          show: false,
        },
      },
    ],
  };

  option = type === "income" ? incomeOptions : theRest;

  const eChartsRef = React.useRef(0);
  const [isLargerThan500] = useMediaQuery("(min-width: 500px)");
  const isSmallScreen = !isLargerThan500;
  const [isLargerThan1200] = useMediaQuery(
    "(min-width: 500px) and (max-width: 1250px)"
  );

  return (
    <>
      {isSmallScreen && (
        <Box width="300px" height="300px" display={"flex"} marginTop={"-40px"}>
          <ReactEcharts
            ref={eChartsRef}
            option={option}
            style={{ height: "100%", width: "100%" }}
          />
        </Box>
      )}
      {!isSmallScreen && (
        <Box
          width="200px"
          height="200px"
          display={"flex"}
          alignItems={"flex-end"}
          marginRight={isLargerThan1200 ? "" : "-30px"}
        >
          <ReactEcharts
            ref={eChartsRef}
            option={option}
            style={{ height: "100%", width: "100%" }}
          />
        </Box>
      )}
    </>
  );
}

export default BarGraph;
