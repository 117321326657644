import React, { useState } from "react";
import axios from "axios";
import { useAuth0 } from "@auth0/auth0-react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  Button,
  Text,
  Box,
  Flex,
  useDisclosure,
  Checkbox,
  Icon,
} from "@chakra-ui/react";
import { CheckIcon, CloseIcon, OptimizeIcon, UpdateMyPlanIcon } from "./CustomIcons";
import Link from "./Link";
import { CheckCircleIcon } from "@chakra-ui/icons";
import { useStore } from "../store";

const Popup = ({ isOpen, onClose, link, setChangedData }) => {
  const userPopupNotification = useStore(
    (store) => store.userPopupNotification
  );
  const setShowDashboardVideo = useStore(
    (store) => store.setShowDashboardVideo
  );

  const { getAccessTokenSilently, user } = useAuth0();
  const [optimizeIconColor, setOptimizeIconColor] = useState("#0F9CD6");
  const [optimizeIconColor2, setOptimizeIconColor2] = useState("#0F9CD6");
  const [optimizeTextColor1, setOptimizeTextColor1] = useState("#004069");
  const [optimizeTextColor2, setOptimizeTextColor2] = useState("#004069");
  const axiosInstance = axios.create();
  async function getToken() {
    // Replace this with your Auth0 token retrieval logic
    const token = await getAccessTokenSilently({
      audience:
        "https://os2lsiwff43wfpb5ggyawt6qri0mgcro.lambda-url.us-east-2.on.aws/",
      scope: "write:optimization",
    });
    return token;
  }

  // Add a request interceptor
  axiosInstance.interceptors.request.use(
    async (config) => {
      const token = await getToken();
      config.headers.Authorization = `Bearer ${token}`;
      return config;
    },
    (error) => {
      // Do something with request error
      return Promise.reject(error);
    }
  );

  const handleCheckboxClickStore = (event) => {
    console.log(event.target.checked);
    if (event.target.checked === true) {
      const incomeURL = (action) =>
        `${
          process.env.REACT_APP_NODE_API_GATEWAY
        }/CompassLambdaApi/SHOW_VIDEO/${action}?UserID=${user.sub.replace(
          "|",
          "%7c"
        )}&NewAnalysis=${false}&DashboardVideo=${
          userPopupNotification?.DashboardVideo
        }`;
      axiosInstance
        .get(incomeURL("add"))
        .then((res) => {
          console.log(res);
          setShowDashboardVideo({
            ...userPopupNotification,
            DashboardVideo: userPopupNotification?.DashboardVideo,
            NewAnalysis: 0,
          });
          if (res.data.code === "ER_DUP_ENTRY") {
            axiosInstance.get(incomeURL("update")).then((res) => {
              console.log(res);
              setShowDashboardVideo({
                ...userPopupNotification,
                DashboardVideo: userPopupNotification?.DashboardVideo,
                NewAnalysis: 0,
              });
            });
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered>
      <ModalOverlay />
      <ModalContent
        border="2px solid #0F9CD6"
        borderRadius="10px"
        
        maxWidth="90%" // Ensure it scales down on smaller screens
        width="600px" // Set a larger width
      >
          <Box
            display={"flex"}
            padding={"10px 10px 0px 0px"}
            justifyContent={"flex-end"}
            alignItems={"flex-start"}
            gap={"10px"}
            alignSelf={"stretch"}
            onClick={onClose}
            _hover={{
              cursor: "pointer",
            }}
          >
            <Icon as={CloseIcon} svgwidth={"18px"} svgheight={"18px"} />
          </Box>
        <ModalHeader textAlign="center">
        
          <Button
            padding="20px 24px"
            justifyContent="center"
            alignItems="center"
            gap="10px"
            borderRadius="10px"
            bg="#27AE60"
            color="white"
            border="2px solid white"
            leftIcon={<CheckIcon svgwidth={"20px"} svgheight={"20px"} outerfillColour={"#27AE60"} InnerfillColour={"white"} />}
            _hover={{ bg: "#2E8B57" }}
          >
            <Text color={"#FFF"} fontSize="18px" fontWeight="600">
              Saved
            </Text>
          </Button>
        </ModalHeader>

        <ModalBody>
          <Box textAlign="center" marginBottom="20px">
            <Text fontFamily="Nunito" fontSize="16px" fontWeight="600">
              To apply this change to your analysis, you will need to run a new
              analysis, or continue making other changes before running the new
              analysis.
            </Text>
          </Box>
          <Flex direction="column" alignItems="center">
            {/* <Link href={link}> */}
            <Button
              margin="10px"
              padding="24px 24px"
              width="250px"
              justifyContent="center"
              alignItems="center"
              gap="10px"
              borderRadius="5px"
              bg="white"
              color="#0F9CD6"
              border="2px solid #0F9CD6"
              onClick={() => {
                onClose();
                setChangedData(false);
              }}
              leftIcon={
                <UpdateMyPlanIcon
                  color={optimizeIconColor}
                  svgwidth="25px"
                  svgheight="25px"
                />
              }
              _hover={{ bg: "#0F9CD6", color: "#FFF" }}
              onMouseEnter={() => {setOptimizeIconColor("#FFF"); setOptimizeTextColor1("#FFF");}}
              onMouseLeave={() => {setOptimizeIconColor("#0F9CD6"); setOptimizeTextColor1("#004069");}}
            >
              <Text fontSize="18px" fontWeight="600" color={optimizeTextColor1}>
                Continue Updating
              </Text>
            </Button>
            {/* </Link> */}
            <Link href={"/lifestrategy"}>
              <Button
                padding="24px 24px"
                paddingLeft={"15px"}
                width="250px"
                justifyContent="center"
                alignItems="center"
                gap="10px"
                borderRadius="5px"
                bg="white"
                color="#0F9CD6"
                border="2px solid #0F9CD6"
                leftIcon={
                  <OptimizeIcon
                    color={optimizeIconColor2}
                    svgwidth="25px"
                    svgheight="25px"
                  />
                }
                _hover={{ bg: "#0F9CD6", color: "white" }}
                onMouseEnter={() => {setOptimizeIconColor2("white"); setOptimizeTextColor2("white");}}
                onMouseLeave={() => {setOptimizeIconColor2("#0F9CD6"); setOptimizeTextColor2("#004069");}}
              >
                <Text fontSize="18px" fontWeight="600" color={optimizeTextColor2}>
                  Run new analysis
                </Text>
              </Button>
            </Link>
          </Flex>
          <Flex direction="column" alignItems="center" margin="20px">
            <Checkbox
              color={"#0F9CD6"}
              colorScheme="blue"
              onChange={handleCheckboxClickStore}
            >
              <Text fontSize="14px" color="#004069">
                Don't show me again
              </Text>
            </Checkbox>
          </Flex>
          {/* Add your additional content here */}
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default Popup;
