import {
  Box,
  Button,
  Flex,
  HStack,
  Icon,
  Text,
  useMediaQuery,
} from "@chakra-ui/react";
import React, { useContext, useEffect, useRef, useState } from "react";
import { useStore } from "../store";
import { PlayIcon, UpdateIcon } from "./CustomIcons";
import DropDownButton from "./InputFields/dropDownButton";
import { SpouseContext } from "./SpouseContext";
import OltreChatBubble from "./OltreChatBubble";
import Link from "./Link";

function GlobalContainerAfterOptimized({
  Heading,
  MainContainerHeading,
  CustomMainContainerHeading,
  item,
  currentPage,
  mainframeContent,
  link,
  setCurrentType,
  notShowChatBubble,
  customButton,
  navigate,
}) {
  const setShowNewOptimization = useStore(
    (store) => store.setShowNewOptimization
  );
  const setMobileSidebarOpen = useStore((store) => store.setMobileSidebarOpen);
  const contentRef = useRef(null);
  const userOptiData = useStore((store) => store.userOptiData);

  const [filterPage, setFilterPage] = useState(currentPage);
  const [portfolio, setPortfolio] = useState("joint-portfolio");

  const userInfo = useStore((store) => store.userInfo);

  const { spouseStatus, setSpouseStatus } = useContext(SpouseContext);

  const handleClickUpdate = () => {
    setShowNewOptimization(true);
    setMobileSidebarOpen(false);
    window.history.pushState({}, "", link);
    const navEvent = new PopStateEvent("popstate");
    window.dispatchEvent(navEvent);
  };

  useEffect(() => {
    if (contentRef.current) {
      contentRef.current.filterTable(filterPage); // Use the latest filterPage state
    }
    setCurrentType(filterPage);
  }, [filterPage]);

  useEffect(() => {
    if (contentRef.current) {
      contentRef.current.spouseDataChange(portfolio); // Use the latest filterPage state
    }
  }, [portfolio]);

  const SidebarPageOptimized = ({ items, currentPage, navigate }) => {
    const hasMainItems = items.some((item) => item.name);
    const hasOnlySubItems = items.some(
      (item) => !item.name && item.subItems && item.subItems.length > 0
    );
    return (
      <div
        className="sidebar-page-options-container mobile-hidden"
        style={{ padding: hasMainItems ? "5px 10px" : "5px 0px" }}
      >
        {items.map((item, index) => (
          <div key={index} className="main-item">
            {navigate && (
              <Link href={item.path}>
                <div
                  className={`main-link ${
                    filterPage === item.name ? "active" : ""
                  }`}
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    setFilterPage(item.filter);
                  }}
                >
                  {item.icon && <span className="icon">{item.icon}</span>}
                  {item.name}
                </div>
              </Link>
            )}
            {!navigate && (
            <div
              className={`main-link ${
                filterPage === item.name ? "active" : ""
              }`}
              style={{ cursor: "pointer" }}
              onClick={() => {
                setFilterPage(item.filter);
              }}
            >
              {item.icon && <span className="icon">{item.icon}</span>}
              {item.name}
            </div>
            )}
            {item.subItems && (items = " ") && (
              <div className="sub-items">
                {item.subItems.map((subItem, subIndex) => (
                  <div key={subIndex} className="sub-link-container">
                    {navigate && (
                      <Link href={subItem.path}>
                        <div
                          onClick={() => {
                            setFilterPage(subItem.filter);
                          }}
                          className={`sub-link ${
                            filterPage === subItem.name ? "active" : ""
                          }`}
                          style={{ cursor: "pointer" }}
                        >
                          <span
                            className={`circle ${
                              filterPage === subItem.name ? "filled" : ""
                            }`}
                          ></span>
                          {subItem.name}
                        </div>
                      </Link>
                    )}
                    {!navigate && (
                    <div
                      onClick={() => {
                        setFilterPage(subItem.filter);
                      }}
                      className={`sub-link ${
                        filterPage === subItem.name ? "active" : ""
                      }`}
                      style={{ cursor: "pointer" }}
                    >
                      <span
                        className={`circle ${
                          filterPage === subItem.name ? "filled" : ""
                        }`}
                      ></span>
                      {subItem.name}
                    </div>
                    )}
                  </div>
                ))}
              </div>
            )}
          </div>
        ))}
      </div>
    );
  };

  const [isLargerThan1200] = useMediaQuery("(min-width: 1870px)");
  return (
    <Flex
      display="flex"
      flexDirection="column"
      alignItems="center"
      gap="20px"
      bg={"#F3FAFF"}
      flex={"1 0 0"}
      w={"100%"}
      padding={"50px"}
      h={"auto"}
    >
      <Box
        display="flex"
        w={"100%"}
        flexDirection="column"
        alignItems="center"
        gap="20px"
        flexShrink="0"
        bg={"#F3FAFF"}
        h={"auto"}
      >
        {/* Flex container for heading and mainframe box */}
        <Flex
          display={"flex"}
          flexDirection={"row"}
          gap={"10px"}
          justifyContent={"space-between"}
          width={"100%"}
          minH={"calc(100vh - 190px)"}
        >
          {/* Heading box */}
          {item && (
            <Box
              display={"flex"}
              flexDirection={"column"}
              alignItems={"flex-start"}
              alignSelf={"stretch"}
              borderRadius={"10px"}
              border={"1px solid #00A2D4"}
              w={"240px"}
              h={"inherit"}
              padding={"20px"}
              bg={"#FFF"}
            >
              <Box
                display={"flex"}
                width={"200px"}
                padding={"10px 0px"}
                flexDirection={"column"}
                alignItems={"flex-start"}
                gap={"5px"}
              >
                <Box
                  display={"flex"}
                  h="31px"
                  flexDirection={"column"}
                  justifyContent={"center"}
                  alignSelf={"stretch"}
                >
                  <Text
                    color={"var(--Optiml---Midnight-Blue, #004069)"}
                    fontFamily={"Nunito"}
                    fontSize={"32px"}
                    fontStyle={"normal"}
                    fontWeight={"700"}
                    lineHeight={"normal"}
                  >
                    {Heading}
                  </Text>
                </Box>
              </Box>
              {/* Subheading box */}
              <SidebarPageOptimized
                items={item}
                currentPage={currentPage}
                navigate={navigate}
              />
            </Box>
          )}

          {/* mainframe box */}
          <Box
            display={"flex"}
            padding={"50px"}
            flexDirection={"column"}
            alignItems={"flex-start"}
            gap={"20px"}
            h={"inherit"}
            flex={"1 0 0"}
            alignSelf={"stretch"}
            borderRadius={"10px"}
            border={"1px solid var(--Optiml---Vibrant-Sky-Blue, #00A2D4)"}
            bg={"#FFF"}
            boxSizing="border-box"
            maxW={"calc(100% - 240px - 0px)"}
          >
            {
              <Box width={"100%"}>
                {CustomMainContainerHeading && customButton ? (
                  <HStack width={"100%"} justifyContent={"space-between"}>
                    <Text
                      color={"#00466D"}
                      fontFamily={"Nunito"}
                      fontSize={"24px"}
                      fontStyle={"normal"}
                      fontWeight={"700"}
                      lineHeight={"normal"}
                    >
                      {CustomMainContainerHeading}
                    </Text>
              <Box>
                    {customButton}
                    </Box>
                  </HStack>
                ) : CustomMainContainerHeading ? (
                  <Text
                    color={"#00466D"}
                    fontFamily={"Nunito"}
                    fontSize={"24px"}
                    fontStyle={"normal"}
                    fontWeight={"700"}
                    lineHeight={"normal"}
                  >
                    {CustomMainContainerHeading}
                  </Text>
                ) : (
                  <HStack>
                    <Text
                      color={"var(--Optiml---Midnight-Blue, #004069)"}
                      fontFamily={"Nunito"}
                      fontSize={"24px"}
                      fontStyle={"normal"}
                      fontWeight={"700"}
                      lineHeight={"normal"}
                      flexShrink={0}
                    >
                      {portfolio === "joint-portfolio" && spouseStatus
                        ? `${userInfo.FirstName} ${userInfo.LastName} & ${userInfo.spouseFirstName} ${userInfo.spouseLastName}`
                        : portfolio === "spouse-two-portfolio"
                        ? `${userInfo.spouseFirstName} ${userInfo.spouseLastName}`
                        : `${userInfo.FirstName} ${userInfo.LastName}`}
                    </Text>
                    {spouseStatus && MainContainerHeading && (
                      <DropDownButton
                        placeholder={"Combined"}
                        value={portfolio}
                        dropDownItems={[
                          { key: "joint-portfolio", value: "Combined" },
                          {
                            key: "spouse-one-portfolio",
                            value: `${userInfo.FirstName} ${userInfo.LastName}`,
                          },
                          {
                            key: "spouse-two-portfolio",
                            value: `${userInfo.spouseFirstName} ${userInfo.spouseLastName}`,
                          },
                        ]}
                        onChange={(key) => setPortfolio(key)}
                        customWidth={"30%"}
                        zIndex={100}
                      />
                    )}
                  </HStack>
                )}
              </Box>
            }
            {/* main container box */}
            <Box
              display={"flex"}
              padding={"20px"}
              flexDirection={"column"}
              alignItems={"flex-start"}
              gap={"20px"}
              alignSelf={"stretch"}
              borderRadius={"10px"}
              border={"1px solid #00A2D4"}
              bg={"#fff"}
            >
              {Heading && !CustomMainContainerHeading && (
                <Box
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                  alignSelf="stretch"
                >
                  <HStack spacing={4}>
                    <Text
                      color="#00A2D4"
                      fontFamily="Nunito"
                      fontSize="20px"
                      fontStyle="normal"
                      fontWeight="700"
                      lineHeight="normal"
                    >
                      {Heading}
                    </Text>
                    {link && (
                      <Button
                        gap={"10px"}
                        border={"1px solid #00A2D4"}
                        background={"#00A2D4"}
                        onClick={handleClickUpdate}
                      >
                        <Icon
                          as={UpdateIcon}
                          svgwidth={"15px"}
                          svgheight={"15px"}
                        />
                        <Text
                          color="#FFF"
                          fontFamily="Nunito"
                          fontSize="12px"
                          fontStyle="normal"
                          fontWeight="600"
                          lineHeight="normal"
                        >
                          Update
                        </Text>
                      </Button>
                    )}
                  </HStack>
                  {/* HIDE Video For Now */}
                  {/* <Box cursor={"pointer"}>
                    <Icon boxSize="25px" as={PlayIcon} svgwidth={"20px"} svgheight={"20px"} />
                  </Box> */}
                </Box>
              )}

              {React.cloneElement(mainframeContent, {
                ref: contentRef,
                type: filterPage,
                portfolio: portfolio,
              })}
            </Box>
          </Box>
        </Flex>
      </Box>
      {!notShowChatBubble && <OltreChatBubble />}
    </Flex>
  );
}

export default GlobalContainerAfterOptimized;
