import React from "react";
import "./ErrorPage.css";
import { Box, Text, VStack } from "@chakra-ui/react";

function ErrorPage() {
  return (
    <VStack gap={"20px"} alignItems={"flex-start"}>
      <Text
        color={"#00466D"}
        fontFamily={"Nunito"}
        fontSize={"24px"}
        fontStyle={"normal"}
        fontWeight={"700"}
        lineHeight={"normal"}
      >
        Whoops! We have a problem.
      </Text>
      <Box
        display={"flex"}
        padding={"20px"}
        flexDirection={"column"}
        justifyContent={"center"}
        alignItems={"center"}
        gap={"10px"}
        alignSelf={"stretch"}
        borderRadius={"10px"}
        border={"1px solid #00A2D4"}
        bg={"rgba(0, 162, 212, 0.10)"}
      >
        <Text
          alignSelf={"stretch"}
          color={"#00466D"}
          textAlign={"center"}
          fontFamily={"Nunito"}
          fontSize={"16px"}
          fontStyle={"normal"}
          fontWeight={"600"}
          lineHeight={"normal"}
        >
          Our apologies from the team at Optiml.
          <Text
            paddingTop={"20px"}
            alignSelf={"stretch"}
            color={"#00466D"}
            textAlign={"center"}
            fontFamily={"Nunito"}
            fontSize={"16px"}
            fontStyle={"normal"}
            fontWeight={"600"}
            lineHeight={"normal"}
          >
            We have created a ticket to address the error you encountered. Once
            we have resolved the issue, a team member will email you with
            confirmation that it has been resolved or provide instructions on
            how to update your account if needed.
          </Text>
          <Text
            paddingTop={"20px"}
            alignSelf={"stretch"}
            color={"#00466D"}
            textAlign={"center"}
            fontFamily={"Nunito"}
            fontSize={"16px"}
            fontStyle={"normal"}
            fontWeight={"600"}
            lineHeight={"normal"}
          >
            We appreciate your patience and will get back to you as soon as
            possible.
          </Text>
          <Text
            paddingTop={"20px"}
            alignSelf={"stretch"}
            color={"#00466D"}
            textAlign={"center"}
            fontFamily={"Nunito"}
            fontSize={"16px"}
            fontStyle={"normal"}
            fontWeight={"600"}
            lineHeight={"normal"}
          >
            Thank you for your understanding.
          </Text>
        </Text>
      </Box>
    </VStack>
  );
}

export default ErrorPage;
