import { useAuth0 } from "@auth0/auth0-react";
import {
  Box,
  Button,
  Flex,
  Heading,
  Text,
  VStack,
  useMediaQuery,
} from "@chakra-ui/react";
import axios from "axios";
import React, {
  forwardRef,
  useContext,
  useEffect,
  useImperativeHandle,
  useState,
} from "react";
import { useStore } from "../../store";
import GlobalBodyPage from "../GlobalContainer/BodyPage";
import DropDownButton from "../InputFields/dropDownButton";
import NormalInputButton from "../InputFields/normalInputButton";
import { SpouseContext } from "../SpouseContext";
import TableComponent from "../TableComponent";
import TooltipContent from "../TooltipContent";
import { CloseIcon } from "../CustomIcons";

const OtherIncomePage = forwardRef((props, ref) => {
  const [isLargerThan768] = useMediaQuery("(min-width: 500px)");
  const isMobile = !isLargerThan768;
  const { getAccessTokenSilently } = useAuth0();

  useImperativeHandle(ref, () => ({
    submitHandler,
    checkMandatoryFields,
  }));

  const checkMandatoryFields = () => {
    return true;
  };

  const axiosInstance = axios.create();
  async function getToken() {
    // Replace this with your Auth0 token retrieval logic
    const token = await getAccessTokenSilently({
      audience:
        "https://os2lsiwff43wfpb5ggyawt6qri0mgcro.lambda-url.us-east-2.on.aws/",
      scope: "write:optimization",
    });
    return token;
  }

  // Add a request interceptor
  axiosInstance.interceptors.request.use(
    async (config) => {
      const token = await getToken();
      config.headers.Authorization = `Bearer ${token}`;
      return config;
    },
    (error) => {
      // Do something with request error
      return Promise.reject(error);
    }
  );
  // Spouse 1
  const [hasOtherIncome, setHasOtherIncome] = useState("No");
  const [otherIncome, setOtherIncome] = useState("");
  const [otherIncomeNumber, setOtherIncomeNumber] = useState("");
  const [incomeName, setIncomeName] = useState("");
  const [incomeFrequency, setIncomeFrequency] = useState("");
  const [incomeAmount, setIncomeAmount] = useState(0);
  const [startReceiving, setStartReceiving] = useState("");
  const [stopReceiving, setStopReceiving] = useState(0);
  const [annualGrowth, setAnnualGrowth] = useState(0);
  const [otherIncomeArray, setOtherIncomeArray] = useState([]);

  // Spouse 2
  const [spouseHasOtherIncome, setSpouseHasOtherIncome] = useState("No");
  const [spouseOtherIncomeNumber, setSpouseOtherIncomeNumber] = useState("");
  const [spouseOtherIncome, setSpouseOtherIncome] = useState("");
  const [spouseIncomeName, setSpouseIncomeName] = useState("");
  const [spouseIncomeFrequency, setSpouseIncomeFrequency] = useState("");
  const [spouseIncomeAmount, setSpouseIncomeAmount] = useState(0);
  const [spouseStartReceiving, setSpouseStartReceiving] = useState(0);
  const [spouseStopReceiving, setSpouseStopReceiving] = useState(0);
  const [spouseAnnualGrowth, setSpouseAnnualGrowth] = useState();
  const [spouseOtherIncomeArray, setSpouseOtherIncomeArray] = useState([]);

  const [editing, setEditing] = useState(false);
  const [editingIndex, setEditingIndex] = useState(null);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const { spouseStatus, setSpouseStatus } = useContext(SpouseContext);
  const [activeArray, setActiveArray] = useState(null);
  const [spouseCheck, setSpouseCheck] = useState("");

  const { user, logout } = useAuth0();
  const userInfo = useStore((store) => store.userInfo);
  const userOtherIncomeInfo = useStore((store) => store.userOtherIncomeInfo);
  const setUserOtherIncomeInfo = useStore(
    (store) => store.setUserOtherIncomeInfo
  );
  const updateUserOtherIncomeInfo = useStore(
    (store) => store.updateUserOtherIncomeInfo
  );
  const deleteUserOtherIncomeInfo = useStore(
    (store) => store.deleteUserOtherIncomeInfo
  );

  const userExpensesInfo = useStore((store) => store.userExpensesInfo);

  const setUserPageLockInfo = useStore((store) => store.setUserPageLockInfo);
  const userPageLockInfo = useStore((store) => store.userPageLockInfo);

  const {
    FirstName: firstName,
    LastName: lastName,
    DateOfBirth: currentAge,
    spouseFirstName,
    spouseLastName,
    spouseDateOfBirth: spouseAge,
  } = userInfo;

  const Select = (userAge) => {
    const currentDate = new Date();
    let age = getAge(userAge, currentDate); // Assuming getAge returns the starting age.
    const runAgeList = [];
    for (let i = age; i <= 100; i++) {
      runAgeList.push({ key: i, value: i.toString() }); // Convert i to string if necessary
    }
    return runAgeList;
  };

  useEffect(() => {
    if (userOtherIncomeInfo?.length > 0) {
      setOtherIncomeArray(
        userOtherIncomeInfo?.filter((x) => x.spouseCheck === 0)
      );
      setSpouseOtherIncomeArray(
        userOtherIncomeInfo?.filter((x) => x.spouseCheck === 1)
      );
    }
  }, [userOtherIncomeInfo]);

  const [errors, setErrors] = useState({});
  const [spouseErrors, setSpouseErrors] = useState({});

  useEffect(() => {
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 100);
  }, [errors, spouseErrors]);

  function getAge(birthday, currentDate) {
    // Get the current date
    // const currentDate = new Date();

    // Convert the birthday string into a Date object
    const birthDate = new Date(birthday);

    // Calculate the age in years
    let age = currentDate.getFullYear() - birthDate.getFullYear();

    // Decrement the age if the current month and day are before the birth month and day
    if (
      currentDate.getMonth() < birthDate.getMonth() ||
      (currentDate.getMonth() === birthDate.getMonth() &&
        currentDate.getDate() <= birthDate.getDate())
    ) {
      age -= 1;
    }

    return age;
  }

  const moneyToNumber = (value) => {
    console.log(value);
    let numValue = value.replace(/[$,\s]/g, "");
    numValue = Number(numValue);
    return numValue;
  };

  const submitHandler = () => {
    //Set Page Lock Info In Database:
    const pageLockURL = (action) =>
      `${
        process.env.REACT_APP_NODE_API_GATEWAY
      }/CompassLambdaApi/NEW_USER_PAGE_LOCK/${action}?PageLockID=${user.sub.replace(
        "|",
        "%7c"
      )}&IncomePage=${1}&InvestmentsPage=${1}`;
    axiosInstance
      .get(pageLockURL("add"))
      .then((res) => {
        setUserPageLockInfo({
          ...userPageLockInfo,
          IncomePage: 1,
        });
        console.log(res);
        if (res.data.code === "ER_DUP_ENTRY") {
          axiosInstance.get(pageLockURL("update")).then((res) => {
            console.log(res);
            setUserPageLockInfo({
              ...userPageLockInfo,
              IncomePage: 1,
            });
          });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const checkMandatoryAddUpdateFields = () => {
    let newErrors = {};
    let newSpouseErrors = {};

    if (hasOtherIncome === "Yes") {
      if (!incomeName) newErrors.incomeName = "Income name is required";
      if (!incomeFrequency)
        newErrors.incomeFrequency = "Income frequency is required";
      if (!incomeAmount) newErrors.incomeAmount = "Income amount is required";
      if (annualGrowth > 20)
        newErrors.annualGrowth = "Growth rate cannot be higher than 20%";
      if (!startReceiving)
        newErrors.startReceiving = "Start receiving date is required";
      if (incomeFrequency === "Annual" && !stopReceiving) {
        newErrors.stopReceiving =
          "Stop receiving date is required for annual frequency";
      }
    }

    if (spouseStatus && spouseHasOtherIncome === "Yes") {
      if (!spouseIncomeName)
        newSpouseErrors.incomeName = "Income name is required";
      if (!spouseIncomeFrequency)
        newSpouseErrors.incomeFrequency = "Income frequency is required";
      if (!spouseIncomeAmount)
        newSpouseErrors.incomeAmount = "Income amount is required";
      if (spouseAnnualGrowth > 20)
        newSpouseErrors.annualGrowth = "Growth rate cannot be higher than 20%";
      if (!spouseStartReceiving)
        newSpouseErrors.startReceiving = "Start receiving date is required";
      if (spouseIncomeFrequency === "Annual" && !spouseStopReceiving) {
        newSpouseErrors.stopReceiving =
          "Stop receiving date is required for annual frequency";
      }
    }

    setSpouseErrors(newSpouseErrors);
    setErrors(newErrors);
    return (
      Object.keys(newErrors).length === 0 &&
      Object.keys(newSpouseErrors).length === 0
    ); // Return true if no errors
  };

  const renderAddUpdate = (arr) => {
    if (
      (hasOtherIncome === "Yes" &&
        (!incomeName ||
          !incomeFrequency ||
          !incomeAmount ||
          !startReceiving ||
          (incomeFrequency === "Annual" && !stopReceiving))) ||
      (spouseStatus &&
        spouseHasOtherIncome === "Yes" &&
        (!spouseIncomeName ||
          !spouseIncomeFrequency ||
          !spouseIncomeAmount ||
          !spouseStartReceiving ||
          (spouseIncomeFrequency === "Annual" && !spouseStopReceiving)))
    ) {
      return (
        <Flex justifyContent={"flex-start"} marginTop={"20px"}>
          <Button
            bg="#27AE60"
            w="172px"
            h="37px"
            align="flex-start"
            onClick={checkMandatoryAddUpdateFields}
            // disabled={!isFieldsValid}
          >
            <Text
              color={"#FFFFFF"}
              fontFamily={"Nunito"}
              fontSize={"14px"}
              fontStyle={"normal"}
              fontWeight={"700"}
              lineHeight={"normal"}
            >
              {editing && activeArray === arr ? "Update" : "Add"}
            </Text>
          </Button>
        </Flex>
      );
    } else {
      return (
        <Flex justifyContent={"flex-start"} marginTop={"20px"}>
          <Button
            bg="#27AE60"
            w="172px"
            h="37px"
            align="flex-start"
            onClick={(e) => addOtherIncome(e, arr)}
            // disabled={!isFieldsValid}
          >
            <Text
              color={"#FFFFFF"}
              fontFamily={"Nunito"}
              fontSize={"14px"}
              fontStyle={"normal"}
              fontWeight={"700"}
              lineHeight={"normal"}
            >
              {editing && activeArray === arr ? "Update" : "Add"}
            </Text>
          </Button>
        </Flex>
      );
    }
  };

  const onSubmitUpdate = (e, newArray) => {
    let x = newArray.Other_Income_Number;
    e.preventDefault();
    // console.log("Editing", newArray)
    const otherIncomeURL = (action) =>
      `${
        process.env.REACT_APP_NODE_API_GATEWAY
      }/CompassLambdaApi/INCOME-OTHER/${action}?IncomeID=${user.sub.replace(
        "|",
        "%7c"
      )}&Do_Other_Income=${newArray.Do_Other_Income}&Other_Income_Type=${
        newArray.Other_Income_Type
      }&Other_Income_Name=${
        newArray.Other_Income_Name
      }&Other_Income_Frequency=${
        newArray.Other_Income_Frequency
      }&Other_Income_Amount=${
        newArray.Other_Income_Amount
      }&Other_Income_Start_Age=${
        newArray.Other_Income_Start_Age
      }&Other_Income_Stop_Age=${
        newArray.Other_Income_Stop_Age
      }&Other_Income_Growth=${
        newArray.Other_Income_Growth
      }&Other_Income_Number=${newArray.Other_Income_Number}&spouseCheck=${
        newArray.spouseCheck
      }`;
    axiosInstance
      .get(otherIncomeURL("update"))
      .then((res) => {
        //   console.log(res);
        const newData = {
          Do_Other_Income: newArray.Do_Other_Income,
          Other_Income_Type: newArray.Other_Income_Type,
          Other_Income_Name: newArray.Other_Income_Name,
          Other_Income_Frequency: newArray.Other_Income_Frequency,
          Other_Income_Amount: newArray.Other_Income_Amount,
          Other_Income_Start_Age: newArray.Other_Income_Start_Age,
          Other_Income_Stop_Age: newArray.Other_Income_Stop_Age,
          Other_Income_Growth: newArray?.Other_Income_Growth ?? 0,
          Other_Income_Number: newArray.Other_Income_Number,
          spouseCheck: newArray.spouseCheck,
        };
        updateUserOtherIncomeInfo(x, newData);
      })
      .catch((error) => {
        // console.log(error);
      });
  };

  const onSubmitAdd = (e, newArray) => {
    e.preventDefault();
    //   console.log("Adding", newArray)
    const otherIncomeURL = (action) =>
      `${
        process.env.REACT_APP_NODE_API_GATEWAY
      }/CompassLambdaApi/INCOME-OTHER/${action}?IncomeID=${user.sub.replace(
        "|",
        "%7c"
      )}&Do_Other_Income=${newArray.Do_Other_Income}&Other_Income_Type=${
        newArray.Other_Income_Type
      }&Other_Income_Name=${
        newArray.Other_Income_Name
      }&Other_Income_Frequency=${
        newArray.Other_Income_Frequency
      }&Other_Income_Amount=${
        newArray.Other_Income_Amount
      }&Other_Income_Start_Age=${
        newArray.Other_Income_Start_Age
      }&Other_Income_Stop_Age=${
        newArray.Other_Income_Stop_Age
      }&Other_Income_Growth=${newArray.Other_Income_Growth}&spouseCheck=${
        newArray.spouseCheck
      }`;
    axiosInstance
      .get(otherIncomeURL("add"))
      .then((res) => {
        console.log(res);
        const newData = [
          {
            Do_Other_Income: newArray.Do_Other_Income,
            Other_Income_Type: newArray.Other_Income_Type,
            Other_Income_Name: newArray.Other_Income_Name,
            Other_Income_Frequency: newArray.Other_Income_Frequency,
            Other_Income_Amount: newArray.Other_Income_Amount,
            Other_Income_Start_Age: newArray.Other_Income_Start_Age,
            Other_Income_Stop_Age: newArray.Other_Income_Stop_Age,
            Other_Income_Growth: newArray.Other_Income_Growth ?? 0,
            Other_Income_Number: res.data.lastInsertedId,
            spouseCheck: newArray.spouseCheck,
          },
        ];
        setUserOtherIncomeInfo(newData);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const editField = (i, array) => {
    setActiveArray(array);
    setEditing(true);
    setEditingIndex(i);
    if (array === otherIncomeArray) {
      setOtherIncome(otherIncomeArray[i]?.Other_Income_Type);
      setIncomeName(otherIncomeArray[i]?.Other_Income_Name);
      setIncomeFrequency(otherIncomeArray[i]?.Other_Income_Frequency);
      setIncomeAmount(otherIncomeArray[i]?.Other_Income_Amount);
      setStartReceiving(otherIncomeArray[i]?.Other_Income_Start_Age);
      setStopReceiving(otherIncomeArray[i]?.Other_Income_Stop_Age);
      setAnnualGrowth(otherIncomeArray[i]?.Other_Income_Growth);
      setHasOtherIncome("Yes");
      setSpouseHasOtherIncome("No");
      setOtherIncomeNumber(otherIncomeArray[i]?.Other_Income_Number);
      setSpouseCheck(0);
    } else {
      setSpouseOtherIncome(spouseOtherIncomeArray[i]?.Other_Income_Type);
      setSpouseIncomeName(spouseOtherIncomeArray[i]?.Other_Income_Name);
      setSpouseIncomeFrequency(
        spouseOtherIncomeArray[i]?.Other_Income_Frequency
      );
      setSpouseIncomeAmount(spouseOtherIncomeArray[i]?.Other_Income_Amount);
      setSpouseStartReceiving(
        spouseOtherIncomeArray[i]?.Other_Income_Start_Age
      );
      setSpouseStopReceiving(spouseOtherIncomeArray[i]?.Other_Income_Stop_Age);
      setSpouseAnnualGrowth(spouseOtherIncomeArray[i]?.Other_Income_Growth);
      setSpouseHasOtherIncome("Yes");
      setHasOtherIncome("No");
      setSpouseOtherIncomeNumber(
        spouseOtherIncomeArray[i]?.Other_Income_Number
      );
      setSpouseCheck(1);
    }
  };

  const onDeleteRow = (i, array) => {
    setSpouseCheck(array[i].spouseCheck);
    setEditingIndex(i);
    setActiveArray(array);
    setShowDeleteModal(true);
  };

  const DeleteIncomeModal = () => {
    const removeIncomeModal = () => {
      const deleteOtherIncomeUrl = (action) =>
        `${process.env.REACT_APP_NODE_API_GATEWAY}/CompassLambdaApi/INCOME-OTHER/${action}?Other_Income_Number=${activeArray[editingIndex].Other_Income_Number}`;
      axiosInstance.get(deleteOtherIncomeUrl("delete")).then((res) => {
        // console.log('Delete res', res)
        if (spouseCheck === 0) {
          deleteUserOtherIncomeInfo(
            otherIncomeArray[editingIndex].Other_Income_Number
          );
          removeField(editingIndex);
          setEditingIndex(null);
          setEditing(false);
          setOtherIncome("");
          setIncomeName("");
          setIncomeFrequency("");
          setIncomeAmount("");
          setStartReceiving();
          setStopReceiving(0);
          setAnnualGrowth(userExpensesInfo?.expenseCPI);
        }
        if (spouseCheck === 1) {
          deleteUserOtherIncomeInfo(
            spouseOtherIncomeArray[editingIndex].Other_Income_Number
          );
          removeField(editingIndex);
          setEditingIndex(null);
          setEditing(false);
          setSpouseOtherIncome("");
          setSpouseIncomeName("");
          setSpouseIncomeFrequency("");
          setSpouseIncomeAmount("");
          setSpouseStartReceiving();
          setSpouseStopReceiving(0);
          setSpouseAnnualGrowth(userExpensesInfo?.expenseCPI);
        }
      });
    };

    if (!showDeleteModal) {
      return null;
    }
    const [yesClicked, setYesClicked] = useState(false);
    const [noClicked, setNoClicked] = useState(false);

    return (
      <div
        className="delete-income-modal"
        style={{
          display: "flex",
          alignItems: "center",
          width: "100%",
          maxWidth: "700px",
          justifyContent: "center",
          top: "50%",
          left: "50%",
          transform: isMobile
            ? "translate(-50%, -50%)"
            : "translate(-50%, -50%)",
        }}
        id="delete-income-modal"
      >
        <Box
          display={"flex"}
          width={"100%"}
          padding={"10px 10px 0px 0px"}
          marginLeft={"5px"}
          alignItems={"flex-end"}
          justifyContent={"flex-end"}
          onClick={() => setShowDeleteModal(false)}
          cursor={"pointer"}
        >
          <CloseIcon
            onClick={() => setShowDeleteModal(false)}
            svgheight={"20px"}
            svgwidth={"20px"}
          />
        </Box>
        <Box
          display={"flex"}
          padding={"0px 25px 25px 25px"}
          flexDirection={"column"}
          alignItems={"center"}
          gap={"10px"}
          alignSelf={"stretch"}
          width={"100%"}
        >
          <Text
            color={"#0093D0"}
            fontFamily={"Nunito"}
            fontSize={"24px"}
            fontStyle={"normal"}
            fontWeight={"600"}
            lineHeight={"normal"}
            textDecor={"underline"}
          >
          Are you sure you want to remove this income?
        </Text>
        <div className="content"></div>
        <div className="actions">
          <button
            className={`decision-button yes-button ${
              yesClicked ? "yes-clicked" : ""
            }`}
            onClick={() => {
              setYesClicked(true);
              setNoClicked(false);
              removeIncomeModal();
            }}
          >
            Yes
          </button>
          <button
            className={`decision-button no-button ${
              noClicked ? "yes-clicked" : ""
            }`}
            onClick={() => {
              {
                setNoClicked(true);
                setYesClicked(false);
                setShowDeleteModal(false);
              }
            }}
          >
            No
          </button>
        </div>
        </Box>
      </div>
    );
  };

  const addOtherIncome = (e, array) => {
    if (editing) {
      if (array === otherIncomeArray) {
        setOtherIncomeArray((prevArray) => {
          // Create a copy of the original array
          let newArray = [...prevArray];
          // Replace the object at editingIndex with the new object
          newArray[editingIndex] = {
            Do_Other_Income: hasOtherIncome,
            Other_Income_Type: otherIncome,
            Other_Income_Name: incomeName,
            Other_Income_Frequency: incomeFrequency,
            Other_Income_Amount: incomeAmount,
            Other_Income_Start_Age: startReceiving,
            Other_Income_Stop_Age: stopReceiving,
            Other_Income_Growth: annualGrowth ?? 0,
            Other_Income_Number: otherIncomeNumber,
            spouseCheck: 0,
          };
          onSubmitUpdate(e, newArray[editingIndex]);
          return newArray;
        });
      }
      if (array === spouseOtherIncomeArray) {
        setSpouseOtherIncomeArray((prevArray) => {
          // Create a copy of the original array
          let newArray = [...prevArray];
          // Replace the object at editingIndex with the new object
          newArray[editingIndex] = {
            Do_Other_Income: spouseHasOtherIncome,
            Other_Income_Type: spouseOtherIncome,
            Other_Income_Name: spouseIncomeName,
            Other_Income_Frequency: spouseIncomeFrequency,
            Other_Income_Amount: spouseIncomeAmount,
            Other_Income_Start_Age: spouseStartReceiving,
            Other_Income_Stop_Age: spouseStopReceiving,
            Other_Income_Growth: spouseAnnualGrowth ?? 0,
            Other_Income_Number: spouseOtherIncomeNumber,
            spouseCheck: 1,
          };
          onSubmitUpdate(e, newArray[editingIndex]);
          return newArray;
        });
      }
      setEditing(false);
      setEditingIndex(null);
    } else {
      if (array === otherIncomeArray) {
        setOtherIncomeArray((prevArray) => {
          let newArray = [
            ...prevArray,
            {
              Do_Other_Income: hasOtherIncome,
              Other_Income_Type: otherIncome,
              Other_Income_Name: incomeName,
              Other_Income_Frequency: incomeFrequency,
              Other_Income_Amount: incomeAmount,
              Other_Income_Start_Age: startReceiving,
              Other_Income_Stop_Age: stopReceiving,
              Other_Income_Growth: annualGrowth ?? 0,
              spouseCheck: 0,
            },
          ];
          onSubmitAdd(e, newArray[newArray.length - 1]);
          return newArray;
        });
      }
      if (array === spouseOtherIncomeArray) {
        setSpouseOtherIncomeArray((prevArray) => {
          let newArray = [
            ...prevArray,
            {
              Do_Other_Income: spouseHasOtherIncome,
              Other_Income_Type: spouseOtherIncome,
              Other_Income_Name: spouseIncomeName,
              Other_Income_Frequency: spouseIncomeFrequency,
              Other_Income_Amount: spouseIncomeAmount,
              Other_Income_Start_Age: spouseStartReceiving,
              Other_Income_Stop_Age: spouseStopReceiving,
              Other_Income_Growth: spouseAnnualGrowth ?? 0,
              spouseCheck: 1,
            },
          ];
          onSubmitAdd(e, newArray[newArray.length - 1]);
          return newArray;
        });
      }
    }
    setOtherIncome("");
    setIncomeName("");
    setIncomeFrequency("");
    setIncomeAmount(0);
    setStartReceiving("");
    setStopReceiving(0);
    setAnnualGrowth(userExpensesInfo?.expenseCPI);
    setHasOtherIncome("No");
    setSpouseHasOtherIncome("No");
    setSpouseOtherIncome("");
    setSpouseIncomeName("");
    setSpouseIncomeFrequency("");
    setSpouseIncomeAmount(0);
    setSpouseStartReceiving("");
    setSpouseStopReceiving(0);
    setSpouseAnnualGrowth(userExpensesInfo?.expenseCPI);
  };

  const removeField = (i) => {
    if (activeArray === otherIncomeArray) {
      otherIncomeArray.splice(i, 1);
      setOtherIncomeArray((prevArray) => [...prevArray]);
    } else {
      spouseOtherIncomeArray.splice(i, 1);
      setSpouseOtherIncomeArray((prevArray) => [...prevArray]);
    }
    setShowDeleteModal(false);
  };

  const handleCancelSpouse1 = () => {
    setHasOtherIncome("No");
  };

  const handleCancelSpouse2 = () => {
    setSpouseHasOtherIncome("No");
  };

  const columns = [
    { header: "Name", field: "name" },
    { header: "Value", field: "value" },
  ];

  const data = otherIncomeArray.map((item) => ({
    name: item.Other_Income_Name,
    value: item.Other_Income_Amount,
  }));

  const spouseData = spouseOtherIncomeArray.map((item) => ({
    name: item.Other_Income_Name,
    value: item.Other_Income_Amount,
  }));

  return (
    <Flex
      display="flex"
      flexWrap="wrap" // Enable wrapping
      justifyContent="flex-start"
      alignItems="start"
      width={"100%"}
    >
      <DeleteIncomeModal />
      {!isMobile && (
        <VStack spacing={4} alignItems={"flex-start"} width={"100%"} mt={-4}>
          <GlobalBodyPage
            Heading={"Other Income"}
            mainframeContent={
              <Box
                display={"flex"}
                width={"100%"}
                flexDirection={"row"}
                alignItems={"flex-end"}
                justifyContent={"space-between"}
                gap={"10px"}
                flexWrap={"wrap"}
              >
                <Flex justify="space-between" w="100%">
                  <Heading
                    height={"27px"}
                    alignSelf={"stretch"}
                    color={"#00466D"}
                    fontFamily={"Nunito"}
                    fontSize={"20px"}
                    fontStyle={"normal"}
                    fontWeight={"700"}
                    lineHeight={"normal"}
                  >
                    {firstName} {lastName}
                  </Heading>
                </Flex>
                {data.length > 0 && hasOtherIncome != "Yes" && (
                  <TableComponent
                    columns={columns}
                    data={data}
                    editField={editField}
                    onDeleteRow={onDeleteRow}
                    rawData={otherIncomeArray}
                  />
                )}
                {hasOtherIncome != "Yes" && data.length < 1 && (
                  <DropDownButton
                    Heading={
                      "Do you have any other income sources you would like to include in the analysis?"
                    }
                    placeholder={"Select"}
                    dropDownItems={[
                      { key: "Yes", value: "Yes" },
                      { key: "No", value: "No" },
                    ]}
                    value={hasOtherIncome}
                    onChange={(key) => {
                      setHasOtherIncome(key);
                      setSpouseHasOtherIncome("No");
                    }}
                    customWidth={"100%"}
                    MenuCustomWidth={"45%"}
                    error={errors.hasOtherIncome}
                    tooltipContent={TooltipContent.otherIncomeYesNo}
                  />
                )}
                {data.length >= 1 && hasOtherIncome != "Yes" && (
                  <Flex justifyContent={"flex-start"} marginTop={"20px"}>
                    <Button
                      bg="#27AE60"
                      w="172px"
                      h="37px"
                      align="flex-start"
                      onClick={() => {
                        setSpouseHasOtherIncome("No");
                        setHasOtherIncome("Yes");
                      }}
                      // disabled={!isFieldsValid}
                    >
                      <Text
                        color={"#FFFFFF"}
                        fontFamily={"Nunito"}
                        fontSize={"14px"}
                        fontStyle={"normal"}
                        fontWeight={"700"}
                        lineHeight={"normal"}
                      >
                        Add
                      </Text>
                    </Button>
                  </Flex>
                )}
                {hasOtherIncome === "Yes" && (
                  <OtherSourceDetails
                    onCancel={handleCancelSpouse1}
                    incomeName={incomeName}
                    setIncomeName={setIncomeName}
                    incomeFrequency={incomeFrequency}
                    setIncomeFrequency={setIncomeFrequency}
                    incomeAmount={incomeAmount}
                    setIncomeAmount={setIncomeAmount}
                    startReceiving={startReceiving}
                    setStartReceiving={setStartReceiving}
                    stopReceiving={stopReceiving}
                    setStopReceiving={setStopReceiving}
                    annualGrowth={annualGrowth}
                    setAnnualGrowth={setAnnualGrowth}
                    currentAge={currentAge}
                    Select={Select}
                    renderAddUpdate={renderAddUpdate}
                    otherIncomeArray={otherIncomeArray}
                    moneyToNumber={moneyToNumber}
                    errors={errors}
                  />
                )}
              </Box>
            }
            videoName={"Other Income Page"}
            video={"Other_Income_Page.mp4"}
          />

          {spouseStatus && (
            <GlobalBodyPage
              mainframeContent={
                <VStack spacing={4} alignItems={"flex-start"} width={"100%"}>
                  <Flex justify="space-between" w="100%">
                    <Heading
                      height={"27px"}
                      alignSelf={"stretch"}
                      color={"#00466D"}
                      fontFamily={"Nunito"}
                      fontSize={"20px"}
                      fontStyle={"normal"}
                      fontWeight={"700"}
                      lineHeight={"normal"}
                    >
                      {spouseFirstName} {spouseLastName}
                    </Heading>
                  </Flex>
                  {spouseData.length > 0 && spouseHasOtherIncome != "Yes" && (
                    <TableComponent
                      columns={columns}
                      data={spouseData}
                      editField={editField}
                      onDeleteRow={onDeleteRow}
                      rawData={spouseOtherIncomeArray}
                    />
                  )}
                  {spouseHasOtherIncome != "Yes" && spouseData.length < 1 && (
                    <DropDownButton
                      Heading={
                        "Do you have any other income sources you would like to include in the analysis?"
                      }
                      placeholder={"Select"}
                      dropDownItems={[
                        { key: "Yes", value: "Yes" },
                        { key: "No", value: "No" },
                      ]}
                      value={spouseHasOtherIncome}
                      onChange={(key) => {
                        setSpouseHasOtherIncome(key);
                        setHasOtherIncome("No");
                      }}
                      customWidth={"100%"}
                      MenuCustomWidth={"45%"}
                      error={spouseErrors.hasOtherIncome}
                      tooltipContent={TooltipContent.otherIncomeYesNo}
                    />
                  )}
                  {spouseData.length >= 1 && spouseHasOtherIncome != "Yes" && (
                    <Flex justifyContent={"flex-start"} marginTop={"20px"}>
                      <Button
                        bg="#27AE60"
                        w="172px"
                        h="37px"
                        align="flex-start"
                        onClick={() => {
                          setSpouseHasOtherIncome("Yes");
                          setHasOtherIncome("No");
                        }}
                        // disabled={!isFieldsValid}
                      >
                        <Text
                          color={"#FFFFFF"}
                          fontFamily={"Nunito"}
                          fontSize={"14px"}
                          fontStyle={"normal"}
                          fontWeight={"700"}
                          lineHeight={"normal"}
                        >
                          Add
                        </Text>
                      </Button>
                    </Flex>
                  )}
                  {spouseHasOtherIncome === "Yes" && (
                    <OtherSourceDetails
                      onCancel={handleCancelSpouse2}
                      incomeName={spouseIncomeName}
                      setIncomeName={setSpouseIncomeName}
                      incomeFrequency={spouseIncomeFrequency}
                      setIncomeFrequency={setSpouseIncomeFrequency}
                      incomeAmount={spouseIncomeAmount}
                      setIncomeAmount={setSpouseIncomeAmount}
                      startReceiving={spouseStartReceiving}
                      setStartReceiving={setSpouseStartReceiving}
                      stopReceiving={spouseStopReceiving}
                      setStopReceiving={setSpouseStopReceiving}
                      annualGrowth={spouseAnnualGrowth}
                      setAnnualGrowth={setSpouseAnnualGrowth}
                      currentAge={spouseAge}
                      Select={Select}
                      renderAddUpdate={renderAddUpdate}
                      otherIncomeArray={spouseOtherIncomeArray}
                      moneyToNumber={moneyToNumber}
                      errors={spouseErrors}
                    />
                  )}
                </VStack>
              }
              videoName={"Other Income Page"}
              video={"Other_Income_Page.mp4"}
            />
          )}
        </VStack>
      )}

      {isMobile && (
        <VStack spacing={4} alignItems={"flex-start"} width={"100%"}>
          <GlobalBodyPage
            Heading={"Other Income"}
            mainframeContent={
              <VStack spacing={4} alignItems={"flex-start"} width={"100%"}>
                <Flex justify="space-between" w="100%">
                  <Heading
                    height={"27px"}
                    alignSelf={"stretch"}
                    color={"#00466D"}
                    fontFamily={"Nunito"}
                    fontSize={"20px"}
                    fontStyle={"normal"}
                    fontWeight={"700"}
                    lineHeight={"normal"}
                  >
                    {firstName} {lastName}
                  </Heading>
                </Flex>
                {data.length > 0 && hasOtherIncome != "Yes" && (
                  <TableComponent
                    columns={columns}
                    data={data}
                    editField={editField}
                    onDeleteRow={onDeleteRow}
                    rawData={otherIncomeArray}
                  />
                )}
                {hasOtherIncome != "Yes" && data.length < 1 && (
                  <DropDownButton
                    Heading={
                      "Do you have any other income sources you would like to include in the analysis?"
                    }
                    placeholder={"Select"}
                    dropDownItems={[
                      { key: "Yes", value: "Yes" },
                      { key: "No", value: "No" },
                    ]}
                    value={hasOtherIncome}
                    onChange={(key) => {
                      setHasOtherIncome(key);
                    }}
                    customWidth={"100%"}
                    MenuCustomWidth={"100%"}
                    error={errors.hasOtherIncome}
                    tooltipContent={TooltipContent.otherIncomeYesNo}
                  />
                )}
                {data.length >= 1 && hasOtherIncome != "Yes" && (
                  <Flex justifyContent={"flex-start"} marginTop={"20px"}>
                    <Button
                      bg="#27AE60"
                      w="172px"
                      h="37px"
                      align="flex-start"
                      onClick={() => {
                        setSpouseHasOtherIncome("No");
                        setHasOtherIncome("Yes");
                      }}
                      // disabled={!isFieldsValid}
                    >
                      <Text
                        color={"#FFFFFF"}
                        fontFamily={"Nunito"}
                        fontSize={"14px"}
                        fontStyle={"normal"}
                        fontWeight={"700"}
                        lineHeight={"normal"}
                      >
                        Add
                      </Text>
                    </Button>
                  </Flex>
                )}
                {hasOtherIncome === "Yes" && (
                  <OtherSourceDetailsMobile
                    onCancel={handleCancelSpouse1}
                    incomeName={incomeName}
                    setIncomeName={setIncomeName}
                    incomeFrequency={incomeFrequency}
                    setIncomeFrequency={setIncomeFrequency}
                    incomeAmount={incomeAmount}
                    setIncomeAmount={setIncomeAmount}
                    startReceiving={startReceiving}
                    setStartReceiving={setStartReceiving}
                    stopReceiving={stopReceiving}
                    setStopReceiving={setStopReceiving}
                    annualGrowth={annualGrowth}
                    setAnnualGrowth={setAnnualGrowth}
                    currentAge={currentAge}
                    Select={Select}
                    renderAddUpdate={renderAddUpdate}
                    otherIncomeArray={otherIncomeArray}
                    moneyToNumber={moneyToNumber}
                    errors={errors}
                  />
                )}
              </VStack>
            }
            videoName={"Other Income Page"}
            video={"Other_Income_Page.mp4"}
          />

          {spouseStatus && (
            <GlobalBodyPage
              mainframeContent={
                <VStack spacing={4} alignItems={"flex-start"} width={"100%"}>
                  <Flex justify="space-between" w="100%">
                    <Heading
                      height={"27px"}
                      alignSelf={"stretch"}
                      color={"#00466D"}
                      fontFamily={"Nunito"}
                      fontSize={"20px"}
                      fontStyle={"normal"}
                      fontWeight={"700"}
                      lineHeight={"normal"}
                    >
                      {spouseFirstName} {spouseLastName}
                    </Heading>
                  </Flex>
                  {spouseData.length > 0 && spouseHasOtherIncome != "Yes" && (
                    <TableComponent
                      columns={columns}
                      data={spouseData}
                      editField={editField}
                      onDeleteRow={onDeleteRow}
                      rawData={spouseOtherIncomeArray}
                    />
                  )}
                  {spouseHasOtherIncome != "Yes" && spouseData.length < 1 && (
                    <DropDownButton
                      Heading={
                        "Do you have any other income sources you would like to include in the analysis?"
                      }
                      placeholder={"Select"}
                      dropDownItems={[
                        { key: "Yes", value: "Yes" },
                        { key: "No", value: "No" },
                      ]}
                      value={spouseHasOtherIncome}
                      onChange={(key) => {
                        setSpouseHasOtherIncome(key);
                      }}
                      customWidth={"100%"}
                      MenuCustomWidth={"100%"}
                      error={spouseErrors.hasOtherIncome}
                      tooltipContent={TooltipContent.otherIncomeYesNo}
                    />
                  )}
                  {spouseData.length >= 1 && spouseHasOtherIncome != "Yes" && (
                    <Flex justifyContent={"flex-start"} marginTop={"20px"}>
                      <Button
                        bg="#27AE60"
                        w="172px"
                        h="37px"
                        align="flex-start"
                        onClick={() => {
                          setSpouseHasOtherIncome("Yes");
                          setHasOtherIncome("No");
                        }}
                        // disabled={!isFieldsValid}
                      >
                        <Text
                          color={"#FFFFFF"}
                          fontFamily={"Nunito"}
                          fontSize={"14px"}
                          fontStyle={"normal"}
                          fontWeight={"700"}
                          lineHeight={"normal"}
                        >
                          Add
                        </Text>
                      </Button>
                    </Flex>
                  )}
                  {spouseHasOtherIncome === "Yes" && (
                    <OtherSourceDetailsMobile
                      onCancel={handleCancelSpouse2}
                      incomeName={spouseIncomeName}
                      setIncomeName={setSpouseIncomeName}
                      incomeFrequency={spouseIncomeFrequency}
                      setIncomeFrequency={setSpouseIncomeFrequency}
                      incomeAmount={spouseIncomeAmount}
                      setIncomeAmount={setSpouseIncomeAmount}
                      startReceiving={spouseStartReceiving}
                      setStartReceiving={setSpouseStartReceiving}
                      stopReceiving={spouseStopReceiving}
                      setStopReceiving={setSpouseStopReceiving}
                      annualGrowth={spouseAnnualGrowth}
                      setAnnualGrowth={setSpouseAnnualGrowth}
                      currentAge={spouseAge}
                      Select={Select}
                      renderAddUpdate={renderAddUpdate}
                      otherIncomeArray={spouseOtherIncomeArray}
                      moneyToNumber={moneyToNumber}
                      errors={errors}
                    />
                  )}
                </VStack>
              }
              videoName={"Other Income Page"}
              video={"Other_Income_Page.mp4"}
            />
          )}
        </VStack>
      )}
    </Flex>
  );
});

function OtherSourceDetails({
  onCancel,
  incomeName,
  setIncomeName,
  incomeFrequency,
  setIncomeFrequency,
  incomeAmount,
  setIncomeAmount,
  startReceiving,
  setStartReceiving,
  stopReceiving,
  setStopReceiving,
  annualGrowth,
  setAnnualGrowth,
  currentAge,
  Select,
  renderAddUpdate,
  otherIncomeArray,
  moneyToNumber,
  errors,
}) {
  return (
    <Flex flexDirection={"column"} gap={"10px"} w={"100%"}>
      <Box
        display={"flex"}
        width={"100%"}
        padding={"20px 10px"}
        flexDirection={"column"}
        alignItems={"flex-start"}
        gap={"10px"}
        borderRadius={"10px"}
        border={"1px solid #00A2D4"}
        bg={"rgba(0, 162, 212, 0.1)"}
      >
        <Text
          alignSelf={"stretch"}
          color={"#00A2D4"}
          textAlign={"center"}
          fontFamily={"Nunito"}
          fontSize={"14px"}
          fontStyle={"normal"}
          fontWeight={"700"}
          lineHeight={"normal"}
        >
          Business and Rental Property income to be input in “Assets” section
        </Text>
      </Box>
      <Flex justifyContent={"flex-end"}>
        <Button
          bg="#FFE5E5"
          w="73px"
          h="31px"
          align="flex-end"
          onClick={onCancel}
        >
          <Text
            color={"#E71D36"}
            fontFamily={"Nunito"}
            fontSize={"14px"}
            fontStyle={"normal"}
            fontWeight={"700"}
            lineHeight={"normal"}
          >
            Cancel
          </Text>
        </Button>
      </Flex>
      <NormalInputButton
        Heading={"Name/Title"}
        placeholder={""}
        customWidth={"100%"}
        InputWidth={"100%"}
        value={incomeName}
        onChange={(e) => {
          setIncomeName(e.target.value);
        }}
        error={errors.incomeName}
        tooltipContent={TooltipContent.otherIncomeName}
      />

      <Flex justify="space-between" w="full" alignItems="flex-end">
        <DropDownButton
          Heading={"Frequency of income"}
          placeholder={"Select"}
          dropDownItems={[
            { key: "1 time payment", value: "1 time payment" },
            { key: "Annual", value: "Annual" },
          ]}
          value={incomeFrequency}
          onChange={(key) => {
            setIncomeFrequency(key);
          }}
          MenuCustomWidth={"100%"}
          error={errors.incomeFrequency}
          tooltipContent={TooltipContent.otherIncomeFrequency}
        />
        <NormalInputButton
          Heading="Income amount"
          placeholder="$"
          value={incomeAmount}
          onChange={(e) => {
            setIncomeAmount(moneyToNumber(e.target.value));
          }}
          useThousandSeparator={true}
          error={errors.incomeAmount}
          tooltipContent={TooltipContent.otherIncomeAmount}
        />
      </Flex>
      <Flex justify="space-between" w="full" alignItems="flex-end">
        <DropDownButton
          Heading={"What age will you start receiving this income"}
          placeholder={"Select"}
          dropDownItems={Select(currentAge)}
          value={startReceiving}
          onChange={(key) => {
            setStartReceiving(key);
          }}
          MenuCustomWidth={"100%"}
          error={errors.startReceiving}
          tooltipContent={TooltipContent.otherIncomeStartAge}
        />
        {incomeFrequency === "Annual" && (
          <DropDownButton
            Heading={"What age will you stop receiving this income"}
            placeholder={"Select"}
            dropDownItems={Select(currentAge)}
            value={stopReceiving}
            onChange={(key) => {
              setStopReceiving(key);
            }}
            MenuCustomWidth={"100%"}
            error={errors.stopReceiving}
            tooltipContent={TooltipContent.otherIncomeStopAge}
          />
        )}
      </Flex>
      <Flex justify="space-between" w="full" alignItems="flex-end">
        <NormalInputButton
          Heading="Annual growth rate"
          placeholder="%"
          value={annualGrowth}
          onChange={(e) => {
            const noSymbol = e.target.value.replace("%", "");
            const growthNum = parseFloat(noSymbol);
            setAnnualGrowth(
              !isNaN(growthNum) ? parseFloat(growthNum.toFixed(2)) : ""
            );
          }}
          customWidth={"100%"}
          usePercentage={true}
          error={errors.annualGrowth}
          tooltipContent={TooltipContent.otherIncomeGrowth}
        />
      </Flex>
      {renderAddUpdate(otherIncomeArray)}
    </Flex>
  );
}

function OtherSourceDetailsMobile({
  onCancel,
  incomeName,
  setIncomeName,
  incomeFrequency,
  setIncomeFrequency,
  incomeAmount,
  setIncomeAmount,
  startReceiving,
  setStartReceiving,
  stopReceiving,
  setStopReceiving,
  annualGrowth,
  setAnnualGrowth,
  currentAge,
  Select,
  renderAddUpdate,
  otherIncomeArray,
  moneyToNumber,
  errors,
}) {
  return (
    <Flex flexDirection={"column"} gap={"10px"} w={"100%"}>
      <Box
        display={"flex"}
        padding={"20px 10px"}
        flexDirection={"column"}
        alignItems={"flex-start"}
        gap={"10px"}
        borderRadius={"10px"}
        border={"1px solid #00A2D4"}
        bg={"rgba(0, 162, 212, 0.1)"}
      >
        <Text
          alignSelf={"stretch"}
          color={"#00A2D4"}
          textAlign={"center"}
          fontFamily={"Nunito"}
          fontSize={"14px"}
          fontStyle={"normal"}
          fontWeight={"700"}
          lineHeight={"normal"}
        >
          Business and Rental Property income to be input in “Assets” section
        </Text>
      </Box>
      <Flex justifyContent={"flex-end"}>
        <Button
          bg="#FFE5E5"
          w="73px"
          h="31px"
          align="flex-end"
          onClick={onCancel}
        >
          <Text
            color={"#E71D36"}
            fontFamily={"Nunito"}
            fontSize={"14px"}
            fontStyle={"normal"}
            fontWeight={"700"}
            lineHeight={"normal"}
          >
            Cancel
          </Text>
        </Button>
      </Flex>
      <NormalInputButton
        Heading={"Name/Title"}
        placeholder={""}
        customWidth={"100%"}
        InputWidth={"100%"}
        value={incomeName}
        onChange={(e) => {
          setIncomeName(e.target.value);
        }}
        error={errors.incomeName}
        tooltipContent={TooltipContent.otherIncomeName}
      />

      <Flex justify="space-between" w="full" alignItems="flex-end">
        <DropDownButton
          Heading={"Frequency of income"}
          placeholder={"Select"}
          dropDownItems={[
            { key: "1 time payment", value: "1 time payment" },
            { key: "Annual", value: "Annual" },
          ]}
          value={incomeFrequency}
          onChange={(key) => {
            setIncomeFrequency(key);
          }}
          MenuCustomWidth={"100%"}
          error={errors.incomeFrequency}
          tooltipContent={TooltipContent.otherIncomeFrequency}
        />
      </Flex>
      <Flex justify="space-between" w="full" alignItems="flex-end">
        <NormalInputButton
          Heading="Income amount"
          placeholder=""
          customWidth={"100%"}
          InputWidth={"100%"}
          value={incomeAmount}
          onChange={(e) => {
            setIncomeAmount(moneyToNumber(e.target.value));
          }}
          useThousandSeparator={true}
          error={errors.incomeAmount}
          tooltipContent={TooltipContent.otherIncomeAmount}
        />
      </Flex>
      <Flex justify="space-between" w="full" alignItems="flex-end">
        <DropDownButton
          Heading={"What age will you start receiving this income"}
          placeholder={"Select"}
          dropDownItems={Select(currentAge)}
          value={startReceiving}
          onChange={(key) => {
            setStartReceiving(key);
          }}
          MenuCustomWidth={"100%"}
          error={errors.startReceiving}
          tooltipContent={TooltipContent.otherIncomeStartAge}
        />
      </Flex>
      {incomeFrequency === "Annual" && (
        <Flex justify="space-between" w="full" alignItems="flex-end">
          <DropDownButton
            Heading={"What age will you stop receiving this income"}
            placeholder={"Select"}
            dropDownItems={Select(currentAge)}
            value={stopReceiving}
            onChange={(key) => {
              setStopReceiving(key);
            }}
            MenuCustomWidth={"100%"}
            error={errors.stopReceiving}
            tooltipContent={TooltipContent.otherIncomeStopAge}
          />
        </Flex>
      )}
      <Flex justify="space-between" w="full" alignItems="flex-end">
        <NormalInputButton
          Heading="Annual growth rate"
          placeholder="%"
          value={annualGrowth}
          onChange={(e) => {
            const noSymbol = e.target.value.replace("%", "");
            const growthNum = parseFloat(noSymbol);
            setAnnualGrowth(
              !isNaN(growthNum) ? parseFloat(growthNum.toFixed(2)) : ""
            );
          }}
          customWidth={"100%"}
          usePercentage={true}
          error={errors.annualGrowth}
          tooltipContent={TooltipContent.otherIncomeGrowth}
          InputWidth={"100%"}
        />
      </Flex>

      {renderAddUpdate(otherIncomeArray)}
    </Flex>
  );
}

export default OtherIncomePage;
