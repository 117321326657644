import { Flex, Heading, VStack, useMediaQuery, Box } from "@chakra-ui/react";
import { useAuth0 } from "@auth0/auth0-react";
import axios from "axios";
import React, {
  forwardRef,
  useContext,
  useEffect,
  useImperativeHandle,
  useState,
} from "react";
import { useStore } from "../../store";
import { SpouseContext } from "../SpouseContext";
import GlobalBodyPage from "../GlobalContainer/BodyPage";
import DropDownButton from "../InputFields/dropDownButton";
import NormalInputButton from "../InputFields/normalInputButton";
import TooltipContent from "../TooltipContent";

const LiraAccount = forwardRef((props, ref) => {
  const { setChangedData } = props;
  useImperativeHandle(ref, () => ({
    submitHandler,
    checkMandatoryFields,
  }));
  const { getAccessTokenSilently } = useAuth0();

  const axiosInstance = axios.create();
  async function getToken() {
    // Replace this with your Auth0 token retrieval logic
    const token = await getAccessTokenSilently({
      audience:
        "https://os2lsiwff43wfpb5ggyawt6qri0mgcro.lambda-url.us-east-2.on.aws/",
      scope: "write:optimization",
    });
    return token;
  }

  // Add a request interceptor
  axiosInstance.interceptors.request.use(
    async (config) => {
      const token = await getToken();
      config.headers.Authorization = `Bearer ${token}`;
      return config;
    },
    (error) => {
      // Do something with request error
      return Promise.reject(error);
    }
  );

  const userLiraInfo = useStore((store) => store.userLiraInfo);
  const setUserLiraInfo = useStore((store) => store.setUserLiraInfo);

  // Spouse 1 Data
  const [hasOtherNonReg, setHasOtherNonReg] = useState("");
  const [hasLif, setHasLif] = useState("");
  const [liraValue, setLiraValue] = useState(0);
  const [liraRateOfReturn, setLiraRateOfReturn] = useState();

  // Spouse 2 Data
  const [spouseHasOtherNonReg, setSpouseHasOtherNonReg] = useState("");
  const [spouseHasLif, setSpouseHasLif] = useState("");
  const [spouseLiraValue, setSpouseLiraValue] = useState(0);
  const [spouseLiraRateOfReturn, setSpouseLiraRateOfReturn] = useState();

  const [isLargerThan768] = useMediaQuery("(min-width: 500px)");
  const isMobile = !isLargerThan768;

  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [spouseName, setSpouseName] = useState("");
  const [spouseLastName, setSpouseLastName] = useState("");

  const [DOB, setDOB] = useState("");
  const { spouseStatus, setSpouseStatus } = useContext(SpouseContext);

  const userInfo = useStore((store) => store.userInfo);

  const [isEditMode, setIsEditMode] = useState(false);

  const userOptiData = useStore((store) => store.userOptiData);
  const [newUser, setNewUser] = useState();

  useEffect(() => {
    if (userOptiData?.primaryUserData?.expenses?.length > 1) {
      setNewUser(false);
    } else if (userOptiData?.primaryUserData?.expenses?.length === undefined) {
      setNewUser(true);
      setIsEditMode(true);
    }
  }, [userOptiData]);

  
  const [errors, setErrors] = useState({});
  const [spouseErrors, setSpouseErrors] = useState({});

  useEffect(() => {
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 100);
  }, [errors, spouseErrors]);

  // Function to toggle edit mode
  const toggleEditMode = () => {
    setIsEditMode(!isEditMode);
  };

  const { user, logout } = useAuth0();

  useEffect(() => {
    if (userLiraInfo !== null) {
      setHasOtherNonReg(userLiraInfo?.Do_You_LIRA);
      setHasLif(userLiraInfo?.Has_LIRA_LIF);
      setLiraValue(userLiraInfo?.SPS1_LIRA_VALUE);
      setLiraRateOfReturn(userLiraInfo?.LIRA_ROR);

      setSpouseHasOtherNonReg(userLiraInfo?.spouseDo_You_LIRA);
      setSpouseHasLif(userLiraInfo?.spouseHas_LIRA_LIF);
      setSpouseLiraValue(userLiraInfo?.SPS2_LIRA_VALUE);
      setSpouseLiraRateOfReturn(userLiraInfo?.spouseLIRA_ROR);
    }
    if (userInfo !== null) {
      setSpouseName(userInfo?.spouseFirstName);
      setSpouseLastName(userInfo?.spouseLastName);
      setFirstName(userInfo?.FirstName);
      setLastName(userInfo?.LastName);
    }
  }, []);

  const submitHandler = (e) => {
    // e.preventDefault();
    setIsEditMode(!isEditMode);
    let LiraValue = liraValue;
    let convertLIF = hasLif;
    let SpouseLiraValue = spouseLiraValue;
    let convertSpouseLif = spouseHasLif;
    if (hasOtherNonReg == "No") {
      LiraValue = 0;
      setLiraValue(0);
      convertLIF = "LIRA";
      setHasLif("LIRA");
    }
    if (spouseHasOtherNonReg == "No") {
      SpouseLiraValue = 0;
      setSpouseLiraValue(0);
      convertSpouseLif = "LIRA";
      setSpouseHasLif("LIRA");
    }
    // console.log('LIRA: ', user.sub, liraValue, hasOtherNonReg, hasLif, liraRateOfReturn);
    const liraURL = (action) =>
      `${
        process.env.REACT_APP_NODE_API_GATEWAY
      }/CompassLambdaApi/LIRA/${action}?LIRAID=${user.sub.replace(
        "|",
        "%7c"
      )}&SPS1_LIRA_VALUE=${LiraValue}&Do_You_LIRA=${hasOtherNonReg}&Has_LIRA_LIF=${convertLIF}&LIRA_ROR=${liraRateOfReturn}&SPS2_LIRA_VALUE=${SpouseLiraValue}&spouseDo_You_LIRA=${spouseHasOtherNonReg}&spouseHas_LIRA_LIF=${convertSpouseLif}&spouseLIRA_ROR=${spouseLiraRateOfReturn}`;
    axiosInstance
      .get(liraURL("add"))
      .then((res) => {
        setUserLiraInfo({
          ...userLiraInfo,
          SPS1_LIRA_VALUE: LiraValue,
          Do_You_LIRA: hasOtherNonReg,
          Has_LIRA_LIF: convertLIF,
          LIRA_ROR: liraRateOfReturn,
          SPS2_LIRA_VALUE: SpouseLiraValue,
          spouseDo_You_LIRA: spouseHasOtherNonReg,
          spouseHas_LIRA_LIF: convertSpouseLif,
          spouseLIRA_ROR: spouseLiraRateOfReturn,
        });
        if (res.data.code === "ER_DUP_ENTRY") {
          axiosInstance.get(liraURL("update")).then((res) => {
            console.log(res);
            setUserLiraInfo({
              ...userLiraInfo,
              SPS1_LIRA_VALUE: LiraValue,
              Do_You_LIRA: hasOtherNonReg,
              Has_LIRA_LIF: convertLIF,
              LIRA_ROR: liraRateOfReturn,
              SPS2_LIRA_VALUE: SpouseLiraValue,
              spouseDo_You_LIRA: spouseHasOtherNonReg,
              spouseHas_LIRA_LIF: convertSpouseLif,
              spouseLIRA_ROR: spouseLiraRateOfReturn,
            });
          });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const checkMandatoryFields = () => {
    let newErrors = {};
    let newSpouseErrors = {};

    // Individual checks

    if (!hasOtherNonReg || hasOtherNonReg === "")
      newErrors.hasOtherNonReg = "LIRA status is required";
    if (hasOtherNonReg === "Locked-In Retirement Account (LIRA)") {
      if (!liraValue) newErrors.liraValue = "LIRA value is required";
      if (!hasLif) newErrors.hasLif = "LIF status is required";
      if (!liraRateOfReturn || liraRateOfReturn > 20)
        newErrors.liraRateOfReturn =
          "Rate of return is required and must be greater than 0% and less than 20%"
    }

    // Spouse checks
    if (spouseStatus) {
      if (!spouseHasOtherNonReg || spouseHasOtherNonReg === "")
        newSpouseErrors.hasOtherNonReg = "LIRA status is required";
      if (spouseHasOtherNonReg === "Locked-In Retirement Account (LIRA)") {
        if (!spouseLiraValue)
          newSpouseErrors.liraValue = "LIRA value is required";
        if (!spouseHasLif) newSpouseErrors.hasLif = "LIF status is required";
        if (!spouseLiraRateOfReturn || spouseLiraRateOfReturn > 20)
          newSpouseErrors.liraRateOfReturn =
            "Rate of return is required and must be greater than 0% and less than 20%"
      }
    }

    setSpouseErrors(newSpouseErrors);
    setErrors(newErrors);
    return (
      Object.keys(newErrors).length === 0 &&
      Object.keys(newSpouseErrors).length === 0
    ); // Return true if no errors
  };

  const moneyToNumber = (value) => {
    let numValue = value.replace(/[$,\s]/g, "");
    numValue = Number(numValue);
    return numValue;
  };

  return (
    <Flex
      display="flex"
      flexWrap="wrap" // Enable wrapping
      justifyContent="flex-start"
      alignItems="start"
      width={"100%"}
    >
      {!isMobile && (
        <VStack alignItems={"flex-start"} width={"100%"} mt={-2}>
          <GlobalBodyPage
            Heading="Locked In Retirement Account (LIRA)"
            mainframeContent={
              <>
                <Flex justify="space-between" w="100%">
                  <Heading
                    height={"27px"}
                    alignSelf={"stretch"}
                    color={"#00466D"}
                    fontFamily={"Nunito"}
                    fontSize={"20px"}
                    fontStyle={"normal"}
                    fontWeight={"700"}
                    lineHeight={"normal"}
                  >
                    {firstName} {lastName}
                  </Heading>
                </Flex>
                <Box
                  display={"flex"}
                  width={"100%"}
                  flexDirection={"row"}
                  paddingTop={"10px"}
                  alignItems={"flex-end"}
                  justifyContent={"space-between"}
                  gap={"10px"}
                  flexWrap={"wrap"}
                >
                  <DropDownButton
                    Heading={"Do you have a LIRA/LIF?"}
                    placeholder={"Select"}
                    dropDownItems={[
                      {
                        key: "Locked-In Retirement Account (LIRA)",
                        value: "Yes",
                      },
                      { key: "No", value: "No" },
                    ]}
                    value={hasOtherNonReg}
                    onChange={(key) => {
                      setChangedData(true);
                      setHasOtherNonReg(key);
                    }}
                    customWidth={"100%"}
                    error={errors.hasOtherNonReg}
                    tooltipContent={TooltipContent.liraDoYouYesNo}
                  />
                  {hasOtherNonReg === "Locked-In Retirement Account (LIRA)" && (
                    <DropDownButton
                      Heading={"Has your LIRA been converted to a LIF?"}
                      placeholder={"Select"}
                      dropDownItems={[
                        { key: "LIF", value: "Yes" },
                        { key: "LIRA", value: "No" },
                      ]}
                      value={hasLif}
                      onChange={(key) => {
                        setChangedData(true);
                        setHasLif(key);
                      }}
                      customWidth={"100%"}
                      error={errors.hasLif}
                      tooltipContent={TooltipContent.liraLifYesNo}
                    />
                  )}
                  {hasOtherNonReg === "Locked-In Retirement Account (LIRA)" &&
                    hasLif === "LIF" && (
                      <RrspConverted
                        spouseCurrentValue={liraValue}
                        setSpouseCurrentValue={setLiraValue}
                        spouseAnnualReturn={liraRateOfReturn}
                        setSpouseAnnualReturn={setLiraRateOfReturn}
                        moneyToNumber={moneyToNumber}
                        errors={errors}
                        setChangedData={setChangedData}
                      />
                    )}

                  {hasOtherNonReg === "Locked-In Retirement Account (LIRA)" &&
                    hasLif === "LIRA" && (
                      <RrspNotConverted
                        spouseCurrentValue={liraValue}
                        setSpouseCurrentValue={setLiraValue}
                        spouseAnnualReturn={liraRateOfReturn}
                        setSpouseAnnualReturn={setLiraRateOfReturn}
                        moneyToNumber={moneyToNumber}
                        errors={errors}
                        setChangedData={setChangedData}
                      />
                    )}
                </Box>
              </>
            }
            videoName={"LIRA Page"}
            video={"Other_registered_accounts.mp4"}
          />
          {spouseStatus && (
            <GlobalBodyPage
              mainframeContent={
                <VStack spacing={4} alignItems={"flex-start"} width={"100%"}>
                  <Flex justify="space-between" w="100%">
                    <Heading
                      height={"27px"}
                      alignSelf={"stretch"}
                      color={"#00466D"}
                      fontFamily={"Nunito"}
                      fontSize={"20px"}
                      fontStyle={"normal"}
                      fontWeight={"700"}
                      lineHeight={"normal"}
                    >
                      {spouseName} {spouseLastName}
                    </Heading>
                  </Flex>
                  <DropDownButton
                    Heading={"Do you have a LIRA/LIF?"}
                    placeholder={"Select"}
                    dropDownItems={[
                      {
                        key: "Locked-In Retirement Account (LIRA)",
                        value: "Yes",
                      },
                      { key: "No", value: "No" },
                    ]}
                    value={spouseHasOtherNonReg}
                    onChange={(key) => {
                      setChangedData(true);
                      setSpouseHasOtherNonReg(key);
                    }}
                    customWidth={"100%"}
                    error={spouseErrors.hasOtherNonReg}
                    tooltipContent={TooltipContent.liraDoYouYesNo}
                  />
                  {spouseHasOtherNonReg ===
                    "Locked-In Retirement Account (LIRA)" && (
                    <>
                      <DropDownButton
                        Heading={"Has your LIRA been converted to a LIF?"}
                        placeholder={"Select"}
                        dropDownItems={[
                          { key: "LIF", value: "Yes" },
                          { key: "LIRA", value: "No" },
                        ]}
                        value={spouseHasLif}
                        onChange={(key) => {
                          setChangedData(true);
                          setSpouseHasLif(key);
                        }}
                        customWidth={"100%"}
                        error={spouseErrors.hasLif}
                        tooltipContent={TooltipContent.liraLifYesNo}
                      />
                      {spouseHasOtherNonReg ===
                        "Locked-In Retirement Account (LIRA)" &&
                        spouseHasLif === "LIF" && (
                          <RrspConverted
                            spouseCurrentValue={spouseLiraValue}
                            setSpouseCurrentValue={setSpouseLiraValue}
                            spouseAnnualReturn={spouseLiraRateOfReturn}
                            setSpouseAnnualReturn={setSpouseLiraRateOfReturn}
                            moneyToNumber={moneyToNumber}
                            errors={spouseErrors}
                            setChangedData={setChangedData}
                          />
                        )}
                      {spouseHasOtherNonReg ===
                        "Locked-In Retirement Account (LIRA)" &&
                        spouseHasLif === "LIRA" && (
                          <RrspNotConverted
                            spouseCurrentValue={spouseLiraValue}
                            setSpouseCurrentValue={setSpouseLiraValue}
                            spouseAnnualReturn={spouseLiraRateOfReturn}
                            setSpouseAnnualReturn={setSpouseLiraRateOfReturn}
                            moneyToNumber={moneyToNumber}
                            errors={spouseErrors}
                            setChangedData={setChangedData}
                          />
                        )}
                    </>
                  )}
                </VStack>
              }
            />
          )}
        </VStack>
      )}
      {isMobile && (
        <VStack alignItems={"flex-start"} width={"100%"}>
          <GlobalBodyPage
            Heading="Locked In Retirement Account (LIRA)"
            mainframeContent={
              <>
                <Flex justify="space-between" w="100%">
                  <Heading
                    height={"27px"}
                    alignSelf={"stretch"}
                    color={"#00466D"}
                    fontFamily={"Nunito"}
                    fontSize={"20px"}
                    fontStyle={"normal"}
                    fontWeight={"700"}
                    lineHeight={"normal"}
                  >
                    {firstName} {lastName}
                  </Heading>
                </Flex>
                <Box
                  display={"flex"}
                  width={"100%"}
                  flexDirection={"row"}
                  alignItems={"flex-end"}
                  justifyContent={"space-between"}
                  gap={"10px"}
                  flexWrap={"wrap"}
                >
                  <DropDownButton
                    Heading={"Do you have a LIRA/LIF?"}
                    placeholder={"Select"}
                    dropDownItems={[
                      {
                        key: "Locked-In Retirement Account (LIRA)",
                        value: "Yes",
                      },
                      { key: "No", value: "No" },
                    ]}
                    value={hasOtherNonReg}
                    onChange={(key) => {
                      setChangedData(true);
                      setHasOtherNonReg(key);
                    }}
                    error={errors.hasOtherNonReg}
                    customWidth={"100%"}
                    MenuCustomWidth={"100%"}
                    tooltipContent={TooltipContent.liraDoYouYesNo}
                  />
                  {hasOtherNonReg === "Locked-In Retirement Account (LIRA)" && (
                    <DropDownButton
                      Heading={"Has your LIRA been converted to a LIF?"}
                      placeholder={"Select"}
                      dropDownItems={[
                        { key: "LIF", value: "Yes" },
                        { key: "LIRA", value: "No" },
                      ]}
                      value={hasLif}
                      onChange={(key) => {
                        setChangedData(true);
                        setHasLif(key);
                      }}
                      MenuCustomWidth={"100%"}
                      error={errors.hasLif}
                      tooltipContent={TooltipContent.liraLifYesNo}
                    />
                  )}
                  {hasOtherNonReg === "Locked-In Retirement Account (LIRA)" &&
                    hasLif === "LIF" && (
                      <RrspConverted
                        spouseCurrentValue={liraValue}
                        setSpouseCurrentValue={setLiraValue}
                        spouseAnnualReturn={liraRateOfReturn}
                        setSpouseAnnualReturn={setLiraRateOfReturn}
                        moneyToNumber={moneyToNumber}
                        errors={errors}
                        setChangedData={setChangedData}
                      />
                    )}

                  {hasOtherNonReg === "Locked-In Retirement Account (LIRA)" &&
                    hasLif === "LIRA" && (
                      <RrspNotConverted
                        spouseCurrentValue={liraValue}
                        setSpouseCurrentValue={setLiraValue}
                        spouseAnnualReturn={liraRateOfReturn}
                        setSpouseAnnualReturn={setLiraRateOfReturn}
                        moneyToNumber={moneyToNumber}
                        errors={errors}
                        setChangedData={setChangedData}
                      />
                    )}
                </Box>
              </>
            }
            videoName={"LIRA Page"}
            video={"Other_registered_accounts.mp4"}
          />
          {spouseStatus && (
            <GlobalBodyPage
              mainframeContent={
                <VStack spacing={4} alignItems={"flex-start"} width={"100%"}>
                  <Flex justify="space-between" w="100%">
                    <Heading
                      height={"27px"}
                      alignSelf={"stretch"}
                      color={"#00466D"}
                      fontFamily={"Nunito"}
                      fontSize={"20px"}
                      fontStyle={"normal"}
                      fontWeight={"700"}
                      lineHeight={"normal"}
                    >
                      {spouseName} {spouseLastName}
                    </Heading>
                  </Flex>
                  <DropDownButton
                    Heading={"Do you have a LIRA/LIF?"}
                    placeholder={"Select"}
                    dropDownItems={[
                      {
                        key: "Locked-In Retirement Account (LIRA)",
                        value: "Yes",
                      },
                      { key: "No", value: "No" },
                    ]}
                    value={spouseHasOtherNonReg}
                    onChange={(key) => {
                      setChangedData(true);
                      setSpouseHasOtherNonReg(key);
                    }}
                    error={spouseErrors.hasOtherNonReg}
                    customWidth={"100%"}
                    MenuCustomWidth={"100%"}
                    tooltipContent={TooltipContent.liraDoYouYesNo}
                  />
                  {spouseHasOtherNonReg ===
                    "Locked-In Retirement Account (LIRA)" && (
                    <VStack>
                      <DropDownButton
                        Heading={"Has your LIRA been converted to a LIF?"}
                        placeholder={"Select"}
                        dropDownItems={[
                          { key: "LIF", value: "Yes" },
                          { key: "LIRA", value: "No" },
                        ]}
                        value={spouseHasLif}
                        onChange={(key) => {
                          setChangedData(true);
                          setSpouseHasLif(key);
                        }}
                        customWidth={"100%"}
                        MenuCustomWidth={"100%"}
                        error={spouseErrors.hasLif}
                        tooltipContent={TooltipContent.liraLifYesNo}
                      />
                      {spouseHasOtherNonReg ===
                        "Locked-In Retirement Account (LIRA)" &&
                        spouseHasLif === "LIF" && (
                          <RrspConverted
                            spouseCurrentValue={spouseLiraValue}
                            setSpouseCurrentValue={setSpouseLiraValue}
                            spouseAnnualReturn={spouseLiraRateOfReturn}
                            setSpouseAnnualReturn={setSpouseLiraRateOfReturn}
                            moneyToNumber={moneyToNumber}
                            errors={spouseErrors}
                            setChangedData={setChangedData}
                          />
                        )}
                      {spouseHasOtherNonReg ===
                        "Locked-In Retirement Account (LIRA)" &&
                        spouseHasLif === "LIRA" && (
                          <RrspNotConverted
                            spouseCurrentValue={spouseLiraValue}
                            setSpouseCurrentValue={setSpouseLiraValue}
                            spouseAnnualReturn={spouseLiraRateOfReturn}
                            setSpouseAnnualReturn={setSpouseLiraRateOfReturn}
                            moneyToNumber={moneyToNumber}
                            errors={spouseErrors}
                            setChangedData={setChangedData}
                          />
                        )}
                    </VStack>
                  )}
                </VStack>
              }
            />
          )}
        </VStack>
      )}
    </Flex>
  );
});

function RrspConverted({
  spouseCurrentValue,
  setSpouseCurrentValue,
  spouseAnnualReturn,
  setSpouseAnnualReturn,
  moneyToNumber,
  errors,
  setChangedData,
}) {
  const [isLargerThan768] = useMediaQuery("(min-width: 500px)");
  const isMobile = !isLargerThan768;
  return (
    <>
      {isMobile && (
        <>
          <NormalInputButton
            Heading={"What is the current total value?"}
            placeholder={"$"}
            value={spouseCurrentValue}
            onChange={(e) => {
              setChangedData(true);
              setSpouseCurrentValue(moneyToNumber(e.target.value));
            }}
            customWidth={"100%"}
            useThousandSeparator={true}
            InputWidth={"100%"}
            error={errors.liraValue}
            tooltipContent={TooltipContent.liraLifValue}
          />
          <NormalInputButton
            Heading={"Expected annual rate of return?"}
            placeholder={"%"}
            value={spouseAnnualReturn}
            onChange={(e) => {
              setChangedData(true);
              const noSymbol = e.target.value.replace("%", "");
              const growthNum = parseFloat(noSymbol);
              setSpouseAnnualReturn(
                !isNaN(growthNum) ? parseFloat(growthNum.toFixed(2)) : ""
              );
            }}
            customWidth={"100%"}
            usePercentage={true}
            InputWidth={"100%"}
            error={errors.liraRateOfReturn}
            tooltipContent={TooltipContent.liraLifGrowth}
          />
        </>
      )}
      {!isMobile && (
        <>
          <NormalInputButton
            Heading={"What is the current total value?"}
            placeholder={"$"}
            value={spouseCurrentValue}
            onChange={(e) => {
              setChangedData(true);
              setSpouseCurrentValue(moneyToNumber(e.target.value));
            }}
            customWidth={"100%"}
            useThousandSeparator={true}
            error={errors.liraValue}
            tooltipContent={TooltipContent.liraLifValue}
          />
          <NormalInputButton
            Heading={"Expected annual rate of return?"}
            placeholder={"%"}
            value={spouseAnnualReturn}
            onChange={(e) => {
              setChangedData(true);
              const noSymbol = e.target.value.replace("%", "");
              const growthNum = parseFloat(noSymbol);
              setSpouseAnnualReturn(
                !isNaN(growthNum) ? parseFloat(growthNum.toFixed(2)) : ""
              );
            }}
            usePercentage={true}
            customWidth={"100%"}
            error={errors.liraRateOfReturn}
            tooltipContent={TooltipContent.liraLifGrowth}
          />
        </>
      )}
    </>
  );
}

function RrspNotConverted({
  spouseCurrentValue,
  setSpouseCurrentValue,
  spouseAnnualReturn,
  setSpouseAnnualReturn,
  moneyToNumber,
  errors,
  setChangedData,
}) {
  const [isLargerThan768] = useMediaQuery("(min-width: 500px)");
  const isMobile = !isLargerThan768;
  return (
    <Flex
      display="flex"
      flexWrap="wrap"
      justifyContent="flex-start"
      alignItems="start"
      width={"100%"}
      gap={"10px"}
    >
      {isMobile && (
        <>
          <NormalInputButton
            Heading={"What is the current total value?"}
            placeholder={"$"}
            value={spouseCurrentValue}
            onChange={(e) => {
              setChangedData(true);
              setSpouseCurrentValue(moneyToNumber(e.target.value));
            }}
            customWidth={"100%"}
            useThousandSeparator={true}
            InputWidth={"100%"}
            error={errors.liraValue}
            tooltipContent={TooltipContent.liraLifValue}
          />
          <NormalInputButton
            Heading={"Expected annual rate of return?"}
            placeholder={"%"}
            value={spouseAnnualReturn}
            onChange={(e) => {
              setChangedData(true);
              const noSymbol = e.target.value.replace("%", "");
              const growthNum = parseFloat(noSymbol);
              setSpouseAnnualReturn(
                !isNaN(growthNum) ? parseFloat(growthNum.toFixed(2)) : ""
              );
            }}
            customWidth={"100%"}
            usePercentage={true}
            InputWidth={"100%"}
            error={errors.liraRateOfReturn}
            tooltipContent={TooltipContent.liraLifGrowth}
          />
        </>
      )}
      {!isMobile && (
        <>
          <NormalInputButton
            Heading={"What is the current total value?"}
            placeholder={"$"}
            value={spouseCurrentValue}
            onChange={(e) => {
              setChangedData(true);
              setSpouseCurrentValue(moneyToNumber(e.target.value));
            }}
            customWidth={"100%"}
            useThousandSeparator={true}
            error={errors.liraValue}
            tooltipContent={TooltipContent.liraLifValue}
          />
          <NormalInputButton
            Heading={"Expected annual rate of return?"}
            placeholder={"%"}
            value={spouseAnnualReturn}
            onChange={(e) => {
              setChangedData(true);
              const noSymbol = e.target.value.replace("%", "");
              const growthNum = parseFloat(noSymbol);
              setSpouseAnnualReturn(
                !isNaN(growthNum) ? parseFloat(growthNum.toFixed(2)) : ""
              );
            }}
            customWidth={"100%"}
            usePercentage={true}
            error={errors.liraRateOfReturn}
            tooltipContent={TooltipContent.liraLifGrowth}
          />
        </>
      )}
    </Flex>
  );
}
export default LiraAccount;
