import ReactEcharts from "echarts-for-react";
import React, { useEffect, useState } from "react";
import FilterIcon from "../../img/filter-icon.png";
import { icon } from "@fortawesome/fontawesome-svg-core";

const currencyFormat = (money) => {
  if (money === 0) {
    return "$0"; // Handle zero explicitly
  } else if (money === "") {
    return "";
  } else {
    const intTotal = Math.round(Math.round(money * 100) / 100);
    const currencyCad = new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD",
      minimumFractionDigits: 0,
    }).format(intTotal);
    return currencyCad;
  }
};

function LineGraph(props) {
  const {
    title,
    tfsaData,
    fhsaData,
    rrspData,
    pensionData,
    liraData,
    nonRegData,
    cashData,
    fixedIncomeData,
    realEstateData,
    lifeInsuranceData,
    businessData,
    businessInvestmentData,
    otherAssetsData,
    totalPortfolioData,
    graphClicked,
    currentAge,
    spouseAge,
    investmentPage,
  } = props;
  const xAxis = tfsaData?.map((item) => item.x);
  const currentYear = new Date().getFullYear();
  const xAxisWithAge = xAxis?.map((year) => {
    const age = currentAge + (year - currentYear);
    const spouse = spouseAge ? spouseAge + (year - currentYear) : "";
    return spouseAge ? `${year}\n(${age} | ${spouse})` : `${year}\n(${age})`;
  });
  const yAxisTFSA = tfsaData?.map((item) => item.y);
  const yAxisFHSA = fhsaData?.map((item) => item.y);
  const yAxisRRSP = rrspData?.map((item) => item.y);
  const yAxisPension = pensionData?.map((item) => item.y);
  const yAxisLIRA = liraData?.map((item) => item.y);
  const yAxisNonReg = nonRegData?.map((item) => item.y);
  const yAxisCash = cashData?.map((item) => item.y);
  const yAxisFixedIncome = fixedIncomeData?.map((item) => item.y);
  const yAxisRealEstate = realEstateData?.map((item) => item.y);
  const yAxisLifeInsurance = lifeInsuranceData?.map((item) => item.y);
  const yAxisBusiness = businessData?.map((item) => item.y);
  const yAxisBusinessInvestments = businessInvestmentData?.map(
    (item) => item.y
  );
  const yAxisOtherAssets = otherAssetsData?.map((item) => item.y);
  const yAxisTotalPortfolio = totalPortfolioData?.map((item) => item.y);

  const [showDropdown, setShowDropdown] = useState(false);
  const [showAll, setShowAll] = useState(true);
  const [showTFSA, setShowTFSA] = useState(false);
  const [showFHSA, setShowFHSA] = useState(false);
  const [showRRSP, setShowRRSP] = useState(false);
  const [showPension, setShowPension] = useState(false);
  const [showLIRA, setShowLIRA] = useState(false);
  const [showNonReg, setShowNonReg] = useState(false);
  const [showCash, setShowCash] = useState(false);
  const [showFixedIncome, setShowFixedIncome] = useState(false);
  const [showRealEstate, setShowRealEstate] = useState(false);
  const [showLifeInsurance, setShowLifeInsurance] = useState(false);
  const [showBusiness, setShowBusiness] = useState(false);
  const [showBusinessInvestments, setShowBusinessInvestments] = useState(false);
  const [showOtherAssets, setShowOtherAssets] = useState(false);
  const [showTotal, setShowTotal] = useState(false);
  const [colorScheme, setColorScheme] = useState([
    "#73B879", // Example color for another data type
    "#30AB9D", // Example color for another data type
    "#00A2D4", // Example color for another data type
    "#007EB5", // Example color for another data type
    "#00466D", // Example color for another data type
    "#00304A", // Example color for another data type
    "#001A2A", // Example color for another data type
    "#147984", // Assumed color for "Business"
    "#c45850", // Newly added distinct color for "CCPC Investments"
    "#a6d854", // Assumed color for another data type
    "green", // Example color for another data type
  ]);

  useEffect(() => {
    selectGraph(graphClicked);
  }, [graphClicked]);

  useEffect(() => {
    let newColors = [];
    if (showTFSA) newColors.push("#73B879");
    if (showFHSA) newColors.push("purple");
    if (showRRSP) newColors.push("#30AB9D");
    if (showPension) newColors.push("#00A2D4");
    if (showLIRA) newColors.push("#007EB5");
    if (showNonReg) newColors.push("#00466D");
    if (showCash) newColors.push("green");
    if (showFixedIncome) newColors.push("green");
    if (showRealEstate) newColors.push("#00304A");
    if (showLifeInsurance) newColors.push("#001A2A");
    if (showBusiness) newColors.push("#147984"); // Distinct color for Business
    if (showBusinessInvestments) newColors.push("#c45850"); // Distinct color for CCPC Investments
    if (showOtherAssets) newColors.push("#06426F");
    if (showTotal) newColors.push("#00A2D4");
    if (showAll)
      newColors = [
        "#73B879",
        "#30AB9D",
        "#00A2D4",
        "#007EB5",
        "#00466D",
        "#00304A",
        "#001A2A",
        "#147984",
        "#c45850",
        "#06426F",
        "green",
        "purple",
      ];

    setColorScheme(newColors);
  }, [
    showTFSA,
    showFHSA,
    showRRSP,
    showPension,
    showLIRA,
    showNonReg,
    showCash,
    showFixedIncome,
    showRealEstate,
    showLifeInsurance,
    showBusiness,
    showBusinessInvestments,
    showOtherAssets,
    showTotal,
    showAll,
  ]);

  function numFormatter(num) {
    if (num > 999 && num < 10000) {
      return "$" + (num / 1000).toFixed(1) + "K";
    } else if (num > 9999 && num < 1000000) {
      return "$" + (num / 1000).toFixed(0) + "K";
    } else if (num > 1000000) {
      return "$" + (num / 1000000).toFixed(1) + "M";
    } else if (num < 900) {
      return "$" + num;
    }
  }

  const headers = [
    { name: "TFSA", show: tfsaData?.some((obj) => obj.y !== 0) },
    { name: "FHSA", show: fhsaData?.some((obj) => obj.y !== 0) },
    { name: "RRSP/RRIF", show: rrspData?.some((obj) => obj.y !== 0) },
    { name: "Pension", show: pensionData?.some((obj) => obj.y !== 0) },
    { name: "LIRA", show: liraData?.some((obj) => obj.y !== 0) },
    { name: "Non-Registered", show: nonRegData?.some((obj) => obj.y !== 0) },
    { name: "Cash", show: cashData?.some((obj) => obj.y !== 0) },
    { name: "Fixed Income", show: fixedIncomeData?.some((obj) => obj.y !== 0) },
    { name: "Real Estate", show: realEstateData?.some((obj) => obj.y !== 0) },
    {
      name: "Life Insurance",
      show: lifeInsuranceData?.some((obj) => obj.y !== 0),
    },
    { name: "Business", show: businessData?.some((obj) => obj.y !== 0) },
    {
      name: "CCPC Investments",
      show: businessInvestmentData?.some((obj) => obj.y !== 0),
    },
    {
      name: "Personal Assets",
      show: otherAssetsData?.some((obj) => obj.y !== 0),
    },
    { name: "Total", show: totalPortfolioData?.some((obj) => obj.y !== 0) },
  ];

  const dataToRender = headers
    .filter((header) => header.show)
    ?.map((header) => header.name);

  const topValue =
    window.innerWidth > 1280 && window.innerWidth <= 1723
      ? 100
      : window.innerWidth > 1200 && window.innerWidth <= 1280
      ? 100
      : window.innerWidth > 500 && window.innerWidth <= 1200
      ? 100
      : window.innerWidth <= 480
      ? 50
      : 70;

  const option = {
    color: colorScheme,
    title: {
      text: window.innerWidth <= 500 ? "Overview" : title,
      left: "center",
      top: 0,
      textStyle: {
        lineHeight: window.innerWidth <= 480 && !showAll ? -30 : null,
      },
    },
    tooltip: {
      trigger: "axis",
      left: 1,
      right: 1,
      axisPointer: {
        type: "shadow", // Use 'line' for X-axis
      },
      formatter: function (params) {
        let result = params[0].axisValueLabel + "<br/>";
        params.forEach(function (item) {
          result +=
            item.marker +
            " " +
            item.seriesName +
            ": " +
            currencyFormat(item.data) +
            "<br/>";
        });
        return result;
      },
    },
    legend: {
      data: dataToRender,
      selectedMode: false,
      top: 35,
      bottom: 100,
      textStyle: {
        fontSize: 10, // Adjust the font size as needed
        color: "#004069",
      },
      show: window.innerWidth <= 480 && showAll ? false : true,
    },
    toolbox: {
      feature: {
        saveAsImage: {
          iconStyle: {
            borderColor: "#004069",
          },
        },
      },
    },
    grid: {
      left: 3,
      top: investmentPage
        ? topValue
        : window.innerWidth > 1200 && window.innerWidth <= 1280
        ? 100
        : window.innerWidth > 500 && window.innerWidth <= 1200
        ? 100
        : window.innerWidth <= 480
        ? 50
        : 70,
      right: 3,
      bottom: "0%",
      containLabel: true,
    },
    xAxis: [
      {
        type: "category",
        boundaryGap: ["20%", "20%"],
        data: xAxisWithAge ? xAxisWithAge : [],
        axisLabel: {
          formatter: function (value) {
            return value.split("\n").join("\n"); // Ensure line breaks are rendered
          },
        },
      },
    ],
    yAxis: [
      {
        type: "value",
        axisLabel: {
          formatter: function (value) {
            return numFormatter(value);
          },
        },
      },
    ],
    series: [
      (showTFSA || showAll) &&
        tfsaData?.some((obj) => obj.y !== 0) && {
          name: "TFSA",
          type: "bar",
          itemStyle: {
            borderRadius: !showAll ? [5, 5, 0, 0] : "", // Apply rounding to the top-left and top-right corners
            // This sets the top corners' radius to 5 (creating a rounded effect)
            // and leaves the bottom corners squared (radius 0).
          },
          stack: "Total",
          smooth: true,
          areaStyle: {
            color: {
              type: "linear",
              x: 0,
              y: 0,
              x2: 0,
              y2: 1,
              colorStops: [
                {
                  offset: 0,
                  color: "#73B879", // color at 0%
                },
                {
                  offset: 1,
                  color: "#73B879", // color at 100%
                },
              ],
              global: false, // default is false
            },
          },
          emphasis: {
            focus: "series",
          },
          data: yAxisTFSA?.map((v) => Math.max(0, v)),
        },
      (showFHSA || showAll) &&
        fhsaData?.some((obj) => obj.y !== 0) && {
          name: "FHSA",
          type: "bar",
          itemStyle: {
            borderRadius: !showAll ? [5, 5, 0, 0] : "", // Apply rounding to the top-left and top-right corners
            // This sets the top corners' radius to 5 (creating a rounded effect)
            // and leaves the bottom corners squared (radius 0).
          },
          stack: "Total",
          smooth: true,
          areaStyle: {
            color: {
              type: "linear",
              x: 0,
              y: 0,
              x2: 0,
              y2: 1,
              colorStops: [
                {
                  offset: 0,
                  color: "purple", // color at 0%
                },
                {
                  offset: 1,
                  color: "purple", // color at 100%
                },
              ],
              global: false, // default is false
            },
          },
          emphasis: {
            focus: "series",
          },
          data: yAxisFHSA?.map((v) => Math.max(0, v)),
        },
      (showRRSP || showAll) &&
        rrspData?.some((obj) => obj.y !== 0) && {
          name: "RRSP/RRIF",
          type: "bar",
          itemStyle: {
            borderRadius: !showAll ? [5, 5, 0, 0] : "", // Apply rounding to the top-left and top-right corners
            // This sets the top corners' radius to 5 (creating a rounded effect)
            // and leaves the bottom corners squared (radius 0).
          },
          stack: "Total",
          // barWidth: '30%',
          smooth: true,
          areaStyle: {
            color: {
              type: "linear",
              x: 0,
              y: 0,
              x2: 0,
              y2: 1,
              colorStops: [
                {
                  offset: 0,
                  color: "#30AB9D", // color at 0%
                },
                {
                  offset: 1,
                  color: "#30AB9D", // color at 100%
                },
              ],
              global: false, // default is false
            },
          },
          emphasis: {
            focus: "series",
          },
          data: yAxisRRSP?.map((v) => Math.max(0, v)),
        },
      (showPension || showAll) &&
        pensionData?.some((obj) => obj.y !== 0) && {
          name: "Pension",
          type: "bar",
          itemStyle: {
            borderRadius: !showAll ? [5, 5, 0, 0] : "", // Apply rounding to the top-left and top-right corners
            // This sets the top corners' radius to 5 (creating a rounded effect)
            // and leaves the bottom corners squared (radius 0).
          },
          stack: "Total",
          // barWidth: '30%',
          smooth: true,
          areaStyle: {
            color: {
              type: "linear",
              x: 0,
              y: 0,
              x2: 0,
              y2: 1,
              colorStops: [
                {
                  offset: 0,
                  color: "#00A2D4", // color at 0%
                },
                {
                  offset: 1,
                  color: "#00A2D4", // color at 100%
                },
              ],
              global: false, // default is false
            },
          },
          emphasis: {
            focus: "series",
          },
          data: yAxisPension?.map((v) => Math.max(0, v)),
        },
      (showLIRA || showAll) &&
        liraData?.some((obj) => obj.y !== 0) && {
          name: "LIRA",
          type: "bar",
          itemStyle: {
            borderRadius: !showAll ? [5, 5, 0, 0] : "", // Apply rounding to the top-left and top-right corners
            // This sets the top corners' radius to 5 (creating a rounded effect)
            // and leaves the bottom corners squared (radius 0).
          },
          stack: "Total",
          // barWidth: '30%',
          smooth: true,
          areaStyle: {
            color: {
              type: "linear",
              x: 0,
              y: 0,
              x2: 0,
              y2: 1,
              colorStops: [
                {
                  offset: 0,
                  color: "#007EB5", // color at 0%
                },
                {
                  offset: 1,
                  color: "#007EB5", // color at 100%
                },
              ],
              global: false, // default is false
            },
          },
          emphasis: {
            focus: "series",
          },
          data: yAxisLIRA?.map((v) => Math.max(0, v)),
        },
      (showNonReg || showAll) &&
        nonRegData?.some((obj) => obj.y !== 0) && {
          name: "Non-Registered",
          type: "bar",
          itemStyle: {
            borderRadius: !showAll ? [8, 8, 0, 0] : 0, // Apply rounding to the top-left and top-right corners
            // This sets the top corners' radius to 5 (creating a rounded effect)
            // and leaves the bottom corners squared (radius 0).
          },
          stack: "Total",
          // barWidth: '30%',
          smooth: true,
          areaStyle: {
            color: {
              type: "linear",
              x: 0,
              y: 0,
              x2: 0,
              y2: 1,
              colorStops: [
                {
                  offset: 0,
                  color: "#00466D", // color at 0%
                },
                {
                  offset: 1,
                  color: "#00466D", // color at 100%
                },
              ],
              global: false, // default is false
            },
          },
          emphasis: {
            focus: "series",
          },
          data: yAxisNonReg?.map((v) => Math.max(0, v)),
        },
      (showCash || showAll) &&
        cashData?.some((obj) => obj.y !== 0) && {
          name: "Cash",
          type: "bar",
          itemStyle: {
            borderRadius: !showAll ? [5, 5, 0, 0] : "", // Apply rounding to the top-left and top-right corners
            // This sets the top corners' radius to 5 (creating a rounded effect)
            // and leaves the bottom corners squared (radius 0).
          },
          stack: "Total",
          // barWidth: '30%',
          smooth: true,
          areaStyle: {
            color: {
              type: "linear",
              x: 0,
              y: 0,
              x2: 0,
              y2: 1,
              colorStops: [
                {
                  offset: 0,
                  color: "green", // color at 0%
                },
                {
                  offset: 1,
                  color: "green", // color at 100%
                },
              ],
              global: false, // default is false
            },
          },
          emphasis: {
            focus: "series",
          },
          data: yAxisCash?.map((v) => Math.max(0, v)),
        },
      (showFixedIncome || showAll) &&
        fixedIncomeData?.some((obj) => obj.y !== 0) && {
          name: "Fixed Income",
          type: "bar",
          itemStyle: {
            borderRadius: !showAll ? [5, 5, 0, 0] : "", // Apply rounding to the top-left and top-right corners
            // This sets the top corners' radius to 5 (creating a rounded effect)
            // and leaves the bottom corners squared (radius 0).
          },
          stack: "Total",
          // barWidth: '30%',
          smooth: true,
          areaStyle: {
            color: {
              type: "linear",
              x: 0,
              y: 0,
              x2: 0,
              y2: 1,
              colorStops: [
                {
                  offset: 0,
                  color: "green", // color at 0%
                },
                {
                  offset: 1,
                  color: "green", // color at 100%
                },
              ],
              global: false, // default is false
            },
          },
          emphasis: {
            focus: "series",
          },
          data: yAxisFixedIncome?.map((v) => Math.max(0, v)),
        },
      (showRealEstate || showAll) &&
        realEstateData?.some((obj) => obj.y !== 0) && {
          name: "Real Estate",
          type: "bar",
          itemStyle: {
            borderRadius: !showAll ? [5, 5, 0, 0] : "", // Apply rounding to the top-left and top-right corners
            // This sets the top corners' radius to 5 (creating a rounded effect)
            // and leaves the bottom corners squared (radius 0).
          },
          stack: "Total",
          // barWidth: '30%',
          smooth: true,
          areaStyle: {
            color: {
              type: "linear",
              x: 0,
              y: 0,
              x2: 0,
              y2: 1,
              colorStops: [
                {
                  offset: 0,
                  color: "#00304A", // color at 0%
                },
                {
                  offset: 1,
                  color: "#00304A", // color at 100%
                },
              ],
              global: false, // default is false
            },
          },
          emphasis: {
            focus: "series",
          },
          data: yAxisRealEstate?.map((v) => Math.max(0, v)),
        },
      (showLifeInsurance || showAll) &&
        lifeInsuranceData?.some((obj) => obj.y !== 0) && {
          name: "Life Insurance",
          type: "bar",
          itemStyle: {
            borderRadius: !showAll ? [5, 5, 0, 0] : "", // Apply rounding to the top-left and top-right corners
            // This sets the top corners' radius to 5 (creating a rounded effect)
            // and leaves the bottom corners squared (radius 0).
          },
          stack: "Total",
          // barWidth: '30%',
          smooth: true,
          areaStyle: {
            color: {
              type: "linear",
              x: 0,
              y: 0,
              x2: 0,
              y2: 1,
              colorStops: [
                {
                  offset: 0,
                  color: "#001A2A", // color at 0%
                },
                {
                  offset: 1,
                  color: "#001A2A", // color at 100%
                },
              ],
              global: false, // default is false
            },
          },
          emphasis: {
            focus: "series",
          },
          data: yAxisLifeInsurance?.map((v) => Math.max(0, v)),
        },
      (showBusiness || showAll) &&
        businessData?.some((obj) => obj.y !== 0) && {
          name: "Business",
          type: "bar",
          itemStyle: {
            borderRadius: !showAll ? [50, 50, 0, 0] : 0, // Apply rounding to the top-left and top-right corners
            // This sets the top corners' radius to 5 (creating a rounded effect)
            // and leaves the bottom corners squared (radius 0).
          },
          stack: "Total",
          // barWidth: '30%',
          smooth: true,
          areaStyle: {
            color: {
              type: "linear",
              x: 0,
              y: 0,
              x2: 0,
              y2: 1,
              colorStops: [
                {
                  offset: 0,
                  color: "#147984", // color at 0%
                },
                {
                  offset: 1,
                  color: "#147984", // color at 100%
                },
              ],
              global: false, // default is false
            },
          },
          emphasis: {
            focus: "series",
          },
          data: yAxisBusiness?.map((v) => Math.max(0, v)),
        },
      (showBusinessInvestments || showAll) &&
        businessInvestmentData?.some((obj) => obj.y !== 0) && {
          name: "CCPC Investments",
          type: "bar",
          itemStyle: {
            borderRadius: !showAll ? [5, 5, 0, 0] : "", // Apply rounding to the top-left and top-right corners
            // This sets the top corners' radius to 5 (creating a rounded effect)
            // and leaves the bottom corners squared (radius 0).
          },
          stack: "Total",
          // barWidth: '30%',
          smooth: true,
          areaStyle: {
            color: {
              type: "linear",
              x: 0,
              y: 0,
              x2: 0,
              y2: 1,
              colorStops: [
                {
                  offset: 0,
                  color: "purple", // color at 0%
                },
                {
                  offset: 1,
                  color: "purple", // color at 100%
                },
              ],
              global: false, // default is false
            },
          },
          emphasis: {
            focus: "series",
          },
          data: yAxisBusinessInvestments?.map((v) => Math.max(0, v)),
        },
      (showOtherAssets || showAll) &&
        otherAssetsData?.some((obj) => obj.y !== 0) && {
          name: "Personal Assets",
          type: "bar",
          itemStyle: {
            borderRadius: !showAll ? [5, 5, 0, 0] : "", // Apply rounding to the top-left and top-right corners
            // This sets the top corners' radius to 5 (creating a rounded effect)
            // and leaves the bottom corners squared (radius 0).
          },
          stack: "Total",
          // barWidth: '30%',
          smooth: true,
          areaStyle: {
            color: {
              type: "linear",
              x: 0,
              y: 0,
              x2: 0,
              y2: 1,
              colorStops: [
                {
                  offset: 0,
                  color: "#06426F", // color at 0%
                },
                {
                  offset: 1,
                  color: "#06426F", // color at 100%
                },
              ],
              global: false, // default is false
            },
          },
          emphasis: {
            focus: "series",
          },
          data: yAxisOtherAssets?.map((v) => Math.max(0, v)),
        },
      showTotal && {
        name: "Total",
        type: "bar",
        itemStyle: {
          borderRadius: !showAll ? [5, 5, 0, 0] : "", // Apply rounding to the top-left and top-right corners
          // This sets the top corners' radius to 5 (creating a rounded effect)
          // and leaves the bottom corners squared (radius 0).
        },
        stack: "Total",
        // barWidth: '30%',
        smooth: true,
        areaStyle: {
          color: {
            type: "linear",
            x: 0,
            y: 0,
            x2: 0,
            y2: 1,
            colorStops: [
              {
                offset: 0,
                color: "#FFD4B9", // color at 0%
              },
              {
                offset: 1,
                color: "#FFD4B9", // color at 100%
              },
            ],
            global: false, // default is false
          },
        },
        emphasis: {
          focus: "series",
        },
        data: yAxisTotalPortfolio?.map((v) => Math.max(0, v)),
      },
    ],
  };
  const eChartsRef = React.useRef(0);

  function selectGraph(graph) {
    if (graph === "Total") {
      setShowTotal(true);
      setShowAll(false);
    } else setShowTotal(false);
    if (graph === "TFSA" || graph === "registered") {
      setShowTFSA(true);
      setShowAll(false);
    } else setShowTFSA(false);
    if (graph === "FHSA" || graph === "registered") {
      setShowFHSA(true);
      setShowAll(false);
    } else setShowFHSA(false);
    if (graph === "RRSP/RRIF" || graph === "registered") {
      setShowRRSP(true);
      setShowAll(false);
    } else setShowRRSP(false);
    if (graph === "Pension" || graph === "registered") {
      setShowPension(true);
      setShowAll(false);
    } else setShowPension(false);
    if (graph === "LIRA" || graph === "registered") {
      setShowLIRA(true);
      setShowAll(false);
    } else setShowLIRA(false);
    if (graph === "Non-Registered") {
      setShowNonReg(true);
      setShowAll(false);
    } else setShowNonReg(false);
    if (graph === "Cash") {
      setShowCash(true);
      setShowAll(false);
    } else setShowCash(false);
    if (graph === "Fixed Income") {
      setShowFixedIncome(true);
      setShowAll(false);
    } else setShowFixedIncome(false);
    if (graph === "Real Estate" || graph === "assets") {
      setShowRealEstate(true);
      setShowAll(false);
    } else setShowRealEstate(false);
    if (graph === "Life Insurance" || graph === "assets") {
      setShowLifeInsurance(true);
      setShowAll(false);
    } else setShowLifeInsurance(false);
    if (
      graph === "Business" ||
      graph === "assets" ||
      graph === "CCPC Investments"
    ) {
      setShowBusiness(true);
      setShowAll(false);
    } else setShowBusiness(false);
    if (
      graph === "CCPC Investments" ||
      graph === "assets" ||
      graph === "Business"
    ) {
      setShowBusinessInvestments(true);
      setShowAll(false);
    } else setShowBusinessInvestments(false);
    if (graph === "Personal Assets" || graph === "assets") {
      setShowOtherAssets(true);
      setShowAll(false);
    } else setShowOtherAssets(false);
    if (graph === "Overview") {
      setShowAll(true);
    } else setShowAll(false);
  }

  return (
    <div
      className="dashboard-graph-container"
      style={{
        border: "none",
        borderRadius: "none",
        boxShadow: "none",
        padding: "0",
      }}
    >
      
        <div
          tabIndex={0}
          className="dropdown"
          onClick={() => setShowDropdown(!showDropdown)}
          onBlur={() => setShowDropdown(showDropdown)}
        >
          <img src={FilterIcon} className="filter-icon" alt="filter-icon" />
          {showDropdown && (
            <div className="dropdown-items" style={{ maxWidth: "100%" }}>
              <ul>
                <div>Display</div>

                <li
                  className={showTotal ? "dropdown-item-selected" : undefined}
                  onClick={() => selectGraph("Total")}
                >
                  Total Portfolio Balance
                </li>
                <li
                  className={showAll ? "dropdown-item-selected" : undefined}
                  onClick={() => selectGraph("Overview")}
                >
                  All
                </li>
                {tfsaData?.some((obj) => obj.y !== 0) && (
                  <li
                    className={showTFSA ? "dropdown-item-selected" : undefined}
                    onClick={() => selectGraph("TFSA")}
                  >
                    TFSA
                  </li>
                )}
                {fhsaData?.some((obj) => obj.y !== 0) && (
                  <li
                    className={showFHSA ? "dropdown-item-selected" : undefined}
                    onClick={() => selectGraph("FHSA")}
                  >
                    FHSA
                  </li>
                )}
                {rrspData?.some((obj) => obj.y !== 0) && (
                  <li
                    className={showRRSP ? "dropdown-item-selected" : undefined}
                    onClick={() => selectGraph("RRSP/RRIF")}
                  >
                    RRSP /RRIF
                  </li>
                )}
                {pensionData?.some((obj) => obj.y !== 0) && (
                  <li
                    className={
                      showPension ? "dropdown-item-selected" : undefined
                    }
                    onClick={() => selectGraph("Pension")}
                  >
                    Pension
                  </li>
                )}
                {liraData?.some((obj) => obj.y !== 0) && (
                  <li
                    className={showLIRA ? "dropdown-item-selected" : undefined}
                    onClick={() => selectGraph("LIRA")}
                  >
                    LIRA
                  </li>
                )}
                {nonRegData?.some((obj) => obj.y !== 0) && (
                  <li
                    className={
                      showNonReg ? "dropdown-item-selected" : undefined
                    }
                    onClick={() => selectGraph("Non-Registered")}
                  >
                    Non-Registered
                  </li>
                )}
                {cashData?.some((obj) => obj.y !== 0) && (
                  <li
                    className={showCash ? "dropdown-item-selected" : undefined}
                    onClick={() => selectGraph("Cash")}
                  >
                    Cash
                  </li>
                )}
                {fixedIncomeData?.some((obj) => obj.y !== 0) && (
                  <li
                    className={
                      showFixedIncome ? "dropdown-item-selected" : undefined
                    }
                    onClick={() => selectGraph("Fixed Income")}
                  >
                    Fixed Income
                  </li>
                )}
                {realEstateData?.some((obj) => obj.y !== 0) && (
                  <li
                    className={
                      showRealEstate ? "dropdown-item-selected" : undefined
                    }
                    onClick={() => selectGraph("Real Estate")}
                  >
                    Real Estate
                  </li>
                )}
                {lifeInsuranceData?.some((obj) => obj.y !== 0) && (
                  <li
                    className={
                      showLifeInsurance ? "dropdown-item-selected" : undefined
                    }
                    onClick={() => selectGraph("Life Insurance")}
                  >
                    Life Insurance
                  </li>
                )}
                {businessData?.some((obj) => obj.y !== 0) && (
                  <li
                    className={
                      showBusiness ? "dropdown-item-selected" : undefined
                    }
                    onClick={() => selectGraph("Business")}
                  >
                    Business
                  </li>
                )}
                {businessInvestmentData?.some((obj) => obj.y !== 0) && (
                  <li
                    className={
                      showBusinessInvestments
                        ? "dropdown-item-selected"
                        : undefined
                    }
                    onClick={() => selectGraph("CCPC Investments")}
                  >
                    CCPC Investments
                  </li>
                )}
                {otherAssetsData?.some((obj) => obj.y !== 0) && (
                  <li
                    className={
                      showOtherAssets ? "dropdown-item-selected" : undefined
                    }
                    onClick={() => selectGraph("Personal Assets")}
                  >
                    Personal Assets
                  </li>
                )}
              </ul>
            </div>
          )}
        </div>
    
      <ReactEcharts
        ref={eChartsRef}
        option={option}
        className="portfolio-balance-chart"
        notMerge
      />
    </div>
  );
}

export default LineGraph;
