import React from 'react';
import { Box, Flex, Text } from "@chakra-ui/react";
import ReactEcharts from "echarts-for-react";

const PieChartContainer = ({ header, dataValues, dataNames }) => {
  const colors = [
    "#73B879", "#30AB9D", "#00A2D4", "#007EB5", "#00466D", "#00304A"
  ];


  const currencyFormat = (money) => {
    var intTotal = Math.round(Math.round(money * 100) / 100);
    if (intTotal > -2 && intTotal < 0.25) {
      intTotal = 0;
    }
    // Math.round(intTotal);
    var currencyCad = new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD",
      minimumFractionDigits: 0,
    }).format(intTotal);
    return currencyCad;
  };
  const pieData = dataNames.map((name, index) => ({
    value: dataValues[index],
    name,
    itemStyle: {
      color: colors[index % colors.length],
    },
  }));

  const options = {
    tooltip: {
      trigger: 'item',
      formatter: (params) => {
        return `${params.seriesName} <br/>${params.name} : ${currencyFormat(params.value)} (${params.percent}%)`;
      },
    },
    series: [
      {
        name: header,
        type: 'pie',
        radius: ['40%', '70%'],
        data: pieData,
        emphasis: {
          itemStyle: {
            shadowBlur: 10,
            shadowOffsetX: 0,
            shadowColor: 'rgba(0, 0, 0, 0.5)',
          },
        },
        label: {
          show: false,
        },
        labelLine: {
          show: false,
        },
      },
    ],
  };

  return (
    <Box>
      <Text fontSize="xl" fontWeight="bold" mb={4} textAlign="left">
        {header}
      </Text>
      <Flex alignItems="center">
        <Box width="50%" height="200px" minW={"160px"} maxHeight={"160px"}>
          <ReactEcharts option={options} style={{ height: '100%', width: '100%' }} />
        </Box>
        <Box width="50%" pl={4}>
          {dataNames?.map((name, index) => (
            <Flex key={index} alignItems="center" mb={2}>
              <Box
                width="12px"
                height="12px"
                backgroundColor={colors[index % colors.length]}
                mr={2}
              />
              <Text>{name}</Text>
            </Flex>
          ))}
        </Box>
      </Flex>
    </Box>
  );
};

export default PieChartContainer;
