import { Box, Flex, Text, VStack, useMediaQuery } from "@chakra-ui/react";
import axios from "axios";
import React, {
  forwardRef,
  useContext,
  useEffect,
  useImperativeHandle,
  useState,
} from "react";
import { useStore } from "../../../store";
import { useAuth0 } from "@auth0/auth0-react";
import GlobalBodyPage from "../../GlobalContainer/BodyPage";
import DropDownButton from "../../InputFields/dropDownButton";
import NormalInputButton from "../../InputFields/normalInputButton";
import TableComponent from "../../TableComponent";
import TooltipContent from "../../TooltipContent";
import { CloseIcon } from "../../CustomIcons";

const BusinessForm = forwardRef((props, ref) => {
  const { getAccessTokenSilently, user } = useAuth0();

  useImperativeHandle(ref, () => ({
    submitHandler,
    checkMandatoryFields,
  }));

  const submitHandler = () => {
    setUserEditAssetInfo({});
    return true;
  };

  const checkMandatoryFields = () => {
    return true;
  };

  const axiosInstance = axios.create();
  async function getToken() {
    // Replace this with your Auth0 token retrieval logic
    const token = await getAccessTokenSilently({
      audience:
        "https://os2lsiwff43wfpb5ggyawt6qri0mgcro.lambda-url.us-east-2.on.aws/",
      scope: "write:optimization",
    });
    return token;
  }

  // Add a request interceptor
  axiosInstance.interceptors.request.use(
    async (config) => {
      const token = await getToken();
      config.headers.Authorization = `Bearer ${token}`;
      return config;
    },
    (error) => {
      // Do something with request error
      return Promise.reject(error);
    }
  );
  const userEditAssetInfo = useStore((store) => store.userEditAssetInfo);
  const setUserEditAssetInfo = useStore((store) => store.setUserEditAssetInfo);

  const userCompanyInfo = useStore((store) => store.userCompanyInfo);
  const setUserCompanyInfo = useStore((store) => store.setUserCompanyInfo);
  const updateUserCompanyInfo = useStore(
    (store) => store.updateUserCompanyInfo
  );
  const deleteUserCompanyInfo = useStore(
    (store) => store.deleteUserCompanyInfo
  );

  // Operating Companies
  const [companyNumber, setCompanyNumber] = useState();
  const [companyName, setCompanyName] = useState();
  const [businessValue, setBusinessValue] = useState(0);
  const [businessOwnership, setBusinessOwnership] = useState(100);
  const [businessLocation, setBusinessLocation] = useState("");
  const [doBusinessDividends, setDoBusinessDividends] = useState("");
  const [businessDividendsSalary, setBusinessDividendsSalary] = useState(0);
  const [
    businessDividendsSalaryNonEligible,
    setBusinessDividendsSalaryNonEligible,
  ] = useState(0);
  const [businessDividendsGrowth, setBusinessDividendsGrowth] = useState(0);
  const [businessGrowthRate, setBusinessGrowthRate] = useState(0);
  const [businessPlansToSell, setBusinessPlansToSell] = useState("");
  const [businessPlansToSellYear, setBusinessPlansToSellYear] = useState();
  const [businessSellValue, setBusinessSellValue] = useState(0);
  const [year, setYear] = useState();

  const [BusinessRevenue, setBusinessRevenue] = useState("");
  const [annualDividendAmount, setAnnualDividendAmount] = useState("");

  const [isLargerThan768] = useMediaQuery("(min-width: 500px)");
  const isMobile = !isLargerThan768;

  const [businessArray, setBusinessArray] = useState([]);
  const [editing, setEditing] = useState(false);
  const [editingIndex, setEditingIndex] = useState(null);
  const [showDeleteModal, setShowDeleteModal] = useState(null);

  const [adding, setAdding] = useState(false);

  const userInfo = useStore((store) => store.userInfo);

  const {
    FirstName: firstName,
    LastName: lastName,
    DateOfBirth: currentAge,
    spouseFirstName,
    spouseLastName,
    runAge,
  } = userInfo;

  function safeAdd(...values) {
    return values.reduce((sum, val) => sum + (Number(val) || 0), 0);
  }
  const currentDate = new Date();
  const currentYear = currentDate.getFullYear();
  useEffect(() => {
    if (userEditAssetInfo?.editAsset?.name === "Company") {
      setCompanyName(userEditAssetInfo?.editAsset?.companyName);
      setBusinessValue(userEditAssetInfo?.editAsset?.value);
      // setBusinessOwnership(userEditAssetInfo?.editAsset?.businessOwnership);
      setDoBusinessDividends(userEditAssetInfo?.editAsset?.doBusinessDividends);
      setBusinessDividendsSalary(
        userEditAssetInfo?.editAsset?.businessDividendsSalary
      );
      setBusinessDividendsSalaryNonEligible(
        userEditAssetInfo?.editAsset?.businessDividendsSalaryNonEligible
      );
      setAnnualDividendAmount(
        safeAdd(
          userEditAssetInfo?.editAsset?.businessDividendsSalary,
          userEditAssetInfo?.editAsset?.businessDividendsSalaryNonEligible
        )
      );
      setBusinessDividendsGrowth(
        userEditAssetInfo?.editAsset?.businessDividendsGrowth
      );
      setBusinessGrowthRate(userEditAssetInfo?.editAsset?.businessGrowthRate);
      setBusinessPlansToSell(userEditAssetInfo?.editAsset?.businessPlansToSell);
      setBusinessPlansToSellYear(
        userEditAssetInfo?.editAsset?.businessPlansToSellYear
      );
      setBusinessSellValue(userEditAssetInfo?.editAsset?.businessSellValue);
      setCompanyNumber(userEditAssetInfo?.editAsset?.companyNumber);
      setEditing(true);
      setAdding(true);
      setEditingIndex(userEditAssetInfo?.editIndex);
    }
  }, [userEditAssetInfo]);

  useEffect(() => {
    const updatedBusinessArray = [];

    if (userCompanyInfo != null) {
      updatedBusinessArray.push(...userCompanyInfo);
    }

    setBusinessArray(updatedBusinessArray);
  }, [userCompanyInfo]);

  const onDeleteRow = (i) => {
    setEditingIndex(i);
    setShowDeleteModal(true);
  };

  const editField = (i, array) => {
    setAdding(true);
    if (array[i].name === "Company") {
      setCompanyName(array[i]?.companyName);
      setBusinessValue(array[i]?.value);
      // setBusinessOwnership(array[i]?.businessOwnership);
      setDoBusinessDividends(array[i]?.doBusinessDividends);
      setBusinessDividendsSalary(array[i]?.businessDividendsSalary);
      setBusinessDividendsSalaryNonEligible(
        array[i]?.businessDividendsSalaryNonEligible
      );
      setAnnualDividendAmount(
        safeAdd(
          array[i]?.businessDividendsSalary,
          array[i]?.businessDividendsSalaryNonEligible
        )
      );
      setBusinessDividendsGrowth(array[i]?.businessDividendsGrowth);
      setBusinessGrowthRate(array[i]?.businessGrowthRate);
      setBusinessPlansToSell(array[i]?.businessPlansToSell);
      setBusinessPlansToSellYear(array[i]?.businessPlansToSellYear);
      setBusinessSellValue(array[i]?.businessSellValue);
      setCompanyNumber(array[i]?.companyNumber);
    }
    setEditing(true);
    setEditingIndex(i);
  };

  const DeleteRealEstateModal = () => {
    let activeArray = businessArray;

    const removeRealEstateModal = () => {
      const deletePropertyURL = (action) =>
        `${process.env.REACT_APP_NODE_API_GATEWAY}/CompassLambdaApi/COMPANY/${action}?companyNumber=${activeArray[editingIndex].companyNumber}`;
      axiosInstance.get(deletePropertyURL("delete")).then((res) => {
        console.log("Delete res", res);
        deleteUserCompanyInfo(businessArray[editingIndex].companyNumber);
        setEditingIndex(null);
        setShowDeleteModal(false);
        setEditing(false);
      });
    };

    if (!showDeleteModal) {
      return null;
    }
    const [yesClicked, setYesClicked] = useState(false);
    const [noClicked, setNoClicked] = useState(false);
    return (
      <div
        className="delete-income-modal"
        style={{
          display: "flex",
          alignItems: "center",
          width: "100%",
          maxWidth: "700px",
          justifyContent: "center",
          top: "50%",
          left: "50%",
          transform: isMobile
            ? "translate(-50%, -50%)"
            : "translate(-50%, -50%)",
        }}
        id="delete-income-modal"
      >
        <Box
          display={"flex"}
          width={"100%"}
          padding={"10px 10px 0px 0px"}
          marginLeft={"5px"}
          alignItems={"flex-end"}
          justifyContent={"flex-end"}
          onClick={() => setShowDeleteModal(false)}
          cursor={"pointer"}
        >
          <CloseIcon
            onClick={() => setShowDeleteModal(false)}
            svgheight={"20px"}
            svgwidth={"20px"}
          />
        </Box>
        <Box
          display={"flex"}
          padding={"0px 25px 25px 25px"}
          flexDirection={"column"}
          alignItems={"center"}
          gap={"10px"}
          alignSelf={"stretch"}
          width={"100%"}
        >
          <Text
            color={"#0093D0"}
            fontFamily={"Nunito"}
            fontSize={"24px"}
            fontStyle={"normal"}
            fontWeight={"600"}
            lineHeight={"normal"}
            textDecor={"underline"}
          >
            Are you sure you want to remove this business asset?
          </Text>
          <div className="content"></div>
          <div className="actions">
            <button
              className={`decision-button yes-button ${
                yesClicked ? "yes-clicked" : ""
              }`}
              onClick={() => {
                setYesClicked(true);
                setNoClicked(false);
                removeRealEstateModal();
              }}
            >
              Yes
            </button>
            <button
              className={`decision-button no-button ${
                noClicked ? "yes-clicked" : ""
              }`}
              onClick={() => {
                {
                  setNoClicked(true);
                  setYesClicked(false);
                  setShowDeleteModal(false);
                }
              }}
            >
              No
            </button>
          </div>
        </Box>
      </div>
    );
  };

  const moneyToNumber = (value) => {
    let numValue = value.toString().replace(/[$,\s]/g, "");
    numValue = Number(numValue);
    return numValue;
  };

  const onSubmitBusinessAdd = (e, newArray) => {
    e.preventDefault();
    if (newArray.name == "Company") {
      const primaryResURL = (action) =>
        `${
          process.env.REACT_APP_NODE_API_GATEWAY
        }/CompassLambdaApi/COMPANY/${action}?companyID=${user.sub.replace(
          "|",
          "%7c"
        )}&name=${newArray.name}&companyName=${newArray.companyName}&value=${
          newArray.value
        }&businessOwnership=${newArray.businessOwnership}&doBusinessDividends=${
          newArray.doBusinessDividends
        }&businessDividendsSalary=${
          newArray.businessDividendsSalary
        }&businessDividendsSalaryNonEligible=${
          newArray.businessDividendsSalaryNonEligible
        }&businessDividendsGrowth=${
          newArray.businessDividendsGrowth
        }&businessGrowthRate=${
          newArray.businessGrowthRate
        }&businessPlansToSell=${
          newArray.businessPlansToSell
        }&businessPlansToSellYear=${
          newArray.businessPlansToSellYear
        }&businessSellValue=${newArray.businessSellValue}`;
      axiosInstance
        .get(primaryResURL("add"))
        .then((res) => {
          console.log(res);
          const newData = [
            {
              name: newArray.name,
              companyName: newArray.companyName,
              value: newArray.value,
              businessOwnership: newArray.businessOwnership,
              doBusinessDividends: newArray.doBusinessDividends,
              businessDividendsSalary: newArray.businessDividendsSalary,
              businessDividendsSalaryNonEligible:
                newArray.businessDividendsSalaryNonEligible,
              businessDividendsGrowth: newArray.businessDividendsGrowth,
              businessGrowthRate: newArray.businessGrowthRate,
              businessPlansToSell: newArray.businessPlansToSell,
              businessPlansToSellYear: newArray.businessPlansToSellYear,
              businessSellValue: newArray.businessSellValue,
              companyNumber: res.data.lastInsertedId,
            },
          ];
          setUserCompanyInfo(newData);
        })
        .catch((error) => {
          console.error(error);
        });
    }
  };

  const onSubmitBusinessUpdate = (e, newArray) => {
    e.preventDefault();
    let y = newArray.companyNumber;
    if (newArray.name == "Company") {
      const otherAssetsURL = (action) =>
        `${
          process.env.REACT_APP_NODE_API_GATEWAY
        }/CompassLambdaApi/COMPANY/${action}?companyID=${user.sub.replace(
          "|",
          "%7c"
        )}&name=${newArray.name}&companyName=${newArray.companyName}&value=${
          newArray.value
        }&businessOwnership=${newArray.businessOwnership}&doBusinessDividends=${
          newArray.doBusinessDividends
        }&businessDividendsSalary=${
          newArray.businessDividendsSalary
        }&businessDividendsSalaryNonEligible=${
          newArray.businessDividendsSalaryNonEligible
        }&businessDividendsGrowth=${
          newArray.businessDividendsGrowth
        }&businessGrowthRate=${
          newArray.businessGrowthRate
        }&businessPlansToSell=${
          newArray.businessPlansToSell
        }&businessPlansToSellYear=${
          newArray.businessPlansToSellYear
        }&businessSellValue=${newArray.businessSellValue}&companyNumber=${
          newArray.companyNumber
        }`;
      axiosInstance
        .get(otherAssetsURL("update"))
        .then((res) => {
          console.log(res);
          const newData = {
            name: newArray.name,
            companyName: newArray.companyName,
            value: newArray.value,
            businessOwnership: newArray.businessOwnership,
            doBusinessDividends: newArray.doBusinessDividends,
            businessDividendsSalary: newArray.businessDividendsSalary,
            businessDividendsSalaryNonEligible:
              newArray.businessDividendsSalaryNonEligible,
            businessDividendsGrowth: newArray.businessDividendsGrowth,
            businessGrowthRate: newArray.businessGrowthRate,
            businessPlansToSell: newArray.businessPlansToSell,
            businessPlansToSellYear: newArray.businessPlansToSellYear,
            businessSellValue: newArray.businessSellValue,
            companyNumber: newArray.companyNumber,
          };
          updateUserCompanyInfo(y, newData);
        })
        .catch((error) => {
          console.error(error);
        });
    }
  };

  const addCompany = (e, list) => {
    if (editing) {
      setBusinessArray((prevArray) => {
        let newArray = [...prevArray];
        newArray[editingIndex] = list;
        onSubmitBusinessUpdate(e, newArray[editingIndex]);
        return newArray;
      });
      setEditing(false);
      setEditingIndex(null);
    } else {
      setBusinessArray((prevArray) => {
        let newArray = [...prevArray, list];
        onSubmitBusinessAdd(e, newArray[newArray.length - 1]);
        return newArray;
      });
    }
    setCompanyName("");
    setBusinessValue(0);
    setBusinessOwnership(100);
    setBusinessGrowthRate(0);
    setDoBusinessDividends("");
    setBusinessDividendsSalary(0);
    setBusinessDividendsSalaryNonEligible(0);
    setBusinessDividendsGrowth(0);
    setBusinessPlansToSell(0);
    setBusinessPlansToSellYear(0);
    setBusinessSellValue(0);
    setAdding(false);
  };

  const [errors, setErrors] = useState({});

  const checkMandatoryAddUpdateFields = () => {
    let newErrors = {};

    // Business property conditions
    if (!businessValue) {
      newErrors.businessValue = "Business value is required";
    }
    if (!businessOwnership) {
      newErrors.businessOwnership = "Business ownership is required";
    }
    if (!businessGrowthRate || businessGrowthRate > 20) {
      newErrors.businessGrowthRate =
        "Business Rate of return is required and must be greater than 0% and less than 20%";
    }
    if (!doBusinessDividends) {
      newErrors.doBusinessDividends =
        "Business dividends information is required";
    } else if (doBusinessDividends === "Yes") {
      if (businessDividendsSalary + businessDividendsSalaryNonEligible <= 0) {
        newErrors.businessDividendsSalary =
          "Business dividends amount is required";
      }
      if (!businessDividendsGrowth || businessDividendsGrowth > 20) {
        newErrors.businessDividendsGrowth =
          "Business dividends growth is required and must be greater than 0% and less than 20%";
      }
    }
    if (!businessPlansToSell) {
      newErrors.businessPlansToSell = "Plan to sell business is required";
    } else if (businessPlansToSell === "Yes") {
      if (!businessSellValue) {
        newErrors.businessSellValue = "Business sell value is required";
      }
      if (!businessPlansToSellYear) {
        newErrors.businessPlansToSellYear = "Year to sell business is required";
      }
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0; // Return true if no errors
  };

  const renderAddUpdateRecreational = () => {
    if (
      !businessValue ||
      // !businessOwnership ||
      !businessGrowthRate ||
      businessGrowthRate > 20 ||
      !doBusinessDividends ||
      (doBusinessDividends === "Yes" &&
        (businessDividendsSalary + businessDividendsSalaryNonEligible <= 0 ||
          !businessDividendsGrowth ||
          businessDividendsGrowth > 20)) ||
      !businessPlansToSell ||
      (businessPlansToSell === "Yes" &&
        (!businessSellValue || !businessPlansToSellYear))
    ) {
      return (
        <Box
          display={"flex"}
          padding={"8px 48px"}
          marginTop={"20px"}
          justifyContent={"flex-end"}
          alignItems={"center"}
          gap={"5px"}
          borderRadius={"5px"}
          bg={"#27AE60"}
          cursor={"pointer"}
          onClick={checkMandatoryAddUpdateFields}
        >
          <Box
            display={"flex"}
            width={"76px"}
            height={"21px"}
            flexDirection={"column"}
            justifyContent={"center"}
          >
            <Text
              color={"#FFFFFF"}
              textAlign={"center"}
              fontFamily={"Nunito"}
              fontSize={"16px"}
              fontStyle={"normal"}
              fontWeight={"600"}
              lineHeight={"normal"}
            >
              {editing ? "Update" : "Add"}
            </Text>
          </Box>
        </Box>
      );
    } else {
      return (
        <Box
          display={"flex"}
          padding={"8px 48px"}
          marginTop={"20px"}
          justifyContent={"flex-end"}
          alignItems={"center"}
          gap={"5px"}
          borderRadius={"5px"}
          bg={"#27AE60"}
          cursor={"pointer"}
          onClick={(e) =>
            addCompany(e, {
              name: "Company",
              companyName: companyName,
              value: businessValue,
              businessOwnership: businessOwnership,
              doBusinessDividends: doBusinessDividends,
              businessDividendsSalary: businessDividendsSalary,
              businessDividendsSalaryNonEligible:
                businessDividendsSalaryNonEligible,
              businessDividendsGrowth: businessDividendsGrowth,
              businessGrowthRate: businessGrowthRate,
              businessPlansToSell: businessPlansToSell,
              businessPlansToSellYear: businessPlansToSellYear,
              businessSellValue: businessSellValue,
              ...(editing ? { companyNumber: companyNumber } : {}), // add field conditionally
            })
          }
        >
          <Box
            display={"flex"}
            width={"76px"}
            height={"21px"}
            flexDirection={"column"}
            justifyContent={"center"}
            cursor={"pointer"}
          >
            <Text
              color={"#FFFFFF"}
              textAlign={"center"}
              fontFamily={"Nunito"}
              fontSize={"16px"}
              fontStyle={"normal"}
              fontWeight={"600"}
              lineHeight={"normal"}
            >
              {editing ? "Update" : "Add"}
            </Text>
          </Box>
        </Box>
      );
    }
  };

  const columns = [
    { header: "Name", field: "name" },
    { header: "Value", field: "value" },
  ];

  const data = businessArray.map((item) => ({
    name: item.companyName,
    value: item?.value,
    link: "/business-form",
  }));

  const handleClick = () => {
    setAdding(true);
  };

  function getAge(birthday, currentDate) {
    // Convert the birthday string into a Date object
    const birthDate = new Date(birthday);

    // Calculate the age in years
    let age = currentDate.getFullYear() - birthDate.getFullYear();

    // Decrement the age if the current month and day are before the birth month and day
    if (
      currentDate.getMonth() < birthDate.getMonth() ||
      (currentDate.getMonth() === birthDate.getMonth() &&
        currentDate.getDate() <= birthDate.getDate())
    ) {
      age -= 1;
    }

    return age;
  }

  const Select = (userAge) => {
    const currentDate = new Date();
    const currentYear = currentDate.getFullYear();
    let age = getAge(userAge, currentDate); // Assuming getAge returns the starting age.
    const runAgeList = [];
    for (let i = age; i <= 100; i++) {
      runAgeList.push({ key: i, value: currentYear + (i - age) }); // Convert i to string if necessary
    }
    return runAgeList;
  };

  return (
    <Flex
      display="flex"
      flexWrap="wrap" // Enable wrapping
      justifyContent="flex-start"
      alignItems="start"
      width={"100%"}
    >
      {!isMobile && (
        <VStack alignItems={"flex-start"} width={"100%"} mt={-2}>
          <GlobalBodyPage
            Heading="Business"
            mainframeContent={
              <>
                <Box
                  display={"flex"}
                  width={"100%"}
                  flexDirection={"row"}
                  paddingTop={"10px"}
                  alignItems={"flex-end"}
                  justifyContent={"space-between"}
                  gap={"10px"}
                  flexWrap={"wrap"}
                >
                  <DeleteRealEstateModal />
                  {data.length > 0 && !adding && (
                    <TableComponent
                      columns={columns}
                      data={data}
                      editField={editField}
                      onDeleteRow={onDeleteRow}
                      rawData={businessArray}
                    />
                  )}
                  {(data.length < 1 || adding) && (
                    <>
                      <Text
                        alignSelf={"stretch"}
                        color={"var(--Optiml---Deep-Ocean-Blue, #007EB5)"}
                        fontFamily={"Nunito"}
                        fontSize={"16px"}
                        fontStyle={"normal"}
                        fontWeight={"600"}
                        lineHeight={"normal"}
                        width={"100%"}
                      >
                        Corporation
                      </Text>
                      <NormalInputButton
                        Heading={"Title/Business name"}
                        value={companyName}
                        onChange={(e) => {
                          setCompanyName(e.target.value);
                        }}
                        customWidth={"100%"}
                        InputWidth={"100%"}
                        error={errors.companyName}
                        tooltipContent={TooltipContent.companyName}
                      />
                      <NormalInputButton
                        Heading={
                          "Enter the dollar value of your ownership of the business"
                        }
                        value={businessValue}
                        onChange={(e) => {
                          setBusinessValue(moneyToNumber(e.target.value));
                        }}
                        customWidth={"100%"}
                        placeholder={"$"}
                        useThousandSeparator={true}
                        error={errors.businessValue}
                        tooltipContent={TooltipContent.companyValue}
                      />
                      {/* <NormalInputButton
                        Heading={"Ownership"}
                        value={businessOwnership}
                        onChange={(e) => {
                          const noSymbol = e.target.value.replace("%", "");
                          const growthNum = parseFloat(noSymbol);
                          setBusinessOwnership(
                            !isNaN(growthNum)
                              ? parseFloat(growthNum.toFixed(2))
                              : 0
                          );
                        }}
                        placeholder={"%"}
                        usePercentage={true}
                        error={errors.businessOwnership}
                        tooltipContent={TooltipContent.companyOwnership}
                      /> */}
                      <NormalInputButton
                        Heading={"Annual Growth"}
                        value={businessGrowthRate}
                        onChange={(e) => {
                          const noSymbol = e.target.value.replace("%", "");
                          const growthNum = parseFloat(noSymbol);
                          setBusinessGrowthRate(
                            !isNaN(growthNum)
                              ? parseFloat(growthNum.toFixed(2))
                              : 0
                          );
                        }}
                        placeholder={"%"}
                        usePercentage={true}
                        error={errors.businessGrowthRate}
                        tooltipContent={TooltipContent.companyGrowth}
                      />
                      {/* <DropDownButton
                    Heading={"Province/Territory registered"}
                    placeholder={"Select"}
                    dropDownItems={[
                      { key: "AB", value: "Alberta" },

                      { key: "BC", value: "British Columbia" },

                      { key: "MB", value: "Manitoba" },

                      { key: "NB", value: "New Brunswick" },

                      { key: "NL", value: "Newfoundland and Labrador" },

                      { key: "NS", value: "Nova Scotia" },

                      { key: "ON", value: "Ontario" },

                      { key: "PE", value: "Prince Edward Island" },

                      { key: "QC", value: "Quebec" },

                      { key: "SK", value: "Saskatchewan" },

                      { key: "NT", value: "Northwest Territories" },

                      { key: "NU", value: "Nunavut" },

                      { key: "YT", value: "Yukon" },
                    ]}
                    value={businessLocation}
                    onChange={(key) => {
                      setBusinessLocation(key);
                    }}
                    customWidth={"100%"}
                  /> */}

                      <Text
                        alignSelf={"stretch"}
                        color={"var(--Optiml---Deep-Ocean-Blue, #007EB5)"}
                        fontFamily={"Nunito"}
                        fontSize={"16px"}
                        fontStyle={"normal"}
                        fontWeight={"600"}
                        lineHeight={"normal"}
                        width={"100%"}
                      >
                        Income
                      </Text>
                      {/* <DropDownButton
                        Heading={"Do you pay yourself an annual salary?"}
                        placeholder={"Select"}
                        dropDownItems={[
                          { key: "Yes", value: "Yes" },
                          { key: "No", value: "No" },
                        ]}
                        value={doBusinessDividends}
                        onChange={(key) => {
                          setDoBusinessDividends(key);
                        }}
                        customWidth={"100%"}
                      />
                      <NormalInputButton
                        Heading={"Annual salary amount?"}
                        value={businessDividendsSalary}
                        onChange={(e) => {
                          setBusinessDividendsSalary(moneyToNumber(e.target.value));
                        }}
                        placeholder={"$"}
                        useThousandSeparator={true}
                      />
                      <NormalInputButton
                        Heading={"Annual salary growth rate"}
                        value={businessDividendsGrowth}
                       onChange={(e) => {
                          const noSymbol = e.target.value.replace("%", "");
                          const growthNum = parseFloat(noSymbol);
                          setBusinessDividendsGrowth(
                            !isNaN(growthNum)
                              ? parseFloat(growthNum.toFixed(2))
                              : 0
                          );
                        }}
                        placeholder={"%"}
                        usePercentage={true}
                      /> */}
                      <DropDownButton
                        Heading={
                          "Do you pay yourself a dividend from your business's operational revenue?"
                        }
                        placeholder={"Select"}
                        dropDownItems={[
                          { key: "Yes", value: "Yes" },
                          { key: "No", value: "No" },
                        ]}
                        value={doBusinessDividends}
                        onChange={(key) => {
                          setDoBusinessDividends(key);
                        }}
                        customWidth={"100%"}
                        error={errors.doBusinessDividends}
                        tooltipContent={TooltipContent.companyDividends}
                      />
                      {doBusinessDividends === "Yes" && (
                        <>
                          <NormalInputButton
                            Heading={"Annual dividend ammount?"}
                            value={annualDividendAmount}
                            onChange={(e) => {
                              setAnnualDividendAmount(
                                moneyToNumber(e.target.value)
                              );
                            }}
                            placeholder={"$"}
                            useThousandSeparator={true}
                            error={errors.annualDividendAmount}
                          />
                          <NormalInputButton
                            Heading={"How much was from Eligible dividends?"}
                            value={businessDividendsSalary}
                            onChange={(e) => {
                              setBusinessDividendsSalary(
                                moneyToNumber(e.target.value)
                              );
                            }}
                            placeholder={"$"}
                            useThousandSeparator={true}
                            error={errors.businessDividendsSalary}
                            tooltipContent={
                              TooltipContent.companyEligibleDividends
                            }
                          />
                          <NormalInputButton
                            Heading={
                              "How much was from Non- Eligible dividends?"
                            }
                            value={businessDividendsSalaryNonEligible}
                            onChange={(e) => {
                              setBusinessDividendsSalaryNonEligible(
                                moneyToNumber(e.target.value)
                              );
                            }}
                            placeholder={"$"}
                            useThousandSeparator={true}
                            error={errors.businessDividendsSalary}
                            tooltipContent={
                              TooltipContent.companyNonEligibleDividend
                            }
                          />
                          <NormalInputButton
                            Heading={"Annual dividend growth rate"}
                            value={businessDividendsGrowth}
                            onChange={(e) => {
                              const noSymbol = e.target.value.replace("%", "");
                              const growthNum = parseFloat(noSymbol);
                              setBusinessDividendsGrowth(
                                !isNaN(growthNum)
                                  ? parseFloat(growthNum.toFixed(2))
                                  : 0
                              );
                            }}
                            placeholder={"%"}
                            usePercentage={true}
                            error={errors.businessDividendsGrowth}
                            tooltipContent={
                              TooltipContent.companyDividendGrowth
                            }
                          />
                        </>
                      )}

                      <Text
                        alignSelf={"stretch"}
                        color={"var(--Optiml---Deep-Ocean-Blue, #007EB5)"}
                        fontFamily={"Nunito"}
                        fontSize={"16px"}
                        fontStyle={"normal"}
                        fontWeight={"600"}
                        lineHeight={"normal"}
                        width={"100%"}
                      >
                        Future Real Estate Plans
                      </Text>
                      <DropDownButton
                        Heading={"Do you have plans to sell your business?"}
                        placeholder={"Select"}
                        dropDownItems={[
                          { key: "Yes", value: "Yes" },
                          { key: "No", value: "No" },
                        ]}
                        value={businessPlansToSell}
                        onChange={(key) => {
                          setBusinessPlansToSell(key);
                        }}
                        customWidth={"100%"}
                        error={errors.businessPlansToSell}
                        tooltipContent={TooltipContent.companySellYesNo}
                      />
                      {businessPlansToSell === "Yes" && (
                        <>
                          <DropDownButton
                            Heading={
                              "When do you anticipate you may sell your business?"
                            }
                            placeholder={"Select"}
                            dropDownItems={Select(currentAge)}
                            value={businessPlansToSellYear}
                            onChange={(key) => {
                              setBusinessPlansToSellYear(key);
                            }}
                            customWidth={"100%"}
                            error={errors.businessPlansToSellYear}
                            tooltipContent={TooltipContent.companySellYear}
                          />
                          {businessPlansToSellYear && (
                            <NormalInputButton
                              Heading={`Input estimated NET After-tax value of business in ${
                                currentYear +
                                businessPlansToSellYear -
                                getAge(currentAge, currentDate)
                              }`}
                              value={businessSellValue}
                              onChange={(e) => {
                                setBusinessSellValue(
                                  moneyToNumber(e.target.value)
                                );
                              }}
                              customWidth={"100%"}
                              placeholder={"$"}
                              useThousandSeparator={true}
                              error={errors.businessSellValue}
                              tooltipContent={TooltipContent.companySellValue}
                            />
                          )}
                        </>
                      )}
                    </>
                  )}
                </Box>
                {data.length > 0 && !adding && (
                  <Box
                    display={"flex"}
                    justifyContent={"flex-start"}
                    alignItems={"center"}
                    alignSelf={"stretch"}
                  >
                    <Box
                      display={"flex"}
                      padding={"8px 48px"}
                      marginTop={"20px"}
                      justifyContent={"flex-end"}
                      alignItems={"center"}
                      gap={"5px"}
                      borderRadius={"5px"}
                      bg={"#27AE60"}
                      cursor={"pointer"}
                      onClick={handleClick}
                    >
                      <Box
                        display={"flex"}
                        width={"76px"}
                        height={"21px"}
                        flexDirection={"column"}
                        justifyContent={"center"}
                      >
                        <Text
                          color={"#FFFFFF"}
                          textAlign={"center"}
                          fontFamily={"Nunito"}
                          fontSize={"16px"}
                          fontStyle={"normal"}
                          fontWeight={"600"}
                          lineHeight={"normal"}
                        >
                          Add
                        </Text>
                      </Box>
                    </Box>
                  </Box>
                )}
                {(data.length < 1 || adding) && (
                  <Box
                    display={"flex"}
                    justifyContent={"flex-end"}
                    alignItems={"center"}
                    alignSelf={"stretch"}
                  >
                    {renderAddUpdateRecreational()}
                  </Box>
                )}
              </>
            }
          />
        </VStack>
      )}
      {isMobile && (
        <VStack alignItems={"flex-start"} width={"100%"} mt={-2}>
          <GlobalBodyPage
            Heading="Business"
            mainframeContent={
              <>
                <Box
                  display={"flex"}
                  width={"100%"}
                  flexDirection={"row"}
                  paddingTop={"10px"}
                  alignItems={"flex-end"}
                  justifyContent={"space-between"}
                  gap={"10px"}
                  flexWrap={"wrap"}
                >
                  <DeleteRealEstateModal />
                  {data.length > 0 && !adding && (
                    <TableComponent
                      columns={columns}
                      data={data}
                      editField={editField}
                      onDeleteRow={onDeleteRow}
                      rawData={businessArray}
                    />
                  )}
                  {(data.length < 1 || adding) && (
                    <>
                      <Text
                        alignSelf={"stretch"}
                        color={"var(--Optiml---Deep-Ocean-Blue, #007EB5)"}
                        fontFamily={"Nunito"}
                        fontSize={"16px"}
                        fontStyle={"normal"}
                        fontWeight={"600"}
                        lineHeight={"normal"}
                        width={"100%"}
                      >
                        Corporation
                      </Text>
                      <NormalInputButton
                        Heading={"Title/Business name"}
                        value={companyName}
                        onChange={(e) => {
                          setCompanyName(e.target.value);
                        }}
                        customWidth={"100%"}
                        InputWidth={"100%"}
                        error={errors.companyName}
                        tooltipContent={TooltipContent.companyName}
                      />
                      <NormalInputButton
                        Heading={
                          "Enter the dollar value of your ownership of the business"
                        }
                        value={businessValue}
                        onChange={(e) => {
                          setBusinessValue(moneyToNumber(e.target.value));
                        }}
                        customWidth={"100%"}
                        InputWidth={"100%"}
                        placeholder={"$"}
                        useThousandSeparator={true}
                        error={errors.businessValue}
                        tooltipContent={TooltipContent.companyValue}
                      />
                      {/* <NormalInputButton
                        Heading={"Ownership"}
                        value={businessOwnership}
                        onChange={(e) => {
                          const noSymbol = e.target.value.replace("%", "");
                          const growthNum = parseFloat(noSymbol);
                          setBusinessOwnership(
                            !isNaN(growthNum)
                              ? parseFloat(growthNum.toFixed(2))
                              : 0
                          );
                        }}
                        placeholder={"%"}
                        usePercentage={true}
                        customWidth={"100%"}
                        InputWidth={"100%"}
                        error={errors.businessOwnership}
                        tooltipContent={TooltipContent.companyOwnership}
                      /> */}
                      <NormalInputButton
                        Heading={"Annual Growth"}
                        value={businessGrowthRate}
                        onChange={(e) => {
                          const noSymbol = e.target.value.replace("%", "");
                          const growthNum = parseFloat(noSymbol);
                          setBusinessGrowthRate(
                            !isNaN(growthNum)
                              ? parseFloat(growthNum.toFixed(2))
                              : 0
                          );
                        }}
                        placeholder={"%"}
                        usePercentage={true}
                        customWidth={"100%"}
                        InputWidth={"100%"}
                        error={errors.businessGrowthRate}
                        tooltipContent={TooltipContent.companyGrowth}
                      />
                      {/* <DropDownButton
                    Heading={"Province/Territory registered"}
                    placeholder={"Select"}
                    dropDownItems={[
                      { key: "AB", value: "Alberta" },

                      { key: "BC", value: "British Columbia" },

                      { key: "MB", value: "Manitoba" },

                      { key: "NB", value: "New Brunswick" },

                      { key: "NL", value: "Newfoundland and Labrador" },

                      { key: "NS", value: "Nova Scotia" },

                      { key: "ON", value: "Ontario" },

                      { key: "PE", value: "Prince Edward Island" },

                      { key: "QC", value: "Quebec" },

                      { key: "SK", value: "Saskatchewan" },

                      { key: "NT", value: "Northwest Territories" },

                      { key: "NU", value: "Nunavut" },

                      { key: "YT", value: "Yukon" },
                    ]}
                    value={businessLocation}
                    onChange={(key) => {
                      setBusinessLocation(key);
                    }}
                    customWidth={"100%"}
                    MenuCustomWidth={"100%"}
                  /> */}

                      <Text
                        alignSelf={"stretch"}
                        color={"var(--Optiml---Deep-Ocean-Blue, #007EB5)"}
                        fontFamily={"Nunito"}
                        fontSize={"16px"}
                        fontStyle={"normal"}
                        fontWeight={"600"}
                        lineHeight={"normal"}
                        width={"100%"}
                      >
                        Income
                      </Text>
                      {/* <DropDownButton
                    Heading={"Do you pay yourself an annual salary?"}
                    placeholder={"Select"}
                    dropDownItems={[
                      { key: "Yes", value: "Yes" },
                      { key: "No", value: "No" },
                    ]}
                    value={doBusinessDividends}
                    onChange={(key) => {
                      setDoBusinessDividends(key);
                    }}
                    customWidth={"100%"}
                    MenuCustomWidth={"100%"}
                  />
                  <NormalInputButton
                    Heading={"Annual salary amount?"}
                    value={businessDividendsSalary}
                    onChange={(e) => {
                      setBusinessDividendsSalary(moneyToNumber(e.target.value));
                    }}
                    placeholder={"$"}
                    useThousandSeparator={true}
                    customWidth={"100%"}
                    InputWidth={"100%"}
                  />
                  <NormalInputButton
                    Heading={"Annual salary growth rate"}
                    value={businessDividendsGrowth}
                    onChange={(e) => {
                          const noSymbol = e.target.value.replace("%", "");
                          const growthNum = parseFloat(noSymbol);
                          setBusinessDividendsGrowth(
                            !isNaN(growthNum)
                              ? parseFloat(growthNum.toFixed(2))
                              : 0
                          );
                        }}
                    placeholder={"%"}
                    usePercentage={true}
                    customWidth={"100%"}
                    InputWidth={"100%"}
                  /> */}
                      <DropDownButton
                        Heading={
                          "Do you pay yourself a dividend from your business's operational revenue?"
                        }
                        placeholder={"Select"}
                        dropDownItems={[
                          { key: "Yes", value: "Yes" },
                          { key: "No", value: "No" },
                        ]}
                        value={doBusinessDividends}
                        onChange={(key) => {
                          setDoBusinessDividends(key);
                        }}
                        customWidth={"100%"}
                        MenuCustomWidth={"100%"}
                        error={errors.doBusinessDividends}
                        tooltipContent={TooltipContent.companyDividends}
                      />
                      {doBusinessDividends === "Yes" && (
                        <>
                          <NormalInputButton
                            Heading={"Annual dividend ammount?"}
                            value={annualDividendAmount}
                            onChange={(e) => {
                              ``;
                              setAnnualDividendAmount(
                                moneyToNumber(e.target.value)
                              );
                            }}
                            placeholder={"$"}
                            useThousandSeparator={true}
                            customWidth={"100%"}
                            InputWidth={"100%"}
                            error={errors.annualDividendAmount}
                          />
                          <NormalInputButton
                            Heading={"How much was from Eligible dividends?"}
                            value={businessDividendsSalary}
                            onChange={(e) => {
                              setBusinessDividendsSalary(
                                moneyToNumber(e.target.value)
                              );
                            }}
                            placeholder={"$"}
                            useThousandSeparator={true}
                            customWidth={"100%"}
                            InputWidth={"100%"}
                            error={errors.businessDividendsSalary}
                            tooltipContent={
                              TooltipContent.companyEligibleDividends
                            }
                          />
                          <NormalInputButton
                            Heading={
                              "How much was from Non- Eligible dividends?"
                            }
                            value={businessDividendsSalaryNonEligible}
                            onChange={(e) => {
                              setBusinessDividendsSalaryNonEligible(
                                moneyToNumber(e.target.value)
                              );
                            }}
                            placeholder={"$"}
                            useThousandSeparator={true}
                            customWidth={"100%"}
                            InputWidth={"100%"}
                            error={errors.businessDividendsSalary}
                            tooltipContent={
                              TooltipContent.companyNonEligibleDividend
                            }
                          />
                          <NormalInputButton
                            Heading={"Annual dividend growth rate"}
                            value={businessDividendsGrowth}
                            onChange={(e) => {
                              const noSymbol = e.target.value.replace("%", "");
                              const growthNum = parseFloat(noSymbol);
                              setBusinessDividendsGrowth(
                                !isNaN(growthNum)
                                  ? parseFloat(growthNum.toFixed(2))
                                  : 0
                              );
                            }}
                            placeholder={"%"}
                            usePercentage={true}
                            customWidth={"100%"}
                            InputWidth={"100%"}
                            error={errors.businessDividendsGrowth}
                            tooltipContent={
                              TooltipContent.companyDividendGrowth
                            }
                          />
                        </>
                      )}

                      <Text
                        alignSelf={"stretch"}
                        color={"var(--Optiml---Deep-Ocean-Blue, #007EB5)"}
                        fontFamily={"Nunito"}
                        fontSize={"16px"}
                        fontStyle={"normal"}
                        fontWeight={"600"}
                        lineHeight={"normal"}
                        width={"100%"}
                      >
                        Future Real Estate Plans
                      </Text>
                      <DropDownButton
                        Heading={"Do you have plans to sell your business?"}
                        placeholder={"Select"}
                        dropDownItems={[
                          { key: "Yes", value: "Yes" },
                          { key: "No", value: "No" },
                        ]}
                        value={businessPlansToSell}
                        onChange={(key) => {
                          setBusinessPlansToSell(key);
                        }}
                        customWidth={"100%"}
                        MenuCustomWidth={"100%"}
                        error={errors.businessPlansToSell}
                        tooltipContent={TooltipContent.companySellYesNo}
                      />
                      {businessPlansToSell === "Yes" && (
                        <>
                          <DropDownButton
                            Heading={
                              "When do you anticipate you may sell your business?"
                            }
                            placeholder={"Select"}
                            dropDownItems={Select(currentAge)}
                            value={businessPlansToSellYear}
                            onChange={(key) => {
                              setBusinessPlansToSellYear(key);
                            }}
                            customWidth={"100%"}
                            MenuCustomWidth={"100%"}
                            error={errors.businessPlansToSellYear}
                            tooltipContent={TooltipContent.companySellYear}
                          />
                          {businessPlansToSellYear && (
                            <NormalInputButton
                              Heading={`Input estimated NET After-tax value of business in ${
                                currentYear +
                                businessPlansToSellYear -
                                getAge(currentAge, currentDate)
                              }`}
                              value={businessSellValue}
                              onChange={(e) => {
                                setBusinessSellValue(
                                  moneyToNumber(e.target.value)
                                );
                              }}
                              customWidth={"100%"}
                              InputWidth={"100%"}
                              placeholder={"$"}
                              useThousandSeparator={true}
                              error={errors.businessSellValue}
                              tooltipContent={TooltipContent.companySellValue}
                            />
                          )}
                        </>
                      )}
                    </>
                  )}
                </Box>
                {data.length > 0 && !adding && (
                  <Box
                    display={"flex"}
                    justifyContent={"flex-start"}
                    alignItems={"center"}
                    alignSelf={"stretch"}
                    onClick={handleClick}
                  >
                    <Box
                      display={"flex"}
                      padding={"8px 48px"}
                      marginTop={"20px"}
                      justifyContent={"flex-end"}
                      alignItems={"center"}
                      gap={"5px"}
                      borderRadius={"5px"}
                      cursor={"pointer"}
                      bg={"#27AE60"}
                    >
                      <Box
                        display={"flex"}
                        width={"76px"}
                        height={"21px"}
                        flexDirection={"column"}
                        justifyContent={"center"}
                      >
                        <Text
                          color={"#FFFFFF"}
                          textAlign={"center"}
                          fontFamily={"Nunito"}
                          fontSize={"16px"}
                          fontStyle={"normal"}
                          fontWeight={"600"}
                          lineHeight={"normal"}
                        >
                          Add
                        </Text>
                      </Box>
                    </Box>
                  </Box>
                )}
                {(data.length < 1 || adding) && (
                  <Box
                    display={"flex"}
                    justifyContent={"flex-end"}
                    alignItems={"center"}
                    alignSelf={"stretch"}
                  >
                    {renderAddUpdateRecreational()}
                  </Box>
                )}
              </>
            }
          />
        </VStack>
      )}
    </Flex>
  );
});

export default BusinessForm;
