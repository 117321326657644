import React, { useEffect, useState } from 'react';
import { CSVLink } from 'react-csv';
import CanvasJSReact from '../../canvasjs.react';
import CSVButton from '../../img/Dowload CSV Button.png';
import nextYearIcon from '../../img/Next Year Blue Button.png';
import pencilIcon from '../../img/pencilIcon.svg';
import './EndOfLifePage.css';

var { CanvasJS } = CanvasJSReact;
var { CanvasJSChart } = CanvasJSReact;

function EndOfLifePage(props) {
    const { optiList, optiNoise } = props;
    // const [total, setTotal] = useState([]);
    const [loading, setLoading] = useState(false);
    const [year, setYear] = useState(new Date().getFullYear());
    const [nextYear, setNextYear] = useState(year + 1);
    const [listPlace, setListPlace] = useState(0);
    const [ageStart, setAgeStart] = useState(false);
    const [ageEnd, setAgeEnd] = useState(true);
    const [otherAssets, setOtherAssets] = useState(false);
    const [maxValue, setMaxValue] = useState(false);

    const currentYear = new Date();

    function getAge(birthday, currentDate) {
        // Convert the birthday string into a Date object
        const birthDate = new Date(birthday);

        // Calculate the age in years
        let age = currentDate.getFullYear() - birthDate.getFullYear();

        // Decrement the age if the current month and day are before the birth month and day
        if (currentDate.getMonth() < birthDate.getMonth() || (currentDate.getMonth() === birthDate.getMonth() && currentDate.getDate() <= birthDate.getDate())) {
          age -= 1;
        }

        return age;
      }

    const perecentageWithdrawals = (total, withdraw) => {
        const percent = (withdraw / total) * 100;
        let x = Math.round(percent);
        if (x < 1 && x > 0) {
            x = 1;
        }
        return x;
    };

    const setPortfolioGraphData = (list) => {
      if (!list) {
        return []; // Return an empty array or some default value
      }
        const dataPoint = [];
        const finalAge = optiList?.sps1_registeredFunds?.length;
        const updatedDate = new Date();
        for (let i = 0; i < finalAge; i++) {
            updatedDate.setFullYear(currentYear.getFullYear() + i);
            const testAge = getAge(optiList?.sps1_birthday, updatedDate);
            dataPoint.push({ x: updatedDate.getFullYear(), y: Math.floor(list[i]), label: `${testAge.toString()}   ${(updatedDate.getFullYear()).toString()}` });
            if (list[i] > -2 && list[i] < 0.25) {
                list[i] = 0;
            }
        }
        return dataPoint;
    };

    const portfolioOptionsWithAssets = {
        animationEnabled: true,
        theme: 'light2',
        colorSet: 'oltreColoursSet1',
        height: 400,
        width: 1100,
        title: {
          text: 'Portfolio Balance',
        },
        toolTip: {
            shared: true,
        },
        axisX: {
            title: 'Year',
            labelMaxWidth: 35,
            labelTextAlign: 'right',
        },
        axisY: {
          title: 'Dollar Value',
          gridThickness: 0.3,
          includeZero: true,
          prefix: '$',
        },
        data: [
        {
            type: 'spline',
            name: 'Non-Registered',
            yValueFormatString: '$#,###',
            xValueFormatString: '####',
            showInLegend: true,
            legendText: 'Non-Registered',
            dataPoints: setPortfolioGraphData(optiList?.sps1_nonregisteredFunds),
          },
          {
            type: 'spline',
            name: 'Registered',
            yValueFormatString: '$#,###',
            xValueFormatString: '####',
            showInLegend: true,
            legendText: 'Registered',
            dataPoints: setPortfolioGraphData(optiList?.sps1_registeredFunds),
          },
          {
            type: 'spline',
            name: 'TFSA',
            yValueFormatString: '$#,###',
            xValueFormatString: '####',
            showInLegend: true,
            legendText: 'TFSA',
            dataPoints: setPortfolioGraphData(optiList?.sps1_tfsaFunds),
          },
          {
            type: 'spline',
            name: 'Other Assets',
            yValueFormatString: '$#,###',
            xValueFormatString: '####',
            showInLegend: true,
            legendText: 'Other Assets',
            dataPoints: setPortfolioGraphData(optiList?.realEstateValue.map((e, i) => e + optiList?.businessValue[i] + optiList?.lifeInsuranceValue[i] + optiList?.otherValue[i])),
          },
          {
            type: 'spline',
            name: 'Total',
            yValueFormatString: '$#,###',
            xValueFormatString: '####',
            showInLegend: true,
            legendText: 'Total Portfolio',
            dataPoints: setPortfolioGraphData(optiList?.sps1_nonregisteredFunds.map((e, i) => e + optiList?.sps1_registeredFunds[i] + optiList?.sps1_tfsaFunds[i] + optiList?.realEstateValue[i] + optiList?.businessValue[i] + optiList?.lifeInsuranceValue[i] + optiList?.otherValue[i])),
          },
    ],
      };

      const portfolioOptionsWithoutAssets = {
        animationEnabled: true,
        theme: 'light2',
        colorSet: 'oltreColoursSet1',
        height: 400,
        width: 1100,
        title: {
          text: 'Portfolio Balance',
        },
        toolTip: {
            shared: true,
        },
        axisX: {
            title: 'Year',
            labelMaxWidth: 35,
            labelTextAlign: 'right',
        },
        axisY: {
          title: 'Dollar Value',
          gridThickness: 0.3,
          includeZero: true,
          prefix: '$',
        },
        data: [
        {
            type: 'spline',
            name: 'Non-Registered',
            yValueFormatString: '$#,###',
            xValueFormatString: '####',
            showInLegend: true,
            legendText: 'Non-Registered',
            dataPoints: setPortfolioGraphData(optiList?.sps1_nonregisteredFunds),
          },
          {
            type: 'spline',
            name: 'Registered',
            yValueFormatString: '$#,###',
            xValueFormatString: '####',
            showInLegend: true,
            legendText: 'Registered',
            dataPoints: setPortfolioGraphData(optiList?.sps1_registeredFunds),
          },
          {
            type: 'spline',
            name: 'TFSA',
            yValueFormatString: '$#,###',
            xValueFormatString: '####',
            showInLegend: true,
            legendText: 'TFSA',
            dataPoints: setPortfolioGraphData(optiList?.sps1_tfsaFunds),
          },
          {
            type: 'spline',
            name: 'Total',
            yValueFormatString: '$#,###',
            xValueFormatString: '####',
            showInLegend: true,
            legendText: 'Total Portfolio',
            dataPoints: setPortfolioGraphData(optiList?.sps1_nonregisteredFunds.map((e, i) => e + optiList?.sps1_registeredFunds[i] + optiList?.sps1_tfsaFunds[i])),
          },
    ],
      };

      const currencyFormat = (money) => {
        var intTotal = Math.round(Math.round(money * 100) / 100);
        if (intTotal > -2 && intTotal < 0.25) {
            intTotal = 0;
        }
        var currencyCad = new Intl.NumberFormat('en-US', {
 style: 'currency',
            currency: 'USD',
minimumFractionDigits: 0,
}).format(intTotal);
        return currencyCad;
    };

    const currencyFormatList = (money) => {
        const moneyArr = [];
        for (let i = 0; i < money.length; i++) {
            var intTotal = Math.round(Math.round(money[i] * 100) / 100);
            if (intTotal > -2 && intTotal < 0.25) {
                intTotal = 0;
            }
            var currencyCad = new Intl.NumberFormat('en-US', {
 style: 'currency',
                currency: 'USD',
minimumFractionDigits: 0,
}).format(intTotal);
            moneyArr[i] = (currencyCad);
        }
        return moneyArr;
    };

    CanvasJS.addColorSet(
'oltreColoursSet1',
    [
        '#B3E5FC',
        '#4DD0E1',
        '#03A9F4',
        '#0288D1',
        '#01579B',
        '#303F9F',
        '#1A237E',
    ],
);

    const annualPortfolioOptionsWithAssets = {
        animationEnabled: true,
        theme: 'light2',
        colorSet: 'oltreColoursSet1',
        height: 500,
        width: 450,
        title: {
            text: 'Annual Portfolio Balance',
        },
        axisY: {
            title: 'Dollars',
            minimum: 0,
            prefix: '$',
        },
        data: [
            {
                type: 'column',
                yValueFormatString: '$#,###',
                dataPoints: [
                    { label: 'Non-Registered', y: optiList?.sps1_nonregisteredFunds?.[listPlace] },
                    { label: 'Registered', y: optiList?.sps1_registeredFunds?.[listPlace] },
                    { label: 'TFSA', y: optiList?.sps1_tfsaFunds?.[listPlace] },
                    { label: 'Other Assets', y: optiList?.realEstateValue?.[listPlace] + optiList?.lifeInsuranceValue?.[listPlace] + optiList?.businessValue?.[listPlace] + optiList?.otherValue?.[listPlace] },
                    { label: 'Total', y: optiList?.sps1_registeredFunds?.[listPlace] + optiList?.sps1_nonregisteredFunds?.[listPlace] + optiList?.sps1_tfsaFunds?.[listPlace] + optiList?.realEstateValue?.[listPlace] + optiList?.lifeInsuranceValue?.[listPlace] + optiList?.businessValue?.[listPlace] + optiList?.otherValue?.[listPlace] },
                ],
            },
        ],
    };

    const annualPortfolioOptionsWithoutAssets = {
        animationEnabled: true,
        theme: 'light2',
        colorSet: 'oltreColoursSet1',
        height: 500,
        width: 450,
        title: {
            text: 'Annual Portfolio Balance',
        },
        axisY: {
            title: 'Dollars',
            minimum: 0,
            prefix: '$',
        },
        data: [
            {
                type: 'column',
                yValueFormatString: '$#,###',
                dataPoints: [
                    { label: 'Non-Registered', y: optiList?.sps1_nonregisteredFunds?.[listPlace] },
                    { label: 'Registered', y: optiList?.sps1_registeredFunds?.[listPlace] },
                    { label: 'TFSA', y: optiList?.sps1_tfsaFunds?.[listPlace] },
                    { label: 'Total', y: optiList?.sps1_registeredFunds?.[listPlace] + optiList?.sps1_nonregisteredFunds?.[listPlace] + optiList?.sps1_tfsaFunds?.[listPlace] },
                ],
            },
        ],
    };

      const withdrawalsOptions = {
        animationEnabled: true,
        colorSet: 'oltreColoursSet1',
        theme: 'light2',
        height: 400,
        width: 500,
        title: {
            text: `Total Sources: ${currencyFormat(optiList?.sps1_salary?.[listPlace] + optiList?.sps1_pension?.Benefits?.Benefits?.Benefits?.Benefits?.[listPlace] + optiList?.sps1_cpp?.[listPlace] + optiList?.sps1_oas?.[listPlace] + optiList?.sps1_registeredWithdrawals?.[listPlace] + optiList?.sps1_nonregisteredWithdrawals?.[listPlace] + optiList?.sps1_tfsaWithdrawals?.[listPlace])}`,
        },
        data: [{
            type: 'pie',
            indexLabelFormatter(e) {
                if (e.dataPoint.y === 0) {
                    return '';
                }
                    return `${e.dataPoint.y}%`;
            },
            showInLegend: true,
            legendText: '{label}',
            toolTipContent: '{label}: <strong>{y}%</strong>',
            indexLabel: '{y}%',
            indexLabelPlacement: 'outside',
            dataPoints: [
                { y: perecentageWithdrawals((optiList?.sps1_registeredWithdrawals?.[listPlace] + optiList?.sps1_nonregisteredWithdrawals?.[listPlace] + optiList?.sps1_cpp?.[listPlace] + optiList?.sps1_oas?.[listPlace] + optiList?.sps1_pension?.Benefits?.[listPlace] + optiList?.sps1_salary?.[listPlace] + optiList?.sps1_tfsaWithdrawals?.[listPlace]), optiList?.sps1_salary?.[listPlace]), label: 'Salary' },
                { y: perecentageWithdrawals((optiList?.sps1_registeredWithdrawals?.[listPlace] + optiList?.sps1_nonregisteredWithdrawals?.[listPlace] + optiList?.sps1_cpp?.[listPlace] + optiList?.sps1_oas?.[listPlace] + optiList?.sps1_pension?.Benefits?.[listPlace] + optiList?.sps1_salary?.[listPlace] + optiList?.sps1_tfsaWithdrawals?.[listPlace]), optiList?.sps1_nonregisteredWithdrawals?.[listPlace]), label: 'Non-Registered' },
                { y: perecentageWithdrawals((optiList?.sps1_registeredWithdrawals?.[listPlace] + optiList?.sps1_nonregisteredWithdrawals?.[listPlace] + optiList?.sps1_cpp?.[listPlace] + optiList?.sps1_oas?.[listPlace] + optiList?.sps1_pension?.Benefits?.[listPlace] + optiList?.sps1_salary?.[listPlace] + optiList?.sps1_tfsaWithdrawals?.[listPlace]), optiList?.sps1_registeredWithdrawals?.[listPlace]), label: 'Registered' },
                { y: perecentageWithdrawals((optiList?.sps1_registeredWithdrawals?.[listPlace] + optiList?.sps1_nonregisteredWithdrawals?.[listPlace] + optiList?.sps1_cpp?.[listPlace] + optiList?.sps1_oas?.[listPlace] + optiList?.sps1_pension?.Benefits?.[listPlace] + optiList?.sps1_salary?.[listPlace] + optiList?.sps1_tfsaWithdrawals?.[listPlace]), optiList?.sps1_tfsaWithdrawals?.[listPlace]), label: 'TFSA' },
                { y: perecentageWithdrawals((optiList?.sps1_registeredWithdrawals?.[listPlace] + optiList?.sps1_nonregisteredWithdrawals?.[listPlace] + optiList?.sps1_cpp?.[listPlace] + optiList?.sps1_oas?.[listPlace] + optiList?.sps1_pension?.Benefits?.[listPlace] + optiList?.sps1_salary?.[listPlace] + optiList?.sps1_tfsaWithdrawals?.[listPlace]), optiList?.sps1_cpp?.[listPlace]), label: 'CPP' },
                { y: perecentageWithdrawals((optiList?.sps1_registeredWithdrawals?.[listPlace] + optiList?.sps1_nonregisteredWithdrawals?.[listPlace] + optiList?.sps1_cpp?.[listPlace] + optiList?.sps1_oas?.[listPlace] + optiList?.sps1_pension?.Benefits?.Benefits?.[listPlace] + optiList?.sps1_salary?.[listPlace] + optiList?.sps1_tfsaWithdrawals?.[listPlace]), optiList?.sps1_oas?.[listPlace]), label: 'OAS' },
                { y: perecentageWithdrawals((optiList?.sps1_registeredWithdrawals?.[listPlace] + optiList?.sps1_nonregisteredWithdrawals?.[listPlace] + optiList?.sps1_cpp?.[listPlace] + optiList?.sps1_oas?.[listPlace] + optiList?.sps1_pension?.Benefits?.[listPlace] + optiList?.sps1_salary?.[listPlace] + optiList?.sps1_tfsaWithdrawals?.[listPlace]), optiList?.sps1_pension?.Benefits?.[listPlace]), label: 'Pension' },
            ],

        }],
      };

    const yearHeaders = () => {
        const Headers = [];
        Headers[0] = '';
        const age = getAge(optiList?.sps1_birthday, currentYear);
        for (let i = 1; i < (optiList?.sps1_registeredFunds?.length + 1); i++) {
            Headers[i] = (age + i - 1).toString();
        }
        return Headers;
    };

    const optiListToCSV = (optiList) => {
        var data = [
            ['Name', optiList?.sps1_name, optiList?.sps1_LastName],
            ['Birthday', optiList?.sps1_birthday.toString()],
            ['Strategy', optiList?.optimizationType],
            ['End of Analysis Portfolio Balance', currencyFormat(optiList?.optimizedEOLAmount)],
            ['End of Analysis Asset Balance', currencyFormat(optiList?.realEstateValue[optiList?.sps1_registeredFunds?.length - 1] + optiList?.businessValue[optiList?.sps1_registeredFunds?.length - 1] + optiList?.lifeInsuranceValue[optiList?.sps1_registeredFunds?.length - 1] + optiList?.otherValue[optiList?.sps1_registeredFunds?.length - 1])],
            ['End of Analysis Total', currencyFormat(optiList?.optimizedEOLAmount + optiList?.realEstateValue[optiList?.sps1_registeredFunds?.length - 1] + optiList?.businessValue[optiList?.sps1_registeredFunds?.length - 1] + optiList?.lifeInsuranceValue[optiList?.sps1_registeredFunds?.length - 1] + optiList?.otherValue[optiList?.sps1_registeredFunds?.length - 1])],
            ['End of Analysis Compass found treasure', currencyFormat(optiList?.optimizedEOLSavings)],
            [''],

            ['Income'],
            ['Salary'].concat(currencyFormatList(optiList?.sps1_salary)),
            ['Pension'].concat(currencyFormatList(optiList?.sps1_pension?.Benefits)),
            ['CPP'].concat(currencyFormatList(optiList?.sps1_cpp)),
            ['OAS'].concat(currencyFormatList(optiList?.sps1_oas)),
            [''],

            ['Investments'],
            ['Registered Funds'].concat(currencyFormatList(optiList?.sps1_registeredFunds)),
            ['Non-Registered Funds'].concat(currencyFormatList(optiList?.sps1_nonregisteredFunds)),
            ['TFSA Funds'].concat(currencyFormatList(optiList?.sps1_tfsaFunds)),
            ['Adjusted Cost Base'].concat(currencyFormatList(optiList?.sps1_acb)),
            ['Total balance'].concat(currencyFormatList(optiList?.sps1_registeredFunds.map((e, i) => e + optiList?.sps1_nonregisteredFunds[i] + optiList?.sps1_tfsaFunds[i]))),
            ['Other Assets'].concat(currencyFormatList(optiList?.realEstateValue.map((e, i) => e + optiList?.businessValue[i] + optiList?.lifeInsuranceValue[i] + optiList?.otherValue[i]))),
            [''],

            ['Withdrawals'],
            ['Registered Withdrawals'].concat(currencyFormatList(optiList?.sps1_registeredWithdrawals)),
            ['Non-Registered Withdrawals'].concat(currencyFormatList(optiList?.sps1_nonregisteredWithdrawals)),
            ['TFSA Withdrawals'].concat(currencyFormatList(optiList?.sps1_tfsaWithdrawals)),
            ['Total Withdrawals'].concat(currencyFormatList(optiList?.sps1_registeredWithdrawals.map((e, i) => e + optiList?.sps1_nonregisteredWithdrawals[i] + optiList?.sps1_tfsaWithdrawals[i]))),
            [''],

            ['Income Taxes'].concat(currencyFormatList(optiList?.sps1_incomeTaxes)),
            ['CPP Contributions'].concat(currencyFormatList(optiList?.sps1_cppContributions)),
            ['EI Premiums'].concat(currencyFormatList(optiList?.sps1_eiPremiums)),
            ['Total'].concat(currencyFormatList(optiList?.sps1_incomeTaxes.map((e, i) => e + optiList?.sps1_cppContributions[i] + optiList?.sps1_eiPremiums[i]))),
            [''],

            ['Net After Tax Funds'].concat(currencyFormatList(optiList?.sps1_netWithdrawal)),
            ['Total Expenses'].concat(currencyFormatList(optiList?.expenses)),
            ['Net After Tax Funds - Expenses'].concat(currencyFormatList(optiList?.sps1_netWithdrawal.map((e, i) => e - optiList?.expenses[i]))),
            [''],

            ['Deposits'],
            ['Registered'].concat(currencyFormatList(optiList?.sps1_registeredContributions)),
            ['Non-Registered'].concat(currencyFormatList(optiList?.sps1_nonRegisteredContributions)),
            ['TFSA'].concat(currencyFormatList(optiList?.sps1_tfsaContributions)),
            ['Total Deposits'].concat(currencyFormatList(optiList?.sps1_registeredContributions.map((e, i) => e + optiList?.sps1_nonRegisteredContributions[i] + optiList?.sps1_tfsaContributions[i]))),

        ];
        return data;
    };

    const csvReport = {
        filename: `Optimized_Report_'${optiList?.sps1_name}'.csv`,
        headers: yearHeaders(),
        data: optiListToCSV(optiList),
    };

    const IncrementFunc = (e) => {
        e.preventDefault();
        const currentYear = new Date().getFullYear();
        if (year < ((optiList?.sps1_registeredFunds?.length + currentYear) - 2)) {
            setAgeEnd(true);
            setAgeStart(true);
             setListPlace(listPlace + 1);
             setYear(year + 1);
             setNextYear(nextYear + 1);
        }
        if (year === ((optiList?.sps1_registeredFunds?.length + currentYear) - 3)) {
            setAgeEnd(false);
        }
    };

    const DecrementFunc = (e) => {
        e.preventDefault();
        const currentYear = new Date().getFullYear();
        if (year > currentYear) {
            setAgeStart(true);
            setAgeEnd(true);
            setListPlace(listPlace - 1);
            setYear(year - 1);
            setNextYear(nextYear - 1);
        }
        if (year === currentYear + 1) {
            setAgeStart(false);
        }
    };

    useEffect(() => {
        setTimeout(() => {
            if (optiList?.IncludeAssets === true) {
                setOtherAssets(true);
            }
            setLoading(true);
        }, 100);
        // console.log('Other assets check', optiList?.IncludeAssets);

        const incrementYear = document.getElementById('incrementYear');
        const decremeantYear = document.getElementById('decremeantYear');

        incrementYear?.addEventListener('click', IncrementFunc);
        decremeantYear?.addEventListener('click', DecrementFunc);
        return () => {
            incrementYear?.removeEventListener('click', IncrementFunc);
            decremeantYear?.removeEventListener('click', DecrementFunc);
        };
    }, [listPlace]);

    useEffect(() => {
        if (optiNoise != null) {
            optiNoise.play();
        }
        if (optiList?.optimizationType === 'Max Value') {
            setMaxValue(true);
        }
    }, []);

    const averageTaxRate = (total, tax) => {
        const percent = (tax / total) * 100;
        const x = Math.round(percent);
        return x;
    };

    const sumArray = (arr) => {
        var sum = arr.reduce((a, b) => a + b, 0);
        return sum;
    };

    const withdrawCppAge = (cpp) => {
        var i = 0;
        var age = 0;
        while (i < cpp.length - 1) {
            if (cpp[i] > 0) {
                age = i;
                break;
            } else {
                age = getAge(optiList?.sps1_birthday, currentYear) * -1;
            }
            i += 1;
        }
        return age;
    };

    const yearToDate = (year) => {
      const date = new Date();
      date.setFullYear(year);
      return date;
    };

    const setRunDate = () => {
      if (optiList?.planRunDate !== null) {
        const dateOfPlan = new Date(optiList?.planRunDate);
        return dateOfPlan.toLocaleDateString();
      }

      const today = new Date();
      const dateOfPlan = today.toLocaleDateString();
      return dateOfPlan;
    };

    return (
      <div>
        <div className="container-center-horizontal">
          <div className="eol-home-page screen">
            <div className="flex-col-eol">
              <div className="last-run-strategy-header">Strategy:</div>
              <div className="last-run-strategy-value">{optiList?.optimizationType}</div>
              <div className="optimized-run-date-header">Date of Run:</div>
              <div className="optimized-run-date-value">{setRunDate()}</div>
              <div className="eol-container">

                <div className="rect-7297-opti" />
                <div className="rect-7291-opti" />
                <div className="rect-7304-opti" />
                <div className="spouse-1-box" />
                <div className="spouse-2-box" />
                <div className="annual-port-balance-box" />

                <div className="port-balance-graph-box" />
                <div className="eol-title">
                  {optiList?.sps1_name}
                  {' '}
                  {optiList?.sps1_LastName}
                  {' '}
                  Analysis Overview
                </div>
                {!otherAssets && <div className="portfolio-balance-title-top">Portfolio Balance</div>}
                {otherAssets
                                && <div className="portfolio-balance-title-top-other-assets">Portfolio and Assets Balance</div>}
                {!maxValue && <div className="estimated-portfolio-savings-title">Compass Score</div>}
                {maxValue && <div className="estimated-portfolio-savings-title">Compass Score</div>}
                <div className="marginal-tax-rate-title">Average Tax Rate</div>
                <div className="average-tax-rate-value">
                  {averageTaxRate((sumArray(optiList?.sps1_registeredWithdrawals) + sumArray(optiList?.sps1_nonregisteredWithdrawals) + sumArray(optiList?.sps1_cpp) + sumArray(optiList?.sps1_oas) + sumArray(optiList?.sps1_pension?.Benefits) + sumArray(optiList?.sps1_salary)), sumArray(optiList?.sps1_incomeTaxes))}
                  %
                </div>
                <div className="lifetime-results-header">End of Analysis Results</div>
                <div className="annual-results-header">Annual Results</div>
                <div className="line-15" />
                <div className="line-16" />
                <div className="line-17" />
                <div className="line-18" />
                <div className="edit-dashboard-box" />
                <div className="edit-dashboard-button">
                  <label className="edit-dashboard-text">Manage Dashboard</label>
                  <img className="edit-pecil-icon" src={pencilIcon} />
                </div>
                <CSVLink {...csvReport}>
                  <div className="csv-download-box" />
                  <div className="csv-download-button">
                    Download csv
                    <img className="csv-button-img" src={CSVButton} />
                  </div>
                </CSVLink>
                <div className="spouse-1-cpp-Withdrawal-age">CPP Withdrawal Age</div>
                <div className="s1-CPP-num">{getAge(optiList?.sps1_birthday, currentYear) + withdrawCppAge(optiList?.sps1_cpp)}</div>

                <div className="spouse-1-oas-Withdrawal-age">OAS Withdrawal Age</div>
                <div className="s1-OAS-num">{getAge(optiList?.sps1_birthday, currentYear) + withdrawCppAge(optiList?.sps1_oas)}</div>

                <div className="current-page-year">{year}</div>
                {ageStart
                                && <img id="decremeantYear" className="arrow-back" src={nextYearIcon} />}
                <div
                  className="next-page-year"
                >
                  Year
                </div>
                {ageEnd
                                && <img id="incrementYear" className="next-year-button" src={nextYearIcon} />}

                <div className="withdraw-box1" />
                <div className="withdraw-box2" />
                <div className="withdraw-box3" />
                <div className="withdraw-box4" />
                <div className="withdraw-box5" />
                <div className="withdraw-box6" />
                <div className="net-after-tax-box" />
                <div className="tax-box" />

                {/* Spouse 1 Income */}
                <div className="Withdrawals-header-opti">Income</div>
                <div className="Withdrawal-age-display">
                  Age
                  {getAge(optiList?.sps1_birthday, yearToDate(year))}
                </div>
                <div className="s1-withdraw-reg">Salary</div>
                <div className="s1-withdraw-reg-value">{currencyFormat(optiList?.sps1_salary?.[listPlace])}</div>
                <div className="s1-withdraw-non-reg">Dividends</div>
                <div className="s1-withdraw-non-reg-value">$0</div>
                <div className="s1-withdraw-cpp ">CPP</div>
                <div className="s1-withdraw-cpp-value">{currencyFormat(optiList?.sps1_cpp?.[listPlace])}</div>
                <div className="s1-withdraw-oas">OAS</div>
                <div className="s1-withdraw-oas-value">{currencyFormat(optiList?.sps1_oas?.[listPlace])}</div>
                <div className="s1-withdraw-pension">Pension</div>
                <div className="s1-withdraw-pension-value">{currencyFormat(optiList?.sps1_pension?.Benefits?.[listPlace])}</div>
                <div className="s1-withdraw-total">Total Income</div>
                <div className="s1-total-withdraw-value">{currencyFormat(optiList?.sps1_salary?.[listPlace] + optiList?.sps1_pension?.Benefits?.[listPlace] + optiList?.sps1_cpp?.[listPlace] + optiList?.sps1_oas?.[listPlace])}</div>

                {/* Desired Funds */}
                <div className="desired-funds-header">Calculated Deposits</div>
                <div className="desired-funds-total-header">Total</div>
                <div className="desired-funds-total-value">{currencyFormat(optiList?.sps1_registeredContributions?.[listPlace] + optiList?.sps1_nonRegisteredContributions?.[listPlace] + optiList?.sps1_tfsaContributions?.[listPlace])}</div>
                {/* Investments will be added */}
                <div className="tax-box3" />
                <div className="house-hold-expenses-header">Registered</div>
                <div className="household-expense-value">{currencyFormat(optiList?.sps1_registeredContributions?.[listPlace])}</div>
                <div className="desired-funds-box-2" />
                <div className="desired-funds-invest-header">Non-Registered</div>
                <div className="desired-funds-invest-value">{currencyFormat(optiList?.sps1_nonRegisteredContributions?.[listPlace])}</div>
                <div className="desired-funds-box-3" />
                <div className="deposits-box-tfsa">
                  <div className="deposits-tfsa-header">TFSA</div>
                  <div className="deposits-tfsa-value">{currencyFormat(optiList?.sps1_tfsaContributions?.[listPlace])}</div>
                </div>

                {/* After Tax Sources */}

                <div className="s1-withdraw-net">Net after-tax sources</div>
                <div className="net-after-tax-total-value-box" />
                <div className="net-after-tax-surplus-value-box" />
                {!maxValue && <div className="surplus-header">Maximized Funds</div>}
                {maxValue && <div className="surplus-header">Expenses</div>}
                <div className="surplus-value">{currencyFormat((optiList?.sps1_registeredWithdrawals?.[listPlace] + optiList?.sps1_nonregisteredWithdrawals?.[listPlace] + optiList?.sps1_tfsaWithdrawals?.[listPlace] + optiList?.sps1_cpp?.[listPlace] + optiList?.sps1_oas?.[listPlace] + optiList?.sps1_pension?.Benefits?.[listPlace] + optiList?.sps1_salary?.[listPlace]) - (optiList?.sps1_incomeTaxes?.[listPlace] + optiList?.sps1_cppContributions?.[listPlace] + optiList?.sps1_eiPremiums?.[listPlace] + optiList?.sps1_registeredContributions?.[listPlace] + optiList?.sps1_nonRegisteredContributions?.[listPlace] + optiList?.sps1_tfsaContributions?.[listPlace]))}</div>
                {!maxValue && (
                <div className="small-text-required-funds">
                  Required annual funds
                  {currencyFormat(optiList?.expenses?.[listPlace])}
                </div>
)}
                <div className="net-after-tax-total-value">Total</div>
                <div className="s1-withdraw-net-value">{currencyFormat((optiList?.sps1_registeredWithdrawals?.[listPlace] + optiList?.sps1_nonregisteredWithdrawals?.[listPlace] + optiList?.sps1_tfsaWithdrawals?.[listPlace] + optiList?.sps1_cpp?.[listPlace] + optiList?.sps1_oas?.[listPlace] + optiList?.sps1_pension?.Benefits?.[listPlace] + optiList?.sps1_salary?.[listPlace]) - (optiList?.sps1_incomeTaxes?.[listPlace] + optiList?.sps1_cppContributions?.[listPlace] + optiList?.sps1_eiPremiums?.[listPlace]))}</div>
                {loading
                && (
                <div className="piechart-container-withdrawals">
                  <CanvasJSChart options={withdrawalsOptions} />
                </div>
)}
                {loading
                && (
                <div className="barchart-container-withdrawals">
                  {otherAssets && <CanvasJSChart options={portfolioOptionsWithAssets} />}
                  {!otherAssets && <CanvasJSChart options={portfolioOptionsWithoutAssets} />}
                </div>
)}
                {loading
                && (
                <div className="annual-port-balance-graph-container">
                  {otherAssets && <CanvasJSChart options={annualPortfolioOptionsWithAssets} />}
                  {!otherAssets && <CanvasJSChart options={annualPortfolioOptionsWithoutAssets} />}
                </div>
)}
                {/* Withdraws */}
                <div className="taxes-header-opti">Calculated Withdrawals</div>
                <div className="tax-box1" />
                <div className="tax-box2" />
                <div className="tax-box4" />
                <div className="tax-box5" />
                <div className="s1-withdraw-income-tax">Total</div>
                <div className="s1-withdraw-income-tax-value">{currencyFormat(optiList?.sps1_registeredWithdrawals?.[listPlace] + optiList?.sps1_nonregisteredWithdrawals?.[listPlace] + optiList?.sps1_tfsaWithdrawals?.[listPlace])}</div>
                <div className="s1-annual-salary">Registered</div>
                <div className="s1-annual-salary-value">{currencyFormat(optiList?.sps1_registeredWithdrawals?.[listPlace])}</div>
                <div className="acb-small-print-header">
                  Adjusted Cost Base Value:
                  {currencyFormat(optiList?.sps1_acb?.[listPlace])}
                </div>
                <div className="non-reg-with-header">Non-Registered</div>
                <div className="s1-desired-expenses-value">{currencyFormat(optiList?.sps1_nonregisteredWithdrawals?.[listPlace])}</div>
                <div className="s1-tfsa-withdraw-header">TFSA</div>
                <div className="s1-tfsa-withdraw-value">{currencyFormat(optiList?.sps1_tfsaWithdrawals?.[listPlace])}</div>

                {/* Taxes */}
                <div className="all-tax-box" />
                <div className="actual-tax-header">Taxes</div>
                <div className="total-tax-box" />
                <div className="total-tax-header">Total</div>
                <div className="total-tax-value">{currencyFormat(optiList?.sps1_incomeTaxes?.[listPlace] + optiList?.sps1_cppContributions?.[listPlace] + optiList?.sps1_eiPremiums?.[listPlace])}</div>
                <div className="provincial-tax-box" />
                <div className="provincial-tax-header">Provincial Tax</div>
                <div className="provincial-tax-header-value">{currencyFormat(optiList?.sps1_provincialTaxes?.[listPlace])}</div>
                <div className="federal-tax-box" />
                <div className="federal-tax-header">Federal Tax</div>
                <div className="federal-tax-header-value">{currencyFormat(optiList?.sps1_federalTaxes?.[listPlace])}</div>
                <div className="average-tax-rate-box" />
                <div className="s1-average-tax-rate">Average Tax Rate</div>
                <div className="s1-average-tax-rate-value">
                  {averageTaxRate((optiList?.sps1_registeredWithdrawals?.[listPlace] + optiList?.sps1_nonregisteredWithdrawals?.[listPlace] + optiList?.sps1_cpp?.[listPlace] + optiList?.sps1_oas?.[listPlace] + optiList?.sps1_pension?.Benefits?.[listPlace] + optiList?.sps1_salary?.[listPlace]), optiList?.sps1_incomeTaxes?.[listPlace])}
                  %
                </div>
                <div className="marginal-tax-rate-box" />
                <div className="marginal-tax-header">CPP Contributions</div>
                <div className="marginal-tax-header-value">{currencyFormat(optiList?.sps1_cppContributions?.[listPlace])}</div>
                <div className="ei-premium-tax-rate-box" />
                <div className="ei-premium-tax-header">EI Premiums</div>
                <div className="ei-premium-tax-header-value">{currencyFormat(optiList?.sps1_eiPremiums?.[listPlace])}</div>

                {/* Annual Portfolio */}

                <div className="port-balance-main-box" />
                <div className="portfolio-balance-header">Portfolio Balance</div>
                <div className="portfolio-balance-box1" />
                <div className="portfolio-balance-box2" />
                <div className="portfolio-balance-box3" />
                <div className="portfolio-balance-box4" />
                <div className="s1-portfolio-balance-reg">Registered</div>
                <div className="s1-portfolio-balance-reg-value">{currencyFormat(optiList?.sps1_registeredFunds?.[listPlace])}</div>
                <div className="s1-portfolio-balance-non-reg">Non-registered</div>
                <div className="s1-portfolio-balance-non-reg-value">{currencyFormat(optiList?.sps1_nonregisteredFunds?.[listPlace])}</div>
                <div className="s1-portfolio-balance-total">Total</div>
                <div className="s1-portfolio-balance-total-value">{currencyFormat(optiList?.sps1_registeredFunds?.[listPlace] + optiList?.sps1_nonregisteredFunds?.[listPlace] + optiList?.sps1_tfsaFunds?.[listPlace])}</div>
                <div className="s1-portfolio-tfsa-header">TFSA</div>
                <div className="s1-portfolio-tfsa-value">{currencyFormat(optiList?.sps1_tfsaFunds?.[listPlace])}</div>

                {!otherAssets && <label className="max-value-response">{currencyFormat(optiList?.optimizedEOLAmount)}</label>}
                {otherAssets && <label className="max-value-response">{currencyFormat(optiList?.optimizedEOLAmount + optiList?.realEstateValue[optiList?.sps1_registeredFunds?.length - 1] + optiList?.businessValue[optiList?.sps1_registeredFunds?.length - 1] + optiList?.lifeInsuranceValue[optiList?.sps1_registeredFunds?.length - 1] + optiList?.otherValue[optiList?.sps1_registeredFunds?.length - 1])}</label> }
              </div>
            </div>
          </div>
        </div>
      </div>
    );
}

export default EndOfLifePage;
