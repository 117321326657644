import { Box, Flex, Text, VStack, useMediaQuery } from "@chakra-ui/react";
import axios from "axios";
import React, {
  forwardRef,
  useContext,
  useEffect,
  useImperativeHandle,
  useState,
} from "react";
import { useStore } from "../../../store";
import { useAuth0 } from "@auth0/auth0-react";
import GlobalBodyPage from "../../GlobalContainer/BodyPage";
import DropDownButton from "../../InputFields/dropDownButton";
import NormalInputButton from "../../InputFields/normalInputButton";
import TableComponent from "../../TableComponent";
import TooltipContent from "../../TooltipContent";
import { CloseIcon } from "../../CustomIcons";

const InvestmentFundsForm = forwardRef((props, ref) => {
  const { getAccessTokenSilently, user } = useAuth0();

  useImperativeHandle(ref, () => ({
    submitHandler,
    checkMandatoryFields,
  }));

  const submitHandler = () => {
    setUserEditAssetInfo({});
    return true;
  };

  const checkMandatoryFields = () => {
    return true;
  };

  const axiosInstance = axios.create();
  async function getToken() {
    // Replace this with your Auth0 token retrieval logic
    const token = await getAccessTokenSilently({
      audience:
        "https://os2lsiwff43wfpb5ggyawt6qri0mgcro.lambda-url.us-east-2.on.aws/",
      scope: "write:optimization",
    });
    return token;
  }

  // Add a request interceptor
  axiosInstance.interceptors.request.use(
    async (config) => {
      const token = await getToken();
      config.headers.Authorization = `Bearer ${token}`;
      return config;
    },
    (error) => {
      // Do something with request error
      return Promise.reject(error);
    }
  );
  const userEditAssetInfo = useStore((store) => store.userEditAssetInfo);
  const setUserEditAssetInfo = useStore((store) => store.setUserEditAssetInfo);

  const userBusinessInfo = useStore((store) => store.userBusinessInfo);
  const setUserBusinessInfo = useStore((store) => store.setUserBusinessInfo);
  const updateUserBusinessInfo = useStore(
    (store) => store.updateUserBusinessInfo
  );
  const deleteUserBusinessInfo = useStore(
    (store) => store.deleteUserBusinessInfo
  );

  // Business Investment Funds
  const [businessNumber, setBusinessNumber] = useState();
  const [businessTitle, setBusinessTitle] = useState("");
  const [ccpcValue, setCcpcValue] = useState(0);
  const [ccpcGrowth, setCcpcGrowth] = useState(0);
  const [businessLocation, setBusinessLocation] = useState("");
  const [ccpcIncomeBearing, setCcpcIncomeBearing] = useState("");
  const [businessHasDebt, setBusinessHasDebt] = useState("");
  const [businessDebt, setBusinessDebt] = useState(0);
  const [doBusinessInvestments, setDoBusinessInvestments] = useState("Yes");
  const [ccpcACB, setCcpcACB] = useState(0);
  const [businessOperationsFundsInvested, setBusinessOperationsFundsInvested] =
    useState(0);
  const [ERDTOH, setERDTOH] = useState(0);
  const [NERDTOH, setNERDTOH] = useState(0);
  const [GRIP, setGRIP] = useState(0);
  const [CDA, setCDA] = useState(0);
  const [businessOperationsFundsGrowth, setBusinessOperationsFundsGrowth] =
    useState(0);
  const [businessEligible, setBusinessEligible] = useState(0);
  const [businessNonEligible, setBusinessNonEligible] = useState(0);
  const [businessForeign, setBusinessForeign] = useState(0);

  const [isLargerThan768] = useMediaQuery("(min-width: 500px)");
  const isMobile = !isLargerThan768;

  const [businessArray, setBusinessArray] = useState([]);
  const [editing, setEditing] = useState(false);
  const [editingIndex, setEditingIndex] = useState(null);
  const [showDeleteModal, setShowDeleteModal] = useState(null);

  const [adding, setAdding] = useState(false);

  const userInfo = useStore((store) => store.userInfo);

  const {
    FirstName: firstName,
    LastName: lastName,
    DateOfBirth: currentAge,
    spouseFirstName,
    spouseLastName,
    runAge,
  } = userInfo;

  useEffect(() => {
    if (userEditAssetInfo?.editAsset?.name === "Investment Funds") {
      setBusinessTitle(userEditAssetInfo?.editAsset?.businessName);
      setCcpcValue(userEditAssetInfo?.editAsset?.ccpcValue);
      setCcpcGrowth(userEditAssetInfo?.editAsset?.ccpcGrowth);
      setCcpcIncomeBearing(userEditAssetInfo?.editAsset?.ccpcIncomeBearing);
      setBusinessHasDebt(userEditAssetInfo?.editAsset?.businessHasDebt);
      setBusinessDebt(userEditAssetInfo?.editAsset?.businessDebt);
      setDoBusinessInvestments(
        userEditAssetInfo?.editAsset?.doBusinessInvestments
      );
      setCcpcACB(userEditAssetInfo?.editAsset?.ccpcACB);
      setBusinessOperationsFundsInvested(
        userEditAssetInfo?.editAsset?.businessOperationsFundsInvested
      );
      setERDTOH(userEditAssetInfo?.editAsset?.ERDTOH);
      setNERDTOH(userEditAssetInfo?.editAsset?.NERDTOH);
      setCDA(userEditAssetInfo?.editAsset?.CDA);
      setGRIP(userEditAssetInfo?.editAsset?.GRIP);
      setBusinessOperationsFundsGrowth(
        userEditAssetInfo?.editAsset?.businessOperationsFundsGrowth
      );
      setBusinessEligible(userEditAssetInfo?.editAsset?.businessEligible);
      setBusinessNonEligible(userEditAssetInfo?.editAsset?.businessNonEligible);
      setBusinessForeign(userEditAssetInfo?.editAsset?.businessForeign);
      setBusinessLocation(userEditAssetInfo?.editAsset?.businessLocation);
      setBusinessNumber(userEditAssetInfo?.editAsset?.businessNumber);
      setEditing(true);
      setAdding(true);
      setEditingIndex(userEditAssetInfo?.editIndex);
    }
  }, [userEditAssetInfo]);

  useEffect(() => {
    if (
      userBusinessInfo?.length >= 1 &&
      userEditAssetInfo?.editAsset?.name != "Investment Funds"
    ) {
      setBusinessTitle(userBusinessInfo?.[0]?.businessName);
      setCcpcValue(userBusinessInfo?.[0]?.ccpcValue);
      setCcpcGrowth(userBusinessInfo?.[0]?.ccpcGrowth);
      setCcpcIncomeBearing(userBusinessInfo?.[0]?.ccpcIncomeBearing);
      setBusinessHasDebt(userBusinessInfo?.[0]?.businessHasDebt);
      setBusinessDebt(userBusinessInfo?.[0]?.businessDebt);
      setDoBusinessInvestments(userBusinessInfo?.[0]?.doBusinessInvestments);
      setCcpcACB(userBusinessInfo?.[0]?.ccpcACB);
      setBusinessOperationsFundsInvested(
        userBusinessInfo?.[0]?.businessOperationsFundsInvested
      );
      setERDTOH(userBusinessInfo?.[0]?.ERDTOH);
      setNERDTOH(userBusinessInfo?.[0]?.NERDTOH);
      setCDA(userBusinessInfo?.[0]?.CDA);
      setGRIP(userBusinessInfo?.[0]?.GRIP);
      setBusinessOperationsFundsGrowth(
        userBusinessInfo?.[0]?.businessOperationsFundsGrowth
      );
      setBusinessEligible(userBusinessInfo?.[0]?.businessEligible);
      setBusinessNonEligible(userBusinessInfo?.[0]?.businessNonEligible);
      setBusinessForeign(userBusinessInfo?.[0]?.businessForeign);
      setBusinessLocation(userBusinessInfo?.[0]?.businessLocation);
      setBusinessNumber(userBusinessInfo?.[0]?.businessNumber);
      setEditing(true);
      setAdding(true);
    }
  }, []);

  useEffect(() => {
    const updatedBusinessArray = [];

    if (userBusinessInfo != null) {
      updatedBusinessArray.push(...userBusinessInfo);
    }

    setBusinessArray(updatedBusinessArray);
  }, [userBusinessInfo]);

  const onDeleteRow = (i) => {
    setEditingIndex(i);
    setShowDeleteModal(true);
  };

  const editField = (i, array) => {
    setAdding(true);
    if (array[i].name === "Investment Funds") {
      setBusinessTitle(array[i]?.businessName);
      setCcpcValue(array[i]?.ccpcValue);
      setCcpcGrowth(array[i]?.ccpcGrowth);
      setCcpcIncomeBearing(array[i]?.ccpcIncomeBearing);
      setBusinessHasDebt(array[i]?.businessHasDebt);
      setBusinessDebt(array[i]?.businessDebt);
      setDoBusinessInvestments(array[i]?.doBusinessInvestments);
      setCcpcACB(array[i]?.ccpcACB);
      setBusinessOperationsFundsInvested(
        array[i]?.businessOperationsFundsInvested
      );
      setERDTOH(array[i]?.ERDTOH);
      setNERDTOH(array[i]?.NERDTOH);
      setCDA(array[i]?.CDA);
      setGRIP(array[i]?.GRIP);
      setBusinessOperationsFundsGrowth(array[i]?.businessOperationsFundsGrowth);
      setBusinessEligible(array[i]?.businessEligible);
      setBusinessNonEligible(array[i]?.businessNonEligible);
      setBusinessForeign(array[i]?.businessForeign);
      setBusinessLocation(array[i]?.businessLocation);
      setBusinessNumber(array[i]?.businessNumber);
    }
    setEditing(true);
    setEditingIndex(i);
  };

  const DeleteRealEstateModal = () => {
    let activeArray = businessArray;

    const removeRealEstateModal = () => {
      const deletePropertyURL = (action) =>
        `${process.env.REACT_APP_NODE_API_GATEWAY}/CompassLambdaApi/BUSINESS/${action}?businessNumber=${activeArray[editingIndex].businessNumber}`;
      axiosInstance.get(deletePropertyURL("delete")).then((res) => {
        console.log("Delete res", res);
        deleteUserBusinessInfo(businessArray[editingIndex].businessNumber);
        setEditingIndex(null);
        setShowDeleteModal(false);
        setEditing(false);
      });
    };

    if (!showDeleteModal) {
      return null;
    }
    const [yesClicked, setYesClicked] = useState(false);
    const [noClicked, setNoClicked] = useState(false);
    return (
      <div
        className="delete-income-modal"
        style={{
          display: "flex",
          alignItems: "center",
          width: "100%",
          maxWidth: "700px",
          justifyContent: "center",
          top: "50%",
          left: "50%",
          transform: isMobile
            ? "translate(-50%, -50%)"
            : "translate(-50%, -50%)",
        }}
        id="delete-income-modal"
      >
        <Box
          display={"flex"}
          width={"100%"}
          padding={"10px 10px 0px 0px"}
          marginLeft={"5px"}
          alignItems={"flex-end"}
          justifyContent={"flex-end"}
          onClick={() => setShowDeleteModal(false)}
          cursor={"pointer"}
        >
          <CloseIcon
            onClick={() => setShowDeleteModal(false)}
            svgheight={"20px"}
            svgwidth={"20px"}
          />
        </Box>
        <Box
          display={"flex"}
          padding={"0px 25px 25px 25px"}
          flexDirection={"column"}
          alignItems={"center"}
          gap={"10px"}
          alignSelf={"stretch"}
          width={"100%"}
        >
          <Text
            color={"#0093D0"}
            fontFamily={"Nunito"}
            fontSize={"24px"}
            fontStyle={"normal"}
            fontWeight={"600"}
            lineHeight={"normal"}
            textDecor={"underline"}
          >
            Are you sure you want to remove this business asset?
          </Text>
          <div className="content"></div>
          <div className="actions">
            <button
              className={`decision-button yes-button ${
                yesClicked ? "yes-clicked" : ""
              }`}
              onClick={() => {
                setYesClicked(true);
                setNoClicked(false);
                removeRealEstateModal();
              }}
            >
              Yes
            </button>
            <button
              className={`decision-button no-button ${
                noClicked ? "yes-clicked" : ""
              }`}
              onClick={() => {
                {
                  setNoClicked(true);
                  setYesClicked(false);
                  setShowDeleteModal(false);
                }
              }}
            >
              No
            </button>
          </div>
        </Box>
      </div>
    );
  };

  const moneyToNumber = (value) => {
    let numValue = value.toString().replace(/[$,\s]/g, "");
    numValue = Number(numValue);
    return numValue;
  };

  const onSubmitBusinessAdd = (e, newArray) => {
    e.preventDefault();
    if (newArray.name == "Investment Funds") {
      const primaryResURL = (action) =>
        `${
          process.env.REACT_APP_NODE_API_GATEWAY
        }/CompassLambdaApi/BUSINESS/${action}?businessID=${user.sub.replace(
          "|",
          "%7c"
        )}&name=${newArray.name}&businessName=${
          newArray.businessName
        }&ccpcValue=${newArray.ccpcValue}&ccpcGrowth=${
          newArray.ccpcGrowth
        }&ccpcIncomeBearing=${
          newArray.ccpcIncomeBearing
        }&doBusinessInvestments=${newArray.doBusinessInvestments}&ccpcACB=${
          newArray.ccpcACB
        }&businessEligible=${newArray.businessEligible}&businessNonEligible=${
          newArray.businessNonEligible
        }&businessForeign=${
          newArray.businessForeign
        }&businessOperationsFundsInvested=${
          newArray.businessOperationsFundsInvested
        }&businessOperationsFundsGrowth=${
          newArray.businessOperationsFundsGrowth
        }&ERDTOH=${newArray.ERDTOH}&NERDTOH=${newArray.NERDTOH}&GRIP=${
          newArray.GRIP
        }&CDA=${newArray.CDA}&businessHasDebt=${
          newArray.businessHasDebt
        }&businessDebt=${newArray.businessDebt}&businessLocation=${
          newArray.businessLocation
        }`;
      axiosInstance
        .get(primaryResURL("add"))
        .then((res) => {
          console.log(res);
          const newData = [
            {
              name: newArray.name,
              businessName: newArray.businessName,
              ccpcValue: newArray.ccpcValue,
              ccpcGrowth: newArray.ccpcGrowth,
              ccpcIncomeBearing: newArray.ccpcIncomeBearing,
              doBusinessInvestments: newArray.doBusinessInvestments,
              ccpcACB: newArray.ccpcACB,
              businessEligible: newArray.businessEligible,
              businessNonEligible: newArray.businessNonEligible,
              businessForeign: newArray.businessForeign,
              businessOperationsFundsInvested:
                newArray.businessOperationsFundsInvested,
              businessOperationsFundsGrowth:
                newArray.businessOperationsFundsGrowth,
              ERDTOH: newArray.ERDTOH,
              NERDTOH: newArray.NERDTOH,
              GRIP: newArray.GRIP,
              CDA: newArray.CDA,
              businessHasDebt: newArray.businessHasDebt,
              businessDebt: newArray.businessDebt,
              businessLocation: newArray.businessLocation,
              businessNumber: res.data.lastInsertedId,
            },
          ];
          setUserBusinessInfo(newData);
          setUserEditAssetInfo({});
        })
        .catch((error) => {
          console.error(error);
        });
    }
  };

  const onSubmitBusinessUpdate = (e, newArray) => {
    e.preventDefault();
    let x = newArray.businessNumber;
    if (newArray.name == "Investment Funds") {
      const otherAssetsURL = (action) =>
        `${
          process.env.REACT_APP_NODE_API_GATEWAY
        }/CompassLambdaApi/BUSINESS/${action}?businessID=${user.sub.replace(
          "|",
          "%7c"
        )}&name=${newArray.name}&businessName=${
          newArray.businessName
        }&ccpcValue=${newArray.ccpcValue}&ccpcGrowth=${
          newArray.ccpcGrowth
        }&ccpcIncomeBearing=${
          newArray.ccpcIncomeBearing
        }&doBusinessInvestments=${newArray.doBusinessInvestments}&ccpcACB=${
          newArray.ccpcACB
        }&businessEligible=${newArray.businessEligible}&businessNonEligible=${
          newArray.businessNonEligible
        }&businessForeign=${
          newArray.businessForeign
        }&businessOperationsFundsInvested=${
          newArray.businessOperationsFundsInvested
        }&businessOperationsFundsGrowth=${
          newArray.businessOperationsFundsGrowth
        }&ERDTOH=${newArray.ERDTOH}&NERDTOH=${newArray.NERDTOH}&GRIP=${
          newArray.GRIP
        }&CDA=${newArray.CDA}&businessHasDebt=${
          newArray.businessHasDebt
        }&businessDebt=${newArray.businessDebt}&businessLocation=${
          newArray.businessLocation
        }&businessNumber=${newArray.businessNumber}`;
      axiosInstance
        .get(otherAssetsURL("update"))
        .then((res) => {
          console.log(res);
          const newData = {
            name: newArray.name,
            businessName: newArray.businessName,
            ccpcValue: newArray.ccpcValue,
            ccpcGrowth: newArray.ccpcGrowth,
            ccpcIncomeBearing: newArray.ccpcIncomeBearing,
            doBusinessInvestments: newArray.doBusinessInvestments,
            ccpcACB: newArray.ccpcACB,
            businessEligible: newArray.businessEligible,
            businessNonEligible: newArray.businessNonEligible,
            businessForeign: newArray.businessForeign,
            businessOperationsFundsInvested:
              newArray.businessOperationsFundsInvested,
            businessOperationsFundsGrowth:
              newArray.businessOperationsFundsGrowth,
            ERDTOH: newArray.ERDTOH,
            NERDTOH: newArray.NERDTOH,
            GRIP: newArray.GRIP,
            CDA: newArray.CDA,
            businessHasDebt: newArray.businessHasDebt,
            businessDebt: newArray.businessDebt,
            businessLocation: newArray.businessLocation,
            businessNumber: newArray.businessNumber,
          };
          updateUserBusinessInfo(x, newData);
          setUserEditAssetInfo({});
        })
        .catch((error) => {
          console.error(error);
        });
    }
  };

  const addBusiness = (e, list) => {
    if (editing) {
      setBusinessArray((prevArray) => {
        let newArray = [...prevArray];
        newArray[editingIndex] = list;
        onSubmitBusinessUpdate(e, newArray[editingIndex]);
        return newArray;
      });
      setEditing(false);
      setEditingIndex(null);
    } else {
      setBusinessArray((prevArray) => {
        let newArray = [...prevArray, list];
        onSubmitBusinessAdd(e, newArray[newArray.length - 1]);
        return newArray;
      });
    }
    setBusinessTitle("");
    setCcpcValue(0);
    setCcpcGrowth(0);
    setCcpcIncomeBearing("");
    setBusinessHasDebt("");
    setBusinessDebt("");
    setDoBusinessInvestments("");
    setCcpcACB(0);
    setBusinessOperationsFundsInvested(0);
    setERDTOH(0);
    setNERDTOH(0);
    setGRIP(0);
    setCDA(0);
    setBusinessOperationsFundsGrowth(0);
    setBusinessEligible(0);
    setBusinessNonEligible(0);
    setBusinessForeign(0);
    setBusinessLocation("");
    setAdding(false);
  };

  const [errors, setErrors] = useState({});

  const checkMandatoryAddUpdateFields = () => {
    console.log("Test", errors);
    let newErrors = {};

    // Business property conditions
    if (!doBusinessInvestments) {
      newErrors.doBusinessInvestments =
        "Business investments information is required";
    }
    if (!businessLocation) {
      newErrors.businessLocation = "Business location is required";
    }
    if (doBusinessInvestments === "Yes") {
      if (!ccpcValue) {
        newErrors.ccpcValue = "CCPC value is required";
      }
      if (!ccpcACB) {
        newErrors.ccpcACB = "CCPC adjusted cost base is required";
      }
      if (!ccpcGrowth || ccpcGrowth > 20) {
        newErrors.ccpcGrowth =
          "CCPC growth is required and must be less than 20%";
      }
      if (businessOperationsFundsGrowth > 20) {
        newErrors.businessOperationsFundsGrowth =
          "CCPC reinvested funds must be less than 20%";
      }
      if (!ccpcIncomeBearing) {
        newErrors.ccpcIncomeBearing =
          "CCPC income bearing information is required";
      } else if (
        ccpcIncomeBearing === "Yes" &&
        businessEligible + businessNonEligible + businessForeign < 1
      ) {
        newErrors.ccpcIncomeBearing =
          "Total eligible, non-eligible, and foreign income must be at least $1.";
      }
      if (
        ccpcIncomeBearing === "Yes" &&
        businessEligible + businessNonEligible + businessForeign >
          (ccpcValue * ccpcGrowth) / 100
      ) {
        newErrors.businessEligible =
          "Total eligible, non-eligible, and foreign dividends must be less than total annual growth";
      }
      if (!businessHasDebt) {
        newErrors.businessHasDebt = "Business debt information is required";
      } else if (businessHasDebt === "Yes" && !businessDebt) {
        newErrors.businessDebt = "Business debt is required";
      }
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0; // Return true if no errors
  };

  const renderAddUpdateRecreational = () => {
    if (
      !doBusinessInvestments ||
      !businessLocation ||
      (doBusinessInvestments === "Yes" &&
        (!ccpcValue ||
          !ccpcACB ||
          !ccpcGrowth ||
          ccpcGrowth > 20 ||
          businessOperationsFundsGrowth > 20 ||
          !ccpcIncomeBearing ||
          (ccpcIncomeBearing === "Yes" &&
            businessEligible + businessNonEligible + businessForeign < 1) ||
          (ccpcIncomeBearing === "Yes" &&
            businessEligible + businessNonEligible + businessForeign >
              (ccpcValue * ccpcGrowth) / 100) ||
          !businessHasDebt ||
          (businessHasDebt === "Yes" && !businessDebt)))
    ) {
      return (
        <Box
          display={"flex"}
          padding={"8px 48px"}
          marginTop={"20px"}
          justifyContent={"flex-end"}
          alignItems={"center"}
          gap={"5px"}
          borderRadius={"5px"}
          bg={"#27AE60"}
          cursor={"pointer"}
          onClick={checkMandatoryAddUpdateFields}
        >
          <Box
            display={"flex"}
            width={"76px"}
            height={"21px"}
            flexDirection={"column"}
            justifyContent={"center"}
          >
            <Text
              color={"#FFFFFF"}
              textAlign={"center"}
              fontFamily={"Nunito"}
              fontSize={"16px"}
              fontStyle={"normal"}
              fontWeight={"600"}
              lineHeight={"normal"}
            >
              {editing ? "Update" : "Add"}
            </Text>
          </Box>
        </Box>
      );
    } else {
      return (
        <Box
          display={"flex"}
          padding={"8px 48px"}
          marginTop={"20px"}
          justifyContent={"flex-end"}
          alignItems={"center"}
          gap={"5px"}
          borderRadius={"5px"}
          bg={"#27AE60"}
          cursor={"pointer"}
          onClick={(e) =>
            addBusiness(e, {
              name: "Investment Funds",
              businessName: businessTitle,
              ccpcValue: ccpcValue,
              ccpcGrowth: ccpcGrowth,
              ccpcIncomeBearing: ccpcIncomeBearing,
              doBusinessInvestments: doBusinessInvestments,
              ccpcACB: ccpcACB,
              businessEligible: businessEligible,
              businessNonEligible: businessNonEligible,
              businessForeign: businessForeign,
              businessOperationsFundsInvested: businessOperationsFundsInvested,
              businessOperationsFundsGrowth: businessOperationsFundsGrowth,
              ERDTOH: ERDTOH,
              NERDTOH: NERDTOH,
              GRIP: GRIP,
              CDA: CDA,
              businessHasDebt: businessHasDebt,
              businessDebt: businessDebt,
              businessLocation: businessLocation,
              ...(editing ? { businessNumber: businessNumber } : {}), // add field conditionally
            })
          }
        >
          <Box
            display={"flex"}
            width={"76px"}
            height={"21px"}
            flexDirection={"column"}
            justifyContent={"center"}
            cursor={"pointer"}
          >
            <Text
              color={"#FFFFFF"}
              textAlign={"center"}
              fontFamily={"Nunito"}
              fontSize={"16px"}
              fontStyle={"normal"}
              fontWeight={"600"}
              lineHeight={"normal"}
            >
              {editing ? "Update" : "Add"}
            </Text>
          </Box>
        </Box>
      );
    }
  };

  const columns = [
    { header: "Name", field: "name" },
    { header: "Value", field: "value" },
  ];

  const data = businessArray.map((item) => ({
    name: item.businessName,
    value: item?.ccpcValue,
    link: "/investment-funds-form",
  }));

  const handleClick = () => {
    setAdding(true);
  };

  function getAge(birthday, currentDate) {
    // Convert the birthday string into a Date object
    const birthDate = new Date(birthday);

    // Calculate the age in years
    let age = currentDate.getFullYear() - birthDate.getFullYear();

    // Decrement the age if the current month and day are before the birth month and day
    if (
      currentDate.getMonth() < birthDate.getMonth() ||
      (currentDate.getMonth() === birthDate.getMonth() &&
        currentDate.getDate() <= birthDate.getDate())
    ) {
      age -= 1;
    }

    return age;
  }

  const Select = (userAge) => {
    const currentDate = new Date();
    const currentYear = currentDate.getFullYear();
    let age = getAge(userAge, currentDate); // Assuming getAge returns the starting age.
    const runAgeList = [];
    for (let i = age; i <= 100; i++) {
      runAgeList.push({ key: i, value: currentYear + (i - age) }); // Convert i to string if necessary
    }
    return runAgeList;
  };

  const [isEditing, setIsEditing] = useState(false);

  return (
    <Flex
      display="flex"
      flexWrap="wrap" // Enable wrapping
      justifyContent="flex-start"
      alignItems="start"
      width={"100%"}
    >
      {!isMobile && (
        <VStack alignItems={"flex-start"} width={"100%"} mt={-2}>
          <GlobalBodyPage
            Heading="Investment Fund"
            mainframeContent={
              <>
                <Box
                  display={"flex"}
                  width={"100%"}
                  flexDirection={"row"}
                  paddingTop={"10px"}
                  alignItems={"flex-end"}
                  justifyContent={"space-between"}
                  gap={"10px"}
                  flexWrap={"wrap"}
                >
                  <DeleteRealEstateModal />
                  {data.length > 0 && !adding && (
                    <TableComponent
                      columns={columns}
                      data={data}
                      editField={editField}
                      onDeleteRow={onDeleteRow}
                      rawData={businessArray}
                    />
                  )}
                  {(data.length < 1 || adding) && (
                    <>
                      <Text
                        alignSelf={"stretch"}
                        color={"var(--Optiml---Deep-Ocean-Blue, #007EB5)"}
                        fontFamily={"Nunito"}
                        fontSize={"16px"}
                        fontStyle={"normal"}
                        fontWeight={"600"}
                        lineHeight={"normal"}
                        width={"100%"}
                      >
                        Corporation
                      </Text>
                      <NormalInputButton
                        Heading={"Title/Business Fund name"}
                        value={businessTitle}
                        onChange={(e) => {
                          setBusinessTitle(e.target.value);
                        }}
                        customWidth={"100%"}
                        InputWidth={"100%"}
                        error={errors.businessTitle}
                        tooltipContent={TooltipContent.holdingName}
                      />
                      <DropDownButton
                        Heading={"Province/Territory registered"}
                        placeholder={"Select"}
                        dropDownItems={[
                          { key: "AB", value: "Alberta" },

                          { key: "BC", value: "British Columbia" },

                          { key: "MB", value: "Manitoba" },

                          { key: "NB", value: "New Brunswick" },

                          { key: "NL", value: "Newfoundland and Labrador" },

                          { key: "NS", value: "Nova Scotia" },

                          { key: "ON", value: "Ontario" },

                          { key: "PE", value: "Prince Edward Island" },

                          { key: "QC", value: "Quebec" },

                          { key: "SK", value: "Saskatchewan" },

                          { key: "NT", value: "Northwest Territories" },

                          { key: "NU", value: "Nunavut" },

                          { key: "YT", value: "Yukon" },
                        ]}
                        value={businessLocation}
                        onChange={(key) => {
                          setBusinessLocation(key);
                        }}
                        customWidth={"100%"}
                        error={errors.businessLocation}
                        tooltipContent={TooltipContent.holdingProvince}
                      />
                      {/* Investments section */}
                      <Text
                        alignSelf={"stretch"}
                        color={"var(--Optiml---Deep-Ocean-Blue, #007EB5)"}
                        fontFamily={"Nunito"}
                        fontSize={"16px"}
                        fontStyle={"normal"}
                        fontWeight={"600"}
                        lineHeight={"normal"}
                        width={"100%"}
                      >
                        Investments
                      </Text>
                      <NormalInputButton
                        Heading={"Investment fund value"}
                        value={ccpcValue}
                        onChange={(e) => {
                          setCcpcValue(moneyToNumber(e.target.value));
                        }}
                        placeholder={"$"}
                        useThousandSeparator={true}
                        error={errors.ccpcValue}
                        tooltipContent={TooltipContent.holdingValue}
                      />
                      <NormalInputButton
                        Heading={"Book Value of investments fund"}
                        value={ccpcACB}
                        onChange={(e) => {
                          setCcpcACB(moneyToNumber(e.target.value));
                        }}
                        placeholder={"$"}
                        useThousandSeparator={true}
                        error={errors.ccpcACB}
                        tooltipContent={TooltipContent.holdingACB}
                      />
                      <NormalInputButton
                        Heading={"Expected annual rate of return"}
                        value={ccpcGrowth}
                        onChange={(e) => {
                          const noSymbol = e.target.value.replace("%", "");
                          const growthNum = parseFloat(noSymbol);
                          setCcpcGrowth(
                            !isNaN(growthNum)
                              ? parseFloat(growthNum.toFixed(2))
                              : 0
                          );
                        }}
                        placeholder={"%"}
                        usePercentage={true}
                        customWidth={"100%"}
                        error={errors.ccpcGrowth}
                        tooltipContent={TooltipContent.holdingGrowth}
                      />
                      {/* Dividends section */}
                      <Text
                        alignSelf={"stretch"}
                        color={"var(--Optiml---Deep-Ocean-Blue, #007EB5)"}
                        fontFamily={"Nunito"}
                        fontSize={"16px"}
                        fontStyle={"normal"}
                        fontWeight={"600"}
                        lineHeight={"normal"}
                        width={"100%"}
                      >
                        Dividends
                      </Text>
                      <DropDownButton
                        Heading={"Are any of the investments income bearing?"}
                        placeholder={"Select"}
                        dropDownItems={[
                          { key: "Yes", value: "Yes" },
                          { key: "No", value: "No" },
                        ]}
                        value={ccpcIncomeBearing}
                        onChange={(key) => {
                          setCcpcIncomeBearing(key);
                        }}
                        customWidth={"100%"}
                        error={errors.ccpcIncomeBearing}
                        tooltipContent={
                          TooltipContent.holdingIncomeBearingYesNo
                        }
                      />
                      {ccpcIncomeBearing === "Yes" && (
                        <>
                          <NormalInputButton
                            Heading={"Annual Eligible dividend income"}
                            value={businessEligible}
                            onChange={(e) => {
                              setBusinessEligible(
                                moneyToNumber(e.target.value)
                              );
                            }}
                            placeholder={"$"}
                            useThousandSeparator={true}
                            error={errors.businessEligible}
                            tooltipContent={
                              TooltipContent.holdingEligibleDividendIncome
                            }
                          />
                          <NormalInputButton
                            Heading={
                              "Annual Non-Eligible income dividend income"
                            }
                            value={businessNonEligible}
                            onChange={(e) => {
                              setBusinessNonEligible(
                                moneyToNumber(e.target.value)
                              );
                            }}
                            placeholder={"$"}
                            useThousandSeparator={true}
                            error={errors.businessEligible}
                            tooltipContent={
                              TooltipContent.holdingNonEligibleDividendIncome
                            }
                          />
                          <NormalInputButton
                            Heading={"Annual Foreign income dividend income"}
                            value={businessForeign}
                            onChange={(e) => {
                              setBusinessForeign(moneyToNumber(e.target.value));
                            }}
                            placeholder={"$"}
                            useThousandSeparator={true}
                            error={errors.businessEligible}
                            tooltipContent={
                              TooltipContent.holdingForeignDividendIncome
                            }
                          />
                        </>
                      )}

                      {/* Operational Earnings section */}
                      <Text
                        alignSelf={"stretch"}
                        color={"var(--Optiml---Deep-Ocean-Blue, #007EB5)"}
                        fontFamily={"Nunito"}
                        fontSize={"16px"}
                        fontStyle={"normal"}
                        fontWeight={"600"}
                        lineHeight={"normal"}
                        width={"100%"}
                      >
                        Operational Earnings and Contributions
                      </Text>
                      <NormalInputButton
                        Heading={
                          "After paying taxes on your annual earnings from operations, how much do you plan to invest in your investment fund this year?"
                        }
                        value={businessOperationsFundsInvested}
                        onChange={(e) => {
                          setBusinessOperationsFundsInvested(
                            moneyToNumber(e.target.value)
                          );
                        }}
                        placeholder={"$"}
                        useThousandSeparator={true}
                        customWidth={"100%"}
                        error={errors.businessOperationsFundsInvested}
                        tooltipContent={TooltipContent.holdingReinvest}
                      />
                      <NormalInputButton
                        Heading={
                          "As you make annual contributions to your investment fund, by what percentage do you expect these contributions to increase each year?"
                        }
                        value={businessOperationsFundsGrowth}
                        onChange={(e) => {
                          const noSymbol = e.target.value.replace("%", "");
                          const growthNum = parseFloat(noSymbol);
                          setBusinessOperationsFundsGrowth(
                            !isNaN(growthNum)
                              ? parseFloat(growthNum.toFixed(2))
                              : 0
                          );
                        }}
                        placeholder={"%"}
                        usePercentage={true}
                        customWidth={"100%"}
                        error={errors.businessOperationsFundsGrowth}
                        tooltipContent={TooltipContent.holdingReinvestGrowth}
                      />
                      {/* Taxation section */}
                      <Text
                        alignSelf={"stretch"}
                        color={"var(--Optiml---Deep-Ocean-Blue, #007EB5)"}
                        fontFamily={"Nunito"}
                        fontSize={"16px"}
                        fontStyle={"normal"}
                        fontWeight={"600"}
                        lineHeight={"normal"}
                        width={"100%"}
                      >
                        Taxation
                      </Text>
                      <NormalInputButton
                        Heading={
                          "Eligible Refundable Dividend Tax on Hand (ERDTOH)"
                        }
                        value={ERDTOH}
                        onChange={(e) => {
                          setERDTOH(moneyToNumber(e.target.value));
                        }}
                        placeholder={"$"}
                        useThousandSeparator={true}
                        error={errors.ERDTOH}
                        tooltipContent={TooltipContent.holdingERDTOH}
                      />
                      <NormalInputButton
                        Heading={
                          "Non-eligible Refundable Dividend Tax on Hand (NERDTOH)"
                        }
                        value={NERDTOH}
                        onChange={(e) => {
                          setNERDTOH(moneyToNumber(e.target.value));
                        }}
                        placeholder={"$"}
                        useThousandSeparator={true}
                        error={errors.NERDTOH}
                        tooltipContent={TooltipContent.holdingERDTOH}
                      />
                      <NormalInputButton
                        Heading={"General rate income pool (GRIP) balance"}
                        value={GRIP}
                        onChange={(e) => {
                          setGRIP(moneyToNumber(e.target.value));
                        }}
                        placeholder={"$"}
                        useThousandSeparator={true}
                        error={errors.GRIP}
                        tooltipContent={TooltipContent.holdingGRIP}
                      />
                      <NormalInputButton
                        Heading={
                          "Capital Dividend Account (CDA) Opening Balance?"
                        }
                        value={CDA}
                        onChange={(e) => {
                          setCDA(moneyToNumber(e.target.value));
                        }}
                        placeholder={"$"}
                        useThousandSeparator={true}
                        error={errors.CDA}
                        tooltipContent={TooltipContent.holdingCDA}
                      />
                      {/* Debt section */}
                      <Text
                        alignSelf={"stretch"}
                        color={"var(--Optiml---Deep-Ocean-Blue, #007EB5)"}
                        fontFamily={"Nunito"}
                        fontSize={"16px"}
                        fontStyle={"normal"}
                        fontWeight={"600"}
                        lineHeight={"normal"}
                        width={"100%"}
                      >
                        Debt
                      </Text>
                      <DropDownButton
                        Heading={
                          "Do you currently have a personal loan to the fund?"
                        }
                        placeholder={"Select"}
                        dropDownItems={[
                          { key: "Yes", value: "Yes" },
                          { key: "No", value: "No" },
                        ]}
                        value={businessHasDebt}
                        onChange={(key) => {
                          setBusinessHasDebt(key);
                        }}
                        customWidth={"100%"}
                        error={errors.businessHasDebt}
                        tooltipContent={TooltipContent.holdingLoanYesNo}
                      />
                      {businessHasDebt === "Yes" && (
                        <NormalInputButton
                          Heading={"Current value of your repayable loan"}
                          value={businessDebt}
                          onChange={(e) => {
                            setBusinessDebt(moneyToNumber(e.target.value));
                          }}
                          placeholder={"$"}
                          useThousandSeparator={true}
                          error={errors.businessDebt}
                          tooltipContent={TooltipContent.holdingLoan}
                        />
                      )}
                    </>
                  )}
                </Box>
                {/* {data.length > 0 && !adding && (
                  <Box
                    display={"flex"}
                    justifyContent={"flex-end"}
                    alignItems={"center"}
                    alignSelf={"stretch"}
                  >
                    <Box
                      display={"flex"}
                      padding={"8px 48px"}
                      marginTop={"20px"}
                      justifyContent={"flex-end"}
                      alignItems={"center"}
                      gap={"5px"}
                      borderRadius={"5px"}
                      bg={"#27AE60"}
                    >
                      <Box
                        display={"flex"}
                        width={"76px"}
                        height={"21px"}
                        flexDirection={"column"}
                        justifyContent={"center"}
                      >
                        <Text
                          color={"#FFFFFF"}
                          textAlign={"center"}
                          fontFamily={"Nunito"}
                          fontSize={"16px"}
                          fontStyle={"normal"}
                          fontWeight={"600"}
                          lineHeight={"normal"}
                        >
                          Add
                        </Text>
                      </Box>
                    </Box>
                  </Box>
                )} */}
                {(data.length < 1 || adding) && (
                  <Box
                    display={"flex"}
                    justifyContent={"flex-end"}
                    alignItems={"center"}
                    alignSelf={"stretch"}
                  >
                    {renderAddUpdateRecreational()}
                  </Box>
                )}
              </>
            }
          />
        </VStack>
      )}
      {isMobile && (
        <VStack alignItems={"flex-start"} width={"100%"} mt={-2}>
          <GlobalBodyPage
            Heading="Business"
            mainframeContent={
              <>
                <Box
                  display={"flex"}
                  width={"100%"}
                  flexDirection={"row"}
                  paddingTop={"10px"}
                  alignItems={"flex-end"}
                  justifyContent={"space-between"}
                  gap={"10px"}
                  flexWrap={"wrap"}
                >
                  <DeleteRealEstateModal />
                  {data.length > 0 && (
                    <TableComponent
                      columns={columns}
                      data={data}
                      editField={editField}
                      onDeleteRow={onDeleteRow}
                      rawData={businessArray}
                    />
                  )}
                  {(data.length < 1 || adding) && (
                    <>
                      <Text
                        alignSelf={"stretch"}
                        color={"var(--Optiml---Deep-Ocean-Blue, #007EB5)"}
                        fontFamily={"Nunito"}
                        fontSize={"16px"}
                        fontStyle={"normal"}
                        fontWeight={"600"}
                        lineHeight={"normal"}
                        width={"100%"}
                      >
                        Corporation
                      </Text>
                      <NormalInputButton
                        Heading={"Title/Business Fund name"}
                        value={businessTitle}
                        onChange={(e) => {
                          setBusinessTitle(e.target.value);
                        }}
                        customWidth={"100%"}
                        InputWidth={"100%"}
                        error={errors.businessTitle}
                        tooltipContent={TooltipContent.holdingName}
                      />
                      <DropDownButton
                        Heading={"Province/Territory registered"}
                        placeholder={"Select"}
                        dropDownItems={[
                          { key: "AB", value: "Alberta" },
                          { key: "BC", value: "British Columbia" },
                          { key: "MB", value: "Manitoba" },
                          { key: "NB", value: "New Brunswick" },
                          { key: "NL", value: "Newfoundland and Labrador" },
                          { key: "NS", value: "Nova Scotia" },
                          { key: "ON", value: "Ontario" },
                          { key: "PE", value: "Prince Edward Island" },
                          { key: "QC", value: "Quebec" },
                          { key: "SK", value: "Saskatchewan" },
                          { key: "NT", value: "Northwest Territories" },
                          { key: "NU", value: "Nunavut" },
                          { key: "YT", value: "Yukon" },
                        ]}
                        value={businessLocation}
                        onChange={(key) => {
                          setBusinessLocation(key);
                        }}
                        customWidth={"100%"}
                        MenuCustomWidth={"100%"}
                        error={errors.businessLocation}
                        tooltipContent={TooltipContent.holdingProvince}
                      />
                      {/* Investments section */}
                      <Text
                        alignSelf={"stretch"}
                        color={"var(--Optiml---Deep-Ocean-Blue, #007EB5)"}
                        fontFamily={"Nunito"}
                        fontSize={"16px"}
                        fontStyle={"normal"}
                        fontWeight={"600"}
                        lineHeight={"normal"}
                        width={"100%"}
                      >
                        Investments
                      </Text>
                      <NormalInputButton
                        Heading={"Investment fund value"}
                        value={ccpcValue}
                        onChange={(e) => {
                          setCcpcValue(moneyToNumber(e.target.value));
                        }}
                        placeholder={"$"}
                        useThousandSeparator={true}
                        customWidth={"100%"}
                        InputWidth={"100%"}
                        error={errors.ccpcValue}
                        tooltipContent={TooltipContent.holdingValue}
                      />
                      <NormalInputButton
                        Heading={"Book Value of investments fund"}
                        value={ccpcACB}
                        onChange={(e) => {
                          setCcpcACB(moneyToNumber(e.target.value));
                        }}
                        placeholder={"$"}
                        useThousandSeparator={true}
                        customWidth={"100%"}
                        InputWidth={"100%"}
                        error={errors.ccpcACB}
                        tooltipContent={TooltipContent.holdingACB}
                      />
                      <NormalInputButton
                        Heading={"Expected annual rate of return"}
                        value={ccpcGrowth}
                        onChange={(e) => {
                          const noSymbol = e.target.value.replace("%", "");
                          const growthNum = parseFloat(noSymbol);
                          setCcpcGrowth(
                            !isNaN(growthNum)
                              ? parseFloat(growthNum.toFixed(2))
                              : 0
                          );
                        }}
                        placeholder={"%"}
                        usePercentage={true}
                        customWidth={"100%"}
                        InputWidth={"100%"}
                        error={errors.ccpcGrowth}
                        tooltipContent={TooltipContent.holdingGrowth}
                      />
                      {/* Dividends section */}
                      <Text
                        alignSelf={"stretch"}
                        color={"var(--Optiml---Deep-Ocean-Blue, #007EB5)"}
                        fontFamily={"Nunito"}
                        fontSize={"      16px"}
                        fontStyle={"normal"}
                        fontWeight={"600"}
                        lineHeight={"normal"}
                        width={"100%"}
                      >
                        Dividends
                      </Text>
                      <DropDownButton
                        Heading={"Are any of the investments income bearing?"}
                        placeholder={"Select"}
                        dropDownItems={[
                          { key: "Yes", value: "Yes" },
                          { key: "No", value: "No" },
                        ]}
                        value={ccpcIncomeBearing}
                        onChange={(key) => {
                          setCcpcIncomeBearing(key);
                        }}
                        customWidth={"100%"}
                        MenuCustomWidth={"100%"}
                        error={errors.ccpcIncomeBearing}
                        tooltipContent={
                          TooltipContent.holdingIncomeBearingYesNo
                        }
                      />
                      {ccpcIncomeBearing === "Yes" && (
                        <>
                          <NormalInputButton
                            Heading={"Annual Eligible dividend income"}
                            value={businessEligible}
                            onChange={(e) => {
                              setBusinessEligible(
                                moneyToNumber(e.target.value)
                              );
                            }}
                            placeholder={"$"}
                            useThousandSeparator={true}
                            customWidth={"100%"}
                            InputWidth={"100%"}
                            error={errors.businessEligible}
                            tooltipContent={
                              TooltipContent.holdingEligibleDividendIncome
                            }
                          />
                          <NormalInputButton
                            Heading={"Annual Non-Eligible dividend income"}
                            value={businessNonEligible}
                            onChange={(e) => {
                              setBusinessNonEligible(
                                moneyToNumber(e.target.value)
                              );
                            }}
                            placeholder={"$"}
                            useThousandSeparator={true}
                            customWidth={"100%"}
                            InputWidth={"100%"}
                            error={errors.businessEligible}
                            tooltipContent={
                              TooltipContent.holdingNonEligibleDividendIncome
                            }
                          />
                          <NormalInputButton
                            Heading={"Annual Foreign dividend income"}
                            value={businessForeign}
                            onChange={(e) => {
                              setBusinessForeign(moneyToNumber(e.target.value));
                            }}
                            placeholder={"$"}
                            useThousandSeparator={true}
                            customWidth={"100%"}
                            InputWidth={"100%"}
                            error={errors.businessEligible}
                            tooltipContent={
                              TooltipContent.holdingForeignDividendIncome
                            }
                          />
                        </>
                      )}

                      {/* Operational Earnings section */}
                      <Text
                        alignSelf={"stretch"}
                        color={"var(--Optiml---Deep-Ocean-Blue, #007EB5)"}
                        fontFamily={"Nunito"}
                        fontSize={"16px"}
                        fontStyle={"normal"}
                        fontWeight={"600"}
                        lineHeight={"normal"}
                        width={"100%"}
                      >
                        Operational Earnings and Contributions
                      </Text>
                      <NormalInputButton
                        Heading={
                          "After paying taxes on your annual earnings from operations, how much do you plan to invest in your investment fund this year?"
                        }
                        value={businessOperationsFundsInvested}
                        onChange={(e) => {
                          setBusinessOperationsFundsInvested(
                            moneyToNumber(e.target.value)
                          );
                        }}
                        placeholder={"$"}
                        useThousandSeparator={true}
                        customWidth={"100%"}
                        InputWidth={"100%"}
                        error={errors.businessOperationsFundsInvested}
                        tooltipContent={TooltipContent.holdingReinvest}
                      />
                      <NormalInputButton
                        Heading={
                          "As you make annual contributions to your investment fund, by what percentage do you expect these contributions to increase each year?"
                        }
                        value={businessOperationsFundsGrowth}
                        onChange={(e) => {
                          const noSymbol = e.target.value.replace("%", "");
                          const growthNum = parseFloat(noSymbol);
                          setBusinessOperationsFundsGrowth(
                            !isNaN(growthNum)
                              ? parseFloat(growthNum.toFixed(2))
                              : 0
                          );
                        }}
                        placeholder={"%"}
                        usePercentage={true}
                        customWidth={"100%"}
                        InputWidth={"100%"}
                        error={errors.businessOperationsFundsGrowth}
                        tooltipContent={TooltipContent.holdingReinvestGrowth}
                      />
                      {/* Taxation section */}
                      <Text
                        alignSelf={"stretch"}
                        color={"var(--Optiml---Deep-Ocean-Blue, #007EB5)"}
                        fontFamily={"Nunito"}
                        fontSize={"16px"}
                        fontStyle={"normal"}
                        fontWeight={"600"}
                        lineHeight={"normal"}
                        width={"100%"}
                      >
                        Taxation
                      </Text>
                      <NormalInputButton
                        Heading={
                          "Eligible Refundable Dividend Tax on Hand (ERDTOH)"
                        }
                        value={ERDTOH}
                        onChange={(e) => {
                          setERDTOH(moneyToNumber(e.target.value));
                        }}
                        placeholder={"$"}
                        useThousandSeparator={true}
                        customWidth={"100%"}
                        InputWidth={"100%"}
                        error={errors.ERDTOH}
                        tooltipContent={TooltipContent.holdingERDTOH}
                      />
                      <NormalInputButton
                        Heading={
                          "Non-eligible Refundable Dividend Tax on Hand (NERDTOH)"
                        }
                        value={NERDTOH}
                        onChange={(e) => {
                          setNERDTOH(moneyToNumber(e.target.value));
                        }}
                        placeholder={"$"}
                        useThousandSeparator={true}
                        customWidth={"100%"}
                        InputWidth={"100%"}
                        error={errors.NERDTOH}
                        tooltipContent={TooltipContent.holdingERDTOH}
                      />
                      <NormalInputButton
                        Heading={"General rate income pool (GRIP) balance"}
                        value={GRIP}
                        onChange={(e) => {
                          setGRIP(moneyToNumber(e.target.value));
                        }}
                        placeholder={"$"}
                        useThousandSeparator={true}
                        customWidth={"100%"}
                        InputWidth={"100%"}
                        error={errors.GRIP}
                        tooltipContent={TooltipContent.holdingGRIP}
                      />
                      <NormalInputButton
                        Heading={
                          "Capital Dividend Account (CDA) Opening Balance"
                        }
                        value={CDA}
                        onChange={(e) => {
                          setCDA(moneyToNumber(e.target.value));
                        }}
                        placeholder={"$"}
                        useThousandSeparator={true}
                        customWidth={"100%"}
                        InputWidth={"100%"}
                        error={errors.CDA}
                        tooltipContent={TooltipContent.holdingCDA}
                      />
                      {/* Debt section */}
                      <Text
                        alignSelf={"stretch"}
                        color={"var(--Optiml---Deep-Ocean-Blue, #007EB5)"}
                        fontFamily={"Nunito"}
                        fontSize={"16px"}
                        fontStyle={"normal"}
                        fontWeight={"600"}
                        lineHeight={"normal"}
                        width={"100%"}
                      >
                        Debt
                      </Text>
                      <DropDownButton
                        Heading={
                          "Do you currently have a personal loan to the fund?"
                        }
                        placeholder={"Select"}
                        dropDownItems={[
                          { key: "Yes", value: "Yes" },
                          { key: "No", value: "No" },
                        ]}
                        value={businessHasDebt}
                        onChange={(key) => {
                          setBusinessHasDebt(key);
                        }}
                        customWidth={"100%"}
                        MenuCustomWidth={"100%"}
                        error={errors.businessHasDebt}
                        tooltipContent={TooltipContent.holdingLoanYesNo}
                      />
                      {businessHasDebt === "Yes" && (
                        <NormalInputButton
                          Heading={"Current value of your repayable loan"}
                          value={businessDebt}
                          onChange={(e) => {
                            setBusinessDebt(moneyToNumber(e.target.value));
                          }}
                          placeholder={"$"}
                          useThousandSeparator={true}
                          customWidth={"100%"}
                          InputWidth={"100%"}
                          error={errors.businessDebt}
                          tooltipContent={TooltipContent.holdingLoan}
                        />
                      )}
                    </>
                  )}
                </Box>
                {/* {data.length > 0 && !adding && (
                  <Box
                    display={"flex"}
                    justifyContent={"flex-end"}
                    alignItems={"center"}
                    alignSelf={"stretch"}
                  >
                    <Box
                      display={"flex"}
                      padding={"8px 48px"}
                      marginTop={"20px"}
                      justifyContent={"flex-end"}
                      alignItems={"center"}
                      gap={"5px"}
                      borderRadius={"5px"}
                      bg={"#27AE60"}
                    >
                      <Box
                        display={"flex"}
                        width={"76px"}
                        height={"21px"}
                        flexDirection={"column"}
                        justifyContent={"center"}
                      >
                        <Text
                          color={"#FFFFFF"}
                          textAlign={"center"}
                          fontFamily={"Nunito"}
                          fontSize={"16px"}
                          fontStyle={"normal"}
                          fontWeight={"600"}
                          lineHeight={"normal"}
                        >
                          Add
                        </Text>
                      </Box>
                    </Box>
                  </Box>
                )} */}
                {(data.length < 1 || adding) && (
                  <Box
                    display={"flex"}
                    justifyContent={"flex-end"}
                    alignItems={"center"}
                    alignSelf={"stretch"}
                  >
                    {renderAddUpdateRecreational()}
                  </Box>
                )}
              </>
            }
          />
        </VStack>
      )}
    </Flex>
  );
});

export default InvestmentFundsForm;
