import { useAuth0 } from "@auth0/auth0-react";
import {
  Box,
  Flex,
  HStack,
  Icon,
  Spacer,
  Text,
  VStack,
  useBreakpointValue,
  useColorModeValue,
  useDisclosure,
  useMediaQuery,
} from "@chakra-ui/react";
import React, { useContext, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { useStore } from "../store";
import {
  ActionPlanIcon,
  AnalysisArchiveIcon,
  BackToDashboardIcon,
  DashboardIcon,
  DebtIcon,
  ExpensesIcon,
  HelpIcon,
  IncomeIcon,
  InsightsIcon,
  InvestmentIcon,
  LogoutIcon,
  OptimizeIcon,
  OptimlBetaIcon,
  SettingIcon,
  TaxesIcon,
  UpdateMyPlanIcon,
} from "./CustomIcons";
import HelpPopup from "./HelpPop";
import Link from "./Link";
import "./Sidebar.css";
import { StripeContext } from "./StripeContext";
import StripeUpgradeModal from "./StripeUpgradeModal";

function Sidebar(props) {
  const bgColor = useColorModeValue("#FFF", "gray.700");
  const sidebarWidth = useBreakpointValue({ base: "240px", w: "20%" });
  const [selectedOption, setSelectedOption] = useState(null);
  const days = 30;
  const [isMobile, setIsMobile] = useState(false);
  const [isLargerThan768] = useMediaQuery("(min-width: 768px)");
  const { isOpen, onOpen, onClose } = useDisclosure();

  const {
    isOpen: isHelpModalOpen,
    onOpen: onOpenHelpModal,
    onClose: onCloseHelpModal,
  } = useDisclosure();

  const btnRef = React.useRef();

  const { setShowModal } = props;

  const handleSelect = (label) => {
    setSelectedOption(label);
    if (label === "Help") {
      onOpenHelpModal();
    }
  };

  const location = useLocation(); // Hook from React Router
  const incomePaths = [
    "/revenue",
    "/income-cpp",
    "/income-oas",
    "/income-employer-pension",
    "/income-other",
  ];

  const incomeOptimizePaths = ["/income"];
  const investmentPaths = [
    "/investment-tfsa-page",
    "/investment-fhsa-page",
    "/rrsp-page",
    "/other-reg-page",
    "/non-reg-page",
    "/non-reg-page",
    "/fixed-income-page",
    "/cash-savings",
    "/other-assets-page",
    "/real-estate",
    "/primary-residence-form",
    "/recreational-property-form",
    "/income-property-form",
    "/land-property-form",
    "/life-insurance-form",
    "/business-page",
    "/business-form",
    "/investment-funds-form",
    "/personal-assets-form",
    "/inheritance-form",
  ];

  const investmentOptimizePaths = ["/investments"];
  const profilePaths = [
    "/",
    "/investments-account",
    "/password-and-security",
    "/changeplan",
  ];

  const dashPath = ["/dashboard-page"];
  const expensePaths = ["/expenses-debt", "/expenses-household", "/Goals"];
  const expenseOptimizePath = ["/expenses"];

  const debtOptimizePath = ["/DebtPage"];
  const actionPlanPath = ["/action-plan-page"];
  const taxOptimizePath = ["/taxes"];
  const InsightsOptimizePath = ["/insights-page"];
  const analysisArchivePath = ["/analysis-archive"];

  const optiPaths = ["/lifestrategy"];

  const updateSelectedOption = (pathname) => {
    if (incomePaths.includes(pathname)) {
      setShowNewOptimization(true);
      setSelectedOption("Income");
    } else if (investmentPaths.includes(pathname)) {
      setShowNewOptimization(true);
      setSelectedOption("Investments");
    } else if (investmentOptimizePaths.includes(pathname)) {
      setSelectedOption("Investments");
    } else if (profilePaths.includes(pathname)) {
      setShowNewOptimization(true);
      setSelectedOption("Settings");
    } else if (expensePaths.includes(pathname)) {
      setShowNewOptimization(true);
      setSelectedOption("Expenses");
    } else if (expenseOptimizePath.includes(pathname)) {
      setSelectedOption("Expenses");
    } else if (optiPaths.includes(pathname)) {
      setShowNewOptimization(true);
      setSelectedOption("Optimize");
    } else if (dashPath.includes(pathname)) {
      setSelectedOption("Dashboard");
    } else if (debtOptimizePath.includes(pathname)) {
      setSelectedOption("Debt");
    } else if (incomeOptimizePaths.includes(pathname)) {
      setSelectedOption("Income");
    } else if (taxOptimizePath.includes(pathname)) {
      setSelectedOption("Taxes");
    } else if (InsightsOptimizePath.includes(pathname)) {
      setSelectedOption("Insights");
    } else if (analysisArchivePath.includes(pathname)) {
      setSelectedOption("Analysis Archive");
    } else if (actionPlanPath.includes(pathname)) {
      setSelectedOption("Action Plan");
    }
  };

  useEffect(() => {
    if (incomePaths.includes(location.pathname)) {
      setSelectedOption("Income");
    }
    if (investmentPaths.includes(location.pathname)) {
      setSelectedOption("Investments");
    }
    if (profilePaths.includes(location.pathname)) {
      setSelectedOption("Settings");
    }
    if (expensePaths.includes(location.pathname)) {
      setSelectedOption("Expenses");
    }
    if (optiPaths.includes(location.pathname)) {
      setSelectedOption("Optimize");
    }
    if (dashPath.includes(location.pathname)) {
      setSelectedOption("Dashboard");
    }
    updateSelectedOption(location.pathname);

    // Add an event listener for the popstate event
    const handlePopState = () => {
      updateSelectedOption(window.location.pathname);
      window.addEventListener("popstate", handlePopState);
    };

    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener("popstate", handlePopState);
    };
  }, [location]); // Re-run the effect if the location changes

  useEffect(() => {
    setIsMobile(!isLargerThan768);
  }, [isLargerThan768]);

  const { logout } = useAuth0();
  const showNewOptimization = useStore((store) => store.showNewOptimization);
  const setMobileSidebarOpen = useStore((store) => store.setMobileSidebarOpen);
  const userPageLockInfo = useStore((store) => store.userPageLockInfo);

  const { stripePlan, setStripePlan } = useContext(StripeContext);

  function newOptimization() {
    setMobileSidebarOpen(false);
    setShowModal();
  }

  const userOptiData = useStore((store) => store.userOptiData);
  const [newUser, setNewUser] = useState();
  const [showStripeModal, setShowStripeModal] = useState(false);

  useEffect(() => {
    if (userOptiData?.primaryUserData?.expenses?.length > 1) {
      setNewUser(false);
    } else if (userOptiData?.primaryUserData?.expenses?.length === undefined) {
      setNewUser(true);
    }
  }, [userOptiData]);

  function UpgradeStripeModal() {
    setShowStripeModal(!showStripeModal);
  }

  const calculateDaysLeft = (trialEndTimestamp) => {
    const currentDate = new Date();
    const trialEndDate = new Date(trialEndTimestamp * 1000); // Convert to milliseconds
    const differenceInTime = trialEndDate.getTime() - currentDate.getTime();
    const differenceInDays = Math.ceil(differenceInTime / (1000 * 3600 * 24));
    return differenceInDays;
  };

  const navigation = [
    ...(!newUser && !showNewOptimization
      ? [
          {
            label: "Dashboard",
            icon: <DashboardIcon svgwidth={"20px"} svgheight={"20px"} />,
            url: "/dashboard-page",
          },
        ]
      : []),
    ...(!newUser && !showNewOptimization
      ? [
          {
            label: "Action Plan",
            icon: <ActionPlanIcon svgwidth={"20px"} svgheight={"20px"} />,
            url: "/action-plan-page",
          },
        ]
      : []),
    ...(!newUser && showNewOptimization
      ? [
          {
            label: "Back to Dashboard",
            icon: <BackToDashboardIcon svgwidth={"20px"} svgheight={"20px"} />,
            url: "/dashboard-page",
          },
        ]
      : []),
    ...(newUser || showNewOptimization
      ? [
          {
            label: "Settings",
            icon: <SettingIcon svgwidth={"20px"} svgheight={"20px"} />,
            url: "/",
          },
        ]
      : []),
    {
      label: "Income",
      icon: <IncomeIcon svgwidth={"20px"} svgheight={"20px"} />,
      url: !showNewOptimization && !newUser ? "/income" : "/revenue",
      disabled: newUser && !userPageLockInfo?.IncomePage,
    },
    {
      label: "Investments",
      icon: <InvestmentIcon svgwidth={"20px"} svgheight={"20px"} />,
      url:
        !showNewOptimization && !newUser
          ? "/investments"
          : "/investment-tfsa-page",
      disabled: newUser && !userPageLockInfo?.InvestmentsPage,
    },
    ...(!showNewOptimization &&
    !newUser &&
    (userOptiData?.primaryUserData?.PrimaryResidence?.Balance?.some(
      (payment) => payment !== 0
    ) ||
      userOptiData?.primaryUserData?.RecreationPropertyYearlyBalance?.some(
        (payment) => payment !== 0
      ) ||
      userOptiData?.primaryUserData?.IncomePropertyYearlyBalance?.some(
        (payment) => payment !== 0
      ) ||
      userOptiData?.primaryUserData?.totalDebt?.some(
        (payment) => payment !== 0
      ))
      ? [
          {
            label: "Debt",
            icon: <DebtIcon svgwidth={"20px"} svgheight={"20px"} />,
            url: "/DebtPage",
          },
        ]
      : []),
    {
      label: "Expenses",
      icon: <ExpensesIcon svgwidth={"20px"} svgheight={"20px"} />,
      url: !showNewOptimization && !newUser ? "/expenses" : "expenses-debt",
      disabled: newUser && !userPageLockInfo?.ExpensesPage,
    },
    ...(newUser || showNewOptimization
      ? [
          {
            label: "Optimize",
            icon: <OptimizeIcon svgwidth={"20px"} svgheight={"20px"} />,
            url: "/lifestrategy",
            disabled: newUser && !userPageLockInfo?.OptimizationPage,
          },
        ]
      : []),
    ...(!newUser && !showNewOptimization
      ? [
          {
            label: "Taxes",
            icon: <TaxesIcon svgwidth={"20px"} svgheight={"20px"} />,
            url: "/taxes",
          },
        ]
      : []),
    // ...(!newUser && !showNewOptimization
    //   ? [
    //       {
    //         label: "Insights",
    //         icon: <InsightsIcon svgwidth={"20px"} svgheight={"20px"} />,
    //         url: "/insights-overview",
    //       },
    //     ]
    //   : []),
    ...(!newUser &&
    stripePlan?.items?.data?.[0]?.price?.lookup_key != "OPTIML_ESSENTIALS" &&
    stripePlan?.items?.data?.[0]?.price?.lookup_key != "OPTIML_ESSENTIALS_M" &&
    stripePlan &&
    !showNewOptimization
      ? [
          {
            label: "Analysis Archive",
            icon: <AnalysisArchiveIcon svgwidth={"20px"} svgheight={"20px"} />,
            url: "/analysis-archive",
          },
        ]
      : []),
  ];

  const LogoutNavigation = [
    {
      label: "Help",
      icon: <HelpIcon svgheight={"20px"} svgwidth={"20px"} />,

      //href:"/help",
    },
    {
      label: "Logout",
      icon: <LogoutIcon svgheight={"20px"} svgwidth={"20px"} />,
      action: logout,
    },
  ];

  const setShowNewOptimization = useStore(
    (store) => store.setShowNewOptimization
  );

  return (
    <div>
      <StripeUpgradeModal
        isOpen={showStripeModal}
        closeModal={UpgradeStripeModal}
      />

      <Flex h="100vh" position="sticky">
        <Box
          display="flex"
          as="sidebar"
          position="fixed"
          height="100vh"
          w={sidebarWidth}
          bg={bgColor}
          boxShadow="md"
          transition="transform 0.25s ease-in-out"
          transform={{
            base: isOpen ? "translateX(0)" : "translateX(-280px)",
            md: "none", // Don't apply transform for non-mobile resolutions
          }}
          padding="20px 20px 50px 20px"
          bgGradient="linear(to-l, rgba(0, 180, 235, 1)1.5px, transparent 1px)"
        >
          <VStack
            display="flex"
            alignSelf={"stretch"}
            gap={"10px"}
            flexDirection={"column"}
            flex={1}
            align="flex-start"
          >
            {newUser && (
              <Link href="/">
                <Box
                  paddingBottom={"30px"}
                  objectFit={"contain"}
                  alignSelf="center"
                >
                  <OptimlBetaIcon />
                </Box>
              </Link>
            )}
            {!newUser && (
              <Link href="/dashboard-page">
                <Box
                  paddingBottom={"30px"}
                  objectFit={"contain"}
                  alignSelf="center"
                  onClick={() => {
                    setShowNewOptimization(false);
                    handleSelect("Dashboard");
                  }}
                >
                  <OptimlBetaIcon />
                </Box>
              </Link>
            )}
            {navigation.map((item) => (
              <Link
                key={item.label}
                href={item.url}
                disabled={item.disabled}
                style={{ textDecoration: "none" }}
              >
                <Box
                  as="button"
                  borderRadius="5px"
                  alignItems={"center"}
                  justifyContent={"left"}
                  gap={"15px"}
                  padding={"5px 10px"}
                  width={"200px"}
                  display={"flex"}
                  background={
                    selectedOption === item.label ? "#C2E5F6" : "transparent"
                  } // Background color based on selection
                  _hover={
                    selectedOption !== item.label && !item.disabled
                      ? {
                          background: "#E9E9E9",
                          transform: "scale(1.02)",
                          boxShadow: "0 6px 12px rgba(0, 0, 0, 0.15)",
                        }
                      : {
                          cursor: item?.disabled ? "not-allowed" : "pointer",
                        }
                  }
                  color="#004069"
                  onClick={() => !item.disabled && handleSelect(item.label)} // Correct usage of handleSelect
                >
                  <VStack spacing={"10px"} align="flex-start">
                    <HStack
                      justifyContent="flex-start"
                      gap={"15px"}
                      alignItems={"center"}
                    >
                      {item.icon}
                      <Text
                        fontFamily="Nunito"
                        fontStyle="normal"
                        fontWeight="600"
                        fontSize="medium"
                      >
                        {item.label}
                      </Text>
                    </HStack>
                  </VStack>
                </Box>
              </Link>
            ))}

            {!newUser && !showNewOptimization && (
              <Box
                onClick={newOptimization}
                justifyContent={"center"}
                display={"flex"}
                flexDirection={"row"}
                gap={"10px"}
                alignItems={"center"}
                alignSelf={"stretch"}
                borderRadius="5px"
                border="1px solid #00A2D4" // Add border
                padding={"15px 20px"}
                bg="white"
                as="button"
                _hover={{
                  transform: "scale(1.02)",
                  boxShadow: "0 6px 12px rgba(0, 0, 0, 0.15)",
                }}
              >
                <Icon
                  as={UpdateMyPlanIcon}
                  svgheight={"20px"}
                  svgwidth={"20px"}
                />
                <Text
                  fontFamily="Nunito"
                  fontStyle="normal"
                  fontWeight="600"
                  lineHeight="normal"
                  textColor="rgba(0, 180, 235, 1)"
                  fontSize="12px"
                >
                  Update My Plan
                </Text>
              </Box>
            )}
            <Spacer />
            {(stripePlan?.items?.data?.[0]?.price?.lookup_key ===
              "OPTIML_ESSENTIALS" ||
              stripePlan?.items?.data?.[0]?.price?.lookup_key ===
                "OPTIML_ESSENTIALS_M") &&
              calculateDaysLeft(stripePlan?.trial_end) >= 1 && (
                <Box
                  onClick={UpgradeStripeModal}
                  justifyContent={"center"}
                  alignItems={"center"}
                  alignSelf={"stretch"}
                  borderRadius="5px"
                  border="1px solid #00A2D4" // Add border
                  padding={"15px 20px"}
                  bg="white"
                  as="button"
                >
                  <Text
                    fontFamily="Nunito"
                    fontStyle="normal"
                    fontWeight="600"
                    lineHeight="normal"
                    textColor="rgba(0, 180, 235, 1)"
                    fontSize="12px"
                  >
                    Free Trial: {calculateDaysLeft(stripePlan?.trial_end)} days
                    remaining
                  </Text>
                </Box>
              )}
            {(stripePlan?.items?.data?.[0]?.price?.lookup_key ===
              "OPTIML_ESSENTIALS" ||
              stripePlan?.items?.data?.[0]?.price?.lookup_key ===
                "OPTIML_ESSENTIALS_M" ||
              stripePlan?.items?.data?.[0]?.price?.lookup_key === "PRO" ||
              stripePlan?.items?.data?.[0]?.price?.lookup_key === "PRO_M") && (
              <Box
                onClick={UpgradeStripeModal}
                as="button"
                bg="#00A2D4"
                borderRadius="5px"
                border="1px solid var(--Optiml---Midnight-Blue, #00466D)" // Add border
                borderColor="#00466D" // Adjust border color
                padding={"15px 20px"}
                justifyContent={"center"}
                alignItems={"center"}
                alignSelf={"stretch"}
              >
                <Text
                  fontSize="12px"
                  fontFamily=" Nunito"
                  fontStyle="normal"
                  fontWeight="600"
                  lineHeight="normal"
                  textColor="white"
                >
                  UPGRADE NOW
                </Text>
              </Box>
            )}
            {LogoutNavigation.map((item) => (
              // <Link
              //   key={item.label}
              //   href={item.href}
              //   target={item.target}
              //   style={{ textDecoration: "none" }}
              //   onClick={item.action} // Call handleSelect on click
              // >
              <Box
                as="button"
                borderRadius="5px"
                alignItems={"center"}
                gap={"15px"}
                padding={"5px 10px"}
                width={"200px"}
                display={"flex"}
                _hover={{
                  background: "#E9E9E9",
                  transform: "scale(1.02)",
                  boxShadow: "0 6px 12px rgba(0, 0, 0, 0.15)",
                }}
                color="#004069"
                onClick={() => handleSelect(item.label)}
              >
                <VStack spacing={"10px"} align="flex-start">
                  <a
                    key={item.label}
                    href={item.href}
                    target={item.target}
                    style={{ textDecoration: "none" }}
                    onClick={item.action} // Call handleSelect on click
                  >
                    <HStack justifyContent="flex-start" spacing={"15px"}>
                      {item.icon}
                      <Text
                        fontFamily="Nunito"
                        fontSize={"16px"}
                        fontStyle={"normal"}
                        fontWeight={"600"}
                        lineHeight={"normal"}
                      >
                        {item.label}
                      </Text>
                    </HStack>
                  </a>
                </VStack>
              </Box>
              // </Link>
            ))}
            <HelpPopup isOpen={isHelpModalOpen} onClose={onCloseHelpModal} />
          </VStack>
        </Box>
      </Flex>
    </div>
  );
}

export default Sidebar;
