import {
  Box,
  HStack,
  Icon,
  Input,
  Text,
  Tooltip,
  useDisclosure,
  useMediaQuery,
} from "@chakra-ui/react";
import { NumericFormat } from "react-number-format";
import { useState } from "react";
import { InputBoxTitleBubbleTip } from "../CustomIcons";

function NormalInputButton({
  Heading,
  placeholder,
  value,
  onChange,
  error,
  customWidth,
  useThousandSeparator,
  usePercentage,
  InputWidth,
  fontSize,
  tooltipContent,
  disabled,
}) {
  const [isLargerThan768] = useMediaQuery("(min-width: 500px)");
  const isMobile = !isLargerThan768;
  const boxWidth = customWidth ? customWidth : isMobile ? "100%" : "45%";
  const [localError, setLocalError] = useState("");

  const { isOpen, onOpen, onToggle, onClose } = useDisclosure();

  const handleInputChange = (e) => {
    const regex = /^[a-zA-Z0-9$,%\. ]*$/; // Regex now includes space
    if (!regex.test(e.target.value)) {
      setLocalError("Only alphanumeric characters, $, , %, ., and space are allowed.");
      return; // Stop the function if the input is not valid
    }
    if (e.target.value.length > 30) {
      setLocalError("Maximum characters of 30 exceeded");
      return;
    } else {
      setLocalError("");
      onChange(e);
    }
  };
  

  return (
    <Box
      display={"flex"}
      width={boxWidth}
      flexDirection={"column"}
      alignItems={"flex-start"}
      gap={"5px"}
    >
      <HStack spacing={"5px"} alignItems={"flex-end"}>
        <Text
          color={"#00466D"}
          fontFamily={"Nunito"}
          fontSize={fontSize ? fontSize : "16px"}
          fontStyle={"normal"}
          fontWeight={"600"}
          lineHeight={"normal"}
        >
          {Heading}
          {tooltipContent && (
            <Tooltip
              isOpen={isOpen}
              padding={"10px"}
              borderRadius={"5px"}
              label={tooltipContent}
              placement={isMobile ? "auto-start" : "right"}
              hasArrow
              closeOnClick={true}
            >
              <span
                onClick={onToggle}
                onMouseEnter={onOpen}
                onMouseLeave={onClose}
              >
                <Icon as={InputBoxTitleBubbleTip} />
              </span>
            </Tooltip>
          )}
        </Text>
      </HStack>
      <Box
        display="flex"
        maxW={InputWidth ? InputWidth : boxWidth === "45%" ? "100%" : "45%"}
        padding="0px 12px"
        alignItems="center"
        gap="10px"
        alignSelf="stretch"
        borderRadius="5px"
        _focusWithin={{ borderBottom: "2px solid #00A2D4", outline: "none" }} // Optional: add border color on focus
        bg={error || localError ? "#FFD2D2" : "#EDF2F7"} // Change background color on error
        border={error || localError ? "1px solid #E53E3E" : "none"} // Optional: add border color on error
      >
        {useThousandSeparator ? (
          <Input
            color="#030229"
            fontFamily="Nunito"
            placeholder={placeholder}
            border="none"
            padding={0}
            fontSize="14px"
            fontWeight="400"
            lineHeight="normal"
            _focusVisible={"none"}
            value={value}
            onChange={handleInputChange}
            required
            as={NumericFormat}
            thousandSeparator=","
            prefix="$"
          />
        ) : usePercentage ? (
          <Input
            color="#030229"
            fontFamily="Nunito"
            placeholder={placeholder}
            border="none"
            padding={0}
            fontSize="14px"
            fontWeight="400"
            lineHeight="normal"
            _focusVisible={"none"}
            suffix="%"
            decimalScale={2}
            as={NumericFormat}
            value={value}
            onChange={handleInputChange}
            required
          />
        ) : (
          <Input
            color="#030229"
            fontFamily="Nunito"
            placeholder={placeholder}
            border="none"
            padding={0}
            fontSize="14px"
            fontWeight="400"
            lineHeight="normal"
            _focusVisible={"none"}
            value={value}
            onChange={handleInputChange}
            required
            disabled={disabled}
          />
        )}
      </Box>
      {(error || localError) && (
        <Text color="#E53E3E" fontSize="sm">
          {error || localError}
        </Text> // Display error message
      )}
    </Box>
  );
}

export default NormalInputButton;
