import { Box, HStack, Text, VStack, keyframes, useMediaQuery } from "@chakra-ui/react";
import styled from "@emotion/styled";
import React, { useEffect, useState } from "react";
import { useStore } from "../../store";
import LoadingCards from "../LoadingCards";
import "./LoadingScreenMessages.css";

const LoadingScreenMessages = (props) => {
  const { loading, strategy, age, run, duration, married } = props;
  const [expenseDone, setExpenseDone] = useState(false);
  const [cppDone, setcppDone] = useState(false);
  const [oasDone, setoasDone] = useState(false);
  const [taxDone, settaxDone] = useState(false);
  const [strategyDone, setstrategyDone] = useState(false);
  const [actionDone, setactionDone] = useState(false);
  const [timeEstimate, setTimeEstimate] = useState("");
  const [ageCalculated, setAgeCalculated] = useState(false);
  const [runTime, setRunTime] = useState(120000);
  const hideProgressBar = useStore((store) => store.hideProgressBar);

  const userInfo = useStore((store) => store.userInfo);

  useEffect(() => {
    window.scrollTo(0, 0);
    const currentDate = new Date();
    const getTimeEstimate = () => {
      let currentAge = getAge(age, currentDate);
      if (currentAge >= 1) {
        if (duration > 60) {
          setTimeEstimate("8-10 minutes");
          setAgeCalculated(true);
          LoadingTimeCalculator(duration);
        } else if (duration > 40) {
          setTimeEstimate("6-8 minutes");
          setAgeCalculated(true);
          LoadingTimeCalculator(duration);
        } else if (duration > 20) {
          setTimeEstimate("4-6 minutes");
          setAgeCalculated(true);
          LoadingTimeCalculator(duration);
        } else {
          setTimeEstimate("1-4 minutes");
          setAgeCalculated(true);
          LoadingTimeCalculator(duration);
        }
      }
    };
    getTimeEstimate();
  });

  const LoadingTimeCalculator = (duration) => {
    const spouseNum1 = 4.571428571;
    const spouseNum2 = 0.80952381;
    const spouseNum3 = 0.21047619;

    const num1 = -4.495867769;
    const num2 = 1.532447855;
    const num3 = 0.024584809;
    let RunningTime = 0;
    if (married) {
      setRunTime(
        spouseNum1 + spouseNum2 * duration + spouseNum3 * duration ** 2
      );
      RunningTime =
        spouseNum1 + spouseNum2 * duration + spouseNum3 * duration ** 2;
      // LoadSpinners(RunningTime * 1000);
    } else {
      setRunTime(num1 + num2 * duration + num3 * duration ** 2);
      RunningTime = num1 + num2 * duration + num3 * duration ** 2;
      // LoadSpinners(RunningTime * 1000);
    }
  };

  const LoadSpinners = (time) => {
    console.log(time);
    setTimeout(
      () => {
        setExpenseDone(true);
      },
      loading ? loading : time * 0.16
    );
    setTimeout(
      () => {
        setcppDone(true);
      },
      loading ? loading : time * 0.33
    );
    setTimeout(
      () => {
        setoasDone(true);
      },
      loading ? loading : time * 0.5
    );
    setTimeout(
      () => {
        setstrategyDone(true);
      },
      loading ? loading : time * 0.66
    );
    setTimeout(
      () => {
        setactionDone(true);
      },
      loading ? loading : time * 0.84
    );
    setTimeout(
      () => {
        settaxDone(true);
      },
      loading ? loading : time * 100
    );
  };

  function getAge(birthday, currentDate) {
    // Get the current date
    // const currentDate = new Date();

    // Convert the birthday string into a Date object
    const birthDate = new Date(birthday);

    // Calculate the age in years
    let age = currentDate.getFullYear() - birthDate.getFullYear();

    // Decrement the age if the current month and day are before the birth month and day
    if (
      currentDate.getMonth() < birthDate.getMonth() ||
      (currentDate.getMonth() === birthDate.getMonth() &&
        currentDate.getDate() <= birthDate.getDate())
    ) {
      age -= 1;
    }

    return age;
  }

  const globalLoader = keyframes`
  0%, 100% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
`;
  const AnimatedPath = styled.path`
    opacity: 0;
    animation: ${globalLoader} 2s infinite;
    animation-delay: ${(props) => props.delay || "0s"};
  `;
  const LoadingAnimation = () => {
    return (
      <Box
        as="svg"
        xmlns="http://www.w3.org/2000/svg"
        width={isMobile ? "50" : "94"}
        height={isMobile ? "50" : "94"}
        viewBox="0 0 94 94"
        fill="none"
      >
        <circle cx="47" cy="47" r="46.5" fill="white" stroke="#00A2D4" />
        <AnimatedPath
          className="dot"
          d="M27.02 63.3578C27.02 65.9166 24.9457 67.9909 22.387 67.9909C19.8282 67.9909 17.7539 65.9166 17.7539 63.3578C17.7539 60.799 19.8282 58.7247 22.387 58.7247C24.9457 58.7247 27.02 60.799 27.02 63.3578Z"
          fill="#0093D0"
          stroke="#00A2D4"
          delay="0.2s"
        />
        <AnimatedPath
          className="dot"
          d="M43.2818 63.3578C43.2818 65.9166 41.2075 67.9909 38.6487 67.9909C36.0899 67.9909 34.0156 65.9166 34.0156 63.3578C34.0156 60.799 36.0899 58.7247 38.6487 58.7247C41.2075 58.7247 43.2818 60.799 43.2818 63.3578Z"
          fill="#00B4EB"
          stroke="#00A2D4"
          delay="0.4s"
        />
        <AnimatedPath
          className="dot"
          d="M59.5435 63.3578C59.5435 65.9166 57.4692 67.9909 54.9104 67.9909C52.3516 67.9909 50.2773 65.9166 50.2773 63.3578C50.2773 60.799 52.3516 58.7247 54.9104 58.7247C57.4692 58.7247 59.5435 60.799 59.5435 63.3578Z"
          fill="#2DBDB6"
          stroke="#00A2D4"
          delay="0.6s"
        />
        <AnimatedPath
          className="dot"
          d="M43.2818 47.1032C43.2818 49.662 41.2075 51.7363 38.6487 51.7363C36.0899 51.7363 34.0156 49.662 34.0156 47.1032C34.0156 44.5444 36.0899 42.4702 38.6487 42.4702C41.2075 42.4702 43.2818 44.5444 43.2818 47.1032Z"
          fill="#00B4EB"
          stroke="#00A2D4"
          delay="0.8s"
        />
        <AnimatedPath
          className="dot"
          d="M59.5435 47.1032C59.5435 49.662 57.4692 51.7363 54.9104 51.7363C52.3516 51.7363 50.2773 49.662 50.2773 47.1032C50.2773 44.5444 52.3516 42.4702 54.9104 42.4702C57.4692 42.4702 59.5435 44.5444 59.5435 47.1032Z"
          fill="#2DBDB6"
          stroke="#00A2D4"
          delay="1s"
        />
        <AnimatedPath
          className="dot"
          d="M75.7974 47.1032C75.7974 49.662 73.7231 51.7363 71.1643 51.7363C68.6056 51.7363 66.5312 49.662 66.5312 47.1032C66.5312 44.5444 68.6056 42.4702 71.1643 42.4702C73.7231 42.4702 75.7974 44.5444 75.7974 47.1032Z"
          fill="#84C98B"
          stroke="#00A2D4"
          delay="1.2s"
        />
        <AnimatedPath
          className="dot"
          d="M75.7974 30.8485C75.7974 33.4072 73.7231 35.4815 71.1643 35.4815C68.6056 35.4815 66.5312 33.4072 66.5312 30.8485C66.5312 28.2897 68.6056 26.2154 71.1643 26.2154C73.7231 26.2154 75.7974 28.2897 75.7974 30.8485Z"
          fill="#84C98B"
          stroke="#00A2D4"
          delay="1.4s"
        />
      </Box>
    );
  };

  const [isLargerThan768] = useMediaQuery("(min-width: 768px)");
  const isMobile = !isLargerThan768;

  const cards = [
    { 
      title: 'This might take a couple minutes', 
      message: 'We are currently crafting the finest financial and retirement plan on the market. This might take a few minutes—yes, just minutes, not those hour-long sessions you might be used to. So, sit back, relax, grab a coffee, or take the dog out. Your plan will be waiting for you when you return!' 
    },
    { 
      title: 'Optiml', 
      message: 'In the initial year of your plan, you might observe that some values appear lower than anticipated. At Optiml, we strategically prorate all growth projections, and other relevant values based on the remaining portion of the year and any actions or expenditures you have already undertaken. This approach enhances the precision of your financial plan, ensuring it is immediately applicable and perfectly tailored to your current circumstances.'
    },
    { 
      title: 'Increase your monthly paycheque by planning ahead with your RRSP contributions', 
      message: 'Just fill out Form T1213 and send it to the CRA or your Employer with details of your RRSP savings. This lets them adjust your taxes so you get more money each month, instead of waiting for a big tax refund next year!' 
    },
    { 
      title: 'Did You Know?', 
      message: 'As of 2024, the annual TFSA (Tax-Free Savings Account) contribution limit in Canada is $7,000. Maximizing your TFSA is a great way to save for the future tax-free!' 
    },
    { 
      title: 'Tax Tips', 
      message: 'Traditional financial planning often adopts a one-size-fits-all approach, applying the same sequence of deposits and decumulation strategies for all users, regardless of their individual goals, net worth, or lifestyle. This standardized method has resulted in Canadians paying millions in unnecessary taxes. Optiml is designed to eliminate this inefficiency by offering tailored financial planning solutions.' 
    },
    { 
      title: 'Current Financial Stats', 
      message: 'The Canadian Consumer Price Index (CPI) has seen an increase, reflecting changes in the cost of living. Staying informed helps in planning your long-term savings strategy.' 
    },
    { 
      title: 'Support', 
      message: 'Have you tried EVA, our virtual financial assistant. For all your questions, EVA is the first place to go. Click in the bottom right corner chat bubble to start your conversation.' 
    },
    { 
      title: 'Tax Tips', 
      message: 'Did you know that RRSP (Registered Retirement Savings Plan) contributions can significantly lower your taxable income? You have until March 1st to contribute for the previous tax year.' 
    },
    { 
      title: 'Tax Tips', 
      message: "At first glance, RRSPs appear highly beneficial, offering immediate tax reductions. However, have you considered the taxes that will be due upon withdrawal? Or the potential losses when converting to a RRIF at age 71? Explore these implications and more with a comprehensive financial plan, exclusively available through Optiml." 
    },
    { 
      title: 'Investment Insight', 
      message: 'The average annual return on Canadian diversified mutual funds has hovered around 6% over the past decade. Diversification is key to managing risk in your investment portfolio.' 
    },
    { 
      title: 'Economic Update', 
      message: 'Canada\'s GDP growth rate was 1.1% last year, showing resilience in many sectors. Understanding economic trends can help you align your investment strategies effectively.' 
    },
    { 
      title: 'RRSP vs TFSA', 
      message: 'A tale as old as time. Say goodbye to those one-size-fits-all recommendations from your brother-in-law. Now, you have the solution that fits your unique situation perfectly.' 
    },
    { 
      title: 'Optiml', 
      message: 'So what sets us apart? Our proprietary algorithm is running hundreds of thousands of different combinations and permutations in the background in terms of your deposits and withdrawals to ensure the greatest returns and the least taxes paid.' 
    },
    { 
      title: 'Financial Goals', 
      message: 'Curious about retiring early? How about buying that dream car or boat? Run all of your goals and what-if scenarios in Optiml.' 
    },
    { 
      title: 'Support', 
      message: 'Our dedicated team is only an email away to assist you in your Optiml onboarding or any other needs. Reach us at Support@optiml.ca' 
    },
    { 
      title: 'FHSA Benefits', 
      message: 'The First Home Savings Account (FHSA) allows Canadians to save $40,000 towards their first home, with contributions being tax-deductible and withdrawals tax-free when used to purchase a first home.'
    },
    { 
      title: 'CCPC Investment Fund Opportunities', 
      message: 'For Canadian-Controlled Private Corporations (CCPCs), investing in small business investment funds can offer significant tax advantages and opportunities for growth.'
    },
    // { 
    //   title: 'Top Performers', 
    //   message: 'In 2023, the top performing Canadian stocks included Shopify, which saw an increase of over 30%, and Enbridge, with a steady 15% gain amidst global economic volatility.'
    // },
    { 
      title: 'Understanding Economic Indicators', 
      message: 'As of 2024, Canada\'s inflation rate appears to be stabilizing around 2.5%. Monitoring these indicators helps tailor a safer, more robust financial plan.'
    },
    { 
      title: 'Green Investment Trends', 
      message: 'Green energy stocks are on the rise. Companies like Canadian Solar have seen substantial growth, reflecting increased investment in sustainable technologies.'
    },
    { 
      title: 'Tech Sector Insights', 
      message: 'Tech continues to dominate growth charts. In 2023, Canadian tech firms like Constellation Software dramatically outperformed market expectations.'
    },
    { 
      title: 'Real Estate Market Update', 
      message: 'Despite fluctuations, the Toronto real estate market has shown resilience, with average housing prices rising by 5% in the past year, signalling a robust investment opportunity.'
    },
    {
      title: "Nova Scotia Real Estate Market Update",
      message: "Nova Scotia's real estate market has continued to attract attention with its steady growth. Average housing prices have increased by approximately 6% over the past year, reflecting a strong demand amid a backdrop of scenic landscapes and vibrant communities."
    },
    { 
      title: 'Interest Rate Outlook', 
      message: 'The Bank of Canada\'s latest updates hint at maintaining interest rates at current levels through the end of 2024 to support economic recovery and stabilize housing markets.'
    },
    { 
      title: 'Retirement Planning Tip', 
      message: 'Leveraging both RRSP and TFSA accounts for retirement planning not only provides tax benefits but also flexibility in withdrawal, which is crucial for managing retirement cash flows efficiently.'
    },
    { 
      title: 'Emerging Markets Focus', 
      message: 'Investments in emerging markets can offer high returns. In 2023, sectors like South American agriculture and Southeast Asian tech showed significant gains.'
    },
    { 
      title: 'Tax Tips', 
      message: 'Not sure if you ever want to buy a home? The FHSA (First Home Savings Account) could still be for you. By utilizing the FHSA even without the goal of purchasing a home can still significantly reduce your taxable income! Of course Optiml allows you to test this theory.' 
    },
    {
      title: 'Buying a home in Canada',
      message: 'In recent years, the quintessential Canadian dream of home-ownership has become increasingly challenging. In 2023, the national average home price in Canada saw a significant increase, rising nearly 10% from the previous year. This trend underscores the necessity of a personalized financial strategy to achieve home-ownership. Optiml provides customized planning tailored to your unique financial situation. Update your FHSA section and explore various scenarios with our what-if feature to understand the adjustments needed to realize your dream of purchasing your ideal home.'
    },
    {
      title: 'To much manual data input?',
      message: 'Don\'t forget to try out the Linked Investment Accounts feature found in your settings, to streamline the set up and future updates process.'
    },
    {
      title: 'Feature Missing',
      message: 'Certain account type missing? We want to make sure Optiml is perfectly tailored to everyone. Let us know what you would like to see in Optiml and we will add it to our development list. Email Contact@optiml.ca'
    },
    {
      title: "Utilize the FHSA",
      message: "First-time home buyers in Canada should consider the First Home Savings Account (FHSA) which combines the tax benefits of both an RRSP and a TFSA. You can contribute up to $8,000 annually, and contributions are tax-deductible."
    },
    {
      title: "Credit Score Awareness",
      message: "Maintaining a healthy credit score is crucial for financial health. In Canada, scores range from 300 to 900. A score above 700 is generally considered good, making it easier to obtain loans with favorable terms."
    },
    {
      title: "The Power of Compound Interest",
      message: "Albert Einstein reportedly called compound interest 'the eighth wonder of the world.' Compounding can turn modest savings into substantial sums; for example, investing just $100 monthly at a 5% annual return becomes over $76,000 in 30 years."
    },
    {
      title: "Global Stock Market Worth",
      message: "As of 2024, the total value of global stock markets is estimated to be over $95 trillion, with the New York Stock Exchange being the largest, representing over $30 trillion of this total."
    },
    {
      title: "Canadian Housing Market Resilience",
      message: "Despite global economic fluctuations, the Canadian real estate market has shown remarkable resilience. Average national home prices have consistently risen, with an average annual increase of approximately 5% over the past decade."
    },
    {
      title: "Cryptocurrency Regulation in Canada",
      message: "Canada is among the first countries to regulate cryptocurrencies under anti-money laundering (AML) laws. This regulatory framework aims to enhance transparency and security for Canadian crypto investors."
    },
    {
      title: "New Capital Gains Tax Rates",
      message: "Significant changes to Canada's capital gains tax have been implemented. For individuals, capital gains exceeding $250,000 are now taxed at an inclusion rate of 2/3%. Similarly, businesses will see all capital gains taxed at this increased rate of 2/3%. These updates are crucial for financial planning and investment strategies, and are of coursed considered in Optiml."
    },
    {
      title: "When do I take CPP & OAS?",
      message: "You are not alone in asking this question. Each year, thousands of Canadians facing retirement find themselves spending countless hours searching for answers, only to encounter generic advice that doesn’t quite fit their specific needs. This is exactly why so many people turn to Optiml—to discover and understand the precise timing that’s right for them."
    }
    
    
  ];
  

  return (
    <Box
      display={"flex"}
      padding={isMobile ? "10px" : "20px"}
      flexDirection={"column"}
      alignItems={"flex-start"}
      gap={"20px"}
      alignSelf={"stretch"}
      borderRadius={"10px"}
      border={"1px solid #00A2D4"}
      bg={"#FFF"}
    >
      <Box
        display={"flex"}
        padding={isMobile ? "10px" : "20px"}
        justifyContent={"space-between"}
        alignItems={"center"}
        alignSelf={"stretch"}
        borderRadius={"5px"}
        border={"1px solid #00A2D4"}
        bg={"#F3FAFF"}
      >
        <VStack alignItems={"flex-start"} gap={isMobile ? "10px" : "20px"}>
          <HStack alignItems={"flex-start"}>
            <Text
              color={"#00466D"}
              fontFamily={"Nunito"}
              fontSize={isMobile ? "12px" : "14px"}
              fontStyle={"normal"}
              fontWeight={"600"}
              lineHeight={"130%"}
            >
              Strategy:
            </Text>
            <Text
              color={"#00A2D4"}
              fontFamily={"Nunito"}
              textAlign={"right"}
              fontSize={isMobile ? "12px" : "14px"}
              fontStyle={"normal"}
              fontWeight={"800"}
              lineHeight={"130%"}
            >
              {strategy}
            </Text>
          </HStack>
          <HStack alignItems={"flex-start"} gap={"5px"}>
            <Text
              color={"#00466D"}
              fontFamily={"Nunito"}
              fontSize={isMobile ? "12px" : "14px"}
              fontStyle={"normal"}
              fontWeight={"600"}
              lineHeight={"130%"}
            >
              Estimated run time:
            </Text>
            {ageCalculated && (
              <Text
                color={"#00A2D4"}
                fontFamily={"Nunito"}
                textAlign={"right"}
                fontSize={isMobile ? "12px" : "14px"}
                fontStyle={"normal"}
                fontWeight={"800"}
                lineHeight={"130%"}
              >
                {timeEstimate}
              </Text>
            )}
          </HStack>
        </VStack>
        <LoadingAnimation />
      </Box>
      <Text
        color={"#00466D"}
        textAlign={"center"}
        fontFamily={"Nunito"}
        fontSize={"14px"}
        fontStyle={"normal"}
        fontWeight={"600"}
        lineHeight={"14px"}
      >
        This analysis is optimizing your taxes and estate for a {duration}
        -year duration. Please keep in mind that this represents the optimal
        strategy for the selected analysis length. Should you decide to change
        the analysis duration in a future run, the results may vary.
      </Text>
      <Box
      display={"flex"}
      flexDirection={"column"}
      alignItems={"flex-start"}
      gap={"5px"}
      alignSelf={"stretch"}
      bg={"#FFF"}
      >
        <LoadingCards cards={cards} interval={20000} />
        {/* <div className="loading-screen-inner-box">
          <Text
            color={"#00A2D4"}
            textAlign={"right"}
            fontFamily={"Nunito"}
            fontSize={isMobile ? "12px" : "16px"}
            fontStyle={"normal"}
            fontWeight={"800"}
            lineHeight={"130%"}
          >
            Deposit Strategy Generated
          </Text>
          <HStack>
            <Text fontSize={"24px"} color={"#B3B3BF"}>
              {" "}
              |{" "}
            </Text>
            {!expenseDone && (
              <div className="loading-circle-container">
                {ageCalculated && <div className="loading-circle"></div>}
              </div>
            )}
            {expenseDone && (
              <img src={loadingDone} className="loading-done-check" />
            )}
          </HStack>
        </div>
        {userInfo?.Province != "QC" && (
          <div className="loading-screen-inner-box">
            <Text
              color={"#00A2D4"}
              textAlign={"right"}
              fontFamily={"Nunito"}
              fontSize={isMobile ? "12px" : "16px"}
              fontStyle={"normal"}
              fontWeight={"800"}
              lineHeight={"130%"}
            >
              Withdrawal Strategy Generated
            </Text>
            <HStack>
              <Text fontSize={"24px"} color={"#B3B3BF"}>
                {" "}
                |{" "}
              </Text>
              {!cppDone && (
                <div className="loading-circle-container">
                  {ageCalculated && <div className="loading-circle"></div>}
                </div>
              )}
              {cppDone && (
                <img src={loadingDone} className="loading-done-check" />
              )}
            </HStack>
          </div>
        )}
        {userInfo?.Province === "QC" && (
          <div className="loading-screen-inner-box">
            <Text
              color={"#00A2D4"}
              textAlign={"right"}
              fontFamily={"Nunito"}
              fontSize={isMobile ? "12px" : "16px"}
              fontStyle={"normal"}
              fontWeight={"800"}
              lineHeight={"130%"}
            >
              Optimized Canadian Benefits
            </Text>
            <HStack>
              <Text fontSize={"24px"} color={"#B3B3BF"}>
                {" "}
                |{" "}
              </Text>
              {!cppDone && (
                <div className="loading-circle-container">
                  {ageCalculated && <div className="loading-circle"></div>}
                </div>
              )}
              {cppDone && (
                <img src={loadingDone} className="loading-done-check" />
              )}
            </HStack>
          </div>
        )}
        <div className="loading-screen-inner-box">
          <Text
            color={"#00A2D4"}
            textAlign={"right"}
            fontFamily={"Nunito"}
            fontSize={isMobile ? "12px" : "16px"}
            fontStyle={"normal"}
            fontWeight={"800"}
            lineHeight={"130%"}
          >
            Tax Minimizer Commenced
          </Text>
          <HStack>
            <Text fontSize={"24px"} color={"#B3B3BF"}>
              {" "}
              |{" "}
            </Text>
            {!oasDone && (
              <div className="loading-circle-container">
                {ageCalculated && <div className="loading-circle"></div>}
              </div>
            )}
            {oasDone && (
              <img src={loadingDone} className="loading-done-check" />
            )}
          </HStack>
        </div>
        <div className="loading-screen-inner-box">
          <Text
            color={"#00A2D4"}
            textAlign={"right"}
            fontFamily={"Nunito"}
            fontSize={isMobile ? "12px" : "16px"}
            fontStyle={"normal"}
            fontWeight={"800"}
            lineHeight={"130%"}
          >
            Adjusting to Strategy
          </Text>
          <HStack>
            <Text fontSize={"24px"} color={"#B3B3BF"}>
              {" "}
              |{" "}
            </Text>
            {!strategyDone && (
              <div className="loading-circle-container">
                {ageCalculated && <div className="loading-circle"></div>}
              </div>
            )}
            {strategyDone && (
              <img src={loadingDone} className="loading-done-check" />
            )}
          </HStack>
        </div>
        <div className="loading-screen-inner-box">
          <Text
            color={"#00A2D4"}
            textAlign={"right"}
            fontFamily={"Nunito"}
            fontSize={isMobile ? "12px" : "16px"}
            fontStyle={"normal"}
            fontWeight={"800"}
            lineHeight={"130%"}
          >
            Maximized Finances Achieved
          </Text>
          <HStack>
            <Text fontSize={"24px"} color={"#B3B3BF"}>
              {" "}
              |{" "}
            </Text>
            {!actionDone && (
              <div className="loading-circle-container">
                {ageCalculated && <div className="loading-circle"></div>}
              </div>
            )}
            {actionDone && (
              <img src={loadingDone} className="loading-done-check" />
            )}
          </HStack>
        </div>
        <div className="loading-screen-inner-box">
          <Text
            color={"#00A2D4"}
            textAlign={"right"}
            fontFamily={"Nunito"}
            fontSize={isMobile ? "12px" : "16px"}
            fontStyle={"normal"}
            fontWeight={"800"}
            lineHeight={"130%"}
          >
            Optiml Plan Generated
          </Text>
          <HStack>
            <Text fontSize={"24px"} color={"#B3B3BF"}>
              {" "}
              |{" "}
            </Text>
            {!taxDone && (
              <div className="loading-circle-container">
                {ageCalculated && <div className="loading-circle"></div>}
              </div>
            )}
            {taxDone && (
              <img src={loadingDone} className="loading-done-check" />
            )}
          </HStack>
        </div> */}
      </Box>

      <Text
        width={"100%"}
        color={"#00466D"}
        textAlign={"center"}
        fontFamily={"Nunito"}
        fontSize={isMobile ? "12px" : "16px"}
        fontStyle={"normal"}
        fontWeight={"500"}
        lineHeight={"130%"}
      >
        Please don't exit the screen or close the browser while the analysis is
        running
      </Text>
    </Box>
  );
};

export default LoadingScreenMessages;
