import { useAuth0 } from "@auth0/auth0-react";
import {
  Box,
  Drawer,
  DrawerContent,
  DrawerOverlay,
  Flex,
  HStack,
  Icon,
  Popover,
  PopoverContent,
  PopoverTrigger,
  Text,
  useDisclosure,
  useMediaQuery,
} from "@chakra-ui/react";
import React, { useEffect, useRef, useState } from "react";
import { useStore } from "../../store";
import {
  HeaderHamburgerIcon,
  LogoutIcon,
  OptimlMobileIcon,
  ProfileIcon,
} from "../CustomIcons";
import Link from "../Link";
import Modal from "../Modal";
import MobileSideBar from "../mobilesidebar";

function HeaderMobile(props) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const btnRef = React.useRef();

  const userOptiData = useStore((store) => store.userOptiData);
  const userInfo = useStore((store) => store.userInfo);
  const [newUser, setNewUser] = useState();
  const [showStripeModal, setShowStripeModal] = useState(false);
  const [profileIcon, setProfileIcon] = useState(userInfo?.Picture); // Initialize with userInfo's picture

  const { logout, user } = useAuth0(); // Moved useAuth0 here for proper initialization
  const { FirstName: firstName, LastName: lastName } = userInfo || {}; // Destructure userInfo safely

  const [isLargerThan1200] = useMediaQuery(
    "(min-width: 500px) and (max-width: 1250px)"
  );
  const popUpProfile = [
    {
      label: "View Profile",
      icon: <ProfileIcon svgwidth={"15px"} svgheight={"15px"} />,
      path: "/",
    },
    {
      label: "Logout",
      icon: <LogoutIcon svgwidth={"15px"} svgheight={"15px"} />,
    },
  ];
  useEffect(() => {
    if (userOptiData?.primaryUserData?.expenses?.length > 1) {
      setNewUser(false);
    } else if (userOptiData?.primaryUserData?.expenses?.length === undefined) {
      setNewUser(true);
    }
    if (userInfo?.Picture) {
      setProfileIcon(userInfo?.Picture);
    }
  }, [userOptiData, userInfo]);

  const handleHamburgerClick = () => {
    // Removed modalShow parameter, added state directly in the component
    const [showModal, setShowModal] = useState(false);

    // const [isSidebarOpen, setIsSidebarOpen] = useState(false);
    const timerRef = useRef(null);

    // Function to reset the timer
    const resetTimer = () => {
      timerRef.current = setTimeout(() => {
        onClose(); // Close the sidebar after 1 second of inactivity
      }, 100); // 1000 milliseconds = 1 second
    };

    return (
      <>
        <Drawer
          isOpen={isOpen}
          placement="left"
          onClose={onClose}
          finalFocusRef={btnRef}
        >
          <DrawerOverlay />
          <DrawerContent
            maxW={"fit-content"}
            sx={{
              transform: "none !important",
            }}
          >
            <Modal show={showModal} onClose={() => setShowModal(false)} />
            <MobileSideBar
              setShowModal={() => setShowModal(true)}
              resetTimer={resetTimer}
            />
          </DrawerContent>
        </Drawer>
      </>
    );
  };

  return (
    <Flex
      display={"flex"}
      padding={isLargerThan1200 ? "15px 50px" : "15px"}
      justifyContent={"space-between"}
      alignItems={"center"}
      alignSelf={"stretch"}
      bg={"#FFF"}
      width={"100%"}
    >
      {newUser && (
        <Link href="/">
          <Icon w={"50px"} h={"35px"} alignSelf={"flex-start"}>
            <OptimlMobileIcon />
          </Icon>
        </Link>
      )}
      {!newUser && (
        <Link href="/dashboard-page">
          <Icon w={"35px"} h={"35px"}>
            <OptimlMobileIcon />
          </Icon>
        </Link>
      )}

      <Box display={"flex"} alignItems={"center"} gap={"15px"}>
        <Popover placement="bottom">
          <PopoverTrigger>
            <Box
              cursor={"pointer"}
              display={"flex"}
              flexDirection={"row"}
              alignItems={"center"}
              gap={"10px"}
            >
              <Text
                color={"#00466D"}
                fontFamily={"Nunito"}
                fontSize={"14px"}
                fontStyle={"normal"}
                fontWeight={"600"}
                lineHeight={"normal"}
              >
                {`Welcome, ${firstName}`}
              </Text>

              <Box>
                <Box
                  display="flex"
                  flexDirection="column"
                  justifyContent="center"
                  alignItems="center"
                  width="30px"
                  height="30px"
                  // padding="10px"
                  gap="10px"
                  borderRadius="50%"
                  border="1px solid #0093D0"
                  background="#FFF"
                  position="relative"
                  overflow="hidden"
                >
                  {profileIcon ? (
                    <img
                      src={profileIcon}
                      alt="Profile"
                      style={{
                        width: "100%",
                        height: "100%",
                        objectFit: "cover",
                        objectPosition: "top center",
                        justifyContent: "center",
                      }}
                    />
                  ) : (
                    <div className="profile-picture">
                      {firstName?.charAt(0)}
                      {lastName?.charAt(0)}
                    </div>
                  )}
                </Box>
              </Box>
            </Box>
          </PopoverTrigger>
          <PopoverContent w={"200px"} marginTop={"25px"}>
            <Box
              display={"inline-flex"}
              padding={"10px"}
              flexDirection={"column"}
              alignItems={"flex-start"}
              gap={"10px"}
              borderRadius={"5px"}
              border={"1px solid #D9D9D9"}
              bg={"#FFF"}
            >
              {popUpProfile.map((item) =>
                item.path ? (
                  <Link
                    key={item.label}
                    href={item.path}
                    style={{ textDecoration: "none" }}
                  >
                    <HStack
                      justifyContent="center"
                      gap={"15px"}
                      alignItems={"baseline"}
                      paddingLeft={"10px"}
                    >
                      <Box fontSize={"20px"} objectFit={"fill"}>
                        {item.icon}
                      </Box>
                      <Box justifyItems={"baseline"}>
                        <Text
                          fontFamily="Nunito"
                          fontSize={"16px"}
                          fontStyle={"normal"}
                          fontWeight={"600"}
                          lineHeight={"normal"}
                        >
                          {item.label}
                        </Text>
                      </Box>
                    </HStack>
                  </Link>
                ) : (
                  <Box
                    key={item.label}
                    onClick={logout}
                    style={{ textDecoration: "none", cursor: "pointer" }}
                  >
                    <HStack
                      justifyContent="center"
                      gap={"15px"}
                      alignItems={"baseline"}
                      paddingLeft={"10px"}
                    >
                      <Box fontSize={"20px"} objectFit={"fill"}>
                        {item.icon}
                      </Box>
                      <Box justifyItems={"baseline"}>
                        <Text
                          fontFamily="Nunito"
                          fontSize={"16px"}
                          fontStyle={"normal"}
                          fontWeight={"600"}
                          lineHeight={"normal"}
                        >
                          {item.label}
                        </Text>
                      </Box>
                    </HStack>
                  </Box>
                )
              )}
            </Box>
          </PopoverContent>
        </Popover>

        <Icon style={{ transform: "none" }} onClick={onOpen} cursor={"pointer"}>
          {handleHamburgerClick(props.setShowModal)}
          <HeaderHamburgerIcon />
        </Icon>
      </Box>
    </Flex>
  );
}

export default HeaderMobile;
