import { useAuth0 } from "@auth0/auth0-react";
import {
  Box,
  Flex,
  HStack,
  Icon,
  Spacer,
  Text,
  VStack,
  useBreakpointValue,
  useColorModeValue,
  useDisclosure,
  useMediaQuery,
} from "@chakra-ui/react";
import React, { useContext, useEffect, useState } from "react";
import { useStore } from "../store";
import {
  ActionPlanIcon,
  AnalysisArchiveIcon,
  BackToDashboardIcon,
  DashboardIcon,
  DebtIcon,
  ExpensesIcon,
  HelpIcon,
  IncomeIcon,
  InsightsIcon,
  InvestmentIcon,
  LogoutIcon,
  OptimizeIcon,
  OptimlBetaIcon,
  ProfileIcon,
  SettingIcon,
  TaxesIcon,
  TutorialVideoIcon,
  UpdateMyPlanIcon,
} from "./CustomIcons";
import Link from "./Link";
import { StripeContext } from "./StripeContext";
import StripeUpgradeModal from "./StripeUpgradeModal";
import CustomBox from "./selectionButton";
import HelpPopup from "./HelpPop";

function MobileSideBar(props) {
  const bgColor = useColorModeValue("#E0F2FF", "gray.700");
  const sidebarWidth = useBreakpointValue({ base: "240px", w: "20%" });
  const [selectedOption, setSelectedOption] = useState(null);

  const { logout } = useAuth0();
  const showNewOptimization = useStore((store) => store.showNewOptimization);
  const userPageLockInfo = useStore((store) => store.userPageLockInfo);

  const { stripePlan, setStripePlan } = useContext(StripeContext);

  const { setShowModal, resetTimer } = props;

  function newOptimization() {
    setShowModal();
  }

  const userOptiData = useStore((store) => store.userOptiData);
  const [newUser, setNewUser] = useState();
  const [showStripeModal, setShowStripeModal] = useState(false);
  const setShowNewOptimization = useStore(
    (store) => store.setShowNewOptimization
  );
  const {
    isOpen: isHelpModalOpen,
    onOpen: onOpenHelpModal,
    onClose: onCloseHelpModal,
  } = useDisclosure();

  useEffect(() => {
    if (userOptiData?.primaryUserData?.expenses?.length > 1) {
      setNewUser(false);
    } else if (userOptiData?.primaryUserData?.expenses?.length === undefined) {
      setNewUser(true);
    }
  }, [userOptiData]);
  const hasNonZeroPayment = (array) => array?.some((payment) => payment !== 0);

  const debtsCondition =
    (hasNonZeroPayment(
      userOptiData?.primaryUserData?.PrimaryResidence?.Balance
    ) ||
      hasNonZeroPayment(
        userOptiData?.primaryUserData?.RecreationPropertyYearlyBalance
      ) ||
      hasNonZeroPayment(
        userOptiData?.primaryUserData?.IncomePropertyYearlyBalance
      ) ||
      hasNonZeroPayment(userOptiData?.primaryUserData?.totalDebt)) &&
    !showNewOptimization &&
    !newUser;

  const navigation = [
    ...(!newUser && !showNewOptimization
      ? [
          {
            label: "Dashboard",
            icon: <DashboardIcon svgwidth={"20px"} svgheight={"20px"} />,
            url: "/dashboard-page",
          },
        ]
      : []),
    ...(!newUser && !showNewOptimization
      ? [
          {
            label: "Action Plan",
            icon: <ActionPlanIcon svgwidth={"20px"} svgheight={"20px"} />,
            url: "/action-plan-page",
          },
        ]
      : []),
    ...(!newUser && showNewOptimization
      ? [
          {
            label: "Back to Dashboard",
            icon: <BackToDashboardIcon svgwidth={"20px"} svgheight={"20px"} />,
            url: "/dashboard-page",
          },
          {
            label: "Settings",
            icon: <SettingIcon svgwidth={"20px"} svgheight={"20px"} />,
            children: [
              { label: "Profile", url: "/" },
              stripePlan && {
                label: "Investment Accounts",
                url: "/investments-account",
              },
              {
                label: "Passwords & Security",
                url: "/password-and-security",
              },
              // {
              //   label: "Assumptions",
              //   url: "/assumptions",
              // },
              // {
              //   label: "Notifications",
              //   url: "/notifications",
              // },
              stripePlan && {
                label: "Manage Subscription",
                url: "/changeplan",
              },
            ],
            url: "/",
          },
        ]
      : []),
    ...(newUser && showNewOptimization
      ? [
          {
            label: "Settings",
            icon: <SettingIcon svgwidth={"20px"} svgheight={"20px"} />,
            children: [
              { label: "Profile", url: "/" },
              stripePlan && {
                label: "Investment Accounts",
                url: "/investments-account",
              },
              {
                label: "Passwords & Security",
                url: "/password-and-security",
              },
              // {
              //   label: "Assumptions",
              //   url: "/assumptions",
              // },
              // {
              //   label: "Notifications",
              //   url: "/notifications",
              // },
              stripePlan && {
                label: "Manage Subscription",
                url: "/changeplan",
              },
            ],
            url: "/",
          },
        ]
      : []),
    {
      label: "Income",
      icon: <IncomeIcon svgwidth={"20px"} svgheight={"20px"} />,
      children: [
        { label: "Income", url: "/revenue" },
        {
          label: "CPP",
          url: "/income-cpp",
        },
        { label: "OAS", url: "/income-oas" },
        { label: "Pension", url: "/income-employer-pension" },
        { label: "Other Income", url: "/income-other" },
      ],
      url: !showNewOptimization && !newUser ? "/income" : "/revenue",
      disabled: newUser && !userPageLockInfo?.IncomePage,
    },
    {
      label: "Investments",
      icon: <InvestmentIcon svgwidth={"20px"} svgheight={"20px"} />,
      children: [
        { label: "TFSA", url: "/investment-tfsa-page" },
        { label: "FHSA", url: "/investment-fhsa-page" },
        {
          label: "RRSP",
          url: "/rrsp-page",
        },
        { label: "LIRA", url: "/other-reg-page" },
        {
          label: "Non-Registered",
          url: "/non-reg-page",
        },
        {
          label: "Fixed Income",
          url: "/fixed-income-page",
        },
        {
          label: "Cash",
          url: "/cash-savings",
        },
        {
          label: "Assets",
          url: "/other-assets-page",
        },
      ],
      url:
        !showNewOptimization && !newUser
          ? "/investments"
          : "/investment-tfsa-page",
      disabled: newUser && !userPageLockInfo?.InvestmentsPage,
    },
    ...(debtsCondition
      ? [
          {
            label: "Debts",
            icon: <DebtIcon svgwidth={"20px"} svgheight={"20px"} />,
            url: "/DebtPage",
          },
        ]
      : []),
    {
      label: "Expenses",
      icon: <ExpensesIcon svgwidth={"20px"} svgheight={"20px"} />,
      children: [
        { label: "Debt", url: "/expenses-debt" },
        {
          label: "Total Expenses",
          url: "/expenses-household",
        },
        {
          label: "Goals",
          url: "/Goals",
        },
      ],
      url: !showNewOptimization && !newUser ? "/expenses" : "expenses-debt",
      disabled: newUser && !userPageLockInfo?.ExpensesPage,
    },
    ...(newUser || showNewOptimization
      ? [
          {
            label: "Optimize",
            icon: <OptimizeIcon svgwidth={"20px"} svgheight={"20px"} />,
            url: "/lifestrategy",
            disabled: newUser && !userPageLockInfo?.OptimizationPage,
          },
        ]
      : []),
    ...(!newUser && !showNewOptimization
      ? [
          {
            label: "Taxes",
            icon: <TaxesIcon svgwidth={"20px"} svgheight={"20px"} />,
            url: "/taxes",
          },
        ]
      : []),
    // ...(!newUser && !showNewOptimization
    //   ? [
    //       {
    //         label: "Insights",
    //         icon: <InsightsIcon svgwidth={"20px"} svgheight={"20px"} />,
    //         children: [
    //           {
    //             label:"overview",
    //             url:"/insights-page",
    //           },
    //           {
    //             label:"Optiml vs OSFA",
    //             url:"/insights-page",
    //           },
    //           {
    //             label:"Compare your analysis",
    //             url:"/insights-page",
    //           },
    //           {
    //             label:"Manual configuration",
    //             url:"/insights-page",
    //           },
    //           {
    //             label:"Monte Carlo Analysis",
    //             url:"/insights-page",
    //           },
    //         ],
    //         url: "/insights-page",
    //       },
    //     ]
    //   : []),
    ...(!newUser &&
    stripePlan?.items?.data?.[0]?.price?.lookup_key != "OPTIML_ESSENTIALS" &&
    stripePlan?.items?.data?.[0]?.price?.lookup_key != "OPTIML_ESSENTIALS_M" &&
    stripePlan &&
    !showNewOptimization
      ? [
          {
            label: "Analysis Archive",
            icon: <AnalysisArchiveIcon svgwidth={"20px"} svgheight={"20px"} />,
            url: "/analysis-archive",
          },
        ]
      : []),
  ];

  const SettingsNavigation = [
    {
      label: "Tutorial videos",
      icon: <TutorialVideoIcon svgheight={"20px"} svgwidth={"20px"} />,
      url: "/tutorial-page",
    },
    !newUser &&
      !showNewOptimization && {
        label: "Settings",
        icon: <SettingIcon svgheight={"20px"} svgwidth={"20px"} />,
        url: "/",
      },
  ].filter(Boolean);

  const LogoutNavigation = [
    {
      label: "Help",
      icon: <HelpIcon svgheight={"16px"} svgwidth={"16px"} />,
      // href: "mailto:support@optiml.ca",
      // target: "_blank",
      //  href: "/help",
    },
    {
      label: "Logout",
      icon: <LogoutIcon svgheight={"16px"} svgwidth={"16px"} />,
      action: logout,
    },
  ];

  const handleSelect = (label) => {
    setSelectedOption(label === selectedOption ? null : label);
    if (label === "Help") {
      onOpenHelpModal();
    }
  };

  function UpgradeStripeModal() {
    setShowStripeModal(!showStripeModal);
  }

  const calculateDaysLeft = (trialEndTimestamp) => {
    const currentDate = new Date();
    const trialEndDate = new Date(trialEndTimestamp * 1000); // Convert to milliseconds
    const differenceInTime = trialEndDate.getTime() - currentDate.getTime();
    const differenceInDays = Math.ceil(differenceInTime / (1000 * 3600 * 24));
    return differenceInDays;
  };

  function NavigationItem({ item, selectedOption, handleSelect }) {
    const [isOpenSubMenu, setIsOpenSubMenu] = useState(false);

    const handleToggle = (e) => {
      // Prevent default behavior when clicking on an item with children
      if (item?.children) {
        e.preventDefault();
        setIsOpenSubMenu(!isOpenSubMenu); // Toggle based on the current state directly
      } else {
        // Direct selection handling if no children and the label is not the same as the last selected label
        handleSelect(item?.label);
      }
    };

    return (
      <div>
        {/* {!isOpenSubMenu && ( */}

        <div onClick={handleToggle} style={{ cursor: "pointer" }}>
          <Link
            key={item?.label}
            href={item?.url}
            disabled={item?.children}
            style={{ textDecoration: "none" }}
          >
            <CustomBox
              item={item}
              selectedOption={selectedOption}
              handleSelect={() => handleSelect(item?.label)}
            />
          </Link>
        </div>
        {/* // )} */}
        {(isOpenSubMenu && item?.children) ||
          (selectedOption === item?.label && item?.children && (
            <div style={{ marginLeft: "20px" }}>
              {item.children &&
                item.children.map((subItem) => {
                  if (!subItem.label && !subItem.url) return null; // Skip rendering if subItem is falsy
                  return (
                    <Link
                      key={subItem.label}
                      href={subItem.url}
                      style={{ textDecoration: "none" }}
                      onClick={(e) => {
                        e.preventDefault();
                        handleSelect(subItem.label); // Ensure this is always a string, not an object
                      }}
                    >
                      <Box margin={"0.5rem"} onClick={resetTimer}>
                        <Text
                          fontFamily="Nunito"
                          fontSize={"14px"}
                          fontStyle={"normal"}
                          fontWeight={"600"}
                          lineHeight={"normal"}
                          margin={"2rem"}
                        >
                          {subItem.label}
                        </Text>
                      </Box>
                    </Link>
                  );
                })}
            </div>
          ))}
      </div>
    );
  }

  const [isLargerThan1200] = useMediaQuery(
    "(min-width: 500px) and (max-width: 1250px)"
  );

  // const [isLargerThan500] = useMediaQuery("(min-width: 500px)");
  // const isMobile = !isLargerThan500;

  return (
    <div>
      <StripeUpgradeModal
        isOpen={showStripeModal}
        closeModal={UpgradeStripeModal}
      />
      <Flex h="100dvh">
        <Box
          display="flex"
          as="sidebar"
          bg={bgColor}
          boxShadow="md"
          padding={
            isLargerThan1200 ? "20px 20px 100px 20px" : "20px 20px 50px 20px"
          }
          bgGradient="linear(to-l, rgba(0, 180, 235, 1)1.5px, transparent 1px)"
        >
          <VStack
            display="flex"
            alignSelf={"stretch"}
            gap={"5px"}
            flexDirection={"column"}
            flex={1}
            align="center"
          >
            {!newUser && (
              <Link href="/dashboard-page">
                <Box
                  paddingBottom={"16px"}
                  objectFit={"contain"}
                  width={"100%"}
                  justifyContent={"center"}
                  alignSelf="center"
                  onClick={() => {
                    setShowNewOptimization(false);
                    handleSelect("Dashboard");
                  }}
                >
                  <OptimlBetaIcon />
                </Box>
              </Link>
            )}
            {!newUser &&
              !showNewOptimization &&
              navigation.map((item) => (
                <Link
                  key={item?.label}
                  href={item?.url}
                  disabled={item?.disabled}
                  style={{ textDecoration: "none" }}
                >
                  <CustomBox
                    item={item}
                    selectedOption={selectedOption}
                    handleSelect={handleSelect}
                    resetTimer={item.subItem ? " " : resetTimer}
                  />
                </Link>
              ))}
            {(newUser || showNewOptimization) &&
              navigation.map((item) => (
                <NavigationItem
                  key={item?.label}
                  item={item}
                  selectedOption={selectedOption}
                  handleSelect={handleSelect}
                />
              ))}
            {!newUser && !showNewOptimization && (
              <Box
                onClick={newOptimization}
                justifyContent={"center"}
                alignItems={"center"}
                display={"flex"}
                flexDirection={"row"}
                gap={"10px"}
                alignSelf={"stretch"}
                borderRadius="5px"
                border="1px solid #00A2D4" // Add border
                padding={"15px 20px"}
                bg="white"
                as="button"
                _hover={{
                  transform: "scale(1.02)",
                  boxShadow: "0 6px 12px rgba(0, 0, 0, 0.15)",
                }}
              >
                <Icon
                  as={UpdateMyPlanIcon}
                  svgheight={"20px"}
                  svgwidth={"20px"}
                />
                <Text
                  fontFamily="Nunito"
                  fontStyle="normal"
                  fontWeight="600"
                  lineHeight="normal"
                  textColor="rgba(0, 180, 235, 1)"
                  fontSize="12px"
                >
                  Update My Plan
                </Text>
              </Box>
            )}
            <Spacer minH="5%" /> {/* Adjust the height value as needed */}
            {(stripePlan?.items?.data?.[0]?.price?.lookup_key ===
              "OPTIML_ESSENTIALS" ||
              stripePlan?.items?.data?.[0]?.price?.lookup_key ===
                "OPTIML_ESSENTIALS_M") &&
              calculateDaysLeft(stripePlan?.trial_end) >= 1 && (
                <Box
                  onClick={UpgradeStripeModal}
                  justifyContent={"center"}
                  alignItems={"center"}
                  alignSelf={"stretch"}
                  borderRadius="5px"
                  border="1px solid #00A2D4" // Add border
                  padding={"15px 20px"}
                  bg="white"
                  as="button"
                >
                  <Text
                    fontFamily="Nunito"
                    fontStyle="normal"
                    fontWeight="600"
                    lineHeight="normal"
                    textColor="rgba(0, 180, 235, 1)"
                    fontSize="12px"
                  >
                    Free Trial: {calculateDaysLeft(stripePlan?.trial_end)} days
                    remaining
                  </Text>
                </Box>
              )}
            {(stripePlan?.items?.data?.[0]?.price?.lookup_key ===
              "OPTIML_ESSENTIALS" ||
              stripePlan?.items?.data?.[0]?.price?.lookup_key ===
                "OPTIML_ESSENTIALS_M" ||
              stripePlan?.items?.data?.[0]?.price?.lookup_key === "PRO" ||
              stripePlan?.items?.data?.[0]?.price?.lookup_key === "PRO_M") && (
              <Box
                onClick={UpgradeStripeModal}
                as="button"
                bg="#00A2D4"
                borderRadius="5px"
                border="1px solid var(--Optiml---Midnight-Blue, #00466D)" // Add border
                borderColor="#00466D" // Adjust border color
                padding={"15px 20px"}
                justifyContent={"center"}
                alignItems={"center"}
                alignSelf={"stretch"}
              >
                <Text
                  fontSize="12px"
                  fontFamily=" Nunito"
                  fontStyle="normal"
                  fontWeight="600"
                  lineHeight="normal"
                  textColor="white"
                >
                  UPGRADE NOW
                </Text>
              </Box>
            )}
            {SettingsNavigation.map((item) => (
              <Link key={item.label} href={item.url}>
                <CustomBox
                  item={item}
                  selectedOption={selectedOption}
                  handleSelect={handleSelect}
                  resetTimer={item.subItem ? " " : resetTimer}
                />
              </Link>
            ))}
            {LogoutNavigation.map((item) => (
              // <Link href={item.href}>
              <Box
                as="button"
                borderRadius="5px"
                alignItems={"center"}
                gap={"15px"}
                padding={"5px 2px"}
                width={"200px"}
                display={"flex"}
                _hover={{
                  background: "#E9E9E9",
                  transform: "scale(1.02)",
                  boxShadow: "0 6px 12px rgba(0, 0, 0, 0.15)",
                }}
                color="#004069"
                onClick={() => handleSelect(item.label)}
              >
                <VStack spacing={"10px"} align="flex-start">
                  <a
                    key={item.label}
                    href={item.href}
                    target={item.target}
                    style={{ textDecoration: "none" }}
                    onClick={item.action} // Call handleSelect on click
                  >
                    <HStack justifyContent="flex-start" spacing={"13px"}>
                      {item.icon}
                      <Text
                        fontFamily="Nunito"
                        fontSize={"16px"}
                        fontStyle={"normal"}
                        fontWeight={"600"}
                        lineHeight={"normal"}
                      >
                        {item.label}
                      </Text>
                    </HStack>
                  </a>
                </VStack>
              </Box>
              // </Link>
            ))}
            <HelpPopup isOpen={isHelpModalOpen} onClose={onCloseHelpModal} />
          </VStack>
        </Box>
      </Flex>
    </div>
  );
}

export default MobileSideBar;
