// import {
//   Box,
//   Button,
//   Icon,
//   IconButton,
//   Input,
//   Menu,
//   MenuButton,
//   MenuItem,
//   MenuList,
//   Select,
//   Table,
//   Tbody,
//   Tooltip,
//   Td,
//   Text,
//   Th,
//   Thead,
//   Tr,
//   useMediaQuery,
// } from "@chakra-ui/react";
// import "react-datepicker/dist/react-datepicker.css"; // Import the default styles
// import { DatePickerIcon, InputBoxTitleBubbleTip } from "../CustomIcons";
// import { useEffect, useState } from "react";
// import { useDisclosure } from "@chakra-ui/react";
// import { ArrowBackIcon, ArrowForwardIcon } from "@chakra-ui/icons";

// function DatePickerButton({
//   Heading,
//   placeholder,
//   value,
//   onChange,
//   maxYear,
//   startingYear,
//   tooltipContent,
//   error,
// }) {
//   const [isLargerThan768] = useMediaQuery("(min-width: 500px)");
//   const isMobile = !isLargerThan768;
//   const boxWidth = isMobile ? "100%" : "45%";

//   const [selectedDate, setSelectedDate] = useState(value);
//   const [currentMonth, setCurrentMonth] = useState(new Date().getMonth());
//   const [currentYear, setCurrentYear] = useState(new Date().getFullYear());

//   const { isOpen, onOpen, onToggle, onClose } = useDisclosure();

//   useEffect(() => {
//     if (value) {
//       const updatedDate = new Date(value);
//       setCurrentMonth(updatedDate.getMonth());
//       setCurrentYear(updatedDate.getFullYear());
//     }
//   }, [value]);

//   const handleSelectDate = (day) => {
//     console.log("date: ", day);
//     const selected = new Date(currentYear, currentMonth, day);
//     const formattedDate = selected.toISOString().split("T")[0]; // Format date to YYYY-MM-DD
//     setSelectedDate(formattedDate);
//     console.log("selected Date: ", selectedDate);
//     value = { selectedDate };
//     if (onChange) {
//       onChange({ target: { value: formattedDate } });
//     }
//   };

//   const handlePreviousMonth = () => {
//     setCurrentMonth((prev) => (prev === 0 ? 11 : prev - 1));
//     setCurrentYear((prev) => (currentMonth === 0 ? prev - 1 : prev));
//   };

//   const handleNextMonth = () => {
//     if (currentYear === maxYear && currentMonth === 11) {
//       // Prevent going to the next year if currentYear is maxYear and month is December
//       return;
//     }
//     setCurrentMonth((prev) => (prev === 11 ? 0 : prev + 1));
//     setCurrentYear((prev) =>
//       currentMonth === 11 && prev < maxYear ? prev + 1 : prev
//     );
//   };

//   // Adjust the year options generation logic to respect maxYear
//   const generateYearOptions = () => {
//     const currentYear = new Date().getFullYear();
//     const startYear = currentYear - (startingYear ? startingYear : 100); // Assuming a 100-year range, adjust as needed
//     const endYear = maxYear || currentYear + 50; // Use maxYear if provided, else default to +50 years

//     return Array.from(
//       { length: endYear - startYear + 1 },
//       (_, index) => startYear + index
//     );
//   };

//   const handleMonthChange = (e) => {
//     setCurrentMonth(parseInt(e.target.value));
//   };

//   const handleYearChange = (e) => {
//     setCurrentYear(parseInt(e.target.value));
//   };

//   const generateCalendar = () => {
//     const daysInMonth = new Date(currentYear, currentMonth + 1, 0).getDate();
//     const startDay = new Date(currentYear, currentMonth, 1).getDay();

//     let calendar = [];
//     let week = [];
//     let day = 1;

//     for (let i = 0; i < startDay; i++) {
//       week.push(<Td key={`empty-${i}`}></Td>);
//     }

//     while (day <= daysInMonth) {
//       // Zero-pad month and day for proper comparison
//       const monthStr = `${currentMonth + 1}`.padStart(2, "0");
//       const dayStr = `${day}`.padStart(2, "0");
//       const dateStr = `${currentYear}-${monthStr}-${dayStr}`;

//       week.push(
//         <Td
//           padding={0}
//           textAlign={"center"}
//           key={`day-${day}`}
//           // Change color based on whether this is the selected date
//           color={selectedDate === dateStr && "#00A2D4"}
//           _hover={{ bg: selectedDate === dateStr && "#00A2D4" }} // Optional: change hover background
//         >
//           <MenuItem
//             paddingTop={0}
//             bg={"none"}
//             _hover={{ bg: "#FFF" }}
//             justifyContent={"center"}
//             alignItems={"center"}
//             paddingBottom={0}
//             alignContent={"center"}
//             onClick={(function (d) {
//               return () => handleSelectDate(d);
//             })(day)}
//             fontWeight={"700"}
//             fontStyle={"normal"}
//           >
//             {day}
//           </MenuItem>
//         </Td>
//       );

//       if (week.length === 7) {
//         calendar.push(
//           <Tr padding={0} key={`week-${calendar.length}`}>
//             {week}
//           </Tr>
//         );
//         week = [];
//       }

//       day++;
//     }

//     if (week.length > 0) {
//       calendar.push(
//         <Tr padding={0} key={`week-${calendar.length}`}>
//           {week}
//         </Tr>
//       );
//     }

//     return calendar;
//   };

//   const monthNames = [
//     "January",
//     "February",
//     "March",
//     "April",
//     "May",
//     "June",
//     "July",
//     "August",
//     "September",
//     "October",
//     "November",
//     "December",
//   ];
//   const weekDays = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];

//   // Function to toggle the date picker open state
//   return (
//     <>
//       <Box
//         display={"inline-flex"}
//         flexDirection={"column"}
//         alignItems={"flex-start"}
//         w={boxWidth}
//         gap={"5px"}
//         onClick={(e) => e.stopPropagation()}
//       >
//         <Box
//           display={"flex"}
//           width={"240px"}
//           height={"15px"}
//           flexDirection={"column"}
//           justifyContent={"center"}
//           flexShrink={0}
//         >
//           <Text
//             color={"#00466D"}
//             fontFamily={"Nunito"}
//             fontSize={"16px"}
//             fontStyle={"normal"}
//             fontWeight={"600"}
//             lineHeight={"normal"}
//           >
//             {Heading}
//             {tooltipContent && (
//             <Tooltip
//               isOpen={isOpen}
//               padding={"10px"}
//               borderRadius={"5px"}
//               label={tooltipContent}
//               placement={isMobile ? "auto-start" : "right"}
//               hasArrow
//               closeOnClick={true}
//             >
//               <span
//                 onClick={onToggle}
//                 onMouseEnter={onOpen}
//                 onMouseLeave={onClose}
//               >
//                 <Icon as={InputBoxTitleBubbleTip} />
//               </span>
//             </Tooltip>
//           )}
//           </Text>
//         </Box>
//         <Box
//           display="flex"
//           height={"40px"}
//           alignItems={"center"}
//           textAlign={"start"}
//           justifyContent={"space-between"}
//           gap={"10px"}
//           alignSelf={"stretch"}
//           borderRadius={"5px"}
//           bg={error ? "#FFD2D2" : "#EDF2F7"} // Change background color on error
//           border={error ? "1px solid #E53E3E" : "none"} // Optional: add border color on error
//           cursor="pointer"
//         >
//           {/* <Input
//             placeholder={placeholder}
//             size="sm"
//             type="date"
//             value={value}
//             onChange={onChange}
//             DatePickerButton
//             max="2020-01-01"
//             __css={{}}
//           /> */}
//           <Menu>
//             <MenuButton
//               as={Button}
//               width="100%"
//               textAlign="left"
//               display="flex"
//               alignItems="center"
//               pl={2}
//               pr={2}
//               pt={0} // Adjust padding to reduce height
//               pb={0}
//               height="32px" // Reduced from 40px to 32px
//               rightIcon={
//                 <DatePickerIcon svgwidth={"18px"} svgheight={"20px"} />
//               }
//             >
//               <Text flex="1">{selectedDate || value  || placeholder}</Text>
//             </MenuButton>

//             <MenuList
//               zIndex={1000}
//               padding={0}
//               width={"100%"}
//               borderRadius={"10px"}
//             >
//               <Box
//                 display="flex"
//                 justifyContent="space-between"
//                 alignItems="center"
//                 padding={0}
//               >
//                 <IconButton
//                   icon={<ArrowBackIcon />}
//                   size="sm"
//                   onClick={handlePreviousMonth}
//                 />
//                 <Select
//                   value={currentMonth}
//                   onChange={handleMonthChange}
//                   width="45%"
//                   padding={0}
//                   border={"none"}
//                 >
//                   {monthNames.map((month, index) => (
//                     <option value={index} key={month}>
//                       {month}
//                     </option>
//                   ))}
//                 </Select>
//                 <Select
//                   value={currentYear}
//                   onChange={handleYearChange}
//                   width="80px"
//                   border={"none"}
//                   maxHeight="20dvh"
//                   overflowY={"hidden"}
//                 >
//                   {generateYearOptions().map((year) => (
//                     <option value={year} key={year}>
//                       {year}
//                     </option>
//                   ))}
//                 </Select>
//                 <IconButton
//                   icon={<ArrowForwardIcon />}
//                   size="sm"
//                   onClick={handleNextMonth}
//                 />
//               </Box>
//               <Table
//                 display={"table-caption"}
//                 borderRadius={"10px"}
//                 border={"1px solid #00A2D4"}
//                 variant="unstyled"
//                 size="sm"
//               >
//                 <Thead width={"inherit"} padding={0}>
//                   <Tr width={"inherit"} padding={0}>
//                     {weekDays.map((day) => (
//                       <Th
//                         textAlign={"center"}
//                         padding={0}
//                         textColor={"#FFF"}
//                         key={day}
//                       >
//                         {day}
//                       </Th>
//                     ))}
//                   </Tr>
//                 </Thead>
//                 <Tbody padding={0}>{generateCalendar()}</Tbody>
//               </Table>
//             </MenuList>
//           </Menu>
//         </Box>
//         {error && (
//           <Text color="#E53E3E" fontSize="sm">
//             {error}
//           </Text> // Display error message
//         )}
//       </Box>
//     </>
//   );
// }
// export default DatePickerButton;

import React, { useState, useEffect, useRef } from "react";
import {
  Box,
  Select,
  Text,
  Input,
  useDisclosure,
  useMediaQuery,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverBody,
  PopoverArrow,
  HStack,
  Icon,
} from "@chakra-ui/react";
import { DatePickerIcon } from "../CustomIcons";

function DatePickerButton({
  Heading,
  placeholder,
  value,
  onChange,
  maxYear,
  startingYear,
  error,
}) {
  const { isOpen, onOpen, onClose } = useDisclosure({
    onClose: () => handleSelectDate(),
  });

  const [displayDate, setDisplayDate] = useState(value || "");

  const [selectedMonth, setSelectedMonth] = useState("");
  const [selectedYear, setSelectedYear] = useState("");
  const [selectedDay, setSelectedDay] = useState("");
  const [selectedDate, setSelectedDate] = useState(value || "");

  const monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  const generateYearOptions = () => {
    const currentYear = new Date().getFullYear();
    const startYear = currentYear - (startingYear ? startingYear : 100);
    const endYear = maxYear || currentYear + 50;
    return Array.from(
      { length: endYear - startYear + 1 },
      (_, index) => startYear + index
    );
  };

  const [isLargerThan768] = useMediaQuery("(min-width: 500px)");
  const isMobile = !isLargerThan768;
  const boxWidth = isMobile ? "100%" : "45%";
  const prevDateRef = useRef(value);

  const handleSelectDate = () => {
    if (selectedYear !== "" && selectedMonth !== "" && selectedDay !== "") {
      const selected = new Date(selectedYear, selectedMonth, selectedDay);
      const formattedDate = selected.toISOString().split("T")[0]; // Format date to YYYY-MM-DD
      // setSelectedDate(formattedDate);
      // setDisplayDate(formattedDate);
      if (formattedDate !== prevDateRef.current) {
        setDisplayDate(formattedDate);
        prevDateRef.current = formattedDate;
        if (onChange) {
          onChange({ target: { value: formattedDate } });
        }
      }
    }
  };

  const deformatDate = (dateString) => {
    if (dateString) {
      const [year, month, day] = dateString.split("-");
      setSelectedYear(parseInt(year));
      setSelectedMonth(parseInt(month) - 1); // Month is zero-based
      setSelectedDay(parseInt(day));
    }
  };

  // Effect to initialize the selected values based on the passed value prop
  useEffect(() => {
    if (value) {
      deformatDate(value);
      // setDisplayDate(value);
    }
  }, [value]);

  const handleInputChange = (e) => {
    setDisplayDate(e.target.value);
    if (onChange) {
      onChange({ target: { value: e.target.value } });
    }
  };

  return (
    <Box
      display={"inline-flex"}
      flexDirection={"column"}
      alignItems={"flex-start"}
      w={boxWidth}
      gap={"5px"}
    >
      <Box
        display={"flex"}
        width={"240px"}
        height={"15px"}
        flexDirection={"column"}
        justifyContent={"center"}
        flexShrink={0}
      >
        <Text
          color={"#00466D"}
          fontFamily={"Nunito"}
          fontSize={"16px"}
          fontStyle={"normal"}
          fontWeight={"600"}
          lineHeight={"normal"}
        >
          {Heading}
        </Text>
      </Box>
      <Popover
        placement="bottom"
        cursor={"pointer"}
        isOpen={isOpen}
        onClose={onClose}
        onOpen={onOpen}
      >
        <PopoverTrigger cursor="pointer">
          <Box
            display="flex"
            height={"40px"}
            alignItems={"center"}
            textAlign={"start"}
            justifyContent={"space-between"}
            paddingRight={"10px"}
            alignSelf={"stretch"}
            borderRadius={"5px"}
            bg={error ? "#FFD2D2" : "#EDF2F7"}
            border={error ? "1px solid #E53E3E" : "none"}
            cursor={"pointer"}
            onClick={onOpen}
          >
            <Input
              placeholder={value ? value : placeholder}
              size="sm"
              value={displayDate}
              onChange={handleSelectDate}
              readOnly
              cursor={"pointer"}
            />
            <Icon
              as={DatePickerIcon}
              svgwidth={"20px"}
              svgheight={"20px"}
              marginRight={"10px"}
            />
            {/* <DatePickerIcon svgheight={"20px"} svgwidth={"20px"} /> */}
          </Box>
        </PopoverTrigger>

        <PopoverContent width={"fit-content"}>
          <PopoverArrow />
          <PopoverBody
            padding={"10px 10px"}
            width={"fit-content"}
            cursor={"pointer"}
            borderRadius={"5px"}
            border={"1px solid #00A2D4"}
          >
            <HStack width={"fit-content"} padding={0} cursor={"pointer"}>
              <Select
                placeholder="Day"
                value={selectedDay}
                onChange={(e) => setSelectedDay(e.target.value)}
                cursor={"pointer"}
                borderRadius={"5px"}
                border={"1px solid #00A2D4"}
                sx={{
                  // Apply styles to the select element
                  "&.chakra-select": {
                    width: "auto",
                    paddingInlineStart: "5px",
                    height: "20px",
                  },
                  // Apply styles to the option elements
                  "&.chakra-select__option": {
                    backgroundColor: "#00A2D4",
                    color: "white",
                  },
                }}
              >
                {[...Array(31).keys()].map((i) => (
                  <option key={i + 1} value={String(i + 1).padStart(2, "0")}>
                    {i + 1}
                  </option>
                ))}
              </Select>

              <Select
                placeholder="Month"
                value={selectedMonth}
                onChange={(e) => setSelectedMonth(e.target.value)}
                borderRadius={"5px"}
                border={"1px solid #00A2D4"}
                cursor={"pointer"}
                sx={{
                  // Apply styles to the select element
                  "&.chakra-select": {
                    width: "fit-content",
                    paddingInlineStart: "5px",
                    height: "20px",
                    paddingEnd: "15px",
                  },
                  // Apply styles to the option elements
                  "&.option": {
                    backgroundColor: "white",
                    maxHeight: isMobile && "50px",
                    color: "black",
                    fontSize: "14px",
                    padding: "4px",
                  },
                }}
              >
                {monthNames.map((month, index) => (
                  <option
                    className="option"
                    style={{ maxH: isMobile && "30vh" }}
                    key={month}
                    value={index}
                  >
                    {month}
                  </option>
                ))}
              </Select>
              <Select
                placeholder="Year"
                value={selectedYear}
                onChange={(e) => setSelectedYear(e.target.value)}
                borderRadius={"5px"}
                border={"1px solid #00A2D4"}
                maxHeight={isMobile && "50px"}
                cursor={"pointer"}
                sx={{
                  // Apply styles to the select element
                  "&.chakra-select": {
                    width: "auto",
                    paddingInlineStart: "5px",
                    height: "20px",
                  },
                  // Apply styles to the option elements
                  "&.chakra-select__option": {
                    backgroundColor: "white",
                    color: "black",
                  },
                }}
              >
                {generateYearOptions().map((year) => (
                  <option key={year} value={year}>
                    {year}
                  </option>
                ))}
              </Select>
            </HStack>
          </PopoverBody>
        </PopoverContent>
      </Popover>
      {error && <Text color="red.500">{error}</Text>}
    </Box>
  );
}

export default DatePickerButton;
