import { useAuth0 } from "@auth0/auth0-react";
import { Flex, HStack, Heading, VStack, useMediaQuery } from "@chakra-ui/react";
import axios from "axios";
import React, {
  forwardRef,
  useContext,
  useEffect,
  useImperativeHandle,
  useState,
} from "react";
import { useStore } from "../../store";
import GlobalBodyPage from "../GlobalContainer/BodyPage";
import DropDownButton from "../InputFields/dropDownButton";
import NormalInputButton from "../InputFields/normalInputButton";
import SalaryGraph from "../SalaryGraph";
import SalaryGrowthTable from "../SalaryGrowthTable";
import { SpouseContext } from "../SpouseContext";
import TooltipContent from "../TooltipContent";
import { StripeContext } from "../StripeContext";

const Salary = forwardRef((props, ref) => {
  const { getAccessTokenSilently } = useAuth0();
  const { setChangedData } = props;

  useImperativeHandle(ref, () => ({
    submitHandler,
    checkMandatoryFields,
  }));

  const axiosInstance = axios.create();
  async function getToken() {
    // Replace this with your Auth0 token retrieval logic
    const token = await getAccessTokenSilently({
      audience:
        "https://os2lsiwff43wfpb5ggyawt6qri0mgcro.lambda-url.us-east-2.on.aws/",
      scope: "write:optimization",
    });
    return token;
  }

  // Add a request interceptor
  axiosInstance.interceptors.request.use(
    async (config) => {
      const token = await getToken();
      config.headers.Authorization = `Bearer ${token}`;
      return config;
    },
    (error) => {
      // Do something with request error
      return Promise.reject(error);
    }
  );

  const [errors, setErrors] = useState({});

  const checkMandatoryFields = () => {
    let newErrors = {};
    const currentDate = new Date();
    if (!employmentStatus)
      newErrors.employmentStatus = "Employment status is required";
    if (employmentStatus === "Yes") {
      if (!userSalary) newErrors.userSalary = "Salary is required";
      if (!retireAge || retireAge < getAge(currentAge, currentDate))
        newErrors.retireAge = "Retirement Age is required";
      if (salaryGrowth > 20) {
        newErrors.salaryGrowth = "Growth rate cannot be greater than 20%";
      }
      if (!employmentType)
        newErrors.employmentType = "Employment Type is required";
      if (employmentType === "Self employed") {
        if (!eiParticipate)
          newErrors.eiParticipate = "EI participation info is required";
      }
    }

    if (spouseStatus) {
      if (!spouseEmploymentStatus)
        newErrors.spouseEmploymentStatus = "Employment status is required";
      if (spouseEmploymentStatus === "Yes") {
        if (!spouseIncome) newErrors.spouseIncome = "Salary is required";
        if (
          !spouseRetireAge ||
          spouseRetireAge < getAge(spouseAge, currentDate)
        )
          newErrors.spouseRetireAge = "Retirement Age is required";
        if (spouseSalaryGrowth > 20) {
          newErrors.spouseSalaryGrowth =
            "Growth rate cannot be greater than 20%";
        }
        if (!spouseEmploymentType)
          newErrors.spouseEmploymentType = "Employment Type is required";
        if (spouseEmploymentType === "Self employed") {
          if (!spouseEiParticipate)
            newErrors.spouseEiParticipate = "EI participation info is required";
        }
      }
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0; // Return true if no errors
  };

  const [loading, setLoading] = useState(false);
  const [userSalary, setUserSalary] = useState("");
  const [spouseIncome, setSpouseIncome] = useState();
  const [retireAge, setRetireAge] = useState();
  const [spouseRetireAge, setSpouseRetireAge] = useState();
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [currentAge, setCurrentAge] = useState();
  const [spouseFirstName, setSpouseFirstName] = useState();
  const [spouseLastName, setSpouseLastName] = useState();
  const [spouseAge, setSpouseAge] = useState();
  const [runAge, setRunAge] = useState();

  const [deduction, setDeductions] = useState("");
  const [eiParticipate, setEiParticipate] = useState("");
  const [spouseDeduction, setSpouseDeduction] = useState("");
  const [spouseEiParticipate, setSpouseEiParticipate] = useState("");

  const { spouseStatus, setSpouseStatus } = useContext(SpouseContext);
  const { stripePlan, setStripePlan } = useContext(StripeContext);
  const [employmentStatus, setEmploymentStatus] = useState();
  const [employmentType, setEmploymentType] = useState();
  const [salaryGrowth, setSalaryGrowth] = useState();
  const [spouseEmploymentStatus, setSpouseEmploymentStatus] = useState("");
  const [spouseEmploymentType, setSpouseEmploymentType] = useState();
  const [spouseSalaryGrowth, setSpouseSalaryGrowth] = useState();
  const [growthOverYears, setGrowthOverYears] = useState([]);
  const [spouseGrowthOverYears, setSpouseGrowthOverYears] = useState([]);

  const [showStripeModal, setShowStripeModal] = useState(false);

  const { user } = useAuth0();
  const userInfo = useStore((store) => store.userInfo);
  const userIncomeInfo = useStore((store) => store.userIncomeInfo);
  const setUserIncomeInfo = useStore((store) => store.setUserIncomeInfo);
  const userExpensesInfo = useStore((store) => store.userExpensesInfo);

  const [isEditMode, setIsEditMode] = useState(true);
  const userOptiData = useStore((store) => store.userOptiData);

  const [newUser, setNewUser] = useState();

  useEffect(() => {
    if (userOptiData?.primaryUserData?.expenses?.length > 1) {
      setNewUser(false);
    } else if (userOptiData?.primaryUserData?.expenses?.length === undefined) {
      setNewUser(true);
      setIsEditMode(true);
    }
  }, [userOptiData]);

  useEffect(() => {
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 100);
  }, [errors]);

  // Function to toggle edit mode
  const toggleEditMode = () => {
    setIsEditMode(!isEditMode);
  };


  const currencyFormat = (money) => {
    if (money == "") {
      return "$0";
    } else if (money == 0) {
      return "$0";
    } else {
      const intTotal = Math.round(Math.round(money * 100) / 100);
      const currencyCad = new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "USD",
        minimumFractionDigits: 0,
      }).format(intTotal);
      return currencyCad;
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      if (userIncomeInfo !== null) {
        setUserSalary(userIncomeInfo?.Salary);
        setGrowthOverYears(userIncomeInfo?.Salary_Array);
        setSalaryGrowth(userIncomeInfo?.Salary_Growth ?? 0);
        setRetireAge(userIncomeInfo?.salaryStopAge);
        setEmploymentStatus(userIncomeInfo?.Currently_Employed);
        setEmploymentType(userIncomeInfo?.Employment_Type);
        setDeductions(userIncomeInfo?.Deductions ?? 0);
        setEiParticipate(userIncomeInfo?.Do_You_EI);

        setSpouseIncome(userIncomeInfo?.spouseIncome);
        setSpouseGrowthOverYears(userIncomeInfo?.spouseSalary_Array);
        setSpouseRetireAge(userIncomeInfo?.spouseSalaryStopAge);
        setSpouseEmploymentStatus(userIncomeInfo?.spouseCurrently_Employed);
        setSpouseEmploymentType(userIncomeInfo?.spouseEmployment_Type);
        setSpouseSalaryGrowth(userIncomeInfo?.spouseSalary_Growth ?? 0);
        setSpouseDeduction(userIncomeInfo?.spouseDeductions ?? 0);
        setSpouseEiParticipate(userIncomeInfo?.spouseDo_You_EI);

        setLoading(true);
      } if (!userIncomeInfo) {
        setUserSalary("");
        setSpouseIncome("");
        setSpouseRetireAge();
        setSalaryGrowth(userExpensesInfo?.expenseCPI ?? 2);
        setSpouseEmploymentStatus("");
        setSpouseEmploymentType("");
        setSpouseSalaryGrowth(userExpensesInfo?.expenseCPI ?? 2);
        setLoading(true);
      }
      if (userInfo !== null) {
        setFirstName(userInfo?.FirstName);
        setLastName(userInfo?.LastName);
        setCurrentAge(userInfo?.DateOfBirth);
        setSpouseFirstName(userInfo?.spouseFirstName);
        setSpouseLastName(userInfo?.spouseLastName);
        setSpouseAge(userInfo?.spouseDateOfBirth);
        setRunAge(userInfo?.runAge);
      }
    };
    fetchData().catch(console.error);
  }, []);
  useEffect(() => {
    setTimeout(() => {
      if (userIncomeInfo !== null && userInfo !== null) {
        if (
          userIncomeInfo?.Salary !== userSalary ||
          userIncomeInfo?.Salary_Growth !== salaryGrowth ||
          userIncomeInfo?.salaryStopAge !== retireAge ||
          userInfo?.DateOfBirth !== currentAge ||
          userIncomeInfo?.Salary_Array?.length !==
            calculateAnnualGrowthArray(
              userSalary,
              salaryGrowth,
              getSalaryYears("primary")?.length,
              "primary"
            )?.length
        ) {
          if (
            typeof userSalary !== "undefined" &&
            typeof salaryGrowth !== "undefined" &&
            typeof retireAge !== "undefined"
          ) {
            calculateAnnualGrowthArray(
              userSalary,
              salaryGrowth,
              getSalaryYears("primary")?.length,
              "primary"
            );
          }
        } else if (
          userIncomeInfo?.Salary == userSalary ||
          userIncomeInfo?.Salary_Growth == salaryGrowth ||
          userIncomeInfo?.salaryStopAge == retireAge
        ) {
          setGrowthOverYears(userIncomeInfo?.Salary_Array);
        }
      } else {
        calculateAnnualGrowthArray(
          userSalary,
          salaryGrowth,
          getSalaryYears("primary")?.length,
          "primary"
        );
      }
    }, 10);
  }, [userSalary, salaryGrowth, retireAge]);

  useEffect(() => {
    setTimeout(() => {
      if (userIncomeInfo !== null && userInfo !== null) {
        if (
          userIncomeInfo?.spouseIncome !== spouseIncome ||
          userIncomeInfo?.spouseSalary_Growth !== spouseSalaryGrowth ||
          userIncomeInfo?.spouseSalaryStopAge !== spouseRetireAge ||
          userInfo?.DateOfBirth !== currentAge ||
          userIncomeInfo?.spouseSalary_Array !==
            calculateAnnualGrowthArray(
              spouseIncome,
              spouseSalaryGrowth,
              getSalaryYears("spouse")?.length,
              "spouse"
            )
        ) {
          if (
            typeof spouseIncome !== "undefined" &&
            typeof spouseSalaryGrowth !== "undefined" &&
            typeof spouseRetireAge !== "undefined"
          ) {
            calculateAnnualGrowthArray(
              spouseIncome,
              spouseSalaryGrowth,
              getSalaryYears("spouse")?.length,
              "spouse"
            );
          }
        } else if (
          userIncomeInfo?.spouseIncome == spouseIncome ||
          userIncomeInfo?.spouseSalary_Growth == spouseSalaryGrowth ||
          userIncomeInfo?.spouseSalaryStopAge == spouseRetireAge
        ) {
          setSpouseGrowthOverYears(userIncomeInfo?.spouseSalary_Array);
        }
      } else {
        calculateAnnualGrowthArray(
          spouseIncome,
          spouseSalaryGrowth,
          getSalaryYears("spouse")?.length,
          "spouse"
        );
      }
    }, 10);
  }, [spouseIncome, spouseSalaryGrowth, spouseRetireAge]);

  function getAge(birthday, currentDate) {
    // Get the current date
    // const currentDate = new Date();

    // Convert the birthday string into a Date object
    const birthDate = new Date(birthday);

    // Calculate the age in years
    let age = currentDate.getFullYear() - birthDate.getFullYear();

    // Decrement the age if the current month and day are before the birth month and day
    if (
      currentDate.getMonth() < birthDate.getMonth() ||
      (currentDate.getMonth() === birthDate.getMonth() &&
        currentDate.getDate() <= birthDate.getDate())
    ) {
      age -= 1;
    }

    return age;
  }

  const moneyToNumber = (value) => {
    let stringValue = String(value); // Convert to string
    let numValue = stringValue.replace(/\D/g, "");
    numValue = Number(numValue);

    return numValue;
  };

  const getSalaryYears = (user) => {
    const currentDate = new Date();
    const currentYear = currentDate.getFullYear();
    const userAge =
      user === "primary"
        ? getAge(currentAge, currentDate)
        : getAge(spouseAge, currentDate);
    const tmpRetireAge = user === "primary" ? retireAge : spouseRetireAge;
    const fullRun = tmpRetireAge - userAge + 1;
    if (fullRun > 0 && Number.isInteger(fullRun)) {
      return [...Array(fullRun).keys()].map((i) => i + currentYear);
    } else {
      // Return an empty array or handle the error appropriately
      return [];
    }
  };

  const submitHandler = (e) => {
    if (e) {
      e.preventDefault();
    }
    setIsEditMode(!isEditMode);
    let finalSalary = userSalary;
    let Deduction = deduction;
    let spouseFinalSalary = spouseIncome;
    let SpouseDeduction = spouseDeduction;
    // let SalFullArray = growthOverYears
    // let SpouseSalFullArray = spouseGrowthOverYears
    let SalArray = JSON.stringify(growthOverYears);
    let SpouseSalArr = JSON.stringify(spouseGrowthOverYears);
    if (employmentStatus == "No") {
      finalSalary = 0;
      setUserSalary(0);
      Deduction = 0;
      setDeductions(0);
      SalArray = [0];
      setGrowthOverYears("");
      // SalFullArray = []
    }
    if (employmentType == "Employee") {
      Deduction = 0;
      setDeductions(0);
    }
    if (spouseEmploymentStatus == "No") {
      spouseFinalSalary = 0;
      setSpouseIncome(0);
      SpouseDeduction = 0;
      setSpouseDeduction(0);
      SpouseSalArr = [0];
      setSpouseGrowthOverYears("");
      // SpouseSalFullArray = []
    }
    if (spouseEmploymentType == "Employee") {
      SpouseDeduction = 0;
      setSpouseDeduction(0);
    }
    // console.log('Income: ', user.sub, finalSalary, retireAge, spouseFinalSalary, spouseRetireAge, employmentStatus, employmentType, salaryGrowth, deduction, eiParticipate, SpouseDeduction, spouseEiParticipate, spouseEmploymentStatus, spouseEmploymentType, spouseSalaryGrowth, SalArray, SpouseSalArr);

    const incomeURL = (action) =>
      `${
        process.env.REACT_APP_NODE_API_GATEWAY
      }/CompassLambdaApi/INCOME-PAGE/${action}?IncomeID=${user.sub.replace(
        "|",
        "%7c"
      )}&Salary=${finalSalary}&salaryStopAge=${retireAge}&spouseIncome=${spouseFinalSalary}&spouseSalaryStopAge=${spouseRetireAge}&currentlyEmployed=${employmentStatus}&employmentType=${employmentType}&salaryGrowth=${salaryGrowth}&Deductions=${Deduction}&Do_You_EI=${eiParticipate}&spouseDeductions=${SpouseDeduction}&spouseDo_You_EI=${spouseEiParticipate}&spouseCurrently_Employed=${spouseEmploymentStatus}&spouseEmployment_Type=${spouseEmploymentType}&spouseSalary_Growth=${spouseSalaryGrowth}&Salary_Array=${SalArray}&spouseSalary_Array=${SpouseSalArr}`;
    axiosInstance
      .get(incomeURL("add"))
      .then((res) => {
        setUserIncomeInfo({
          ...userIncomeInfo,
          Salary: finalSalary,
          Salary_Array: SalArray,
          salaryStopAge: retireAge,
          spouseIncome: spouseFinalSalary,
          spouseSalary_Array: SpouseSalArr,
          spouseSalaryStopAge: spouseRetireAge,
          Currently_Employed: employmentStatus,
          Employment_Type: employmentType,
          Salary_Growth: salaryGrowth,
          Deductions: Deduction,
          Do_You_EI: eiParticipate,
          spouseCurrently_Employed: spouseEmploymentStatus,
          spouseEmployment_Type: spouseEmploymentType,
          spouseSalary_Growth: spouseSalaryGrowth,
          spouseDeductions: SpouseDeduction,
          spouseDo_You_EI: spouseEiParticipate,
        });
        console.log(res);
        if (res.data.code === "ER_DUP_ENTRY") {
          axiosInstance.get(incomeURL("update")).then((res) => {
            console.log(res);
            setUserIncomeInfo({
              ...userIncomeInfo,
              Salary: finalSalary,
              Salary_Array: SalArray,
              salaryStopAge: retireAge,
              spouseIncome: spouseFinalSalary,
              spouseSalary_Array: SpouseSalArr,
              spouseSalaryStopAge: spouseRetireAge,
              Currently_Employed: employmentStatus,
              Employment_Type: employmentType,
              Salary_Growth: salaryGrowth,
              Deductions: Deduction,
              Do_You_EI: eiParticipate,
              spouseCurrently_Employed: spouseEmploymentStatus,
              spouseEmployment_Type: spouseEmploymentType,
              spouseSalary_Growth: spouseSalaryGrowth,
              spouseDeductions: SpouseDeduction,
              spouseDo_You_EI: spouseEiParticipate,
            });
          });
        }
      })
      .catch((error) => {
        // console.log(error);
      });
  };

  const yearToDate = (year) => {
    const date = new Date();
    date.setFullYear(year);
    return date;
  };

  function calculateAnnualGrowthArray(initialValue, percentage, years, user) {
    if (typeof initialValue === "string") {
      initialValue = moneyToNumber(initialValue);
    }
    const growthArray = [initialValue];

    for (let i = 1; i <= years - 1; i++) {
      const currentValue = growthArray[i - 1] * (1 + percentage / 100);
      growthArray.push(parseInt(currentValue.toFixed(2)));
    }
    if (user === "primary") {
      setGrowthOverYears(growthArray);
    } else if (user === "spouse") {
      setSpouseGrowthOverYears(growthArray);
    }
    // console.log("GA", growthArray)
    return growthArray;
  }

  function onUpdateGrowthOverYears(user, val) {
    setChangedData(true);
    if (user === "primary") {
      setGrowthOverYears(val);
    } else setSpouseGrowthOverYears(val);
  }

  const employmentStatusList = [
    { key: "Yes", value: "Yes" },
    { key: "No", value: "No" },
  ];

  const employmentTypeList = [
    { key: "Employee", value: "Employee" },
    { key: "Self employed", value: "Self-employed" },
  ];

  const Select = (userAge) => {
    const currentDate = new Date();
    let age = getAge(userAge, currentDate); // Assuming getAge returns the starting age.
    const runAgeList = [];
    for (let i = age; i <= 100; i++) {
      runAgeList.push({ key: i, value: i.toString() }); // Convert i to string if necessary
    }
    return runAgeList;
  };

  const [isLargerThan768] = useMediaQuery("(min-width: 768px)");
  const isMobile = !isLargerThan768;

  return (
    <Flex
      display="flex"
      flexWrap="wrap" // Enable wrapping
      justifyContent="flex-start"
      alignItems="start"
      w="100%"
      flexDirection={isMobile ? "column" : "row"}
    >
      {isMobile && (
        <VStack w={"100%"} gap={"20px"} mt={-1.5}>
          <GlobalBodyPage
            Heading={"Employment Income"}
            mainframeContent={
              <VStack
                spacing={4}
                alignItems={"flex-start"}
                width={"100%"}
                flexDirection={"column"}
              >
                <Flex justify="space-between" w="100%">
                  <Heading
                    height={"27px"}
                    alignSelf={"stretch"}
                    color={"#00466D"}
                    fontFamily={"Nunito"}
                    fontSize={"20px"}
                    fontStyle={"normal"}
                    fontWeight={"700"}
                    lineHeight={"normal"}
                  >
                    {firstName} {lastName}
                  </Heading>
                </Flex>
                <Flex justify="space-between" w="full">
                  <Heading
                    height={"22px"}
                    alignSelf={"stretch"}
                    color={"#007EB5"}
                    fontFamily={"Nunito"}
                    fontSize={"16px"}
                    fontStyle={"normal"}
                    fontWeight={"600"}
                    lineHeight={"normal"}
                  >
                    Current Employment Status
                  </Heading>
                </Flex>
                <Flex justify="space-between" w="full">
                  <DropDownButton
                    Heading={"Are you currently employed"}
                    placeholder={"Select"}
                    dropDownItems={employmentStatusList}
                    value={employmentStatus}
                    onChange={(key) => {
                      setEmploymentStatus(key);
                      setChangedData(true);
                    }}
                    customWidth={"100%"}
                    MenuCustomWidth={"100%"}
                    error={errors.employmentStatus}
                    tooltipContent={TooltipContent.employmentIncomeYesNo}
                  />
                </Flex>
                {employmentStatus === "Yes" && (
                  <Flex justify="space-between" w="full">
                    <DropDownButton
                      Heading={"Employment Type"}
                      placeholder={"Select"}
                      dropDownItems={employmentTypeList}
                      value={employmentType}
                      onChange={(key) => {
                        setEmploymentType(key);
                        setChangedData(true);
                      }}
                      customWidth={"100%"}
                      MenuCustomWidth={"100%"}
                      error={errors.employmentType}
                      tooltipContent={TooltipContent.employmentType}
                    />
                  </Flex>
                )}
                
                {employmentStatus === "Yes" && (
                  <Flex justify="space-between" w="full">
                    <Heading
                      alignSelf={"stretch"}
                      color={"#007EB5"}
                      fontFamily={"Nunito"}
                      fontSize={"16px"}
                      fontStyle={"normal"}
                      fontWeight={"600"}
                      lineHeight={"normal"}
                    >
                      Income and Retirement Planning
                    </Heading>
                  </Flex>
                )}

                {employmentStatus === "Yes" && (
                  <Flex justify="space-between" w="full">
                    <NormalInputButton
                      Heading={"Annual Income"}
                      placeholder={"$"}
                      value={userSalary}
                      onChange={(e) => {
                        setUserSalary(moneyToNumber(e.target.value));
                        setChangedData(true);
                      }}
                      customWidth={"100%"}
                      useThousandSeparator={true}
                      error={errors.userSalary}
                      InputWidth={"100%"}
                      tooltipContent={TooltipContent.employmentIncomeAmount}
                    />
                  </Flex>
                )}
                {employmentStatus === "Yes" && (
                  <Flex justify="space-between" w="full">
                    <NormalInputButton
                      Heading={"Salary growth rate"}
                      placeholder={"%"}
                      value={salaryGrowth}
                      onChange={(e) => {
                        const noSymbol = e.target.value.replace("%", "");
                        const growthNum = parseFloat(noSymbol);
                        setSalaryGrowth(
                          !isNaN(growthNum)
                            ? parseFloat(growthNum.toFixed(2))
                            : ""
                        );
                        setChangedData(true);
                      }}
                      error={errors.salaryGrowth}
                      customWidth={"100%"}
                      usePercentage={true}
                      InputWidth={"100%"}
                      tooltipContent={TooltipContent.employmentIncomeGrowth}
                    />
                  </Flex>
                )}
                {employmentStatus === "Yes" &&
                  employmentType === "Self employed" && (
                    <Flex justify="space-between" w="full">
                      <NormalInputButton
                        Heading={"Annual Deductions"}
                        placeholder={"$"}
                        value={deduction}
                        onChange={(e) => {
                          setDeductions(moneyToNumber(e.target.value));
                          setChangedData(true);
                        }}
                        customWidth={"100%"}
                        InputWidth={"100%"}
                        useThousandSeparator={true}
                        tooltipContent={TooltipContent.employmentIncomeDeductions}
                      />
                    </Flex>
                  )}
                {employmentStatus === "Yes" &&
                  employmentType === "Self employed" && (
                    <Flex justify="space-between" w="full">
                      <DropDownButton
                        Heading={"Do you participate in EI?"}
                        placeholder={"Select"}
                        dropDownItems={employmentStatusList}
                        value={eiParticipate}
                        onChange={(key) => {
                          setEiParticipate(key);
                          setChangedData(true);
                        }}
                        customWidth={"100%"}
                        MenuCustomWidth={"100%"}
                        error={errors.eiParticipate}
                        tooltipContent={TooltipContent.employmentIncomeInsuranceYesNo}
                      />
                    </Flex>
                  )}
                {employmentStatus === "Yes" && (
                  <Flex justify="space-between" w="full">
                    <DropDownButton
                      Heading={"Planned age of retirement"}
                      placeholder={"Select"}
                      dropDownItems={Select(currentAge)}
                      value={retireAge}
                      onChange={(key) => {
                        setRetireAge(key);
                        setChangedData(true);
                      }}
                      customWidth={"100%"}
                      MenuCustomWidth={"100%"}
                      error={errors.retireAge}
                      tooltipContent={TooltipContent.employmentRetirementAge}
                    />
                  </Flex>
                )}

                {employmentStatus === "Yes" &&
                  loading &&
                  userSalary > 0 &&
                  retireAge && (
                    <Flex alignSelf={"stretch"}>
                      <SalaryGrowthTable
                        salary={moneyToNumber(userSalary)}
                        getAge={getAge}
                        currentAge={currentAge}
                        retireAge={retireAge}
                        yearToDate={yearToDate}
                        currencyFormat={currencyFormat}
                        moneyToNumber={moneyToNumber}
                        growthOverYears={growthOverYears}
                        setGrowthOverYears={onUpdateGrowthOverYears}
                        isEditMode={isEditMode}
                        height={
                          employmentType === "Self employed" ? "580px" : "430px"
                        }
                        user="primary"
                      />
                    </Flex>
                  )}

                {employmentStatus === "Yes" &&
                  loading &&
                  userSalary > 0 &&
                  retireAge && (
                    <Flex
                      display={"flex"}
                      justifyContent={"center"}
                      alignItems={"center"}
                      gap={"10px"}
                      alignSelf={"stretch"}
                      borderRadius={"10px"}
                    >
                      <SalaryGraph
                        getAge={getAge}
                        currentAge={currentAge}
                        retireAge={retireAge}
                        growthOverYears={growthOverYears}
                      />
                    </Flex>
                  )}
              </VStack>
            }
            videoName={"Income Page"}
            video={"Income_page.mp4"}
          />
          {spouseStatus && (
            <GlobalBodyPage
              mainframeContent={
                <VStack
                  spacing={4}
                  alignItems={"flex-start"}
                  width={"100%"}
                  flexDirection={"column"}
                >
                  <Flex justify="space-between" w="100%">
                    <Heading
                      height={"27px"}
                      alignSelf={"stretch"}
                      color={"#00466D"}
                      fontFamily={"Nunito"}
                      fontSize={"20px"}
                      fontStyle={"normal"}
                      fontWeight={"700"}
                      lineHeight={"normal"}
                    >
                      {spouseFirstName} {spouseLastName}
                    </Heading>
                  </Flex>
                  <Flex justify="space-between" w="full">
                    <Heading
                      height={"22px"}
                      alignSelf={"stretch"}
                      color={"#007EB5"}
                      fontFamily={"Nunito"}
                      fontSize={"16px"}
                      fontStyle={"normal"}
                      fontWeight={"600"}
                      lineHeight={"normal"}
                    >
                      Current Employment Status
                    </Heading>
                  </Flex>
                  <Flex justify="space-between" w="full">
                    <DropDownButton
                      Heading={"Are you currently employed"}
                      placeholder={"Select"}
                      dropDownItems={employmentStatusList}
                      value={spouseEmploymentStatus}
                      onChange={(key) => {
                        setSpouseEmploymentStatus(key);
                        setChangedData(true);
                      }}
                      customWidth={"100%"}
                      MenuCustomWidth={"100%"}
                      error={errors.spouseEmploymentStatus}
                      tooltipContent={TooltipContent.employmentIncomeYesNo}
                    />
                  </Flex>
                  {spouseEmploymentStatus === "Yes" && (
                    <Flex justify="space-between" w="full">
                      <DropDownButton
                        Heading={"Employment Type"}
                        placeholder={"Select"}
                        dropDownItems={employmentTypeList}
                        value={spouseEmploymentType}
                        onChange={(key) => {
                          setSpouseEmploymentType(key);
                          setChangedData(true);
                        }}
                        customWidth={"100%"}
                        MenuCustomWidth={"100%"}
                        error={errors.spouseEmploymentType}
                        tooltipContent={TooltipContent.employmentType}
                      />
                    </Flex>
                  )}
                 
                  {spouseEmploymentStatus === "Yes" && (
                    <Flex justify="space-between" w="full">
                      <Heading
                        alignSelf={"stretch"}
                        color={"#007EB5"}
                        fontFamily={"Nunito"}
                        fontSize={"16px"}
                        fontStyle={"normal"}
                        fontWeight={"600"}
                        lineHeight={"normal"}
                      >
                        Income and Retirement Planning
                      </Heading>
                    </Flex>
                  )}

                  {spouseEmploymentStatus === "Yes" && (
                    <Flex justify="space-between" w="full">
                      <NormalInputButton
                        Heading={"Annual Income"}
                        placeholder={"$"}
                        value={spouseIncome}
                        onChange={(e) => {
                          setSpouseIncome(moneyToNumber(e.target.value));
                          setChangedData(true);
                        }}
                        customWidth={"100%"}
                        useThousandSeparator={true}
                        error={errors.spouseIncome}
                        InputWidth={"100%"}
                        tooltipContent={TooltipContent.employmentIncomeAmount}
                      />
                    </Flex>
                  )}
                  {spouseEmploymentStatus === "Yes" && (
                    <Flex justify="space-between" w="full">
                      <NormalInputButton
                        Heading={"Salary growth rate"}
                        placeholder={"2%"}
                        value={spouseSalaryGrowth}
                        onChange={(e) => {
                          const noSymbol = e.target.value.replace("%", "");
                          const growthNum = parseFloat(noSymbol);
                          setSpouseSalaryGrowth(
                            !isNaN(growthNum)
                              ? parseFloat(growthNum.toFixed(2))
                              : ""
                          );
                          setChangedData(true);
                        }}
                        error={errors.spouseSalaryGrowth}
                        customWidth={"100%"}
                        usePercentage={true}
                        InputWidth={"100%"}
                        tooltipContent={TooltipContent.employmentIncomeGrowth}
                      />
                    </Flex>
                  )}
                   {spouseEmploymentStatus === "Yes" &&
                    spouseEmploymentType === "Self employed" && (
                      <Flex justify="space-between" w="full">
                        <NormalInputButton
                          Heading={"Annual Deductions"}
                          placeholder={"$"}
                          value={spouseDeduction}
                          onChange={(e) => {
                            setSpouseDeduction(moneyToNumber(e.target.value));
                            setChangedData(true);
                          }}
                          customWidth={"100%"}
                          InputWidth={"100%"}
                          useThousandSeparator={true}
                          tooltipContent={TooltipContent.employmentIncomeDeductions}
                        />
                      </Flex>
                    )}
                  {spouseEmploymentStatus === "Yes" &&
                    spouseEmploymentType === "Self employed" && (
                      <Flex justify="space-between" w="full">
                        <DropDownButton
                          Heading={"Do you participate in EI?"}
                          placeholder={"Select"}
                          dropDownItems={employmentStatusList}
                          value={spouseEiParticipate}
                          onChange={(key) => {
                            setSpouseEiParticipate(key);
                            setChangedData(true);
                          }}
                          customWidth={"100%"}
                          MenuCustomWidth={"100%"}
                          error={errors.spouseEiParticipate}
                          tooltipContent={TooltipContent.employmentIncomeInsuranceYesNo}
                        />
                      </Flex>
                    )}
                  {spouseEmploymentStatus === "Yes" && (
                    <Flex justify="space-between" w="full">
                      <DropDownButton
                        Heading={"Planned age of retirement"}
                        placeholder={"Select"}
                        dropDownItems={Select(spouseAge)}
                        value={spouseRetireAge}
                        onChange={(key) => {
                          setSpouseRetireAge(key);
                          setChangedData(true);
                        }}
                        customWidth={"100%"}
                        MenuCustomWidth={"100%"}
                        error={errors.spouseRetireAge}
                        tooltipContent={TooltipContent.employmentRetirementAge}
                      />
                    </Flex>
                  )}

                  {spouseEmploymentStatus === "Yes" &&
                    loading &&
                    spouseIncome > 0 &&
                    spouseRetireAge && (
                      <Flex alignSelf={"stretch"}>
                        <SalaryGrowthTable
                          salary={moneyToNumber(spouseIncome)}
                          getAge={getAge}
                          currentAge={spouseAge}
                          retireAge={spouseRetireAge}
                          yearToDate={yearToDate}
                          currencyFormat={currencyFormat}
                          moneyToNumber={moneyToNumber}
                          growthOverYears={spouseGrowthOverYears}
                          setGrowthOverYears={onUpdateGrowthOverYears}
                          isEditMode={isEditMode}
                          height={
                            spouseEmploymentType === "Self employed"
                              ? "580px"
                              : "430px"
                          }
                          user="spouse"
                        />
                      </Flex>
                    )}

                  {spouseEmploymentStatus === "Yes" &&
                    loading &&
                    spouseStatus &&
                    spouseIncome > 0 &&
                    spouseRetireAge && (
                      <Flex
                        display={"flex"}
                        justifyContent={"center"}
                        alignItems={"center"}
                        gap={"10px"}
                        alignSelf={"stretch"}
                        borderRadius={"10px"}
                      >
                        <SalaryGraph
                          getAge={getAge}
                          currentAge={spouseAge}
                          retireAge={spouseRetireAge}
                          growthOverYears={spouseGrowthOverYears}
                        />
                      </Flex>
                    )}
                </VStack>
              }
              videoName={"Income Page"}
              video={"Income_page.mp4"}
            />
          )}
        </VStack>
      )}
      {!isMobile && (
        <VStack w={"100%"}>
          <VStack alignItems={"flex-start"} width={"100%"} mt={-2}>
            <GlobalBodyPage
              Heading={"Employment Income"}
              mainframeContent={
                <VStack spacing={4} alignItems={"flex-start"} width={"100%"}>
                  <HStack w={"100%"} align={"stretch"} justifyContent={"space-between"} maxH={"auto"}>
                    <VStack spacing={4} alignItems={"flex-start"} width={"45%"}>
                      <Flex justify="space-between" w="100%">
                        <Heading
                          height={"27px"}
                          alignSelf={"stretch"}
                          color={"#00466D"}
                          fontFamily={"Nunito"}
                          fontSize={"20px"}
                          fontStyle={"normal"}
                          fontWeight={"700"}
                          lineHeight={"normal"}
                        >
                          {firstName} {lastName}
                        </Heading>
                      </Flex>
                      <Flex justify="space-between" w="full">
                        <Heading
                          height={"22px"}
                          alignSelf={"stretch"}
                          color={"#007EB5"}
                          fontFamily={"Nunito"}
                          fontSize={"16px"}
                          fontStyle={"normal"}
                          fontWeight={"600"}
                          lineHeight={"normal"}
                        >
                          Current Employment Status
                        </Heading>
                      </Flex>
                      <Flex justify="space-between" w="full">
                        <DropDownButton
                          Heading={"Are you currently employed"}
                          placeholder={"Select"}
                          dropDownItems={employmentStatusList}
                          value={employmentStatus}
                          onChange={(key) => {
                            setEmploymentStatus(key);
                            setChangedData(true);
                          }}
                          customWidth={"100%"}
                          error={errors.employmentStatus}
                          MenuCustomWidth={"100%"}
                          tooltipContent={TooltipContent.employmentIncomeYesNo}
                        />
                      </Flex>
                      {employmentStatus === "Yes" && (
                        <Flex justify="space-between" w="full">
                          <DropDownButton
                            Heading={"Employment Type"}
                            placeholder={"Select"}
                            dropDownItems={employmentTypeList}
                            value={employmentType}
                            onChange={(key) => {
                              setEmploymentType(key);
                              setChangedData(true);
                            }}
                            customWidth={"100%"}
                            error={errors.employmentType}
                            MenuCustomWidth={"100%"}
                            tooltipContent={TooltipContent.employmentType}
                          />
                        </Flex>
                      )}
                      
                      {employmentStatus === "Yes" && (
                        <Flex justify="space-between" w="full">
                          <Heading
                            alignSelf={"stretch"}
                            color={"#007EB5"}
                            fontFamily={"Nunito"}
                            fontSize={"16px"}
                            fontStyle={"normal"}
                            fontWeight={"600"}
                            lineHeight={"normal"}
                          >
                            Income and Retirement Planning
                          </Heading>
                        </Flex>
                      )}

                      {employmentStatus === "Yes" && (
                        <Flex justify="space-between" w="full">
                          <NormalInputButton
                            Heading={"Annual Income"}
                            placeholder={"$"}
                            value={userSalary}
                            onChange={(e) => {
                              setUserSalary(moneyToNumber(e.target.value));
                              setChangedData(true);
                            }}
                            customWidth={"100%"}
                            useThousandSeparator={true}
                            error={errors.userSalary}
                            InputWidth={"100%"}
                            tooltipContent={TooltipContent.employmentIncomeAmount}
                          />
                        </Flex>
                      )}
                      {employmentStatus === "Yes" && (
                        <Flex justify="space-between" w="full">
                          <NormalInputButton
                            Heading={"Salary growth rate"}
                            placeholder={"%"}
                            value={salaryGrowth}
                            onChange={(e) => {
                              const noSymbol = e.target.value.replace("%", "");
                              const growthNum = parseFloat(noSymbol);
                              setSalaryGrowth(
                                !isNaN(growthNum)
                                  ? parseFloat(growthNum.toFixed(2))
                                  : ""
                              );
                              setChangedData(true);
                            }}
                            error={errors.salaryGrowth}
                            customWidth={"100%"}
                            usePercentage={true}
                            InputWidth={"100%"}
                            tooltipContent={TooltipContent.employmentIncomeGrowth}
                          />
                        </Flex>
                      )}
                      {employmentStatus === "Yes" &&
                        employmentType === "Self employed" && (
                          <Flex justify="space-between" w="full">
                            <NormalInputButton
                              Heading={"Annual Deductions"}
                              placeholder={"$"}
                              value={deduction}
                              onChange={(e) => {
                                setDeductions(moneyToNumber(e.target.value));
                                setChangedData(true);
                              }}
                              customWidth={"100%"}
                              InputWidth={"100%"}
                              useThousandSeparator={true}
                              tooltipContent={TooltipContent.employmentIncomeDeductions}
                            />
                          </Flex>
                        )}
                      {employmentStatus === "Yes" &&
                        employmentType === "Self employed" && (
                          <Flex justify="space-between" w="full">
                            <DropDownButton
                              Heading={"Do you participate in EI?"}
                              placeholder={"Select"}
                              dropDownItems={employmentStatusList}
                              value={eiParticipate}
                              onChange={(key) => {
                                setEiParticipate(key);
                                setChangedData(true);
                              }}
                              customWidth={"100%"}
                              MenuCustomWidth={"100%"}
                              error={errors.eiParticipate}
                              tooltipContent={TooltipContent.employmentIncomeInsuranceYesNo}
                            />
                          </Flex>
                        )}
                      {employmentStatus === "Yes" && (
                        <Flex justify="space-between" w="full">
                          <DropDownButton
                            Heading={"Planned age of retirement"}
                            placeholder={"Select"}
                            dropDownItems={Select(currentAge)}
                            value={retireAge}
                            onChange={(key) => {
                              setRetireAge(key);
                              setChangedData(true);
                            }}
                            customWidth={"100%"}
                            error={errors.retireAge}
                            MenuCustomWidth={"100%"}
                            tooltipContent={TooltipContent.employmentRetirementAge}
                          />
                        </Flex>
                      )}
                    </VStack>
                    {employmentStatus === "Yes" &&
                      loading &&
                      userSalary > 0 &&
                      retireAge && (
                        <Flex alignSelf={"stretch"} minH={"0"} width={"48%"}>
                          <SalaryGrowthTable
                            salary={moneyToNumber(userSalary)}
                            getAge={getAge}
                            currentAge={currentAge}
                            retireAge={retireAge}
                            yearToDate={yearToDate}
                            currencyFormat={currencyFormat}
                            moneyToNumber={moneyToNumber}
                            growthOverYears={growthOverYears}
                            setGrowthOverYears={onUpdateGrowthOverYears}
                            isEditMode={isEditMode}
                            height={
                              employmentType === "Self employed"
                                ? "580px"
                                : "430px"
                            }
                            user="primary"
                          />
                        </Flex>
                      )}
                  </HStack>
                  {employmentStatus === "Yes" &&
                    loading &&
                    userSalary > 0 &&
                    retireAge && (
                      <Flex
                        display={"flex"}
                        justifyContent={"center"}
                        alignItems={"center"}
                        gap={"10px"}
                        alignSelf={"stretch"}
                        borderRadius={"10px"}
                      >
                        <SalaryGraph
                          getAge={getAge}
                          currentAge={currentAge}
                          retireAge={retireAge}
                          growthOverYears={growthOverYears}
                        />
                      </Flex>
                    )}
                </VStack>
              }
              videoName={"Income Page"}
              video={"Income_page.mp4"}
            />
          </VStack>
          {spouseStatus && (
            <VStack spacing={4} alignItems={"flex-start"} width={"100%"}>
              <GlobalBodyPage
                mainframeContent={
                  <VStack spacing={4} alignItems={"flex-start"} width={"100%"}>
                    <HStack w={"100%"} justifyContent={"space-between"}>
                      <VStack
                        spacing={4}
                        alignItems={"flex-start"}
                        width={"45%"}
                      >
                        <Flex justify="space-between" w="100%">
                          <Heading
                            height={"27px"}
                            alignSelf={"stretch"}
                            color={"#00466D"}
                            fontFamily={"Nunito"}
                            fontSize={"20px"}
                            fontStyle={"normal"}
                            fontWeight={"700"}
                            lineHeight={"normal"}
                          >
                            {spouseFirstName} {spouseLastName}
                          </Heading>
                        </Flex>
                        <Flex justify="space-between" w="full">
                          <Heading
                            height={"22px"}
                            alignSelf={"stretch"}
                            color={"#007EB5"}
                            fontFamily={"Nunito"}
                            fontSize={"16px"}
                            fontStyle={"normal"}
                            fontWeight={"600"}
                            lineHeight={"normal"}
                          >
                            Current Employment Status
                          </Heading>
                        </Flex>
                        <Flex justify="space-between" w="full">
                          <DropDownButton
                            Heading={"Are you currently employed"}
                            placeholder={"Select"}
                            dropDownItems={employmentStatusList}
                            value={spouseEmploymentStatus}
                            onChange={(key) => {
                              setSpouseEmploymentStatus(key);
                              setChangedData(true);
                            }}
                            customWidth={"100%"}
                            InputWidth={"100%"}
                            MenuCustomWidth={"100%"}
                            error={errors.spouseEmploymentStatus}
                            tooltipContent={TooltipContent.employmentIncomeYesNo}
                          />
                        </Flex>
                        {spouseEmploymentStatus === "Yes" && (
                          <Flex justify="space-between" w="full">
                            <DropDownButton
                              Heading={"Employment Type"}
                              placeholder={"Select"}
                              dropDownItems={employmentTypeList}
                              value={spouseEmploymentType}
                              onChange={(key) => {
                                setSpouseEmploymentType(key);
                                setChangedData(true);
                              }}
                              customWidth={"100%"}
                              InputWidth={"100%"}
                              MenuCustomWidth={"100%"}
                              error={errors.spouseEmploymentType}
                              tooltipContent={TooltipContent.employmentType}
                            />
                          </Flex>
                        )}
                        
                        {spouseEmploymentStatus === "Yes" && (
                          <Flex justify="space-between" w="full">
                            <Heading
                              alignSelf={"stretch"}
                              color={"#007EB5"}
                              fontFamily={"Nunito"}
                              fontSize={"16px"}
                              fontStyle={"normal"}
                              fontWeight={"600"}
                              lineHeight={"normal"}
                            >
                              Income and Retirement Planning
                            </Heading>
                          </Flex>
                        )}

                        {spouseEmploymentStatus === "Yes" && (
                          <Flex justify="space-between" w="full">
                            <NormalInputButton
                              Heading={"Annual Income"}
                              placeholder={"$"}
                              value={spouseIncome}
                              onChange={(e) => {
                                setSpouseIncome(moneyToNumber(e.target.value));
                                setChangedData(true);
                              }}
                              customWidth={"100%"}
                              InputWidth={"100%"}
                              MenuCustomWidth={"100%"}
                              useThousandSeparator={true}
                              error={errors.spouseIncome}
                              tooltipContent={TooltipContent.employmentIncomeAmount}
                            />
                          </Flex>
                        )}
                        {spouseEmploymentStatus === "Yes" && (
                          <Flex justify="space-between" w="full">
                            <NormalInputButton
                              Heading={"Salary growth rate"}
                              placeholder={"2%"}
                              value={spouseSalaryGrowth}
                              onChange={(e) => {
                                const noSymbol = e.target.value.replace(
                                  "%",
                                  ""
                                );
                                const growthNum = parseFloat(noSymbol);
                                setSpouseSalaryGrowth(
                                  !isNaN(growthNum)
                                    ? parseFloat(growthNum.toFixed(2))
                                    : ""
                                );
                                setChangedData(true);
                              }}
                              error={errors.spouseSalaryGrowth}
                              usePercentage={true}
                              customWidth={"100%"}
                              InputWidth={"100%"}
                              MenuCustomWidth={"100%"}
                              tooltipContent={TooltipContent.employmentIncomeGrowth}
                            />
                          </Flex>
                        )}
                        {spouseEmploymentStatus === "Yes" &&
                          spouseEmploymentType === "Self employed" && (
                            <Flex justify="space-between" w="full">
                              <NormalInputButton
                                Heading={"Annual Deductions"}
                                placeholder={"$"}
                                value={spouseDeduction}
                                onChange={(e) => {
                                  setSpouseDeduction(
                                    moneyToNumber(e.target.value)
                                  );
                                  setChangedData(true);
                                }}
                                customWidth={"100%"}
                                InputWidth={"100%"}
                                MenuCustomWidth={"100%"}
                                useThousandSeparator={true}
                                tooltipContent={TooltipContent.employmentIncomeDeductions}
                              />
                            </Flex>
                          )}
                        {spouseEmploymentStatus === "Yes" &&
                          spouseEmploymentType === "Self employed" && (
                            <Flex justify="space-between" w="full">
                              <DropDownButton
                                Heading={"Do you participate in EI?"}
                                placeholder={"Select"}
                                dropDownItems={employmentStatusList}
                                value={spouseEiParticipate}
                                onChange={(key) => {
                                  setSpouseEiParticipate(key);
                                  setChangedData(true);
                                }}
                                customWidth={"100%"}
                                InputWidth={"100%"}
                                MenuCustomWidth={"100%"}
                                error={errors.spouseEiParticipate}
                                tooltipContent={TooltipContent.employmentIncomeInsuranceYesNo}
                              />
                            </Flex>
                          )}
                        {spouseEmploymentStatus === "Yes" && (
                          <Flex justify="space-between" w="full">
                            <DropDownButton
                              Heading={"Planned age of retirement"}
                              placeholder={"Select"}
                              dropDownItems={Select(spouseAge)}
                              value={spouseRetireAge}
                              onChange={(key) => {
                                setSpouseRetireAge(key);
                                setChangedData(true);
                              }}
                              customWidth={"100%"}
                              InputWidth={"100%"}
                              MenuCustomWidth={"100%"}
                              error={errors.spouseRetireAge}
                              tooltipContent={TooltipContent.employmentRetirementAge}
                            />
                          </Flex>
                        )}
                      </VStack>
                      {spouseEmploymentStatus === "Yes" &&
                        loading &&
                        spouseIncome > 0 &&
                        spouseRetireAge && (
                          <Flex alignSelf={"stretch"} minH={"0"} width={"48%"}>
                            <SalaryGrowthTable
                              salary={moneyToNumber(spouseIncome)}
                              getAge={getAge}
                              currentAge={spouseAge}
                              retireAge={spouseRetireAge}
                              yearToDate={yearToDate}
                              currencyFormat={currencyFormat}
                              moneyToNumber={moneyToNumber}
                              growthOverYears={spouseGrowthOverYears}
                              setGrowthOverYears={onUpdateGrowthOverYears}
                              isEditMode={isEditMode}
                              height={
                                spouseEmploymentType === "Self employed"
                                  ? "580px"
                                  : "430px"
                              }
                              user="spouse"
                            />
                          </Flex>
                        )}
                    </HStack>
                    {spouseEmploymentStatus === "Yes" &&
                      loading &&
                      spouseIncome > 0 &&
                      spouseRetireAge && (
                        <Flex
                          display={"flex"}
                          justifyContent={"center"}
                          alignItems={"center"}
                          gap={"10px"}
                          alignSelf={"stretch"}
                          borderRadius={"10px"}
                        >
                          <SalaryGraph
                            getAge={getAge}
                            currentAge={spouseAge}
                            retireAge={spouseRetireAge}
                            growthOverYears={spouseGrowthOverYears}
                          />
                        </Flex>
                      )}
                  </VStack>
                }
                videoName={"Income Page"}
                video={"Income_page.mp4"}
              />
            </VStack>
          )}
        </VStack>
      )}
    </Flex>
  );
});

export default Salary;
