import {
  Box,
  Flex,
  HStack,
  Text,
  VStack,
  useColorModeValue,
  Icon,
  useMediaQuery,
} from "@chakra-ui/react";
import { EditIcon } from "@chakra-ui/icons";
import React, { useEffect, useState, useContext } from "react";
import { SpouseContext } from "./SpouseContext";
import { get } from "lodash";

const ExpenseGrowthTable = (props) => {
  const {
    getAge,
    getSpouseAge,
    currentAge,
    runAge,
    yearToDate,
    currencyFormat,
    moneyToNumber,
    growthOverYears,
    mortgageExpenses,
    debtExpenses,
    insuranceExpenses,
    setGrowthOverYears,
    isEditMode,
    user,
  } = props;

  const [currencyFormatted, setCurrencyFormatted] = useState([]);
  const [fixedFormatted, setFixedFormatted] = useState([]);
  const [showFixedExpenses, setShowFixedExpenses] = useState(false);
  const { spouseStatus, setSpouseStatus } = useContext(SpouseContext);

  const bgColor = useColorModeValue("white", "gray.800");
  const borderColor = useColorModeValue("gray.200", "gray.600");

  const getYears = () => {
    const currentDate = new Date();
    const currentYear = currentDate.getFullYear();
    const userAge = getAge(currentAge, currentDate);
    const fullRun = runAge - userAge;
    if (fullRun > 0 && Number.isInteger(fullRun)) {
      return [...Array(fullRun).keys()].map((i) => i + currentYear);
    } else {
      return [];
    }
  };

  const formatArray = (array, formatter) => {
    return array ? array.map((value) => formatter(value)) : [];
  };

  const sumArrays = (...arrays) => {
    const length = Math.max(...arrays.map((array) => array.length));
    return Array.from({ length }).map((_, i) =>
      arrays.reduce((sum, array) => sum + (array[i] || 0), 0)
    );
  };

  useEffect(() => {
    const fixedExpenses = sumArrays(
      mortgageExpenses,
      debtExpenses,
      insuranceExpenses
    );
    setCurrencyFormatted(formatArray(growthOverYears, currencyFormat));
    setFixedFormatted(formatArray(fixedExpenses, currencyFormat));
    setShowFixedExpenses(fixedExpenses.some((expense) => expense !== 0));
  }, [growthOverYears, mortgageExpenses, debtExpenses, insuranceExpenses]);

  const saveCellChanges = (e, i, type) => {
    const value = moneyToNumber(e.currentTarget.textContent);
    if (type === "growth") {
      growthOverYears[i] = value;
      setGrowthOverYears(user, [...growthOverYears]);
    }
    setCurrencyFormatted(formatArray(growthOverYears, currencyFormat));
    e.target.innerText = currencyFormat(value);
  };

  const handleKeyDown = (event) => {
    const isDigit = /^\d$/;
    if (
      !isDigit.test(event.key) &&
      event.key !== "Backspace" &&
      event.key !== "Delete" &&
      event.key !== "ArrowUp" &&
      event.key !== "ArrowDown" &&
      event.key !== "ArrowLeft" &&
      event.key !== "ArrowRight"
    ) {
      event.preventDefault();
    }

    const maxLength = 8;
    let text = event.target.innerText;
    if (
      event.key !== "Backspace" &&
      event.key !== "Delete" &&
      text.length >= maxLength &&
      event.key !== "ArrowUp" &&
      event.key !== "ArrowDown" &&
      event.key !== "ArrowLeft" &&
      event.key !== "ArrowRight"
    ) {
      event.preventDefault();
    }
  };

  const handleFocus = (event) => {
    event.target.innerText = event.target.innerText.replace(/\D/g, "");
  };

  const calculateTotal = (index) => {
    const fixedExpenses = sumArrays(
      mortgageExpenses,
      debtExpenses,
      insuranceExpenses
    );
    return (fixedExpenses[index] || 0) + (growthOverYears[index] || 0);
  };

  const [isLargerThan500] = useMediaQuery("(min-width: 500px)");
  const isMobile = !isLargerThan500;

  const [isLargerThan1200] = useMediaQuery(
    "(min-width: 500px) and (max-width: 1250px)"
  );

  function calculateAge(dob, year = new Date().getFullYear()) {
    const birthDate = new Date(dob);
    const targetDate = new Date(year, birthDate.getMonth(), birthDate.getDate());
    let age = year - birthDate.getFullYear();
    if (targetDate > new Date()) { // If the target date is in the future compared to now
      age--;
    }
    return age;
  }

  return (
    <Box
      bg={bgColor}
      p={isMobile ? 3 : 4}
      borderRadius="md"
      boxShadow="md"
      width="100%"
      maxW="1200px"
      mx="auto"
      border="1px solid #00A2D4"
    >
      <Flex mb={4} borderBottom="2px" borderColor="gray.200" pb={2}>
        <Text
          fontSize={isMobile ? "10px" : "l"}
          fontWeight="bold"
          flex={isLargerThan1200 ? "0.6" : showFixedExpenses ? "0.7" : "0.6"}
        >
          Year
        </Text>
        <Text fontSize={isMobile ? "10px" : "l"} fontWeight="bold" flex={"0.5"}>
          Age
        </Text>
        {showFixedExpenses && (
          <Text
            fontSize={isMobile ? "10px" : "l"}
            fontWeight="bold"
            flex="0.8"
            textAlign={"end"}
          >
            Fixed Expenses
          </Text>
        )}
        <Text
          fontSize={isMobile ? "10px" : "l"}
          fontWeight="bold"
          flex={isLargerThan1200 ? "1.05" : "1"}
          textAlign={"end"}
          paddingRight={!showFixedExpenses && (isMobile ? "25px" : "35px")}
        >
          Living Expenses
        </Text>
        {showFixedExpenses && (
          <Text
            fontSize={isMobile ? "10px" : "l"}
            fontWeight="bold"
            flex={isLargerThan1200 ? "0.8" : "0.9"}
            textAlign="end"
            paddingRight={"18px"}
          >
            Total
          </Text>
        )}
      </Flex>
      <VStack spacing={2} maxHeight="300px" overflowY="auto">
        {" "}
        {/* Adjusted spacing to 2 */}
        {getYears().map((year, i) => (
          <Flex
            key={i}
            w="100%"
            bg={useColorModeValue("gray.50", "gray.700")}
            p={isMobile ? 2 : 3}
            color={"#00A2D4"}
            fontFamily={"Nunito"}
            fontWeight={"600"}
            borderRadius="md"
            boxShadow="sm"
          >
            <HStack flex="0.5">
              <Text fontSize={isMobile ? "10px" : "l"}>{year}</Text>
            </HStack>
            <HStack flex={isMobile ? "0.4" : "0.4"}>
              <Text fontSize={isMobile ? "10px" : "l"}>
                {getAge(currentAge, yearToDate(year))}
                {spouseStatus && getSpouseAge && `, ${calculateAge(getSpouseAge, year)}`}
              </Text>
            </HStack>
            {showFixedExpenses && (
              <HStack
                flex={isMobile ? "0.6" : "0.7"}
                justify={isLargerThan1200 ? "flex-end" : "flex-end"}
              >
                <Text fontSize={isMobile ? "10px" : "l"}>
                  {fixedFormatted[i]}
                </Text>
              </HStack>
            )}
            <HStack
              flex={isMobile ? "0.9" : "0.9"}
              border={`1px solid ${borderColor}`}
              borderRadius="md"
              justify={"flex-end"}
            >
              {!isEditMode && (
                <Text
                  textAlign={isLargerThan1200 ? "end" : "center"}
                  fontSize={isMobile ? "10px" : "l"}
                >
                  {currencyFormatted[i]}
                </Text>
              )}
              {isEditMode && (
                <Text
                  contentEditable
                  onBlur={(e) => saveCellChanges(e, i, "growth")}
                  onKeyDown={handleKeyDown}
                  onFocus={handleFocus}
                  cursor={"pointer"}
                  textAlign="center"
                  fontSize={isMobile ? "10px" : "l"}
                >
                  {currencyFormatted[i]}
                  <Icon as={EditIcon} ml={isMobile ? 1 : 2} color="#00A2D4" />
                </Text>
              )}
            </HStack>
            {showFixedExpenses && (
              <HStack flex={isMobile ? "0.7" : "0.75"} justify="flex-end">
                <Text textAlign="center" fontSize={isMobile ? "10px" : "l"}>
                  {currencyFormat(calculateTotal(i))}
                </Text>
              </HStack>
            )}
          </Flex>
        ))}
      </VStack>
    </Box>
  );
};

export default ExpenseGrowthTable;
