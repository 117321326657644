import React, { useState, useEffect } from "react";
import axios from "axios";
import { useAuth0 } from "@auth0/auth0-react";
import Modal from "react-modal";
import { useStore } from "../../store";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleXmark } from "@fortawesome/pro-light-svg-icons";
import AWS from "aws-sdk";
import "./VideoModal.css";
import { CloseIcon } from "../CustomIcons";
import { CloseButton, Icon } from "@chakra-ui/react";

Modal.setAppElement("#root"); // Set the root element for accessibility

// Custom CSS directly in the same file
const isMobile = window.innerWidth <= 1200;
const isLargerThan1200 = window.innerWidth > 1800;
const modalStyles = {
  overlay: {
    zIndex: 1000,
    backgroundColor: "rgba(0, 0, 0, 0)",
    display: "flex",
    alignItems: "stretch",
    justifyContent: "center",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    maxHeight: isMobile ? "80vh" : "auto", // Maximum height for mobile
    width: isMobile ? "100%" : "50%", // Adjust width for mobile
  },
  content: {
    inset: "auto",
    border: "1px solid #00A2D4",
    position: "fixed",
    backgroundColor: "white",
    padding: "0px 10px",
    borderRadius: "5px",
    outline: "none",
    height:isLargerThan1200 ? "47vh" :"67dvh",
    maxHeight: isMobile ? "80vh" : "auto", // Maximum height for mobile
    width:isLargerThan1200 ? "65%" : "100%" , // Adjust width for mobile
  },
};

const VideoModal = ({
  isOpen,
  closeModal,
  videoName,
  title = "404: Video Not Found",
}) => {
  const userPopupNotification = useStore(
    (store) => store.userPopupNotification
  );
  const setShowDashboardVideo = useStore(
    (store) => store.setShowDashboardVideo
  );
  const [isCheckboxChecked, setIsCheckboxChecked] = useState(false);

  const { getAccessTokenSilently, user } = useAuth0();

  const axiosInstance = axios.create();
  async function getToken() {
    // Replace this with your Auth0 token retrieval logic
    const token = await getAccessTokenSilently({
      audience:
        "https://os2lsiwff43wfpb5ggyawt6qri0mgcro.lambda-url.us-east-2.on.aws/",
      scope: "write:optimization",
    });
    return token;
  }

  // Add a request interceptor
  axiosInstance.interceptors.request.use(
    async (config) => {
      const token = await getToken();
      config.headers.Authorization = `Bearer ${token}`;
      return config;
    },
    (error) => {
      // Do something with request error
      return Promise.reject(error);
    }
  );

  const handleCheckboxClick = () => {
    setIsCheckboxChecked(!isCheckboxChecked);
  };

  const handleCheckboxClickStore = () => {
    if (isCheckboxChecked === true) {
      const incomeURL = (action) =>
        `${
          process.env.REACT_APP_NODE_API_GATEWAY
        }/CompassLambdaApi/SHOW_VIDEO/${action}?UserID=${user.sub.replace(
          "|",
          "%7c"
        )}&DashboardVideo=${false}&NewAnalysis=${
          userPopupNotification?.NewAnalysis
        }`;
      axiosInstance
        .get(incomeURL("add"))
        .then((res) => {
          console.log(res);
          setShowDashboardVideo({
            ...userPopupNotification,
            DashboardVideo: 0,
            NewAnalysis: userPopupNotification?.NewAnalysis,
          });
          if (res.data.code === "ER_DUP_ENTRY") {
            axiosInstance.get(incomeURL("update")).then((res) => {
              console.log(res);
              setShowDashboardVideo({
                ...userPopupNotification,
                DashboardVideo: 0,
                NewAnalysis: userPopupNotification?.NewAnalysis,
              });
            });
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  const [presignedUrl, setPresignedUrl] = useState(null);

  useEffect(() => {
    // Configure AWS SDK with environment variables
    AWS.config.update({
      accessKeyId: "AKIA2BWNKJSSRZAU3UV2",
      secretAccessKey: "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",
      region: "us-east-2",
    });

    // Create a new S3 instance
    const s3 = new AWS.S3();

    // Specify the bucket and object key
    const bucketName = "oltrefinancialhelpvideos";
    const objectKey = videoName;

    // Set the expiration time for the pre-signed URL (in seconds)
    const expirationTime = 3600; // 1 hour

    // Generate a pre-signed URL
    const params = {
      Bucket: bucketName,
      Key: objectKey,
      Expires: expirationTime,
    };
    s3.getSignedUrl("getObject", params, (err, url) => {
      if (err) {
        console.error("Error generating pre-signed URL:", err);
      } else {
        setPresignedUrl(url);
      }
    });
  }, [videoName]);

  const handleCombinedClick = () => {
    closeModal();
    handleCheckboxClickStore();
  };

  return (
    <div className="ReactModal-Content-Container">
      {presignedUrl && (
        <Modal
          isOpen={isOpen}
          onRequestClose={closeModal}
          contentLabel="Help Video"
          style={modalStyles}
        >
          <div className="video-modal-header">
            <h1 className="video-modal-title">{title}</h1>
            <FontAwesomeIcon
              icon={faCircleXmark}
              className="video-close-icon"
              onClick={handleCombinedClick}
              size="2x"
            />
          </div>
          <div className="video-container">
            <iframe
              title="Embedded Video"
              width={"100%"}
              height={"100%"}
              src={presignedUrl}
              frameBorder="0"
              allow="autoplay; encrypted-media"
              allowFullScreen
            ></iframe>
          </div>
          {title === "Welcome" && (
            <div className="bottom-video-box-no-show">
              <input
                id="no-show-checkbox"
                className="checkbox-no-show"
                type="checkbox"
                onClick={handleCheckboxClick}
                checked={isCheckboxChecked}
              />
              <label htmlFor="no-show-checkbox" className="No-Show-Text">
                Don't show me again
              </label>
            </div>
          )}
        </Modal>
      )}
    </div>
  );
};

export default VideoModal;
