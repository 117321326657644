import {
  Box,
  Button,
  Flex,
  Text,
  useMediaQuery,
  useDisclosure,
} from "@chakra-ui/react";
import React, { useEffect, useRef, useState } from "react";
import { useStore } from "../store";
import Link from "./Link";
import Popup from "./PlanUpdatePopup";
import OltreChatBubble from "./OltreChatBubble";

function GlobalContainerMobile({ Heading, mainframeContent, link, backLink, notShowChatBubble }) {
  const [dataSave, setDataSave] = useState(true);
  const [changedData, setChangedData] = useState(false);
  const contentRef = useRef(null);
  const userOptiData = useStore((store) => store.userOptiData);
  const userPopupNotification = useStore(
    (store) => store.userPopupNotification
  );
  const { isOpen, onOpen, onClose } = useDisclosure();

  const handleNextClick = () => {
    if (contentRef.current && contentRef.current.checkMandatoryFields()) {
      contentRef.current.submitHandler(); // Call the method directly
      window.history.pushState({}, "", link);
      const navEvent = new PopStateEvent("popstate");
      window.dispatchEvent(navEvent);
    } else {
      // If validation fails, do nothing or provide feedback
      console.error("Validation failed. Please fill all required fields.");
    }
  };

  useEffect(() => {
    if (userOptiData?.primaryUserData?.expenses?.length > 1) {
      setDataSave(true);
    } else {
      setDataSave(false);
    }
  }, [userOptiData]);

  const handleSaveClick = () => {
    if (contentRef.current && contentRef.current.checkMandatoryFields()) {
      contentRef.current.submitHandler(); // Call the method directly
      onOpen();
    } else {
      // If validation fails, do nothing or provide feedback
      console.error("Validation failed. Please fill all required fields.");
    }
  };

  const [isLargerThan1200] = useMediaQuery(
    "(min-width: 500px) and (max-width: 1250px)"
  );

  return (
    <Flex
      display={"flex"}
      flexDirection={"column"}
      paddingTop={"15px"}
      padding={isLargerThan1200 && "15px 50px 50px 50px"}
      alignItems={"center"}
      gap={"10px"}
      flex={"1 0 0"}
      bg={"#F3FAFF"}
      minH={"87dvh"}
      paddingBottom={"40px"}
    >
      {/** MainContent box */}
      <Box
        display={"flex"}
        width={isLargerThan1200 ? "100%" : "calc(100% - 2 * 15px)"}
        padding={"20px 15px"}
        minH={"inherit"}
        flexDirection={"column"}
        alignItems={"flex-start"}
        gap={"10px"}
        border={"1px solid #00A2D4"}
        background={"#FFF"}
        borderRadius={"10px"}
        
      >
        {/* Heading box */}
        <Box
          display={"flex"}
          justifyContent={"space-between"}
          alignItems={"center"}
          alignSelf={"stretch"}
        >
          <Box flex={"1 0 0"}>
            <Text
              color={"#00466D"}
              fontFamily={"Nunito"}
              fontSize={"24px"}
              fontStyle={"normal"}
              fontWeight={"700"}
              lineHeight={"normal"}
            >
              {Heading}
            </Text>
          </Box>
        </Box>

        {/* Body */}
        {React.cloneElement(mainframeContent, {
          ref: contentRef,
          setChangedData: setChangedData,
        })}

        {!dataSave && (
          <Box
            display="flex"
            flexDirection="row" // Change this to row for horizontal layout
            justifyContent="space-between" // This will space out the children across the full width
            alignItems="center" // Align items vertically in the center
            alignSelf="stretch"
            width="100%" // Ensure the box takes the full width of its container
          >
            {backLink ? (
              <Link href={backLink}>
                <Button
                  display={"flex"}
                  width={"75px"}
                  height={"25px"}
                  padding={"8px 48px"}
                  justifyContent={"center"}
                  alignItems={"center"}
                  gap={"5px"}
                  borderRadius={"5px"}
                  background={"#00A2D4"}
                >
                  <Text
                    color={"#FFF"}
                    textAlign={"center"}
                    fontFamily={"Nunito"}
                    fontSize={"16px"}
                    fontStyle={"normal"}
                    fontWeight={"600"}
                    lineHeight={"normal"}
                  >
                    Back
                  </Text>
                </Button>
              </Link>
            ) : (
              <Box width="8px" height="48px" opacity={0} /> // Invisible spacer with similar dimensions to the button
            )}
            {link && (
              <Button
                display={"flex"}
                width={"75px"}
                height={"25px"}
                padding={"8px 48px"}
                justifyContent={"center"}
                alignItems={"center"}
                gap={"5px"}
                borderRadius={"5px"}
                background={"#00A2D4"}
                zIndex={"1001"}
                onClick={() => handleNextClick()}
              >
                <Text
                  color={"#FFF"}
                  textAlign={"center"}
                  fontFamily={"Nunito"}
                  fontSize={"16px"}
                  fontStyle={"normal"}
                  fontWeight={"600"}
                  lineHeight={"normal"}
                >
                  Next
                </Text>
              </Button>
            )}
          </Box>
        )}
        {dataSave && changedData && (
          <Box
            display="flex"
            flexDirection="row" // Change this to row for horizontal layout
            justifyContent="space-between" // This will space out the children across the full width
            alignItems="center" // Align items vertically in the center
            alignSelf="stretch"
            width="100%" // Ensure the box takes the full width of its container
          >
            <Box width="8px" height="48px" opacity={0} />
            <Button
              display={"flex"}
              width={"75px"}
              height={"25px"}
              padding={"8px 48px"}
              justifyContent={"center"}
              alignItems={"center"}
              gap={"5px"}
              borderRadius={"5px"}
              background={"#27AE60"}
              zIndex={"1001"}
              onClick={() => handleSaveClick()}
            >
              <Text
                color={"#FFF"}
                textAlign={"center"}
                fontFamily={"Nunito"}
                fontSize={"16px"}
                fontStyle={"normal"}
                fontWeight={"600"}
                lineHeight={"normal"}
              >
                {isOpen ? "Saved" : "Save"}
              </Text>
            </Button>
          </Box>
        )}
        {userPopupNotification?.NewAnalysis === 1 && (
          <Popup isOpen={isOpen} onClose={onClose} link={link} setChangedData={setChangedData} />
        )}
      </Box>
      {!notShowChatBubble &&
      <OltreChatBubble />
      }
    </Flex>
  );
}

export default GlobalContainerMobile;
