import React, { useState, useEffect } from "react";
import {
  Box,
  Flex,
  HStack,
  Text,
  VStack,
  useColorModeValue,
  Icon,
  Spacer,
  useMediaQuery,
} from "@chakra-ui/react";
import { EditIcon } from "@chakra-ui/icons";
import ReactDOM from "react-dom";

function SalaryGrowthTable(props) {
  const {
    salary,
    getAge,
    currentAge,
    retireAge,
    yearToDate,
    currencyFormat,
    moneyToNumber,
    growthOverYears,
    setGrowthOverYears,
    isEditMode,
    user,
    height,
  } = props;

  const [currencyFormatted, setCurrencyFormatted] = useState([]);

  const bgColor = useColorModeValue("white", "gray.800");
  const borderColor = useColorModeValue("gray.200", "gray.600");

  const getYears = () => {
    const currentDate = new Date();
    const currentYear = currentDate.getFullYear();
    const userAge = getAge(currentAge, currentDate);
    const fullRun = retireAge - userAge + 1;
    if (fullRun > 0 && Number.isInteger(fullRun)) {
      return [...Array(fullRun).keys()].map((i) => i + currentYear);
    } else {
      return [];
    }
  };

  const formatArray = (growthOverYears) => {
    const array = [];
    if (growthOverYears) {
      growthOverYears.map((salary) => {
        array.push(currencyFormat(salary));
      });
      setCurrencyFormatted(array);
    }
  };

  useEffect(() => {
    formatArray(growthOverYears);
  }, [growthOverYears]);

  const saveCellChanges = (e, i) => {
    const value = moneyToNumber(e.currentTarget.textContent);
    growthOverYears[i] = value;
    setGrowthOverYears(user, [...growthOverYears]);
    formatArray(growthOverYears);

    const formattedValue = currencyFormat(value);

    // Create a temporary container for the formatted value and the icon
    const tempDiv = document.createElement("div");

    // Render the formatted value and the icon into the temporary container using React
    ReactDOM.render(
      <div style={{ display: 'flex', justifyContent:'flex-end', gap:'0.5rem' }}>
      <Text textAlign={"right"}>{formattedValue}</Text>
      <Icon as={EditIcon} ml={isMobile ? 1 : 2} color="#00A2D4" marginLeft={"0px"} />
    </div>,
      tempDiv
    );

    // Set the innerHTML of the target element to the HTML of the temporary container
    e.target.innerHTML = tempDiv.innerHTML;
  };

  const handleKeyDown = (event) => {
    const isDigit = /^\d$/;
    if (
      !isDigit.test(event.key) &&
      event.key !== "Backspace" &&
      event.key !== "Delete" &&
      event.key !== "ArrowUp" &&
      event.key !== "ArrowDown" &&
      event.key !== "ArrowLeft" &&
      event.key !== "ArrowRight"
    ) {
      event.preventDefault();
    }

    const maxLength = 8;
    let text = event.target.innerText;
    if (
      event.key !== "Backspace" &&
      event.key !== "Delete" &&
      text.length >= maxLength &&
      event.key !== "ArrowUp" &&
      event.key !== "ArrowDown" &&
      event.key !== "ArrowLeft" &&
      event.key !== "ArrowRight"
    ) {
      event.preventDefault();
    }
  };

  const handleFocus = (event) => {
    event.target.innerText = event.target.innerText.replace(/\D/g, "");
    event.target.style.width = "85px";
  };

  const [isLargerThan768] = useMediaQuery("(min-width: 500px)");
  const isMobile = !isLargerThan768;

  return (
    <Box
      bg={bgColor}
      p={4}
      borderRadius="md"
      boxShadow="md"
      width="100%"
      maxW="1200px"
      mx="auto"
      border="1px solid #00A2D4"
      //   maxH="500px"
      overflow="hidden" // Ensure overflow content is hidden
    >
      <Flex
        mb={4}
        borderBottom="2px"
        borderColor="gray.200"
        pb={2}
        justifyContent={"space-between"}
      >
        <Flex
          width={"40%"}
          flexDirection={"row"}
          justifyContent={"space-between"}
        >
          <Text fontSize="l" fontWeight="bold">
            Year
          </Text>
          <Text fontSize="l" fontWeight="bold">
            Age
          </Text>
        </Flex>
        <Text
          fontSize="l"
          fontWeight="bold"
          textAlign={"center"}
          paddingRight={"35px"}
        >
          Salary
        </Text>
      </Flex>
      <VStack spacing={2} maxHeight={height} overflowY="auto">
        {" "}
        {/* Adjust maxHeight to ensure the rows fit */}
        {getYears().map((year, i) => (
          <Flex
            key={i}
            w="100%"
            bg={useColorModeValue("gray.50", "gray.700")}
            p={3}
            color={"#00A2D4"}
            fontFamily={"Nunito"}
            fontWeight={"600"}
            borderRadius="md"
            boxShadow="sm"
          >
            <Flex
              width={"40%"}
              flexDirection={"row"}
              justifyContent={"space-between"}
            >
              <HStack>
                <Text>{year}</Text>
              </HStack>
              <HStack>
                <Text>{getAge(currentAge, yearToDate(year))}</Text>
              </HStack>
            </Flex>
            <HStack
              flex="1"
              border={`1px solid ${borderColor}`}
              borderRadius="md"
              justify="end"
            >
              {!isEditMode && (
                <>
                  <Text textAlign="end">
                    {currencyFormatted[i]}{" "}
                    <Icon as={EditIcon} ml={2} color="#00A2D4" />
                  </Text>
                </>
              )}
              {isEditMode && (
                <>
                  <Text
                    contentEditable
                    onBlur={(e) => saveCellChanges(e, i)}
                    onKeyDown={handleKeyDown}
                    onFocus={handleFocus}
                    cursor={"pointer"}
                    textAlign="end"
                  >
                    {currencyFormatted[i]}
                    <Icon as={EditIcon} ml={2} color="#00A2D4" />
                  </Text>
                </>
              )}
            </HStack>
          </Flex>
        ))}
      </VStack>
    </Box>
  );
}

export default SalaryGrowthTable;
