import { Flex, Heading, VStack, useMediaQuery, Box } from "@chakra-ui/react";
import React, {
  useContext,
  useEffect,
  forwardRef,
  useImperativeHandle,
  useState,
} from "react";
import { useAuth0 } from "@auth0/auth0-react";
import axios from "axios";
import { useStore } from "../../store";
import { SpouseContext } from "../SpouseContext";
import GlobalBodyPage from "../GlobalContainer/BodyPage";
import DropDownButton from "../InputFields/dropDownButton";
import NormalInputButton from "../InputFields/normalInputButton";
import TooltipContent from "../TooltipContent";

const Pension = forwardRef((props, ref) => {
  const { setChangedData } = props;
  const { getAccessTokenSilently } = useAuth0();

  useImperativeHandle(ref, () => ({
    submitHandler,
    checkMandatoryFields,
  }));

  const axiosInstance = axios.create();
  async function getToken() {
    // Replace this with your Auth0 token retrieval logic
    const token = await getAccessTokenSilently({
      audience:
        "https://os2lsiwff43wfpb5ggyawt6qri0mgcro.lambda-url.us-east-2.on.aws/",
      scope: "write:optimization",
    });
    return token;
  }

  // Add a request interceptor
  axiosInstance.interceptors.request.use(
    async (config) => {
      const token = await getToken();
      config.headers.Authorization = `Bearer ${token}`;
      return config;
    },
    (error) => {
      // Do something with request error
      return Promise.reject(error);
    }
  );
  const [isLargerThan768] = useMediaQuery("(min-width: 500px)");
  const isMobile = !isLargerThan768;

  const [employerPensionArray, setEmployerPensionArray] = useState([]);
  const [spouseEmployerPensionArray, setSpouseEmployerPensionArray] = useState(
    []
  );
  // Spouse 1
  const [pensionCurrentValue, setPensionCurrentValue] = useState("");
  const [pensionGrowth, setPensionGrowth] = useState("");
  const [planMember, setPlanMember] = useState("");
  const [pensionPlan, setPensionPlan] = useState("");
  const [employerContribution, setEmployerContribution] = useState("");
  const [pensionNumber, setPensionNumber] = useState("");
  const [pensionAge, setPensionAge] = useState("");

  // Spouse 2
  const [spousePensionNumber, setSpousePensionNumber] = useState("");
  const [spousePensionCurrentValue, setSpousePensionCurrentValue] =
    useState("");
  const [spousePensionGrowth, setSpousePensionGrowth] = useState("");
  const [spousePensionAge, setSpousePensionAge] = useState("");
  const [spousePlanMember, setSpousePlanMember] = useState("");
  const [spousePensionPlan, setSpousePensionPlan] = useState("");
  const [spouseEmployerContribution, setSpouseEmployerContribution] =
    useState("");

  const [spouseCheck, setSpouseCheck] = useState("");

  const [errors, setErrors] = useState({});
  const [spouseErrors, setSpouseErrors] = useState({});

  const [editing, setEditing] = useState(false);
  const [spouseEdit, setSpouseEdit] = useState(false);
  const [singleEdit, setSingleEdit] = useState(false);
  const { spouseStatus, setSpouseStatus } = useContext(SpouseContext);

  const [editingIndex, setEditingIndex] = useState(null);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [activeArray, setActiveArray] = useState(null);

  const { user, logout } = useAuth0();
  const userInfo = useStore((store) => store.userInfo);
  const userPensionInfo = useStore((store) => store.userPensionInfo);
  const setUserPensionInfo = useStore((store) => store.setUserPensionInfo);
  const updateUserPensionInfo = useStore(
    (store) => store.updateUserPensionInfo
  );
  const deleteUserPensionInfo = useStore(
    (store) => store.deleteUserPensionInfo
  );
  const userIncomeInfo = useStore((store) => store.userIncomeInfo);

  const {
    FirstName: firstName,
    LastName: lastName,
    DateOfBirth: currentAge,
    spouseFirstName,
    spouseLastName,
    spouseDateOfBirth,
    runAge,
  } = userInfo;

  useEffect(() => {
    if (userPensionInfo != null) {
      setPlanMember(
        userPensionInfo?.filter((x) => x.spouseCheck === 0)?.[0]?.Pension_Member
      );
      setPensionPlan(
        userPensionInfo?.filter((x) => x.spouseCheck === 0)?.[0]?.Pension_Type
      );
      setPensionCurrentValue(
        userPensionInfo?.filter((x) => x.spouseCheck === 0)?.[0]?.Pension
      );
      setEmployerContribution(
        userPensionInfo?.filter((x) => x.spouseCheck === 0)?.[0]?.Employer_Cont
      );
      setPensionGrowth(
        userPensionInfo?.filter((x) => x.spouseCheck === 0)?.[0]?.Pension_Index
      );
      setPensionNumber(
        userPensionInfo?.filter((x) => x.spouseCheck === 0)?.[0]?.Pension_Number
      );
      setPensionAge(
        userPensionInfo?.filter((x) => x.spouseCheck === 0)?.[0]?.pensionAge
      );

      // Spouse 2 Database & Store Pull
      setSpousePlanMember(
        userPensionInfo?.filter((x) => x.spouseCheck === 1)?.[0]?.Pension_Member
      );
      setSpousePensionPlan(
        userPensionInfo?.filter((x) => x.spouseCheck === 1)?.[0]?.Pension_Type
      );
      setSpousePensionCurrentValue(
        userPensionInfo?.filter((x) => x.spouseCheck === 1)?.[0]?.Pension
      );
      setSpouseEmployerContribution(
        userPensionInfo?.filter((x) => x.spouseCheck === 1)?.[0]?.Employer_Cont
      );
      setSpousePensionGrowth(
        userPensionInfo?.filter((x) => x.spouseCheck === 1)?.[0]?.Pension_Index
      );
      setSpousePensionNumber(
        userPensionInfo?.filter((x) => x.spouseCheck === 1)?.[0]?.Pension_Number
      );
      setSpousePensionAge(
        userPensionInfo?.filter((x) => x.spouseCheck === 1)?.[0]?.pensionAge
      );
    }
  }, []);

  useEffect(() => {
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 100);
  }, [errors, spouseErrors]);

  const selectPensionAge = (userAge, retirementAge) => {
    let i = getAge(userAge) + 1;
    const ageList = [{ key: getAge(userAge), value: "Already Receiving" }];
    for (i; i <= 100; i++) {
      if (i === retirementAge) {
        ageList.push({ key: i, value: i.toString() + " - Retirement" });
      } else {
        ageList.push({ key: i, value: i.toString() });
      }
    } // Convert i to string if necessary
    return ageList;
  };

  const moneyToNumber = (value) => {
    let numValue = value.replace(/[$,\s]/g, "");
    numValue = Number(numValue);
    return numValue;
  };

  function getAge(birthday) {
    const currentDate = new Date();
    // Get the current date
    // const currentDate = new Date();

    // Convert the birthday string into a Date object
    const birthDate = new Date(birthday);

    // Calculate the age in years
    let age = currentDate.getFullYear() - birthDate.getFullYear();

    // Decrement the age if the current month and day are before the birth month and day
    if (
      currentDate.getMonth() < birthDate.getMonth() ||
      (currentDate.getMonth() === birthDate.getMonth() &&
        currentDate.getDate() <= birthDate.getDate())
    ) {
      age -= 1;
    }

    return age;
  }

  const checkMandatoryFields = () => {
    let newErrors = {};
    let newSpouseErrors = {};
    const validPlans = [
      "Pooled Registered Pension Plan",
      "Defined Contribution Plan",
      "Deferred Profit Sharing Plan",
      "Group RRSP",
    ];

    // Individual checks
    if (!planMember) {
      newErrors.planMember = "Required Field";
    }
    if (planMember === "Yes") {
      if (!pensionPlan) {
        newErrors.pensionPlan = "Pension plan is required";
      }
      if (!pensionCurrentValue) {
        newErrors.pensionCurrentValue = "Pension current value is required";
      }
      if (
        validPlans.includes(pensionPlan) &&
        (!employerContribution || employerContribution > 20) &&
        userIncomeInfo?.Salary > 0 &&
        userIncomeInfo?.Employment_Type === "Employee"
      ) {
        newErrors.employerContribution =
          "Employer contribution is required for selected pension plan and cannot exceed 20%";
      }
      if (validPlans.includes(pensionPlan) && pensionGrowth > 20 || !pensionGrowth) {
        newErrors.pensionGrowth = "Growth rate cannot be greater than 20%";
      }
      if (pensionPlan === "Defined Benefit Plan" && !pensionAge) {
        newErrors.pensionAge =
          "Pension age is required for Defined Benefit Plan";
      }
    }

    // Spouse checks
    if (spouseStatus && !spousePlanMember) {
      newSpouseErrors.planMember = "Required Field";
    }
    if (spouseStatus && spousePlanMember === "Yes") {
      // if (!spousePensionGrowth) {
      //   newSpouseErrors.pensionGrowth =
      //     "Pension growth is required";
      // }
      if (!spousePensionPlan) {
        newSpouseErrors.pensionPlan = "Pension plan is required";
      }
      if (!spousePensionCurrentValue) {
        newSpouseErrors.pensionCurrentValue =
          "Pension current value is required";
      }
      if (
        validPlans.includes(spousePensionPlan) &&
        (!spouseEmployerContribution || spouseEmployerContribution > 20) &&
        userIncomeInfo?.spouseIncome > 0 &&
        userIncomeInfo?.spouseEmployment_Type === "Employee"
      ) {
        newSpouseErrors.employerContribution =
          "Employer contribution is required for selected pension plan and cannot exceed 20%";
      }
      if (validPlans.includes(pensionPlan) && spousePensionGrowth > 20 || !spousePensionGrowth) {
        newErrors.pensionGrowth = "Growth rate cannot be greater than 20%";
      }
      if (spousePensionPlan === "Defined Benefit Plan" && !spousePensionAge) {
        newSpouseErrors.pensionAge =
          "Pension age is required for Defined Benefit Plan";
      }
    }

    setSpouseErrors(newSpouseErrors);
    setErrors(newErrors);
    return (
      Object.keys(newErrors).length === 0 &&
      Object.keys(newSpouseErrors).length === 0
    ); // Return true if no errors
  };

  const submitHandler = (e) => {
    if (e) {
      e.preventDefault();
    }
    let PensionValue = pensionCurrentValue;
    let PensionContribution = employerContribution;
    let SpousePensionValue = spousePensionCurrentValue;
    let SpousePensionContribution = spouseEmployerContribution;
    if (planMember === "No") {
      PensionValue = 0;
      PensionContribution = 0;
    }
    if (spousePlanMember === "No") {
      SpousePensionValue = 0;
      SpousePensionContribution = 0;
    }
    if (pensionNumber) {
      const pensionURL = (action) =>
        `${
          process.env.REACT_APP_NODE_API_GATEWAY
        }/CompassLambdaApi/PENSION/${action}?PensionID=${user.sub.replace(
          "|",
          "%7c"
        )}&Pension_Member=${planMember}&Pension_Type=${pensionPlan}&Pension_Index=${pensionGrowth}&Pension=${PensionValue}&Employer_Cont=${PensionContribution}&Personal_Cont=${PensionContribution}&pensionAge=${pensionAge}&spouseCheck=${0}&Pension_Number=${pensionNumber}`;
      axiosInstance
        .get(pensionURL("update"))
        .then((res) => {
          console.log(res);
          const newData = {
            Pension_Member: planMember,
            Pension_Type: pensionPlan,
            Pension_Index: pensionGrowth,
            Pension: PensionValue,
            Employer_Cont: PensionContribution,
            Personal_Cont: PensionContribution,
            pensionAge: pensionAge,
            Pension_Number: pensionNumber,
            spouseCheck: 0,
          };
          updateUserPensionInfo(pensionNumber, newData);
        })
        .catch((error) => {
          console.log(error);
        });
    }
    if (!pensionNumber) {
      const pensionURL = (action) =>
        `${
          process.env.REACT_APP_NODE_API_GATEWAY
        }/CompassLambdaApi/PENSION/${action}?PensionID=${user.sub.replace(
          "|",
          "%7c"
        )}&Pension_Member=${planMember}&Pension_Type=${pensionPlan}&Pension_Index=${pensionGrowth}&Pension=${PensionValue}&Employer_Cont=${PensionContribution}&Personal_Cont=${PensionContribution}&pensionAge=${pensionAge}&spouseCheck=0`;
      axiosInstance
        .get(pensionURL("add"))
        .then((res) => {
          console.log(res);
          const newData = [
            {
              Pension_Member: planMember,
              Pension_Type: pensionPlan,
              Pension_Index: pensionGrowth,
              Pension: PensionValue,
              Employer_Cont: PensionContribution,
              Personal_Cont: PensionContribution,
              pensionAge: pensionAge,
              Pension_Number: res.data.lastInsertedId,
              spouseCheck: 0,
            },
          ];
          setUserPensionInfo(newData);
        })
        .catch((error) => {
          console.log(error);
        });
    }
    if (spouseStatus) {
      if (spousePensionNumber) {
        const pensionURL = (action) =>
          `${
            process.env.REACT_APP_NODE_API_GATEWAY
          }/CompassLambdaApi/PENSION/${action}?PensionID=${user.sub.replace(
            "|",
            "%7c"
          )}&Pension_Member=${spousePlanMember}&Pension_Type=${spousePensionPlan}&Pension_Index=${spousePensionGrowth}&Pension=${SpousePensionValue}&Employer_Cont=${SpousePensionContribution}&Personal_Cont=${SpousePensionContribution}&pensionAge=${spousePensionAge}&spouseCheck=1&Pension_Number=${spousePensionNumber}`;
        axiosInstance
          .get(pensionURL("update"))
          .then((res) => {
            console.log(res);
            const newData = {
              Pension_Member: spousePlanMember,
              Pension_Type: spousePensionPlan,
              Pension_Index: spousePensionGrowth,
              Pension: SpousePensionValue,
              Employer_Cont: SpousePensionContribution,
              Personal_Cont: SpousePensionContribution,
              pensionAge: spousePensionAge,
              Pension_Number: spousePensionNumber,
              spouseCheck: 1,
            };
            updateUserPensionInfo(spousePensionNumber, newData);
          })
          .catch((error) => {
            console.log(error);
          });
      }
      if (!spousePensionNumber) {
        const pensionURL = (action) =>
          `${
            process.env.REACT_APP_NODE_API_GATEWAY
          }/CompassLambdaApi/PENSION/${action}?PensionID=${user.sub.replace(
            "|",
            "%7c"
          )}&Pension_Member=${spousePlanMember}&Pension_Type=${spousePensionPlan}&Pension_Index=${spousePensionGrowth}&Pension=${SpousePensionValue}&Employer_Cont=${SpousePensionContribution}&Personal_Cont=${SpousePensionContribution}&pensionAge=${spousePensionAge}&spouseCheck=1`;
        axiosInstance
          .get(pensionURL("add"))
          .then((res) => {
            console.log(res);
            const newData = [
              {
                Pension_Member: spousePlanMember,
                Pension_Type: spousePensionPlan,
                Pension_Index: spousePensionGrowth,
                Pension: SpousePensionValue,
                Employer_Cont: SpousePensionContribution,
                Personal_Cont: SpousePensionContribution,
                pensionAge: spousePensionAge,
                Pension_Number: res.data.lastInsertedId,
                spouseCheck: 1,
              },
            ];
            setUserPensionInfo(newData);
          })
          .catch((error) => {
            console.log(error);
          });
      }
    }
  };

  const retirementAge = userIncomeInfo?.salaryStopAge;
  const spouseRetirementAge = userIncomeInfo?.spouseSalaryStopAge;

  return (
    <Flex
      display="flex"
      flexWrap="wrap" // Enable wrapping
      justifyContent="flex-start"
      alignItems="start"
      width={"100%"}
    >
      {isMobile && (
        <VStack spacing={4} alignItems={"flex-start"} width={"100%"}>
          <GlobalBodyPage
            Heading="Employer Pension"
            mainframeContent={
              <VStack spacing={4} alignItems={"flex-start"} width={"100%"}>
                <Flex justify="space-between" w="100%">
                  <Heading
                    height={"27px"}
                    alignSelf={"stretch"}
                    color={"#00466D"}
                    fontFamily={"Nunito"}
                    fontSize={"20px"}
                    fontStyle={"normal"}
                    fontWeight={"700"}
                    lineHeight={"normal"}
                  >
                    {firstName} {lastName}
                  </Heading>
                </Flex>
                <DropDownButton
                  Heading={"Are you a member of an employer pension plan?"}
                  placeholder={"Select"}
                  dropDownItems={[
                    { key: "Yes", value: "Yes" },
                    { key: "No", value: "No" },
                  ]}
                  value={planMember}
                  onChange={(key) => {
                    setChangedData(true);
                    setPlanMember(key);
                  }}
                  customWidth={"100%"}
                  MenuCustomWidth={"100%"}
                  error={errors.planMember}
                  tooltipContent={TooltipContent.havePensionYesNo}
                />
                {planMember === "Yes" && userIncomeInfo?.Salary > 0 && (
                  <DropDownButton
                    Heading={"What type of pension plan are you enrolled in?"}
                    placeholder={"Select"}
                    dropDownItems={[
                      {
                        key: "Defined Benefit Plan",
                        value: "Defined Benefit Plan",
                      },
                      {
                        key: "Defined Contribution Plan",
                        value: "Defined Contribution Plan",
                      },
                      {
                        key: "Pooled Registered Pension Plan",
                        value: "Pooled Registered Pension Plan",
                      },
                      {
                        key: "Deferred Profit Sharing Plan",
                        value: "Deferred Profit Sharing Plan",
                      },
                      { key: "Group RRSP", value: "Group RRSP" },
                    ]}
                    value={pensionPlan}
                    onChange={(value) => {
                      setChangedData(true);
                      setPensionPlan(value);
                    }}
                    customWidth={"100%"}
                    MenuCustomWidth={"100%"}
                    error={errors.pensionPlan}
                    tooltipContent={TooltipContent.pensionPlanType}
                  />
                )}
                {planMember === "Yes" && !userIncomeInfo?.Salary && (
                  <DropDownButton
                    Heading={
                      "As you are currently retired all defined contribution plans will have been converted to a RRSP or a LIRA"
                    }
                    placeholder={"Select"}
                    dropDownItems={[
                      {
                        key: "Defined Benefit Plan",
                        value: "Defined Benefit Plan",
                      },
                    ]}
                    value={pensionPlan}
                    onChange={(value) => {
                      setChangedData(true);
                      setPensionPlan(value);
                    }}
                    customWidth={"100%"}
                    MenuCustomWidth={"100%"}
                    error={errors.pensionPlan}
                    tooltipContent={TooltipContent.pensionPlanType}
                  />
                )}
                {planMember === "Yes" &&
                pensionPlan &&
                pensionPlan === "Defined Benefit Plan" ? (
                  <DefinedBenefitPlan
                    PensionAge={pensionAge}
                    setPensionAge={setPensionAge}
                    PensionAmount={pensionCurrentValue}
                    setPensionAmount={setPensionCurrentValue}
                    PensionIndexed={pensionGrowth}
                    setPensionIndex={setPensionGrowth}
                    selectPensionAge={selectPensionAge}
                    currentAge={currentAge}
                    setChangedData={setChangedData}
                    moneyToNumber={moneyToNumber}
                    errors={errors}
                    TooltipContent={TooltipContent}
                    retirementAge={retirementAge}
                  />
                ) : planMember === "Yes" &&
                  pensionPlan &&
                  userIncomeInfo?.Salary > 0 &&
                  pensionPlan != "Defined Benefit Plan" ? (
                  <OtherPension
                    PensionValue={pensionCurrentValue}
                    setPensionValue={setPensionCurrentValue}
                    PensionPercentage={employerContribution}
                    setPensionPercentage={setEmployerContribution}
                    AnnualReturn={pensionGrowth}
                    setAnnualReturn={setPensionGrowth}
                    moneyToNumber={moneyToNumber}
                    setChangedData={setChangedData}
                    salary={userIncomeInfo?.Salary}
                    employmentType={userIncomeInfo?.Employment_Type}
                    errors={errors}
                    TooltipContent={TooltipContent}
                  />
                ) : null}
              </VStack>
            }
            videoName={"Pension Page"}
            video={"Pension_Page.mp4"}
          />
          {spouseStatus && (
            <GlobalBodyPage
              mainframeContent={
                <VStack spacing={4} alignItems={"flex-start"} width={"100%"}>
                  <Flex justify="space-between" w="100%">
                    <Heading
                      height={"27px"}
                      alignSelf={"stretch"}
                      color={"#00466D"}
                      fontFamily={"Nunito"}
                      fontSize={"20px"}
                      fontStyle={"normal"}
                      fontWeight={"700"}
                      lineHeight={"normal"}
                    >
                      {spouseFirstName} {spouseLastName}
                    </Heading>
                  </Flex>
                  <DropDownButton
                    Heading={"Are you a member of an employer pension plan?"}
                    placeholder={"Select"}
                    dropDownItems={[
                      { key: "Yes", value: "Yes" },
                      { key: "No", value: "No" },
                    ]}
                    value={spousePlanMember}
                    onChange={(value) => {
                      setChangedData(true);
                      setSpousePlanMember(value);
                    }}
                    customWidth={"100%"}
                    MenuCustomWidth={"100%"}
                    error={spouseErrors.planMember}
                    tooltipContent={TooltipContent.havePensionYesNo}
                  />
                  {spousePlanMember === "Yes" &&
                    userIncomeInfo?.spouseIncome > 0 && (
                      <DropDownButton
                        Heading={
                          "What type of pension plan are you enrolled in?"
                        }
                        placeholder={"Select"}
                        dropDownItems={[
                          {
                            key: "Defined Benefit Plan",
                            value: "Defined Benefit Plan",
                          },
                          {
                            key: "Defined Contribution Plan",
                            value: "Defined Contribution Plan",
                          },
                          {
                            key: "Pooled Registered Pension Plan",
                            value: "Pooled Registered Pension Plan",
                          },
                          {
                            key: "Deferred Profit Sharing Plan",
                            value: "Deferred Profit Sharing Plan",
                          },
                          { key: "Group RRSP", value: "Group RRSP" },
                        ]}
                        value={spousePensionPlan}
                        onChange={(value) => {
                          setChangedData(true);
                          setSpousePensionPlan(value);
                        }}
                        customWidth={"100%"}
                        MenuCustomWidth={"100%"}
                        error={spouseErrors.pensionPlan}
                        tooltipContent={TooltipContent.pensionPlanType}
                      />
                    )}
                  {spousePlanMember === "Yes" &&
                    !userIncomeInfo?.spouseIncome && (
                      <DropDownButton
                        Heading={
                          "As you are currently retired all defined contribution plans will have been converted to a RRSP or a LIRA"
                        }
                        placeholder={"Select"}
                        dropDownItems={[
                          {
                            key: "Defined Benefit Plan",
                            value: "Defined Benefit Plan",
                          },
                        ]}
                        value={spousePensionPlan}
                        onChange={(value) => {
                          setChangedData(true);
                          setSpousePensionPlan(value);
                        }}
                        customWidth={"100%"}
                        MenuCustomWidth={"100%"}
                        error={spouseErrors.pensionPlan}
                        tooltipContent={TooltipContent.pensionPlanType}
                      />
                    )}
                  {spousePlanMember === "Yes" &&
                  spousePensionPlan === "Defined Benefit Plan" ? (
                    <DefinedBenefitPlan
                      PensionAge={spousePensionAge}
                      setPensionAge={setSpousePensionAge}
                      PensionAmount={spousePensionCurrentValue}
                      setPensionAmount={setSpousePensionCurrentValue}
                      PensionIndexed={spousePensionGrowth}
                      setPensionIndex={setSpousePensionGrowth}
                      selectPensionAge={selectPensionAge}
                      currentAge={spouseDateOfBirth}
                      moneyToNumber={moneyToNumber}
                      setChangedData={setChangedData}
                      errors={spouseErrors}
                      TooltipContent={TooltipContent}
                      retirementAge={spouseRetirementAge}
                    />
                  ) : spousePlanMember === "Yes" &&
                    spousePensionPlan &&
                    userIncomeInfo?.spouseIncome > 0 &&
                    spousePensionPlan != "Defined Benefit Plan" ? (
                    <OtherPension
                      PensionValue={spousePensionCurrentValue}
                      setPensionValue={setSpousePensionCurrentValue}
                      PensionPercentage={spouseEmployerContribution}
                      setPensionPercentage={setSpouseEmployerContribution}
                      AnnualReturn={spousePensionGrowth}
                      setAnnualReturn={setSpousePensionGrowth}
                      moneyToNumber={moneyToNumber}
                      setChangedData={setChangedData}
                      salary={userIncomeInfo?.spouseIncome}
                      employmentType={userIncomeInfo?.spouseEmployment_Type}
                      errors={spouseErrors}
                      TooltipContent={TooltipContent}
                    />
                  ) : null}
                </VStack>
              }
            />
          )}
        </VStack>
      )}
      {!isMobile && (
        <VStack alignItems={"flex-start"} width={"100%"} mt={-2}>
          <GlobalBodyPage
            Heading="Employer Pension"
            mainframeContent={
              <VStack spacing={4} alignItems={"flex-start"} width={"100%"}>
                <Flex justify="space-between" w="100%">
                  <Heading
                    height={"27px"}
                    alignSelf={"stretch"}
                    color={"#00466D"}
                    fontFamily={"Nunito"}
                    fontSize={"20px"}
                    fontStyle={"normal"}
                    fontWeight={"700"}
                    lineHeight={"normal"}
                  >
                    {firstName} {lastName}
                  </Heading>
                </Flex>
                <Box
                  display={"flex"}
                  width={"100%"}
                  flexDirection={"row"}
                  alignItems={"flex-end"}
                  justifyContent={"space-between"}
                  gap={"10px"}
                  flexWrap={"wrap"}
                >
                  <DropDownButton
                    Heading={"Are you a member of an employer pension plan?"}
                    placeholder={"Select"}
                    dropDownItems={[
                      { key: "Yes", value: "Yes" },
                      { key: "No", value: "No" },
                    ]}
                    value={planMember}
                    onChange={(key) => {
                      setChangedData(true);
                      setPlanMember(key);
                    }}
                    customWidth={"100%"}
                    error={errors.planMember}
                    tooltipContent={TooltipContent.havePensionYesNo}
                  />
                  {planMember === "Yes" && userIncomeInfo?.Salary > 0 && (
                    <DropDownButton
                      Heading={"What type of pension plan are you enrolled in?"}
                      placeholder={"Select"}
                      dropDownItems={[
                        {
                          key: "Defined Benefit Plan",
                          value: "Defined Benefit Plan",
                        },
                        {
                          key: "Defined Contribution Plan",
                          value: "Defined Contribution Plan",
                        },
                        {
                          key: "Pooled Registered Pension Plan",
                          value: "Pooled Registered Pension Plan",
                        },
                        {
                          key: "Deferred Profit Sharing Plan",
                          value: "Deferred Profit Sharing Plan",
                        },
                        { key: "Group RRSP", value: "Group RRSP" },
                      ]}
                      value={pensionPlan}
                      onChange={(value) => {
                        setChangedData(true);
                        setPensionPlan(value);
                      }}
                      customWidth={"100%"}
                      error={errors.pensionPlan}
                      tooltipContent={TooltipContent.pensionPlanType}
                    />
                  )}
                  {planMember === "Yes" && !userIncomeInfo?.Salary && (
                    <DropDownButton
                      Heading={
                        "As you are currently retired all defined contribution plans will have been converted to a RRSP or a LIRA"
                      }
                      placeholder={"Select"}
                      dropDownItems={[
                        {
                          key: "Defined Benefit Plan",
                          value: "Defined Benefit Plan",
                        },
                      ]}
                      value={pensionPlan}
                      onChange={(value) => {
                        setChangedData(true);
                        setPensionPlan(value);
                      }}
                      customWidth={"100%"}
                      error={errors.pensionPlan}
                      tooltipContent={TooltipContent.pensionPlanType}
                    />
                  )}
                  {planMember === "Yes" &&
                  pensionPlan &&
                  pensionPlan === "Defined Benefit Plan" ? (
                    <DefinedBenefitPlan
                      PensionAge={pensionAge}
                      setPensionAge={setPensionAge}
                      PensionAmount={pensionCurrentValue}
                      setPensionAmount={setPensionCurrentValue}
                      PensionIndexed={pensionGrowth}
                      setPensionIndex={setPensionGrowth}
                      selectPensionAge={selectPensionAge}
                      currentAge={currentAge}
                      setChangedData={setChangedData}
                      moneyToNumber={moneyToNumber}
                      errors={errors}
                      TooltipContent={TooltipContent}
                      retirementAge={retirementAge}
                    />
                  ) : planMember === "Yes" &&
                    pensionPlan &&
                    userIncomeInfo?.Salary > 0 &&
                    pensionPlan != "Defined Benefit Plan" ? (
                    <OtherPension
                      PensionValue={pensionCurrentValue}
                      setPensionValue={setPensionCurrentValue}
                      PensionPercentage={employerContribution}
                      setPensionPercentage={setEmployerContribution}
                      AnnualReturn={pensionGrowth}
                      setAnnualReturn={setPensionGrowth}
                      moneyToNumber={moneyToNumber}
                      setChangedData={setChangedData}
                      salary={userIncomeInfo?.Salary}
                      employmentType={userIncomeInfo?.Employment_Type}
                      errors={errors}
                      TooltipContent={TooltipContent}
                    />
                  ) : null}
                </Box>
              </VStack>
            }
            videoName={"Pension Page"}
            video={"Pension_Page.mp4"}
          />
          {spouseStatus && (
            <GlobalBodyPage
              mainframeContent={
                <VStack spacing={4} alignItems={"flex-start"} width={"100%"}>
                  <Flex justify="space-between" w="100%">
                    <Heading
                      height={"27px"}
                      alignSelf={"stretch"}
                      color={"#00466D"}
                      fontFamily={"Nunito"}
                      fontSize={"20px"}
                      fontStyle={"normal"}
                      fontWeight={"700"}
                      lineHeight={"normal"}
                    >
                      {spouseFirstName} {spouseLastName}
                    </Heading>
                  </Flex>
                  <DropDownButton
                    Heading={"Are you a member of an employer pension plan?"}
                    placeholder={"Select"}
                    dropDownItems={[
                      { key: "Yes", value: "Yes" },
                      { key: "No", value: "No" },
                    ]}
                    value={spousePlanMember}
                    onChange={(value) => {
                      setChangedData(true);
                      setSpousePlanMember(value);
                    }}
                    customWidth={"100%"}
                    error={spouseErrors.planMember}
                    tooltipContent={TooltipContent.havePensionYesNo}
                  />
                  {spousePlanMember === "Yes" &&
                    userIncomeInfo?.spouseIncome > 0 && (
                      <DropDownButton
                        Heading={
                          "What type of pension plan are you enrolled in?"
                        }
                        placeholder={"Select"}
                        dropDownItems={[
                          {
                            key: "Defined Benefit Plan",
                            value: "Defined Benefit Plan",
                          },
                          {
                            key: "Defined Contribution Plan",
                            value: "Defined Contribution Plan",
                          },
                          {
                            key: "Pooled Registered Pension Plan",
                            value: "Pooled Registered Pension Plan",
                          },
                          {
                            key: "Deferred Profit Sharing Plan",
                            value: "Deferred Profit Sharing Plan",
                          },
                          { key: "Group RRSP", value: "Group RRSP" },
                        ]}
                        value={spousePensionPlan}
                        onChange={(value) => {
                          setChangedData(true);
                          setSpousePensionPlan(value);
                        }}
                        customWidth={"100%"}
                        error={spouseErrors.pensionPlan}
                        tooltipContent={TooltipContent.pensionPlanType}
                      />
                    )}
                  {spousePlanMember === "Yes" &&
                    !userIncomeInfo?.spouseIncome && (
                      <DropDownButton
                        Heading={
                          "As you are currently retired all defined contribution plans will have been converted to a RRSP or a LIRA"
                        }
                        placeholder={"Select"}
                        dropDownItems={[
                          {
                            key: "Defined Benefit Plan",
                            value: "Defined Benefit Plan",
                          },
                        ]}
                        value={spousePensionPlan}
                        onChange={(value) => {
                          setChangedData(true);
                          setSpousePensionPlan(value);
                        }}
                        customWidth={"100%"}
                        error={spouseErrors.pensionPlan}
                        tooltipContent={TooltipContent.pensionPlanType}
                      />
                    )}
                  {spousePlanMember === "Yes" &&
                  spousePensionPlan === "Defined Benefit Plan" ? (
                    <DefinedBenefitPlan
                      PensionAge={spousePensionAge}
                      setPensionAge={setSpousePensionAge}
                      PensionAmount={spousePensionCurrentValue}
                      setPensionAmount={setSpousePensionCurrentValue}
                      PensionIndexed={spousePensionGrowth}
                      setPensionIndex={setSpousePensionGrowth}
                      selectPensionAge={selectPensionAge}
                      currentAge={spouseDateOfBirth}
                      setChangedData={setChangedData}
                      moneyToNumber={moneyToNumber}
                      errors={spouseErrors}
                      TooltipContent={TooltipContent}
                      retirementAge={spouseRetirementAge}
                    />
                  ) : spousePlanMember === "Yes" &&
                    spousePensionPlan &&
                    userIncomeInfo?.spouseIncome > 0 &&
                    spousePensionPlan != "Defined Benefit Plan" ? (
                    <OtherPension
                      PensionValue={spousePensionCurrentValue}
                      setPensionValue={setSpousePensionCurrentValue}
                      PensionPercentage={spouseEmployerContribution}
                      setPensionPercentage={setSpouseEmployerContribution}
                      AnnualReturn={spousePensionGrowth}
                      setAnnualReturn={setSpousePensionGrowth}
                      moneyToNumber={moneyToNumber}
                      setChangedData={setChangedData}
                      salary={userIncomeInfo?.spouseIncome}
                      employmentType={userIncomeInfo?.spouseEmployment_Type}
                      errors={spouseErrors}
                      TooltipContent={TooltipContent}
                    />
                  ) : null}
                </VStack>
              }
            />
          )}
        </VStack>
      )}
    </Flex>
  );
});

function DefinedBenefitPlan({
  PensionAmount,
  setPensionAmount,
  PensionAge,
  setPensionAge,
  PensionIndexed,
  setPensionIndex,
  selectPensionAge,
  currentAge,
  setChangedData,
  moneyToNumber,
  errors,
  TooltipContent,
  retirementAge,
}) {
  const [isLargerThan768] = useMediaQuery("(min-width: 500px)");
  const isMobile = !isLargerThan768;

  return (
    <Box
      display={"flex"}
      width={"100%"}
      flexDirection={"row"}
      paddingTop={"10px"}
      alignItems={"flex-end"}
      justifyContent={"space-between"}
      gap={"10px"}
      flexWrap={"wrap"}
    >
      {isMobile && (
        <VStack spacing={4} alignItems={"flex-start"} width={"100%"}>
          <NormalInputButton
            Heading={
              "What is your estimated annual pension from your Defined Benefit Plan?"
            }
            placeholder={"$"}
            value={PensionAmount}
            onChange={(e) => {
              setPensionAmount(moneyToNumber(e.target.value));
              setChangedData(true);
            }}
            customWidth={"100%"}
            useThousandSeparator={true}
            InputWidth={"100%"}
            error={errors.pensionCurrentValue}
            tooltipContent={TooltipContent.pensionPlanUncertain}
          />
          <DropDownButton
            Heading={"At what age will you start receiving your pension?"}
            placeholder={"Select"}
            dropDownItems={selectPensionAge(currentAge, retirementAge)}
            value={PensionAge}
            onChange={(value) => {
              setChangedData(true);
              setPensionAge(value);
            }}
            MenuCustomWidth={"100%"}
            error={errors.pensionAge}
            tooltipContent={TooltipContent.pensionPlanStartCollecting}
          />
          <DropDownButton
            Heading={"Is your pension indexed?"}
            placeholder={"Select"}
            dropDownItems={[
              { key: 2, value: "Yes" },
              { key: 0, value: "No" },
            ]}
            value={PensionIndexed}
            onChange={(value) => {
              setChangedData(true);
              setPensionIndex(value);
            }}
            MenuCustomWidth={"100%"}
            error={errors.pensionGrowth}
            tooltipContent={TooltipContent.pensionIndex}
          />
        </VStack>
      )}
      {!isMobile && (
        <>
          <NormalInputButton
            Heading={
              "What is your estimated annual pension from your Defined Benefit Plan?"
            }
            placeholder={"$"}
            value={PensionAmount}
            onChange={(e) => {
              setPensionAmount(moneyToNumber(e.target.value));
              setChangedData(true);
            }}
            useThousandSeparator={true}
            error={errors.pensionCurrentValue}
            tooltipContent={TooltipContent.pensionPlanUncertain}
          />
          <DropDownButton
            Heading={"At what age will you start receiving your pension?"}
            placeholder={"Select"}
            dropDownItems={selectPensionAge(currentAge, retirementAge)}
            value={PensionAge}
            onChange={(value) => {
              setChangedData(true);
              setPensionAge(value);
            }}
            MenuCustomWidth={"100%"}
            error={errors.pensionAge}
            tooltipContent={TooltipContent.pensionPlanStartCollecting}
          />
          <DropDownButton
            Heading={"Is your pension indexed?"}
            placeholder={"Select"}
            dropDownItems={[
              { key: 2, value: "Yes" },
              { key: 0, value: "No" },
            ]}
            value={PensionIndexed}
            onChange={(value) => {
              setChangedData(true);
              setPensionIndex(value);
            }}
            error={errors.pensionGrowth}
            tooltipContent={TooltipContent.pensionIndex}
          />
        </>
      )}
    </Box>
  );
}

function OtherPension({
  PensionValue,
  setPensionValue,
  PensionPercentage,
  setPensionPercentage,
  AnnualReturn,
  setAnnualReturn,
  moneyToNumber,
  setChangedData,
  salary,
  employmentType,
  errors,
  TooltipContent,
}) {
  const [isLargerThan768] = useMediaQuery("(min-width: 500px)");
  const isMobile = !isLargerThan768;
  return (
    <>
      {isMobile && (
        <>
          <NormalInputButton
            Heading={"What is the current value of your plan?"}
            placeholder={"$"}
            value={PensionValue}
            onChange={(e) => {
              setPensionValue(moneyToNumber(e.target.value));
              setChangedData(true);
            }}
            customWidth={"100%"}
            useThousandSeparator={true}
            InputWidth={"100%"}
            error={errors.pensionCurrentValue}
            tooltipContent={TooltipContent.pensionPlanUncertain}
          />
          {salary > 0 && employmentType === "Employee" && (
            <NormalInputButton
              Heading={
                "What percentage of your salary does your employer contribute to your pension?"
              }
              placeholder={"%"}
              value={PensionPercentage}
              onChange={(e) => {
                const noSymbol = e.target.value.replace("%", "");
                const growthNum = parseFloat(noSymbol);
                setPensionPercentage(
                  !isNaN(growthNum) ? parseFloat(growthNum.toFixed(2)) : ""
                );
                setChangedData(true);
              }}
              customWidth={"100%"}
              usePercentage={true}
              InputWidth={"100%"}
              error={errors.employerContribution}
              tooltipContent={TooltipContent.pensionEmployerContribution}
            />
          )}
          <NormalInputButton
            Heading={"Expected annual rate of return"}
            placeholder={"%"}
            value={AnnualReturn}
            onChange={(e) => {
              const noSymbol = e.target.value.replace("%", "");
              const growthNum = parseFloat(noSymbol);
              setAnnualReturn(
                !isNaN(growthNum) ? parseFloat(growthNum.toFixed(2)) : ""
              );
              setChangedData(true);
            }}
            customWidth={"100%"}
            usePercentage={true}
            InputWidth={"100%"}
            error={errors.pensionGrowth}
            tooltipContent={TooltipContent.pensionGrowth}
          />
        </>
      )}
      {!isMobile && (
        <>
          <NormalInputButton
            Heading={"What is the current value of your plan?"}
            placeholder={"$"}
            value={PensionValue}
            onChange={(e) => {
              setPensionValue(moneyToNumber(e.target.value));
              setChangedData(true);
            }}
            customWidth={"100%"}
            useThousandSeparator={true}
            InputWidth={"45%"}
            error={errors.pensionCurrentValue}
            tooltipContent={TooltipContent.pensionPlanUncertain}
          />
          {salary > 0 && employmentType === "Employee" && (
            <NormalInputButton
              Heading={
                "What percentage of your salary does your employer contribute to your pension?"
              }
              placeholder={"%"}
              value={PensionPercentage}
              onChange={(e) => {
                const noSymbol = e.target.value.replace("%", "");
                const growthNum = parseFloat(noSymbol);
                setPensionPercentage(
                  !isNaN(growthNum) ? parseFloat(growthNum.toFixed(2)) : ""
                );
                setChangedData(true);
              }}
              customWidth={"100%"}
              usePercentage={true}
              error={errors.employerContribution}
              tooltipContent={TooltipContent.pensionEmployerContribution}
            />
          )}
          <NormalInputButton
            Heading={"Expected annual rate of return"}
            placeholder={"%"}
            value={AnnualReturn}
            onChange={(e) => {
              const noSymbol = e.target.value.replace("%", "");
              const growthNum = parseFloat(noSymbol);
              setAnnualReturn(
                !isNaN(growthNum) ? parseFloat(growthNum.toFixed(2)) : ""
              );
              setChangedData(true);
            }}
            customWidth={"100%"}
            usePercentage={true}
            error={errors.pensionGrowth}
            tooltipContent={TooltipContent.pensionGrowth}
          />
        </>
      )}
    </>
  );
}

export default Pension;
