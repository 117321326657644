import { Box, Button, Input, Text, Flex, VStack } from "@chakra-ui/react";
import React, { useEffect, useRef, useState } from "react";
import Modal from "../components/Modal";
import GlobalBodyPage from "./GlobalContainer/BodyPage";
import "./PersonalInfo.css";

function Assumptions() {
  const [showModal, setShowModal] = useState(false);

  const highlightText = (text, highlight) => {
    if (!highlight) return text;

    const parts = text.split(new RegExp(`(${highlight})`, "gi"));
    return parts.map((part, index) =>
      part.toLowerCase() === highlight.toLowerCase() ? (
        <span key={index} style={{ backgroundColor: "yellow" }}>
          {part}
        </span>
      ) : (
        part
      )
    );
  };

  const [searchTerm, setSearchTerm] = useState("");
  const [currentMatchIndex, setCurrentMatchIndex] = useState(0);
  const [matches, setMatches] = useState([]);
  const itemRefs = useRef({});

  const items = [
    { id: "cpp", name: "Canadian Pension Plan (CPP)" },
    { id: "cpi", name: "Consumer Price Index (CPI)" },
    { id: "expenses", name: "Expenses" },
    { id: "income", name: "Income" },
    { id: "investmentGrowthTiming", name: "Investment Growth Timing" },
    { id: "nonRegistered", name: "Non-registered" },
    { id: "oas", name: "Old Age Security (OAS)" },
    { id: "pensionPlans", name: "Pension Plans" },
    { id: "realEstatePortfolio", name: "Real Estate Portfolio" },
    { id: "rrif", name: "Registered Retirement Income Fund (RRIF)" },
    { id: "runAge", name: "Run Age" },
    { id: "tax", name: "Tax" },
    { id: "tfsa", name: "Tax Free Savings Account (TFSA)" },
    { id: "rrsp", name: "Registered Retirement Savings Plan (RRSP)" },
  ];

  const sectionRefs = {
    cpp: useRef(null),
    cpi: useRef(null),
    expenses: useRef(null),
    income: useRef(null),
    investmentGrowthTiming: useRef(null),
    nonRegistered: useRef(null),
    oas: useRef(null),
    pensionPlans: useRef(null),
    realEstatePortfolio: useRef(null),
    rrif: useRef(null),
    runAge: useRef(null),
    tax: useRef(null),
    tfsa: useRef(null),
    rrsp: useRef(null),
  };

  const jumpToContent = (id) => {
    const section = sectionRefs[id]?.current;
    if (section) {
      section.scrollIntoView({ behavior: "smooth" });
    }
  };

  // Initialize refs for each item
  useEffect(() => {
    items.forEach((item) => {
      if (!itemRefs.current[item.id]) {
        itemRefs.current[item.id] = React.createRef();
      }
    });
  }, []);

  const handleSearchChange = (event) => {
    const term = event.target.value;
    setSearchTerm(term);

    if (term) {
      const newMatches = items.filter((item) =>
        item.name.toLowerCase().includes(term.toLowerCase())
      );
      setMatches(newMatches);
      setCurrentMatchIndex(0);

      if (
        newMatches.length > 0 &&
        itemRefs.current[newMatches[0].id]?.current
      ) {
        itemRefs.current[newMatches[0].id].current.scrollIntoView({
          behavior: "smooth",
        });
      }
    } else {
      setMatches([]);
    }
  };

  const goToNextMatch = () => {
    if (matches.length > 0) {
      const nextIndex = (currentMatchIndex + 1) % matches.length;
      setCurrentMatchIndex(nextIndex);
      const match = matches[nextIndex];
      if (itemRefs.current[match.id].current) {
        itemRefs.current[match.id].current.scrollIntoView({
          behavior: "smooth",
        });
      }
    }
  };

  const goToPreviousMatch = () => {
    if (matches.length > 0) {
      const prevIndex =
        (currentMatchIndex - 1 + matches.length) % matches.length;
      setCurrentMatchIndex(prevIndex);
      const match = matches[prevIndex];
      if (itemRefs.current[match.id].current) {
        itemRefs.current[match.id].current.scrollIntoView({
          behavior: "smooth",
        });
      }
    }
  };

  const inputRef = useRef();

  useEffect(() => {
    inputRef.current.focus();
  }, [searchTerm, matches]);

  const HeaderSearch = () => {
    useEffect(() => {
      if (matches.length > 0) {
        const match = matches[currentMatchIndex];
        if (itemRefs.current[match.id].current) {
          itemRefs.current[match.id].current.scrollIntoView({
            behavior: "smooth",
          });
        }
      }
    }, [currentMatchIndex, matches]);
    return (
      <Box
        display={"flex"}
        justifyContent={"space-between"}
        alignItems={"center"}
        alignSelf={"stretch"}
        flexWrap={"wrap"}
        marginBottom={"10px"}
        w={"-webkit-fill-available"}
      >
        <Text
          color={"#00A2D4"}
          fontFamily={"Nunito"}
          fontSize={"20px"}
          fontStyle={"normal"}
          fontWeight={"700"}
          lineHeight={"normal"}
          textAlign={"center"}
        >
          Assumptions
        </Text>
        <Box display={"flex"} alignItems={"center"}>
          <Box
            display={"flex"}
            width={"173px"}
            height={"28px"}
            flexShrink={0}
            border={"1px solid #00A2D4"}
            borderRadius={"5px"}
            background={"#F5FAFF"}
          >
            <Input
              ref={inputRef}
              placeholder="Search"
              value={searchTerm}
              onChange={handleSearchChange}
              border="none"
              height="100%"
              width="100%"
              padding="0 8px"
              _focus={{ boxShadow: "none" }}
              onKeyPress={(event) => {
                if (event.key === "Enter") {
                  jumpToContent(searchTerm);
                }
              }}
            />
          </Box>
          <Button
            onClick={() => jumpToContent(searchTerm)}
            disabled={!searchTerm}
          >
            Jump to Content
          </Button>
        </Box>
      </Box>
    );
  };

  return (
    <Flex
      display="flex"
      flexWrap="wrap" // Enable wrapping
      justifyContent="flex-start"
      alignItems="start"
      width={"100%"}
    >
      <VStack alignItems={"flex-start"} width={"100%"} mt={-2}>
        <Modal show={showModal} onClose={() => setShowModal(false)} />
        <GlobalBodyPage
          mainframeContent={
            <>
              <HeaderSearch />
              <Box
                display={"flex"}
                width={"-webkit-fill-available"}
                padding={"15px"}
                flexDirection={"column"}
                alignItems={"flex-start"}
                gap={"10px"}
                flexShrink={0}
                alignSelf={"stretch"}
                borderRadius={"14px 10px 10px 10px"}
                border={"1px solid #00A2D4"}
              >
                <div
                  ref={sectionRefs.cpp}
                  id="cpp"
                  className="nunito-bold-blue-18px"
                >
                  {highlightText("Canadian Pension Plan (CPP)", searchTerm)}
                </div>
                <ol className="assumptions-list">
                  <li>
                    {highlightText(
                      `
                        For users who do not have immediate access to their
                        monthly CPP (Canada Pension Plan) payout as displayed on
                        their MyCRA (Canada Revenue Agency) account, we provide
                        alternative information to assist them. The feature
                        includes the average CPP payout for the current year and
                        the maximum payout as a reference point. This allows users
                        to estimate their CPP amount until they have the official
                        information.`,
                      searchTerm
                    )}
                  </li>
                  <li>
                    {highlightText(
                      `
                        The CPP amount input will grow in accordance with the CPI
                        (Consumer Price Index) to reflect inflation and ensure
                        accuracy over time.`,
                      searchTerm
                    )}
                  </li>
                  <li>
                    {highlightText(
                      `
                        Users can update their CPP field whenever they obtain
                        their official CPP payout details and rerun their analysis`,
                      searchTerm
                    )}
                  </li>
                </ol>

                <div
                  ref={sectionRefs.cpi}
                  id="cpi"
                  className="nunito-bold-blue-18px"
                >
                  {highlightText("Consumer Price Index (CPI)", searchTerm)}
                </div>
                <p>
                  <ol className="assumptions-list">
                    <li>
                      {highlightText(
                        `
                          In our financial planning software, we consider the
                          impact of inflation on your financial projections using
                          the Consumer Price Index (CPI). By default, we use the
                          historical average CPI as the basis for:`,
                        searchTerm
                      )}
                    </li>
                  </ol>
                  <ul className="assumptions-list">
                    <li> {highlightText(`Salary`, searchTerm)}</li>
                    <li> {highlightText(`Expenses`, searchTerm)}</li>
                    <li>
                      {" "}
                      {highlightText(`Canadian Pension Plan (CPP)`, searchTerm)}
                    </li>
                    <li>
                      {" "}
                      {highlightText(
                        `Defined Benefit Pension Plan`,
                        searchTerm
                      )}
                    </li>
                    <li>
                      {" "}
                      {highlightText(`Old Age Security (OAS)`, searchTerm)}
                    </li>
                    <li> {highlightText(`Tax brackets`, searchTerm)}</li>
                    <li>
                      {" "}
                      {highlightText(`Real Estate portfolio`, searchTerm)}
                    </li>
                  </ul>

                  <p style={{ marginTop: "10px" }} className="assumptions-list">
                    {highlightText(
                      `
                        The current historic CPI is: 2.2%`,
                      searchTerm
                    )}
                  </p>

                  <p style={{ marginTop: "10px" }}  className="assumptions-list">
                    {highlightText(
                      `
                        However, we understand that individual circumstances vary.
                        That's why we allow you to customize the CPI value
                        according to your preferences. You have the flexibility to
                        input a custom CPI / Growth value that aligns with your
                        own inflation expectations, for the below inputs:`,
                      searchTerm
                    )}
                  </p>
                  <ul className="assumptions-list">
                    <li> {highlightText(`Salary`, searchTerm)}</li>
                    <li> {highlightText(`Expenses`, searchTerm)}</li>

                    <li>
                      {" "}
                      {highlightText(
                        `Defined Benefit Pension Plan`,
                        searchTerm
                      )}
                    </li>

                    <li>
                      {" "}
                      {highlightText(`Real Estate portfolio`, searchTerm)}
                    </li>
                  </ul>
                </p>

                <div
                  ref={sectionRefs.expenses}
                  id="expenses"
                  className="nunito-bold-blue-18px"
                >
                  {highlightText("Expenses", searchTerm)}
                </div>
                <ol className="assumptions-list"> 
                  <li>
                    {highlightText(
                      `
                        Gives users two options to grow their expenses throughout
                        the length of the analysis:`,
                      searchTerm
                    )}
                    <ul>
                      <li>
                        {" "}
                        {highlightText(`Grow expenses by CPI.`, searchTerm)}
                      </li>
                      <ul>
                        <li>
                          {highlightText(
                            `Expenses will grow at default CPI.`,
                            searchTerm
                          )}
                        </li>
                        <li>
                          {highlightText(
                            `Users can manually select their own unique growth rate`,
                            searchTerm
                          )}
                        </li>
                      </ul>
                      <li>
                        {highlightText(
                          `Grow expenses by Consumer Price Index (CEI) model`,
                          searchTerm
                        )}
                      </li>
                      <ul>
                        <li>
                          {highlightText(
                            `
                              The Consumer Expense Index (CEI) is an algorithm
                              that adjusts a user's annual expenses in relation to
                              their income?. Unlike the CPI, which simply grows
                              expenses each year, the CEI takes into account the
                              realistic understanding that as a user's income
                              increases, their expenses tend to rise as well.
                              However, in retirement and as one gets older,
                              expenses may start to stabilize.`,
                            searchTerm
                          )}
                        </li>
                        <li>
                          {highlightText(
                            `
                              While the CEI incorporates the growth rate from the
                              CPI in its calculation, it also utilizes additional
                              calculations to provide a more precise analysis of a
                              user's future expenses based on their current annual
                              expenses. By considering the relationship between
                              income and expenses, the CEI offers a more accurate
                              projection of how a user's spending patterns may
                              evolve over time.`,
                            searchTerm
                          )}
                        </li>
                      </ul>
                    </ul>
                  </li>
                  <li>
                    {highlightText(
                      `
                        Additionally, the expense page provides a table where
                        users can manually adjust their expenses for individual
                        years.`,
                      searchTerm
                    )}
                  </li>
                </ol>

                <div
                  ref={sectionRefs.income}
                  id="income"
                  className="nunito-bold-blue-18px"
                >
                  {highlightText("Income", searchTerm)}
                </div>
                <ol className="assumptions-list">
                  <li>
                    {highlightText(
                      `Income growth rate set to default CPI.`,
                      searchTerm
                    )}
                  </li>
                  <li>
                    {highlightText(
                      `Allows users to select their own unique growth rate on the
                        income page`,
                      searchTerm
                    )}
                  </li>
                  <li>
                    {highlightText(
                      `Additionally, provides a table where users can manually
                        adjust their salary for individual years.`,
                      searchTerm
                    )}
                  </li>
                </ol>

                <div
                  ref={sectionRefs.investmentGrowthTiming}
                  id="investmentGrowthTiming"
                  className="nunito-bold-blue-18px"
                >
                  {highlightText("Investment Growth Timing", searchTerm)}
                </div>
                <ol className="assumptions-list">
                  <li>
                    {highlightText(
                      `The growth of all investment accounts, including Tax-Free
                        Savings Account (TFSA), Registered Retirement Savings Plan
                        (RRSP), and Non-Registered account, is determined by their
                        respective values at the beginning of the year.`,
                      searchTerm
                    )}
                  </li>
                </ol>

                <div
                  ref={sectionRefs.nonRegistered}
                  id="nonRegistered"
                  className="nunito-bold-blue-18px"
                >
                  {highlightText("Non-registered", searchTerm)}
                </div>
                <ol className="assumptions-list">
                  <li> {highlightText(`TO-DO`, searchTerm)}</li>
                </ol>

                <div
                  ref={sectionRefs.oas}
                  id="oas"
                  className="nunito-bold-blue-18px"
                >
                  {highlightText("Old Age Security (OAS)", searchTerm)}
                </div>
                <ol className="assumptions-list">
                  <li>
                    {highlightText(
                      `
                        For users who do not have immediate access to their
                        monthly CPP (Canada Pension Plan) payout as displayed on
                        their MyCRA (Canada Revenue Agency) account, we provide
                        alternative information to assist them. The feature
                        includes the average CPP payout for the current year and
                        the maximum payout as a reference point. This allows users
                        to estimate their CPP amount until they have the official
                        information.`,
                      searchTerm
                    )}
                  </li>
                  <li>
                    {highlightText(
                      `The CPP amount input will grow in accordance with the CPI
                        (Consumer Price Index) to reflect inflation and ensure
                        accuracy over time.`,
                      searchTerm
                    )}
                  </li>
                  <li>
                    {highlightText(
                      `Users can update their CPP field whenever they obtain
                        their official CPP payout details and rerun their analysis`,
                      searchTerm
                    )}
                  </li>
                </ol>

                <div
                  ref={sectionRefs.pensionPlans}
                  id="pensionPlans"
                  className="nunito-bold-blue-18px"
                >
                  {highlightText("Pension Plans", searchTerm)}
                </div>
                <ol className="assumptions-list">
                  <li>
                    {highlightText(
                      `A defined benefit pension plan automatically grows by the
                        default Consumer Price Index (CPI) annually. Additionally,
                        it allows users to customize their own unique growth rate
                        if desired.`,
                      searchTerm
                    )}
                  </li>
                  <li>
                    {highlightText(
                      `All other pension types grow based on the expected annual
                        rate of return, as input by the user.`,
                      searchTerm
                    )}
                  </li>
                </ol>

                <div
                  ref={sectionRefs.realEstatePortfolio}
                  id="realEstatePortfolio"
                  className="nunito-bold-blue-18px"
                >
                  {highlightText("Real Estate Portfolio", searchTerm)}
                </div>
                <ol className="assumptions-list">
                  <li>
                    {highlightText(
                      `All types of real estate investments, including primary
                        residences, recreational properties, and income
                        properties, will grow in value each year based on the
                        selected Consumer Price Index (CPI).`,
                      searchTerm
                    )}
                  </li>
                  <li>
                    {highlightText(
                      `Users are provided with the flexibility to customize
                        growth rates for each recreational property and individual
                        income property, or alternatively, set a growth rate for
                        the entire income property portfolio.`,
                      searchTerm
                    )}
                  </li>
                </ol>

                <div
                  ref={sectionRefs.rrif}
                  id="rrif"
                  className="nunito-bold-blue-18px"
                >
                  {highlightText(
                    "Registered Retirement Income Fund (RRIF)",
                    searchTerm
                  )}
                </div>
                <ol className="assumptions-list">
                  <li>
                    {highlightText(
                      `All calculations and withdrawals are determined based on
                        the minimum withdrawal percentage set by the Canada
                        Revenue Agency for each corresponding year. The analysis
                        may suggest withdrawing more than the minimum requirement;
                        however, it ensures that the minimum withdrawal is always
                        represented and presented in the analysis.`,
                      searchTerm
                    )}
                  </li>
                  <li>
                    {highlightText(
                      `Minimum withdrawal calculation source: Link`,
                      searchTerm
                    )}
                  </li>
                  <li>
                    {highlightText(
                      `https://www.canada.ca/en/revenue-agency/services/tax/businesses/topics/completing-slips-summaries/t4rsp-t4rif-information-returns/payments/chart-prescribed-factors.html`,
                      searchTerm
                    )}
                  </li>
                </ol>

                <div
                  ref={sectionRefs.runAge}
                  id="runAge"
                  className="nunito-bold-blue-18px"
                >
                  {highlightText("Run Age", searchTerm)}
                </div>
                <ol className="assumptions-list">
                  <li>
                    {highlightText(
                      `By default, the analysis runs until the age of 85.
                        However, users have the flexibility to manually update the
                        age by selecting from the dropdown menu. It's important to
                        note that the analysis will specifically run until the age
                        of 85 for spouse one.`,
                      searchTerm
                    )}
                  </li>
                </ol>

                <div
                  ref={sectionRefs.tax}
                  id="tax"
                  className="nunito-bold-blue-18px"
                >
                  {highlightText("Tax", searchTerm)}
                </div>
                <ol className="assumptions-list">
                  <li>
                    {highlightText(
                      `The tax brackets utilized for federal and provincial
                        taxation are initially determined based on the latest
                        available data from 2023. However, to account for
                        inflation and maintain the relevance of the brackets over
                        time, the bracket amounts will be adjusted annually using
                        the Consumer Price Index (CPI). This adjustment ensures
                        that the tax brackets keep pace with inflation, reflecting
                        the changing economic landscape during the analysis
                        period.`,
                      searchTerm
                    )}
                  </li>
                </ol>

                <div
                  ref={sectionRefs.tfsa}
                  id="tfsa"
                  className="nunito-bold-blue-18px"
                >
                  {highlightText("Tax Free Savings Account (TFSA)", searchTerm)}
                </div>
                <ol className="assumptions-list">
                  <li>
                    {highlightText(
                      `The Tax-Free Savings Account (TFSA) contribution limit is
                        set at $6,500 annually, and this limit will increase each
                        year in line with the Consumer Price Index (CPI). By
                        growing at the CPI annually, we ensure that your
                        contribution limit keeps pace with inflation, allowing you
                        to maximize the benefits of your TFSA over time.`,
                      searchTerm
                    )}
                  </li>
                  <li>
                    {highlightText(
                      `TFSA contribution room can be stacked year over year,
                        i.e., $6,500 room in 2023 not used can be contributed to
                        in later years: You have the flexibility to carry forward
                        any unused TFSA contribution room from one year to the
                        next. For example, if you have a contribution limit of
                        $6,500 in 2023 and you don't fully utilize it, the
                        remaining room can be added to your contribution limit for
                        future years. This stacking feature enables you to make
                        larger contributions in subsequent years, taking full
                        advantage of the TFSA's tax-free growth potential.`,
                      searchTerm
                    )}
                  </li>
                  <li>
                    {highlightText(
                      `If a new user does not already have a Tax-Free Savings
                        Account (TFSA), the analysis may recommend creating one.
                        The analysis will display deposits and withdrawals over
                        the course of the analysis, with a default growth rate set
                        at 6%.`,
                      searchTerm
                    )}
                  </li>
                </ol>

                <div
                  ref={sectionRefs.rrsp}
                  id="rrsp"
                  className="nunito-bold-blue-18px"
                >
                  {highlightText(
                    "Registered Retirement Savings Plan (RRSP)",
                    searchTerm
                  )}
                </div>
                <ol className="assumptions-list">
                  <li>
                    {highlightText(
                      `RRSP annual contribution limit is $30,780 or 18% of your
                        salary, whichever is less (2023): For your Registered
                        Retirement Savings Plan (RRSP), the annual contribution
                        limit is determined by two factors: a maximum limit of
                        $30,780 or 18% of your salary, whichever is less. This
                        ensures that your contributions are within the prescribed
                        limits set by the government. By adhering to this rule,
                        you can maximize your RRSP contributions while remaining
                        compliant with the regulations.`,
                      searchTerm
                    )}
                  </li>
                  <li>
                    {highlightText(
                      `If a new user does not already have a Registered
                        Retirement Savings Plan (RRSP), the analysis may recommend
                        creating one. The analysis will display deposits and
                        withdrawals over the course of the analysis, with a
                        default growth rate set at 6%.`,
                      searchTerm
                    )}
                  </li>
                </ol>
              </Box>
            </>
          }
        />
      </VStack>
    </Flex>
  );
}

export default Assumptions;
