import React, { useState, useContext } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import Modal from "react-modal";
import { StripeContext } from "../StripeContext";
import axios from "axios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCheckCircle,
  faCircleXmark,
  faLoveseat,
} from "@fortawesome/pro-regular-svg-icons";
import CompassSpinner from "../CompassSpinner";
import "./StripeUpgradeModal.css";

const StripeUpgradeModal = ({ isOpen, closeModal }) => {
  const { user, getAccessTokenSilently } = useAuth0();
  const [loading, setLoading] = useState(false);

  const { stripePlan, setStripePlan } = useContext(StripeContext);

  const onSubmit = (action) => {
    setLoading(true);
    const axiosInstance = axios.create();
    async function getToken() {
      // Replace this with your Auth0 token retrieval logic
      const token = await getAccessTokenSilently({
        audience:
          "https://os2lsiwff43wfpb5ggyawt6qri0mgcro.lambda-url.us-east-2.on.aws/",
        scope: "write:optimization",
      });
      return token;
    }
    // Add a request interceptor
    axiosInstance.interceptors.request.use(
      async (config) => {
        const token = await getToken();
        config.headers.Authorization = `Bearer ${token}`;
        return config;
      },
      (error) => {
        // Do something with request error
        return Promise.reject(error);
      }
    );
    const auth0_params = {
      user_id: user.sub,
      action: "Get_Stripe_ID",
    };
    try {
      axiosInstance
        .post(process.env.REACT_APP_AUTH0_MANAGEMENT_API, auth0_params)
        .then((res) => {
          const params = {
            stripe_id: res.data,
            action: action,
          };
          axios
            .post(process.env.REACT_APP_STRIPE_API_KEY, params)
            .then((res) => {
              if (res.data.message.url) {
                window.location.href = res.data.message.url;
              }
            })
            .catch((error) => {
              console.log(error);
            });
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {
      console.error("Error redirecting to checkout:", error);
    }
  };

  const onSubmitFreeTrial = (action) => {
    setLoading(true);
    const axiosInstance = axios.create();
    async function getToken() {
      // Replace this with your Auth0 token retrieval logic
      const token = await getAccessTokenSilently({
        audience:
          "https://os2lsiwff43wfpb5ggyawt6qri0mgcro.lambda-url.us-east-2.on.aws/",
        scope: "write:optimization",
      });
      return token;
    }
    // Add a request interceptor
    axiosInstance.interceptors.request.use(
      async (config) => {
        const token = await getToken();
        config.headers.Authorization = `Bearer ${token}`;
        return config;
      },
      (error) => {
        // Do something with request error
        return Promise.reject(error);
      }
    );
    const auth0_params = {
      user_id: user.sub,
      action: "Get_Stripe_ID",
    };
    try {
      axiosInstance
        .post(process.env.REACT_APP_AUTH0_MANAGEMENT_API, auth0_params)
        .then((res) => {
          const params = {
            stripe_id: res.data,
            action: action,
          };
          axios
            .post(process.env.REACT_APP_STRIPE_API_KEY, params)
            .then((res) => {
              if (res.data.url) {
                window.location.href = res.data.url;
              }
              if (res.data.message === "Upgrade Successful.") {
                window.location.reload();
              }
            })
            .catch((error) => {
              console.log(error);
            });
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {
      console.error("Error redirecting to checkout:", error);
    }
  };

  const modalStyles = {
    overlay: {
      position: "fixed",
      top: 0,
      left: 0,
      width: "100vw",
      height: "100vh",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      backgroundColor: "rgba(0, 0, 0, 0.5)",
      zIndex: "10000",
    },
    content: {
      position: "relative", // Add this to position the close icon absolutely within
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      inset: '0',
      justifyContent: "center",
      padding: "2rem",
      backgroundColor: "#fff",
      borderRadius: "8px",
      boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
      maxWidth: "400px", // Adjusted for better mobile responsiveness
      maxHeight: "90vh", // Ensures the modal doesn't exceed viewport height
      overflowY: "auto", // Adds scroll for overflow content
      margin: "1rem",
    },
  };

  const styles = {
    closeIcon: {
      position: "absolute", // Position the icon absolutely within the modalContent
      top: "10px", // Adjust top and right as needed
      right: "10px",
      cursor: "pointer",
    },
    header: {
      marginTop: "20px", // Adjust if needed to avoid overlap with the close icon
      marginBottom: "16px",
      textAlign: "center",
      fontSize: "24px",
      fontWeight: "bold",
    },
    bodyText: {
      textAlign: "center",
      marginBottom: "24px",
      fontSize: "16px",
      color: "#666",
    },
    button: {
      backgroundColor: "#00A2D4",
      color: "#FFFFFF",
      fontSize: "16px",
      padding: "10px 20px",
      border: "none",
      borderRadius: "4px",
      cursor: "pointer",
      transition: "background-color 0.3s",
    },
    button2: {
      backgroundColor: "#00A2D4",
      color: "#FFFFFF",
      fontSize: "16px",
      margin: "1rem",
      marginTop: "0",
      padding: "10px 20px",
      border: "none",
      borderRadius: "4px",
      cursor: "pointer",
      transition: "background-color 0.3s",
    },
  };


  const calculateDaysLeft = (trialEndTimestamp) => {
    const currentDate = new Date();
    const trialEndDate = new Date(trialEndTimestamp * 1000); // Convert to milliseconds
    const differenceInTime = trialEndDate.getTime() - currentDate.getTime();
    const differenceInDays = Math.ceil(differenceInTime / (1000 * 3600 * 24));
    return differenceInDays;
  };

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={closeModal}
      contentLabel="Help Video"
      style={modalStyles}
    >
      {loading && (
        <CompassSpinner
          loading={loading}
          className="stripe-compass-spinner"
          small={true}
        />
      )}
      <FontAwesomeIcon
        icon={faCircleXmark}
        style={styles.closeIcon}
        onClick={closeModal}
        size="2x"
      />
      {!loading &&
        (!stripePlan?.trial_end ||
          calculateDaysLeft(stripePlan?.trial_end) < 1) && (
          <>
            <h2 style={styles.header}>Update Your Optiml Subscription</h2>
            <p style={styles.bodyText}>
              Upgrading your Optiml subscription will allow you to gain access
              to some of our unique and more advanced services. Click the button
              below to manage your subscription via Stripe.
            </p>
            <button
              style={styles.button}
              onClick={() => onSubmit("Manage_Subscription")}
            >
              Manage Subscription
            </button>
          </>
        )}
      {!loading && calculateDaysLeft(stripePlan?.trial_end) >= 1 && (
        <>
          <h2 style={styles.header}>Update Your Optiml Subscription</h2>
          <p style={styles.bodyText}>
            By unlocking essentials before finishing the free trial you will be
            billed immediately. This will give you full access to all of your
            data and features included in the Essentials package.
          </p>
          <button
            style={styles.button2}
            onClick={() => onSubmitFreeTrial("Upgrade_Trial")}
          >
            Unlock Essentials
          </button>
          <p style={styles.bodyText}>
            Upgrading your Optiml subscription will allow you to gain access to
            some of our unique and more advanced services. Click the button
            below to manage your subscription via Stripe.
          </p>
          <button
            style={styles.button2}
            onClick={() => onSubmit("Manage_Subscription")}
          >
            Manage Subscription
          </button>
        </>
      )}
    </Modal>
  );
};

export default StripeUpgradeModal;
