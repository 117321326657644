import React, { useEffect, useState } from "react";
import { useStore } from "../../store";
import Link from "../Link";
import "./SidebarPageButtons.css";

const SidebarPageButtons = ({ items, currentPage }) => {
  const userOptiData = useStore((store) => store.userOptiData);
  const [newUser, setNewUser] = useState();

  useEffect(() => {
    if (userOptiData?.primaryUserData?.expenses?.length > 1) {
      setNewUser(false);
    } else if (userOptiData?.primaryUserData?.expenses?.length === undefined) {
      setNewUser(true);
    }
  }, [userOptiData]);

  return (
    <>
      {!newUser && (
        <div className="sidebar-page-options-container mobile-hidden">
          {items.map((item, index) => (
            <div key={index} className="main-item">
              <Link
                href={item.path}
                className={`main-link ${
                  currentPage === item.name ? "active" : ""
                }`}
              >
                {item.icon && <span className="icon">{item.icon}</span>}
                {item.name}
              </Link>
              {item.subItems && (
                <div className="sub-items">
                  {item.subItems.map((subItem, subIndex) => (
                    <div key={subIndex} className="sub-link-container">
                      <Link
                        href={subItem.path}
                        className={`sub-link ${
                          currentPage === subItem.name ? "active" : ""
                        }`}
                      >
                        <span
                          className={`circle ${
                            currentPage === subItem.name ? "filled" : ""
                          }`}
                        ></span>
                        {subItem.name}
                      </Link>
                    </div>
                  ))}
                </div>
              )}
            </div>
          ))}
        </div>
      )}
      {newUser && (
        <div className="sidebar-page-options-container mobile-hidden">
          {items.map((item, index) => (
            <div key={index} className="main-item">
              {!item.locked ? (
                <Link
                  href={item.path}
                  className={`main-link ${
                    currentPage === item.name ? "active" : ""
                  }`}
                >
                  {item.icon && <span className="icon">{item.icon}</span>}
                  {item.name}
                </Link>
              ) : (
                <span
                  className={`main-link locked ${
                    currentPage === item.name ? "active" : ""
                  }`}
                >
                  {item.icon && <span className="icon">{item.icon}</span>}
                  {item.name}
                </span>
              )}
              {item.subItems && (
                <div className="sub-items">
                  {item.subItems.map((subItem, subIndex) => (
                    <div key={subIndex} className="sub-link-container">
                      {!subItem.locked ? (
                        <Link
                          href={subItem.path}
                          className={`sub-link ${
                            currentPage === subItem.name ? "active" : ""
                          }`}
                        >
                          <span
                            className={`circle ${
                              currentPage === subItem.name ? "filled" : ""
                            }`}
                          ></span>
                          {subItem.name}
                        </Link>
                      ) : (
                        <span
                          className={`sub-link locked ${
                            currentPage === subItem.name ? "active" : ""
                          }`}
                        >
                          <span
                            className={`circle ${
                              currentPage === subItem.name ? "filled" : ""
                            }`}
                          ></span>
                          {subItem.name}
                        </span>
                      )}
                    </div>
                  ))}
                </div>
              )}
            </div>
          ))}
        </div>
      )}
    </>
  );
};

export default SidebarPageButtons;

