import "bootstrap/dist/css/bootstrap.min.css";
import React, { useState } from "react";
import { useStore } from "../../store";
// import Button from 'react-bootstrap/Button';
import { useAuth0 } from "@auth0/auth0-react";
import { Text } from "@chakra-ui/react";
import axios from "axios";
import DropDownButton from "../InputFields/dropDownButton";

const TimeoutSection = () => {
  const { user, getAccessTokenSilently } = useAuth0();
  const logoutMultiplier = useStore((store) => store.logoutMultiplier);
  const setLogoutMultiplier = useStore((store) => store.setLogoutMultiplier);
  const [timeout, setTimeout] = useState(logoutMultiplier);

  const axiosInstance = axios.create();
  async function getToken() {
    // Replace this with your Auth0 token retrieval logic
    const token = await getAccessTokenSilently({
      audience:
        "https://os2lsiwff43wfpb5ggyawt6qri0mgcro.lambda-url.us-east-2.on.aws/",
      scope: "write:optimization",
    });
    return token;
  }

  // Add a request interceptor
  axiosInstance.interceptors.request.use(
    async (config) => {
      const token = await getToken();
      config.headers.Authorization = `Bearer ${token}`;
      return config;
    },
    (error) => {
      // Do something with request error
      return Promise.reject(error);
    }
  );

  const handleChangeTimeout = (newTimeout) => {
    console.log(newTimeout);
    
    const logoutMultiplier = (action) =>
      `${
        process.env.REACT_APP_NODE_API_GATEWAY
      }/CompassLambdaApi/SETTINGS/${action}?UserID=${user.sub.replace(
        "|",
        "%7c"
      )}&autoLogout=${newTimeout}`;
    axiosInstance
      .get(logoutMultiplier("add"))
      .then((res) => {
        //Unlock Income Sidebar:
        setTimeout(newTimeout);
        setLogoutMultiplier(newTimeout);
        console.log(res);
        if (res.data.code === "ER_DUP_ENTRY") {
          axiosInstance.get(logoutMultiplier("update")).then((res) => {
            console.log(res);
            setTimeout(newTimeout);
            setLogoutMultiplier(newTimeout);
          });
        }
      })
      .catch((error) => {
        console.log(error);
      });

    //Update timeout in App.js
  };

  return (
    <>
      <Text
        alignSelf={"stretch"}
        color={"#00A2D4"}
        fontFamily={"Nunito"}
        fontSize={"16px"}
        fontStyle={"normal"}
        fontWeight={"700"}
        lineHeight={"normal"}
      >
        Auto-logout
      </Text>
      <>
        {
          <>
            <Text
              alignSelf={"stretch"}
              color={"#004069"}
              fontFamily={"Nunito"}
              fontSize={"14px"}
              fontStyle={"normal"}
              fontWeight={"400"}
              lineHeight={"normal"}
            >
              Auto-logout is a security feature that automatically signs you out
              of your account after a period of inactivity to help keep your
              information safe; you can adjust the length of this period using
              the dropdown below.
            </Text>
            <DropDownButton
              placeholder={"15 minutes"}
              value={timeout}
              onChange={(key) => {
                handleChangeTimeout(key);
              }}
              dropDownItems={[
                { key: 1, value: "15 minutes" },
                { key: 2, value: "30 minutes" },
                { key: 4, value: "60 minutes" },
              ]}
            />
          </>
        }
      </>
    </>
  );
};

export default TimeoutSection;
