import React from "react";
import {
  Box,
  Flex,
  Text,
  VStack,
  HStack,
  IconButton,
  useColorModeValue,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverArrow,
  PopoverBody,
  Button,
  useMediaQuery,
} from "@chakra-ui/react";
import { EditIcon, DeleteIcon } from "@chakra-ui/icons";
import Link from "../Link";

const ThreeDotsIcon = () => (
  <Box display="flex" alignItems="center" justifyContent="center">
    <Box w={1} h={1} bg="blue.500" borderRadius="50%" mr={1}></Box>
    <Box w={1} h={1} bg="blue.500" borderRadius="50%" mr={1}></Box>
    <Box w={1} h={1} bg="blue.500" borderRadius="50%"></Box>
  </Box>
);

const TableComponent = ({ columns, data, editField, onDeleteRow, rawData }) => {
  const bgColor = useColorModeValue("white", "gray.800");

  const formatCurrency = (value) => {
    if (!value) {
      return "$0";
    }
    return new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD",
      maximumFractionDigits: 0,
    }).format(value);
  };

  const textStyle = {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  };

  const [isLargerThan500] = useMediaQuery("(min-width: 500px)");
  const isSmallScreen = !isLargerThan500;
  const [isLargerThan1200] = useMediaQuery(
    "(min-width: 500px) and (max-width: 1250px)"
  );

  return (
    <Box
      bg={bgColor}
      p={isSmallScreen ? "10px" :4}
      borderRadius="md"
      boxShadow="md"
      width="inherit"
      // maxW="1200px"
      mx="auto"
      border="1px solid #00A2D4"
      
    >
      {columns.length === 2 && (
        <>
          <Flex
            mb={4}
            borderBottom="2px"
            borderColor="gray.200"
            pb={2}
            justifyContent={"space-between"}
          >
            {columns.map((column, index) => (
              <Text
                key={index}
                fontSize={isSmallScreen ? "12px" : "l"}
                fontWeight="bold"
                textAlign={index === 1 ? "right" : "left"}
                width={index === 0 ? "60%" : "38%"}
                mr={index === 1 && "45px"}
              >
                {column.header}
              </Text>
            ))}
          </Flex>
          <VStack spacing={4}>
            {data.map((item, index) => (
              <Flex
                key={index}
                w="100%"
                bg={useColorModeValue("gray.50", "gray.700")}
                p={3}
                borderRadius="md"
                boxShadow="sm"
              >
                {columns.map((column, colIndex) => (
                  <HStack
                    key={colIndex}
                    w={colIndex === 0 ? "70%" : "30%"}
                    justify={colIndex === 1 ? "flex-end" : "flex-start"}
                    flexWrap={"wrap"}
                  >
                    {colIndex !== columns?.length - 1 && (
                      <Text
                        fontSize={isSmallScreen ? "10px" : "l"}
                        flexWrap={"wrap"}
                        textAlign="left"
                      >
                        {item[column.field]}
                      </Text>
                    )}
                    {colIndex === columns?.length - 1 && (
                      <HStack justifyContent={"end"} flexWrap={"wrap"}>
                        <Text
                          fontSize={isSmallScreen ? "10px" : "l"}
                          textAlign="right"
                        >
                          {formatCurrency(item[column.field])}
                        </Text>
                        <Popover>
                          <PopoverTrigger>
                            <IconButton
                              aria-label="Options"
                              icon={<ThreeDotsIcon />}
                              size="sm"
                              variant="ghost"
                            />
                          </PopoverTrigger>
                          <PopoverContent
                            width="100px"
                            _focus={{ boxShadow: "none" }}
                          >
                            <PopoverArrow />
                            <PopoverBody>
                              {editField && (
                                <Button
                                  leftIcon={<EditIcon />}
                                  colorScheme="blue"
                                  variant="ghost"
                                  width="full"
                                  justifyContent="flex-start"
                                  size="sm"
                                  mb={2}
                                  onClick={() => editField(index, rawData)}
                                >
                                  Edit
                                </Button>
                              )}
                              {!editField && (
                                <Button
                                  leftIcon={<EditIcon />}
                                  colorScheme="blue"
                                  variant="ghost"
                                  width="full"
                                  justifyContent="flex-start"
                                  size="sm"
                                  mb={2}
                                >
                                  <Link href={item.link}>Edit</Link>
                                </Button>
                              )}
                              <Button
                                leftIcon={<DeleteIcon />}
                                colorScheme="red"
                                variant="ghost"
                                width="full"
                                justifyContent="flex-start"
                                size="sm"
                                onClick={() => onDeleteRow(index, rawData)}
                              >
                                Delete
                              </Button>
                            </PopoverBody>
                          </PopoverContent>
                        </Popover>
                      </HStack>
                    )}
                  </HStack>
                ))}
              </Flex>
            ))}
          </VStack>
        </>
      )}

      {columns.length > 2 && (
        <>
          <Flex
            mb={4}
            borderBottom="2px"
            borderColor="gray.200"
            pb={2}
            justifyContent={"space-between"}
          >
            {columns.map((column, index) => (
              <Text
                key={index}
                fontSize={isSmallScreen ? "10px" : "l"}
                fontWeight="bold"
                textAlign={index === 0 ? "left" : "right"}
                width={index === 0 ? "40%" : index === 1 ? "22%" : "38%"}
                paddingRight={index === columns?.length - 1 ?  "45px" : "0"}
                
              >
                {column.header}
              </Text>
            ))}
          </Flex>
          <VStack spacing={4}>
            {data.map((item, index) => (
              <Flex
                key={index}
                w="100%"
                bg={useColorModeValue("gray.50", "gray.700")}
                p={isSmallScreen ? "8px":3}
                borderRadius="md"
                boxShadow="sm"
              >
                {columns.map((column, colIndex) => (
                  <HStack
                    key={colIndex}
                    w={colIndex === 0 ? "40%" : colIndex === 1 ? "22%" : "40%"}
                    justify={colIndex === 0 ? "left" : "right"}
                  >
                    {colIndex !== columns?.length - 1 && (
                      <Text style={textStyle} textAlign="left" fontSize={isSmallScreen && "10px"} overflow={colIndex === 0 && "hidden"}>{item[column.field]}</Text>
                    )}
                    {colIndex === columns?.length - 1 && (
                      <HStack justify={"flex-end"} w={"100%"}>
                        <Text style={textStyle} textAlign="right" fontSize={isSmallScreen && "10px"}>
                          {formatCurrency(item[column.field])}
                        </Text>
                        <Popover>
                          <PopoverTrigger>
                            <IconButton
                              aria-label="Options"
                              icon={<ThreeDotsIcon />}
                              size="sm"
                              variant="ghost"
                            />
                          </PopoverTrigger>
                          <PopoverContent
                            width="100px"
                            _focus={{ boxShadow: "none" }}
                          >
                            <PopoverArrow />
                            <PopoverBody>
                              {editField && (
                                <Button
                                  leftIcon={<EditIcon />}
                                  colorScheme="blue"
                                  variant="ghost"
                                  width="full"
                                  justifyContent="flex-start"
                                  size="sm"
                                  mb={2}
                                  onClick={() => editField(index, rawData)}
                                >
                                  Edit
                                </Button>
                              )}
                              {!editField && (
                                <Button
                                  leftIcon={<EditIcon />}
                                  colorScheme="blue"
                                  variant="ghost"
                                  width="full"
                                  justifyContent="flex-start"
                                  size="sm"
                                  mb={2}
                                >
                                  <Link href={item.link}>Edit</Link>
                                </Button>
                              )}
                              <Button
                                leftIcon={<DeleteIcon />}
                                colorScheme="red"
                                variant="ghost"
                                width="full"
                                justifyContent="flex-start"
                                size="sm"
                                onClick={() => onDeleteRow(index, rawData)}
                              >
                                Delete
                              </Button>
                            </PopoverBody>
                          </PopoverContent>
                        </Popover>
                      </HStack>
                    )}
                  </HStack>
                ))}
              </Flex>
            ))}
          </VStack>
        </>
      )}
    </Box>
  );
};

export default TableComponent;
