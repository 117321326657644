import { Box, Flex, VStack, Switch, Text } from "@chakra-ui/react";
import React, {
  forwardRef,
  useContext,
  useEffect,
  useImperativeHandle,
  useState,
} from "react";
import axios from "axios";
import { useAuth0 } from "@auth0/auth0-react";
import { useStore } from "../../store";
import GlobalBodyPage from "../GlobalContainer/BodyPage";

const Notifications = forwardRef((props, ref) => {
  const { setChangedData } = props;
  useImperativeHandle(ref, () => ({
    submitHandler,
    checkMandatoryFields,
  }));

  const checkMandatoryFields = () => {
    return true;
  }
  const { getAccessTokenSilently, user } = useAuth0();
  const axiosInstance = axios.create();
  async function getToken() {
    // Replace this with your Auth0 token retrieval logic
    const token = await getAccessTokenSilently({
      audience:
        "https://os2lsiwff43wfpb5ggyawt6qri0mgcro.lambda-url.us-east-2.on.aws/",
      scope: "write:optimization",
    });
    return token;
  }

  // Add a request interceptor
  axiosInstance.interceptors.request.use(
    async (config) => {
      const token = await getToken();
      config.headers.Authorization = `Bearer ${token}`;
      return config;
    },
    (error) => {
      // Do something with request error
      return Promise.reject(error);
    }
  );
  const setUserNotificationInfo = useStore(
    (store) => store.setUserNotificationInfo
  );
  const userNotificationInfo = useStore((store) => store.userNotificationInfo);

  
  const [emailNotifications, setEmailNotifications] = useState([
    { id: "rrspContDeadline", label: "RRSP contribution deadline", isChecked: 1 },
    { id: "tfsaContRoom", label: "TFSA contribution room changes", isChecked: 1 },
    { id: "rrspContRoom", label: "RRSP contribution room changes", isChecked: 1 },
    { id: "taxDeadline", label: "Tax filing deadline reminder", isChecked: 1 },
  ]);
  const [regulatoryUpdates, setRegulatoryUpdates] = useState([
    { id: "taxBracket", label: "Changes to tax brackets", isChecked: 1 },
    { id: "estateRegulations", label: "Changes in estate planning regulations", isChecked: 1 },
  ]);
  const [accountUpdates, setAccountUpdates] = useState([
    { id: "activityReminder", label: "Reminder to update your plan after 2 months of inactivity", isChecked: 1 },
  ]);

  useEffect(() => {
    if (userNotificationInfo) {
      setEmailNotifications((current) =>
        current.map(notification => ({
          ...notification,
          isChecked: userNotificationInfo?.[notification.id] !== undefined ? parseInt(userNotificationInfo?.[notification.id], 10) : 1
        }))
      );

      setRegulatoryUpdates((current) =>
        current.map(notification => ({
          ...notification,
          isChecked: userNotificationInfo[notification.id] !== undefined ? parseInt(userNotificationInfo?.[notification.id], 10) : 1
        }))
      );

      setAccountUpdates((current) =>
        current.map(notification => ({
          ...notification,
          isChecked: userNotificationInfo[notification.id] !== undefined ? parseInt(userNotificationInfo?.[notification.id], 10) : 1
        }))
      );
    }
  }, []); // Dependency to react on userNotificationInfo updates

  // Function to handle toggle
  const handleToggle = (id, setFunc) => {
    setChangedData(true)
    setFunc((prev) =>
      prev.map((item) =>
        item.id === id ? { ...item, isChecked: item.isChecked === 1 ? 0 : 1 } : item
      )
    );
  };

  // Mapping toggle handlers
  const emailNotificationItems = emailNotifications.map((item) => ({
    ...item,
    onToggle: () => handleToggle(item.id, setEmailNotifications),
  }));

  const accountUpdatesItems = accountUpdates.map((item) => ({
    ...item,
    onToggle: () => handleToggle(item.id, setAccountUpdates),
  }));

  const regulatoryUpdatesItems = regulatoryUpdates.map((item) => ({
    ...item,
    onToggle: () => handleToggle(item.id, setRegulatoryUpdates),
  }));

  const submitHandler = (e) => {
    if (e) {
      e.preventDefault();
    }
    const notificationURL = (action) =>
      `${
        process.env.REACT_APP_NODE_API_GATEWAY
      }/CompassLambdaApi/NOTIFICATIONS/${action}?notificationID=${user.sub.replace(
        "|",
        "%7c"
      )}&email=${user?.email}&rrspContDeadline=${emailNotifications?.[0]?.isChecked}&tfsaContRoom=${emailNotifications?.[1]?.isChecked}&rrspContRoom=${emailNotifications?.[2]?.isChecked}&taxDeadline=${emailNotifications?.[3]?.isChecked}&taxBracket=${regulatoryUpdates?.[0]?.isChecked}&estateRegulations=${regulatoryUpdates?.[1]?.isChecked}&activityReminder=${accountUpdates?.[0]?.isChecked}`;
    axiosInstance
      .get(notificationURL("add"))
      .then((res) => {
        console.log(res)
        setUserNotificationInfo({
          ...userNotificationInfo,
          email: user?.email,
          rrspContDeadline: emailNotifications?.[0]?.isChecked,
          tfsaContRoom: emailNotifications?.[1]?.isChecked,
          rrspContRoom: emailNotifications?.[2]?.isChecked,

          taxDeadline: emailNotifications?.[3]?.isChecked,
          taxBracket: regulatoryUpdates?.[0]?.isChecked,
          estateRegulations: regulatoryUpdates?.[1]?.isChecked,
          activityReminder: accountUpdates?.[0]?.isChecked,
        });
        if (res.data.code === "ER_DUP_ENTRY") {
          axiosInstance.get(notificationURL("update")).then((res) => {
            console.log(res);
            setUserNotificationInfo({
              ...userNotificationInfo,
              email: user?.email,
              rrspContDeadline: emailNotifications?.[0]?.isChecked,
              tfsaContRoom: emailNotifications?.[1]?.isChecked,
              rrspContRoom: emailNotifications?.[2]?.isChecked,

              taxDeadline: emailNotifications?.[3]?.isChecked,
              taxBracket: regulatoryUpdates?.[0]?.isChecked,
              estateRegulations: regulatoryUpdates?.[1]?.isChecked,
              activityReminder: accountUpdates?.[0]?.isChecked,
            });
          });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const ToggleList = ({ heading, items }) => {
    return (
      <Box
        width="100%"
        paddingBottom={"10px"}
        borderBottom="2px solid rgba(179, 179, 191, 1)"
        marginBottom={"1rem"}
      >
        <Text
          fontSize="16px"
          fontWeight="800"
          mb="4"
          fontFamily={"Nunito"}
          color={"#00A2D4"}
        >
          {heading}
        </Text>
        <VStack alignItems="flex-start" spacing="4" width="100%">
          {items.map((item, index) => (
            <Flex
              key={index}
              width="100%"
              alignItems="center"
              justifyContent="space-between"
            >
              <Text fontSize="14px" fontWeight="600" fontFamily={"Nunito"}>
                {item.label}
              </Text>
              <Switch
                size={"lg"}
                colorScheme={"blue"}
                id={item.id}
                isChecked={item.isChecked}
                onChange={item.onToggle}
                height={"20px"}
              />
            </Flex>
          ))}
        </VStack>
      </Box>
    );
  };

  return (
    <Flex
      display="flex"
      flexWrap="wrap" // Enable wrapping
      justifyContent="flex-start"
      alignItems="start"
      width={"100%"}
    >
      <VStack alignItems={"flex-start"} width={"100%"} mt={-2}>
        <GlobalBodyPage
          Heading="Email Notifications"
          mainframeContent={
            <Box width={"100%"}>
              <ToggleList
                heading="Tax and Financial Deadlines"
                items={emailNotificationItems}
              />
              <ToggleList
                heading="Regulatory Updates"
                items={regulatoryUpdatesItems}
              />
              <ToggleList
                heading="Account Activity"
                items={accountUpdatesItems}
              />
            </Box>
          }
        />
      </VStack>
    </Flex>
  );
})

export default Notifications;
