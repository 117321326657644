import { Box, Flex, Text, VStack } from "@chakra-ui/layout";
import { useMediaQuery } from "@chakra-ui/media-query";
import axios from "axios";
import React, {
  forwardRef,
  useContext,
  useEffect,
  useImperativeHandle,
  useState,
} from "react";
import { useStore } from "../../../store";
import { useAuth0 } from "@auth0/auth0-react";
import GlobalBodyPage from "../../GlobalContainer/BodyPage";
import NormalInputButton from "../../InputFields/normalInputButton";
import TableComponent from "../../TableComponent";
import TooltipContent from "../../TooltipContent";
import { CloseIcon } from "../../CustomIcons";


const PersonalAssets = forwardRef((props, ref) => {
  const { getAccessTokenSilently, user } = useAuth0();

  useImperativeHandle(ref, () => ({
    submitHandler,
    checkMandatoryFields,
  }));

  const submitHandler = () => {
    setUserEditAssetInfo({});
    return true;
  };

  const checkMandatoryFields = () => {
    return true;
  };

  const axiosInstance = axios.create();
  async function getToken() {
    // Replace this with your Auth0 token retrieval logic
    const token = await getAccessTokenSilently({
      audience:
        "https://os2lsiwff43wfpb5ggyawt6qri0mgcro.lambda-url.us-east-2.on.aws/",
      scope: "write:optimization",
    });
    return token;
  }

  // Add a request interceptor
  axiosInstance.interceptors.request.use(
    async (config) => {
      const token = await getToken();
      config.headers.Authorization = `Bearer ${token}`;
      return config;
    },
    (error) => {
      // Do something with request error
      return Promise.reject(error);
    }
  );
  const userEditAssetInfo = useStore((store) => store.userEditAssetInfo);
  const setUserEditAssetInfo = useStore((store) => store.setUserEditAssetInfo);

  const userOtherAssetsInfo = useStore((store) => store.userOtherAssetsInfo);
  const setUserOtherAssetsInfo = useStore(
    (store) => store.setUserOtherAssetsInfo
  );
  const updateUserOtherAssetsInfo = useStore(
    (store) => store.updateUserOtherAssetsInfo
  );
  const deleteUserOtherAssetsInfo = useStore(
    (store) => store.deleteUserOtherAssetsInfo
  );
  const [otherAssetsArray, setOtherAssetsArray] = useState([]);

  const [isLargerThan768] = useMediaQuery("(min-width: 500px)");

  const isMobile = !isLargerThan768;

  const [otherAssetName, setOtherAssetName] = useState("");
  const [otherAssetValue, setOtherAssetValue] = useState(0);
  const [otherAssetAppreciation, setOtherAssetAppreciation] = useState(0);
  const [otherAssetNumber, setOtherAssetNumber] = useState();

  const [editing, setEditing] = useState(false);
  const [editingIndex, setEditingIndex] = useState(null);
  const [showDeleteModal, setShowDeleteModal] = useState(null);

  const [adding, setAdding] = useState(false);

  const userInfo = useStore((store) => store.userInfo);

  const {
    FirstName: firstName,
    LastName: lastName,
    DateOfBirth: currentAge,
    spouseFirstName,
    spouseLastName,
    runAge,
  } = userInfo;

  useEffect(() => {
    console.log(userEditAssetInfo);
    if (userEditAssetInfo?.editAsset?.Other_Asset_Value) {
      setOtherAssetName(userEditAssetInfo?.editAsset?.name);
      setOtherAssetValue(userEditAssetInfo?.editAsset?.Other_Asset_Value);
      setOtherAssetAppreciation(
        userEditAssetInfo?.editAsset?.Other_Asset_Appreciation
      );
      setOtherAssetNumber(userEditAssetInfo?.editAsset?.Other_Assets_Number);
      setEditing(true);
      setAdding(true);
      setEditingIndex(userEditAssetInfo?.editIndex);
    }
  }, [userEditAssetInfo]);

  useEffect(() => {
    const updatedOtherAssetArray = [];

    if (userOtherAssetsInfo != null) {
      updatedOtherAssetArray.push(...userOtherAssetsInfo);
    }

    setOtherAssetsArray(updatedOtherAssetArray);
  }, [userOtherAssetsInfo]);

  const onDeleteRow = (i) => {
    setEditingIndex(i);
    setShowDeleteModal(true);
  };

  const editField = (i, array) => {
    setAdding(true);
    setOtherAssetName(array[i]?.name);
    setOtherAssetValue(array[i]?.Other_Asset_Value);
    setOtherAssetAppreciation(array[i]?.Other_Asset_Appreciation);
    setOtherAssetNumber(array[i]?.Other_Assets_Number);
    setEditing(true);
    setEditingIndex(i);
  };

  const DeleteRealEstateModal = () => {
    let activeArray = otherAssetsArray;

    const removeRealEstateModal = () => {
      const deleteOtherAssetURL = (action) =>
        `${process.env.REACT_APP_NODE_API_GATEWAY}/CompassLambdaApi/OTHER_ASSETS/${action}?Other_Assets_Number=${activeArray[editingIndex].Other_Assets_Number}`;
      axiosInstance.get(deleteOtherAssetURL("delete")).then((res) => {
        console.log("Delete res", res);
        deleteUserOtherAssetsInfo(
          otherAssetsArray[editingIndex].Other_Assets_Number
        );
        setShowDeleteModal(false);
        setEditingIndex(null);
        setEditing(false);
      });
    };

    if (!showDeleteModal) {
      return null;
    }
    const [yesClicked, setYesClicked] = useState(false);
    const [noClicked, setNoClicked] = useState(false);
    return (
      <div
        className="delete-income-modal"
        style={{
          display: "flex",
          alignItems: "center",
          width:"100%",
          maxWidth:"700px",
          justifyContent: "center",
          top: "50%",
          left: "50%",
          transform: isMobile
            ? "translate(-50%, -50%)"
            : "translate(-50%, -50%)",
        }}
        id="delete-income-modal"
      >
        <Box
          display={"flex"}
          width={"100%"}
          padding={"10px 10px 0px 0px"}
          marginLeft={"5px"}
          alignItems={"flex-end"}
          justifyContent={"flex-end"}
          onClick={() => setShowDeleteModal(false)}
          cursor={"pointer"}
        >
          <CloseIcon
            onClick={() => setShowDeleteModal(false)}
            svgheight={"20px"}
            svgwidth={"20px"}
          />
        </Box>
        <Box
          display={"flex"}
          padding={"0px 25px 25px 25px"}
          flexDirection={"column"}
          alignItems={"center"}
          gap={"10px"}
          alignSelf={"stretch"}
          width={"100%"}
        >
          <Text
            color={"#0093D0"}
            fontFamily={"Nunito"}
            fontSize={"24px"}
            fontStyle={"normal"}
            fontWeight={"600"}
            lineHeight={"normal"}
            textDecor={"underline"}
          >
          Are you sure you want to remove this asset?
        </Text>
        <div className="content"></div>
        <div className="actions">
          <button className={`decision-button yes-button ${
              yesClicked ? "yes-clicked" : ""
            }`}
            onClick={() => {
              setYesClicked(true);
              setNoClicked(false);
              removeRealEstateModal();
            }}>Yes</button>
          <button className={`decision-button no-button ${
              noClicked ? "yes-clicked" : ""
            }`}
            onClick={() => {
              {
                setNoClicked(true);
                setYesClicked(false);
                setShowDeleteModal(false);
              }
            }}>No</button>
        </div>
        </Box>
      </div>
    );
  };

  const moneyToNumber = (value) => {
    let numValue = value.toString().replace(/[$,\s]/g, "");
    numValue = Number(numValue);
    return numValue;
  };

  const onSubmitOtherAssetAdd = (e, newArray) => {
    e.preventDefault();
    const primaryResURL = (action) =>
      `${
        process.env.REACT_APP_NODE_API_GATEWAY
      }/CompassLambdaApi/OTHER_ASSETS/${action}?AssetsID=${user.sub.replace(
        "|",
        "%7c"
      )}&name=${newArray.name}&Other_Asset_Value=${
        newArray.Other_Asset_Value
      }&Other_Asset_Appreciation=${newArray.Other_Asset_Appreciation}`;
    axiosInstance
      .get(primaryResURL("add"))
      .then((res) => {
        console.log(res);
        const newData = [
          {
            name: newArray.name,
            Other_Asset_Value: newArray.Other_Asset_Value,
            Other_Asset_Appreciation: newArray.Other_Asset_Appreciation,
            Other_Assets_Number: res.data.lastInsertedId,
          },
        ];
        setUserOtherAssetsInfo(newData);
        setUserEditAssetInfo({});
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const onSubmitOtherAssetUpdate = (e, newArray) => {
    e.preventDefault();
    let x = newArray.Other_Assets_Number;
    const otherAssetsURL = (action) =>
      `${
        process.env.REACT_APP_NODE_API_GATEWAY
      }/CompassLambdaApi/OTHER_ASSETS/${action}?AssetsID=${user.sub.replace(
        "|",
        "%7c"
      )}&name=${newArray.name}&Other_Asset_Value=${
        newArray.Other_Asset_Value
      }&Other_Asset_Appreciation=${
        newArray.Other_Asset_Appreciation
      }&Other_Assets_Number=${newArray.Other_Assets_Number}`;
    axiosInstance
      .get(otherAssetsURL("update"))
      .then((res) => {
        console.log(res);
        const newData = {
          name: newArray.name,
          Other_Asset_Value: newArray.Other_Asset_Value,
          Other_Asset_Appreciation: newArray.Other_Asset_Appreciation,
          Other_Assets_Number: newArray.Other_Assets_Number,
        };
        updateUserOtherAssetsInfo(x, newData);
        setUserEditAssetInfo({});
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const addOtherAssets = (e, list) => {
    if (editing) {
      setOtherAssetsArray((prevArray) => {
        let newArray = [...prevArray];
        newArray[editingIndex] = list;
        onSubmitOtherAssetUpdate(e, newArray[editingIndex]);
        return newArray;
      });
      setEditing(false);
      setEditingIndex(null);
    } else {
      setOtherAssetsArray((prevArray) => {
        let newArray = [...prevArray, list];
        onSubmitOtherAssetAdd(e, newArray[newArray.length - 1]);
        return newArray;
      });
    }
    setOtherAssetName("");
    setOtherAssetValue(0);
    setOtherAssetAppreciation(0);
    setAdding(false);
  };

  const [errors, setErrors] = useState({});

  const checkMandatoryAddUpdateFields = () => {
    let newErrors = {};

    if (!otherAssetName) {
      newErrors.otherAssetName = "Asset name is required";
    }
    if (!otherAssetValue) {
      newErrors.otherAssetValue = "Asset value is required";
    }
    if (otherAssetAppreciation > 20) {
      newErrors.otherAssetAppreciation =
        "Asset growth rate must be less than 20%";
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0; // Return true if no errors
  };

  const renderAddUpdateRecreational = () => {
    if (!otherAssetName || !otherAssetValue || otherAssetAppreciation > 20) {
      return (
        <Box
          display={"flex"}
          padding={"8px 48px"}
          marginTop={"20px"}
          justifyContent={"flex-end"}
          alignItems={"center"}
          gap={"5px"}
          borderRadius={"5px"}
          bg={"#27AE60"}
          cursor={"pointer"}
          onClick={checkMandatoryAddUpdateFields}
        >
          <Box
            display={"flex"}
            width={"76px"}
            height={"21px"}
            flexDirection={"column"}
            justifyContent={"center"}
          >
            <Text
              color={"#FFFFFF"}
              textAlign={"center"}
              fontFamily={"Nunito"}
              fontSize={"16px"}
              fontStyle={"normal"}
              fontWeight={"600"}
              lineHeight={"normal"}
            >
              {editing ? "Update" : "Add"}
            </Text>
          </Box>
        </Box>
      );
    } else {
      return (
        <Box
          display={"flex"}
          padding={"8px 48px"}
          marginTop={"20px"}
          justifyContent={"flex-end"}
          alignItems={"center"}
          gap={"5px"}
          borderRadius={"5px"}
          bg={"#27AE60"}
          cursor={"pointer"}
          onClick={(e) =>
            addOtherAssets(e, {
              type: "Other Asset",
              name: otherAssetName,
              Other_Asset_Value: otherAssetValue,
              Other_Asset_Appreciation: otherAssetAppreciation,
              ...(editing ? { Other_Assets_Number: otherAssetNumber } : {}), // add field conditionally
            })
          }
        >
          <Box
            display={"flex"}
            width={"76px"}
            height={"21px"}
            flexDirection={"column"}
            justifyContent={"center"}
            cursor={"pointer"}
          >
            <Text
              color={"#FFFFFF"}
              textAlign={"center"}
              fontFamily={"Nunito"}
              fontSize={"16px"}
              fontStyle={"normal"}
              fontWeight={"600"}
              lineHeight={"normal"}
            >
              {editing ? "Update" : "Add"}
            </Text>
          </Box>
        </Box>
      );
    }
  };

  const columns = [
    { header: "Name", field: "name" },
    { header: "Value", field: "value" },
  ];

  const data = otherAssetsArray.map((item) => ({
    name: item.name,
    value: item?.Other_Asset_Value,
    link: "/personal-assets-form",
  }));

  const handleClick = () => {
    setAdding(true);
  };

  return (
    <Flex
      display="flex"
      flexWrap="wrap"
      justifyContent="flex-start"
      alignItems="start"
      width={"100%"}
    >
      {!isMobile && (
        <VStack alignItems={"flex-start"} width={"100%"} mt={-2}>
          <GlobalBodyPage
            Heading={"Personal Assets"}
            mainframeContent={
              <>
                <Box
                  display={"flex"}
                  width={"100%"}
                  flexDirection={"row"}
                  alignItems={"flex-end"}
                  justifyContent={"space-between"}
                  gap={"10px"}
                  flexWrap={"wrap"}
                >
                  <DeleteRealEstateModal />
                  {data.length > 0 && !editing && !adding && (
                    <TableComponent
                      columns={columns}
                      data={data}
                      editField={editField}
                      onDeleteRow={onDeleteRow}
                      rawData={otherAssetsArray}
                    />
                  )}
                  {(data.length < 1 || adding) && (
                    <>
                      <NormalInputButton
                        Heading={"Name/Title"}
                        value={otherAssetName}
                        onChange={(e) => {
                          setOtherAssetName(e.target.value);
                        }}
                        customWidth={"100%"}
                        InputWidth={"100%"}
                        error={errors.otherAssetName}
                        tooltipContent={TooltipContent.otherAssetName}
                      />
                      <NormalInputButton
                        Heading={"Current Value"}
                        value={otherAssetValue}
                        onChange={(e) => {
                          setOtherAssetValue(moneyToNumber(e.target.value));
                        }}
                        placeholder={"$"}
                        useThousandSeparator={true}
                        error={errors.otherAssetValue}
                        tooltipContent={TooltipContent.otherAssetValue}
                      />
                      <NormalInputButton
                        Heading={"Appreciation"}
                        value={otherAssetAppreciation}
                        onChange={(e) => {
                          const noSymbol = e.target.value.replace("%", "");
                          const growthNum = parseFloat(noSymbol);
                          setOtherAssetAppreciation(
                            !isNaN(growthNum)
                              ? parseFloat(growthNum.toFixed(2))
                              : 0
                          );
                        }}
                        placeholder={"%"}
                        usePercentage={true}
                        error={errors.otherAssetAppreciation}
                        tooltipContent={TooltipContent.otherAssetGrowth}
                      />
                    </>
                  )}
                </Box>
                {data.length > 0 && !adding && (
                  <Box
                    display={"flex"}
                    justifyContent={"flex-start"}
                    alignItems={"center"}
                    alignSelf={"stretch"}
                    w={"100%"}
                  >
                    <Box
                      display={"flex"}
                      padding={"8px 48px"}
                      marginTop={"20px"}
                      justifyContent={"flex-end"}
                      alignItems={"center"}
                      gap={"5px"}
                      borderRadius={"5px"}
                      bg={"#27AE60"}
                      cursor={"pointer"}
                      onClick={handleClick}
                    >
                      <Box
                        display={"flex"}
                        width={"76px"}
                        height={"21px"}
                        flexDirection={"column"}
                        justifyContent={"center"}
                      >
                        <Text
                          color={"#FFFFFF"}
                          textAlign={"center"}
                          fontFamily={"Nunito"}
                          fontSize={"16px"}
                          fontStyle={"normal"}
                          fontWeight={"600"}
                          lineHeight={"normal"}
                        >
                          Add
                        </Text>
                      </Box>
                    </Box>
                  </Box>
                )}
                {(data.length < 1 || adding) && (
                  <Box
                    display={"flex"}
                    justifyContent={"flex-end"}
                    alignItems={"center"}
                    alignSelf={"stretch"}
                  >
                    {renderAddUpdateRecreational()}
                  </Box>
                )}
              </>
            }
            videoName={"Personal Assets Page"}
            video={"Personal_assets.mp4"}
          />
        </VStack>
      )}
      {isMobile && (
        <VStack spacing={4} alignItems={"flex-start"} width={"100%"}>
          <GlobalBodyPage
            Heading={"Personal Assets"}
            mainframeContent={
              <>
                <Box
                  display={"flex"}
                  width={"100%"}
                  flexDirection={"row"}
                  alignItems={"flex-end"}
                  justifyContent={"space-between"}
                  gap={"10px"}
                  flexWrap={"wrap"}
                >
                  <DeleteRealEstateModal />
                  {data.length > 0 && !editing && !adding && (
                    <TableComponent
                      columns={columns}
                      data={data}
                      editField={editField}
                      onDeleteRow={onDeleteRow}
                      rawData={otherAssetsArray}
                    />
                  )}
                  {(data.length < 1 || adding) && (
                    <>
                      <NormalInputButton
                        Heading={"Name/Title"}
                        value={otherAssetName}
                        onChange={(e) => {
                          setOtherAssetName(e.target.value);
                        }}
                        customWidth={"100%"}
                        InputWidth={"100%"}
                        error={errors.otherAssetName}
                        tooltipContent={TooltipContent.otherAssetName}
                      />
                      <NormalInputButton
                        Heading={"Current Value"}
                        value={otherAssetValue}
                        onChange={(e) => {
                          setOtherAssetValue(moneyToNumber(e.target.value));
                        }}
                        placeholder={"$"}
                        useThousandSeparator={true}
                        customWidth={"100%"}
                        InputWidth={"100%"}
                        error={errors.otherAssetValue}
                        tooltipContent={TooltipContent.otherAssetValue}
                      />
                      <NormalInputButton
                        Heading={"Appreciation"}
                        value={otherAssetAppreciation}
                        onChange={(e) => {
                          const noSymbol = e.target.value.replace("%", "");
                          const growthNum = parseFloat(noSymbol);
                          setOtherAssetAppreciation(
                            !isNaN(growthNum)
                              ? parseFloat(growthNum.toFixed(2))
                              : 0
                          );
                        }}
                        placeholder={"%"}
                        usePercentage={true}
                        customWidth={"100%"}
                        InputWidth={"100%"}
                        error={errors.otherAssetAppreciation}
                        tooltipContent={TooltipContent.otherAssetGrowth}
                      />
                    </>
                  )}
                </Box>
                {data.length > 0 && !adding && (
                  <Box
                    display={"flex"}
                    justifyContent={"flex-start"}
                    alignItems={"center"}
                    alignSelf={"stretch"}
                    w={"100%"}
                  >
                    <Box
                      display={"flex"}
                      padding={"8px 48px"}
                      marginTop={"20px"}
                      justifyContent={"flex-end"}
                      alignItems={"center"}
                      gap={"5px"}
                      borderRadius={"5px"}
                      bg={"#27AE60"}
                      cursor={"pointer"}
                      onClick={handleClick}
                    >
                      <Box
                        display={"flex"}
                        width={"76px"}
                        height={"21px"}
                        flexDirection={"column"}
                        justifyContent={"center"}
                      >
                        <Text
                          color={"#FFFFFF"}
                          textAlign={"center"}
                          fontFamily={"Nunito"}
                          fontSize={"16px"}
                          fontStyle={"normal"}
                          fontWeight={"600"}
                          lineHeight={"normal"}
                        >
                          Add
                        </Text>
                      </Box>
                    </Box>
                  </Box>
                )}
                {(data.length < 1 || adding) && (
                  <Box
                    display={"flex"}
                    justifyContent={"flex-end"}
                    alignItems={"center"}
                    alignSelf={"stretch"}
                  >
                    {renderAddUpdateRecreational()}
                  </Box>
                )}
              </>
            }
            videoName={"Personal Assets Page"}
            video={"Personal_assets.mp4"}
          />
        </VStack>
      )}
    </Flex>
  );
});

export default PersonalAssets;
