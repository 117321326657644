import React from "react";
import incomeIcon from "../../img/income-icon.png";
import depositsIcon from "../../img/deposits-icon.png";
import withdrawalsIcon from "../../img/withdrawals-icon.png";
import taxesIcon from "../../img/taxes-icon.png";
import budgetSummaryIcon from "../../img/budget-summary-icon.png";
import portfolioBalanceIcon from "../../img/portfolio-balance-icon.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "./EndOfLifePageSpouse.css";
import BarGraph from "./BarGraph";
import { useStore } from "../../store";
import { faBadgeDollar, faCreditCard } from "@fortawesome/pro-light-svg-icons";
import { Box, Text, useMediaQuery } from "@chakra-ui/react";
import DashboardSubCards from "./DashboardCards/DashboardSubCards";

function safeAdd(...values) {
  return values.reduce((sum, val) => sum + (Number(val) || 0), 0);
}

const currencyFormat = (money) => {
  var intTotal = Math.round(Math.round(money * 100) / 100);
  if (intTotal > -2 && intTotal < 0.25) {
    intTotal = 0;
  }
  // Math.round(intTotal);
  var currencyCad = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
    minimumFractionDigits: 0,
  }).format(intTotal);
  return currencyCad;
};

function Income(props) {
  const {
    isLargerThan1200,
    isSmallScreen,
    income,
    combinedCPP,
    combinedOAS,
    combinedPension,
    combinedOtherIncome,
    combinedTotalIncome,
    combinedAssetSaleIncome,
    combinedIncomePropertyIncome,
    combinedBusinessIncome,
    combinedInheritanceIncome,
    spousePage,
  } = props;
  const userInfo = useStore((store) => store.userInfo);

  return (
    <>
      {isSmallScreen && (
        <>
          <Box
            display={"flex"}
            flexDirection={"column"}
            width={"100%"}
            alignItems={"center"}
          >
            <Box display={"flex"} alignSelf={"center"} fill={"#00A2D4"}>
              <BarGraph
                type="income"
                income={income}
                combinedCPP={combinedCPP}
                combinedOAS={combinedOAS}
                combinedPension={combinedPension}
                combinedTotalIncome={combinedTotalIncome}
                combinedIncomePropertyIncome={combinedIncomePropertyIncome}
                combinedBusinessIncome={combinedBusinessIncome}
                combinedAssetSaleIncome={combinedAssetSaleIncome}
                combinedOtherIncome={combinedOtherIncome}
                combinedInheritanceIncome={combinedInheritanceIncome}
              />
            </Box>
            <Box
              display={"flex"}
              width={"100%"}
              flexDirection={"column"}
              alignItems={"flex-start"}
              gap={"10px"}
            >
              <Text
                color={"#00A2D4"}
                textAlign={"right"}
                fontFamily={"Nunito"}
                fontSize={"24px"}
                fontStyle={"normal"}
                fontWeight={"800"}
                lineHeight={"130%"}
              >
                Income
              </Text>

              {income >= 1 && (
                <Box
                  display={"flex"}
                  height={"20px"}
                  justifyContent={"space-between"}
                  alignItems={"flex-start"}
                  alignSelf={"stretch"}
                  borderBottom={"1px solid #B9B9B9"}
                >
                  <Text
                    color={"#004069"}
                    fontFamily={"Nunito"}
                    fontSize={"14px"}
                    fontStyle={"normal"}
                    fontWeight={"600"}
                    lineHeight={"130%"}
                  >
                    Salary
                  </Text>
                  <Text
                    color={"#00A2D4"}
                    textAlign={"right"}
                    fontFamily={"Nunito"}
                    fontSize={"14px"}
                    fontStyle={"normal"}
                    fontWeight={"800"}
                    lineHeight={"130%"}
                  >
                    {currencyFormat(income)}
                  </Text>
                </Box>
              )}
              {combinedCPP !== 0 && (
                <div className="activity-summary-subtitle-container">
                  {userInfo?.Province != "QC" && (
                    <Text
                      color={"#004069"}
                      fontFamily={"Nunito"}
                      fontSize={"14px"}
                      fontStyle={"normal"}
                      fontWeight={"600"}
                      lineHeight={"130%"}
                    >
                      CPP
                    </Text>
                  )}
                  {userInfo?.Province === "QC" && (
                    <Text
                      color={"#004069"}
                      fontFamily={"Nunito"}
                      fontSize={"14px"}
                      fontStyle={"normal"}
                      fontWeight={"600"}
                      lineHeight={"130%"}
                    >
                      QPP
                    </Text>
                  )}
                  <Text
                    color={"#00A2D4"}
                    textAlign={"right"}
                    fontFamily={"Nunito"}
                    fontSize={"14px"}
                    fontStyle={"normal"}
                    fontWeight={"800"}
                    lineHeight={"130%"}
                  >
                    {currencyFormat(combinedCPP)}
                  </Text>
                </div>
              )}
              {combinedOAS !== 0 && (
                <div className="activity-summary-subtitle-container">
                  <Text
                    color={"#004069"}
                    fontFamily={"Nunito"}
                    fontSize={"14px"}
                    fontStyle={"normal"}
                    fontWeight={"600"}
                    lineHeight={"130%"}
                  >
                    OAS
                  </Text>
                  <Text
                    color={"#00A2D4"}
                    textAlign={"right"}
                    fontFamily={"Nunito"}
                    fontSize={"14px"}
                    fontStyle={"normal"}
                    fontWeight={"800"}
                    lineHeight={"130%"}
                  >
                    {currencyFormat(combinedOAS)}
                  </Text>
                </div>
              )}
              {combinedPension >= 1 && (
                <div className="activity-summary-subtitle-container">
                  <Text
                    color={"#004069"}
                    fontFamily={"Nunito"}
                    fontSize={"14px"}
                    fontStyle={"normal"}
                    fontWeight={"600"}
                    lineHeight={"130%"}
                  >
                    Pension
                  </Text>
                  <Text
                    color={"#00A2D4"}
                    textAlign={"right"}
                    fontFamily={"Nunito"}
                    fontSize={"14px"}
                    fontStyle={"normal"}
                    fontWeight={"800"}
                    lineHeight={"130%"}
                  >
                    {currencyFormat(combinedPension)}
                  </Text>
                </div>
              )}
              {combinedOtherIncome >= 1 && (
                <div className="activity-summary-subtitle-container">
                  <Text
                    color={"#004069"}
                    fontFamily={"Nunito"}
                    fontSize={"14px"}
                    fontStyle={"normal"}
                    fontWeight={"600"}
                    lineHeight={"130%"}
                  >
                    Other Income
                  </Text>
                  <Text
                    color={"#00A2D4"}
                    textAlign={"right"}
                    fontFamily={"Nunito"}
                    fontSize={"14px"}
                    fontStyle={"normal"}
                    fontWeight={"800"}
                    lineHeight={"130%"}
                  >
                    {currencyFormat(combinedOtherIncome)}
                  </Text>
                </div>
              )}
              {combinedIncomePropertyIncome >= 1 &&
                spousePage === "joint-portfolio" && (
                  <div className="activity-summary-subtitle-container">
                    <Text
                      color={"#004069"}
                      fontFamily={"Nunito"}
                      fontSize={"14px"}
                      fontStyle={"normal"}
                      fontWeight={"600"}
                      lineHeight={"130%"}
                    >
                      Rental Income
                    </Text>
                    <Text
                      color={"#00A2D4"}
                      textAlign={"right"}
                      fontFamily={"Nunito"}
                      fontSize={"14px"}
                      fontStyle={"normal"}
                      fontWeight={"800"}
                      lineHeight={"130%"}
                    >
                      {currencyFormat(combinedIncomePropertyIncome)}
                    </Text>
                  </div>
                )}
              {combinedIncomePropertyIncome >= 1 &&
                spousePage != "joint-portfolio" && (
                  <div className="activity-summary-subtitle-container">
                    <Text
                      color={"#004069"}
                      fontFamily={"Nunito"}
                      fontSize={"14px"}
                      fontStyle={"normal"}
                      fontWeight={"600"}
                      lineHeight={"130%"}
                    >
                      Rental Income
                    </Text>
                    <Text
                      color={"#00A2D4"}
                      textAlign={"right"}
                      fontFamily={"Nunito"}
                      fontSize={"14px"}
                      fontStyle={"normal"}
                      fontWeight={"800"}
                      lineHeight={"130%"}
                    >
                      {currencyFormat(combinedIncomePropertyIncome / 2)}
                    </Text>
                  </div>
                )}
              {combinedBusinessIncome >= 1 &&
                spousePage === "joint-portfolio" && (
                  <div className="activity-summary-subtitle-container">
                    <Text
                      color={"#004069"}
                      fontFamily={"Nunito"}
                      fontSize={"14px"}
                      fontStyle={"normal"}
                      fontWeight={"600"}
                      lineHeight={"130%"}
                    >
                      Business Dividends
                    </Text>
                    <Text
                      color={"#00A2D4"}
                      textAlign={"right"}
                      fontFamily={"Nunito"}
                      fontSize={"14px"}
                      fontStyle={"normal"}
                      fontWeight={"800"}
                      lineHeight={"130%"}
                    >
                      {currencyFormat(combinedBusinessIncome)}
                    </Text>
                  </div>
                )}
              {combinedBusinessIncome >= 1 &&
                spousePage != "joint-portfolio" && (
                  <div className="activity-summary-subtitle-container">
                    <Text
                      color={"#004069"}
                      fontFamily={"Nunito"}
                      fontSize={"14px"}
                      fontStyle={"normal"}
                      fontWeight={"600"}
                      lineHeight={"130%"}
                    >
                      Business Dividends
                    </Text>
                    <Text
                      color={"#00A2D4"}
                      textAlign={"right"}
                      fontFamily={"Nunito"}
                      fontSize={"14px"}
                      fontStyle={"normal"}
                      fontWeight={"800"}
                      lineHeight={"130%"}
                    >
                      {currencyFormat(combinedBusinessIncome / 2)}
                    </Text>
                  </div>
                )}
              {combinedInheritanceIncome >= 1 &&
                spousePage === "joint-portfolio" && (
                  <div className="activity-summary-subtitle-container">
                    <Text
                      color={"#004069"}
                      fontFamily={"Nunito"}
                      fontSize={"14px"}
                      fontStyle={"normal"}
                      fontWeight={"600"}
                      lineHeight={"130%"}
                    >
                      Inheritance
                    </Text>
                    <Text
                      color={"#00A2D4"}
                      textAlign={"right"}
                      fontFamily={"Nunito"}
                      fontSize={"14px"}
                      fontStyle={"normal"}
                      fontWeight={"800"}
                      lineHeight={"130%"}
                    >
                      {currencyFormat(combinedInheritanceIncome)}
                    </Text>
                  </div>
                )}
              {combinedInheritanceIncome >= 1 &&
                spousePage != "joint-portfolio" && (
                  <div className="activity-summary-subtitle-container">
                    <Text
                      color={"#004069"}
                      fontFamily={"Nunito"}
                      fontSize={"14px"}
                      fontStyle={"normal"}
                      fontWeight={"600"}
                      lineHeight={"130%"}
                    >
                      Inheritance
                    </Text>
                    <Text
                      color={"#00A2D4"}
                      textAlign={"right"}
                      fontFamily={"Nunito"}
                      fontSize={"14px"}
                      fontStyle={"normal"}
                      fontWeight={"800"}
                      lineHeight={"130%"}
                    >
                      {currencyFormat(combinedInheritanceIncome / 2)}
                    </Text>
                  </div>
                )}
              {combinedAssetSaleIncome >= 1 &&
                spousePage === "joint-portfolio" && (
                  <div className="activity-summary-subtitle-container">
                    <Text
                      color={"#004069"}
                      fontFamily={"Nunito"}
                      fontSize={"14px"}
                      fontStyle={"normal"}
                      fontWeight={"600"}
                      lineHeight={"130%"}
                    >
                      Asset Sale
                    </Text>
                    <Text
                      color={"#00A2D4"}
                      textAlign={"right"}
                      fontFamily={"Nunito"}
                      fontSize={"14px"}
                      fontStyle={"normal"}
                      fontWeight={"800"}
                      lineHeight={"130%"}
                    >
                      {currencyFormat(combinedAssetSaleIncome)}
                    </Text>
                  </div>
                )}
              {combinedAssetSaleIncome >= 1 &&
                spousePage != "joint-portfolio" && (
                  <div className="activity-summary-subtitle-container">
                    <Text
                      color={"#004069"}
                      fontFamily={"Nunito"}
                      fontSize={"14px"}
                      fontStyle={"normal"}
                      fontWeight={"600"}
                      lineHeight={"130%"}
                    >
                      Asset Sale
                    </Text>
                    <Text
                      color={"#00A2D4"}
                      textAlign={"right"}
                      fontFamily={"Nunito"}
                      fontSize={"14px"}
                      fontStyle={"normal"}
                      fontWeight={"800"}
                      lineHeight={"130%"}
                    >
                      {currencyFormat(combinedAssetSaleIncome / 2)}
                    </Text>
                  </div>
                )}
              {spousePage === "joint-portfolio" && (
                <Box
                  display={"flex"}
                  height={"26px"}
                  justifyContent={"space-between"}
                  alignItems={"flex-start"}
                  alignSelf={"stretch"}
                >
                  <Text
                    color={"#004069"}
                    fontFamily={"Nunito"}
                    fontSize={"20px"}
                    fontStyle={"normal"}
                    fontWeight={"900"}
                    lineHeight={"130%"}
                  >
                    Total
                  </Text>
                  <Text
                    color={"#00A2D4"}
                    textAlign={"right"}
                    fontFamily={"Nunito"}
                    fontSize={"20px"}
                    fontStyle={"normal"}
                    fontWeight={"900"}
                    lineHeight={"130%"}
                  >
                    {currencyFormat(
                      safeAdd(
                        combinedTotalIncome,
                        combinedAssetSaleIncome,
                        combinedIncomePropertyIncome,
                        combinedBusinessIncome,
                        combinedInheritanceIncome
                      )
                    )}
                  </Text>
                </Box>
              )}
              {spousePage != "joint-portfolio" && (
                <Box
                  display={"flex"}
                  height={"26px"}
                  justifyContent={"space-between"}
                  alignItems={"flex-start"}
                  alignSelf={"stretch"}
                >
                  <Text
                    color={"#004069"}
                    fontFamily={"Nunito"}
                    fontSize={"20px"}
                    fontStyle={"normal"}
                    fontWeight={"900"}
                    lineHeight={"130%"}
                  >
                    Total
                  </Text>
                  <Text
                    color={"#00A2D4"}
                    textAlign={"right"}
                    fontFamily={"Nunito"}
                    fontSize={"20px"}
                    fontStyle={"normal"}
                    fontWeight={"900"}
                    lineHeight={"130%"}
                  >
                    {currencyFormat(
                      safeAdd(
                        combinedTotalIncome,
                        combinedAssetSaleIncome / 2,
                        combinedIncomePropertyIncome / 2,
                        combinedBusinessIncome / 2,
                        combinedInheritanceIncome / 2
                      )
                    )}
                  </Text>
                </Box>
              )}
            </Box>
          </Box>
        </>
      )}
      {!isSmallScreen && (
        <>
          <DashboardSubCards
            Heading={"Income"}
            mainframecontent={
              <>
                {" "}
                {income >= 1 && (
                  <Box
                    display={"flex"}
                    height={"20px"}
                    justifyContent={"space-between"}
                    alignItems={"flex-start"}
                    alignSelf={"stretch"}
                    borderBottom={"1px solid #B9B9B9"}
                  >
                    <Text
                      color={"#004069"}
                      fontFamily={"Nunito"}
                      fontSize={"14px"}
                      fontStyle={"normal"}
                      fontWeight={"600"}
                      lineHeight={"130%"}
                    >
                      Salary
                    </Text>
                    <Text
                      color={"#00A2D4"}
                      textAlign={"right"}
                      fontFamily={"Nunito"}
                      fontSize={"14px"}
                      fontStyle={"normal"}
                      fontWeight={"800"}
                      lineHeight={"130%"}
                    >
                      {currencyFormat(income)}
                    </Text>
                  </Box>
                )}
                {combinedCPP !== 0 && (
                  <div className="activity-summary-subtitle-container">
                    {userInfo?.Province != "QC" && (
                      <Text
                        color={"#004069"}
                        fontFamily={"Nunito"}
                        fontSize={"14px"}
                        fontStyle={"normal"}
                        fontWeight={"600"}
                        lineHeight={"130%"}
                      >
                        CPP
                      </Text>
                    )}
                    {userInfo?.Province === "QC" && (
                      <Text
                        color={"#004069"}
                        fontFamily={"Nunito"}
                        fontSize={"14px"}
                        fontStyle={"normal"}
                        fontWeight={"600"}
                        lineHeight={"130%"}
                      >
                        QPP
                      </Text>
                    )}
                    <Text
                      color={"#00A2D4"}
                      textAlign={"right"}
                      fontFamily={"Nunito"}
                      fontSize={"14px"}
                      fontStyle={"normal"}
                      fontWeight={"800"}
                      lineHeight={"130%"}
                    >
                      {currencyFormat(combinedCPP)}
                    </Text>
                  </div>
                )}
                {combinedOAS !== 0 && (
                  <div className="activity-summary-subtitle-container">
                    <Text
                      color={"#004069"}
                      fontFamily={"Nunito"}
                      fontSize={"14px"}
                      fontStyle={"normal"}
                      fontWeight={"600"}
                      lineHeight={"130%"}
                    >
                      OAS
                    </Text>
                    <Text
                      color={"#00A2D4"}
                      textAlign={"right"}
                      fontFamily={"Nunito"}
                      fontSize={"14px"}
                      fontStyle={"normal"}
                      fontWeight={"800"}
                      lineHeight={"130%"}
                    >
                      {currencyFormat(combinedOAS)}
                    </Text>
                  </div>
                )}
                {combinedPension >= 1 && (
                  <div className="activity-summary-subtitle-container">
                    <Text
                      color={"#004069"}
                      fontFamily={"Nunito"}
                      fontSize={"14px"}
                      fontStyle={"normal"}
                      fontWeight={"600"}
                      lineHeight={"130%"}
                    >
                      Pension
                    </Text>
                    <Text
                      color={"#00A2D4"}
                      textAlign={"right"}
                      fontFamily={"Nunito"}
                      fontSize={"14px"}
                      fontStyle={"normal"}
                      fontWeight={"800"}
                      lineHeight={"130%"}
                    >
                      {currencyFormat(combinedPension)}
                    </Text>
                  </div>
                )}
                {combinedOtherIncome >= 1 && (
                  <div className="activity-summary-subtitle-container">
                    <Text
                      color={"#004069"}
                      fontFamily={"Nunito"}
                      fontSize={"14px"}
                      fontStyle={"normal"}
                      fontWeight={"600"}
                      lineHeight={"130%"}
                    >
                      Other Income
                    </Text>
                    <Text
                      color={"#00A2D4"}
                      textAlign={"right"}
                      fontFamily={"Nunito"}
                      fontSize={"14px"}
                      fontStyle={"normal"}
                      fontWeight={"800"}
                      lineHeight={"130%"}
                    >
                      {currencyFormat(combinedOtherIncome)}
                    </Text>
                  </div>
                )}
                {combinedIncomePropertyIncome >= 1 &&
                  spousePage === "joint-portfolio" && (
                    <div className="activity-summary-subtitle-container">
                      <Text
                        color={"#004069"}
                        fontFamily={"Nunito"}
                        fontSize={"14px"}
                        fontStyle={"normal"}
                        fontWeight={"600"}
                        lineHeight={"130%"}
                      >
                        Rental Income
                      </Text>
                      <Text
                        color={"#00A2D4"}
                        textAlign={"right"}
                        fontFamily={"Nunito"}
                        fontSize={"14px"}
                        fontStyle={"normal"}
                        fontWeight={"800"}
                        lineHeight={"130%"}
                      >
                        {currencyFormat(combinedIncomePropertyIncome)}
                      </Text>
                    </div>
                  )}
                {combinedIncomePropertyIncome >= 1 &&
                  spousePage != "joint-portfolio" && (
                    <div className="activity-summary-subtitle-container">
                      <Text
                        color={"#004069"}
                        fontFamily={"Nunito"}
                        fontSize={"14px"}
                        fontStyle={"normal"}
                        fontWeight={"600"}
                        lineHeight={"130%"}
                      >
                        Rental Income
                      </Text>
                      <Text
                        color={"#00A2D4"}
                        textAlign={"right"}
                        fontFamily={"Nunito"}
                        fontSize={"14px"}
                        fontStyle={"normal"}
                        fontWeight={"800"}
                        lineHeight={"130%"}
                      >
                        {currencyFormat(combinedIncomePropertyIncome / 2)}
                      </Text>
                    </div>
                  )}
                {combinedBusinessIncome >= 1 &&
                  spousePage === "joint-portfolio" && (
                    <div className="activity-summary-subtitle-container">
                      <Text
                        color={"#004069"}
                        fontFamily={"Nunito"}
                        fontSize={"14px"}
                        fontStyle={"normal"}
                        fontWeight={"600"}
                        lineHeight={"130%"}
                      >
                        Business Dividend
                      </Text>
                      <Text
                        color={"#00A2D4"}
                        textAlign={"right"}
                        fontFamily={"Nunito"}
                        fontSize={"14px"}
                        fontStyle={"normal"}
                        fontWeight={"800"}
                        lineHeight={"130%"}
                      >
                        {currencyFormat(combinedBusinessIncome)}
                      </Text>
                    </div>
                  )}
                {combinedBusinessIncome >= 1 &&
                  spousePage != "joint-portfolio" && (
                    <div className="activity-summary-subtitle-container">
                      <Text
                        color={"#004069"}
                        fontFamily={"Nunito"}
                        fontSize={"14px"}
                        fontStyle={"normal"}
                        fontWeight={"600"}
                        lineHeight={"130%"}
                      >
                        Business Dividend
                      </Text>
                      <Text
                        color={"#00A2D4"}
                        textAlign={"right"}
                        fontFamily={"Nunito"}
                        fontSize={"14px"}
                        fontStyle={"normal"}
                        fontWeight={"800"}
                        lineHeight={"130%"}
                      >
                        {currencyFormat(combinedBusinessIncome / 2)}
                      </Text>
                    </div>
                  )}
                {combinedInheritanceIncome >= 1 &&
                  spousePage === "joint-portfolio" && (
                    <div className="activity-summary-subtitle-container">
                      <Text
                        color={"#004069"}
                        fontFamily={"Nunito"}
                        fontSize={"14px"}
                        fontStyle={"normal"}
                        fontWeight={"600"}
                        lineHeight={"130%"}
                      >
                        Inheritance
                      </Text>
                      <Text
                        color={"#00A2D4"}
                        textAlign={"right"}
                        fontFamily={"Nunito"}
                        fontSize={"14px"}
                        fontStyle={"normal"}
                        fontWeight={"800"}
                        lineHeight={"130%"}
                      >
                        {currencyFormat(combinedInheritanceIncome)}
                      </Text>
                    </div>
                  )}
                {combinedInheritanceIncome >= 1 &&
                  spousePage != "joint-portfolio" && (
                    <div className="activity-summary-subtitle-container">
                      <Text
                        color={"#004069"}
                        fontFamily={"Nunito"}
                        fontSize={"14px"}
                        fontStyle={"normal"}
                        fontWeight={"600"}
                        lineHeight={"130%"}
                      >
                        Inheritance
                      </Text>
                      <Text
                        color={"#00A2D4"}
                        textAlign={"right"}
                        fontFamily={"Nunito"}
                        fontSize={"14px"}
                        fontStyle={"normal"}
                        fontWeight={"800"}
                        lineHeight={"130%"}
                      >
                        {currencyFormat(combinedInheritanceIncome / 2)}
                      </Text>
                    </div>
                  )}
                {combinedAssetSaleIncome >= 1 &&
                  spousePage === "joint-portfolio" && (
                    <div className="activity-summary-subtitle-container">
                      <Text
                        color={"#004069"}
                        fontFamily={"Nunito"}
                        fontSize={"14px"}
                        fontStyle={"normal"}
                        fontWeight={"600"}
                        lineHeight={"130%"}
                      >
                        Asset Sale
                      </Text>
                      <Text
                        color={"#00A2D4"}
                        textAlign={"right"}
                        fontFamily={"Nunito"}
                        fontSize={"14px"}
                        fontStyle={"normal"}
                        fontWeight={"800"}
                        lineHeight={"130%"}
                      >
                        {currencyFormat(combinedAssetSaleIncome)}
                      </Text>
                    </div>
                  )}
                {combinedAssetSaleIncome >= 1 &&
                  spousePage != "joint-portfolio" && (
                    <div className="activity-summary-subtitle-container">
                      <Text
                        color={"#004069"}
                        fontFamily={"Nunito"}
                        fontSize={"14px"}
                        fontStyle={"normal"}
                        fontWeight={"600"}
                        lineHeight={"130%"}
                      >
                        Asset Sale
                      </Text>
                      <Text
                        color={"#00A2D4"}
                        textAlign={"right"}
                        fontFamily={"Nunito"}
                        fontSize={"14px"}
                        fontStyle={"normal"}
                        fontWeight={"800"}
                        lineHeight={"130%"}
                      >
                        {currencyFormat(combinedAssetSaleIncome / 2)}
                      </Text>
                    </div>
                  )}
                {spousePage === "joint-portfolio" && (
                  <Box
                    display={"flex"}
                    height={"26px"}
                    justifyContent={"space-between"}
                    alignItems={"flex-start"}
                    alignSelf={"stretch"}
                  >
                    <Text
                      color={"#004069"}
                      fontFamily={"Nunito"}
                      fontSize={"20px"}
                      fontStyle={"normal"}
                      fontWeight={"900"}
                      lineHeight={"130%"}
                    >
                      Total
                    </Text>
                    <Text
                      color={"#00A2D4"}
                      textAlign={"right"}
                      fontFamily={"Nunito"}
                      fontSize={"20px"}
                      fontStyle={"normal"}
                      fontWeight={"900"}
                      lineHeight={"130%"}
                    >
                      {currencyFormat(
                        safeAdd(
                          combinedTotalIncome,
                          combinedAssetSaleIncome,
                          combinedIncomePropertyIncome,
                          combinedBusinessIncome,
                          combinedInheritanceIncome
                        )
                      )}
                    </Text>
                  </Box>
                )}
                {spousePage != "joint-portfolio" && (
                  <Box
                    display={"flex"}
                    height={"26px"}
                    justifyContent={"space-between"}
                    alignItems={"flex-start"}
                    alignSelf={"stretch"}
                  >
                    <Text
                      color={"#004069"}
                      fontFamily={"Nunito"}
                      fontSize={"20px"}
                      fontStyle={"normal"}
                      fontWeight={"900"}
                      lineHeight={"130%"}
                    >
                      Total
                    </Text>
                    <Text
                      color={"#00A2D4"}
                      textAlign={"right"}
                      fontFamily={"Nunito"}
                      fontSize={"20px"}
                      fontStyle={"normal"}
                      fontWeight={"900"}
                      lineHeight={"130%"}
                    >
                      {currencyFormat(
                        safeAdd(
                          combinedTotalIncome,
                          combinedAssetSaleIncome / 2,
                          combinedIncomePropertyIncome / 2,
                          combinedBusinessIncome / 2,
                          combinedInheritanceIncome / 2
                        )
                      )}
                    </Text>
                  </Box>
                )}
              </>
            }
            Graph={
              <BarGraph
                type="income"
                income={income}
                combinedCPP={combinedCPP}
                combinedOAS={combinedOAS}
                combinedPension={combinedPension}
                combinedTotalIncome={combinedTotalIncome}
                combinedIncomePropertyIncome={combinedIncomePropertyIncome}
                combinedBusinessIncome={combinedBusinessIncome}
                combinedAssetSaleIncome={combinedAssetSaleIncome}
                combinedOtherIncome={combinedOtherIncome}
                combinedInheritanceIncome={combinedInheritanceIncome}
              />
            }
          />
        </>
      )}
    </>
  );
}

function Deposits(props) {
  const {
    isLargerThan1200,
    isSmallScreen,
    combinedTFSA,
    combinedFHSA,
    combinedRRSP,
    combinedNonReg,
    combinedRRSPContributions,
    combinedPensionContributions,
  } = props;


  return (
    <>
      {isSmallScreen && (
        <>
          <Box
            display={"flex"}
            flexDirection={"column"}
            width={"100%"}
            flexGrow={1}
            alignItems={"center"}
          >
            <Box display={"flex"} alignSelf={"center"} fill={"#00A2D4"}>
              <BarGraph
                type="deposits"
                combinedTFSA={combinedTFSA}
                combinedFHSA={combinedFHSA}
                combinedRRSP={safeAdd(combinedRRSP, combinedRRSPContributions)}
                combinedNonReg={combinedNonReg}
                combinedPensionContributions={combinedPensionContributions}
                combinedRRSPContributions={combinedRRSPContributions}
                combinedTotal={safeAdd(
                  combinedTFSA,
                  combinedFHSA,
                  combinedRRSP,
                  combinedRRSPContributions,
                  combinedNonReg,
                  combinedPensionContributions,
                  combinedRRSPContributions
                )}
              />
            </Box>
            <Box
              display={"flex"}
              width={"100%"}
              flexDirection={"column"}
              alignItems={"flex-start"}
              gap={"10px"}
            >
              <Box>
                <Text
                  color={"#00A2D4"}
                  textAlign={"right"}
                  fontFamily={"Nunito"}
                  fontSize={"24px"}
                  fontStyle={"normal"}
                  fontWeight={"800"}
                  lineHeight={"130%"}
                >
                  Deposits
                </Text>
              </Box>
              {combinedTFSA >= 1 && (
                <div className="activity-summary-subtitle-container">
                  <Text
                    color={"#004069"}
                    fontFamily={"Nunito"}
                    fontSize={"14px"}
                    fontStyle={"normal"}
                    fontWeight={"600"}
                    lineHeight={"130%"}
                  >
                    TFSA
                  </Text>
                  <Text
                    color={"#00A2D4"}
                    textAlign={"right"}
                    fontFamily={"Nunito"}
                    fontSize={"14px"}
                    fontStyle={"normal"}
                    fontWeight={"800"}
                    lineHeight={"130%"}
                  >
                    {currencyFormat(combinedTFSA)}
                  </Text>
                </div>
              )}
              {combinedFHSA >= 1 && (
                <div className="activity-summary-subtitle-container">
                  <Text
                    color={"#004069"}
                    fontFamily={"Nunito"}
                    fontSize={"14px"}
                    fontStyle={"normal"}
                    fontWeight={"600"}
                    lineHeight={"130%"}
                  >
                    FHSA
                  </Text>
                  <Text
                    color={"#00A2D4"}
                    textAlign={"right"}
                    fontFamily={"Nunito"}
                    fontSize={"14px"}
                    fontStyle={"normal"}
                    fontWeight={"800"}
                    lineHeight={"130%"}
                  >
                    {currencyFormat(combinedFHSA)}
                  </Text>
                </div>
              )}
              {combinedRRSP >= 1 && (
                <div className="activity-summary-subtitle-container">
                  <Text
                    color={"#004069"}
                    fontFamily={"Nunito"}
                    fontSize={"14px"}
                    fontStyle={"normal"}
                    fontWeight={"600"}
                    lineHeight={"130%"}
                  >
                    RRSP{" "}
                  </Text>
                  <Text
                    color={"#00A2D4"}
                    textAlign={"right"}
                    fontFamily={"Nunito"}
                    fontSize={"14px"}
                    fontStyle={"normal"}
                    fontWeight={"800"}
                    lineHeight={"130%"}
                  >
                    {currencyFormat(
                      safeAdd(combinedRRSP, combinedRRSPContributions)
                    )}
                  </Text>
                </div>
              )}
              {combinedRRSPContributions >= 1 && (
                <div className="activity-summary-subtitle-container">
                  <Text
                    color={"#004069"}
                    fontFamily={"Nunito"}
                    fontSize={"14px"}
                    fontStyle={"normal"}
                    fontWeight={"600"}
                    lineHeight={"130%"}
                  >
                    Employer{" "}
                    <span className="mobile-hidden">RRSP Contributions</span>
                  </Text>
                  <Text
                    color={"#00A2D4"}
                    textAlign={"right"}
                    fontFamily={"Nunito"}
                    fontSize={"14px"}
                    fontStyle={"normal"}
                    fontWeight={"800"}
                    lineHeight={"130%"}
                  >
                    {currencyFormat(combinedRRSPContributions)}
                  </Text>
                </div>
              )}
              {combinedPensionContributions >= 1 && (
                <div className="activity-summary-subtitle-container">
                  <Text
                    color={"#004069"}
                    fontFamily={"Nunito"}
                    fontSize={"14px"}
                    fontStyle={"normal"}
                    fontWeight={"600"}
                    lineHeight={"130%"}
                  >
                    Employer{" "}
                    <span className="mobile-hidden">Pension Contributions</span>
                  </Text>
                  <Text
                    color={"#00A2D4"}
                    textAlign={"right"}
                    fontFamily={"Nunito"}
                    fontSize={"14px"}
                    fontStyle={"normal"}
                    fontWeight={"800"}
                    lineHeight={"130%"}
                  >
                    {currencyFormat(combinedPensionContributions)}
                  </Text>
                </div>
              )}
              {combinedNonReg >= 1 && (
                <div className="activity-summary-subtitle-container">
                  <Text
                    color={"#004069"}
                    fontFamily={"Nunito"}
                    fontSize={"14px"}
                    fontStyle={"normal"}
                    fontWeight={"600"}
                    lineHeight={"130%"}
                  >
                    Non-Reg<span className="mobile-hidden">istered</span>
                  </Text>

                  <Text
                    color={"#00A2D4"}
                    textAlign={"right"}
                    fontFamily={"Nunito"}
                    fontSize={"14px"}
                    fontStyle={"normal"}
                    fontWeight={"800"}
                    lineHeight={"130%"}
                  >
                    {currencyFormat(combinedNonReg)}
                  </Text>
                </div>
              )}

              {combinedPensionContributions >= 1 && (
                <div className="activity-summary-subtitle-container">
                  <Text
                    color={"#004069"}
                    fontFamily={"Nunito"}
                    fontSize={"14px"}
                    fontStyle={"normal"}
                    fontWeight={"600"}
                    lineHeight={"130%"}
                  >
                    Pension <span className="mobile-hidden">contributions</span>
                  </Text>
                  <Text
                    color={"#00A2D4"}
                    textAlign={"right"}
                    fontFamily={"Nunito"}
                    fontSize={"14px"}
                    fontStyle={"normal"}
                    fontWeight={"800"}
                    lineHeight={"130%"}
                  >
                    {currencyFormat(combinedPensionContributions)}
                  </Text>
                </div>
              )}

              <Box
                display={"flex"}
                height={"26px"}
                justifyContent={"space-between"}
                alignItems={"flex-start"}
                alignSelf={"stretch"}
              >
                <Text
                  color={"#004069"}
                  fontFamily={"Nunito"}
                  fontSize={"20px"}
                  fontStyle={"normal"}
                  fontWeight={"900"}
                  lineHeight={"130%"}
                >
                  Total
                </Text>
                <Text
                  color={"#00A2D4"}
                  textAlign={"right"}
                  fontFamily={"Nunito"}
                  fontSize={"20px"}
                  fontStyle={"normal"}
                  fontWeight={"900"}
                  lineHeight={"130%"}
                >
                  {currencyFormat(
                    safeAdd(
                      combinedTFSA,
                      combinedFHSA,
                      combinedRRSP,
                      combinedRRSPContributions,
                      combinedNonReg,
                      combinedPensionContributions,
                      combinedRRSPContributions
                    )
                  )}
                </Text>
              </Box>
            </Box>
          </Box>
        </>
      )}
      {!isSmallScreen && (
        <DashboardSubCards
          Heading={"Deposits"}
          mainframecontent={
            <>
              {combinedTFSA >= 1 && (
                <div className="activity-summary-subtitle-container">
                  <Text
                    color={"#004069"}
                    fontFamily={"Nunito"}
                    fontSize={"14px"}
                    fontStyle={"normal"}
                    fontWeight={"600"}
                    lineHeight={"130%"}
                  >
                    TFSA
                  </Text>
                  <Text
                    color={"#00A2D4"}
                    textAlign={"right"}
                    fontFamily={"Nunito"}
                    fontSize={"14px"}
                    fontStyle={"normal"}
                    fontWeight={"800"}
                    lineHeight={"130%"}
                  >
                    {currencyFormat(combinedTFSA)}
                  </Text>
                </div>
              )}
              {combinedFHSA >= 1 && (
                <div className="activity-summary-subtitle-container">
                  <Text
                    color={"#004069"}
                    fontFamily={"Nunito"}
                    fontSize={"14px"}
                    fontStyle={"normal"}
                    fontWeight={"600"}
                    lineHeight={"130%"}
                  >
                    FHSA
                  </Text>
                  <Text
                    color={"#00A2D4"}
                    textAlign={"right"}
                    fontFamily={"Nunito"}
                    fontSize={"14px"}
                    fontStyle={"normal"}
                    fontWeight={"800"}
                    lineHeight={"130%"}
                  >
                    {currencyFormat(combinedFHSA)}
                  </Text>
                </div>
              )}
              {combinedRRSP >= 1 && (
                <div className="activity-summary-subtitle-container">
                  <Text
                    color={"#004069"}
                    fontFamily={"Nunito"}
                    fontSize={"14px"}
                    fontStyle={"normal"}
                    fontWeight={"600"}
                    lineHeight={"130%"}
                  >
                    RRSP{" "}
                  </Text>
                  <Text
                    color={"#00A2D4"}
                    textAlign={"right"}
                    fontFamily={"Nunito"}
                    fontSize={"14px"}
                    fontStyle={"normal"}
                    fontWeight={"800"}
                    lineHeight={"130%"}
                  >
                    {currencyFormat(combinedRRSP)}
                  </Text>
                </div>
              )}
              {combinedRRSPContributions >= 1 && (
                <div className="activity-summary-subtitle-container">
                  <Text
                    color={"#004069"}
                    fontFamily={"Nunito"}
                    fontSize={"14px"}
                    fontStyle={"normal"}
                    fontWeight={"600"}
                    lineHeight={"130%"}
                  >
                    Employer{" "}
                    <span className="mobile-hidden">RRSP Contributions</span>
                  </Text>
                  <Text
                    color={"#00A2D4"}
                    textAlign={"right"}
                    fontFamily={"Nunito"}
                    fontSize={"14px"}
                    fontStyle={"normal"}
                    fontWeight={"800"}
                    lineHeight={"130%"}
                  >
                    {currencyFormat(combinedRRSPContributions)}
                  </Text>
                </div>
              )}
              {combinedPensionContributions >= 1 && (
                <div className="activity-summary-subtitle-container">
                  <Text
                    color={"#004069"}
                    fontFamily={"Nunito"}
                    fontSize={"14px"}
                    fontStyle={"normal"}
                    fontWeight={"600"}
                    lineHeight={"130%"}
                  >
                    Employer{" "}
                    <span className="mobile-hidden">Pension Contributions</span>
                  </Text>
                  <Text
                    color={"#00A2D4"}
                    textAlign={"right"}
                    fontFamily={"Nunito"}
                    fontSize={"14px"}
                    fontStyle={"normal"}
                    fontWeight={"800"}
                    lineHeight={"130%"}
                  >
                    {currencyFormat(combinedPensionContributions)}
                  </Text>
                </div>
              )}
              {combinedNonReg >= 1 && (
                <div className="activity-summary-subtitle-container">
                  <Text
                    color={"#004069"}
                    fontFamily={"Nunito"}
                    fontSize={"14px"}
                    fontStyle={"normal"}
                    fontWeight={"600"}
                    lineHeight={"130%"}
                  >
                    Non-Reg<span className="mobile-hidden">istered</span>
                  </Text>

                  <Text
                    color={"#00A2D4"}
                    textAlign={"right"}
                    fontFamily={"Nunito"}
                    fontSize={"14px"}
                    fontStyle={"normal"}
                    fontWeight={"800"}
                    lineHeight={"130%"}
                  >
                    {currencyFormat(combinedNonReg)}
                  </Text>
                </div>
              )}

              {combinedPensionContributions >= 1 && (
                <div className="activity-summary-subtitle-container">
                  <Text
                    color={"#004069"}
                    fontFamily={"Nunito"}
                    fontSize={"14px"}
                    fontStyle={"normal"}
                    fontWeight={"600"}
                    lineHeight={"130%"}
                  >
                    Pension <span className="mobile-hidden">contributions</span>
                  </Text>
                  <Text
                    color={"#00A2D4"}
                    textAlign={"right"}
                    fontFamily={"Nunito"}
                    fontSize={"14px"}
                    fontStyle={"normal"}
                    fontWeight={"800"}
                    lineHeight={"130%"}
                  >
                    {currencyFormat(combinedPensionContributions)}
                  </Text>
                </div>
              )}

              <Box
                display={"flex"}
                height={"26px"}
                justifyContent={"space-between"}
                alignItems={"flex-start"}
                alignSelf={"stretch"}
              >
                <Text
                  color={"#004069"}
                  fontFamily={"Nunito"}
                  fontSize={"20px"}
                  fontStyle={"normal"}
                  fontWeight={"900"}
                  lineHeight={"130%"}
                >
                  Total
                </Text>
                <Text
                  color={"#00A2D4"}
                  textAlign={"right"}
                  fontFamily={"Nunito"}
                  fontSize={"20px"}
                  fontStyle={"normal"}
                  fontWeight={"900"}
                  lineHeight={"130%"}
                >
                  {currencyFormat(
                    safeAdd(
                      combinedTFSA,
                      combinedFHSA,
                      combinedRRSP,
                      combinedNonReg,
                      combinedPensionContributions,
                      combinedRRSPContributions
                    )
                  )}
                </Text>
              </Box>
            </>
          }
          Graph={
            <BarGraph
              name={"Deposits"}
              type="deposits"
              combinedTFSA={combinedTFSA}
              combinedFHSA={combinedFHSA}
              combinedRRSP={safeAdd(combinedRRSP, combinedRRSPContributions)}
              combinedNonReg={combinedNonReg}
              combinedPensionContributions={combinedPensionContributions}
              combinedRRSPContributions={combinedRRSPContributions}
              combinedTotal={safeAdd(
                combinedTFSA,
                combinedFHSA,
                combinedRRSP,
                combinedRRSPContributions,
                combinedNonReg,
                combinedPensionContributions,
                combinedRRSPContributions
              )}
            />
          }
        />
      )}
    </>
  );
}

function ActionPlan(props) {
  const {
    combinedTFSA,
    combinedRRSP,
    combinedNonReg,
    combinedCashWithdrawals,
    combinedFixedWithdrawals,
    combinedRRSPContributions,
    combinedPensionContributions,
    name,
    combinedTFSAWithdrawals,
    combinedRRSPWithdrawals,
    combinedNonRegWithdrawals,
    combinedLIRAWithdrawals,
    combinedCCPCCapitalDividends,
    combinedCCPCEligibleDividends,
    combinedCCPCNonEligibleDividends,
  } = props;
  const userTFSAInfo = useStore((store) => store.userTFSAInfo);
  const userRRSPInfo = useStore((store) => store.userRRSPInfo);
  const userFixedIncomeInfo = useStore((store) => store.userFixedIncomeInfo);
  const userNonRegInvestmentsInfo = useStore(
    (store) => store.userNonRegInvestmentsInfo
  );
  const userBusinessInfo = useStore((store) => store.userBusinessInfo);
  const userPensionInfo = useStore((store) => store.userPensionInfo);
  const userLiraInfo = useStore((store) => store.userLiraInfo);
  let singleFoundItem = userPensionInfo?.find(
    (item) =>
      item.spouseCheck === 0 && item.Pension_Type != "Defined Benefit Plan"
  );

  return (
    <div className="card-container">
      <div className="activity-summary-card-action activity-summary-mobile-card">
        <div className="activity-summary-subtitle-container-action">
          <div className="activity-summary-subtitle-3-action-name">{name}</div>
        </div>
        {combinedTFSAWithdrawals >= 50 && (
          <div className="activity-summary-subtitle-container-action">
            <div className="activity-summary-subtitle-3-action">
              TFSA Total Annual Withdrawals:
            </div>
            <div className="activity-summary-data-3-action">
              {currencyFormat(combinedTFSAWithdrawals)}
            </div>
          </div>
        )}
        {combinedTFSAWithdrawals >= 50 && (
          <span style={{ marginBottom: "1rem", fontSize: "1rem" }}>
            Assuming TFSA grows {userTFSAInfo?.TFSA_ROR}% annually
          </span>
        )}
        {combinedRRSPWithdrawals >= 50 && (
          <div className="activity-summary-subtitle-container-action">
            <div className="activity-summary-subtitle-3-action">
              RRSP Total Annual Withdrawals:
            </div>
            <div className="activity-summary-data-3-action">
              {currencyFormat(combinedRRSPWithdrawals)}
            </div>
          </div>
        )}
        {combinedRRSPWithdrawals >= 50 && (
          <span style={{ marginBottom: "1rem", fontSize: "1rem" }}>
            Assuming RRSP grows {userRRSPInfo?.RRSP_ROR}% annually
          </span>
        )}
        {combinedNonRegWithdrawals >= 50 && (
          <div className="activity-summary-subtitle-container-action">
            <div className="activity-summary-subtitle-3-action">
              Non-Reg<span className="mobile-hidden">istered</span> Total Annual
              Withdrawals:
            </div>
            <div className="activity-summary-data-3-action">
              {currencyFormat(combinedNonRegWithdrawals)}
            </div>
          </div>
        )}
        {combinedNonRegWithdrawals >= 50 && (
          <span style={{ marginBottom: "1rem", fontSize: "1rem" }}>
            Assuming Non-Registered grows{" "}
            {userNonRegInvestmentsInfo?.Non_Reg_ROR}% annually
          </span>
        )}
        {combinedCashWithdrawals >= 50 && (
          <div className="activity-summary-subtitle-container-action">
            <div className="activity-summary-subtitle-3-action">
              Cash Total Annual Withdrawals:
            </div>
            <div className="activity-summary-data-3-action">
              {currencyFormat(combinedCashWithdrawals)}
            </div>
          </div>
        )}
        {combinedCashWithdrawals >= 50 && (
          <span style={{ marginBottom: "1rem", fontSize: "1rem" }}>
            0% growth annually
          </span>
        )}
        {combinedFixedWithdrawals >= 50 && (
          <div className="activity-summary-subtitle-container-action">
            <div className="activity-summary-subtitle-3-action">
              Fixed Income Total Annual Withdrawals:
            </div>
            <div className="activity-summary-data-3-action">
              {currencyFormat(combinedFixedWithdrawals)}
            </div>
          </div>
        )}
        {combinedFixedWithdrawals >= 50 && (
          <span style={{ marginBottom: "1rem", fontSize: "1rem" }}>
            Assuming Non-Registered grows{" "}
            {userNonRegInvestmentsInfo?.Non_Reg_ROR}% annually
          </span>
        )}
        {combinedCCPCCapitalDividends >= 50 && (
          <div className="activity-summary-subtitle-container-action">
            <div className="activity-summary-subtitle-3-action">
              CCPC Capital Dividends Issued:
            </div>
            <div className="activity-summary-data-3-action">
              {currencyFormat(combinedCCPCCapitalDividends)}
            </div>
          </div>
        )}
        {combinedCCPCCapitalDividends >= 50 && (
          <span style={{ marginBottom: "1rem", fontSize: "1rem" }}>
            Assuming CCPC Investment Fund grows{" "}
            {userBusinessInfo?.[0]?.ccpcGrowth}% annually
          </span>
        )}
        {combinedCCPCEligibleDividends >= 50 && (
          <div className="activity-summary-subtitle-container-action">
            <div className="activity-summary-subtitle-3-action">
              CCPC Eligible Dividends Issued:
            </div>
            <div className="activity-summary-data-3-action">
              {currencyFormat(combinedCCPCEligibleDividends)}
            </div>
          </div>
        )}
        {combinedCCPCEligibleDividends >= 50 && (
          <span style={{ marginBottom: "1rem", fontSize: "1rem" }}>
            Assuming CCPC Investment Fund grows{" "}
            {userBusinessInfo?.[0]?.ccpcGrowth}% annually
          </span>
        )}
        {combinedCCPCNonEligibleDividends >= 50 && (
          <div className="activity-summary-subtitle-container-action">
            <div className="activity-summary-subtitle-3-action">
              CCPC Non-Eligible Dividends Issued:
            </div>
            <div className="activity-summary-data-3-action">
              {currencyFormat(combinedCCPCNonEligibleDividends)}
            </div>
          </div>
        )}
        {combinedCCPCNonEligibleDividends >= 50 && (
          <span style={{ marginBottom: "1rem", fontSize: "1rem" }}>
            Assuming CCPC Investment Fund grows{" "}
            {userBusinessInfo?.[0]?.ccpcGrowth}% annually
          </span>
        )}
        {combinedLIRAWithdrawals >= 50 && (
          <div className="activity-summary-subtitle-container-action">
            <div className="activity-summary-subtitle-3-action">
              LIRA Total Annual Withdrawals:
            </div>
            <div className="activity-summary-data-3-action">
              {currencyFormat(combinedLIRAWithdrawals)}
            </div>
          </div>
        )}
        {combinedLIRAWithdrawals >= 50 && (
          <span style={{ marginBottom: "1rem", fontSize: "1rem" }}>
            Assuming LIRA grows {userLiraInfo?.LIRA_ROR}% annually
          </span>
        )}
        {combinedTFSA >= 50 && (
          <div className="activity-summary-subtitle-container-action">
            <div className="activity-summary-subtitle-3-action">
              TFSA Total Annual Deposits:
            </div>
            <div className="activity-summary-data-3-action">
              {currencyFormat(combinedTFSA)}
            </div>
          </div>
        )}
        {combinedTFSA >= 50 && (
          <span style={{ marginBottom: "1rem", fontSize: "1rem" }}>
            Assuming TFSA grows {userTFSAInfo?.TFSA_ROR}% annually
          </span>
        )}
        {combinedRRSP >= 50 && (
          <div className="activity-summary-subtitle-container-action">
            <div className="activity-summary-subtitle-3-action">
              RRSP Total Annual Deposits:
            </div>
            <div className="activity-summary-data-3-action">
              {currencyFormat(safeAdd(combinedRRSP, combinedRRSPContributions))}
            </div>
          </div>
        )}
        {combinedRRSP >= 50 && (
          <span style={{ marginBottom: "1rem", fontSize: "1rem" }}>
            Assuming RRSP grows {userRRSPInfo?.RRSP_ROR}% annually
          </span>
        )}
        {combinedRRSPContributions >= 50 && (
          <div className="activity-summary-subtitle-container-action">
            <div className="activity-summary-subtitle-3-action">
              Employer{" "}
              <span className="mobile-hidden">RRSP Contributions:</span>
            </div>
            <div className="activity-summary-data-3-action">
              {currencyFormat(combinedRRSPContributions)}
            </div>
          </div>
        )}
        {combinedRRSPContributions >= 50 && (
          <span style={{ marginBottom: "1rem", fontSize: "1rem" }}>
            Assuming RRSP grows {userRRSPInfo?.RRSP_ROR}% annually
          </span>
        )}
        {combinedPensionContributions >= 50 && (
          <div className="activity-summary-subtitle-container-action">
            <div className="activity-summary-subtitle-3-action">
              Employer{" "}
              <span className="mobile-hidden">Pension Contributions:</span>
            </div>
            <div className="activity-summary-data-3-action">
              {currencyFormat(combinedPensionContributions)}
            </div>
          </div>
        )}
        {combinedPensionContributions >= 50 && (
          <span style={{ marginBottom: "1rem", fontSize: "1rem" }}>
            Assuming Pension grows {singleFoundItem?.Pension_Index}% annually
          </span>
        )}
        {combinedNonReg >= 50 && (
          <div className="activity-summary-subtitle-container-action">
            <div className="activity-summary-subtitle-3-action">
              Non-Reg<span className="mobile-hidden">istered</span> Total Annual
              Deposits:
            </div>
            <div className="activity-summary-data-3-action">
              {currencyFormat(combinedNonReg)}
            </div>
          </div>
        )}
        {combinedNonReg >= 50 && (
          <span style={{ marginBottom: "1rem", fontSize: "1rem" }}>
            Assuming Non-Registered grows{" "}
            {userNonRegInvestmentsInfo?.Non_Reg_ROR}% annually
          </span>
        )}
        {combinedPensionContributions >= 50 && (
          <div className="activity-summary-subtitle-container-action">
            <div className="activity-summary-subtitle-3-action">
              Pension{" "}
              <span className="mobile-hidden">Total Annual Deposits:</span>
            </div>
            <div className="activity-summary-data-3-action">
              {currencyFormat(combinedPensionContributions)}
            </div>
          </div>
        )}
        {combinedPensionContributions >= 50 && (
          <span style={{ marginBottom: "1rem", fontSize: "1rem" }}>
            Assuming Pension grows {singleFoundItem?.Pension_Index}% annually
          </span>
        )}
      </div>
    </div>
  );
}

function Withdrawals(props) {
  const {
    isLargerThan1200,
    isSmallScreen,
    combinedTFSA,
    combinedFHSA,
    combinedRRSP,
    combinedNonReg,
    combinedCashWithdrawals,
    combinedFixedWithdrawals,
    combinedBusinessDividendWithdrawals,
    combinedLIRAWithdrawals,
  } = props;

  return (
    <>
      {isSmallScreen && (
        <>
          <Box
            display={"flex"}
            flexDirection={"column"}
            width={"100%"}
            flexGrow={1}
            alignItems={"center"}
          >
            <Box display={"flex"} alignSelf={"center"} fill={"#00A2D4"}>
              <BarGraph
                type="withdrawals"
                combinedTFSA={combinedTFSA}
                combinedFHSA={combinedFHSA}
                combinedRRSP={combinedRRSP}
                combinedNonReg={combinedNonReg}
                combinedCash={combinedCashWithdrawals}
                combinedFixedIncome={combinedFixedWithdrawals}
                combinedBusinessDividendWithdrawals={
                  combinedBusinessDividendWithdrawals
                }
                combinedLIRA={combinedLIRAWithdrawals}
                combinedTotal={safeAdd(
                  combinedTFSA,
                  combinedFHSA,
                  combinedRRSP,
                  combinedNonReg,
                  combinedLIRAWithdrawals,
                  combinedBusinessDividendWithdrawals
                )}
              />
            </Box>
            <Box
              display={"flex"}
              width={"100%"}
              flexDirection={"column"}
              alignItems={"flex-start"}
              gap={"10px"}
            >
              <Box>
                <Text
                  color={"#00A2D4"}
                  textAlign={"right"}
                  fontFamily={"Nunito"}
                  fontSize={"24px"}
                  fontStyle={"normal"}
                  fontWeight={"800"}
                  lineHeight={"130%"}
                >
                  Withdrawals
                </Text>
              </Box>
              {combinedTFSA >= 1 && (
                <div className="activity-summary-subtitle-container">
                  <Text
                    color={"#004069"}
                    fontFamily={"Nunito"}
                    fontSize={"14px"}
                    fontStyle={"normal"}
                    fontWeight={"600"}
                    lineHeight={"130%"}
                  >
                    TFSA
                  </Text>

                  <Text
                    color={"#00A2D4"}
                    textAlign={"right"}
                    fontFamily={"Nunito"}
                    fontSize={"14px"}
                    fontStyle={"normal"}
                    fontWeight={"800"}
                    lineHeight={"130%"}
                  >
                    {currencyFormat(combinedTFSA)}
                  </Text>
                </div>
              )}
              {combinedFHSA >= 1 && (
                <div className="activity-summary-subtitle-container">
                  <Text
                    color={"#004069"}
                    fontFamily={"Nunito"}
                    fontSize={"14px"}
                    fontStyle={"normal"}
                    fontWeight={"600"}
                    lineHeight={"130%"}
                  >
                    FHSA
                  </Text>

                  <Text
                    color={"#00A2D4"}
                    textAlign={"right"}
                    fontFamily={"Nunito"}
                    fontSize={"14px"}
                    fontStyle={"normal"}
                    fontWeight={"800"}
                    lineHeight={"130%"}
                  >
                    {currencyFormat(combinedFHSA)}
                  </Text>
                </div>
              )}
              {combinedRRSP >= 1 && (
                <div className="activity-summary-subtitle-container">
                  <Text
                    color={"#004069"}
                    fontFamily={"Nunito"}
                    fontSize={"14px"}
                    fontStyle={"normal"}
                    fontWeight={"600"}
                    lineHeight={"130%"}
                  >
                    RRSP/RRIF{" "}
                  </Text>

                  <Text
                    color={"#00A2D4"}
                    textAlign={"right"}
                    fontFamily={"Nunito"}
                    fontSize={"14px"}
                    fontStyle={"normal"}
                    fontWeight={"800"}
                    lineHeight={"130%"}
                  >
                    {currencyFormat(combinedRRSP)}
                  </Text>
                </div>
              )}
              {combinedLIRAWithdrawals >= 1 && (
                <div className="activity-summary-subtitle-container">
                  <Text
                    color={"#004069"}
                    fontFamily={"Nunito"}
                    fontSize={"14px"}
                    fontStyle={"normal"}
                    fontWeight={"600"}
                    lineHeight={"130%"}
                  >
                    LIF
                  </Text>
                  <Text
                    color={"#00A2D4"}
                    textAlign={"right"}
                    fontFamily={"Nunito"}
                    fontSize={"14px"}
                    fontStyle={"normal"}
                    fontWeight={"800"}
                    lineHeight={"130%"}
                  >
                    {currencyFormat(combinedLIRAWithdrawals)}
                  </Text>
                </div>
              )}
              {combinedNonReg >= 1 && (
                <div className="activity-summary-subtitle-container">
                  <Text
                    color={"#004069"}
                    fontFamily={"Nunito"}
                    fontSize={"14px"}
                    fontStyle={"normal"}
                    fontWeight={"600"}
                    lineHeight={"130%"}
                  >
                    Non-Reg<span className="mobile-hidden">istered</span>
                  </Text>
                  <Text
                    color={"#00A2D4"}
                    textAlign={"right"}
                    fontFamily={"Nunito"}
                    fontSize={"14px"}
                    fontStyle={"normal"}
                    fontWeight={"800"}
                    lineHeight={"130%"}
                  >
                    {currencyFormat(combinedNonReg)}
                  </Text>
                </div>
              )}
              {combinedCashWithdrawals >= 1 && (
                <div className="activity-summary-subtitle-container">
                  <Text
                    color={"#004069"}
                    fontFamily={"Nunito"}
                    fontSize={"14px"}
                    fontStyle={"normal"}
                    fontWeight={"600"}
                    lineHeight={"130%"}
                  >
                    Cash
                  </Text>
                  <Text
                    color={"#00A2D4"}
                    textAlign={"right"}
                    fontFamily={"Nunito"}
                    fontSize={"14px"}
                    fontStyle={"normal"}
                    fontWeight={"800"}
                    lineHeight={"130%"}
                  >
                    {currencyFormat(combinedCashWithdrawals)}
                  </Text>
                </div>
              )}
              {combinedFixedWithdrawals >= 1 && (
                <div className="activity-summary-subtitle-container">
                  <Text
                    color={"#004069"}
                    fontFamily={"Nunito"}
                    fontSize={"14px"}
                    fontStyle={"normal"}
                    fontWeight={"600"}
                    lineHeight={"130%"}
                  >
                    Fixed Income
                  </Text>
                  <Text
                    color={"#00A2D4"}
                    textAlign={"right"}
                    fontFamily={"Nunito"}
                    fontSize={"14px"}
                    fontStyle={"normal"}
                    fontWeight={"800"}
                    lineHeight={"130%"}
                  >
                    {currencyFormat(combinedFixedWithdrawals)}
                  </Text>
                </div>
              )}
              {combinedBusinessDividendWithdrawals >= 1 && (
                <div className="activity-summary-subtitle-container">
                  <Text
                    color={"#004069"}
                    fontFamily={"Nunito"}
                    fontSize={"14px"}
                    fontStyle={"normal"}
                    fontWeight={"600"}
                    lineHeight={"130%"}
                  >
                    CCPC Investment Dividends
                  </Text>
                  <Text
                    color={"#00A2D4"}
                    textAlign={"right"}
                    fontFamily={"Nunito"}
                    fontSize={"14px"}
                    fontStyle={"normal"}
                    fontWeight={"800"}
                    lineHeight={"130%"}
                  >
                    {currencyFormat(combinedBusinessDividendWithdrawals)}
                  </Text>
                </div>
              )}
              <Box
                display={"flex"}
                height={"26px"}
                justifyContent={"space-between"}
                alignItems={"flex-start"}
                alignSelf={"stretch"}
              >
                <Text
                  color={"#004069"}
                  fontFamily={"Nunito"}
                  fontSize={"20px"}
                  fontStyle={"normal"}
                  fontWeight={"900"}
                  lineHeight={"130%"}
                >
                  Total
                </Text>
                <Text
                  color={"#00A2D4"}
                  textAlign={"right"}
                  fontFamily={"Nunito"}
                  fontSize={"20px"}
                  fontStyle={"normal"}
                  fontWeight={"900"}
                  lineHeight={"130%"}
                >
                  {currencyFormat(
                    safeAdd(
                      combinedTFSA,
                      combinedFHSA,
                      combinedRRSP,
                      combinedNonReg,
                      combinedCashWithdrawals,
                      combinedFixedWithdrawals,
                      combinedBusinessDividendWithdrawals,
                      combinedLIRAWithdrawals
                    )
                  )}
                </Text>
              </Box>
            </Box>
          </Box>
        </>
      )}
      {!isSmallScreen && (
        <DashboardSubCards
          Heading={"Withdrawals"}
          mainframecontent={
            <>
              {combinedTFSA >= 1 && (
                <div className="activity-summary-subtitle-container">
                  <Text
                    color={"#004069"}
                    fontFamily={"Nunito"}
                    fontSize={"14px"}
                    fontStyle={"normal"}
                    fontWeight={"600"}
                    lineHeight={"130%"}
                  >
                    TFSA
                  </Text>

                  <Text
                    color={"#00A2D4"}
                    textAlign={"right"}
                    fontFamily={"Nunito"}
                    fontSize={"14px"}
                    fontStyle={"normal"}
                    fontWeight={"800"}
                    lineHeight={"130%"}
                  >
                    {currencyFormat(combinedTFSA)}
                  </Text>
                </div>
              )}
              {combinedFHSA >= 1 && (
                <div className="activity-summary-subtitle-container">
                  <Text
                    color={"#004069"}
                    fontFamily={"Nunito"}
                    fontSize={"14px"}
                    fontStyle={"normal"}
                    fontWeight={"600"}
                    lineHeight={"130%"}
                  >
                    FHSA
                  </Text>

                  <Text
                    color={"#00A2D4"}
                    textAlign={"right"}
                    fontFamily={"Nunito"}
                    fontSize={"14px"}
                    fontStyle={"normal"}
                    fontWeight={"800"}
                    lineHeight={"130%"}
                  >
                    {currencyFormat(combinedFHSA)}
                  </Text>
                </div>
              )}
              {combinedRRSP >= 1 && (
                <div className="activity-summary-subtitle-container">
                  <Text
                    color={"#004069"}
                    fontFamily={"Nunito"}
                    fontSize={"14px"}
                    fontStyle={"normal"}
                    fontWeight={"600"}
                    lineHeight={"130%"}
                  >
                    RRSP/RRIF{" "}
                  </Text>

                  <Text
                    color={"#00A2D4"}
                    textAlign={"right"}
                    fontFamily={"Nunito"}
                    fontSize={"14px"}
                    fontStyle={"normal"}
                    fontWeight={"800"}
                    lineHeight={"130%"}
                  >
                    {currencyFormat(combinedRRSP)}
                  </Text>
                </div>
              )}
              {combinedLIRAWithdrawals >= 1 && (
                <div className="activity-summary-subtitle-container">
                  <Text
                    color={"#004069"}
                    fontFamily={"Nunito"}
                    fontSize={"14px"}
                    fontStyle={"normal"}
                    fontWeight={"600"}
                    lineHeight={"130%"}
                  >
                    LIF
                  </Text>
                  <Text
                    color={"#00A2D4"}
                    textAlign={"right"}
                    fontFamily={"Nunito"}
                    fontSize={"14px"}
                    fontStyle={"normal"}
                    fontWeight={"800"}
                    lineHeight={"130%"}
                  >
                    {currencyFormat(combinedLIRAWithdrawals)}
                  </Text>
                </div>
              )}
              {combinedNonReg >= 1 && (
                <div className="activity-summary-subtitle-container">
                  <Text
                    color={"#004069"}
                    fontFamily={"Nunito"}
                    fontSize={"14px"}
                    fontStyle={"normal"}
                    fontWeight={"600"}
                    lineHeight={"130%"}
                  >
                    Non-Reg<span className="mobile-hidden">istered</span>
                  </Text>
                  <Text
                    color={"#00A2D4"}
                    textAlign={"right"}
                    fontFamily={"Nunito"}
                    fontSize={"14px"}
                    fontStyle={"normal"}
                    fontWeight={"800"}
                    lineHeight={"130%"}
                  >
                    {currencyFormat(combinedNonReg)}
                  </Text>
                </div>
              )}
              {combinedCashWithdrawals >= 1 && (
                <div className="activity-summary-subtitle-container">
                  <Text
                    color={"#004069"}
                    fontFamily={"Nunito"}
                    fontSize={"14px"}
                    fontStyle={"normal"}
                    fontWeight={"600"}
                    lineHeight={"130%"}
                  >
                    Cash
                  </Text>
                  <Text
                    color={"#00A2D4"}
                    textAlign={"right"}
                    fontFamily={"Nunito"}
                    fontSize={"14px"}
                    fontStyle={"normal"}
                    fontWeight={"800"}
                    lineHeight={"130%"}
                  >
                    {currencyFormat(combinedCashWithdrawals)}
                  </Text>
                </div>
              )}
              {combinedFixedWithdrawals >= 1 && (
                <div className="activity-summary-subtitle-container">
                  <Text
                    color={"#004069"}
                    fontFamily={"Nunito"}
                    fontSize={"14px"}
                    fontStyle={"normal"}
                    fontWeight={"600"}
                    lineHeight={"130%"}
                  >
                    Fixed Income
                  </Text>
                  <Text
                    color={"#00A2D4"}
                    textAlign={"right"}
                    fontFamily={"Nunito"}
                    fontSize={"14px"}
                    fontStyle={"normal"}
                    fontWeight={"800"}
                    lineHeight={"130%"}
                  >
                    {currencyFormat(combinedFixedWithdrawals)}
                  </Text>
                </div>
              )}
              {combinedBusinessDividendWithdrawals >= 1 && (
                <div className="activity-summary-subtitle-container">
                  <Text
                    color={"#004069"}
                    fontFamily={"Nunito"}
                    fontSize={"14px"}
                    fontStyle={"normal"}
                    fontWeight={"600"}
                    lineHeight={"130%"}
                  >
                    CCPC Investment Dividends
                  </Text>
                  <Text
                    color={"#00A2D4"}
                    textAlign={"right"}
                    fontFamily={"Nunito"}
                    fontSize={"14px"}
                    fontStyle={"normal"}
                    fontWeight={"800"}
                    lineHeight={"130%"}
                  >
                    {currencyFormat(combinedBusinessDividendWithdrawals)}
                  </Text>
                </div>
              )}
              <Box
                display={"flex"}
                height={"26px"}
                justifyContent={"space-between"}
                alignItems={"flex-start"}
                alignSelf={"stretch"}
              >
                <Text
                  color={"#004069"}
                  fontFamily={"Nunito"}
                  fontSize={"20px"}
                  fontStyle={"normal"}
                  fontWeight={"900"}
                  lineHeight={"130%"}
                >
                  Total
                </Text>
                <Text
                  color={"#00A2D4"}
                  textAlign={"right"}
                  fontFamily={"Nunito"}
                  fontSize={"20px"}
                  fontStyle={"normal"}
                  fontWeight={"900"}
                  lineHeight={"130%"}
                >
                  {currencyFormat(
                    safeAdd(
                      combinedTFSA,
                      combinedFHSA,
                      combinedRRSP,
                      combinedNonReg,
                      combinedCashWithdrawals,
                      combinedFixedWithdrawals,
                      combinedBusinessDividendWithdrawals,
                      combinedLIRAWithdrawals
                    )
                  )}
                </Text>
              </Box>
            </>
          }
          Graph={
            <BarGraph
              type="withdrawals"
              combinedTFSA={combinedTFSA}
              combinedFHSA={combinedFHSA}
              combinedRRSP={combinedRRSP}
              combinedNonReg={combinedNonReg}
              combinedCash={combinedCashWithdrawals}
              combinedFixedIncome={combinedFixedWithdrawals}
              combinedBusinessDividendWithdrawals={
                combinedBusinessDividendWithdrawals
              }
              combinedLIRA={combinedLIRAWithdrawals}
              combinedTotal={safeAdd(
                combinedTFSA,
                combinedFHSA,
                combinedRRSP,
                combinedNonReg,
                combinedLIRAWithdrawals,
                combinedBusinessDividendWithdrawals
              )}
            />
          }
        />
      )}
    </>
  );
}

function Taxes(props) {
  const {
    isLargerThan1200,
    isSmallScreen,
    provincialTax,
    federalTax,
    cppContributions,
    eiPremium,
    taxesTotal,
    averageTaxRate,
    combinedDeductions,
  } = props;
  const userInfo = useStore((store) => store.userInfo);

  return (
    <>
      {isSmallScreen && (
        <>
          <Box
            display={"flex"}
            flexDirection={"column"}
            width={"100%"}
            flexGrow={1}
            alignItems={"center"}
          >
            <Box display={"flex"} alignSelf={"center"} fill={"#00A2D4"}>
              <BarGraph
                type="taxes"
                provincial={provincialTax}
                federal={federalTax}
                cpp={cppContributions}
                ei={eiPremium}
                combinedTotal={taxesTotal}
              />
            </Box>
            <Box
              display={"flex"}
              width={"100%"}
              flexDirection={"column"}
              alignItems={"flex-start"}
              gap={"10px"}
            >
              <Box>
                <Text
                  color={"#00A2D4"}
                  textAlign={"right"}
                  fontFamily={"Nunito"}
                  fontSize={"24px"}
                  fontStyle={"normal"}
                  fontWeight={"800"}
                  lineHeight={"130%"}
                >
                  Taxes
                </Text>
              </Box>

              {provincialTax >= 1 && (
                <div className="activity-summary-subtitle-container">
                  <Text
                    color={"#004069"}
                    fontFamily={"Nunito"}
                    fontSize={"14px"}
                    fontStyle={"normal"}
                    fontWeight={"600"}
                    lineHeight={"130%"}
                  >
                    Provincial
                  </Text>

                  <Text
                    color={"#00A2D4"}
                    textAlign={"right"}
                    fontFamily={"Nunito"}
                    fontSize={"14px"}
                    fontStyle={"normal"}
                    fontWeight={"800"}
                    lineHeight={"130%"}
                  >
                    {currencyFormat(provincialTax)}
                  </Text>
                </div>
              )}
              {federalTax >= 1 && (
                <div className="activity-summary-subtitle-container">
                  <Text
                    color={"#004069"}
                    fontFamily={"Nunito"}
                    fontSize={"14px"}
                    fontStyle={"normal"}
                    fontWeight={"600"}
                    lineHeight={"130%"}
                  >
                    Federal
                  </Text>
                  <Text
                    color={"#00A2D4"}
                    textAlign={"right"}
                    fontFamily={"Nunito"}
                    fontSize={"14px"}
                    fontStyle={"normal"}
                    fontWeight={"800"}
                    lineHeight={"130%"}
                  >
                    {currencyFormat(federalTax)}
                  </Text>
                </div>
              )}
              {cppContributions >= 1 && (
                <div className="activity-summary-subtitle-container">
                  {userInfo?.Province != "QC" && (
                    <Text
                      color={"#004069"}
                      fontFamily={"Nunito"}
                      fontSize={"14px"}
                      fontStyle={"normal"}
                      fontWeight={"600"}
                      lineHeight={"130%"}
                    >
                      CPP Contributions
                    </Text>
                  )}
                  {userInfo?.Province === "QC" && (
                    <Text
                      color={"#004069"}
                      fontFamily={"Nunito"}
                      fontSize={"14px"}
                      fontStyle={"normal"}
                      fontWeight={"600"}
                      lineHeight={"130%"}
                    >
                      QPP Contributions
                    </Text>
                  )}
                  <Text
                    color={"#00A2D4"}
                    textAlign={"right"}
                    fontFamily={"Nunito"}
                    fontSize={"14px"}
                    fontStyle={"normal"}
                    fontWeight={"800"}
                    lineHeight={"130%"}
                  >
                    {currencyFormat(cppContributions)}
                  </Text>
                </div>
              )}
              {eiPremium >= 1 && (
                <div className="activity-summary-subtitle-container">
                  <Text
                    color={"#004069"}
                    fontFamily={"Nunito"}
                    fontSize={"14px"}
                    fontStyle={"normal"}
                    fontWeight={"600"}
                    lineHeight={"130%"}
                  >
                    EI Premium{" "}
                  </Text>
                  <Text
                    color={"#00A2D4"}
                    textAlign={"right"}
                    fontFamily={"Nunito"}
                    fontSize={"14px"}
                    fontStyle={"normal"}
                    fontWeight={"800"}
                    lineHeight={"130%"}
                  >
                    {currencyFormat(eiPremium)}
                  </Text>
                </div>
              )}
              {/* {combinedDeductions && (
            <div className="activity-summary-subtitle-container">
            <div className="activity-summary-subtitle-3">
              Deductions
            </div>
            <div className="activity-summary-data-3">{currencyFormat(combinedDeductions)}</div>
          </div>
          )} */}

              <Box
                display={"flex"}
                height={"26px"}
                justifyContent={"space-between"}
                alignItems={"flex-start"}
                alignSelf={"stretch"}
              >
                <Text
                  color={"#004069"}
                  fontFamily={"Nunito"}
                  fontSize={"20px"}
                  fontStyle={"normal"}
                  fontWeight={"900"}
                  lineHeight={"130%"}
                >
                  Total{" "}
                </Text>
                <Text
                  color={"#00A2D4"}
                  textAlign={"right"}
                  fontFamily={"Nunito"}
                  fontSize={"20px"}
                  fontStyle={"normal"}
                  fontWeight={"900"}
                  lineHeight={"130%"}
                >
                  {currencyFormat(taxesTotal)}
                </Text>

                {/* <div>
            <div className="activity-summary-average">Avg.</div>
            <div
              style={{ marginRight: "1rem" }}
              className="activity-summary-average-data"
            >
              {averageTaxRate}%
            </div>
          </div> */}
              </Box>
            </Box>
          </Box>
        </>
      )}
      {!isSmallScreen && (
        <DashboardSubCards
          Heading={"Taxes"}
          mainframecontent={
            <>
              {provincialTax >= 1 && (
                <div className="activity-summary-subtitle-container">
                  <Text
                    color={"#004069"}
                    fontFamily={"Nunito"}
                    fontSize={"14px"}
                    fontStyle={"normal"}
                    fontWeight={"600"}
                    lineHeight={"130%"}
                  >
                    Provincial
                  </Text>

                  <Text
                    color={"#00A2D4"}
                    textAlign={"right"}
                    fontFamily={"Nunito"}
                    fontSize={"14px"}
                    fontStyle={"normal"}
                    fontWeight={"800"}
                    lineHeight={"130%"}
                  >
                    {currencyFormat(provincialTax)}
                  </Text>
                </div>
              )}
              {federalTax >= 1 && (
                <div className="activity-summary-subtitle-container">
                  <Text
                    color={"#004069"}
                    fontFamily={"Nunito"}
                    fontSize={"14px"}
                    fontStyle={"normal"}
                    fontWeight={"600"}
                    lineHeight={"130%"}
                  >
                    Federal
                  </Text>
                  <Text
                    color={"#00A2D4"}
                    textAlign={"right"}
                    fontFamily={"Nunito"}
                    fontSize={"14px"}
                    fontStyle={"normal"}
                    fontWeight={"800"}
                    lineHeight={"130%"}
                  >
                    {currencyFormat(federalTax)}
                  </Text>
                </div>
              )}
              {cppContributions >= 1 && (
                <div className="activity-summary-subtitle-container">
                  {userInfo?.Province != "QC" && (
                    <Text
                      color={"#004069"}
                      fontFamily={"Nunito"}
                      fontSize={"14px"}
                      fontStyle={"normal"}
                      fontWeight={"600"}
                      lineHeight={"130%"}
                    >
                      CPP Contributions
                    </Text>
                  )}
                  {userInfo?.Province === "QC" && (
                    <Text
                      color={"#004069"}
                      fontFamily={"Nunito"}
                      fontSize={"14px"}
                      fontStyle={"normal"}
                      fontWeight={"600"}
                      lineHeight={"130%"}
                    >
                      QPP Contributions
                    </Text>
                  )}
                  <Text
                    color={"#00A2D4"}
                    textAlign={"right"}
                    fontFamily={"Nunito"}
                    fontSize={"14px"}
                    fontStyle={"normal"}
                    fontWeight={"800"}
                    lineHeight={"130%"}
                  >
                    {currencyFormat(cppContributions)}
                  </Text>
                </div>
              )}
              {eiPremium >= 1 && (
                <div className="activity-summary-subtitle-container">
                  <Text
                    color={"#004069"}
                    fontFamily={"Nunito"}
                    fontSize={"14px"}
                    fontStyle={"normal"}
                    fontWeight={"600"}
                    lineHeight={"130%"}
                  >
                    EI Premium{" "}
                  </Text>
                  <Text
                    color={"#00A2D4"}
                    textAlign={"right"}
                    fontFamily={"Nunito"}
                    fontSize={"14px"}
                    fontStyle={"normal"}
                    fontWeight={"800"}
                    lineHeight={"130%"}
                  >
                    {currencyFormat(eiPremium)}
                  </Text>
                </div>
              )}
              {/* {combinedDeductions && (
            <div className="activity-summary-subtitle-container">
            <div className="activity-summary-subtitle-3">
              Deductions
            </div>
            <div className="activity-summary-data-3">{currencyFormat(combinedDeductions)}</div>
          </div>
          )} */}

              <Box
                display={"flex"}
                height={"26px"}
                justifyContent={"space-between"}
                alignItems={"flex-start"}
                alignSelf={"stretch"}
              >
                <Text
                  color={"#004069"}
                  fontFamily={"Nunito"}
                  fontSize={"20px"}
                  fontStyle={"normal"}
                  fontWeight={"900"}
                  lineHeight={"130%"}
                >
                  Total{" "}
                </Text>
                <Text
                  color={"#00A2D4"}
                  textAlign={"right"}
                  fontFamily={"Nunito"}
                  fontSize={"20px"}
                  fontStyle={"normal"}
                  fontWeight={"900"}
                  lineHeight={"130%"}
                >
                  {currencyFormat(taxesTotal)}
                </Text>

                {/* <div>
            <div className="activity-summary-average">Avg.</div>
            <div
              style={{ marginRight: "1rem" }}
              className="activity-summary-average-data"
            >
              {averageTaxRate}%
            </div>
          </div> */}
              </Box>
            </>
          }
          Graph={
            <BarGraph
              type="taxes"
              provincial={provincialTax}
              federal={federalTax}
              cpp={cppContributions}
              ei={eiPremium}
              combinedTotal={taxesTotal}
            />
          }
        />
      )}
    </>
  );
}

function BudgetSummary(props) {
  const {
    isLargerThan1200,
    isSmallScreen,
    totalIncome,
    totalTaxes,
    totalDeposits,
    totalExpenses,
    thisYearDeposits,
    extraSpending,
    totalGoals,
    totalWithdrawals,
    spousePage,
    combinedAssetSaleIncome,
    combinedIncomePropertyIncome,
    combinedBusinessIncome,
    extraSpendingSpouse,
  } = props;

  return (
    <>
      {isSmallScreen && (
        <>
          <Box
            display={"flex"}
            flexDirection={"column"}
            width={"100%"}
            flexGrow={1}
            alignItems={"center"}
          >
            <Box display={"flex"} alignSelf={"center"} fill={"#00A2D4"}>
              <BarGraph
                type="income"
                totalIncome={totalIncome}
                totalTaxes={totalTaxes}
                totalDeposits={totalDeposits}
                totalExpenses={totalExpenses}
                thisYearDeposits={thisYearDeposits}
                extraSpending={extraSpending}
                totalGoals={totalGoals}
                combinedTotalIncome={safeAdd(
                  extraSpending,
                  totalExpenses,
                  thisYearDeposits,
                  totalGoals,
                  totalIncome,
                  totalTaxes,
                  totalDeposits
                )}
              />
            </Box>
            <Box
              display={"flex"}
              width={"100%"}
              flexDirection={"column"}
              alignItems={"flex-start"}
              gap={"10px"}
            >
              <Box>
                <Text
                  color={"#00A2D4"}
                  textAlign={"right"}
                  fontFamily={"Nunito"}
                  fontSize={"24px"}
                  fontStyle={"normal"}
                  fontWeight={"800"}
                  lineHeight={"130%"}
                >
                  Spending Summary
                </Text>
              </Box>

              {totalIncome >= 1 && spousePage === "joint-portfolio" && (
                <div className="activity-summary-subtitle-container">
                  <Text
                    color={"#004069"}
                    fontFamily={"Nunito"}
                    fontSize={"14px"}
                    fontStyle={"normal"}
                    fontWeight={"600"}
                    lineHeight={"130%"}
                  >
                    Total Income 
                  </Text>
                  <Text
                    color={"#00A2D4"}
                    textAlign={"right"}
                    fontFamily={"Nunito"}
                    fontSize={"14px"}
                    fontStyle={"normal"}
                    fontWeight={"800"}
                    lineHeight={"130%"}
                  >
                    {currencyFormat(totalIncome)}
                  </Text>
                </div>
              )}
              {totalIncome >= 1 && spousePage != "joint-portfolio" && (
                <div className="activity-summary-subtitle-container">
                  <Text
                    color={"#004069"}
                    fontFamily={"Nunito"}
                    fontSize={"14px"}
                    fontStyle={"normal"}
                    fontWeight={"600"}
                    lineHeight={"130%"}
                  >
                    Total Income
                  </Text>
                  <Text
                    color={"#00A2D4"}
                    textAlign={"right"}
                    fontFamily={"Nunito"}
                    fontSize={"14px"}
                    fontStyle={"normal"}
                    fontWeight={"800"}
                    lineHeight={"130%"}
                  >
                    {currencyFormat(
                      totalIncome -
                        safeAdd(
                          combinedAssetSaleIncome,
                          combinedIncomePropertyIncome,
                          combinedBusinessIncome
                        ) /
                          2
                    )}
                  </Text>
                </div>
              )}
              {thisYearDeposits >= 1 && (
                <div className="activity-summary-subtitle-container">
                  <Text
                    color={"#004069"}
                    fontFamily={"Nunito"}
                    fontSize={"14px"}
                    fontStyle={"normal"}
                    fontWeight={"600"}
                    lineHeight={"130%"}
                    overflowWrap={"break-word"}
                  >
                    Deposits already made this year
                  </Text>
                  <Text
                    color={"#00A2D4"}
                    textAlign={"right"}
                    fontFamily={"Nunito"}
                    fontSize={"14px"}
                    fontStyle={"normal"}
                    fontWeight={"800"}
                    lineHeight={"130%"}
                  >
                    - {currencyFormat(thisYearDeposits)}
                  </Text>
                </div>
              )}
              {totalWithdrawals >= 1 && (
                <div className="activity-summary-subtitle-container">
                  <Text
                    color={"#004069"}
                    fontFamily={"Nunito"}
                    fontSize={"14px"}
                    fontStyle={"normal"}
                    fontWeight={"600"}
                    lineHeight={"130%"}
                  >
                    Total Withdrawals
                  </Text>
                  <Text
                    color={"#00A2D4"}
                    textAlign={"right"}
                    fontFamily={"Nunito"}
                    fontSize={"14px"}
                    fontStyle={"normal"}
                    fontWeight={"800"}
                    lineHeight={"130%"}
                  >
                    {currencyFormat(totalWithdrawals)}
                  </Text>
                </div>
              )}
              {totalDeposits >= 1 && (
                <div className="activity-summary-subtitle-container">
                  <Text
                    color={"#004069"}
                    fontFamily={"Nunito"}
                    fontSize={"14px"}
                    fontStyle={"normal"}
                    fontWeight={"600"}
                    lineHeight={"130%"}
                  >
                    Total Deposits
                  </Text>
                  <Text
                    color={"#00A2D4"}
                    textAlign={"right"}
                    fontFamily={"Nunito"}
                    fontSize={"14px"}
                    fontStyle={"normal"}
                    fontWeight={"800"}
                    lineHeight={"130%"}
                  >
                    - {currencyFormat(totalDeposits)}
                  </Text>
                </div>
              )}
              {totalTaxes >= 1 && (
                <div className="activity-summary-subtitle-container">
                  <Text
                    color={"#004069"}
                    fontFamily={"Nunito"}
                    fontSize={"14px"}
                    fontStyle={"normal"}
                    fontWeight={"600"}
                    lineHeight={"130%"}
                  >
                    Total taxes
                  </Text>
                  <Text
                    color={"#00A2D4"}
                    textAlign={"right"}
                    fontFamily={"Nunito"}
                    fontSize={"14px"}
                    fontStyle={"normal"}
                    fontWeight={"800"}
                    lineHeight={"130%"}
                  >
                    - {currencyFormat(totalTaxes)}
                  </Text>
                </div>
              )}
              {spousePage === "joint-portfolio" && (
                <Box
                  display={"flex"}
                  height={"26px"}
                  justifyContent={"space-between"}
                  alignItems={"flex-start"}
                  alignSelf={"stretch"}
                >
                  <Text
                    color={"#004069"}
                    fontFamily={"Nunito"}
                    fontSize={"20px"}
                    fontStyle={"normal"}
                    fontWeight={"900"}
                    lineHeight={"130%"}
                  >
                    Available Funds
                  </Text>
                  <Text
                    color={"#00A2D4"}
                    textAlign={"right"}
                    fontFamily={"Nunito"}
                    fontSize={"20px"}
                    fontStyle={"normal"}
                    fontWeight={"900"}
                    lineHeight={"130%"}
                  >
                    {currencyFormat(
                      safeAdd(extraSpending, totalExpenses, totalGoals)
                    )}
                  </Text>
                </Box>
              )}
              {spousePage != "joint-portfolio" && (
                <Box
                  display={"flex"}
                  height={"26px"}
                  justifyContent={"space-between"}
                  alignItems={"flex-start"}
                  alignSelf={"stretch"}
                >
                  <Text
                    color={"#004069"}
                    fontFamily={"Nunito"}
                    fontSize={"20px"}
                    fontStyle={"normal"}
                    fontWeight={"900"}
                    lineHeight={"130%"}
                  >
                    Available Funds 
                  </Text>
                  <Text
                    color={"#00A2D4"}
                    textAlign={"right"}
                    fontFamily={"Nunito"}
                    fontSize={"20px"}
                    fontStyle={"normal"}
                    fontWeight={"900"}
                    lineHeight={"130%"}
                  >
                    {currencyFormat(
                      safeAdd(
                        extraSpendingSpouse,
                      )
                    )}
                  </Text>
                </Box>
              )}
            </Box>
          </Box>
        </>
      )}
      {!isSmallScreen && (
        <DashboardSubCards
          Heading={"Spending Summary"}
          mainframecontent={
            <>
              {totalIncome >= 1 && spousePage === "joint-portfolio" && (
                <div className="activity-summary-subtitle-container">
                  <Text
                    color={"#004069"}
                    fontFamily={"Nunito"}
                    fontSize={"14px"}
                    fontStyle={"normal"}
                    fontWeight={"600"}
                    lineHeight={"130%"}
                  >
                    Total Income
                  </Text>
                  <Text
                    color={"#00A2D4"}
                    textAlign={"right"}
                    fontFamily={"Nunito"}
                    fontSize={"14px"}
                    fontStyle={"normal"}
                    fontWeight={"800"}
                    lineHeight={"130%"}
                  >
                    {currencyFormat(totalIncome)}
                  </Text>
                </div>
              )}
              {totalIncome >= 1 && spousePage != "joint-portfolio" && (
                <div className="activity-summary-subtitle-container">
                  <Text
                    color={"#004069"}
                    fontFamily={"Nunito"}
                    fontSize={"14px"}
                    fontStyle={"normal"}
                    fontWeight={"600"}
                    lineHeight={"130%"}
                  >
                    Total Income
                  </Text>
                  <Text
                    color={"#00A2D4"}
                    textAlign={"right"}
                    fontFamily={"Nunito"}
                    fontSize={"14px"}
                    fontStyle={"normal"}
                    fontWeight={"800"}
                    lineHeight={"130%"}
                  >
                    {currencyFormat(
                      totalIncome -
                        safeAdd(
                          combinedAssetSaleIncome,
                          combinedIncomePropertyIncome,
                          combinedBusinessIncome
                        ) /
                          2
                    )}
                  </Text>
                </div>
              )}
              {thisYearDeposits >= 1 && (
                <div className="activity-summary-subtitle-container">
                  <Text
                    color={"#004069"}
                    fontFamily={"Nunito"}
                    fontSize={"14px"}
                    fontStyle={"normal"}
                    fontWeight={"600"}
                    lineHeight={"130%"} 
                  >
                    Deposits already made
                  </Text>
                  <Text
                    color={"#00A2D4"}
                    textAlign={"right"}
                    fontFamily={"Nunito"}
                    fontSize={"14px"}
                    fontStyle={"normal"}
                    fontWeight={"800"}
                    lineHeight={"130%"}
                  >
                    - {currencyFormat(thisYearDeposits)}
                  </Text>
                </div>
              )}
              {totalWithdrawals >= 1 && (
                <div className="activity-summary-subtitle-container">
                  <Text
                    color={"#004069"}
                    fontFamily={"Nunito"}
                    fontSize={"14px"}
                    fontStyle={"normal"}
                    fontWeight={"600"}
                    lineHeight={"130%"}
                  >
                    Total Withdrawals
                  </Text>
                  <Text
                    color={"#00A2D4"}
                    textAlign={"right"}
                    fontFamily={"Nunito"}
                    fontSize={"14px"}
                    fontStyle={"normal"}
                    fontWeight={"800"}
                    lineHeight={"130%"}
                  >
                    {currencyFormat(totalWithdrawals)}
                  </Text>
                </div>
              )}
              {totalDeposits >= 1 && (
                <div className="activity-summary-subtitle-container">
                  <Text
                    color={"#004069"}
                    fontFamily={"Nunito"}
                    fontSize={"14px"}
                    fontStyle={"normal"}
                    fontWeight={"600"}
                    lineHeight={"130%"}
                  >
                    Total Deposits
                  </Text>
                  <Text
                    color={"#00A2D4"}
                    textAlign={"right"}
                    fontFamily={"Nunito"}
                    fontSize={"14px"}
                    fontStyle={"normal"}
                    fontWeight={"800"}
                    lineHeight={"130%"}
                  >
                    - {currencyFormat(totalDeposits)}
                  </Text>
                </div>
              )}
              {totalTaxes >= 1 && (
                <div className="activity-summary-subtitle-container">
                  <Text
                    color={"#004069"}
                    fontFamily={"Nunito"}
                    fontSize={"14px"}
                    fontStyle={"normal"}
                    fontWeight={"600"}
                    lineHeight={"130%"}
                  >
                    Total taxes
                  </Text>
                  <Text
                    color={"#00A2D4"}
                    textAlign={"right"}
                    fontFamily={"Nunito"}
                    fontSize={"14px"}
                    fontStyle={"normal"}
                    fontWeight={"800"}
                    lineHeight={"130%"}
                  >
                    - {currencyFormat(totalTaxes)}
                  </Text>
                </div>
              )}
              {spousePage === "joint-portfolio" && (
                <Box
                  display={"flex"}
                  height={"26px"}
                  justifyContent={"space-between"}
                  alignItems={"flex-end"}
                  alignSelf={"stretch"}
                >
                  <Text
                    color={"#004069"}
                    fontFamily={"Nunito"}
                    fontSize={isLargerThan1200 ? "16px" : "20px"}
                    fontStyle={"normal"}
                    fontWeight={"900"}
                    lineHeight={"130%"}
                  >
                    Available Funds
                  </Text>
                  <Text
                    color={"#00A2D4"}
                    textAlign={"right"}
                    fontFamily={"Nunito"}
                    fontSize={"20px"}
                    fontStyle={"normal"}
                    fontWeight={"900"}
                    lineHeight={"130%"}
                  >
                    {currencyFormat(
                      safeAdd(extraSpending, totalExpenses, totalGoals)
                    )}
                  </Text>
                </Box>
              )}
              {spousePage != "joint-portfolio" && (
                <Box
                  display={"flex"}
                  height={"26px"}
                  justifyContent={"space-between"}
                  alignItems={"flex-start"}
                  alignSelf={"stretch"}
                >
                  <Text
                    color={"#004069"}
                    fontFamily={"Nunito"}
                    fontSize={"20px"}
                    fontStyle={"normal"}
                    fontWeight={"900"}
                    lineHeight={"130%"}
                  >
                    Available Funds 
                  </Text>
                  <Text
                    color={"#00A2D4"}
                    textAlign={"right"}
                    fontFamily={"Nunito"}
                    fontSize={"20px"}
                    fontStyle={"normal"}
                    fontWeight={"900"}
                    lineHeight={"130%"}
                  >
                    {currencyFormat(
                      safeAdd(
                        extraSpendingSpouse,
        
                      )
                    )}
                  </Text>
                </Box>
              )}
            </>
          }
          Graph={
            <BarGraph
              type="income"
              totalIncome={totalIncome}
              totalTaxes={totalTaxes}
              totalDeposits={totalDeposits}
              thisYearDeposits={thisYearDeposits}
              totalExpenses={totalExpenses}
              extraSpending={extraSpending}
              totalGoals={totalGoals}
              combinedTotalIncome={safeAdd(
                extraSpending,
                totalExpenses,
                totalGoals,
                thisYearDeposits,
                totalIncome,
                totalTaxes,
                totalDeposits
              )}
            />
          }
        />
      )}
    </>
  );
}

function PortFolioBalance(props) {
  const {
    isLargerThan1200,
    isSmallScreen,
    combinedTFSA,
    combinedFHSA,
    combinedRRSP,
    combinedNonReg,
    combinedCashBalance,
    combinedFixedIncomeBalance,
    combinedLIRAPortfolioBalance,
    combinedAssetPortfolioBalance,
    combinedBusinessInvestmentsValue,
    combinedBusinessValue,
    combinedLifeInsuranceBalances,
    combinedPensionValue,
    combinedPersonAssetsBalance,
    assetsList,
    spousePage,
  } = props;

  return (
    <>
      {isSmallScreen && (
        <>
          <Box
            display={"flex"}
            flexDirection={"column"}
            width={"100%"}
            flexGrow={1}
            alignItems={"center"}
          >
            <Box display={"flex"} alignSelf={"center"} fill={"#00A2D4"}>
              <BarGraph
                combinedTFSA={combinedTFSA}
                combinedFHSA={combinedFHSA}
                combinedRRSP={combinedRRSP}
                combinedNonReg={combinedNonReg}
                combinedCash={combinedCashBalance}
                combinedFixedIncome={combinedFixedIncomeBalance}
                combinedLIRA={combinedLIRAPortfolioBalance}
                combinedAssets={combinedAssetPortfolioBalance}
                combinedBusiness={combinedBusinessValue}
                combinedBusinessInvestments={combinedBusinessInvestmentsValue}
                combinedPersonalAssets={combinedPersonAssetsBalance}
                combinedLifeInsuranceBalances={combinedLifeInsuranceBalances}
                combinedPensionValue={combinedPensionValue}
                combinedTotal={safeAdd(
                  combinedTFSA,
                  combinedFHSA,
                  combinedRRSP,
                  combinedNonReg,
                  combinedCashBalance,
                  combinedFixedIncomeBalance,
                  combinedLIRAPortfolioBalance,
                  combinedAssetPortfolioBalance,
                  combinedBusinessValue,
                  combinedBusinessInvestmentsValue,
                  combinedPensionValue,
                  combinedLifeInsuranceBalances,
                  combinedPersonAssetsBalance
                )}
              />
            </Box>
            <Box
              display={"flex"}
              width={"100%"}
              flexDirection={"column"}
              alignItems={"flex-start"}
              gap={"10px"}
            >
              <Box>
                <Text
                  color={"#00A2D4"}
                  textAlign={"right"}
                  fontFamily={"Nunito"}
                  fontSize={"24px"}
                  fontStyle={"normal"}
                  fontWeight={"800"}
                  lineHeight={"130%"}
                >
                  Portfolio balance
                </Text>
              </Box>

              {combinedTFSA >= 1 && (
                <div className="activity-summary-subtitle-container">
                  <Text
                    color={"#004069"}
                    fontFamily={"Nunito"}
                    fontSize={"14px"}
                    fontStyle={"normal"}
                    fontWeight={"600"}
                    lineHeight={"130%"}
                  >
                    TFSA
                  </Text>
                  <Text
                    color={"#00A2D4"}
                    textAlign={"right"}
                    fontFamily={"Nunito"}
                    fontSize={"14px"}
                    fontStyle={"normal"}
                    fontWeight={"800"}
                    lineHeight={"130%"}
                  >
                    {currencyFormat(combinedTFSA)}
                  </Text>
                </div>
              )}
              {combinedFHSA >= 1 && (
                <div className="activity-summary-subtitle-container">
                  <Text
                    color={"#004069"}
                    fontFamily={"Nunito"}
                    fontSize={"14px"}
                    fontStyle={"normal"}
                    fontWeight={"600"}
                    lineHeight={"130%"}
                  >
                    FHSA
                  </Text>
                  <Text
                    color={"#00A2D4"}
                    textAlign={"right"}
                    fontFamily={"Nunito"}
                    fontSize={"14px"}
                    fontStyle={"normal"}
                    fontWeight={"800"}
                    lineHeight={"130%"}
                  >
                    {currencyFormat(combinedFHSA)}
                  </Text>
                </div>
              )}
              {combinedRRSP >= 1 && (
                <div className="activity-summary-subtitle-container">
                  <Text
                    color={"#004069"}
                    fontFamily={"Nunito"}
                    fontSize={"14px"}
                    fontStyle={"normal"}
                    fontWeight={"600"}
                    lineHeight={"130%"}
                  >
                    RRSP/RRIF
                  </Text>
                  <Text
                    color={"#00A2D4"}
                    textAlign={"right"}
                    fontFamily={"Nunito"}
                    fontSize={"14px"}
                    fontStyle={"normal"}
                    fontWeight={"800"}
                    lineHeight={"130%"}
                  >
                    {currencyFormat(combinedRRSP)}
                  </Text>
                </div>
              )}
              {combinedLIRAPortfolioBalance >= 1 && (
                <div className="activity-summary-subtitle-container">
                  <Text
                    color={"#004069"}
                    fontFamily={"Nunito"}
                    fontSize={"14px"}
                    fontStyle={"normal"}
                    fontWeight={"600"}
                    lineHeight={"130%"}
                  >
                    LIRA/LIF
                  </Text>
                  <Text
                    color={"#00A2D4"}
                    textAlign={"right"}
                    fontFamily={"Nunito"}
                    fontSize={"14px"}
                    fontStyle={"normal"}
                    fontWeight={"800"}
                    lineHeight={"130%"}
                  >
                    {currencyFormat(combinedLIRAPortfolioBalance)}
                  </Text>
                </div>
              )}
              {combinedNonReg >= 1 && (
                <div className="activity-summary-subtitle-container">
                  <Text
                    color={"#004069"}
                    fontFamily={"Nunito"}
                    fontSize={"14px"}
                    fontStyle={"normal"}
                    fontWeight={"600"}
                    lineHeight={"130%"}
                  >
                    Non-Reg<span className="mobile-hidden">istered</span>
                  </Text>
                  <Text
                    color={"#00A2D4"}
                    textAlign={"right"}
                    fontFamily={"Nunito"}
                    fontSize={"14px"}
                    fontStyle={"normal"}
                    fontWeight={"800"}
                    lineHeight={"130%"}
                  >
                    {currencyFormat(combinedNonReg)}
                  </Text>
                </div>
              )}
              {combinedCashBalance >= 1 && (
                <div className="activity-summary-subtitle-container">
                  <Text
                    color={"#004069"}
                    fontFamily={"Nunito"}
                    fontSize={"14px"}
                    fontStyle={"normal"}
                    fontWeight={"600"}
                    lineHeight={"130%"}
                  >
                    Cash
                  </Text>
                  <Text
                    color={"#00A2D4"}
                    textAlign={"right"}
                    fontFamily={"Nunito"}
                    fontSize={"14px"}
                    fontStyle={"normal"}
                    fontWeight={"800"}
                    lineHeight={"130%"}
                  >
                    {currencyFormat(combinedCashBalance)}
                  </Text>
                </div>
              )}
              {combinedFixedIncomeBalance >= 1 && (
                <div className="activity-summary-subtitle-container">
                  <Text
                    color={"#004069"}
                    fontFamily={"Nunito"}
                    fontSize={"14px"}
                    fontStyle={"normal"}
                    fontWeight={"600"}
                    lineHeight={"130%"}
                  >
                    Fixed Income
                  </Text>
                  <Text
                    color={"#00A2D4"}
                    textAlign={"right"}
                    fontFamily={"Nunito"}
                    fontSize={"14px"}
                    fontStyle={"normal"}
                    fontWeight={"800"}
                    lineHeight={"130%"}
                  >
                    {currencyFormat(combinedFixedIncomeBalance)}
                  </Text>
                </div>
              )}
              {combinedAssetPortfolioBalance >= 1 &&
                spousePage === "joint-portfolio" && (
                  <div className="activity-summary-subtitle-container">
                    <Text
                      color={"#004069"}
                      fontFamily={"Nunito"}
                      fontSize={"14px"}
                      fontStyle={"normal"}
                      fontWeight={"600"}
                      lineHeight={"130%"}
                    >
                      Real Estate
                    </Text>
                    <Text
                      color={"#00A2D4"}
                      textAlign={"right"}
                      fontFamily={"Nunito"}
                      fontSize={"14px"}
                      fontStyle={"normal"}
                      fontWeight={"800"}
                      lineHeight={"130%"}
                    >
                      {currencyFormat(combinedAssetPortfolioBalance)}
                    </Text>
                  </div>
                )}
              {combinedAssetPortfolioBalance >= 1 &&
                spousePage != "joint-portfolio" && (
                  <div className="activity-summary-subtitle-container">
                    <Text
                      color={"#004069"}
                      fontFamily={"Nunito"}
                      fontSize={"14px"}
                      fontStyle={"normal"}
                      fontWeight={"600"}
                      lineHeight={"130%"}
                    >
                      Real Estate{" "}
                    </Text>
                    <Text
                      color={"#00A2D4"}
                      textAlign={"right"}
                      fontFamily={"Nunito"}
                      fontSize={"14px"}
                      fontStyle={"normal"}
                      fontWeight={"800"}
                      lineHeight={"130%"}
                    >
                      {currencyFormat(combinedAssetPortfolioBalance / 2)}
                    </Text>
                  </div>
                )}
              {combinedBusinessValue >= 1 &&
                spousePage === "joint-portfolio" && (
                  <div className="activity-summary-subtitle-container">
                    <Text
                      color={"#004069"}
                      fontFamily={"Nunito"}
                      fontSize={"14px"}
                      fontStyle={"normal"}
                      fontWeight={"600"}
                      lineHeight={"130%"}
                    >
                      Business
                    </Text>
                    <Text
                      color={"#00A2D4"}
                      textAlign={"right"}
                      fontFamily={"Nunito"}
                      fontSize={"14px"}
                      fontStyle={"normal"}
                      fontWeight={"800"}
                      lineHeight={"130%"}
                    >
                      {currencyFormat(combinedBusinessValue)}
                    </Text>
                  </div>
                )}
              {combinedBusinessValue >= 1 &&
                spousePage != "joint-portfolio" && (
                  <div className="activity-summary-subtitle-container">
                    <Text
                      color={"#004069"}
                      fontFamily={"Nunito"}
                      fontSize={"14px"}
                      fontStyle={"normal"}
                      fontWeight={"600"}
                      lineHeight={"130%"}
                    >
                      Business
                    </Text>
                    <Text
                      color={"#00A2D4"}
                      textAlign={"right"}
                      fontFamily={"Nunito"}
                      fontSize={"14px"}
                      fontStyle={"normal"}
                      fontWeight={"800"}
                      lineHeight={"130%"}
                    >
                      {currencyFormat(combinedBusinessValue / 2)}
                    </Text>
                  </div>
                )}
              {combinedBusinessInvestmentsValue >= 1 &&
                spousePage === "joint-portfolio" && (
                  <div className="activity-summary-subtitle-container">
                    <Text
                      color={"#004069"}
                      fontFamily={"Nunito"}
                      fontSize={"14px"}
                      fontStyle={"normal"}
                      fontWeight={"600"}
                      lineHeight={"130%"}
                    >
                      CCPC Investment Funds
                    </Text>
                    <Text
                      color={"#00A2D4"}
                      textAlign={"right"}
                      fontFamily={"Nunito"}
                      fontSize={"14px"}
                      fontStyle={"normal"}
                      fontWeight={"800"}
                      lineHeight={"130%"}
                    >
                      {currencyFormat(combinedBusinessInvestmentsValue)}
                    </Text>
                  </div>
                )}
              {combinedBusinessInvestmentsValue >= 1 &&
                spousePage != "joint-portfolio" && (
                  <div className="activity-summary-subtitle-container">
                    <Text
                      color={"#004069"}
                      fontFamily={"Nunito"}
                      fontSize={"14px"}
                      fontStyle={"normal"}
                      fontWeight={"600"}
                      lineHeight={"130%"}
                    >
                      CCPC Investment Funds
                    </Text>
                    <Text
                      color={"#00A2D4"}
                      textAlign={"right"}
                      fontFamily={"Nunito"}
                      fontSize={"14px"}
                      fontStyle={"normal"}
                      fontWeight={"800"}
                      lineHeight={"130%"}
                    >
                      {currencyFormat(combinedBusinessInvestmentsValue / 2)}
                    </Text>
                  </div>
                )}
              {combinedLifeInsuranceBalances >= 1 &&
                spousePage === "joint-portfolio" && (
                  <div className="activity-summary-subtitle-container">
                    <Text
                      color={"#004069"}
                      fontFamily={"Nunito"}
                      fontSize={"14px"}
                      fontStyle={"normal"}
                      fontWeight={"600"}
                      lineHeight={"130%"}
                    >
                      Life Insurance Value
                    </Text>
                    <Text
                      color={"#00A2D4"}
                      textAlign={"right"}
                      fontFamily={"Nunito"}
                      fontSize={"14px"}
                      fontStyle={"normal"}
                      fontWeight={"800"}
                      lineHeight={"130%"}
                    >
                      {currencyFormat(combinedLifeInsuranceBalances)}
                    </Text>
                  </div>
                )}
              {combinedLifeInsuranceBalances >= 1 &&
                spousePage != "joint-portfolio" && (
                  <div className="activity-summary-subtitle-container">
                    <Text
                      color={"#004069"}
                      fontFamily={"Nunito"}
                      fontSize={"14px"}
                      fontStyle={"normal"}
                      fontWeight={"600"}
                      lineHeight={"130%"}
                    >
                      Life Insurance Value
                    </Text>
                    <Text
                      color={"#00A2D4"}
                      textAlign={"right"}
                      fontFamily={"Nunito"}
                      fontSize={"14px"}
                      fontStyle={"normal"}
                      fontWeight={"800"}
                      lineHeight={"130%"}
                    >
                      {currencyFormat(combinedLifeInsuranceBalances / 2)}
                    </Text>
                  </div>
                )}
              {combinedPensionValue >= 1 && (
                <div className="activity-summary-subtitle-container">
                  <Text
                    color={"#004069"}
                    fontFamily={"Nunito"}
                    fontSize={"14px"}
                    fontStyle={"normal"}
                    fontWeight={"600"}
                    lineHeight={"130%"}
                  >
                    Pension Value
                  </Text>
                  <Text
                    color={"#00A2D4"}
                    textAlign={"right"}
                    fontFamily={"Nunito"}
                    fontSize={"14px"}
                    fontStyle={"normal"}
                    fontWeight={"800"}
                    lineHeight={"130%"}
                  >
                    {currencyFormat(combinedPensionValue)}
                  </Text>
                </div>
              )}
              {combinedPersonAssetsBalance >= 1 &&
                spousePage === "joint-portfolio" && (
                  <div className="activity-summary-subtitle-container">
                    <Text
                      color={"#004069"}
                      fontFamily={"Nunito"}
                      fontSize={"14px"}
                      fontStyle={"normal"}
                      fontWeight={"600"}
                      lineHeight={"130%"}
                    >
                      Personal assets
                    </Text>
                    <Text
                      color={"#00A2D4"}
                      textAlign={"right"}
                      fontFamily={"Nunito"}
                      fontSize={"14px"}
                      fontStyle={"normal"}
                      fontWeight={"800"}
                      lineHeight={"130%"}
                    >
                      {currencyFormat(combinedPersonAssetsBalance)}
                    </Text>
                  </div>
                )}
              {combinedPersonAssetsBalance >= 1 &&
                spousePage != "joint-portfolio" && (
                  <div className="activity-summary-subtitle-container">
                    <Text
                      color={"#004069"}
                      fontFamily={"Nunito"}
                      fontSize={"14px"}
                      fontStyle={"normal"}
                      fontWeight={"600"}
                      lineHeight={"130%"}
                    >
                      Personal assets
                    </Text>
                    <Text
                      color={"#00A2D4"}
                      textAlign={"right"}
                      fontFamily={"Nunito"}
                      fontSize={"14px"}
                      fontStyle={"normal"}
                      fontWeight={"800"}
                      lineHeight={"130%"}
                    >
                      {currencyFormat(combinedPersonAssetsBalance / 2)}
                    </Text>
                  </div>
                )}
              {spousePage === "joint-portfolio" && (
                <Box
                  display={"flex"}
                  height={"26px"}
                  justifyContent={"space-between"}
                  alignItems={"flex-start"}
                  alignSelf={"stretch"}
                >
                  <Text
                    color={"#004069"}
                    fontFamily={"Nunito"}
                    fontSize={"20px"}
                    fontStyle={"normal"}
                    fontWeight={"900"}
                    lineHeight={"130%"}
                  >
                    Total
                  </Text>
                  <Text
                    color={"#00A2D4"}
                    textAlign={"right"}
                    fontFamily={"Nunito"}
                    fontSize={"20px"}
                    fontStyle={"normal"}
                    fontWeight={"900"}
                    lineHeight={"130%"}
                  >
                    {currencyFormat(
                      safeAdd(
                        combinedTFSA,
                        combinedFHSA,
                        combinedRRSP,
                        combinedNonReg,
                        combinedCashBalance,
                        combinedFixedIncomeBalance,
                        combinedLIRAPortfolioBalance,
                        combinedAssetPortfolioBalance,
                        combinedBusinessValue,
                        combinedBusinessInvestmentsValue,
                        combinedLifeInsuranceBalances,
                        combinedPensionValue,
                        combinedPersonAssetsBalance
                      )
                    )}
                  </Text>
                </Box>
              )}
              {spousePage != "joint-portfolio" && (
                <Box
                  display={"flex"}
                  height={"26px"}
                  justifyContent={"space-between"}
                  alignItems={"flex-start"}
                  alignSelf={"stretch"}
                >
                  <Text
                    color={"#004069"}
                    fontFamily={"Nunito"}
                    fontSize={"20px"}
                    fontStyle={"normal"}
                    fontWeight={"900"}
                    lineHeight={"130%"}
                  >
                    Total
                  </Text>
                  <Text
                    color={"#00A2D4"}
                    textAlign={"right"}
                    fontFamily={"Nunito"}
                    fontSize={"20px"}
                    fontStyle={"normal"}
                    fontWeight={"900"}
                    lineHeight={"130%"}
                  >
                    {currencyFormat(
                      safeAdd(
                        combinedTFSA,
                        combinedFHSA,
                        combinedRRSP,
                        combinedNonReg,
                        combinedCashBalance,
                        combinedFixedIncomeBalance,
                        combinedLIRAPortfolioBalance,
                        combinedAssetPortfolioBalance / 2,
                        combinedBusinessValue / 2,
                        combinedBusinessInvestmentsValue / 2,
                        combinedLifeInsuranceBalances / 2,
                        combinedPensionValue,
                        combinedPersonAssetsBalance / 2
                      )
                    )}
                  </Text>
                </Box>
              )}
            </Box>
          </Box>
        </>
      )}
      {!isSmallScreen && (
        <DashboardSubCards
          Heading={"Portfolio balance"}
          mainframecontent={
            <>
              {combinedTFSA >= 1 && (
                <div className="activity-summary-subtitle-container">
                  <Text
                    color={"#004069"}
                    fontFamily={"Nunito"}
                    fontSize={"14px"}
                    fontStyle={"normal"}
                    fontWeight={"600"}
                    lineHeight={"130%"}
                  >
                    TFSA
                  </Text>
                  <Text
                    color={"#00A2D4"}
                    textAlign={"right"}
                    fontFamily={"Nunito"}
                    fontSize={"14px"}
                    fontStyle={"normal"}
                    fontWeight={"800"}
                    lineHeight={"130%"}
                  >
                    {currencyFormat(combinedTFSA)}
                  </Text>
                </div>
              )}
              {combinedFHSA >= 1 && (
                <div className="activity-summary-subtitle-container">
                  <Text
                    color={"#004069"}
                    fontFamily={"Nunito"}
                    fontSize={"14px"}
                    fontStyle={"normal"}
                    fontWeight={"600"}
                    lineHeight={"130%"}
                  >
                    FHSA
                  </Text>
                  <Text
                    color={"#00A2D4"}
                    textAlign={"right"}
                    fontFamily={"Nunito"}
                    fontSize={"14px"}
                    fontStyle={"normal"}
                    fontWeight={"800"}
                    lineHeight={"130%"}
                  >
                    {currencyFormat(combinedFHSA)}
                  </Text>
                </div>
              )}
              {combinedRRSP >= 1 && (
                <div className="activity-summary-subtitle-container">
                  <Text
                    color={"#004069"}
                    fontFamily={"Nunito"}
                    fontSize={"14px"}
                    fontStyle={"normal"}
                    fontWeight={"600"}
                    lineHeight={"130%"}
                  >
                    RRSP/RRIF
                  </Text>
                  <Text
                    color={"#00A2D4"}
                    textAlign={"right"}
                    fontFamily={"Nunito"}
                    fontSize={"14px"}
                    fontStyle={"normal"}
                    fontWeight={"800"}
                    lineHeight={"130%"}
                  >
                    {currencyFormat(combinedRRSP)}
                  </Text>
                </div>
              )}
              {combinedLIRAPortfolioBalance >= 1 && (
                <div className="activity-summary-subtitle-container">
                  <Text
                    color={"#004069"}
                    fontFamily={"Nunito"}
                    fontSize={"14px"}
                    fontStyle={"normal"}
                    fontWeight={"600"}
                    lineHeight={"130%"}
                  >
                    LIRA/LIF
                  </Text>
                  <Text
                    color={"#00A2D4"}
                    textAlign={"right"}
                    fontFamily={"Nunito"}
                    fontSize={"14px"}
                    fontStyle={"normal"}
                    fontWeight={"800"}
                    lineHeight={"130%"}
                  >
                    {currencyFormat(combinedLIRAPortfolioBalance)}
                  </Text>
                </div>
              )}
              {combinedNonReg >= 1 && (
                <div className="activity-summary-subtitle-container">
                  <Text
                    color={"#004069"}
                    fontFamily={"Nunito"}
                    fontSize={"14px"}
                    fontStyle={"normal"}
                    fontWeight={"600"}
                    lineHeight={"130%"}
                  >
                    Non-Reg<span className="mobile-hidden">istered</span>
                  </Text>
                  <Text
                    color={"#00A2D4"}
                    textAlign={"right"}
                    fontFamily={"Nunito"}
                    fontSize={"14px"}
                    fontStyle={"normal"}
                    fontWeight={"800"}
                    lineHeight={"130%"}
                  >
                    {currencyFormat(combinedNonReg)}
                  </Text>
                </div>
              )}
              {combinedCashBalance >= 1 && (
                <div className="activity-summary-subtitle-container">
                  <Text
                    color={"#004069"}
                    fontFamily={"Nunito"}
                    fontSize={"14px"}
                    fontStyle={"normal"}
                    fontWeight={"600"}
                    lineHeight={"130%"}
                  >
                    Cash
                  </Text>
                  <Text
                    color={"#00A2D4"}
                    textAlign={"right"}
                    fontFamily={"Nunito"}
                    fontSize={"14px"}
                    fontStyle={"normal"}
                    fontWeight={"800"}
                    lineHeight={"130%"}
                  >
                    {currencyFormat(combinedCashBalance)}
                  </Text>
                </div>
              )}
              {combinedFixedIncomeBalance >= 1 && (
                <div className="activity-summary-subtitle-container">
                  <Text
                    color={"#004069"}
                    fontFamily={"Nunito"}
                    fontSize={"14px"}
                    fontStyle={"normal"}
                    fontWeight={"600"}
                    lineHeight={"130%"}
                  >
                    Fixed Income
                  </Text>
                  <Text
                    color={"#00A2D4"}
                    textAlign={"right"}
                    fontFamily={"Nunito"}
                    fontSize={"14px"}
                    fontStyle={"normal"}
                    fontWeight={"800"}
                    lineHeight={"130%"}
                  >
                    {currencyFormat(combinedFixedIncomeBalance)}
                  </Text>
                </div>
              )}
              {combinedAssetPortfolioBalance >= 1 &&
                spousePage === "joint-portfolio" && (
                  <div className="activity-summary-subtitle-container">
                    <Text
                      color={"#004069"}
                      fontFamily={"Nunito"}
                      fontSize={"14px"}
                      fontStyle={"normal"}
                      fontWeight={"600"}
                      lineHeight={"130%"}
                    >
                      Real Estate
                    </Text>
                    <Text
                      color={"#00A2D4"}
                      textAlign={"right"}
                      fontFamily={"Nunito"}
                      fontSize={"14px"}
                      fontStyle={"normal"}
                      fontWeight={"800"}
                      lineHeight={"130%"}
                    >
                      {currencyFormat(combinedAssetPortfolioBalance)}
                    </Text>
                  </div>
                )}
              {combinedAssetPortfolioBalance >= 1 &&
                spousePage != "joint-portfolio" && (
                  <div className="activity-summary-subtitle-container">
                    <Text
                      color={"#004069"}
                      fontFamily={"Nunito"}
                      fontSize={"14px"}
                      fontStyle={"normal"}
                      fontWeight={"600"}
                      lineHeight={"130%"}
                    >
                      Real Estate{" "}
                    </Text>
                    <Text
                      color={"#00A2D4"}
                      textAlign={"right"}
                      fontFamily={"Nunito"}
                      fontSize={"14px"}
                      fontStyle={"normal"}
                      fontWeight={"800"}
                      lineHeight={"130%"}
                    >
                      {currencyFormat(combinedAssetPortfolioBalance / 2)}
                    </Text>
                  </div>
                )}
              {combinedBusinessValue >= 1 &&
                spousePage === "joint-portfolio" && (
                  <div className="activity-summary-subtitle-container">
                    <Text
                      color={"#004069"}
                      fontFamily={"Nunito"}
                      fontSize={"14px"}
                      fontStyle={"normal"}
                      fontWeight={"600"}
                      lineHeight={"130%"}
                    >
                      Business
                    </Text>
                    <Text
                      color={"#00A2D4"}
                      textAlign={"right"}
                      fontFamily={"Nunito"}
                      fontSize={"14px"}
                      fontStyle={"normal"}
                      fontWeight={"800"}
                      lineHeight={"130%"}
                    >
                      {currencyFormat(combinedBusinessValue)}
                    </Text>
                  </div>
                )}
              {combinedBusinessValue >= 1 &&
                spousePage != "joint-portfolio" && (
                  <div className="activity-summary-subtitle-container">
                    <Text
                      color={"#004069"}
                      fontFamily={"Nunito"}
                      fontSize={"14px"}
                      fontStyle={"normal"}
                      fontWeight={"600"}
                      lineHeight={"130%"}
                    >
                      Business
                    </Text>
                    <Text
                      color={"#00A2D4"}
                      textAlign={"right"}
                      fontFamily={"Nunito"}
                      fontSize={"14px"}
                      fontStyle={"normal"}
                      fontWeight={"800"}
                      lineHeight={"130%"}
                    >
                      {currencyFormat(combinedBusinessValue / 2)}
                    </Text>
                  </div>
                )}
              {combinedBusinessInvestmentsValue >= 1 &&
                spousePage === "joint-portfolio" && (
                  <div className="activity-summary-subtitle-container">
                    <Text
                      color={"#004069"}
                      fontFamily={"Nunito"}
                      fontSize={"14px"}
                      fontStyle={"normal"}
                      fontWeight={"600"}
                      lineHeight={"130%"}
                    >
                      CCPC Investment Funds
                    </Text>
                    <Text
                      color={"#00A2D4"}
                      textAlign={"right"}
                      fontFamily={"Nunito"}
                      fontSize={"14px"}
                      fontStyle={"normal"}
                      fontWeight={"800"}
                      lineHeight={"130%"}
                    >
                      {currencyFormat(combinedBusinessInvestmentsValue)}
                    </Text>
                  </div>
                )}
              {combinedBusinessInvestmentsValue >= 1 &&
                spousePage != "joint-portfolio" && (
                  <div className="activity-summary-subtitle-container">
                    <Text
                      color={"#004069"}
                      fontFamily={"Nunito"}
                      fontSize={"14px"}
                      fontStyle={"normal"}
                      fontWeight={"600"}
                      lineHeight={"130%"}
                    >
                      CCPC Investment Funds
                    </Text>
                    <Text
                      color={"#00A2D4"}
                      textAlign={"right"}
                      fontFamily={"Nunito"}
                      fontSize={"14px"}
                      fontStyle={"normal"}
                      fontWeight={"800"}
                      lineHeight={"130%"}
                    >
                      {currencyFormat(combinedBusinessInvestmentsValue / 2)}
                    </Text>
                  </div>
                )}
              {combinedLifeInsuranceBalances >= 1 &&
                spousePage === "joint-portfolio" && (
                  <div className="activity-summary-subtitle-container">
                    <Text
                      color={"#004069"}
                      fontFamily={"Nunito"}
                      fontSize={"14px"}
                      fontStyle={"normal"}
                      fontWeight={"600"}
                      lineHeight={"130%"}
                    >
                      Life Insurance Value
                    </Text>
                    <Text
                      color={"#00A2D4"}
                      textAlign={"right"}
                      fontFamily={"Nunito"}
                      fontSize={"14px"}
                      fontStyle={"normal"}
                      fontWeight={"800"}
                      lineHeight={"130%"}
                    >
                      {currencyFormat(combinedLifeInsuranceBalances)}
                    </Text>
                  </div>
                )}
              {combinedLifeInsuranceBalances >= 1 &&
                spousePage != "joint-portfolio" && (
                  <div className="activity-summary-subtitle-container">
                    <Text
                      color={"#004069"}
                      fontFamily={"Nunito"}
                      fontSize={"14px"}
                      fontStyle={"normal"}
                      fontWeight={"600"}
                      lineHeight={"130%"}
                    >
                      Life Insurance Value
                    </Text>
                    <Text
                      color={"#00A2D4"}
                      textAlign={"right"}
                      fontFamily={"Nunito"}
                      fontSize={"14px"}
                      fontStyle={"normal"}
                      fontWeight={"800"}
                      lineHeight={"130%"}
                    >
                      {currencyFormat(combinedLifeInsuranceBalances / 2)}
                    </Text>
                  </div>
                )}
              {combinedPensionValue >= 1 && (
                <div className="activity-summary-subtitle-container">
                  <Text
                    color={"#004069"}
                    fontFamily={"Nunito"}
                    fontSize={"14px"}
                    fontStyle={"normal"}
                    fontWeight={"600"}
                    lineHeight={"130%"}
                  >
                    Pension Value
                  </Text>
                  <Text
                    color={"#00A2D4"}
                    textAlign={"right"}
                    fontFamily={"Nunito"}
                    fontSize={"14px"}
                    fontStyle={"normal"}
                    fontWeight={"800"}
                    lineHeight={"130%"}
                  >
                    {currencyFormat(combinedPensionValue)}
                  </Text>
                </div>
              )}
              {combinedPersonAssetsBalance >= 1 &&
                spousePage === "joint-portfolio" && (
                  <div className="activity-summary-subtitle-container">
                    <Text
                      color={"#004069"}
                      fontFamily={"Nunito"}
                      fontSize={"14px"}
                      fontStyle={"normal"}
                      fontWeight={"600"}
                      lineHeight={"130%"}
                    >
                      Personal assets
                    </Text>
                    <Text
                      color={"#00A2D4"}
                      textAlign={"right"}
                      fontFamily={"Nunito"}
                      fontSize={"14px"}
                      fontStyle={"normal"}
                      fontWeight={"800"}
                      lineHeight={"130%"}
                    >
                      {currencyFormat(combinedPersonAssetsBalance)}
                    </Text>
                  </div>
                )}
              {combinedPersonAssetsBalance >= 1 &&
                spousePage != "joint-portfolio" && (
                  <div className="activity-summary-subtitle-container">
                    <Text
                      color={"#004069"}
                      fontFamily={"Nunito"}
                      fontSize={"14px"}
                      fontStyle={"normal"}
                      fontWeight={"600"}
                      lineHeight={"130%"}
                    >
                      Personal assets
                    </Text>
                    <Text
                      color={"#00A2D4"}
                      textAlign={"right"}
                      fontFamily={"Nunito"}
                      fontSize={"14px"}
                      fontStyle={"normal"}
                      fontWeight={"800"}
                      lineHeight={"130%"}
                    >
                      {currencyFormat(combinedPersonAssetsBalance / 2)}
                    </Text>
                  </div>
                )}
              {spousePage === "joint-portfolio" && (
                <Box
                  display={"flex"}
                  height={"26px"}
                  justifyContent={"space-between"}
                  alignItems={"flex-start"}
                  alignSelf={"stretch"}
                >
                  <Text
                    color={"#004069"}
                    fontFamily={"Nunito"}
                    fontSize={"20px"}
                    fontStyle={"normal"}
                    fontWeight={"900"}
                    lineHeight={"130%"}
                  >
                    Total
                  </Text>
                  <Text
                    color={"#00A2D4"}
                    textAlign={"right"}
                    fontFamily={"Nunito"}
                    fontSize={"20px"}
                    fontStyle={"normal"}
                    fontWeight={"900"}
                    lineHeight={"130%"}
                  >
                    {currencyFormat(
                      safeAdd(
                        combinedTFSA,
                        combinedFHSA,
                        combinedRRSP,
                        combinedNonReg,
                        combinedCashBalance,
                        combinedFixedIncomeBalance,
                        combinedLIRAPortfolioBalance,
                        combinedAssetPortfolioBalance,
                        combinedBusinessValue,
                        combinedBusinessInvestmentsValue,
                        combinedLifeInsuranceBalances,
                        combinedPensionValue,
                        combinedPersonAssetsBalance
                      )
                    )}
                  </Text>
                </Box>
              )}
              {spousePage != "joint-portfolio" && (
                <Box
                  display={"flex"}
                  height={"26px"}
                  justifyContent={"space-between"}
                  alignItems={"flex-start"}
                  alignSelf={"stretch"}
                >
                  <Text
                    color={"#004069"}
                    fontFamily={"Nunito"}
                    fontSize={"20px"}
                    fontStyle={"normal"}
                    fontWeight={"900"}
                    lineHeight={"130%"}
                  >
                    Total
                  </Text>
                  <Text
                    color={"#00A2D4"}
                    textAlign={"right"}
                    fontFamily={"Nunito"}
                    fontSize={"20px"}
                    fontStyle={"normal"}
                    fontWeight={"900"}
                    lineHeight={"130%"}
                  >
                    {currencyFormat(
                      safeAdd(
                        combinedTFSA,
                        combinedFHSA,
                        combinedRRSP,
                        combinedNonReg,
                        combinedCashBalance,
                        combinedFixedIncomeBalance,
                        combinedLIRAPortfolioBalance,
                        combinedAssetPortfolioBalance / 2,
                        combinedBusinessValue / 2,
                        combinedBusinessInvestmentsValue / 2,
                        combinedLifeInsuranceBalances / 2,
                        combinedPensionValue,
                        combinedPersonAssetsBalance / 2
                      )
                    )}
                  </Text>
                </Box>
              )}
            </>
          }
          Graph={
            <BarGraph
              combinedTFSA={combinedTFSA}
              combinedFHSA={combinedFHSA}
              combinedRRSP={combinedRRSP}
              combinedNonReg={combinedNonReg}
              combinedCash={combinedCashBalance}
              combinedFixedIncome={combinedFixedIncomeBalance}
              combinedLIRA={combinedLIRAPortfolioBalance}
              combinedAssets={combinedAssetPortfolioBalance}
              combinedBusiness={combinedBusinessValue}
              combinedBusinessInvestments={combinedBusinessInvestmentsValue}
              combinedPersonalAssets={combinedPersonAssetsBalance}
              combinedLifeInsuranceBalances={combinedLifeInsuranceBalances}
              combinedPensionValue={combinedPensionValue}
              combinedTotal={safeAdd(
                combinedTFSA,
                combinedFHSA,
                combinedRRSP,
                combinedNonReg,
                combinedCashBalance,
                combinedFixedIncomeBalance,
                combinedLIRAPortfolioBalance,
                combinedAssetPortfolioBalance,
                combinedBusinessValue,
                combinedBusinessInvestmentsValue,
                combinedPensionValue,
                combinedLifeInsuranceBalances,
                combinedPersonAssetsBalance
              )}
            />
          }
        />
      )}
    </>
  );
}

function Debt(props) {
  const {
    isLargerThan1200,
    isSmallScreen,
    combinedPrimaryResMortgage,
    combinedRecreationMortgage,
    combinedLandMortgage,
    combinedIncomePropertyMortgage,
    combinedLoans,
    spousePage,
  } = props;

  return (
    <>
      {isSmallScreen && (
        <>
          <Box
            display={"flex"}
            flexDirection={"column"}
            width={"100%"}
            flexGrow={1}
            alignItems={"center"}
          >
            <Box display={"flex"} alignSelf={"center"} fill={"#00A2D4"}>
              <BarGraph
                combinedPrimaryResMortgage={combinedPrimaryResMortgage}
                combinedRecreationMortgage={combinedRecreationMortgage}
                combinedLandMortgage={combinedLandMortgage}
                combinedIncomePropertyMortgage={combinedIncomePropertyMortgage}
                combinedLoans={combinedLoans}
                combinedTotal={safeAdd(
                  combinedLoans,
                  combinedIncomePropertyMortgage,
                  combinedRecreationMortgage,
                  combinedLandMortgage,
                  combinedPrimaryResMortgage
                )}
              />
            </Box>
            <Box
              display={"flex"}
              width={"100%"}
              flexDirection={"column"}
              alignItems={"flex-start"}
              gap={"10px"}
            >
              <Box>
                <Text
                  color={"#00A2D4"}
                  textAlign={"right"}
                  fontFamily={"Nunito"}
                  fontSize={"24px"}
                  fontStyle={"normal"}
                  fontWeight={"800"}
                  lineHeight={"130%"}
                >
                  Debt
                </Text>
              </Box>

              {combinedPrimaryResMortgage >= 1 &&
                spousePage === "joint-portfolio" && (
                  <div className="activity-summary-subtitle-container">
                    <Text
                      color={"#004069"}
                      fontFamily={"Nunito"}
                      fontSize={"14px"}
                      fontStyle={"normal"}
                      fontWeight={"600"}
                      lineHeight={"130%"}
                    >
                      Primary Residence Mortgage
                    </Text>
                    <Text
                      color={"#00A2D4"}
                      textAlign={"right"}
                      fontFamily={"Nunito"}
                      fontSize={"14px"}
                      fontStyle={"normal"}
                      fontWeight={"800"}
                      lineHeight={"130%"}
                    >
                      {currencyFormat(combinedPrimaryResMortgage)}
                    </Text>
                  </div>
                )}
              {combinedPrimaryResMortgage >= 1 &&
                spousePage != "joint-portfolio" && (
                  <div className="activity-summary-subtitle-container">
                    <Text
                      color={"#004069"}
                      fontFamily={"Nunito"}
                      fontSize={"14px"}
                      fontStyle={"normal"}
                      fontWeight={"600"}
                      lineHeight={"130%"}
                    >
                      Primary Residence Mortgage
                    </Text>
                    <Text
                      color={"#00A2D4"}
                      textAlign={"right"}
                      fontFamily={"Nunito"}
                      fontSize={"14px"}
                      fontStyle={"normal"}
                      fontWeight={"800"}
                      lineHeight={"130%"}
                    >
                      {currencyFormat(combinedPrimaryResMortgage / 2)}
                    </Text>
                  </div>
                )}
              {combinedRecreationMortgage >= 1 &&
                spousePage === "joint-portfolio" && (
                  <div className="activity-summary-subtitle-container">
                    <Text
                      color={"#004069"}
                      fontFamily={"Nunito"}
                      fontSize={"14px"}
                      fontStyle={"normal"}
                      fontWeight={"600"}
                      lineHeight={"130%"}
                    >
                      Recreation Property Mortgage
                    </Text>
                    <Text
                      color={"#00A2D4"}
                      textAlign={"right"}
                      fontFamily={"Nunito"}
                      fontSize={"14px"}
                      fontStyle={"normal"}
                      fontWeight={"800"}
                      lineHeight={"130%"}
                    >
                      {currencyFormat(combinedRecreationMortgage)}
                    </Text>
                  </div>
                )}
                {combinedLandMortgage >= 1 &&
                spousePage === "joint-portfolio" && (
                  <div className="activity-summary-subtitle-container">
                    <Text
                      color={"#004069"}
                      fontFamily={"Nunito"}
                      fontSize={"14px"}
                      fontStyle={"normal"}
                      fontWeight={"600"}
                      lineHeight={"130%"}
                    >
                      Land Debt
                    </Text>
                    <Text
                      color={"#00A2D4"}
                      textAlign={"right"}
                      fontFamily={"Nunito"}
                      fontSize={"14px"}
                      fontStyle={"normal"}
                      fontWeight={"800"}
                      lineHeight={"130%"}
                    >
                      {currencyFormat(combinedLandMortgage)}
                    </Text>
                  </div>
                )}
              {combinedRecreationMortgage >= 1 &&
                spousePage != "joint-portfolio" && (
                  <div className="activity-summary-subtitle-container">
                    <Text
                      color={"#004069"}
                      fontFamily={"Nunito"}
                      fontSize={"14px"}
                      fontStyle={"normal"}
                      fontWeight={"600"}
                      lineHeight={"130%"}
                    >
                      Recreation Property Mortgage
                    </Text>
                    <Text
                      color={"#00A2D4"}
                      textAlign={"right"}
                      fontFamily={"Nunito"}
                      fontSize={"14px"}
                      fontStyle={"normal"}
                      fontWeight={"800"}
                      lineHeight={"130%"}
                    >
                      {currencyFormat(combinedRecreationMortgage / 2)}
                    </Text>
                  </div>
                )}
                {combinedLandMortgage >= 1 &&
                spousePage != "joint-portfolio" && (
                  <div className="activity-summary-subtitle-container">
                    <Text
                      color={"#004069"}
                      fontFamily={"Nunito"}
                      fontSize={"14px"}
                      fontStyle={"normal"}
                      fontWeight={"600"}
                      lineHeight={"130%"}
                    >
                      Land Debt
                    </Text>
                    <Text
                      color={"#00A2D4"}
                      textAlign={"right"}
                      fontFamily={"Nunito"}
                      fontSize={"14px"}
                      fontStyle={"normal"}
                      fontWeight={"800"}
                      lineHeight={"130%"}
                    >
                      {currencyFormat(combinedLandMortgage / 2)}
                    </Text>
                  </div>
                )}
              {combinedIncomePropertyMortgage >= 1 &&
                spousePage === "joint-portfolio" && (
                  <div className="activity-summary-subtitle-container">
                    <Text
                      color={"#004069"}
                      fontFamily={"Nunito"}
                      fontSize={"14px"}
                      fontStyle={"normal"}
                      fontWeight={"600"}
                      lineHeight={"130%"}
                    >
                      Income Property Mortgage
                    </Text>
                    <Text
                      color={"#00A2D4"}
                      textAlign={"right"}
                      fontFamily={"Nunito"}
                      fontSize={"14px"}
                      fontStyle={"normal"}
                      fontWeight={"800"}
                      lineHeight={"130%"}
                    >
                      {currencyFormat(combinedIncomePropertyMortgage)}
                    </Text>
                  </div>
                )}
              {combinedIncomePropertyMortgage >= 1 &&
                spousePage != "joint-portfolio" && (
                  <div className="activity-summary-subtitle-container">
                    <Text
                      color={"#004069"}
                      fontFamily={"Nunito"}
                      fontSize={"14px"}
                      fontStyle={"normal"}
                      fontWeight={"600"}
                      lineHeight={"130%"}
                    >
                      Income Property Mortgage
                    </Text>
                    <Text
                      color={"#00A2D4"}
                      textAlign={"right"}
                      fontFamily={"Nunito"}
                      fontSize={"14px"}
                      fontStyle={"normal"}
                      fontWeight={"800"}
                      lineHeight={"130%"}
                    >
                      {currencyFormat(combinedIncomePropertyMortgage / 2)}
                    </Text>
                  </div>
                )}
              {combinedLoans >= 1 && spousePage === "joint-portfolio" && (
                <div className="activity-summary-subtitle-container">
                  <Text
                    color={"#004069"}
                    fontFamily={"Nunito"}
                    fontSize={"14px"}
                    fontStyle={"normal"}
                    fontWeight={"600"}
                    lineHeight={"130%"}
                  >
                    Total Loans
                  </Text>
                  <Text
                    color={"#00A2D4"}
                    textAlign={"right"}
                    fontFamily={"Nunito"}
                    fontSize={"14px"}
                    fontStyle={"normal"}
                    fontWeight={"800"}
                    lineHeight={"130%"}
                  >
                    {currencyFormat(combinedLoans)}
                  </Text>
                </div>
              )}

              {combinedLoans >= 1 && spousePage != "joint-portfolio" && (
                <div className="activity-summary-subtitle-container">
                  <Text
                    color={"#004069"}
                    fontFamily={"Nunito"}
                    fontSize={"14px"}
                    fontStyle={"normal"}
                    fontWeight={"600"}
                    lineHeight={"130%"}
                  >
                    Total Loans
                  </Text>
                  <Text
                    color={"#00A2D4"}
                    textAlign={"right"}
                    fontFamily={"Nunito"}
                    fontSize={"14px"}
                    fontStyle={"normal"}
                    fontWeight={"800"}
                    lineHeight={"130%"}
                  >
                    {currencyFormat(combinedLoans / 2)}
                  </Text>
                </div>
              )}
              {spousePage === "joint-portfolio" && (
                <Box
                  display={"flex"}
                  height={"26px"}
                  justifyContent={"space-between"}
                  alignItems={"flex-start"}
                  alignSelf={"stretch"}
                >
                  <Text
                    color={"#004069"}
                    fontFamily={"Nunito"}
                    fontSize={"20px"}
                    fontStyle={"normal"}
                    fontWeight={"900"}
                    lineHeight={"130%"}
                  >
                    Total Debt{" "}
                  </Text>
                  <Text
                    color={"#00A2D4"}
                    textAlign={"right"}
                    fontFamily={"Nunito"}
                    fontSize={"20px"}
                    fontStyle={"normal"}
                    fontWeight={"900"}
                    lineHeight={"130%"}
                  >
                    {currencyFormat(
                      safeAdd(
                        combinedLoans,
                        combinedIncomePropertyMortgage,
                        combinedRecreationMortgage,
                        combinedLandMortgage,
                        combinedPrimaryResMortgage
                      )
                    )}
                  </Text>
                </Box>
              )}
              {spousePage != "joint-portfolio" && (
                <Box
                  display={"flex"}
                  height={"26px"}
                  justifyContent={"space-between"}
                  alignItems={"flex-start"}
                  alignSelf={"stretch"}
                >
                  <Text
                    color={"#004069"}
                    fontFamily={"Nunito"}
                    fontSize={"20px"}
                    fontStyle={"normal"}
                    fontWeight={"900"}
                    lineHeight={"130%"}
                  >
                    Total Debt{" "}
                  </Text>
                  <Text
                    color={"#00A2D4"}
                    textAlign={"right"}
                    fontFamily={"Nunito"}
                    fontSize={"20px"}
                    fontStyle={"normal"}
                    fontWeight={"900"}
                    lineHeight={"130%"}
                  >
                    {currencyFormat(
                      safeAdd(
                        combinedLoans / 2,
                        combinedIncomePropertyMortgage / 2,
                        combinedRecreationMortgage / 2,
                        combinedLandMortgage / 2,
                        combinedPrimaryResMortgage / 2
                      )
                    )}
                  </Text>
                </Box>
              )}
            </Box>
          </Box>
        </>
      )}
      {!isSmallScreen && (
        <DashboardSubCards
          Heading={"Debt"}
          mainframecontent={
            <>
              {combinedPrimaryResMortgage >= 1 &&
                spousePage === "joint-portfolio" && (
                  <div className="activity-summary-subtitle-container">
                    <Text
                      color={"#004069"}
                      fontFamily={"Nunito"}
                      fontSize={"14px"}
                      fontStyle={"normal"}
                      fontWeight={"600"}
                      lineHeight={"130%"}
                    >
                      Primary Residence Mortgage
                    </Text>
                    <Text
                      color={"#00A2D4"}
                      textAlign={"right"}
                      fontFamily={"Nunito"}
                      fontSize={"14px"}
                      fontStyle={"normal"}
                      fontWeight={"800"}
                      lineHeight={"130%"}
                    >
                      {currencyFormat(combinedPrimaryResMortgage)}
                    </Text>
                  </div>
                )}
              {combinedPrimaryResMortgage >= 1 &&
                spousePage != "joint-portfolio" && (
                  <div className="activity-summary-subtitle-container">
                    <Text
                      color={"#004069"}
                      fontFamily={"Nunito"}
                      fontSize={"14px"}
                      fontStyle={"normal"}
                      fontWeight={"600"}
                      lineHeight={"130%"}
                    >
                      Primary Residence Mortgage
                    </Text>
                    <Text
                      color={"#00A2D4"}
                      textAlign={"right"}
                      fontFamily={"Nunito"}
                      fontSize={"14px"}
                      fontStyle={"normal"}
                      fontWeight={"800"}
                      lineHeight={"130%"}
                    >
                      {currencyFormat(combinedPrimaryResMortgage / 2)}
                    </Text>
                  </div>
                )}
              {combinedRecreationMortgage >= 1 &&
                spousePage === "joint-portfolio" && (
                  <div className="activity-summary-subtitle-container">
                    <Text
                      color={"#004069"}
                      fontFamily={"Nunito"}
                      fontSize={"14px"}
                      fontStyle={"normal"}
                      fontWeight={"600"}
                      lineHeight={"130%"}
                    >
                      Recreation Property Mortgage
                    </Text>
                    <Text
                      color={"#00A2D4"}
                      textAlign={"right"}
                      fontFamily={"Nunito"}
                      fontSize={"14px"}
                      fontStyle={"normal"}
                      fontWeight={"800"}
                      lineHeight={"130%"}
                    >
                      {currencyFormat(combinedRecreationMortgage)}
                    </Text>
                  </div>
                )}
              {combinedRecreationMortgage >= 1 &&
                spousePage != "joint-portfolio" && (
                  <div className="activity-summary-subtitle-container">
                    <Text
                      color={"#004069"}
                      fontFamily={"Nunito"}
                      fontSize={"14px"}
                      fontStyle={"normal"}
                      fontWeight={"600"}
                      lineHeight={"130%"}
                    >
                      Recreation Property Mortgage
                    </Text>
                    <Text
                      color={"#00A2D4"}
                      textAlign={"right"}
                      fontFamily={"Nunito"}
                      fontSize={"14px"}
                      fontStyle={"normal"}
                      fontWeight={"800"}
                      lineHeight={"130%"}
                    >
                      {currencyFormat(combinedRecreationMortgage / 2)}
                    </Text>
                  </div>
                )}
                {combinedLandMortgage >= 1 &&
                spousePage === "joint-portfolio" && (
                  <div className="activity-summary-subtitle-container">
                    <Text
                      color={"#004069"}
                      fontFamily={"Nunito"}
                      fontSize={"14px"}
                      fontStyle={"normal"}
                      fontWeight={"600"}
                      lineHeight={"130%"}
                    >
                      Land Debt
                    </Text>
                    <Text
                      color={"#00A2D4"}
                      textAlign={"right"}
                      fontFamily={"Nunito"}
                      fontSize={"14px"}
                      fontStyle={"normal"}
                      fontWeight={"800"}
                      lineHeight={"130%"}
                    >
                      {currencyFormat(combinedLandMortgage)}
                    </Text>
                  </div>
                )}
              {combinedLandMortgage >= 1 &&
                spousePage != "joint-portfolio" && (
                  <div className="activity-summary-subtitle-container">
                    <Text
                      color={"#004069"}
                      fontFamily={"Nunito"}
                      fontSize={"14px"}
                      fontStyle={"normal"}
                      fontWeight={"600"}
                      lineHeight={"130%"}
                    >
                      Land Debt
                    </Text>
                    <Text
                      color={"#00A2D4"}
                      textAlign={"right"}
                      fontFamily={"Nunito"}
                      fontSize={"14px"}
                      fontStyle={"normal"}
                      fontWeight={"800"}
                      lineHeight={"130%"}
                    >
                      {currencyFormat(combinedLandMortgage / 2)}
                    </Text>
                  </div>
                )}
              {combinedIncomePropertyMortgage >= 1 &&
                spousePage === "joint-portfolio" && (
                  <div className="activity-summary-subtitle-container">
                    <Text
                      color={"#004069"}
                      fontFamily={"Nunito"}
                      fontSize={"14px"}
                      fontStyle={"normal"}
                      fontWeight={"600"}
                      lineHeight={"130%"}
                    >
                      Income Property Mortgage
                    </Text>
                    <Text
                      color={"#00A2D4"}
                      textAlign={"right"}
                      fontFamily={"Nunito"}
                      fontSize={"14px"}
                      fontStyle={"normal"}
                      fontWeight={"800"}
                      lineHeight={"130%"}
                    >
                      {currencyFormat(combinedIncomePropertyMortgage)}
                    </Text>
                  </div>
                )}
              {combinedIncomePropertyMortgage >= 1 &&
                spousePage != "joint-portfolio" && (
                  <div className="activity-summary-subtitle-container">
                    <Text
                      color={"#004069"}
                      fontFamily={"Nunito"}
                      fontSize={"14px"}
                      fontStyle={"normal"}
                      fontWeight={"600"}
                      lineHeight={"130%"}
                    >
                      Income Property Mortgage
                    </Text>
                    <Text
                      color={"#00A2D4"}
                      textAlign={"right"}
                      fontFamily={"Nunito"}
                      fontSize={"14px"}
                      fontStyle={"normal"}
                      fontWeight={"800"}
                      lineHeight={"130%"}
                    >
                      {currencyFormat(combinedIncomePropertyMortgage / 2)}
                    </Text>
                  </div>
                )}
              {combinedLoans >= 1 && spousePage === "joint-portfolio" && (
                <div className="activity-summary-subtitle-container">
                  <Text
                    color={"#004069"}
                    fontFamily={"Nunito"}
                    fontSize={"14px"}
                    fontStyle={"normal"}
                    fontWeight={"600"}
                    lineHeight={"130%"}
                  >
                    Total Loans
                  </Text>
                  <Text
                    color={"#00A2D4"}
                    textAlign={"right"}
                    fontFamily={"Nunito"}
                    fontSize={"14px"}
                    fontStyle={"normal"}
                    fontWeight={"800"}
                    lineHeight={"130%"}
                  >
                    {currencyFormat(combinedLoans)}
                  </Text>
                </div>
              )}
              {combinedLoans >= 1 && spousePage != "joint-portfolio" && (
                <div className="activity-summary-subtitle-container">
                  <Text
                    color={"#004069"}
                    fontFamily={"Nunito"}
                    fontSize={"14px"}
                    fontStyle={"normal"}
                    fontWeight={"600"}
                    lineHeight={"130%"}
                  >
                    Total Loans
                  </Text>
                  <Text
                    color={"#00A2D4"}
                    textAlign={"right"}
                    fontFamily={"Nunito"}
                    fontSize={"14px"}
                    fontStyle={"normal"}
                    fontWeight={"800"}
                    lineHeight={"130%"}
                  >
                    {currencyFormat(combinedLoans / 2)}
                  </Text>
                </div>
              )}
              {spousePage === "joint-portfolio" && (
                <Box
                  display={"flex"}
                  height={"26px"}
                  justifyContent={"space-between"}
                  alignItems={"flex-start"}
                  alignSelf={"stretch"}
                >
                  <Text
                    color={"#004069"}
                    fontFamily={"Nunito"}
                    fontSize={"20px"}
                    fontStyle={"normal"}
                    fontWeight={"900"}
                    lineHeight={"130%"}
                  >
                    Total Debt{" "}
                  </Text>
                  <Text
                    color={"#00A2D4"}
                    textAlign={"right"}
                    fontFamily={"Nunito"}
                    fontSize={"20px"}
                    fontStyle={"normal"}
                    fontWeight={"900"}
                    lineHeight={"130%"}
                  >
                    {currencyFormat(
                      safeAdd(
                        combinedLoans,
                        combinedIncomePropertyMortgage,
                        combinedRecreationMortgage,
                        combinedLandMortgage,
                        combinedPrimaryResMortgage
                      )
                    )}
                  </Text>
                </Box>
              )}
              {spousePage != "joint-portfolio" && (
                <Box
                  display={"flex"}
                  height={"26px"}
                  justifyContent={"space-between"}
                  alignItems={"flex-start"}
                  alignSelf={"stretch"}
                >
                  <Text
                    color={"#004069"}
                    fontFamily={"Nunito"}
                    fontSize={"20px"}
                    fontStyle={"normal"}
                    fontWeight={"900"}
                    lineHeight={"130%"}
                  >
                    Total Debt{" "}
                  </Text>
                  <Text
                    color={"#00A2D4"}
                    textAlign={"right"}
                    fontFamily={"Nunito"}
                    fontSize={"20px"}
                    fontStyle={"normal"}
                    fontWeight={"900"}
                    lineHeight={"130%"}
                  >
                    {currencyFormat(
                      safeAdd(
                        combinedLoans / 2,
                        combinedIncomePropertyMortgage / 2,
                        combinedRecreationMortgage / 2,
                        combinedLandMortgage / 2,
                        combinedPrimaryResMortgage / 2
                      )
                    )}
                  </Text>
                </Box>
              )}
            </>
          }
          Graph={
            <BarGraph
              combinedPrimaryResMortgage={combinedPrimaryResMortgage}
              combinedRecreationMortgage={combinedRecreationMortgage}
              combinedLandMortgage={combinedLandMortgage}
              combinedIncomePropertyMortgage={combinedIncomePropertyMortgage}
              combinedLoans={combinedLoans}
              combinedTotal={safeAdd(
                combinedLoans,
                combinedIncomePropertyMortgage,
                combinedRecreationMortgage,
                combinedLandMortgage,
                combinedPrimaryResMortgage
              )}
            />
          }
        />
      )}
    </>
  );
}

function NetWorth(props) {
  const {
    isLargerThan1200,
    isSmallScreen,
    combinedTFSA,
    combinedFHSA,
    combinedRRSP,
    combinedNonReg,
    combinedCashBalance,
    combinedFixedIncomeBalance,
    combinedLIRAPortfolioBalance,
    combinedAssetPortfolioBalance,
    combinedBusinessValue,
    combinedBusinessInvestmentsValue,
    combinedLifeInsuranceBalances,
    combinedPensionValue,
    combinedPersonAssetsBalance,
    combinedPrimaryResMortgage,
    combinedRecreationMortgage,
    combinedLandMortgage,
    combinedIncomePropertyMortgage,
    combinedLoans,
    spousePage,
  } = props;

  return (
    <>
      {isSmallScreen && (
        <>
          <Box
            display={"flex"}
            flexDirection={"column"}
            width={"100%"}
            flexGrow={1}
            alignItems={"center"}
          >
            <Box display={"flex"} alignSelf={"center"} fill={"#00A2D4"}>
              <BarGraph
                totalPortfolio={safeAdd(
                  combinedTFSA,
                  combinedFHSA,
                  combinedRRSP,
                  combinedNonReg,
                  combinedCashBalance,
                  combinedFixedIncomeBalance,
                  combinedLIRAPortfolioBalance,
                  combinedAssetPortfolioBalance,
                  combinedBusinessValue,
                  combinedBusinessInvestmentsValue,
                  combinedLifeInsuranceBalances,
                  combinedPensionValue,
                  combinedPersonAssetsBalance
                )}
                totalDebt={safeAdd(
                  combinedLoans,
                  combinedIncomePropertyMortgage,
                  combinedRecreationMortgage,
                  combinedLandMortgage,
                  combinedPrimaryResMortgage
                )}
                combinedTotal={
                  safeAdd(
                    combinedTFSA,
                    combinedFHSA,
                    combinedRRSP,
                    combinedNonReg,
                    combinedCashBalance,
                    combinedFixedIncomeBalance,
                    combinedLIRAPortfolioBalance,
                    combinedAssetPortfolioBalance,
                    combinedBusinessValue,
                    combinedBusinessInvestmentsValue,
                    combinedLifeInsuranceBalances,
                    combinedPensionValue,
                    combinedPersonAssetsBalance
                  ) -
                  safeAdd(
                    combinedLoans,
                    combinedIncomePropertyMortgage,
                    combinedRecreationMortgage,
                    combinedLandMortgage,
                    combinedPrimaryResMortgage
                  )
                }
              />
            </Box>
            <Box
              display={"flex"}
              width={"100%"}
              flexDirection={"column"}
              alignItems={"flex-start"}
              gap={"10px"}
            >
              <Box>
                <Text
                  color={"#00A2D4"}
                  textAlign={"right"}
                  fontFamily={"Nunito"}
                  fontSize={"24px"}
                  fontStyle={"normal"}
                  fontWeight={"800"}
                  lineHeight={"130%"}
                >
                  Total Net Worth
                </Text>
              </Box>

              {spousePage === "joint-portfolio" && (
                <div className="activity-summary-subtitle-container">
                  <Text
                    color={"#004069"}
                    fontFamily={"Nunito"}
                    fontSize={"14px"}
                    fontStyle={"normal"}
                    fontWeight={"600"}
                    lineHeight={"130%"}
                  >
                    Total Portfolio
                  </Text>
                  <Text
                    color={"#00A2D4"}
                    textAlign={"right"}
                    fontFamily={"Nunito"}
                    fontSize={"14px"}
                    fontStyle={"normal"}
                    fontWeight={"800"}
                    lineHeight={"130%"}
                  >
                    {currencyFormat(
                      safeAdd(
                        combinedTFSA,
                        combinedFHSA,
                        combinedRRSP,
                        combinedNonReg,
                        combinedCashBalance,
                        combinedFixedIncomeBalance,
                        combinedLIRAPortfolioBalance,
                        combinedAssetPortfolioBalance,
                        combinedBusinessValue,
                        combinedBusinessInvestmentsValue,
                        combinedLifeInsuranceBalances,
                        combinedPensionValue,
                        combinedPersonAssetsBalance
                      )
                    )}
                  </Text>
                </div>
              )}
              {spousePage != "joint-portfolio" && (
                <div className="activity-summary-subtitle-container">
                  <Text
                    color={"#004069"}
                    fontFamily={"Nunito"}
                    fontSize={"14px"}
                    fontStyle={"normal"}
                    fontWeight={"600"}
                    lineHeight={"130%"}
                  >
                    Total Portfolio{" "}
                  </Text>
                  <Text
                    color={"#00A2D4"}
                    textAlign={"right"}
                    fontFamily={"Nunito"}
                    fontSize={"14px"}
                    fontStyle={"normal"}
                    fontWeight={"800"}
                    lineHeight={"130%"}
                  >
                    {currencyFormat(
                      safeAdd(
                        combinedTFSA,
                        combinedFHSA,
                        combinedRRSP,
                        combinedNonReg,
                        combinedCashBalance,
                        combinedFixedIncomeBalance,
                        combinedLIRAPortfolioBalance,
                        combinedAssetPortfolioBalance / 2,
                        combinedBusinessValue / 2,
                        combinedBusinessInvestmentsValue / 2,
                        combinedLifeInsuranceBalances / 2,
                        combinedPensionValue,
                        combinedPersonAssetsBalance / 2
                      )
                    )}
                  </Text>
                </div>
              )}
              {spousePage === "joint-portfolio" && (
                <div className="activity-summary-subtitle-container">
                  <Text
                    color={"#004069"}
                    fontFamily={"Nunito"}
                    fontSize={"14px"}
                    fontStyle={"normal"}
                    fontWeight={"600"}
                    lineHeight={"130%"}
                  >
                    Total Debt{" "}
                  </Text>
                  <Text
                    color={"#00A2D4"}
                    textAlign={"right"}
                    fontFamily={"Nunito"}
                    fontSize={"14px"}
                    fontStyle={"normal"}
                    fontWeight={"800"}
                    lineHeight={"130%"}
                  >
                    -{" "}
                    {currencyFormat(
                      safeAdd(
                        combinedLoans,
                        combinedIncomePropertyMortgage,
                        combinedRecreationMortgage,
                        combinedLandMortgage,
                        combinedPrimaryResMortgage
                      )
                    )}
                  </Text>
                </div>
              )}
              {spousePage != "joint-portfolio" && (
                <div className="activity-summary-subtitle-container">
                  <Text
                    color={"#004069"}
                    fontFamily={"Nunito"}
                    fontSize={"14px"}
                    fontStyle={"normal"}
                    fontWeight={"600"}
                    lineHeight={"130%"}
                  >
                    Total Debt{" "}
                  </Text>
                  <Text
                    color={"#00A2D4"}
                    textAlign={"right"}
                    fontFamily={"Nunito"}
                    fontSize={"14px"}
                    fontStyle={"normal"}
                    fontWeight={"800"}
                    lineHeight={"130%"}
                  >
                    -{" "}
                    {currencyFormat(
                      safeAdd(
                        combinedLoans / 2,
                        combinedIncomePropertyMortgage / 2,
                        combinedRecreationMortgage / 2,
                        combinedLandMortgage / 2,
                        combinedPrimaryResMortgage / 2
                      )
                    )}
                  </Text>
                </div>
              )}
              {spousePage === "joint-portfolio" && (
                <Box
                  display={"flex"}
                  height={"26px"}
                  justifyContent={"space-between"}
                  alignItems={"flex-start"}
                  alignSelf={"stretch"}
                >
                  <Text
                    color={"#004069"}
                    fontFamily={"Nunito"}
                    fontSize={"20px"}
                    fontStyle={"normal"}
                    fontWeight={"900"}
                    lineHeight={"130%"}
                  >
                    Net{" "}
                  </Text>
                  <Text
                    color={"#00A2D4"}
                    textAlign={"right"}
                    fontFamily={"Nunito"}
                    fontSize={"20px"}
                    fontStyle={"normal"}
                    fontWeight={"900"}
                    lineHeight={"130%"}
                  >
                    {currencyFormat(
                      safeAdd(
                        combinedTFSA,
                        combinedFHSA,
                        combinedRRSP,
                        combinedNonReg,
                        combinedCashBalance,
                        combinedFixedIncomeBalance,
                        combinedLIRAPortfolioBalance,
                        combinedAssetPortfolioBalance,
                        combinedBusinessValue,
                        combinedBusinessInvestmentsValue,
                        combinedLifeInsuranceBalances,
                        combinedPensionValue,
                        combinedPersonAssetsBalance
                      ) -
                        safeAdd(
                          combinedLoans,
                          combinedIncomePropertyMortgage,
                          combinedRecreationMortgage,
                          combinedLandMortgage,
                          combinedPrimaryResMortgage
                        )
                    )}
                  </Text>
                </Box>
              )}
              {spousePage != "joint-portfolio" && (
                <Box
                  display={"flex"}
                  height={"26px"}
                  justifyContent={"space-between"}
                  alignItems={"flex-start"}
                  alignSelf={"stretch"}
                >
                  <Text
                    color={"#004069"}
                    fontFamily={"Nunito"}
                    fontSize={"20px"}
                    fontStyle={"normal"}
                    fontWeight={"900"}
                    lineHeight={"130%"}
                  >
                    Net{" "}
                  </Text>
                  <Text
                    color={"#00A2D4"}
                    textAlign={"right"}
                    fontFamily={"Nunito"}
                    fontSize={"20px"}
                    fontStyle={"normal"}
                    fontWeight={"900"}
                    lineHeight={"130%"}
                  >
                    {currencyFormat(
                      safeAdd(
                        combinedTFSA,
                        combinedFHSA,
                        combinedRRSP,
                        combinedNonReg,
                        combinedCashBalance,
                        combinedFixedIncomeBalance,
                        combinedLIRAPortfolioBalance,
                        combinedAssetPortfolioBalance / 2,
                        combinedBusinessValue / 2,
                        combinedBusinessInvestmentsValue / 2,
                        combinedLifeInsuranceBalances / 2,
                        combinedPensionValue,
                        combinedPersonAssetsBalance / 2
                      ) -
                        safeAdd(
                          combinedLoans / 2,
                          combinedIncomePropertyMortgage / 2,
                          combinedRecreationMortgage / 2,
                          combinedLandMortgage / 2, 
                          combinedPrimaryResMortgage / 2
                        )
                    )}
                  </Text>
                </Box>
              )}
            </Box>
          </Box>
        </>
      )}
      {!isSmallScreen && (
        <DashboardSubCards
          Heading={"Net Worth"}
          mainframecontent={
            <>
              {spousePage === "joint-portfolio" && (
                <div className="activity-summary-subtitle-container">
                  <Text
                    color={"#004069"}
                    fontFamily={"Nunito"}
                    fontSize={"14px"}
                    fontStyle={"normal"}
                    fontWeight={"600"}
                    lineHeight={"130%"}
                  >
                    Total Portfolio
                  </Text>
                  <Text
                    color={"#00A2D4"}
                    textAlign={"right"}
                    fontFamily={"Nunito"}
                    fontSize={"14px"}
                    fontStyle={"normal"}
                    fontWeight={"800"}
                    lineHeight={"130%"}
                  >
                    {currencyFormat(
                      safeAdd(
                        combinedTFSA,
                        combinedFHSA,
                        combinedRRSP,
                        combinedNonReg,
                        combinedCashBalance,
                        combinedFixedIncomeBalance,
                        combinedLIRAPortfolioBalance,
                        combinedAssetPortfolioBalance,
                        combinedBusinessValue,
                        combinedBusinessInvestmentsValue,
                        combinedLifeInsuranceBalances,
                        combinedPensionValue,
                        combinedPersonAssetsBalance
                      )
                    )}
                  </Text>
                </div>
              )}
              {spousePage != "joint-portfolio" && (
                <div className="activity-summary-subtitle-container">
                  <Text
                    color={"#004069"}
                    fontFamily={"Nunito"}
                    fontSize={"14px"}
                    fontStyle={"normal"}
                    fontWeight={"600"}
                    lineHeight={"130%"}
                  >
                    Total Portfolio{" "}
                  </Text>
                  <Text
                    color={"#00A2D4"}
                    textAlign={"right"}
                    fontFamily={"Nunito"}
                    fontSize={"14px"}
                    fontStyle={"normal"}
                    fontWeight={"800"}
                    lineHeight={"130%"}
                  >
                    {currencyFormat(
                      safeAdd(
                        combinedTFSA,
                        combinedFHSA,
                        combinedRRSP,
                        combinedNonReg,
                        combinedCashBalance,
                        combinedFixedIncomeBalance,
                        combinedLIRAPortfolioBalance,
                        combinedAssetPortfolioBalance / 2,
                        combinedBusinessValue / 2,
                        combinedBusinessInvestmentsValue / 2,
                        combinedLifeInsuranceBalances / 2,
                        combinedPensionValue,
                        combinedPersonAssetsBalance / 2
                      )
                    )}
                  </Text>
                </div>
              )}
              {spousePage === "joint-portfolio" && (
                <div className="activity-summary-subtitle-container">
                  <Text
                    color={"#004069"}
                    fontFamily={"Nunito"}
                    fontSize={"14px"}
                    fontStyle={"normal"}
                    fontWeight={"600"}
                    lineHeight={"130%"}
                  >
                    Total Debt{" "}
                  </Text>
                  <Text
                    color={"#00A2D4"}
                    textAlign={"right"}
                    fontFamily={"Nunito"}
                    fontSize={"14px"}
                    fontStyle={"normal"}
                    fontWeight={"800"}
                    lineHeight={"130%"}
                  >
                    -{" "}
                    {currencyFormat(
                      safeAdd(
                        combinedLoans,
                        combinedIncomePropertyMortgage,
                        combinedRecreationMortgage,
                        combinedLandMortgage,
                        combinedPrimaryResMortgage
                      )
                    )}
                  </Text>
                </div>
              )}
              {spousePage != "joint-portfolio" && (
                <div className="activity-summary-subtitle-container">
                  <Text
                    color={"#004069"}
                    fontFamily={"Nunito"}
                    fontSize={"14px"}
                    fontStyle={"normal"}
                    fontWeight={"600"}
                    lineHeight={"130%"}
                  >
                    Total Debt{" "}
                  </Text>
                  <Text
                    color={"#00A2D4"}
                    textAlign={"right"}
                    fontFamily={"Nunito"}
                    fontSize={"14px"}
                    fontStyle={"normal"}
                    fontWeight={"800"}
                    lineHeight={"130%"}
                  >
                    -{" "}
                    {currencyFormat(
                      safeAdd(
                        combinedLoans / 2,
                        combinedIncomePropertyMortgage / 2,
                        combinedRecreationMortgage / 2,
                        combinedLandMortgage / 2,
                        combinedPrimaryResMortgage / 2
                      )
                    )}
                  </Text>
                </div>
              )}
              {spousePage === "joint-portfolio" && (
                <Box
                  display={"flex"}
                  height={"26px"}
                  justifyContent={"space-between"}
                  alignItems={"flex-start"}
                  alignSelf={"stretch"}
                >
                  <Text
                    color={"#004069"}
                    fontFamily={"Nunito"}
                    fontSize={"20px"}
                    fontStyle={"normal"}
                    fontWeight={"900"}
                    lineHeight={"130%"}
                  >
                    Net{" "}
                  </Text>
                  <Text
                    color={"#00A2D4"}
                    textAlign={"right"}
                    fontFamily={"Nunito"}
                    fontSize={"20px"}
                    fontStyle={"normal"}
                    fontWeight={"900"}
                    lineHeight={"130%"}
                  >
                    {currencyFormat(
                      safeAdd(
                        combinedTFSA,
                        combinedFHSA,
                        combinedRRSP,
                        combinedNonReg,
                        combinedCashBalance,
                        combinedFixedIncomeBalance,
                        combinedLIRAPortfolioBalance,
                        combinedAssetPortfolioBalance,
                        combinedBusinessValue,
                        combinedBusinessInvestmentsValue,
                        combinedLifeInsuranceBalances,
                        combinedPensionValue,
                        combinedPersonAssetsBalance
                      ) -
                        safeAdd(
                          combinedLoans,
                          combinedIncomePropertyMortgage,
                          combinedRecreationMortgage,
                          combinedLandMortgage,
                          combinedPrimaryResMortgage
                        )
                    )}
                  </Text>
                </Box>
              )}
              {spousePage != "joint-portfolio" && (
                <Box
                  display={"flex"}
                  height={"26px"}
                  justifyContent={"space-between"}
                  alignItems={"flex-start"}
                  alignSelf={"stretch"}
                >
                  <Text
                    color={"#004069"}
                    fontFamily={"Nunito"}
                    fontSize={"20px"}
                    fontStyle={"normal"}
                    fontWeight={"900"}
                    lineHeight={"130%"}
                  >
                    Net{" "}
                  </Text>
                  <Text
                    color={"#00A2D4"}
                    textAlign={"right"}
                    fontFamily={"Nunito"}
                    fontSize={"20px"}
                    fontStyle={"normal"}
                    fontWeight={"900"}
                    lineHeight={"130%"}
                  >
                    {currencyFormat(
                      safeAdd(
                        combinedTFSA,
                        combinedFHSA,
                        combinedRRSP,
                        combinedNonReg,
                        combinedCashBalance,
                        combinedFixedIncomeBalance,
                        combinedLIRAPortfolioBalance,
                        combinedAssetPortfolioBalance / 2,
                        combinedBusinessValue / 2,
                        combinedBusinessInvestmentsValue / 2,
                        combinedLifeInsuranceBalances / 2,
                        combinedPensionValue,
                        combinedPersonAssetsBalance / 2
                      ) -
                        safeAdd(
                          combinedLoans / 2,
                          combinedIncomePropertyMortgage / 2,
                          combinedRecreationMortgage / 2,
                          combinedLandMortgage / 2, 
                          combinedPrimaryResMortgage / 2
                        )
                    )}
                  </Text>
                </Box>
              )}
            </>
          }
          Graph={
            <BarGraph
              totalPortfolio={safeAdd(
                combinedTFSA,
                combinedFHSA,
                combinedRRSP,
                combinedNonReg,
                combinedCashBalance,
                combinedFixedIncomeBalance,
                combinedLIRAPortfolioBalance,
                combinedAssetPortfolioBalance,
                combinedBusinessValue,
                combinedBusinessInvestmentsValue,
                combinedLifeInsuranceBalances,
                combinedPensionValue,
                combinedPersonAssetsBalance
              )}
              totalDebt={safeAdd(
                combinedLoans,
                combinedIncomePropertyMortgage,
                combinedRecreationMortgage,
                combinedLandMortgage,
                combinedPrimaryResMortgage
              )}
              combinedTotal={
                safeAdd(
                  combinedTFSA,
                  combinedFHSA,
                  combinedRRSP,
                  combinedNonReg,
                  combinedCashBalance,
                  combinedFixedIncomeBalance,
                  combinedLIRAPortfolioBalance,
                  combinedAssetPortfolioBalance,
                  combinedBusinessValue,
                  combinedBusinessInvestmentsValue,
                  combinedLifeInsuranceBalances,
                  combinedPensionValue,
                  combinedPersonAssetsBalance
                ) -
                safeAdd(
                  combinedLoans,
                  combinedIncomePropertyMortgage,
                  combinedRecreationMortgage,
                  combinedLandMortgage,
                  combinedPrimaryResMortgage
                )
              }
            />
          }
        />
      )}
    </>
  );
}

function DashboardLargeCard(props) {
  const {
    card,
    optiList,
    assetsList,
    spousePage,
    spouseOptiList,
    combinedIncome,
    combinedAssetSaleIncome,
    combinedCPP,
    combinedOAS,
    combinedPension,
    combinedOtherIncome,
    combinedIncomePropertyIncome,
    combinedBusinessIncome,
    combinedInheritanceIncome,
    combinedTotalIncome,
    combinedTFSADeposits,
    combinedFHSADeposits,
    combinedRRSPDeposits,
    combinedNonRegDeposits,
    combinedRRSPContributions,
    combinedPensionContributions,
    combinedTFSAWithdrawals,
    combinedFHSAWithdrawals,
    combinedRRSPWithdrawals,
    combinedLIRAWithdrawals,
    combinedNonRegWithdrawals,
    combinedCashWithdrawals,
    combinedFixedWithdrawals,
    combinedBusinessDividendWithdrawals,
    combinedCCPCEligibleDividends,
    combinedCCPCNonEligibleDividends,
    combinedCCPCCapitalDividends,
    combinedTFSAPortfolioBalance,
    combinedFHSAPortfolioBalance,
    combinedRRSPPortfolioBalance,
    combinedLIRAPortfolioBalance,
    combinedNonRegPortfolioBalance,
    combinedCashBalance,
    combinedFixedIncomeBalance,
    combinedAssetPortfolioBalance,
    combinedBusinessValue,
    combinedBusinessInvestmentsValue,
    combinedPersonAssetsBalance,
    combinedLifeInsuranceBalances,
    combinedPensionValue,
    provincialTax,
    federalTax,
    cppContributions,
    eiPremium,
    combinedDeductions,
    taxesTotal,
    averageTaxRate,
    totalIncome,
    totalTaxes,
    totalDeposits,
    totalWithdrawals,
    totalExpenses,
    thisYearDeposits,
    extraSpending,
    totalGoals,
    extraSpendingSpouse,
    combinedPrimaryResMortgage,
    combinedRecreationMortgage,
    combinedLandMortgage,
    combinedIncomePropertyMortgage,
    combinedLoans,
    name,
  } = props;

  const [isLargerThan500] = useMediaQuery("(min-width: 500px)");
  const isSmallScreen = !isLargerThan500;
  const [isLargerThan1200] = useMediaQuery(
    "(min-width: 500px) and (max-width: 1250px)"
  );

  return (
    <>
      {card === "income" && (
        <Income
          isLargerThan1200={isLargerThan1200}
          isSmallScreen={isSmallScreen}
          income={combinedIncome}
          combinedCPP={combinedCPP}
          combinedOAS={combinedOAS}
          combinedPension={combinedPension}
          combinedTotalIncome={combinedTotalIncome}
          combinedOtherIncome={combinedOtherIncome}
          combinedAssetSaleIncome={combinedAssetSaleIncome}
          combinedIncomePropertyIncome={combinedIncomePropertyIncome}
          combinedBusinessIncome={combinedBusinessIncome}
          combinedInheritanceIncome={combinedInheritanceIncome}
          spousePage={spousePage}
        />
      )}
      {card === "deposits" && (
        <Deposits
          isLargerThan1200={isLargerThan1200}
          isSmallScreen={isSmallScreen}
          combinedTFSA={combinedTFSADeposits}
          combinedFHSA={combinedFHSADeposits}
          combinedRRSP={combinedRRSPDeposits}
          combinedNonReg={combinedNonRegDeposits}
          combinedRRSPContributions={combinedRRSPContributions}
          combinedPensionContributions={combinedPensionContributions}
        />
      )}
      {card === "ActionPlan" && (
        <ActionPlan
          isLargerThan1200={isLargerThan1200}
          isSmallScreen={isSmallScreen}
          combinedTFSA={combinedTFSADeposits}
          combinedFHSA={combinedFHSADeposits}
          combinedRRSP={combinedRRSPDeposits}
          combinedNonReg={combinedNonRegDeposits}
          combinedRRSPContributions={combinedRRSPContributions}
          combinedPensionContributions={combinedPensionContributions}
          name={name}
          combinedTFSAWithdrawals={combinedTFSAWithdrawals}
          combinedFHSAWithdrawals={combinedFHSAWithdrawals}
          combinedRRSPWithdrawals={combinedRRSPWithdrawals}
          combinedNonRegWithdrawals={combinedNonRegWithdrawals}
          combinedCashWithdrawals={combinedCashWithdrawals}
          combinedFixedWithdrawals={combinedFixedWithdrawals}
          combinedLIRAWithdrawals={combinedLIRAWithdrawals}
          combinedCCPCEligibleDividends={combinedCCPCEligibleDividends}
          combinedCCPCNonEligibleDividends={combinedCCPCNonEligibleDividends}
          combinedCCPCCapitalDividends={combinedCCPCCapitalDividends}
        />
      )}
      {card === "withdrawals" && (
        <Withdrawals
          isLargerThan1200={isLargerThan1200}
          isSmallScreen={isSmallScreen}
          combinedTFSA={combinedTFSAWithdrawals}
          combinedFHSA={combinedFHSAWithdrawals}
          combinedRRSP={combinedRRSPWithdrawals}
          combinedNonReg={combinedNonRegWithdrawals}
          combinedCashWithdrawals={combinedCashWithdrawals}
          combinedFixedWithdrawals={combinedFixedWithdrawals}
          combinedBusinessDividendWithdrawals={
            combinedBusinessDividendWithdrawals
          }
          combinedLIRAWithdrawals={combinedLIRAWithdrawals}
        />
      )}
      {card === "taxes" && (
        <Taxes
          isLargerThan1200={isLargerThan1200}
          isSmallScreen={isSmallScreen}
          provincialTax={provincialTax}
          federalTax={federalTax}
          cppContributions={cppContributions}
          eiPremium={eiPremium}
          taxesTotal={taxesTotal}
          averageTaxRate={averageTaxRate}
          combinedDeductions={combinedDeductions}
        />
      )}
      {card === "budgetSummary" && (
        <BudgetSummary
          isLargerThan1200={isLargerThan1200}
          isSmallScreen={isSmallScreen}
          totalIncome={totalIncome}
          totalTaxes={totalTaxes}
          totalDeposits={totalDeposits}
          totalExpenses={totalExpenses}
          thisYearDeposits={thisYearDeposits}
          extraSpending={extraSpending}
          totalGoals={totalGoals}
          totalWithdrawals={totalWithdrawals}
          spousePage={spousePage}
          combinedAssetSaleIncome={combinedAssetSaleIncome}
          combinedIncomePropertyIncome={combinedIncomePropertyIncome}
          combinedBusinessIncome={combinedBusinessIncome}
          extraSpendingSpouse={extraSpendingSpouse}
        />
      )}
      {card === "portfolioBalance" && (
        <PortFolioBalance
          isLargerThan1200={isLargerThan1200}
          isSmallScreen={isSmallScreen}
          combinedTFSA={combinedTFSAPortfolioBalance}
          combinedFHSA={combinedFHSAPortfolioBalance}
          combinedRRSP={combinedRRSPPortfolioBalance}
          combinedNonReg={combinedNonRegPortfolioBalance}
          combinedCashBalance={combinedCashBalance}
          combinedFixedIncomeBalance={combinedFixedIncomeBalance}
          combinedLIRAPortfolioBalance={combinedLIRAPortfolioBalance}
          combinedAssetPortfolioBalance={combinedAssetPortfolioBalance}
          combinedBusinessInvestmentsValue={combinedBusinessInvestmentsValue}
          combinedBusinessValue={combinedBusinessValue}
          combinedLifeInsuranceBalances={combinedLifeInsuranceBalances}
          combinedPensionValue={combinedPensionValue}
          combinedPersonAssetsBalance={combinedPersonAssetsBalance}
          assetsList={assetsList}
          spousePage={spousePage}
        />
      )}
      {card === "debt" && (
        <Debt
          isLargerThan1200={isLargerThan1200}
          isSmallScreen={isSmallScreen}
          combinedPrimaryResMortgage={combinedPrimaryResMortgage}
          combinedRecreationMortgage={combinedRecreationMortgage}
          combinedLandMortgage={combinedLandMortgage}
          combinedIncomePropertyMortgage={combinedIncomePropertyMortgage}
          combinedLoans={combinedLoans}
          spousePage={spousePage}
        />
      )}
      {card === "netWorth" && (
        <NetWorth
          isLargerThan1200={isLargerThan1200}
          isSmallScreen={isSmallScreen}
          combinedTFSA={combinedTFSAPortfolioBalance}
          combinedFHSA={combinedFHSAPortfolioBalance}
          combinedRRSP={combinedRRSPPortfolioBalance}
          combinedNonReg={combinedNonRegPortfolioBalance}
          combinedCashBalance={combinedCashBalance}
          combinedFixedIncomeBalance={combinedFixedIncomeBalance}
          combinedLIRAPortfolioBalance={combinedLIRAPortfolioBalance}
          combinedAssetPortfolioBalance={combinedAssetPortfolioBalance}
          combinedBusinessInvestmentsValue={combinedBusinessInvestmentsValue}
          combinedBusinessValue={combinedBusinessValue}
          combinedLifeInsuranceBalances={combinedLifeInsuranceBalances}
          combinedPensionValue={combinedPensionValue}
          combinedPersonAssetsBalance={combinedPersonAssetsBalance}
          combinedPrimaryResMortgage={combinedPrimaryResMortgage}
          combinedRecreationMortgage={combinedRecreationMortgage}
          combinedLandMortgage={combinedLandMortgage}
          combinedIncomePropertyMortgage={combinedIncomePropertyMortgage}
          combinedLoans={combinedLoans}
          spousePage={spousePage}
        />
      )}
    </>
  );
}

export default DashboardLargeCard;
