import axios from "axios";
import React, {
  forwardRef,
  useContext,
  useEffect,
  useImperativeHandle,
  useState,
} from "react";
import { useStore } from "../../../store";
import { useAuth0 } from "@auth0/auth0-react";
import { Flex, VStack, Box, Text, useMediaQuery } from "@chakra-ui/react";
import GlobalBodyPage from "../../GlobalContainer/BodyPage";
import DropDownButton from "../../InputFields/dropDownButton";
import NormalInputButton from "../../InputFields/normalInputButton";
import TableComponent from "../../TableComponent";
import TooltipContent from "../../TooltipContent";
import { CloseIcon } from "../../CustomIcons";

const LifeInsurancePage = forwardRef((props, ref) => {
  const { getAccessTokenSilently, user } = useAuth0();

  useImperativeHandle(ref, () => ({
    submitHandler,
    checkMandatoryFields,
  }));

  const submitHandler = () => {
    setUserEditAssetInfo({});
    return true;
  };

  const checkMandatoryFields = () => {
    return true;
  };

  const axiosInstance = axios.create();
  async function getToken() {
    // Replace this with your Auth0 token retrieval logic
    const token = await getAccessTokenSilently({
      audience:
        "https://os2lsiwff43wfpb5ggyawt6qri0mgcro.lambda-url.us-east-2.on.aws/",
      scope: "write:optimization",
    });
    return token;
  }

  // Add a request interceptor
  axiosInstance.interceptors.request.use(
    async (config) => {
      const token = await getToken();
      config.headers.Authorization = `Bearer ${token}`;
      return config;
    },
    (error) => {
      // Do something with request error
      return Promise.reject(error);
    }
  );
  const userEditAssetInfo = useStore((store) => store.userEditAssetInfo);
  const setUserEditAssetInfo = useStore((store) => store.setUserEditAssetInfo);

  const [lifeInsuranceType, setLifeInsuranceType] = useState("");
  const [lifeInsuranceValue, setLifeInsuranceValue] = useState(0);
  const [lifeInsurancePremium, setLifeInsurancePremium] = useState(0);
  const [lifeInsuranceEndDate, setLifeInsuranceEndDate] = useState("");
  const [lifeInsuranceNumber, setLifeInsuranceNumber] = useState("");

  // New Features
  const [faceValue, setFaceValue] = useState("");
  const [CashValue, setCashValue] = useState("");
  const [PolicyHolder, setPolicyHolder] = useState("");
  // New Features

  const [isLargerThan768] = useMediaQuery("(min-width: 500px)");
  const isMobile = !isLargerThan768;

  const [lifeInsuranceArray, setLifeInsuranceArray] = useState([]);
  const [editing, setEditing] = useState(false);
  const [editingIndex, setEditingIndex] = useState(null);
  const [showDeleteModal, setShowDeleteModal] = useState(null);

  const [adding, setAdding] = useState(false);

  const userLifeInsuranceInfo = useStore(
    (store) => store.userLifeInsuranceInfo
  );
  const setUserLifeInsuranceInfo = useStore(
    (store) => store.setUserLifeInsuranceInfo
  );
  const updateUserLifeInsuranceInfo = useStore(
    (store) => store.updateUserLifeInsuranceInfo
  );
  const deleteUserLifeInsuranceInfo = useStore(
    (store) => store.deleteUserLifeInsuranceInfo
  );

  const userInfo = useStore((store) => store.userInfo);

  const {
    FirstName: firstName,
    LastName: lastName,
    DateOfBirth: currentAge,
    spouseFirstName,
    spouseLastName,
    runAge,
  } = userInfo;

  useEffect(() => {
    console.log(userEditAssetInfo);
    if (
      userEditAssetInfo?.editAsset?.name === "Term life insurance" ||
      userEditAssetInfo?.editAsset?.name === "Whole life insurance" ||
      userEditAssetInfo?.editAsset?.name === "Whole life - limited payments"
    ) {
      setLifeInsuranceType(userEditAssetInfo?.editAsset?.name);
      setLifeInsuranceValue(userEditAssetInfo?.editAsset?.value);
      setLifeInsurancePremium(userEditAssetInfo?.editAsset?.Monthly_Premiums);
      setLifeInsuranceEndDate(
        userEditAssetInfo?.editAsset?.Life_Insurance_End_Date
      );
      setLifeInsuranceNumber(
        userEditAssetInfo?.editAsset?.Life_Insurance_Number
      );
      setEditing(true);
      setAdding(true);
      setEditingIndex(userEditAssetInfo?.editIndex);
    }
  }, [userEditAssetInfo]);

  useEffect(() => {
    const updateLifeInsuranceArray = [];

    if (userLifeInsuranceInfo != null) {
      updateLifeInsuranceArray.push(...userLifeInsuranceInfo);
    }

    setLifeInsuranceArray(updateLifeInsuranceArray);
  }, [userLifeInsuranceInfo]);

  const onDeleteRow = (i) => {
    setEditingIndex(i);
    setShowDeleteModal(true);
  };

  const editField = (i, array) => {
    setAdding(true);
    if (
      array[i].name === "Term life insurance" ||
      array[i].name === "Whole life insurance" ||
      array[i].name === "Whole life - limited payments"
    ) {
      setLifeInsuranceType(array[i]?.name);
      setLifeInsuranceValue(array[i]?.value);
      setLifeInsurancePremium(array[i]?.Monthly_Premiums);
      setLifeInsuranceEndDate(array[i]?.Life_Insurance_End_Date);
      setLifeInsuranceNumber(array[i]?.Life_Insurance_Number);
    }
    setEditing(true);
    setEditingIndex(i);
  };

  const DeleteRealEstateModal = () => {
    let activeArray = lifeInsuranceArray;

    const removeRealEstateModal = () => {
      const lifeInsuranceURL = (action) =>
        `${process.env.REACT_APP_NODE_API_GATEWAY}/CompassLambdaApi/LIFE_INSURANCE/${action}?Life_Insurance_Number=${activeArray[editingIndex].Life_Insurance_Number}`;
      axiosInstance.get(lifeInsuranceURL("delete")).then((res) => {
        console.log("Delete res", res);
        deleteUserLifeInsuranceInfo(
          lifeInsuranceArray[editingIndex].Life_Insurance_Number
        );
        setShowDeleteModal(false);
        setEditingIndex(null);
        setEditing(false);
      });
    };

    if (!showDeleteModal) {
      return null;
    }
    const [yesClicked, setYesClicked] = useState(false);
    const [noClicked, setNoClicked] = useState(false);
    return (
      <div
        className="delete-income-modal"
        style={{
          display: "flex",
          alignItems: "center",
          width: "100%",
          maxWidth: "700px",
          justifyContent: "center",
          top: "50%",
          left: "50%",
          transform: isMobile
            ? "translate(-50%, -50%)"
            : "translate(-50%, -50%)",
        }}
        id="delete-income-modal"
      >
        <Box
          display={"flex"}
          width={"100%"}
          padding={"10px 10px 0px 0px"}
          marginLeft={"5px"}
          alignItems={"flex-end"}
          justifyContent={"flex-end"}
          onClick={() => setShowDeleteModal(false)}
          cursor={"pointer"}
        >
          <CloseIcon
            onClick={() => setShowDeleteModal(false)}
            svgheight={"20px"}
            svgwidth={"20px"}
          />
        </Box>
        <Box
          display={"flex"}
          padding={"0px 25px 25px 25px"}
          flexDirection={"column"}
          alignItems={"center"}
          gap={"10px"}
          alignSelf={"stretch"}
          width={"100%"}
        >
          <Text
            color={"#0093D0"}
            fontFamily={"Nunito"}
            fontSize={"24px"}
            fontStyle={"normal"}
            fontWeight={"600"}
            lineHeight={"normal"}
            textDecor={"underline"}
          >
            Are you sure you want to remove this Insurance policy?
          </Text>
          <div className="content"></div>
          <div className="actions">
            <button
              className={`decision-button yes-button ${
                yesClicked ? "yes-clicked" : ""
              }`}
              onClick={() => {
                setYesClicked(true);
                setNoClicked(false);
                removeRealEstateModal();
              }}
            >
              Yes
            </button>
            <button
              className={`decision-button no-button ${
                noClicked ? "yes-clicked" : ""
              }`}
              onClick={() => {
                {
                  setNoClicked(true);
                  setYesClicked(false);
                  setShowDeleteModal(false);
                }
              }}
            >
              No
            </button>
          </div>
        </Box>
      </div>
    );
  };

  const moneyToNumber = (value) => {
    let numValue = value.toString().replace(/[$,\s]/g, "");
    numValue = Number(numValue);
    return numValue;
  };

  const onSubmitLifeInsuranceAdd = (e, newArray) => {
    e.preventDefault();
    const lifeInsuranceURL = (action) =>
      `${
        process.env.REACT_APP_NODE_API_GATEWAY
      }/CompassLambdaApi/LIFE_INSURANCE/${action}?LifeInsuranceID=${user.sub.replace(
        "|",
        "%7c"
      )}&name=${newArray.name}&value=${newArray.value}&Monthly_Premiums=${
        newArray.Monthly_Premiums
      }&Life_Insurance_End_Date=${newArray.Life_Insurance_End_Date}`;
    axiosInstance
      .get(lifeInsuranceURL("add"))
      .then((res) => {
        console.log(res);
        const newData = [
          {
            name: newArray.name,
            value: newArray.value,
            Monthly_Premiums: newArray.Monthly_Premiums,
            Life_Insurance_End_Date: newArray.Life_Insurance_End_Date,
            Life_Insurance_Number: res.data.lastInsertedId,
          },
        ];
        setUserLifeInsuranceInfo(newData);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const onSubmitLifeInsuranceUpdate = (e, newArray) => {
    e.preventDefault();
    let x = newArray.Life_Insurance_Number;
    const lifeInsuranceURL = (action) =>
      `${
        process.env.REACT_APP_NODE_API_GATEWAY
      }/CompassLambdaApi/LIFE_INSURANCE/${action}?LifeInsuranceID=${user.sub.replace(
        "|",
        "%7c"
      )}&name=${newArray.name}&value=${newArray.value}&Monthly_Premiums=${
        newArray.Monthly_Premiums
      }&Life_Insurance_End_Date=${
        newArray.Life_Insurance_End_Date
      }&Life_Insurance_Number=${newArray.Life_Insurance_Number}`;
    axiosInstance
      .get(lifeInsuranceURL("update"))
      .then((res) => {
        console.log(res);
        const newData = {
          name: newArray.name,
          value: newArray.value,
          Monthly_Premiums: newArray.Monthly_Premiums,
          Life_Insurance_End_Date: newArray.Life_Insurance_End_Date,
          Life_Insurance_Number: newArray.Life_Insurance_Number,
        };
        updateUserLifeInsuranceInfo(x, newData);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const addLifeInsurance = (e, list) => {
    if (editing) {
      setLifeInsuranceArray((prevArray) => {
        let newArray = [...prevArray];
        newArray[editingIndex] = list;
        onSubmitLifeInsuranceUpdate(e, newArray[editingIndex]);
        return newArray;
      });
      setEditing(false);
      setEditingIndex(null);
    } else {
      setLifeInsuranceArray((prevArray) => {
        let newArray = [...prevArray, list];
        onSubmitLifeInsuranceAdd(e, newArray[newArray.length - 1]);
        return newArray;
      });
    }
    setLifeInsuranceType("");
    setLifeInsuranceValue(0);
    setLifeInsurancePremium(0);
    setLifeInsuranceEndDate("");
    setAdding(false);
  };

  const [errors, setErrors] = useState({});

  const checkMandatoryAddUpdateFields = () => {
    let newErrors = {};

    // Recreation property conditions
    if (!lifeInsuranceType)
      newErrors.lifeInsuranceType = "Insurance Type is required";
    if (!lifeInsuranceValue)
      newErrors.lifeInsuranceValue = "Insurance value is required";
    if (lifeInsuranceType === "Whole life insurance" && !lifeInsuranceEndDate)
      newErrors.lifeInsuranceEndDate = "Policy Term end date required";
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0; // Return true if no errors
  };

  const renderAddUpdateRecreational = () => {
    if (
      !lifeInsuranceType ||
      !lifeInsuranceValue ||
      (lifeInsuranceType !== "Whole life insurance" && !lifeInsuranceEndDate)
    ) {
      return (
        <Box
          display={"flex"}
          padding={"8px 48px"}
          marginTop={"20px"}
          justifyContent={"flex-end"}
          alignItems={"center"}
          gap={"5px"}
          borderRadius={"5px"}
          bg={"#27AE60"}
          cursor={"pointer"}
          onClick={checkMandatoryAddUpdateFields}
        >
          <Box
            display={"flex"}
            width={"76px"}
            height={"21px"}
            flexDirection={"column"}
            justifyContent={"center"}
          >
            <Text
              color={"#FFFFFF"}
              textAlign={"center"}
              fontFamily={"Nunito"}
              fontSize={"16px"}
              fontStyle={"normal"}
              fontWeight={"600"}
              lineHeight={"normal"}
            >
              {editing ? "Update" : "Add"}
            </Text>
          </Box>
        </Box>
      );
    } else {
      return (
        <Box
          display={"flex"}
          padding={"8px 48px"}
          marginTop={"20px"}
          justifyContent={"flex-end"}
          alignItems={"center"}
          gap={"5px"}
          borderRadius={"5px"}
          bg={"#27AE60"}
          cursor={"pointer"}
          onClick={(e) =>
            addLifeInsurance(e, {
              name: lifeInsuranceType,
              value: lifeInsuranceValue,
              Monthly_Premiums: lifeInsurancePremium,
              Life_Insurance_End_Date: lifeInsuranceEndDate,
              ...(editing
                ? { Life_Insurance_Number: lifeInsuranceNumber }
                : {}), // add field conditionally
            })
          }
        >
          <Box
            display={"flex"}
            width={"76px"}
            height={"21px"}
            flexDirection={"column"}
            justifyContent={"center"}
            cursor={"pointer"}
          >
            <Text
              color={"#FFFFFF"}
              textAlign={"center"}
              fontFamily={"Nunito"}
              fontSize={"16px"}
              fontStyle={"normal"}
              fontWeight={"600"}
              lineHeight={"normal"}
            >
              {editing ? "Update" : "Add"}
            </Text>
          </Box>
        </Box>
      );
    }
  };

  const columns = [
    { header: "Name", field: "name" },
    { header: "Value", field: "value" },
  ];

  const data = lifeInsuranceArray.map((item) => ({
    name: item.name,
    value: item?.value,
    link: "/life-insurance-form",
  }));

  const handleClick = () => {
    setAdding(true);
  };

  function getAge(birthday, currentDate) {
    // Convert the birthday string into a Date object
    const birthDate = new Date(birthday);

    // Calculate the age in years
    let age = currentDate.getFullYear() - birthDate.getFullYear();

    // Decrement the age if the current month and day are before the birth month and day
    if (
      currentDate.getMonth() < birthDate.getMonth() ||
      (currentDate.getMonth() === birthDate.getMonth() &&
        currentDate.getDate() <= birthDate.getDate())
    ) {
      age -= 1;
    }

    return age;
  }

  const Select = (userAge) => {
    const currentDate = new Date();
    const currentYear = currentDate.getFullYear();
    let age = getAge(userAge, currentDate); // Assuming getAge returns the starting age.
    const runAgeList = [];
    for (let i = age; i <= 100; i++) {
      runAgeList.push({ key: i, value: currentYear + (i - age) }); // Convert i to string if necessary
    }
    return runAgeList;
  };

  return (
    <Flex
      display="flex"
      flexWrap="wrap" // Enable wrapping
      justifyContent="flex-start"
      alignItems="start"
      width={"100%"}
    >
      {!isMobile && (
        <VStack alignItems={"flex-start"} width={"100%"} mt={-2}>
          <GlobalBodyPage
            Heading="Life Insurance"
            mainframeContent={
              <>
                <Box
                  display={"flex"}
                  width={"100%"}
                  flexDirection={"row"}
                  paddingTop={"10px"}
                  alignItems={"flex-end"}
                  justifyContent={"space-between"}
                  gap={"10px"}
                  flexWrap={"wrap"}
                >
                  <DeleteRealEstateModal />
                  {data.length > 0 && !editing && !adding && (
                    <TableComponent
                      columns={columns}
                      data={data}
                      editField={editField}
                      onDeleteRow={onDeleteRow}
                      rawData={lifeInsuranceArray}
                    />
                  )}
                  {(data.length < 1 || adding) && (
                    <>
                      {/* <Text
                        alignSelf={"stretch"}
                        color={"var(--Optiml---Deep-Ocean-Blue, #007EB5)"}
                        fontFamily={"Nunito"}
                        fontSize={"16px"}
                        fontStyle={"normal"}
                        fontWeight={"600"}
                        lineHeight={"normal"}
                        width={"100%"}
                      >
                        Name/Title
                      </Text> */}
                      <NormalInputButton
                        Heading={"Current value"}
                        value={lifeInsuranceValue}
                        onChange={(e) => {
                          setLifeInsuranceValue(moneyToNumber(e.target.value));
                        }}
                        placeholder={"$"}
                        useThousandSeparator={true}
                        customWidth={"100%"}
                        error={errors.lifeInsuranceValue}
                        tooltipContent={TooltipContent.lifeInsuranceValue}
                      />
                      <DropDownButton
                        Heading={"Type of policy"}
                        placeholder={"Select"}
                        dropDownItems={[
                          {
                            key: "Term life insurance",
                            value: "Term life insurance",
                          },
                          {
                            key: "Whole life insurance",
                            value: "Whole life insurance",
                          },
                          {
                            key: "Whole life - limited payments",
                            value: "Whole life - limited payments",
                          },
                        ]}
                        value={lifeInsuranceType}
                        onChange={(key) => {
                          setLifeInsuranceType(key);
                        }}
                        error={errors.lifeInsuranceType}
                        tooltipContent={TooltipContent.lifeInsuranceType}
                      />
                      {/* <NormalInputButton
                        Heading={"Face value"}
                        value={faceValue}
                        onChange={(e) => {
                          setFaceValue(moneyToNumber(e.target.value));
                        }}
                        placeholder={"$"}
                        useThousandSeparator={true}
                      /> */}
                      <NormalInputButton
                        Heading={"Monthly premium"}
                        value={lifeInsurancePremium}
                        onChange={(e) => {
                          setLifeInsurancePremium(
                            moneyToNumber(e.target.value)
                          );
                        }}
                        placeholder={"$"}
                        useThousandSeparator={true}
                        error={errors.lifeInsurancePremium}
                        tooltipContent={TooltipContent.lifeInsurancePremiums}
                      />
                      {lifeInsuranceType &&
                        lifeInsuranceType != "Whole life insurance" && (
                          <DropDownButton
                            Heading={"Term end date"}
                            placeholder={"Select"}
                            dropDownItems={Select(currentAge)}
                            value={lifeInsuranceEndDate}
                            onChange={(key) => {
                              setLifeInsuranceEndDate(key);
                            }}
                            error={errors.lifeInsuranceEndDate}
                            tooltipContent={TooltipContent.lifeInsuranceTermEnd}
                          />
                        )}
                      {/* <NormalInputButton
                        Heading={"Current cash value"}
                        value={CashValue}
                        onChange={(e) => {
                          setCashValue(moneyToNumber(e.target.value));
                        }}
                        placeholder={"$"}
                        useThousandSeparator={true}
                      /> */}
                      {/* <DropDownButton
                        Heading={"Policy holder"}
                        placeholder={"Select"}
                        dropDownItems={[
                          {
                            key: "Term life insurance",
                            value: "Term life insurance",
                          },
                          {
                            key: "Whole life insurance",
                            value: "Whole life insurance",
                          },
                          {
                            key: "Whole life - limited payments",
                            value: "Whole life - limited payments",
                          },
                        ]}
                        value={PolicyHolder}
                        onChange={(key) => {
                          setPolicyHolder(key);
                        }}
                      /> */}
                    </>
                  )}
                </Box>
                {data.length > 0 && !adding && (
                  <Box
                    display={"flex"}
                    justifyContent={"flex-start"}
                    alignItems={"center"}
                    alignSelf={"stretch"}
                  >
                    <Box
                      display={"flex"}
                      padding={"8px 48px"}
                      marginTop={"20px"}
                      justifyContent={"flex-end"}
                      alignItems={"center"}
                      gap={"5px"}
                      borderRadius={"5px"}
                      bg={"#27AE60"}
                      onClick={handleClick}
                    >
                      <Box
                        display={"flex"}
                        width={"76px"}
                        height={"21px"}
                        flexDirection={"column"}
                        justifyContent={"center"}
                      >
                        <Text
                          color={"#FFFFFF"}
                          textAlign={"center"}
                          fontFamily={"Nunito"}
                          fontSize={"16px"}
                          fontStyle={"normal"}
                          fontWeight={"600"}
                          lineHeight={"normal"}
                        >
                          Add
                        </Text>
                      </Box>
                    </Box>
                  </Box>
                )}
                {(data.length < 1 || adding) && (
                  <Box
                    display={"flex"}
                    justifyContent={"flex-end"}
                    alignItems={"center"}
                    alignSelf={"stretch"}
                  >
                    {renderAddUpdateRecreational()}
                  </Box>
                )}
              </>
            }
            videoName={"Life Insurance Page"}
            video={"Life_Insurance.mp4"}
          />
        </VStack>
      )}
      {isMobile && (
        <VStack alignItems={"flex-start"} width={"100%"} mt={-2}>
          <GlobalBodyPage
            Heading="Life Insurance"
            mainframeContent={
              <>
                <Box
                  display={"flex"}
                  width={"100%"}
                  flexDirection={"row"}
                  paddingTop={"10px"}
                  alignItems={"flex-end"}
                  justifyContent={"space-between"}
                  gap={"10px"}
                  flexWrap={"wrap"}
                >
                  <DeleteRealEstateModal />
                  {data.length > 0 && !editing && !adding && (
                    <TableComponent
                      columns={columns}
                      data={data}
                      editField={editField}
                      onDeleteRow={onDeleteRow}
                      rawData={lifeInsuranceArray}
                    />
                  )}
                  {(data.length < 1 || adding) && (
                    <>
                      {/* <Text
                        alignSelf={"stretch"}
                        color={"var(--Optiml---Deep-Ocean-Blue, #007EB5)"}
                        fontFamily={"Nunito"}
                        fontSize={"16px"}
                        fontStyle={"normal"}
                        fontWeight={"600"}
                        lineHeight={"normal"}
                        width={"100%"}
                      >
                        Name/Title
                      </Text> */}
                      <NormalInputButton
                        Heading={"Current value"}
                        value={lifeInsuranceValue}
                        onChange={(e) => {
                          setLifeInsuranceValue(moneyToNumber(e.target.value));
                        }}
                        customWidth={"100%"}
                        InputWidth={"100%"}
                        placeholder={"$"}
                        useThousandSeparator={true}
                        error={errors.lifeInsuranceValue}
                        tooltipContent={TooltipContent.lifeInsuranceValue}
                      />
                      <DropDownButton
                        Heading={"Type of policy"}
                        placeholder={"Select"}
                        dropDownItems={[
                          {
                            key: "Term life insurance",
                            value: "Term life insurance",
                          },
                          {
                            key: "Whole life insurance",
                            value: "Whole life insurance",
                          },
                          {
                            key: "Whole life - limited payments",
                            value: "Whole life - limited payments",
                          },
                        ]}
                        value={lifeInsuranceType}
                        onChange={(key) => {
                          setLifeInsuranceType(key);
                        }}
                        customWidth={"100%"}
                        MenuCustomWidth={"100%"}
                        error={errors.lifeInsuranceType}
                        tooltipContent={TooltipContent.lifeInsuranceType}
                      />
                      {/* <NormalInputButton
                        Heading={"Face value"}
                        value={faceValue}
                        onChange={(e) => {
                          setFaceValue(moneyToNumber(e.target.value));
                        }}
                        customWidth={"100%"}
                        InputWidth={"100%"}
                        placeholder={"$"}
                        useThousandSeparator={true}
                      /> */}
                      <NormalInputButton
                        Heading={"Monthly premium"}
                        value={lifeInsurancePremium}
                        onChange={(e) => {
                          setLifeInsurancePremium(
                            moneyToNumber(e.target.value)
                          );
                        }}
                        customWidth={"100%"}
                        InputWidth={"100%"}
                        placeholder={"$"}
                        useThousandSeparator={true}
                        error={errors.lifeInsurancePremium}
                        tooltipContent={TooltipContent.lifeInsurancePremiums}
                      />
                      {lifeInsuranceType &&
                        lifeInsuranceType != "Whole life insurance" && (
                          <DropDownButton
                            Heading={"Term end date"}
                            placeholder={"Select"}
                            dropDownItems={Select(currentAge)}
                            value={lifeInsuranceEndDate}
                            onChange={(key) => {
                              setLifeInsuranceEndDate(key);
                            }}
                            customWidth={"100%"}
                            MenuCustomWidth={"100%"}
                            error={errors.lifeInsuranceEndDate}
                            tooltipContent={TooltipContent.lifeInsuranceTermEnd}
                          />
                        )}
                      {/* <NormalInputButton
                        Heading={"Current cash value"}
                        value={CashValue}
                        onChange={(e) => {
                          setCashValue(moneyToNumber(e.target.value));
                        }}
                        customWidth={"100%"}
                        placeholder={"$"}
                        useThousandSeparator={true}
                        InputWidth={"100%"}
                      /> */}
                      {/* <DropDownButton
                        Heading={"Policy holder"}
                        placeholder={"Select"}
                        dropDownItems={[
                          { key: "Term", value: "Term" },
                          { key: "Whole", value: "Whole" },
                          { key: "Universal", value: "Universal" },
                          { key: "Variable", value: "Variable" },
                          { key: "Other", value: "Other" },
                        ]}
                        value={PolicyHolder}
                        onChange={(key) => {
                          setPolicyHolder(key);
                        }}
                        customWidth={"100%"}
                        MenuCustomWidth={"100%"}
                      /> */}
                    </>
                  )}
                </Box>
                {data.length > 0 && !adding && (
                  <Box
                    display={"flex"}
                    justifyContent={"flex-start"}
                    alignItems={"center"}
                    alignSelf={"stretch"}
                  >
                    <Box
                      display={"flex"}
                      padding={"8px 48px"}
                      marginTop={"20px"}
                      justifyContent={"flex-end"}
                      alignItems={"center"}
                      gap={"5px"}
                      borderRadius={"5px"}
                      bg={"#27AE60"}
                      onClick={handleClick}
                    >
                      <Box
                        display={"flex"}
                        width={"76px"}
                        height={"21px"}
                        flexDirection={"column"}
                        justifyContent={"center"}
                      >
                        <Text
                          color={"#FFFFFF"}
                          textAlign={"center"}
                          fontFamily={"Nunito"}
                          fontSize={"16px"}
                          fontStyle={"normal"}
                          fontWeight={"600"}
                          lineHeight={"normal"}
                        >
                          Add
                        </Text>
                      </Box>
                    </Box>
                  </Box>
                )}
                {(data.length < 1 || adding) && (
                  <Box
                    display={"flex"}
                    justifyContent={"flex-end"}
                    alignItems={"center"}
                    alignSelf={"stretch"}
                  >
                    {renderAddUpdateRecreational()}
                  </Box>
                )}
              </>
            }
            videoName={"Life Insurance Page"}
            video={"Life_Insurance.mp4"}
          />
        </VStack>
      )}
    </Flex>
  );
});

export default LifeInsurancePage;
