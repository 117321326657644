import { useContext, useState } from "react";
import { useStore } from "../../store";
import DashboardSmallCard from "../EndOfLifePageSpouse/DashboardSmallCard";
import { Box, useMediaQuery } from "@chakra-ui/react";
import LineGraph from "../EndOfLifePageSpouse/LineGraph";
import InsightGraph from "../EndOfLifePageSpouse/InsightsGraph";
import { StripeContext } from "../StripeContext";

function InsightsPage() {
  const userInfo = useStore((store) => store.userInfo);
  const userOptiData = useStore((store) => store.userOptiData);
  const userInsightsData = useStore((store) => store.userInsightsData);
  const dashboardLayout = useStore((store) => store.dashboardLayout);
  console.log(userInsightsData)

  const passedOptiList = userOptiData?.primaryUserData;
  const passedSpouseOptiList = userOptiData?.spouseData;
  const [optiList, setOptiList] = useState(passedOptiList);
  const [assetsList, setAssetsList] = useState(passedOptiList);
  const [spouseOptiList, setSpouseOptiList] = useState(passedSpouseOptiList);
  const [spousePrefix, setSpousePrefix] = useState("sps1");
  const [spousePage, setSpousePage] = useState("joint-portfolio");
  const [graphClicked, setGraphClicked] = useState("Overview");

  const [userRotData, setUserRotData] = useState(userOptiData?.spouse1_ROT);
  const [spouseRotData, setSpouseRotData] = useState(
    userOptiData?.spouse2_ROT
  );

  console.log("HERE ", userRotData, spouseRotData)

  const calculateDaysLeft = (trialEndTimestamp) => {
    const currentDate = new Date();
    const trialEndDate = new Date(trialEndTimestamp * 1000); // Convert to milliseconds
    const differenceInTime = trialEndDate.getTime() - currentDate.getTime();
    const differenceInDays = Math.ceil(differenceInTime / (1000 * 3600 * 24));
    return differenceInDays;
  };
  const { stripePlan, setStripePlan } = useContext(StripeContext);
  const currentYear = new Date();

  function getAge(birthday, currentDate) {
    // Convert the birthday string into a Date object
    const birthDate = new Date(birthday);

    // Calculate the age in years
    let age = currentDate?.getFullYear() - birthDate?.getFullYear();

    // Decrement the age if the current month and day are before the birth month and day
    if (
      currentDate?.getMonth() < birthDate?.getMonth() ||
      (currentDate?.getMonth() === birthDate?.getMonth() &&
        currentDate?.getDate() < birthDate?.getDate())
    ) {
      age -= 1;
    }

    return age;
  }

  const withdrawCppAge = (cpp, spouseBday) => {
    let age = 70; // Default age set to 70

    for (let i = 0; i < cpp?.length; i++) {
      if (cpp?.[i] > 0) {
        age = i;
        break;
      }
    }

    return Math.min(age + spouseBday, 70);
  };

  const currencyFormat = (money) => {
    var intTotal = Math.round(Math.round(money * 100) / 100);
    if (intTotal > -2 && intTotal < 0.25) {
      intTotal = 0;
    }
    // Math.round(intTotal);
    var currencyCad = new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD",
      minimumFractionDigits: 0,
    }).format(intTotal);
    return currencyCad;
  };

  const averageTaxRate = (total, tax) => {
    // Check if total is zero, null, or undefined
    if (total === 0 || total === null || total === undefined) {
      return 0; // or return null, depending on how you want to handle this case
    }

    const percent = (tax / total) * 100;
    const x = Math.round(percent);
    return x;
  };

  const sumArray = (arr) => {
    var sum = arr?.reduce((a, b) => a + b, 0);
    return sum;
  };
  function safeAdd(...values) {
    return values.reduce((sum, val) => sum + (Number(val) || 0), 0);
  }
  const currentDate = new Date();
  const setPortfolioGraphData = (list) => {
    // Check if list is null (or undefined) and handle it
    if (!list) {
      return []; // Return an empty array or some default value
    }

    const dataPoint = [];
    const finalAge = optiList?.[`${spousePrefix}_registeredFunds`]?.length;
    const updatedDate = new Date();
    for (let i = 0; i < finalAge; i++) {
      const currentDate = new Date();
      updatedDate.setFullYear(currentDate.getFullYear() + i);
      const testAge = getAge(
        optiList?.[`${spousePrefix}_birthday`],
        updatedDate
      );

      // Safeguard against undefined list items with (list[i] || 0)
      dataPoint.push({
        x: updatedDate.getFullYear(),
        y: Math.floor(list[i] || 0),
        label: `${testAge.toString()} ${updatedDate.getFullYear().toString()}`,
      });

      if (list[i] > -2 && list[i] < 0.25) {
        list[i] = 0;
      }
    }
    return dataPoint;
  };

  const [isLargerThan1200] = useMediaQuery(
    "(min-width: 500px) and (max-width: 1250px)"
  );
  const excludedCards = ["averageTaxRate", "optimalCPPAge", "optimalOASAge", "optimlValue", "netWorth"];

  return (
    <>
      <Box
        flexDirection={"row"}
        w={"100%"}
        justifyContent={"space-between"}
        flexWrap={"wrap"}
      >
        {/* {!isDragDisabled && (
            <Box className="move-small-card-up-container">
              <img
                id="incrementYear"
                className="next-year-button"
                src={nextYearIcon}
                onClick={() => onDragOverallLayoutEnd(card, "up")}
              />
            </Box>
          )} */}
        <Box>
          {/* <div className="small-box-edit">Edit</div> */}

          <div className="dashboard-small-box-container">
            {dashboardLayout.smallCards
              ?.filter((card) => !excludedCards.includes(card))
              .map((card, index) => (
                <Box
                  display="flex"
                  justifyContent={
                    card === "averageTaxRate" ? "center" : "flex-start"
                  }
                  alignSelf="stretch"
                  flexWrap="wrap"
                  bg={"#FFF"}
                  padding={"15px 20px"}
                  alignItems={"center"}
                  borderRadius={"5px"}
                  border={"1px solid #00A2D4"}
                  w={isLargerThan1200 && "48%"}
                >
                  <DashboardSmallCard
                    card={card}
                    optiList={optiList}
                    assetsList={assetsList}
                    spouseOptiList={spouseOptiList}
                    currentYear={currentYear}
                    getAge={getAge}
                    withdrawCppAge={withdrawCppAge}
                    currencyFormat={currencyFormat}
                    averageTaxRate={averageTaxRate}
                    sumArray={sumArray}
                    spousePrefix={spousePrefix}
                    spousePage={spousePage}
                    showpop={true}
                    isInsights={true}
                  />
                </Box>
              ))}
          </div>
        </Box>
      </Box>
      {/* {spousePage === "joint-portfolio" && */}
          <div className="revenue-graph-container">
            <InsightGraph
              title="Overview"
              investmentPage={true}
              insights={true}
              currentAge={getAge(
                optiList?.[`${spousePrefix}_birthday`],
                currentDate
              )}
              spouseAge={getAge(spouseOptiList?.sps2_birthday, currentDate)}
              tfsaData={setPortfolioGraphData(
                optiList?.[`${spousePrefix}_tfsaFunds`]?.map((e, i) =>
                  safeAdd(e, spouseOptiList?.sps2_tfsaFunds?.[i])
                )
              )}
              rottfsaData={setPortfolioGraphData(
                userRotData?.[`${spousePrefix}_tfsaFunds`]?.map((e, i) =>
                  safeAdd(e, spouseRotData?.sps2_tfsaFunds?.[i])
                )
              )}
              tfsaContributionData={setPortfolioGraphData(
                optiList?.[`${spousePrefix}_tfsaContributions`]?.map((e, i) =>
                  safeAdd(e, spouseOptiList?.sps2_tfsaContributions?.[i])
                )
              )}
              rottfsaContributionData={setPortfolioGraphData(
                userRotData?.[`${spousePrefix}_tfsaContributions`]?.map(
                  (e, i) =>
                    safeAdd(e, spouseRotData?.sps2_tfsaContributions?.[i])
                )
              )}
              tfsaWithdrawalData={setPortfolioGraphData(
                optiList?.[`${spousePrefix}_tfsaWithdrawals`]?.map((e, i) =>
                  safeAdd(e, spouseOptiList?.sps2_tfsaWithdrawals?.[i])
                )
              )}
              rottfsaWithdrawalData={setPortfolioGraphData(
                userRotData?.[`${spousePrefix}_tfsaWithdrawals`]?.map((e, i) =>
                  safeAdd(e, spouseRotData?.sps2_tfsaWithdrawals?.[i])
                )
              )}
              fhsaData={setPortfolioGraphData(
                optiList?.[`${spousePrefix}_fhsaFunds`]?.map((e, i) =>
                  safeAdd(e, spouseOptiList?.sps2_fhsaFunds?.[i])
                )
              )}
              rotfhsaData={setPortfolioGraphData(
                userRotData?.[`${spousePrefix}_fhsaFunds`]?.map((e, i) =>
                  safeAdd(e, spouseRotData?.sps2_fhsaFunds?.[i])
                )
              )}
              fhsaContributionData={setPortfolioGraphData(
                optiList?.[`${spousePrefix}_fhsaContributions`]?.map((e, i) =>
                  safeAdd(e, spouseOptiList?.sps2_fhsaContributions?.[i])
                )
              )}
              rotfhsaContributionData={setPortfolioGraphData(
                userRotData?.[`${spousePrefix}_fhsaContributions`]?.map(
                  (e, i) =>
                    safeAdd(e, spouseRotData?.sps2_fhsaContributions?.[i])
                )
              )}
              fhsaWithdrawalData={setPortfolioGraphData(
                optiList?.[`${spousePrefix}_fhsaWithdrawals`]?.map((e, i) =>
                  safeAdd(e, spouseOptiList?.sps2_fhsaWithdrawals?.[i])
                )
              )}
              rotfhsaWithdrawalData={setPortfolioGraphData(
                userRotData?.[`${spousePrefix}_fhsaWithdrawals`]?.map((e, i) =>
                  safeAdd(e, spouseRotData?.sps2_fhsaWithdrawals?.[i])
                )
              )}
              rrspData={setPortfolioGraphData(
                optiList?.[`${spousePrefix}_registeredFunds`]?.map((e, i) =>
                  safeAdd(e, spouseOptiList?.sps2_registeredFunds?.[i])
                )
              )}
              rotrrspData={setPortfolioGraphData(
                userRotData?.[`${spousePrefix}_registeredFunds`]?.map((e, i) =>
                  safeAdd(e, spouseRotData?.sps2_registeredFunds?.[i])
                )
              )}
              rrspContributionData={setPortfolioGraphData(
                optiList?.[`${spousePrefix}_registeredContributions`]?.map(
                  (e, i) =>
                    safeAdd(
                      e,
                      spouseOptiList?.sps2_registeredContributions?.[i]
                    )
                )
              )}
              rotrrspContributionData={setPortfolioGraphData(
                userRotData?.[`${spousePrefix}_registeredContributions`]?.map(
                  (e, i) =>
                    safeAdd(e, spouseRotData?.sps2_registeredContributions?.[i])
                )
              )}
              rrspWithdrawalData={setPortfolioGraphData(
                optiList?.[`${spousePrefix}_registeredWithdrawals`]?.map(
                  (e, i) =>
                    safeAdd(e, spouseOptiList?.sps2_registeredWithdrawals?.[i])
                )
              )}
              rotrrspWithdrawalData={setPortfolioGraphData(
                userRotData?.[`${spousePrefix}_registeredWithdrawals`]?.map(
                  (e, i) =>
                    safeAdd(e, spouseRotData?.sps2_registeredWithdrawals?.[i])
                )
              )}
              nonRegData={setPortfolioGraphData(
                optiList?.[`${spousePrefix}_nonregisteredFunds`]?.map((e, i) =>
                  safeAdd(e, spouseOptiList?.sps2_nonregisteredFunds?.[i])
                )
              )}
              rotnonRegData={setPortfolioGraphData(
                userRotData?.[`${spousePrefix}_nonregisteredFunds`]?.map(
                  (e, i) =>
                    safeAdd(e, spouseRotData?.sps2_nonregisteredFunds?.[i])
                )
              )}
              nonRegContributionData={setPortfolioGraphData(
                optiList?.[`${spousePrefix}_nonRegisteredContributions`]?.map(
                  (e, i) =>
                    safeAdd(
                      e,
                      spouseOptiList?.sps2_nonregisteredContributions?.[i]
                    )
                )
              )}
              rotnonRegContributionData={setPortfolioGraphData(
                userRotData?.[
                  `${spousePrefix}_nonRegisteredContributions`
                ]?.map((e, i) =>
                  safeAdd(
                    e,
                    spouseRotData?.sps2_nonregisteredContributions?.[i]
                  )
                )
              )}
              nonRegWithdrawalData={setPortfolioGraphData(
                optiList?.[`${spousePrefix}_nonregisteredWithdrawals`]?.map(
                  (e, i) =>
                    safeAdd(
                      e,
                      spouseOptiList?.sps2_nonregisteredWithdrawals?.[i]
                    )
                )
              )}
              rotnonRegWithdrawalData={setPortfolioGraphData(
                userRotData?.[`${spousePrefix}_nonregisteredWithdrawals`]?.map(
                  (e, i) =>
                    safeAdd(
                      e,
                      spouseRotData?.sps2_nonregisteredWithdrawals?.[i]
                    )
                )
              )}
              // LIRA
              liraData={setPortfolioGraphData(
                optiList?.[`${spousePrefix}_liraFunds`]?.map((e, i) =>
                  safeAdd(e, spouseOptiList?.sps2_liraFunds?.[i])
                )
              )}
              rotliraData={setPortfolioGraphData(
                userRotData?.[`${spousePrefix}_liraFunds`]?.map((e, i) =>
                  safeAdd(e, spouseRotData?.sps2_liraFunds?.[i])
                )
              )}
              liraWithdrawalData={setPortfolioGraphData(
                optiList?.[`${spousePrefix}_liraWithdrawals`]?.map((e, i) =>
                  safeAdd(e, spouseOptiList?.sps2_liraWithdrawals?.[i])
                )
              )}
              rotliraWithdrawalData={setPortfolioGraphData(
                userRotData?.[`${spousePrefix}_liraWithdrawals`]?.map((e, i) =>
                  safeAdd(e, spouseRotData?.sps2_liraWithdrawals?.[i])
                )
              )}
              // LIRA
              totalPortfolioData={setPortfolioGraphData(
                optiList?.[`${spousePrefix}_nonregisteredFunds`]?.map((e, i) =>
                  safeAdd(
                    e,
                    optiList?.[`${spousePrefix}_registeredFunds`]?.[i],
                    spouseOptiList?.sps2_registeredFunds?.[i],
                    spouseOptiList?.sps2_nonregisteredFunds?.[i],
                    optiList?.[`${spousePrefix}_tfsaFunds`]?.[i],
                    spouseOptiList?.sps2_tfsaFunds?.[i],
                    optiList?.[`${spousePrefix}_fhsaFunds`]?.[i],
                    spouseOptiList?.sps2_fhsaFunds?.[i],
                    optiList?.[`${spousePrefix}_liraFunds`]?.[i],
                    spouseOptiList?.sps2_liraFunds?.[i]
                  )
                )
              )}
              rottotalPortfolioData={setPortfolioGraphData(
                userRotData?.[`${spousePrefix}_nonregisteredFunds`]?.map(
                  (e, i) =>
                    safeAdd(
                      e,
                      userRotData?.[`${spousePrefix}_registeredFunds`]?.[i],
                      spouseRotData?.sps2_registeredFunds?.[i],
                      spouseRotData?.sps2_nonregisteredFunds?.[i],
                      userRotData?.[`${spousePrefix}_tfsaFunds`]?.[i],
                      spouseRotData?.sps2_tfsaFunds?.[i],
                      userRotData?.[`${spousePrefix}_fhsaFunds`]?.[i],
                      spouseRotData?.sps2_fhsaFunds?.[i],
                      userRotData?.[`${spousePrefix}_liraFunds`]?.[i],
                      spouseRotData?.sps2_liraFunds?.[i]
                    )
                )
              )}
              totalPortfolioDataContribution={setPortfolioGraphData(
                optiList?.[`${spousePrefix}_nonRegisteredContributions`]?.map(
                  (e, i) =>
                    safeAdd(
                      e,
                      optiList?.[`${spousePrefix}_registeredContributions`]?.[
                        i
                      ],
                      spouseOptiList?.sps2_registeredContributions?.[i],
                      spouseOptiList?.sps2_nonRegisteredContributions?.[i],
                      optiList?.[`${spousePrefix}_tfsaContributions`]?.[i],
                      spouseOptiList?.sps2_tfsaContributions?.[i],
                      optiList?.[`${spousePrefix}_fhsaContributions`]?.[i],
                      spouseOptiList?.sps2_fhsaContributions?.[i],
                      optiList?.[`${spousePrefix}_liraContributions`]?.[i],
                      spouseOptiList?.sps2_liraContributions?.[i]
                    )
                )
              )}
              rottotalPortfolioDataContribution={setPortfolioGraphData(
                userRotData?.[
                  `${spousePrefix}_nonRegisteredContributions`
                ]?.map((e, i) =>
                  safeAdd(
                    e,
                    userRotData?.[`${spousePrefix}_registeredContributions`]?.[
                      i
                    ],
                    spouseRotData?.sps2_registeredContributions?.[i],
                    spouseRotData?.sps2_nonregisteredContributions?.[i],
                    userRotData?.[`${spousePrefix}_tfsaContributions`]?.[i],
                    spouseRotData?.sps2_tfsaContributions?.[i],
                    userRotData?.[`${spousePrefix}_fhsaContributions`]?.[i],
                    spouseRotData?.sps2_fhsaContributions?.[i],
                    userRotData?.[`${spousePrefix}_liraContributions`]?.[i],
                    spouseRotData?.sps2_liraContributions?.[i]
                  )
                )
              )}
              totalPortfolioDataWithdrawal={setPortfolioGraphData(
                optiList?.[`${spousePrefix}_nonregisteredWithdrawals`]?.map(
                  (e, i) =>
                    safeAdd(
                      e,
                      optiList?.[`${spousePrefix}_registeredWithdrawals`]?.[i],
                      spouseOptiList?.sps2_registeredWithdrawals?.[i],
                      spouseOptiList?.sps2_nonregisteredWithdrawals?.[i],
                      optiList?.[`${spousePrefix}_tfsaWithdrawals`]?.[i],
                      spouseOptiList?.sps2_tfsaWithdrawals?.[i],
                      optiList?.[`${spousePrefix}_fhsaWithdrawals`]?.[i],
                      spouseOptiList?.sps2_fhsaWithdrawals?.[i],
                      optiList?.[`${spousePrefix}_liraWithdrawals`]?.[i],
                      spouseOptiList?.sps2_liraWithdrawals?.[i]
                    )
                )
              )}
              rottotalPortfolioDataWithdrawal={setPortfolioGraphData(
                userRotData?.[`${spousePrefix}_nonregisteredWithdrawals`]?.map(
                  (e, i) =>
                    safeAdd(
                      e,
                      userRotData?.[`${spousePrefix}_registeredWithdrawals`]?.[
                        i
                      ],
                      spouseRotData?.sps2_registeredWithdrawals?.[i],
                      spouseRotData?.sps2_nonregisteredWithdrawals?.[i],
                      userRotData?.[`${spousePrefix}_tfsaWithdrawals`]?.[i],
                      spouseRotData?.sps2_tfsaWithdrawals?.[i],
                      userRotData?.[`${spousePrefix}_fhsaWithdrawals`]?.[i],
                      spouseRotData?.sps2_fhsaWithdrawals?.[i],
                      userRotData?.[`${spousePrefix}_liraWithdrawals`]?.[i],
                      spouseRotData?.sps2_liraWithdrawals?.[i]
                    )
                )
              )}
              graphClicked={graphClicked}
            />
          </div>
      {/* } */}
    </>
  );
}

export default InsightsPage;
