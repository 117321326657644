import {
  Box,
  Flex,
  HStack,
  Icon,
  Popover,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Portal,
  Text,
  VStack,
  useMediaQuery,
} from "@chakra-ui/react";
import React, { useContext, useEffect, useState } from "react";
import Modal from "../../components/Modal";
import {
  CancelSubscriptionIcon,
  ChangePaymentIcon,
  CheckedIcon,
  GearIcon,
  GreyUncheckedIcon,
  UncheckedIcon,
  ViewDownloadInvoiceIcon,
} from "../CustomIcons";
import Link from "../Link";
import { StripeContext } from "../StripeContext";
import StripeUpgradeModal from "../StripeUpgradeModal";
import "./ChangePlanPage.css";

function ChangePlanPage() {
  const [showModal, setShowModal] = useState(false);
  const { stripePlan, setStripePlan } = useContext(StripeContext);
  const [showStripeModal, setShowStripeModal] = useState(false);
  const [billingEnd, setBillingEnd] = useState();

  useEffect(() => {
    if (stripePlan?.current_period_end) {
      const date = new Date(stripePlan.current_period_end * 1000);
      const formattedDate = `${
        date.getMonth() + 1
      }/${date.getDate()}/${date.getFullYear()}`;
      setBillingEnd(formattedDate);
    }
  }, [stripePlan]);

  const popUpGear = [
    {
      label: "Change payment details",
      icon: <ChangePaymentIcon svgwidth={"20px"} svgheight={"15px"} />,
    },
    {
      label: "View/download invoice/s",
      icon: <ViewDownloadInvoiceIcon svgheight={"21px"} svgwidth={"17px"} />,
    },
    {
      label: "Cancel subscription",
      icon: <CancelSubscriptionIcon svgheight={"14px"} svgwidth={"20px"} />,
    },
  ];

  const [isLargerThan768] = useMediaQuery("(min-width: 768px)");
  const isMobile = !isLargerThan768;

  const fontSize = "14px";

  const SubscriptionCard = ({ currentPlan, planCol, price }) => {
    return (
      <div
        className={
          currentPlan === planCol
            ? "subscription-card-plan-current"
            : "subscription-card-plan"
        }
      >
        <div className="card-body-plan" style={{ gap: "10px" }}>
          <div className="card-header-plan d-flex">
            {planCol === ("OPTIML_ESSENTIALS" || "OPTIML_ESSENTIALS_M") && "ESSENTIALS"}
            {planCol === ("PREMIUM" || "PREMIUM_M") && "PREMIUM Package"}
            {planCol === ("PRO" || "PRO_M") && "PRO Package"}
            {planCol !== "OPTIML_ESSENTIALS" &&
            planCol !== "OPTIML_ESSENTIALS_M" &&
              planCol !== "PREMIUM" &&
              planCol !== "PREMIUM_M" &&
              planCol !== "PRO" &&
              planCol !== "PRO_M" &&
              planCol}{" "}
            {/* - {price} */}
            <button
              onClick={() => {
                UpgradeStripeModal();
              }}
              className={
                currentPlan === planCol
                  ? "manage-subscription-btn-plan-current"
                  : "manage-subscription-btn-plan"
              }
            >
              {currentPlan === planCol ? "Current Plan" : "Select"}
            </button>
          </div>
          {(planCol === "OPTIML_ESSENTIALS" || planCol === "OPTIML_ESSENTIALS_M") && (
            <Box
              display={"flex"}
              alignItems={"flex-start"}
              gap={"40px"}
              alignSelf={"stretch"}
              padding={"0px 0px"}
              paddingBottom={"20px"}
              {...(isMobile ? { flexDirection: "column", gap: "5px" } : {})}
            >
              <Box
                display={"flex"}
                flexDirection={"column"}
                alignItems={"flex-start"}
                gap={"5px"}
              >
                <Box display={"flex"} alignItems={"center"} gap={"10px"}>
                  <Icon
                    as={CheckedIcon}
                    svgheight={"22.5px"}
                    svgwidth={"22.5px"}
                  />
                  <Text
                    color={"#00466D"}
                    fontFamily={"Nunito"}
                    fontSize={fontSize}
                    fontStyle={"normal"}
                    fontWeight={"400"}
                    lineHeight={"normal"}
                  >
                    Deposit strategy
                  </Text>
                </Box>
                <Box display={"flex"} alignItems={"center"} gap={"10px"}>
                  <Icon
                    as={CheckedIcon}
                    svgheight={"22.5px"}
                    svgwidth={"22.5px"}
                  />
                  <Text
                    color={"#00466D"}
                    fontFamily={"Nunito"}
                    fontSize={fontSize}
                    fontStyle={"normal"}
                    fontWeight={"400"}
                    lineHeight={"normal"}
                  >
                    Withdrawal strategy
                  </Text>
                </Box>
                <Box display={"flex"} alignItems={"center"} gap={"10px"}>
                  <Icon
                    as={CheckedIcon}
                    svgheight={"22.5px"}
                    svgwidth={"22.5px"}
                  />
                  <Text
                    color={"#00466D"}
                    fontFamily={"Nunito"}
                    fontSize={fontSize}
                    fontStyle={"normal"}
                    fontWeight={"400"}
                    lineHeight={"normal"}
                  >
                    Canadian Pension Plan start age
                  </Text>
                </Box>
                <Box display={"flex"} alignItems={"center"} gap={"10px"}>
                  <Icon
                    as={CheckedIcon}
                    svgheight={"22.5px"}
                    svgwidth={"22.5px"}
                  />
                  <Text
                    color={"#00466D"}
                    fontFamily={"Nunito"}
                    fontSize={fontSize}
                    fontStyle={"normal"}
                    fontWeight={"400"}
                    lineHeight={"normal"}
                  >
                    Old Age Security start age
                  </Text>
                </Box>
                <Box display={"flex"} alignItems={"center"} gap={"10px"}>
                  <Icon
                    as={CheckedIcon}
                    svgheight={"22.5px"}
                    svgwidth={"22.5px"}
                  />
                  <Text
                    color={"#00466D"}
                    fontFamily={"Nunito"}
                    fontSize={fontSize}
                    fontStyle={"normal"}
                    fontWeight={"400"}
                    lineHeight={"normal"}
                  >
                    Unlimited what-if scenarios
                  </Text>
                </Box>
                <Box display={"flex"} alignItems={"center"} gap={"10px"}>
                  <Icon
                    as={CheckedIcon}
                    svgheight={"22.5px"}
                    svgwidth={"22.5px"}
                  />
                  <Text
                    color={"#00466D"}
                    fontFamily={"Nunito"}
                    fontSize={fontSize}
                    fontStyle={"normal"}
                    fontWeight={"400"}
                    lineHeight={"normal"}
                  >
                    Virtual assistant
                  </Text>
                </Box>
              </Box>
              <Box
                display={"flex"}
                flexDirection={"column"}
                alignItems={"flex-start"}
                gap={"5px"}
              >
                <Box display={"flex"} alignItems={"center"} gap={"10px"}>
                  <Icon
                    as={GreyUncheckedIcon}
                    svgheight={"22.5px"}
                    svgwidth={"22.5px"}
                  />
                  <Text
                    color={"#00466D"}
                    fontFamily={"Nunito"}
                    fontSize={fontSize}
                    fontStyle={"normal"}
                    fontWeight={"400"}
                    lineHeight={"normal"}
                  >
                    Advanced Insights
                  </Text>
                </Box>
                <Box display={"flex"} alignItems={"center"} gap={"10px"}>
                  <Icon
                    as={GreyUncheckedIcon}
                    svgheight={"22.5px"}
                    svgwidth={"22.5px"}
                  />
                  <Text
                    color={"#00466D"}
                    fontFamily={"Nunito"}
                    fontSize={fontSize}
                    fontStyle={"normal"}
                    fontWeight={"400"}
                    lineHeight={"normal"}
                  >
                    Save your analysis
                  </Text>
                </Box>
                <Box display={"flex"} alignItems={"center"} gap={"10px"}>
                  <Icon
                    as={GreyUncheckedIcon}
                    svgheight={"22.5px"}
                    svgwidth={"22.5px"}
                  />
                  <Text
                    color={"#00466D"}
                    fontFamily={"Nunito"}
                    fontSize={fontSize}
                    fontStyle={"normal"}
                    fontWeight={"400"}
                    lineHeight={"normal"}
                  >
                    Premium Support
                  </Text>
                </Box>
                <Box display={"flex"} alignItems={"center"} gap={"10px"}>
                  <Icon
                    as={GreyUncheckedIcon}
                    svgheight={"22.5px"}
                    svgwidth={"22.5px"}
                  />
                  <Text
                    color={"#00466D"}
                    fontFamily={"Nunito"}
                    fontSize={fontSize}
                    fontStyle={"normal"}
                    fontWeight={"400"}
                    lineHeight={"normal"}
                  >
                    Linked bank accounts
                  </Text>
                </Box>
                <Box display={"flex"} alignItems={"center"} gap={"10px"}>
                  <Icon
                    as={GreyUncheckedIcon}
                    svgheight={"22.5px"}
                    svgwidth={"22.5px"}
                  />
                  <Text
                    color={"#00466D"}
                    fontFamily={"Nunito"}
                    fontSize={fontSize}
                    fontStyle={"normal"}
                    fontWeight={"400"}
                    lineHeight={"normal"}
                  >
                    Income properties
                  </Text>
                </Box>
                <Box display={"flex"} alignItems={"center"} gap={"10px"}>
                  <Icon
                    as={GreyUncheckedIcon}
                    svgheight={"22.5px"}
                    svgwidth={"22.5px"}
                  />
                  <Text
                    color={"#00466D"}
                    fontFamily={"Nunito"}
                    fontSize={fontSize}
                    fontStyle={"normal"}
                    fontWeight={"400"}
                    lineHeight={"normal"}
                  >
                    Business (CCPC)
                  </Text>
                </Box>
              </Box>
            </Box>
          )}
          {(planCol === "PRO" || planCol === "PRO_M") && (
            <>
              {(currentPlan === "OPTIML_ESSENTIALS" || currentPlan === "OPTIML_ESSENTIALS_M") && (
                <Text
                  color={"#00466D"}
                  fontFamily={"Nunito"}
                  fontSize={"9px"}
                  fontStyle={"normal"}
                  fontWeight={"700"}
                  lineHeight={"normal"}
                  width={"80%"}
                >
                  Upgrade now at a reduced cost based on the remaining duration
                  of your annual subscription. You only pay the difference for
                  the upgrade until your next billing cycle.
                </Text>
              )}
              <Box
                display={"flex"}
                alignItems={"flex-start"}
                gap={"40px"}
                alignSelf={"stretch"}
                paddingBottom={"20px"}
                {...(isMobile ? { flexDirection: "column", gap: "5px" } : {})}
              >
                <Box
                  display={"flex"}
                  flexDirection={"column"}
                  alignItems={"flex-start"}
                  gap={"5px"}
                >
                  <Box display={"flex"} alignItems={"center"} gap={"10px"}>
                    <Icon
                      as={CheckedIcon}
                      svgheight={"22.5px"}
                      svgwidth={"22.5px"}
                    />
                    <Text
                      color={"#00466D"}
                      fontFamily={"Nunito"}
                      fontSize={fontSize}
                      fontStyle={"normal"}
                      fontWeight={"400"}
                      lineHeight={"normal"}
                    >
                      Deposit strategy
                    </Text>
                  </Box>
                  <Box display={"flex"} alignItems={"center"} gap={"10px"}>
                    <Icon
                      as={CheckedIcon}
                      svgheight={"22.5px"}
                      svgwidth={"22.5px"}
                    />
                    <Text
                      color={"#00466D"}
                      fontFamily={"Nunito"}
                      fontSize={fontSize}
                      fontStyle={"normal"}
                      fontWeight={"400"}
                      lineHeight={"normal"}
                    >
                      Withdrawal strategy
                    </Text>
                  </Box>
                  <Box display={"flex"} alignItems={"center"} gap={"10px"}>
                    <Icon
                      as={CheckedIcon}
                      svgheight={"22.5px"}
                      svgwidth={"22.5px"}
                    />
                    <Text
                      color={"#00466D"}
                      fontFamily={"Nunito"}
                      fontSize={fontSize}
                      fontStyle={"normal"}
                      fontWeight={"400"}
                      lineHeight={"normal"}
                    >
                      Canadian Pension Plan start age
                    </Text>
                  </Box>
                  <Box display={"flex"} alignItems={"center"} gap={"10px"}>
                    <Icon
                      as={CheckedIcon}
                      svgheight={"22.5px"}
                      svgwidth={"22.5px"}
                    />
                    <Text
                      color={"#00466D"}
                      fontFamily={"Nunito"}
                      fontSize={fontSize}
                      fontStyle={"normal"}
                      fontWeight={"400"}
                      lineHeight={"normal"}
                    >
                      Old Age Security start age
                    </Text>
                  </Box>
                  <Box display={"flex"} alignItems={"center"} gap={"10px"}>
                    <Icon
                      as={CheckedIcon}
                      svgheight={"22.5px"}
                      svgwidth={"22.5px"}
                    />
                    <Text
                      color={"#00466D"}
                      fontFamily={"Nunito"}
                      fontSize={fontSize}
                      fontStyle={"normal"}
                      fontWeight={"400"}
                      lineHeight={"normal"}
                    >
                      Unlimited what-if scenarios
                    </Text>
                  </Box>
                  <Box display={"flex"} alignItems={"center"} gap={"10px"}>
                    <Icon
                      as={CheckedIcon}
                      svgheight={"22.5px"}
                      svgwidth={"22.5px"}
                    />
                    <Text
                      color={"#00466D"}
                      fontFamily={"Nunito"}
                      fontSize={fontSize}
                      fontStyle={"normal"}
                      fontWeight={"400"}
                      lineHeight={"normal"}
                    >
                      Virtual assistant
                    </Text>
                  </Box>
                </Box>
                <Box
                  display={"flex"}
                  flexDirection={"column"}
                  alignItems={"flex-start"}
                  gap={"5px"}
                >
                  <Box display={"flex"} alignItems={"center"} gap={"10px"}>
                    <Icon
                      as={CheckedIcon}
                      svgheight={"22.5px"}
                      svgwidth={"22.5px"}
                    />
                    <Text
                      color={"#00466D"}
                      fontFamily={"Nunito"}
                      fontSize={fontSize}
                      fontStyle={"normal"}
                      fontWeight={"400"}
                      lineHeight={"normal"}
                    >
                      Advanced Insights
                    </Text>
                  </Box>
                  <Box display={"flex"} alignItems={"center"} gap={"10px"}>
                    <Icon
                      as={CheckedIcon}
                      svgheight={"22.5px"}
                      svgwidth={"22.5px"}
                    />
                    <Text
                      color={"#00466D"}
                      fontFamily={"Nunito"}
                      fontSize={fontSize}
                      fontStyle={"normal"}
                      fontWeight={"400"}
                      lineHeight={"normal"}
                    >
                      Save your analysis
                    </Text>
                  </Box>
                  <Box display={"flex"} alignItems={"center"} gap={"10px"}>
                    <Icon
                      as={CheckedIcon}
                      svgheight={"22.5px"}
                      svgwidth={"22.5px"}
                    />
                    <Text
                      color={"#00466D"}
                      fontFamily={"Nunito"}
                      fontSize={fontSize}
                      fontStyle={"normal"}
                      fontWeight={"400"}
                      lineHeight={"normal"}
                    >
                      Premium Support
                    </Text>
                  </Box>
                  <Box display={"flex"} alignItems={"center"} gap={"10px"}>
                    <Icon
                      as={CheckedIcon}
                      svgheight={"22.5px"}
                      svgwidth={"22.5px"}
                    />
                    <Text
                      color={"#00466D"}
                      fontFamily={"Nunito"}
                      fontSize={fontSize}
                      fontStyle={"normal"}
                      fontWeight={"400"}
                      lineHeight={"normal"}
                    >
                      Linked bank accounts
                    </Text>
                  </Box>
                  <Box display={"flex"} alignItems={"center"} gap={"10px"}>
                    <Icon
                      as={GreyUncheckedIcon}
                      svgheight={"22.5px"}
                      svgwidth={"22.5px"}
                    />
                    <Text
                      color={"#00466D"}
                      fontFamily={"Nunito"}
                      fontSize={fontSize}
                      fontStyle={"normal"}
                      fontWeight={"400"}
                      lineHeight={"normal"}
                    >
                      Income properties
                    </Text>
                  </Box>
                  <Box display={"flex"} alignItems={"center"} gap={"10px"}>
                    <Icon
                      as={GreyUncheckedIcon}
                      svgheight={"22.5px"}
                      svgwidth={"22.5px"}
                    />
                    <Text
                      color={"#00466D"}
                      fontFamily={"Nunito"}
                      fontSize={fontSize}
                      fontStyle={"normal"}
                      fontWeight={"400"}
                      lineHeight={"normal"}
                    >
                      Business (CCPC)
                    </Text>
                  </Box>
                </Box>
              </Box>
            </>
          )}
          {(planCol === "PREMIUM" || planCol === "PREMIUM_M") && (
            <>
              {(currentPlan != "PREMIUM" && currentPlan != "PREMIUM_M") && (
                <Text
                  color={"#00466D"}
                  fontFamily={"Nunito"}
                  fontSize={"9px"}
                  fontStyle={"normal"}
                  fontWeight={"700"}
                  lineHeight={"normal"}
                  width={"80%"}
                >
                  Upgrade now at a reduced cost based on the remaining duration
                  of your annual subscription. You only pay the difference for
                  the upgrade until your next billing cycle.
                </Text>
              )}

              <Box
                display={"flex"}
                alignItems={"flex-start"}
                gap={"40px"}
                alignSelf={"stretch"}
                paddingBottom={"20px"}
                {...(isMobile ? { flexDirection: "column", gap: "5px" } : {})}
              >
                <Box
                  display={"flex"}
                  flexDirection={"column"}
                  alignItems={"flex-start"}
                  gap={"5px"}
                >
                  <Box display={"flex"} alignItems={"center"} gap={"10px"}>
                    <Icon
                      as={CheckedIcon}
                      svgheight={"22.5px"}
                      svgwidth={"22.5px"}
                    />
                    <Text
                      color={"#00466D"}
                      fontFamily={"Nunito"}
                      fontSize={fontSize}
                      fontStyle={"normal"}
                      fontWeight={"400"}
                      lineHeight={"normal"}
                    >
                      Deposit strategy
                    </Text>
                  </Box>
                  <Box display={"flex"} alignItems={"center"} gap={"10px"}>
                    <Icon
                      as={CheckedIcon}
                      svgheight={"22.5px"}
                      svgwidth={"22.5px"}
                    />
                    <Text
                      color={"#00466D"}
                      fontFamily={"Nunito"}
                      fontSize={fontSize}
                      fontStyle={"normal"}
                      fontWeight={"400"}
                      lineHeight={"normal"}
                    >
                      Withdrawal strategy
                    </Text>
                  </Box>
                  <Box display={"flex"} alignItems={"center"} gap={"10px"}>
                    <Icon
                      as={CheckedIcon}
                      svgheight={"22.5px"}
                      svgwidth={"22.5px"}
                    />
                    <Text
                      color={"#00466D"}
                      fontFamily={"Nunito"}
                      fontSize={fontSize}
                      fontStyle={"normal"}
                      fontWeight={"400"}
                      lineHeight={"normal"}
                    >
                      Canadian Pension Plan start age
                    </Text>
                  </Box>
                  <Box display={"flex"} alignItems={"center"} gap={"10px"}>
                    <Icon
                      as={CheckedIcon}
                      svgheight={"22.5px"}
                      svgwidth={"22.5px"}
                    />
                    <Text
                      color={"#00466D"}
                      fontFamily={"Nunito"}
                      fontSize={fontSize}
                      fontStyle={"normal"}
                      fontWeight={"400"}
                      lineHeight={"normal"}
                    >
                      Old Age Security start age
                    </Text>
                  </Box>
                  <Box display={"flex"} alignItems={"center"} gap={"10px"}>
                    <Icon
                      as={CheckedIcon}
                      svgheight={"22.5px"}
                      svgwidth={"22.5px"}
                    />
                    <Text
                      color={"#00466D"}
                      fontFamily={"Nunito"}
                      fontSize={fontSize}
                      fontStyle={"normal"}
                      fontWeight={"400"}
                      lineHeight={"normal"}
                    >
                      Unlimited what-if scenarios
                    </Text>
                  </Box>
                  <Box display={"flex"} alignItems={"center"} gap={"10px"}>
                    <Icon
                      as={CheckedIcon}
                      svgheight={"22.5px"}
                      svgwidth={"22.5px"}
                    />
                    <Text
                      color={"#00466D"}
                      fontFamily={"Nunito"}
                      fontSize={fontSize}
                      fontStyle={"normal"}
                      fontWeight={"400"}
                      lineHeight={"normal"}
                    >
                      Virtual assistant
                    </Text>
                  </Box>
                </Box>
                <Box
                  display={"flex"}
                  flexDirection={"column"}
                  alignItems={"flex-start"}
                  gap={"5px"}
                >
                  <Box display={"flex"} alignItems={"center"} gap={"10px"}>
                    <Icon
                      as={CheckedIcon}
                      svgheight={"22.5px"}
                      svgwidth={"22.5px"}
                    />
                    <Text
                      color={"#00466D"}
                      fontFamily={"Nunito"}
                      fontSize={fontSize}
                      fontStyle={"normal"}
                      fontWeight={"400"}
                      lineHeight={"normal"}
                    >
                      Advanced Insights
                    </Text>
                  </Box>
                  <Box display={"flex"} alignItems={"center"} gap={"10px"}>
                    <Icon
                      as={CheckedIcon}
                      svgheight={"22.5px"}
                      svgwidth={"22.5px"}
                    />
                    <Text
                      color={"#00466D"}
                      fontFamily={"Nunito"}
                      fontSize={fontSize}
                      fontStyle={"normal"}
                      fontWeight={"400"}
                      lineHeight={"normal"}
                    >
                      Save your analysis
                    </Text>
                  </Box>
                  <Box display={"flex"} alignItems={"center"} gap={"10px"}>
                    <Icon
                      as={CheckedIcon}
                      svgheight={"22.5px"}
                      svgwidth={"22.5px"}
                    />
                    <Text
                      color={"#00466D"}
                      fontFamily={"Nunito"}
                      fontSize={fontSize}
                      fontStyle={"normal"}
                      fontWeight={"400"}
                      lineHeight={"normal"}
                    >
                      Premium Support
                    </Text>
                  </Box>
                  <Box display={"flex"} alignItems={"center"} gap={"10px"}>
                    <Icon
                      as={CheckedIcon}
                      svgheight={"22.5px"}
                      svgwidth={"22.5px"}
                    />
                    <Text
                      color={"#00466D"}
                      fontFamily={"Nunito"}
                      fontSize={fontSize}
                      fontStyle={"normal"}
                      fontWeight={"400"}
                      lineHeight={"normal"}
                    >
                      Linked bank accounts
                    </Text>
                  </Box>
                  <Box display={"flex"} alignItems={"center"} gap={"10px"}>
                    <Icon
                      as={CheckedIcon}
                      svgheight={"22.5px"}
                      svgwidth={"22.5px"}
                    />
                    <Text
                      color={"#00466D"}
                      fontFamily={"Nunito"}
                      fontSize={fontSize}
                      fontStyle={"normal"}
                      fontWeight={"400"}
                      lineHeight={"normal"}
                    >
                      Income properties
                    </Text>
                  </Box>
                  <Box display={"flex"} alignItems={"center"} gap={"10px"}>
                    <Icon
                      as={CheckedIcon}
                      svgheight={"22.5px"}
                      svgwidth={"22.5px"}
                    />
                    <Text
                      color={"#00466D"}
                      fontFamily={"Nunito"}
                      fontSize={fontSize}
                      fontStyle={"normal"}
                      fontWeight={"400"}
                      lineHeight={"normal"}
                    >
                      Business (CCPC)
                    </Text>
                  </Box>
                </Box>
              </Box>
            </>
          )}
        </div>
      </div>
    );
  };

  function UpgradeStripeModal() {
    setShowStripeModal(!showStripeModal);
  }

  const planDescriptions = {
    "ESSENTIALS": "Essentials Plan (annual)",
    "ESSENTIALS_M": "Essentials Plan (monthly)",
    "PRO": "Professional Plan (annual)",
    "PRO_M": "Professional Plan (monthly)",
    "PREMIUM": "Premium Plan (annual)",
    "PREMIUM_M": "Premium Plan (monthly)",
  };

  const lookupKey = stripePlan?.items?.data?.[0]?.price?.lookup_key;
  const customValue = planDescriptions[lookupKey] || "Default Plan Description";

  const [isAnnual, setIsAnnual] = useState(!lookupKey.endsWith('_M'));
  
  return (
    <Flex
      display="flex"
      flexWrap="wrap" // Enable wrapping
      justifyContent="flex-start"
      alignItems="start"
      width={"100%"}
    >
      <Modal show={showModal} onClose={() => setShowModal(false)} />
      <StripeUpgradeModal
        isOpen={showStripeModal}
        closeModal={UpgradeStripeModal}
      />
      <VStack alignItems={"flex-start"} width={"100%"}>
        <Box
          display={"flex"}
          padding={isMobile ? "15px" : "20px"}
          flexDirection={"column"}
          alignItems={"flex-start"}
          gap={"10px"}
          alignSelf={"stretch"}
          borderRadius={"14px 10px 10px 10px"}
          border={"1px solid #00A2D4"}
          bg={"#FFF"}
        >
          <HStack justifyContent={"space-between"} width={"100%"}>
            <Text
              color={"#00A2D4"}
              fontFamily={"Nunito"}
              fontSize={"20px"}
              fontStyle={"normal"}
              fontWeight={"700"}
              lineHeight={"normal"}
            >
              Change Plan
            </Text>
            <Popover placement="left">
              <PopoverTrigger >
                <Box cursor={"pointer"}>
                  <Icon
                    as={GearIcon}
                    svgwidth={"20px"}
                    svgheight={"22px"}
                    cursor={"pointer"}
                  />
                </Box>
              </PopoverTrigger>
              <Portal>
                <PopoverContent
                  w={"-webkit-fill-available"}
                  borderRadius={"10px"}
                  border={"none"}
                  marginTop={"90px"}
                >
                  <Box
                    display={"inline-flex"}
                    padding={"15px"}
                    flexDirection={"column"}
                    alignItems={"flex-start"}
                    gap={"10px"}
                    borderRadius={"10px"}
                    border={"1px solid #AFB2AF"}
                    bg={"#FFF"}
                    flexGrow={1}
                  >
                    {popUpGear.map((item) => (
                      <HStack
                        justifyContent="flex-start"
                        gap={"15px"}
                        alignItems={"center"}
                        w={"100%"}
                        onClick={() => {
                          UpgradeStripeModal();
                        }}
                        cursor={"pointer"}
                      >
                        <Box
                          display="flex"
                          alignItems="center"
                          justifyContent="flex-start"
                          w={"24px"}

                        >
                          {item.icon}
                        </Box>
                        <Box flex="1" display="flex" alignItems="center">
                          <Text
                            fontFamily="Nunito"
                            fontSize={"14px"}
                            fontStyle={"normal"}
                            fontWeight={"600"}
                            lineHeight={"normal"}
                            
                          >
                            {item.label}
                          </Text>
                        </Box>
                      </HStack>
                    ))}
                  </Box>
                </PopoverContent>
              </Portal>
            </Popover>
          </HStack>
          <>
            <Box
              display={"flex"}
              padding={"20px"}
              flexDirection={"column"}
              alignItems={"flex-start"}
              gap={"10px"}
              borderRadius={"10px"}
              border={"1px solid #00A2D4"}
              alignSelf={"stretch"}
            >
              <HStack>
                <Text
                  color={"#004069"}
                  fontFamily={"Nunito"}
                  fontSize={isMobile ? "14px" : "16px"}
                  fontStyle={"normal"}
                  fontWeight={"600"}
                  lineHeight={"130%"}
                >
                  Current Plan:
                </Text>
                <Text
                  color={"#00A2D4"}
                  fontFamily={"Nunito"}
                  fontSize={isMobile ? "14px" : "16px"}
                  fontStyle={"normal"}
                  fontWeight={"800"}
                  lineHeight={"130%"}
                >
                  {customValue}
                </Text>
              </HStack>
              <HStack>
                <Text
                  color={"#004069"}
                  fontFamily={"Nunito"}
                  fontSize={isMobile ? "14px" : "16px"}
                  fontStyle={"normal"}
                  fontWeight={"600"}
                  lineHeight={"130%"}
                >
                  Next billing date:
                </Text>
                <Text
                  color={"#00A2D4"}
                  fontFamily={"Nunito"}
                  fontSize={isMobile ? "14px" : "16px"}
                  fontStyle={"normal"}
                  fontWeight={"800"}
                  lineHeight={"130%"}
                >
                  {billingEnd}
                </Text>
              </HStack>
              {/* <HStack>
                  <Text
                    color={"#004069"}
                    fontFamily={"Nunito"}
                    fontSize={isMobile ? "14px" : "16px"}
                    fontStyle={"normal"}
                    fontWeight={"600"}
                    lineHeight={"130%"}
                  >
                    Amount due on next billing cycle:
                  </Text>
                  <Text
                    color={"#00A2D4"}
                    fontFamily={"Nunito"}
                    fontSize={isMobile ? "14px" : "16px"}
                    fontStyle={"normal"}
                    fontWeight={"800"}
                    lineHeight={"130%"}
                  >
                    ${stripePlan?.items?.data?.[0]?.plan?.amount / 100}.00
                  </Text>
                </HStack> */}
            </Box>
            <SubscriptionCard
              currentPlan={stripePlan?.items?.data?.[0]?.price?.lookup_key}
              planCol={isAnnual ? "OPTIML_ESSENTIALS" : "OPTIML_ESSENTIALS_M"}
              price="$149/year | $14.99/month"
            />
            <SubscriptionCard
              currentPlan={stripePlan?.items?.data?.[0]?.price?.lookup_key}
              planCol={isAnnual ? "PRO" : "PRO_M"}
              price="$249/year | $24.99/month"
            />
            <SubscriptionCard
              currentPlan={stripePlan?.items?.data?.[0]?.price?.lookup_key}
              planCol={isAnnual ? "PREMIUM" : "PREMIUM_M"}
              price="$349/year | $34.99/month"
            />
          </>
        </Box>
      </VStack>
    </Flex>
  );
}

export default ChangePlanPage;
