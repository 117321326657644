import { useAuth0 } from "@auth0/auth0-react";
import {
  Box,
  Button,
  HStack,
  Text,
  useMediaQuery,
  Switch,
  FormLabel,
} from "@chakra-ui/react";
import { faCheckCircle } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import OptimlLogo from "../../img/Optiml_logo.svg";
import optimlDots from "../../img/OptimlDots.png";
import {
  BackIcon,
  BackToDashboardIcon,
  OptimlDotsIcon,
  OptimlIcon,
} from "../CustomIcons";
import { StripeContext } from "../StripeContext";
import "./StripePayments.css";

function StripePayments(props) {
  let [message, setMessage] = useState("");
  let [success, setSuccess] = useState(false);
  let [sessionId, setSessionId] = useState("");
  const [loading, setLoading] = useState(false);
  const { stripePlan, setStripePlan } = useContext(StripeContext);

  const { user, getAccessTokenSilently, logout } = useAuth0();

  const onSubmitHandler = (action, value) => {
    setLoading(true);
    const axiosInstance = axios.create();
    async function getToken() {
      // Replace this with your Auth0 token retrieval logic
      const token = await getAccessTokenSilently({
        audience:
          "https://os2lsiwff43wfpb5ggyawt6qri0mgcro.lambda-url.us-east-2.on.aws/",
        scope: "write:optimization",
      });
      return token;
    }
    // Add a request interceptor
    axiosInstance.interceptors.request.use(
      async (config) => {
        const token = await getToken();
        config.headers.Authorization = `Bearer ${token}`;
        return config;
      },
      (error) => {
        // Do something with request error
        return Promise.reject(error);
      }
    );
    const auth0_params = {
      user_id: user.sub,
      action: "Get_Stripe_ID",
    };
    try {
      axiosInstance
        .post(process.env.REACT_APP_AUTH0_MANAGEMENT_API, auth0_params)
        .then((res) => {
          const params = {
            stripe_id: res.data,
            action: action,
            price: value,
          };
          axios
            .post(process.env.REACT_APP_STRIPE_API_KEY, params)
            .then((res) => {
              if (res.data.message === "Subscription created successfully") {
                // props.setStriped(true);
                window.location.reload();
              }
              if (
                res.data.message != "Subscription created successfully" &&
                res.data.message.url
              ) {
                window.location.href = res.data.message.url;
              }
            })
            .catch((error) => {
              console.log(error);
            });
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {
      console.error("Error redirecting to checkout:", error);
    }
  };

  const [isLargerThan1200] = useMediaQuery(
    "(min-width: 1200px) and (max-width: 1300px)"
  );

  const [isLargerThan1000] = useMediaQuery(
    "(max-width: 1030px) and (min-width: 500px)"
  );
  const [isLargerThan500] = useMediaQuery("(max-width: 500px)");

  const highlight = ({ content }) => {
    return (
      <Box
        display={"flex"}
        padding={"20px"}
        flexDirection={"column"}
        alignItems={"center"}
        gap={"10px"}
        borderRadius={"10px"}
        border={"2px solid #0093D0"}
        bg={"#FFF"}
      >
        <Box
          display={"flex"}
          padding={"10px 25px"}
          justifyContent={"center"}
          alignItems={"center"}
          gap={"10px"}
          alignSelf={"stretch"}
          borderRadius={"100px"}
          bg={"#30AB9D"}
        >
          <Text
            color={"#FFF"}
            fontFamily={"Nunito"}
            fontSize={"16px"}
            fontStyle={"normal"}
            fontWeight={"600"}
            lineHeight={"normal"}
          >
            Most popular
          </Text>
        </Box>
        {content}
      </Box>
    );
  };

  const ToggleSwitch = ({ isAnnual, onToggle }) => {
    return (
        <div className="toggle-container">
            <div className={`option ${!isAnnual ? 'selected' : ''}`} onClick={() => onToggle(false)}>
                Monthly
            </div>
            <div className={`option ${isAnnual ? 'selected' : ''}`} onClick={() => onToggle(true)}>
                Annual
            </div>
        </div>
    );
};

  const ProductDisplay = ({ loading, onSubmitHandler }) => {
    const [isAnnual, setIsAnnual] = useState(false);  // This state toggles between Monthly and Annual

    // This function will be called by the ToggleSwitch to update the state
    const handleToggle = (newIsAnnual) => {
      setIsAnnual(newIsAnnual);
    };

    return (
      <Box
        display={"flex"}
        justifyContent={"center"}
        height={"100dvh"}
        overflowY={"scroll"}
        alignItems={"flex-start"}
        padding={
          isLargerThan1200 ? "25px" : isLargerThan500 ? "25px 15px" : "50px"
        }
        gap={"10px"}
        bg={"#007EB5"}
        position={isLargerThan500 ? "sticky" : "relative"}
        overflow={isLargerThan500 && "auto"}
      >
        <Box className="pricing-section">
          {/* Toggle switch for Monthly/Annual Subscription */}
          <ToggleSwitch isAnnual={isAnnual} onToggle={handleToggle} />
          <Text fontWeight={'700'}>Save 20% annually</Text>
          <Box
            alignSelf={"flex-start"}
            zIndex={5}
            onClick={logout}
            cursor={"pointer"}
          >
            <BackIcon svgwidth={"25px"} svgheight={"25px"} />
          </Box>
          <Box
            position={"absolute"}
            alignSelf={"flex-end"}
            marginRight={
              isLargerThan500 ? "-12px" : isLargerThan1200 ? "-40px" : "-65px"
            }
            marginTop={
              isLargerThan500 ? "-15px" : isLargerThan1200 ? "-30px" : "-65px"
            }
          >
            <OptimlDotsIcon
              svgwidth={isLargerThan500 ? "120px" : "298px"}
              svgheight={isLargerThan500 ? "80px" : "178px"}
            />
          </Box>
          <div className="pricing-container">
            <Box>
              <OptimlIcon />
            </Box>
            <Box>
              <Text
                color={"#004069"}
                fontFamily={"Nunito"}
                fontSize={isLargerThan1200 ? "20px" : "24px"}
                fontStyle={"normal"}
                fontWeight={"700"}
                lineHeight={"normal"}
              >
                Select your plan
              </Text>
            </Box>
            <Box
              display={"flex"}
              gap={isLargerThan500 ? "25px" : "10px"}
              flexDirection={
                isLargerThan1000 || isLargerThan500 ? "column" : "row"
              }
              justifyContent={"space-between"}
              alignItems={"center"}
              width={"100%"}
            >
              <div className="pricing-option">
                <div
                  className="pricing-header"
                  style={{ backgroundColor: "#73B879" }}
                >
                  Essentials
                </div>
                <div className="description">
                  {isAnnual && <h3>$149.00 / Year</h3>}
                  {!isAnnual && <h3>$14.99 / Month</h3>}
                  <ul>
                    <li className="pricing-item">
                      <FontAwesomeIcon
                        icon={faCheckCircle}
                        style={{ color: "#0F9CD6" }}
                        className="pricing-icon"
                      />{" "}
                      <span className="pricing-text">
                        Unlimited what-if scenarios
                      </span>
                    </li>
                    <li className="pricing-item">
                      <FontAwesomeIcon
                        icon={faCheckCircle}
                        style={{ color: "#0F9CD6" }}
                        className="pricing-icon"
                      />{" "}
                      <span className="pricing-text">Virtual Assistant</span>
                    </li>
                    <li className="pricing-item">
                      <FontAwesomeIcon
                        icon={faCheckCircle}
                        style={{ color: "#0F9CD6" }}
                        className="pricing-icon"
                      />{" "}
                      <span className="pricing-text">
                        Optimal deposit strategy
                      </span>
                    </li>
                    <li className="pricing-item">
                      <FontAwesomeIcon
                        icon={faCheckCircle}
                        style={{ color: "#0F9CD6" }}
                        className="pricing-icon"
                      />{" "}
                      <span className="pricing-text">
                        Optimal withdrawal strategy
                      </span>
                    </li>
                    <li className="pricing-item">
                      <FontAwesomeIcon
                        icon={faCheckCircle}
                        style={{ color: "#0F9CD6" }}
                        className="pricing-icon"
                      />{" "}
                      <span className="pricing-text">Optimal CPP age</span>
                    </li>
                    <li className="pricing-item">
                      <FontAwesomeIcon
                        icon={faCheckCircle}
                        style={{ color: "#0F9CD6" }}
                        className="pricing-icon"
                      />{" "}
                      <span className="pricing-text">Optimal OAS age</span>
                    </li>
                  </ul>
                </div>
                <div>
                  <input
                    type="hidden"
                    name="lookup_key"
                    value="OPTIML_ESSENTIALS"
                  />
                  {!loading && (
                    <Button
                      onClick={() =>
                        onSubmitHandler(
                          "Create_Checkout",
                          isAnnual ? process.env.REACT_APP_OPTIML_ESSENTIALS : process.env.REACT_APP_OPTIML_ESSENTIALS_M
                        )
                      }
                    >
                      {props?.stripeData?.status === "paused" ||
                      props?.stripeData?.status === "canceled"
                        ? "Select Plan"
                        : "30 Day Free Trial"}
                    </Button>
                  )}
                  {loading && <Button isLoading>Loading</Button>}
                </div>
              </div>
              {highlight({
                content: (
                  <div className="pricing-option">
                    <div
                      className="pricing-header"
                      style={{ backgroundColor: "#30AB9D" }}
                    >
                      Pro
                    </div>
                    <div className="description">
                    {isAnnual && <h3>$249.00 / Year</h3>}
                      {!isAnnual && <h3>$24.99 / Month</h3>}
                      <h5>Everything in Essentials, Plus:</h5>
                      <ul>
                        <li className="pricing-item">
                          <FontAwesomeIcon
                            icon={faCheckCircle}
                            style={{ color: "#0F9CD6" }}
                            className="pricing-icon"
                          />{" "}
                          <span className="pricing-text">
                            Save multiple analyses
                          </span>
                        </li>
                        <li className="pricing-item">
                          <FontAwesomeIcon
                            icon={faCheckCircle}
                            style={{ color: "#0F9CD6" }}
                            className="pricing-icon"
                          />{" "}
                          <span className="pricing-text">Premium Support</span>
                        </li>
                        <li className="pricing-item">
                          <FontAwesomeIcon
                            icon={faCheckCircle}
                            style={{ color: "#0F9CD6" }}
                            className="pricing-icon"
                          />{" "}
                          <span className="pricing-text">
                            Business Value/Income
                          </span>
                        </li>
                        <li className="pricing-item">
                          <FontAwesomeIcon
                            icon={faCheckCircle}
                            style={{ color: "#0F9CD6" }}
                            className="pricing-icon"
                          />{" "}
                          <span className="pricing-text">
                            Linked Investment Accounts
                          </span>
                        </li>
                        <li className="pricing-item">
                          <FontAwesomeIcon
                            icon={faCheckCircle}
                            style={{ color: "#0F9CD6" }}
                            className="pricing-icon"
                          />{" "}
                          <span className="pricing-text">Insights</span>
                        </li>
                      </ul>
                    </div>
                    <div>
                      <input type="hidden" name="lookup_key" value="PRO" />
                      {!loading && (
                        <Button
                          colorScheme="teal"
                          variant="outline"
                          onClick={() =>
                            onSubmitHandler(
                              "Create_Checkout",
                              isAnnual ? process.env.REACT_APP_PRO : process.env.REACT_APP_PRO_M
                            )
                          }
                        >
                          Select Plan
                        </Button>
                      )}
                      {loading && (
                        <Button colorScheme="teal" variant="outline" isLoading>
                          Loading
                        </Button>
                      )}
                    </div>
                  </div>
                ),
              })}

              <div className="pricing-option">
                <div
                  className="pricing-header"
                  style={{ backgroundColor: "#00A2D4" }}
                >
                  Premium
                </div>
                <div className="description">
                  {isAnnual && <h3>$349.00 / Year</h3>}
                  {!isAnnual && <h3>$34.99 / Month</h3>}
                  <h5>Everything in Pro, Plus:</h5>
                  <ul>
                    <li className="pricing-item">
                      <FontAwesomeIcon
                        icon={faCheckCircle}
                        style={{ color: "#0F9CD6" }}
                        className="pricing-icon"
                      />{" "}
                      <span className="pricing-text">Income Properties</span>
                    </li>
                    <li className="pricing-item">
                      <FontAwesomeIcon
                        icon={faCheckCircle}
                        style={{ color: "#0F9CD6" }}
                        className="pricing-icon"
                      />{" "}
                      <span className="pricing-text">
                        Business (CCPC) Investments
                      </span>
                    </li>
                    <li className="pricing-item">
                      <FontAwesomeIcon
                        icon={faCheckCircle}
                        style={{ color: "#0F9CD6" }}
                        className="pricing-icon"
                      />{" "}
                      <span className="pricing-text">Advanced Insights</span>
                    </li>
                  </ul>
                </div>
                <div>
                  <input type="hidden" name="lookup_key" value="PREMIUM" />
                  {!loading && (
                    <Button
                      colorScheme="teal"
                      variant="outline"
                      onClick={() =>
                        onSubmitHandler(
                          "Create_Checkout",
                          isAnnual ? process.env.REACT_APP_PREMIUM : process.env.REACT_APP_PREMIUM_M
                        )
                      }
                    >
                      Select Plan
                    </Button>
                  )}
                  {loading && (
                    <Button colorScheme="teal" variant="outline" isLoading>
                      Loading
                    </Button>
                  )}
                </div>
              </div>
            </Box>
            {/* <Box
            display={"flex"}
            alignItems={"flex-start"}
            gap={"10px"}
            alignSelf={"stretch"}
          >
            <Button
              display={"flex"}
              width={"105px"}
              bg={"#00A2D4"}
              borderRadius={"5px"}
              onClick={logout}
            >
              <Text color={"#FFF"} fontFamily={"Nunito"}>
                Back
              </Text>
            </Button>
          </Box> */}
          </div>

          <div className="image-container-optiml">
            <img className="oltreDots" src={optimlDots} alt="Optiml Dots" />
          </div>
        </Box>
      </Box>
    );
  };

  const SuccessDisplay = ({ sessionId }) => {
    return (
      <section>
        <div className="product Box-root">
          <Logo />
          <div className="description Box-root">
            <h3>Subscription to starter plan successful!</h3>
          </div>
        </div>
        <form
          action="https://eah3lot3wzjt72rrunaljhlrd40mvfvx.lambda-url.us-east-2.on.aws/create-portal-session"
          method="POST"
        >
          <input
            type="hidden"
            id="session-id"
            name="session_id"
            value={sessionId}
          />
          <button id="checkout-and-portal-button" type="submit">
            Manage your billing information
          </button>
        </form>
      </section>
    );
  };

  const Message = ({ message }) => (
    <section>
      <p>{message}</p>
    </section>
  );

  const Logo = () => <img style={{ width: "15rem" }} src={OptimlLogo} />;

  useEffect(() => {
    // Check to see if this is a redirect back from Checkout
    const query = new URLSearchParams(window.location.search);

    console.log(query); // For debugging

    const sessionIdValue = query.get("session_id");

    if (sessionIdValue) {
      setSuccess(true);
      setSessionId(sessionIdValue);
      props.setStriped(true);
    } else {
      // If there's no session_id, you might want to handle this differently
      // For example, checking if there's a "canceled" flag or something similar
      console.log("No session_id found in the URL");
    }
  }, [sessionId, props.setStriped]);

  if (!success && message === "") {
    return (
      <ProductDisplay loading={loading} onSubmitHandler={onSubmitHandler} />
    );
  } else {
    return null;
  }
}

export default StripePayments;
