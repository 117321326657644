import { Box, Flex, VStack } from "@chakra-ui/layout";
import { Icon, Text, Tooltip, useMediaQuery } from "@chakra-ui/react";
import { useAuth0 } from "@auth0/auth0-react";
import axios from "axios";
import React, {
  forwardRef,
  useContext,
  useEffect,
  useImperativeHandle,
  useState,
} from "react";
import { useStore } from "../../../store";
import {
  AddAssetsIcon,
  CloseIcon,
  CottageIcon,
  IncomePropertyIcon,
  InputBoxTitleBubbleTip,
  LandIcon,
  PrimaryEstateIcon,
} from "../../CustomIcons";
import GlobalBodyPage from "../../GlobalContainer/BodyPage";
import Link from "../../Link";
import { StripeContext } from "../../StripeContext";
import StripeUpgradeModal from "../../StripeUpgradeModal";
import TableComponent from "../../TableComponent";

const RealEstatePage = forwardRef((props, ref) => {
  const { user, getAccessTokenSilently } = useAuth0();

  useImperativeHandle(ref, () => ({
    submitHandler,
    checkMandatoryFields,
  }));

  const checkMandatoryFields = () => {
    return true;
  };

  const axiosInstance = axios.create();
  async function getToken() {
    // Replace this with your Auth0 token retrieval logic
    const token = await getAccessTokenSilently({
      audience:
        "https://os2lsiwff43wfpb5ggyawt6qri0mgcro.lambda-url.us-east-2.on.aws/",
      scope: "write:optimization",
    });
    return token;
  }

  // Add a request interceptor
  axiosInstance.interceptors.request.use(
    async (config) => {
      const token = await getToken();
      config.headers.Authorization = `Bearer ${token}`;
      return config;
    },
    (error) => {
      // Do something with request error
      return Promise.reject(error);
    }
  );
  const [showStripeModal, setShowStripeModal] = useState(false);

  const [isLargerThan768] = useMediaQuery("(min-width: 500px)");
  const isMobile = !isLargerThan768;

  const [realEstateArray, setRealEstateArray] = useState([]);
  const { stripePlan, setStripePlan } = useContext(StripeContext);

  const [editing, setEditing] = useState(false);
  const [editingIndex, setEditingIndex] = useState(null);
  const [showDeleteModal, setShowDeleteModal] = useState(null);

  const userPrimaryResidenceInfo = useStore(
    (store) => store.userPrimaryResidenceInfo
  );

  const userFHSAInfo = useStore((store) => store.userFHSAInfo);
  const deleteUserPrimaryResidenceInfo = useStore(
    (store) => store.deleteUserPrimaryResidenceInfo
  );

  const userCottageInfo = useStore((store) => store.userCottageInfo);
  const deleteUserCottageInfo = useStore(
    (store) => store.deleteUserCottageInfo
  );

  const userPropertiesInfo = useStore((store) => store.userPropertiesInfo);
  const deleteUserPropertiesInfo = useStore(
    (store) => store.deleteUserPropertiesInfo
  );

  const userLandInfo = useStore((store) => store.userLandInfo);
  const deleteUserLandInfo = useStore((store) => store.deleteUserLandInfo);

  const userEditAssetInfo = useStore((store) => store.userEditAssetInfo);
  const setUserEditAssetInfo = useStore((store) => store.setUserEditAssetInfo);

  const setUserPageLockInfo = useStore((store) => store.setUserPageLockInfo);
  const userPageLockInfo = useStore((store) => store.userPageLockInfo);

  function UpgradeStripeModal() {
    setShowStripeModal(!showStripeModal);
  }

  useEffect(() => {
    setUserEditAssetInfo({});
  }, []);

  useEffect(() => {
    const updatedRealEstateArray = [];
    if (userPrimaryResidenceInfo[0] != null) {
      updatedRealEstateArray.push(userPrimaryResidenceInfo[0]);
    }

    if (userCottageInfo != null) {
      updatedRealEstateArray.push(...userCottageInfo);
    }

    if (userPropertiesInfo != null) {
      updatedRealEstateArray.push(...userPropertiesInfo);
    }

    if (userLandInfo != null) {
      updatedRealEstateArray.push(...userLandInfo);
    }

    setRealEstateArray(updatedRealEstateArray);
  }, [
    userPrimaryResidenceInfo,
    userCottageInfo,
    userPropertiesInfo,
    userLandInfo,
  ]);

  const submitHandler = () => {
    //Set Page Lock Info In Database:
    setUserEditAssetInfo({});
    const pageLockURL = (action) =>
      `${
        process.env.REACT_APP_NODE_API_GATEWAY
      }/CompassLambdaApi/NEW_USER_PAGE_LOCK/${action}?PageLockID=${user.sub.replace(
        "|",
        "%7c"
      )}&IncomePage=${1}&InvestmentsPage=${1}&ExpensesPage=${1}`;
    axiosInstance
      .get(pageLockURL("add"))
      .then((res) => {
        //Unlock Income Sidebar:
        setUserPageLockInfo({
          ...userPageLockInfo,
          ExpensesPage: 1,
        });
        console.log(res);
        if (res.data.code === "ER_DUP_ENTRY") {
          axiosInstance.get(pageLockURL("update")).then((res) => {
            console.log(res);
            //Unlock Income Sidebar:
            setUserPageLockInfo({
              ...userPageLockInfo,
              ExpensesPage: 1,
            });
          });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [realEstateArray]);


  const Assets = [
    {
      title: "Primary",
      icon: PrimaryEstateIcon,
      path: "/primary-residence-form",
      stripe:
        userFHSAInfo?.doFhsa === "No" && userFHSAInfo?.fhsaEligible === "No",
    },
    {
      title: "Cottage",
      icon: CottageIcon,
      path: "/recreational-property-form",
      stripe:
        stripePlan?.items?.data?.[0]?.price?.lookup_key === "PREMIUM" ||
        stripePlan?.items?.data?.[0]?.price?.lookup_key === "PREMIUM_M" ||
        stripePlan?.items?.data?.[0]?.price?.lookup_key === "PRO_M" ||
        stripePlan?.items?.data?.[0]?.price?.lookup_key === "PRO",
    },
    {
      title: "Income Property",
      icon: IncomePropertyIcon,
      path: "/income-property-form",
      stripe: stripePlan?.items?.data?.[0]?.price?.lookup_key === "PREMIUM" || stripePlan?.items?.data?.[0]?.price?.lookup_key === "PREMIUM_M",
    },
    {
      title: "Land",
      icon: LandIcon,
      path: "/land-property-form",
      stripe: stripePlan?.items?.data?.[0]?.price?.lookup_key === "PREMIUM" || stripePlan?.items?.data?.[0]?.price?.lookup_key === "PREMIUM_M",
    },
  ];

  const onDeleteRow = (i) => {
    setEditingIndex(i);
    setShowDeleteModal(true);
  };

  const editField = (i, array) => {
    const data = {
      list: array[i],
      editingIndex: i,
    };
    setUserEditAssetInfo(data);
    if (array[i].name === "Primary residence") {
      window.history.pushState({}, "", "/primary-residence-form");
      const navEvent = new PopStateEvent("popstate");
      window.dispatchEvent(navEvent);
    } else if (array[i].name === "Recreation property") {
      window.history.pushState({}, "", "/recreational-property-form");
      const navEvent = new PopStateEvent("popstate");
      window.dispatchEvent(navEvent);
    } else if (array[i].name === "Income property") {
      window.history.pushState({}, "", "/income-property-form");
      const navEvent = new PopStateEvent("popstate");
      window.dispatchEvent(navEvent);
    } else if (array[i].name === "Land") {
      window.history.pushState({}, "", "/land-property-form");
      const navEvent = new PopStateEvent("popstate");
      window.dispatchEvent(navEvent);
    }
  };

  const DeleteRealEstateModal = () => {
    let activeArray = realEstateArray;

    const removeRealEstateModal = () => {
      if (activeArray[editingIndex].name === "Primary residence") {
        const deletePrimaryResUrl = (action) =>
          `${process.env.REACT_APP_NODE_API_GATEWAY}/CompassLambdaApi/REAL_ESTATE/${action}?Primary_Res_Number=${activeArray[editingIndex].Primary_Res_Number}`;
        axiosInstance.get(deletePrimaryResUrl("delete")).then((res) => {
          console.log("Delete res", res);
          deleteUserPrimaryResidenceInfo(
            activeArray[editingIndex].Primary_Res_Number
          );
          removeField(editingIndex, activeArray);
          setEditingIndex(null);
          setEditing(false);
        });
      } else if (activeArray[editingIndex].name === "Recreation property") {
        const deleteCottageURL = (action) =>
          `${process.env.REACT_APP_NODE_API_GATEWAY}/CompassLambdaApi/COTTAGE/${action}?Cottage_Number=${activeArray[editingIndex].Cottage_Number}`;
        axiosInstance.get(deleteCottageURL("delete")).then((res) => {
          console.log("Delete res", res);
          deleteUserCottageInfo(activeArray[editingIndex].Cottage_Number);
          removeField(editingIndex, activeArray);
          setEditingIndex(null);
          setEditing(false);
        });
      } else if (activeArray[editingIndex].name === "Income property") {
        const deletePropertyURL = (action) =>
          `${process.env.REACT_APP_NODE_API_GATEWAY}/CompassLambdaApi/PROPERTIES/${action}?Property_Number=${activeArray[editingIndex].Property_Number}`;
        axiosInstance.get(deletePropertyURL("delete")).then((res) => {
          console.log("Delete res", res);
          deleteUserPropertiesInfo(activeArray[editingIndex].Property_Number);
          removeField(editingIndex, activeArray);
          setEditingIndex(null);
          setEditing(false);
        });
      } else if (activeArray[editingIndex].name === "Land") {
        const deletePropertyURL = (action) =>
          `${process.env.REACT_APP_NODE_API_GATEWAY}/CompassLambdaApi/LAND/${action}?landNumber=${activeArray[editingIndex].landNumber}`;
        axiosInstance.get(deletePropertyURL("delete")).then((res) => {
          console.log("Delete res", res);
          deleteUserLandInfo(activeArray[editingIndex].landNumber);
          removeField(editingIndex, activeArray);
          setEditingIndex(null);
          setEditing(false);
        });
      }
    };

    if (!showDeleteModal) {
      return null;
    }
    const [yesClicked, setYesClicked] = useState(false);
    const [noClicked, setNoClicked] = useState(false);
    return (
      <div
        className="delete-income-modal"
        style={{
          display: "flex",
          alignItems: "center",
          width: "100%",
          maxWidth: "700px",
          justifyContent: "center",
          top: "50%",
          left: "50%",
          transform: isMobile
            ? "translate(-50%, -50%)"
            : "translate(-50%, -50%)",
        }}
        id="delete-income-modal"
      >
        <Box
          display={"flex"}
          width={"100%"}
          padding={"10px 10px 0px 0px"}
          marginLeft={"5px"}
          alignItems={"flex-end"}
          justifyContent={"flex-end"}
          onClick={() => setShowDeleteModal(false)}
          cursor={"pointer"}
        >
          <CloseIcon
            onClick={() => setShowDeleteModal(false)}
            svgheight={"20px"}
            svgwidth={"20px"}
          />
        </Box>
        <Box
          display={"flex"}
          padding={"0px 25px 25px 25px"}
          flexDirection={"column"}
          alignItems={"center"}
          gap={"10px"}
          alignSelf={"stretch"}
          width={"100%"}
        >
          <Text
            color={"#0093D0"}
            fontFamily={"Nunito"}
            fontSize={"24px"}
            fontStyle={"normal"}
            fontWeight={"600"}
            lineHeight={"normal"}
            textDecor={"underline"}
          >
            Are you sure you want to remove this real estate asset?
          </Text>
          <div className="content"></div>
          <div className="actions">
            <button
              className={`decision-button yes-button ${
                yesClicked ? "yes-clicked" : ""
              }`}
              onClick={() => {
                setYesClicked(true);
                setNoClicked(false);
                removeRealEstateModal();
              }}
            >
              Yes
            </button>
            <button
              className={`decision-button no-button ${
                noClicked ? "yes-clicked" : ""
              }`}
              onClick={() => {
                {
                  setNoClicked(true);
                  setYesClicked(false);
                  setShowDeleteModal(false);
                }
              }}
            >
              No
            </button>
          </div>
        </Box>
      </div>
    );
  };

  const removeField = (i, array) => {
    array.splice(i, 1);
    setRealEstateArray((prevArray) => [...prevArray]);
    setShowDeleteModal(false);
  };

  const columns = [
    { header: "Name", field: "name" },
    { header: "Value", field: "value" },
  ];

  const data = realEstateArray.map((item) => ({
    name: item?.Cottage_Address
      ? item?.Cottage_Address
      : item?.landAddress
      ? item?.landAddress
      : item.name,
    value: item?.value,
    link:
      item?.name === "Primary residence"
        ? "/primary-residence-form"
        : item?.name === "Recreation property"
        ? "/recreational-property-form"
        : item?.name === "Land"
        ? "/land-property-form"
        : "/income-property-form",
  }));

  return (
    <Flex
      display="flex"
      flexWrap="wrap" // Enable wrapping
      justifyContent="flex-start"
      alignItems="start"
      width={"100%"}
    >
      {!isMobile && (
        <VStack alignItems={"flex-start"} width={"100%"} mt={-2}>
          <GlobalBodyPage
            Heading="Real Estate"
            mainframeContent={
              <>
                <StripeUpgradeModal
                  isOpen={showStripeModal}
                  closeModal={UpgradeStripeModal}
                />
                <Box
                  display={"flex"}
                  width={"100%"}
                  padding={"10px"}
                  justifyContent={"space-between"}
                  alignItems={"flex-start"}
                  alignContent={"flex-start"}
                  rowGap={"10px"}
                  gap={"10px"}
                  alignSelf={"stretch"}
                  flexWrap={"wrap"}
                  flex={"1 0 auto"}
                  borderRadius={"10px"}
                  border={"1px solid var(--Optiml---Vibrant-Sky-Blue, #00A2D4)"}
                  bg={"#FFF"}
                >
                  <DeleteRealEstateModal />
                  {data.length > 0 && (
                    <TableComponent
                      columns={columns}
                      data={data}
                      editField={editField}
                      onDeleteRow={onDeleteRow}
                      rawData={realEstateArray}
                    />
                  )}
                  {/* Assets Box */}
                  {Assets.map((asset) => (
                    <Box
                      display={"flex"}
                      minH={"121px"}
                      flexDirection={"column"}
                      padding={"4px"}
                      gap={"3px"}
                      alignItems={"center"}
                      borderRadius={"10px"}
                      border={"1px solid #0093D0"}
                      bg={"#F3FAFF"}
                      boxShadow={"0px 4px 4px 0px #00A2D4"}
                    >
                      <Box
                        display={"flex"}
                        justifyContent={"flex-end"}
                        alignItems={"flex-start"}
                        gap={"10px"}
                        alignSelf={"stretch"}
                      >
                        <Tooltip
                          label="Choose which real estate asset to add"
                          placement="right"
                          hasArrow
                        >
                          <span>
                            <Icon
                              as={InputBoxTitleBubbleTip}
                              cursor={"pointer"}
                              w={"9px"}
                              h={"9px"}
                            />
                          </span>
                        </Tooltip>
                      </Box>
                      <Box
                        display={"flex"}
                        justifyContent={"center"}
                        alignItems={"flex-end"}
                        w={"40px"}
                        h={"40px"}
                        overflow={"hidden"}
                        position={"relative"}
                      >
                        <Icon
                          as={asset.icon}
                          h={"-webkit-fit-content"}
                          w={"100%"}
                        />
                      </Box>
                      <Box
                        display={"flex"}
                        width={"105px"}
                        height={"17px"}
                        flexDirection={"column"}
                        justifyContent={"center"}
                      >
                        <Text
                          color={"#00466D"}
                          fontFamily={"Nunito"}
                          fontSize={"13px"}
                          fontStyle={"normal"}
                          fontWeight={"700"}
                          lineHeight={"normal"}
                          textAlign={"center"}
                        >
                          {asset.title}
                        </Text>
                      </Box>

                      {/* Add Button Box */}
                      {asset.stripe && (
                        <Link href={asset.path}>
                          <Box
                            display={"flex"}
                            padding={"3px 20px"}
                            justifyContent={"center"}
                            alignItems={"center"}
                            gap={"6px"}
                            alignSelf={"stretch"}
                            borderRadius={"5px"}
                            border={"1px solid #00A2D4"}
                            bg={"#FFF"}
                            cursor={"pointer"}
                          >
                            <Icon as={AddAssetsIcon} w={"12px"} h={"12px"} />
                            <Text
                              color={"#00A2D4"}
                              fontFamily={"Nunito"}
                              fontSize={"10px"}
                              fontStyle={"normal"}
                              fontWeight={"700"}
                              lineHeight={"normal"}
                            >
                              {asset.title === "Primary" &&
                              userPrimaryResidenceInfo?.length > 0
                                ? "Edit"
                                : "Add"}
                            </Text>
                          </Box>
                        </Link>
                      )}
                      {!asset.stripe && asset.title != "Primary" && (
                        <Box
                          display={"flex"}
                          padding={"3px 20px"}
                          justifyContent={"center"}
                          alignItems={"center"}
                          gap={"6px"}
                          alignSelf={"stretch"}
                          borderRadius={"5px"}
                          border={"1px solid #00A2D4"}
                          bg={"#FFF"}
                          cursor={"pointer"}
                          onClick={UpgradeStripeModal}
                        >
                          {/* <Icon as={AddAssetsIcon} w={"12px"} h={"12px"} /> */}
                          {asset.title != "Primary" && (
                            <Text
                              color={"#00A2D4"}
                              fontFamily={"Nunito"}
                              fontSize={"10px"}
                              fontStyle={"normal"}
                              fontWeight={"700"}
                              lineHeight={"normal"}
                            >
                              Upgrade
                            </Text>
                          )}
                        </Box>
                      )}
                      {!asset.stripe && asset.title === "Primary" && (
                        
                        <Box
                          display={"flex"}
                          // padding={"3px 5px"}
                          justifyContent={"center"}
                          alignItems={"center"}
                          // gap={"6px"}
                          alignSelf={"stretch"}
                          borderRadius={"5px"}
                          border={"1px solid #00A2D4"}
                          bg={"#FFF"}
                          cursor={"pointer"}
                        >
                          <Text
                            color={"#00A2D4"}
                            fontFamily={"Nunito"}
                            fontSize={"10px"}
                            fontStyle={"normal"}
                            fontWeight={"700"}
                            lineHeight={"normal"}
                          >
                            Ineligible
                          </Text>
                          <Tooltip
                            label="You already have an FHSA, making you ineligible for a Primary Residence. Please remove the FHSA if you already have a primary residence."
                            placement="right"
                            hasArrow
                          >
                            <span>
                              <Icon
                                as={InputBoxTitleBubbleTip}
                                cursor={"pointer"}
                                w={"9px"}
                                h={"9px"}
                              />
                            </span>
                          </Tooltip>
                        </Box>
                      )}
                    </Box>
                  ))}
                </Box>
              </>
            }
          />
        </VStack>
      )}
      {isMobile && (
        <VStack alignItems={"flex-start"} width={"100%"} mt={-2}>
          <GlobalBodyPage
            Heading="Real Estate"
            mainframeContent={
              <>
                <StripeUpgradeModal
                  isOpen={showStripeModal}
                  closeModal={UpgradeStripeModal}
                />
                <Box
                  display={"flex"}
                  width={"100%"}
                  padding={"10px"}
                  flexDirection={"row"}
                  justifyContent={"center"}
                  alignItems={"flex-start"}
                  alignContent={"flex-start"}
                  rowGap={"10px"}
                  gap={"5px"}
                  alignSelf={"stretch"}
                  flexWrap={"wrap"}
                  flex={"1 0 auto"}
                  borderRadius={"10px"}
                  border={"1px solid var(--Optiml---Vibrant-Sky-Blue, #00A2D4)"}
                  bg={"#FFF"}
                >
                  <DeleteRealEstateModal />
                  {data.length > 0 && (
                    <TableComponent
                      columns={columns}
                      data={data}
                      editField={editField}
                      onDeleteRow={onDeleteRow}
                      rawData={realEstateArray}
                    />
                  )}
                  {/* Assets Box */}
                  {Assets.map((asset) => (
                    <Box
                      display={"flex"}
                      minH={"121px"}
                      padding={"10px"}
                      flexDirection={"column"}
                      alignItems={"center"}
                      gap={"5px"}
                      borderRadius={"10px"}
                      border={"1px solid #0093D0"}
                      bg={"#F3FAFF"}
                      boxShadow={"0px 4px 4px 0px #00A2D4"}
                    >
                      <Box
                        display={"flex"}
                        justifyContent={"flex-end"}
                        alignItems={"flex-start"}
                        gap={"10px"}
                        alignSelf={"stretch"}
                      >
                        <Tooltip
                          label="Choose which real estate asset to add"
                          placement="right"
                          hasArrow
                        >
                          <span>
                            <Icon
                              as={InputBoxTitleBubbleTip}
                              cursor={"pointer"}
                              w={"9px"}
                              h={"9px"}
                            />
                          </span>
                        </Tooltip>
                      </Box>
                      <Box
                        display={"flex"}
                        justifyContent={"center"}
                        alignItems={"center"}
                        w={"40px"}
                        h={"40px"}
                      >
                        <Icon as={asset.icon} />
                      </Box>
                      <Box
                        display={"flex"}
                        width={"105px"}
                        height={"17px"}
                        flexDirection={"column"}
                        justifyContent={"center"}
                      >
                        <Text
                          color={"#00466D"}
                          fontFamily={"Nunito"}
                          fontSize={"13px"}
                          fontStyle={"normal"}
                          fontWeight={"700"}
                          lineHeight={"normal"}
                          textAlign={"center"}
                        >
                          {asset.title}
                        </Text>
                      </Box>

                      {/* Add Button Box */}
                      {asset.stripe && (
                        <Link href={asset.path}>
                          <Box
                            display={"flex"}
                            padding={"3px 20px"}
                            justifyContent={"center"}
                            alignItems={"center"}
                            gap={"6px"}
                            alignSelf={"stretch"}
                            borderRadius={"5px"}
                            border={"1px solid #00A2D4"}
                            bg={"#FFF"}
                            cursor={"pointer"}
                          >
                            <Icon as={AddAssetsIcon} w={"12px"} h={"12px"} />
                            <Text
                              color={"#00A2D4"}
                              fontFamily={"Nunito"}
                              fontSize={"10px"}
                              fontStyle={"normal"}
                              fontWeight={"700"}
                              lineHeight={"normal"}
                            >
                              {asset.title === "Primary" &&
                              userPrimaryResidenceInfo?.length > 0
                                ? "Edit"
                                : "Add"}
                            </Text>
                          </Box>
                        </Link>
                      )}
                      {!asset.stripe && asset.title != "Primary" && (
                        <Box
                          display={"flex"}
                          padding={"3px 20px"}
                          justifyContent={"center"}
                          alignItems={"center"}
                          gap={"6px"}
                          alignSelf={"stretch"}
                          borderRadius={"5px"}
                          border={"1px solid #00A2D4"}
                          bg={"#FFF"}
                          cursor={"pointer"}
                          onClick={UpgradeStripeModal}
                        >
                          {/* <Icon as={AddAssetsIcon} w={"12px"} h={"12px"} /> */}
                          <Text
                            color={"#00A2D4"}
                            fontFamily={"Nunito"}
                            fontSize={"10px"}
                            fontStyle={"normal"}
                            fontWeight={"700"}
                            lineHeight={"normal"}
                          >
                            Upgrade
                          </Text>
                        </Box>
                      )}
                      {!asset.stripe && asset.title === "Primary" && (
                        <Box
                          display={"flex"}
                          padding={"3px 10px"}
                          justifyContent={"center"}
                          alignItems={"center"}
                          gap={"6px"}
                          alignSelf={"stretch"}
                          borderRadius={"5px"}
                          border={"1px solid #00A2D4"}
                          bg={"#FFF"}
                          cursor={"pointer"}
                        >
                          <Text
                            color={"#00A2D4"}
                            fontFamily={"Nunito"}
                            fontSize={"10px"}
                            fontStyle={"normal"}
                            fontWeight={"700"}
                            lineHeight={"normal"}
                          >
                            Ineligible
                          </Text>
                          <Tooltip
                            label="You already have an FHSA, making you ineligible for a Primary Residence. Please remove the FHSA if you already have a primary residence."
                            placement="right"
                            hasArrow
                          >
                            <span>
                              <Icon
                                as={InputBoxTitleBubbleTip}
                                cursor={"pointer"}
                                w={"9px"}
                                h={"9px"}
                              />
                            </span>
                          </Tooltip>
                        </Box>
                      )}
                    </Box>
                  ))}
                </Box>
              </>
            }
          />
        </VStack>
      )}
    </Flex>
  );
});

export default RealEstatePage;
