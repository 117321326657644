import { Box, Button, Flex, Text, Icon, HStack, useMediaQuery } from "@chakra-ui/react";
import React, { useContext, useEffect, useRef, useState } from "react";
import { useStore } from "../store";
import Link from "./Link";
import SidebarPageButtons from "./SidebarPageButtons";
import { PlayIcon, UpdateIcon } from "./CustomIcons";
import DropDownButton from "./InputFields/dropDownButton";
import { SpouseContext } from "./SpouseContext";
import OltreChatBubble from "./OltreChatBubble";

function GlobalContainerAfterOptimizedMobile({
  Heading,
  MainContainerHeading,
  item,
  currentPage,
  mainframeContent,
  link,
  setCurrentType,
  notShowChatBubble,
}) {
  const setShowNewOptimization = useStore(
    (store) => store.setShowNewOptimization
  );
  const setMobileSidebarOpen = useStore((store) => store.setMobileSidebarOpen);
  const contentRef = useRef(null);
  const userOptiData = useStore((store) => store.userOptiData);

  const [filterPage, setFilterPage] = useState(currentPage);
  const [portfolio, setPortfolio] = useState("joint-portfolio");

  const userInfo = useStore((store) => store.userInfo);

  const { spouseStatus, setSpouseStatus } = useContext(SpouseContext);

  const handleClickUpdate = () => {
    setShowNewOptimization(true);
    setMobileSidebarOpen(false);
    window.history.pushState({}, "", link);
    const navEvent = new PopStateEvent("popstate");
    window.dispatchEvent(navEvent);
  };

  const [isLargerThan1200] = useMediaQuery(
    "(min-width: 500px) and (max-width: 1250px)"
  );

  const [isLargerThan500] = useMediaQuery("(min-width: 500px)");
  const isMobile = !isLargerThan500;

  useEffect(() => {
    if (contentRef.current) {
      contentRef.current.filterTable(filterPage); // Use the latest filterPage state
    }
    setCurrentType(filterPage);
  }, [filterPage]);

  useEffect(() => {
    if (contentRef.current) {
      contentRef.current.spouseDataChange(portfolio); // Use the latest filterPage state
    }
  }, [portfolio]);

  return (
    <Flex
      display="flex"
      padding={isLargerThan1200 ? "50px 50px" : "15px"}
      flexDirection={"column"}
      alignItems={"flex-start"}
      paddingTop={"15px"}
      gap={"10px"}
      flex={"1 0 auto"}
      alignSelf={"stretch"}
      bg={"#F3FAFF"}
      width={"inherit"}
      minH={"87dvh"}
      paddingBottom={"40px"}
    >
      {/* Flex container for heading and mainframe box */}

      {/* mainframe box */}
      <Box
        display={"flex"}
        padding={"20px 15px"}
        flexDirection={"column"}
        alignItems={"flex-start"}
        gap={"5px"}
        alignSelf={"stretch"}
        borderRadius={"10px"}
        border={"1px solid #00A2D4"}
        bg={"#fff"}
        minH={"inherit"}
        paddingBottom={"inherit"}
      >
        {MainContainerHeading && (
          <Box width={"100%"} display={"flex"} flexDirection={"row"} gap={"5px"}>
            <Text
              color={"#00466D"}
              fontFamily={"Nunito"}
              fontSize={"24px"}
              fontStyle={"normal"}
              fontWeight={"700"}
              lineHeight={"normal"}
            >
              {portfolio === "joint-portfolio" && spouseStatus
                ? "Combined"
                : portfolio === "spouse-two-portfolio"
                ? `${userInfo.spouseFirstName} ${userInfo.spouseLastName}`
                : `${userInfo.FirstName} ${userInfo.LastName}`}
            </Text>

            <HStack>
             
              {spouseStatus && (
                <DropDownButton
                  placeholder={"Combined"}
                  value={portfolio}
                  dropDownItems={[
                    { key: "joint-portfolio", value: "Combined" },
                    {
                      key: "spouse-one-portfolio",
                      value: `${userInfo.FirstName} ${userInfo.LastName}`,
                    },
                    {
                      key: "spouse-two-portfolio",
                      value: `${userInfo.spouseFirstName} ${userInfo.spouseLastName}`,
                    },
                  ]}
                  onChange={(key) => setPortfolio(key)}
                  customWidth={isLargerThan1200 ? "100%" : ""}
                  MenuCustomWidth={"100%"}
                />
              )}
            </HStack>
          </Box>
        )}
        {/* main container box */}
        <Box
          display={"flex"}
          padding={"20px 15px"}
          flexDirection={"column"}
          alignItems={"flex-start"}
          gap={"20px"}
          alignSelf={"stretch"}
          borderRadius={"10px"}
          border={"1px solid #00A2D4"}
          bg={"#fff"}
          
        >
          {Heading && (
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              alignSelf="stretch"
            >
              <HStack spacing={4}>
                <Text
                  color="#00A2D4"
                  fontFamily="Nunito"
                  fontSize="20px"
                  fontStyle="normal"
                  fontWeight="700"
                  lineHeight="normal"
                >
                  {Heading}
                </Text>
                {link && (
                  <Button
                    gap={"10px"}
                    border={"1px solid #00A2D4"}
                    background={"#00A2D4"}
                    onClick={handleClickUpdate}
                  >
                    <Icon
                      as={UpdateIcon}
                      svgwidth={"15px"}
                      svgheight={"15px"}
                    />
                    <Text
                      color="#FFF"
                      fontFamily="Nunito"
                      fontSize="12px"
                      fontStyle="normal"
                      fontWeight="600"
                      lineHeight="normal"
                    >
                      Update
                    </Text>
                  </Button>
                )}
              </HStack>
            </Box>
          )}

          {React.cloneElement(mainframeContent, {
            ref: contentRef,
            type: filterPage,
            portfolio: portfolio,
          })}
        </Box>
      </Box>
      {!notShowChatBubble &&
      <OltreChatBubble />
      }
    </Flex>
  );
}

export default GlobalContainerAfterOptimizedMobile;
