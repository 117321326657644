import { useAuth0 } from "@auth0/auth0-react";
import {
  Box,
  Button,
  ButtonGroup,
  Checkbox,
  CheckboxGroup,
  Icon,
  Modal,
  ModalContent,
  ModalOverlay,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverFooter,
  PopoverHeader,
  PopoverTrigger,
  Text,
  useDisclosure,
  useMediaQuery,
} from "@chakra-ui/react";
import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import optiNoise from "../Sounds/optiNoise.mp3";
import { useStore } from "../store";
import {
  CloseIcon,
  MaxSpendGraphIcon,
  MaxValueGraphIcon,
  PlayIcon,
  SetValueGraphIcon,
} from "./CustomIcons";
import "./LifeStrategy.css";
import Link from "./Link";
import { SpouseContext } from "./SpouseContext";
import NormalInputButton from "./InputFields/normalInputButton";
import VideoModal from "./VideoModal/VideoModal";
import { set } from "lodash";

function LifeStrategy() {
  const { getAccessTokenSilently, user } = useAuth0();

  const axiosInstance = axios.create();
  async function getToken() {
    // Replace this with your Auth0 token retrieval logic
    const token = await getAccessTokenSilently({
      audience:
        "https://os2lsiwff43wfpb5ggyawt6qri0mgcro.lambda-url.us-east-2.on.aws/",
      scope: "write:optimization",
    });
    return token;
  }

  // Add a request interceptor
  axiosInstance.interceptors.request.use(
    async (config) => {
      const token = await getToken();
      config.headers.Authorization = `Bearer ${token}`;
      return config;
    },
    (error) => {
      // Do something with request error
      return Promise.reject(error);
    }
  );
  const [customValue, setCustomValue] = useState("");
  const [includeAssets, setIncludeAssets] = useState(true);
  const [includePrimaryRes, setIncludePrimaryRes] = useState(true);
  const [includeRecreationProperty, setIncludeRecreationProperty] =
    useState(true);
  const [includeIncomeProperty, setIncludeIncomeProperty] = useState(true);
  const [includeLand, setIncludeLand] = useState(true);
  const [includeBusiness, setIncludeBusiness] = useState(true);
  const [includeLifeInsurance, setIncludeLifeInsurance] = useState(true);
  const [assetWarning, setAssetWarning] = useState(false);
  const [sumAssetValue, setSumAssetValue] = useState(0);
  const [currentAge, setCurrentAge] = useState("");
  const { spouseStatus, setSpouseStatus } = useContext(SpouseContext);
  const userInfo = useStore((store) => store.userInfo);
  const {
    FirstName: firstName,
    LastName: lastName,
    DateOfBirth,
    spouseFirstName,
    spouseLastName,
    runAge,
    spouseRunAge,
  } = userInfo;

  // const userInfo = useStore((store) => store.userInfo);
  const userExpensesInfo = useStore((store) => store.userExpensesInfo);
  const userSetValueInfo = useStore((store) => store.userSetValueInfo);
  const setUserSetValueInfo = useStore((store) => store.setUserSetValueInfo);
  const setUserExpensesInfo = useStore((store) => store.setUserExpensesInfo);
  const userOtherAssetsInfo = useStore((store) => store.userOtherAssetsInfo);
  const userPrimaryResidenceInfo = useStore(
    (store) => store.userPrimaryResidenceInfo
  );
  const userCottageInfo = useStore((store) => store.userCottageInfo);
  const userPropertiesInfo = useStore((store) => store.userPropertiesInfo);
  const userLandInfo = useStore((store) => store.userLandInfo);
  const userBusinessInfo = useStore((store) => store.userBusinessInfo);
  const userCompanyInfo = useStore((store) => store.userCompanyInfo);
  const userLifeInsuranceInfo = useStore(
    (store) => store.userLifeInsuranceInfo
  );

  const [isVideoModalOpen, setIsVideoModalOpen] = useState(false);
  const [videoName, setVideoName] = useState("");
  const [video, setVideo] = useState("");

  const openVideoModal = (videName, video) => {
    setVideoName(videName);
    setVideo(video);
    setIsVideoModalOpen(true);
  };

  const closeVideoModal = () => {
    setIsVideoModalOpen(false);
  };

  const [checkboxes, setCheckboxes] = useState([]);

  const handleCheckboxChange = (checkboxId, checkedTest) => {
    setCheckboxes((prevCheckboxes) =>
      prevCheckboxes.map((checkbox) =>
        checkbox.id === checkboxId
          ? { ...checkbox, checked: !checkbox.checked }
          : checkbox
      )
    );
    if (checkboxId === "other-assets-id") {
      setIncludeAssets(!checkedTest);
    } else if (checkboxId === "primary-residence-id") {
      setIncludePrimaryRes(!checkedTest);
    } else if (checkboxId === "recreation-property-id") {
      setIncludeRecreationProperty(!checkedTest);
    } else if (checkboxId === "income-property-id") {
      setIncludeIncomeProperty(!checkedTest);
    } else if (checkboxId === "land-id") {
      setIncludeLand(!checkedTest);
    }else if (checkboxId === "life-insurance-id") {
      setIncludeLifeInsurance(!checkedTest);
    } else if (checkboxId === "business-id") {
      setIncludeBusiness(!checkedTest);
    }
  };

  const calculateProratedGrowth = (assetGrowthRate) => {
    const currentDate = new Date();
    const currentDayOfYear =
      Math.ceil(
        (currentDate - new Date(currentDate.getFullYear(), 0, 1)) / 86400000
      ) + 1;
    const isLeapYear = currentDate.getFullYear() % 4 === 0;
    const daysInYear = isLeapYear ? 366 : 365;
    const remainingDays = daysInYear - currentDayOfYear;
    const calculatedProratedGrowth =
      assetGrowthRate * (remainingDays / daysInYear);

    return calculatedProratedGrowth;
  };

  const updateCheckBoxWarningValue = () => {
    let sumAllAssetsEOL = 0;
    if (
      includeAssets &&
      userOtherAssetsInfo &&
      userOtherAssetsInfo?.length > 0
    ) {
      const sum = userOtherAssetsInfo?.reduce((accumulator, asset) => {
        let grossedUpValue = asset.Other_Asset_Value;
        for (let i = 0; i < runAge - getAge(DateOfBirth); i++) {
          if (i == 0) {
            grossedUpValue =
              grossedUpValue *
              (1 +
                calculateProratedGrowth(asset?.Other_Asset_Appreciation / 100));
          } else {
            grossedUpValue =
              grossedUpValue * (1 + asset?.Other_Asset_Appreciation / 100);
          }
        }
        sumAllAssetsEOL += grossedUpValue;
        return accumulator + asset.Other_Asset_Value;
      }, 0);
    }
    if (
      includePrimaryRes &&
      userPrimaryResidenceInfo &&
      userPrimaryResidenceInfo?.length > 0 &&
      userPrimaryResidenceInfo[0]?.Do_Primary_Res_Sell == "No"
    ) {
      const sum2 = userPrimaryResidenceInfo?.reduce(
        (accumulator, residence) => {
          let grossedUpValue = residence.value;
          for (let i = 0; i < runAge - getAge(DateOfBirth); i++) {
            if (i == 0) {
              grossedUpValue =
                grossedUpValue *
                (1 +
                  calculateProratedGrowth(residence?.Primary_Res_Growth / 100));
            } else {
              grossedUpValue =
                grossedUpValue * (1 + residence?.Primary_Res_Growth / 100);
            }
          }
          sumAllAssetsEOL += grossedUpValue;
          return accumulator + residence.value;
        },
        0
      );
    }
    if (
      includeRecreationProperty &&
      userCottageInfo &&
      userCottageInfo?.length > 0 &&
      userCottageInfo[0]?.Do_Cottage_Sell == "No"
    ) {
      const sum3 = userCottageInfo?.reduce((accumulator, residence) => {
        // console.log("Including Cottage");
        if (residence?.Do_Cottage_Sell == "No") {
          let grossedUpValue = residence.value;
          for (let i = 0; i < runAge - getAge(DateOfBirth); i++) {
            if (i == 0) {
              grossedUpValue =
                grossedUpValue *
                (1 + calculateProratedGrowth(residence?.Cottage_Growth / 100));
            } else {
              grossedUpValue =
                grossedUpValue * (1 + residence?.Cottage_Growth / 100);
            }
          }
          sumAllAssetsEOL += grossedUpValue;
          return accumulator + residence.value;
        } else {
          return accumulator;
        }
      }, 0);
    }
    if (
      includeIncomeProperty &&
      userPropertiesInfo &&
      userPropertiesInfo?.length > 0
    ) {
      const sum4 = userPropertiesInfo?.reduce((accumulator, residence) => {
        if (residence?.Do_Sell_Income_Property == "No") {
          let grossedUpValue =
            residence.value;
          for (let i = 0; i < runAge - getAge(DateOfBirth); i++) {
            if (i == 0) {
              grossedUpValue =
                grossedUpValue *
                (1 +
                  calculateProratedGrowth(
                    residence?.Income_Property_Growth / 100
                  ));
            } else {
              grossedUpValue =
                grossedUpValue * (1 + residence?.Income_Property_Growth / 100);
            }
          }
          sumAllAssetsEOL += grossedUpValue;
          return (
            accumulator +
            residence.value
          );
        } else {
          return accumulator;
        }
      }, 0);
    }
    if (
      includeLand &&
      userLandInfo &&
      userLandInfo?.length > 0
    ) {
      const sum4 = userLandInfo?.reduce((accumulator, residence) => {
        if (residence?.sellLandYesNo == "No") {
          let grossedUpValue =
            residence.value;
          for (let i = 0; i < runAge - getAge(DateOfBirth); i++) {
            if (i == 0) {
              grossedUpValue =
                grossedUpValue *
                (1 +
                  calculateProratedGrowth(
                    residence?.landGrowth / 100
                  ));
            } else {
              grossedUpValue =
                grossedUpValue * (1 + residence?.landGrowth / 100);
            }
          }
          sumAllAssetsEOL += grossedUpValue;
          return (
            accumulator +
            residence.value
          );
        } else {
          return accumulator;
        }
      }, 0);
    }
    if (includeBusiness && userCompanyInfo && userCompanyInfo?.length > 0) {
      const sum6 = userCompanyInfo?.reduce((accumulator, business) => {
        if (business?.businessPlansToSell == "No") {
          let grossedUpValue =
            business.value;
          for (let i = 0; i < runAge - getAge(DateOfBirth); i++) {
            if (i == 0) {
              grossedUpValue =
                grossedUpValue *
                (1 +
                  calculateProratedGrowth(business?.businessGrowthRate / 100));
            } else {
              grossedUpValue =
                grossedUpValue * (1 + business?.businessGrowthRate / 100);
            }
          }
          sumAllAssetsEOL += grossedUpValue;
          return (
            accumulator + business.value
          );
        } else {
          return accumulator;
        }
      }, 0);
    }
    if (
      includeLifeInsurance &&
      userLifeInsuranceInfo &&
      userLifeInsuranceInfo?.length > 0
    ) {
      const sum5 = userLifeInsuranceInfo?.reduce((accumulator, insurance) => {
        if (
          !insurance?.Life_Insurance_End_Date ||
          Number(insurance?.Life_Insurance_End_Date) >= userInfo?.runAge
        ) {
          sumAllAssetsEOL += insurance.value;
          return accumulator + insurance.value;
        } else {
          return accumulator;
        }
      }, 0);
    }
    // console.log(sumAllAssetsEOL);
    setSumAssetValue(sumAllAssetsEOL);
    if (sumAllAssetsEOL > customValue && customValue != 0) {
      setAssetWarning(true);
    } else {
      setAssetWarning(false);
    }
  };

  useEffect(() => {
    updateCheckBoxWarningValue();
  }, [
    customValue,
    includeAssets,
    includePrimaryRes,
    includeRecreationProperty,
    includeIncomeProperty,
    includeLand,
    includeBusiness,
    includeLifeInsurance,
  ]);

  useEffect(() => {
    const updatedCheckboxArray = [];
    if (userOtherAssetsInfo && userOtherAssetsInfo?.length > 0) {
      const sum = userOtherAssetsInfo?.reduce((accumulator, asset) => {
        return accumulator + asset.Other_Asset_Value;
      }, 0);
      const otherAssetCheckbox = {
        id: "other-assets-id",
        label: "Other Assets",
        checked: true,
        value: currencyFormat(sum),
      };
      updatedCheckboxArray.push(otherAssetCheckbox);
    }
    if (
      userPrimaryResidenceInfo &&
      userPrimaryResidenceInfo?.length > 0 &&
      userPrimaryResidenceInfo[0]?.Do_Primary_Res_Sell == "No"
    ) {
      const sum2 = userPrimaryResidenceInfo?.reduce(
        (accumulator, residence) => {
          return accumulator + residence.value;
        },
        0
      );
      if (sum2 > 0) {
        const primaryResidenceCheckbox = {
          id: "primary-residence-id",
          label: "Primary Residence",
          checked: true,
          value: currencyFormat(sum2),
        };
        updatedCheckboxArray.push(primaryResidenceCheckbox);
      }
    }
    if (
      userCottageInfo &&
      userCottageInfo?.length > 0 &&
      userCottageInfo[0]?.Do_Cottage_Sell == "No"
    ) {
      const sum3 = userCottageInfo?.reduce((accumulator, residence) => {
        if (residence?.Do_Cottage_Sell == "No") {
          return accumulator + residence.value;
        } else {
          return accumulator;
        }
      }, 0);
      if (sum3 > 0) {
        const recreationPropertyCheckbox = {
          id: "recreation-property-id",
          label: "Recreation Properties",
          checked: true,
          value: currencyFormat(sum3),
        };
        updatedCheckboxArray.push(recreationPropertyCheckbox);
      }
    }
    if (userPropertiesInfo && userPropertiesInfo?.length > 0) {
      const sum4 = userPropertiesInfo?.reduce((accumulator, residence) => {
        if (residence?.Do_Sell_Income_Property == "No") {
          return (
            accumulator +
            residence.value
          );
        } else {
          return accumulator;
        }
      }, 0);
      if (sum4 > 0) {
        const incomePropertyCheckbox = {
          id: "income-property-id",
          label: "Income Properties",
          checked: true,
          value: currencyFormat(sum4),
        };
        updatedCheckboxArray.push(incomePropertyCheckbox);
      }
    }
    if (userLandInfo && userLandInfo?.length > 0) {
      const sum4 = userLandInfo?.reduce((accumulator, residence) => {
        if (residence?.sellLandYesNo == "No") {
          return (
            accumulator +
            residence.value
          );
        } else {
          return accumulator;
        }
      }, 0);
      if (sum4 > 0) {
        const landCheckbox = {
          id: "land-id",
          label: "Land",
          checked: true,
          value: currencyFormat(sum4),
        };
        updatedCheckboxArray.push(landCheckbox);
      }
    }
    if (userCompanyInfo && userCompanyInfo?.length > 0) {
      const sum6 = userCompanyInfo?.reduce((accumulator, business) => {
        if (business?.businessPlansToSell == "No") {
          return (
            accumulator + business.value
          );
        } else {
          return accumulator;
        }
      }, 0);
      if (sum6 > 0) {
        const businessCheckbox = {
          id: "business-id",
          label: "Business",
          checked: true,
          value: currencyFormat(sum6),
        };
        updatedCheckboxArray.push(businessCheckbox);
      }
    }
    if (userLifeInsuranceInfo && userLifeInsuranceInfo?.length > 0) {
      const sum5 = userLifeInsuranceInfo?.reduce((accumulator, insurance) => {
        if (
          !insurance?.Life_Insurance_End_Date ||
          Number(insurance?.Life_Insurance_End_Date) >= userInfo?.runAge
        ) {
          return accumulator + insurance.value;
        } else {
          return null;
        }
      }, 0);
      // console.log(userLifeInsuranceInfo);
      if (sum5 > 0) {
        const lifeInsuranceCheckbox = {
          id: "life-insurance-id",
          label: "Life Insurance",
          checked: true,
          value: currencyFormat(sum5),
        };
        updatedCheckboxArray.push(lifeInsuranceCheckbox);
      }
    }
    setCheckboxes(updatedCheckboxArray);
    updateCheckBoxWarningValue();
  }, [
    userOtherAssetsInfo,
    userPrimaryResidenceInfo,
    userCottageInfo,
    userPropertiesInfo,
    userLandInfo,
    userCompanyInfo,
    userLifeInsuranceInfo,
  ]);

  useEffect(() => {
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 100);
  }, []);

  const GrowthSelect = () => {
    const runAgeList = [];
    for (let i = 0.1; i <= 15; i += 0.1) {
      runAgeList.push(
        <option value={Math.round(i * 100) / 10000}>
          {Math.round(i * 100) / 100}%
        </option>
      );
    }
    return runAgeList;
  };

  useEffect(() => {
    if (
      userInfo?.runAge - getAge(userInfo?.DateOfBirth) !=
      userExpensesInfo?.expenseArray.length
    ) {
      calculateAnnualGrowthArray(
        userExpensesInfo?.total,
        userExpensesInfo?.expenseCPI,
        userInfo?.runAge - getAge(userInfo?.DateOfBirth),
        "primary",
        userExpensesInfo?.growth
      );
    }
  }, []);

  function calculateAnnualGrowthArray(
    initialValue,
    percentage,
    years,
    user,
    expenseGrowthRate
  ) {
    console.log("Mismatched List, rebuilding...");
    if (typeof initialValue === "string") {
      initialValue = moneyToNumber(initialValue);
    }
    const growthArray = [initialValue];

    if (expenseGrowthRate == "Consumer Expense Index") {
      let currentExpenses = initialValue;
      let userAge = getAge(currentAge);

      for (let year = 1; year < years; year++) {
        // Start from year 1 as initial expenses are already in the array
        let ageFactor = 1;

        if (userAge < 35) {
          ageFactor += (92286 - 75494) / (75494 * 9); // Average annual growth until age 35
        } else if (userAge >= 35 && userAge < 45) {
          ageFactor += (107072 - 92286) / (92286 * 10); // Average annual growth from age 35 to 45
        } else if (userAge >= 45 && userAge < 55) {
          ageFactor += (90092 - 107072) / (107072 * 10); // Average annual decrease from age 45 to 55
        } else if (userAge >= 55 && userAge < 65) {
          ageFactor += (63789 - 90092) / (90092 * 10); // Average annual decrease from age 55 to 65
        } else if (userAge >= 65) {
          ageFactor -= (90092 - 63789) / (90092 * 10); // Constant annual decrease after 65
        }

        // Apply the age factor for this year's expenses
        currentExpenses *= ageFactor;
        // Apply the general inflation or expense growth percentage
        currentExpenses *= 1 + percentage / 100;
        growthArray.push(parseFloat(currentExpenses.toFixed(2))); // Rounded to two decimal places

        userAge++;
      }
    } else {
      for (let i = 1; i < years; i++) {
        const currentValue = growthArray[i - 1] * (1 + percentage / 100);
        growthArray.push(parseInt(currentValue.toFixed(2)));
      }
    }

    setUserExpensesInfo({
      ...userExpensesInfo,
      expenseArray: growthArray,
    });

    return growthArray;
  }

  const currencyFormat = (money) => {
    if (money == "") {
      return "";
    } else {
      const intTotal = Math.round(Math.round(money * 100) / 100);
      const currencyCad = new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "USD",
        minimumFractionDigits: 0,
      }).format(intTotal);
      return currencyCad;
    }
  };

  const moneyToNumber = (value) => {
    let stringValue = String(value); // Convert to string
    let numValue = stringValue.replace(/\D/g, "");
    numValue = Number(numValue);

    return numValue;
  };

  function getAge(birthday) {
    const currentDate = new Date();
    // Get the current date
    // const currentDate = new Date();

    // Convert the birthday string into a Date object
    const birthDate = new Date(birthday);

    // Calculate the age in years
    let age = currentDate.getFullYear() - birthDate.getFullYear();

    // Decrement the age if the current month and day are before the birth month and day
    if (
      currentDate.getMonth() < birthDate.getMonth() ||
      (currentDate.getMonth() === birthDate.getMonth() &&
        currentDate.getDate() <= birthDate.getDate())
    ) {
      age -= 1;
    }

    return age;
  }

  document.body.style.overflowY = "scroll";

  const { isOpen, onOpen, onClose } = useDisclosure();

  const [isPopoverOpen, setIsPopoverOpen] = useState(false);

  const CheckboxItem = (
    { label, value, isChecked, onChange } // Added onChange as a prop
  ) => (
    <Box
      display={"flex"}
      alignItems="center"
      alignContent={"center"}
      justifyContent="space-between"
      width="100%"
    >
      <Checkbox isChecked={isChecked} onChange={onChange}>
        {label}
      </Checkbox>{" "}
      {/* Updated to use onChange */}
      <Text>{value}</Text>
    </Box>
  );
  const [isLargerThan768] = useMediaQuery("(min-width: 1000px)");
  const isMobile = !isLargerThan768;

  return (
    <>
      <VideoModal
        isOpen={isVideoModalOpen}
        closeModal={closeVideoModal}
        videoName={video}
        title={videoName}
      />
      {isMobile && (
        <>
          <Box
            display="flex"
            padding="20px 15px"
            flexDirection="column"
            alignItems="flex-start"
            gap="10px"
            alignSelf="stretch"
            borderRadius="10px"
            border="1px solid var(--Optiml---Vibrant-Sky-Blue, #00A2D4)"
            bg="#FFF"
          >
            <div className="life-strat-max-value-box">
              <Box
                display={"flex"}
                justifyContent={"space-between"}
                alignItems={"center"}
                alignSelf={"stretch"}
                flexDirection={"row"}
              >
                <Text
                  color={"#00A2D4"}
                  fontFamily={"Nunito"}
                  fontSize={"20px"}
                  fontStyle={"normal"}
                  fontWeight={"700"}
                  lineHeight={"normal"}
                >
                  Max Value
                </Text>
                <Box
                  cursor={"pointer"}
                  onClick={() => openVideoModal("Max Value", "Max_Value.mp4")}
                >
                  <Icon as={PlayIcon} svgwidth={"20px"} svgheight={"20px"} />
                </Box>
              </Box>
              {/* <div class="questionBubbleCircle" data-tooltip-id="my-tooltip" data-tooltip-content="Enter information here">
                                            <FontAwesomeIcon className="question-icon" icon={faQuestion} size='xl' />
                                            <ReactTooltip id="my-tooltip" className="tooltip-popup" />
                                        </div> */}

              <Box
                display={"flex"}
                padding={"3px"}
                justifyContent={"center"}
                alignItems={"center"}
                borderRadius={"2px"}
                bg={"#BEEBFF"}
              >
                <Icon
                  as={MaxValueGraphIcon}
                  svgwidth={"100%"}
                  svgheight={"100%"}
                  width={"100%"}
                  height={"100%"}
                />
              </Box>

              <Box
                display={"flex"}
                alignItems={"center"}
                justifyContent={"flex-start"}
                width={"100%"}
              >
                <Text
                  flex={"1 0 0"}
                  color={"#00A2D4"}
                  fontFamily={"Nunito"}
                  fontSize={"14px"}
                  fontStyle={"normal"}
                  fontWeight={"700"}
                  textAlign={"start"}
                >
                  Maximize & Preserve
                </Text>
              </Box>
              <Box
                display={"flex"}
                alignItems={"center"}
                justifyContent={"flex-start"}
                width={"100%"}
                marginBottom={"40px"}
              >
                <Text
                  color={"#00466D"}
                  fontFamily={"Nunito"}
                  fontSize={"12px"}
                  fontStyle={"normal"}
                  fontWeight={"600"}
                  lineHeight={"normal"}
                >
                  This strategy is designed to help you grow the value of your
                  estate to its fullest potential while ensuring sufficient
                  annual income to cover living expenses and minimizing tax
                  liabilities. It's ideal if you are looking to leave a
                  substantial legacy without compromising your quality of life.
                </Text>
              </Box>

              <Link href={`/inputsummary?strategy=${"Max Value"}`}>
                <Box
                  display={"flex"}
                  height={"50px"}
                  flexDirection={"column"}
                  justifyContent={"flex-end"}
                  alignItems={"center"}
                >
                  <button type="submit" className="max-value-button">
                    Select
                  </button>
                </Box>
              </Link>
            </div>

            <div className="life-strat-set-value-box">
              <Box
                display={"flex"}
                justifyContent={"space-between"}
                alignItems={"center"}
                alignSelf={"stretch"}
                flexDirection={"row"}
              >
                <Text
                  color={"#00A2D4"}
                  fontFamily={"Nunito"}
                  fontSize={"20px"}
                  fontStyle={"normal"}
                  fontWeight={"700"}
                  lineHeight={"normal"}
                >
                  Set Value
                </Text>
                <Box
                  cursor={"pointer"}
                  onClick={() => openVideoModal("Set Value", "Set_value.mp4")}
                >
                  <Icon as={PlayIcon} svgwidth={"20px"} svgheight={"20px"} />
                </Box>
              </Box>
              <Box
                display={"flex"}
                padding={"3px"}
                justifyContent={"center"}
                alignItems={"center"}
                borderRadius={"2px"}
                bg={"#BEEBFF"}
              >
                <Icon
                  as={SetValueGraphIcon}
                  svgwidth={"100%"}
                  svgheight={"100%"}
                  width={"70%"}
                  height={"70%"}
                />
              </Box>

              <Box
                display={"flex"}
                alignItems={"center"}
                justifyContent={"flex-start"}
                width={"100%"}
              >
                <Text
                  flex={"1 0 0"}
                  color={"#00A2D4"}
                  fontFamily={"Nunito"}
                  fontSize={"14px"}
                  fontStyle={"normal"}
                  fontWeight={"700"}
                  textAlign={"start"}
                >
                  Goal Oriented Wealth
                </Text>
              </Box>
              <Box
                display={"flex"}
                alignItems={"center"}
                justifyContent={"flex-start"}
                width={"100%"}
                marginBottom={"40px"}
              >
                <Text
                  color={"#00466D"}
                  fontFamily={"Nunito"}
                  fontSize={"12px"}
                  fontStyle={"normal"}
                  fontWeight={"600"}
                  lineHeight={"normal"}
                >
                  If you have a specific financial legacy in mind, this strategy
                  is for you. It calculates the most tax-efficient way to reach
                  your estate target while covering your annual expenses. It
                  allows for the maximum possible spending each year without
                  derailing your financial goals.
                </Text>
              </Box>
              <NormalInputButton
                Heading={"Enter your desired estate goal"}
                placeholder={"$"}
                fontSize={"14px"}
                value={customValue}
                onChange={(e) => {
                  setCustomValue(moneyToNumber(e.target.value));
                  setIsPopoverOpen(false);
                }}
                customWidth={"100%"}
                InputWidth={"100%"}
                useThousandSeparator={true}
              />
              {/* {assetWarning && (
                          <div className="EstateValueWarning">
                            Great news! Your current assets are already on track
                            to meet your desired estate goal. You can choose to
                            un-check certain assets listed below. Alternatively,
                            you can consider increasing your desired estate
                            goal. The total end of analysis value of your
                            included assets is{" "}
                            <strong>{currencyFormat(sumAssetValue)}</strong>.
                          </div>
                        )} */}
              {/* <CurrencyInput
                          className="custom-eol-amount border-1px-mischka"
                          type="text"
                          id="custome-value"
                          style={{ marginTop: "10px" }}
                          placeholder="$"
                          name="custom-value"
                          value={customValue}
                          onChange={(e) => {
                            setCustomValue(moneyToNumber(e.target.value));
                          }}
                        /> */}
              <Box
                display={"flex"}
                flexDirection={"column"}
                justifyContent={"flex-end"}
                alignItems={"center"}
              >
                <Popover
                  isOpen={isPopoverOpen}
                  placement="bottom"
                  isCentered={false}
                >
                  {checkboxes?.length >= 1 && (
                    <PopoverTrigger>
                      <Button
                        type="submit"
                        className="custom-value-button"
                        onClick={(e) => {
                          if (customValue) {
                            e.preventDefault(); // Prevent form submission or button default action
                            onOpen(); // Open the popover if customValue is false
                          } else {
                            setIsPopoverOpen(true);
                          }
                        }}
                      >
                        Select
                      </Button>
                    </PopoverTrigger>
                  )}
                  {checkboxes?.length < 1 && customValue && (
                    <Link href={`/inputsummary?strategy=${"Set Value"}`}>
                      <button
                        type="submit"
                        className="custom-value-button"
                        onClick={(e) => {
                          if (customValue) {
                            e.preventDefault(); // Prevent form submission or button default action
                            setUserSetValueInfo({
                              ...userSetValueInfo,
                              SetValue: customValue,
                              IncludeAssets: includeAssets,
                              IncludePrimaryRes: includePrimaryRes,
                              IncludeRecreationProperty:
                                includeRecreationProperty,
                              IncludeIncomeProperty: includeIncomeProperty,
                              IncludeLand: includeLand,
                              IncludeBusiness: includeBusiness,
                              IncludeLifeInsurance: includeLifeInsurance,
                            });
                          } else {
                            setIsPopoverOpen(true);
                          }
                        }}
                      >
                        Select
                      </button>
                    </Link>
                  )}
                  <PopoverContent
                    color="white"
                    bg="#00A2D4"
                    borderColor="#00A2D4"
                  >
                    <PopoverBody>
                      Please Enter a value before selecting
                    </PopoverBody>
                  </PopoverContent>
                </Popover>
                <Modal isOpen={isOpen} onClose={onclose} isCentered={true}>
                  <ModalOverlay />

                  <ModalContent
                    borderRadius={"10px"}
                    border={
                      "1px solid var(--Optiml---Vibrant-Sky-Blue, #00A2D4)"
                    }
                  >
                    <Box
                      display={"flex"}
                      width={"100%"}
                      height={"-webkit-fit-content"}
                      flexDirection={"column"}
                      alignItems={"center"}
                      gap={"10px"}
                      borderRadius={"10px"}
                      bg={"#FFF"}
                    >
                      <Box
                        display={"flex"}
                        padding={"10px 10px 0px 0px"}
                        justifyContent={"flex-end"}
                        alignItems={"flex-start"}
                        gap={"10px"}
                        alignSelf={"stretch"}
                        onClick={onClose}
                        _hover={{
                          cursor: "pointer",
                        }}
                      >
                        <Icon
                          as={CloseIcon}
                          svgwidth={"18px"}
                          svgheight={"18px"}
                        />
                      </Box>
                      <Box
                        display={"flex"}
                        padding={"25px 50px 50px 50px"}
                        flexDirection={"column"}
                        alignItems={"center"}
                        gap={"20px"}
                        borderRadius={"10px"}
                        alignSelf={"stretch"}
                        bg={"#FFF"}
                      >
                        <Text
                          color={"#00A2D4"}
                          textAlign={"center"}
                          fontFamily={"Nunito"}
                          fontSize={"20px"}
                          fontStyle={"normal"}
                          fontWeight={"700"}
                          lineHeight={"12px"}
                        >
                          Customize your estate value goal
                        </Text>
                        <Text
                          color={"#00466D"}
                          textAlign={"center"}
                          fontFamily={"Nunito"}
                          fontSize={"14px"}
                          fontStyle={"normal"}
                          fontWeight={"600"}
                          lineHeight={"16px"}
                        >
                          Your investment accounts (TFSA, RRSP, and
                          Non-Registered Accounts) are automatically included in
                          reaching your estate goal of{" "}
                          {currencyFormat(customValue)}. You have the freedom to
                          choose which, if any, of your other assets you want to
                          contribute to your estate goal, or keep them separate
                        </Text>
                        {checkboxes?.length >= 1 && customValue >= 1 && (
                          <Text
                            color={"#00A2D4"}
                            textAlign={"center"}
                            fontFamily={"Nunito"}
                            fontSize={"14px"}
                            fontStyle={"normal"}
                            fontWeight={"600"}
                            lineHeight={"12px"}
                            width={"436px"}
                            height={"18px"}
                          >
                            Include these assets in estate value
                          </Text>
                        )}
                        {customValue >= 1 && (
                          <Box
                            display={"flex"}
                            height={"64px"}
                            flexDirection={"column"}
                            justifyContent={"space-between"}
                            alignItems={"flex-start"}
                            alignSelf={"stretch"}
                            h={"auto"}
                          >
                            <CheckboxGroup colorScheme="cyan">
                              {checkboxes.map((checkbox, index) => (
                                <CheckboxItem
                                  key={checkbox.id} // Fixed: it should be checkbox.id instead of index.id
                                  label={checkbox.label}
                                  value={checkbox.value}
                                  isChecked={checkbox.checked} // Updated: passing the checked value
                                  onChange={() =>
                                    handleCheckboxChange(
                                      checkbox.id,
                                      checkbox.checked
                                    )
                                  }
                                />
                              ))}
                            </CheckboxGroup>
                          </Box>
                        )}
                        <Box
                          display={"flex"}
                          height={"43px"}
                          flexDirection={"column"}
                          justifyContent={"center"}
                          alignSelf={"stretch"}
                        >
                          <Text
                            color={"#00466D"}
                            textAlign={"center"}
                            fontFamily={"Nunito"}
                            fontSize={"12px"}
                            fontStyle={"normal"}
                            fontWeight={"600"}
                            lineHeight={"14px"}
                          >
                            <Text as="span" fontWeight={"700"}>
                              Tip:
                            </Text>{" "}
                            Excluding certain assets, like a home you don't plan
                            to sell, can provide a clearer view of your liquid
                            estate and investment growth towards your financial
                            future.
                          </Text>
                        </Box>
                        <Box
                          display={"flex"}
                          justifyContent={"flex-end"}
                          alignItems={"center"}
                          alignSelf={"stretch"}
                        >
                          <Link href={`/inputsummary?strategy=${"Set Value"}`}>
                            <Button
                              padding="8px 48px"
                              justifyContent="center"
                              alignItems="center"
                              gap="5px"
                              borderRadius="5px"
                              bg="#00A2D4"
                              onClick={() => {
                                setUserSetValueInfo({
                                  ...userSetValueInfo,
                                  SetValue: customValue,
                                  IncludeAssets: includeAssets,
                                  IncludePrimaryRes: includePrimaryRes,
                                  IncludeRecreationProperty:
                                    includeRecreationProperty,
                                  IncludeIncomeProperty: includeIncomeProperty,
                                  IncludeLand: includeLand,
                                  IncludeBusiness: includeBusiness,
                                  IncludeLifeInsurance: includeLifeInsurance,
                                });
                              }}
                            >
                              <Text
                                color="#FFF"
                                textAlign="center"
                                fontFamily="Nunito"
                                fontSize="16px"
                                fontStyle="normal"
                                fontWeight="600"
                                lineHeight="normal"
                              >
                                Next
                              </Text>
                            </Button>
                          </Link>
                        </Box>
                      </Box>
                    </Box>
                  </ModalContent>
                </Modal>
              </Box>
            </div>

            <div className="life-strat-max-spend-box">
              <Box
                display={"flex"}
                justifyContent={"space-between"}
                alignItems={"center"}
                alignSelf={"stretch"}
                flexDirection={"row"}
              >
                <Text
                  color={"#00A2D4"}
                  fontFamily={"Nunito"}
                  fontSize={"20px"}
                  fontStyle={"normal"}
                  fontWeight={"700"}
                  lineHeight={"normal"}
                >
                  Max Spend
                </Text>
                <Box
                  cursor={"pointer"}
                  onClick={() => openVideoModal("Max Spend", "Max_spend.mp4")}
                >
                  <Icon as={PlayIcon} svgwidth={"20px"} svgheight={"20px"} />
                </Box>
              </Box>
              <Box
                display={"flex"}
                padding={"3px"}
                justifyContent={"center"}
                alignItems={"center"}
                borderRadius={"2px"}
                bg={"#BEEBFF"}
              >
                <Icon
                  as={MaxSpendGraphIcon}
                  svgwidth={"100%"}
                  svgheight={"100%"}
                  width={"50%"}
                  height={"50%"}
                />
              </Box>
              <Box
                display={"flex"}
                alignItems={"center"}
                justifyContent={"flex-start"}
                width={"100%"}
              >
                <Text
                  flex={"1 0 0"}
                  color={"#00A2D4"}
                  fontFamily={"Nunito"}
                  fontSize={"14px"}
                  fontStyle={"normal"}
                  fontWeight={"700"}
                  textAlign={"start"}
                >
                  Spend It All
                </Text>
              </Box>
              <Box
                display={"flex"}
                alignItems={"center"}
                justifyContent={"flex-start"}
                width={"100%"}
                marginBottom={"40px"}
              >
                <Text
                  color={"#00466D"}
                  fontFamily={"Nunito"}
                  fontSize={"12px"}
                  fontStyle={"normal"}
                  fontWeight={"600"}
                  lineHeight={"normal"}
                >
                  If you wish to enjoy your retirement to the fullest by
                  spending all your savings, this approach ensures you can spend
                  every penny of your savings in the most tax-efficient way,
                  aiming to reduce your estate to zero by the end of your
                  analysis. Perfect for maximizing retirement enjoyment without
                  leaving unspent assets.
                </Text>
              </Box>
              <Link href={`/inputsummary?strategy=${"Max Spend"}`}>
                <button type="submit" className="max-spend-button">
                  Select
                </button>
              </Link>
            </div>
          </Box>
        </>
      )}
      {!isMobile && (
        <>
          <Box
            display="flex"
            padding="15px"
            flexDirection="row"
            alignItems="flex-start"
            gap="15px"
            alignSelf="stretch"
            borderRadius="10px"
            border="1px solid var(--Optiml---Vibrant-Sky-Blue, #00A2D4)"
            bg="#FFF"
          >
            <div className="life-strat-max-value-box">
              <Box
                display={"flex"}
                justifyContent={"space-between"}
                alignItems={"center"}
                alignSelf={"stretch"}
                flexDirection={"row"}
              >
                <Text
                  color={"#00A2D4"}
                  fontFamily={"Nunito"}
                  fontSize={"20px"}
                  fontStyle={"normal"}
                  fontWeight={"700"}
                  lineHeight={"normal"}
                >
                  Max Value
                </Text>
                <Box
                  cursor={"pointer"}
                  onClick={() => openVideoModal("Max Value", "Max_Value.mp4")}
                >
                  <Icon as={PlayIcon} svgwidth={"20px"} svgheight={"20px"} />
                </Box>
              </Box>
              {/* <div class="questionBubbleCircle" data-tooltip-id="my-tooltip" data-tooltip-content="Enter information here">
                                            <FontAwesomeIcon className="question-icon" icon={faQuestion} size='xl' />
                                            <ReactTooltip id="my-tooltip" className="tooltip-popup" />
                                        </div> */}

              <Box
                display={"flex"}
                padding={"3px"}
                justifyContent={"center"}
                alignItems={"center"}
                borderRadius={"2px"}
                bg={"#BEEBFF"}
              >
                <Icon
                  as={MaxValueGraphIcon}
                  svgwidth={"224px"}
                  svgheight={"139px"}
                />
              </Box>

              <Box
                display={"flex"}
                alignItems={"center"}
                justifyContent={"flex-start"}
                width={"100%"}
              >
                <Text
                  flex={"1 0 0"}
                  color={"#00A2D4"}
                  fontFamily={"Nunito"}
                  fontSize={"14px"}
                  fontStyle={"normal"}
                  fontWeight={"700"}
                  textAlign={"start"}
                >
                  Maximize & Preserve
                </Text>
              </Box>
              <Box
                display={"flex"}
                alignItems={"center"}
                justifyContent={"flex-start"}
                width={"100%"}
              >
                <Text
                  color={"#00466D"}
                  fontFamily={"Nunito"}
                  fontSize={"12px"}
                  fontStyle={"normal"}
                  fontWeight={"600"}
                  lineHeight={"normal"}
                >
                  This strategy is designed to help you grow the value of your
                  estate to its fullest potential while ensuring sufficient
                  annual income to cover living expenses and minimizing tax
                  liabilities. It's ideal if you are looking to leave a
                  substantial legacy without compromising your quality of life.
                </Text>
              </Box>

              <Link href={`/inputsummary?strategy=${"Max Value"}`}>
                <Box
                  display={"flex"}
                  height={"50px"}
                  flexDirection={"column"}
                  justifyContent={"flex-end"}
                  alignItems={"center"}
                >
                  <button type="submit" className="max-value-button">
                    Select
                  </button>
                </Box>
              </Link>
            </div>

            <div className="life-strat-set-value-box">
              <Box
                display={"flex"}
                justifyContent={"space-between"}
                alignItems={"center"}
                alignSelf={"stretch"}
                flexDirection={"row"}
              >
                <Text
                  color={"#00A2D4"}
                  fontFamily={"Nunito"}
                  fontSize={"20px"}
                  fontStyle={"normal"}
                  fontWeight={"700"}
                  lineHeight={"normal"}
                >
                  Set Value
                </Text>
                <Box
                  cursor={"pointer"}
                  onClick={() => openVideoModal("Set Value", "Set_value.mp4")}
                >
                  <Icon as={PlayIcon} svgwidth={"20px"} svgheight={"20px"} />
                </Box>
              </Box>
              <Box
                display={"flex"}
                padding={"3px"}
                justifyContent={"center"}
                alignItems={"center"}
                borderRadius={"2px"}
                bg={"#BEEBFF"}
              >
                <Icon
                  as={SetValueGraphIcon}
                  svgwidth={"224px"}
                  svgheight={"139px"}
                />
              </Box>

              <Box
                display={"flex"}
                alignItems={"center"}
                justifyContent={"flex-start"}
                width={"100%"}
              >
                <Text
                  flex={"1 0 0"}
                  color={"#00A2D4"}
                  fontFamily={"Nunito"}
                  fontSize={"14px"}
                  fontStyle={"normal"}
                  fontWeight={"700"}
                  textAlign={"start"}
                >
                  Goal Oriented Wealth
                </Text>
              </Box>
              <Box
                display={"flex"}
                alignItems={"center"}
                justifyContent={"flex-start"}
                width={"100%"}
                marginBottom={"10px"}
              >
                <Text
                  color={"#00466D"}
                  fontFamily={"Nunito"}
                  fontSize={"12px"}
                  fontStyle={"normal"}
                  fontWeight={"600"}
                  lineHeight={"normal"}
                >
                  If you have a specific financial legacy in mind, this strategy
                  is for you. It calculates the most tax-efficient way to reach
                  your estate target while covering your annual expenses. It
                  allows for the maximum possible spending each year without
                  derailing your financial goals.
                </Text>
              </Box>
              <NormalInputButton
                Heading={"Enter your desired estate goal"}
                placeholder={"$"}
                fontSize={"14px"}
                value={customValue}
                onChange={(e) => {
                  setCustomValue(moneyToNumber(e.target.value));
                  setIsPopoverOpen(false);
                }}
                customWidth={"100%"}
                InputWidth={"100%"}
                useThousandSeparator={true}
              />
              {/* {assetWarning && (
                          <div className="EstateValueWarning">
                            Great news! Your current assets are already on track
                            to meet your desired estate goal. You can choose to
                            un-check certain assets listed below. Alternatively,
                            you can consider increasing your desired estate
                            goal. The total end of analysis value of your
                            included assets is{" "}
                            <strong>{currencyFormat(sumAssetValue)}</strong>.
                          </div>
                        )} */}
              {/* <CurrencyInput
                          className="custom-eol-amount border-1px-mischka"
                          type="text"
                          id="custome-value"
                          style={{ marginTop: "10px" }}
                          placeholder="$"
                          name="custom-value"
                          value={customValue}
                          onChange={(e) => {
                            setCustomValue(moneyToNumber(e.target.value));
                          }}
                        /> */}
              <Box
                display={"flex"}
                height={"auto"}
                flexDirection={"column"}
                justifyContent={"flex-end"}
                alignItems={"center"}
              >
                <Popover
                  isOpen={isPopoverOpen}
                  placement="bottom"
                  isCentered={false}
                >
                  {checkboxes?.length >= 1 && (
                    <PopoverTrigger>
                      <button
                        type="submit"
                        className="custom-value-button"
                        onClick={(e) => {
                          if (customValue) {
                            e.preventDefault(); // Prevent form submission or button default action
                            onOpen(); // Open the popover if customValue is false
                          } else {
                            setIsPopoverOpen(true);
                          }
                        }}
                      >
                        Select
                      </button>
                    </PopoverTrigger>
                  )}
                  {checkboxes?.length < 1 && customValue && (
                    <Link href={`/inputsummary?strategy=${"Set Value"}`}>
                      <button
                        type="submit"
                        className="custom-value-button"
                        onClick={(e) => {
                          if (customValue) {
                            e.preventDefault(); // Prevent form submission or button default action
                            setUserSetValueInfo({
                              ...userSetValueInfo,
                              SetValue: customValue,
                              IncludeAssets: includeAssets,
                              IncludePrimaryRes: includePrimaryRes,
                              IncludeRecreationProperty:
                                includeRecreationProperty,
                              IncludeIncomeProperty: includeIncomeProperty,
                              IncludeLand: includeLand,
                              IncludeBusiness: includeBusiness,
                              IncludeLifeInsurance: includeLifeInsurance,
                            });
                          } else {
                            setIsPopoverOpen(true);
                          }
                        }}
                      >
                        Select
                      </button>
                    </Link>
                  )}
                  <PopoverContent
                    color="white"
                    bg="#00A2D4"
                    borderColor="#00A2D4"
                  >
                    <PopoverBody>
                      Please Enter a value before selecting
                    </PopoverBody>
                  </PopoverContent>
                </Popover>
                <Modal isOpen={isOpen} onClose={onclose} isCentered={true}>
                  <ModalOverlay />

                  <ModalContent
                    borderRadius={"10px"}
                    border={
                      "1px solid var(--Optiml---Vibrant-Sky-Blue, #00A2D4)"
                    }
                  >
                    <Box
                      display={"flex"}
                      width={"490px"}
                      height={"-webkit-fit-content"}
                      flexDirection={"column"}
                      alignItems={"center"}
                      gap={"10px"}
                      borderRadius={"10px"}
                      bg={"#FFF"}
                    >
                      <Box
                        display={"flex"}
                        padding={"10px 10px 0px 0px"}
                        justifyContent={"flex-end"}
                        alignItems={"flex-start"}
                        gap={"10px"}
                        alignSelf={"stretch"}
                        onClick={onClose}
                        _hover={{
                          cursor: "pointer",
                        }}
                      >
                        <Icon
                          as={CloseIcon}
                          svgwidth={"18px"}
                          svgheight={"18px"}
                        />
                      </Box>
                      <Box
                        display={"flex"}
                        padding={"25px 50px 50px 50px"}
                        flexDirection={"column"}
                        alignItems={"center"}
                        gap={"20px"}
                        borderRadius={"10px"}
                        alignSelf={"stretch"}
                        bg={"#FFF"}
                      >
                        <Text
                          color={"#00A2D4"}
                          textAlign={"center"}
                          fontFamily={"Nunito"}
                          fontSize={"20px"}
                          fontStyle={"normal"}
                          fontWeight={"700"}
                          lineHeight={"12px"}
                        >
                          Customize your estate value goal
                        </Text>
                        <Text
                          color={"#00466D"}
                          textAlign={"center"}
                          fontFamily={"Nunito"}
                          fontSize={"14px"}
                          fontStyle={"normal"}
                          fontWeight={"600"}
                          lineHeight={"16px"}
                        >
                          Your investment accounts (TFSA, RRSP, and
                          Non-Registered Accounts) are automatically included in
                          reaching your estate goal of{" "}
                          {currencyFormat(customValue)}. You have the freedom to
                          choose which, if any, of your other assets you want to
                          contribute to your estate goal, or keep them separate
                        </Text>
                        {checkboxes?.length >= 1 && customValue >= 1 && (
                          <Text
                            color={"#00A2D4"}
                            textAlign={"center"}
                            fontFamily={"Nunito"}
                            fontSize={"14px"}
                            fontStyle={"normal"}
                            fontWeight={"600"}
                            lineHeight={"12px"}
                            width={"436px"}
                            height={"18px"}
                          >
                            Include these assets in estate value
                          </Text>
                        )}
                        {customValue >= 1 && (
                          <Box
                            display={"flex"}
                            height={"64px"}
                            flexDirection={"column"}
                            justifyContent={"space-between"}
                            alignItems={"flex-start"}
                            alignSelf={"stretch"}
                            h={"auto"}
                          >
                            <CheckboxGroup colorScheme="cyan">
                              {checkboxes.map((checkbox, index) => (
                                <CheckboxItem
                                  key={checkbox.id} // Fixed: it should be checkbox.id instead of index.id
                                  label={checkbox.label}
                                  value={checkbox.value}
                                  isChecked={checkbox.checked} // Updated: passing the checked value
                                  onChange={() =>
                                    handleCheckboxChange(
                                      checkbox.id,
                                      checkbox.checked
                                    )
                                  }
                                />
                              ))}
                            </CheckboxGroup>
                          </Box>
                        )}
                        <Box
                          display={"flex"}
                          height={"43px"}
                          flexDirection={"column"}
                          justifyContent={"center"}
                          alignSelf={"stretch"}
                        >
                          <Text
                            color={"#00466D"}
                            textAlign={"center"}
                            fontFamily={"Nunito"}
                            fontSize={"12px"}
                            fontStyle={"normal"}
                            fontWeight={"600"}
                            lineHeight={"14px"}
                          >
                            <Text as="span" fontWeight={"700"}>
                              Tip:
                            </Text>{" "}
                            Excluding certain assets, like a home you don't plan
                            to sell, can provide a clearer view of your liquid
                            estate and investment growth towards your financial
                            future.
                          </Text>
                        </Box>
                        <Box
                          display={"flex"}
                          justifyContent={"flex-end"}
                          alignItems={"center"}
                          alignSelf={"stretch"}
                        >
                          <Link href={`/inputsummary?strategy=${"Set Value"}`}>
                            <Button
                              padding="8px 48px"
                              justifyContent="center"
                              alignItems="center"
                              gap="5px"
                              borderRadius="5px"
                              bg="#00A2D4"
                              onClick={() => {
                                setUserSetValueInfo({
                                  ...userSetValueInfo,
                                  SetValue: customValue,
                                  IncludeAssets: includeAssets,
                                  IncludePrimaryRes: includePrimaryRes,
                                  IncludeRecreationProperty:
                                    includeRecreationProperty,
                                  IncludeIncomeProperty: includeIncomeProperty,
                                  IncludeLand: includeLand,
                                  IncludeBusiness: includeBusiness,
                                  IncludeLifeInsurance: includeLifeInsurance,
                                });
                              }}
                            >
                              <Text
                                color="#FFF"
                                textAlign="center"
                                fontFamily="Nunito"
                                fontSize="16px"
                                fontStyle="normal"
                                fontWeight="600"
                                lineHeight="normal"
                              >
                                Next
                              </Text>
                            </Button>
                          </Link>
                        </Box>
                      </Box>
                    </Box>
                  </ModalContent>
                </Modal>
              </Box>
            </div>

            <div className="life-strat-max-spend-box">
              <Box
                display={"flex"}
                justifyContent={"space-between"}
                alignItems={"center"}
                alignSelf={"stretch"}
                flexDirection={"row"}
              >
                <Text
                  color={"#00A2D4"}
                  fontFamily={"Nunito"}
                  fontSize={"20px"}
                  fontStyle={"normal"}
                  fontWeight={"700"}
                  lineHeight={"normal"}
                >
                  Max Spend
                </Text>
                <Box
                  cursor={"pointer"}
                  onClick={() => openVideoModal("Max Spend", "Max_spend.mp4")}
                >
                  <Icon as={PlayIcon} svgwidth={"20px"} svgheight={"20px"} />
                </Box>
              </Box>
              <Box
                display={"flex"}
                padding={"3px"}
                justifyContent={"center"}
                alignItems={"center"}
                borderRadius={"2px"}
                bg={"#BEEBFF"}
              >
                <Icon
                  as={MaxSpendGraphIcon}
                  svgwidth={"224px"}
                  svgheight={"139px"}
                />
              </Box>
              <Box
                display={"flex"}
                alignItems={"center"}
                justifyContent={"flex-start"}
                width={"100%"}
              >
                <Text
                  flex={"1 0 0"}
                  color={"#00A2D4"}
                  fontFamily={"Nunito"}
                  fontSize={"14px"}
                  fontStyle={"normal"}
                  fontWeight={"700"}
                  textAlign={"start"}
                >
                  Spend It All
                </Text>
              </Box>
              <Box
                display={"flex"}
                alignItems={"center"}
                justifyContent={"flex-start"}
                width={"100%"}
              >
                <Text
                  color={"#00466D"}
                  fontFamily={"Nunito"}
                  fontSize={"12px"}
                  fontStyle={"normal"}
                  fontWeight={"600"}
                  lineHeight={"normal"}
                >
                  If you wish to enjoy your retirement to the fullest by
                  spending all your savings, this approach ensures you can spend
                  every penny of your savings in the most tax-efficient way,
                  aiming to reduce your estate to zero by the end of your
                  analysis. Perfect for maximizing retirement enjoyment without
                  leaving unspent assets.
                </Text>
              </Box>

              <Link href={`/inputsummary?strategy=${"Max Spend"}`}>
                <button type="submit" className="max-spend-button">
                  Select
                </button>
              </Link>
            </div>
          </Box>
        </>
      )}
    </>
  );
}

export default LifeStrategy;
