import React, { useState, useEffect } from "react";
import Modal from "react-modal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faRocket } from "@fortawesome/pro-light-svg-icons";
import { faEdit } from "@fortawesome/pro-light-svg-icons";
import Link from "../Link";
import { useStore } from "../../store";
import "./ErrorModal.css";
import { LaunchNewOptimizationIcon } from "../CustomIcons";
import { Button, HStack, Icon, Text } from "@chakra-ui/react";
import { color } from "framer-motion";
Modal.setAppElement("#root");
function ErrorModal(props) {
  const [presignedUrl, setPresignedUrl] = useState(null);
  const [alternatePlanViewed, setAlternatePlanViewed] = useState(""); // Added state for tracking if alternate plan is viewed
  useEffect(() => {
    const viewed = sessionStorage.getItem("alternatePlanViewed");
    if (viewed === "true") {
      setAlternatePlanViewed("true");
      props.setErrorModalShown === true;
    }
    else{
      setAlternatePlanViewed("false");
      props.setErrorModalShown === false;
    }
    // Configure AWS SDK with environment variables
    AWS.config.update({
      accessKeyId: "AKIA2BWNKJSSRZAU3UV2",
      secretAccessKey: "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",
      region: "us-east-2",
    });

    // Create a new S3 instance
    const s3 = new AWS.S3();

    // Specify the bucket and object key
    const bucketName = "oltrefinancialhelpvideos";
    const objectKey = "ERROR.mp4";

    // Set the expiration time for the pre-signed URL (in seconds)
    const expirationTime = 3600; // 1 hour

    // Generate a pre-signed URL
    const params = {
      Bucket: bucketName,
      Key: objectKey,
      Expires: expirationTime,
    };
    s3.getSignedUrl("getObject", params, (err, url) => {
      if (err) {
        console.error("Error generating pre-signed URL:", err);
      } else {
        setPresignedUrl(url);
      }
    });
  }, []);

  const setShowNewOptimization = useStore(
    (store) => store?.setShowNewOptimization
  );
  const setMobileSidebarOpen = useStore((store) => store?.setMobileSidebarOpen);
  const userOptiData = useStore((store) => store?.userOptiData);
  const { primaryUserData, spouseData: spouseUserData } = userOptiData;
  const userExpensesInfo = useStore((store) => store.userExpensesInfo);

  const onClose = (e) => {
    props.onClose && props.onClose(e);
  };

  function countLessThan(arr1, arr2) {
    if (!arr1 || !arr2) {
      return 0;
    }

    let count = 0;
    if (arr1.length !== arr2.length) {
      count = 5;
    } else {
      for (let i = 0; i < arr1.length; i++) {
        if (arr2[i] < arr1[i]) {
          count++;
        }
      }
    }
    return count;
  }

  const isMobile = window.innerWidth <= 1200;
  const isSmallScreen = 1200 <= window.innerWidth && window.innerWidth <= 1280;
  const modalStyles = {
    overlay: {
      zIndex: 1000,
      backgroundColor: "rgba(0, 0, 0, 0)",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",

      top: "55%",
      left: "55%",
      transform: isMobile ? "translate(-60%, -60%)" : "translate(-60%, -60%)",
    },
    content: {
      inset: "auto",
      border: "2px solid #00A2D4",
      position: "absolute",
      top: "50%",
      left: "50%",
      display: "flex",
      flexDirection: "column",
      backgroundColor: "white",
      padding: isMobile ? "10px" : "15px",
      borderRadius: "8px",
      outline: "none",
      gap: isSmallScreen ? "10px" : "20px",
      boxShadow: "0 2px 10px rgba(0, 0, 0, 0.1)",
      width: isMobile ? "210%" : isSmallScreen ? "800px" : "100%", // Adjust width for mobile
      maxWidth: isMobile ? "80vw" : "900px", // Maximum width for mobile
      maxHeight: isMobile ? "90vh" : "85vh", // Maximum height for mobile
    },
  };

  const newOptimization = (e) => {
    setShowNewOptimization(true);
    setMobileSidebarOpen(false);
    onClose();
    e.preventDefault();
    window.history.pushState({}, "", "/revenue");
    const navEvent = new PopStateEvent("popstate");
    window.dispatchEvent(navEvent);
  };

  const viewAlternatePlan = (e) => {
    setShowNewOptimization(false); // Hide new optimization
    setAlternatePlanViewed("true"); // Set to string "true"
    sessionStorage.setItem("alternatePlanViewed", "true"); // Store viewed state in local storage
    props.setErrorModalShown(true); // Notify parent component if needed
    onClose();
    e.preventDefault();
    window.history.pushState({}, "", "/expenses");
    const navEvent = new PopStateEvent("popstate");
    window.dispatchEvent(navEvent);
  };

  
  const [hover, setHover] = useState(false);
  return (
    <Modal
      isOpen={props.isOpen && alternatePlanViewed === "false"}
      onRequestClose={props.closeModal}
      contentLabel="Help Video"
      style={modalStyles}
    >
      <div className="close-btn" onClick={props.closeModal}>
        <span style={{ fontWeight: "800" }}>X</span>
      </div>
      <h2 className="error-modal-title">Short Fall in Analysis</h2>
      <h4 className="error-modal-subtitle">
        We are unable to achieve your desired goals based on you inputs
      </h4>
      <div className="error-modal-video-box">
        <center>
          <iframe
            title="Embedded Video"
            style={{
              width: "70%",
              height: "250px",
            }}
            src={presignedUrl}
            allow="encrypted-media"
            allowFullScreen
          ></iframe>
        </center>
      </div>
      <p className="error-modal-textContent">
        Based on your inputs and financial goals, the analysis was unable to
        solve. You will unfortunately have shortfalls in{" "}
        {countLessThan(
          userExpensesInfo?.expenseArray,
          primaryUserData?.expenses
        )}{" "}
        years during this analysis. We recommend lowering your household
        expenses, or changing how much your expenses will grow during the
        analysis. Not to worry, our tool was able to create an alternative plan.
        We created the <strong>Calculated Max Living Expenses</strong> you
        are able to spend which is displayed on the expenses page.
      </p>
      <div className="error-modal-buttons">
        <button className="btn" onClick={newOptimization}>
          <FontAwesomeIcon
            icon={faEdit}
            className="error-modal-edit-icon me-3 ms-2"
            size="xl"
          />
          Update my current plan
        </button>

        <Button
          className="btn"
          onMouseEnter={() => setHover(true)}
          onMouseLeave={() => setHover(false)}
          minW={"280px"}
          h={"auto"}
          flex={"1 1 auto"}
          borderRadius={"30px"}
          padding={"10px"}
          boxShadow={"0px 4px 4px #00000040"}
          onClick={viewAlternatePlan}
          _hover={{ ".icon , .text": { color: "#00A2D4" } }}
        >
          <HStack width={"80%"} justifyContent={"space-between"}>
            <LaunchNewOptimizationIcon
              color={hover ? "#00A2D4" : "#FFF"}
              svgheight={"24px"}
              svgwidth={"24px"}
            />
            <Text
              color={"#FFF"}
              className="text"
              fontSize={"18px"}
              fontFamily={"Nunito"}
            >
              View alternate plan
            </Text>
          </HStack>
        </Button>
      </div>
    </Modal>
  );
}

export default ErrorModal;
