import { useAuth0 } from "@auth0/auth0-react";
import axios from "axios";
import React, {
  forwardRef,
  useContext,
  useEffect,
  useImperativeHandle,
  useState,
} from "react";
import { useStore } from "../../../store";
import { Flex, VStack, Box, Text, useMediaQuery } from "@chakra-ui/react";
import GlobalBodyPage from "../../GlobalContainer/BodyPage";
import DropDownButton from "../../InputFields/dropDownButton";
import NormalInputButton from "../../InputFields/normalInputButton";
import TooltipContent from "../../TooltipContent";

const PrimaryResidencePage = forwardRef((props, ref) => {
  const { setChangedData } = props;
  const { user, getAccessTokenSilently } = useAuth0();

  useImperativeHandle(ref, () => ({
    submitHandler,
    checkMandatoryFields,
  }));

  const axiosInstance = axios.create();
  async function getToken() {
    // Replace this with your Auth0 token retrieval logic
    const token = await getAccessTokenSilently({
      audience:
        "https://os2lsiwff43wfpb5ggyawt6qri0mgcro.lambda-url.us-east-2.on.aws/",
      scope: "write:optimization",
    });
    return token;
  }

  // Add a request interceptor
  axiosInstance.interceptors.request.use(
    async (config) => {
      const token = await getToken();
      config.headers.Authorization = `Bearer ${token}`;
      return config;
    },
    (error) => {
      // Do something with request error
      return Promise.reject(error);
    }
  );

  const userInfo = useStore((store) => store.userInfo);

  const userPrimaryResidenceInfo = useStore(
    (store) => store.userPrimaryResidenceInfo
  );
  const setUserPrimaryResidenceInfo = useStore(
    (store) => store.setUserPrimaryResidenceInfo
  );
  const updateUserPrimaryResidenceInfo = useStore(
    (store) => store.updateUserPrimaryResidenceInfo
  );
  const deleteUserPrimaryResidenceInfo = useStore(
    (store) => store.deleteUserPrimaryResidenceInfo
  );

  const {
    FirstName: firstName,
    LastName: lastName,
    DateOfBirth: currentAge,
    spouseFirstName,
    spouseLastName,
    runAge,
  } = userInfo;

  const [primaryResidenceValue, setPrimaryResidenceValue] = useState(0);
  const [primaryResidenceGrowthRate, setPrimaryResidenceGrowthRate] =
    useState(0);
  const [primaryResidenceMortgageBalance, setPrimaryResidenceMortgageBalance] =
    useState(0);
  const [primaryResidenceInterestRate, setPrimaryResidenceInterestRate] =
    useState(0);
  const [primaryResidenceMonthlyPayment, setPrimaryResidenceMonthlyPayment] =
    useState(0);
  const [primaryResidencePlansToSell, setPrimaryResidencePlansToSell] =
    useState("");
  const [primaryResidencePlansToSellYear, setPrimaryResidencePlansToSellYear] =
    useState("");
  const [primaryResidenceNumber, setPrimaryResidenceNumber] = useState();

  const [isLargerThan768] = useMediaQuery("(min-width: 500px)");
  const isMobile = !isLargerThan768;

  useEffect(() => {
    setPrimaryResidenceValue(userPrimaryResidenceInfo[0]?.value);
    setPrimaryResidenceMortgageBalance(
      userPrimaryResidenceInfo[0]?.Primary_Res_Mortgage_Balance
    );
    setPrimaryResidenceInterestRate(
      userPrimaryResidenceInfo[0]?.Primary_Res_Interest
    );
    setPrimaryResidenceGrowthRate(
      userPrimaryResidenceInfo[0]?.Primary_Res_Growth
    );
    setPrimaryResidenceMonthlyPayment(
      userPrimaryResidenceInfo[0]?.Primary_Res_Monthly_Payments
    );
    setPrimaryResidencePlansToSell(
      userPrimaryResidenceInfo[0]?.Do_Primary_Res_Sell
    );
    setPrimaryResidencePlansToSellYear(
      userPrimaryResidenceInfo[0]?.Primary_Res_Sell_Date
    );
    setPrimaryResidenceNumber(userPrimaryResidenceInfo[0]?.Primary_Res_Number);
  }, [userPrimaryResidenceInfo]);

  const [errors, setErrors] = useState({});

  const currencyFormat = (money) => {
    if (money === 0) {
      return "$0"; // Handle zero explicitly
    } else if (money === "") {
      return "";
    } else {
      const intTotal = Math.round(Math.round(money * 100) / 100);
      const currencyCad = new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "USD",
        minimumFractionDigits: 0,
      }).format(intTotal);
      return currencyCad;
    }
  };

  const checkMandatoryFields = () => {
    let newErrors = {};

    // Primary residence conditions
    if (!primaryResidenceValue)
      newErrors.primaryResidenceValue = "Primary residence value is required";
    if (!primaryResidencePlansToSell)
      newErrors.primaryResidencePlansToSell =
        "Plan to sell primary residence is required";
    if (primaryResidenceGrowthRate > 20) {
      newErrors.primaryResidenceGrowthRate =
        "Growth rate must be less than 20%";
    }
    if (primaryResidenceInterestRate > 20) {
      newErrors.primaryResidenceInterestRate =
        "Interest rate must be less than 20%";
    }
    if (
      primaryResidencePlansToSell === "Yes" &&
      !primaryResidencePlansToSellYear
    )
      newErrors.primaryResidencePlansToSellYear =
        "Year to sell primary residence is required";

    const effectiveMonthlyInterest =
      (1 + ((1 + primaryResidenceInterestRate / 200) ** 2 - 1)) ** (1 / 12) - 1;

    if (
      primaryResidenceMortgageBalance > 0 &&
      primaryResidenceMonthlyPayment <
        primaryResidenceMortgageBalance * effectiveMonthlyInterest
    ) {
      newErrors.primaryResidenceMonthlyPayment = `Monthly payment is to low and needs to be greater than ${currencyFormat(
        primaryResidenceMortgageBalance * effectiveMonthlyInterest
      )}.`;
    }

    if (
      primaryResidenceMortgageBalance > 0 &&
      primaryResidenceMonthlyPayment < 1
    ) {
      newErrors.primaryResidenceMonthlyPayment =
        "Monthly mortgage payment is required.";
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0; // Return true if no errors
  };

  const submitHandler = () => {
    if (!primaryResidenceNumber) {
      const primaryResURL = (action) =>
        `${
          process.env.REACT_APP_NODE_API_GATEWAY
        }/CompassLambdaApi/REAL_ESTATE/${action}?REALESTATEID=${user.sub.replace(
          "|",
          "%7c"
        )}&value=${primaryResidenceValue}&Primary_Res_Interest=${primaryResidenceInterestRate}&Primary_Res_Monthly_Payments=${primaryResidenceMonthlyPayment}&Do_Primary_Res_Sell=${primaryResidencePlansToSell}&Primary_Res_Sell_Date=${primaryResidencePlansToSellYear}&Primary_Res_Mortgage_Balance=${primaryResidenceMortgageBalance}&name=${"Primary residence"}&Primary_Res_Growth=${primaryResidenceGrowthRate}`;
      axiosInstance
        .get(primaryResURL("add"))
        .then((res) => {
          console.log(res);
          const newData = [
            {
              value: primaryResidenceValue,
              Primary_Res_Interest: primaryResidenceInterestRate
                ? primaryResidenceInterestRate
                : 0,
              Primary_Res_Monthly_Payments: primaryResidenceMonthlyPayment
                ? primaryResidenceMonthlyPayment
                : 0,
              Do_Primary_Res_Sell: primaryResidencePlansToSell,
              Primary_Res_Sell_Date: primaryResidencePlansToSellYear,

              Primary_Res_Mortgage_Balance: primaryResidenceMortgageBalance
                ? primaryResidenceMortgageBalance
                : 0,
              Primary_Res_Number: res.data.lastInsertedId,
              name: "Primary residence",
              Primary_Res_Growth: primaryResidenceGrowthRate,
            },
          ];
          setUserPrimaryResidenceInfo(newData);
        })
        .catch((error) => {
          console.error(error);
        });
    } else {
      const primaryResURL = (action) =>
        `${
          process.env.REACT_APP_NODE_API_GATEWAY
        }/CompassLambdaApi/REAL_ESTATE/${action}?REALESTATEID=${user.sub.replace(
          "|",
          "%7c"
        )}&value=${primaryResidenceValue}&Primary_Res_Interest=${primaryResidenceInterestRate}&Primary_Res_Monthly_Payments=${primaryResidenceMonthlyPayment}&Do_Primary_Res_Sell=${primaryResidencePlansToSell}&Primary_Res_Sell_Date=${primaryResidencePlansToSellYear}&Primary_Res_Mortgage_Balance=${primaryResidenceMortgageBalance}&Primary_Res_Number=${primaryResidenceNumber}&name=${"Primary residence"}&Primary_Res_Growth=${primaryResidenceGrowthRate}`;
      axiosInstance
        .get(primaryResURL("update"))
        .then((res) => {
          console.log(res);
          const newData = {
            value: primaryResidenceValue,
            Primary_Res_Interest: primaryResidenceInterestRate
              ? primaryResidenceInterestRate
              : 0,
            Primary_Res_Monthly_Payments: primaryResidenceMonthlyPayment
              ? primaryResidenceMonthlyPayment
              : 0,
            Do_Primary_Res_Sell: primaryResidencePlansToSell,
            Primary_Res_Sell_Date: primaryResidencePlansToSellYear,

            Primary_Res_Mortgage_Balance: primaryResidenceMortgageBalance
              ? primaryResidenceMortgageBalance
              : 0,
            Primary_Res_Number: primaryResidenceNumber,
            name: "Primary residence",
            Primary_Res_Growth: primaryResidenceGrowthRate,
          };
          updateUserPrimaryResidenceInfo(primaryResidenceNumber, newData);
        })
        .catch((error) => {
          console.error(error);
        });
    }
  };

  const moneyToNumber = (value) => {
    let numValue = value.toString().replace(/[$,\s]/g, "");
    numValue = Number(numValue);
    return numValue;
  };

  function getAge(birthday, currentDate) {
    // Convert the birthday string into a Date object
    const birthDate = new Date(birthday);

    // Calculate the age in years
    let age = currentDate.getFullYear() - birthDate.getFullYear();

    // Decrement the age if the current month and day are before the birth month and day
    if (
      currentDate.getMonth() < birthDate.getMonth() ||
      (currentDate.getMonth() === birthDate.getMonth() &&
        currentDate.getDate() <= birthDate.getDate())
    ) {
      age -= 1;
    }

    return age;
  }

  const Select = (userAge) => {
    const currentDate = new Date();
    const currentYear = currentDate.getFullYear();
    let age = getAge(userAge, currentDate); // Assuming getAge returns the starting age.
    const runAgeList = [];
    for (let i = age; i <= 100; i++) {
      runAgeList.push({ key: i, value: currentYear + (i - age) }); // Convert i to string if necessary
    }
    return runAgeList;
  };

  return (
    <Flex
      display="flex"
      flexWrap="wrap" // Enable wrapping
      justifyContent="flex-start"
      alignItems="start"
      width={"100%"}
    >
      {!isMobile && (
        <VStack alignItems={"flex-start"} width={"100%"} mt={-2}>
          <GlobalBodyPage
            Heading="Primary Residence"
            mainframeContent={
              <>
                <Box
                  display={"flex"}
                  width={"100%"}
                  flexDirection={"row"}
                  paddingTop={"10px"}
                  alignItems={"flex-end"}
                  justifyContent={"space-between"}
                  gap={"10px"}
                  flexWrap={"wrap"}
                >
                  <Text
                    alignSelf={"stretch"}
                    color={"var(--Optiml---Deep-Ocean-Blue, #007EB5)"}
                    fontFamily={"Nunito"}
                    fontSize={"16px"}
                    fontStyle={"normal"}
                    fontWeight={"600"}
                    lineHeight={"normal"}
                    width={"100%"}
                  >
                    Property details:
                  </Text>
                  <NormalInputButton
                    Heading={"Current value"}
                    value={primaryResidenceValue}
                    onChange={(e) => {
                      setChangedData(true);
                      setPrimaryResidenceValue(moneyToNumber(e.target.value));
                    }}
                    customWidth={"100%"}
                    placeholder={"$"}
                    useThousandSeparator={true}
                    error={errors.primaryResidenceValue}
                    tooltipContent={TooltipContent.primaryResValue}
                  />
                  <Text
                    alignSelf={"stretch"}
                    color={"var(--Optiml---Deep-Ocean-Blue, #007EB5)"}
                    fontFamily={"Nunito"}
                    fontSize={"16px"}
                    fontStyle={"normal"}
                    fontWeight={"600"}
                    lineHeight={"normal"}
                    width={"100%"}
                  >
                    Financial performance:
                  </Text>
                  <NormalInputButton
                    Heading={"Growth rate"}
                    value={primaryResidenceGrowthRate}
                    onChange={(e) => {
                      setChangedData(true);
                      const noSymbol = e.target.value.replace("%", "");
                      const growthNum = parseFloat(noSymbol);
                      setPrimaryResidenceGrowthRate(
                        !isNaN(growthNum)
                          ? parseFloat(growthNum.toFixed(2))
                          : ""
                      );
                    }}
                    customWidth={"100%"}
                    placeholder={"%"}
                    usePercentage={true}
                    error={errors.primaryResidenceGrowthRate}
                    tooltipContent={TooltipContent.primaryResGrowth}
                  />
                  <Text
                    alignSelf={"stretch"}
                    color={"var(--Optiml---Deep-Ocean-Blue, #007EB5)"}
                    fontFamily={"Nunito"}
                    fontSize={"16px"}
                    fontStyle={"normal"}
                    fontWeight={"600"}
                    lineHeight={"normal"}
                    width={"100%"}
                  >
                    Mortgage information:
                  </Text>
                  <NormalInputButton
                    Heading={"Remaining mortgage balance"}
                    value={primaryResidenceMortgageBalance}
                    onChange={(e) => {
                      setChangedData(true);
                      setPrimaryResidenceMortgageBalance(
                        moneyToNumber(e.target.value)
                      );
                    }}
                    placeholder={"$"}
                    useThousandSeparator={true}
                    error={errors.primaryResidenceMortgageBalance}
                    tooltipContent={TooltipContent.primaryResMortgageBalance}
                  />
                  <NormalInputButton
                    Heading={"Monthly mortgage payment"}
                    value={primaryResidenceMonthlyPayment}
                    onChange={(e) => {
                      setChangedData(true);
                      setPrimaryResidenceMonthlyPayment(
                        moneyToNumber(e.target.value)
                      );
                    }}
                    placeholder={"$"}
                    useThousandSeparator={true}
                    error={errors.primaryResidenceMonthlyPayment}
                    tooltipContent={TooltipContent.primaryResMonthlyMortgage}
                  />
                  <NormalInputButton
                    Heading={"Mortgage interest rate"}
                    value={primaryResidenceInterestRate}
                    onChange={(e) => {
                      setChangedData(true);
                      const noSymbol = e.target.value.replace("%", "");
                      const growthNum = parseFloat(noSymbol);
                      setPrimaryResidenceInterestRate(
                        !isNaN(growthNum)
                          ? parseFloat(growthNum.toFixed(2))
                          : ""
                      );
                    }}
                    placeholder={"%"}
                    usePercentage={true}
                    error={errors.primaryResidenceInterestRate}
                    tooltipContent={TooltipContent.primaryResInterestRate}
                  />
                  <Text
                    alignSelf={"stretch"}
                    color={"var(--Optiml---Deep-Ocean-Blue, #007EB5)"}
                    fontFamily={"Nunito"}
                    fontSize={"16px"}
                    fontStyle={"normal"}
                    fontWeight={"600"}
                    lineHeight={"normal"}
                    width={"100%"}
                  >
                    Future Real Estate Plans
                  </Text>
                  <DropDownButton
                    Heading={
                      "Do you have plans to sell your primary residence?"
                    }
                    placeholder={"Select"}
                    dropDownItems={[
                      { key: "Yes", value: "Yes" },
                      { key: "No", value: "No" },
                    ]}
                    value={primaryResidencePlansToSell}
                    onChange={(key) => {
                      setChangedData(true);
                      setPrimaryResidencePlansToSell(key);
                    }}
                    customWidth={"100%"}
                    error={errors.primaryResidencePlansToSell}
                    tooltipContent={TooltipContent.primaryResSaleYesNo}
                  />
                  {primaryResidencePlansToSell === "Yes" && (
                    <DropDownButton
                      Heading={
                        "When do you anticipate you may sell your primary residence?"
                      }
                      placeholder={"Select"}
                      dropDownItems={Select(currentAge)}
                      value={primaryResidencePlansToSellYear}
                      onChange={(key) => {
                        setChangedData(true);
                        setPrimaryResidencePlansToSellYear(key);
                      }}
                      customWidth={"100%"}
                      error={errors.primaryResidencePlansToSellYear}
                      tooltipContent={TooltipContent.primaryResSaleAge}
                    />
                  )}
                </Box>
              </>
            }
            videoName={"Primary Residence Page"}
            video={"Primary_Residence.mp4"}
          />
        </VStack>
      )}
      {isMobile && (
        <VStack alignItems={"flex-start"} width={"100%"} mt={-2}>
          <GlobalBodyPage
            Heading="Primary Residence"
            mainframeContent={
              <>
                <Box
                  display={"flex"}
                  width={"100%"}
                  flexDirection={"row"}
                  paddingTop={"10px"}
                  alignItems={"flex-end"}
                  justifyContent={"space-between"}
                  gap={"10px"}
                  flexWrap={"wrap"}
                >
                  <Text
                    alignSelf={"stretch"}
                    color={"#007EB5"}
                    fontFamily={"Nunito"}
                    fontSize={"16px"}
                    fontStyle={"normal"}
                    fontWeight={"600"}
                    lineHeight={"normal"}
                  >
                    Property details:
                  </Text>
                  <NormalInputButton
                    Heading={"Current value"}
                    value={primaryResidenceValue}
                    onChange={(e) => {
                      setChangedData(true);
                      setPrimaryResidenceValue(moneyToNumber(e.target.value));
                    }}
                    customWidth={"100%"}
                    InputWidth={"100%"}
                    placeholder={"$"}
                    error={errors.primaryResidenceValue}
                    useThousandSeparator={true}
                    tooltipContent={TooltipContent.primaryResValue}
                  />
                  <Text
                    alignSelf={"stretch"}
                    color={"var(--Optiml---Deep-Ocean-Blue, #007EB5"}
                    fontFamily={"Nunito"}
                    fontSize={"16px"}
                    fontStyle={"normal"}
                    fontWeight={"600"}
                    lineHeight={"normal"}
                  >
                    Financial performance:
                  </Text>
                  <NormalInputButton
                    Heading={"Growth rate"}
                    value={primaryResidenceGrowthRate}
                    onChange={(e) => {
                      setChangedData(true);
                      const noSymbol = e.target.value.replace("%", "");
                      const growthNum = parseFloat(noSymbol);
                      setPrimaryResidenceGrowthRate(
                        !isNaN(growthNum)
                          ? parseFloat(growthNum.toFixed(2))
                          : ""
                      );
                    }}
                    customWidth={"100%"}
                    InputWidth={"100%"}
                    placeholder={"%"}
                    usePercentage={true}
                    error={errors.primaryResidenceGrowthRate}
                    tooltipContent={TooltipContent.primaryResGrowth}
                  />
                  <Text
                    alignSelf={"stretch"}
                    color={"var(--Optiml---Deep-Ocean-Blue, #007EB5"}
                    fontFamily={"Nunito"}
                    fontSize={"16px"}
                    fontStyle={"normal"}
                    fontWeight={"600"}
                    lineHeight={"normal"}
                  >
                    Mortgage information:
                  </Text>
                  <NormalInputButton
                    Heading={"Remaining mortgage balance"}
                    value={primaryResidenceMortgageBalance}
                    onChange={(e) => {
                      setChangedData(true);
                      setPrimaryResidenceMortgageBalance(
                        moneyToNumber(e.target.value)
                      );
                    }}
                    customWidth={"100%"}
                    InputWidth={"100%"}
                    placeholder={"$"}
                    error={errors.primaryResidenceMortgageBalance}
                    useThousandSeparator={true}
                    tooltipContent={TooltipContent.primaryResMortgageBalance}
                  />
                  <NormalInputButton
                    Heading={"Monthly mortgage payment"}
                    value={primaryResidenceMonthlyPayment}
                    onChange={(e) => {
                      setChangedData(true);
                      setPrimaryResidenceMonthlyPayment(
                        moneyToNumber(e.target.value)
                      );
                    }}
                    customWidth={"100%"}
                    InputWidth={"100%"}
                    placeholder={"$"}
                    useThousandSeparator={true}
                    error={errors.primaryResidenceMonthlyPayment}
                    tooltipContent={TooltipContent.primaryResMonthlyMortgage}
                  />
                  <NormalInputButton
                    Heading={"Mortgage interest rate"}
                    value={primaryResidenceInterestRate}
                    onChange={(e) => {
                      setChangedData(true);
                      const noSymbol = e.target.value.replace("%", "");
                      const growthNum = parseFloat(noSymbol);
                      setPrimaryResidenceInterestRate(
                        !isNaN(growthNum)
                          ? parseFloat(growthNum.toFixed(2))
                          : ""
                      );
                    }}
                    customWidth={"100%"}
                    InputWidth={"100%"}
                    placeholder={"%"}
                    usePercentage={true}
                    error={errors.primaryResidenceInterestRate}
                    tooltipContent={TooltipContent.primaryResInterestRate}
                  />
                  <Text
                    alignSelf={"stretch"}
                    color={"var(--Optiml---Deep-Ocean-Blue, #007EB5"}
                    fontFamily={"Nunito"}
                    fontSize={"16px"}
                    fontStyle={"normal"}
                    fontWeight={"600"}
                    lineHeight={"normal"}
                  >
                    Future Real Estate Plans
                  </Text>
                  <DropDownButton
                    Heading={
                      "Do you have plans to sell your primary residence?"
                    }
                    placeholder={"Select"}
                    dropDownItems={[
                      { key: "Yes", value: "Yes" },
                      { key: "No", value: "No" },
                    ]}
                    value={primaryResidencePlansToSell}
                    onChange={(key) => {
                      setChangedData(true);
                      setPrimaryResidencePlansToSell(key);
                    }}
                    customWidth={"100%"}
                    error={errors.primaryResidencePlansToSell}
                    MenuCustomWidth={"100%"}
                    tooltipContent={TooltipContent.primaryResSaleYesNo}
                  />
                  {primaryResidencePlansToSell === "Yes" && (
                    <DropDownButton
                      Heading={
                        "When do you anticipate you may sell your primary residence?"
                      }
                      placeholder={"Select"}
                      dropDownItems={Select(currentAge)}
                      value={primaryResidencePlansToSellYear}
                      onChange={(key) => {
                        setChangedData(true);
                        setPrimaryResidencePlansToSellYear(key);
                      }}
                      customWidth={"100%"}
                      error={errors.primaryResidencePlansToSellYear}
                      MenuCustomWidth={"100%"}
                      tooltipContent={TooltipContent.primaryResSaleAge}
                    />
                  )}
                </Box>
              </>
            }
            videoName={"Primary Residence Page"}
            video={"Primary_Residence.mp4"}
          />
        </VStack>
      )}
    </Flex>
  );
});

export default PrimaryResidencePage;
