import { Box, Flex, VStack } from "@chakra-ui/layout";
import { Icon, Text, Tooltip, useMediaQuery } from "@chakra-ui/react";
import { useAuth0 } from "@auth0/auth0-react";
import axios from "axios";
import React, {
  forwardRef,
  useContext,
  useEffect,
  useImperativeHandle,
  useState,
} from "react";
import { useStore } from "../../../store";
import {
  AddAssetsIcon,
  BusinessAssetsIcon,
  CloseIcon,
  CottageIcon,
  InputBoxTitleBubbleTip,
  InvestmentFundIcon,
} from "../../CustomIcons";
import GlobalBodyPage from "../../GlobalContainer/BodyPage";
import Link from "../../Link";
import { StripeContext } from "../../StripeContext";
import StripeUpgradeModal from "../../StripeUpgradeModal";
import TableComponent from "../../TableComponent";

const BusinessPage = forwardRef((props, ref) => {
  const { user, getAccessTokenSilently } = useAuth0();

  useImperativeHandle(ref, () => ({
    submitHandler,
    checkMandatoryFields,
  }));

  const checkMandatoryFields = () => {
    return true;
  };

  const submitHandler = () => {
    setUserEditAssetInfo({});
    return true;
  };

  const axiosInstance = axios.create();
  async function getToken() {
    // Replace this with your Auth0 token retrieval logic
    const token = await getAccessTokenSilently({
      audience:
        "https://os2lsiwff43wfpb5ggyawt6qri0mgcro.lambda-url.us-east-2.on.aws/",
      scope: "write:optimization",
    });
    return token;
  }

  // Add a request interceptor
  axiosInstance.interceptors.request.use(
    async (config) => {
      const token = await getToken();
      config.headers.Authorization = `Bearer ${token}`;
      return config;
    },
    (error) => {
      // Do something with request error
      return Promise.reject(error);
    }
  );
  const [showStripeModal, setShowStripeModal] = useState(false);
  const [isLargerThan768] = useMediaQuery("(min-width: 500px)");
  const isMobile = !isLargerThan768;

  const [businessArray, setBusinessArray] = useState([]);
  const { stripePlan, setStripePlan } = useContext(StripeContext);

  const [editing, setEditing] = useState(false);
  const [editingIndex, setEditingIndex] = useState(null);
  const [showDeleteModal, setShowDeleteModal] = useState(null);

  const userBusinessInfo = useStore((store) => store.userBusinessInfo);
  const setUserBusinessInfo = useStore((store) => store.setUserBusinessInfo);
  const updateUserBusinessInfo = useStore(
    (store) => store.updateUserBusinessInfo
  );
  const deleteUserBusinessInfo = useStore(
    (store) => store.deleteUserBusinessInfo
  );

  const userCompanyInfo = useStore((store) => store.userCompanyInfo);
  const setUserCompanyInfo = useStore((store) => store.setUserCompanyInfo);
  const updateUserCompanyInfo = useStore(
    (store) => store.updateUserCompanyInfo
  );
  const deleteUserCompanyInfo = useStore(
    (store) => store.deleteUserCompanyInfo
  );

  const userEditAssetInfo = useStore((store) => store.userEditAssetInfo);
  const setUserEditAssetInfo = useStore((store) => store.setUserEditAssetInfo);

  function UpgradeStripeModal() {
    setShowStripeModal(!showStripeModal);
  }

  useEffect(() => {
    const updateBusinessArray = [];

    if (userBusinessInfo != null) {
      updateBusinessArray.push(...userBusinessInfo);
    }

    if (userCompanyInfo != null) {
      updateBusinessArray.push(...userCompanyInfo);
    }

    setBusinessArray(updateBusinessArray);
  }, [userBusinessInfo, userCompanyInfo]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [businessArray]);

  const Assets = [
    {
      title: "Business",
      icon: BusinessAssetsIcon,
      path: "/business-form",
      stripe:
        stripePlan?.items?.data?.[0]?.price?.lookup_key === "PREMIUM" ||
        stripePlan?.items?.data?.[0]?.price?.lookup_key === "PREMIUM_M" ||
        stripePlan?.items?.data?.[0]?.price?.lookup_key === "PRO_M" ||
        stripePlan?.items?.data?.[0]?.price?.lookup_key === "PRO",
    },
    {
      title: "Investment Fund",
      icon: InvestmentFundIcon,
      path: "/investment-funds-form",
      stripe: stripePlan?.items?.data?.[0]?.price?.lookup_key === "PREMIUM" || stripePlan?.items?.data?.[0]?.price?.lookup_key === "PREMIUM_M",
    },
  ];

  const onDeleteRow = (i) => {
    setEditingIndex(i);
    setShowDeleteModal(true);
  };

  useEffect(() => {
    setUserEditAssetInfo({});
  }, []);

  const editField = (i, array) => {
    const data = {
      list: array[i],
      editingIndex: i,
    };
    setUserEditAssetInfo(data);
    if (array[i].name === "Company") {
      window.history.pushState({}, "", "/business-form");
      const navEvent = new PopStateEvent("popstate");
      window.dispatchEvent(navEvent);
    } else if (array[i].name === "Investment Funds") {
      window.history.pushState({}, "", "/investment-funds-form");
      const navEvent = new PopStateEvent("popstate");
      window.dispatchEvent(navEvent);
    }
  };

  const DeleteRealEstateModal = () => {
    let activeArray = businessArray;

    const removeRealEstateModal = () => {
      if (activeArray[editingIndex].name === "Investment Funds") {
        const deletePropertyURL = (action) =>
          `${process.env.REACT_APP_NODE_API_GATEWAY}/CompassLambdaApi/BUSINESS/${action}?businessNumber=${activeArray[editingIndex].businessNumber}`;
        axiosInstance.get(deletePropertyURL("delete")).then((res) => {
          console.log("Delete res", res);
          deleteUserBusinessInfo(businessArray[editingIndex].businessNumber);
          setEditingIndex(null);
          setShowDeleteModal(false);
          setEditing(false);
        });
      } else if (activeArray[editingIndex].name === "Company") {
        const deletePropertyURL = (action) =>
          `${process.env.REACT_APP_NODE_API_GATEWAY}/CompassLambdaApi/COMPANY/${action}?companyNumber=${activeArray[editingIndex].companyNumber}`;
        axiosInstance.get(deletePropertyURL("delete")).then((res) => {
          console.log("Delete res", res);
          deleteUserCompanyInfo(businessArray[editingIndex].companyNumber);
          setEditingIndex(null);
          setShowDeleteModal(false);
          setEditing(false);
        });
      }
    };

    if (!showDeleteModal) {
      return null;
    }

    const [yesClicked, setYesClicked] = useState(false);
    const [noClicked, setNoClicked] = useState(false);
    return (
      <div
        className="delete-income-modal"
        style={{
          display: "flex",
          alignItems: "center",
          width: "100%",
          maxWidth: "700px",
          justifyContent: "center",
          top: "50%",
          left: "50%",
          transform: isMobile
            ? "translate(-50%, -50%)"
            : "translate(-50%, -50%)",
        }}
        id="delete-income-modal"
      >
        <Box
          display={"flex"}
          width={"100%"}
          padding={"10px 10px 0px 0px"}
          marginLeft={"5px"}
          alignItems={"flex-end"}
          justifyContent={"flex-end"}
          onClick={() => setShowDeleteModal(false)}
          cursor={"pointer"}
        >
          <CloseIcon
            onClick={() => setShowDeleteModal(false)}
            svgheight={"20px"}
            svgwidth={"20px"}
          />
        </Box>
        <Box
          display={"flex"}
          padding={"0px 25px 25px 25px"}
          flexDirection={"column"}
          alignItems={"center"}
          gap={"10px"}
          alignSelf={"stretch"}
          width={"100%"}
        >
          <Text
            color={"#0093D0"}
            fontFamily={"Nunito"}
            fontSize={"24px"}
            fontStyle={"normal"}
            fontWeight={"600"}
            lineHeight={"normal"}
            textDecor={"underline"}
          >
            Are you sure you want to remove this business asset?
          </Text>
          <div className="content"></div>
          <div className="actions">
            <button
              className={`decision-button yes-button ${
                yesClicked ? "yes-clicked" : ""
              }`}
              onClick={() => {
                setYesClicked(true);
                setNoClicked(false);
                removeRealEstateModal();
              }}
            >
              Yes
            </button>
            <button
              className={`decision-button no-button ${
                noClicked ? "yes-clicked" : ""
              }`}
              onClick={() => {
                {
                  setNoClicked(true);
                  setYesClicked(false);
                  setShowDeleteModal(false);
                }
              }}
            >
              No
            </button>
          </div>
        </Box>
      </div>
    );
  };

  const columns = [
    { header: "Name", field: "name" },
    { header: "Value", field: "value" },
  ];

  const data = businessArray.map((item) => ({
    name: item?.companyName || item?.businessName,
    value: item?.value || item?.ccpcValue,
    link:
      item?.name === "Company" ? "/business-form" : "/investment-funds-form",
  }));

  return (
    <Flex
      display="flex"
      flexWrap="wrap" // Enable wrapping
      justifyContent="flex-start"
      alignItems="start"
      width={"100%"}
    >
      {!isMobile && (
        <VStack alignItems={"flex-start"} width={"100%"} mt={-2}>
          <GlobalBodyPage
            Heading="Business"
            mainframeContent={
              <>
                <StripeUpgradeModal
                  isOpen={showStripeModal}
                  closeModal={UpgradeStripeModal}
                />
                <Box
                  display={"flex"}
                  width={"100%"}
                  padding={"10px"}
                  alignItems={"flex-start"}
                  alignContent={"flex-start"}
                  rowGap={"10px"}
                  gap={"10px"}
                  alignSelf={"stretch"}
                  flexWrap={"wrap"}
                  flex={"1 0 auto"}
                  borderRadius={"10px"}
                  border={"1px solid var(--Optiml---Vibrant-Sky-Blue, #00A2D4)"}
                  bg={"#FFF"}
                >
                  <DeleteRealEstateModal />
                  {data.length > 0 && (
                    <TableComponent
                      columns={columns}
                      data={data}
                      editField={editField}
                      onDeleteRow={onDeleteRow}
                      rawData={businessArray}
                    />
                  )}
                  {/* Assets Box */}
                  {Assets.map((asset) => (
                    <Box
                      display={"flex"}
                      minH={"121px"}
                      flexDirection={"column"}
                      padding={"4px"}
                      gap={"5px"}
                      alignItems={"center"}
                      borderRadius={"10px"}
                      border={"1px solid #0093D0"}
                      bg={"#F3FAFF"}
                      boxShadow={"0px 4px 4px 0px #00A2D4"}
                    >
                      <Box
                        display={"flex"}
                        justifyContent={"flex-end"}
                        alignItems={"flex-start"}
                        gap={"10px"}
                        alignSelf={"stretch"}
                      >
                        <Tooltip
                          label="Click to add you business or business investments"
                          placement="right"
                          hasArrow
                        >
                          <span>
                            <Icon
                              as={InputBoxTitleBubbleTip}
                              cursor={"pointer"}
                              w={"9px"}
                              h={"9px"}
                            />
                          </span>
                        </Tooltip>
                      </Box>
                      <Box
                        display={"flex"}
                        justifyContent={"center"}
                        alignItems={"center"}
                        w={"40px"}
                        h={"40px"}
                      >
                        <Icon as={asset.icon} />
                      </Box>
                      <Box
                        display={"flex"}
                        width={"105px"}
                        height={"17px"}
                        flexDirection={"column"}
                        justifyContent={"center"}
                      >
                        <Text
                          color={"#00466D"}
                          fontFamily={"Nunito"}
                          fontSize={"13px"}
                          fontStyle={"normal"}
                          fontWeight={"700"}
                          lineHeight={"normal"}
                          textAlign={"center"}
                        >
                          {asset.title}
                        </Text>
                      </Box>

                      {/* Add Button Box */}
                      {asset.stripe && (
                        <Link href={asset.path}>
                          <Box
                            display={"flex"}
                            padding={"3px 20px"}
                            justifyContent={"center"}
                            alignItems={"center"}
                            gap={"6px"}
                            alignSelf={"stretch"}
                            borderRadius={"5px"}
                            border={"1px solid #00A2D4"}
                            bg={"#FFF"}
                            cursor={"pointer"}
                          >
                            <Icon as={AddAssetsIcon} w={"12px"} h={"12px"} />
                            <Text
                              color={"#00A2D4"}
                              fontFamily={"Nunito"}
                              fontSize={"10px"}
                              fontStyle={"normal"}
                              fontWeight={"700"}
                              lineHeight={"normal"}
                            >
                              {asset.title === "Investment Fund" &&
                              userBusinessInfo?.length > 0
                                ? "Edit"
                                : "Add"}
                            </Text>
                          </Box>
                        </Link>
                      )}
                      {!asset.stripe && (
                        <Box
                          display={"flex"}
                          padding={"3px 20px"}
                          justifyContent={"center"}
                          alignItems={"center"}
                          gap={"6px"}
                          alignSelf={"stretch"}
                          borderRadius={"5px"}
                          border={"1px solid #00A2D4"}
                          bg={"#FFF"}
                          cursor={"pointer"}
                          onClick={UpgradeStripeModal}
                        >
                          <Icon as={AddAssetsIcon} w={"12px"} h={"12px"} />
                          <Text
                            color={"#00A2D4"}
                            fontFamily={"Nunito"}
                            fontSize={"10px"}
                            fontStyle={"normal"}
                            fontWeight={"700"}
                            lineHeight={"normal"}
                          >
                            Upgrade
                          </Text>
                        </Box>
                      )}
                    </Box>
                  ))}
                </Box>
              </>
            }
          />
        </VStack>
      )}
      {isMobile && (
        <VStack alignItems={"flex-start"} width={"100%"} mt={-2}>
          <GlobalBodyPage
            Heading="Assets"
            mainframeContent={
              <>
                <StripeUpgradeModal
                  isOpen={showStripeModal}
                  closeModal={UpgradeStripeModal}
                />
                <Box
                  display={"flex"}
                  width={"100%"}
                  padding={"10px"}
                  flexDirection={"row"}
                  justifyContent={"center"}
                  alignItems={"flex-start"}
                  alignContent={"flex-start"}
                  rowGap={"10px"}
                  gap={"5px"}
                  alignSelf={"stretch"}
                  flexWrap={"wrap"}
                  flex={"1 0 auto"}
                  borderRadius={"10px"}
                  border={"1px solid var(--Optiml---Vibrant-Sky-Blue, #00A2D4)"}
                  bg={"#FFF"}
                >
                  <DeleteRealEstateModal />
                  {data.length > 0 && (
                    <TableComponent
                      columns={columns}
                      data={data}
                      editField={editField}
                      onDeleteRow={onDeleteRow}
                      rawData={businessArray}
                    />
                  )}
                  {/* Assets Box */}
                  {Assets.map((asset) => (
                    <Box
                      display={"flex"}
                      minH={"121px"}
                      padding={"10px"}
                      flexDirection={"column"}
                      alignItems={"center"}
                      gap={"5px"}
                      borderRadius={"10px"}
                      border={"1px solid #0093D0"}
                      bg={"#F3FAFF"}
                      boxShadow={"0px 4px 4px 0px #00A2D4"}
                    >
                      <Box
                        display={"flex"}
                        justifyContent={"flex-end"}
                        alignItems={"flex-start"}
                        gap={"10px"}
                        alignSelf={"stretch"}
                      >
                        <Tooltip
                          label="Click to add you business or business investments"
                          placement="right"
                          hasArrow
                        >
                          <span>
                            <Icon
                              as={InputBoxTitleBubbleTip}
                              cursor={"pointer"}
                              w={"9px"}
                              h={"9px"}
                            />
                          </span>
                        </Tooltip>
                      </Box>
                      <Box
                        display={"flex"}
                        justifyContent={"center"}
                        alignItems={"center"}
                        w={"40px"}
                        h={"40px"}
                      >
                        <Icon as={asset.icon} />
                      </Box>
                      <Box
                        display={"flex"}
                        width={"105px"}
                        height={"17px"}
                        flexDirection={"column"}
                        justifyContent={"center"}
                      >
                        <Text
                          color={"#00466D"}
                          fontFamily={"Nunito"}
                          fontSize={"13px"}
                          fontStyle={"normal"}
                          fontWeight={"700"}
                          lineHeight={"normal"}
                          textAlign={"center"}
                        >
                          {asset.title}
                        </Text>
                      </Box>

                      {/* Add Button Box */}
                      {asset.stripe && (
                        <Link href={asset.path}>
                          <Box
                            display={"flex"}
                            padding={"3px 20px"}
                            justifyContent={"center"}
                            alignItems={"center"}
                            gap={"6px"}
                            alignSelf={"stretch"}
                            borderRadius={"5px"}
                            border={"1px solid #00A2D4"}
                            bg={"#FFF"}
                            cursor={"pointer"}
                          >
                            <Icon as={AddAssetsIcon} w={"12px"} h={"12px"} />
                            <Text
                              color={"#00A2D4"}
                              fontFamily={"Nunito"}
                              fontSize={"10px"}
                              fontStyle={"normal"}
                              fontWeight={"700"}
                              lineHeight={"normal"}
                            >
                              {asset.title === "Investment Fund" &&
                              userBusinessInfo?.length > 0
                                ? "Edit"
                                : "Add"}
                            </Text>
                          </Box>
                        </Link>
                      )}
                      {!asset.stripe && (
                        <Box
                          display={"flex"}
                          padding={"3px 20px"}
                          justifyContent={"center"}
                          alignItems={"center"}
                          gap={"6px"}
                          alignSelf={"stretch"}
                          borderRadius={"5px"}
                          border={"1px solid #00A2D4"}
                          bg={"#FFF"}
                          cursor={"pointer"}
                        >
                          <Icon as={AddAssetsIcon} w={"12px"} h={"12px"} />
                          <Text
                            color={"#00A2D4"}
                            fontFamily={"Nunito"}
                            fontSize={"10px"}
                            fontStyle={"normal"}
                            fontWeight={"700"}
                            lineHeight={"normal"}
                          >
                            Add
                          </Text>
                        </Box>
                      )}
                    </Box>
                  ))}
                </Box>
              </>
            }
          />
        </VStack>
      )}
    </Flex>
  );
});

export default BusinessPage;
