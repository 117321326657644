import { useAuth0 } from "@auth0/auth0-react";
import {
  Box,
  Button,
  HStack,
  Icon,
  Text,
  VStack,
  useMediaQuery,
} from "@chakra-ui/react";
import axios from "axios";
import isEqual from "lodash/isEqual";
import React, { useContext, useEffect, useState } from "react";
import Modal from "../components/Modal";
import InvestmentImageOne from "../img/rectangle-6518@2x.svg";
import TrashCanIcon from "../img/trash-can-icon.png";
import { useStore } from "../store";
import { CloseIcon, CSVDownloadIcon, SaveAnalysisIcon } from "./CustomIcons";
import "./Expenses.css";
import DropDownButton from "./InputFields/dropDownButton";
import SaveAnalysisModal from "./Save Analysis Modal";
import { SpouseContext } from "./SpouseContext";
import { StripeContext } from "./StripeContext";

function AnalysisArchive() {
  const { getAccessTokenSilently } = useAuth0();
  const { spouseStatus, setSpouseStatus } = useContext(SpouseContext);
  const { stripePlan, setStripePlan } = useContext(StripeContext);

  const axiosInstance = axios.create();
  async function getToken() {
    // Replace this with your Auth0 token retrieval logic
    const token = await getAccessTokenSilently({
      audience:
        "https://os2lsiwff43wfpb5ggyawt6qri0mgcro.lambda-url.us-east-2.on.aws/",
      scope: "write:optimization",
    });
    return token;
  }

  // Add a request interceptor
  axiosInstance.interceptors.request.use(
    async (config) => {
      const token = await getToken();
      config.headers.Authorization = `Bearer ${token}`;
      return config;
    },
    (error) => {
      // Do something with request error
      return Promise.reject(error);
    }
  );

  const [showModal, setShowModal] = useState(false);
  const [archiveArray, setArchiveArray] = useState();
  const [editingIndex, setEditingIndex] = useState(null);
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const setShowNewOptimization = useStore(
    (store) => store.setShowNewOptimization
  );
  const userArchiveInfo = useStore((store) => store.userArchiveInfo);
  const setUserArchiveInfo = useStore((store) => store.setUserArchiveInfo);
  const deleteUserArchiveInfo = useStore(
    (store) => store.deleteUserArchiveInfo
  );
  const setUserOptiData = useStore((store) => store.setUserOptiData);
  const userOptiData = useStore((store) => store.userOptiData);
  const setUserInsightsData = useStore((store) => store.setUserInsightsData);
  const userInfo = useStore((store) => store.userInfo);
  setShowNewOptimization(false);

  useEffect(() => {
    if (userArchiveInfo != null) {
      setArchiveArray(userArchiveInfo);
    }
  }, [userArchiveInfo]);

  const currencyFormat = (money) => {
    if (money == "") {
      return "$0";
    } else {
      const intTotal = Math.round(Math.round(money * 100) / 100);
      const currencyCad = new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "USD",
        minimumFractionDigits: 0,
      }).format(intTotal);
      return currencyCad;
    }
  };

  const onDeleteRow = (i) => {
    setEditingIndex(i);
    setShowDeleteModal(true);
  };

  const removeField = (archive) => {
    setArchiveArray(prevArray => prevArray.filter(item => item.Archive_Number !== archive.Archive_Number));
    setShowDeleteModal(false);
  };
  

  const DeleteArchiveModal = () => {
    const removeArchiveModal = () => {
      const deleteArchiveUrl = (action) =>
        `${process.env.REACT_APP_NODE_API_GATEWAY}/CompassLambdaApi/ANALYSIS_ARCHIVE/${action}?Archive_Number=${editingIndex.Archive_Number}`;
      axiosInstance.get(deleteArchiveUrl("delete")).then((res) => {
        deleteUserArchiveInfo(editingIndex.Archive_Number);
        removeField(editingIndex);
        setEditingIndex(null);
      });
    };

    if (!showDeleteModal) {
      return null;
    }
    const [yesClicked, setYesClicked] = useState(false);
    const [noClicked, setNoClicked] = useState(false);
    return (
      <div
        className="delete-income-modal"
        style={{
          display: "flex",
          alignItems: "center",
          width:"100%",
          maxWidth:"700px",
          justifyContent: "center",
          top: "50%",
          left: "50%",
          transform: isMobile
            ? "translate(-50%, -50%)"
            : "translate(-50%, -50%)",
        }}
        id="delete-income-modal"
      >
        <Box
          display={"flex"}
          width={"100%"}
          padding={"10px 10px 0px 0px"}
          marginLeft={"5px"}
          alignItems={"flex-end"}
          justifyContent={"flex-end"}
          onClick={() => setShowDeleteModal(false)}
          cursor={"pointer"}
        >
          <CloseIcon
            onClick={() => setShowDeleteModal(false)}
            svgheight={"20px"}
            svgwidth={"20px"}
          />
        </Box>
        <Box
          display={"flex"}
          padding={"0px 25px 25px 25px"}
          flexDirection={"column"}
          alignItems={"center"}
          gap={"10px"}
          alignSelf={"stretch"}
          width={"100%"}
        >
          <Text
            color={"#0093D0"}
            fontFamily={"Nunito"}
            fontSize={"24px"}
            fontStyle={"normal"}
            fontWeight={"600"}
            lineHeight={"normal"}
            textDecor={"underline"}
            textAlign={"center"}
          >
          Are you sure you want to delete this analysis from your archive?
          </Text>
        <div className="content"></div>
        <div className="actions">
          <button
            className={`decision-button yes-button ${
              yesClicked ? "yes-clicked" : ""
            }`}
            onClick={() => {
              setYesClicked(true);
              setNoClicked(false);
              removeArchiveModal();
            }}
          >
            Yes
          </button>
          <button
            className={`decision-button no-button ${
              noClicked ? "yes-clicked" : ""
            }`}
            onClick={() => {
              {
                setNoClicked(true);
                setYesClicked(false);
                setShowDeleteModal(false);
              }
            }}
          >
            No
          </button>
        </div>
      </Box>
      </div>
    );
  };

  function launchAnalysis(analysisData) {
    setUserOptiData({
      user_opti_data: analysisData?.Archive_Opti_Data,
      spouse_opti_data: analysisData?.Archive_Spouse_Opti_Data,
      spouse1_ROT: analysisData?.Archive_ROT,
      spouse2_ROT: analysisData?.Archive_Spouse_ROT
    });
    setUserInsightsData({
      retireEarly: analysisData?.Archive_Retire_Early,
      retireLate: analysisData?.Archive_Retire_Late
    });
    window.history.pushState({}, "", "/dashboard-page");
    window.dispatchEvent(new PopStateEvent("popstate"));
  }

  const [isLargerThan500] = useMediaQuery("(min-width: 500px)");
  const isMobile = !isLargerThan500;

  const [saveAnalysisModal, setSaveAnalysisModal] = useState(false);
  const [currentAnalysis, setCurrentAnalysis] = useState();

  function remainingSaves() {
    if ((stripePlan?.items?.data?.[0]?.price?.lookup_key === "PREMIUM" || stripePlan?.items?.data?.[0]?.price?.lookup_key === "PREMIUM_M")) {
      return 20 - userArchiveInfo.length;
    }
    if ((stripePlan?.items?.data?.[0]?.price?.lookup_key === "PRO" || stripePlan?.items?.data?.[0]?.price?.lookup_key === "PRO_M")) {
      return 10 - userArchiveInfo.length;
    } else {
      return 10;
    }
  }

  function openSaveAnalysis() {
    setSaveAnalysisModal(true);
  }

  function closeSaveAnalysisModal() {
    setSaveAnalysisModal(false);
    if (userArchiveInfo?.length >= 1) {
      launchAnalysis(currentAnalysis);
    }
  }

  function closeModalX() {
    setSaveAnalysisModal(false);
  }

  function checkIfAnalysisIsSaved() {
    return userArchiveInfo.some((item) => {
      if (spouseStatus) {
        return (
          isEqual(
            userOptiData?.primaryUserData?.sps1_nonregisteredWithdrawals,
            JSON.parse(item?.Archive_Opti_Data)?.sps1_nonregisteredWithdrawals
          ) &&
          isEqual(
            userOptiData?.spouseData?.sps2_nonregisteredWithdrawals,
            JSON.parse(item?.Archive_Spouse_Opti_Data)
              ?.sps2_nonregisteredWithdrawals
          ) &&
          isEqual(
            userOptiData?.primaryUserData?.sps1_provincialTaxes,
            JSON.parse(item?.Archive_Opti_Data)?.sps1_provincialTaxes
          ) &&
          isEqual(
            userOptiData?.primaryUserData?.optimizedEOLAmount,
            JSON.parse(item?.Archive_Opti_Data)?.optimizedEOLAmount
          )
        );
      } else {
        return (
          isEqual(
            userOptiData?.primaryUserData?.sps1_nonregisteredWithdrawals,
            JSON.parse(item?.Archive_Opti_Data)?.sps1_nonregisteredWithdrawals
          ) &&
          isEqual(
            userOptiData?.primaryUserData?.sps1_provincialTaxes,
            JSON.parse(item?.Archive_Opti_Data)?.sps1_provincialTaxes
          ) &&
          isEqual(
            userOptiData?.primaryUserData?.optimizedEOLAmount,
            JSON.parse(item?.Archive_Opti_Data)?.optimizedEOLAmount
          )
        );
      }
    });
  }

  const [isLargerThan1200] = useMediaQuery(
    "(min-width: 500px) and (max-width: 1280px)"
  );

  function formatDate(dateString) {
    const date = new Date(dateString);
    const options = { month: "long", day: "numeric", year: "numeric" };
    const formatted = date?.toLocaleDateString("en-US", options);

    const day = date?.getDate();
    let suffix = "th";
    if (day === 1 || day === 21 || day === 31) {
      suffix = "st";
    } else if (day === 2 || day === 22) {
      suffix = "nd";
    } else if (day === 3 || day === 23) {
      suffix = "rd";
    }

    const dayWithSuffix = day + suffix;

    return formatted.replace(day.toString(), dayWithSuffix);
  }

  const [launch, setLaunch] = useState(false);

  const [yearOptions, setYearOptions] = useState([]);
  const [selectedYear, setSelectedYear] = useState("2024");

  useEffect(() => {
    if (archiveArray) {
      const years = new Set();
      archiveArray?.forEach((analysis) => {
        const yearMatch = analysis?.Archive_Date.match(/\d{4}$/);
        if (yearMatch) {
          years.add(yearMatch[0]);
        }
      });
      const sortedYears = Array.from(years).sort();
      setYearOptions(sortedYears);
    }
  }, [archiveArray]);

  // Function to parse the year from the date string
  const getYearFromDateString = (dateString) => {
    const match = dateString.match(/\d{4}$/);
    return match ? match[0] : null;
  };

  // Filter the data based on the selected year
  const filteredData = archiveArray?.filter(
    (analysis) => getYearFromDateString(analysis?.Archive_Date) === selectedYear
  );

  return (
    <>
      <Modal show={showModal} onClose={() => setShowModal(false)} />
      <SaveAnalysisModal
        isOpen={saveAnalysisModal}
        closeModal={closeSaveAnalysisModal}
        closeModalX={closeModalX}
        remainingSaves={remainingSaves()}
        optiList={userOptiData?.primaryUserData}
        spouseOptiList={userOptiData?.spouseData}
        launch={launch}
      />
      <DeleteArchiveModal />
      {archiveArray?.length > 0 && (
        <DropDownButton
          placeholder="Year"
          value={selectedYear}
          dropDownItems={yearOptions.map((year) => ({
            key: year,
            value: year,
          }))}
          onChange={(key) => setSelectedYear(key)}
          customWidth="50%"
          MenuCustomWidth="50%"
          zIndex={100}
        />
      )}
      {archiveArray?.length > 0 && (
        <table style={{ border: "1px solid #0F9CD6", width: "100%" }}>
          <thead>
            <tr className="table100-head">
              <th
                className="column6-aa"
                style={{ textAlign: "left", width: "30%" }}
              >
                Name
              </th>
              <th className="column6-aa">Date</th>
              <th className="column6-aa mobile-hidden">Strategy</th>
              <th className="column6-aa mobile-hidden">Analysis Duration</th>
              <th
                className="column6-aa mobile-hidden"
                style={{ textAlign: "right" }}
              >
                Estate Value
              </th>

              <th className="column6-aa" style={{ textAlign: "right" }}>
                Launch
              </th>
              {!isMobile && (
                <th
                  className="column6-aa"
                  style={{ paddingRight: "8px", textAlign: "right" }}
                >
                  Remove
                </th>
              )}
            </tr>
          </thead>
          {filteredData && (
            <tbody>
              {filteredData
               ?.slice()  // Create a copy of the array to avoid mutating the original array directly
               ?.reverse()  // Reverse the order of the array
              ?.map((analysis, i) => {
                return (
                  <tr key={i}>
                    <td
                      className="column2-aa"
                      style={{
                        minWidth: "15rem",
                        textAlign: "start",
                        paddingLeft: isMobile && "9px",
                      }}
                    >
                      {analysis?.Archive_Name ? analysis?.Archive_Name : "-"}
                    </td>
                    <td className="column2-aa">{analysis?.Archive_Date}</td>
                    <td className="column2-aa mobile-hidden">
                      {analysis?.Archive_Strategy}
                    </td>
                    <td className="column2-aa mobile-hidden">
                      {analysis.Archive_Duration}
                    </td>
                    <td
                      className="column2-aa mobile-hidden"
                      style={{ textAlign: "right" }}
                    >
                      {currencyFormat(analysis?.Archive_Estate)}
                    </td>

                    <td
                      className="column2-aa"
                      // onClick={() => launchAnalysis(i)}
                      onClick={() => {
                        setLaunch(true);
                        if (remainingSaves() > 0 && !checkIfAnalysisIsSaved()) {
                          openSaveAnalysis();
                          setCurrentAnalysis(analysis);
                        }
                        if (remainingSaves() <= 0 || checkIfAnalysisIsSaved()) {
                          launchAnalysis(analysis);
                        }
                      }}
                      style={{
                        fontWeight: 800,
                        textDecoration: "underline",
                        fontSize: isMobile ? "16px" : "18px",
                        color: "#0F9CD6",
                        textAlign: "right",
                        paddingLeft: "10px",
                      }}
                    >
                      Launch
                    </td>
                    {!isMobile && (
                      <td
                        style={{
                          display: "flex",
                          textAlign: "end",
                          alignItems: "center",
                          justifyContent: "center",
                          paddingLeft: "40px",
                        }}
                        className=""
                        onClick={() => onDeleteRow(analysis)}
                      >
                        <img
                          src={TrashCanIcon}
                          style={{
                            cursor: "pointer",
                            width: "20px",
                            marginTop: "1rem",
                          }}
                          alt="remove-field-icon"
                        />
                      </td>
                    )}
                  </tr>
                );
              })}
            </tbody>
          )}
        </table>
      )}
      {archiveArray?.length === 0 && (
        <>
          {!isMobile && !isLargerThan1200 && (
            <Box
              display={"flex"}
              padding={"10px"}
              flexDirection={"column"}
              alignItems={"flex-start"}
              gap={"10px"}
              alignSelf={"stretch"}
              bg={"#FFF"}
            >
              <Text
                alignSelf={"stretch"}
                color={"#00466D"}
                fontFamily={"Nunito"}
                fontSize={"18px"}
                fontStyle={"normal"}
                fontWeight={"400"}
                lineHeight={"normal"}
              >
                It looks like you haven't saved any analyses to your account
                yet. To start building your archive, simply click on 'Save
                Analysis to my archive' from the dashboard home page.
              </Text>
              <div className="rect-7297-opti">
                <HStack justifyContent={"space-between"} width={"100%"}>
                  {" "}
                  <Box
                    display={"flex"}
                    flexDirection={"column"}
                    gap={"10px"}
                    flexGrow={1}
                  >
                    <HStack>
                      <Text
                        color={"#004069"}
                        fontSize={isLargerThan1200 ? "14px" : "20px"}
                        fontWeight={"600"}
                        fontFamily={"Nunito"}
                        fontStyle={"normal"}
                        lineHeight={"130%"}
                      >
                        Strategy:
                      </Text>

                      <Text
                        color={"#00A2D4"}
                        textAlign={"right"}
                        fontSize={isLargerThan1200 ? "14px" : "20px"}
                        fontFamily={"Nunito"}
                        fontStyle={"normal"}
                        fontWeight={"800"}
                        lineHeight={"130%"}
                      >
                        {userOptiData?.primaryUserData?.optimizationType}
                      </Text>
                    </HStack>
                    <HStack>
                      <Text
                        color={"#004069"}
                        fontSize={"20px"}
                        fontWeight={"600"}
                        fontFamily={"Nunito"}
                        fontStyle={"normal"}
                        lineHeight={"130%"}
                      >
                        Analysis date:
                      </Text>
                      <Text
                        color={"#00A2D4"}
                        textAlign={"right"}
                        fontSize={"20px"}
                        fontFamily={"Nunito"}
                        fontStyle={"normal"}
                        fontWeight={"800"}
                        lineHeight={"130%"}
                      >
                        {formatDate(userOptiData?.primaryUserData?.planRunDate)}
                      </Text>
                    </HStack>
                    <Box
                      display={"flex"}
                      gap={"5px"}
                      alignItems={isLargerThan1200 ? "flex-start" : "center"}
                      justifyContent={"flex-start"}
                      flexDirection={isLargerThan1200 ? "column" : "row"}
                    >
                      <Text
                        color={"#004069"}
                        fontFamily={"Nunito"}
                        fontSize={"20px"}
                        fontStyle={"normal"}
                        fontWeight={"600"}
                        lineHeight={"130%"}
                      >
                        {userInfo.FirstName} {userInfo.LastName}
                        {spouseStatus && (
                          <span>
                            , {userInfo.spouseFirstName}{" "}
                            {userInfo.spouseLastName}{" "}
                          </span>
                        )}
                      </Text>
                    </Box>
                  </Box>
                  <VStack>
                    <Button
                      w={"296px"}
                      h={"50px"}
                      flexShrink={0}
                      borderRadius={"5px"}
                      border={"1px solid #DADCE5"}
                      padding={"10px"}
                      justifyContent={"space-between"}
                      bg={"#fff"}
                      onClick={() => {
                        // Only call onSubmitAdd if remainingSaves is greater than 0
                        if (remainingSaves() > 0) {
                          openSaveAnalysis();
                        }
                      }}
                    >
                      <Icon as={SaveAnalysisIcon} w={"24px"} h={"20px"} />
                      <Box
                        display={"flex"}
                        w={"204px"}
                        h={"22px"}
                        flexDirection={"column"}
                        justifyContent={"center"}
                        flexShrink={0}
                      >
                        <Text
                          color={"#004069"}
                          fontSize={"14px"}
                          fontFamily={"Nunito"}
                          fontStyle={"normal"}
                          fontWeight={"600"}
                          lineHeight={"normal"}
                          letterSpacing={"0.14px"}
                        >
                          Save analysis to my archive
                        </Text>
                      </Box>
                      |
                      <Box
                        display={"flex"}
                        w={"22px"}
                        h={"22px"}
                        flexDirection={"column"}
                        justifyContent={"center"}
                        flexShrink={0}
                      >
                        <Text
                          color={"#7AC381"}
                          fontSize={"14px"}
                          fontFamily={"Nunito"}
                          fontStyle={"normal"}
                          fontWeight={"600"}
                          lineHeight={"normal"}
                          letterSpacing={"0.14px"}
                        >
                          10
                        </Text>
                      </Box>
                    </Button>
                    <HStack
                      h={"-webkit-fit-content"}
                      justifyContent={"flex-end"}
                      w={"100%"}
                    >
                      <Box
                        width={"83px"}
                        height={"40px"}
                        flexShrink={0}
                        border={"1px solid #DADCE5"}
                        borderRadius={"5px"}
                      >
                        <span
                          className="dashboard-download-btns"
                          style={{ color: "#00A2D4", padding: "9px" }}
                        >
                          <Icon as={CSVDownloadIcon} />
                          CSV
                        </span>
                      </Box>
                    </HStack>
                  </VStack>
                </HStack>
              </div>

              <Text
                alignSelf={"stretch"}
                color={"#00466D"}
                fontFamily={"Nunito"}
                fontSize={"18px"}
                fontStyle={"normal"}
                fontWeight={"400"}
                lineHeight={"normal"}
              >
                Once you save an analysis, it will be stored here for easy and
                continent access in the future.{" "}
              </Text>
            </Box>
          )}
          {(isMobile || isLargerThan1200) && (
            <>
              <Text
                alignSelf={"stretch"}
                color={"#00466D"}
                fontFamily={"Nunito"}
                fontSize={"18px"}
                fontStyle={"normal"}
                fontWeight={"400"}
                lineHeight={"normal"}
              >
                It looks like you haven't saved any analyses to your account
                yet. To start building your archive, simply click on 'Save
                Analysis to my archive'.
              </Text>
              <Button
                w={"296px"}
                h={"50px"}
                flexShrink={0}
                borderRadius={"5px"}
                border={"1px solid #DADCE5"}
                padding={"10px"}
                justifyContent={"space-between"}
                bg={"#fff"}
                onClick={() => {
                  // Only call onSubmitAdd if remainingSaves is greater than 0
                  if (remainingSaves() > 0) {
                    openSaveAnalysis();
                  }
                }}
              >
                <Icon as={SaveAnalysisIcon} w={"24px"} h={"20px"} />
                <Box
                  display={"flex"}
                  w={"204px"}
                  h={"22px"}
                  flexDirection={"column"}
                  justifyContent={"center"}
                  flexShrink={0}
                >
                  <Text
                    color={"#004069"}
                    fontSize={"14px"}
                    fontFamily={"Nunito"}
                    fontStyle={"normal"}
                    fontWeight={"600"}
                    lineHeight={"normal"}
                    letterSpacing={"0.14px"}
                  >
                    Save analysis to my archive
                  </Text>
                </Box>
                |
                <Box
                  display={"flex"}
                  w={"22px"}
                  h={"22px"}
                  flexDirection={"column"}
                  justifyContent={"center"}
                  flexShrink={0}
                >
                  <Text
                    color={"#7AC381"}
                    fontSize={"14px"}
                    fontFamily={"Nunito"}
                    fontStyle={"normal"}
                    fontWeight={"600"}
                    lineHeight={"normal"}
                    letterSpacing={"0.14px"}
                  >
                    10
                  </Text>
                </Box>
              </Button>
              <Text
                alignSelf={"stretch"}
                color={"#00466D"}
                fontFamily={"Nunito"}
                fontSize={"18px"}
                fontStyle={"normal"}
                fontWeight={"400"}
                lineHeight={"normal"}
              >
                Once you save an analysis, it will be stored here for easy and
                continent access in the future.{" "}
              </Text>
            </>
          )}
        </>
      )}
    </>
  );
}

export default AnalysisArchive;
