import ReactEcharts from "echarts-for-react";
import React, { useEffect, useState } from "react";
import FilterIcon from "../../img/filter-icon.png";
import { emphasize } from "@mui/material";

const currencyFormat = (money) => {
  if (money === 0) {
    return "$0"; // Handle zero explicitly
  } else if (money === "") {
    return "";
  } else {
    const intTotal = Math.round(Math.round(money * 100) / 100);
    const currencyCad = new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD",
      minimumFractionDigits: 0,
    }).format(intTotal);
    return currencyCad;
  }
};

function InsightGraph(props) {
  const {
    title,
    tfsaData,
    rottfsaData,
    tfsaContributionData,
    rottfsaContributionData,
    tfsaWithdrawalData,
    rottfsaWithdrawalData,
    fhsaData,
    rotfhsaData,
    fhsaContributionData,
    rotfhsaContributionData,
    fhsaWithdrawalData,
    rotfhsaWithdrawalData,
    rrspData,
    rotrrspData,
    rrspContributionData,
    rotrrspContributionData,
    rrspWithdrawalData,
    rotrrspWithdrawalData,
    nonRegData,
    rotnonRegData,
    nonRegContributionData,
    rotnonRegContributionData,
    nonRegWithdrawalData,
    rotnonRegWithdrawalData,
    // LIRA
    liraData,
    rotliraData,
    liraWithdrawalData,
    rotliraWithdrawalData,
    // LIRA
    totalPortfolioData,
    rottotalPortfolioData,
    totalPortfolioDataContribution,
    rottotalPortfolioDataContribution,
    totalPortfolioDataWithdrawal,
    rottotalPortfolioDataWithdrawal,
    graphClicked,
    currentAge,
    spouseAge,
    investmentPage,
  } = props;

  const xAxis = tfsaData?.map((item) => item.x);
  const currentYear = new Date().getFullYear();
  const xAxisWithAge = xAxis?.map((year) => {
    const age = currentAge + (year - currentYear);
    const spouse = spouseAge ? spouseAge + (year - currentYear) : "";
    return spouseAge ? `${year}\n(${age} | ${spouse})` : `${year}\n(${age})`;
  });
  const yAxisTFSA = tfsaData?.map((item) => item.y);
  const yAxisrotTFSA = rottfsaData?.map((item) => item.y);
  const yAxisTFSAContribution = tfsaContributionData?.map((item) => item.y);
  const yAxisrotTFSAContribution = rottfsaContributionData?.map(
    (item) => item.y
  );
  const yAxisTFSAWithdrawal = tfsaWithdrawalData?.map((item) => item.y);
  const yAxisrotTFSAWithdrawal = rottfsaWithdrawalData?.map((item) => item.y);
  const yAxisFHSA = fhsaData?.map((item) => item.y);
  const yAxisrotFHSA = rotfhsaData?.map((item) => item.y);
  const yAxisFHSAContribution = fhsaContributionData?.map((item) => item.y);
  const yAxisrotFHSAContribution = rotfhsaContributionData?.map(
    (item) => item.y
  );
  const yAxisFHSAWithdrawal = fhsaWithdrawalData?.map((item) => item.y);
  const yAxisrotFHSAWithdrawal = rotfhsaWithdrawalData?.map((item) => item.y);
  const yAxisRRSP = rrspData?.map((item) => item.y);
  const yAxisrotRRSP = rotrrspData?.map((item) => item.y);
  const yAxisRRSPContribution = rrspContributionData?.map((item) => item.y);
  const yAxisrotRRSPContribution = rotrrspContributionData?.map(
    (item) => item.y
  );
  const yAxisRRSPWithdrawal = rrspWithdrawalData?.map((item) => item.y);
  const yAxisrotRRSPWithdrawal = rotrrspWithdrawalData?.map((item) => item.y);
  const yAxisNonReg = nonRegData?.map((item) => item.y);
  const yAxisrotNonReg = rotnonRegData?.map((item) => item.y);
  const yAxisNonRegContribution = nonRegContributionData?.map((item) => item.y);
  const yAxisrotNonRegContribution = rotnonRegContributionData?.map(
    (item) => item.y
  );
  const yAxisNonRegWithdrawal = nonRegWithdrawalData?.map((item) => item.y);
  const yAxisrotNonRegWithdrawal = rotnonRegWithdrawalData?.map(
    (item) => item.y
  );
  // LIRA
  const yAxisLIRA = liraData?.map((item) => item.y);
  const yAxisrotLIRA = rotliraData?.map((item) => item.y);
  const yAxisLIRAWithdrawal = liraWithdrawalData?.map((item) => item.y);
  const yAxisrotLIRAWithdrawal = rotliraWithdrawalData?.map((item) => item.y);
  // LIRA
  const yAxisTotalPortfolio = totalPortfolioData?.map((item) => item.y);
  const yAxisrotTotalPortfolio = rottotalPortfolioData?.map((item) => item.y);
  const yAxisTotalPortfolioContribution = totalPortfolioDataContribution?.map(
    (item) => item.y
  );
  const yAxisrotTotalPortfolioContribution =
    rottotalPortfolioDataContribution?.map((item) => item.y);
  const yAxisTotalPortfolioWithdrawal = totalPortfolioDataWithdrawal?.map(
    (item) => item.y
  );
  const yAxisrotTotalPortfolioWithdrawal = rottotalPortfolioDataWithdrawal?.map(
    (item) => item.y
  );

  const [showDropdown, setShowDropdown] = useState(false);
  const [showAll, setShowAll] = useState(true);
  const [showAllContribution, setShowAllContribution] = useState(false);
  const [showAllWithdrawal, setShowAllWithdrawal] = useState(false);
  const [showTFSA, setShowTFSA] = useState(false);
  const [showContributionTFSA, setShowContributionTFSA] = useState(false);
  const [showWithdrawalTFSA, setShowWithdrawalTFSA] = useState(false);
  const [showFHSA, setShowFHSA] = useState(false);
  const [showContributionFHSA, setShowContributionFHSA] = useState(false);
  const [showWithdrawalFHSA, setShowWithdrawalFHSA] = useState(false);
  const [showRRSP, setShowRRSP] = useState(false);
  const [showContributionRRSP, setShowContributionRRSP] = useState(false);
  const [showWithdrawalRRSP, setShowWithdrawalRRSP] = useState(false);
  const [showNonReg, setShowNonReg] = useState(false);
  const [showContributionNonReg, setShowContributionNonReg] = useState(false);
  const [showWithdrawalNonReg, setShowWithdrawalNonReg] = useState(false);
  const [showLIRA, setShowLIRA] = useState(false);
  const [showWithdrawalLIRA, setShowWithdrawalLIRA] = useState(false);
  const [graphType, setGraphType] = useState("Overview");
  const [colorScheme, setColorScheme] = useState([
    "#73B879", // Example color for another data type
    "#30AB9D", // Example color for another data type
    "#00A2D4", // Example color for another data type
    "#007EB5", // Example color for another data type
    "#00466D", // Example color for another data type
    "#00304A", // Example color for another data type
    "#001A2A", // Example color for another data type
    "#147984", // Assumed color for "Business"
    "#c45850", // Newly added distinct color for "CCPC Investments"
    "#a6d854", // Assumed color for another data type
    "green", // Example color for another data type
  ]);

  useEffect(() => {
    selectGraph(graphClicked);
  }, [graphClicked]);

  const getSeries = () => {
    switch (graphType) {
      case "TFSA":
        return [
          {
            name: "Optiml",
            type: "bar",

            data: yAxisTFSA?.map((v) => Math.max(0, v)),
            emphasis: {
              focus: "series",
            },
            blur: {
              itemStyle: {
                opacity: 0.2,
              },
            },
          },
          {
            name: "OSFA",
            type: "bar",

            data: yAxisrotTFSA?.map((v) => Math.max(0, v)),
            emphasis: {
              focus: "series",
            },
            blur: {
              itemStyle: {
                opacity: 0.2,
              },
            },
          },
        ];
      case "TFSA Deposit":
        return [
          {
            name: "Optiml",
            type: "bar",

            data: yAxisTFSAContribution?.map((v) => Math.max(0, v)),
            emphasis: {
              focus: "series",
            },
            blur: {
              itemStyle: {
                opacity: 0.2,
              },
            },
          },
          {
            name: "OSFA",
            type: "bar",

            data: yAxisrotTFSAContribution?.map((v) => Math.max(0, v)),
            emphasis: {
              focus: "series",
            },
            blur: {
              itemStyle: {
                opacity: 0.2,
              },
            },
          },
        ];
      case "TFSA Withdrawal":
        return [
          {
            name: "Optiml",
            type: "bar",

            data: yAxisTFSAWithdrawal?.map((v) => Math.max(0, v)),
            emphasis: {
              focus: "series",
            },
            blur: {
              itemStyle: {
                opacity: 0.2,
              },
            },
          },
          {
            name: "OSFA",
            type: "bar",

            data: yAxisrotTFSAWithdrawal?.map((v) => Math.max(0, v)),
            emphasis: {
              focus: "series",
            },
            blur: {
              itemStyle: {
                opacity: 0.2,
              },
            },
          },
        ];
      case "FHSA":
        return [
          {
            name: "Optiml",
            type: "bar",

            data: yAxisFHSA?.map((v) => Math.max(0, v)),
            emphasis: {
              focus: "series",
            },
            blur: {
              itemStyle: {
                opacity: 0.2,
              },
            },
          },
          {
            name: "OSFA",
            type: "bar",

            data: yAxisrotFHSA?.map((v) => Math.max(0, v)),
            emphasis: {
              focus: "series",
            },
            blur: {
              itemStyle: {
                opacity: 0.2,
              },
            },
          },
        ];
      case "FHSA Deposit":
        return [
          {
            name: "Optiml",
            type: "bar",

            data: yAxisFHSAContribution?.map((v) => Math.max(0, v)),
            emphasis: {
              focus: "series",
            },
            blur: {
              itemStyle: {
                opacity: 0.2,
              },
            },
          },
          {
            name: "OSFA",
            type: "bar",

            data: yAxisrotFHSAContribution?.map((v) => Math.max(0, v)),
            emphasis: {
              focus: "series",
            },
            blur: {
              itemStyle: {
                opacity: 0.2,
              },
            },
          },
        ];
      case "FHSA Withdrawal":
        return [
          {
            name: "Optiml",
            type: "bar",

            data: yAxisFHSAWithdrawal?.map((v) => Math.max(0, v)),
            emphasis: {
              focus: "series",
            },
            blur: {
              itemStyle: {
                opacity: 0.2,
              },
            },
          },
          {
            name: "OSFA",
            type: "bar",

            data: yAxisrotFHSAWithdrawal?.map((v) => Math.max(0, v)),
            emphasis: {
              focus: "series",
            },
            blur: {
              itemStyle: {
                opacity: 0.2,
              },
            },
          },
        ];
      case "RRSP/RRIF":
        return [
          {
            name: "Optiml",
            type: "bar",

            data: yAxisRRSP?.map((v) => Math.max(0, v)),
            emphasis: {
              focus: "series",
            },
            blur: {
              itemStyle: {
                opacity: 0.2,
              },
            },
          },
          {
            name: "OSFA",
            type: "bar",

            data: yAxisrotRRSP?.map((v) => Math.max(0, v)),
            emphasis: {
              focus: "series",
            },
            blur: {
              itemStyle: {
                opacity: 0.2,
              },
            },
          },
        ];
      case "RRSP/RRIF Deposit":
        return [
          {
            name: "Optiml",
            type: "bar",

            data: yAxisRRSPContribution?.map((v) => Math.max(0, v)),
            emphasis: {
              focus: "series",
            },
            blur: {
              itemStyle: {
                opacity: 0.2,
              },
            },
          },
          {
            name: "OSFA",
            type: "bar",

            data: yAxisrotRRSPContribution?.map((v) => Math.max(0, v)),
            emphasis: {
              focus: "series",
            },
            blur: {
              itemStyle: {
                opacity: 0.2,
              },
            },
          },
        ];
      case "RRSP/RRIF Withdrawal":
        return [
          {
            name: "Optiml",
            type: "bar",

            data: yAxisRRSPWithdrawal?.map((v) => Math.max(0, v)),
            emphasis: {
              focus: "series",
            },
            blur: {
              itemStyle: {
                opacity: 0.2,
              },
            },
          },
          {
            name: "OSFA",
            type: "bar",

            data: yAxisrotRRSPWithdrawal?.map((v) => Math.max(0, v)),
            emphasis: {
              focus: "series",
            },
            blur: {
              itemStyle: {
                opacity: 0.2,
              },
            },
          },
        ];
      case "Non-Registered":
        return [
          {
            name: "Optiml",
            type: "bar",

            data: yAxisNonReg?.map((v) => Math.max(0, v)),
            emphasis: {
              focus: "series",
            },
            blur: {
              itemStyle: {
                opacity: 0.2,
              },
            },
          },
          {
            name: "OSFA",
            type: "bar",

            data: yAxisrotNonReg?.map((v) => Math.max(0, v)),
            emphasis: {
              focus: "series",
            },
            blur: {
              itemStyle: {
                opacity: 0.2,
              },
            },
          },
        ];
      case "Non-Registered Deposit":
        return [
          {
            name: "Optiml",
            type: "bar",

            data: yAxisNonRegContribution?.map((v) => Math.max(0, v)),
            emphasis: {
              focus: "series",
            },
            blur: {
              itemStyle: {
                opacity: 0.2,
              },
            },
          },
          {
            name: "OSFA",
            type: "bar",

            data: yAxisrotNonRegContribution?.map((v) => Math.max(0, v)),
            emphasis: {
              focus: "series",
            },
            blur: {
              itemStyle: {
                opacity: 0.2,
              },
            },
          },
        ];
      case "Non-Registered Withdrawal":
        return [
          {
            name: "Optiml",
            type: "bar",

            data: yAxisNonRegWithdrawal?.map((v) => Math.max(0, v)),
            emphasis: {
              focus: "series",
            },
            blur: {
              itemStyle: {
                opacity: 0.2,
              },
            },
          },
          {
            name: "OSFA",
            type: "bar",

            data: yAxisrotNonRegWithdrawal?.map((v) => Math.max(0, v)),
            emphasis: {
              focus: "series",
            },
            blur: {
              itemStyle: {
                opacity: 0.2,
              },
            },
          },
        ];
      case "LIRA":
        return [
          {
            name: "Optiml",
            type: "bar",

            data: yAxisLIRA?.map((v) => Math.max(0, v)),
            emphasis: {
              focus: "series",
            },
            blur: {
              itemStyle: {
                opacity: 0.2,
              },
            },
          },
          {
            name: "OSFA",
            type: "bar",

            data: yAxisrotLIRA?.map((v) => Math.max(0, v)),
            emphasis: {
              focus: "series",
            },
            blur: {
              itemStyle: {
                opacity: 0.2,
              },
            },
          },
        ];
      case "LIRA Withdrawal":
        return [
          {
            name: "Optiml",
            type: "bar",

            data: yAxisLIRAWithdrawal?.map((v) => Math.max(0, v)),
            emphasis: {
              focus: "series",
            },
            blur: {
              itemStyle: {
                opacity: 0.2,
              },
            },
          },
          {
            name: "OSFA",
            type: "bar",

            data: yAxisrotLIRAWithdrawal?.map((v) => Math.max(0, v)),
            emphasis: {
              focus: "series",
            },
            blur: {
              itemStyle: {
                opacity: 0.2,
              },
            },
          },
        ];
      case "Overview Deposit":
        return [
          {
            name: "Optiml",
            type: "bar",

            data: yAxisTotalPortfolioContribution?.map((v) => Math.max(0, v)),
            emphasis: {
              focus: "series",
            },
            blur: {
              itemStyle: {
                opacity: 0.2,
              },
            },
          },
          {
            name: "OSFA",
            type: "bar",

            data: yAxisrotTotalPortfolioContribution?.map((v) =>
              Math.max(0, v)
            ),
            emphasis: {
              focus: "series",
            },
            blur: {
              itemStyle: {
                opacity: 0.2,
              },
            },
          },
        ];
      case "Overview Withdrawal":
        return [
          {
            name: "Optiml",
            type: "bar",
            data: yAxisTotalPortfolioWithdrawal?.map((v) => Math.max(0, v)),
            emphasis: {
              focus: "series",
            },
            blur: {
              itemStyle: {
                opacity: 0.2,
              },
            },
          },
          {
            name: "OSFA",
            type: "bar",
            data: yAxisrotTotalPortfolioWithdrawal?.map((v) => Math.max(0, v)),
            emphasis: {
              focus: "series",
            },
            blur: {
              itemStyle: {
                opacity: 0.2,
              },
            },
          },
        ];

      case "Overview":
        return [
          {
            name: "Optiml",
            type: "bar",
            data: yAxisTotalPortfolio?.map((v) => Math.max(0, v)),
            emphasis: {
              focus: "series",
            },
            blur: {
              itemStyle: {
                opacity: 0.2,
              },
            },
          },
          {
            name: "OSFA",
            type: "bar",
            data: yAxisrotTotalPortfolio?.map((v) => Math.max(0, v)),
            emphasis: {
              focus: "series",
            },
            blur: {
              itemStyle: {
                opacity: 0.2,
              },
            },
          },
        ];
    }
  };
  useEffect(() => {
    let newColors = [];
    if (showAll) {
      newColors = [
        "#73B879",
        "#004069",
        "#30AB9D",
        "#007EB5",
        "#00A2D4",
        "#00304A",
        "#147984",
        "green",
        "#c45850",
        "#a6d854",
      ];
    } else {
      if (graphType === "TFSA") newColors = ["#73B879", "#004069"];
      else if (graphType === "TFSA Deposit") newColors = ["#73B879", "#004069"];
      else if (graphType === "TFSA Withdrawal")
        newColors = ["#73B879", "#004069"];
      else if (graphType === "FHSA") newColors = ["purple", "pink"];
      else if (graphType === "FHSA Deposit") newColors = ["purple", "pink"];
      else if (graphType === "FHSA Withdrawal") newColors = ["purple", "pink"];
      else if (graphType === "RRSP/RRIF") newColors = ["#30AB9D", "#00466D"];
      else if (graphType === "RRSP/RRIF Deposit")
        newColors = ["#30AB9D", "#00466D"];
      else if (graphType === "RRSP/RRIF Withdrawal")
        newColors = ["#30AB9D", "#00466D"];
      else if (graphType === "Non-Registered")
        newColors = ["#00466D", "#00A2D4"];
      else if (graphType === "Non-Registered Deposit")
        newColors = ["#00466D", "#00A2D4"];
      else if (graphType === "Non-Registered Withdrawal")
        newColors = ["#00466D", "#00A2D4"];
      else if (graphType === "LIRA") newColors = ["#c45850", "#00A2D4"];
      else if (graphType === "LIRA Withdrawal")
        newColors = ["#c45850", "#00A2D4"];
      else if (graphType === "Overview Deposit")
        newColors = ["#73B879", "#004069"];
      else if (graphType === "Overview Withdrawal")
        newColors = ["#73B879", "#004069"];
    }
    setColorScheme(newColors);
  }, [graphType, showAll]);

  function numFormatter(num) {
    if (num === null || num === undefined) return "$0";
    const absNum = Math.abs(num);
    const sign = num < 0 ? "-" : "";

    if (absNum >= 1000000) {
      return sign + "$" + (absNum / 1000000).toFixed(1) + "M";
    } else if (absNum >= 10000) {
      return sign + "$" + (absNum / 1000).toFixed(0) + "K";
    } else if (absNum >= 1000) {
      return sign + "$" + (absNum / 1000).toFixed(1) + "K";
    } else {
      return sign + "$" + absNum.toFixed(0);
    }
  }

  const headers = [
    { name: "Optiml", show: tfsaData?.some((obj) => obj.y !== 0) },
    { name: "OSFA", show: rottfsaData?.some((obj) => obj.y !== 0) },
    { name: "FHSA", show: fhsaData?.some((obj) => obj.y !== 0) },
    { name: "OSFA FHSA", show: rotfhsaData?.some((obj) => obj.y !== 0) },
    { name: "RRSP/RRIF", show: rrspData?.some((obj) => obj.y !== 0) },
    { name: "OSFA RRSP", show: rotrrspData?.some((obj) => obj.y !== 0) },
    { name: "Non-Registered", show: nonRegData?.some((obj) => obj.y !== 0) },
    {
      name: "OSFA Non-Registered",
      show: rotnonRegData?.some((obj) => obj.y !== 0),
    },
    { name: "LIRA", show: liraData?.some((obj) => obj.y !== 0) },
    { name: "LIRA OSFA", show: rotliraData?.some((obj) => obj.y !== 0) },
    { name: "Total", show: totalPortfolioData?.some((obj) => obj.y !== 0) },
  ];

  const dataToRender = headers
    .filter((header) => header.show)
    ?.map((header) => header.name);

  const topValue =
    window.innerWidth > 1280 && window.innerWidth <= 1723
      ? 100
      : window.innerWidth > 1200 && window.innerWidth <= 1280
      ? 100
      : window.innerWidth > 500 && window.innerWidth <= 1200
      ? 100
      : window.innerWidth <= 480
      ? 50
      : 70;

  const option = {
    color: colorScheme,
    title: {
      text: graphType,
      left: "center",
      top: 0,
      textStyle: {
        // lineHeight: window.innerWidth<=500 && !showAll ? -30 : null,
      },
    },
    tooltip: {
      trigger: "axis",
      left: 1,
      right: 1,
      axisPointer: {
        type: "shadow", // Use 'line' for X-axis
      },
      formatter: function (params) {
        let result = params[0].axisValueLabel + "<br/>";
        params.sort((a, b) => {
          if (a.seriesName === "Optiml") return -1;
          if (b.seriesName === "Optiml") return 1;
          return 0;
        });
        params.forEach(function (item) {
          result +=
            item.marker +
            " " +
            item.seriesName +
            ": " +
            currencyFormat(item.data) +
            "<br/>";
        });
        return result;
      },
    },
    legend: {
      data: dataToRender,
      selectedMode: false,
      top: window.innerWidth <= 500 ? 22 : 35,
      bottom: 100,
      textStyle: {
        fontSize: 10, // Adjust the font size as needed
      },
      show: window.innerWidth <= 480 && showAll ? false : true,
    },
    toolbox: {
      feature: {
        saveAsImage: {
          iconStyle: {
            borderColor: "#004069",
          },
        },
      },
    },
    grid: {
      left: 3,
      top: investmentPage
        ? topValue
        : window.innerWidth > 1200 && window.innerWidth <= 1280
        ? 100
        : window.innerWidth > 500 && window.innerWidth <= 1200
        ? 100
        : window.innerWidth <= 480
        ? 50
        : 70,
      right: 3,
      bottom: "0%",
      containLabel: true,
    },
    xAxis: [
      {
        type: "category",
        boundaryGap: ["20%", "20%"],
        data: xAxisWithAge ? xAxisWithAge : [],
        axisLabel: {
          formatter: function (value) {
            return value.split("\n").join("\n"); // Ensure line breaks are rendered
          },
        },
      },
    ],
    yAxis: [
      {
        type: "value",
        axisLabel: {
          formatter: function (value) {
            return numFormatter(value);
          },
        },
      },
    ],
    series: getSeries(),
  };

  const selectGraph = (graph) => {
    setGraphType(graph);
    setShowAll(graph === "Overview");
    setShowAllContribution(graph === "Overview Deposit");
    setShowAllWithdrawal(graph === "Overview Withdrawal");
    setShowTFSA(graph === "TFSA");
    setShowContributionTFSA(graph === "TFSA Deposit");
    setShowWithdrawalTFSA(graph === "TFSA Withdrawal");
    setShowFHSA(graph === "FHSA");
    setShowContributionFHSA(graph === "FHSA Deposit");
    setShowWithdrawalFHSA(graph === "FHSA Withdrawal");
    setShowRRSP(graph === "RRSP/RRIF");
    setShowContributionRRSP(graph === "RRSP/RRIF Deposit");
    setShowWithdrawalRRSP(graph === "RRSP/RRIF Withdrawal");
    setShowNonReg(graph === "Non-Registered");
    setShowContributionNonReg(graph === "Non-Registered Deposit");
    setShowWithdrawalNonReg(graph === "Non-Registered Withdrawal");
    setShowLIRA(graph === "LIRA");
    setShowWithdrawalLIRA(graph === "LIRA Withdrawal");
  };

  return (
    <div
      className="dashboard-graph-container"
      style={{
        border: "none",
        borderRadius: "none",
        boxShadow: "none",
        padding: "0",
      }}
    >
      <div
        tabIndex={0}
        className="dropdown"
        onClick={() => setShowDropdown(!showDropdown)}
        onBlur={() => setShowDropdown(showDropdown)}
        style={{right:'48px'}}
      >
        <img src={FilterIcon} className="filter-icon" alt="filter-icon" />
        {showDropdown && (
          <div
            className="dropdown-items"
            style={{ maxWidth: "100%", marginTop: "-80px", right: "-50px" }}
          >
            <ul style={{ paddingBottom: "0px", marginBottom: "0px" }}>
              <div>Portfolio Balances</div>
              <li
                className={showAll ? "dropdown-item-selected" : undefined}
                onClick={() => selectGraph("Overview")}
              >
                All
              </li>
              {tfsaData?.some((obj) => obj.y !== 0) && (
                <li
                  className={showTFSA ? "dropdown-item-selected" : undefined}
                  onClick={() => selectGraph("TFSA")}
                >
                  TFSA
                </li>
              )}
              {fhsaData?.some((obj) => obj.y !== 0) && (
                <li
                  className={showFHSA ? "dropdown-item-selected" : undefined}
                  onClick={() => selectGraph("FHSA")}
                >
                  FHSA
                </li>
              )}
              {rrspData?.some((obj) => obj.y !== 0) && (
                <li
                  className={showRRSP ? "dropdown-item-selected" : undefined}
                  onClick={() => selectGraph("RRSP/RRIF")}
                >
                  RRSP /RRIF
                </li>
              )}
              {liraData?.some((obj) => obj.y !== 0) && (
                <li
                  className={showLIRA ? "dropdown-item-selected" : undefined}
                  onClick={() => selectGraph("LIRA")}
                >
                  LIRA
                </li>
              )}
              {nonRegData?.some((obj) => obj.y !== 0) && (
                <li
                  className={showNonReg ? "dropdown-item-selected" : undefined}
                  onClick={() => selectGraph("Non-Registered")}
                >
                  Non-Registered
                </li>
              )}
            </ul>
            <ul style={{ paddingTop: "0px", marginBottom: "0px" }}>
              <div>Deposits</div>
              <li
                className={
                  showAllContribution ? "dropdown-item-selected" : undefined
                }
                onClick={() => selectGraph("Overview Deposit")}
              >
                All
              </li>
              {tfsaContributionData?.some((obj) => obj.y !== 0) && (
                <li
                  className={
                    showContributionTFSA ? "dropdown-item-selected" : undefined
                  }
                  onClick={() => selectGraph("TFSA Deposit")}
                >
                  TFSA
                </li>
              )}
              {fhsaContributionData?.some((obj) => obj.y !== 0) && (
                <li
                  className={
                    showContributionFHSA ? "dropdown-item-selected" : undefined
                  }
                  onClick={() => selectGraph("FHSA Deposit")}
                >
                  FHSA
                </li>
              )}
              {rrspContributionData?.some((obj) => obj.y !== 0) && (
                <li
                  className={
                    showContributionRRSP ? "dropdown-item-selected" : undefined
                  }
                  onClick={() => selectGraph("RRSP/RRIF Deposit")}
                >
                  RRSP /RRIF
                </li>
              )}
              {nonRegContributionData?.some((obj) => obj.y !== 0) && (
                <li
                  className={
                    showContributionNonReg
                      ? "dropdown-item-selected"
                      : undefined
                  }
                  onClick={() => selectGraph("Non-Registered Deposit")}
                >
                  Non-Registered
                </li>
              )}
            </ul>
            <ul style={{ paddingTop: "0px" }}>
              <div>Withdrawals</div>
              <li
                className={
                  showAllWithdrawal ? "dropdown-item-selected" : undefined
                }
                onClick={() => selectGraph("Overview Withdrawal")}
              >
                All
              </li>
              {tfsaWithdrawalData?.some((obj) => obj.y !== 0) && (
                <li
                  className={
                    showWithdrawalTFSA ? "dropdown-item-selected" : undefined
                  }
                  onClick={() => selectGraph("TFSA Withdrawal")}
                >
                  TFSA
                </li>
              )}
              {fhsaWithdrawalData?.some((obj) => obj.y !== 0) && (
                <li
                  className={
                    showWithdrawalFHSA ? "dropdown-item-selected" : undefined
                  }
                  onClick={() => selectGraph("FHSA Withdrawal")}
                >
                  FHSA
                </li>
              )}
              {rrspWithdrawalData?.some((obj) => obj.y !== 0) && (
                <li
                  className={
                    showWithdrawalRRSP ? "dropdown-item-selected" : undefined
                  }
                  onClick={() => selectGraph("RRSP/RRIF Withdrawal")}
                >
                  RRSP /RRIF
                </li>
              )}
              {liraWithdrawalData?.some((obj) => obj.y !== 0) && (
                <li
                  className={
                    showWithdrawalLIRA ? "dropdown-item-selected" : undefined
                  }
                  onClick={() => selectGraph("LIRA Withdrawal")}
                >
                  LIRA
                </li>
              )}
              {nonRegWithdrawalData?.some((obj) => obj.y !== 0) && (
                <li
                  className={
                    showWithdrawalNonReg ? "dropdown-item-selected" : undefined
                  }
                  onClick={() => selectGraph("Non-Registered Withdrawal")}
                >
                  Non-Registered
                </li>
              )}
            </ul>
          </div>
        )}
      </div>

      <ReactEcharts
        // ref={eChartsRef}
        option={option}
        className="portfolio-balance-chart"
        notMerge
      />
    </div>
  );
}

export default InsightGraph;
