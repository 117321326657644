import { Box, Flex, Text, VStack, useMediaQuery } from "@chakra-ui/react";
import axios from "axios";
import React, {
  forwardRef,
  useContext,
  useEffect,
  useImperativeHandle,
  useState,
} from "react";
import { useStore } from "../../../store";
import { useAuth0 } from "@auth0/auth0-react";
import GlobalBodyPage from "../../GlobalContainer/BodyPage";
import DropDownButton from "../../InputFields/dropDownButton";
import NormalInputButton from "../../InputFields/normalInputButton";
import TableComponent from "../../TableComponent";
import TooltipContent from "../../TooltipContent";
import { CloseIcon } from "../../CustomIcons";

const LandPage = forwardRef((props, ref) => {
  const { getAccessTokenSilently, user } = useAuth0();

  useImperativeHandle(ref, () => ({
    submitHandler,
    checkMandatoryFields,
  }));

  const submitHandler = () => {
    setUserEditAssetInfo({});
    return true;
  };

  const checkMandatoryFields = () => {
    return true;
  };

  const axiosInstance = axios.create();
  async function getToken() {
    // Replace this with your Auth0 token retrieval logic
    const token = await getAccessTokenSilently({
      audience:
        "https://os2lsiwff43wfpb5ggyawt6qri0mgcro.lambda-url.us-east-2.on.aws/",
      scope: "write:optimization",
    });
    return token;
  }

  // Add a request interceptor
  axiosInstance.interceptors.request.use(
    async (config) => {
      const token = await getToken();
      config.headers.Authorization = `Bearer ${token}`;
      return config;
    },
    (error) => {
      // Do something with request error
      return Promise.reject(error);
    }
  );
  const userEditAssetInfo = useStore((store) => store.userEditAssetInfo);
  const setUserEditAssetInfo = useStore((store) => store.setUserEditAssetInfo);

  const [CurrentValue, setCurrentValue] = useState("");
  const [Address, setAddress] = useState("");
  const [Ownership, setOwnership] = useState(100);
  const [GrowthRate, setGrowthRate] = useState("");
  const [MortgageBalance, setMortgageBalance] = useState("");
  const [MortgageRate, setMortgageRate] = useState("");
  const [MonthlyPayment, setMonthlyPayment] = useState("");
  const [PlanToSell, setPlanToSell] = useState("");
  const [YearsToSell, setYearsToSell] = useState("");
  const [PurchasePrice, setPurchasePrice] = useState("");
  const [CapitalImprovements, setCapitalImprovements] = useState("");
  const [landNumber, setLandNumber] = useState();

  const [isLargerThan768] = useMediaQuery("(min-width: 500px)");
  const isMobile = !isLargerThan768;

  const [realEstateArray, setRealEstateArray] = useState([]);
  const [editing, setEditing] = useState(false);
  const [editingIndex, setEditingIndex] = useState(null);
  const [showDeleteModal, setShowDeleteModal] = useState(null);

  const [adding, setAdding] = useState(false);

  const userLandInfo = useStore((store) => store.userLandInfo);
  const setUserLandInfo = useStore((store) => store.setUserLandInfo);
  const updateUserLandInfo = useStore((store) => store.updateUserLandInfo);
  const deleteUserLandInfo = useStore((store) => store.deleteUserLandInfo);

  const userInfo = useStore((store) => store.userInfo);

  const {
    FirstName: firstName,
    LastName: lastName,
    DateOfBirth: currentAge,
    spouseFirstName,
    spouseLastName,
    runAge,
  } = userInfo;

  function getAge(birthday, currentDate) {
    // Convert the birthday string into a Date object
    const birthDate = new Date(birthday);

    // Calculate the age in years
    let age = currentDate.getFullYear() - birthDate.getFullYear();

    // Decrement the age if the current month and day are before the birth month and day
    if (
      currentDate.getMonth() < birthDate.getMonth() ||
      (currentDate.getMonth() === birthDate.getMonth() &&
        currentDate.getDate() <= birthDate.getDate())
    ) {
      age -= 1;
    }

    return age;
  }

  const moneyToNumber = (value) => {
    let numValue = value.toString().replace(/[$,\s]/g, "");
    numValue = Number(numValue);
    return numValue;
  };

  const Select = (userAge) => {
    const currentDate = new Date();
    const currentYear = currentDate.getFullYear();
    let age = getAge(userAge, currentDate); // Assuming getAge returns the starting age.
    const runAgeList = [];
    for (let i = age; i <= 100; i++) {
      runAgeList.push({ key: i, value: currentYear + (i - age) }); // Convert i to string if necessary
    }
    return runAgeList;
  };

  const SelectYearsPast = (userAge) => {
    const currentDate = new Date();
    const currentYear = currentDate.getFullYear();
    let age = getAge(userAge, currentDate); // Assuming getAge returns the user's current age.
    const runAgeList = [];
    for (let i = age; i >= 0; i--) {
      runAgeList.push({ key: i, value: currentYear - (age - i) }); // Convert i to string if necessary
    }
    return runAgeList;
  };

  const currencyFormat = (money) => {
    if (money === 0) {
      return "$0"; // Handle zero explicitly
    } else if (money === "") {
      return "";
    } else {
      const intTotal = Math.round(Math.round(money * 100) / 100);
      const currencyCad = new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "USD",
        minimumFractionDigits: 0,
      }).format(intTotal);
      return currencyCad;
    }
  };

  const addRealEstate = (e, list) => {
    if (editing) {
      setRealEstateArray((prevArray) => {
        let newArray = [...prevArray];
        newArray[editingIndex] = list;
        onSubmitRealEstateUpdate(e, newArray[editingIndex]);
        return newArray;
      });
      setEditing(false);
      setEditingIndex(null);
    } else {
      setRealEstateArray((prevArray) => {
        let newArray = [...prevArray, list];
        onSubmitRealEstateAdd(e, newArray[newArray.length - 1]);
        return newArray;
      });
    }

    setAddress("");
    setCurrentValue(0);
    setGrowthRate(0);
    setOwnership(100);
    setMortgageRate(0);
    setMortgageBalance(0);
    setPlanToSell("");
    setPurchasePrice(0);
    setCapitalImprovements(0);
    setMonthlyPayment(0);
    setYearsToSell(85);
    setAdding(false);
  };

  const [errors, setErrors] = useState({});

  const checkMandatoryAddUpdateFields = () => {
    let newErrors = {};

    // Land conditions
    if (!CurrentValue) newErrors.CurrentValue = "Land value is required";
    // if (!Ownership) newErrors.Ownership = "Land ownership is required";
    if (!PlanToSell) newErrors.PlanToSell = "Plan to sell Land is required";
    if (!PurchasePrice)
      newErrors.PurchasePrice = "Land purchase price is required";
    if (PlanToSell === "Yes" && !YearsToSell) {
      if (!YearsToSell) newErrors.YearsToSell = "Year to sell Land is required";
    }
    if (GrowthRate > 20)
      newErrors.GrowthRate = "Land growth rate must be less than 20%";
    if (MortgageRate > 20)
      newErrors.MortgageRate = "Mortgage growth rate must be less than 20%";
    const effectiveMonthlyInterest =
      (1 + ((1 + MortgageRate / 200) ** 2 - 1)) ** (1 / 12) - 1;

    if (
      MortgageBalance > 0 &&
      MonthlyPayment < MortgageBalance * effectiveMonthlyInterest
    ) {
      newErrors.MonthlyPayment =
        "Monthly payment is less than the effective monthly interest.";
    }

    if (MortgageBalance > 0 && MonthlyPayment < 1) {
      newErrors.MonthlyPayment = "Monthly payment is less than 1.";
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0; // Return true if no errors
  };

  useEffect(() => {
    console.log(userEditAssetInfo);
    if (userEditAssetInfo?.editAsset?.name === "Land") {
      setAddress(userEditAssetInfo?.editAsset?.landAddress);
      setCurrentValue(userEditAssetInfo?.editAsset?.value);
      setGrowthRate(userEditAssetInfo?.editAsset?.landGrowth);
      // setOwnership(userEditAssetInfo?.editAsset?.landOwnership);
      setMortgageRate(userEditAssetInfo?.editAsset?.landDebtInterest);
      setPlanToSell(userEditAssetInfo?.editAsset?.sellLandYesNo);
      setPurchasePrice(userEditAssetInfo?.editAsset?.landPurchasePrice);
      setCapitalImprovements(
        userEditAssetInfo?.editAsset?.landCapitalImprovements
      );
      setMonthlyPayment(userEditAssetInfo?.editAsset?.landDebtPayments);
      setYearsToSell(userEditAssetInfo?.editAsset?.sellLandDate);
      setLandNumber(userEditAssetInfo?.editAsset?.landNumber);
      setMortgageBalance(userEditAssetInfo?.editAsset?.landDebt);
      setEditing(true);
      setAdding(true);
      setEditingIndex(userEditAssetInfo?.editIndex);
    }
  }, [userEditAssetInfo]);

  useEffect(() => {
    const updatedRealEstateArray = [];

    if (userLandInfo != null) {
      updatedRealEstateArray.push(...userLandInfo);
    }

    setRealEstateArray(updatedRealEstateArray);
  }, [userLandInfo]);

  const onDeleteRow = (i) => {
    setEditingIndex(i);
    setShowDeleteModal(true);
  };

  const editField = (i, array) => {
    setAdding(true);
    if (array[i].name === "Land") {
      setAddress(array[i]?.landAddress);
      setCurrentValue(array[i]?.value);
      setGrowthRate(array[i]?.landGrowth);
      // setOwnership(array[i]?.landOwnership);
      setMortgageRate(array[i]?.landDebtInterest);
      setPlanToSell(array[i]?.sellLandYesNo);
      setPurchasePrice(array[i]?.landPurchasePrice);
      setCapitalImprovements(array[i]?.landCapitalImprovements);
      setMonthlyPayment(array[i]?.landDebtPayments);
      setYearsToSell(array[i]?.sellLandDate);
      setLandNumber(array[i]?.landNumber);
      setMortgageBalance(array[i]?.landDebt);
    }
    setEditing(true);
    setEditingIndex(i);
  };

  const DeleteRealEstateModal = () => {
    let activeArray = realEstateArray;

    const removeRealEstateModal = () => {
      const deleteLandURL = (action) =>
        `${process.env.REACT_APP_NODE_API_GATEWAY}/CompassLambdaApi/LAND/${action}?landNumber=${activeArray[editingIndex].landNumber}`;
      axiosInstance.get(deleteLandURL("delete")).then((res) => {
        console.log("Delete res", res);
        deleteUserLandInfo(realEstateArray[editingIndex].landNumber);
        removeField(editingIndex, activeArray);
        setEditingIndex(null);
        setEditing(false);
      });
    };

    if (!showDeleteModal) {
      return null;
    }
    const [yesClicked, setYesClicked] = useState(false);
    const [noClicked, setNoClicked] = useState(false);
    return (
      <div
        className="delete-income-modal"
        style={{
          display: "flex",
          alignItems: "center",
          width: "100%",
          maxWidth: "700px",
          justifyContent: "center",
          top: "50%",
          left: "50%",
          transform: isMobile
            ? "translate(-50%, -50%)"
            : "translate(-50%, -50%)",
        }}
        id="delete-income-modal"
      >
        <Box
          display={"flex"}
          width={"100%"}
          padding={"10px 10px 0px 0px"}
          marginLeft={"5px"}
          alignItems={"flex-end"}
          justifyContent={"flex-end"}
          onClick={() => setShowDeleteModal(false)}
          cursor={"pointer"}
        >
          <CloseIcon
            onClick={() => setShowDeleteModal(false)}
            svgheight={"20px"}
            svgwidth={"20px"}
          />
        </Box>
        <Box
          display={"flex"}
          padding={"0px 25px 25px 25px"}
          flexDirection={"column"}
          alignItems={"center"}
          gap={"10px"}
          alignSelf={"stretch"}
          width={"100%"}
        >
          <Text
            color={"#0093D0"}
            fontFamily={"Nunito"}
            fontSize={"24px"}
            fontStyle={"normal"}
            fontWeight={"600"}
            lineHeight={"normal"}
            textDecor={"underline"}
          >
            Are you sure you want to remove this real estate asset?
          </Text>
          <div className="content"></div>
          <div className="actions">
            <button
              className={`decision-button yes-button ${
                yesClicked ? "yes-clicked" : ""
              }`}
              onClick={() => {
                setYesClicked(true);
                setNoClicked(false);
                removeRealEstateModal();
              }}
            >
              Yes
            </button>
            <button
              className={`decision-button no-button ${
                noClicked ? "yes-clicked" : ""
              }`}
              onClick={() => {
                {
                  setNoClicked(true);
                  setYesClicked(false);
                  setShowDeleteModal(false);
                }
              }}
            >
              No
            </button>
          </div>
        </Box>
      </div>
    );
  };

  const removeField = (i, array) => {
    array.splice(i, 1);
    setRealEstateArray((prevArray) => [...prevArray]);
    setShowDeleteModal(false);
  };

  const onSubmitRealEstateAdd = (e, newArray) => {
    e.preventDefault();
    if (newArray.name === "Land") {
      const url = `${
        process.env.REACT_APP_NODE_API_GATEWAY
      }/CompassLambdaApi/LAND/add?landID=${user.sub.replace("|", "%7c")}`;
      axiosInstance
        .post(url, newArray)
        .then((res) => {
          console.log(res);
          const newData = { ...newArray, landNumber: res.data.lastInsertedId };
          setUserLandInfo([newData]);
          setUserEditAssetInfo({});
        })
        .catch((error) => {
          console.error("Error:", error);
        });
    }
  };

  const onSubmitRealEstateUpdate = (e, newArray) => {
    e.preventDefault();
    console.log("UPDATING THIS: ", newArray);

    if (newArray.name === "Land") {
      const url = `${
        process.env.REACT_APP_NODE_API_GATEWAY
      }/CompassLambdaApi/LAND/update?landID=${user.sub.replace("|", "%7c")}`;

      axiosInstance
        .post(url, newArray)
        .then((res) => {
          console.log(res);
          const newData = { ...newArray, landNumber: newArray.landNumber };
          updateUserLandInfo(newArray.landNumber, newData);
          setUserEditAssetInfo({});
        })
        .catch((error) => {
          console.error("Error:", error);
        });
    }
  };

  const renderAddUpdateRecreational = () => {
    if (
      !CurrentValue ||
      // !Ownership ||
      !PlanToSell ||
      !PurchasePrice ||
      GrowthRate > 20 ||
      MortgageRate > 20 ||
      (PlanToSell === "Yes" && !YearsToSell) ||
      (MortgageBalance > 0 &&
        MonthlyPayment <
          MortgageBalance *
            ((1 + ((1 + MortgageRate / 200) ** 2 - 1)) ** (1 / 12) - 1)) ||
      (MortgageBalance > 0 && MonthlyPayment < 1)
    ) {
      return (
        <Box
          display={"flex"}
          padding={"8px 48px"}
          marginTop={"20px"}
          justifyContent={"flex-end"}
          alignItems={"center"}
          gap={"5px"}
          borderRadius={"5px"}
          bg={"#27AE60"}
          cursor={"pointer"}
          onClick={checkMandatoryAddUpdateFields}
        >
          <Box
            display={"flex"}
            width={"76px"}
            height={"21px"}
            flexDirection={"column"}
            justifyContent={"center"}
          >
            <Text
              color={"#FFFFFF"}
              textAlign={"center"}
              fontFamily={"Nunito"}
              fontSize={"16px"}
              fontStyle={"normal"}
              fontWeight={"600"}
              lineHeight={"normal"}
            >
              {editing ? "Update" : "Add"}
            </Text>
          </Box>
        </Box>
      );
    } else {
      return (
        <Box
          display={"flex"}
          padding={"8px 48px"}
          marginTop={"20px"}
          justifyContent={"flex-end"}
          alignItems={"center"}
          gap={"5px"}
          borderRadius={"5px"}
          bg={"#27AE60"}
          cursor={"pointer"}
          onClick={(e) =>
            addRealEstate(e, {
              name: "Land",
              value: CurrentValue,
              landGrowth: GrowthRate,
              landOwnership: Ownership,
              sellLandYesNo: PlanToSell,
              landPurchasePrice: PurchasePrice,
              landCapitalImprovements: CapitalImprovements,
              landDebtPayments: MonthlyPayment ?? 0,
              sellLandDate: YearsToSell,
              landAddress: Address,
              landDebt: MortgageBalance ?? 0,
              landDebtInterest: MortgageRate ?? 0,
              ...(editing ? { landNumber: landNumber } : {}), // add field conditionally
            })
          }
        >
          <Box
            display={"flex"}
            width={"76px"}
            height={"21px"}
            flexDirection={"column"}
            justifyContent={"center"}
            cursor={"pointer"}
          >
            <Text
              color={"#FFFFFF"}
              textAlign={"center"}
              fontFamily={"Nunito"}
              fontSize={"16px"}
              fontStyle={"normal"}
              fontWeight={"600"}
              lineHeight={"normal"}
            >
              {editing ? "Update" : "Add"}
            </Text>
          </Box>
        </Box>
      );
    }
  };

  const columns = [
    { header: "Name", field: "name" },
    { header: "Value", field: "value" },
  ];

  const data = realEstateArray.map((item) => ({
    name: item?.landAddress ? item?.landAddress : item.name,
    value: item?.value,
    link: "/land-property-form",
  }));

  const handleClick = () => {
    setAdding(true);
  };

  return (
    <Flex
      display="flex"
      flexWrap="wrap" // Enable wrapping
      justifyContent="flex-start"
      alignItems="start"
      width={"100%"}
    >
      {!isMobile && (
        <VStack alignItems={"flex-start"} width={"100%"} mt={-2}>
          <GlobalBodyPage
            Heading="Land"
            mainframeContent={
              <>
                <Box
                  display={"flex"}
                  width={"100%"}
                  flexDirection={"row"}
                  paddingTop={"10px"}
                  alignItems={"flex-end"}
                  justifyContent={"space-between"}
                  gap={"10px"}
                  flexWrap={"wrap"}
                >
                  <DeleteRealEstateModal />
                  {data.length > 0 && !adding && (
                    <TableComponent
                      columns={columns}
                      data={data}
                      editField={editField}
                      onDeleteRow={onDeleteRow}
                      rawData={realEstateArray}
                    />
                  )}
                  {(data.length < 1 || adding) && (
                    <>
                      <Text
                        alignSelf={"stretch"}
                        color={"var(--Optiml---Deep-Ocean-Blue, #007EB5)"}
                        fontFamily={"Nunito"}
                        fontSize={"16px"}
                        fontStyle={"normal"}
                        fontWeight={"600"}
                        lineHeight={"normal"}
                        width={"100%"}
                      >
                        Land details:
                      </Text>
                      <NormalInputButton
                        Heading={"Address"}
                        value={Address}
                        onChange={(e) => {
                          setAddress(e.target.value);
                        }}
                        customWidth={"100%"}
                        InputWidth={"100%"}
                        error={errors?.Address}
                        tooltipContent={TooltipContent.landAddress}
                      />
                      <NormalInputButton
                        Heading={
                          "Enter the dollar value of your ownership of the land"
                        }
                        value={CurrentValue}
                        onChange={(e) => {
                          setCurrentValue(moneyToNumber(e.target.value));
                        }}
                        customWidth={"100%"}
                        placeholder={"$"}
                        useThousandSeparator={true}
                        error={errors?.CurrentValue}
                        tooltipContent={TooltipContent.landValue}
                      />
                      <Text
                        alignSelf={"stretch"}
                        color={"var(--Optiml---Deep-Ocean-Blue, #007EB5)"}
                        fontFamily={"Nunito"}
                        fontSize={"16px"}
                        fontStyle={"normal"}
                        fontWeight={"600"}
                        lineHeight={"normal"}
                        width={"100%"}
                      >
                        Financial performance:
                      </Text>

                      <NormalInputButton
                        Heading={"Growth rate"}
                        value={GrowthRate}
                        onChange={(e) => {
                          const noSymbol = e.target.value.replace("%", "");
                          const growthNum = parseFloat(noSymbol);
                          setGrowthRate(
                            !isNaN(growthNum)
                              ? parseFloat(growthNum.toFixed(2))
                              : 0
                          );
                        }}
                        placeholder={"%"}
                        usePercentage={true}
                        error={errors?.GrowthRate}
                        tooltipContent={TooltipContent.landGrowth}
                      />
                      <Text
                        alignSelf={"stretch"}
                        color={"var(--Optiml---Deep-Ocean-Blue, #007EB5)"}
                        fontFamily={"Nunito"}
                        fontSize={"16px"}
                        fontStyle={"normal"}
                        fontWeight={"600"}
                        lineHeight={"normal"}
                        width={"100%"}
                      >
                        Investment:
                      </Text>
                      {/* <NormalInputButton
                        Heading={"Ownership"}
                        value={Ownership}
                        onChange={(e) => {
                          const noSymbol = e.target.value.replace("%", "");
                          const growthNum = parseFloat(noSymbol);
                          setOwnership(
                            !isNaN(growthNum)
                              ? parseFloat(growthNum.toFixed(2))
                              : 0
                          );
                        }}
                        placeholder={"%"}
                        usePercentage={true}
                        error={errors?.Ownership}
                        tooltipContent={TooltipContent.landOwnership}
                      /> */}
                      <NormalInputButton
                        Heading={"Purchase price"}
                        value={PurchasePrice}
                        onChange={(e) => {
                          setPurchasePrice(moneyToNumber(e.target.value));
                        }}
                        placeholder={"$"}
                        useThousandSeparator={true}
                        error={errors?.PurchasePrice}
                        tooltipContent={TooltipContent.landPurchasePrice}
                      />
                      <NormalInputButton
                        Heading={"Capital improvements"}
                        value={CapitalImprovements}
                        onChange={(e) => {
                          setCapitalImprovements(moneyToNumber(e.target.value));
                        }}
                        placeholder={"$"}
                        useThousandSeparator={true}
                        customWidth={"100%"}
                        error={errors?.CapitalImprovements}
                        tooltipContent={TooltipContent.landCapitalImprovements}
                      />

                      <Text
                        alignSelf={"stretch"}
                        color={"var(--Optiml---Deep-Ocean-Blue, #007EB5)"}
                        fontFamily={"Nunito"}
                        fontSize={"16px"}
                        fontStyle={"normal"}
                        fontWeight={"600"}
                        lineHeight={"normal"}
                        width={"100%"}
                      >
                        Debt information:
                      </Text>
                      <NormalInputButton
                        Heading={"Remaining Debt balance"}
                        value={MortgageBalance}
                        onChange={(e) => {
                          setMortgageBalance(moneyToNumber(e.target.value));
                        }}
                        placeholder={"$"}
                        useThousandSeparator={true}
                        error={errors?.MortgageBalance}
                        tooltipContent={TooltipContent.landMortgage}
                      />
                      <NormalInputButton
                        Heading={"Monthly Debt payment"}
                        value={MonthlyPayment}
                        onChange={(e) => {
                          setMonthlyPayment(moneyToNumber(e.target.value));
                        }}
                        placeholder={"$"}
                        useThousandSeparator={true}
                        error={errors?.MonthlyPayment}
                        tooltipContent={TooltipContent.landPayment}
                      />
                      <NormalInputButton
                        Heading={"Debt interest rate"}
                        value={MortgageRate}
                        onChange={(e) => {
                          const noSymbol = e.target.value.replace("%", "");
                          const growthNum = parseFloat(noSymbol);
                          setMortgageRate(
                            !isNaN(growthNum)
                              ? parseFloat(growthNum.toFixed(2))
                              : 0
                          );
                        }}
                        placeholder={"%"}
                        usePercentage={true}
                        error={errors?.MortgageRate}
                        tooltipContent={TooltipContent.landMortgageRate}
                      />

                      <Text
                        alignSelf={"stretch"}
                        color={"var(--Optiml---Deep-Ocean-Blue, #007EB5)"}
                        fontFamily={"Nunito"}
                        fontSize={"16px"}
                        fontStyle={"normal"}
                        fontWeight={"600"}
                        lineHeight={"normal"}
                        width={"100%"}
                      >
                        Future Real Estate Plans
                      </Text>
                      <DropDownButton
                        Heading={"Do you have plans to sell your Land?"}
                        placeholder={"Select"}
                        dropDownItems={[
                          { key: "Yes", value: "Yes" },
                          { key: "No", value: "No" },
                        ]}
                        value={PlanToSell}
                        onChange={(key) => {
                          setPlanToSell(key);
                        }}
                        customWidth={"100%"}
                        error={errors?.PlanToSell}
                        tooltipContent={TooltipContent.landPlanToSell}
                      />
                      {PlanToSell === "Yes" && (
                        <DropDownButton
                          Heading={
                            "When do you anticipate you may sell this Land?"
                          }
                          placeholder={"Select"}
                          dropDownItems={Select(currentAge)}
                          value={YearsToSell}
                          onChange={(key) => {
                            setYearsToSell(key);
                          }}
                          customWidth={"100%"}
                          error={errors?.YearsToSell}
                          tooltipContent={TooltipContent.landSellYear}
                        />
                      )}
                    </>
                  )}
                </Box>
                {data.length > 0 && !adding && (
                  <Box
                    display={"flex"}
                    justifyContent={"flex-start"}
                    alignItems={"center"}
                    alignSelf={"stretch"}
                    onClick={handleClick}
                  >
                    <Box
                      display={"flex"}
                      padding={"8px 48px"}
                      marginTop={"20px"}
                      justifyContent={"flex-end"}
                      alignItems={"center"}
                      gap={"5px"}
                      borderRadius={"5px"}
                      bg={"#27AE60"}
                      cursor={"pointer"}
                    >
                      <Box
                        display={"flex"}
                        width={"76px"}
                        height={"21px"}
                        flexDirection={"column"}
                        justifyContent={"center"}
                      >
                        <Text
                          color={"#FFFFFF"}
                          textAlign={"center"}
                          fontFamily={"Nunito"}
                          fontSize={"16px"}
                          fontStyle={"normal"}
                          fontWeight={"600"}
                          lineHeight={"normal"}
                        >
                          Add
                        </Text>
                      </Box>
                    </Box>
                  </Box>
                )}
                {(data.length < 1 || adding) && (
                  <Box
                    display={"flex"}
                    justifyContent={"flex-end"}
                    alignItems={"center"}
                    alignSelf={"stretch"}
                  >
                    {renderAddUpdateRecreational()}
                  </Box>
                )}
              </>
            }
            // videoName={"Land Page"}
            // video={"Land.mp4"}
          />
        </VStack>
      )}
      {isMobile && (
        <VStack alignItems={"flex-start"} width={"100%"} mt={-2}>
          <GlobalBodyPage
            Heading="Land"
            mainframeContent={
              <>
                <Box
                  display={"flex"}
                  width={"100%"}
                  flexDirection={"row"}
                  paddingTop={"10px"}
                  alignItems={"flex-end"}
                  justifyContent={"space-between"}
                  gap={"10px"}
                  flexWrap={"wrap"}
                >
                  <DeleteRealEstateModal />
                  {data.length > 0 && !adding && (
                    <TableComponent
                      columns={columns}
                      data={data}
                      editField={editField}
                      onDeleteRow={onDeleteRow}
                      rawData={realEstateArray}
                    />
                  )}
                  {(data.length < 1 || adding) && (
                    <>
                      <Text
                        alignSelf={"stretch"}
                        color={"var(--Optiml---Deep-Ocean-Blue, #007EB5)"}
                        fontFamily={"Nunito"}
                        fontSize={"16px"}
                        fontStyle={"normal"}
                        fontWeight={"600"}
                        lineHeight={"normal"}
                        width={"100%"}
                      >
                        Land details:
                      </Text>
                      <NormalInputButton
                        Heading={"Address"}
                        value={Address}
                        onChange={(e) => {
                          setAddress(e.target.value);
                        }}
                        customWidth={"100%"}
                        InputWidth={"100%"}
                        error={errors?.Address}
                        tooltipContent={TooltipContent.landAddress}
                      />
                      <NormalInputButton
                        Heading={
                          "Enter the dollar value of your ownership of the land"
                        }
                        value={CurrentValue}
                        onChange={(e) => {
                          setCurrentValue(moneyToNumber(e.target.value));
                        }}
                        customWidth={"100%"}
                        InputWidth={"100%"}
                        placeholder={"$"}
                        useThousandSeparator={true}
                        error={errors?.CurrentValue}
                        tooltipContent={TooltipContent.landValue}
                      />
                      <Text
                        alignSelf={"stretch"}
                        color={"var(--Optiml---Deep-Ocean-Blue, #007EB5)"}
                        fontFamily={"Nunito"}
                        fontSize={"16px"}
                        fontStyle={"normal"}
                        fontWeight={"600"}
                        lineHeight={"normal"}
                        width={"100%"}
                      >
                        Financial performance:
                      </Text>

                      <NormalInputButton
                        Heading={"Growth rate"}
                        value={GrowthRate}
                        onChange={(e) => {
                          const noSymbol = e.target.value.replace("%", "");
                          const growthNum = parseFloat(noSymbol);
                          setGrowthRate(
                            !isNaN(growthNum)
                              ? parseFloat(growthNum.toFixed(2))
                              : 0
                          );
                        }}
                        placeholder={"%"}
                        usePercentage={true}
                        InputWidth={"100%"}
                        error={errors?.GrowthRate}
                        tooltipContent={TooltipContent.landGrowth}
                      />
                      <Text
                        alignSelf={"stretch"}
                        color={"var(--Optiml---Deep-Ocean-Blue, #007EB5)"}
                        fontFamily={"Nunito"}
                        fontSize={"16px"}
                        fontStyle={"normal"}
                        fontWeight={"600"}
                        lineHeight={"normal"}
                        width={"100%"}
                      >
                        Investment:
                      </Text>
                      {/* <NormalInputButton
                        Heading={"Ownership"}
                        value={Ownership}
                        onChange={(e) => {
                          const noSymbol = e.target.value.replace("%", "");
                          const growthNum = parseFloat(noSymbol);
                          setOwnership(
                            !isNaN(growthNum)
                              ? parseFloat(growthNum.toFixed(2))
                              : 0
                          );
                        }}
                        placeholder={"%"}
                        usePercentage={true}
                        InputWidth={"100%"}
                        error={errors?.Ownership}
                        tooltipContent={TooltipContent.landOwnership}
                      /> */}
                      <NormalInputButton
                        Heading={"Purchase price"}
                        value={PurchasePrice}
                        onChange={(e) => {
                          setPurchasePrice(moneyToNumber(e.target.value));
                        }}
                        placeholder={"$"}
                        useThousandSeparator={true}
                        InputWidth={"100%"}
                        error={errors?.PurchasePrice}
                        tooltipContent={TooltipContent.landPurchasePrice}
                      />
                      <NormalInputButton
                        Heading={"Capital improvements"}
                        value={CapitalImprovements}
                        onChange={(e) => {
                          setCapitalImprovements(moneyToNumber(e.target.value));
                        }}
                        placeholder={"$"}
                        useThousandSeparator={true}
                        customWidth={"100%"}
                        InputWidth={"100%"}
                        error={errors?.CapitalImprovements}
                        tooltipContent={TooltipContent.landCapitalImprovements}
                      />

                      <Text
                        alignSelf={"stretch"}
                        color={"var(--Optiml---Deep-Ocean-Blue, #007EB5)"}
                        fontFamily={"Nunito"}
                        fontSize={"16px"}
                        fontStyle={"normal"}
                        fontWeight={"600"}
                        lineHeight={"normal"}
                        width={"100%"}
                      >
                        Debt information:
                      </Text>
                      <NormalInputButton
                        Heading={"Remaining Debt balance"}
                        value={MortgageBalance}
                        onChange={(e) => {
                          setMortgageBalance(moneyToNumber(e.target.value));
                        }}
                        placeholder={"$"}
                        useThousandSeparator={true}
                        InputWidth={"100%"}
                        error={errors?.MortgageBalance}
                        tooltipContent={TooltipContent.landMortgage}
                      />
                      <NormalInputButton
                        Heading={"Monthly Debt payment"}
                        value={MonthlyPayment}
                        onChange={(e) => {
                          setMonthlyPayment(moneyToNumber(e.target.value));
                        }}
                        placeholder={"$"}
                        useThousandSeparator={true}
                        InputWidth={"100%"}
                        error={errors?.MonthlyPayment}
                        tooltipContent={TooltipContent.landPayment}
                      />
                      <NormalInputButton
                        Heading={"Debt interest rate"}
                        value={MortgageRate}
                        onChange={(e) => {
                          const noSymbol = e.target.value.replace("%", "");
                          const growthNum = parseFloat(noSymbol);
                          setMortgageRate(
                            !isNaN(growthNum)
                              ? parseFloat(growthNum.toFixed(2))
                              : 0
                          );
                        }}
                        placeholder={"%"}
                        usePercentage={true}
                        InputWidth={"100%"}
                        error={errors?.MortgageRate}
                        tooltipContent={TooltipContent.landMortgageRate}
                      />

                      <Text
                        alignSelf={"stretch"}
                        color={"var(--Optiml---Deep-Ocean-Blue, #007EB5)"}
                        fontFamily={"Nunito"}
                        fontSize={"16px"}
                        fontStyle={"normal"}
                        fontWeight={"600"}
                        lineHeight={"normal"}
                        width={"100%"}
                      >
                        Future Real Estate Plans
                      </Text>
                      <DropDownButton
                        Heading={"Do you have plans to sell your Land?"}
                        placeholder={"Select"}
                        dropDownItems={[
                          { key: "Yes", value: "Yes" },
                          { key: "No", value: "No" },
                        ]}
                        value={PlanToSell}
                        onChange={(key) => {
                          setPlanToSell(key);
                        }}
                        customWidth={"100%"}
                        MenuCustomWidth={"100%"}
                        error={errors?.PlanToSell}
                        tooltipContent={TooltipContent.landPlanToSell}
                      />
                      {PlanToSell === "Yes" && (
                        <DropDownButton
                          Heading={
                            "When do you anticipate you may sell this Land?"
                          }
                          placeholder={"Select"}
                          dropDownItems={Select(currentAge)}
                          value={YearsToSell}
                          onChange={(key) => {
                            setYearsToSell(key);
                          }}
                          customWidth={"100%"}
                          MenuCustomWidth={"100%"}
                          error={errors?.YearsToSell}
                          tooltipContent={TooltipContent.landSellYear}
                        />
                      )}
                    </>
                  )}
                </Box>
                {data.length > 0 && !adding && (
                  <Box
                    display={"flex"}
                    justifyContent={"flex-start"}
                    alignItems={"center"}
                    alignSelf={"stretch"}
                  >
                    <Box
                      display={"flex"}
                      padding={"8px 48px"}
                      marginTop={"20px"}
                      justifyContent={"flex-end"}
                      alignItems={"center"}
                      gap={"5px"}
                      borderRadius={"5px"}
                      bg={"#27AE60"}
                      cursor={"pointer"}
                      onClick={handleClick}
                    >
                      <Box
                        display={"flex"}
                        width={"76px"}
                        height={"21px"}
                        flexDirection={"column"}
                        justifyContent={"center"}
                      >
                        <Text
                          color={"#FFFFFF"}
                          textAlign={"center"}
                          fontFamily={"Nunito"}
                          fontSize={"16px"}
                          fontStyle={"normal"}
                          fontWeight={"600"}
                          lineHeight={"normal"}
                        >
                          Add
                        </Text>
                      </Box>
                    </Box>
                  </Box>
                )}
                {(data.length < 1 || adding) && (
                  <Box
                    display={"flex"}
                    justifyContent={"flex-end"}
                    alignItems={"center"}
                    alignSelf={"stretch"}
                  >
                    {renderAddUpdateRecreational()}
                  </Box>
                )}
              </>
            }
            // videoName={"Land Page"}
            // video={"Land.mp4"}
          />
        </VStack>
      )}
    </Flex>
  );
});

export default LandPage;
