import React, { useState, useEffect, useMemo, useContext } from "react";
import { useStore } from "./store";
import { useIdleTimer } from "react-idle-timer";
import { useAuth0 } from "@auth0/auth0-react";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import Route from "./components/Route";
import { BrowserRouter, Navigate } from "react-router-dom";
import Callback from "./components/Callback";
import Main from "./components/Main";
import EmailVerify from "./components/Email Verification Page";
import CompassSpinner from "./components/CompassSpinner";
import { StripeContext } from "./components/StripeContext";
import axios from "axios";
import StripePayments from "./components/StripePayment";
import "./App.css";
import ReactGA from "react-ga4";
import { ChakraBaseProvider, ChakraProvider } from "@chakra-ui/react";
function App() {
  const logoutMultiplier = useStore((store) => store.logoutMultiplier);
  ReactGA.initialize("G-C67JT6YMLZ");
  ReactGA.send({
    hitType: "pageview",
    page: document.location.pathname,
    title: document.location.pathname,
  });
  const [newUser, setNewUser] = useState(false);
  const [loading, setLoading] = useState(true);
  const [striped, setStriped] = useState(false);
  const {
    loginWithRedirect,
    logout,
    user,
    isAuthenticated,
    isLoading,
    getAccessTokenSilently,
  } = useAuth0();

  const onIdle = () => {
    console.log("User has been idle for 15 minutes, logging out...");
    logout(); // logs the user out using Auth0
  };
  const [stripePlan, setStripePlan] = useState("");
  const value = useMemo(
    () => ({ stripePlan, setStripePlan }),
    [stripePlan, setStripePlan]
  );

  useEffect(() => {
    const axiosInstance = axios.create();
    async function getToken() {
      // Replace this with your Auth0 token retrieval logic
      const token = await getAccessTokenSilently({
        audience:
          "https://os2lsiwff43wfpb5ggyawt6qri0mgcro.lambda-url.us-east-2.on.aws/",
        scope: "write:optimization",
      });
      return token;
    }
    // Add a request interceptor
    axiosInstance.interceptors.request.use(
      async (config) => {
        const token = await getToken();
        config.headers.Authorization = `Bearer ${token}`;
        return config;
      },
      (error) => {
        // Do something with request error
        return Promise.reject(error);
      }
    );

    if (isAuthenticated) {
      const auth0_params = {
        user_id: user.sub,
        action: "Get_Stripe_ID",
      };
      try {
        axiosInstance
          .post(process.env.REACT_APP_AUTH0_MANAGEMENT_API, auth0_params)
          .then((res) => {
            // console.log('Auth0 Params', res)
            const params = {
              stripe_id: res.data,
              action: "Check_Subscription",
            };
            axios
              .post(process.env.REACT_APP_STRIPE_API_KEY, params)
              .then((res) => {
                if (res.data.message === "Subscription Active") {
                  setStripePlan(res.data?.data?.[0]);
                  setLoading(false);
                  setStriped(true);
                } else if(res.data.message === "Subscription Paused"){
                  setStripePlan(res.data?.data?.[0]);
                  setLoading(false);
                  setStriped(false);
                } else {
                  setLoading(false);
                  setStriped(false);
                }
              })
              .catch((error) => {
                console.log(error);
                setLoading(false);
              });
          })
          .catch((error) => {
            console.log(error);
            setLoading(false);
          });
      } catch (error) {
        console.error("Error redirecting to checkout:", error);
        setLoading(false);
      }
    }
  }, [isAuthenticated]);

  // Setup the idle timer with a 15-minute timeout
  useIdleTimer({
    onIdle,
    timeout: 20 * 60 * 1000 * (logoutMultiplier >= 1 ? logoutMultiplier : 1), // 15 minutes in milliseconds
    debounce: 500, // Optional: debouncing the idle events
  });
  return (
    <BrowserRouter>
      <ChakraProvider>
        <Route path="/callback">
          <Callback />
        </Route>
        {loading && isAuthenticated && <CompassSpinner loading={loading} className="Main-Compass-Spinner-Class" small={false} />}
        {!loading && isAuthenticated && !striped && user?.email_verified && (
          <StripePayments
            user={user}
            setStriped={setStriped}
            getAccessTokenSilently={getAccessTokenSilently}
            stripeData={stripePlan}
          />
        )}
        {!isAuthenticated && !isLoading && !user && <Callback />}
        <StripeContext.Provider value={value}>
          {/* {!loading && isAuthenticated && <Main />} */}
          {!loading && isAuthenticated && striped && user?.email_verified && <Main />}
        </StripeContext.Provider>
        {!user?.email_verified && isAuthenticated && !striped && !loading && <EmailVerify /> }
      </ChakraProvider>
    </BrowserRouter>
  );
}
export default App;
