import { useAuth0 } from "@auth0/auth0-react";
import {
  Box,
  Flex,
  Heading,
  Text,
  VStack,
  useMediaQuery,
} from "@chakra-ui/react";
import axios from "axios";
import React, {
  forwardRef,
  useContext,
  useEffect,
  useImperativeHandle,
  useState,
} from "react";
import { useStore } from "../../store";
import GlobalBodyPage from "../GlobalContainer/BodyPage";
import DropDownButton from "../InputFields/dropDownButton";
import NormalInputButton from "../InputFields/normalInputButton";
import { SpouseContext } from "../SpouseContext";
import TooltipContent from "../TooltipContent";
import { error } from "jquery";
import { set } from "lodash";

const CppPage = forwardRef((props, ref) => {
  const { setChangedData } = props;
  useImperativeHandle(ref, () => ({
    submitHandler,
    checkMandatoryFields,
  }));
  const { getAccessTokenSilently } = useAuth0();

  const axiosInstance = axios.create();
  async function getToken() {
    // Replace this with your Auth0 token retrieval logic
    const token = await getAccessTokenSilently({
      audience:
        "https://os2lsiwff43wfpb5ggyawt6qri0mgcro.lambda-url.us-east-2.on.aws/",
      scope: "write:optimization",
    });
    return token;
  }

  // Add a request interceptor
  axiosInstance.interceptors.request.use(
    async (config) => {
      const token = await getToken();
      config.headers.Authorization = `Bearer ${token}`;
      return config;
    },
    (error) => {
      // Do something with request error
      return Promise.reject(error);
    }
  );

  const { spouseStatus, setSpouseStatus } = useContext(SpouseContext);
  const [isLargerThan768] = useMediaQuery("(min-width: 500px)");
  const isMobile = !isLargerThan768;
  const [cppSalary, setCppSalary] = useState("");
  // const [statementYear, setStatementYear] = useState();
  const [customCppSalary, setCustomCppSalary] = useState("");
  const [cppStatement, setCppStatement] = useState("");
  const [cppStatementValue, setCppStatementValue] = useState("");
  const [spouseCppSalary, setSpouseCppSalary] = useState("");
  const [spouseCustomCppSalary, setSpouseCustomCppSalary] = useState("");
  const [spouseCppStatement, setSpouseCppStatement] = useState("");
  const [spouseCppStatementValue, setSpouseCppStatementValue] = useState("");

  const [olderThan60, setOlderThan60] = useState(false);
  const [haveStartedCPP, setHaveStartedCPP] = useState("");
  const [haveStartedThisYear, setHaveStartedThisYear] = useState("");
  const [monthStartedCPP, setMonthStartedCPP] = useState("");

  const [spouseOlderThan60, setSpouseOlderThan60] = useState(false);
  const [spouseHaveStartedCPP, setSpouseHaveStartedCPP] = useState("");
  const [spouseHaveStartedThisYear, setSpouseHaveStartedThisYear] =
    useState("");
  const [spouseMonthStartedCPP, setSpouseMonthStartedCPP] = useState("");

  const { user, logout } = useAuth0();
  const userInfo = useStore((store) => store.userInfo);
  const userIncomeInfo = useStore((store) => store.userIncomeInfo);
  const setUserIncomeInfo = useStore((store) => store.setUserIncomeInfo);

  const [isEditMode, setIsEditMode] = useState(false);

  const userOptiData = useStore((store) => store.userOptiData);
  const [errors, setErrors] = useState({});
  const [spouseErrors, setSpouseErrors] = useState({});
  const [newUser, setNewUser] = useState();

  useEffect(() => {
    if (userOptiData?.primaryUserData?.expenses?.length > 1) {
      setNewUser(false);
    } else if (userOptiData?.primaryUserData?.expenses?.length === undefined) {
      setNewUser(true);
      setIsEditMode(true);
    }
  }, [userOptiData]);

  useEffect(() => {
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 100);
  }, [errors, spouseErrors]);

  // Function to toggle edit mode
  const toggleEditMode = () => {
    setIsEditMode(!isEditMode);
  };

  const {
    FirstName: firstName,
    LastName: lastName,
    DateOfBirth,
    spouseFirstName,
    spouseLastName,
    spouseDateOfBirth,
  } = userInfo;

  useEffect(() => {
    if (getAge(DateOfBirth) >= 60) {
      setOlderThan60(true);
    }
    if (getAge(spouseDateOfBirth) >= 60) {
      setSpouseOlderThan60(true);
    }
    const fetchData = async () => {
      if (userIncomeInfo !== null) {
        setCppStatement(userIncomeInfo?.Do_You_CPP);
        setCppStatementValue(userIncomeInfo?.Would_Monthly_Avg);
        setCustomCppSalary(userIncomeInfo?.Monthly_Retirement_Pension);
        setHaveStartedCPP(userIncomeInfo?.hasStartedCPP);
        setHaveStartedThisYear(userIncomeInfo?.hasStartedCPPThisYear);
        setMonthStartedCPP(userIncomeInfo?.cppMonth);

        setSpouseCppStatement(userIncomeInfo?.spouseDo_You_CPP);
        setSpouseCustomCppSalary(
          userIncomeInfo?.spouseMonthly_Retirement_Pension
        );
        setSpouseCppStatementValue(userIncomeInfo?.spouseWould_Monthly_Avg);
        setSpouseHaveStartedCPP(userIncomeInfo?.spouseHasStartedCPP);
        setSpouseHaveStartedThisYear(
          userIncomeInfo?.spouseHasStartedCPPThisYear
        );
        setSpouseMonthStartedCPP(userIncomeInfo?.spouseCPPMonth);
      } else {
        setCppStatement("");
        // setStatementYear('')
        setCustomCppSalary(0);
        setCppStatementValue("");
        setCustomCppSalary(0);
      }
    };
    fetchData().catch(console.error);
  }, []);

  function getAge(birthday) {
    // Get the current date
    const currentDate = new Date();

    // Convert the birthday string into a Date object
    const birthDate = new Date(birthday);

    // Calculate the age in years
    let age = currentDate.getFullYear() - birthDate.getFullYear();

    // Decrement the age if the current month and day are before the birth month and day
    if (
      currentDate.getMonth() < birthDate.getMonth() ||
      (currentDate.getMonth() === birthDate.getMonth() &&
        currentDate.getDate() <= birthDate.getDate())
    ) {
      age -= 1;
    }

    return age;
  }

  const moneyToNumber = (value) => {
    let numValue = 0;
    // console.log('VALUE 1', value)
    // console.log('VALUE', typeof(value))
    if (value) {
      numValue = value.replace(/[$,\s]/g, "");
      numValue = Number(numValue);
    }
    return numValue;
  };

  const checkMandatoryFields = () => {
    let newErrors = {};
    let newSpouseErrors = {};
    // Individual checks
    if (
      (!olderThan60 || haveStartedCPP === "No") &&
      (!cppStatementValue || cppStatementValue === "")
    ) {
      newErrors.cppStatementValue = "CPP statement is required";
    }
    if (
      cppStatementValue === "No" &&
      (!customCppSalary || customCppSalary > 1364)
    ) {
      newErrors.customCppSalary =
        "Custom CPP salary is required and must be less than $1,365";
    }
    if (olderThan60) {
      if (!haveStartedCPP) {
        newErrors.haveStartedCPP = "Status of CPP start is required";
      }
      if (haveStartedCPP === "Yes") {
        // Check if the 'Year of CPP start' is not specified
        if (!haveStartedThisYear) {
          newErrors.haveStartedThisYear = "Year of CPP start is required";
        }

        // Check if 'CPP salary' is not specified or is greater than $1,364
        if (!customCppSalary || customCppSalary > 1364) {
          newErrors.customCppSalary =
            "CPP salary is required and must be less than $1,365";
        }
      }

      if (
        (haveStartedThisYear === "Yes" && !monthStartedCPP) ||
        monthStartedCPP === "null"
      ) {
        newErrors.monthStartedCPP = "Month of CPP start is required";
      }
    }

    // Spouse checks
    if (spouseStatus) {
      if (
        (!spouseOlderThan60 || spouseHaveStartedCPP === "No") &&
        (!spouseCppStatementValue || spouseCppStatementValue === "")
      ) {
        newSpouseErrors.cppStatementValue = "CPP statement is required";
      }
      if (
        spouseCppStatementValue === "No" &&
        (!spouseCustomCppSalary || spouseCppStatementValue > 1364)
      ) {
        newSpouseErrors.customCppSalary =
          "Custom CPP salary is required and must be less than $1,365";
      }
      if (spouseOlderThan60) {
        if (!spouseHaveStartedCPP) {
          newSpouseErrors.spouseHaveStartedCPP =
            "Status of CPP start is required";
        }
        if (spouseHaveStartedCPP === "Yes") {
          // Check if the 'Year of CPP start' for the spouse is not specified
          if (!spouseHaveStartedThisYear) {
            newSpouseErrors.haveStartedThisYear =
              "Year of CPP start is required";
          }

          // Check if 'CPP salary' for the spouse is not specified or is greater than $1,364
          if (!spouseCustomCppSalary || spouseCustomCppSalary > 1364) {
            newSpouseErrors.customCppSalary =
              "CPP salary is required and must be less than $1,365";
          }
        }

        if (
          (spouseHaveStartedThisYear === "Yes" && !spouseMonthStartedCPP) ||
          spouseMonthStartedCPP === "null"
        ) {
          newSpouseErrors.monthStartedCPP = "Month of CPP start is required";
        }
      }
    }

    setSpouseErrors(newSpouseErrors);
    setErrors(newErrors);
    return (
      Object.keys(newErrors).length === 0 &&
      Object.keys(newSpouseErrors).length === 0
    ); // Return true if no errors
  };

  const submitHandler = (e) => {
    if (e) {
      e.preventDefault();
    }
    setIsEditMode(!isEditMode);
    let salaryCPP = customCppSalary;
    let customSalaryCPP = customCppSalary;
    let haveCPP = haveStartedCPP;
    let haveCPPNow = haveStartedThisYear;

    let SpouseCPPSalary = spouseCustomCppSalary;
    let SpouseCustomSalaryCPP = spouseCustomCppSalary;
    let spouseHaveCPP = spouseHaveStartedCPP;
    let spouseHaveCPPNow = spouseHaveStartedThisYear;

    if (!olderThan60) {
      setHaveStartedCPP("No");
      setHaveStartedThisYear("No");
      setMonthStartedCPP("");
      haveCPP = "No";
      haveCPPNow = "No";
      if (cppStatementValue === "Yes") {
        customSalaryCPP = 831;
        setCustomCppSalary(831);
      }
    }
    if (olderThan60) {
      if (haveStartedCPP === "No" && cppStatementValue === "Yes") {
        customSalaryCPP = 831;
        setCustomCppSalary(831);
      }
    }
    if (!spouseOlderThan60) {
      setSpouseHaveStartedCPP("No");
      setSpouseHaveStartedThisYear("No");
      setSpouseMonthStartedCPP("");
      spouseHaveCPP = "No";
      spouseHaveCPPNow = "No";
      if (spouseCppStatementValue === "Yes") {
        SpouseCustomSalaryCPP = 831;
        setSpouseCustomCppSalary(831);
      }
    }
    if (spouseOlderThan60) {
      if (spouseHaveStartedCPP === "No" && spouseCppStatementValue === "Yes") {
        SpouseCustomSalaryCPP = 831;
        setSpouseCustomCppSalary(831);
      }
    }

    const incomeURL = (action) =>
      `${
        process.env.REACT_APP_NODE_API_GATEWAY
      }/CompassLambdaApi/INCOME-CPP/${action}?IncomeID=${user.sub.replace(
        "|",
        "%7c"
      )}&Do_You_CPP=${cppStatement}&Monthly_Retirement_Pension=${customSalaryCPP}&Would_Monthly_Avg=${cppStatementValue}&CPP=${salaryCPP}&spouseDo_You_CPP=${spouseCppStatement}&spouseMonthly_Retirement_Pension=${SpouseCustomSalaryCPP}&spouseWould_Monthly_Avg=${spouseCppStatementValue}&spouseCPP=${SpouseCPPSalary}&hasStartedCPP=${haveCPP}&hasStartedCPPThisYear=${haveCPPNow}&cppMonth=${monthStartedCPP}&spouseHasStartedCPP=${spouseHaveCPP}&spouseHasStartedCPPThisYear=${spouseHaveCPPNow}&spouseCPPMonth=${spouseMonthStartedCPP}`;
    axiosInstance
      .get(incomeURL("add"))
      .then((res) => {
        console.log(res);
        setUserIncomeInfo({
          ...userIncomeInfo,
          Do_You_CPP: cppStatement,
          Monthly_Retirement_Pension: customSalaryCPP,
          Would_Monthly_Avg: cppStatementValue,
          CPP: salaryCPP,
          spouseDo_You_CPP: spouseCppStatement,
          spouseMonthly_Retirement_Pension: SpouseCustomSalaryCPP,
          spouseWould_Monthly_Avg: spouseCppStatementValue,
          spouseCPP: SpouseCPPSalary,
          hasStartedCPP: haveCPP,
          hasStartedCPPThisYear: haveCPPNow,
          cppMonth: monthStartedCPP,
          spouseHasStartedCPP: spouseHaveCPP,
          spouseHasStartedCPPThisYear: spouseHaveCPPNow,
          spouseCPPMonth: spouseMonthStartedCPP,
        });
        if (res.data.code === "ER_DUP_ENTRY") {
          axiosInstance.get(incomeURL("update")).then((res) => {
            console.log(res);
            setUserIncomeInfo({
              ...userIncomeInfo,
              Do_You_CPP: cppStatement,
              Monthly_Retirement_Pension: customSalaryCPP,
              Would_Monthly_Avg: cppStatementValue,
              CPP: salaryCPP,
              spouseDo_You_CPP: spouseCppStatement,
              spouseMonthly_Retirement_Pension: SpouseCustomSalaryCPP,
              spouseWould_Monthly_Avg: spouseCppStatementValue,
              spouseCPP: SpouseCPPSalary,
              hasStartedCPP: haveCPP,
              hasStartedCPPThisYear: haveCPPNow,
              cppMonth: monthStartedCPP,
              spouseHasStartedCPP: spouseHaveCPP,
              spouseHasStartedCPPThisYear: spouseHaveCPPNow,
              spouseCPPMonth: spouseMonthStartedCPP,
            });
          });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <Flex
      display="flex"
      flexWrap="wrap" // Enable wrapping
      justifyContent="flex-start"
      alignItems="start"
      width={"100%"}
    >
      {!isMobile && (
        <VStack alignItems={"flex-start"} width={"100%"} mt={-2}>
          <GlobalBodyPage
            Heading={
              userInfo?.Province === "QC"
                ? "Quebec Pension Plan (QPP)"
                : "Canadian Pension Plan (CPP)"
            }
            mainframeContent={
              <>
                {!olderThan60 && (
                  <VStack spacing={4} alignItems={"flex-start"} width={"100%"}>
                    <Flex justify="space-between" w="100%">
                      <Heading
                        height={"27px"}
                        alignSelf={"stretch"}
                        color={"#00466D"}
                        fontFamily={"Nunito"}
                        fontSize={"20px"}
                        fontStyle={"normal"}
                        fontWeight={"700"}
                        lineHeight={"normal"}
                      >
                        {firstName} {lastName}
                      </Heading>
                    </Flex>
                    {/* <DropDownButton
                      Heading={
                        <span>
                          Do you have a{" "}
                          <a
                            href="https://www.canada.ca/en/employment-social-development/services/my-account.html"
                            target="_blank"
                            rel="noopener noreferrer" // for security reasons
                          >
                            {userInfo?.Province === "QC"
                              ? "Quebec Pension Plan (QPP)"
                              : "Canada Pension Plan (CPP)"}
                          </a>{" "}
                          Statement of Contributions that states your retirement
                          pension estimate based on your past contributions?
                        </span>
                      }
                      placeholder={"Select"}
                      dropDownItems={[
                        { key: "Yes", value: "Yes" },
                        { key: "No", value: "No" },
                      ]}
                      value={cppStatement}
                      onChange={(key) => {
                        setCppStatement(key);
                        setChangedData(true);
                      }}
                      customWidth={"100%"}
                      error={errors.cppStatement}
                      tooltipContent={TooltipContent.startedCPPPayments}
                    />
                    {cppStatement === "Yes" && (
                      <NormalInputButton
                        Heading={
                          "Enter your gross monthly pre-tax retirement pension estimate (at age 65) indicated on your statement."
                        }
                        placeholder={"$"}
                        value={cppSalary}
                        onChange={(e) => {
                          const newValue = moneyToNumber(e.target.value);
                          if (newValue > 1364) {
                            errors.cppSalary =
                              "The maximum CPP payment is $1,364";
                            setCustomCppSalary(1364);
                          } else if (newValue <= 1364) {
                            setCustomCppSalary(newValue);
                            errors.cppSalary = "";
                          }
                          setChangedData(true);
                        }}
                        customWidth={"100%"}
                        useThousandSeparator={true}
                        error={errors.cppSalary}
                        tooltipContent={TooltipContent.monthlyCPPPayments}
                      />
                    )} */}
                    {/* {cppStatement === "No" && ( */}
                    <NoCpp
                      userInfo={userInfo}
                      cppStatementValue={cppStatementValue}
                      setCppStatementValue={setCppStatementValue}
                      customCppSalary={customCppSalary}
                      setCustomCppSalary={setCustomCppSalary}
                      moneyToNumber={moneyToNumber}
                      setChangedData={setChangedData}
                      errors={errors}
                    />
                    {/* )} */}
                  </VStack>
                )}
                {olderThan60 && (
                  <VStack spacing={4} alignItems={"flex-start"} width={"100%"}>
                    <Flex justify="space-between" w="100%">
                      <Heading
                        height={"27px"}
                        alignSelf={"stretch"}
                        color={"#00466D"}
                        fontFamily={"Nunito"}
                        fontSize={"20px"}
                        fontStyle={"normal"}
                        fontWeight={"700"}
                        lineHeight={"normal"}
                      >
                        {firstName} {lastName}
                      </Heading>
                    </Flex>
                    <DropDownButton
                      Heading={
                        <span>
                          Have you started receiving monthly{" "}
                          {userInfo?.Province === "QC" ? "(QPP)" : "(CPP)"}{" "}
                          payments?
                        </span>
                      }
                      placeholder={"Select"}
                      dropDownItems={[
                        { key: "Yes", value: "Yes" },
                        { key: "No", value: "No" },
                      ]}
                      value={haveStartedCPP}
                      onChange={(key) => {
                        setHaveStartedCPP(key);
                        setChangedData(true);
                      }}
                      customWidth={"100%"}
                      error={errors.haveStartedCPP}
                      tooltipContent={TooltipContent.startedCPPPayments}
                    />
                    {haveStartedCPP === "Yes" && (
                      <StartedCPP
                        haveStartedThisYear={haveStartedThisYear}
                        setHaveStartedThisYear={setHaveStartedThisYear}
                        monthStartedCPP={monthStartedCPP}
                        setMonthStartedCPP={setMonthStartedCPP}
                        cppSalary={customCppSalary}
                        setCppSalary={setCustomCppSalary}
                        setChangedData={setChangedData}
                        moneyToNumber={moneyToNumber}
                        errors={errors}
                      />
                    )}
                    {haveStartedCPP === "No" && (
                      //   <DropDownButton
                      //     Heading={
                      //       <span>
                      //         Do you have a{" "}
                      //         <a
                      //           href="https://www.canada.ca/en/employment-social-development/services/my-account.html"
                      //           target="_blank"
                      //           rel="noopener noreferrer" // for security reasons
                      //         >
                      //           {userInfo?.Province === "QC"
                      //             ? "Quebec Pension Plan (QPP)"
                      //             : "Canada Pension Plan (CPP)"}
                      //         </a>{" "}
                      //         Statement of Contributions that states your
                      //         retirement pension estimate based on your past
                      //         contributions?
                      //       </span>
                      //     }
                      //     placeholder={"Select"}
                      //     dropDownItems={[
                      //       { key: "Yes", value: "Yes" },
                      //       { key: "No", value: "No" },
                      //     ]}
                      //     value={cppStatement}
                      //     onChange={(key) => {
                      //       setCppStatement(key);
                      //       setChangedData(true);
                      //     }}
                      //     customWidth={"100%"}
                      //     error={errors.cppStatement}
                      //     tooltipContent={TooltipContent.startedCPPPayments}
                      //   />
                      // )}
                      // {cppStatement === "Yes" && haveStartedCPP == "No" && (
                      //   <NormalInputButton
                      //     Heading={
                      //       "Enter your gross monthly pre-tax retirement pension estimate (at age 65) indicated on your statement."
                      //     }
                      //     placeholder={"$"}
                      //     value={cppSalary}
                      //     onChange={(e) => {
                      //       const newValue = moneyToNumber(e.target.value);
                      //       if (newValue > 1364) {
                      //         errors.cppSalary =
                      //           "The maximum CPP payment is $1,364";
                      //         setCppSalary(1364);
                      //       } else if (newValue <= 1364) {
                      //         setCppSalary(newValue);
                      //         errors.cppSalary = "";
                      //       }
                      //       setChangedData(true);
                      //     }}
                      //     customWidth={"100%"}
                      //     useThousandSeparator={true}
                      //     error={errors.cppSalary}
                      //     tooltipContent={TooltipContent.monthlyCPPPayments}
                      //   />
                      // )}
                      // {cppStatement === "No" && haveStartedCPP == "No" && (
                      <NoCpp
                        userInfo={userInfo}
                        cppStatementValue={cppStatementValue}
                        setCppStatementValue={setCppStatementValue}
                        customCppSalary={customCppSalary}
                        setCustomCppSalary={setCustomCppSalary}
                        moneyToNumber={moneyToNumber}
                        setChangedData={setChangedData}
                        errors={errors}
                      />
                    )}
                  </VStack>
                )}
              </>
            }
            videoName={"CPP Page"}
            video={"CPP_Page.mp4"}
          />

          {spouseStatus && (
            <GlobalBodyPage
              mainframeContent={
                <>
                  {!spouseOlderThan60 && (
                    <VStack
                      spacing={4}
                      alignItems={"flex-start"}
                      width={"100%"}
                    >
                      <Flex justify="space-between" w="100%">
                        <Heading
                          height={"27px"}
                          alignSelf={"stretch"}
                          color={"#00466D"}
                          fontFamily={"Nunito"}
                          fontSize={"20px"}
                          fontStyle={"normal"}
                          fontWeight={"700"}
                          lineHeight={"normal"}
                        >
                          {spouseFirstName} {spouseLastName}
                        </Heading>
                      </Flex>
                      {/* <DropDownButton
                        Heading={
                          <span>
                            Do you have a{" "}
                            <a
                              href="https://www.canada.ca/en/employment-social-development/services/my-account.html"
                              target="_blank"
                              rel="noopener noreferrer" // for security reasons
                            >
                              {userInfo?.Province === "QC"
                                ? "Quebec Pension Plan (QPP)"
                                : "Canada Pension Plan (CPP)"}
                            </a>{" "}
                            Statement of Contributions that states your
                            retirement pension estimate based on your past
                            contributions?
                          </span>
                        }
                        placeholder={"Select"}
                        dropDownItems={[
                          { key: "Yes", value: "Yes" },
                          { key: "No", value: "No" },
                        ]}
                        value={spouseCppStatement}
                        onChange={(key) => {
                          setSpouseCppStatement(key);
                          setChangedData(true);
                        }}
                        customWidth={"100%"}
                        error={spouseErrors.spouseCppStatement}
                        tooltipContent={TooltipContent.startedCPPPayments}
                      /> */}
                      {/* {spouseCppStatement === "Yes" && (
                        <NormalInputButton
                          Heading={
                            "Enter your gross monthly pre-tax retirement pension estimate (at age 65) indicated on your statement."
                          }
                          placeholder={"$"}
                          value={spouseCppSalary}
                          onChange={(e) => {
                            const newValue = moneyToNumber(e.target.value);
                            if (newValue > 1364) {
                              spouseErrors.cppSalary =
                                "The maximum CPP payment is $1,364";
                              setSpouseCppSalary(1364);
                            } else if (newValue <= 1364) {
                              setSpouseCppSalary(newValue);
                              spouseErrors.cppSalary = "";
                            }
                            setChangedData(true);
                          }}
                          customWidth={"100%"}
                          useThousandSeparator={true}
                          error={spouseErrors.cppSalary}
                          tooltipContent={TooltipContent.monthlyCPPPayments}
                        />
                      )} */}
                      {/* {spouseCppStatement === "No" && ( */}
                      <NoCpp
                        userInfo={userInfo}
                        cppStatementValue={spouseCppStatementValue}
                        setCppStatementValue={setSpouseCppStatementValue}
                        customCppSalary={spouseCustomCppSalary}
                        setCustomCppSalary={setSpouseCustomCppSalary}
                        moneyToNumber={moneyToNumber}
                        setChangedData={setChangedData}
                        errors={spouseErrors}
                      />
                      {/* )} */}
                    </VStack>
                  )}
                  {spouseOlderThan60 && (
                    <VStack
                      spacing={4}
                      alignItems={"flex-start"}
                      width={"100%"}
                    >
                      <Flex justify="space-between" w="100%">
                        <Heading
                          height={"27px"}
                          alignSelf={"stretch"}
                          color={"#00466D"}
                          fontFamily={"Nunito"}
                          fontSize={"20px"}
                          fontStyle={"normal"}
                          fontWeight={"700"}
                          lineHeight={"normal"}
                        >
                          {spouseFirstName} {spouseLastName}
                        </Heading>
                      </Flex>
                      <DropDownButton
                        Heading={
                          <span>
                            Have you started receiving monthly{" "}
                            {userInfo?.Province === "QC" ? "(QPP)" : "(CPP)"}{" "}
                            payments?
                          </span>
                        }
                        placeholder={"Select"}
                        dropDownItems={[
                          { key: "Yes", value: "Yes" },
                          { key: "No", value: "No" },
                        ]}
                        value={spouseHaveStartedCPP}
                        onChange={(key) => {
                          setSpouseHaveStartedCPP(key);
                          setChangedData(true);
                        }}
                        customWidth={"100%"}
                        error={spouseErrors.spouseHaveStartedCPP}
                        tooltipContent={TooltipContent.startedCPPPayments}
                      />
                      {spouseHaveStartedCPP === "Yes" && (
                        <StartedCPP
                          haveStartedThisYear={spouseHaveStartedThisYear}
                          setHaveStartedThisYear={setSpouseHaveStartedThisYear}
                          monthStartedCPP={spouseMonthStartedCPP}
                          setMonthStartedCPP={setSpouseMonthStartedCPP}
                          cppSalary={spouseCustomCppSalary}
                          setCppSalary={setSpouseCustomCppSalary}
                          setChangedData={setChangedData}
                          moneyToNumber={moneyToNumber}
                          errors={spouseErrors}
                        />
                      )}
                      {spouseHaveStartedCPP === "No" && (
                        //   <DropDownButton
                        //     Heading={
                        //       <span>
                        //         Do you have a{" "}
                        //         <a
                        //           href="https://www.canada.ca/en/employment-social-development/services/my-account.html"
                        //           target="_blank"
                        //           rel="noopener noreferrer" // for security reasons
                        //         >
                        //           {userInfo?.Province === "QC"
                        //             ? "Quebec Pension Plan (QPP)"
                        //             : "Canada Pension Plan (CPP)"}
                        //         </a>{" "}
                        //         Statement of Contributions that states your
                        //         retirement pension estimate based on your past
                        //         contributions?
                        //       </span>
                        //     }
                        //     placeholder={"Select"}
                        //     dropDownItems={[
                        //       { key: "Yes", value: "Yes" },
                        //       { key: "No", value: "No" },
                        //     ]}
                        //     value={spouseCppStatement}
                        //     onChange={(key) => {
                        //       setSpouseCppStatement(key);
                        //       setChangedData(true);
                        //     }}
                        //     customWidth={"100%"}
                        //     error={spouseErrors.spouseCppStatement}
                        //     tooltipContent={TooltipContent.startedCPPPayments}
                        //   />
                        // )}
                        // {spouseCppStatement === "Yes" &&
                        //   spouseHaveStartedCPP === "No" && (
                        //     <NormalInputButton
                        //       Heading={
                        //         "Enter your gross monthly pre-tax retirement pension estimate (at age 65) indicated on your statement."
                        //       }
                        //       placeholder={"$"}
                        //       value={spouseCppSalary}
                        //       onChange={(e) => {
                        //         const newValue = moneyToNumber(e.target.value);
                        //         if (newValue > 1364) {
                        //           spouseErrors.cppSalary =
                        //             "The maximum CPP payment is $1,364";
                        //           setSpouseCppSalary(1364);
                        //         } else if (newValue <= 1364) {
                        //           setSpouseCppSalary(newValue);
                        //           spouseErrors.cppSalary = "";
                        //         }
                        //         setChangedData(true);
                        //       }}
                        //       customWidth={"100%"}
                        //       useThousandSeparator={true}
                        //       error={spouseErrors.cppSalary}
                        //       tooltipContent={TooltipContent.monthlyCPPPayments}
                        //     />
                        //   )}
                        // {spouseCppStatement === "No" &&
                        //   spouseHaveStartedCPP === "No" && (
                        <NoCpp
                          userInfo={userInfo}
                          cppStatementValue={spouseCppStatementValue}
                          setCppStatementValue={setSpouseCppStatementValue}
                          customCppSalary={spouseCustomCppSalary}
                          setCustomCppSalary={setSpouseCustomCppSalary}
                          moneyToNumber={moneyToNumber}
                          setChangedData={setChangedData}
                          errors={spouseErrors}
                        />
                      )}
                    </VStack>
                  )}
                </>
              }
            />
          )}
        </VStack>
      )}

      {isMobile && (
        <VStack spacing={4} alignItems={"flex-start"} width={"100%"}>
          <GlobalBodyPage
            Heading={
              userInfo?.Province === "QC"
                ? "Quebec Pension Plan (QPP)"
                : "Canadian Pension Plan (CPP)"
            }
            mainframeContent={
              <>
                {!olderThan60 && (
                  <VStack spacing={4} alignItems={"flex-start"} width={"100%"}>
                    <Flex justify="space-between" w="100%">
                      <Heading
                        height={"27px"}
                        alignSelf={"stretch"}
                        color={"#00466D"}
                        fontFamily={"Nunito"}
                        fontSize={"20px"}
                        fontStyle={"normal"}
                        fontWeight={"700"}
                        lineHeight={"normal"}
                      >
                        {firstName} {lastName}
                      </Heading>
                    </Flex>
                    {/* <DropDownButton
                      Heading={
                        <span>
                          Do you have a{" "}
                          <a
                            href="https://www.canada.ca/en/employment-social-development/services/my-account.html"
                            target="_blank"
                            rel="noopener noreferrer" // for security reasons
                          >
                            {userInfo?.Province === "QC"
                              ? "Quebec Pension Plan (QPP)"
                              : "Canada Pension Plan (CPP)"}
                          </a>{" "}
                          Statement of Contributions that states your retirement
                          pension estimate based on your past contributions?
                        </span>
                      }
                      placeholder={"Select"}
                      dropDownItems={[
                        { key: "Yes", value: "Yes" },
                        { key: "No", value: "No" },
                      ]}
                      value={cppStatement}
                      onChange={(key) => {
                        setCppStatement(key);
                        setChangedData(true);
                      }}
                      customWidth={"100%"}
                      MenuCustomWidth={"100%"}
                      error={errors.cppStatement}
                      tooltipContent={TooltipContent.startedCPPPayments}
                    />
                    {cppStatement === "Yes" && (
                      <NormalInputButton
                        Heading={
                          "Enter your gross monthly pre-tax retirement pension estimate (at age 65) indicated on your statement."
                        }
                        placeholder={"$"}
                        value={cppSalary}
                        onChange={(e) => {
                          const newValue = moneyToNumber(e.target.value);
                          if (newValue > 1364) {
                            errors.cppSalary =
                              "The maximum CPP payment is $1,364";
                            setCppSalary(1364);
                          } else if (newValue <= 1364) {
                            setCppSalary(newValue);
                            errors.cppSalary = "";
                          }
                          setChangedData(true);
                        }}
                        customWidth={"100%"}
                        useThousandSeparator={true}
                        InputWidth={"100%"}
                        error={errors.cppSalary}
                        tooltipContent={TooltipContent.monthlyCPPPayments}
                      />
                    )}
                    {cppStatement === "No" && ( */}
                    <NoCppMobile
                      userInfo={userInfo}
                      cppStatementValue={cppStatementValue}
                      setCppStatementValue={setCppStatementValue}
                      customCppSalary={customCppSalary}
                      setCustomCppSalary={setCustomCppSalary}
                      moneyToNumber={moneyToNumber}
                      setChangedData={setChangedData}
                      errors={errors}
                    />
                    {/* )} */}
                  </VStack>
                )}
                {olderThan60 && (
                  <VStack spacing={4} alignItems={"flex-start"} width={"100%"}>
                    <Flex justify="space-between" w="100%">
                      <Heading
                        height={"27px"}
                        alignSelf={"stretch"}
                        color={"#00466D"}
                        fontFamily={"Nunito"}
                        fontSize={"20px"}
                        fontStyle={"normal"}
                        fontWeight={"700"}
                        lineHeight={"normal"}
                      >
                        {firstName} {lastName}
                      </Heading>
                    </Flex>
                    <DropDownButton
                      Heading={
                        <span>
                          Have you started receiving monthly{" "}
                          {userInfo?.Province === "QC" ? "(QPP)" : "(CPP)"}{" "}
                          payments?
                        </span>
                      }
                      placeholder={"Select"}
                      dropDownItems={[
                        { key: "Yes", value: "Yes" },
                        { key: "No", value: "No" },
                      ]}
                      value={haveStartedCPP}
                      onChange={(key) => {
                        setHaveStartedCPP(key);
                        setChangedData(true);
                      }}
                      customWidth={"100%"}
                      MenuCustomWidth={"100%"}
                      error={errors.haveStartedCPP}
                      tooltipContent={TooltipContent.startedCPPPayments}
                    />
                    {haveStartedCPP === "Yes" && (
                      <StartedCPP
                        haveStartedThisYear={haveStartedThisYear}
                        setHaveStartedThisYear={setHaveStartedThisYear}
                        monthStartedCPP={monthStartedCPP}
                        setMonthStartedCPP={setMonthStartedCPP}
                        cppSalary={customCppSalary}
                        setCppSalary={setCustomCppSalary}
                        setChangedData={setChangedData}
                        moneyToNumber={moneyToNumber}
                        errors={errors}
                      />
                    )}
                    {haveStartedCPP === "No" && (
                      //   <DropDownButton
                      //     Heading={
                      //       <span>
                      //         Do you have a{" "}
                      //         <a
                      //           href="https://www.canada.ca/en/employment-social-development/services/my-account.html"
                      //           target="_blank"
                      //           rel="noopener noreferrer" // for security reasons
                      //         >
                      //           {userInfo?.Province === "QC"
                      //             ? "Quebec Pension Plan (QPP)"
                      //             : "Canada Pension Plan (CPP)"}
                      //         </a>{" "}
                      //         Statement of Contributions that states your
                      //         retirement pension estimate based on your past
                      //         contributions?
                      //       </span>
                      //     }
                      //     placeholder={"Select"}
                      //     dropDownItems={[
                      //       { key: "Yes", value: "Yes" },
                      //       { key: "No", value: "No" },
                      //     ]}
                      //     value={cppStatement}
                      //     onChange={(key) => {
                      //       setCppStatement(key);
                      //       setChangedData(true);
                      //     }}
                      //     customWidth={"100%"}
                      //     MenuCustomWidth={"100%"}
                      //     error={errors.cppStatement}
                      //     tooltipContent={TooltipContent.startedCPPPayments}
                      //   />
                      // )}
                      // {cppStatement === "Yes" && haveStartedCPP === "No" && (
                      //   <NormalInputButton
                      //     Heading={
                      //       "Enter your gross monthly pre-tax retirement pension estimate (at age 65) indicated on your statement."
                      //     }
                      //     placeholder={"$"}
                      //     value={cppSalary}
                      //     onChange={(e) => {
                      //       const newValue = moneyToNumber(e.target.value);
                      //       if (newValue > 1364) {
                      //         errors.cppSalary =
                      //           "The maximum CPP payment is $1,364";
                      //         setCppSalary(1364);
                      //       } else if (newValue <= 1364) {
                      //         setCppSalary(newValue);
                      //         errors.cppSalary = "";
                      //       }
                      //       setChangedData(true);
                      //     }}
                      //     customWidth={"100%"}
                      //     useThousandSeparator={true}
                      //     InputWidth={"100%"}
                      //     error={errors.cppSalary}
                      //     tooltipContent={TooltipContent.monthlyCPPPayments}
                      //   />
                      // )}
                      // {cppStatement === "No" && haveStartedCPP === "No" && (
                      <NoCppMobile
                        userInfo={userInfo}
                        cppStatementValue={cppStatementValue}
                        setCppStatementValue={setCppStatementValue}
                        customCppSalary={customCppSalary}
                        setCustomCppSalary={setCustomCppSalary}
                        moneyToNumber={moneyToNumber}
                        setChangedData={setChangedData}
                        errors={errors}
                      />
                    )}
                  </VStack>
                )}
              </>
            }
            videoName={"CPP Page"}
            video={"CPP_Page.mp4"}
          />

          {spouseStatus && (
            <GlobalBodyPage
              mainframeContent={
                <>
                  {!spouseOlderThan60 && (
                    <VStack
                      spacing={4}
                      alignItems={"flex-start"}
                      width={"100%"}
                    >
                      <Flex justify="space-between" w="100%">
                        <Heading
                          height={"27px"}
                          alignSelf={"stretch"}
                          color={"#00466D"}
                          fontFamily={"Nunito"}
                          fontSize={"20px"}
                          fontStyle={"normal"}
                          fontWeight={"700"}
                          lineHeight={"normal"}
                        >
                          {spouseFirstName} {spouseLastName}
                        </Heading>
                      </Flex>
                      {/* <DropDownButton
                        Heading={
                          <span>
                            Do you have a{" "}
                            <a
                              href="https://www.canada.ca/en/employment-social-development/services/my-account.html"
                              target="_blank"
                              rel="noopener noreferrer" // for security reasons
                            >
                              {userInfo?.Province === "QC"
                                ? "Quebec Pension Plan (QPP)"
                                : "Canada Pension Plan (CPP)"}
                            </a>{" "}
                            Statement of Contributions that states your
                            retirement pension estimate based on your past
                            contributions?
                          </span>
                        }
                        placeholder={"Select"}
                        dropDownItems={[
                          { key: "Yes", value: "Yes" },
                          { key: "No", value: "No" },
                        ]}
                        value={spouseCppStatement}
                        onChange={(key) => {
                          setSpouseCppStatement(key);
                          setChangedData(true);
                        }}
                        customWidth={"100%"}
                        MenuCustomWidth={"100%"}
                        error={spouseErrors.spouseCppStatement}
                        tooltipContent={TooltipContent.startedCPPPayments}
                      />
                      {spouseCppStatement === "Yes" && (
                        <NormalInputButton
                          Heading={
                            "Enter your gross monthly pre-tax retirement pension estimate (at age 65) indicated on your statement."
                          }
                          placeholder={"$"}
                          value={spouseCppSalary}
                          onChange={(e) => {
                            const newValue = moneyToNumber(e.target.value);
                            if (newValue > 1364) {
                              spouseErrors.cppSalary =
                                "The maximum CPP payment is $1,364";
                              setSpouseCppSalary(1364);
                            } else if (newValue <= 1364) {
                              setSpouseCppSalary(newValue);
                              spouseErrors.cppSalary = "";
                            }
                            setChangedData(true);
                          }}
                          customWidth={"100%"}
                          useThousandSeparator={true}
                          InputWidth={"100%"}
                          error={spouseErrors.cppSalary}
                          tooltipContent={TooltipContent.monthlyCPPPayments}
                        />
                      )}
                      {spouseCppStatement === "No" && ( */}
                      <NoCppMobile
                        userInfo={userInfo}
                        cppStatementValue={spouseCppStatementValue}
                        setCppStatementValue={setSpouseCppStatementValue}
                        customCppSalary={spouseCustomCppSalary}
                        setCustomCppSalary={setSpouseCustomCppSalary}
                        moneyToNumber={moneyToNumber}
                        setChangedData={setChangedData}
                        errors={spouseErrors}
                      />
                      {/* )} */}
                    </VStack>
                  )}
                  {spouseOlderThan60 && (
                    <VStack
                      spacing={4}
                      alignItems={"flex-start"}
                      width={"100%"}
                    >
                      <Flex justify="space-between" w="100%">
                        <Heading
                          height={"27px"}
                          alignSelf={"stretch"}
                          color={"#00466D"}
                          fontFamily={"Nunito"}
                          fontSize={"20px"}
                          fontStyle={"normal"}
                          fontWeight={"700"}
                          lineHeight={"normal"}
                        >
                          {spouseFirstName} {spouseLastName}
                        </Heading>
                      </Flex>
                      <DropDownButton
                        Heading={
                          <span>
                            Have you started receiving monthly{" "}
                            {userInfo?.Province === "QC" ? "(QPP)" : "(CPP)"}{" "}
                            payments?
                          </span>
                        }
                        placeholder={"Select"}
                        dropDownItems={[
                          { key: "Yes", value: "Yes" },
                          { key: "No", value: "No" },
                        ]}
                        value={spouseHaveStartedCPP}
                        onChange={(key) => {
                          setSpouseHaveStartedCPP(key);
                          setChangedData(true);
                        }}
                        customWidth={"100%"}
                        MenuCustomWidth={"100%"}
                        error={spouseErrors.spouseHaveStartedCPP}
                        tooltipContent={TooltipContent.startedCPPPayments}
                      />
                      {spouseHaveStartedCPP === "Yes" && (
                        <StartedCPP
                          haveStartedThisYear={spouseHaveStartedThisYear}
                          setHaveStartedThisYear={setSpouseHaveStartedThisYear}
                          monthStartedCPP={spouseMonthStartedCPP}
                          setMonthStartedCPP={setSpouseMonthStartedCPP}
                          cppSalary={spouseCustomCppSalary}
                          setCppSalary={setSpouseCustomCppSalary}
                          setChangedData={setChangedData}
                          moneyToNumber={moneyToNumber}
                          errors={spouseErrors}
                        />
                      )}
                      {spouseHaveStartedCPP === "No" && (
                        //   <DropDownButton
                        //     Heading={
                        //       <span>
                        //         Do you have a{" "}
                        //         <a
                        //           href="https://www.canada.ca/en/employment-social-development/services/my-account.html"
                        //           target="_blank"
                        //           rel="noopener noreferrer" // for security reasons
                        //         >
                        //           {userInfo?.Province === "QC"
                        //             ? "Quebec Pension Plan (QPP)"
                        //             : "Canada Pension Plan (CPP)"}
                        //         </a>{" "}
                        //         Statement of Contributions that states your
                        //         retirement pension estimate based on your past
                        //         contributions?
                        //       </span>
                        //     }
                        //     placeholder={"Select"}
                        //     dropDownItems={[
                        //       { key: "Yes", value: "Yes" },
                        //       { key: "No", value: "No" },
                        //     ]}
                        //     value={spouseCppStatement}
                        //     onChange={(key) => {
                        //       setSpouseCppStatement(key);
                        //       setChangedData(true);
                        //     }}
                        //     customWidth={"100%"}
                        //     MenuCustomWidth={"100%"}
                        //     error={spouseErrors.spouseCppStatement}
                        //     tooltipContent={TooltipContent.startedCPPPayments}
                        //   />
                        // )}
                        // {spouseCppStatement === "Yes" &&
                        //   spouseHaveStartedCPP === "No" && (
                        //     <NormalInputButton
                        //       Heading={
                        //         "Enter your gross monthly pre-tax retirement pension estimate (at age 65) indicated on your statement."
                        //       }
                        //       placeholder={"$"}
                        //       value={spouseCppSalary}
                        //       onChange={(e) => {
                        //         const newValue = moneyToNumber(e.target.value);
                        //         if (newValue > 1364) {
                        //           spouseErrors.cppSalary =
                        //             "The maximum CPP payment is $1,364";
                        //           setSpouseCppSalary(1364);
                        //         } else if (newValue <= 1364) {
                        //           setSpouseCppSalary(newValue);
                        //           spouseErrors.cppSalary = "";
                        //         }
                        //         setChangedData(true);
                        //       }}
                        //       customWidth={"100%"}
                        //       useThousandSeparator={true}
                        //       InputWidth={"100%"}
                        //       error={spouseErrors.cppSalary}
                        //       tooltipContent={TooltipContent.monthlyCPPPayments}
                        //     />
                        //   )}
                        // {spouseCppStatement === "No" &&
                        //   spouseHaveStartedCPP === "No" && (
                        <NoCppMobile
                          userInfo={userInfo}
                          cppStatementValue={spouseCppStatementValue}
                          setCppStatementValue={setSpouseCppStatementValue}
                          customCppSalary={spouseCustomCppSalary}
                          setCustomCppSalary={setSpouseCustomCppSalary}
                          moneyToNumber={moneyToNumber}
                          errors={spouseErrors}
                          setChangedData={setChangedData}
                        />
                      )}
                    </VStack>
                  )}
                </>
              }
            />
          )}
        </VStack>
      )}
    </Flex>
  );
});

function StartedCPP({
  haveStartedThisYear,
  setHaveStartedThisYear,
  monthStartedCPP,
  setMonthStartedCPP,
  cppSalary,
  setCppSalary,
  errors,
  setChangedData,
  moneyToNumber,
}) {
  const monthDropDown = [
    { key: "January", value: "January" },
    { key: "February", value: "February" },
    { key: "March", value: "March" },
    { key: "April", value: "April" },
    { key: "May", value: "May" },
    { key: "June", value: "June" },
    { key: "July", value: "July" },
    { key: "August", value: "August" },
    { key: "September", value: "September" },
    { key: "October", value: "October" },
    { key: "November", value: "November" },
    { key: "December", value: "December" },
  ];

  // Get the current month (0-11, where 0 is January and 11 is December)
  const currentMonth = new Date().getMonth();

  const [isLargerThan768] = useMediaQuery("(min-width: 500px)");
  const isMobile = !isLargerThan768;

  // Filter the monthDropDown to include only the months up to the current month
  const filteredMonthDropDown = monthDropDown.slice(0, currentMonth + 1);

  return (
    <Flex w="100%" flexDirection={"column"} gap={"10px"}>
      <Flex justify="space-between" w="full" alignItems="flex-end">
        <DropDownButton
          Heading={"Did you start taking your CPP this year?"}
          placeholder={"Select"}
          dropDownItems={[
            { key: "Yes", value: "Yes" },
            { key: "No", value: "No" },
          ]}
          customWidth={"100%"}
          value={haveStartedThisYear}
          onChange={(key) => {
            setHaveStartedThisYear(key);
            setChangedData(true);
          }}
          error={errors.haveStartedThisYear}
          MenuCustomWidth={isMobile && "100%"}
          tooltipContent={TooltipContent.startedCPPPayments}
        />
      </Flex>
      {haveStartedThisYear === "Yes" && (
        <Flex justify="space-between" w="full" alignItems="flex-end">
          <DropDownButton
            Heading={"What month did you start receiving CPP?"}
            placeholder={"Select"}
            dropDownItems={filteredMonthDropDown}
            customWidth={"100%"}
            value={monthStartedCPP}
            onChange={(key) => {
              setMonthStartedCPP(key);
              setChangedData(true);
            }}
            error={errors.monthStartedCPP}
            tooltipContent={TooltipContent.startedCPPPayments}
            MenuCustomWidth={isMobile && "100%"}
          />
        </Flex>
      )}
      {haveStartedThisYear && (
        <NormalInputButton
          Heading={"Enter your monthly CPP payment."}
          placeholder={"$"}
          value={cppSalary}
          onChange={(e) => {
            const newValue = moneyToNumber(e.target.value);
            setCppSalary(newValue);
            // if (newValue <= 1364) {
            //   setCppSalary(newValue);
            //   errors.cppSalary = "";
            // } else if (newValue > 1364) {
            //   errors.cppSalary = "The maximum monthly CPP payout is $1,364";
            //   setCppSalary(1364);
            // }
            setChangedData(true);
          }}
          customWidth={"100%"}
          InputWidth={isMobile && "100%"}
          useThousandSeparator={true}
          error={errors.customCppSalary}
          tooltipContent={TooltipContent.enterMonthlyCPPPayout}
        />
      )}
    </Flex>
  );
}

function NoCpp({
  userInfo,
  cppStatementValue,
  setCppStatementValue,
  customCppSalary,
  setCustomCppSalary,
  moneyToNumber,
  setChangedData,
  errors,
}) {
  return (
    <Flex w="100%" flexDirection={"column"} gap={"10px"}>
      <Flex justify="space-between" w="full" alignItems="flex-end">
        <DropDownButton
          Heading={
            <span>
              How would you like to include your{" "}
              <a
                href="https://www.canada.ca/en/employment-social-development/services/my-account.html"
                target="_blank"
                rel="noopener noreferrer" // for security reasons
              >
                {userInfo?.Province === "QC"
                  ? "Quebec Pension Plan (QPP)"
                  : "Canada Pension Plan (CPP)"}
              </a>{" "}
              in your analysis?
            </span>
          }
          placeholder={"Select"}
          dropDownItems={[
            { key: "Yes", value: "Use the monthly average" },
            { key: "No", value: "Input my own value" },
          ]}
          customWidth={"100%"}
          value={cppStatementValue}
          onChange={(key) => {
            setCppStatementValue(key);
            setChangedData(true);
          }}
          error={errors.cppStatementValue}
          tooltipContent={TooltipContent.useCPPAverageYesNo}
        />
      </Flex>
      {cppStatementValue === "Yes" && (
        <Box
          display={"flex"}
          width={"100%"}
          padding={"10px"}
          flexDirection={"column"}
          alignItems={"flex-start"}
          gap={"10px"}
          borderRadius={"10px"}
          border={"1px solid #00A2D4"}
          bg={"rgba(0, 162, 212, 0.1)"}
        >
          <Text
            alignSelf={"stretch"}
            color={"#00A2D4"}
            textAlign={"center"}
            fontFamily={"Nunito"}
            fontSize={"14px"}
            fontStyle={"normal"}
            fontWeight={"700"}
            lineHeight={"normal"}
          >
            In 2024, the average monthly{" "}
            {userInfo?.Province === "QC" ? "QPP" : "CPP"} payout at the age of
            65 is $831, and the maximum is $1364. All{" "}
            {userInfo?.Province === "QC" ? "QPP" : "CPP"} payouts are pre-tax
            values
            <br />
            <br />
            You can update your plan at any time once you know your accurate{" "}
            {userInfo?.Province === "QC" ? "QPP" : "CPP"} payout.
            {/* In 2024
      the average Gross monthly{" "}
      {userInfo?.Province === "QC" ? "QPP" : "CPP"} payout
      was $831 and the maximum was $1364. The{" "}
      {userInfo?.Province === "QC" ? "QPP" : "CPP"} payouts
      are all pre-tax values */}
          </Text>
        </Box>
      )}
      {cppStatementValue === "No" && (
        <>
          <Box
            display={"flex"}
            width={"100%"}
            padding={"20px 10px"}
            flexDirection={"column"}
            alignItems={"flex-start"}
            gap={"10px"}
            borderRadius={"10px"}
            border={"1px solid #00A2D4"}
            bg={"rgba(0, 162, 212, 0.1)"}
          >
            <Text
              alignSelf={"stretch"}
              color={"#00A2D4"}
              textAlign={"center"}
              fontFamily={"Nunito"}
              fontSize={"14px"}
              fontStyle={"normal"}
              fontWeight={"700"}
              lineHeight={"normal"}
            >
              In 2024 the average Gross monthly{" "}
              {userInfo?.Province === "QC" ? "QPP" : "CPP"} payout was $831 and
              the maximum was $1364. The{" "}
              {userInfo?.Province === "QC" ? "QPP" : "CPP"} payouts are all
              pre-tax values
            </Text>
          </Box>
          <Flex justify="space-between" w="full" alignItems="flex-end">
            <NormalInputButton
              Heading={`Input monthly ${
                userInfo?.Province === "QC" ? "QPP" : "CPP"
              } payout up to a maximum of $1,364`}
              placeholder={"$"}
              value={customCppSalary}
              onChange={(e) => {
                const newValue = moneyToNumber(e.target.value);
                setCustomCppSalary(newValue);
                // if (newValue <= 1364) {
                //   setCustomCppSalary(newValue);
                //   errors.customCppSalary = "";
                // } else if (newValue > 1364) {
                //   errors.customCppSalary =
                //     "The maximum monthly CPP payout is $1,364";
                //   setCustomCppSalary(1364);
                // }
                setChangedData(true);
              }}
              customWidth={"100%"}
              useThousandSeparator={true}
              error={errors.customCppSalary}
              tooltipContent={TooltipContent.enterMonthlyCPPPayout}
            />
          </Flex>
        </>
      )}
    </Flex>
  );
}

function NoCppMobile({
  userInfo,
  cppStatementValue,
  setCppStatementValue,
  customCppSalary,
  setCustomCppSalary,
  moneyToNumber,
  setChangedData,
  errors,
}) {
  return (
    <Flex w="100%" flexDirection={"column"} gap={"10px"}>
      <Flex justify="space-between" w="full" alignItems="flex-end">
        <DropDownButton
          Heading={
            <span>
              How would you like to include your{" "}
              <a
                href="https://www.canada.ca/en/employment-social-development/services/my-account.html"
                target="_blank"
                rel="noopener noreferrer" // for security reasons
              >
                {userInfo?.Province === "QC"
                  ? "Quebec Pension Plan (QPP)"
                  : "Canada Pension Plan (CPP)"}
              </a>{" "}
              in your analysis?
            </span>
          }
          placeholder={"Select"}
          dropDownItems={[
            { key: "Yes", value: "Use the monthly average" },
            { key: "No", value: "Input my own value" },
          ]}
          customWidth={"100%"}
          value={cppStatementValue}
          onChange={(key) => {
            setCppStatementValue(key);
            setChangedData(true);
          }}
          error={errors.cppStatementValue}
          tooltipContent={TooltipContent.useCPPAverageYesNo}
          MenuCustomWidth={"100%"}
        />
      </Flex>
      {cppStatementValue === "Yes" && (
        <Box
          display={"flex"}
          width={"100%"}
          padding={"10px"}
          flexDirection={"column"}
          alignItems={"flex-start"}
          gap={"10px"}
          borderRadius={"10px"}
          border={"1px solid #00A2D4"}
          bg={"rgba(0, 162, 212, 0.1)"}
        >
          <Text
            alignSelf={"stretch"}
            color={"#00A2D4"}
            textAlign={"center"}
            fontFamily={"Nunito"}
            fontSize={"14px"}
            fontStyle={"normal"}
            fontWeight={"700"}
            lineHeight={"normal"}
          >
            In 2024, the average monthly{" "}
            {userInfo?.Province === "QC" ? "QPP" : "CPP"} payout at the age of
            65 is $831, and the maximum is $1364. All{" "}
            {userInfo?.Province === "QC" ? "QPP" : "CPP"} payouts are pre-tax
            values
            <br />
            <br />
            You can update your plan at any time once you know your accurate{" "}
            {userInfo?.Province === "QC" ? "QPP" : "CPP"} payout.
            {/* In 2024
      the average Gross monthly{" "}
      {userInfo?.Province === "QC" ? "QPP" : "CPP"} payout
      was $831 and the maximum was $1364. The{" "}
      {userInfo?.Province === "QC" ? "QPP" : "CPP"} payouts
      are all pre-tax values */}
          </Text>
        </Box>
      )}
      {cppStatementValue === "No" && (
        <>
          <Box
            display={"flex"}
            width={"100%"}
            padding={"20px 10px"}
            flexDirection={"column"}
            alignItems={"flex-start"}
            gap={"10px"}
            borderRadius={"10px"}
            border={"1px solid #00A2D4"}
            bg={"rgba(0, 162, 212, 0.1)"}
          >
            <Text
              alignSelf={"stretch"}
              color={"#00A2D4"}
              textAlign={"center"}
              fontFamily={"Nunito"}
              fontSize={"14px"}
              fontStyle={"normal"}
              fontWeight={"700"}
              lineHeight={"normal"}
            >
              In 2024 the average Gross monthly{" "}
              {userInfo?.Province === "QC" ? "QPP" : "CPP"} payout was $831 and
              the maximum was $1364. The{" "}
              {userInfo?.Province === "QC" ? "QPP" : "CPP"} payouts are all
              pre-tax values
            </Text>
          </Box>
          <Flex justify="space-between" w="full" alignItems="flex-end">
            <NormalInputButton
              Heading={`Input monthly ${
                userInfo?.Province === "QC" ? "QPP" : "CPP"
              } payout up to a maximum of $1,364`}
              placeholder={"$"}
              value={customCppSalary}
              onChange={(e) => {
                const newValue = moneyToNumber(e.target.value);
                setCustomCppSalary(newValue);
                // if (newValue <= 1364) {
                //   setCustomCppSalary(newValue);
                //   errors.customCppSalary = "";
                // } else if (newValue > 1364) {
                //   errors.customCppSalary =
                //     "The maximum monthly CPP payout is $1,364";
                //   setCustomCppSalary(1364);
                // }
                setChangedData(true);
              }}
              customWidth={"100%"}
              useThousandSeparator={true}
              InputWidth={"100%"}
              error={errors.customCppSalary}
              tooltipContent={TooltipContent.enterMonthlyCPPPayout}
            />
          </Flex>
        </>
      )}
    </Flex>
  );
}

export default CppPage;
