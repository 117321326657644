import { useAuth0 } from "@auth0/auth0-react";
import React, { useContext, useEffect, useMemo, useState } from "react";
import { useStore } from "../../store";
import ActionPlanPage from "../ActionPlanPage";
import DashboardHomePage from "../DashboardHomePage";
import Investments from "../Investments";
import Route from "../Route";
// import InvestmentsRRIF from '../InvestmentsRRIF';
import { Box, Flex, useMediaQuery } from "@chakra-ui/react";
import { useLocation } from "react-router-dom";
import Assumptions from "../Assumptions";
import ChangePlanPage from "../ChangePlanPage";
import DebtPage from "../DebtPage";
import Expenses from "../Expenses";
import GlobalContainer from "../GlobalContainer";
import GlobalContainerAfterOptimized from "../GlobalContainerAfterOptimized";
import GlobalContainerAfterOptimizedMobile from "../GlobalContainerAfterOptimizedMobile";
import GlobalContainerMobile from "../GlobalContainerMobile";
import Header from "../Header/header";
import HeaderMobile from "../Header/headerMobile";
import HelpPage from "../Help/HelpPage";
import Income from "../Income";
import CppPage from "../IncomePage/CppPage";
import OasPage from "../IncomePage/OasPage";
import Pension from "../IncomePage/Pension";
import OtherIncomePage from "../IncomePage/otherIncomePage";
import Salary from "../IncomePage/salary";
import InputSummary from "../InputSummary";
import InsightsPage from "../InsightsPage/Insights";
// import InsightsOverview, {
//   InsightsYear,
// } from "../InsightsPage/InsightsOverview";
import InsightsOverview from "../InsightsPage/InsightsOverview";
import InvestmentAccountsPage from "../InvestmentAccountsPage";
import Assets from "../Investments/Assets/Assets";
import BusinessPage from "../Investments/Assets/Business";
import BusinessForm from "../Investments/Assets/BusinessForm";
import IncomePropertyPage from "../Investments/Assets/IncomeProperty";
import InheritancePage from "../Investments/Assets/Inheritance";
import InvestmentFundsForm from "../Investments/Assets/InvestmentFundForm";
import LandPage from "../Investments/Assets/Land";
import LifeInsurancePage from "../Investments/Assets/LifeInsurance";
import PersonalAssets from "../Investments/Assets/PersonalAssets";
import PrimaryResidencePage from "../Investments/Assets/PrimaryResidence";
import RealEstatePage from "../Investments/Assets/RealEstate";
import RecreationalPropertyPage from "../Investments/Assets/RecreationalProperty";
import CashSavingsNonRegistered from "../Investments/CashSavingsNonRegistered";
import ExpenseDebtPage from "../Investments/Expenses/Debt";
import Goals from "../Investments/Expenses/Goals";
import GoalsForm from "../Investments/Expenses/GoalsForm";
import HouseHoldExpensesForm from "../Investments/Expenses/HouseHoldExpenses";
import Fhsa from "../Investments/Fhsa";
import FixedIncomePage from "../Investments/FixedIncome";
import LiraAccount from "../Investments/Lira";
import NonRegistered from "../Investments/NonRegistered";
import RrspAccount from "../Investments/Rrsp";
import TfsaAccount from "../Investments/Tfsa";
import LifeStrategy from "../LifeStrategy";
import Modal from "../Modal";
import PasswordAndSecurityPage from "../PasswordAndSecurityPage";
import Notifications from "../PasswordAndSecurityPage/Notifications";
import CreateProfileForm from "../ProfilePage/profilepage";
import Sidebar from "../Sidebar";
import { SpouseContext } from "../SpouseContext";
import { StripeContext } from "../StripeContext";
import TutorialVideoPage from "../TutorialVideoPage";
import AnalysisArchive from "../analysis-archive";
import Taxes from "../taxes";
import HeadingWithMessage from "./HeadingWithMessage";
import ManualConfiguration from "../InsightsPage/ManualConfiguration";

function MainScreen(props) {
  const { newUser } = props;
  const { user } = useAuth0();

  const location = useLocation();
  const [seenPaths, setSeenPaths] = useState(new Set());
  const [lockedIn, setLockedIn] = useState(false);

  const [spouseStatus, setSpouseStatus] = useState(false);
  const setUserInfo = useStore((store) => store.setUserInfo);
  const value = useMemo(
    () => ({ spouseStatus, setSpouseStatus }),
    [spouseStatus, setSpouseStatus]
  );
  const userInfo = useStore((store) => store.userInfo);
  const setShowNewOptimization = useStore(
    (store) => store.setShowNewOptimization
  );
  const userOptiData = useStore((store) => store.userOptiData);
  const { stripePlan, setStripePlan } = useContext(StripeContext);
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    if (userInfo !== null) {
      if (userInfo?.MaritalStatus === "married") {
        setSpouseStatus(true);
      }
    }
  }, [userInfo]);

  const [lastVisitedPath, setLastVisitedPath] = useState(
    window.location.pathname
  );

  useEffect(() => {
    if (userOptiData?.primaryUserData?.expenses?.length > 1) {
      setShowNewOptimization(false);
      window.history.pushState({}, "", "/dashboard-page");
      const navEvent = new PopStateEvent("popstate");
      window.dispatchEvent(navEvent);
    }
    if (userOptiData?.primaryUserData?.expenses?.length <= 1) {
      const lastVisitedPage = lastVisitedPath ? lastVisitedPath : "/";
      window.history.pushState({}, "", lastVisitedPage);
      const navEvent = new PopStateEvent("popstate");
      window.dispatchEvent(navEvent);
      setShowNewOptimization(true);
      setLockedIn(true);
    }
  }, [userOptiData]);

  useEffect(() => {
    // Configure AWS SDK with environment variables
    function loadNewPicture() {
      AWS.config.update({
        accessKeyId: process.env.REACT_APP_PICTURE_ID,
        secretAccessKey: process.env.REACT_APP_PROFILE_SECRET,
        region: "us-east-2",
      });
      const s3 = new AWS.S3();
      const params = {
        Bucket: "optiml-user-pictures",
        Key: "profile-pics/" + user?.sub, // Unique key per user
        Expires: 3600, // Valid for 1 hour
      };

      s3.getSignedUrl("getObject", params, (err, url) => {
        if (err) {
          console.error("Error generating pre-signed URL:", err);
        } else {
          setUserInfo({
            ...userInfo,
            Picture: url,
          });
        }
      });
    }
    function loadNewPictureSpouse() {
      AWS.config.update({
        accessKeyId: process.env.REACT_APP_PICTURE_ID,
        secretAccessKey: process.env.REACT_APP_PROFILE_SECRET,
        region: "us-east-2",
      });
      const s3 = new AWS.S3();
      const spouseParams = {
        Bucket: "optiml-user-pictures",
        Key: "profile-pics/" + user?.sub + "spouse", // Unique key per user
        Expires: 3600, // Valid for 1 hour
      };

      s3.getSignedUrl("getObject", spouseParams, (err, url) => {
        if (err) {
          console.error("Error generating pre-signed URL:", err);
        } else {
          // setUserInfo({
          //   ...userInfo,
          //   spousePicture: url,
          // });
        }
      });
    }
    if (!userInfo?.Picture || !userInfo?.spousePicture) {
      loadNewPicture();
      loadNewPictureSpouse();
    }
  }, []);

  useEffect(() => {
    if (userOptiData?.primaryUserData?.expenses?.length > 1) {
      setLockedIn(false);
    } else if (userOptiData?.primaryUserData?.expenses?.length === undefined) {
      setLockedIn(true);
      setSeenPaths(new Set());
    }
  }, [userOptiData]);

  const ProfileItems = [
    { name: "Profile", path: "/" },
    stripePlan && {
      name: "Investment Accounts",
      path: "/investments-account",
    },
    !lockedIn && {
      name: "Passwords & Security",
      path: "/password-and-security",
    },
    // !lockedIn && {
    //   name: "Assumptions",
    //   path: "/assumptions",
    // },
    // !lockedIn && {
    //   name: "Notifications",
    //   path: "/notifications",
    // },
    !lockedIn &&
      stripePlan && {
        name: "Manage Subscription",
        path: "/changeplan",
      },
  ];

  const investmentItems = [
    {
      name: "Registered",
      path: "/investment-tfsa-page",
      subItems: [
        { name: "TFSA", path: "/investment-tfsa-page" },
        { name: "FHSA", path: "/investment-fhsa-page" },
        {
          name: "RRSP/RRIF",
          path: "/rrsp-page",
        },
        { name: "LIRA", path: "/other-reg-page" },
      ],
    },
    {
      name: "Non-Registered",
      path: "/non-reg-page",
      subItems: [
        { name: "Investments", path: "/non-reg-page" },
        { name: "Fixed Income", path: "/fixed-income-page" },
        { name: "Cash Savings", path: "/cash-savings" },
      ],
    },
    {
      name: "Assets",
      path: "/other-assets-page",
      subItems: [
        { name: "Real Estate", path: "/real-estate" },
        { name: "Insurance", path: "/life-insurance-form" },
        { name: "Business", path: "/business-page" },
        { name: "Personal Assets", path: "/personal-assets-form" },
        { name: "Inheritance", path: "/inheritance-form" },
      ],
    },
  ];

  const incomeItems = [
    { name: "Income", path: "/revenue" },
    { name: "Other Income", path: "/income-other" },
    {
      name: "CPP",
      path: "/income-cpp",
    },
    { name: "OAS", path: "/income-oas" },
    { name: "Pension", path: "/income-employer-pension" },
  ];

  const expensesItems = [
    { name: "Debt", path: "/expenses-debt" },
    {
      name: "Total Expenses",
      path: "/expenses-household",
    },
    {
      name: "Goals",
      path: "/Goals",
    },
  ];

  const additionalPaths = [
    "/real-estate",
    "/life-insurance-form",
    "/business-page",
    "/personal-assets-form",
    "/inheritance-form",
    // add more paths as needed
  ];

  useEffect(() => {
    setSeenPaths((prev) => {
      const newPaths = new Set(prev);
      newPaths.add(location.pathname);
      if (location.pathname === "/other-assets-page") {
        additionalPaths.forEach((path) => newPaths.add(path));
      }
      return newPaths;
    });
  }, [location.pathname]);

  const updateItemsWithLockStatus = (items) => {
    return items.map((item) => ({
      ...item,
      locked: updateItemsWithLockStatus && !seenPaths.has(item.path),
      subItems: item.subItems?.map((subItem) => ({
        ...subItem,
        locked: updateItemsWithLockStatus && !seenPaths.has(subItem.path),
      })),
    }));
  };
  const updatedIncomeItems = updateItemsWithLockStatus(incomeItems);
  const updatedInvestmentItems = updateItemsWithLockStatus(investmentItems);
  const updatedExpensesItems = updateItemsWithLockStatus(expensesItems);
  const updatedProfileItems = updateItemsWithLockStatus(ProfileItems);

  const shouldIncludeItem = (funds) => {
    if (Array.isArray(funds)) {
      return funds.some((fund) => fund > 0);
    }
    return funds > 0;
  };

  const InvestmentsOptimized = [
    {
      name: "Overview",
      filter: "Overview",
    },
    {
      name: "Registered",
      filter: "registered",
      path: "/investment-tfsa-page",
      subItems: [
        { name: "TFSA", filter: "TFSA", path: "/investment-tfsa-page" },
        { name: "FHSA", filter: "FHSA", path: "/investment-fhsa-page" },
        { name: "RRSP/RRIF", filter: "RRSP/RRIF", path: "/rrsp-page" },
        { name: "LIRA", filter: "LIRA", path: "/other-reg-page" },
        {
          name: "Pension",
          filter: "Pension",
          path: "/income-employer-pension",
        },
      ],
    },
    {
      name: "Non-Registered",
      filter: "Non-Registered",
      path: "/non-reg-page",
      subItems: [
        {
          name: "Fixed Income",
          filter: "Fixed Income",
          path: "/fixed-income-page",
        },
        { name: "Cash", filter: "Cash", path: "/cash-savings" },
      ],
    },
    {
      name: "Assets",
      filter: "assets",
      path: "/other-assets-page",
      subItems: [
        { name: "Real Estate", filter: "Real Estate", path: "/real-estate" },
        {
          name: "Life Insurance",
          filter: "Life Insurance",
          path: "/life-insurance-form",
        },
        { name: "Business", filter: "Business", path: "/business-page" },
        {
          name: "Personal Assets",
          filter: "Personal Assets",
          path: "/personal-assets-form",
        },
      ],
    },
  ];

  const IncomeOptimized = [
    {
      name: "Overview",
      filter: "Overview",
    },
    {
      name: "Salary",
      filter: "Salary",
      path: "/revenue",
    },
    {
      name: "CPP",
      filter: "CPP",
      path: "/income-cpp",
    },
    {
      name: "OAS",
      filter: "OAS",
      path: "/income-oas",
    },
    {
      name: "Pension",
      filter: "Pension",
      path: "/income-employer-pension",
    },
    {
      name: "Rental Income",
      filter: "Rental",
      path: "/income-property-form",
    },
    {
      name: "Asset Sale",
      filter: "Asset Sale",
      path: "other-assets-page",
    },
    {
      name: "Business Dividends",
      filter: "Business Dividends",
      path: "/business-page",
    },
    {
      name: "Other Income",
      filter: "Other",
      path: "/income-other",
    },
    {
      name: "Inheritance",
      filter: "Inheritance",
      path: "/inheritance-form",
    },
  ];

  const DebtOptimized = [
    {
      name: "Overview",
      filter: "Overview",
    },
    {
      name: "Mortgages",
      filter: "Mortgages",
      path: "/DebtPage",
      subItems: [
        {
          name: "Primary Residence",
          filter: "Primary Residence",
          path: "/primary-residence-form",
        },
        {
          name: "Recreation Property",
          filter: "Recreation Property",
          path: "/recreational-property-form",
        },
        { name: "Land", filter: "Land", path: "/land-property-form" },
        {
          name: "Income Property",
          filter: "Income Property",
          path: "/income-property-form",
        },
      ],
    },
    {
      name: "Loans",
      filter: "Loans",
      path: "/expenses-debt",
    },
  ];

  const expensesItemsOptimized = [
    {
      name: "Overview",
      filter: "Overview",
    },
  ];

  const TaxesOptimized = [
    {
      name: "Overview",
      filter: "Overview",
    },
  ];

  const ArchivesOptimized = [
    {
      name: "Overview",
      filter: "Overview",
    },
  ];


  const InsightsOptimized = [
    {
      subItems: [
        {
          name: "Overview",
          filter: "Overview",
          path: "/insights-overview",
        },
        ...(userOptiData?.primaryUserData?.optimizationType === "Max Value"
          ? [
              {
                name: "Optiml vs OSFA",
                filter: "Optiml vs OSFA",
                path: "/insights-page",
              },
            ]
          : []),

        {
          name: "Compare your analysis",
          filter: "Compare your analysis",
          path: "/insights-page",
        },
        {
          name: "Manual configuration",
          filter: "Manual configuration",
          path: "/manual-configuration",
        },
        {
          name: "Monte Carlo Analysis",
          filter: "Monte Carlo Analysis",
          path: "/insights-page",
        },
      ],
    },
  ];

  const allItems = [
    ...InvestmentsOptimized,
    ...DebtOptimized,
    ...IncomeOptimized,
    ...expensesItemsOptimized,
    ...InsightsOptimized,
  ];

  const [currentType, setCurrentType] = useState("");

  const findLinkByPath = (currentType) => {
    if (currentType)
      for (const item of allItems) {
        if (
          item.filter &&
          item.filter.toLowerCase() === currentType.toLowerCase()
        ) {
          return item.path;
        } else if (item.subItems) {
          for (const subItem of item.subItems) {
            if (
              subItem.filter &&
              subItem.filter.toLowerCase() === currentType.toLowerCase()
            ) {
              return subItem.path;
            }
          }
        }
      }
  };

  const combineValuesAtEachIndex = (...arrays) => {
    const maxLength = Math.max(...arrays.map((arr) => arr?.length || 0));
    return Array.from({ length: maxLength }, (_, index) =>
      arrays.reduce((sum, arr) => sum + (arr?.[index] || 0), 0)
    );
  };

  const conditions = {
    FHSA: spouseStatus
      ? combineValuesAtEachIndex(
          userOptiData?.primaryUserData?.sps1_fhsaFunds,
          userOptiData?.spouseData?.sps2_fhsaFunds
        )
      : userOptiData?.primaryUserData?.sps1_fhsaFunds,
    LIRA: spouseStatus
      ? combineValuesAtEachIndex(
          userOptiData?.primaryUserData?.sps1_liraFunds,
          userOptiData?.spouseData?.sps2_liraFunds
        )
      : userOptiData?.primaryUserData?.sps1_liraFunds,
    Pension: spouseStatus
      ? combineValuesAtEachIndex(
          userOptiData?.primaryUserData?.sps1_pension?.EmployerContributions,
          userOptiData?.spouseData?.sps2_pension?.EmployerContributions
        )
      : userOptiData?.primaryUserData?.sps1_pension?.EmployerContributions,
    "Fixed Income": spouseStatus
      ? combineValuesAtEachIndex(
          userOptiData?.primaryUserData?.sps1_fixedIncomeTotal,
          userOptiData?.spouseData?.sps2_fixedIncomeTotal
        )
      : userOptiData?.primaryUserData?.sps1_fixedIncomeTotal,
    Cash: spouseStatus
      ? combineValuesAtEachIndex(
          userOptiData?.primaryUserData?.sps1_cashFunds,
          userOptiData?.spouseData?.sps2_cashFunds
        )
      : userOptiData?.primaryUserData?.sps1_cashFunds,
    "Real Estate": combineValuesAtEachIndex(
      userOptiData?.primaryUserData?.PrimaryResidence?.Value,
      userOptiData?.primaryUserData?.IncomePropertyYearlyValue,
      userOptiData?.primaryUserData?.RecreationPropertyYearlyValue,
      userOptiData?.primaryUserData?.LandYearlyValue
    ),
    "Life Insurance": userOptiData?.primaryUserData?.lifeInsuranceYearlyValue,
    Business: combineValuesAtEachIndex(
      userOptiData?.primaryUserData?.businessYearlyValue,
      userOptiData?.primaryUserData?.ccpcYearlyBalance
    ),
    "Personal Assets": userOptiData?.primaryUserData?.otherAssetsYearlyValue,
    assets: combineValuesAtEachIndex(
      userOptiData?.primaryUserData?.PrimaryResidence?.Value,
      userOptiData?.primaryUserData?.IncomePropertyYearlyValue,
      userOptiData?.primaryUserData?.RecreationPropertyYearlyValue,
      userOptiData?.primaryUserData?.LandYearlyValue,
      userOptiData?.primaryUserData?.lifeInsuranceYearlyValue,
      userOptiData?.primaryUserData?.businessYearlyValue,
      userOptiData?.primaryUserData?.ccpcYearlyBalance,
      userOptiData?.primaryUserData?.otherAssetsYearlyValue
    ),
  };

  const incomeConditions = {
    Salary: spouseStatus
      ? combineValuesAtEachIndex(
          userOptiData?.primaryUserData?.sps1_salary,
          userOptiData?.spouseData?.sps2_salary
        )
      : userOptiData?.primaryUserData?.sps1_salary,
    CPP: spouseStatus
      ? combineValuesAtEachIndex(
          userOptiData?.primaryUserData?.sps1_cpp,
          userOptiData?.spouseData?.sps2_cpp
        )
      : userOptiData?.primaryUserData?.sps1_cpp,
    OAS: spouseStatus
      ? combineValuesAtEachIndex(
          userOptiData?.primaryUserData?.sps1_oas,
          userOptiData?.spouseData?.sps2_oas
        )
      : userOptiData?.primaryUserData?.sps1_oas,
    Pension: spouseStatus
      ? combineValuesAtEachIndex(
          userOptiData?.primaryUserData?.sps1_pension?.Benefits,
          userOptiData?.spouseData?.sps2_pension?.Benefits
        )
      : userOptiData?.primaryUserData?.sps1_pension?.Benefits,
    Rental: userOptiData?.primaryUserData?.IncomePropertyYearlyIncome,
    "Asset Sale": combineValuesAtEachIndex(
      userOptiData?.primaryUserData?.IncomePropertyYearlySale,
      userOptiData?.primaryUserData?.RecreationPropertyYearlySale,
      userOptiData?.primaryUserData?.LandYearlySale,
      userOptiData?.primaryUserData?.PrimaryResidence?.Sale
    ),
    "Business Dividends":
      userOptiData?.primaryUserData?.businessYearlyDividends,
    Other: spouseStatus
      ? combineValuesAtEachIndex(
          userOptiData?.primaryUserData?.sps1_otherIncomeTotal,
          userOptiData?.spouseData?.sps2_otherIncomeTotal
        )
      : userOptiData?.primaryUserData?.sps1_otherIncomeTotal,
    Inheritance: userOptiData?.primaryUserData?.inheritanceYearlyIncome,
  };

  const debtConditions = {
    "Primary Residence":
      userOptiData?.primaryUserData?.PrimaryResidence?.BalanceProrated,
    "Recreation Property":
      userOptiData?.primaryUserData?.RecreationPropertyYearlyBalanceProrated,
    Land: userOptiData?.primaryUserData?.LandYearlyBalanceProrated,
    "Income Property":
      userOptiData?.primaryUserData?.IncomePropertyYearlyBalanceProrated,
    Loans: userOptiData?.primaryUserData?.totalDebtProrated,
    Mortgages: combineValuesAtEachIndex(
      userOptiData?.primaryUserData?.PrimaryResidence?.BalanceProrated,
      userOptiData?.primaryUserData?.RecreationPropertyYearlyBalanceProrated,
      userOptiData?.primaryUserData?.LandYearlyBalanceProrated,
      userOptiData?.primaryUserData?.IncomePropertyYearlyBalanceProrated
    ),
  };

  const filterSubItems = (subItems, conditions) => {
    return subItems.filter((subItem) => {
      const funds = conditions[subItem.filter];
      return funds ? shouldIncludeItem(funds) : true;
    });
  };

  // Function to filter items based on the provided conditions
  const filterItems = (items, conditions) => {
    return items
      .map((item) => {
        if (item.subItems) {
          const filteredSubItems = filterSubItems(item.subItems, conditions);
          // Include the main item only if there are filtered sub-items
          if (filteredSubItems?.length > 0) {
            return { ...item, subItems: filteredSubItems };
          } else {
            return null;
          }
        } else {
          const condition = conditions[item.filter];
          return condition !== undefined
            ? shouldIncludeItem(condition)
              ? item
              : null
            : item;
        }
      })
      .filter((item) => item !== null); // Remove any null items from the array
  };

  // Filter the InvestmentsOptimized list
  const filteredInvestmentsOptimized = InvestmentsOptimized.map((item) => {
    if (item.subItems) {
      const filteredSubItems = filterSubItems(item.subItems, conditions);
      // Include the main item only if there are filtered sub-items
      if (item.filter === "assets") {
        return filteredSubItems?.length > 0
          ? { ...item, subItems: filteredSubItems }
          : null;
      }
      return { ...item, subItems: filteredSubItems };
    }
    return item;
  }).filter((item) => item !== null); // Remove any null items from the array

  // Filtered Income List
  const filteredIncomeOptimized = filterItems(
    IncomeOptimized,
    incomeConditions
  );

  // Filter the DebtOptimized list
  // const filteredDebtOptimized = DebtOptimized.map((item) => {
  //   if (item.subItems) {
  //     const filteredSubItems = filterSubItems(item.subItems, debtConditions);
  //     if (item.filter === "Mortgages") {
  //       return filteredSubItems?.length > 0
  //         ? { ...item, subItems: filteredSubItems }
  //         : null;
  //     }
  //     return { ...item, subItems: filteredSubItems };
  //   }else{
  //     filterItems(item, debtConditions)
  //   }
  //   return item;
  // }).filter((item) => item !== null);

  const filteredDebtOptimized = filterItems(DebtOptimized, debtConditions);

  const [InputSummaryheading, setInputSummaryHeading] =
    useState("Input Summary");

  const [removeBackButton, setRemoveBackButton] = useState(false);

  const updateBackButtonStatus = (value) => {
    setRemoveBackButton(value);
  };

  const [isLargerThan768] = useMediaQuery("(min-width: 1200px)");
  const isMobile = !isLargerThan768;

  return (
    <Box display="flex" flexDirection="row" w={"100%"}>
      {isMobile ? (
        <Box
          display={"flex"}
          flexDirection={"column"}
          width={"100%"}
          alignItems={"center"}
        >
          <Box w={"100%"} flexDirection={"column"}>
            <HeaderMobile />
            <SpouseContext.Provider value={value}>
              <Flex w={"100%"} alignContent={"center"}>
                <Route path="/">
                  <GlobalContainerMobile
                    Heading="Settings"
                    item={updatedProfileItems}
                    currentPage={"Profile"}
                    mainframeContent={<CreateProfileForm />}
                    link="/investments-account"
                  />
                </Route>
                <Route path="/investment-tfsa-page">
                  <GlobalContainerMobile
                    Heading="Investments"
                    item={updatedInvestmentItems}
                    currentPage={"TFSA"}
                    mainframeContent={<TfsaAccount />}
                    link="/investment-fhsa-page"
                    backLink="/income-employer-pension"
                  />
                </Route>
                <Route path="/investment-fhsa-page">
                  <GlobalContainerMobile
                    Heading="Investments"
                    item={updatedInvestmentItems}
                    currentPage={"FHSA"}
                    mainframeContent={<Fhsa />}
                    link="/rrsp-page"
                    backLink="/investment-tfsa-page"
                  />
                </Route>
                <Route path="/revenue">
                  <GlobalContainerMobile
                    Heading="Income"
                    item={updatedIncomeItems}
                    currentPage={"Income"}
                    mainframeContent={<Salary />}
                    link="/income-other"
                    backLink="/investments-account"
                  />
                </Route>
                <Route path="/income-cpp">
                  <GlobalContainerMobile
                    Heading="Income"
                    item={updatedIncomeItems}
                    currentPage={"CPP"}
                    mainframeContent={<CppPage />}
                    link="/income-oas"
                    backLink="/income-other"
                  />
                </Route>
                <Route path="/income-oas">
                  <GlobalContainerMobile
                    Heading="Income"
                    item={updatedIncomeItems}
                    currentPage={"OAS"}
                    mainframeContent={<OasPage />}
                    link="/income-employer-pension"
                    backLink="/income-cpp"
                  />
                </Route>
                <Route path="/income-employer-pension">
                  <GlobalContainerMobile
                    Heading="Income"
                    item={updatedIncomeItems}
                    currentPage={"Pension"}
                    mainframeContent={<Pension />}
                    link="/investment-tfsa-page"
                    backLink="/income-oas"
                  />
                </Route>
                <Route path="/income-other">
                  <GlobalContainerMobile
                    Heading="Income"
                    item={updatedIncomeItems}
                    currentPage={"Other Income"}
                    mainframeContent={<OtherIncomePage />}
                    link="/income-cpp"
                    backLink="/revenue"
                  />
                </Route>
                <Route path="/expenses-debt">
                  <GlobalContainerMobile
                    Heading="Expenses"
                    item={updatedExpensesItems}
                    currentPage={"Debt"}
                    mainframeContent={<ExpenseDebtPage />}
                    link="/expenses-household"
                    backLink="/other-assets-page"
                  />
                </Route>
                <Route path="/expenses-household">
                  <GlobalContainerMobile
                    Heading="Expenses"
                    item={updatedExpensesItems}
                    currentPage={"Total Expenses"}
                    mainframeContent={<HouseHoldExpensesForm />}
                    link="/Goals"
                    backLink="/expenses-debt"
                  />
                </Route>
                <Route path="/Goals">
                  <GlobalContainerMobile
                    Heading="Expenses"
                    item={updatedExpensesItems}
                    currentPage={"Goals"}
                    mainframeContent={<Goals />}
                    link="/lifestrategy"
                    backLink="/expenses-household"
                  />
                </Route>
                <Route path="/Goals-form">
                  <GlobalContainerMobile
                    Heading="Expenses"
                    item={updatedExpensesItems}
                    currentPage={"Goals"}
                    mainframeContent={<GoalsForm />}
                    link="/lifestrategy"
                    backLink="/Goals"
                  />
                </Route>
                <Route path="/non-reg-page">
                  <GlobalContainerMobile
                    Heading="Investments"
                    item={updatedInvestmentItems}
                    currentPage={"Investments"}
                    mainframeContent={<NonRegistered />}
                    link="/fixed-income-page"
                    backLink="/other-reg-page"
                  />
                </Route>
                <Route path="/fixed-income-page">
                  <GlobalContainerMobile
                    Heading="Investments"
                    item={updatedInvestmentItems}
                    currentPage={"Fixed Income"}
                    mainframeContent={<FixedIncomePage />}
                    link="/cash-savings"
                    backLink="/non-reg-page"
                  />
                </Route>
                <Route path="/cash-savings">
                  <GlobalContainerMobile
                    Heading="Investments"
                    item={updatedInvestmentItems}
                    currentPage={"Cash Savings"}
                    mainframeContent={<CashSavingsNonRegistered />}
                    link="/other-assets-page"
                    backLink="/fixed-income-page"
                  />
                </Route>
                <Route path="/rrsp-page">
                  <GlobalContainerMobile
                    Heading="Investments"
                    item={updatedInvestmentItems}
                    currentPage={"RRSP/RRIF"}
                    mainframeContent={<RrspAccount />}
                    link="/other-reg-page"
                    backLink={"/investment-fhsa-page"}
                  />
                </Route>
                <Route path="/other-reg-page">
                  <GlobalContainerMobile
                    Heading="Investments"
                    item={updatedInvestmentItems}
                    currentPage={"LIRA"}
                    mainframeContent={<LiraAccount />}
                    link="/non-reg-page"
                    backLink={"/rrsp-page"}
                  />
                </Route>
                <Route path="/real-estate">
                  <GlobalContainerMobile
                    Heading="Investments"
                    item={updatedInvestmentItems}
                    currentPage={"Real Estate"}
                    mainframeContent={<RealEstatePage />}
                    link="/other-assets-page"
                    backLink="/other-assets-page"
                  />
                </Route>
                <Route path="/primary-residence-form">
                  <GlobalContainerMobile
                    Heading="Investments"
                    item={updatedInvestmentItems}
                    currentPage={"Real Estate"}
                    mainframeContent={<PrimaryResidencePage />}
                    link="/real-estate"
                    backLink="/real-estate"
                  />
                </Route>
                <Route path="/recreational-property-form">
                  <GlobalContainerMobile
                    Heading="Investments"
                    item={updatedInvestmentItems}
                    currentPage={"Real Estate"}
                    mainframeContent={<RecreationalPropertyPage />}
                    link="/real-estate"
                    backLink="/real-estate"
                  />
                </Route>
                <Route path="/income-property-form">
                  <GlobalContainerMobile
                    Heading="Investments"
                    item={updatedInvestmentItems}
                    currentPage={"Real Estate"}
                    mainframeContent={<IncomePropertyPage />}
                    link="/real-estate"
                    backLink="/real-estate"
                  />
                </Route>
                <Route path="/land-property-form">
                  <GlobalContainerMobile
                    Heading="Investments"
                    item={updatedInvestmentItems}
                    currentPage={"Real Estate"}
                    mainframeContent={<LandPage />}
                    link="/real-estate"
                    backLink="/real-estate"
                  />
                </Route>
                <Route path="/life-insurance-form">
                  <GlobalContainerMobile
                    Heading="Assets"
                    item={updatedInvestmentItems}
                    currentPage={"Insurance"}
                    mainframeContent={<LifeInsurancePage />}
                    link="/other-assets-page"
                    backLink="/other-assets-page"
                  />
                </Route>
                <Route path="/investment-funds-form">
                  <GlobalContainerMobile
                    Heading="Investments"
                    MainContainerHeading={"Assets"}
                    item={updatedInvestmentItems}
                    currentPage={"Business"}
                    mainframeContent={<InvestmentFundsForm />}
                    link="/business-page"
                    backLink="/business-page"
                  />
                </Route>
                <Route path="/inheritance-form">
                  <GlobalContainerMobile
                    Heading="Investments"
                    MainContainerHeading={"Assets"}
                    item={updatedInvestmentItems}
                    currentPage={"Inheritance"}
                    mainframeContent={<InheritancePage />}
                    link="/other-assets-page"
                    backLink="/other-assets-page"
                  />
                </Route>
                <Route path="/other-assets-page">
                  <GlobalContainerMobile
                    Heading="Investments"
                    item={updatedInvestmentItems}
                    currentPage={"Assets"}
                    mainframeContent={<Assets />}
                    link="expenses-debt"
                    backLink={"/cash-savings"}
                  />
                </Route>
                <Route path="/personal-assets-form">
                  <GlobalContainerMobile
                    Heading="Investments"
                    item={Investments}
                    currentPage={"Assets"}
                    mainframeContent={<PersonalAssets />}
                    link="/revenue"
                    backLink={"/"}
                  />
                </Route>
                <Route path="/investments-account">
                  <GlobalContainerMobile
                    Heading="Settings"
                    item={updatedProfileItems}
                    currentPage={"Investment Accounts"}
                    mainframeContent={<InvestmentAccountsPage />}
                    link="/revenue"
                    backLink={"/"}
                  />
                </Route>
                <Route path="/business-page">
                  <GlobalContainerMobile
                    Heading="Assets"
                    item={updatedInvestmentItems}
                    currentPage={"Business"}
                    mainframeContent={<BusinessPage />}
                    link="/other-assets-page"
                    backLink="/other-assets-page"
                  />
                </Route>
                <Route path="/business-form">
                  <GlobalContainerMobile
                    Heading="Assets"
                    item={updatedInvestmentItems}
                    currentPage={"Business"}
                    mainframeContent={<BusinessForm />}
                    link="/business-page"
                    backLink="/business-page"
                  />
                </Route>
                <Route path="/investments">
                  <GlobalContainerAfterOptimizedMobile
                    Heading="Investments"
                    MainContainerHeading={true}
                    item={filteredInvestmentsOptimized}
                    currentPage={"Overview"}
                    mainframeContent={<Investments />}
                    setCurrentType={setCurrentType}
                    link={
                      findLinkByPath(currentType)
                        ? findLinkByPath(currentType)
                        : "/investment-tfsa-page"
                    }
                  />
                </Route>
                <Route path="/income">
                  <GlobalContainerAfterOptimizedMobile
                    Heading="Income"
                    MainContainerHeading={false}
                    item={filteredIncomeOptimized}
                    currentPage={"Overview"}
                    mainframeContent={<Income />}
                    setCurrentType={setCurrentType}
                    link={
                      findLinkByPath(currentType)
                        ? findLinkByPath(currentType)
                        : "/revenue"
                    }
                  />
                </Route>
              </Flex>
              <Route path="/assumptions">
                <GlobalContainerMobile
                  mainframeContent={<Assumptions />}
                  item={updatedProfileItems}
                  currentPage={"Assumptions"}
                />
              </Route>
              <Route path="/dashboard-page">
                <DashboardHomePage />
              </Route>
              <Route path="/action-plan-page">
                <ActionPlanPage />
              </Route>
              <Route path="/DebtPage">
                <GlobalContainerAfterOptimizedMobile
                  Heading="Debt"
                  MainContainerHeading={false}
                  item={filteredDebtOptimized}
                  currentPage={"Overview"}
                  mainframeContent={<DebtPage />}
                  setCurrentType={setCurrentType}
                  link={
                    findLinkByPath(currentType)
                      ? findLinkByPath(currentType)
                      : "/expenses-debt"
                  }
                />
              </Route>
              <Route path="/lifestrategy">
                <GlobalContainerMobile
                  mainframeContent={<LifeStrategy />}
                  item={[]}
                  backLink={"/Goals"}
                  Heading={"Optimization Strategy"}
                />
              </Route>
              <Route path="/help">
                <GlobalContainerMobile
                  mainframeContent={<HelpPage />}
                  item={[]}
                  backLink={"/Goals"}
                  Heading={"Help Desk"}
                  notShowChatBubble={true}
                />
              </Route>
              <Route path="/inputsummary">
                <GlobalContainerMobile
                  mainframeContent={
                    <InputSummary
                      setHeading={setInputSummaryHeading}
                      updateBackButtonStatus={updateBackButtonStatus}
                    />
                  }
                  item={[]}
                  backLink={"/lifestrategy"}
                  Heading={
                    InputSummaryheading === "Input Summary" ? (
                      <HeadingWithMessage
                        heading="Input Summary"
                        message={
                          "Before clicking 'Optimize', please double-check that all data is entered correctly."
                        }
                      />
                    ) : InputSummaryheading === "" ? (
                      ""
                    ) : (
                      "Generating your new analysis"
                    )
                  }
                />
              </Route>

              <Route path="/tutorial-page">
                <GlobalContainerMobile
                  mainframeContent={<TutorialVideoPage />}
                />
              </Route>
              <Route path="/expenses">
                <GlobalContainerAfterOptimizedMobile
                  Heading="Expenses"
                  MainContainerHeading={false}
                  item={expensesItemsOptimized}
                  currentPage={"Overview"}
                  mainframeContent={<Expenses />}
                  setCurrentType={setCurrentType}
                  link={
                    findLinkByPath(currentType)
                      ? findLinkByPath(currentType)
                      : "/expenses-debt"
                  }
                />
              </Route>
              <Route path="/changeplan">
                <GlobalContainerMobile
                  Heading="Settings"
                  item={updatedProfileItems}
                  currentPage={"Manage Subscription"}
                  mainframeContent={<ChangePlanPage />}
                  link="/revenue"
                  backLink={"/password-and-security"}
                  progress={14}
                />
              </Route>
              <Route path="/password-and-security">
                <GlobalContainerMobile
                  Heading="Settings"
                  item={updatedProfileItems}
                  currentPage={"Passwords & Security"}
                  mainframeContent={<PasswordAndSecurityPage />}
                  link="/changeplan"
                  backLink={"/investments-account"}
                />
              </Route>
              <Route path="/notifications">
                <GlobalContainerMobile
                  Heading="Settings"
                  item={updatedProfileItems}
                  currentPage={"Notifications"}
                  mainframeContent={<Notifications />}
                  link="/changeplan"
                  backLink={"/password-and-security"}
                />
              </Route>

              <Route path="/taxes">
                <GlobalContainerAfterOptimizedMobile
                  Heading="Taxes"
                  MainContainerHeading={true}
                  item={TaxesOptimized}
                  currentPage={"Overview"}
                  setCurrentType={setCurrentType}
                  mainframeContent={<Taxes />}
                />
              </Route>
              {/* <Route path="/insights">
                <Insights />
              </Route> */}
              <Route path="/insights-page">
                <GlobalContainerAfterOptimizedMobile
                  Heading="Insights"
                  item={InsightsOptimized}
                  currentPage={"Optiml vs OSFA"}
                  setCurrentType={setCurrentType}
                  mainframeContent={<InsightsPage />}
                />
              </Route>
              <Route path="/analysis-archive">
                <GlobalContainerAfterOptimizedMobile
                  Heading="Analysis Archive"
                  item={ArchivesOptimized}
                  currentPage={"Overview"}
                  setCurrentType={setCurrentType}
                  mainframeContent={<AnalysisArchive />}
                />
              </Route>
              {/* <Route path="/help">
                <Help />
              </Route> */}
              <Route path="/success">
                <GlobalContainerMobile
                  Heading="Settings"
                  item={updatedProfileItems}
                  currentPage={"Profile"}
                  mainframeContent={<CreateProfileForm />}
                  link="/investments-account"
                />
              </Route>
              {/* <OltreChatBubble /> */}
            </SpouseContext.Provider>
          </Box>
        </Box>
      ) : (
        <>
          <Sidebar setShowModal={() => setShowModal(true)} />
          <Modal show={showModal} onClose={() => setShowModal(false)} />
          <Flex
            flexDirection={"column"}
            marginLeft={"240px"}
            width={"calc(100% - 240px)"}
            // width="100%"
            alignItems={"center"}
          >
            <Header />
            <SpouseContext.Provider value={value}>
              <Flex w={"100%"} alignContent={"center"}>
                <Route path="/">
                  <GlobalContainer
                    Heading="Settings"
                    item={updatedProfileItems}
                    currentPage={"Profile"}
                    mainframeContent={<CreateProfileForm />}
                    link={"/investments-account"}
                    progress={5}
                  />
                </Route>
                <Route path="/investment-tfsa-page">
                  <GlobalContainer
                    Heading="Investments"
                    item={updatedInvestmentItems}
                    currentPage={"TFSA"}
                    mainframeContent={<TfsaAccount />}
                    link="/investment-fhsa-page"
                    backLink="/income-employer-pension"
                    progress={32}
                  />
                </Route>
                <Route path="/investment-fhsa-page">
                  <GlobalContainer
                    Heading="Investments"
                    item={updatedInvestmentItems}
                    currentPage={"FHSA"}
                    mainframeContent={<Fhsa />}
                    link="/rrsp-page"
                    backLink="/investment-tfsa-page"
                    progress={33}
                  />
                </Route>
                <Route path="/revenue">
                  <GlobalContainer
                    Heading="Income"
                    item={updatedIncomeItems}
                    currentPage={"Income"}
                    mainframeContent={<Salary />}
                    link="/income-other"
                    backLink="/investments-account"
                    progress={16}
                  />
                </Route>
                <Route path="/income-cpp">
                  <GlobalContainer
                    Heading="Income"
                    item={updatedIncomeItems}
                    currentPage={"CPP"}
                    mainframeContent={<CppPage />}
                    link="/income-oas"
                    backLink="/income-other"
                    progress={24}
                  />
                </Route>
                <Route path="/income-oas">
                  <GlobalContainer
                    Heading="Income"
                    item={updatedIncomeItems}
                    currentPage={"OAS"}
                    mainframeContent={<OasPage />}
                    link="/income-employer-pension"
                    backLink="/income-cpp"
                    progress={28}
                  />
                </Route>
                <Route path="/income-employer-pension">
                  <GlobalContainer
                    Heading="Income"
                    item={updatedIncomeItems}
                    currentPage={"Pension"}
                    mainframeContent={<Pension />}
                    link="/investment-tfsa-page"
                    backLink="/income-oas"
                    progress={30}
                  />
                </Route>
                <Route path="/income-other">
                  <GlobalContainer
                    Heading="Income"
                    item={updatedIncomeItems}
                    currentPage={"Other Income"}
                    mainframeContent={<OtherIncomePage />}
                    link="/income-cpp"
                    backLink="/revenue"
                    progress={20}
                  />
                </Route>
                <Route path="/expenses-debt">
                  <GlobalContainer
                    Heading="Expenses"
                    item={updatedExpensesItems}
                    currentPage={"Debt"}
                    mainframeContent={<ExpenseDebtPage />}
                    link="/expenses-household"
                    backLink="/other-assets-page"
                    progress={68}
                  />
                </Route>
                <Route path="/expenses-household">
                  <GlobalContainer
                    Heading="Expenses"
                    item={updatedExpensesItems}
                    currentPage={"Total Expenses"}
                    mainframeContent={<HouseHoldExpensesForm />}
                    link="/Goals"
                    backLink="/expenses-debt"
                    progress={74}
                  />
                </Route>
                <Route path="/Goals">
                  <GlobalContainer
                    Heading="Expenses"
                    item={updatedExpensesItems}
                    currentPage={"Goals"}
                    mainframeContent={<Goals />}
                    link="/lifestrategy"
                    backLink="/expenses-household"
                    progress={78}
                  />
                </Route>
                <Route path="/help">
                  <GlobalContainer
                    Heading="Help Desk"
                    item={[]}
                    mainframeContent={<HelpPage />}
                    link="/lifestrategy"
                    backLink="/dashboard-page"
                    notShowChatBubble={true}
                  />
                </Route>

                <Route path="/non-reg-page">
                  <GlobalContainer
                    Heading="Investments"
                    item={updatedInvestmentItems}
                    currentPage={"Investments"}
                    mainframeContent={<NonRegistered />}
                    link="/fixed-income-page"
                    backLink="/other-reg-page"
                    progress={42}
                  />
                </Route>
                <Route path="/fixed-income-page">
                  <GlobalContainer
                    Heading="Investments"
                    item={updatedInvestmentItems}
                    currentPage={"Fixed Income"}
                    mainframeContent={<FixedIncomePage />}
                    link="/cash-savings"
                    backLink="/non-reg-page"
                    progress={45}
                  />
                </Route>
                <Route path="/cash-savings">
                  <GlobalContainer
                    Heading="Investments"
                    item={updatedInvestmentItems}
                    currentPage={"Cash Savings"}
                    mainframeContent={<CashSavingsNonRegistered />}
                    link="/other-assets-page"
                    backLink="/fixed-income-page"
                    progress={47}
                  />
                </Route>
                <Route path="/rrsp-page">
                  <GlobalContainer
                    Heading="Investments"
                    item={updatedInvestmentItems}
                    currentPage={"RRSP/RRIF"}
                    mainframeContent={<RrspAccount />}
                    link="/other-reg-page"
                    backLink={"/investment-fhsa-page"}
                    progress={36}
                  />
                </Route>
                <Route path="/other-reg-page">
                  <GlobalContainer
                    Heading="Investments"
                    item={updatedInvestmentItems}
                    currentPage={"LIRA"}
                    mainframeContent={<LiraAccount />}
                    link="/non-reg-page"
                    backLink={"/rrsp-page"}
                    progress={40}
                  />
                </Route>

                <Route path="/other-assets-page">
                  <GlobalContainer
                    Heading="Investments"
                    item={updatedInvestmentItems}
                    currentPage={"Assets"}
                    mainframeContent={<Assets />}
                    link="expenses-debt"
                    backLink={"/cash-savings"}
                    progress={58}
                  />
                </Route>
                <Route path="/investments-account">
                  <GlobalContainer
                    Heading="Settings"
                    item={updatedProfileItems}
                    currentPage={"Investment Accounts"}
                    mainframeContent={<InvestmentAccountsPage />}
                    link="/revenue"
                    backLink={"/"}
                    progress={8}
                  />
                </Route>
                <Route path="/personal-assets-form">
                  <GlobalContainer
                    Heading="Investments"
                    MainContainerHeading={"Assets"}
                    item={updatedInvestmentItems}
                    currentPage={"Personal Assets"}
                    mainframeContent={<PersonalAssets />}
                    link="/other-assets-page"
                    backLink={"/other-assets-page"}
                    progress={58}
                  />
                </Route>
                <Route path="/investments">
                  <GlobalContainerAfterOptimized
                    Heading="Investments"
                    MainContainerHeading={true}
                    item={filteredInvestmentsOptimized}
                    currentPage={"Overview"}
                    mainframeContent={<Investments />}
                    setCurrentType={setCurrentType}
                    link={
                      findLinkByPath(currentType)
                        ? findLinkByPath(currentType)
                        : "/investment-tfsa-page"
                    }
                  />
                </Route>
                <Route path="/income">
                  <GlobalContainerAfterOptimized
                    Heading="Income"
                    MainContainerHeading={false}
                    item={filteredIncomeOptimized}
                    currentPage={"Overview"}
                    mainframeContent={<Income />}
                    setCurrentType={setCurrentType}
                    link={
                      findLinkByPath(currentType)
                        ? findLinkByPath(currentType)
                        : "/revenue"
                    }
                  />
                </Route>
              </Flex>
              <Route path="/assumptions">
                <GlobalContainer
                  Heading={"Settings"}
                  item={updatedProfileItems}
                  currentPage={"Assumptions"}
                  mainframeContent={<Assumptions />}
                />
              </Route>
              <Route path="/dashboard-page">
                <DashboardHomePage />
              </Route>
              <Route path="/action-plan-page">
                <ActionPlanPage />
              </Route>
              <Route path="/DebtPage">
                <GlobalContainerAfterOptimized
                  Heading="Debt"
                  MainContainerHeading={false}
                  item={filteredDebtOptimized}
                  currentPage={"Overview"}
                  mainframeContent={<DebtPage />}
                  setCurrentType={setCurrentType}
                  link={
                    findLinkByPath(currentType)
                      ? findLinkByPath(currentType)
                      : "/expenses-debt"
                  }
                />
              </Route>
              <Route path="/lifestrategy">
                <GlobalContainer
                  MainContainerHeading={"Optimization Strategy"}
                  item={[]}
                  currentPage={"Life Strategy"}
                  mainframeContent={<LifeStrategy />}
                  backLink={"/Goals"}
                  progress={90}
                />
              </Route>
              <Route path="/inputsummary">
                <GlobalContainer
                  mainframeContent={
                    <InputSummary
                      setHeading={setInputSummaryHeading}
                      updateBackButtonStatus={updateBackButtonStatus}
                    />
                  }
                  item={[]}
                  MainContainerHeading={
                    InputSummaryheading === "Input Summary" ? (
                      <HeadingWithMessage
                        heading="Input Summary"
                        message={
                          "Before clicking 'Optimize', please double-check that all data is entered correctly."
                        }
                      />
                    ) : InputSummaryheading === "" ? (
                      ""
                    ) : (
                      "Generating your new analysis"
                    )
                  }
                  backLink={!removeBackButton && "/lifestrategy"}
                  progress={99}
                />
              </Route>
              <Route path="/tutorial-page">
                <TutorialVideoPage />
              </Route>
              <Route path="/expenses">
                <GlobalContainerAfterOptimized
                  Heading="Expenses"
                  MainContainerHeading={false}
                  item={expensesItemsOptimized}
                  currentPage={"Overview"}
                  mainframeContent={<Expenses />}
                  setCurrentType={setCurrentType}
                  link={
                    findLinkByPath(currentType)
                      ? findLinkByPath(currentType)
                      : "/expenses-household"
                  }
                />
              </Route>
              <Route path="/changeplan">
                <GlobalContainer
                  Heading="Settings"
                  item={updatedProfileItems}
                  currentPage={"Manage Subscription"}
                  mainframeContent={<ChangePlanPage />}
                  link="/revenue"
                  backLink={"/password-and-security"}
                  progress={14}
                />
              </Route>
              <Route path="/password-and-security">
                <GlobalContainer
                  Heading={"Settings"}
                  item={updatedProfileItems}
                  currentPage={"Passwords & Security"}
                  mainframeContent={<PasswordAndSecurityPage />}
                  link="/notifications"
                  backLink={"/investments-account"}
                  progress={11}
                />
              </Route>
              <Route path="/notifications">
                <GlobalContainer
                  Heading="Settings"
                  item={updatedProfileItems}
                  currentPage={"Notifications"}
                  mainframeContent={<Notifications />}
                  link="/changeplan"
                  backLink={"/password-and-security"}
                  progress={14}
                />
              </Route>

              <Route path="/taxes">
                <GlobalContainerAfterOptimized
                  Heading="Taxes"
                  MainContainerHeading={true}
                  item={TaxesOptimized}
                  currentPage={"Overview"}
                  setCurrentType={setCurrentType}
                  mainframeContent={<Taxes />}
                />
              </Route>
              {/* <Route path="/insights">
                <Insights />
              </Route> */}
              <Route path="/analysis-archive">
                <GlobalContainerAfterOptimized
                  Heading="Archive"
                  MainContainerHeading={false}
                  currentPage={"Overview"}
                  item={ArchivesOptimized}
                  setCurrentType={setCurrentType}
                  mainframeContent={<AnalysisArchive />}
                />
              </Route>
              <Route path="/insights-page">
                <GlobalContainerAfterOptimized
                  Heading="Insights"
                  CustomMainContainerHeading={"Optiml Vs One Size Fits All"}
                  currentPage={"Optiml vs OSFA"}
                  item={InsightsOptimized}
                  setCurrentType={setCurrentType}
                  mainframeContent={<InsightsPage />}
                  link={findLinkByPath(currentType)}
                  navigate={true}
                />
              </Route>
              <Route path="/insights-overview">
                <GlobalContainerAfterOptimized
                  Heading="Insights"
                  CustomMainContainerHeading={"Overview"}
                  currentPage={"Overview"}
                  item={InsightsOptimized}
                  setCurrentType={setCurrentType}
                  mainframeContent={<InsightsOverview />}
                  link={findLinkByPath(currentType)}
                  navigate={true}
                  // customButton={<InsightsYear />}
                />
              </Route>
              <Route path="/manual-configuration">
                <GlobalContainerAfterOptimized
                  Heading="Insights"
                  CustomMainContainerHeading={"Manual Configuration"}
                  currentPage={"Manual configuration"}
                  item={InsightsOptimized}
                  setCurrentType={setCurrentType}
                  mainframeContent={<ManualConfiguration />}
                  link={findLinkByPath(currentType)}
                  navigate={true}
                  // customButton={<InsightsYear />}
                />
              </Route>

              {/* <Route path="/help">
                <Help />
              </Route> */}
              <Route path="/success">
                <GlobalContainer
                  Heading="Settings"
                  item={updatedProfileItems}
                  currentPage={"Settings"}
                  mainframeContent={<CreateProfileForm />}
                  link="/investments-account"
                  progress={5}
                />
              </Route>
              <Route path="/real-estate">
                <GlobalContainer
                  Heading="Investments"
                  MainContainerHeading={"Assets"}
                  item={updatedInvestmentItems}
                  currentPage={"Real Estate"}
                  mainframeContent={<RealEstatePage />}
                  progress={58}
                  link="/other-assets-page"
                  backLink="/other-assets-page"
                />
              </Route>
              <Route path="/primary-residence-form">
                <GlobalContainer
                  Heading="Investments"
                  MainContainerHeading={"Assets"}
                  item={updatedInvestmentItems}
                  currentPage={"Real Estate"}
                  mainframeContent={<PrimaryResidencePage />}
                  progress={58}
                  link="/real-estate"
                  backLink="/real-estate"
                />
              </Route>
              <Route path="/recreational-property-form">
                <GlobalContainer
                  Heading="Investments"
                  MainContainerHeading={"Assets"}
                  item={updatedInvestmentItems}
                  currentPage={"Real Estate"}
                  mainframeContent={<RecreationalPropertyPage />}
                  progress={58}
                  link="/real-estate"
                  backLink="/real-estate"
                />
              </Route>
              <Route path="/income-property-form">
                <GlobalContainer
                  Heading="Investments"
                  MainContainerHeading={"Assets"}
                  item={updatedInvestmentItems}
                  currentPage={"Real Estate"}
                  mainframeContent={<IncomePropertyPage />}
                  progress={58}
                  link="/real-estate"
                  backLink="/real-estate"
                />
              </Route>
              <Route path="/land-property-form">
                <GlobalContainer
                  Heading="Investments"
                  MainContainerHeading={"Assets"}
                  item={updatedInvestmentItems}
                  currentPage={"Real Estate"}
                  mainframeContent={<LandPage />}
                  link="/real-estate"
                  backLink="/real-estate"
                  progress={58}
                />
              </Route>
              <Route path="/life-insurance-form">
                <GlobalContainer
                  Heading="Investments"
                  MainContainerHeading={"Assets"}
                  item={updatedInvestmentItems}
                  currentPage={"Insurance"}
                  mainframeContent={<LifeInsurancePage />}
                  link="/other-assets-page"
                  backLink="/other-assets-page"
                  progress={58}
                />
              </Route>
              <Route path="/business-page">
                <GlobalContainer
                  Heading="Investments"
                  MainContainerHeading={"Assets"}
                  item={updatedInvestmentItems}
                  currentPage={"Business"}
                  mainframeContent={<BusinessPage />}
                  link="/other-assets-page"
                  backLink="/other-assets-page"
                  progress={58}
                />
              </Route>
              <Route path="/business-form">
                <GlobalContainer
                  Heading="Investments"
                  MainContainerHeading={"Assets"}
                  item={updatedInvestmentItems}
                  currentPage={"Business"}
                  mainframeContent={<BusinessForm />}
                  link="/business-page"
                  backLink="/business-page"
                  progress={58}
                />
              </Route>
              <Route path="/investment-funds-form">
                <GlobalContainer
                  Heading="Investments"
                  MainContainerHeading={"Assets"}
                  item={updatedInvestmentItems}
                  currentPage={"Business"}
                  mainframeContent={<InvestmentFundsForm />}
                  link="/business-page"
                  backLink="/business-page"
                  progress={58}
                />
              </Route>
              <Route path="/inheritance-form">
                <GlobalContainer
                  Heading="Investments"
                  MainContainerHeading={"Assets"}
                  item={updatedInvestmentItems}
                  currentPage={"Inheritance"}
                  mainframeContent={<InheritancePage />}
                  link="/other-assets-page"
                  backLink="/other-assets-page"
                  progress={58}
                />
              </Route>
              {/* <OltreChatBubble /> */}
            </SpouseContext.Provider>
          </Flex>
        </>
      )}
    </Box>
  );
}

export default MainScreen;
