import React, { useState, useEffect } from 'react';
import ReactEcharts from 'echarts-for-react';
import FilterIcon from '../../img/filter-icon.png';


const currencyFormat = (money) => {
  if (money === 0) {
      return '$0'; // Handle zero explicitly
  } else if (money === '') {
      return '';
  } else {
      const intTotal = Math.round(Math.round(money * 100) / 100);
      const currencyCad = new Intl.NumberFormat('en-US', {
          style: 'currency',
          currency: 'USD',
          minimumFractionDigits: 0,
      }).format(intTotal);
      return currencyCad;
  }
};


function DebtLineGraph(props) {
const {
 title,
 primaryRes,
 recreationProperty,
 landProperty,
 incomeProperty,
 debt,
 totalDebt,
 graphClicked,
 currentAge,
 spouseAge,
} = props;
const xAxis = totalDebt?.map((item) => item.x);
const currentYear = new Date().getFullYear();

const xAxisWithAge = xAxis?.map((year) => {
  const age = currentAge + (year - currentYear);
  const spouse = spouseAge ? spouseAge + (year - currentYear) : "";
  return spouseAge ? `${year}\n(${age} | ${spouse})` : `${year}\n(${age})`;
});
const yAxisPrimaryResidence = primaryRes?.map((item) => item.y);
const yAxisRecreationProperty = recreationProperty?.map((item) => item.y);
const yAxisLandProperty = landProperty?.map((item) => item.y);
const yAxisIncomeProperty = incomeProperty?.map((item) => item.y);
const yAxisDebt = debt?.map((item) => item.y);
const yAxisTotalPortfolio = totalDebt?.map((item) => item.y);

const [showDropdown, setShowDropdown] = useState(false);
const [showAll, setShowAll] = useState(true);
const [showPrimaryResidence, setShowPrimaryResidence] = useState(false);
const [showRecreationProperty, setShowRecreationProperty] = useState(false);
const [showLandProperty, setShowLandProperty] = useState(false);
const [showIncomeProperty, setShowIncomeProperty] = useState(false);
const [showDebt, setShowDebt] = useState(false);
const [showTotal, setShowTotal] = useState(false);
const [colorScheme, setColorScheme] = useState(['#73B879', '#30AB9D', '#00A2D4', '#007EB5', '#00466D', '#00304A', '#001A2A', '#147984', '#06426F']);

useEffect(() => {
 
  selectGraph(graphClicked)
}, [graphClicked])

useEffect(() => {
    if (showPrimaryResidence) setColorScheme(['#30AB9D']);
    if (showRecreationProperty) setColorScheme(['#00A2D4']);
    if (showLandProperty) setColorScheme(['#06426F']);
    if (showIncomeProperty) setColorScheme(['#007EB5'])
    if (showDebt) setColorScheme(['#004069']);
    if (showTotal) setColorScheme(['#00A2D4']);
    if (showAll) setColorScheme(['#73B879', '#004069', '#00A2D4', '#007EB5', '#00466D', '#00304A', '#001A2A', '#147984', '#06426F']);
}, [showPrimaryResidence, showRecreationProperty, showLandProperty, showDebt, showTotal, showAll, showIncomeProperty]);

function numFormatter(num) {  
  if (num > 999 && num < 10000){
    return '$'+(num/1000).toFixed(1) + 'K';
  }
  else if (num > 9999 && num < 1000000){
    return '$'+(num/1000).toFixed(0) + 'K';
  }
  else if (num > 1000000){
    return '$'+(num/1000000).toFixed(1) + 'M';
  } 
  else if (num < 900){
      return '$'+num;
  }
}

const headers = [
  { name: 'Primary Residence', show: (primaryRes?.some(obj => obj.y !== 0)) },
  { name: 'Recreation Property', show: (recreationProperty?.some(obj => obj.y !== 0)) },
  { name: 'Land', show: (landProperty?.some(obj => obj.y !== 0)) },
  { name: 'Income Property', show: (incomeProperty?.some(obj => obj.y !== 0)) },
  { name: 'Loans', show: (debt?.some(obj => obj.y !== 0)) },
  { name: 'Total', show: (totalDebt?.some(obj => obj.y !== 0)) }
];

const dataToRender = headers.filter(header => header.show)?.map(header => header.name)


const option = {
  color: colorScheme,
  title: {
    text: window.innerWidth <=500 ? "Overview":title,
    left: 'center', 
    top: '0%',
    textStyle: {
      lineHeight: window.innerWidth <= 480 && !showAll ? -30 : null
    }
  },
  tooltip: {
    trigger: 'axis',
       axisPointer: {
        type: 'shadow', // Use 'line' for X-axis
    },
    formatter: function(params) {
      let result = params[0].axisValueLabel + '<br/>';
      params.forEach(function (item) {
        result += item.marker + ' ' + item.seriesName + ': ' + currencyFormat(item.data) + '<br/>';
      });
      return result;
    }
  },
  legend: {
    data: dataToRender,
    top: '10%',
    selectedMode: false,
    top: 25,
    show: window.innerWidth <= 480 && showAll ? false : true
  },
  toolbox: {
    feature: {
      saveAsImage: {
        iconStyle: {
          borderColor: "#004069",
        },
      },
    },
  },
  grid: {
    left: 3,
    right: 3,
    bottom: "0%",
    containLabel: true,
  },
  xAxis: [
    {
      type: 'category',
      boundaryGap: ['20%', '20%'],
      data: xAxisWithAge,
    },
  ],
  yAxis: [
    {
      type: 'value',
      axisLabel: {
        formatter: function(value) {
          return numFormatter(value);
        }
      }
    },
],
  series: [
    ((showPrimaryResidence || showAll) && (primaryRes?.some(obj => obj.y !== 0))) && {
      name: 'Primary Residence',
      type: 'bar',
      itemStyle: {
        borderRadius: !showAll ? [5, 5, 0, 0]: '',
      },
      stack: 'Total',
       // barWidth: '30%',
      smooth: true,
      areaStyle: {
        color: {
            type: 'linear',
            x: 0,
            y: 0,
            x2: 0,
            y2: 1,
            colorStops: [{
                offset: 0, color: '#30AB9D', // color at 0%
            }, {
                offset: 1, color: '#30AB9D', // color at 100%
            }],
            global: false, // default is false
          },
      },
      emphasis: {
        focus: 'series',
      },
      data: yAxisPrimaryResidence?.map(v => Math.max(0, v)),
    },
    ((showRecreationProperty || showAll) && (recreationProperty?.some(obj => obj.y !== 0))) && {
      name: 'Recreation Property',
      type: 'bar',
      itemStyle: {
        borderRadius: !showAll ? [5, 5, 0, 0]: '',
      },
      stack: 'Total',
       // barWidth: '30%',
      smooth: true,
      areaStyle: {
        color: {
            type: 'linear',
            x: 0,
            y: 0,
            x2: 0,
            y2: 1,
            colorStops: [{
                offset: 0, color: '#00A2D4', // color at 0%
            }, {
                offset: 1, color: '#00A2D4', // color at 100%
            }],
            global: false, // default is false
          },
      },
      emphasis: {
        focus: 'series',
      },
      data: yAxisRecreationProperty?.map(v => Math.max(0, v)),
    },
    ((showLandProperty || showAll) && (landProperty?.some(obj => obj.y !== 0))) && {
      name: 'Land',
      type: 'bar',
      itemStyle: {
        borderRadius: !showAll ? [5, 5, 0, 0]: '',
      },
      stack: 'Total',
       // barWidth: '30%',
      smooth: true,
      areaStyle: {
        color: {
            type: 'linear',
            x: 0,
            y: 0,
            x2: 0,
            y2: 1,
            colorStops: [{
                offset: 0, color: 'green', // color at 0%
            }, {
                offset: 1, color: 'green', // color at 100%
            }],
            global: false, // default is false
          },
      },
      emphasis: {
        focus: 'series',
      },
      data: yAxisLandProperty?.map(v => Math.max(0, v)),
    },
    ((showIncomeProperty || showAll) && (incomeProperty?.some(obj => obj.y !== 0))) && {
      name: 'Income Property',
      type: 'bar',
      itemStyle: {
        borderRadius: !showAll ? [5, 5, 0, 0]: '',
      },
      stack: 'Total',
       // barWidth: '30%',
      smooth: true,
      areaStyle: {
        color: {
            type: 'linear',
            x: 0,
            y: 0,
            x2: 0,
            y2: 1,
            colorStops: [{
                offset: 0, color: '#007EB5', // color at 0%
            }, {
                offset: 1, color: '#007EB5', // color at 100%
            }],
            global: false, // default is false
          },
      },
      emphasis: {
        focus: 'series',
      },
      data: yAxisIncomeProperty?.map(v => Math.max(0, v)),
    },
    ((showDebt || showAll) && (debt?.some(obj => obj.y !== 0))) && {
      name: 'Loans',
      type: 'bar',
      itemStyle: {
        borderRadius: !showAll ? [5, 5, 0, 0]: '',
      },
      stack: 'Total',
       // barWidth: '30%',
      smooth: true,
      areaStyle: {
        color: {
            type: 'linear',
            x: 0,
            y: 0,
            x2: 0,
            y2: 1,
            colorStops: [{
                offset: 0, color: '#00466D', // color at 0%
            }, {
                offset: 1, color: '#00466D', // color at 100%
            }],
            global: false, // default is false
          },
      },
      emphasis: {
        focus: 'series',
      },
      data: yAxisDebt?.map(v => Math.max(0, v)),
    },
    showTotal && {
      name: 'Total',
      type: 'bar',
      itemStyle: {
        borderRadius: !showAll ? [5, 5, 0, 0]: '',
      },
      stack: 'Total',
       // barWidth: '30%',
      smooth: true,
      areaStyle: {
        color: {
            type: 'linear',
            x: 0,
            y: 0,
            x2: 0,
            y2: 1,
            colorStops: [{
                offset: 0, color: '#FFD4B9', // color at 0%
            }, {
                offset: 1, color: '#FFD4B9', // color at 100%
            }],
            global: false, // default is false
        },
      },
      emphasis: {
        focus: 'series',
      },
      data: yAxisTotalPortfolio?.map(v => Math.max(0, v)),
    },
  ],
};
const eChartsRef = React.useRef(0);

function selectGraph(graph) {
    if (graph === 'Total') { setShowTotal(true); setShowAll(false); } else setShowTotal(false);
    if (graph === 'Primary Residence' || graph === 'Mortgages') { setShowPrimaryResidence(true); setShowAll(false); } else setShowPrimaryResidence(false);
    if (graph === 'Recreation Property' || graph === 'Mortgages') { setShowRecreationProperty(true); setShowAll(false); } else setShowRecreationProperty(false);
    if (graph === 'Land' || graph === 'Mortgages') { setShowLandProperty(true); setShowAll(false); } else setShowLandProperty(false);
    if (graph === 'Income Property' || graph === 'Mortgages') { setShowIncomeProperty(true); setShowAll(false); } else setShowIncomeProperty(false);
    if (graph === 'Loans') { setShowDebt(true); setShowAll(false); } else setShowDebt(false);
    if (graph === 'Overview' || graph === 'expand' || graph === 'collapse') { setShowAll(true); } else setShowAll(false);
}

  return (
    <div
    className="dashboard-graph-container"
    style={{ border: "none", borderRadius: "none", boxShadow: "none", padding: '0'}}
  >
      <div
        tabIndex={0}
        className="dropdown"
        onClick={() => setShowDropdown(!showDropdown)}
        onBlur={() => setShowDropdown(showDropdown)}
        style={{right:'48px'}}
      >
        <img src={FilterIcon} className="filter-icon" alt="filter-icon" />
        {showDropdown && (
        <div className="dropdown-items">
          <ul>
            <div>Display</div>
            <li className={showTotal ? 'dropdown-item-selected' : undefined} onClick={() => selectGraph('Total')}>Total Debt Balance</li>
            <li className={showAll ? 'dropdown-item-selected' : undefined} onClick={() => selectGraph('Overview')}>All</li>
            {(primaryRes?.some(obj => obj.y!== 0)) && (<li className={showPrimaryResidence ? 'dropdown-item-selected' : undefined} onClick={() => selectGraph('Primary Residence')}>Primary Residence</li>)}
            {(recreationProperty?.some(obj => obj.y!== 0)) && (<li className={showRecreationProperty ? 'dropdown-item-selected' : undefined} onClick={() => selectGraph('Recreation Property')}>Recreation Property</li>)}
            {(landProperty?.some(obj => obj.y!== 0)) && (<li className={showLandProperty ? 'dropdown-item-selected' : undefined} onClick={() => selectGraph('Land')}>Land</li>)}
            {(incomeProperty?.some(obj => obj.y !== 0)) && (<li className={showIncomeProperty ? 'dropdown-item-selected' : undefined} onClick={() => selectGraph('Income Property')}>Income Property</li>)}
            {(debt?.some(obj => obj.y!== 0)) && (<li className={showDebt ? 'dropdown-item-selected' : undefined} onClick={() => selectGraph('Loans')}>Loans</li>)}
          </ul>
        </div>
)}
      </div>
      <ReactEcharts style={{width:"100%"}} ref={eChartsRef} option={option} className="portfolio-balance-chart" notMerge />
    </div>

  );
}

export default DebtLineGraph;
