import React, { useEffect, useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { Box, Text, Button, Center } from "@chakra-ui/react";
function Callback() {
  const { isAuthenticated, isLoading, loginWithRedirect, logout, user } =
    useAuth0();
  const [errorScreen, setErrorScreen] = useState(false);

  const ErrorPage = ({ user, logout }) => {
    return (
      <Center h="100vh" bg="#0F9CD6">
        <Box
          textAlign="center"
          p={10}
          bg="white"
          boxShadow="lg"
          borderRadius="md"
          maxW={'500px'}
        >
          <Text fontSize="2xl" fontWeight="bold" color="#2DBDB6" mb={4}>
            This account has been used before
          </Text>
          <Text fontSize="lg" color="gray.700" mb={8}>
            This GOOGLE account has be created before. If you just continued with
            GOOGLE, try logging in with the email and password, otherwise hit continue with
            GOOGLE from the sign in page with this account.
          </Text>
          <Button color="#FFF" bg="#2DBDB6" onClick={logout}>
            Logout Now
          </Button>
        </Box>
      </Center>
    );
  };

  useEffect(() => {
    if (!isLoading && !isAuthenticated) {
      const searchParams = new URLSearchParams(window.location.search);
      
      // Handling the error scenario first
      if (searchParams.has("error")) {
        setErrorScreen(true);
        return; // Stop further execution if there's an error
      }
  
      // Determine the screen hint
      const screenHint = searchParams.has("login") ? "login" 
                      : searchParams.has("signup") ? "signup" 
                      : undefined;
  
      // Only call loginWithRedirect if there's a screen hint or no specific action
      if (screenHint) {
        loginWithRedirect({ screen_hint: screenHint });
      } else {
        loginWithRedirect(); // Default redirection
      }
  
    } else if (!isLoading && isAuthenticated) {
      // Redirect to home if authenticated and not loading
      window.location.href = window.location.origin;
    }
  }, [isLoading, isAuthenticated, loginWithRedirect]);
  

  if (errorScreen) {
    return <ErrorPage user={user} logout={logout} />;
  }

  if (isLoading) {
    return <div>Loading...</div>;
  }
  if (!isAuthenticated) {
    return null;
  }
  return null;
}
export default Callback;
