import { useAuth0 } from "@auth0/auth0-react";
import {
  Box,
  Button,
  Flex,
  Heading,
  Text,
  VStack,
  useMediaQuery,
} from "@chakra-ui/react";
import axios from "axios";
import React, {
  forwardRef,
  useContext,
  useEffect,
  useImperativeHandle,
  useState,
} from "react";
import { useStore } from "../../store";
import GlobalBodyPage from "../GlobalContainer/BodyPage";
import DropDownButton from "../InputFields/dropDownButton";
import NormalInputButton from "../InputFields/normalInputButton";
import { SpouseContext } from "../SpouseContext";
import TableComponent from "../TableComponent";
import TooltipContent from "../TooltipContent";
import { CloseIcon } from "../CustomIcons";
import DatePickerButton from "../InputFields/datePickerButton";

const FixedIncomePage = forwardRef((props, ref) => {
  const [isLargerThan768] = useMediaQuery("(min-width: 500px)");
  const isMobile = !isLargerThan768;
  const { getAccessTokenSilently } = useAuth0();

  useImperativeHandle(ref, () => ({
    submitHandler,
    checkMandatoryFields,
  }));

  const checkMandatoryFields = () => {
    return true;
  };

  const axiosInstance = axios.create();
  async function getToken() {
    // Replace this with your Auth0 token retrieval logic
    const token = await getAccessTokenSilently({
      audience:
        "https://os2lsiwff43wfpb5ggyawt6qri0mgcro.lambda-url.us-east-2.on.aws/",
      scope: "write:optimization",
    });
    return token;
  }

  // Add a request interceptor
  axiosInstance.interceptors.request.use(
    async (config) => {
      const token = await getToken();
      config.headers.Authorization = `Bearer ${token}`;
      return config;
    },
    (error) => {
      // Do something with request error
      return Promise.reject(error);
    }
  );

  const userFixedIncomeInfo = useStore((store) => store.userFixedIncomeInfo);
  const setUserFixedIncomeInfo = useStore(
    (store) => store.setUserFixedIncomeInfo
  );
  const updateUserFixedIncomeInfo = useStore(
    (store) => store.updateUserFixedIncomeInfo
  );
  const deleteUserFixedIncomeInfo = useStore(
    (store) => store.deleteUserFixedIncomeInfo
  );

  // Spouse 1
  const [fixedIncomeNumber, setFixedIncomeNumber] = useState("");
  const [hasFixedIncome, setHasFixedIncome] = useState("No");
  const [fixedIncomeName, setFixedIncomeName] = useState("");
  const [interestType, setInterestType] = useState("Compound");
  const [principleAmount, setPrincipleAmount] = useState(0);
  const [maturityDate, setMaturityDate] = useState("");
  const [purchaseDate, setPurchaseDate] = useState("");
  const [annualGrowth, setAnnualGrowth] = useState("");
  const [fixedIncomeArray, setFixedIncomeArray] = useState([]);

  // Spouse 2
  const [spouseFixedIncomeNumber, setSpouseFixedIncomeNumber] = useState("");
  const [spouseHasFixedIncome, setSpouseHasFixedIncome] = useState("No");
  const [spouseFixedIncomeName, setSpouseFixedIncomeName] = useState("");
  const [spouseInterestType, setSpouseInterestType] = useState("Compound");
  const [spousePrincipleAmount, setSpousePrincipleAmount] = useState(0);
  const [spouseMaturityDate, setSpouseMaturityDate] = useState(0);
  const [spousePurchaseDate, setSpousePurchaseDate] = useState("");
  const [spouseAnnualGrowth, setSpouseAnnualGrowth] = useState();
  const [spouseFixedIncomeArray, setSpouseFixedIncomeArray] = useState([]);

  const [editing, setEditing] = useState(false);
  const [editingIndex, setEditingIndex] = useState(null);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const { spouseStatus, setSpouseStatus } = useContext(SpouseContext);
  const [activeArray, setActiveArray] = useState(null);
  const [spouseCheck, setSpouseCheck] = useState("");

  const { user, logout } = useAuth0();
  const userInfo = useStore((store) => store.userInfo);

  const userExpensesInfo = useStore((store) => store.userExpensesInfo);

  const setUserPageLockInfo = useStore((store) => store.setUserPageLockInfo);
  const userPageLockInfo = useStore((store) => store.userPageLockInfo);

  useEffect(() => {
    setHasFixedIncome("No");
    setSpouseHasFixedIncome("No");
  }, []);

  const {
    FirstName: firstName,
    LastName: lastName,
    DateOfBirth: currentAge,
    spouseFirstName,
    spouseLastName,
    spouseDateOfBirth: spouseAge,
  } = userInfo;

  const Select = (userAge) => {
    const currentDate = new Date();
    const currentYear = currentDate.getFullYear();
    let age = getAge(userAge, currentDate); // Assuming getAge returns the starting age.
    const runAgeList = [];
    for (let i = age; i <= 100; i++) {
      runAgeList.push({ key: i, value: currentYear + (i - age) }); // Convert i to string if necessary
    }
    return runAgeList;
  };

  useEffect(() => {
    if (userFixedIncomeInfo?.length > 0) {
      setFixedIncomeArray(
        userFixedIncomeInfo?.filter((x) => x.spouseCheck === 0)
      );
      setSpouseFixedIncomeArray(
        userFixedIncomeInfo?.filter((x) => x.spouseCheck === 1)
      );
    }
  }, [userFixedIncomeInfo]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [fixedIncomeArray, spouseFixedIncomeArray]);

  function getAge(birthday, currentDate) {
    // Get the current date
    // const currentDate = new Date();

    // Convert the birthday string into a Date object
    const birthDate = new Date(birthday);

    // Calculate the age in years
    let age = currentDate.getFullYear() - birthDate.getFullYear();

    // Decrement the age if the current month and day are before the birth month and day
    if (
      currentDate.getMonth() < birthDate.getMonth() ||
      (currentDate.getMonth() === birthDate.getMonth() &&
        currentDate.getDate() <= birthDate.getDate())
    ) {
      age -= 1;
    }

    return age;
  }

  const moneyToNumber = (value) => {
    let numValue = value.replace(/[$,\s]/g, "");
    numValue = Number(numValue);
    return numValue;
  };

  const submitHandler = () => {
    //Set Page Lock Info In Database:
    const pageLockURL = (action) =>
      `${
        process.env.REACT_APP_NODE_API_GATEWAY
      }/CompassLambdaApi/NEW_USER_PAGE_LOCK/${action}?PageLockID=${user.sub.replace(
        "|",
        "%7c"
      )}&IncomePage=${1}&InvestmentsPage=${1}`;
    axiosInstance
      .get(pageLockURL("add"))
      .then((res) => {
        setUserPageLockInfo({
          ...userPageLockInfo,
          InvestmentsPage: 1,
        });
        console.log(res);
        if (res.data.code === "ER_DUP_ENTRY") {
          axiosInstance.get(pageLockURL("update")).then((res) => {
            console.log(res);
            setUserPageLockInfo({
              ...userPageLockInfo,
              InvestmentsPage: 1,
            });
          });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const [errors, setErrors] = useState({});
  const [spouseErrors, setSpouseErrors] = useState({});

  const isValidDate = (dateStr) => {
    const regex = /^\d{4}-\d{2}-\d{2}$/; // Matches YYYY-MM-DD
    if (dateStr && regex.test(dateStr)) {
      const date = new Date(dateStr);
      return date instanceof Date && !isNaN(date);
    }
    return false;
  };
  

  const checkMandatoryAddUpdateFields = () => {
    let newErrors = {};
    let newSpouseErrors = {};

    if (hasFixedIncome === "Yes") {
      if (!fixedIncomeName)
        newErrors.fixedIncomeName = "Fixed income name is required";
      if (!interestType)
        newErrors.interestType = "Fixed income frequency is required";
      if (!principleAmount)
        newErrors.principleAmount = "Principle amount is required";
      if (!maturityDate || !isValidDate(maturityDate)) newErrors.maturityDate = "Maturity date is required";
      if (!purchaseDate || !isValidDate(purchaseDate)) newErrors.purchaseDate = "Purchase date is required";
      if (!annualGrowth || annualGrowth > 20)
        newErrors.annualGrowth =
          "Growth rate is required and must be less than 20%";
    }

    if (spouseStatus && spouseHasFixedIncome === "Yes") {
      if (!spouseFixedIncomeName)
        newSpouseErrors.fixedIncomeName = "Fixed income name is required";
      if (!spouseInterestType)
        newSpouseErrors.interestType = "Fixed income frequency is required";
      if (!spousePrincipleAmount)
        newSpouseErrors.principleAmount = "Principle amount is required";
      if (!spouseMaturityDate || !isValidDate(spouseMaturityDate))
        newSpouseErrors.maturityDate = "Maturity date is required";
      if (!spousePurchaseDate || !isValidDate(spousePurchaseDate))
        newSpouseErrors.purchaseDate = "Purchase date is required";
      if (!spouseAnnualGrowth || spouseAnnualGrowth > 20)
        newSpouseErrors.annualGrowth =
          "Growth rate is required and must be less than 20%";
    }

    setSpouseErrors(newSpouseErrors);
    setErrors(newErrors);
    return (
      Object.keys(newErrors).length === 0 &&
      Object.keys(newSpouseErrors).length === 0
    ); // Return true if no errors
  };

  const renderAddUpdate = (arr) => {
    if (
      (hasFixedIncome === "Yes" &&
        (!fixedIncomeName ||
          !interestType ||
          !principleAmount ||
          !maturityDate ||
          !isValidDate(maturityDate) ||
          !purchaseDate ||
          !isValidDate(purchaseDate) ||
          !annualGrowth ||
          annualGrowth > 20)) ||
      (spouseStatus &&
        spouseHasFixedIncome === "Yes" &&
        (!spouseFixedIncomeName ||
          !spouseInterestType ||
          !spousePrincipleAmount ||
          !spouseMaturityDate ||
          !isValidDate(spouseMaturityDate) ||
          !spousePurchaseDate ||
          !isValidDate(spousePurchaseDate) ||
          !spouseAnnualGrowth ||
          spouseAnnualGrowth > 20))
    ) {
      return (
        <Flex justifyContent={"flex-start"} marginTop={"20px"}>
          <Button
            bg="#27AE60"
            w="172px"
            h="37px"
            align="flex-start"
            onClick={checkMandatoryAddUpdateFields}
            // disabled={!isFieldsValid}
          >
            <Text
              color={"#FFFFFF"}
              fontFamily={"Nunito"}
              fontSize={"14px"}
              fontStyle={"normal"}
              fontWeight={"700"}
              lineHeight={"normal"}
            >
              {editing && activeArray === arr ? "Update" : "Add"}
            </Text>
          </Button>
        </Flex>
      );
    } else {
      return (
        <Flex justifyContent={"flex-start"} marginTop={"20px"}>
          <Button
            bg="#27AE60"
            w="172px"
            h="37px"
            align="flex-start"
            onClick={(e) => addFixedIncome(e, arr)}
            // disabled={!isFieldsValid}
          >
            <Text
              color={"#FFFFFF"}
              fontFamily={"Nunito"}
              fontSize={"14px"}
              fontStyle={"normal"}
              fontWeight={"700"}
              lineHeight={"normal"}
            >
              {editing && activeArray === arr ? "Update" : "Add"}
            </Text>
          </Button>
        </Flex>
      );
    }
  };

  const onSubmitUpdate = (e, newArray) => {
    let x = newArray.fixedIncomeNumber;
    if (e) {
      e.preventDefault();
    }
    const fixedIncomeURL = (action) =>
      `${
        process.env.REACT_APP_NODE_API_GATEWAY
      }/CompassLambdaApi/FIXED_INCOME/${action}?fixedIncomeID=${user.sub.replace(
        "|",
        "%7c"
      )}&fixedIncomeTitle=${newArray.fixedIncomeTitle}&fixedIncomeType=${
        newArray.fixedIncomeType
      }&fixedIncomeValue=${newArray.fixedIncomeValue}&fixedIncomeEndDate=${
        newArray.fixedIncomeEndDate
      }&fixedIncomeStart=${newArray.fixedIncomeStart}&fixedIncomeInterest=${
        newArray.fixedIncomeInterest
      }&fixedIncomeNumber=${newArray.fixedIncomeNumber}&spouseCheck=${
        newArray.spouseCheck
      }`;
    axiosInstance
      .get(fixedIncomeURL("update"))
      .then((res) => {
        console.log(res);
        const newData = {
          fixedIncomeTitle: newArray.fixedIncomeTitle,
          fixedIncomeType: newArray.fixedIncomeType,
          fixedIncomeValue: newArray.fixedIncomeValue,
          fixedIncomeEndDate: newArray.fixedIncomeEndDate,
          fixedIncomeStart: newArray.fixedIncomeStart,
          fixedIncomeInterest: newArray.fixedIncomeInterest,
          fixedIncomeNumber: newArray.fixedIncomeNumber,
          spouseCheck: newArray.spouseCheck,
        };
        updateUserFixedIncomeInfo(x, newData);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const onSubmitAdd = (e, newArray) => {
    e.preventDefault();
    const fixedIncomeURL = (action) =>
      `${
        process.env.REACT_APP_NODE_API_GATEWAY
      }/CompassLambdaApi/FIXED_INCOME/${action}?fixedIncomeID=${user.sub.replace(
        "|",
        "%7c"
      )}&fixedIncomeTitle=${newArray.fixedIncomeTitle}&fixedIncomeType=${
        newArray.fixedIncomeType
      }&fixedIncomeValue=${newArray.fixedIncomeValue}&fixedIncomeEndDate=${
        newArray.fixedIncomeEndDate
      }&fixedIncomeStart=${newArray.fixedIncomeStart}&fixedIncomeInterest=${
        newArray.fixedIncomeInterest
      }&spouseCheck=${newArray.spouseCheck}`;
    axiosInstance
      .get(fixedIncomeURL("add"))
      .then((res) => {
        console.log(res);
        const newData = [
          {
            fixedIncomeTitle: newArray.fixedIncomeTitle,
            fixedIncomeType: newArray.fixedIncomeType,
            fixedIncomeValue: newArray.fixedIncomeValue,
            fixedIncomeEndDate: newArray.fixedIncomeEndDate,
            fixedIncomeStart: newArray.fixedIncomeStart,
            fixedIncomeInterest: newArray.fixedIncomeInterest,
            fixedIncomeNumber: res.data.lastInsertedId,
            spouseCheck: newArray.spouseCheck,
          },
        ];
        setUserFixedIncomeInfo(newData);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const editField = (i, array) => {
    setActiveArray(array);
    if (array === fixedIncomeArray) {
      setFixedIncomeName(fixedIncomeArray[i]?.fixedIncomeTitle);
      setInterestType(fixedIncomeArray[i]?.fixedIncomeType);
      setPrincipleAmount(fixedIncomeArray[i]?.fixedIncomeValue);
      setMaturityDate(fixedIncomeArray[i]?.fixedIncomeEndDate);
      setPurchaseDate(fixedIncomeArray[i]?.fixedIncomeStart);
      setAnnualGrowth(fixedIncomeArray[i]?.fixedIncomeInterest);
      setHasFixedIncome("Yes");
      setSpouseHasFixedIncome("No");
      setFixedIncomeNumber(fixedIncomeArray[i]?.fixedIncomeNumber);
      setSpouseCheck(0);
    } else {
      setSpouseFixedIncomeName(spouseFixedIncomeArray[i]?.fixedIncomeTitle);
      setSpouseInterestType(spouseFixedIncomeArray[i]?.fixedIncomeType);
      setSpousePrincipleAmount(spouseFixedIncomeArray[i]?.fixedIncomeValue);
      setSpouseMaturityDate(spouseFixedIncomeArray[i]?.fixedIncomeEndDate);
      setSpousePurchaseDate(spouseFixedIncomeArray[i]?.fixedIncomeStart);
      setSpouseAnnualGrowth(spouseFixedIncomeArray[i]?.fixedIncomeInterest);
      setSpouseHasFixedIncome("Yes");
      setHasFixedIncome("No");
      setSpouseFixedIncomeNumber(spouseFixedIncomeArray[i]?.fixedIncomeNumber);
      setSpouseCheck(1);
    }

    setEditing(true);
    setEditingIndex(i);
  };

  const onDeleteRow = (i, array) => {
    setSpouseCheck(array[i].spouseCheck);
    setEditingIndex(i);
    setActiveArray(array);
    setShowDeleteModal(true);
  };

  const DeleteIncomeModal = () => {
    const removeIncomeModal = () => {
      const deleteFixedIncomeUrl = (action) =>
        `${process.env.REACT_APP_NODE_API_GATEWAY}/CompassLambdaApi/FIXED_INCOME/${action}?fixedIncomeNumber=${activeArray[editingIndex].fixedIncomeNumber}`;
      axiosInstance.get(deleteFixedIncomeUrl("delete")).then((res) => {
        if (spouseCheck === 0) {
          deleteUserFixedIncomeInfo(
            fixedIncomeArray[editingIndex].fixedIncomeNumber
          );
          removeField(editingIndex);
          setEditingIndex(null);
          setEditing(false);
          setFixedIncomeName("");
          setInterestType("");
          setPrincipleAmount("");
          setMaturityDate();
          setPurchaseDate();
          setAnnualGrowth(userExpensesInfo?.expenseCPI);
        }
        if (spouseCheck === 1) {
          deleteUserFixedIncomeInfo(
            spouseFixedIncomeArray[editingIndex].fixedIncomeNumber
          );
          removeField(editingIndex);
          setEditingIndex(null);
          setEditing(false);
          setSpouseFixedIncomeName("");
          setSpouseInterestType("");
          setSpousePrincipleAmount("");
          setSpouseMaturityDate();
          setSpousePurchaseDate();
          setSpouseAnnualGrowth(userExpensesInfo?.expenseCPI);
        }
      });
    };

    if (!showDeleteModal) {
      return null;
    }
    const [yesClicked, setYesClicked] = useState(false);
    const [noClicked, setNoClicked] = useState(false);
    return (
      <div
        className="delete-income-modal"
        style={{
          display: "flex",
          alignItems: "center",
          width: "100%",
          maxWidth: "700px",
          justifyContent: "center",
          top: "50%",
          left: "50%",
          transform: isMobile
            ? "translate(-50%, -50%)"
            : "translate(-50%, -50%)",
        }}
        id="delete-income-modal"
      >
        <Box
          display={"flex"}
          width={"100%"}
          padding={"10px 10px 0px 0px"}
          marginLeft={"5px"}
          alignItems={"flex-end"}
          justifyContent={"flex-end"}
          onClick={() => setShowDeleteModal(false)}
          cursor={"pointer"}
        >
          <CloseIcon
            onClick={() => setShowDeleteModal(false)}
            svgheight={"20px"}
            svgwidth={"20px"}
          />
        </Box>
        <Box
          display={"flex"}
          padding={"0px 25px 25px 25px"}
          flexDirection={"column"}
          alignItems={"center"}
          gap={"10px"}
          alignSelf={"stretch"}
          width={"100%"}
        >
          <Text
            color={"#0093D0"}
            fontFamily={"Nunito"}
            fontSize={"24px"}
            fontStyle={"normal"}
            fontWeight={"600"}
            lineHeight={"normal"}
            textDecor={"underline"}
          >
            Are you sure you want to remove this income?
          </Text>
          <div className="content"></div>
          <div className="actions">
            <button
              className={`decision-button yes-button ${
                yesClicked ? "yes-clicked" : ""
              }`}
              onClick={() => {
                setYesClicked(true);
                setNoClicked(false);
                removeIncomeModal();
              }}
            >
              Yes
            </button>
            <button
              className={`decision-button no-button ${
                noClicked ? "yes-clicked" : ""
              }`}
              onClick={() => {
                {
                  setNoClicked(true);
                  setYesClicked(false);
                  setShowDeleteModal(false);
                }
              }}
            >
              No
            </button>
          </div>
        </Box>
      </div>
    );
  };

  const addFixedIncome = (e, array) => {
    if (editing) {
      if (array === fixedIncomeArray) {
        setFixedIncomeArray((prevArray) => {
          // Create a copy of the original array
          let newArray = [...prevArray];
          // Replace the object at editingIndex with the new object
          newArray[editingIndex] = {
            // Do_Other_Income: hasFixedIncome,
            fixedIncomeTitle: fixedIncomeName,
            fixedIncomeType: interestType,
            fixedIncomeValue: principleAmount,
            fixedIncomeEndDate: maturityDate,
            fixedIncomeStart: purchaseDate,
            fixedIncomeInterest: annualGrowth,
            fixedIncomeNumber: fixedIncomeNumber,
            spouseCheck: 0,
          };
          onSubmitUpdate(e, newArray[editingIndex]);
          return newArray;
        });
      }
      if (array === spouseFixedIncomeArray) {
        setSpouseFixedIncomeArray((prevArray) => {
          // Create a copy of the original array
          let newArray = [...prevArray];
          // Replace the object at editingIndex with the new object
          newArray[editingIndex] = {
            // Do_Other_Income: spouseHasFixedIncome,
            fixedIncomeTitle: spouseFixedIncomeName,
            fixedIncomeType: spouseInterestType,
            fixedIncomeValue: spousePrincipleAmount,
            fixedIncomeEndDate: spouseMaturityDate,
            fixedIncomeStart: spousePurchaseDate,
            fixedIncomeInterest: spouseAnnualGrowth,
            fixedIncomeNumber: spouseFixedIncomeNumber,
            spouseCheck: 1,
          };
          onSubmitUpdate(e, newArray[editingIndex]);
          return newArray;
        });
      }
      setEditing(false);
      setEditingIndex(null);
    } else {
      if (array === fixedIncomeArray) {
        setFixedIncomeArray((prevArray) => {
          let newArray = [
            ...prevArray,
            {
              // Do_Other_Income: hasFixedIncome,
              fixedIncomeTitle: fixedIncomeName,
              fixedIncomeType: interestType,
              fixedIncomeValue: principleAmount,
              fixedIncomeEndDate: maturityDate,
              fixedIncomeStart: purchaseDate,
              fixedIncomeInterest: annualGrowth,
              spouseCheck: 0,
            },
          ];
          onSubmitAdd(e, newArray[newArray.length - 1]);
          return newArray;
        });
      }
      if (array === spouseFixedIncomeArray) {
        setSpouseFixedIncomeArray((prevArray) => {
          let newArray = [
            ...prevArray,
            {
              // Do_Other_Income: spouseHasFixedIncome,
              fixedIncomeTitle: spouseFixedIncomeName,
              fixedIncomeType: spouseInterestType,
              fixedIncomeValue: spousePrincipleAmount,
              fixedIncomeEndDate: spouseMaturityDate,
              fixedIncomeStart: spousePurchaseDate,
              fixedIncomeInterest: spouseAnnualGrowth,
              spouseCheck: 1,
            },
          ];
          onSubmitAdd(e, newArray[newArray.length - 1]);
          return newArray;
        });
      }
    }
    setFixedIncomeName("");
    setInterestType("");
    setPrincipleAmount(0);
    setMaturityDate("");
    setPurchaseDate("");
    setAnnualGrowth(userExpensesInfo?.expenseCPI);
    setHasFixedIncome("No");
    setSpouseHasFixedIncome("No");
    setSpouseFixedIncomeName("");
    setSpouseInterestType("");
    setSpousePrincipleAmount(0);
    setSpouseMaturityDate("");
    setSpousePurchaseDate("");
    setSpouseAnnualGrowth(userExpensesInfo?.expenseCPI);
  };

  const removeField = (i) => {
    if (activeArray === fixedIncomeArray) {
      fixedIncomeArray.splice(i, 1);
      setFixedIncomeArray((prevArray) => [...prevArray]);
    } else {
      spouseFixedIncomeArray.splice(i, 1);
      setSpouseFixedIncomeArray((prevArray) => [...prevArray]);
    }
    setShowDeleteModal(false);
  };

  const handleCancelSpouse1 = () => {
    setHasFixedIncome("No");
  };

  const handleCancelSpouse2 = () => {
    setSpouseHasFixedIncome("No");
  };

  const columns = [
    { header: "Name", field: "name" },
    { header: "Value", field: "value" },
  ];

  const data = fixedIncomeArray.map((item) => ({
    name: item.fixedIncomeTitle,
    value: item.fixedIncomeValue,
  }));

  const spouseData = spouseFixedIncomeArray.map((item) => ({
    name: item.fixedIncomeTitle,
    value: item.fixedIncomeValue,
  }));

  return (
    <Flex
      display="flex"
      flexWrap="wrap" // Enable wrapping
      justifyContent="flex-start"
      alignItems="start"
      width={"100%"}
    >
      <DeleteIncomeModal />
      {!isMobile && (
        <VStack spacing={2} alignItems={"flex-start"} width={"100%"} mt={-4}>
          <GlobalBodyPage
            Heading={"Fixed Income"}
            mainframeContent={
              <Box
                display={"flex"}
                width={"100%"}
                flexDirection={"row"}
                alignItems={"flex-end"}
                justifyContent={"space-between"}
                gap={"10px"}
                flexWrap={"wrap"}
              >
                <Flex justify="space-between" w="100%">
                  <Heading
                    height={"27px"}
                    alignSelf={"stretch"}
                    color={"#00466D"}
                    fontFamily={"Nunito"}
                    fontSize={"20px"}
                    fontStyle={"normal"}
                    fontWeight={"700"}
                    lineHeight={"normal"}
                  >
                    {firstName} {lastName}
                  </Heading>
                </Flex>
                {data.length > 0 && hasFixedIncome != "Yes" && (
                  <TableComponent
                    columns={columns}
                    data={data}
                    editField={editField}
                    onDeleteRow={onDeleteRow}
                    rawData={fixedIncomeArray}
                  />
                )}
                {hasFixedIncome != "Yes" && data.length < 1 && (
                  <DropDownButton
                    Heading={
                      "Do you have any fixed income non-registered investments?"
                    }
                    placeholder={"Select"}
                    dropDownItems={[
                      { key: "Yes", value: "Yes" },
                      { key: "No", value: "No" },
                    ]}
                    value={hasFixedIncome}
                    onChange={(key) => {
                      setHasFixedIncome(key);
                      setSpouseHasFixedIncome("No");
                    }}
                    customWidth={"100%"}
                    MenuCustomWidth={"45%"}
                    error={errors.hasFixedIncome}
                    tooltipContent={TooltipContent.fixedIncomeYesNo}
                  />
                )}
                {data.length >= 1 && hasFixedIncome != "Yes" && (
                  <Flex justifyContent={"flex-start"} marginTop={"20px"}>
                    <Button
                      bg="#27AE60"
                      w="172px"
                      h="37px"
                      align="flex-start"
                      onClick={() => {
                        setSpouseHasFixedIncome("No");
                        setHasFixedIncome("Yes");
                      }}
                      // disabled={!isFieldsValid}
                    >
                      <Text
                        color={"#FFFFFF"}
                        fontFamily={"Nunito"}
                        fontSize={"14px"}
                        fontStyle={"normal"}
                        fontWeight={"700"}
                        lineHeight={"normal"}
                      >
                        Add
                      </Text>
                    </Button>
                  </Flex>
                )}
                {hasFixedIncome === "Yes" && (
                  <OtherSourceDetails
                    onCancel={handleCancelSpouse1}
                    fixedIncomeName={fixedIncomeName}
                    setFixedIncomeName={setFixedIncomeName}
                    interestType={interestType}
                    setInterestType={setInterestType}
                    principleAmount={principleAmount}
                    setPrincipleAmount={setPrincipleAmount}
                    maturityDate={maturityDate}
                    setMaturityDate={setMaturityDate}
                    purchaseDate={purchaseDate}
                    setPurchaseDate={setPurchaseDate}
                    annualGrowth={annualGrowth}
                    setAnnualGrowth={setAnnualGrowth}
                    currentAge={currentAge}
                    Select={Select}
                    renderAddUpdate={renderAddUpdate}
                    fixedIncomeArray={fixedIncomeArray}
                    moneyToNumber={moneyToNumber}
                    errors={errors}
                  />
                )}
              </Box>
            }
            videoName={"Fixed Income Page"}
            video={"Fixed_Income_Page.mp4"}
          />

          {spouseStatus && (
            <GlobalBodyPage
              mainframeContent={
                <VStack spacing={4} alignItems={"flex-start"} width={"100%"}>
                  <Flex justify="space-between" w="100%">
                    <Heading
                      height={"27px"}
                      alignSelf={"stretch"}
                      color={"#00466D"}
                      fontFamily={"Nunito"}
                      fontSize={"20px"}
                      fontStyle={"normal"}
                      fontWeight={"700"}
                      lineHeight={"normal"}
                    >
                      {spouseFirstName} {spouseLastName}
                    </Heading>
                  </Flex>
                  {spouseData.length > 0 && spouseHasFixedIncome != "Yes" && (
                    <TableComponent
                      columns={columns}
                      data={spouseData}
                      editField={editField}
                      onDeleteRow={onDeleteRow}
                      rawData={spouseFixedIncomeArray}
                    />
                  )}
                  {spouseHasFixedIncome != "Yes" && spouseData.length < 1 && (
                    <DropDownButton
                      Heading={
                        "Do you have any fixed income non-registered investments?"
                      }
                      placeholder={"Select"}
                      dropDownItems={[
                        { key: "Yes", value: "Yes" },
                        { key: "No", value: "No" },
                      ]}
                      value={spouseHasFixedIncome}
                      onChange={(key) => {
                        setSpouseHasFixedIncome(key);
                        setHasFixedIncome("No");
                      }}
                      customWidth={"100%"}
                      MenuCustomWidth={"45%"}
                      error={spouseErrors.hasFixedIncome}
                      tooltipContent={TooltipContent.fixedIncomeYesNo}
                    />
                  )}
                  {spouseData.length >= 1 && spouseHasFixedIncome != "Yes" && (
                    <Flex justifyContent={"flex-start"} marginTop={"20px"}>
                      <Button
                        bg="#27AE60"
                        w="172px"
                        h="37px"
                        align="flex-start"
                        onClick={() => {
                          setSpouseHasFixedIncome("Yes");
                          setHasFixedIncome("No");
                        }}
                        // disabled={!isFieldsValid}
                      >
                        <Text
                          color={"#FFFFFF"}
                          fontFamily={"Nunito"}
                          fontSize={"14px"}
                          fontStyle={"normal"}
                          fontWeight={"700"}
                          lineHeight={"normal"}
                        >
                          Add
                        </Text>
                      </Button>
                    </Flex>
                  )}
                  {spouseHasFixedIncome === "Yes" && (
                    <OtherSourceDetails
                      onCancel={handleCancelSpouse2}
                      fixedIncomeName={spouseFixedIncomeName}
                      setFixedIncomeName={setSpouseFixedIncomeName}
                      interestType={spouseInterestType}
                      setInterestType={setSpouseInterestType}
                      principleAmount={spousePrincipleAmount}
                      setPrincipleAmount={setSpousePrincipleAmount}
                      maturityDate={spouseMaturityDate}
                      setMaturityDate={setSpouseMaturityDate}
                      purchaseDate={spousePurchaseDate}
                      setPurchaseDate={setSpousePurchaseDate}
                      annualGrowth={spouseAnnualGrowth}
                      setAnnualGrowth={setSpouseAnnualGrowth}
                      currentAge={spouseAge}
                      Select={Select}
                      renderAddUpdate={renderAddUpdate}
                      fixedIncomeArray={spouseFixedIncomeArray}
                      moneyToNumber={moneyToNumber}
                      errors={spouseErrors}
                    />
                  )}
                </VStack>
              }
              videoName={"Fixed Income Page"}
              video={"Fixed_Income_Page.mp4"}
            />
          )}
        </VStack>
      )}

      {isMobile && (
        <VStack spacing={4} alignItems={"flex-start"} width={"100%"}>
          <GlobalBodyPage
            Heading={"Fixed Income"}
            mainframeContent={
              <VStack spacing={4} alignItems={"flex-start"} width={"100%"}>
                <Flex justify="space-between" w="100%">
                  <Heading
                    height={"27px"}
                    alignSelf={"stretch"}
                    color={"#00466D"}
                    fontFamily={"Nunito"}
                    fontSize={"20px"}
                    fontStyle={"normal"}
                    fontWeight={"700"}
                    lineHeight={"normal"}
                  >
                    {firstName} {lastName}
                  </Heading>
                </Flex>
                {data.length > 0 && hasFixedIncome != "Yes" && (
                  <TableComponent
                    columns={columns}
                    data={data}
                    editField={editField}
                    onDeleteRow={onDeleteRow}
                    rawData={fixedIncomeArray}
                  />
                )}
                {hasFixedIncome != "Yes" && data.length < 1 && (
                  <DropDownButton
                    Heading={
                      "Do you have any fixed income non-registered investments?"
                    }
                    placeholder={"Select"}
                    dropDownItems={[
                      { key: "Yes", value: "Yes" },
                      { key: "No", value: "No" },
                    ]}
                    value={hasFixedIncome}
                    onChange={(key) => {
                      setHasFixedIncome(key);
                      setSpouseHasFixedIncome("No");
                    }}
                    customWidth={"100%"}
                    MenuCustomWidth={"100%"}
                    error={errors.hasFixedIncome}
                    tooltipContent={TooltipContent.fixedIncomeYesNo}
                  />
                )}
                {data.length >= 1 && hasFixedIncome != "Yes" && (
                  <Flex justifyContent={"flex-start"} marginTop={"20px"}>
                    <Button
                      bg="#27AE60"
                      w="172px"
                      h="37px"
                      align="flex-start"
                      onClick={() => {
                        setSpouseHasFixedIncome("No");
                        setHasFixedIncome("Yes");
                      }}
                      // disabled={!isFieldsValid}
                    >
                      <Text
                        color={"#FFFFFF"}
                        fontFamily={"Nunito"}
                        fontSize={"14px"}
                        fontStyle={"normal"}
                        fontWeight={"700"}
                        lineHeight={"normal"}
                      >
                        Add
                      </Text>
                    </Button>
                  </Flex>
                )}
                {hasFixedIncome === "Yes" && (
                  <OtherSourceDetailsMobile
                    onCancel={handleCancelSpouse1}
                    fixedIncomeName={fixedIncomeName}
                    setFixedIncomeName={setFixedIncomeName}
                    interestType={interestType}
                    setInterestType={setInterestType}
                    principleAmount={principleAmount}
                    setPrincipleAmount={setPrincipleAmount}
                    maturityDate={maturityDate}
                    setMaturityDate={setMaturityDate}
                    purchaseDate={purchaseDate}
                    setPurchaseDate={setPurchaseDate}
                    annualGrowth={annualGrowth}
                    setAnnualGrowth={setAnnualGrowth}
                    currentAge={currentAge}
                    Select={Select}
                    renderAddUpdate={renderAddUpdate}
                    fixedIncomeArray={fixedIncomeArray}
                    moneyToNumber={moneyToNumber}
                    errors={errors}
                  />
                )}
              </VStack>
            }
            videoName={"Fixed Income Page"}
            video={"Fixed_Income_Page.mp4"}
          />

          {spouseStatus && (
            <GlobalBodyPage
              mainframeContent={
                <VStack spacing={4} alignItems={"flex-start"} width={"100%"}>
                  <Flex justify="space-between" w="100%">
                    <Heading
                      height={"27px"}
                      alignSelf={"stretch"}
                      color={"#00466D"}
                      fontFamily={"Nunito"}
                      fontSize={"20px"}
                      fontStyle={"normal"}
                      fontWeight={"700"}
                      lineHeight={"normal"}
                    >
                      {spouseFirstName} {spouseLastName}
                    </Heading>
                  </Flex>
                  {spouseData.length > 0 && spouseHasFixedIncome != "Yes" && (
                    <TableComponent
                      columns={columns}
                      data={spouseData}
                      editField={editField}
                      onDeleteRow={onDeleteRow}
                      rawData={spouseFixedIncomeArray}
                    />
                  )}
                  {spouseHasFixedIncome != "Yes" && spouseData.length < 1 && (
                    <DropDownButton
                      Heading={
                        "Do you have any fixed income non-registered investments?"
                      }
                      placeholder={"Select"}
                      dropDownItems={[
                        { key: "Yes", value: "Yes" },
                        { key: "No", value: "No" },
                      ]}
                      value={spouseHasFixedIncome}
                      onChange={(key) => {
                        setSpouseHasFixedIncome(key);
                        setHasFixedIncome("No");
                      }}
                      customWidth={"100%"}
                      MenuCustomWidth={"100%"}
                      error={spouseErrors.hasFixedIncome}
                      tooltipContent={TooltipContent.fixedIncomeYesNo}
                    />
                  )}
                  {spouseData.length >= 1 && spouseHasFixedIncome != "Yes" && (
                    <Flex justifyContent={"flex-start"} marginTop={"20px"}>
                      <Button
                        bg="#27AE60"
                        w="172px"
                        h="37px"
                        align="flex-start"
                        onClick={() => {
                          setSpouseHasFixedIncome("Yes");
                          setHasFixedIncome("No");
                        }}
                        // disabled={!isFieldsValid}
                      >
                        <Text
                          color={"#FFFFFF"}
                          fontFamily={"Nunito"}
                          fontSize={"14px"}
                          fontStyle={"normal"}
                          fontWeight={"700"}
                          lineHeight={"normal"}
                        >
                          Add
                        </Text>
                      </Button>
                    </Flex>
                  )}
                  {spouseHasFixedIncome === "Yes" && (
                    <OtherSourceDetailsMobile
                      onCancel={handleCancelSpouse2}
                      fixedIncomeName={spouseFixedIncomeName}
                      setFixedIncomeName={setSpouseFixedIncomeName}
                      interestType={spouseInterestType}
                      setInterestType={setSpouseInterestType}
                      principleAmount={spousePrincipleAmount}
                      setPrincipleAmount={setSpousePrincipleAmount}
                      maturityDate={spouseMaturityDate}
                      setMaturityDate={setSpouseMaturityDate}
                      purchaseDate={spousePurchaseDate}
                      setPurchaseDate={setSpousePurchaseDate}
                      annualGrowth={spouseAnnualGrowth}
                      setAnnualGrowth={setSpouseAnnualGrowth}
                      currentAge={spouseAge}
                      Select={Select}
                      renderAddUpdate={renderAddUpdate}
                      fixedIncomeArray={spouseFixedIncomeArray}
                      moneyToNumber={moneyToNumber}
                      errors={errors}
                    />
                  )}
                </VStack>
              }
              videoName={"Fixed Income Page"}
              video={"Fixed_Income_Page.mp4"}
            />
          )}
        </VStack>
      )}
    </Flex>
  );
});

function OtherSourceDetails({
  onCancel,
  fixedIncomeName,
  setFixedIncomeName,
  interestType,
  setInterestType,
  principleAmount,
  setPrincipleAmount,
  maturityDate,
  setMaturityDate,
  purchaseDate,
  setPurchaseDate,
  annualGrowth,
  setAnnualGrowth,
  currentAge,
  Select,
  renderAddUpdate,
  fixedIncomeArray,
  moneyToNumber,
  errors,
}) {
  return (
    <Flex flexDirection={"column"} gap={"10px"} w={"100%"}>
      <Flex justifyContent={"flex-end"}>
        <Button
          bg="#FFE5E5"
          w="73px"
          h="31px"
          align="flex-end"
          onClick={onCancel}
        >
          <Text
            color={"#E71D36"}
            fontFamily={"Nunito"}
            fontSize={"14px"}
            fontStyle={"normal"}
            fontWeight={"700"}
            lineHeight={"normal"}
          >
            Cancel
          </Text>
        </Button>
      </Flex>
      <NormalInputButton
        Heading={"Name/Title"}
        placeholder={""}
        customWidth={"100%"}
        InputWidth={"100%"}
        value={fixedIncomeName}
        onChange={(e) => {
          setFixedIncomeName(e.target.value);
        }}
        error={errors.fixedIncomeName}
        tooltipContent={TooltipContent.fixedIncomeName}
      />

      <Flex justify="space-between" w="full" alignItems="flex-end">
        <NormalInputButton
          Heading="Principle amount"
          placeholder="$"
          value={principleAmount}
          onChange={(e) => {
            setPrincipleAmount(moneyToNumber(e.target.value));
          }}
          useThousandSeparator={true}
          error={errors.principleAmount}
          tooltipContent={TooltipContent.fixedIncomePrincipleAmount}
        />
        <NormalInputButton
          Heading="Interest amount"
          placeholder="%"
          value={annualGrowth}
          onChange={(e) => {
            const noSymbol = e.target.value.replace("%", "");
            const growthNum = parseFloat(noSymbol);
            setAnnualGrowth(
              !isNaN(growthNum) ? parseFloat(growthNum.toFixed(2)) : ""
            );
          }}
          //   customWidth={"100%"}
          MenuCustomWidth={"100%"}
          usePercentage={true}
          error={errors.annualGrowth}
          tooltipContent={TooltipContent.fixedIncomeInterest}
        />
      </Flex>
      <Flex justify="space-between" w="full" alignItems="flex-end">
        <DropDownButton
          Heading={"Interest type"}
          placeholder={"Select"}
          dropDownItems={[
            { key: "Compound", value: "Compound" },
            { key: "Simple", value: "Simple" },
          ]}
          value={interestType}
          onChange={(key) => {
            setInterestType(key);
          }}
          MenuCustomWidth={"100%"}
          error={errors.interestType}
          tooltipContent={TooltipContent.fixedIncomeInterestType}
        />
        <DatePickerButton
          Heading="Purchase Date"
          placeholder="xx/xx/xxxx"
          value={purchaseDate}
          onChange={(e) => {
            setPurchaseDate(e.target.value);
          }}
          maxYear={2024}
          error={errors.purchaseDate}
          tooltipContent={TooltipContent.fixedIncomeStart}
        />
      </Flex>
      <Flex justify="space-between" w="full" alignItems="flex-end">
        {purchaseDate && (
          <DatePickerButton
            Heading={"Maturity date"}
            placeholder="xx/xx/xxxx"
            value={maturityDate}
            onChange={(e) => {
              setMaturityDate(e.target.value);
            }}
            MenuCustomWidth={"100%"}
            maxYear={2035}
            startingYear={1}
            error={errors.maturityDate}
            tooltipContent={TooltipContent.fixedIncomeMaturityDate}
          />
        )}
      </Flex>
      {renderAddUpdate(fixedIncomeArray)}
    </Flex>
  );
}

function OtherSourceDetailsMobile({
  onCancel,
  fixedIncomeName,
  setFixedIncomeName,
  interestType,
  setInterestType,
  principleAmount,
  setPrincipleAmount,
  maturityDate,
  setMaturityDate,
  purchaseDate,
  setPurchaseDate,
  annualGrowth,
  setAnnualGrowth,
  currentAge,
  Select,
  renderAddUpdate,
  fixedIncomeArray,
  moneyToNumber,
  errors,
}) {
  return (
    <Flex flexDirection={"column"} gap={"10px"} w={"100%"}>
      <Flex justifyContent={"flex-end"}>
        <Button
          bg="#FFE5E5"
          w="73px"
          h="31px"
          align="flex-end"
          onClick={onCancel}
        >
          <Text
            color={"#E71D36"}
            fontFamily={"Nunito"}
            fontSize={"14px"}
            fontStyle={"normal"}
            fontWeight={"700"}
            lineHeight={"normal"}
          >
            Cancel
          </Text>
        </Button>
      </Flex>
      <NormalInputButton
        Heading={"Name/Title"}
        placeholder={""}
        customWidth={"100%"}
        InputWidth={"100%"}
        value={fixedIncomeName}
        onChange={(e) => {
          setFixedIncomeName(e.target.value);
        }}
        error={errors.fixedIncomeName}
        tooltipContent={TooltipContent.fixedIncomeName}
      />
      <Flex justify="space-between" w="full" alignItems="flex-end">
        <NormalInputButton
          Heading="Principle amount"
          placeholder=""
          customWidth={"100%"}
          InputWidth={"100%"}
          value={principleAmount}
          onChange={(e) => {
            setPrincipleAmount(moneyToNumber(e.target.value));
          }}
          useThousandSeparator={true}
          error={errors.principleAmount}
          tooltipContent={TooltipContent.fixedIncomePrincipleAmount}
        />
      </Flex>
      <Flex justify="space-between" w="full" alignItems="flex-end">
        <NormalInputButton
          Heading="Interest amount"
          placeholder="%"
          value={annualGrowth}
          onChange={(e) => {
            const noSymbol = e.target.value.replace("%", "");
            const growthNum = parseFloat(noSymbol);
            setAnnualGrowth(
              !isNaN(growthNum) ? parseFloat(growthNum.toFixed(2)) : ""
            );
          }}
          customWidth={"100%"}
          usePercentage={true}
          error={errors.annualGrowth}
          InputWidth={"100%"}
          tooltipContent={TooltipContent.fixedIncomeInterest}
        />
      </Flex>
      <Flex justify="space-between" w="full" alignItems="flex-end">
        <DropDownButton
          Heading={"Interest type"}
          placeholder={"Select"}
          dropDownItems={[
            { key: "Compound", value: "Compound" },
            { key: "Simple", value: "Simple" },
          ]}
          value={interestType}
          onChange={(key) => {
            setInterestType(key);
          }}
          MenuCustomWidth={"100%"}
          error={errors.interestType}
          tooltipContent={TooltipContent.fixedIncomeInterestType}
        />
      </Flex>
      <Flex justify="space-between" w="full" alignItems="flex-end">
        <DatePickerButton
          Heading="Purchase Date"
          placeholder="xx/xx/xxxx"
          value={purchaseDate}
          onChange={(e) => {
            setPurchaseDate(e.target.value);
          }}
          maxYear={2024}
          error={errors.purchaseDate}
          tooltipContent={TooltipContent.fixedIncomeStart}
        />
      </Flex>
      <Flex justify="space-between" w="full" alignItems="flex-end">
        {purchaseDate && (
          <DatePickerButton
            Heading={"Maturity date"}
            placeholder="xx/xx/xxxx"
            value={maturityDate}
            onChange={(e) => {
              setMaturityDate(e.target.value);
            }}
            MenuCustomWidth={"100%"}
            maxYear={2035}
            startingYear={1}
            error={errors.maturityDate}
            tooltipContent={TooltipContent.fixedIncomeMaturityDate}
          />
        )}
      </Flex>

      {renderAddUpdate(fixedIncomeArray)}
    </Flex>
  );
}

export default FixedIncomePage;
