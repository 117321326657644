import React, { useState, useEffect } from 'react';
import ReactEcharts from 'echarts-for-react';
import FilterIcon from '../img/filter-icon.png';

const currencyFormat = (money) => {
  if (money === 0) {
      return '$0'; // Handle zero explicitly
  } else if (money === '') {
      return '';
  } else {
      const intTotal = Math.round(Math.round(money * 100) / 100);
      const currencyCad = new Intl.NumberFormat('en-US', {
          style: 'currency',
          currency: 'USD',
          minimumFractionDigits: 0,
      }).format(intTotal);
      return currencyCad;
  }
};


function TaxLineGraph(props) {
const {
 title,
 provincialData,
 federalData,
 cppData,
 eiData,
 currentAge,
 spouseAge,
//  deductionData,
 totalPortfolioData,
} = props;

console.log("THIS WHY? ", provincialData)
const xAxis = provincialData?.map((item) => item.x);
const xAxisWithAge = xAxis?.map((year) => {
  const currentYear = new Date().getFullYear();
  const age = currentAge + (year - currentYear);
  const spouse = spouseAge ? spouseAge + (year - currentYear) : "";
  return spouseAge ? `${year}\n(${age} | ${spouse})` : `${year}\n(${age})`;
});
const yAxisProvincial = provincialData?.map((item) => item.y);
const yAxisFederal = federalData?.map((item) => item.y);
const yAxisCPP = cppData?.map((item) => item.y);
const yAxisEI = eiData?.map((item) => item.y);
// const yAxisDeductions = deductionData?.map((item) => item.y);
const yAxisTotalPortfolio = totalPortfolioData?.map((item) => item.y);

const [showDropdown, setShowDropdown] = useState(false);
const [showAll, setShowAll] = useState(true);
const [showProvincial, setShowProvincial] = useState(false);
const [showFederal, setShowFederal] = useState(false);
const [showCPP, setShowCPP] = useState(false);
const [showEI, setShowEI] = useState(false)
// const [showDeductions, setShowDeductions] = useState(false)
const [showTotal, setShowTotal] = useState(false);
const [colorScheme, setColorScheme] = useState(['#73B879', '#00466D', '#00A2D4', '#007EB5', '#30AB9D']);

useEffect(() => {
    if (showProvincial) setColorScheme(['#73B879']);
    if (showFederal) setColorScheme(['#00466D']);
    if (showCPP) setColorScheme(['#2BD900']);
    if (showEI) setColorScheme(['#007EB5'])
    // if (showDeductions) setColorScheme(['#30AB9D'])
    if (showTotal) setColorScheme(['#00A2D4']);
    if (showAll) setColorScheme(['#73B879', '#00466D', '#00A2D4', '#007EB5', '#30AB9D']);
}, [showProvincial, showFederal, showCPP, showTotal, showAll, showEI]);
// showDeductions

function numFormatter(num) {  
  if (num > 999 && num < 10000){
    return '$'+(num/1000).toFixed(1) + 'K';
  }
  else if (num > 9999 && num < 1000000){
    return '$'+(num/1000).toFixed(0) + 'K';
  }
  else if (num > 1000000){
    return '$'+(num/1000000).toFixed(1) + 'M';
  } 
  else if (num < 900){
      return '$'+num;
  }
}

const headers = [
  { name: 'Provincial', show: (provincialData?.some(obj => obj.y !== 0)) },
  { name: 'Federal', show: (federalData?.some(obj => obj.y !== 0)) },
  { name: 'CPP/QPP Contributions', show: (cppData?.some(obj => obj.y !== 0)) },
  { name: 'EI Premiums', show: (eiData?.some(obj => obj.y !== 0)) },
  // { name: 'Deductions', show: (deductionData?.some(obj => obj.y > 1)) },
  { name: 'Total', show: (totalPortfolioData?.some(obj => obj.y !== 0)) }
];

const dataToRender = headers.filter(header => header.show)?.map(header => header.name)

const option = {
  color: colorScheme,
  title: {
    text: window.innerWidth <=500 ? "Overview":title,
    left: 'center', 
    top: '0%',
    textStyle: {
      lineHeight: window.innerWidth <= 480 && !showAll ? -30 : null
    }
  },
  tooltip: {
    trigger: 'axis',
       axisPointer: {
        type: 'shadow', // Use 'line' for X-axis
    },
    formatter: function(params) {
      let result = params[0].axisValueLabel + '<br/>';
      params.forEach(function (item) {
        result += item.marker + ' ' + item.seriesName + ': ' + currencyFormat(item.data) + '<br/>';
      });
      return result;
    }
  },
  legend: {
    data: dataToRender,
    top: '10%',
    selectedMode: false,
    top: 25,
    show: window.innerWidth <= 480 && showAll ? false : true
  },
  toolbox: {
    feature: {
      saveAsImage: {
        iconStyle: {
          borderColor: "#004069",
        },
      },
    },
  },
  grid: {
    left: 3,
    right: 3,
    bottom: '0%',
    containLabel: true,
  },
  xAxis: [
    {
      type: 'category',
      boundaryGap: ['20%', '20%'],
      data: xAxisWithAge ? xAxisWithAge : [],
    },
  ],
  yAxis: [
    {
      type: 'value',
      axisLabel: {
        formatter: function(value) {
          return numFormatter(value);
        }
      }
    },
],
  series: [
    (showProvincial || showAll) && {
      name: 'Provincial',
      type: 'bar',
      itemStyle: {
        borderRadius: !showAll ? [5, 5, 0, 0]: '',
      },
      stack: 'Total',
      smooth: true,
      areaStyle: {
        color: {
            type: 'linear',
            x: 0,
            y: 0,
            x2: 0,
            y2: 1,
            colorStops: [{
                offset: 0, color: '#73B879', // color at 0%
            }, {
                offset: 1, color: '#73B879', // color at 100%
            }],
            global: false, // default is false
          },
      },
      emphasis: {
        focus: 'series',
      },
      data: yAxisProvincial,
    },
    (showFederal || showAll) && {
      name: 'Federal',
      type: 'bar',
      itemStyle: {
        borderRadius: !showAll ? [5, 5, 0, 0]: '',
      },
      stack: 'Total',
      smooth: true,
      areaStyle: {
        color: {
            type: 'linear',
            x: 0,
            y: 0,
            x2: 0,
            y2: 1,
            colorStops: [{
                offset: 0, color: '#00466D', // color at 0%
            }, {
                offset: 1, color: '#00466D', // color at 100%
            }],
            global: false, // default is false
          },
      },
      emphasis: {
        focus: 'series',
      },
      data: yAxisFederal,
    },
    (showCPP || showAll) && {
      name: 'CPP/QPP Contributions',
      type: 'bar',
      itemStyle: {
        borderRadius: !showAll ? [5, 5, 0, 0]: '',
      },
      stack: 'Total',
      smooth: true,
      areaStyle: {
        color: {
            type: 'linear',
            x: 0,
            y: 0,
            x2: 0,
            y2: 1,
            colorStops: [{
                offset: 0, color: '#00A2D4', // color at 0%
            }, {
                offset: 1, color: '#00A2D4', // color at 100%
            }],
            global: false, // default is false
          },
      },
      emphasis: {
        focus: 'series',
      },
      data: yAxisCPP,
    },
    (showEI || showAll) && {
      name: 'EI Premiums',
      type: 'bar',
      itemStyle: {
        borderRadius: !showAll ? [5, 5, 0, 0]: '',
      },
      stack: 'Total',
      smooth: true,
      areaStyle: {
        color: {
            type: 'linear',
            x: 0,
            y: 0,
            x2: 0,
            y2: 1,
            colorStops: [{
                offset: 0, color: '#007EB5', // color at 0%
            }, {
                offset: 1, color: '#007EB5', // color at 100%
            }],
            global: false, // default is false
          },
      },
      emphasis: {
        focus: 'series',
      },
      data: yAxisEI,
    },
    // ((showDeductions || showAll) && (deductionData?.some(obj => obj.y > 1))) && {
    //   name: 'Deductions',
    //   type: 'bar',
    //   stack: 'Total',
    //   smooth: true,
    //   areaStyle: {
    //     color: {
    //         type: 'linear',
    //         x: 0,
    //         y: 0,
    //         x2: 0,
    //         y2: 1,
    //         colorStops: [{
    //             offset: 0, color: '#30AB9D', // color at 0%
    //         }, {
    //             offset: 1, color: '#30AB9D', // color at 100%
    //         }],
    //         global: false, // default is false
    //       },
    //   },
    //   emphasis: {
    //     focus: 'series',
    //   },
    //   data: yAxisDeductions,
    // },
    showTotal && {
      name: 'Total',
      type: 'bar',
      itemStyle: {
        borderRadius: !showAll ? [5, 5, 0, 0]: '',
      },
      stack: 'Total',
      smooth: true,
      areaStyle: {
        color: {
            type: 'linear',
            x: 0,
            y: 0,
            x2: 0,
            y2: 1,
            colorStops: [{
                offset: 0, color: '#2BD900', // color at 0%
            }, {
                offset: 1, color: '#F1EDFF', // color at 100%
            }],
            global: false, // default is false
        },
      },
      emphasis: {
        focus: 'series',
      },
      data: yAxisTotalPortfolio,
    },
  ],
};
const eChartsRef = React.useRef(0);

function selectGraph(graph) {
    if (graph === 'Total') { setShowTotal(true); setShowAll(false); } else setShowTotal(false);
    if (graph === 'Provincial') { setShowProvincial(true); setShowAll(false); } else setShowProvincial(false);
    if (graph === 'Federal') { setShowFederal(true); setShowAll(false); } else setShowFederal(false);
    if (graph === 'CPP/QPP Contributions') { setShowCPP(true); setShowAll(false); } else setShowCPP(false);
    if (graph === 'EI Premiums') { setShowEI(true); setShowAll(false); } else setShowEI(false);
    // if (graph === 'Deductions') { setShowDeductions(true); setShowAll(false); } else setShowDeductions(false);
    if (graph === 'All') { setShowAll(true); } else setShowAll(false);
}

  return (
    <div
    className="dashboard-graph-container"
    style={{ border: "none", borderRadius: "none", boxShadow: "none", padding: '0'}}
  >
      <div
        tabIndex={0}
        className="dropdown"
        onClick={() => setShowDropdown(!showDropdown)}
        onBlur={() => setShowDropdown(showDropdown)}
      >
        <img src={FilterIcon} className="filter-icon" alt="filter-icon" />
        {showDropdown && (
        <div className="dropdown-items">
          <ul>
            <div>Display</div>
            <li className={showTotal ? 'dropdown-item-selected' : undefined} onClick={() => selectGraph('Total')}>Total Taxes</li>
            <li className={showAll ? 'dropdown-item-selected' : undefined} onClick={() => selectGraph('All')}>All</li>
            {(provincialData?.some(obj => obj.y !== 0)) && <li className={showProvincial ? 'dropdown-item-selected' : undefined} onClick={() => selectGraph('Provincial')}>Provincial</li>}
            {(federalData?.some(obj => obj.y !== 0)) && <li className={showFederal ? 'dropdown-item-selected' : undefined} onClick={() => selectGraph('Federal')}>Federal</li>}
            {(cppData?.some(obj => obj.y !== 0)) && <li className={showCPP ? 'dropdown-item-selected' : undefined} onClick={() => selectGraph('CPP/QPP Contributions')}>CPP/QPP Contributions</li>}
            {(eiData?.some(obj => obj.y !== 0)) && <li className={showEI ? 'dropdown-item-selected' : undefined} onClick={() => selectGraph('EI Premiums')}>EI Premiums</li>}
            {/* {(deductionData?.some(obj => obj.y !== 0)) && <li className={showDeductions ? 'dropdown-item-selected' : undefined} onClick={() => selectGraph('Deductions')}>Deductions</li>} */}
          </ul>
        </div>
)}
      </div>
      <ReactEcharts ref={eChartsRef} option={option} className="portfolio-balance-chart" notMerge />
    </div>

  );
}

export default TaxLineGraph;
