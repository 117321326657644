import React from "react";
import ReactEcharts from "echarts-for-react";
import { useMediaQuery } from "@chakra-ui/react";

function DepositsWithdrawalsGraph({
  tfsaDeposits,
  tfsaWithdrawals,
  fhsaDeposits,
  fhsaWithdrawals,
  rrspDeposits,
  rrspWithdrawals,
  nonRegDeposits,
  nonRegWithdrawals,
  liraWithdrawals,
  cashWithdrawals,
  fixedWithdrawals,
  pensionDeposits,
  eligibleDividends,
  nonEligibleDividends,
  capitalDividends,
  loanRepayments,
  height,
}) {
  // Define a helper function to check if a value is effectively zero (including null, undefined, and NaN)
  const isZero = (value) => {
    return value === null || value === undefined || isNaN(value) || value === 0;
  };

  // Prepare the data for deposits and withdrawals
  const depositWithdrawalData = [
    { name: "TFSA", deposit: Math.round(tfsaDeposits), withdrawal: Math.round(tfsaWithdrawals) },
    { name: "FHSA", deposit: Math.round(fhsaDeposits), withdrawal: Math.round(fhsaWithdrawals) },
    { name: "RRSP", deposit: Math.round(rrspDeposits), withdrawal: Math.round(rrspWithdrawals) },
    { name: "Non-Reg", deposit: Math.round(nonRegDeposits), withdrawal: Math.round(nonRegWithdrawals) },
    { name: "LIRA", deposit: 0, withdrawal: Math.round(liraWithdrawals) },
    { name: "CASH", deposit: 0, withdrawal: Math.round(cashWithdrawals) },
    { name: "Pension", deposit: Math.round(pensionDeposits), withdrawal: 0 },
    { name: "Fixed Income", deposit: 0, withdrawal: Math.round(fixedWithdrawals) },
    { name: "Eligible", deposit: 0, withdrawal: Math.round(eligibleDividends) },
    { name: "Non-Eligible", deposit: 0, withdrawal: Math.round(nonEligibleDividends) },
    { name: "Capital", deposit: 0, withdrawal: Math.round(capitalDividends) },
    { name: "Loan Repayments", deposit: 0, withdrawal: Math.round(loanRepayments) },
  ].filter((item) => !isZero(item.deposit) || !isZero(item.withdrawal)); // Filter out entries with no effective data

  // Prepare the data for CCPC dividends and loan repayments
  const ccpcData = [
    { name: "Eligible", value: eligibleDividends },
    { name: "Non-Eligible", value: nonEligibleDividends },
    { name: "Capital", value: capitalDividends },
    { name: "Loan Repayments", value: loanRepayments },
  ].filter((item) => !isZero(item.value)); // Filter out entries with no effective data

  const currencyFormat = (value) => {
    if (isZero(value)) return ""; // Handle zero, null, or NaN values gracefully
    const formatted = new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD",
      minimumFractionDigits: 0,
    }).format(Math.abs(value));
    return value < 0 ? `-${formatted}` : formatted;
  };

  const [isLargerThan1200] = useMediaQuery(
    "(min-width: 500px) and (max-width: 1250px)"
  );

  const [isLargerThan500] = useMediaQuery("(min-width: 500px)");
  const isMobile = !isLargerThan500;

  // Option for deposits and withdrawals graph
  const depositWithdrawalOption = {
    tooltip: {
      trigger: "axis",
      axisPointer: { type: "shadow" },
      formatter: function (params) {
        let result = params[0].axisValue + "<br/>";
        params.forEach((item) => {
          result +=
            item.marker +
            " " +
            item.seriesName +
            ": " +
            currencyFormat(item.data) +
            "<br/>";
        });
        return result;
      },
    },
    grid: {
      left: "2%",
      right: "2%",
      bottom: "10%",
      containLabel: true,
    },
    graphic: [
      {
        type: "text",
        right: "center",
        top: 10, // Adjust as needed
        left: isMobile ? '40%':'45%',
        style: {
          text: "Deposits",
          textAlign: "center",
          fontFamily: "Nunito",
          fontSize: (isMobile ? "13px":"15px"),
          fontWeight: "800",
        },
      },
      {
        type: "text",
        right: "center",
        bottom: 0, // Adjust as needed
        left: ccpcData ? (isMobile ? "28%" :"35%") : (isMobile ? "40%" : "45%"),
        style: {
          text: ccpcData ? "Withdrawals/Dividends" : "Withdrawals",
          textAlign: "center",
          fontFamily: "Nunito",
          fontSize: (isMobile ? "13px" : "15px"),
          fontWeight: "800",
        },
      },
    ],
    xAxis: {
      type: "category",
      data: depositWithdrawalData.map((item) => item.name),
      axisLabel: {
        rotate: 45,
        interval: 0
      },
    },
    yAxis: {
      type: "value",
      axisLabel: {
        formatter: currencyFormat,
      },
      splitLine: {
        show: false, // This will remove the grid lines
      },
    },
    series: [
      {
        name: "Deposits",
        type: "bar",
        stack: "total",
        label: {
          show: true,
          position: "top",
          formatter: currencyFormat,
        },
        data: depositWithdrawalData.map((item) => item.deposit),
        itemStyle: {
          color: "#00A2D4",
          barBorderRadius: [10, 10, 0, 0], // Top-left, top-right, bottom-right, bottom-left
        },
      },
      {
        name: "Withdrawals",
        type: "bar",
        stack: "total",
        label: {
          show: true,
          position: "bottom",
          formatter: currencyFormat,
        },
        data: depositWithdrawalData.map((item) => -item.withdrawal),
        itemStyle: {
          color: "#51459E",
          barBorderRadius: [0, 0, 10, 10], // Top-left, top-right, bottom-right, bottom-left
        },
      },
    ],
  };

  // Option for CCPC dividends and loan repayments graph
  const ccpcOption = {
    title: {
      text: "CCPC Dividends Issued",
      left: "center",
      top: "5%",
      textStyle: {
        fontSize: 18,
        fontWeight: "bold",
      },
    },
    tooltip: {
      trigger: "axis",
      axisPointer: { type: "shadow" },
      formatter: function (params) {
        let result = params[0].axisValue + "<br/>";
        params.forEach((item) => {
          result +=
            item.marker +
            " " +
            item.seriesName +
            ": " +
            currencyFormat(item.data) +
            "<br/>";
        });
        return result;
      },
    },
    grid: {
      left: "2%",
      right: "2%",
      bottom: "2%",
      containLabel: true,
    },
    xAxis: {
      type: "category",
      data: ccpcData.map((item) => item.name),
      axisLabel: {
        rotate: 45,
        interval: 0
      },
    },
    yAxis: {
      type: "value",
      axisLabel: {
        formatter: currencyFormat,
      },
      splitLine: {
        show: false, // This will remove the grid lines
      },
    },
    series: [
      {
        name: "Dividends and Repayments",
        type: "bar",
        label: {
          show: true,
          position: "top",
          formatter: currencyFormat,
        },
        data: ccpcData.map((item) => item.value),
        itemStyle: {
          color: function (params) {
            const itemName = params.name;
            return itemName === "Eligible"
              ? "#FFA07A"
              : itemName === "Non-Eligible"
              ? "#FF6347"
              : itemName === "Capital"
              ? "#4682B4"
              : "#20B2AA";
          },
          barBorderRadius: [10, 10, 0, 0], // Top-left, top-right, bottom-right, bottom-left
        },
      },
    ],
  };

  // Decide which option to render based on the presence of data
  const option =
    depositWithdrawalData.length > 0 ? depositWithdrawalOption : ccpcOption;

  return (
    <ReactEcharts option={option} style={{ height: height, width: "100%" }} />
  );
}

export default DepositsWithdrawalsGraph;
