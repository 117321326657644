import { useAuth0 } from "@auth0/auth0-react";
import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import "../App.css";
import {
  getLogoutMultiplier,
  getUserArchiveInfo,
  getUserBusinessInfo,
  getUserCASHInfo,
  getUserCompanyInfo,
  getUserCottageInfo,
  getUserDebtInfo,
  getUserExpensesInfo,
  getUserFHSAInfo,
  getUserFixedIncomeInfo,
  getUserGoalInfo,
  getUserIncomeInfo,
  getUserInfo,
  getUserInheritanceInfo,
  getUserLandInfo,
  getUserLifeInsuranceInfo,
  getUserLifInfo,
  getUserLiraInfo,
  getUserLogin,
  getUserNonRegInvestmentsInfo,
  getUserNotificationInfo,
  getUserOptiData,
  getUserInsightsData,
  getUserOtherAssetsInfo,
  getUserOtherIncomeInfo,
  getUserPageLockInfo,
  getUserPensionInfo,
  getUserPrimaryResidenceInfo,
  getUserPropertiesInfo,
  getUserRRSPInfo,
  getUserShowDashVideo,
  getUserTFSAInfo,
  getUserWealthicaData,
} from "../config";
import { useStore } from "../store";
import CompassSpinner from "./CompassSpinner";
import MainScreen from "./MainScreen";
import { StripeContext } from "./StripeContext";
import VideoModal from "./VideoModal/VideoModal";
function Main() {
  const { getAccessTokenSilently } = useAuth0();

  const axiosInstance = axios.create();
  async function getToken() {
    // Replace this with your Auth0 token retrieval logic
    const token = await getAccessTokenSilently({
      audience:
        "https://os2lsiwff43wfpb5ggyawt6qri0mgcro.lambda-url.us-east-2.on.aws/",
      scope: "write:optimization",
    });
    return token;
  }

  // Add a request interceptor
  axiosInstance.interceptors.request.use(
    async (config) => {
      const token = await getToken();
      config.headers.Authorization = `Bearer ${token}`;
      return config;
    },
    (error) => {
      // Do something with request error
      return Promise.reject(error);
    }
  );
  const [newUser, setNewUser] = useState(false);
  const [loading, setLoading] = useState(true);
  const [isLoadingData, setIsLoadingData] = useState(true); // Added loading state
  const { stripePlan, setStripePlan } = useContext(StripeContext);
  const userInfo = useStore((store) => store.userInfo);
  const { loginWithRedirect, logout, user, isAuthenticated } = useAuth0();
  const setUserInfo = useStore((store) => store.setUserInfo);
  const setUserNotificationInfo = useStore(
    (store) => store.setUserNotificationInfo
  );
  const setUserIncomeInfo = useStore((store) => store.setUserIncomeInfo);
  const setUserLiraInfo = useStore((store) => store.setUserLiraInfo);
  const setUserLifInfo = useStore((store) => store.setUserLifInfo);
  const setUserExpensesInfo = useStore((store) => store.setUserExpensesInfo);
  const setUserRRSPInfo = useStore((store) => store.setUserRRSPInfo);
  const setUserFHSAInfo = useStore((store) => store.setUserFHSAInfo);
  const setUserTFSAInfo = useStore((store) => store.setUserTFSAInfo);
  const setUserNonRegInvestmentsInfo = useStore(
    (store) => store.setUserNonRegInvestmentsInfo
  );
  const setUserCASHInfo = useStore((store) => store.setUserCASHInfo);
  const setUserFixedIncomeInfo = useStore(
    (store) => store.setUserFixedIncomeInfo
  );
  const setUserOtherAssetsInfo = useStore(
    (store) => store.setUserOtherAssetsInfo
  );
  const setUserInheritanceInfo = useStore(
    (store) => store.setUserInheritanceInfo
  );
  const setUserOptiData = useStore((store) => store.setUserOptiData);
  const setUserInsightsData = useStore((store) => store.setUserInsightsData);
  const setUserWealthicaData = useStore((store) => store.setUserWealthicaData);
  // const setDashboardLayout = useStore((store) => store.setDashboardLayout);
  const setUserDebtInfo = useStore((store) => store.setUserDebtInfo);
  const setUserGoalInfo = useStore((store) => store.setUserGoalInfo);
  const setUserPensionInfo = useStore((store) => store.setUserPensionInfo);
  const setUserArchiveInfo = useStore((store) => store.setUserArchiveInfo);
  const setHideProgressBar = useStore((store) => store.setHideProgressBar);
  const setUserOtherIncomeInfo = useStore(
    (store) => store.setUserOtherIncomeInfo
  );
  const setUserPrimaryResidenceInfo = useStore(
    (store) => store.setUserPrimaryResidenceInfo
  );
  const setUserCottageInfo = useStore((store) => store.setUserCottageInfo);
  const setUserPropertiesInfo = useStore(
    (store) => store.setUserPropertiesInfo
  );
  const setUserBusinessInfo = useStore((store) => store.setUserBusinessInfo);
  const setUserCompanyInfo = useStore((store) => store.setUserCompanyInfo);
  const setUserLifeInsuranceInfo = useStore(
    (store) => store.setUserLifeInsuranceInfo
  );
  const setUserLandInfo = useStore((store) => store.setUserLandInfo);
  const setShowDashboardVideo = useStore(
    (store) => store.setShowDashboardVideo
  );
  const setUserPageLockInfo = useStore((store) => store.setUserPageLockInfo);
  const setLogoutMultiplier = useStore((store) => store.setLogoutMultiplier);

  const smallCards = [
    "estateBalance",
    "compassScore",
    "optimalCPPAge",
    "optimalOASAge",
    "averageTaxRate",
  ];
  const largeCards = [
    "income",
    "deposits",
    "withdrawals",
    "taxes",
    "budgetSummary",
    "portfolioBalance",
  ];
  const overallLayout = ["smallCards", "largeCards", "graph"];

  const [isVideoModalOpen, setIsVideoModalOpen] = useState(true);

  const closeVideoModal = () => {
    setIsVideoModalOpen(false);
  };
  useEffect(() => {
    setLoading(true);
    if (!newUser && (isAuthenticated || user)) {
      const promise0 = getUserLogin(user, axiosInstance);
      const promise1 = getUserInfo(user, axiosInstance);
      const promise2 = getUserIncomeInfo(user, axiosInstance);
      const promise3 = getUserLiraInfo(user, axiosInstance);
      const promise4 = getUserLifInfo(user, axiosInstance);
      const promise5 = getUserExpensesInfo(user, axiosInstance);
      const promise6 = getUserRRSPInfo(user, axiosInstance);
      const promise7 = getUserTFSAInfo(user, axiosInstance);
      const promise8 = getUserNonRegInvestmentsInfo(user, axiosInstance);
      const promise9 = getUserOtherAssetsInfo(user, axiosInstance);
      const promise10 = getUserOptiData(user, axiosInstance);
      const promise11 = getUserDebtInfo(user, axiosInstance);
      const promise12 = getUserOtherIncomeInfo(user, axiosInstance);
      const promise13 = getUserPensionInfo(user, axiosInstance);
      const promise14 = getUserPrimaryResidenceInfo(user, axiosInstance);
      const promise15 = getUserCottageInfo(user, axiosInstance);
      const promise16 = getUserPropertiesInfo(user, axiosInstance);
      const promise17 = getUserLifeInsuranceInfo(user, axiosInstance);
      const promise18 = getUserPageLockInfo(user, axiosInstance);
      const promise19 = getUserShowDashVideo(user, axiosInstance);
      const promise20 = getUserArchiveInfo(user, axiosInstance);
      const promise21 = getLogoutMultiplier(user, axiosInstance);
      const promise22 = getUserInheritanceInfo(user, axiosInstance);
      const promise23 = getUserCASHInfo(user, axiosInstance);
      const promise24 = getUserFixedIncomeInfo(user, axiosInstance);
      const promise25 = getUserBusinessInfo(user, axiosInstance);
      const promise26 = getUserWealthicaData(user, axiosInstance);
      const promise27 = getUserCompanyInfo(user, axiosInstance);
      const promise28 = getUserGoalInfo(user, axiosInstance);
      const promise29 = getUserNotificationInfo(user, axiosInstance);
      const promise30 = getUserLandInfo(user, axiosInstance);
      const promise31 = getUserFHSAInfo(user, axiosInstance);
      const promise32 = getUserInsightsData(user, axiosInstance);
      Promise.all([
        promise0,
        promise1,
        promise2,
        promise3,
        promise4,
        promise5,
        promise6,
        promise7,
        promise8,
        promise9,
        promise10,
        promise11,
        promise12,
        promise13,
        promise14,
        promise15,
        promise16,
        promise17,
        promise18,
        promise19,
        promise20,
        promise21,
        promise22,
        promise23,
        promise24,
        promise25,
        promise26,
        promise27,
        promise28,
        promise29,
        promise30,
        promise31,
        promise32,
      ])
        .then((values) => {
          if (values[0] !== null) {
            setNewUser(false);
            setUserInfo(values[1]);
            if (values[2] != null) setUserIncomeInfo(values[2]);
            if (values[3] != null) setUserLiraInfo(values[3]);
            if (values[4] != null) setUserLifInfo(values[4]);
            if (values[5] != null) setUserExpensesInfo(values[5]);
            if (values[6] != null) setUserRRSPInfo(values[6]);
            if (values[7] != null) setUserTFSAInfo(values[7]);
            if (values[8] != null) setUserNonRegInvestmentsInfo(values[8]);
            if (values[9] != null) setUserOtherAssetsInfo(values[9]);
            if (values[10] !== null) {
              setUserOptiData(values[10]);
              setHideProgressBar(true);
            }
            if (values[11] !== null) setUserDebtInfo(values[11]);
            if (values[12] !== null) setUserOtherIncomeInfo(values[12]);
            if (values[13] !== null) setUserPensionInfo(values[13]);
            if (values[14] !== null) setUserPrimaryResidenceInfo(values[14]);
            if (
              values[15] !== null &&
              (stripePlan?.items?.data?.[0]?.price?.lookup_key !=
                "OPTIML_ESSENTIALS" && stripePlan?.items?.data?.[0]?.price?.lookup_key !=
                "OPTIML_ESSENTIALS_M")
            )
              setUserCottageInfo(values[15]);
            if (
              values[16] !== null &&
              (stripePlan?.items?.data?.[0]?.price?.lookup_key === "PREMIUM" || stripePlan?.items?.data?.[0]?.price?.lookup_key === "PREMIUM_M")
            )
              setUserPropertiesInfo(values[16]);
            if (values[17] !== null) setUserLifeInsuranceInfo(values[17]);
            if (values[18] !== null) setUserPageLockInfo(values[18][0]);
            if (values[19] !== null) setShowDashboardVideo(values[19]);
            if (
              values[20] !== null &&
              (stripePlan?.items?.data?.[0]?.price?.lookup_key !=
                "OPTIML_ESSENTIALS" && stripePlan?.items?.data?.[0]?.price?.lookup_key !=
                "OPTIML_ESSENTIALS_M")
            )
              setUserArchiveInfo(values[20]);
            if (values[21] !== null) setLogoutMultiplier(values[21]);
            if (values[22] != null) setUserInheritanceInfo(values[22]);
            if (values[23] != null) setUserCASHInfo(values[23]);
            if (values[24] != null) setUserFixedIncomeInfo(values[24]);
            if (
              values[25] != null &&
              (stripePlan?.items?.data?.[0]?.price?.lookup_key === "PREMIUM" || stripePlan?.items?.data?.[0]?.price?.lookup_key === "PREMIUM_M")
            )
              setUserBusinessInfo(values[25]);
            if (
              values[26] != null &&
              (stripePlan?.items?.data?.[0]?.price?.lookup_key !=
                "OPTIML_ESSENTIALS" && stripePlan?.items?.data?.[0]?.price?.lookup_key !=
                "OPTIML_ESSENTIALS_M")
            )
              setUserWealthicaData(values[26]);
            if (
              values[27] != null &&
              (stripePlan?.items?.data?.[0]?.price?.lookup_key === "PREMIUM" || stripePlan?.items?.data?.[0]?.price?.lookup_key === "PREMIUM_M")
            )
              setUserCompanyInfo(values[27]);
            if (values?.[28] != null) setUserGoalInfo(values[28]);
            if (values?.[29] != null) setUserNotificationInfo(values[29]);
            if (
              values[30] !== null &&
              (stripePlan?.items?.data?.[0]?.price?.lookup_key !=
                "OPTIML_ESSENTIALS" && stripePlan?.items?.data?.[0]?.price?.lookup_key !=
                "OPTIML_ESSENTIALS_M")
            )
              setUserLandInfo(values[30]);
            if (values[31] !== null) setUserFHSAInfo(values[31]);
            if (
              values[32] != null &&
              (stripePlan?.items?.data?.[0]?.price?.lookup_key !=
                "OPTIML_ESSENTIALS" && stripePlan?.items?.data?.[0]?.price?.lookup_key !=
                "OPTIML_ESSENTIALS_M")
            )
              setUserInsightsData(values[32]);
          } else {
            setNewUser(true);
          }
          setTimeout(() => {
            setLoading(false);
            setIsLoadingData(false);
          }, 500);
        })
        .catch((error) => {
          console.log(error);
          setNewUser(true);
        });
    } else {
      if (isAuthenticated && user) {
        setUserInfo({
          ...userInfo,
          FirstName: "",
          LastName: "",
          spouseFirstName: "",
          spouseLastName: "",
          Email: user.email,
          spouseEmail: "",
          DateOfBirth: "",
          spouseDateOfBirth: "",
          Country: "Canada",
          Province: "",
          MaritalStatus: "single",
        });
        setTimeout(() => {
          setLoading(false);
          setIsLoadingData(false);
        }, 500);
      }
    }
  }, [isAuthenticated, user, newUser]);
  if (isLoadingData) {
    // Render a loading indicator while waiting for data
    return (
      <CompassSpinner
        loading={isLoadingData}
        className="Main-Compass-Spinner-Class"
        small={false}
      />
    );
  }
  return (
    <div>
      {!newUser ? (
        <div>
          {loading ? (
            <CompassSpinner
              loading={loading}
              className="Main-Compass-Spinner-Class"
              small={false}
            />
          ) : (
            <MainScreen newUser={newUser} />
          )}
        </div>
      ) : (
        <div>
          <VideoModal
            isOpen={isVideoModalOpen}
            closeModal={closeVideoModal}
            videoName={"Welcome.mp4"}
            title="Welcome Video"
          />
          {loading ? (
            <CompassSpinner
              loading={loading}
              className="Main-Compass-Spinner-Class"
              small={false}
            />
          ) : (
            <>
              <MainScreen newUser={newUser} />
            </>
          )}
        </div>
      )}
    </div>
  );
}
export default Main;
