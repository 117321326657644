import {
  Box,
  Button,
  Flex,
  HStack,
  Icon,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Text,
  Tooltip,
  useDisclosure,
  useMediaQuery,
} from "@chakra-ui/react";
import { DropDownIcon, InputBoxTitleBubbleTip } from "../CustomIcons";
function DropDownButton({
  Heading,
  placeholder,
  dropDownItems,
  value,
  onChange,
  customWidth,
  MenuCustomWidth,
  error,
  tooltipContent,
  disabled,
}) {
  const [isLargerThan768] = useMediaQuery("(min-width: 500px)");
  const isMobile = !isLargerThan768;

  const boxWidth = customWidth || (isMobile ? "100%" : "45%");
  const selectedItem = dropDownItems.find((item) => item.key === value);
  const { isOpen, onOpen, onToggle, onClose } = useDisclosure();
  return (
    <Box
      display={"flex"}
      width={boxWidth} // Fixed width of the outer container
      flexDirection={"column"}
      alignItems={"flex-start"}
      gap={"5px"}
    >
      {Heading && (
        <HStack
          spacing={"5px"} // Using HStack for horizontal stacking with gap handling
          alignItems={"flex-end"}
          w={customWidth || "100%"}
        >
          <Text
            color={"#00466D"}
            fontFamily={"Nunito"}
            fontSize={"16px"}
            fontStyle={"normal"}
            fontWeight={"600"}
            whiteSpace={"normal"} // Ensure text can wrap
            overflow={"hidden"} // Hide overflow text
            textOverflow={"ellipsis"} // Add ellipsis if text is too long
            width={"100%"} // Set max width to content width
            lineHeight={"normal"}
          >
            {Heading}
            {tooltipContent && (
              <Tooltip
                isOpen={isOpen}
                closeOnClick={true}
                padding={"10px"}
                borderRadius={"5px"}
                label={tooltipContent}
                placement={isMobile ? "auto-start" : "right"}
                hasArrow
              >
                <span
                  onClick={onToggle}
                  onMouseEnter={onOpen}
                  onMouseLeave={onClose}
                >
                  <Icon as={InputBoxTitleBubbleTip}></Icon>
                </span>
              </Tooltip>
            )}
          </Text>
        </HStack>
      )}

      <Box
        display="flex"
        gap="10px"
        alignSelf="stretch"
        borderRadius="5px"
        bg={error ? "#FFD2D2" : "#F5F5F5"} // Change background color on error
        border={error ? "1px solid #E53E3E" : "none"} // Optional: add border color on error
        alignContent="flex-start"
        maxW={
          MenuCustomWidth
            ? MenuCustomWidth
            : boxWidth === "45%"
            ? "100%"
            : "45%"
        }
      >
        <Menu>
          <MenuButton
            as={Button}
            w={"100%"}
            padding={0}
            fontFamily="Nunito"
            fontSize="14px"
            fontStyle="normal"
            fontWeight="400"
            lineHeight="normal"
          >
            <Flex justifyContent="space-between" alignItems="center" w="100%">
              <Text
                textAlign="left"
                padding={"15px 12px"}
                color={"#030229"}
                flexGrow={1}
                isTruncated
              >
                {selectedItem ? selectedItem.value : placeholder}
              </Text>

              <Icon as={DropDownIcon} />
            </Flex>
          </MenuButton>
          <MenuList
            maxHeight="300px" // Set a max height
            overflowY="auto"
            zIndex={100}
          >
            {dropDownItems.map((item) => (
              <MenuItem
                color={"#030229"}
                key={item.key}
                onClick={() => !disabled && onChange(item.key)}
                isDisabled={disabled}
                
              >
                {item.value}
              </MenuItem>
            ))}
          </MenuList>
        </Menu>
      </Box>
      {error && (
        <Text color="#E53E3E" fontSize="sm">
          {error}
        </Text> // Display error message
      )}
    </Box>
  );
}
export default DropDownButton;
