import React from "react";
import { PDFDownloadLink } from "@react-pdf/renderer";
import { Text as ChackText, Icon, Box, Spacer } from "@chakra-ui/react";
import { DownloadIcon } from "../CustomIcons";
import OptimlBetaIcon from "../../img/OPT Logo Colour.png";
import { useStore } from "../../store";
import {
  StyleSheet,
  Document,
  Page,
  Text,
  View,
  Image,
  Font,
} from "@react-pdf/renderer";

const fontNormal = require("../../assets/fonts/Nunito-Regular.ttf");
const fontBold = require("../../assets/fonts/Nunito-Bold.ttf");
Font.register({
  family: "Nunito",
  fonts: [
    {
      src: fontNormal, // Normal weight
    },
    {
      src: fontBold, // Bold weight
      fontWeight: "bold",
    },
  ],
});

const styles = StyleSheet.create({
  page: {
    flexDirection: "column",
    backgroundColor: "#FFFFFF",
    padding: 20, // 50px padding on all sides
    paddingLeft: 40,
    paddingRight: 40,
    fontFamily: "Nunito",
  },
  headerContainer: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: 20,
  },
  logo: {
    width: 130, // Set according to your logo's aspect ratio
    height: 60,
  },
  title: {
    fontSize: 10,
    fontWeight: 900,
    fontFamily: "Nunito",
  },
  subTitle: {
    fontSize: 12,
    marginBottom: 0,
    fontWeight: 500,
    fontFamily: "Nunito",
  },
  subHeading: {
    marginTop: 10,
    fontFamily: "Nunito",
    fontSize: 12,
    fontWeight: 900,
  },
  subText: {
    fontSize: 10,
    fontWeight: 500,
    marginTop: 10,
    marginBottom: 10,
    fontFamily: "Nunito",
  },
  subTextBold: {
    fontWeight: 800,
    fontSize: 12,
    fontFamily: "Nunito",
  },
  textWrap: {
    flexDirection: "row",
    alignItems: "flex-start",
    width: "100%",
  },
  row: {
    flexDirection: "row",
    flexWrap: "wrap",
    alignItems: "flex-start",
    justifyContent: "space-between",
    width: "100%",
  },
  section: {
    width: "33%", // Margin bottom for spacing between rows
    marginTop: 3,
    fontFamily: "Nunito",
  },
  textRow: {
    marginTop: 3,
    fontSize: 10,
    fontWeight: 500,
    fontFamily: "Nunito",
  },
  textRowHeader: {
    marginTop: 3,
    fontSize: 12,
    fontWeight: 900,
    fontFamily: "Nunito",
  },
  highlight: {
    color: "#0F9CD6",
    fontWeight: "bold",
    fontWeight: 500,
    fontFamily: "Nunito",
  },
  normalText: {
    color: "#0F9CD6",
    fontWeight: 500,
    fontFamily: "Nunito",
  },
  description: {
    marginTop: 3,
    fontSize: 8,
    fontWeight: 500,
    fontFamily: "Nunito",
  },
  graphImage: {
    width: "100%", // Adjust as needed based on your layout preferences
    height: "auto", // Match the height you've used in your web component or adjust as needed
    marginTop: 20,
    marginBottom: 15,
  },
  footerContainer: {
    flexDirection: "row",
    alignItems: "flex-end",
    justifyContent: "flex-end", // This will align the items to the right
    marginTop: 5,
    fontSize: 10,
    fontFamily: "Nunito",
  },
  footerContainer2: {
    flexDirection: "row",
    alignItems: "flex-start",
    marginTop: 3,
  },
  subMessage: {
    fontSize: 6,
    fontFamily: "Nunito",
  },
});
const MyDocument = ({
  spouse1Data,
  spouse2Data,
  logoUrl,
  firstName,
  lastName,
  spouseFirstName,
  spouseLastName,
  year,
  index,
  graphImage,
  spouseStatus,
  spouseGraphImage,
  userTFSAInfo,
  userFHSAInfo,
  userNonRegInvestmentsInfo,
  userFixedIncomeInfo,
  userRRSPInfo,
  userLiraInfo,
  userPensionInfo,
  userBusinessInfo,
}) => {
  const currentDate = new Date().toISOString().split("T")[0]; // Formats date as YYYY-MM-DD
  const currentYear = new Date().getFullYear();

  let filteredItems = userFixedIncomeInfo?.filter(
    (item) => item?.spouseCheck === 0
  );
  let totalInterest = filteredItems.reduce(
    (sum, item) => sum + (item?.fixedIncomeInterest || 0),
    0
  );
  let averageInterest =
    filteredItems?.length > 0 ? totalInterest / filteredItems?.length : 0;

  let filteredItemsSpouse = userFixedIncomeInfo?.filter(
    (item) => item?.spouseCheck === 1
  );
  let totalInterestSpouse = filteredItemsSpouse.reduce(
    (sum, item) => sum + (item?.fixedIncomeInterest || 0),
    0
  );

  let averageInterestSpouse =
    filteredItemsSpouse?.length > 0
      ? totalInterestSpouse / filteredItemsSpouse?.length
      : 0;

  let singleFoundItem = userPensionInfo?.find(
    (item) =>
      item?.spouseCheck === 0 && item?.Pension_Type != "Defined Benefit Plan"
  );
  let spouseFoundItem = userPensionInfo?.find(
    (item) =>
      item?.spouseCheck === 1 && item?.Pension_Type != "Defined Benefit Plan"
  );

  function formatDate(dateString) {
    const date = new Date(dateString);
    const options = { month: "long", day: "numeric", year: "numeric" };
    const formatted = date?.toLocaleDateString("en-US", options);

    const day = date?.getDate();
    let suffix = "th";
    if (day === 1 || day === 21 || day === 31) {
      suffix = "st";
    } else if (day === 2 || day === 22) {
      suffix = "nd";
    } else if (day === 3 || day === 23) {
      suffix = "rd";
    }

    const dayWithSuffix = day + suffix;

    return formatted?.replace(day?.toString(), dayWithSuffix);
  }

  function safeAdd(...values) {
    return values?.reduce((sum, val) => sum + (Number(val) || 0), 0);
  }

  const currencyFormat = (money) => {
    var intTotal = Math.round(Math.round(money * 100) / 100);
    if (intTotal > -2 && intTotal < 0.25) {
      intTotal = 0;
    }
    // Math.round(intTotal);
    var currencyCad = new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD",
      minimumFractionDigits: 0,
    }).format(intTotal);
    return currencyCad;
  };

  return (
    <Document>
      <Page size="A4" style={styles.page}>
        {/* Header Section */}
        <View style={styles.headerContainer}>
          <Image style={styles.logo} src={logoUrl} />
          <Text style={styles.title}>{year} Action Plan</Text>
        </View>

        {/* Subtitle Section */}
        {spouseStatus && (
          <View style={styles.textWrap}>
            <Text style={styles.subTextBold} as={"span"}>
              Prepared for:{" "}
            </Text>
            <Text style={styles.subTitle}>
              {firstName} {lastName} & {spouseFirstName} {spouseLastName}
            </Text>
          </View>
        )}
        {!spouseStatus && (
          <View style={styles.textWrap}>
            <Text style={styles.subTextBold} as={"span"}>
              Prepared for:{" "}
            </Text>
            <Text style={styles.subTitle}>
              {firstName} {lastName}
            </Text>
          </View>
        )}
        <View style={styles.textWrap}>
          <Text style={[styles.subTextBold]}>Date: </Text>
          <Text style={styles.subTitle}>{currentDate}</Text>
        </View>
        <View style={styles.textWrap}>
          <Text style={[styles.subTextBold]}>Analysis Date: </Text>
          <Text style={styles.subTitle}>
            {formatDate(spouse1Data?.planRunDate)}
          </Text>
        </View>
        <Text style={styles.subText}>
          This action plan provides a comprehensive overview of your deposits
          and withdrawals for {year}. To ensure you stay on track with your
          financial goals, it's important to focus on the following key areas
          this year:
        </Text>
        <Text style={styles.subTitle}>
          {firstName} {lastName}, this year you need to:
        </Text>
        {/* Withdrawal Sections */}
        {(spouse1Data?.sps1_cashWithdrawals?.length > 1 ||
          spouse1Data?.sps1_fhsaWithdrawals?.length > 1 ||
          spouse1Data?.sps1_tfsaWithdrawals?.length > 1 ||
          spouse1Data?.sps1_registeredWithdrawals?.length > 1 ||
          spouse1Data?.sps1_fixedIncomeWithdrawals?.length > 1 ||
          spouse1Data?.sps1_nonregisteredWithdrawals?.length > 1) && (
          <Text style={styles.subHeading}>WITHDRAWALS</Text>
        )}
        <View style={styles.row}>
          {spouse1Data?.sps1_tfsaWithdrawals?.[index] >= 50 && (
            <View style={styles.section}>
              <Text style={styles.textRowHeader}>TFSA</Text>
              <Text style={styles.textRow}>
                Withdraw{" "}
                <Text style={styles.highlight}>
                  {currencyFormat(spouse1Data?.sps1_tfsaWithdrawals?.[index])}
                </Text>{" "}
                from your <Text style={styles.highlight}>TFSA</Text>
              </Text>
              <Text style={styles.textRow}>
                <Text style={styles.highlight}>
                  {currencyFormat(
                    spouse1Data?.sps1_tfsaWithdrawals?.[index] / 12
                  )}
                </Text>{" "}
                per month
              </Text>
              <Text style={styles.description}>
                Expected annual growth rate: {userTFSAInfo?.TFSA_ROR}%
              </Text>
            </View>
          )}
          {spouse1Data?.sps1_fhsaWithdrawals?.[index] >= 50 && (
            <View style={styles.section}>
              <Text style={styles.textRowHeader}>FHSA</Text>
              <Text style={styles.textRow}>
                Withdraw{" "}
                <Text style={styles.highlight}>
                  {currencyFormat(spouse1Data?.sps1_fhsaWithdrawals?.[index])}
                </Text>{" "}
                from your <Text style={styles.highlight}>FHSA</Text>
              </Text>
              <Text style={styles.textRow}>
                <Text style={styles.highlight}>
                  {currencyFormat(spouse1Data?.sps1_fhsaWithdrawals?.[index])}
                </Text>{" "}
                in house purchase month
              </Text>
              <Text style={styles.description}>
                Expected annual growth rate: {userFHSAInfo?.fhsaGrowth}%
              </Text>
            </View>
          )}
          {spouse1Data?.sps1_registeredWithdrawals?.[index] >= 50 && (
            <View style={styles.section}>
              <Text style={styles.textRowHeader}>RRSP</Text>
              <Text style={styles.textRow}>
                Withdraw{" "}
                <Text style={styles.highlight}>
                  {currencyFormat(
                    spouse1Data?.sps1_registeredWithdrawals?.[index]
                  )}
                </Text>{" "}
                from your <Text style={styles.highlight}>RRSP</Text>
              </Text>
              <Text style={styles.textRow}>
                <Text style={styles.highlight}>
                  {currencyFormat(
                    spouse1Data?.sps1_registeredWithdrawals?.[index] / 12
                  )}
                </Text>{" "}
                per month
              </Text>
              <Text style={styles.description}>
                Expected annual growth rate: {userRRSPInfo?.RRSP_ROR}%
              </Text>
            </View>
          )}
          {spouse1Data?.sps1_nonregisteredWithdrawals?.[index] && (
            <View style={styles.section}>
              <Text style={styles.textRowHeader}>Non-Registered</Text>
              <Text style={styles.textRow}>
                Withdraw{" "}
                <Text style={styles.highlight}>
                  {currencyFormat(
                    spouse1Data?.sps1_nonregisteredWithdrawals?.[index]
                  )}
                </Text>{" "}
                from your <Text style={styles.highlight}>Non-Registered</Text>
              </Text>
              <Text style={styles.textRow}>
                <Text style={styles.highlight}>
                  {currencyFormat(
                    spouse1Data?.sps1_nonregisteredWithdrawals?.[index] / 12
                  )}
                </Text>{" "}
                per month
              </Text>
              <Text style={styles.description}>
                Expected annual growth rate:{" "}
                {userNonRegInvestmentsInfo?.Non_Reg_ROR}%
              </Text>
            </View>
          )}
          {spouse1Data?.sps1_cashWithdrawals?.[index] >= 50 && (
            <View style={styles.section}>
              <Text style={styles.textRowHeader}>Cash Savings</Text>
              <Text style={styles.textRow}>
                Withdraw{" "}
                <Text style={styles.highlight}>
                  {currencyFormat(spouse1Data?.sps1_cashWithdrawals?.[index])}
                </Text>{" "}
                from your <Text style={styles.highlight}>Cash Savings</Text>
              </Text>
              <Text style={styles.textRow}>
                <Text style={styles.highlight}>
                  {currencyFormat(
                    spouse1Data?.sps1_cashWithdrawals?.[index] / 12
                  )}
                </Text>{" "}
                per month
              </Text>
              {/* <Text style={styles.description}>
                Expected annual growth rate: 6%
              </Text> */}
            </View>
          )}
          {spouse1Data?.sps1_fixedIncomeWithdrawals?.[index] >= 50 && (
            <View style={styles.section}>
              <Text style={styles.textRowHeader}>Fixed Income</Text>
              <Text style={styles.textRow}>
                Withdraw{" "}
                <Text style={styles.highlight}>
                  {currencyFormat(
                    spouse1Data?.sps1_fixedIncomeWithdrawals?.[index]
                  )}
                </Text>{" "}
                from your <Text style={styles.highlight}>Fixed Income</Text>
              </Text>
              <Text style={styles.textRow}>
                <Text style={styles.highlight}>
                  {currencyFormat(
                    spouse1Data?.sps1_fixedIncomeWithdrawals?.[index] / 12
                  )}
                </Text>{" "}
                per month
              </Text>
              <Text style={styles.description}>
                Expected annual growth rate: {averageInterest}%
              </Text>
            </View>
          )}
          {safeAdd(
            spouse1Data?.ccpcYearlyCapitalDividendsIssued?.[index],
            spouse1Data?.ccpcYearlyEligibleDividendsIssued?.[index],
            spouse1Data?.ccpcYearlyNonEligibleDividendsIssued?.[index],
            spouse1Data?.ccpcYearlyShareholderLoanRepayments?.[index]
          ) >= 50 && (
            <View style={styles.section}>
              <Text style={styles.textRowHeader}>CCPC Dividends Issued</Text>
              {spouse1Data?.ccpcYearlyCapitalDividendsIssued?.[index] >= 50 && (
                <Text style={styles.textRow}>
                  Issue{" "}
                  <Text style={styles.highlight}>
                    {currencyFormat(
                      spouse1Data?.ccpcYearlyCapitalDividendsIssued?.[index]
                    )}
                  </Text>{" "}
                  as a <Text style={styles.highlight}>Capital Dividend</Text>
                </Text>
              )}
              {spouse1Data?.ccpcYearlyEligibleDividendsIssued?.[index] >=
                50 && (
                <Text style={styles.textRow}>
                  Issue{" "}
                  <Text style={styles.highlight}>
                    {currencyFormat(
                      spouse1Data?.ccpcYearlyEligibleDividendsIssued?.[index]
                    )}
                  </Text>{" "}
                  as a <Text style={styles.highlight}>Eligible Dividend</Text>
                </Text>
              )}
              {spouse1Data?.ccpcYearlyNonEligibleDividendsIssued?.[index] >=
                50 && (
                <Text style={styles.textRow}>
                  Issue{" "}
                  <Text style={styles.highlight}>
                    {currencyFormat(
                      spouse1Data?.ccpcYearlyNonEligibleDividendsIssued?.[index]
                    )}
                  </Text>{" "}
                  as a{" "}
                  <Text style={styles.highlight}>Non-Eligible Dividend</Text>
                </Text>
              )}
              {spouse1Data?.ccpcYearlyShareholderLoanRepayments?.[index] >=
                50 && (
                <Text style={styles.textRow}>
                  Issue{" "}
                  <Text style={styles.highlight}>
                    {currencyFormat(
                      spouse1Data?.ccpcYearlyShareholderLoanRepayments?.[index]
                    )}
                  </Text>{" "}
                  as a <Text style={styles.highlight}>Loan Repayment</Text>
                </Text>
              )}
              <Text style={styles.description}>
                Expected annual growth rate: {userBusinessInfo?.[0]?.ccpcGrowth}
                %
              </Text>
            </View>
          )}
          {spouse1Data?.sps2_liraWithdrawals?.[index] >= 50 && (
            <View style={styles.section}>
              <Text style={styles.textRowHeader}>LIRA</Text>
              <Text style={styles.textRow}>
                Withdraw{" "}
                <Text style={styles.highlight}>
                  {currencyFormat(spouse1Data?.sps2_liraWithdrawals?.[index])}
                </Text>{" "}
                from your <Text style={styles.highlight}>LIRA</Text>
              </Text>
              <Text style={styles.textRow}>
                <Text style={styles.highlight}>
                  {currencyFormat(
                    spouse1Data?.sps2_liraWithdrawals?.[index] / 12
                  )}
                </Text>{" "}
                per month
              </Text>
              <Text style={styles.description}>
                Expected annual growth rate: {userLiraInfo?.LIRA_ROR}%
              </Text>
            </View>
          )}
          {/* Repeat the above <View> for each section as necessary, adjusting data */}
        </View>
        {/* Deposit Sections */}
        {(spouse1Data?.sps1_tfsaContributions?.length > 1 ||
          spouse1Data?.sps1_fhsaContributions?.length > 1 ||
          spouse1Data?.sps1_registeredContributions?.length > 1 ||
          spouse1Data?.sps1_nonRegisteredContributions?.length > 1 ||
          spouse1Data?.sps1_pension?.EmployerContributions?.length > 1) && (
          <Text style={styles.subHeading}>DEPOSITS</Text>
        )}
        <View style={styles.row}>
          {spouse1Data?.sps1_tfsaContributions?.[index] >= 50 && (
            <View style={styles.section}>
              <Text style={styles.textRowHeader}>TFSA</Text>
              <Text style={styles.textRow}>
                Deposit{" "}
                <Text style={styles.highlight}>
                  {currencyFormat(spouse1Data?.sps1_tfsaContributions?.[index])}
                </Text>{" "}
                into your <Text style={styles.highlight}>TFSA</Text>
              </Text>
              <Text style={styles.textRow}>
                <Text style={styles.highlight}>
                  {currencyFormat(
                    spouse1Data?.sps1_tfsaContributions?.[index] / 12
                  )}
                </Text>{" "}
                per month
              </Text>
              <Text style={styles.description}>
                Expected annual growth rate: {userTFSAInfo?.TFSA_ROR}%
              </Text>
            </View>
          )}
          {spouse1Data?.sps1_fhsaContributions?.[index] >= 50 && (
            <View style={styles.section}>
              <Text style={styles.textRowHeader}>FHSA</Text>
              <Text style={styles.textRow}>
                Deposit{" "}
                <Text style={styles.highlight}>
                  {currencyFormat(spouse1Data?.sps1_fhsaContributions?.[index])}
                </Text>{" "}
                into your <Text style={styles.highlight}>FHSA</Text>
              </Text>
              <Text style={styles.textRow}>
                <Text style={styles.highlight}>
                  {currencyFormat(
                    spouse1Data?.sps1_fhsaContributions?.[index] / 12
                  )}
                </Text>{" "}
                per month
              </Text>
              <Text style={styles.description}>
                Expected annual growth rate: {userFHSAInfo?.fhsaGrowth}%
              </Text>
            </View>
          )}
          {safeAdd(
            spouse1Data?.sps1_registeredContributions?.[index],
            spouse1Data?.sps1_registeredContributionsMatched?.[index]
          ) >= 50 && (
            <View style={styles.section}>
              <Text style={styles.textRowHeader}>RRSP</Text>
              <Text style={styles.textRow}>
                Deposit{" "}
                <Text style={styles.highlight}>
                  {currencyFormat(
                    safeAdd(
                      spouse1Data?.sps1_registeredContributions?.[index],
                      spouse1Data?.sps1_registeredContributionsMatched?.[index]
                    )
                  )}
                </Text>{" "}
                into your <Text style={styles.highlight}>RRSP</Text>
              </Text>
              <Text style={styles.textRow}>
                <Text style={styles.highlight}>
                  {currencyFormat(
                    safeAdd(
                      spouse1Data?.sps1_registeredContributions?.[index] / 12,
                      spouse1Data?.sps1_registeredContributionsMatched?.[
                        index
                      ] / 12
                    )
                  )}
                </Text>{" "}
                per month
              </Text>
              <Text style={styles.description}>
                Expected annual growth rate: {userRRSPInfo?.RRSP_ROR}%
              </Text>
            </View>
          )}
          {spouse1Data?.sps1_nonRegisteredContributions?.[index] >= 50 && (
            <View style={styles.section}>
              <Text style={styles.textRowHeader}>Non-Registered</Text>
              <Text style={styles.textRow}>
                Deposit{" "}
                <Text style={styles.highlight}>
                  {currencyFormat(
                    spouse1Data?.sps1_nonRegisteredContributions?.[index]
                  )}
                </Text>{" "}
                into your <Text style={styles.highlight}>Non-Registered</Text>
              </Text>
              <Text style={styles.textRow}>
                <Text style={styles.highlight}>
                  {currencyFormat(
                    spouse1Data?.sps1_nonRegisteredContributions?.[index] / 12
                  )}
                </Text>{" "}
                per month
              </Text>
              <Text style={styles.description}>
                Expected annual growth rate:{" "}
                {userNonRegInvestmentsInfo?.Non_Reg_ROR}%
              </Text>
            </View>
          )}
          {spouse1Data?.sps1_pension?.EmployerContributions?.[index] >= 50 && (
            <View style={styles.section}>
              <Text style={styles.textRowHeader}>Pension</Text>
              <Text style={styles.textRow}>
                Contribute{" "}
                <Text style={styles.highlight}>
                  {currencyFormat(
                    spouse1Data?.sps1_pension?.EmployerContributions?.[index]
                  )}
                </Text>{" "}
                into your <Text style={styles.highlight}>Pension</Text>
              </Text>
              <Text style={styles.textRow}>
                <Text style={styles.highlight}>
                  {currencyFormat(
                    spouse1Data?.sps1_pension?.EmployerContributions?.[index] /
                      12
                  )}
                </Text>{" "}
                per month
              </Text>
              <Text style={styles.description}>
                Expected annual growth rate: {singleFoundItem?.Pension_Index}%
              </Text>
            </View>
          )}
          {/* Repeat the above <View> for each section as necessary, adjusting data */}
        </View>
        <View style={styles.row}>
          <Image src={graphImage} style={styles.graphImage} />
        </View>
        <View style={styles.footerContainer}>
          <Text>optiml.ca</Text>
        </View>
        <View style={styles.footerContainer2}>
          <Text style={styles.subMessage}>
            Oltre Financial Inc. operates under the trademark OPTIML FINTECH
            SOLUTIONS™ (“Optiml™“) and provides certain online goods and
            services software for personalized lifetime tax and estate
            optimization. WE DO NOT PROVIDE FINANCIAL OR INVESTMENT ADVICE. We
            refer to our service offerings as the “Service”, and to Optiml™ as
            we, us and our.
          </Text>
        </View>
      </Page>
      {/* SPOUSE DATA */}
      {spouseStatus && (
        <Page size="A4" style={styles.page}>
          <Text style={styles.subTitle}>
            {spouseFirstName} {spouseLastName}, this year you need to:
          </Text>
          {/* Withdrawal Sections */}
          {(spouse2Data?.sps2_tfsaWithdrawals?.[index] >= 50 ||
            spouse2Data?.sps2_cashWithdrawals?.[index] >= 50 ||
            spouse2Data?.sps2_fhsaWithdrawals?.[index] >= 50 ||
            spouse2Data?.sps2_liraWithdrawals?.[index] >= 50 ||
            spouse2Data?.sps2_registeredWithdrawals?.[index] >= 50 ||
            spouse2Data?.sps2_fixedIncomeWithdrawals?.[index] >= 50 ||
            spouse2Data?.sps2_nonregisteredWithdrawals?.[index] >= 50) && (
            <Text style={styles.subHeading}>WITHDRAWALS</Text>
          )}
          <View style={styles.row}>
            {spouse2Data?.sps2_tfsaWithdrawals?.[index] >= 50 && (
              <View style={styles.section}>
                <Text style={styles.textRowHeader}>TFSA</Text>
                <Text style={styles.textRow}>
                  Withdraw{" "}
                  <Text style={styles.highlight}>
                    {currencyFormat(spouse2Data?.sps2_tfsaWithdrawals?.[index])}
                  </Text>{" "}
                  from your <Text style={styles.highlight}>TFSA</Text>
                </Text>
                <Text style={styles.textRow}>
                  <Text style={styles.highlight}>
                    {currencyFormat(
                      spouse2Data?.sps2_tfsaWithdrawals?.[index] / 12
                    )}
                  </Text>{" "}
                  per month
                </Text>
                <Text style={styles.description}>
                  Expected annual growth rate: {userTFSAInfo?.TFSA_ROR}%
                </Text>
              </View>
            )}
            {spouse2Data?.sps2_fhsaWithdrawals?.[index] >= 50 && (
              <View style={styles.section}>
                <Text style={styles.textRowHeader}>FHSA</Text>
                <Text style={styles.textRow}>
                  Withdraw{" "}
                  <Text style={styles.highlight}>
                    {currencyFormat(spouse2Data?.sps2_fhsaWithdrawals?.[index])}
                  </Text>{" "}
                  from your <Text style={styles.highlight}>FHSA</Text>
                </Text>
                <Text style={styles.textRow}>
                  <Text style={styles.highlight}>
                    {currencyFormat(spouse2Data?.sps2_fhsaWithdrawals?.[index])}
                  </Text>{" "}
                  in house purchase month
                </Text>
                <Text style={styles.description}>
                  Expected annual growth rate: {userFHSAInfo?.spouseFhsaGrowth}%
                </Text>
              </View>
            )}
            {spouse2Data?.sps2_registeredWithdrawals?.[index] >= 50 && (
              <View style={styles.section}>
                <Text style={styles.textRowHeader}>RRSP</Text>
                <Text style={styles.textRow}>
                  Withdraw{" "}
                  <Text style={styles.highlight}>
                    {currencyFormat(
                      spouse2Data?.sps2_registeredWithdrawals?.[index]
                    )}
                  </Text>{" "}
                  from your <Text style={styles.highlight}>RRSP</Text>
                </Text>
                <Text style={styles.textRow}>
                  <Text style={styles.highlight}>
                    {currencyFormat(
                      spouse2Data?.sps2_registeredWithdrawals?.[index] / 12
                    )}
                  </Text>{" "}
                  per month
                </Text>
                <Text style={styles.description}>
                  Expected annual growth rate: {userRRSPInfo?.RRSP_ROR}%
                </Text>
              </View>
            )}
            {spouse2Data?.sps2_nonregisteredWithdrawals?.[index] && (
              <View style={styles.section}>
                <Text style={styles.textRowHeader}>Non-Registered</Text>
                <Text style={styles.textRow}>
                  Withdraw{" "}
                  <Text style={styles.highlight}>
                    {currencyFormat(
                      spouse2Data?.sps2_nonregisteredWithdrawals?.[index]
                    )}
                  </Text>{" "}
                  from your <Text style={styles.highlight}>Non-Registered</Text>
                </Text>
                <Text style={styles.textRow}>
                  <Text style={styles.highlight}>
                    {currencyFormat(
                      spouse2Data?.sps2_nonregisteredWithdrawals?.[index] / 12
                    )}
                  </Text>{" "}
                  per month
                </Text>
                <Text style={styles.description}>
                  Expected annual growth rate:{" "}
                  {userNonRegInvestmentsInfo?.Non_Reg_ROR}%
                </Text>
              </View>
            )}
            {spouse2Data?.sps2_cashWithdrawals?.[index] >= 50 && (
              <View style={styles.section}>
                <Text style={styles.textRowHeader}>Cash Savings</Text>
                <Text style={styles.textRow}>
                  Withdraw{" "}
                  <Text style={styles.highlight}>
                    {currencyFormat(spouse2Data?.sps2_cashWithdrawals?.[index])}
                  </Text>{" "}
                  from your <Text style={styles.highlight}>Cash Savings</Text>
                </Text>
                <Text style={styles.textRow}>
                  <Text style={styles.highlight}>
                    {currencyFormat(
                      spouse2Data?.sps2_cashWithdrawals?.[index] / 12
                    )}
                  </Text>{" "}
                  per month
                </Text>
                {/* <Text style={styles.description}>
                  Expected annual growth rate: 6%
                </Text> */}
              </View>
            )}
            {spouse2Data?.sps2_fixedIncomeWithdrawals?.[index] >= 50 && (
              <View style={styles.section}>
                <Text style={styles.textRowHeader}>Fixed Income</Text>
                <Text style={styles.textRow}>
                  Withdraw{" "}
                  <Text style={styles.highlight}>
                    {currencyFormat(
                      spouse2Data?.sps2_fixedIncomeWithdrawals?.[index]
                    )}
                  </Text>{" "}
                  from your <Text style={styles.highlight}>Fixed Income</Text>
                </Text>
                <Text style={styles.textRow}>
                  <Text style={styles.highlight}>
                    {currencyFormat(
                      spouse2Data?.sps2_fixedIncomeWithdrawals?.[index] / 12
                    )}
                  </Text>{" "}
                  per month
                </Text>
                <Text style={styles.description}>
                  Expected annual growth rate: {averageInterestSpouse}%
                </Text>
              </View>
            )}
            {/* {safeAdd(
                spouse1Data?.ccpcYearlyCapitalDividendsIssued?.[index],
                spouse1Data?.ccpcYearlyEligibleDividendsIssued?.[index],
                spouse1Data?.ccpcYearlyNonEligibleDividendsIssued?.[index],
                spouse1Data?.ccpcYearlyShareholderLoanRepayments?.[index]
              ) >= 50 && (
                <View style={styles.section}>
                  <Text style={styles.textRowHeader}>
                    CCPC Dividends Issued
                  </Text>
                  {spouse1Data?.ccpcYearlyCapitalDividendsIssued?.[index] >=
                    50 && (
                    <Text style={styles.textRow}>
                      Issue{" "}
                      <Text style={styles.highlight}>
                        {currencyFormat(
                          spouse1Data?.ccpcYearlyCapitalDividendsIssued?.[index] / 2
                        )}
                      </Text>{" "}
                      as a{" "}
                      <Text style={styles.highlight}>Capital Dividend</Text>
                    </Text>
                  )}
                  {spouse1Data?.ccpcYearlyEligibleDividendsIssued?.[index] >=
                    50 && (
                    <Text style={styles.textRow}>
                      Issue{" "}
                      <Text style={styles.highlight}>
                        {currencyFormat(
                          spouse1Data?.ccpcYearlyEligibleDividendsIssued?.[
                            index
                          ] / 2
                        )}
                      </Text>{" "}
                      as a{" "}
                      <Text style={styles.highlight}>Eligible Dividend</Text>
                    </Text>
                  )}
                  {spouse1Data?.ccpcYearlyNonEligibleDividendsIssued?.[index] >=
                    50 && (
                    <Text style={styles.textRow}>
                      Issue{" "}
                      <Text style={styles.highlight}>
                        {currencyFormat(
                          spouse1Data?.ccpcYearlyNonEligibleDividendsIssued?.[
                            index
                          ] / 2
                        )}
                      </Text>{" "}
                      as a{" "}
                      <Text style={styles.highlight}>
                        Non-Eligible Dividend
                      </Text>
                    </Text>
                  )}
                  {spouse1Data?.ccpcYearlyShareholderLoanRepayments?.[index] >=
                    50 && (
                    <Text style={styles.textRow}>
                      Issue{" "}
                      <Text style={styles.highlight}>
                        {currencyFormat(
                          spouse1Data?.ccpcYearlyShareholderLoanRepayments?.[
                            index
                          ] / 2
                        )}
                      </Text>{" "}
                      as a <Text style={styles.highlight}>Loan Repayment</Text>
                    </Text>
                  )}
                  <Text style={styles.description}>
                    Expected annual growth rate: 6%
                  </Text>
                </View>
              )} */}
            {spouse1Data?.sps2_liraWithdrawals?.[index] >= 50 && (
              <View style={styles.section}>
                <Text style={styles.textRowHeader}>LIRA</Text>
                <Text style={styles.textRow}>
                  Withdraw{" "}
                  <Text style={styles.highlight}>
                    {currencyFormat(spouse1Data?.sps2_liraWithdrawals?.[index])}
                  </Text>{" "}
                  from your <Text style={styles.highlight}>LIRA</Text>
                </Text>
                <Text style={styles.textRow}>
                  <Text style={styles.highlight}>
                    {currencyFormat(
                      spouse1Data?.sps2_liraWithdrawals?.[index] / 12
                    )}
                  </Text>{" "}
                  per month
                </Text>
                <Text style={styles.description}>
                  Expected annual growth rate: {userLiraInfo?.LIRA_ROR}%
                </Text>
              </View>
            )}
            {/* Repeat the above <View> for each section as necessary, adjusting data */}
          </View>
          {/* Deposit Sections */}
          {(spouse2Data?.sps2_tfsaContributions?.length > 1 ||
            spouse2Data?.sps2_fhsaContributions?.length > 1 ||
            spouse2Data?.sps2_registeredContributions?.length > 1 ||
            spouse2Data?.sps2_registeredContributionsMatched?.length > 1 ||
            spouse2Data?.sps2_nonRegisteredContributions?.length > 1) && (
            <Text style={styles.subHeading}>DEPOSITS</Text>
          )}
          <View style={styles.row}>
            {spouse2Data?.sps2_tfsaContributions?.[index] >= 50 && (
              <View style={styles.section}>
                <Text style={styles.textRowHeader}>TFSA</Text>
                <Text style={styles.textRow}>
                  Deposit{" "}
                  <Text style={styles.highlight}>
                    {currencyFormat(
                      spouse2Data?.sps2_tfsaContributions?.[index]
                    )}
                  </Text>{" "}
                  into your <Text style={styles.highlight}>TFSA</Text>
                </Text>
                <Text style={styles.textRow}>
                  <Text style={styles.highlight}>
                    {currencyFormat(
                      spouse2Data?.sps2_tfsaContributions?.[index] / 12
                    )}
                  </Text>{" "}
                  per month
                </Text>
                <Text style={styles.description}>
                  Expected annual growth rate: {userTFSAInfo?.TFSA_ROR}%
                </Text>
              </View>
            )}
            {spouse2Data?.sps2_fhsaContributions?.[index] >= 50 && (
              <View style={styles.section}>
                <Text style={styles.textRowHeader}>FHSA</Text>
                <Text style={styles.textRow}>
                  Deposit{" "}
                  <Text style={styles.highlight}>
                    {currencyFormat(
                      spouse2Data?.sps2_fhsaContributions?.[index]
                    )}
                  </Text>{" "}
                  into your <Text style={styles.highlight}>FHSA</Text>
                </Text>
                <Text style={styles.textRow}>
                  <Text style={styles.highlight}>
                    {currencyFormat(
                      spouse2Data?.sps2_fhsaContributions?.[index] / 12
                    )}
                  </Text>{" "}
                  per month
                </Text>
                <Text style={styles.description}>
                  Expected annual growth rate: {userFHSAInfo?.spouseFhsaGrowth}%
                </Text>
              </View>
            )}
            {safeAdd(
              spouse2Data?.sps2_registeredContributions?.[index],
              spouse2Data?.sps2_registeredContributionsMatched?.[index]
            ) >= 50 && (
              <View style={styles.section}>
                <Text style={styles.textRowHeader}>RRSP</Text>
                <Text style={styles.textRow}>
                  Deposit{" "}
                  <Text style={styles.highlight}>
                    {currencyFormat(
                      safeAdd(
                        spouse2Data?.sps2_registeredContributions?.[index],
                        spouse2Data?.sps2_registeredContributionsMatched?.[
                          index
                        ]
                      )
                    )}
                  </Text>{" "}
                  into your <Text style={styles.highlight}>RRSP</Text>
                </Text>
                <Text style={styles.textRow}>
                  <Text style={styles.highlight}>
                    {currencyFormat(
                      safeAdd(
                        spouse2Data?.sps2_registeredContributions?.[index] / 12,
                        spouse2Data?.sps2_registeredContributionsMatched?.[
                          index
                        ] / 12
                      )
                    )}
                  </Text>{" "}
                  per month
                </Text>
                <Text style={styles.description}>
                  Expected annual growth rate: {userRRSPInfo?.RRSP_ROR}%
                </Text>
              </View>
            )}
            {spouse2Data?.sps2_nonRegisteredContributions?.[index] >= 50 && (
              <View style={styles.section}>
                <Text style={styles.textRowHeader}>Non-Registered</Text>
                <Text style={styles.textRow}>
                  Deposit{" "}
                  <Text style={styles.highlight}>
                    {currencyFormat(
                      spouse2Data?.sps2_nonRegisteredContributions?.[index]
                    )}
                  </Text>{" "}
                  into your <Text style={styles.highlight}>Non-Registered</Text>
                </Text>
                <Text style={styles.textRow}>
                  <Text style={styles.highlight}>
                    {currencyFormat(
                      spouse2Data?.sps2_nonRegisteredContributions?.[index] / 12
                    )}
                  </Text>{" "}
                  per month
                </Text>
                <Text style={styles.description}>
                  Expected annual growth rate:{" "}
                  {userNonRegInvestmentsInfo?.Non_Reg_ROR}%
                </Text>
              </View>
            )}
            {spouse2Data?.sps2_pension?.EmployerContributions?.[index] >=
              50 && (
              <View style={styles.section}>
                <Text style={styles.textRowHeader}>Pension</Text>
                <Text style={styles.textRow}>
                  Contribute{" "}
                  <Text style={styles.highlight}>
                    {currencyFormat(
                      spouse2Data?.sps2_pension?.EmployerContributions?.[index]
                    )}
                  </Text>{" "}
                  into your <Text style={styles.highlight}>Pension</Text>
                </Text>
                <Text style={styles.textRow}>
                  <Text style={styles.highlight}>
                    {currencyFormat(
                      spouse2Data?.sps2_pension?.EmployerContributions?.[
                        index
                      ] / 12
                    )}
                  </Text>{" "}
                  per month
                </Text>
                <Text style={styles.description}>
                  Expected annual growth rate: {spouseFoundItem?.Pension_Index}%
                </Text>
              </View>
            )}
            {/* Repeat the above <View> for each section as necessary, adjusting data */}
          </View>
          <View style={styles.row}>
            <Image src={spouseGraphImage} style={styles.graphImage} />
          </View>
          <View style={styles.footerContainer}>
            <Text>optiml.ca</Text>
          </View>
          <View style={styles.footerContainer2}>
            <Text style={styles.subMessage}>
              Oltre Financial Inc. operates under the trademark OPTIML FINTECH
              SOLUTIONS™ (“Optiml™“) and provides certain online goods and
              services software for personalized lifetime tax and estate
              optimization. WE DO NOT PROVIDE FINANCIAL OR INVESTMENT ADVICE. We
              refer to our service offerings as the “Service”, and to Optiml™ as
              we, us and our.
            </Text>
          </View>
        </Page>
      )}
    </Document>
  );
};

const DownloadPDF = ({
  spouse1Data,
  spouse2Data,
  userInfo,
  firstName,
  lastName,
  spouseFirstName,
  spouseLastName,
  year,
  index,
  graphImage,
  spouseStatus,
  spouseGraphImage,
  userTFSAInfo,
  userFHSAInfo,
  userNonRegInvestmentsInfo,
  userFixedIncomeInfo,
  userRRSPInfo,
  userLiraInfo,
  userPensionInfo,
  userBusinessInfo,
}) => (
  <Box>
    <PDFDownloadLink
      document={
        <MyDocument
          spouse1Data={spouse1Data}
          spouse2Data={spouse2Data}
          logoUrl={OptimlBetaIcon}
          firstName={firstName}
          lastName={lastName}
          spouseFirstName={spouseFirstName}
          spouseLastName={spouseLastName}
          year={year}
          index={index}
          graphImage={graphImage}
          spouseStatus={spouseStatus}
          spouseGraphImage={spouseGraphImage}
          userTFSAInfo={userTFSAInfo}
          userFHSAInfo={userFHSAInfo}
          userNonRegInvestmentsInfo={userNonRegInvestmentsInfo}
          userFixedIncomeInfo={userFixedIncomeInfo}
          userRRSPInfo={userRRSPInfo}
          userLiraInfo={userLiraInfo}
          userPensionInfo={userPensionInfo}
          userBusinessInfo={userBusinessInfo}
        />
      }
      fileName="OptimlActionPlan.pdf"
    >
      {({ blob, url, loading, error }) =>
        loading ? (
          <Box display="flex" alignItems="center" cursor="pointer">
            <ChackText
              color="#00A2D4"
              fontFamily="Nunito"
              fontSize="12px"
              fontStyle="normal"
              fontWeight="500"
              lineHeight="normal"
              ml={2} // margin left for spacing
            >
              Preparing PDF...
            </ChackText>
          </Box>
        ) : (
          <Box display="flex" alignItems="center" cursor="pointer">
            <Icon as={DownloadIcon} h="16px" w="16px" color="#00A2D4" />
            <ChackText
              color="#00A2D4"
              fontFamily="Nunito"
              fontSize="16px"
              fontStyle="normal"
              fontWeight="500"
              lineHeight="normal"
              ml={2} // margin left for spacing
            >
              PDF
            </ChackText>
          </Box>
        )
      }
    </PDFDownloadLink>
  </Box>
);

export default DownloadPDF;
