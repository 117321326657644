import { useAuth0 } from "@auth0/auth0-react";
import { Box, Flex, Text, VStack, useMediaQuery } from "@chakra-ui/react";
import axios from "axios";
import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState,
} from "react";
import { useStore } from "../../../store";
import GlobalBodyPage from "../../GlobalContainer/BodyPage";
import DropDownButton from "../../InputFields/dropDownButton";
import NormalInputButton from "../../InputFields/normalInputButton";
import TableComponent from "../../TableComponent";
import TooltipContent from "../../TooltipContent";
import { CloseIcon } from "../../CustomIcons";

const RecreationalPropertyPage = forwardRef((props, ref) => {
  const { getAccessTokenSilently, user } = useAuth0();

  useImperativeHandle(ref, () => ({
    submitHandler,
    checkMandatoryFields,
  }));

  const submitHandler = () => {
    setUserEditAssetInfo({});
    return true;
  };

  const checkMandatoryFields = () => {
    return true;
  };

  const axiosInstance = axios.create();
  async function getToken() {
    // Replace this with your Auth0 token retrieval logic
    const token = await getAccessTokenSilently({
      audience:
        "https://os2lsiwff43wfpb5ggyawt6qri0mgcro.lambda-url.us-east-2.on.aws/",
      scope: "write:optimization",
    });
    return token;
  }

  // Add a request interceptor
  axiosInstance.interceptors.request.use(
    async (config) => {
      const token = await getToken();
      config.headers.Authorization = `Bearer ${token}`;
      return config;
    },
    (error) => {
      // Do something with request error
      return Promise.reject(error);
    }
  );
  const userEditAssetInfo = useStore((store) => store.userEditAssetInfo);
  const setUserEditAssetInfo = useStore((store) => store.setUserEditAssetInfo);

  const [recreationPropertyValue, setRecreationPropertyValue] = useState(0);
  const [recreationPropertyGrowthRate, setRecreationPropertyGrowthRate] =
    useState(0);
  const [recreationPropertyAddress, setRecreationPropertyAddress] = useState();
  const [recreationPropertyMortgageBalance, setRecreationMortgageBalance] =
    useState(0);
  const [recreationPropertyInterestRate, setRecreationPropertyInterestRate] =
    useState(0);
  const [
    recreationPropertyMonthlyPayment,
    setRecreationPropertyMonthlyPayment,
  ] = useState(0);
  const [recreationPropertyPlansToSell, setRecreationPropertyPlansToSell] =
    useState("");
  const [
    recreationPropertyPlansToSellYear,
    setRecreationPropertyPlansToSellYear,
  ] = useState("");
  const [recreationPropertyACB, setRecreationPropertyACB] = useState(0);
  const [recreationCapitalImprovements, setRecreationCapitalImprovements] =
    useState(0);
  const [recreationPropertyNumber, setRecreationPropertyNumber] = useState();

  const [realEstateArray, setRealEstateArray] = useState([]);
  const [editing, setEditing] = useState(false);
  const [editingIndex, setEditingIndex] = useState(null);
  const [showDeleteModal, setShowDeleteModal] = useState(null);

  const [adding, setAdding] = useState(false);

  const [isLargerThan768] = useMediaQuery("(min-width: 500px)");
  const isMobile = !isLargerThan768;

  const userCottageInfo = useStore((store) => store.userCottageInfo);
  const setUserCottageInfo = useStore((store) => store.setUserCottageInfo);
  const updateUserCottageInfo = useStore(
    (store) => store.updateUserCottageInfo
  );
  const deleteUserCottageInfo = useStore(
    (store) => store.deleteUserCottageInfo
  );

  const userInfo = useStore((store) => store.userInfo);

  const {
    FirstName: firstName,
    LastName: lastName,
    DateOfBirth: currentAge,
    spouseFirstName,
    spouseLastName,
    runAge,
  } = userInfo;

  useEffect(() => {
    if (userEditAssetInfo?.editAsset?.name === "Recreation property") {
      setRecreationPropertyValue(userEditAssetInfo?.editAsset?.value);
      setRecreationPropertyInterestRate(
        userEditAssetInfo?.editAsset?.Cottage_Interest
      );
      setRecreationPropertyGrowthRate(
        userEditAssetInfo?.editAsset?.Cottage_Growth
      );
      setRecreationPropertyMonthlyPayment(
        userEditAssetInfo?.editAsset?.Cottage_Monthly_Payments
      );
      setRecreationPropertyPlansToSell(
        userEditAssetInfo?.editAsset?.Do_Cottage_Sell
      );
      setRecreationPropertyPlansToSellYear(
        userEditAssetInfo?.editAsset?.Cottage_Sell_Date
      );
      setRecreationMortgageBalance(
        userEditAssetInfo?.editAsset?.Cottage_Mortgage_Balance
      );
      setRecreationPropertyNumber(userEditAssetInfo?.editAsset?.Cottage_Number);
      setRecreationPropertyACB(userEditAssetInfo?.editAsset?.Cottage_ACB);
      setRecreationCapitalImprovements(
        userEditAssetInfo?.editAsset?.Cottage_Capital_Improvements
      );
      setRecreationPropertyAddress(
        userEditAssetInfo?.editAsset?.Cottage_Address
      );
      setEditing(true);
      setAdding(true);
      setEditingIndex(userEditAssetInfo?.editIndex);
    }
  }, [userEditAssetInfo]);

  useEffect(() => {
    const updatedRealEstateArray = [];

    if (userCottageInfo != null) {
      updatedRealEstateArray.push(...userCottageInfo);
    }

    setRealEstateArray(updatedRealEstateArray);
  }, [userCottageInfo]);

  const onDeleteRow = (i) => {
    setEditingIndex(i);
    setShowDeleteModal(true);
  };

  const editField = (i, array) => {
    setAdding(true);
    if (array[i].name === "Recreation property") {
      setRecreationPropertyValue(array[i]?.value);
      setRecreationPropertyInterestRate(array[i]?.Cottage_Interest);
      setRecreationPropertyGrowthRate(array[i]?.Cottage_Growth);
      setRecreationPropertyMonthlyPayment(array[i]?.Cottage_Monthly_Payments);
      setRecreationPropertyPlansToSell(array[i]?.Do_Cottage_Sell);
      setRecreationPropertyPlansToSellYear(array[i]?.Cottage_Sell_Date);
      setRecreationMortgageBalance(array[i]?.Cottage_Mortgage_Balance);
      setRecreationPropertyNumber(array[i]?.Cottage_Number);
      setRecreationPropertyACB(array[i]?.Cottage_ACB);
      setRecreationCapitalImprovements(array[i]?.Cottage_Capital_Improvements);
      setRecreationPropertyAddress(array[i]?.Cottage_Address);
    }
    setEditing(true);
    setEditingIndex(i);
  };

  const DeleteRealEstateModal = () => {
    let activeArray = realEstateArray;

    const removeRealEstateModal = () => {
      const deleteCottageURL = (action) =>
        `${process.env.REACT_APP_NODE_API_GATEWAY}/CompassLambdaApi/COTTAGE/${action}?Cottage_Number=${activeArray[editingIndex].Cottage_Number}`;
      axiosInstance.get(deleteCottageURL("delete")).then((res) => {
        console.log("Delete res", res);
        deleteUserCottageInfo(activeArray[editingIndex].Cottage_Number);
        removeField(editingIndex, activeArray);
        setEditingIndex(null);
        setEditing(false);
      });
    };

    if (!showDeleteModal) {
      return null;
    }
    const [yesClicked, setYesClicked] = useState(false);
    const [noClicked, setNoClicked] = useState(false);
    return (
      <div
        className="delete-income-modal"
        style={{
          display: "flex",
          alignItems: "center",
          width: "100%",
          maxWidth: "700px",
          justifyContent: "center",
          top: "50%",
          left: "50%",
          transform: isMobile
            ? "translate(-50%, -50%)"
            : "translate(-50%, -50%)",
        }}
        id="delete-income-modal"
      >
        <Box
          display={"flex"}
          width={"100%"}
          padding={"10px 10px 0px 0px"}
          marginLeft={"5px"}
          alignItems={"flex-end"}
          justifyContent={"flex-end"}
          onClick={() => setShowDeleteModal(false)}
          cursor={"pointer"}
        >
          <CloseIcon
            onClick={() => setShowDeleteModal(false)}
            svgheight={"20px"}
            svgwidth={"20px"}
          />
        </Box>
        <Box
          display={"flex"}
          padding={"0px 25px 25px 25px"}
          flexDirection={"column"}
          alignItems={"center"}
          gap={"10px"}
          alignSelf={"stretch"}
          width={"100%"}
        >
          <Text
            color={"#0093D0"}
            fontFamily={"Nunito"}
            fontSize={"24px"}
            fontStyle={"normal"}
            fontWeight={"600"}
            lineHeight={"normal"}
            textDecor={"underline"}
          >
            Are you sure you want to remove this real estate asset?
          </Text>
          <div className="content"></div>
          <div className="actions">
            <button
              className={`decision-button yes-button ${
                yesClicked ? "yes-clicked" : ""
              }`}
              onClick={() => {
                setYesClicked(true);
                setNoClicked(false);
                removeRealEstateModal();
              }}
            >
              Yes
            </button>
            <button
              className={`decision-button no-button ${
                noClicked ? "yes-clicked" : ""
              }`}
              onClick={() => {
                {
                  setNoClicked(true);
                  setYesClicked(false);
                  setShowDeleteModal(false);
                }
              }}
            >
              No
            </button>
          </div>
        </Box>
      </div>
    );
  };

  const removeField = (i, array) => {
    array.splice(i, 1);
    setRealEstateArray((prevArray) => [...prevArray]);
    setShowDeleteModal(false);
  };

  const moneyToNumber = (value) => {
    let numValue = value.toString().replace(/[$,\s]/g, "");
    numValue = Number(numValue);
    return numValue;
  };

  const onSubmitRealEstateAdd = (e, newArray) => {
    e.preventDefault();
    if (newArray.name == "Recreation property") {
      const cottageURL = (action) =>
        `${
          process.env.REACT_APP_NODE_API_GATEWAY
        }/CompassLambdaApi/COTTAGE/${action}?CottageID=${user.sub.replace(
          "|",
          "%7c"
        )}&value=${newArray.value}&Cottage_Interest=${
          newArray.Cottage_Interest
        }&Cottage_Monthly_Payments=${
          newArray.Cottage_Monthly_Payments
        }&Do_Cottage_Sell=${newArray.Do_Cottage_Sell}&Cottage_Sell_Date=${
          newArray.Cottage_Sell_Date
        }&Cottage_Mortgage_Balance=${newArray.Cottage_Mortgage_Balance}&name=${
          newArray.name
        }&Cottage_ACB=${newArray.Cottage_ACB}&Cottage_Address=${
          newArray.Cottage_Address
        }&Cottage_Capital_Improvements=${
          newArray.Cottage_Capital_Improvements
        }&Cottage_Growth=${newArray.Cottage_Growth}`;
      axiosInstance
        .get(cottageURL("add"))
        .then((res) => {
          console.log(res);
          const newData = [
            {
              value: newArray.value,
              Cottage_Interest: newArray.Cottage_Interest,
              Cottage_Monthly_Payments: newArray.Cottage_Monthly_Payments,
              Do_Cottage_Sell: newArray.Do_Cottage_Sell,
              Cottage_Sell_Date: newArray.Cottage_Sell_Date,
              Cottage_Mortgage_Balance: newArray.Cottage_Mortgage_Balance,
              Cottage_Number: res.data.lastInsertedId,
              name: newArray.name,
              Cottage_ACB: newArray.Cottage_ACB,
              Cottage_Address: newArray.Cottage_Address,
              Cottage_Capital_Improvements:
                newArray.Cottage_Capital_Improvements,
              Cottage_Growth: newArray.Cottage_Growth,
            },
          ];
          setUserCottageInfo(newData);
          setUserEditAssetInfo({});
        })
        .catch((error) => {
          console.error(error);
        });
    }
  };

  const onSubmitRealEstateUpdate = (e, newArray) => {
    e.preventDefault();
    let y = newArray.Cottage_Number;
    if (newArray.name == "Recreation property") {
      const cottageURL = (action) =>
        `${
          process.env.REACT_APP_NODE_API_GATEWAY
        }/CompassLambdaApi/COTTAGE/${action}?CottageID=${user.sub.replace(
          "|",
          "%7c"
        )}&value=${newArray.value}&Cottage_Interest=${
          newArray.Cottage_Interest
        }&Cottage_Monthly_Payments=${
          newArray.Cottage_Monthly_Payments
        }&Do_Cottage_Sell=${newArray.Do_Cottage_Sell}&Cottage_Sell_Date=${
          newArray.Cottage_Sell_Date
        }&Cottage_Mortgage_Balance=${
          newArray.Cottage_Mortgage_Balance
        }&Cottage_Number=${newArray.Cottage_Number}&name=${
          newArray.name
        }&Cottage_ACB=${newArray.Cottage_ACB}&Cottage_Address=${
          newArray.Cottage_Address
        }&Cottage_Capital_Improvements=${
          newArray.Cottage_Capital_Improvements
        }&Cottage_Growth=${newArray.Cottage_Growth}`;
      axiosInstance
        .get(cottageURL("update"))
        .then((res) => {
          console.log(res);
          const newData = {
            value: newArray.value,
            Cottage_Interest: newArray.Cottage_Interest,
            Cottage_Monthly_Payments: newArray.Cottage_Monthly_Payments,
            Do_Cottage_Sell: newArray.Do_Cottage_Sell,
            Cottage_Sell_Date: newArray.Cottage_Sell_Date,
            Cottage_Mortgage_Balance: newArray.Cottage_Mortgage_Balance,
            Cottage_Number: newArray.Cottage_Number,
            name: newArray.name,
            Cottage_ACB: newArray.Cottage_ACB,
            Cottage_Address: newArray.Cottage_Address,
            Cottage_Capital_Improvements: newArray.Cottage_Capital_Improvements,
            Cottage_Growth: newArray.Cottage_Growth,
          };
          updateUserCottageInfo(y, newData);
          setUserEditAssetInfo({});
        })
        .catch((error) => {
          console.error(error);
        });
    }
  };

  const addRealEstate = (e, list) => {
    if (editing) {
      setRealEstateArray((prevArray) => {
        let newArray = [...prevArray];
        newArray[editingIndex] = list;
        onSubmitRealEstateUpdate(e, newArray[editingIndex]);
        return newArray;
      });
      setEditing(false);
      setEditingIndex(null);
    } else {
      setRealEstateArray((prevArray) => {
        let newArray = [...prevArray, list];
        onSubmitRealEstateAdd(e, newArray[newArray.length - 1]);
        return newArray;
      });
    }

    setRecreationPropertyValue(0);
    setRecreationPropertyInterestRate(0);
    setRecreationPropertyGrowthRate(0);
    setRecreationPropertyMonthlyPayment(0);
    setRecreationPropertyPlansToSell("");
    setRecreationPropertyPlansToSellYear("");
    setRecreationPropertyACB(0);
    setRecreationCapitalImprovements(0);
    setRecreationPropertyAddress("");
    setAdding(false);
  };

  const [errors, setErrors] = useState({});

  const checkMandatoryAddUpdateFields = () => {
    let newErrors = {};

    // Recreation property conditions
    if (!recreationPropertyValue)
      newErrors.recreationPropertyValue =
        "Recreation property value is required";
    if (!recreationPropertyPlansToSell)
      newErrors.recreationPropertyPlansToSell =
        "Plan to sell recreation property is required";
    if (recreationPropertyGrowthRate > 20)
      newErrors.recreationPropertyGrowthRate =
        "Growth rate must be less than 20%";
    if (recreationPropertyInterestRate > 20)
      newErrors.recreationPropertyInterestRate =
        "Interest rate must be less than 20%";
    if (
      recreationPropertyPlansToSell === "Yes" &&
      (!recreationPropertyPlansToSellYear ||
        !recreationPropertyACB ||
        !recreationCapitalImprovements)
    ) {
      if (!recreationPropertyPlansToSellYear)
        newErrors.recreationPropertyPlansToSellYear =
          "Year to sell recreation property is required";
      if (!recreationPropertyACB)
        newErrors.recreationPropertyACB = "Adjusted cost base is required";
      if (!recreationCapitalImprovements)
        newErrors.recreationCapitalImprovements =
          "Capital improvements are required";
    }

    const effectiveMonthlyInterest =
      (1 + ((1 + recreationPropertyInterestRate / 200) ** 2 - 1)) ** (1 / 12) -
      1;

    if (
      recreationPropertyMortgageBalance > 0 &&
      recreationPropertyMonthlyPayment <
        recreationPropertyMortgageBalance * effectiveMonthlyInterest
    ) {
      newErrors.recreationPropertyMonthlyPayment =
        "Monthly payment is less than the effective monthly interest.";
    }

    if (
      recreationPropertyMortgageBalance > 0 &&
      recreationPropertyMonthlyPayment < 1
    ) {
      newErrors.recreationPropertyMonthlyPayment =
        "Monthly payment is less than 1.";
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0; // Return true if no errors
  };

  const renderAddUpdateRecreational = () => {
    if (
      !recreationPropertyValue ||
      !recreationPropertyPlansToSell ||
      recreationPropertyGrowthRate > 20 ||
      recreationPropertyInterestRate > 20 ||
      (recreationPropertyPlansToSell === "Yes" &&
        (!recreationPropertyPlansToSellYear ||
          !recreationPropertyACB ||
          !recreationCapitalImprovements)) ||
      (recreationPropertyMortgageBalance > 0 &&
        recreationPropertyMonthlyPayment <
          recreationPropertyMortgageBalance *
            ((1 + ((1 + recreationPropertyInterestRate / 200) ** 2 - 1)) **
              (1 / 12) -
              1)) ||
      (recreationPropertyMortgageBalance > 0 &&
        recreationPropertyMonthlyPayment < 1)
    ) {
      return (
        <Box
          display={"flex"}
          padding={"8px 48px"}
          marginTop={"20px"}
          justifyContent={"flex-end"}
          alignItems={"center"}
          gap={"5px"}
          borderRadius={"5px"}
          bg={"#27AE60"}
          cursor={"pointer"}
          onClick={checkMandatoryAddUpdateFields}
        >
          <Box
            display={"flex"}
            width={"76px"}
            height={"21px"}
            flexDirection={"column"}
            justifyContent={"center"}
          >
            <Text
              color={"#FFFFFF"}
              textAlign={"center"}
              fontFamily={"Nunito"}
              fontSize={"16px"}
              fontStyle={"normal"}
              fontWeight={"600"}
              lineHeight={"normal"}
            >
              {editing ? "Update" : "Add"}
            </Text>
          </Box>
        </Box>
      );
    } else {
      return (
        <Box
          display={"flex"}
          padding={"8px 48px"}
          marginTop={"20px"}
          justifyContent={"flex-end"}
          alignItems={"center"}
          gap={"5px"}
          borderRadius={"5px"}
          bg={"#27AE60"}
          cursor={"pointer"}
          onClick={(e) =>
            addRealEstate(e, {
              name: "Recreation property",
              value: recreationPropertyValue,
              Cottage_Interest: recreationPropertyInterestRate,
              Cottage_Growth: recreationPropertyGrowthRate,
              Cottage_Monthly_Payments: recreationPropertyMonthlyPayment,
              Do_Cottage_Sell: recreationPropertyPlansToSell,
              Cottage_Sell_Date: recreationPropertyPlansToSellYear,
              Cottage_Mortgage_Balance: recreationPropertyMortgageBalance,
              Cottage_ACB: recreationPropertyACB,
              Cottage_Capital_Improvements: recreationCapitalImprovements,
              Cottage_Address: recreationPropertyAddress,
              ...(editing ? { Cottage_Number: recreationPropertyNumber } : {}), // add field conditionally
            })
          }
        >
          <Box
            display={"flex"}
            width={"76px"}
            height={"21px"}
            flexDirection={"column"}
            justifyContent={"center"}
            cursor={"pointer"}
          >
            <Text
              color={"#FFFFFF"}
              textAlign={"center"}
              fontFamily={"Nunito"}
              fontSize={"16px"}
              fontStyle={"normal"}
              fontWeight={"600"}
              lineHeight={"normal"}
            >
              {editing ? "Update" : "Add"}
            </Text>
          </Box>
        </Box>
      );
    }
  };

  const columns = [
    { header: "Name", field: "name" },
    { header: "Value", field: "value" },
  ];

  const data = realEstateArray.map((item) => ({
    name: item?.Cottage_Address ? item?.Cottage_Address : item.name,
    value: item?.value,
    link: "/recreational-property-form",
  }));

  const handleClick = () => {
    setAdding(true);
  };

  function getAge(birthday, currentDate) {
    // Convert the birthday string into a Date object
    const birthDate = new Date(birthday);

    // Calculate the age in years
    let age = currentDate.getFullYear() - birthDate.getFullYear();

    // Decrement the age if the current month and day are before the birth month and day
    if (
      currentDate.getMonth() < birthDate.getMonth() ||
      (currentDate.getMonth() === birthDate.getMonth() &&
        currentDate.getDate() <= birthDate.getDate())
    ) {
      age -= 1;
    }

    return age;
  }

  const Select = (userAge) => {
    const currentDate = new Date();
    const currentYear = currentDate.getFullYear();
    let age = getAge(userAge, currentDate); // Assuming getAge returns the starting age.
    const runAgeList = [];
    for (let i = age; i <= 100; i++) {
      runAgeList.push({ key: i, value: currentYear + (i - age) }); // Convert i to string if necessary
    }
    return runAgeList;
  };

  return (
    <Flex
      display="flex"
      flexWrap="wrap" // Enable wrapping
      justifyContent="flex-start"
      alignItems="start"
      width={"100%"}
    >
      {!isMobile && (
        <VStack alignItems={"flex-start"} width={"100%"} mt={-2}>
          <GlobalBodyPage
            Heading="Recreational property"
            mainframeContent={
              <>
                <Box
                  display={"flex"}
                  width={"100%"}
                  flexDirection={"row"}
                  paddingTop={"10px"}
                  alignItems={"flex-end"}
                  justifyContent={"space-between"}
                  gap={"10px"}
                  flexWrap={"wrap"}
                >
                  <DeleteRealEstateModal />
                  {data.length > 0 && !adding && (
                    <TableComponent
                      columns={columns}
                      data={data}
                      editField={editField}
                      onDeleteRow={onDeleteRow}
                      rawData={realEstateArray}
                    />
                  )}
                  {(data.length < 1 || adding) && (
                    <>
                      <Text
                        alignSelf={"stretch"}
                        color={"var(--Optiml---Deep-Ocean-Blue, #007EB5)"}
                        fontFamily={"Nunito"}
                        fontSize={"16px"}
                        fontStyle={"normal"}
                        fontWeight={"600"}
                        lineHeight={"normal"}
                        width={"100%"}
                      >
                        Property details:
                      </Text>
                      <NormalInputButton
                        Heading={"Address"}
                        value={recreationPropertyAddress}
                        onChange={(e) => {
                          setRecreationPropertyAddress(e.target.value);
                        }}
                        customWidth={"100%"}
                        InputWidth={"100%"}
                        error={errors.recreationPropertyAddress}
                        tooltipContent={TooltipContent.recPropertyAddress}
                      />
                      <NormalInputButton
                        Heading={"Current value"}
                        value={recreationPropertyValue}
                        onChange={(e) => {
                          setRecreationPropertyValue(
                            moneyToNumber(e.target.value)
                          );
                        }}
                        customWidth={"100%"}
                        placeholder={"$"}
                        useThousandSeparator={true}
                        error={errors.recreationPropertyValue}
                        tooltipContent={TooltipContent.recPropertyValue}
                      />
                      <Text
                        alignSelf={"stretch"}
                        color={"var(--Optiml---Deep-Ocean-Blue, #007EB5)"}
                        fontFamily={"Nunito"}
                        fontSize={"16px"}
                        fontStyle={"normal"}
                        fontWeight={"600"}
                        lineHeight={"normal"}
                        width={"100%"}
                      >
                        Financial performance:
                      </Text>
                      <NormalInputButton
                        Heading={"Growth rate"}
                        value={recreationPropertyGrowthRate}
                        onChange={(e) => {
                          const noSymbol = e.target.value.replace("%", "");
                          const growthNum = parseFloat(noSymbol);
                          setRecreationPropertyGrowthRate(
                            !isNaN(growthNum)
                              ? parseFloat(growthNum.toFixed(2))
                              : 0
                          );
                        }}
                        customWidth={"100%"}
                        placeholder={"%"}
                        usePercentage={true}
                        error={errors.recreationPropertyGrowthRate}
                        tooltipContent={TooltipContent.recPropertyGrowth}
                      />
                      <Text
                        alignSelf={"stretch"}
                        color={"var(--Optiml---Deep-Ocean-Blue, #007EB5)"}
                        fontFamily={"Nunito"}
                        fontSize={"16px"}
                        fontStyle={"normal"}
                        fontWeight={"600"}
                        lineHeight={"normal"}
                        width={"100%"}
                      >
                        Mortgage information:
                      </Text>
                      <NormalInputButton
                        Heading={"Remaining mortgage balance"}
                        value={recreationPropertyMortgageBalance}
                        onChange={(e) => {
                          setRecreationMortgageBalance(
                            moneyToNumber(e.target.value)
                          );
                        }}
                        placeholder={"$"}
                        useThousandSeparator={true}
                        error={errors.recreationPropertyMortgageBalance}
                        tooltipContent={
                          TooltipContent.recPropertyMortgageBalance
                        }
                      />
                      <NormalInputButton
                        Heading={"Monthly mortgage payment"}
                        value={recreationPropertyMonthlyPayment}
                        onChange={(e) => {
                          setRecreationPropertyMonthlyPayment(
                            moneyToNumber(e.target.value)
                          );
                        }}
                        placeholder={"$"}
                        useThousandSeparator={true}
                        error={errors.recreationPropertyMonthlyPayment}
                        tooltipContent={
                          TooltipContent.recPropertyMonthlyMortgage
                        }
                      />
                      <NormalInputButton
                        Heading={"Mortgage interest rate"}
                        value={recreationPropertyInterestRate}
                        onChange={(e) => {
                          const noSymbol = e.target.value.replace("%", "");
                          const growthNum = parseFloat(noSymbol);
                          setRecreationPropertyInterestRate(
                            !isNaN(growthNum)
                              ? parseFloat(growthNum.toFixed(2))
                              : 0
                          );
                        }}
                        placeholder={"%"}
                        usePercentage={true}
                        error={errors.recreationPropertyInterestRate}
                        tooltipContent={TooltipContent.recPropertyInterestRate}
                      />
                      <Text
                        alignSelf={"stretch"}
                        color={"var(--Optiml---Deep-Ocean-Blue, #007EB5)"}
                        fontFamily={"Nunito"}
                        fontSize={"16px"}
                        fontStyle={"normal"}
                        fontWeight={"600"}
                        lineHeight={"normal"}
                        width={"100%"}
                      >
                        Future Real Estate Plans
                      </Text>
                      <DropDownButton
                        Heading={
                          "Do you have plans to sell your recreation property?"
                        }
                        placeholder={"Select"}
                        dropDownItems={[
                          { key: "Yes", value: "Yes" },
                          { key: "No", value: "No" },
                        ]}
                        value={recreationPropertyPlansToSell}
                        onChange={(key) => {
                          setRecreationPropertyPlansToSell(key);
                        }}
                        customWidth={"100%"}
                        error={errors.recreationPropertyPlansToSell}
                        tooltipContent={TooltipContent.recPropertySaleYesNo}
                      />
                      {recreationPropertyPlansToSell === "Yes" && (
                        <DropDownButton
                          Heading={
                            "When do you anticipate you may sell your recreation property?"
                          }
                          placeholder={"Select"}
                          dropDownItems={Select(currentAge)}
                          value={recreationPropertyPlansToSellYear}
                          onChange={(key) => {
                            setRecreationPropertyPlansToSellYear(key);
                          }}
                          customWidth={"100%"}
                          error={errors.recreationPropertyPlansToSellYear}
                          tooltipContent={TooltipContent.recPropertySaleAge}
                        />
                      )}
                      {recreationPropertyPlansToSell === "Yes" && (
                        <Text
                          alignSelf={"stretch"}
                          color={"var(--Optiml---Deep-Ocean-Blue, #007EB5)"}
                          fontFamily={"Nunito"}
                          fontSize={"16px"}
                          fontStyle={"normal"}
                          fontWeight={"600"}
                          lineHeight={"normal"}
                          width={"100%"}
                        >
                          Investment:
                        </Text>
                      )}
                      {recreationPropertyPlansToSell === "Yes" && (
                        <NormalInputButton
                          Heading={"Purchase price"}
                          value={recreationPropertyACB}
                          onChange={(e) => {
                            setRecreationPropertyACB(
                              moneyToNumber(e.target.value)
                            );
                          }}
                          placeholder={"$"}
                          useThousandSeparator={true}
                          error={errors.recreationPropertyACB}
                          tooltipContent={
                            TooltipContent.recPropertyPurchasePrice
                          }
                        />
                      )}
                      {recreationPropertyPlansToSell === "Yes" && (
                        <NormalInputButton
                          Heading={"Capital improvements"}
                          value={recreationCapitalImprovements}
                          onChange={(e) => {
                            setRecreationCapitalImprovements(
                              moneyToNumber(e.target.value)
                            );
                          }}
                          placeholder={"$"}
                          useThousandSeparator={true}
                          error={errors.recreationCapitalImprovements}
                          tooltipContent={
                            TooltipContent.recPropertyCapitalImprovements
                          }
                        />
                      )}
                    </>
                  )}
                </Box>
                {data.length > 0 && !adding && (
                  <Box
                    display={"flex"}
                    justifyContent={"flex-start"}
                    alignItems={"center"}
                    alignSelf={"stretch"}
                    onClick={handleClick}
                  >
                    <Box
                      display={"flex"}
                      padding={"8px 48px"}
                      marginTop={"20px"}
                      justifyContent={"flex-end"}
                      alignItems={"center"}
                      gap={"5px"}
                      borderRadius={"5px"}
                      bg={"#27AE60"}
                      cursor={"pointer"}
                    >
                      <Box
                        display={"flex"}
                        width={"76px"}
                        height={"21px"}
                        flexDirection={"column"}
                        justifyContent={"center"}
                      >
                        <Text
                          color={"#FFFFFF"}
                          textAlign={"center"}
                          fontFamily={"Nunito"}
                          fontSize={"16px"}
                          fontStyle={"normal"}
                          fontWeight={"600"}
                          lineHeight={"normal"}
                        >
                          Add
                        </Text>
                      </Box>
                    </Box>
                  </Box>
                )}
                {(data.length < 1 || adding) && (
                  <Box
                    display={"flex"}
                    justifyContent={"flex-end"}
                    alignItems={"center"}
                    alignSelf={"stretch"}
                  >
                    {renderAddUpdateRecreational()}
                  </Box>
                )}
              </>
            }
            videoName={"Recreation Property Page"}
            video={"Recreational_Property.mp4"}
          />
        </VStack>
      )}
      {isMobile && (
        <VStack alignItems={"flex-start"} width={"100%"} mt={-2}>
          <GlobalBodyPage
            Heading="Recreational property"
            mainframeContent={
              <>
                <Box
                  display={"flex"}
                  width={"100%"}
                  flexDirection={"row"}
                  paddingTop={"10px"}
                  alignItems={"flex-end"}
                  justifyContent={"space-between"}
                  gap={"10px"}
                  flexWrap={"wrap"}
                >
                  <DeleteRealEstateModal />
                  {data.length > 0 && !adding && (
                    <TableComponent
                      columns={columns}
                      data={data}
                      editField={editField}
                      onDeleteRow={onDeleteRow}
                      rawData={realEstateArray}
                    />
                  )}
                  {(data.length < 1 || adding) && (
                    <>
                      <Text
                        alignSelf={"stretch"}
                        color={"#007EB5"}
                        fontFamily={"Nunito"}
                        fontSize={"16px"}
                        fontStyle={"normal"}
                        fontWeight={"600"}
                        lineHeight={"normal"}
                      >
                        Property details:
                      </Text>
                      <NormalInputButton
                        Heading={"Address"}
                        value={recreationPropertyAddress}
                        onChange={(e) => {
                          setRecreationPropertyAddress(e.target.value);
                        }}
                        customWidth={"100%"}
                        InputWidth={"100%"}
                        error={errors.recreationPropertyAddress}
                        tooltipContent={TooltipContent.recPropertyAddress}
                      />
                      <NormalInputButton
                        Heading={"Current value"}
                        value={recreationPropertyValue}
                        onChange={(e) => {
                          setRecreationPropertyValue(e.target.value);
                        }}
                        customWidth={"100%"}
                        InputWidth={"100%"}
                        error={errors.recreationPropertyValue}
                        tooltipContent={TooltipContent.recPropertyValue}
                      />
                      <Text
                        alignSelf={"stretch"}
                        color={"var(--Optiml---Deep-Ocean-Blue, #007EB5"}
                        fontFamily={"Nunito"}
                        fontSize={"16px"}
                        fontStyle={"normal"}
                        fontWeight={"600"}
                        lineHeight={"normal"}
                      >
                        Financial performance:
                      </Text>
                      <NormalInputButton
                        Heading={"Growth rate"}
                        value={recreationPropertyGrowthRate}
                        onChange={(e) => {
                          setRecreationPropertyGrowthRate(e.target.value);
                        }}
                        customWidth={"100%"}
                        InputWidth={"100%"}
                        usePercentage={true}
                        error={errors.recreationPropertyGrowthRate}
                        tooltipContent={TooltipContent.recPropertyGrowth}
                      />
                      <Text
                        alignSelf={"stretch"}
                        color={"var(--Optiml---Deep-Ocean-Blue, #007EB5"}
                        fontFamily={"Nunito"}
                        fontSize={"16px"}
                        fontStyle={"normal"}
                        fontWeight={"600"}
                        lineHeight={"normal"}
                      >
                        Mortgage information:
                      </Text>
                      <NormalInputButton
                        Heading={"Remaining mortgage balance"}
                        value={recreationPropertyMortgageBalance}
                        onChange={(e) => {
                          setRecreationMortgageBalance(e.target.value);
                        }}
                        customWidth={"100%"}
                        InputWidth={"100%"}
                        error={errors.recreationPropertyMortgageBalance}
                        tooltipContent={
                          TooltipContent.recPropertyMonthlyMortgage
                        }
                      />
                      <NormalInputButton
                        Heading={"Monthly mortgage payment"}
                        value={recreationPropertyInterestRate}
                        onChange={(e) => {
                          setRecreationPropertyInterestRate(e.target.value);
                        }}
                        customWidth={"100%"}
                        InputWidth={"100%"}
                        usePercentage={true}
                        error={errors.recreationPropertyInterestRate}
                        tooltipContent={TooltipContent.recPropertyInterestRate}
                      />
                      <NormalInputButton
                        Heading={"Mortgage interest rate"}
                        value={recreationPropertyMonthlyPayment}
                        onChange={(e) => {
                          setRecreationPropertyMonthlyPayment(e.target.value);
                        }}
                        customWidth={"100%"}
                        InputWidth={"100%"}
                        error={errors.recreationPropertyMonthlyPayment}
                        tooltipContent={
                          TooltipContent.recPropertyMonthlyMortgage
                        }
                      />
                      <Text
                        alignSelf={"stretch"}
                        color={"var(--Optiml---Deep-Ocean-Blue, #007EB5"}
                        fontFamily={"Nunito"}
                        fontSize={"16px"}
                        fontStyle={"normal"}
                        fontWeight={"600"}
                        lineHeight={"normal"}
                      >
                        Future Real Estate Plans
                      </Text>
                      <DropDownButton
                        Heading={
                          "Do you have plans to sell your recreation property?"
                        }
                        placeholder={"Select"}
                        dropDownItems={[
                          { key: "Yes", value: "Yes" },
                          { key: "No", value: "No" },
                        ]}
                        value={recreationPropertyPlansToSell}
                        onChange={(key) => {
                          setRecreationPropertyPlansToSell(key);
                        }}
                        customWidth={"100%"}
                        MenuCustomWidth={"100%"}
                        error={errors.recreationPropertyPlansToSell}
                        tooltipContent={TooltipContent.recPropertySaleYesNo}
                      />
                      {recreationPropertyPlansToSell === "Yes" && (
                        <DropDownButton
                          Heading={
                            "When do you anticipate you may sell your recreation property?"
                          }
                          placeholder={"Select"}
                          dropDownItems={Select(currentAge)}
                          value={recreationPropertyPlansToSellYear}
                          onChange={(key) => {
                            setRecreationPropertyPlansToSellYear(key);
                          }}
                          customWidth={"100%"}
                          MenuCustomWidth={"100%"}
                          error={errors.recreationPropertyPlansToSellYear}
                          tooltipContent={TooltipContent.recPropertySaleAge}
                        />
                      )}
                      {recreationPropertyPlansToSell === "Yes" && (
                        <Text
                          alignSelf={"stretch"}
                          color={"var(--Optiml---Deep-Ocean-Blue, #007EB5)"}
                          fontFamily={"Nunito"}
                          fontSize={"16px"}
                          fontStyle={"normal"}
                          fontWeight={"600"}
                          lineHeight={"normal"}
                          width={"100%"}
                        >
                          Investment:
                        </Text>
                      )}
                      {recreationPropertyPlansToSell === "Yes" && (
                        <NormalInputButton
                          Heading={"Purchase price"}
                          value={recreationPropertyACB}
                          onChange={(e) => {
                            setRecreationPropertyACB(e.target.value);
                          }}
                          customWidth={"100%"}
                          InputWidth={"100%"}
                          error={errors.recreationPropertyACB}
                          tooltipContent={
                            TooltipContent.recPropertyPurchasePrice
                          }
                        />
                      )}
                      {recreationPropertyPlansToSell === "Yes" && (
                        <NormalInputButton
                          Heading={"Capital improvements"}
                          value={recreationCapitalImprovements}
                          onChange={(e) => {
                            setRecreationCapitalImprovements(e.target.value);
                          }}
                          customWidth={"100%"}
                          InputWidth={"100%"}
                          error={errors.recreationCapitalImprovements}
                          tooltipContent={
                            TooltipContent.recPropertyCapitalImprovements
                          }
                        />
                      )}
                    </>
                  )}
                </Box>
                {data.length > 0 && !adding && (
                  <Box
                    display={"flex"}
                    justifyContent={"flex-start"}
                    alignItems={"center"}
                    alignSelf={"stretch"}
                  >
                    <Box
                      display={"flex"}
                      padding={"8px 48px"}
                      marginTop={"20px"}
                      justifyContent={"flex-end"}
                      alignItems={"center"}
                      gap={"5px"}
                      borderRadius={"5px"}
                      bg={"#27AE60"}
                      cursor={"pointer"}
                      onClick={handleClick}
                    >
                      <Box
                        display={"flex"}
                        width={"76px"}
                        height={"21px"}
                        flexDirection={"column"}
                        justifyContent={"center"}
                      >
                        <Text
                          color={"#FFFFFF"}
                          textAlign={"center"}
                          fontFamily={"Nunito"}
                          fontSize={"16px"}
                          fontStyle={"normal"}
                          fontWeight={"600"}
                          lineHeight={"normal"}
                        >
                          Add
                        </Text>
                      </Box>
                    </Box>
                  </Box>
                )}
                {(data.length < 1 || adding) && (
                  <Box
                    display={"flex"}
                    justifyContent={"flex-end"}
                    alignItems={"center"}
                    alignSelf={"stretch"}
                  >
                    {renderAddUpdateRecreational()}
                  </Box>
                )}
              </>
            }
            videoName={"Recreation Property Page"}
            video={"Recreational_Property.mp4"}
          />
        </VStack>
      )}
    </Flex>
  );
});

export default RecreationalPropertyPage;
