import React from 'react';
import { Box, Text } from '@chakra-ui/react';

const HeadingWithMessage = ({ heading, message }) => {
  return (
    <Box
    display={"flex"}
    alignItems={"center"}
    gap={"10px"}
    alignSelf={"stretch"} 
    width={"100%"}
    flexWrap={"wrap"}
    >
      <Text color={"#00466D"} fontFamily={"Nunito"} fontSize={"24px"} fontStyle={"normal"} fontWeight={"700"} lineHeight={"normal"}>
        {heading}
      </Text>
      {message && (
        <Box maxW={"100%"} height={"48px"} border={"1px solid #E71D36"} borderRadius={"5px"} bg={"rgba(231, 29, 54, 0.10)"} flexShrink={0} alignContent={"center"} padding={"10px"}> 
          <Text color={"#E71D36"} fontFamily={"Nunito"} fontSize={"12px"} fontStyle={"normal"} fontWeight={"700"} lineHeight={"normal"}>
            {message}
          </Text>
        </Box>
      )}
    </Box>
  );
};

export default HeadingWithMessage;
